import { ProcessJsonResponse } from './common';

const baseUrlOrdre = '/sparing-min-side-web/rest/resource/fond/ordre';

export const HENT_SISTE_ORDRE = 'HENT_SISTE_ORDRE';
export const HENT_VENTENDE_ORDRE = 'HENT_VENTENDE_ORDRE';
export const HENT_ALLE_VENTENDE_ORDRE = 'HENT_ALLE_VENTENDE_ORDRE';

export const hentVentendeOrdre = (portfolioId, isin) => {
    const url = `${baseUrlOrdre}/ventende/${portfolioId}/${isin}`;

    return (dispatch) => {
        fetch(url, { credentials: 'same-origin' }).then((data) => {
            ProcessJsonResponse(HENT_VENTENDE_ORDRE, data, dispatch, { portfolioId, isin });
        });
    };
};

export const hentSisteOrdre = (portefoljeData) => {
    const portfolioCodes = portefoljeData.portefoljer.map((p) => p.portfolioBaseCode);
    const url = `${baseUrlOrdre}/siste?${portfolioCodes.map((p) => `portfolioCodes=${p}`).join('&')}`;

    const config = {
        credentials: 'same-origin',
    };

    return (dispatch) => {
        fetch(url, config).then((data) => ProcessJsonResponse(HENT_SISTE_ORDRE, data, dispatch));
    };
};

export const hentAlleVentendeOrdre = (portefoljeData) => {
    const { portefoljer } = portefoljeData;
    const data = [];
    portefoljer.map((p) => data.push({ portefoljeId: p.id, bankregnr: { value: p.bank.bankregnr } }));

    const url = `${baseUrlOrdre}/hentalleventendeordre`;

    const config = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        fetch(url, config).then((data) => ProcessJsonResponse(HENT_ALLE_VENTENDE_ORDRE, data, dispatch));
    };
};
