import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';

require('../../styles/smartspar.scss');

class SmartSparPopUp extends Component {
    static propTypes = {
        texts: PropTypes.object,
        hentVisSmartSparPopup: PropTypes.func,
        skjulSmartSparPopup: PropTypes.func,
        registrerSmartSparPopupVisning: PropTypes.func,
        visSmartsparPopup: PropTypes.bool,
        appStore: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            visSmartsparPopup: props.visSmartsparPopup
        };

        this.props.hentVisSmartSparPopup();
    }

    componentDidMount = () => this.props.registrerSmartSparPopupVisning();

    UNSAFE_componentWillReceiveProps = newProps => {
        if (newProps.visSmartsparPopup !== this.state.visSmartsparPopup) {
            this.setState({
                visSmartsparPopup: newProps.visSmartsparPopup
            });
        }
    };

    render() {
        const { texts, skjulSmartSparPopup, appStore } = this.props;
        const { visSmartsparPopup } = this.state;

        return (
            <div id="sparing-react-app">
                <Modal visModal={visSmartsparPopup} lukkModal={skjulSmartSparPopup}>
                    <div id="smartspar-popup">
                        <div className="tittel">{texts.get('smartsparPopupTittel')}</div>

                        <div className="liste-punkter">
                            <i className="ikon-sparing-24" />
                            {texts.get('smartsparPunktEn')}
                        </div>

                        <div className="liste-punkter">
                            <i className="ikon-sparegris-24" />
                            {texts.get('smartsparPunktTo')}
                        </div>

                        <div className="liste-punkter">
                            <i className="ikon-lyspaere-24" />
                            {texts.get('smartsparPunktTre')}
                        </div>

                        <div className="app-store-images">
                            <a target="_blank" href={appStore.link} rel="noopener noreferrer">
                                <img
                                    className="app-link-image"
                                    height="57"
                                    width="192"
                                    alt="Appstore logo"
                                    src={appStore.image}
                                />
                            </a>
                        </div>
                        <a
                            className="lenke"
                            target="_blank"
                            href="https://www.eika.no/smartspar"
                            rel="noopener noreferrer"
                        >
                            {texts.get('smartsparLenke')}
                        </a>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default SmartSparPopUp;
