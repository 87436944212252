import {
    HENT_LISTE_OVER_CRS_LAND,
    KUNDEVURDERING,
    KJENNDINKUNDE_SKJEMA_FOR_KUNDE,
    KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN,
    KJENNDINKUNDE_KAN_UTFYLLE_AHV,
    KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE,
    KJENNDINKUNDE_LOADING,
} from '../actions/kjenndinkunde';

const initialState = {
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case KJENNDINKUNDE_LOADING:
            return {
                ...state,
                loading: action.data,
            };
        case HENT_LISTE_OVER_CRS_LAND:
            return {
                ...state,
                crsLand: action.data,
                loading: false,
            };
        case KUNDEVURDERING:
            return {
                ...state,
                kundevurdering: action.data,
                loading: false,
            };
        case KJENNDINKUNDE_SKJEMA_FOR_KUNDE:
            return {
                ...state,
                skjemaForKunde: action.data,
                loading: false,
            };
        case KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE:
            return {
                ...state,
                oppfolgingSkjemaForKunde: action.data,
                loading: false,
            };
        case KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN:
            return {
                ...state,
                skjemaForKundeBarn: action.data,
                loading: false,
            };
        case KJENNDINKUNDE_KAN_UTFYLLE_AHV:
            return {
                ...state,
                kanUtfylleAHV: action.data,
                loading: false,
            };
        default:
            return state;
    }
};
