import React from 'react';
import classNames from 'classnames';
import { TransactionList } from '@eika/transaction-list';
import { formatDateToText, formatAmount } from '../../../common/util/Functions';
import Card from '../../../common/Card/Card';
import './ShareComponents.scss';

/** ************************************************************************
 ************************************************************************* */
interface ViewTransactionContentProps {
    order: string[];
    storage: { [k: string]: any };
    title?: string;
    type?: string;
};

export const ViewTransactionContent = ({ order, storage, title, type='normal' }: ViewTransactionContentProps): JSX.Element | null => {
    return order.length ? <div className={`view-transaction-content -${type}`}>
        { !!title && <h2 className="view-transaction-title">{title}</h2> }
        { order.map( (year: string, i: number) => (
            <div key={`transaction-collection-${i}`} className="transaction-collection">
                <div className="collection-header">{year}</div>
                { !!storage && <div className="collection-holder">
                    { (storage[year] || []).map( (data: any, j: number) => (
                        <Card key={`transaksjon-item-${i}-${j}`} {...data} type="spare-link-panel -transaksjon -collection" />
                    ))}
                </div> }
            </div >
        ))}
    </div> : null;
};

/** ************************************************************************
 ************************************************************************* */
interface ViewLegendDescriptionProps {
    list: any;
    namespace?: string;
};

export const ViewLegendDescription = ({ list, namespace }: ViewLegendDescriptionProps): JSX.Element | null => {
    return (list || []).length ? <ul className={classNames('view-legend-description', namespace)}>
        { list.map( (data: any, i: number) => {
            return <li key={`legend-description-item-${i}`}>
                <div className="item-color" aria-hidden="true"><span style={{backgroundColor: data.color}}/></div>
                <div className="item-text">{data.text}</div>
                <div className="item-value">{data.value}</div>
            </li>
        })}        
    </ul> : null;
};

/** ************************************************************************
 ************************************************************************* */
interface ViewTransaksjonProps {
    avtale: any;
    namespace?: string;
};

export const ViewTransaksjon = ({ avtale, namespace }: ViewTransaksjonProps): JSX.Element | null => {
    const note: any = { year: null, list: []};
    (((avtale || {}).avtaleinnhold || {}).transaksjoner || []).forEach( (data: any) => {
        const date = new Date( data.kol1 );
        // @ts-ignore
        if ( !(date instanceof Date) || isNaN(date) ) { return; }
        
        const year = date.getFullYear();
        if ( year !== note.year ) {
            note.year = year;
            note.list.push({
                id: `transaksjon-${year}`,
                label: year,
                transactions: [],
            });
        }
        note.list[(note.list.length - 1)].transactions.push({
            id: `${date.getTime()}`,
            date: formatDateToText( date, 'dd.mm'),
            amount: formatAmount( data.kol2, true ),
            description: avtale.firmanavn ? `Innskudd fra ${avtale.firmanavn}` : 'Innskuddpensjon'
        });
    });


    return (note.list || []).length ? <div className={classNames('view-transaksjon-wrapper', namespace)}>
        {/* @ts-ignore */}
        <TransactionList transactions={note.list}  />        
    </div> : null;
};

/** ************************************************************************
 ************************************************************************* */
interface ViewSatserProps {
    list: any;
    text?: string;
    namespace?: string;
};

export const ViewSatser = ({ list, text, namespace }: ViewSatserProps): JSX.Element | null => {
    return (list || []).length ? <div className={classNames('view-satser-wrapper', namespace)}>
        <ul className="view-satser-list flat">
            { list.map( (data: any, i: number) => {
                return <li key={`satser-item-${i}`}>
                    <div className="percent-value">{data.prosent}</div>
                    <div className="date-value">{data.sats}</div>
                </li>
            })}
        </ul>

        { !!text && <div className="description">{text}</div>}
    </div> : null;
};

/** ************************************************************************
 ************************************************************************* */
interface ViewAvtaleDetaljerProps {
    list: any;
    appTexts: any;
    namespace?: string;
};

export const ViewAvtaleDetaljer = ({ list, appTexts, namespace }: ViewAvtaleDetaljerProps): JSX.Element | null => {
    return (list || []).length ? <div className={classNames('view-avtale-detaljer-wrapper', namespace)}>
        { list.map( (data: any, i: number) => {
            return data && (data.avtaleKort || []).length ? <div key={`avtale-detalje-item-${i}`}>
                { data.tittel && <div className="paragraph-small"><h2>{appTexts.get(data.tittel)}</h2></div>}
                <div className="view-avtalekort-list">
                    { data.avtaleKort.map( (info: any, j: number) => {
                        let value = `${info.kol2 || ''}`.replace( ',-', ' kr');
                        if ( value && /^\d{4}-\d{2}-\d{2}/.test(value) ) {
                            const date = new Date( value );
                            // @ts-ignore
                            if ( date instanceof Date && !isNaN(date) ) {
                                value = formatDateToText( date, 'dd. MMm yyyy');
                            }
                        }

                        return <Card key={`avtale-kort-item-${i}-${j}`} type="list-item" data={{text: [[
                            appTexts.get(info.kol1), value
                        ]]}}/>
                    })}
                </div>
            </div> : null;
        })}
    </div> : null;
};