/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NedTrekksMenyKonto from '../ned-trekks-meny-konto';
import Validering from '../validation-functions';

import * as AccountTypes from '../../domain/account-types';
import NumberInput from '../common/number-input';
import ValidationMessages from '../common/validation-messages';

class VelgKonto extends Component {
    static propTypes = {
        id: PropTypes.string,
        tittel: PropTypes.string,
        kontoer: PropTypes.array,
        texts: PropTypes.object,
        onChange: PropTypes.func,
        visHjelpetekst: PropTypes.bool,
        leggTilAnnenKonto: PropTypes.bool,
        children: PropTypes.object,
        disabled: PropTypes.bool,
        defaultValue: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            leggTilAnnenKonto: false,
            kontonummer: props.defaultValue || '',
        };
    }

    settKontonummer = event => {
        const kontonummer = event.target.value;

        if (Validering.onlyDigits(kontonummer)) {
            this.setState({
                kontonummer
            });

            if (Validering.gyldigKontonummer(kontonummer)) {
                this.props.onChange({ [this.props.id]: kontonummer });
            } else {
                this.props.onChange({ [this.props.id]: undefined });
            }
        }
    };

    velgKonto = (kontoNavn, kontonummer) => {
        if (kontonummer === AccountTypes.EGENDEFINERT) {
            this.velgEgendefinertKonto();
        } else {
            this.velgForhaandsdefinertKonto(kontonummer);
        }
    };

    velgEgendefinertKonto = () => {
        const kontoValg = {
            [this.props.id]: undefined,
            bankkontoType: AccountTypes.EGENDEFINERT
        };

        this.setState({
            leggTilAnnenKonto: true,
            kontonummer: ''
        });

        this.props.onChange(kontoValg);
    };

    velgForhaandsdefinertKonto = kontonummer => {
        const konto = this.props.kontoer.find(konto => konto.kontonummer === kontonummer);
        const kontoValg = {
            [this.props.id]: konto.kontonummer,
            portefoljeId: konto.portefoljeId,
            bankkontoType: konto.bankkontoType
        };

        this.setState({
            kontonummer,
            leggTilAnnenKonto: false
        });

        this.props.onChange(kontoValg);
    };

    leggTilAnnenKontoValg = kontoer => {
        const kontoValg = kontoer.slice();

        kontoValg.unshift({
            type: AccountTypes.EGENDEFINERT,
            navn: this.props.texts.get('leggTilAnnenKonto')
        });

        return kontoValg;
    };

    leggTilEgenKonto = () =>
        this.state.leggTilAnnenKonto ? (
            <div className="legg-til-konto">
                <NumberInput
                    id="skrivInnKontonummer"
                    label={this.props.texts.get('skrivInnKontonummer')}
                    value={this.state.kontonummer}
                    onChange={this.settKontonummer}
                />
                <ValidationMessages
                    showValidationMessages={this.props.visHjelpetekst}
                    validationMessages={[
                        {
                            condition: !Validering.gyldigKontonummer(this.state.kontonummer),
                            message: this.props.texts.get('skrivInnEttGyldigKontoNummer')
                        }
                    ]}
                />
            </div>
        ) : null;

    render = () => (
        <div className="velg-konto">
            <label>
                <div className="label-text">{this.props.tittel}</div>
                <NedTrekksMenyKonto
                    texts={this.props.texts}
                    kontoer={
                        this.props.leggTilAnnenKonto
                            ? this.leggTilAnnenKontoValg(this.props.kontoer)
                            : this.props.kontoer
                    }
                    onChange={this.velgKonto}
                    disabled={this.props.disabled}
                    defaultValue={this.props.defaultValue}
                />
                <ValidationMessages
                    showValidationMessages={this.props.visHjelpetekst}
                    validationMessages={[
                        {
                            condition: this.state.kontonummer === '' && !this.state.leggTilAnnenKonto,
                            message: this.props.texts.get('duMaaVelgeKonto')
                        }
                    ]}
                />
            </label>
            {this.props.children}
            {this.leggTilEgenKonto()}
        </div>
    );
}

export default VelgKonto;
