import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { SearchInput } from '@eika/input';
import { IconButton, LinkButton, StandardButton } from '@eika/button';
import { IconTypes } from '@eika/icon';
import { Modal, ModalSize, SubModal } from '@eika/modal';
import { ButtonToggles, RadioButtonPanels } from '@eika/radio-button';
import { CheckboxPanels } from '@eika/checkbox';
import Card from '../Card/Card';
import FlexTextHolder from '../FlexTextHolder/FlexTextHolder';
import Slider from '../Slider/Slider';
import {
    createRegexp,
    formatAmount,
    generateId,
    getClosestParent,
    isIOS,
    scrollBodyTop,
    sortList,
} from '../util/Functions';
import { getFilterConfig, getFundTextList, getMinAmount, getRiskKey } from './util/Functions';

import './FundOverview.scss';
import FundList from '../FundList/FundList';

interface Props {
    appTexts: any;
    displayRightArrow?: boolean;
    displayToggleArrow?: boolean;
    suggestionUrl?: string;
    root?: boolean;
    title?: string;
    description?: string;
    useFundText?: string;
    // eslint-disable-next-line
    searchText?: string;
    // eslint-disable-next-line
    useSelectedCallback?: (s: any) => boolean | void;
    // eslint-disable-next-line
    selectCallback?: (s: any) => boolean | void;
    // eslint-disable-next-line
    fundList?: any;
    // eslint-disable-next-line
    monthly?: boolean;
    onetime?: boolean;
    filter?: any;
    fundDetailUrlPath?: string;
}

interface State {
    id: string;
    timer: any;
    fundList: any;
    eikaFirst: any;
    result: any;
    searchedMessage: string;
    openFilter: number;
    selected?: any;
    filter: any;
    information: any;
    arrow: string;
}

const getFilterNotification = (filter: any): string[] => {
    const list: any = [];
    if (!filter) {
        return list;
    }

    if (filter.sort && filter.sort.current !== '') {
        list.push(`Sorter ${formatAmount(filter.sort.current)}`);
    }

    if (
        filter.amount &&
        filter.amount.current &&
        filter.amount.current.join('-') !== filter.amount.interval.join('-')
    ) {
        list.push(`Minstebeløp ${formatAmount(filter.amount.current)} kr`);
    }

    if (filter.risk && filter.risk.current !== 'all') {
        const opt = filter.risk.options.find((d: any) => d.value === filter.risk.current);
        if (opt) {
            list.push(`Risiko ${opt.label.toLowerCase()}`);
        }
    }

    if (filter.askAccount && filter.askAccount.options[0].checked) {
        list.push('Fond som kan plasseres i en aksjesparekonto');
    }

    return list;
};

const getFundMinimumAmount = (props: Props, fund: any): null | number => {
    if (!fund) {
        return null;
    }
    const { monthly, onetime } = props;

    if (monthly) {
        return fund.minimumsBelopPeriodiskKjop;
    } if (onetime) {
        return fund.minimumsBelopEngangsKjop;
    } 
        return fund.minimumsBelopEngangsKjop || fund.minimumsBelopPeriodiskKjop;
    
};

const filterFund = (props: Props, filter: any, fundList = [], eikaFirst = []): any => {
    const note = getFilterNotification(filter);
    const text = filter.searchText || '';
    let result = text || (note || []).length ? fundList : eikaFirst;
    if (!result.length) {
        return result;
    }

    const textReg = text ? createRegexp(text, 0, 1, 1) : null;
    result = result.filter((d: any) => {
        if (textReg && !textReg.test(d.navn)) {
            const alternative = (d.navn || '').match(/(\+|\spluss)/i)
                ? [d.navn.replace(/(\+|\spluss)/i, '+'), d.navn.replace(/(\+|\spluss)/i, 'pluss')]
                : null;

            if (!alternative || !alternative.find((v) => textReg.test(v))) {
                return false;
            }
        }

        const amount = getFundMinimumAmount(props, d);
        if (!amount) {
            return false;
        }

        if (
            filter.amount &&
            filter.amount.current &&
            filter.amount.current.join('-') !== filter.amount.interval.join('-')
        ) {
            if (amount < filter.amount.current[0] || amount > filter.amount.current[1]) {
                return false;
            }
        }

        if (filter.askAccount.options[0].checked && !d.aksjesparekontoKompatibel) {
            return false;
        }

        return !(filter.risk.current !== 'all' && filter.risk.current !== getRiskKey(d.risiko));
    });

    if (filter.sort.current === 'minAmount') {
        result = sortList(result, 'minAmount', undefined, true);
    } else if (filter.sort.current === 'esg') {
        result = sortList(result, 'eikespirer', true, true);
    }

    const output: any = [[], []];
    result.forEach((d: any) => {
        const index = /^eika/i.test(d.navn) ? 0 : 1;
        output[index].push(d);
    });
    return output.filter((list: any) => list.length > 0);
};

const handleSelectedFund = (props: Props, state: State, setState: (s: State) => void, data: any): void => {
    if (typeof props.useSelectedCallback === 'function') {
        if (props.useSelectedCallback(data) === false) {
            return;
        }
    }
    setState({ ...state, selected: undefined });
};

const setSelected = (props: Props, state: State, setState: (s: State) => void, data: any): void => {
    if (typeof props.selectCallback === 'function') {
        const filter = ['sort', 'amount', 'risk', 'askAccount', 'searchText'].reduce((p: any, key: string) => {
            if (key === 'askAccount') {
                p[key] = !!state.filter[key].options[0].checked;
            } else if (key === 'searchText') {
                p[key] = state.filter[key] || '';
            } else {
                p[key] = state.filter[key].current || undefined;
            }
            return p;
        }, {});
        if (props.selectCallback({ filter, fund: data }) === false) {
            return;
        }
    }

    if ((state.selected || {}).id === data.id) {
        setState({ ...state, selected: undefined });
    } else {
        setState({ ...state, selected: data });
    }
};

const search = (props: Props, state: State, setState: (s: State) => void, e: any): void => {
    const value = e.target.value || '';
    const current = { ...state, filter: { ...state.filter, searchText: value } };
    setState(current);

    clearTimeout(current.timer.current.search || 0);
    current.timer.current.search = setTimeout(() => {
        const result = filterFund(props, current.filter, current.fundList, current.eikaFirst);
        const searchedMessage = result.length ? `Søkt resultat ${result.length}` : 'Ingen treffet fond';
        setState({ ...current, result, searchedMessage });
    }, 300);
};

const changeFilter = (props: Props, state: State, setState: (s: State) => void, e: any, key = '', data?: any): void => {
    if (!state.filter.cloned) {
        state.filter.cloned = JSON.parse(JSON.stringify(state.filter));
    }
    if (key === 'amount') {
        state.filter[key].current = e.current;
    } else if (key === 'sort' || key === 'risk') {
        state.filter[key].current = e;
    } else if (key === 'askAccount' && data) {
        const found = state.filter[key].options.find((d: any) => d.value === data.value);
        if (found) {
            found.checked = !found.checked;
        }
    }
    setState({ ...state });
};

const click = (props: Props, state: State, setState: (s: State) => void, e: any, key = '', data?: any): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if (key === 'fund-selected' && data) {
        setSelected(props, state, setState, data);
    } else if (key === 'use-selected-fund') {
        handleSelectedFund(props, state, setState, data);
    } else if (key === 'close-filter') {
        if (state.openFilter) {
            if (state.filter.cloned) {
                state.filter = state.filter.cloned;
            }
            delete state.filter.cloned;
            setState({ ...state, openFilter: 0 });
        }
    } else if (key === 'open-filter') {
        const note = getFilterNotification(state.filter);
        setState({ ...state, openFilter: note.length ? 2 : 1 });
    } else if (key === 'open-information' && data) {
        setState({ ...state, information: data });
    } else if (key === 'close-information') {
        setState({ ...state, information: null });
    } else if (key === 'reset-filter' || key === 'render-filter') {
        if (key === 'render-filter') {
            delete state.filter.cloned;
        } else {
            state.filter = getFilterConfig();
        }
        const result = filterFund(props, state.filter, state.fundList, state.eikaFirst);
        const searchedMessage = result.length ? `Søkt resultat ${result.length}` : 'Ingen treffet fond';
        setState({ ...state, result, searchedMessage, openFilter: 0 });
    }
};

const initFilter = (props: Props): any => {
    const config = getFilterConfig();
    config.searchText = props.searchText || '';

    if (props.filter) {
        for (const key in config) {
            if (!props.filter[key]) {
                continue;
            }

            if (key === 'askAccount') {
                config[key].options[0].checked = !!props.filter[key];
            } else if (key === 'searchText') {
                config[key] = props.filter[key];
            } else {
                config[key].current = props.filter[key];
            }
        }
    }

    return config;
};

const initState = (props: Props): any => {
    const filter = initFilter(props);
    const eikaReg = createRegexp('eika', 0, 1, 2);
    const fundList = (props.fundList || [])
        .map((d: any) => {
            return d && d.id && d.navn && !!getFundMinimumAmount(props, d)
                ? {
                      ...d,
                      isEikaFund: eikaReg.test(d.navn),
                      textList: getFundTextList(d, props.monthly),
                      minAmount: getMinAmount(d, props.monthly),
                  }
                : null;
        })
        .filter((d: any) => !!d);

    const eikaFirst = JSON.parse(JSON.stringify(fundList)).sort((a: any, b: any): number => {
        const x = a.isEikaFund ? 0 : 1;
        const y = b.isEikaFund ? 0 : 1;
        if (x === y) {
            return 0;
        }
        return x < y ? -1 : 1;
    });
    const result = filterFund(props, filter, fundList, eikaFirst);
    return {
        fundList: sortList(fundList, 'navn'),
        eikaFirst,
        filter,
        result,
    };
};

export default (props: Props): JSX.Element => {
    const { useFundText, suggestionUrl, root, title, description } = props;
    const [state, setState] = useState<State>({
        ...initState(props),
        id: generateId('fund-overview'),
        searchedMessage: '',
        openFilter: 0,
        information: null,
        timer: useRef<any>({}),
        // eslint-disable-next-line
        arrow: props.displayRightArrow ? '-right-arrow' : props.displayToggleArrow ? '-toggle-arrow' : '-normal',
    });
    const { result, selected = {} } = state;
    const filterNotification = state.openFilter ? [] : getFilterNotification(state.filter);

    useEffect(() => {
        scrollBodyTop(0);
    }, []);

    return (
        <section
            id={state.id}
            aria-labelledby={`${state.id}-title`}
            className={classNames('fund-overview-wrapper', {
                '-no-matched-fund': (result || []).length === 0,
                '-root': !!root,
                '-open-filter': !!state.openFilter,
                '-has-filter-notification': filterNotification.length > 0,
            })}
        >
            {!!title && <h1 id={`${state.id}-title`}>{title}</h1>}
            {!!description && <p className="lead">{description}</p>}

            {suggestionUrl && (
                <Card
                    data={{
                        url: suggestionUrl,
                        title: 'Få hjelp til å velge type fond',
                        subTextLineOne: 'Prøv fondsveilederen',
                    }}
                    type="linkPanel"
                />
            )}

            <div className="search-wrapper">
                <div className="search-content">
                    <SearchInput
                        aria-label="Fond søkefelt"
                        id="fund-overview-search-field"
                        value={state.filter.searchText}
                        onChange={(e) => {
                            search(props, state, setState, e);
                        }}
                    />
                    <div className="fund-filter-button">
                        {filterNotification.length > 0 && (
                            <span
                                className="filter-notification"
                                aria-label={`${filterNotification.length} filter er satt`}
                            >
                                {filterNotification.length}
                            </span>
                        )}
                        <LinkButton
                            icon={IconTypes.INNSTILLINGER_24}
                            onClick={() => {
                                click(props, state, setState, null, 'open-filter');
                            }}
                        >
                            Vis filter
                        </LinkButton>
                    </div>
                </div>
                <div className="search-info">
                    {state.searchedMessage && (
                        <div className="aria-visible" aria-live="polite">
                            {state.searchedMessage}
                        </div>
                    )}
                </div>
            </div>

            <FundList
                result={result}
                selected={selected}
                fundDetailUrlPath={props.fundDetailUrlPath}
                useFundText={useFundText}
                click={(data) => setSelected(props, state, setState, data)}
            />

            <Modal
                modalSize={ModalSize.LARGE}
                id="fund-overview-filter"
                appNamespace="sparing-react-app"
                onClose={() => {
                    click(props, state, setState, null, 'close-filter');
                }}
                show={!!state.openFilter}
                headerElements={
                    <>
                        <span>Filtre</span>
                        <span>
                            <IconButton
                                type="button"
                                onClick={() => {
                                    click(props, state, setState, null, 'close-filter');
                                }}
                                icon={IconTypes.KRYSS_24}
                            />
                        </span>
                    </>
                }
                footerClickableElements={
                    <div className={`action-holder ${state.information ? 'hidden' : 'normal'}`}>
                        {state.openFilter === 2 && (
                            <LinkButton
                                icon={IconTypes.KRYSS_24}
                                onClick={() => {
                                    click(props, state, setState, null, 'reset-filter');
                                }}
                            >
                                Tøm filter
                            </LinkButton>
                        )}
                        <StandardButton
                            onClick={() => {
                                click(props, state, setState, null, 'render-filter');
                            }}
                        >
                            Vis resultater
                        </StandardButton>
                    </div>
                }
            >
                <div id={`${state.id}-search-filter-widget`} className="search-filter-widget">
                    <div className="widget-content">
                        <div
                            className={`filter-section -amount -${state.filter.amount.active ? 'active' : 'inactive'}`}
                        >
                            <div className="filter-type-title">
                                <span>{state.filter.amount.label}</span>
                                {!!state.filter.amount.information && (
                                    <IconButton
                                        aria-label="Informasjon om minstebeløp"
                                        icon={IconTypes.INFO_24}
                                        onClick={(e) => {
                                            click(
                                                props,
                                                state,
                                                setState,
                                                e,
                                                'open-information',
                                                state.filter.amount.information
                                            );
                                        }}
                                    />
                                )}
                            </div>
                            <Slider
                                {...state.filter.amount}
                                round={100}
                                _adjustmentButton={[
                                    { step: 100, label: 'Mink 100' },
                                    { step: 100, label: 'Økt 100' },
                                ]}
                                change={(e) => {
                                    changeFilter(props, state, setState, e, 'amount');
                                }}
                                focus={
                                    isIOS()
                                        ? () => {
                                              const node = document.getElementById(`${state.id}-search-filter-widget`);
                                              const parent = getClosestParent(node, 'content');
                                              if (parent && parent.scrollTop) {
                                                  scrollBodyTop(0, 100, parent);
                                              }
                                          }
                                        : undefined
                                }
                            />
                        </div>
                        <div className="filter-section">
                            <div className="filter-type-title">
                                <span>{state.filter.risk.label}</span>
                                {!!state.filter.risk.information && (
                                    <IconButton
                                        aria-label="Informasjon om risiko"
                                        icon={IconTypes.INFO_24}
                                        onClick={(e) => {
                                            click(
                                                props,
                                                state,
                                                setState,
                                                e,
                                                'open-information',
                                                state.filter.risk.information
                                            );
                                        }}
                                    />
                                )}
                            </div>
                            <ButtonToggles
                                {...state.filter.risk}
                                selectedOption={state.filter.risk.current}
                                onChange={(e) => {
                                    changeFilter(props, state, setState, e, 'risk');
                                }}
                            />
                        </div>
                        <div className="filter-section">
                            <div className="filter-type-title">
                                <span>{state.filter.askAccount.label}</span>
                                {!!state.filter.askAccount.information && (
                                    <IconButton
                                        aria-label="Informasjon om aksjesparekonto"
                                        icon={IconTypes.INFO_24}
                                        onClick={(e) => {
                                            click(
                                                props,
                                                state,
                                                setState,
                                                e,
                                                'open-information',
                                                state.filter.askAccount.information
                                            );
                                        }}
                                    />
                                )}
                            </div>
                            <CheckboxPanels
                                {...state.filter.askAccount}
                                options={state.filter.askAccount.options.map((d: any) => {
                                    return {
                                        ...d,
                                        onChange: () => {
                                            changeFilter(props, state, setState, null, 'askAccount', d);
                                        },
                                    };
                                })}
                            />
                        </div>
                        <div className="filter-section">
                            <div className="filter-type-title">
                                <span>{state.filter.sort.label}</span>
                                {!!state.filter.sort.information && (
                                    <IconButton
                                        aria-label="Informasjon om sortering"
                                        icon={IconTypes.INFO_24}
                                        onClick={(e) => {
                                            click(
                                                props,
                                                state,
                                                setState,
                                                e,
                                                'open-information',
                                                state.filter.sort.information
                                            );
                                        }}
                                    />
                                )}
                            </div>
                            <RadioButtonPanels
                                {...state.filter.sort}
                                selectedOption={state.filter.sort.current}
                                onChange={(e) => {
                                    changeFilter(props, state, setState, e, 'sort');
                                }}
                            />
                        </div>
                    </div>
                </div>

                <SubModal
                    id="fund-overview-filter-submobal"
                    appNamespace="sparing-react-app"
                    onClose={() => {
                        click(props, state, setState, null, 'close-information');
                    }}
                    show={!!state.information}
                >
                    {!!state.information && (
                        <div className="filter-information">
                            {!!state.information.title && <h1>{state.information.title}</h1>}
                            <FlexTextHolder text={state.information.text} />
                        </div>
                    )}
                </SubModal>
            </Modal>
        </section>
    );
};
