import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import FondAnbefaling from '../common/FondAnbefaling';

class IndividuellPensjonsSparingAnbefaling extends Component {
    static propTypes = {
        texts: PropTypes.object.isRequired,
        pensjonsValg: PropTypes.object.isRequired,
        portefoljeId: PropTypes.string.isRequired,
        alder: PropTypes.number.isRequired,
        morningstar: PropTypes.array.isRequired
    };

    render() {
        if (this.props.pensjonsValg && this.props.morningstar) {
            const tidshorisont = this.props.pensjonsValg.ipsUttaksAlder - this.props.alder;

            return (
                <FondAnbefaling
                    header={this.props.texts.get('anbefalteFond')}
                    texts={this.props.texts}
                    risikoprofil={this.props.pensjonsValg.ipsRisikoprofil}
                    tidshorisont={tidshorisont}
                    portefoljeId={this.props.portefoljeId}
                    morningstar={this.props.morningstar}
                />
            );
        }

        return <Loading title={this.props.texts.get('lasterDinIpsProfil')} />;
    }
}

export default IndividuellPensjonsSparingAnbefaling;
