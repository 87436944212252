class SparemaalUtils {
    static fetchSparemaalFromId = (sparemaal, id) => {
        let result;
        return (
            sparemaal.some((s) => {
                result = s;
                return s.id && s.id.toString() === id;
            }) && result
        );
    };
}

export default SparemaalUtils;
