import React from 'react';
import PropTypes from 'prop-types';
import SliderInput from '../common/slider-input';
import Format from '../format-functions';
import Card from '../card';

const FondsKortSlider = props =>
    props.totalverdi ? (
        <div>
            <SliderInput
                id={props.id}
                min={0}
                max={props.totalverdi}
                step={1}
                value={props.oremerket}
                onChange={props.onChange}
            />
            <div>
                <div className="oremerket">{props.texts.get('sparemaalSparemiksOremerket')}</div>
                <div className="verdi">{Format.currency(props.oremerket)}</div>
            </div>
        </div>
    ) : null;

FondsKortSlider.propTypes = {
    texts: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    totalverdi: PropTypes.number.isRequired,
    oremerket: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

const SparemiksFondsKort = props => (
    <Card>
        <div className="sparemaal-fonds-kort">
            <div className="fonds-navn">{props.fondsnavn}</div>
            <div className="fonds-isin">{props.portefoljenavn}</div>
            <FondsKortSlider
                texts={props.texts}
                onChange={e => props.onChange(parseInt(e.target.value, 10))}
                id={`oremerking-${props.isin}-${props.portefoljeId}`}
                oremerket={props.oremerket}
                totalverdi={props.totalverdi}
            />
            {props.andreSparemaal}
        </div>
    </Card>
);

SparemiksFondsKort.propTypes = {
    texts: PropTypes.object.isRequired,
    fondsnavn: PropTypes.string.isRequired,
    isin: PropTypes.string.isRequired,
    portefoljeId: PropTypes.string.isRequired,
    portefoljenavn: PropTypes.string.isRequired,
    totalverdi: PropTypes.number.isRequired,
    oremerket: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    andreSparemaal: PropTypes.array.isRequired
};

export default SparemiksFondsKort;
