import React from 'react';
import classNames from 'classnames';
import Collapse from 'react-collapse';

const VerticalStep = (props)  => {
    const { title, open, available, done, stepIndex, totalSteps = 100, onHeaderClick, onNextClick, children } = props;

    const listClassNames = classNames('list-group-item', 'card-list-process-item', 'can-expand', {
        open,
        available,
        done
    });

    const onClick = open ? () => {} : () => onHeaderClick(stepIndex);

    function onHeaderKeyUp(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            onHeaderClick(stepIndex);
        }
    }

    function onNextKeyUp(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            onNextClick(stepIndex);
        }
    }

    return (
        <div className={listClassNames} role="tab">
            <div
                id={`step-${stepIndex}`}
                onClick={onClick}
                onKeyUp={onHeaderKeyUp}
                className="list-group-item-heading card-list-process-heading clearfix"
                tabIndex={available ? 0 : -1}
                role="button"
            >
                <i className="progress-counter">{stepIndex + 1}</i>
                <strong className="subheading pull-left">{title}</strong>
            </div>
            <Collapse isOpened={open}>
                <div className="list-group-item-body card-list-process-body"
                    style={ open ? {visibility: 'visible', height: 'auto'} : {}}
                >
                    <div className="card-body">{children}</div>

                    {onNextClick !== undefined && stepIndex !== totalSteps && (
                        <div
                            onClick={() => onNextClick(stepIndex)}
                            onKeyUp={onNextKeyUp}
                            className="progress-next"
                            role="button"
                            tabIndex={0}
                        >
                            <p>Neste</p>
                        </div>
                    )}
                </div>
            </Collapse>
        </div>
    );
};

VerticalStep.displayName = 'VerticalStep';

export default VerticalStep;
