import React, { useEffect, useState, useRef, MouseEvent, KeyboardEvent } from 'react';
import classNames from 'classnames';
import { ErrorLabel } from '@eika/label';
import { Input } from '@eika/input';
import { LinkButton } from '@eika/button';
import { generateId, createRegexp } from '../util/Functions';
import Message from '../Message/Message';
import FlexTextHolder, { FlexText } from '../FlexTextHolder/FlexTextHolder';
import { MultiObject, FormElementChangeEvent } from '../../domain/Types';
import './DropdownMenu.scss';

export interface linkNextToLabel {
    text: string;
    click: (e?: MouseEvent | null) => void;
};

export interface DropdownMenuOption {
    id: string;
    text?: string | string[];
    textList?: string[] | string[][] | FlexText[] | FlexText[][];
    ariaLabel?: string;
    disabled?: boolean;
    tagList?: FlexText[] | FlexText[][];
    category?: string;
    balance?: string;
};

interface Props {
    selected?: string;
    text?: string | string[] | string[][];
    textList?: string[] | string[][] | FlexText[] | FlexText[][];
    type?: string;
    action?: string;
    click?: (e: MouseEvent | null, k: string, d?: any) => void;
    optionList?: DropdownMenuOption[];
    searchField?: string;
    linkNextToLabel?: linkNextToLabel;
    linkAfterButton?: JSX.Element;
    loading?: boolean;
    view?: string;
    hint?: string;
    error?: string;
    label?: string;
    defaultText?: string;
    id?: string;
    disabled?: boolean;
    minRow?: number;
    maxRow?: number;
    message?: {type?: string; text?: string; title?: string, role?: string};
    selectedLead?: string;
    children?: JSX.Element;
};

interface State {
    id: string;
    noneSelected?: any;
    focus?: string;
    indexMatched?: any;
    started?: any;
};

const isNew = ( data: any ): boolean => {
    return /^new-/i.test( (data || {}).category );
};

const scrollIntoView = ( state: State, id='', option={block: 'start', inline: 'nearest'} ): void => {
    const optBtn = id ? document.getElementById(`opt-btn-${id}`) : null;
    if ( !optBtn ) { return; }

    const content = document.getElementById(`${state.id}-popup-content`);
    if ( content && content.clientWidth ) {
        const offset = optBtn.offsetTop;
        const height = optBtn.clientHeight;
        content.scrollTop = offset + height - content.clientHeight;
    } else {
        // @ts-ignore
        optBtn.scrollIntoView(option);
    }
}

const changeSelect = (props: Props, state: State, setState: (s: State) => void, e: FormElementChangeEvent | null, id='', force=false): void => {
    if ( !id && e ) { id = e.target.value; }
    if ( !id ) { return; }

    const { click, action, optionList=[] } = props;
    const data = optionList.find( (data: DropdownMenuOption) => data.id === id );
    if ( ! data ) { return; }

    if ( !force && isNew(data) ) {
        setState({...state, focus: id});
        scrollIntoView(state, id);
    } else if ( click ) {
        scrollIntoView( state, data.id );
        click( null, (action || ''), data );
        if ( state.focus ) {
            setState({...state, focus: ''});
        }
    }
};

const blurSelect = (props: Props, state: State, setState: (s: State) => void): void => {
    if ( state.focus ) {
        changeSelect(props, state, setState, null, state.focus, true);
    }
};

const keyUpSelect = (props: Props, state: State, setState: (s: State) => void, e: KeyboardEvent): void => {
    if ( (e || {}).keyCode === 13 && state.focus ) {
        changeSelect(props, state, setState, null, state.focus, true);
    }
};

const blur = (state: State, setState: (s: State) => void): void => {
    setState({...state, focus: undefined});
};

const keyDown = ( e: KeyboardEvent ): void => {
    const code = (e || {}).keyCode;
    if ( code === 13 || code === 38 || code === 40 ) {
        e.preventDefault();
    }
};

const keyUp = (props: Props, state: State, setState: (s: State) => void, e: KeyboardEvent): void => {
    const code = (e || {}).keyCode;
    if ( code === 13 && state.focus ) {
        changeSelect(props, state, setState, null, state.focus);
    } else if ( code === 38 || code === 40) {
        e.preventDefault();
        const wrapper = document.getElementById(state.id);
        if ( !wrapper ) { return; }

        const buttonList = wrapper.querySelectorAll('.dropdown-menu-option-btn') || [];
        const length = buttonList.length;
        if ( length > 0 ) {
            let index = -1;
            for ( let i=0; i<length; i++ ) {
                if (buttonList[i] &&  /(^|\s)?-focus/i.test( buttonList[i].getAttribute('class') ?? '') ) {
                    index = i;
                    i = length;
                }   
            }

            /* eslint-disable */
            let focus = index === -1 ?  (code === 38 ? (length - 1) : 0) : (index + (code === 38 ? -1 : 1)); 
            /* eslint-enable */
            if ( focus < 0 ) {
                focus =  length - 1;
            } else if ( focus >= length ) {
                focus = 0;
            }

            const id = buttonList[focus] ? (buttonList[focus].getAttribute('id') ?? '').replace('opt-btn-', '') : '';
            setState({...state, focus: id});
            scrollIntoView(state, id);
        }
    }
};

const getText = ( data: any ): string => {
    return (data instanceof Array ? data : [data]).map( (src: any) => {
        if ( src instanceof Array ) { return getText(src); }
        return typeof(src) === 'string' ? src : src.text;
    }).join(' ');
};

const search = (props: Props, state: State, setState: (s: State) => void, e: React.ChangeEvent<HTMLInputElement> ): void => {
    const value = e.target.value.trim();
    if (!value) { return setState({...state, indexMatched: undefined}); }

    const reg = createRegexp(value, 1, 1, 1);
    const indexMatched: MultiObject = {};
    (props.optionList || []).forEach( (data: DropdownMenuOption, i: number) => {
        const text = getText( (data.textList || data.text) );
        if ( reg.test(text) ) { indexMatched[i] = 1; }
    });

    setState({...state, indexMatched });
} 

export default (props: Props): JSX.Element | null => {
    const [state, setState] = useState<State>({
        id: props.id || generateId('dd-menu'),
        noneSelected: {text: props.defaultText || 'Velg en'},
        started: useRef<boolean>(false),
    });

    const { 
        children, type='basic', label, disabled, selectedLead, text, error, optionList, selected, 
        searchField, linkNextToLabel, linkAfterButton, hint, view, message, minRow, maxRow,
    } = props;
    const { id, noneSelected, indexMatched, focus } = state;
    const selectedOpt = selected ? ((optionList || []).find((d: DropdownMenuOption) => d.id === selected) || noneSelected) : noneSelected;
    const style = classNames(`dropdown-menu-wrapper -${type}`, view, {
        '-has-link-next-to-label': linkNextToLabel,
        '-has-search-field': !!searchField,
        '-has-error': !!error,
        '-disabled': !!disabled
    });

    useEffect(() => {
        if ( selected && !state.started.current ) {
            state.started.current = true;
            scrollIntoView(state, selected);
        }
    }, [selected, state]);

    return (optionList || []).length ? <div className={style} id={state.id}>
        <div className="dropdown-menu-label-wrapper">
            { !!label && <label id={`${id}-label`} htmlFor={id} className="field-label">{label}</label> } 

            { !!linkNextToLabel && <div className="dropdown-menu-link-next-to-label">
                { (linkNextToLabel instanceof Array ? linkNextToLabel : [linkNextToLabel]).map( (link, i) =>(
                    <LinkButton key={`link-label-${i}`} id={link.id} onClick={link.click}>{link.text}</LinkButton>
                ))}
            </div> }
        </div>

        <div className="dropdown-menu-content">
            <select id={id} value={selected} aria-invalid={!!error}  className="dropdown-menu-select" size={2}
                onChange={(e)=>{ changeSelect(props, state, setState, e); }} 
                onBlur={()=>{ blurSelect(props, state, setState); }}
                onKeyUp={(e)=>{ keyUpSelect(props, state, setState, e); }}
            >
                { JSON.stringify(noneSelected) === JSON.stringify(selectedOpt) && <option>{noneSelected.text}</option> }

                { (optionList || []).map((data: DropdownMenuOption) => {
                    const attr: MultiObject = {value: data.id};

                    const textList = data.textList || data.text;
                    if ( data.disabled ) { attr.disabled = true; }
                    return <option id={`option-${data.id}`} key={`select-option-${data.id}`} {...attr}>
                        { /* @ts-ignore */ }
                        { (textList instanceof Array ? textList : [ (textList || {}).text || textList]).map( (text) => {
                            return (text instanceof Array ? text : [(text || {}).text ||text]).map( (t) => {
                                return t instanceof Array ? '' : (t || {}).text || t;
                            }).join(' - ');
                        }).join(' - ')}
                    </option>;
                })}
            </select>

            { !!searchField && <Input
                    aria-label="Søk"
                    className="dropdown-menu-search-field"
                    placeholder="Søk"
                    onChange={(e)=>{search(props, state, setState, e);}} 
                    onKeyUp={(e)=>{keyUp(props, state, setState, e);}} 
                    onKeyDown={(e)=>{keyDown(e);}} 
                    onBlur={()=>{blur(state, setState);}} 
            />}

            <div id={id} aria-haspopup="listbox" className={`dropdown-menu-option-selected ${(selectedOpt.category || '').toLowerCase()}`}>
                <div className="dropdown-menu-option-selected-content">
                    { !!selectedLead && <div className="dropdown-menu-option-selected-lead">{selectedLead}</div> }
                    { (text || children) ? (text || children) : <FlexTextHolder maxRow={maxRow} minRow={minRow}
                        text={selectedOpt.textList || selectedOpt.text}
                    /> }
                </div>
            </div>

            <div className="dropdown-menu-popup">
                <div className="dropdown-menu-popup-content" id={`${state.id}-popup-content`}>
                    <ul tabIndex={-1} role="listbox" aria-labelledby={`${id}-label`} className="dropdown-menu-list">
                        { (optionList || []).map((data: DropdownMenuOption, i: number) => {
                            const attr = data.ariaLabel ? {'aria-label': data.ariaLabel} : {};
                            return !indexMatched || indexMatched[i] ? <li key={`dropdown-option-${data.id}`}>
                                <a id={`opt-btn-${data.id}`} href="#" role="button" tabIndex={-1} {...attr}
                                    onClick={(e)=>{ e.preventDefault(); }} 
                                    onMouseDown={()=>{ changeSelect(props, state, setState, null, data.id); }} 
                                    className={classNames('dropdown-menu-option-btn', (data.category || '').toLowerCase(), {
                                        '-disabled': !!data.disabled,
                                        '-selected': data.id === selected,
                                        '-has-balance': data.balance !== undefined,
                                        '-focus': data.id === focus,
                                        '-new': isNew( data ),
                                    })}                                  
                                > 
                                    { /* @ts-ignore */ }
                                    <FlexTextHolder text={view === 'draw-day' ? [data.id] : (data.tagList || []).concat((data.textList || data.text))} />
                                </a>
                            </li> : null;
                        }) }                    
                    </ul>

                    { !!message && <Message {...message} type={message.type || 'InfoMessage'}/>  }
                </div>
            </div>
        </div>

        { !!hint && <div className="dropdown-menu-hint information-text">{hint}</div> }

        { !!linkAfterButton && <div className="dropdown-menu-link-afte-button">
            { (linkAfterButton instanceof Array ? linkAfterButton : [linkAfterButton]).map( (link, i) =>(
                <LinkButton key={`link-after-${i}`} id={link.id} onClick={link.click}>{link.text}</LinkButton>
            ))}
        </div> }

        { !!error && <ErrorLabel aria-live="polite" text={error} />}            
    </div> : null;
};

