import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ListPanelWrapper } from '@eika/link-panel';
import { IconTypes } from '@eika/icon';
import { StandardButton } from '@eika/button';
import { Modal } from '@eika/modal';
import { RouteComponentProps } from 'react-router';
import { Label } from '@eika/label';
import { formatAmount, getPageAppending } from '../../../../common/util/Functions';
import { useStorebandInfo } from '../../../../common/util/Hook';
import AppMessage from '../../../../common/AppMessage/AppMessage';
import { HeaderLink, Spinner } from '../../../../common/share/ShareComponents';
import '../../Arbeidsgiver.scss';
import '../Storebrand.scss';
import './SavingsProfiles.scss';
import { State } from '../../../../domain/State';
import safeArray from '../../util/SafeArray';
import '@eika/layout-style';
import Grid from '../../../../common/Grid/Grid';
import ListPanelWithContent from '../../../../common/ExtendedStyleguide/ListPanelWithContent';
import { StorebrandContractFund } from '../../../../domain/Storebrand';
import RiskLevel from './RiskLevel';
import LargeLinkCardWithAction from '../../../../common/ExtendedStyleguide/LargeLinkCardWithAction';
import { RouterConfig } from '../../../../app/Router';

type SavingProfilePageProps = RouteComponentProps<{ id?: string }> & RouterConfig;

const SavingProfilePage: FC<SavingProfilePageProps> = (props) => {
    const storebrandState = useSelector((state: State) => state?.Storebrand);
    const appState = useSelector((state: State) => state?.App);

    useStorebandInfo({ Storebrand: storebrandState }, props.action);

    const appending = getPageAppending(props);
    const [loading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const agreementInfo = useMemo(() => storebrandState?.info?.[props.match?.params.id || ''], [storebrandState]);

    const changeProfile = () => {
        // check if user can change spareprofil
        window.location.href = `${location.href}/switch`;
    };

    const funds = useMemo(() => {
        return safeArray(agreementInfo?.funds);
    }, [agreementInfo]);

    return (
        <div className={`saving-profile-page ${appending || 'none-appending'}`}>
            {funds.length === 1 && <Redirect to={`spareprofile/${funds[0].isin}`} />}

            <Grid
                namespace="sparing-top"
                list={[
                    {
                        layout: 'twelve',
                        element: <HeaderLink length={1} text="Eika Innskuddspensjon" />,
                    },
                ]}
            />

            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>Spareprofiler</h1>,
                    },
                ]}
            />

            <Grid
                list={[
                    {
                        layout: 'seven',
                        element: (
                            <>
                                <AppMessage appMessage={appState?.serviceMessage} context="STOREBRAND_RISK_PROFILE" />
                                {!agreementInfo?.riskReductionInProgress && (
                                    <LargeLinkCardWithAction
                                        background="light"
                                        buttonText="Om nedtrapping"
                                        title="Nedtrappingen er igang"
                                        icon={IconTypes.KLOKKE_36}
                                        text="Vi gjør klart for uttak av pensjon."
                                        buttonType="negative"
                                        action={() => setModalOpen(true)}
                                    />
                                )}
                                {funds && !!funds.length && (
                                    <ListPanelWrapper style={{ marginTop: 24 }}>
                                        {funds.map(
                                            (fund: StorebrandContractFund) =>
                                                fund && (
                                                    <ListPanelWithContent
                                                        key={fund.isin}
                                                        url={`${location.href}/${fund.isin}`}
                                                        title={fund.name}
                                                        textOne={formatAmount(fund.marketValue)}
                                                        labels={
                                                            fund.isin
                                                                ? [<Label text="Profilen det trappes ned til" />]
                                                                : undefined
                                                        }
                                                    >
                                                        <div className="savings-profile-info-box">
                                                            <div className="-item">
                                                                <div>{`${formatAmount(fund.totalReturnPct)} %`}</div>
                                                                <div className="-label">Avkastning</div>
                                                            </div>
                                                            <div className="-item">
                                                                <RiskLevel level={4} />
                                                                <div className="-label">Data from backend</div>
                                                            </div>
                                                        </div>
                                                    </ListPanelWithContent>
                                                )
                                        )}
                                    </ListPanelWrapper>
                                )}

                                <div className="change-profile-button-wrapper">
                                    <StandardButton
                                        icon={IconTypes.PENN_24}
                                        size="large"
                                        buttonType="negative"
                                        onClick={changeProfile}
                                    >
                                        {props.appTexts.get('changeProfile')}
                                    </StandardButton>
                                </div>

                                {loading && <Spinner type="linkPanel" />}
                            </>
                        ),
                    },
                    {
                        layout: 'four',
                        element: null,
                    },
                ]}
            />
            <Modal onClose={() => setModalOpen(false)} show={modalOpen}>
                <>
                    <h2 className="h2 paragraph">Om nedtrapping</h2>
                    <p>
                        Når nedtrappingen er igang, betyr det at risikoen på investeringene dine gradvis trappes ned til
                        en lavere risikoprofil.
                    </p>
                    <p>
                        Nedtrappingen pågår i noen år før du skal bruke pengene, slik at du ikke risikerer å ta ut
                        pengene på feil tidspunkt.
                    </p>
                </>
            </Modal>
        </div>
    );
};

export default SavingProfilePage;
