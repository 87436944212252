import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import DonutChart from '../../../components/charts/donut-chart';
import Legend from '../../../components/legends/dot-legend';
import ColorFunctions from '../../../components/color-functions';
import { settSideTittel } from '../../../actions/header';
import Feilmelding from '../../../components/feilmelding';
import Format from '../../../components/format-functions';
import HelperFunctions from '../../../components/helper-functions';
import LinkCard from '../../../components/link-card';
import UtlistningsKort from '../../../components/danica/utlistnings-kort';
import DateFormatting from '../../../components/date-functions';
import AppMessage from '../../../components/AppMessage';

import { lastarbeidsgiversPensjonssparing, lastPrognoseForAvtale } from '../../../actions/pensjon';

class AvtaleOversikt extends Component {
    static propTypes = {
        match: PropTypes.object,
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        arbeidsgiversPensjonsSparing: PropTypes.array,
        lastPrognoseForAvtale: PropTypes.func,
        lastarbeidsgiversPensjonssparing: PropTypes.func,
        errors: PropTypes.object,
        prognoser: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        if (!this.props.arbeidsgiversPensjonsSparing) {
            this.props.lastarbeidsgiversPensjonssparing();
        }
        const { id } = this.props.match.params;

        this.finnAvtale(this.props.arbeidsgiversPensjonsSparing, id);
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        const { id } = newProps.match.params;

        if (!this.state.avtale) {
            this.finnAvtale(newProps.arbeidsgiversPensjonsSparing, id);
        }
    };

    finnAvtale = (arbeidsgiversPensjonsSparing, id) => {
        if (arbeidsgiversPensjonsSparing && id) {
            const avtale = HelperFunctions.finnAvtale(arbeidsgiversPensjonsSparing, id);

            this.setState({
                avtale,
            });

            this.props.lastPrognoseForAvtale(avtale.avtalenummer, avtale.polisenummer);
            this.props.settSideTittel(avtale.produktbetegnelse, avtale.firmanavn);
        }
    };

    createChartData = (avtale) => {
        return [
            {
                text: this.props.texts.get('innskudd'),
                value: avtale.avtaleinnhold.innskudd,
                color: ColorFunctions.hentFarge(0),
                active: true,
            },
            {
                text: this.props.texts.get('gevinst'),
                value: avtale.avtaleinnhold.gevinst,
                color: ColorFunctions.hentFarge(1),
                active: true,
            },
        ];
    };

    visTransaksjoner = (transaksjoner) => {
        const fullWidth = true;

        const format = (text) => {
            const parsedtext = HelperFunctions.tryMapText(text, this.props.texts); // tries find in texts
            return DateFormatting.skrivUtDatoDDMMMMYYYY(parsedtext, this.props.texts); // tries map to date format
        };

        if (transaksjoner) {
            return (
                <div className="utlistningskort">
                    <div className="kort-tittel">{this.props.texts.get('sisteInnskudd')}</div>
                    <UtlistningsKort
                        liste={transaksjoner}
                        fullWidth={fullWidth}
                        format={format}
                        texts={this.props.texts}
                    />
                </div>
            );
        }

        return null;
    };

    visValgtRisiko = (prognoser, texts) => {
        if (prognoser) {
            const keys = Object.keys(prognoser.values);

            let aktivRisiko = '';
            return keys.some((key) => {
                aktivRisiko = texts.get(key);
                return prognoser.values[key].aktiv;
            })
                ? aktivRisiko
                : texts.get('aapenProfil');
        }

        return null;
    };

    sjekkForServerFeil = (errors) => {
        return errors.LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA;
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.avtale) {
            const chartData = this.createChartData(this.state.avtale);

            return (
                <div className="danica-agreement-wrapper">
                    <AppMessage appMessage={this.props.serviceMessage} context="DANICA" />

                    <DonutChart data={chartData}>
                        <div className="donut-labels">
                            <div className="label-section">
                                <span className="ikon-palme-24" />
                                <div className="text">{this.props.texts.get('verdi')}</div>
                                <div className="value">
                                    {Format.currency(this.state.avtale.avtaleinnhold.totalVerdi)}
                                </div>
                            </div>
                        </div>
                    </DonutChart>
                    <Legend data={chartData} />
                    <LinkCard link={`arbeidsgiversSparing/avtale/${this.state.avtale.id}/risiko`}>
                        <div>
                            {this.props.texts.get('dinValgteRisikoProfil')}
                            <br />
                            {this.visValgtRisiko(this.props.prognoser, this.props.texts)}
                        </div>
                    </LinkCard>
                    <LinkCard link={`arbeidsgiversSparing/avtale/${this.state.avtale.id}/detaljer`}>
                        <div>
                            {this.props.texts.get('omPensjonsavtalenDin')}
                            <br />
                            {this.state.avtale.produktbetegnelse}
                        </div>
                    </LinkCard>
                    {this.visTransaksjoner(this.state.avtale.avtaleinnhold.transaksjoner)}
                </div>
            );
        }
        return <Loading title={this.props.texts.get('lasterArbeidsgiversPensjonsSparing')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Pensjon,
            ...state.Feilmeldinger,
            ...state.Texts,
            ...state.Texts,
            ...state.Header,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel, undertittel) => {
                dispatch(settSideTittel(sideTittel, undertittel));
            },
            lastPrognoseForAvtale: (avtalenummer, polisenummer) => {
                dispatch(lastPrognoseForAvtale(avtalenummer, polisenummer));
            },
            lastarbeidsgiversPensjonssparing: () => {
                dispatch(lastarbeidsgiversPensjonssparing());
            },
        };
    }
)(AvtaleOversikt);
