// @ts-nocheck // kiet fix it later
import React from 'react';
import classNames from 'classnames';
import './TabList.scss';

/* eslint-disable */
interface Props {
    list: any;
    current: string;
    click: (e: any, k: string, d?: any) => void;
    action: string;
    small?: boolean;
};
/* eslint-enable */

export default ({ list = [], click = () => {}, current = '', action = 'change-tab', small=false }: Props): JSX.Element => {
    return (
        <ul className={classNames('tab-list-wrapper', `-count-${list.length}`, { '-small': !!small})} role="tablist">
            {list.map((data: any) => {
                const attr = {
                    id: `tab-${data.id}`,
                    'aria-selected': data.id === current,
                    'aria-controls': data.control || `panel-${data.id}`,
                };

                return <li key={`tab-item-${data.id}`} role="presentation">
                    <a href="#" role="tab" {...attr}
                        className={classNames('tab-btn', {'-active': data.id === current})}
                        onClick={e => {click(e, action, data);}}
                    >{data.name}</a>
                </li>
            })}
        </ul>
    );
};

