/**
 * Created by vagrant on 22.02.17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import NedTrekksMeny from '../ned-trekks-meny';

const DateDropdown = props => (
    <label className="trekk-dato">
        <div className="label-text">{props.label}</div>
        <NedTrekksMeny
            id={props.id}
            options={props.options}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            disabled={props.disabled}
        />
    </label>
);

DateDropdown.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool
};

export default DateDropdown;
