
const mod11OfNumberWithControlDigit = (text=''): number => {
    let controlNumber = 2;
    let sumForMod = 0;
    let i;
    for (i = text.length - 2; i >= 0; --i) {
        // @ts-ignore
        sumForMod += text.charAt(i) * controlNumber;
        if (++controlNumber > 7) {
            controlNumber = 2;
        }
    }
    const result = 11 - (sumForMod % 11);
    return result === 11 ? 0 : result;
};

/** ****************************************************************************
  Number validation
***************************************************************************** */
export const validateNumber = (value?: string | number): boolean => {
    if ( !/string|number/.test(typeof(value)) ) { return false; }
    const text = `${value}`.replace(/\s+/g, '');
    return /^(-)?[0-9,.]+$/.test(text);
};

/** ****************************************************************************
  Amount validation
***************************************************************************** */
export const validateAmount = (value?: string | number): boolean => {
    if ( !/string|number/.test(typeof(value)) ) { return false; }
    const text = `${value}`.replace(/\s+/g, '');
    return text === '0' || /^[1-9]([0-9]+)?$/.test(text);
};

/** ****************************************************************************
  bank account validation
***************************************************************************** */
export const validateBankAccount = (value?: string): boolean => {
    const text = (value || '').replace(/[\s-]+/g, '');
    if (!validateNumber(text)) {
        return false;
    }

    return text.length === 11 && parseInt(text.charAt(text.length - 1), 10) === mod11OfNumberWithControlDigit(text);
};

/** ****************************************************************************
  Email validation
***************************************************************************** */
export const validateEmail = (value?: string): boolean => {
    const text = `${value || ''}`.replace(/\s+/g, '');
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(text);
};

/** ****************************************************************************
  Mobile validation
***************************************************************************** */
export const separatePhoneCountryCode = (text?: string): string[] => {
    if (!text) { text = ''; }
    const reg = /^(\+|00)/;
    const out: string[] = ['', text];
    if ( reg.test(text) ) {
        out[0] = ((text || '').match( reg ) || [])[1] || '';
        out[1] = out[1].replace( reg, '' );
        const splited = out[1].split('');
        if (splited.length > 2) {
            out[0] += `${(splited.shift() ?? '') + (splited.shift() ?? '')} `;
            out[1] = splited.join('');
        }
    }
    return out;
};

export const validatePhone = (value?: string): boolean => {
    const text = `${value || ''}`.replace(/\s+/g, '');
    // const option = { no: [8], sv: [9, 11], da: [8] };
    let interval = [8]; // option[country] || option.no;

    const separated = separatePhoneCountryCode(text);
    if (separated[0] === '+46 ') {
        interval = [9, 11];
    }

    const length = separated[1].replace(/\s+/g, '').length;
    return interval[1] ? (interval[0] <= length && interval[1] > length) : interval[0] === length;
};

/** ****************************************************************************
***************************************************************************** */
export const validateLessThanOrLike = (value?: string | number, max?: string | number): boolean => {
    if (!validateNumber(value) || !validateNumber(max)) { return false; }

    value = parseFloat((`${value || ''}`).replace(/\s+/g, ''));
    max = parseFloat((`${max || ''}`).replace(/\s+/g, ''));
    return value <= max;
};

/** ****************************************************************************
***************************************************************************** */
export const validateGreatThanOrLike = (value?: string | number, min?: string | number): boolean => {
    if (!validateNumber(value) || !validateNumber(min)) { return false; }

    value = parseFloat((`${value || ''}`).replace(/\s+/g, ''));
    min = parseFloat((`${min || ''}`).replace(/\s+/g, ''));
    return value >= min;
};

/** ****************************************************************************
 ***************************************************************************** */
export const validate = (type='', value?: string | number): boolean => {
    let valid = true;
    value = `${value || ''}`.trim();
    if ( /^required$/i.test(type) ) {
        valid = !!value;
    } else if ( /^(mobile|phone)$/i.test(type) ) {
        valid = validatePhone(value);
    } else if ( /^(bankAccount|account)$/i.test(type) ) {
        valid = validateBankAccount( value );
    } else if ( /^(email|epost)$/i.test(type) ) {
        valid = validateEmail(value);
    } 

    return valid;
};
