import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InfoBoks extends Component {
    static propTypes = {
        infoTekst: PropTypes.string,
        icon: PropTypes.string,
        info: PropTypes.bool,
    };

    render() {
        const icon = `${this.props.info ? 'ikon-info-24' : 'ikon-lyspaere-24'} infoboks-icon`;
        return (
            <div className="infoboks box">
                <div className="infoboks-icon-wrapper">
                    <span className={this.props.icon ?? icon} />
                </div>
                <div className="infoboks-text">
                    <p>{this.props.infoTekst}</p>
                </div>
            </div>
        );
    }
}

export default InfoBoks;
