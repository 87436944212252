import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { LinkButton } from '@eika/button';
import { Checkbox } from '@eika/checkbox';
import Slider from '../../common/Slider/Slider';
import Grid from '../../common/Grid/Grid';
import Message from '../../common/Message/Message';
import AmountField from '../../common/AmountField/AmountField';
import { HeaderLink, Spinner } from '../../common/share/ShareComponents';
import { StringObject, MultiObject } from '../../domain/Types';
import './KalkulatorLayout.scss';

export interface InputValueChangeEvent {
    currentTarget: {value: string; name: string; };
};

interface Props {
    id: string;
    namespace: string;
    title: string;
    appending: string;
    result: JSX.Element;
    loading: boolean;
    inputList: any;
    error: StringObject;
    value: StringObject;
    click: (e: MouseEvent, k: string, d?: any) => void;
    changeInputValue: (e: InputValueChangeEvent) => void;
    changeCheckbox: (e: boolean, k:string) => void;
    changeSlider?: (e: any, input: any) => void; // Temporary not in use at all, because of new design 
    tool?: JSX.Element;
    children?: JSX.Element | JSX.Element[] | boolean;
    message?: string;
    appMessage?: JSX.Element | null;
    noteMessage?: any[];
    noTopBack?: boolean;
};

export default (props: Props): JSX.Element => {
    return (
        <div className={classNames('kalkulator-wrapper', props.namespace)}>
            { !props.noTopBack && <Grid namespace="sparing-top" list={[{
                layout: 'twelve',
                element: <HeaderLink text="Forrige side" />
            }]}/> }

            { !!props.title && <Grid namespace="sparing-header" list={[{
                layout: 'twelve',
                element: <h1>{props.title}</h1>
            }]}/> }
            
            <Grid namespace="sparing-content" list={[{
                layout: 'twelve',
                element: <div className={`kalkulator-content ${props.appending}`}>
                    { props.appMessage }

                    { (props.noteMessage || []).map( (msg: MultiObject, i:number) => (
                        <div key={`note-message-${i}`} className="paragraph">
                            <Message {...msg} />
                        </div>
                    ))}

                    <div className="view-content">
                        <section className="tool-wrapper" aria-label="Kalkultor verktøy">                            
                            <div className="tool-holder">
                                <ul className="input-wrapper">
                                    { (props.inputList || []).map( (input: any) => {
                                        return <li key={`input-hoolder-${input.name}`} className={classNames(`input-holder -${input.type}`, {
                                            '-has-error': !!props.error[input.name]
                                        })}>
                                            { input.type !== 'checkbox' && <label htmlFor={`input-${input.name}`}>{input.label}</label> }

                                            <div className="relative">
                                                { !!(input.adjustment || [])[0] && <a href="#" role="button"
                                                        title={input.adjustment[0].label} 
                                                        aria-label={input.adjustment[0].label}
                                                        className="adjustment-button -decrease"
                                                        onClick={(e: any)=>{ props.click(e, 'adjustment-decrease', {...input, ...input.adjustment[0]} ); }}
                                                />}

                                                <div className="input-content">
                                                    { input.type === 'amount' && <AmountField
                                                        standard
                                                        maxLength={input.maxLength || 20}
                                                        id={`input-${input.name}`}
                                                        value={props.value[input.name] || ''}
                                                        onChange={(e) => { props.changeInputValue({currentTarget: {
                                                            name: input.name,
                                                            value: e.target.value
                                                        }}); }}
                                                    />}

                                                    { input.type === 'field' && 
                                                        <input id={`input-${input.name}`} maxLength={input.maxLength || 20} name={input.name} type="text" value={props.value[input.name] || ''}
                                                            onChange={(e) => { props.changeInputValue(e); }}
                                                        />
                                                    }

                                                    { input.type === 'checkbox' && <Checkbox 
                                                        onValueChange={(e) => { props.changeCheckbox(e, input.name); }} 
                                                        checked={!!props.value.inkluderFond} 
                                                        label={input.label} 
                                                    /> }

                                                    {!!input.unit  && <div className="unit">{input.unit}</div>}
                                                </div>

                                                { !!(input.adjustment || [])[1] && <a href="#" role="button"
                                                        title={input.adjustment[1].label} 
                                                        aria-label={input.adjustment[1].label}
                                                        className="adjustment-button -increase"
                                                        onClick={(e: any)=>{ props.click(e, 'adjustment-increase', {...input, ...input.adjustment[1]} ); }}
                                                />}
                                            </div>

                                            { /* Temporary not in use at all. */ }
                                            { !!props.changeSlider && false && <Slider current={[parseInt(`${props.value[input.name] || 0}`, 10)]} 
                                                interval={input.interval} 
                                                unit={input.unit} 
                                                label={input.label} 
                                                round={input.round || 1}
                                                change={(e)=>{ if ( props.changeSlider ) { props.changeSlider(e, input); }}}
                                            /> }

                                            { !!props.error[input.name] && <div className="input-error">{props.error[input.name]}</div> }

                                            { !!input.link && <div className="input-link-wrapper">
                                                <div className="input-link-holder">
                                                    { !!input.value && <div className="input-link-value">{input.value}</div> }
                                                    <div>
                                                        <LinkButton onClick={(e)=>{ props.click(e, input.link.action); }}>{input.link.text}</LinkButton>
                                                    </div>
                                                </div>
                                                { !!input.error && <div className="input-error">{input.error}</div>}
                                            </div>}
                                        </li>
                                    })}
                                </ul>
                            </div>

                            { props.tool }

                            { !!props.message && <Message type="InfoMessage" icon text={props.message}/> }
                        </section>
                        <section className="summary-wrapper" aria-labelledby={`${props.id}-summary-title`}>
                            <div className="summary-content">
                                {props.result }
                                { props.loading && <div className="paragraph -include-top"><Spinner type="link-list" /></div> }
                            </div>
                        </section>
                    </div>
                </div>
            }]}/>

            { props.children }
        </div>
    );
};