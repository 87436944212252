import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import HelperFunctions from '../../components/helper-functions';
import FondsOversikt from './fonds-oversikt';
import { settSideTittel } from '../../actions/header';
import Feilmelding from '../../components/feilmelding';

class FondsOversiktController extends Component {
    static propTypes = {
        errors: PropTypes.object,
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        portefolje: PropTypes.object,
    };

    componentDidMount = () => this.props.settSideTittel(this.props.texts.get('lasterFondsApp'));

    sjekkForServerFeil = (errors) => errors.LAST_PORTEFOLJE;

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.props.portefolje) {
            return <FondsOversikt portefolje={this.props.portefolje} />;
        }

        return <Loading title={this.props.texts.get('lasterFondsApp')} />;
    }
}

const mapToPortefolje = (portefoljeData, portefoljeId) =>
    portefoljeData ? HelperFunctions.hentPortefoljeBasertPaaId(portefoljeId, portefoljeData.portefoljer) : null;

export default connect(
    (state, ownProps) => {
        const portefolje =
            state.Fond.portefoljeData &&
            mapToPortefolje(state.Fond.portefoljeData, ownProps.match.params.portefoljeId, state.Texts.texts);

        return {
            ...state.Texts,
            ...state.Feilmeldinger,
            portefolje,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel, undertittel) => {
                dispatch(settSideTittel(sideTittel, undertittel));
            },
        };
    }
)(FondsOversiktController);
