import { LEGG_TIL_KONTO_FRA_ANNEN_BANK, HENTE_KONTOER, HENT_KUNDEFORHOLD } from '../actions/bank';

import { AUTENTISERINGS_SJEKK } from '../actions/common';

const initialState = {
    authenticated: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HENTE_KONTOER:
            return {
                ...state,
                kontoer: action.data,
            };
        case LEGG_TIL_KONTO_FRA_ANNEN_BANK: {
            return {
                ...state,
                nyKontoId: action.data,
                leggTilKontoFeilet: false,
            };
        }
        case AUTENTISERINGS_SJEKK: {
            return {
                ...state,
                authenticated: action.data.ok,
                kundeIdUrl: action.data.kundeIdUrl,
            };
        }
        case HENT_KUNDEFORHOLD: {
            return {
                ...state,
                kundeforhold: action.data,
            };
        }
        default:
            return state;
    }
};
