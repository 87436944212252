/**
 * Created by vagrant on 22.02.17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';

import ActionButton from '../action-button';
import Card from '../card';
import GdprSamtykke from './gdpr-samtykke';
import Vilkaar from './vilkaar';
import * as VILKAARTYPES from '../../domain/vilkaar-types';

class MaaGodkjennes extends Component {
    static propTypes = {
        texts: PropTypes.object.isRequired,
        endreAlleSamtykker: PropTypes.func.isRequired,
        endreSamtykkeType: PropTypes.func.isRequired,
        samtykker: PropTypes.object,
        nyKunde: PropTypes.bool,
        headline: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = this.init(props.samtykker);
    }

    UNSAFE_componentWillMount = () => this.setState(this.init(this.props.samtykker));

    UNSAFE_componentWillReceiveProps = newProps => this.setState(this.init(newProps.samtykker));

    init = samtykker =>
        samtykker
            ? {
                  godtattAlleSamtykker: samtykker.godtattAlle,
                  godtattGdpr: this.gdprGodtatt(samtykker),
                  lasterSamtykker: false
              }
            : {
                  lasterSamtykker: true,
                  lasteTekst: 'sjekkSamtykker'
              };

    gdprGodtatt = samtykker => {
        const gdpr = samtykker.valg.find(valg => valg.samtykkeType === VILKAARTYPES.GDPR);
        return gdpr && gdpr.samtykket;
    };

    endreSamtykke = () => {
        this.setState({
            lasterSamtykker: true,
            lasteTekst: 'godtarSamtykker'
        });

        this.props.endreAlleSamtykker();
    };

    endreGdprSamtykke = () => {
        this.setState({
            lasterSamtykker: true,
            lasteTekst: 'godtarGdprSamtykker'
        });

        this.props.endreSamtykkeType({ samtykkeType: VILKAARTYPES.GDPR, samtykket: true });
    };

    visLasteAnimasjon = key => <Loading title={this.props.texts.get(key)} />;

    render = () => {
        const { headline } = this.props;
        if (!this.state.lasterSamtykker) {
            if (!this.state.godtattAlleSamtykker) {
                return <Vilkaar {...this.props} endreAlleSamtykker={this.endreSamtykke} nyKunde={this.props.nyKunde} />;
            }
            if (!this.state.godtattGdpr) {
                return (
                    <Card>
                        <div className="vilkaar">
                            {!!headline && <div className="align-center paragraph -include-top font-size-regular">{headline}</div>}

                            <GdprSamtykke />
                            <ActionButton
                                text={this.props.texts.get('GodkjenneGdpr')}
                                action={this.endreGdprSamtykke}
                            />
                        </div>
                    </Card>
                );
            }
        }
        return this.visLasteAnimasjon(this.state.lasteTekst);
    };
}

export default MaaGodkjennes;
