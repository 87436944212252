import { get, post, put } from "@eika-infrastruktur/request";
import {processError, processResponse} from './common';
import { postLog } from './app';

export const LAST_SPARE_PROFIL_DATA = 'LAST_SPARE_PROFIL_DATA';
export const ENDRE_SPARE_PROFIL = 'ENDRE_SPARE_PROFIL';
export const LAST_SAMTYKKE = 'LAST_SAMTYKKE';
export const LAST_SAMTYKKE_VALG = 'LAST_SAMTYKKE_VALG';
export const HENT_KUNDE_VALG = 'HENT_KUNDE_VALG';
export const ENDRE_KUNDE_VALG = 'ENDRE_KUNDE_VALG';
export const SET_TERMS = 'SET_TERMS';

const spareUrl = '/sparing-min-side-web/rest/resource/spare';
const spareProfilUrl = `${spareUrl}/spareprofil`;

const samtykkeUrl = '/sparing-min-side-web/rest/resource/samtykke';
const hentSamtykkerUrl = `${samtykkeUrl}/samtykker`;
const endreSamtykkeUrl = `${samtykkeUrl}/kundesamtykke`;
const kundeValgUrl = '/sparing-min-side-web/rest/resource/kunde/kundevalg';
const endreSamtykkeTypeUrl = `${samtykkeUrl}/kundesamtykke/type`;

export const lastSpareProfil = () => dispatch =>  {
    const api = spareProfilUrl;
    return get(api).then(data =>{
        return processResponse(LAST_SPARE_PROFIL_DATA, data, dispatch, undefined, api);
    }).catch( (error) => {
        dispatch(postLog(error, {api, function: 'lastSpareProfil'}));
    });
}

export const hentKundeValg = () => dispatch => {
    const api = kundeValgUrl;
    return get(api).then(data =>{
        return processResponse(HENT_KUNDE_VALG, data, dispatch, undefined, api);
    }).catch( (error) => {
        dispatch(postLog(error, {api, function: 'hentKundeValg'}));
    });
}

export const lastSamtykke = () => dispatch => {
    const api = hentSamtykkerUrl;
    return get(api).then(data => {
        return processResponse(LAST_SAMTYKKE, data, dispatch, undefined, api);
    }).catch( (error) => {
        dispatch(postLog(error, {api, function: 'lastSamtykke'}));
    });
}

export const endreSpareProfil = endring => {
    const api = spareProfilUrl;
    return dispatch =>
        put(api, endring)
            .then(data => processResponse(LAST_SPARE_PROFIL_DATA, data, dispatch, undefined, api))
            .catch((error) => processError(LAST_SPARE_PROFIL_DATA, error, dispatch));
};

export const endreKundeValg = valg => {
    return dispatch => {
        put(kundeValgUrl, valg);

        dispatch({
            type: ENDRE_KUNDE_VALG,
            valg
        });
    };
};

export const endrePensjonsValg = endring => endreSpareProfil(endring, 'pensjonsvalg');

export const endreKundeInfo = endring => endreSpareProfil(endring, 'kundeInfo');

export const endreSamtykke = (id, endring) => {
    return () => put(`${endreSamtykkeUrl}/${id}`, endring);
};

export const endreSamtykkeType = samtykke => {
    const api = endreSamtykkeTypeUrl;
    return dispatch =>
        put(api, samtykke)
            .then(data => processResponse(LAST_SAMTYKKE_VALG, data, dispatch, undefined, api))
            .catch((error) => processError(LAST_SAMTYKKE_VALG, error, dispatch));
};

export const endreAlleSamtykker = () => {
    const api = endreSamtykkeUrl;
    return dispatch =>
        put(api, true)
            .then(data => processResponse(LAST_SAMTYKKE_VALG, data, dispatch, undefined, api))
            .catch((error) => processError(LAST_SAMTYKKE_VALG, error, dispatch));
};

/******************************************************************************
******************************************************************************/
export const getTerms = (application='MOBILBANK') => async (dispatch) => {
    let result = [];

    const url = `/sparing-min-side-web/rest/resource/mandatory-consents/tasks?app=${application}`;
    try {
        result = await get( url ).then( (response) => {
            return response || [];
        }).catch( (error) => {
            dispatch(postLog(error, {api: url, function: 'getTerms - fetch-catch'}));
            return []; 
        });
    } catch ( error ) {
        dispatch(postLog(error, {api: url, function: 'getTerms - try-catch'}));
        result = [];
    }
    
    result.forEach( (d) => { d.originSparingApplication = application; });
    return dispatch({ type: SET_TERMS, result });
};

export const postTerms = ( terms, term ) => async (dispatch) => {
    let result = false;
    
    const url = '/sparing-min-side-web/rest/resource/user-consents';
    let cloned = JSON.parse(JSON.stringify(terms));
    let found = cloned.find( (d) => d.consentCode === term.consentCode ) || {};
    found.done = null;

    dispatch({ type: SET_TERMS, result: cloned });
    try {
        result = await post( url, [term]).then(() => true).catch( () => false );
    } catch ( error ) {
        result = false;
    }

    cloned = JSON.parse(JSON.stringify(terms));
    found = cloned.find( (d) => d.consentCode === term.consentCode ) || {};
    found.done = result;
    dispatch({ type: SET_TERMS, result: cloned });
};

export const submitAllTerms = (terms) => async (dispatch) => {
    const url = '/sparing-min-side-web/rest/resource/user-consents';

    try {
        const result = await post(url, terms);
        dispatch({ type: SET_TERMS, result: terms });
        return result;
    } catch (error) {
        return false;
    }
};

/******************************************************************************
******************************************************************************/

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_TERMS:
            return { ...state, terms: action.result, };
        case LAST_SPARE_PROFIL_DATA:
            return {
                ...state,
                ...action.data
            };
        case LAST_SAMTYKKE:
            return {
                ...state,
                samtykkeTekster: action.data
            };
        case LAST_SAMTYKKE_VALG:
            return {
                ...state,
                samtykker: action.data
            };

        case ENDRE_SPARE_PROFIL:
            return {
                ...state,
                [action.subObjectName]: {
                    ...state[action.subObjectName],
                    ...action.endring
                }
            };
        case HENT_KUNDE_VALG:
            return {
                ...state,
                kundevalg: action.data
            };
        case ENDRE_KUNDE_VALG:
            return {
                ...state,
                kundevalg: {
                    ...state.kundevalg,
                    ...action.valg
                }
            };
        default:
            return state;
    }
};
