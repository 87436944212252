import { RAPPORTER_SERVERKALL_FEIL } from '../actions/common';

const initialState = {
    errors: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RAPPORTER_SERVERKALL_FEIL:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.data.type]: action.data,
                },
            };
        default:
            return state;
    }
};
