import { logClientSideError } from '../actions/common';

const checkIfIsVerge = (kanUtfylleAHV) => {
    if (!kanUtfylleAHV) {
        return false;
    }
    if (
        kanUtfylleAHV.foedselsnummerErGyldig &&
        kanUtfylleAHV.barnErUnderAttenAar &&
        !kanUtfylleAHV.eksistererSparemaalPaaBarn &&
        kanUtfylleAHV.innloggetBrukerErVerge
    ) {
        return true;
    }
    return false;
};

export const getErrorMessage = (kanUtfylleAHV, dispatch) => {
    if (!kanUtfylleAHV) {
        return '';
    }
    if (!kanUtfylleAHV.foedselsnummerErGyldig) {
        dispatch(logClientSideError('Ugyldig fødselsnummer på barnet'));
        return 'Vennligst sjekk at du har skrevet inn rett fødselsnummer.';
    }
    if (!kanUtfylleAHV.barnErUnderAttenAar) {
        dispatch(logClientSideError('Barnet er over 18 år'));
        return 'Dersom barnet er over 18 år, må barnet opprette sparefondet selv, eller så må du spare til barnet i ditt navn.';
    }
    if (kanUtfylleAHV.eksistererSparemaalPaaBarn) {
        dispatch(logClientSideError('Sparemaal på barnet eksisterer allerede'));
        return 'Foreløpig kan man bare ha ett sparemål i barnets navn. Dersom du ønsker å ha flere sparemål til barnet, kan det opprettes i ditt navn.';
    }
    if (!kanUtfylleAHV.innloggetBrukerErVerge) {
        dispatch(logClientSideError('Innlogget bruker er ikke verge'));
        return 'Sparefond i barnets navn kan du kun opprette for dine egne barn som er under 18 år.';
    }
    return '';
};

/*
const checkIfIsVerge = (kanUtfylleAHV, opprettSparemal=false) => {
    if (!kanUtfylleAHV) { return false; }

    const valid = kanUtfylleAHV.foedselsnummerErGyldig &&
        kanUtfylleAHV.barnErUnderAttenAar &&
        kanUtfylleAHV.innloggetBrukerErVerge;
    if ( !valid ) { return false; }

    if ( kanUtfylleAHV.eksistererSparemaalPaaBarn && opprettSparemal ) {
        return false;
    }
    
    return true;
};

export const getErrorMessage = (kanUtfylleAHV, dispatch, opprettSparemal=false) => {
    if (!kanUtfylleAHV) {
        return '';
    }
    if (!kanUtfylleAHV.foedselsnummerErGyldig) {
        dispatch(logClientSideError('Ugyldig fødselsnummer på barnet'));
        return 'Vennligst sjekk at du har skrevet inn rett fødselsnummer.';
    }
    if (!kanUtfylleAHV.barnErUnderAttenAar) {
        dispatch(logClientSideError('Barnet er over 18 år'));
        return 'Dersom barnet er over 18 år, må barnet opprette sparefondet selv, eller så må du spare til barnet i ditt navn.';
    }
    if (kanUtfylleAHV.eksistererSparemaalPaaBarn && opprettSparemal) {
        dispatch(logClientSideError('Sparemaal på barnet eksisterer allerede'));
        return 'Foreløpig kan man bare ha ett sparemål i barnets navn. Dersom du ønsker å ha flere sparemål til barnet, kan det opprettes i ditt navn.';
    }
    if (!kanUtfylleAHV.innloggetBrukerErVerge) {
        dispatch(logClientSideError('Innlogget bruker er ikke verge'));
        return 'Sparefond i barnets navn kan du kun opprette for dine egne barn som er under 18 år.';
    }
    return '';
};
*/

export default checkIfIsVerge;
