import React, { Component } from 'react';
import { connect } from 'react-redux';
import Format from '../../components/format-functions';
import AppMessage from '../../components/AppMessage';

require('../../styles/ArbeidsgiverRisikoprofil.scss');

class ArbeidsgiverRisikoprofil extends Component  {
    constructor(props) {
    	super(props);
    	this.state = {
    		information: [{
			    reg: /livssyklus/i,
			    spareprofil: [
		        	['Spareprofil'],
		            ['Risikoprofil', 'Livssyklus'],
		            ['Aksjer', '100%'],
		        ],
			    priser: [
		        	['Priser'],
		            ['Kjøp', '0%'],
		            ['Salg', '0%'],
		            ['Forvaltning', '0,7%'],
		        ],
			    title: 'Om Livssyklus risikoprofil',
			    text: [
			        'Eika Selvvalgt Livssyklus er en spareprofil hvor sparemidlene plasseres i aksje- og rentefond forvaltet av Eika Kapitalforvaltning AS.',
			        'Gjennom profilen vil man være investert i 100 % aksjer frem til man fyller 60 år. I perioden fra man er 60 år til man fyller 67 år vil risikoen i fondet gradvis reduseres ved at aksjeandelen reduseres til 40 % og renteandelen økes til 60 %.',
			    ],
			}, {
			    reg: /moderat/i,
			    spareprofil: [
		        	['Spareprofil'],
		            ['Risikoprofil', 'Moderat'],
		            ['Renter', '35%'],
		            ['Aksjer', '65%'],
		        ],
			    priser: [
		        	['Priser'],
		            ['Kjøp', '0%'],
		            ['Salg', '0%'],
		            ['Forvaltning', '0,58%'],
		        ],
			    title: 'Om Moderat risikoprofil',
			    text: [
			        'Eika Selvvalgt Moderat er en spareprofil hvor sparemidlene plasseres i aksje- og rentefond forvaltet av Eika Kapitalforvaltning AS.',
			        'Gjennom profilen vil man normalt være investert i 65 % aksjer og 35 % renter. Plasseringene vil rebalanseres til normalvekt minst én gang i året, og uansett ved avvik fra normalvekten med mer enn 5 %. Profilen passer for en investor som ønsker en balansert risiko mellom aksjer og renter.',
			    ],
			}, {
			    reg: /lav/i,
			    spareprofil: [
		        	['Spareprofil'],
		            ['Risikoprofil', 'Lav'],
		            ['Renter', '100%'],
			    ],
			    priser: [
		        	['Priser'],
		            ['Kjøp', '0%'],
		            ['Salg', '0%'],
		            ['Forvaltning', '0,35%'],
			    ],
			    title: 'Om Lav risikoprofil',
			    text: [
			        'Eika Selvvalgt Forsiktig er en spareprofil hvor sparemidlene plasseres i rentefond forvaltet av Eika Kapitalforvaltning AS.',
			        'Gjennom profilen vil din pensjonskapital bli investert i det norske og nordiske rentemarkedet som generelt kjennetegnes av lav til moderat risiko. Avkastning og risiko i profilen avhenger av det generelle rentenivået og hvordan rentemarkedet vurderer risikobildet til det enkelte selskap og markedet generelt.',
			    ],
			}], 
			query: this.getURLquery(),
    	};
    }

    render() {
    	const { match={} } = this.props;
    	const { information, query } = this.state; 

    	return (<>
            <AppMessage appMessage={this.props.serviceMessage} context="EPK_PROFILE" />

	        <div className="arbeidsgiver-risikoprofil-wrapper">
	        	{information.map( (info) => {
	        		return info.reg.test( (match.params?.riskprofile) ) ? <article key={info.title}>
	        			<div className="amount">{Format.currency(query.innskuddssaldo)}</div>
	        			<div className="product">{query.produkt}</div>

	        			<ul className="spareprofil-list">
		        			{ info.spareprofil.map( (d,i) => (
		        				<li className="" key={`spareprofil-${i}`}>
		        					<div className="">{d[0] || ''}</div>
		        					<div className="">{d[1] || ''}</div>
		        				</li>
		        			))}
	        			</ul>


	        			<ul className="priser-list">
		        			{ info.priser.map( (d,i) => (
		        				<li className="" key={`priser-${i}`}>
		        					<div className="">{d[0] || ''}</div>
		        					<div className="">{d[1] || ''}</div>
		        				</li>
		        			))}
	        			</ul>

	        			<h1>{info.title}</h1>
	        			{ info.text.map( (text,i) => (
	        				<div key={`info-text-${i}`} className="info-text">{text}</div>
	        			))}
	        		</article> : null
	        	})}
	        </div>
        </>);
    }

	getURLquery = () => {
	    const opt = {};
	    const url = window.location.href;
	    const matched = url.replace(/\?+/g, '?').match(/^([\w.\-\s_#%/:]+)\?(.*)/);

	    if (matched) {
	        const splited = (decodeURIComponent(matched[2]) || '').replace(/#\?/g, '&').split('&');

	        for (let i = 0; i < splited.length; i++) {
	            const m = splited[i].match(/(\w+)=(.*)/);
	            // eslint-disable-next-line
	            if ( ! m || ! m[1] || ! m[2] ) { continue; }

	            opt[m[1]] = m[2].replace(/#$/, '');

	            const n = opt[m[1]].match( /^\[(.*)\]$/ );
	            // eslint-disable-next-line
	            if ( ! n || ! n[1] ) { continue; }

	            opt[m[1]] = n[1].split(',').reduce( (p,d) => {
	                if ( d ) {
	                    try {
	                        p.push(JSON.parse(d));
	                    } catch ( error ) {
	                        p.push(d);
	                    }
	                }
	                return p;
	            }, []);
	        }
	    }
	    return opt;
	}
}

export default connect((state) => {
    return {
        ...state.Header,
    };
}, null)(ArbeidsgiverRisikoprofil);