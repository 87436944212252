import { ProcessJsonResponse } from './common';

export const LEGG_TIL_KONTO_FRA_ANNEN_BANK = 'LEGG_TIL_KONTO_FRA_ANNEN_BANK';
export const HENTE_KONTOER = 'HENTE_KONTOER';
export const HENT_KUNDEFORHOLD = 'HENT_KUNDEFORHOLD';

const bankUrl = '/sparing-min-side-web/rest/resource/bank';
const setAktivKontoUrl = `${bankUrl}/konto/settaktiv`;
const kontoUrl = `${bankUrl}/konto`;
const bankKundeForholdUrl = `${bankUrl}/kundeforhold`;

export const leggTilKontoFraAnnenBank = (konto) => {
    const config = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(konto),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        fetch(kontoUrl, config).then((data) => {
            if (data.ok) {
                ProcessJsonResponse(HENTE_KONTOER, data, dispatch);
            }
        });
    };
};

export const lagreStatusForKonto = (id, aktiv) => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(aktiv),
    };

    return (dispatch) => {
        fetch(`${setAktivKontoUrl}/${id}`, config).then((data) => {
            if (data.ok) {
                ProcessJsonResponse(HENTE_KONTOER, data, dispatch);
            }
        });
    };
};

export const slettKonto = (id) => {
    const config = {
        credentials: 'same-origin',
        method: 'DELETE',
    };

    return (dispatch) => {
        fetch(`${kontoUrl}/${id}`, config).then((data) => {
            if (data.ok) {
                ProcessJsonResponse(HENTE_KONTOER, data, dispatch);
            }
        });
    };
};

export const endreKonto = (id, text, value) => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify({ navn: text, verdi: value }),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        fetch(`${kontoUrl}/${id}`, config).then((data) => {
            if (data.ok) {
                ProcessJsonResponse(HENTE_KONTOER, data, dispatch);
            }
        });
    };
};

export const hentKontoer = () => {
    return (dispatch) => {
        return fetch(kontoUrl, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(HENTE_KONTOER, data, dispatch)
        );
    };
};

export const hentKundeForhold = () => {
    return (dispatch) => {
        fetch(bankKundeForholdUrl, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(HENT_KUNDEFORHOLD, data, dispatch)
        );
    };
};
