/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import { settSideTittel } from '../../actions/header';
import { endreKundeInfo } from '../../actions/spareprofil';
import Feilmelding from '../../components/feilmelding';
import EndreOmMeg from '../../components/spareprofil/endre-om-meg';
import Card from '../../components/card';

require('../../styles/spare-profil.scss');

class OmMeg extends Component {
    static propTypes = {
        texts: PropTypes.object,
        kundeInfo: PropTypes.object,
        endreKundeInfo: PropTypes.func,
        errors: PropTypes.object,
        settSideTittel: PropTypes.func,
        history: PropTypes.object,
    };

    UNSAFE_componentWillMount = () => this.props.settSideTittel(this.props.texts.get('omMeg'));

    sjekkForServerFeil = (errors) => errors.LAST_SPARE_PROFIL_DATA;

    endreKundeInfo = (endringer) => {
        this.props.endreKundeInfo(endringer);
        this.props.history.goBack();
    };

    avbryt = () => {
        this.props.history.goBack();
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.props.kundeInfo) {
            return (
                <Card>
                    <EndreOmMeg
                        texts={this.props.texts}
                        endreKundeInfo={this.endreKundeInfo}
                        kundeInfo={this.props.kundeInfo}
                        avbryt={this.avbryt}
                    />
                </Card>
            );
        }
        return <Loading title={this.props.texts.get('lasterDinSpareProfil')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.SpareProfil,
            ...state.Feilmeldinger,
            ...state.Texts,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            endreKundeInfo: (endring) => {
                dispatch(endreKundeInfo(endring));
            },
        };
    }
)(OmMeg);
