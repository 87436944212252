// @ts-nocheck // kiet fix it later
import classnames from 'classnames';
import { generateId, addEvent, getWindowSize, getOffset, clearSelection, sortList, isIE, isEdge } from '../../util/Functions';
import initGraphBarInfo from './ChartBarFunction';
import initAxisList from './ChartAxisFunction';
import initGraphLineInfo from './ChartLineFunction';
import initGraphPieInfo from './ChartPieFunction';
import initLegendInfo from './ChartLegendFunction';
import { getPieBlackboardContent, getLineBlackboardContent, getBarBlackboardContent } from './ChartBlackboardFunction';
import { getPositionTopPercentByYpx, getPositionLeftPercentByXpx, getXaxisElementInterval, getXaxisTitle } from './ChartFunction';

export const initPointTextList = ( state ) => {
    state.graph.pointTextList.forEach( (src) => {
        src.type = 'point-text';
        src.style = {
            backgroundColor: src.color || '#eee',
            top: `${getPositionTopPercentByYpx(src.cy, state)}%`,
            left: `${getPositionLeftPercentByXpx(src.cx, state)}%`                
        };

        if ( src.textColor ) { src.style.color = src.textColor; }
    });
};

export const initGraph = (state) => {
    const info = {
        list: [],
        pin: {},
        sum: 0,
        highest: 0,
        lowest: 0,
        distance: 0,
        multiple: false,
        color: { i: 0, used: [] },
        symbol: { i: 0, used: [] },
        animation: [],
        pointTextList: []
    };

    if (!state) { return info; }

    if (typeof (state.highest) === 'number') { info.highest = state.highest; }
    if (typeof (state.lowest) === 'number' ) { info.lowest  = state.lowest;  }

    (state.data instanceof Array ? state.data : [state.data]).forEach((d, i) => {
        /* eslint-disable */
        const data = typeof (d) === 'number' ? { value: d } : (
            typeof (d.value) === 'number' || d instanceof Array ? d : null
        );
        /* eslint-enable */
        if (!data) { return; }

        let value = 0;
        let cloned = null;
        if (data instanceof Array) {
            info.multiple = true;

            if (info.symbol.next) {
                info.symbol.used.push(state.symbolList[info.symbol.i]);
                info.symbol.i++;
            }
            info.symbol.next = false;

            cloned = data.reduce((p, n) => {
                /* eslint-disable */
                const t = typeof (n) === 'number' ? { value: n } : (
                    typeof (n.value) === 'number' ? n : null
                );
                /* eslint-enable */
                if (!t) { return null; }

                if (state.type === 'pie' && t.value < 0 ) {
                    t.value = 0;
                }

                const tmp = { ...t, id: generateId(`graph-${i}`), orgId: t.id || '' };
                if (tmp.symbol !== false && state.symbol) {
                    tmp.symbol = state.symbolList[info.symbol.i];
                    info.symbol.next = true;
                }

                if (state.type === 'engine') {
                    tmp.size = tmp.size || state.engineSize || 20;
                    tmp.removePart = tmp.removePart || state.engineRemovePart || 6;
                }

                value += tmp.value || 0;

                info.highest = info.highest < tmp.value ? tmp.value : info.highest;
                info.lowest = info.lowest > tmp.value ? tmp.value : info.lowest;

                info.pin[tmp.id] = tmp;
                p.push(tmp);
                return p;
            }, []);

            if (state.type === 'bar' && state.concatnation && info.highest < value) {
                info.highest = value;
            }
        } else {
            cloned = { ...data, id: generateId(`graph-${i}`), orgId: data.id || '' };
            if (state.type === 'pie' && cloned.value < 0 ) {
                cloned.value = 0;
            }

            if (state.type === 'engine') {
                cloned.size = cloned.size || state.engineSize || 20;
                cloned.removePart = cloned.removePart || state.engineRemovePart || 6;
            } else if (state.type === 'pie' && state.symbol) {
                cloned.symbol = state.symbolList[info.symbol.i++];
            }

            value = cloned.value || 0;
            info.pin[cloned.id] = cloned;                
            info.highest = info.highest < value ? value : info.highest;
            info.lowest = info.lowest > value ? value : info.lowest;
        }

        info.sum += value;
        info.list.push(cloned);
    });

    if (info.symbol.next) {
        info.symbol.used.push(state.symbolList[info.symbol.i]);
        info.symbol.i++;
    }

    if (state.highest < info.highest) {
        state.highest = info.highest;
    }
    if (state.lowest > info.lowest) {
        state.lowest = info.lowest;
    }

    info.distance = info.highest + Math.abs(info.lowest);
    state.distance = info.distance;

    if (state.lowest < 0 && state.axis && state.axis.y) {
        const zeroPercent = Math.abs(state.lowest) / state.distance;
        state.axis.y.zero = (state.axis.y.max ?? 0) - ((state.axis.y.max ?? 0) * zeroPercent);
    }

    if (state.type === 'progress') {
        info.sum = 100;
    } else if (typeof (state.sum) === 'number' && state.sum > info.sum) {
        info.sum = state.sum;
    }

    if (state.type === 'pie' || state.type === 'progress') {
        //if ( state.type === 'pie' && info.highest === 0 ) {
        if ( info.highest === 0 ) {
            const empty = { value: 100, color: '#F3F1EF', id: generateId('graph-emtpy-progress') };
            state.type = 'progress';
            state.animation = false;
            state.highest = empty.value;
            info.highest = empty.value;
            info.sum = empty.value;
            info.pin[empty.id] = empty;
            info.list = [empty];
            info.noText = true;
        }

        initGraphPieInfo(state, info);
    } else if (state.type === 'line' || state.type === 'spline' || state.type === 'bar') {
        if (info.list[0] instanceof Array) {
            const collection = info.list; let storage = []; const
                length = collection.length;

            for (let i = 0; i < length; i++) {
                if (state.type !== 'bar' || !state.concatnation) {
                    const color = state.color.list[info.color.i++];
                    collection[i].forEach((d) => { d.color = d.color || color; });
                }

                const tmp = { ...info, list: collection[i] };

                /* eslint-disable */
                state.type === 'line' || state.type === 'spline' ?
                    initGraphLineInfo(state, tmp) :
                    initGraphBarInfo(state, tmp, {
                        count: state.concatnation ? info.list.length : length,
                        index: i
                    });
                /* eslint-enable */
                // const relation = tmp.list.map(d => d.id);
                // tmp.list.forEach((d) => { d.relation = relation; });

                storage = storage.concat(tmp.list);
            }
            info.list = storage;
        } else {
            const color = state.color.list[info.color.i++];
            info.list.forEach((d) => { d.color = d.color || color; });
            /* eslint-disable */
            state.type === 'line' || state.type === 'spline' ?
                initGraphLineInfo(state, info) :
                initGraphBarInfo(state, info);
            /* eslint-enable */
        }
    }

    initLegendInfo(state, info);

    state.style = classnames(state.type, {
        '-multiple': info.multiple,
        '-concatnation': state.concatnation
    });

    return info;
};

export const initState = (props = {}) => {
    const { xAxis = {}, yAxis = {}, forceIEView } = props;
    const isMSbrowser = forceIEView || isIE() || isEdge();
    const state = {
        duration: props.duration || 600,
        view: props.view || [1040, 1040],
        padding: props.padding || 100,
        barSpace: props.barSpace || 20,
        lineRadius: 7,
        lineSpace: 20,
        lineWidth: props.lineWidth || 3,
        lineColor: props.lineColor || '',
        engineSize: 20,
        engineRemovePart: 6,
        data: props.data || [],
        type: props.type || 'bar',
        fill: props.fill || false,
        max: props.max || 0,
        highest: props.highest || (/^(bar|line|spline)/i.test(props.type) ? .01 : 0),
        lowest: props.lowest || 0,
        sum: props.sum || 0,
        axis: {},
        color: props.color || {
            default: props.colorDefault || '#ccc',
            background: props.colorBackground || '#fff',
            list: props.colorList || [
                '#1cc99d', // green
                '#52b7f2', // blue
                '#f35072', // red
                '#f0c55c', // yellow
                '#8675f4', // purple
                '#d8903b', // orange
                '#e9a3bf', // pink
            ]
        },
        symbolList: [
            'circle', 'square', 'triangle', 'triangle-down',
            'square-single-cross', 'square-cross', 'triangle-left',
            'triangle-right', ''
        ],
        symbol: props.symbol !== false ? (props.symbol || true) : false,
        animation: props.animation !== false && !isMSbrowser,
        legend: props.legend,
        description: props.description,
        concatnation: props.concatnation === true,
        shadow: props.shadow !== false,
        pin: {},
        hover: props.hover !== false,
        config: props.config,
    };

    if ( props.setStaticSize ) {
        state.staticSize = {width: `${state.view[0]}px`, height:  `${state.view[1]}px`};
    }
    
    const pieStrokeDivideSize = state.config ? (state.config.pieStrokeDivideSize || 4) : 4;
    state.pieRadius = state.config && state.config.radius ? 
        parseInt((state.config.radius / 3), 10) : parseInt((state.view[0] / 3), 10);
    state.pieStroke = parseInt((state.pieRadius / pieStrokeDivideSize), 10);

    if (typeof (state.padding) === 'number') {
        state.padding = {
            top: state.padding,
            left: state.padding,
            right: state.padding,
            bottom: state.padding
        };
    }

    if (typeof (state.padding) !== 'object') { state.padding = {}; }

    ['top', 'left', 'right', 'bottom'].forEach((key) => {
        state.padding[key] = state.padding[`${key}Original`] || state.padding[key] || 0;
    });

    state.axis.x = {
        max: xAxis.max || (state.view[0] - state.padding.left - state.padding.right),
        list: [],
        grid: typeof (xAxis.grid) === 'number' ? xAxis.grid : 1,
        color: xAxis.color || '#333',
        textColor: xAxis.textColor || '#333',
        lineSize: xAxis.lineSize || [2, 4],
        text: xAxis.text,
        title: xAxis.title,
        selectionInterval: (xAxis.intervalSelection || []).sort(),
        leftGap: xAxis.leftGap || 0,
        rightGap: xAxis.rightGap || 0,
        size: xAxis.size,
        stretch: xAxis.stretch !== false,
    };

    state.axis.y = {
        max: yAxis.max || (state.view[1] - state.padding.top - state.padding.bottom),
        list: [],
        grid: typeof (yAxis.grid) === 'number' ? yAxis.grid : 1,
        color: yAxis.color || '#333',
        textColor: yAxis.textColor || '#333',
        lineSize: yAxis.lineSize || [4, 2],
        separation: yAxis.separation || 0,
        separationLine: yAxis.separationLine === true,
        unit: yAxis.unit || '',
        title: yAxis.title,
        noText: yAxis.noText,
        size: yAxis.size,
        toRight: yAxis.toRight === true,
        trimNumber: yAxis.trimNumber,
        stretch: xAxis.stretch !== false,
    };

    state.centerPoint = [
        (state.axis.x.max + state.padding.left + state.padding.right) / 2,
        (state.axis.y.max + state.padding.top + state.padding.bottom) / 2
    ];

    state.viewBox = [0, 0, state.view[0], state.view[1]].join(' ');
    state.graph = initGraph(state);

    // if ( ! state.graph.highest ) {
    //     state.graph.list = [];         
    // }

    if (props.axis === true || (props.axis !== false && state.type.match(/^(bar|line|spline)/i))) {
        state.axis.x.list = initAxisList('x', state);
        state.axis.y.list = initAxisList('y', state);

        if (state.axis.x.selectionInterval.length > 1) {
            state.axis.x.textList = state.axis.x.list.filter(d => d.classname === 'chart-text');
            state.elementIntervalPosition =
                getXaxisElementInterval(state.axis.x.selectionInterval, state);
        }
    }

    ((state.graph || {}).list || []).forEach((d) => {
        if (!d.id) { return; }
        state.pin[d.id] = d;
    });

    initPointTextList( state );

    return state;
};

/** **************************************************************************
****************************************************************************/
export const getBlackboardPosition = (point, width) => {
    if (!point || !width) { return null; }

    const size = getWindowSize();
    const gap = 10;
    let left = point[0] - (width / 2);

    if (left < gap) {
        left = gap;
    } else if ((left + width + gap) > size[0]) {
        left = size[0] - (width + gap);
    }

    return { left: `${left}px`, top: `${point[1] + 60}px`, width: `${width}px` };
};

export const getLinePointList = (data, state) => {
    return state.graph.list.reduce((prev, src) => {
        if (src.type === 'line-point-none' && src.cx === data.cx) {
            prev.push({
                id: src.id,
                color: src.color,
                style: {
                    backgroundColor: src.color,
                    top: `${getPositionTopPercentByYpx(src.cy, state)}%`,
                    left: `${getPositionLeftPercentByXpx(src.cx, state)}%`
                }
            });
        }
        return prev;
    }, []);
};

export const hideBlackboard = ( state, setState ) => {
    const { id, timer } = state;
    clearTimeout(timer.current[`${id}hideBlackboard`] || 0);
    // clearTimeout(timer.current[`${id}showBlackboard`] || 0);
    timer.current[`${id}hideBlackboard`] = setTimeout(() => {
        setState({...state, blackboard: null, linePointList: null });
    }, 300);
};

export const showBlackboard = (state, setState, { elementId, point, width=260 }) => {
    if (!elementId || !point ) { return; }

    const { id, pin = {}, type = '', timer } = state;
    const data = pin[elementId];
    if (!data) { return; }

    clearTimeout(timer.current[`${id}hideBlackboard`] || 0);
    const option = {
        pie: getPieBlackboardContent,
        line: getLineBlackboardContent,
        spline: getLineBlackboardContent,
        bar: getBarBlackboardContent
    };

    const blackboard = {
        content: option[type] ? option[type](data, state) : '',
        style: getBlackboardPosition(point, width),
        point,
        width
    };

    if (type.match(/^(line|spline)$/i)) {
        const linePointList = getLinePointList(data, state);
        setState({...state, blackboard, linePointList });
    } else {
        setState({...state, blackboard });
    }
};

export const linePointClearTimer = (state, setState) => {
    const { id, blackboard, timer } = state;
    setTimeout(() => {
        clearTimeout(timer.current[`${id}hideBlackboard`] || 0);
        clearTimeout(timer.current[`${id}showBlackboard`] || 0);

        if (!blackboard) { return; }

        const point = [blackboard.point[0], (blackboard.point[1] + 40)];
        const position = getBlackboardPosition(point, blackboard.width);

        if (!position) { return; }
        setState({...state, blackboard: { ...blackboard, style: position } });
    }, 50);
};

export const mousedown = (state, setState, e) => {
    state.binded.current.xAxisSelection = e && e.target && e.target.id ?
        (state.elementIntervalPosition || []).find(d => d.id === e.target.id) : null;
};

// eslint-disable-next-line
export const mouseup = (state, setState) => {
    if (state.binded.current.xAxisSelection) {
        clearSelection();
    }

    state.binded.current.xAxisSelection = null;
};

/* eslint-disable */
export const mousemove = (state, setState, e) => {
    if (!state.binded.current.xAxisSelection) { return; }

    clearSelection();
    const target = e.currentTarget;
    const width = target.clientWidth;
    const offset = getOffset( target );

    const clientX = e.clientX;
    const percent = (((clientX - offset[0]) / width) * 100);
    const textList = state.axis.x.textList;
    const length = textList.length;

    if (!length) { return; }

    let index = -1;
    for (let i = 0; i < length; i++) {
        if (textList[i].leftPositionPercent >= percent) {
            index = i;
            i = length;
        }
    }

    if ( index === -1 ) {
        if ( percent >= 100 ) {
            index = length - 1;
        } else if ( percent < 0 ) {
            index = 0;
        }
    }

    if (index === -1 || index > length) { return; }

    let current = index;
    if (index === (length - 1)) {
        current = length - 1;
    } else if (textList[(index - 1)]) {
        const a = textList[index].leftPositionPercent - percent;
        const b = percent - textList[(index - 1)].leftPositionPercent;
        if (b < a) {
            current = index - 1;
        }
    }

    let ignorUdate = false;
    let positions = (state.elementIntervalPosition || []).map((d) => {
        const cloned = JSON.parse(JSON.stringify(d));
        if (state.binded.current.xAxisSelection.id === cloned.id) {
            ignorUdate = cloned.index === current;
            cloned.index = current;
            cloned.style.left = `${getPositionLeftPercentByXpx(textList[current].x, state)}%`;
            cloned.text = getXaxisTitle(current, state);
        }
        return cloned;
    });

    if (ignorUdate) { return; }

    const last = positions.pop();
    positions = sortList(positions, 'index', null, true);
    last.style.left = positions[0].style.left;
    last.style.right =
        `${100 - parseInt(positions[1].style.left, 10)}%`;

    positions.push(last);
    setState({...state, elementIntervalPosition: positions });
}
/* eslint-enable */

export const mouseover = (state, setState, e) => {
    const elementId = e ? e.target.id : '';
    if (!elementId) { return; }

    const { id, timer } = state;
    clearTimeout(timer.current[`${id}mouseout`] || 0);
    // clearTimeout(timer.current[`${id}showBlackboard`] || 0);
    timer.current[`${id}showBlackboard`] = setTimeout( () => {
        showBlackboard(state, setState, {
            elementId,
            width: 400,
            point: [e.clientX, e.clientY]
        });
    }, 10);
};

export const mouseout = (state, setState,) => {
    const { id, timer } = state;
    clearTimeout(timer.current[`${id}hideBlackboard`] || 0);
    clearTimeout(timer.current[`${id}showBlackboard`] || 0);
    timer.current[`${id}mouseout`] = setTimeout(() => { hideBlackboard(state, setState); }, 100);
};

export const bindGraphEvent = (state, setState) => {
    if (!state.svgRef.current || state.type === 'progress' || state.hover === false) { return; }

    const elements = state.svgRef.current.querySelectorAll('.graph-source') || [];
    Array.from({ length: elements.length }).forEach( (x,i) => {
        const id = elements[i].id;
        if ( !id || state.binded.current[id]) { return; }

        state.binded.current[id] = 1;
        addEvent((e) => { mouseover(state, setState, e); }, elements[i], 'mousemove');
        addEvent((e) => { mouseover(state, setState, e); }, elements[i], 'mouseover');
        addEvent((e) => { mouseout(state, setState, e); }, elements[i], 'mouseout');
    });
};

/** **************************************************************************
****************************************************************************/
export const renewGraph = (props, state, setState) => {
    if ( state.unmounted.current ) { return; }

    const size = [
        `width: ${state.wrapperRef.current.clientWidth}px`,
        `height: ${state.wrapperRef.current.clientHeight}px`,
    ];
    state.wrapperRef.current.setAttribute('style', size.join(';'));
    setState({...state, graph: null});
    setTimeout( () => {
        if ( state.unmounted.current ) { return; }
        try {
            setState({...state, ...initState(props)});
            state.wrapperRef.current.removeAttribute('style');
        } catch ( error ) {
            // do nothing
        }
    }, 300);    
};


