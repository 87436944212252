import React, { useState, MouseEvent } from 'react';

import './GiftCard.scss';

interface Props {
    type?: string;
    text?: string;
    signing?: string;
    title?: string;
    // eslint-disable-next-line
    callback?: (o: boolean) => void;
};

interface State {
    open: boolean;
};

const click = (props: Props, state: State, setState: (s: State) => void, e: MouseEvent, key=''): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if (key === 'toggle') {
        const open = !state.open;
        setState({...state, open});
        if ( props.callback ) { props.callback(open); }
    }
}

export default (props: Props): JSX.Element => {
    const [state, setState] = useState<State>({
        open: false
    }); 

    const {
        title='Kjære barne barn',
        text='Gratulerer med dagen! Jeg håper alle dine bursdagsønsker og drømmer blir til virkelighet.',
        signing='Bestemor, Annette Nilsen',
    } = props;

    return <>
        <a href="#" className={`gift-card -${props.type || 'basic'} -${state.open ? 'open': 'close'}`} onClick={(e)=>{click(props, state, setState, e, 'toggle');}}>
            <div className="gift-card-page cart-page-front">
                <div className="gift-card-page cart-page-outside"/>
                <div className="gift-card-page cart-page-inside">
                    <div className="card-left-content">
                        <div className="receipt">
                            <span className="header"><i>Eika</i></span>
                            <div className="amount">10 000 kr</div>
                            <div className="fund">Eika Spar</div>
                            <div className="account">Aksjesparekonto</div>
                            <div className="account">ASK_026990023413</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gift-card-page cart-page-bottom">
                <div className="card-right-content">
                    { !! title && <p className="title">{title}</p> }                
                    { !! text && <p className="message">{text}</p> }
                    { !! signing && <p className="signing">{signing}</p> }
                </div>
            </div>
        </a>
        <div className="gift-card-bg"/>
    </>
};