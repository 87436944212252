import { postMessageRN, WebToRNAction } from '@eika-infrastruktur/mobilbank-lib';
import { getParam, getOrderStorage, save } from './FondHandelFunctions'; 
import Constant from '../../../static/data/Constant';
import { getMobilbankDeeplink, getRedirectPageUrl } from '../../../common/util/Functions';
import { getAppName } from '../../../common/util/BusinessFunctions';
import { setFondOrderStorage, getAISplan } from '../../../common/util/FondFunctions';
import { getAISsource } from './FondHandelAutomatiskOkingSparingFunctions';
import { Props, State } from '../FondHandelDomain';
import { AISplan } from '../../../domain/Types';

/**************************************************************************
 * == Internal functions ==
**************************************************************************/
const uttak = (props: Props, state: State): void => {
    props.action.setSigneringUrl('');
    const { fund, placement, bankAccount } = state;
    const orderStorage = getOrderStorage( props );
    const fundData = fund.pin[orderStorage.fund] || {};
    const amount = parseInt(`${orderStorage.amount}`.replace(/\s+/g, ''), 10);
    const bank = (placement.pin[orderStorage.placement] || {}).bank || {};
    const selgHeleBeholdningen = amount === orderStorage.maxAmount;
    const bankAccountData = bankAccount.pin[orderStorage.bankAccount];
    const bankkontoType = bankAccountData.category || Constant.typeBankkonto;
    const data = {
        epost: orderStorage.email,
        selgHeleBeholdningen,
        engangsBelop: selgHeleBeholdningen && orderStorage.action !== Constant.actionUttak ? 0 : amount,
        bankkontoType,
        kontonummer: bankkontoType === Constant.typeKlientkonto ? null : bankAccountData.kontonummer,
        bankregnr: bank.bankregnr || {value: orderStorage.bankregnr},
        isin: fundData.isin,
        portefoljeId: orderStorage.placement,
        sparemaal: {},
        appName: getAppName(),
    };

    if ( orderStorage.action === Constant.actionUttak ) { // uttak fra klientkonto
        props.action.OpprettUttaksOrdre( data );
    } else {
        props.action.opprettSalgsOrdre( data );
    }
};

const byttFond = (props: Props, state: State): void => {
    const { fund } = state;
    const orderStorage = getOrderStorage( props );
    const fundData = fund.pin[orderStorage.fund];
    const amount = parseInt(`${orderStorage.amount}`.replace(/\s+/g, ''), 10);
    const byttHeleBeholdning = amount === orderStorage.maxAmount;

    const data = {
        byttHeleBeholdning,
        engangsBelop: byttHeleBeholdning ? 0 : amount,
        bankregnr: {value: orderStorage.bankregnr},
        nyIsin: fundData.isin,
        isin: orderStorage.current,
        portefoljeId: orderStorage.initedPlacement,
        flyttSpareavtel: false,
        epost: orderStorage.email,
        appName: getAppName(),
    };
    props.action.byttFond( data );
};

const settInnPenger = (props: Props, state: State): void => {
    const { fund, bankAccount, placement } = state;

    const param = getParam( props );
    const orderStorage = getOrderStorage( props, param );
    const fundData = fund.pin[orderStorage.fund];
    const bankAccountData = bankAccount.pin[orderStorage.bankAccount] || {};
    const amount = parseInt(`${orderStorage.amount}`.replace(/\s+/g, ''), 10);
    const isNewPlacement = orderStorage.placement === 'ASK' || orderStorage.placement === 'VPK';
    const accountNumber = bankAccountData.kontonummer;
    const konti = placement.pin[orderStorage.placement] || {};
    const bank = konti.bank || {};

    const aisSource = getAISsource( orderStorage );
    const aisPlan = konti.portefoljeType !== Constant.typeIps && orderStorage.ais && orderStorage.type === Constant.typeManedligSparing && orderStorage.aisValue ? 
        getAISplan({
            ...orderStorage, 
            amount, 
            year: 3, 
            aisValue: aisSource.value,
            onlyWantDifferentDeposit: true,
            increaseImmediately: orderStorage.action === Constant.actionEndreSpareavtale,
        }) : [];

    const data: any = {
        epost: orderStorage.email,
        fondsNavn: fundData.navn,
        isin: fundData.isin,
        bankkontoType: bankAccountData.bankkontoType, // Denne som bestemmer belastningkonto type 
        value: bank.bankregnr?.value || orderStorage.bankregnr,
        kontonummer: accountNumber,
        bankregnr: bank.bankregnr || {value: orderStorage.bankregnr},
        ...(orderStorage.type === Constant.typeManedligSparing ? {sparebelopKonto: accountNumber} : {innskuddsKonto: accountNumber}),
        ...(orderStorage.type === Constant.typeManedligSparing ? {spareBelop: amount} : {engangsBelop: amount}),
        ...(orderStorage.type === Constant.typeManedligSparing ? {trekkDag: parseInt(`${orderStorage.drawDay}`, 10)} : {}),
        opprettPortefolje: isNewPlacement ? orderStorage.placement : null,
        ...(isNewPlacement ? {} : {portefoljeId: orderStorage.placement}),
        /* eslint-disable */
        automaticIncrease: (aisPlan || []).length ? {
            isActive: true,
            increaseValueType: aisSource.type,
            increaseAmount: aisSource.type === Constant.typePercent ? undefined : aisSource.value,
            percentage: aisSource.type === Constant.typePercent ? aisSource.value : undefined,
            frequency: aisSource.frequency,
            startDate: ((aisPlan || []).find( (d: AISplan) => d.deposit !== amount ) || {}).stamp || '',
        } : (orderStorage.automaticIncrease ? {...orderStorage.automaticIncrease, isActive: false} : undefined),         
        // PS: currently backend does not support to remove autotmatic increase,
        // It's temporary using isActive=false to deactive automatic increase.
        /* eslint-enable */
        isOpenPage: !!orderStorage.fromOpenPage,
        appName: getAppName(),
    };

    if ( orderStorage.action === Constant.actionEndreSpareavtale ) {
        data.id = orderStorage.id;
        data.spareavtalenavn = '';
        data.optimisedFlow = true;
        if ( orderStorage.avsluttSpareavtale ) {
            data.avsluttSpareavtale = true;
        }
        props.action.endreMndSparing( data );
    } else {
        props.action.kjopFond( data );
    }
};

const displayAvtalegiroWithInlineBrowser = ( props: Props ): void => {
    const orderStorage = getOrderStorage( props );
    const prefix = 'sparing';
    const deeplink = `${getMobilbankDeeplink()}://${prefix}`;
    const stamp = (new Date()).getTime();
    const linking = getRedirectPageUrl();
    const config = orderStorage.receipt?.config ?? {};
    const source = config.avtalegiroObject;
    const url = source && source.uri && source.body ?
        `${linking}?deeplink=${encodeURIComponent(deeplink)}&MBavtalegiro=${stamp}&uri=${source.uri}&${source.body}`.replace('&returnUrl=', '') : '';

    postMessageRN({
        action: WebToRNAction.OPEN_BROWSER_CALLBACK,
        payload: { uri: url, callbackResultPrefix: prefix },
    });
};

/**************************************************************************
 * == External functions ==
**************************************************************************/
export const submit = (props: Props, state: State, setState: (s: State) => void, info?: any): void => {
    props.action.settKvitteringsInnhold(null);
    // eslint-disable-next-line
    save(props, state, setState, {receipt: null, ...(info || {})}, true, true);
    const orderStorage = getOrderStorage( props );

    if ( orderStorage.action === Constant.actionBytt ) {
        byttFond(props, state);
    } else if ( orderStorage.action === Constant.actionSelg || orderStorage.action === Constant.actionUttak ) {
        uttak(props, state);
    } else {
        settInnPenger(props, state);
    }

    const shallEndreSpareProfil = (orderStorage.email && orderStorage.initedEmail !== orderStorage.email);
    if ( shallEndreSpareProfil ) {
        props.action.endreSpareProfil({
            epost: orderStorage.email || orderStorage.initedEmail,
            mobil: orderStorage.mobil || orderStorage.initedMobil,
        });
    }
};

export const displayAvtalegiro = ( props: Props, state: State, setState: (s: State) => void ): void => {
    const { reducer={} } = state;

    if ( reducer.App?.isOnWebview ) {
        return displayAvtalegiroWithInlineBrowser( props );
    }

    const param = getParam( props );
    const orderStorage = getOrderStorage( props );

    state.mode.current.stepBack = undefined;
    orderStorage.history.avtalegiroLength = window.history.length;
    setFondOrderStorage( param.order, orderStorage );

    const config = orderStorage.receipt?.config ?? {};
    const avtalegiroObject = config.avtalegiroObject;
    const url = config.avtalegiroUrl || reducer.Modals?.kvitteringConfig?.avtalegiroUrl || '';
    if ( !url && !avtalegiroObject ) {
        return setState({...state, dialog: 'avtalegiroURLerror'});        
    }

    if ( avtalegiroObject ) {
        const id = 'fond-handel-avtalegiro-form';
        const formParameters = avtalegiroObject.formParameters;
        let form: any = document.getElementById( id );
        if ( form ) { form.parentNode.removeChild(form); }

        form = document.createElement('form');
        form.style = 'display: none';
        form.id = id;
        form.method = 'POST';
        form.action = avtalegiroObject.uri;

        formParameters.returnUrl = window.location.href;

        Object.keys(formParameters).forEach(name => {
            const input = document.createElement('input');
            input.name = name;
            input.value = formParameters[name];
            form.appendChild(input);
        })
        document.body.appendChild(form);
        form.submit();
    } else {
        window.location.href = `${url}${encodeURIComponent(window.location.href)}`;
    }
};