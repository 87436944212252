import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { ErrorLabel, InfoLabel, LabelTypes } from '@eika/label';
import { IconTypes } from '@eika/icon';
import { RowButton } from '@eika/button';
import FlexTextHolder, { FlexText } from '../FlexTextHolder/FlexTextHolder';
import DropdownMenu, { DropdownMenuOption, linkNextToLabel } from '../DropdownMenu/DropdownMenu';
import { ReturnPie } from '../share/ShareComponents';

import './Card.scss';

export interface CardLabel {
    type?: string;
    text?: string;
    name?: string;
    ariaLabel?: string;
    id?: string;
    large?: boolean;
}

export interface CardData {
    id?: string;
    minRow?: number;
    text?: string | string[] | string[][];
    textList?: string[] | string[][] | FlexText[] | FlexText[][];
    title?: string;
    ariaLabel?: string;
    url?: string;
    tooltip?: string;
    subTextLineOne?: string;
    subTextLineTwo?: string;
    textOne?: string;
    error?: string;
    fieldLabel?: string;
    defaultText?: string;
    placeholder?: string;
    category?: string;
    failed?: boolean;
    blocked?: boolean;
    disabled?: boolean;
    singleLine?: boolean;
    tagList?: FlexText[] | FlexText[][];
    labelList?: CardLabel[];
    selectedLead?: string;
    view?: string;
    selected?: string;
    icon?: keyof typeof IconTypes;
    optionList?: DropdownMenuOption[];
    rigthContent?: any; // Kiet will correct it soon
    linkNextToLabel?: linkNextToLabel;
    dataTrackId?: string;
    dataTrackLinkname?: string;
}

export interface Props {
    children?: JSX.Element | boolean;
    data?: CardData;
    textList?: FlexText[] | FlexText[][];
    url?: string;
    type?: string;
    action?: string;
    click?: (e: MouseEvent | null, k: any, d?: any) => void;
    noIcon?: boolean;
    namespace?: string;
    disabled?: boolean;
    style?: { [k: string]: string | number };
    ignoreAriaLabelGenerating?: boolean;
    blank?: boolean;
    id?: string;
    stepDown?: boolean;
}

const getListItemAriaLabel = (data: any, textList: any) => {
    const list = [data.fieldLabel];
    (textList || []).forEach((text: any) => {
        (text instanceof Array ? text : [text]).forEach((v) => {
            if (v) {
                list.push(v.text || v);
            }
        });
    });

    return { 'aria-label': list.filter((v) => !!v).join(', ') };
};

const CardWrapper = ({
    url,
    type,
    click,
    action,
    children,
    textList,
    style,
    ignoreAriaLabelGenerating,
    blank,
    data = {},
}: Props): JSX.Element => {
    const attr: any = type === 'list-item' && !ignoreAriaLabelGenerating ? getListItemAriaLabel(data, textList) : {};
    attr.className = classNames('card-wrapper', `-${type || 'basic'}`, {
        '-no-text': (textList || []).length === 0,
        '-has-error': !!data.error,
    });
    attr.title = data.tooltip || '';

    if (blank && url) {
        attr.target = 'blank';
    }

    if (data.dataTrackId) {
        attr['data-track-id'] = data.dataTrackId;
    }
    if (data.dataTrackLinkname) {
        attr['data-track-linkname'] = data.dataTrackLinkname;
    }

    if (style) {
        attr.style = style;
    }
    if (data.ariaLabel) {
        attr['aria-label'] = data.ariaLabel;
    }

    if (click && !data.blocked) {
        return (
            <a
                {...attr}
                href="#"
                role="button"
                onClick={(e: MouseEvent) => {
                    click(e, action || '', data);
                }}
            >
                {children}
            </a>
        );
    }
    return url ? (
        <a {...attr} href={url}>
            {children}
        </a>
    ) : (
        <div {...attr}>{children}</div>
    );
};

export default (props: Props): JSX.Element | null => {
    const {
        children,
        url = '',
        type = 'basic',
        data = {},
        click,
        action,
        noIcon,
        namespace,
        disabled,
        style,
        ignoreAriaLabelGenerating,
        blank,
    } = props;
    const { labelList, error, category, singleLine, tagList, ...rest } = data;
    const textList: any = [[data.title, data.subTextLineOne || data.textOne].filter((v) => !!v), data.subTextLineTwo]
        .concat((data.textList as string | string[] | string[][]) || data.text)
        .filter((d) => {
            return d instanceof Array ? d.filter((t) => !!t).length > 0 : !!d;
        });

    if (type === 'dropdownMenu') {
        return <DropdownMenu {...data} label={data.fieldLabel} error={error} click={click} action={action} />;
    }

    const classStyle = [type, category, namespace, rest.failed ? 'failed' : '', data.blocked ? '-blocked' : '']
        .filter((v) => !!v)
        .join(' -');
    if (type === 'rowButton' && click) {
        const firstRow = textList[0] instanceof Array ? textList[0] : [textList[0]];
        const secondRow = textList[1] instanceof Array ? textList[1] : [textList[1]];
        let name = typeof firstRow[0] === 'object' ? firstRow[0].text : firstRow[0];
        let amount = typeof firstRow[1] === 'object' ? firstRow[1].text : firstRow[1];
        const account = typeof secondRow[0] === 'object' ? secondRow[0].text : secondRow[0];
        const credit = typeof secondRow[1] === 'object' ? secondRow[1].text : secondRow[1];
        let label =
            textList.length && textList.join(' ') !== (data.placeholder || data.defaultText)
                ? data.fieldLabel
                : undefined;

        if (singleLine && name) {
            amount = name;
            name = label;
            label = undefined;
        }

        return (
            <div
                className={classNames('card-wrapper -row-button', classStyle, { '-single-line': !!singleLine })}
                style={style}
            >
                <RowButton
                    onClick={() => {
                        click(null, action || '', data);
                    }}
                    disabled={!!disabled || !!data.disabled}
                >
                    <RowButton.AccountSelectorLayout
                        placeholder={data.placeholder ?? data.defaultText}
                        label={label}
                        accountAlias={name}
                        amount={amount}
                        accountNumber={account}
                        credit={credit}
                        // @ts-ignore
                        icon={
                            (data.icon && IconTypes[data.icon]) ||
                            (noIcon || disabled || data.disabled ? undefined : IconTypes.LITEN_PIL_HOEYRE)
                        }
                        error={error}
                    />
                    {children}
                </RowButton>
            </div>
        );
    }

    return (
        <CardWrapper
            style={style}
            type={classStyle}
            url={data.url || url}
            blank={blank}
            click={click}
            action={action}
            data={data}
            textList={textList}
            ignoreAriaLabelGenerating={ignoreAriaLabelGenerating}
        >
            <>
                {(labelList || []).length > 0 && (
                    <div className="label-holder">
                        {(labelList || []).map((d: any, i: number) =>
                            d && (d.name || d.text) ? (
                                <InfoLabel
                                    key={d.id || `card-label-${i}`}
                                    className={`-${d.type || 'basic'}`}
                                    text={d.name || d.text}
                                    type={d.large ? undefined : LabelTypes.INFO_SMALL}
                                    icon={d.type === 'dispose' ? IconTypes.UNG_24 : undefined}
                                    {...(d.ariaLabel ? { 'aria-label': data.ariaLabel } : {})}
                                />
                            ) : null
                        )}
                    </div>
                )}

                {!!data.fieldLabel && <div className="field-label">{data.fieldLabel}</div>}
                {!!tagList && <FlexTextHolder text={tagList} />}

                {data.rigthContent ? (
                    <ul className="card-container-with-right-content">
                        <li>
                            <FlexTextHolder text={textList} />
                        </li>
                        <li>
                            {data.rigthContent.type === 'return-pie' && (
                                <ReturnPie {...(data.rigthContent.config || {})} />
                            )}

                            {data.rigthContent.type === 'span' && (
                                <span
                                    title={data.rigthContent.config.title || ''}
                                    className={`right-content-item -${data.rigthContent.config.namespace || 'item'}`}
                                >
                                    {data.rigthContent.config.value || data.rigthContent.config.text}
                                </span>
                            )}

                            {data.rigthContent.type === 'a' && data.rigthContent.config.url && (
                                <a
                                    href={data.rigthContent.config.url}
                                    title={data.rigthContent.config.title || ''}
                                    className={`right-content-item -${data.rigthContent.config.namespace || 'item'}`}
                                >
                                    {data.rigthContent.config.value || data.rigthContent.config.text}
                                </a>
                            )}
                        </li>
                    </ul>
                ) : (
                    <FlexTextHolder text={textList} />
                )}
                {children}

                {!!error && <ErrorLabel aria-live="polite" icon={IconTypes.UTROPSTEGN_24} text={error} />}
            </>
        </CardWrapper>
    );
};
