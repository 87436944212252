import { shape, string, bool, number, object, arrayOf } from 'prop-types';

const pensjonsrettighetDtoType = shape({
    produktbetegnelse: string,
    innskuddssaldo: number,
    avtalenummer: string,
    polisenummer: string,
    avtaleinnhold: object,
    firmanavn: string,
    id: number,
});

const textsType = object;
const gradientType = object;

export const pensjonErrorType = shape({
    message: string,
    name: string,
    acknowledged: bool,
});

export const pensjonsDataPropType = shape({
    differanseFraDagensLoenn: number,
    folketrygdPerAar: number,
    individuelleOrdningerPerYear: number,
    offentligTjenestepensjonPerYear: number,
    pensjonIProsentAvDagensLoenn: number,
    pensjonPeriodeIAar: number,
    privatAFPPerYear: number,
    privatTjenestePensjonPerAar: number,
    sumEgenSparingIFond: number,
    sumEikaGPSSparing: number,
    sumFastSparingPrMnd: number,
    sumIPASparing: number,
    sumIndividuellPensjonsSparing: number,
    totalForventetPensjonPerAar: number,
    totalForventetPensjonPerMnd: number,
});

export const spareProfilConfigPropType = shape({
    maximumsAlder: number,
    minimumsAlder: number,
});

export default shape({
    texts: textsType,
    gradient: gradientType,
    tittel: string,
    avtaler: arrayOf(pensjonsrettighetDtoType),
});
