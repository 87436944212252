import { get, post } from "@eika-infrastruktur/request";
import { getAppName } from '../common/util/BusinessFunctions';

export const SET_EPK_MOVE_STATUS = 'SET_EPK_MOVE_STSTUS';
export const SET_EPK_SIGNING_CONFIG = 'SET_EPK_SIGNING_CONFIG';
export const SET_EPK_SIGNING_CANCEL = 'SET_EPK_SIGNING_CANCEL';
export const SET_EPK_MOVE_DATA = 'SET_EPK_MOVE_DATA';
export const SET_MOVE_EPK = 'SET_MOVE_EPK';
export const SET_EPK_RISK_PROFILE = 'SET_EPK_RISK_PROFILE';
export const SET_DEVELOPMENT_HISOTORY_GRAPH = 'SET_DEVELOPMENT_HISOTORY_GRAPH';

export const getEPKmoveData = (callback) => async (dispatch) => {
    let result = null;

    const url = '/sparing-min-side-web/rest/resource/epk/ready-for-move';
    try {
        result = await get( url ).catch( () => null );
    } catch ( error ) {
        result = null;
    }
    
    return typeof(callback) === 'function' ? 
        callback(result) : dispatch({ type: SET_EPK_MOVE_DATA, data: result });
};

export const setEPKmoveData = ( data ) => async (dispatch) => {
    return dispatch({ type: SET_EPK_MOVE_DATA, data});
}

/******************************************************************************
******************************************************************************/
export const postMoveEPK = (data, callback) => async (dispatch) => {
    let result = null;
    if ( !data ) {
        return typeof(callback) === 'function' ? 
            callback(result) : dispatch({ type: SET_MOVE_EPK, data: result });        
    }

    const body = {...data, channel: getAppName()};
    const url = `/sparing-min-side-web/rest/resource/epk/signing/move-epk`;
    try {
        result = await post(url, body).catch( () => null );
    } catch ( error ) {
        result = null;
    }
    
    return typeof(callback) === 'function' ? 
        callback(result) : dispatch({ type: SET_MOVE_EPK, data: result });
};

/******************************************************************************
******************************************************************************/
export const changeEPKriskProfile = (riskProfileId, callback) => async (dispatch) => {
    let result = null;
    if ( !riskProfileId ) {
        return typeof(callback) === 'function' ? 
            callback(result) : dispatch({ type: SET_EPK_RISK_PROFILE, data: result });        
    }

    const body = {newRiskProfileId: riskProfileId, channel: getAppName()};
    const url = `/sparing-min-side-web/rest/resource/epk/signering/endrerisikoprofil`;
    try {
        result = await post(url, body).catch( () => null );
    } catch ( error ) {
        result = null;
    }
    
    return typeof(callback) === 'function' ? 
        callback(result) : dispatch({ type: SET_EPK_RISK_PROFILE, data: result });
};


/******************************************************************************
******************************************************************************/
export const getDevelopmentHistoryGraph = (data, callback) => async (dispatch) => {
    let result = [];
    const config = {portfolioValue: 1000, inflationAdjust: false, channelId: '6504', rebalance: false, ...(data || {})};
    const url = `/sparing-min-side-web/rest/resource/history/portfolio/historicDevelopment`;
    const riskList = [{
        ...config,
        duration: 1,
        risk: 3.5000000000000004, // LAV or FORSIKTIG
    }, {
        ...config,
        duration: 3,
        risk: 10.500000000000002, // MODERAT
    }, {
        ...config,
        duration: 6,
        risk: 24.000000000000004, // LiVSSYKLUS or HOY,
    }];

    try {
        result = await Promise.all([
            post(url, riskList[0]).catch( () => null ),
            post(url, riskList[1]).catch( () => null ),
            post(url, riskList[2]).catch( () => null ),
        ]);
    } catch ( error ) {
        result = null;
    }

    return typeof(callback) === 'function' ? 
        callback(result) : dispatch({ type: SET_DEVELOPMENT_HISOTORY_GRAPH, data: result });
};

export const setDevelopmentHistoryGraph = ( data ) => async (dispatch) => {
    return dispatch({ type: SET_DEVELOPMENT_HISOTORY_GRAPH, data});
}

/******************************************************************************
******************************************************************************/
export const cancelEPKsigning = ( signingId, callback) => async (dispatch) => {
    let result = null;
    if ( !signingId ) {
        return typeof(callback) === 'function' ?
            callback(result) : dispatch({ type: SET_EPK_SIGNING_CANCEL, data: result });        
    }

    const url = `/sparing-min-side-web/rest/resource/epk/signing/cancel/${signingId}`;
    try {
        result = await post(url, {id: signingId}).catch( () => null );
    } catch ( error ) {
        result = null;
    }
    
    return typeof(callback) === 'function' ?
        callback(result) : dispatch({ type: SET_EPK_SIGNING_CANCEL, data: result });
};

/******************************************************************************
******************************************************************************/
export const getEPKsigningConfig = ( callback ) => async (dispatch) => {
    let result = null;

    const url = `/sparing-min-side-web/rest/resource/epk/signing/last/config`;
    try {
        result = await get( url ).catch( () => null );
    } catch ( error ) {
        result = null;
    }

    return typeof(callback) === 'function' ?
        callback(result) : dispatch({ type: SET_EPK_SIGNING_CONFIG, data: result });    
};

export const setEPKsigningConfig = ( data ) => async (dispatch) => {
    return dispatch({ type: SET_EPK_SIGNING_CONFIG, data});
}

/******************************************************************************
******************************************************************************/
export const getEPKmoveStatus = ( callback ) => async (dispatch) => {
    let result = null;

    const url = `/sparing-min-side-web/rest/resource/epk/move-status`;
    try {
        result = await get( url ).catch( () => null );
    } catch ( error ) {
        result = null;
    }

    return typeof(callback) === 'function' ?
        callback(result) : dispatch({ type: SET_EPK_MOVE_STATUS, data: result });    
};


/******************************************************************************
******************************************************************************/

const initialState = {
    signingConfig: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_EPK_RISK_PROFILE:
            return { ...state, changeEPKriskProfile: action.data };
        case SET_MOVE_EPK:
            return { ...state, moveEPK: action.data };
        case SET_DEVELOPMENT_HISOTORY_GRAPH:
            return { ...state, historyGraph: action.data };
        case SET_EPK_MOVE_DATA:
            return { ...state, epkData: action.data };
        case SET_EPK_SIGNING_CANCEL:
            return { ...state, signingCancel: action.data };
        case SET_EPK_SIGNING_CONFIG:
            return { ...state, signingConfig: action.data };
        case SET_EPK_MOVE_STATUS:
            return { ...state, moveStatus: action.data };
        default:
            return state;
    }
};
