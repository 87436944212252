import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import { hentForventetPensjon, beregnPensjonVedOktMndSparing, acknowledgeError } from '../../actions/pensjon';
import { endreKundeValg } from '../../actions/spareprofil';
import OmBeregningene from '../../components/vilkaar/om-beregningene';
import PensjonsKalkulator from '../../components/pensjon/pensjons-kalkulator';
import SparingPerMaanedSlider from '../../components/pensjon/sparing-per-maaned-slider';
import HelperFunctions from '../../components/helper-functions';
import { settSideTittel } from '../../actions/header';
import Feilmelding from '../../components/feilmelding';
import AppMessage from '../../components/AppMessage';
import ExpandableCard from '../../components/expandable-card';
import {
    pensjonsDataPropType,
    spareProfilConfigPropType,
    pensjonErrorType,
} from '../../domain/prop-types/pensjonsPropTypes';
import Modal from '../../components/modal';
import OverskredetAntallKall from '../../components/pensjon/overskredet-antall-kall';

require('../../styles/pensjonsKalkulator.scss');

class FremtidigVerdi extends Component {
    static propTypes = {
        texts: PropTypes.object,
        pensjonsData: pensjonsDataPropType,
        config: spareProfilConfigPropType,
        pensjonsvalg: PropTypes.object,
        nyPensjonVedOktSparing: PropTypes.number,
        hentForventetPensjon: PropTypes.func,
        beregnPensjonVedOktMndSparing: PropTypes.func,
        settSideTittel: PropTypes.func,
        endreKundeValg: PropTypes.func,
        errors: PropTypes.object,
        kundevalg: PropTypes.object,
        lasterPensjonsEstimater: PropTypes.bool,
        kundeInfo: PropTypes.object,
        beregnetPensjonError: pensjonErrorType,
        acknowledeError: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const minAge = props.kundeInfo?.alder && props.kundeInfo?.alder > props.config.minimumsAlder ?
              props.kundeInfo?.alder : props.config.minimumsAlder;
        const onsketPensjonsAlder = props.pensjonsvalg?.pensjonsAlder || 67;
        const defaultOnsketPensjonsAlder =  onsketPensjonsAlder > minAge ?  onsketPensjonsAlder : minAge;

        this.state = {
            onsketPensjonsAlder: defaultOnsketPensjonsAlder,
            defaultBruttoAarsInntekt: 0,
            oktMndSparing: 2000,
            disclaimers: [
                {
                    id: 0,
                    expanded: false,
                    onExpand: this.onExpand,
                },
            ],
            config: {
                ...props.config,
                minimumsAlder: minAge,
            }
        };
    }

    onExpand = (id) => {
        const { disclaimers } = this.state;
        this.setState({
            disclaimers: disclaimers.map((d) => {
                return { ...d, expanded: d.id === id ? !d.expanded : false };
            }),
        });
    };

    componentDidMount() {
        const { settSideTittel, texts } = this.props;
        settSideTittel(texts.get('pensjonskalulatorTittel'));
        this.beregnPensjonBasertPaaOnsketPensjonsAlderOgBruttoInntekt();
    }

    beregnPensjonBasertPaaOnsketPensjonsAlderOgBruttoInntekt = () => {
        const { onsketPensjonsAlder, defaultBruttoAarsInntekt, oktMndSparing } = this.state;
        const { pensjonsvalg, kundevalg, beregnPensjonVedOktMndSparing, hentForventetPensjon } = this.props;

        if (pensjonsvalg && kundevalg) {
            const bruttoAarsInntekt = pensjonsvalg.bruttoInntekt || defaultBruttoAarsInntekt;
            this.setState({
                inkluderFond: kundevalg.pensjonskalkulatorIncludeFondssparing,
                onsketPensjonsAlder,
                bruttoAarsInntekt,
                bruktBruttoAarsInntektTilBeregning: bruttoAarsInntekt
            });

            beregnPensjonVedOktMndSparing(onsketPensjonsAlder, oktMndSparing);
            hentForventetPensjon(
                onsketPensjonsAlder,
                bruttoAarsInntekt,
                kundevalg.pensjonskalkulatorIncludeFondssparing
            );
        }
    };

    endreInkluderFond = (id, inkluderFond) => {
        const { endreKundeValg } = this.props;
        endreKundeValg({ [id]: inkluderFond });
    };

    endreOnsketPensjonsAlder = (onsketPensjonsAlder) => {
        this.setState({ onsketPensjonsAlder });
    };

    endreBruttoAarsInntekt = (event) => {
        const value = event.target.value ? event.target.value : 0;
        const bruttoAarsInntekt = HelperFunctions.clamp(value, 0, 9999999);
        this.setState({ bruttoAarsInntekt });
    };

    hentForventetPensjon = () => {
        const { onsketPensjonsAlder, bruttoAarsInntekt, inkluderFond } = this.state;
        const { hentForventetPensjon } = this.props;
        this.setState({bruktBruttoAarsInntektTilBeregning: bruttoAarsInntekt });
        hentForventetPensjon(onsketPensjonsAlder, bruttoAarsInntekt, inkluderFond);
    };

    endreMndSparing = (oktMndSparing) => {
        const formatertOktMndSparing = parseInt(oktMndSparing, 10);
        this.setState({ oktMndSparing: formatertOktMndSparing });
    };

    beregnPensjonVedOktMndSparing = () => {
        const { onsketPensjonsAlder, oktMndSparing } = this.state;
        const { beregnPensjonVedOktMndSparing } = this.props;
        beregnPensjonVedOktMndSparing(onsketPensjonsAlder, oktMndSparing);
    };

    sjekkForServerFeil = (errors) => {
        return errors.HENT_FORVENTET_PENSJONSTALL || errors.LAST_SPARE_PROFIL_DATA;
    };

    render() {
        const { onsketPensjonsAlder, bruttoAarsInntekt, oktMndSparing, disclaimers, bruktBruttoAarsInntektTilBeregning, config } = this.state;
        const {
            errors,
            texts,
            pensjonsData,
            nyPensjonVedOktSparing,
            kundevalg,
            lasterPensjonsEstimater,
            kundeInfo,
            beregnetPensjonError,
            acknowledeError,
        } = this.props;
        if (this.sjekkForServerFeil(errors)) {
            return <Feilmelding feil={errors} texts={texts} />;
        }

        const showModal = beregnetPensjonError ? !beregnetPensjonError.acknowledged : false;
        const pensjonsDataOrError = !!pensjonsData || !!beregnetPensjonError;
        if (pensjonsDataOrError && (nyPensjonVedOktSparing === 0 || nyPensjonVedOktSparing) && config && kundevalg) {
            return (
                <div className="pensjons-kalkulator">
                    <AppMessage appMessage={this.props.serviceMessage} context="PENSION_CALCULATOR" />

                    <Modal visModal={showModal} lukkModal={acknowledeError}>
                        <OverskredetAntallKall onClick={acknowledeError} texts={texts} />
                    </Modal>
                    <PensjonsKalkulator
                        texts={texts}
                        pensjonsData={pensjonsData}
                        endreBruttoAarsInntekt={this.endreBruttoAarsInntekt}
                        endreOnsketPensjonsAlder={this.endreOnsketPensjonsAlder}
                        onsketPensjonsAlder={onsketPensjonsAlder}
                        endreInkluderFond={this.endreInkluderFond}
                        bruttoAarsInntekt={bruttoAarsInntekt}
                        bruktBruttoAarsInntektTilBeregning={bruktBruttoAarsInntektTilBeregning}
                        config={config}
                        pensjonskalkulatorIncludeFondssparing={kundevalg.pensjonskalkulatorIncludeFondssparing}
                        lasterPensjonsEstimater={lasterPensjonsEstimater}
                        hentForventetPensjon={this.hentForventetPensjon}
                    />
                    <SparingPerMaanedSlider
                        texts={texts}
                        bruttoAarsInntekt={bruttoAarsInntekt}
                        pensjonPerMnd={pensjonsData ? pensjonsData.totalForventetPensjonPerMnd : 0}
                        nyPensjonVedOktSparing={nyPensjonVedOktSparing}
                        endreMndSparing={this.endreMndSparing}
                        beregnPensjonVedOktMndSparing={this.beregnPensjonVedOktMndSparing}
                        oktMndSparing={oktMndSparing}
                        kundeInfo={kundeInfo}
                    />
                    <ExpandableCard header={texts.get('omBeregningene')} expandItem={disclaimers[0]}>
                        <OmBeregningene />
                    </ExpandableCard>
                </div>
            );
        }
        return <Loading title={texts.get('lasterPensjonsApp')} />;
    }
}

const mapStateToProps = (state) => ({
    ...state.Pensjon,
    ...state.SpareProfil,
    ...state.Feilmeldinger,
    ...state.Texts,
    ...state.Header,
});

const mapDispatchToProps = (dispatch) => {
    return {
        hentForventetPensjon: (alder, brutto, inkluderFond) => {
            dispatch(hentForventetPensjon(alder, brutto, inkluderFond));
        },
        beregnPensjonVedOktMndSparing: (alder, oktMndSparing) => {
            dispatch(beregnPensjonVedOktMndSparing(alder, oktMndSparing));
        },
        endreKundeValg: (valg) => {
            dispatch(endreKundeValg(valg));
        },
        settSideTittel: (sideTittel) => {
            dispatch(settSideTittel(sideTittel));
        },
        acknowledeError: () => {
            dispatch(acknowledgeError());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FremtidigVerdi);
