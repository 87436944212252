import React from 'react';
import Sprouts from '../Sprouts/Sprouts';
import { StringObject } from '../../domain/Types';
import './FlexTextHolder.scss';

export interface FlexText {
    text?: string | string[];
    name?: string;
    title?: string;
    type?: string;
    namespace?: string;
    style?: StringObject;
    ariaLabel?: string;
    ariaLabelledby?: string;
    role?: string;
    id?: string;
    'data-text'?: string;
    element?: JSX.Element | null;
    innerElement?: boolean;
    includeHtmlElement?: boolean;
}

interface Props {
    text: any;
    namespace?: string;
    spanType?: string;
    maxRow?: number;
    minRow?: number;
}

const FlexTextSpan = ({ text }: { text: string | string[] | FlexText | FlexText[] }) => {
    const data = typeof text === 'object' ? text : { text };
    if (data instanceof Array) {
        return (
            <span className="inner-text">
                {data.map((t: string | string[] | FlexText, i: number) => {
                    return <FlexTextSpan key={`inner-text-${i}`} text={t} />;
                })}
            </span>
        );
    }

    if (data.element) {
        return data.element;
    }

    const attrList: string[] = ['id', 'ariaLabel', 'ariaLabelledby', 'title', 'data-text'];
    const attr: StringObject = attrList.reduce((p: StringObject, k: string) => {
        if (!!p && data[k as keyof FlexText] !== undefined) {
            const key = k.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
            // @ts-ignore
            p[key] = data[k as keyof FlexText];
        }
        return p;
    }, {});

    if (data.type === 'sprouts') {
        const active = parseInt(`${data.text || 0}`, 10);
        return (
            <span {...attr} className={`text -${data.type || 'normal'}`} style={data.style || {}}>
                <Sprouts max={5} active={active} />
            </span>
        );
    }

    return (
        <span {...attr} className={`text -${data.type || 'normal'}`} style={data.style || {}}>
            {data.text instanceof Array ? (
                <FlexTextSpan text={data.text} />
            ) : (
                <>{data.innerElement ? <i>{data.text || data.name || ''}</i> : <>{data.text || data.name || ''}</>}</>
            )}
        </span>
    );
};

export default ({ text, maxRow, minRow, spanType, namespace }: Props): JSX.Element | null => {
    if (!text) {
        return null;
    }
    const list = text instanceof Array ? text : [text];
    if (minRow && list.length < minRow) {
        Array.from({ length: minRow - list.length }).forEach(() => {
            list.push({ text: ' ', type: 'white-space' });
        });
    }

    return (
        <>
            {list.map((src, i) => {
                if (maxRow && i > maxRow) {
                    return null;
                }

                const key = `flex-text-${i}`;
                return spanType ? (
                    <span
                        key={key}
                        className={`flex-text-holder -${spanType} -index-${i} ${
                            src instanceof Array ? `-length-${src.length}` : '-basic'
                        } ${namespace || ''}`}
                    >
                        {(src instanceof Array ? src : [src]).map((t, j) => {
                            return <FlexTextSpan key={`${key}-${j}`} text={t} />;
                        })}
                    </span>
                ) : (
                    <div
                        key={key}
                        className={`flex-text-holder -index-${i} ${
                            src instanceof Array ? `-length-${src.length}` : '-basic'
                        } ${namespace || src.namespace || ''}`}
                    >
                        {(src instanceof Array ? src : [src]).map((t, j) => {
                            return <FlexTextSpan key={`${key}-${j}`} text={t} />;
                        })}
                    </div>
                );
            })}
        </>
    );
};
