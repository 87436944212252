/**
 * Created by vagrant on 01.03.17.
 */

import React from 'react';
import PropTypes from 'prop-types';
import StandaloneExpandableCard from '../common/standalone-expandable-card';

const PrestasjonsGrafDisclaimer = props => (
    <StandaloneExpandableCard header={props.texts.get('prestasjonsGrafDisclaimerTittel')}>
        <div className="vilkaar-tekst">
            <p>
                Historisk avkastning er ingen garanti for fremtidig avkastning. Fremtidig avkastning vil blant annet
                avhenge av markedsutviklingen forvalterens dyktighet, fondets risikoprofil og tegnings- og
                forvaltningshonorar.
            </p>
            <p>Avkastningen kan bli negativ som følge av kurstap.</p>
        </div>
    </StandaloneExpandableCard>
);

PrestasjonsGrafDisclaimer.propTypes = {
    texts: PropTypes.object.isRequired
};

export default PrestasjonsGrafDisclaimer;
