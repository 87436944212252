import React, { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@eika/modal';
import { StandardButton } from '@eika/button';
import { Link } from '@eika/link';
import { IconTypes } from '@eika/icon';
import {RouteComponentProps} from "react-router";
import { formatAmount, generateId, getPageAppending } from '../../../../common/util/Functions';
import { usePrevious } from '../../../../common/util/Hook';
import Grid from '../../../../common/Grid/Grid';
import Card, { Props as CardProps } from '../../../../common/Card/Card';
import Message from '../../../../common/Message/Message';
import AppMessage from '../../../../common/AppMessage/AppMessage';
import { HeaderLink, Spinner } from '../../../../common/share/ShareComponents';
import Constant from '../../../../static/data/Constant';
import '../../Arbeidsgiver.scss';
import '../Storebrand.scss';
import { ContentData, WithNestedData } from '../types';
import safeArray from '../../util/SafeArray';
import {RouterConfig} from "../../../../app/Router";

type Props = RouteComponentProps<{ id?: string }> & RouterConfig

interface Content {
    totalValue?: number;
    product?: string;
    category?: string;
    riskProfile?: ContentData;
    portfolioBaseCode?: string[];
    detail?: any;
}

interface Transaction {
    order?: string[];
    pin: { [k: string]: any };
    hasMore: boolean;
}

interface State {
    appending: string;
    loading: boolean;
    now: number;
    modal: boolean;
    content?: Content;
    transaction?: Transaction;
    pendingTransaction?: Transaction;
}

// this has to be rewritten completely
// eslint-disable-next-line
const getProfileDetail = (title: string, type = 'Aksjefond'): any => {
    return {
        id: generateId('ST'),
        open: false,
        detail: {
            title,
            price: [
                {
                    data: {
                        textList: [
                            [
                                { text: 'Fordeling', type: 'font-normal' },
                                { text: '75%', type: '' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                { text: 'Total beholdning', type: 'font-normal' },
                                { text: '200 000 kr', type: '' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                { text: 'Fondtype', type: 'font-normal' },
                                { text: type, type: '' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                { text: 'Kostnader', type: 'font-normal' },
                                { text: 'Betales av arbeidsgiver', type: '' },
                            ],
                        ],
                    },
                },
            ],
        },
    };
};

const getContent = (props: Props, state: State, setState: (s: State) => void, reducer: any): void => {
    const content: Content = { ...(state.content ?? {}) };
    const { arbeidsgiversPensjonsSparing = [] } = reducer.Pensjon ?? {};
    if (content.riskProfile) {
        return;
    }

    const id = props.match?.params?.id;
    const data = arbeidsgiversPensjonsSparing.find((d: any) => d.agreementNumber === id);

    if (data) {
        content.riskProfile = {
            title: 'Eika 100 % Aksjer',
            text: 'Du har profilen Eika 100 % Aksjer. Når det nærmer seg pensjonsalder, vil risikoen på din pensjon trappes ned gradvis over 10 år.',
            note: [
                'Om Eika 100 % Aksjer',
                'Eika 100 % Aksjer er en spareprofil hvor sparemidlene plasseres i aksje- og rentefond forvaltet av Eika Kapitalforvaltning AS.',
                'Gjennom profilen vil man være investert i 100 % aksjefond.',
                'Andel aksjefond trappes ned gradvis frem til 60 år og blir 60 % rentefond og 40 % aksjefond fra og med 67 år.',
            ],
            detail: [
                {
                    data: {
                        textList: [
                            [
                                { text: 'Nedtrapping', type: 'font-normal' },
                                { text: 'Ja', type: '' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                {
                                    text: 'Total beholdning',
                                    type: 'font-normal',
                                },
                                { text: formatAmount(data.balance || 0, true), type: '' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                { text: 'Fondtype', type: 'font-normal' },
                                { text: 'Aksjefond', type: '' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                { text: 'Kostnader', type: 'font-normal' },
                                { text: 'Betales av arbeidsgiver', type: '' },
                            ],
                        ],
                    },
                },
            ],
            // list: [ getProfileDetail('Eika Spar'), getProfileDetail('Eika Rente+', 'Rentefond')  ],
        };
    }

    setState({ ...state, content, loading: false });
};

const toggleFund = (state: State, setState: (s: State) => void, id: string): void => {
    if (!state.content?.riskProfile) {
        return;
    }

    const riskProfile = JSON.parse(JSON.stringify(state.content.riskProfile));
    riskProfile.list.forEach((profile: any) => {
        profile.open = profile.id === id ? !profile.open : false;
    });
    setState({ ...state, content: { ...state.content, riskProfile } });
};

const click = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    e: MouseEvent | null,
    key = '',
    data?: any
): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if (key === 'toggle-profile-info') {
        setState({ ...state, modal: !state.modal });
    } else if (key === 'toggle-fund' && data?.id) {
        toggleFund(state, setState, data.id);
    }
};

const SavingProfileDetailsPage = (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Pensjon', 'Storebrand'].reduce((p: { [k: string]: any }, k: string) => {
            return { ...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const [state, setState] = useState<State>({
        loading: true,
        modal: false,
        now: new Date().getTime(),
        appending: getPageAppending(props),
    });

    const previousReducer = usePrevious(reducer);
    useEffect(() => {
        const ignore =
            !!state.content ||
            !reducer.Pensjon?.arbeidsgiversPensjonsSparing ||
            JSON.stringify(reducer) === JSON.stringify(previousReducer);
        if (ignore) {
            return;
        }
        getContent(props, state, setState, reducer);
    }, [previousReducer, reducer, state, props]);

    return (
        <div className="storebrand-risk-profile-wrapper">
            <Grid
                namespace="sparing-top"
                list={[
                    {
                        layout: 'twelve',
                        element: <HeaderLink text="Eika Innskuddspensjon" />,
                    },
                ]}
            />

            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: (
                            <>
                                <h1>Om din risk-profile</h1>
                            </>
                        ),
                    },
                ]}
            />

            <div
                className={`arbeidsgiver-profil-common-wrapper storebrand-risk-profile-content ${
                    state.appending || 'none-appending'
                }`}
            >
                <Grid
                    namespace="sparing-content"
                    list={[
                        {
                            layout: 'seven',
                            element: (
                                <>
                                    <AppMessage
                                        appMessage={reducer?.App?.serviceMessage}
                                        context="STOREBRAND_RISK_PROFILE"
                                    />

                                    {!!state.content?.riskProfile && (
                                        <div className="paragraph">
                                            <Message
                                                type="info-message"
                                                namespace="risk-profile-info-message"
                                                titleType="div"
                                                role=""
                                                {...(state.content.riskProfile as WithNestedData)}
                                            >
                                                <div className="paragraph-large -only-top center">
                                                    <StandardButton
                                                        onClick={(e: MouseEvent) => {
                                                            click(props, state, setState, e, 'toggle-profile-info');
                                                        }}
                                                    >
                                                        {props.appTexts.get('changeRiskProfile')}
                                                    </StandardButton>
                                                </div>
                                            </Message>

                                            <section className="storebrand-risk-profile-note-holder">
                                                {safeArray((state.content.riskProfile as WithNestedData)?.note).map(
                                                    (t: string, i: number) => (
                                                        <div key={`note-${i}`} className="note-item paragraph">
                                                            {t}
                                                        </div>
                                                    )
                                                )}
                                            </section>

                                            {safeArray((state.content.riskProfile as WithNestedData).list).map(
                                                (profile: any) => {
                                                    return (
                                                        <div
                                                            key={profile.id}
                                                            className="storebrand-risk-profile-item-wrappe paragraph -only-top"
                                                        >
                                                            <div
                                                                className={`risk-profile-wrapper -expander -${
                                                                    profile.open ? 'open' : 'close'
                                                                }`}
                                                            >
                                                                <section className="header-section" aria-label="Risiko">
                                                                    <div className="header-section-content">
                                                                        <div>
                                                                            <h2>{profile.detail.title || ''}</h2>
                                                                            <p>Se detaljer om fondet</p>
                                                                        </div>
                                                                        <div>
                                                                            <a
                                                                                href="#"
                                                                                role="button"
                                                                                className="expander-btn"
                                                                                onClick={(e) => {
                                                                                    click(
                                                                                        props,
                                                                                        state,
                                                                                        setState,
                                                                                        e,
                                                                                        'toggle-fund',
                                                                                        profile
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <span className="aria-visible">
                                                                                    {profile.open
                                                                                        ? 'Lukke detaljer om risikoprofilen'
                                                                                        : 'Åpne detaljer om risikoprofilen'}
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                                <section
                                                                    className="description-section paragraph -only-top"
                                                                    aria-label="Beskrivelse"
                                                                >
                                                                    {(profile.detail.text || []).map(
                                                                        (config: CardProps, i: number) => (
                                                                            <Card
                                                                                key={`detail-text-${i}`}
                                                                                {...config}
                                                                                type="list-item"
                                                                            />
                                                                        )
                                                                    )}
                                                                </section>
                                                                <section
                                                                    className="price-section paragraph -only-top"
                                                                    aria-label="Priser"
                                                                >
                                                                    {(profile.detail.price || []).map(
                                                                        (config: CardProps, i: number) => (
                                                                            <Card
                                                                                key={`detail-price-${i}`}
                                                                                {...config}
                                                                                type="list-item"
                                                                            />
                                                                        )
                                                                    )}
                                                                </section>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}

                                    {state.loading && <Spinner type="linkPanel" />}
                                </>
                            ),
                        },
                        {
                            layout: 'four',
                            element: (
                                <>
                                    <div className="only-for-desktop_">
                                        {state.content?.riskProfile && (
                                            <section className="storebrand-risk-profile-detail-wrapper paragraph -only-top">
                                                {safeArray((state.content?.riskProfile as WithNestedData)?.detail).map(
                                                    (d: CardProps, i: number) => (
                                                        <Card key={`increasing-item-${i}`} {...d} type="list-item" />
                                                    )
                                                )}
                                            </section>
                                        )}
                                        {state.loading && <Spinner type="linkPanel" />}
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </div>

            <Modal
                id="storebrand-risk-profile-modal"
                appNamespace="sparing-react-app"
                onClose={() => {
                    setState({ ...state, modal: false });
                }}
                show={state.modal}
            >
                <Message
                    type="error-hand"
                    role=""
                    title={props.appTexts.get('changeRiskProfile')}
                    text={props.appTexts.get('storebrandChangeRiskProfileDescription')}
                >
                    <div className="paragraph-small -only-top center">
                        <Link
                            icon={IconTypes.EKSTERN_24}
                            href={Constant.urlStorebrandLogin}
                            target="blank"
                            iconOnRightSide
                        >
                            {props.appTexts.get('goTo', { name: 'Storebrand' })}
                        </Link>
                    </div>
                </Message>
            </Modal>
        </div>
    );
};

export default SavingProfileDetailsPage;
