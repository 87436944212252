import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from './card';

require('../styles/modal.scss');

class Modal extends Component {
    static propTypes = {
        visModal: PropTypes.bool,
        lukkModal: PropTypes.func,
        children: PropTypes.object,
        namespace: PropTypes.string,
    };

    visLukkKnapp = () => {
        if (this.props.lukkModal) {
            return (
                <button type="button" className="close-modal" onClick={this.props.lukkModal}>
                    <i className="ikon-lukk-24" />
                </button>
            );
        }
        return '';
    };

    render() {
        const modalVisibility = {
            display: this.props.visModal ? 'block' : 'none'
        };

        return (
            <div className={`min-side-modal -${this.props.namespace || 'normal'}`} style={modalVisibility}>
                <div className="backdrop" />
                <Card>
                    <div>
                        {this.props.children}
                        {this.visLukkKnapp()}
                    </div>
                </Card>
            </div>
        );
    }
}

export default Modal;
