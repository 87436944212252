import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';

import FondDetaljer from './fond/fond-detaljer';
import PortefoljeOversikt from './fond/PortefoljeOversikt';
import KjopFond from './fond/kjop-fond/kjop-fond';
import KjopFondInnganger from './fond/kjop-fond/kjop-fond-innganger';

import App from './app';

import SparingSammendrag from './sparing/sparing-sammendrag';
import ArbeidsgiversPensjonssparing from './sparing/arbeidsgivers-pensjonssparing';
import ArbeidsgiverRisikoprofil from './sparing/ArbeidsgiverRisikoprofil';
import MinBankSparing from './sparing/min-bank-sparing';
import SpareProfil from './spareprofil/spare-profil';
import OmMeg from './spareprofil/om-meg';
import Samtykker from './spareprofil/samtykke-tekst';
import MinPensjon from './spareprofil/min-pensjon';
import FremtidigVerdi from './pensjon/fremtidig-verdi';
import KlientKonto from './fond/klient-konto';
import IndividuellPensjonsSparing from './fond/individuell-pensjons-sparing';
import IpsAvtale from './spareprofil/ips-avtale';
import Nominee from './nominee/nominee';
import AvtaleDetaljer from './sparing/spare-avtaler/avtale-detaljer';
import AvtaleRisiko from './sparing/spare-avtaler/avtale-risiko';
import AvtaleOversikt from './sparing/spare-avtaler/avtale-oversikt';

import DetaljerSparemaal from './sparemaal/detaljer-sparemaal';
import OpprettSparemaal from './sparemaal/opprett-sparemaal';
import OversiktSparemaal from './sparemaal/oversikt-sparemaal';
import RedigerSparemaal from './sparemaal/rediger-sparemaal';
import Sparemiks from './sparemaal/sparemiks';
import KjopFondVeiviser from './fond/kjop-fond/kjop-fond-veiviser';
import SisteTransaksjoner from './fond/siste-transaksjoner';
import VentendeTransaksjoner from './fond/ventende-transaksjoner';
import FondsOversiktController from './fond/fonds-oversikt-controller';
import DisponertFondOversiktController from './fond/disponert-fond-oversikt-controller';

export default (ownProps) => {
    return (
        <HashRouter basename="/">
            <App {...ownProps}>
                <Switch>
                    <Route exact path="/" component={(props) => <SparingSammendrag {...ownProps} {...props} />} />
                    <Route
                        exact
                        path="/sparemaal"
                        component={(props) => <OversiktSparemaal {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/sparemaal/:id/rediger"
                        component={(props) => <RedigerSparemaal {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/sparemaal/opprett"
                        component={(props) => <OpprettSparemaal {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/sparemaal/:id"
                        component={(props) => <DetaljerSparemaal {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/sparemaal/:id/sparemiks"
                        component={(props) => <Sparemiks {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/sparemaal/kjopfond/:sparemaalId"
                        component={(props) => <KjopFondInnganger {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/sparemaal/kjopfond/:sparemaalId/:fondIsin"
                        component={(props) => <KjopFond {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/arbeidsgiversSparing/avtale/:id"
                        component={(props) => <AvtaleOversikt {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/arbeidsgiversSparing/avtale/:id/detaljer"
                        component={(props) => <AvtaleDetaljer {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/arbeidsgiversSparing/avtale/:id/risiko"
                        component={(props) => <AvtaleRisiko {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/arbeidsgiversSparing/:riskprofile"
                        component={(props) => <ArbeidsgiverRisikoprofil {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/nominee/:portefoljeId/:fondIsin"
                        component={(props) => <Nominee {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/individuellpensjonssparing"
                        component={(props) => <IndividuellPensjonsSparing {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/portefolje/:portefoljeId"
                        component={(props) => <FondsOversiktController {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/disponertePortefolje/:portefoljeId"
                        component={(props) => <DisponertFondOversiktController {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/klientkonto/:portefoljeId"
                        component={(props) => <KlientKonto {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/fond/sistetransaksjoner"
                        component={(props) => <SisteTransaksjoner {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/fond/ventendetransaksjoner/:portefoljeId/:fondIsin"
                        component={(props) => <VentendeTransaksjoner {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/fond/:portefoljeId/:fondIsin"
                        component={(props) => <FondDetaljer {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/kjopFond"
                        component={(props) => <KjopFondInnganger {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/kjopFond/portefolje/:portefoljeId"
                        component={(props) => <KjopFondInnganger {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/kjopFond/:fondIsin/:portefoljeId"
                        component={(props) => <KjopFond {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/kjopFondSparemaal/:fondIsin/:monthlyDeposit"
                        component={(props) => <KjopFond {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/kjopFondSparemaal/:fondIsin"
                        component={(props) => <KjopFond {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/kjopFond/:fondIsin"
                        component={(props) => <KjopFond {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/kjopFond/fond/alle"
                        component={(props) => <KjopFondInnganger {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/veiviser/kjopFond"
                        component={(props) => <KjopFondVeiviser {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/veiviser/kjopFond/fond/alle"
                        component={(props) => <KjopFondInnganger {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/veiviser/kjopFond/:portefoljeId"
                        component={(props) => <KjopFondVeiviser {...ownProps} {...props} />}
                    />
                    <Route exact path="/fond" component={(props) => <PortefoljeOversikt {...ownProps} {...props} />} />
                    <Route
                        exact
                        path="/arbeidsgiversSparing"
                        component={(props) => <ArbeidsgiversPensjonssparing {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/minbanksparing"
                        component={(props) => <MinBankSparing {...ownProps} {...props} />}
                    />
                    <Route exact path="/spareprofil" component={(props) => <SpareProfil {...ownProps} {...props} />} />
                    <Route exact path="/spareprofil/ommeg" component={(props) => <OmMeg {...ownProps} {...props} />} />
                    <Route
                        exact
                        path="/spareprofil/ipsavtale"
                        component={(props) => <IpsAvtale {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/spareprofil/minpensjon"
                        component={(props) => <MinPensjon {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/spareprofil/samtykker"
                        component={(props) => <Samtykker {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/pensjonsKalkulator"
                        component={(props) => <FremtidigVerdi {...ownProps} {...props} />}
                    />
                </Switch>
            </App>
        </HashRouter>
    );
};
