// @ts-nocheck // kiet fix it later
import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { generateId, createRegexp } from '../Functions';

import './Suggestion.scss';

const verifySearchFilter = (filter, data, searchKeys, innerField) => {
    if (!innerField) {
        innerField = ['name', 'text', 'type', 'id'];
    }

    const tmp = filter || [];
    const loop = tmp.length;
    if (!loop || !data || !(searchKeys || []).length) {
        return false;
    }

    let j = 0;
    let lookup = loop;
    const testObject = (obj, reg, fields) => {
        let verify = false;
        let k = 0;
        if (typeof obj === 'string') {
            verify = !!obj.match(reg);
        } else {
            while (verify === false && fields && fields[k]) {
                verify = !!`${obj[fields[k++]] || ''}`.match(reg);
            }
        }
        return verify;
    };

    for (j; j < loop; j++) {
        const orList = tmp[j];
        const pin = {};
        let h = 0;
        const orLength = (orList || []).length;
        let found = false;
        for (h; h < orLength; h++) {
            const note = orList[h];

            found = (note.searchKeys || searchKeys).find(key => {
                const reg = note.reg;
                const text = data[key] || '';
                let test = false;
                if (
                    pin[key] ||
                    !reg ||
                    !text ||
                    (note.key && note.key !== key && typeof text !== 'object' && !key.match(/tag/i))
                ) {
                    return test;
                }

                if (typeof text === 'object') {
                    const fields = note.key ? [note.key] : innerField;
                    if (text instanceof Array) {
                        for (let x = 0; x < text.length; x++) {
                            test = testObject(text[x], reg, fields);
                            if (test) {
                                x = text.length;
                            }
                        }
                    } else {
                        test = testObject(text, reg, fields);
                    }
                } else {
                    test = !!`${text || ''}`.match(reg);
                }

                if (test) {
                    pin[key] = 1;
                }
                return test;
            });

            if (found) {
                h = orLength;
            }
        }

        if (found) {
            lookup--;
            if (lookup === 0) {
                j = loop;
            }
        } else {
            j = loop;
        }
    }

    return lookup === 0;
};

const createSearchFilter = (value, keyChange, debug, ignorColon) => {
    if (!value) {
        return null;
    }

    const andList = [];
    const splited = value.split('&');
    for (let x = 0; x < splited.length; x++) {
        const v = (splited[x] || '').trim();
        let orList = [];
        if (!v) { continue; }

        const orSplit = v.split('|');
        if (orSplit.length) {
            for (let y = 0; y < orSplit.length; y++) {
                const t = (orSplit[y] || '').trim();
                if (!t) { continue; }

                let r = t;
                let k = '';
                const m = ignorColon ? [t] : t.split(':');
                let forceInserted = null;
                if (m.length > 1 && m[0]) {
                    k = (m.shift() || '').trim();
                    r = (m.join(':') || '').trim();

                    if (typeof keyChange === 'function') {
                        const config = keyChange(k, r, t, andList, orList);
                        if (config) {
                            k = config.k;
                            r = config.r;

                            if (config.addition instanceof Array) {
                                orList = orList.concat(config.addition);
                            }
                        }
                    }
                } else if (typeof keyChange === 'function') {
                    const config = keyChange(k, r, t, andList, orList);
                    if (config) {
                        k = config.k;
                        r = config.r;

                        if (config.addition instanceof Array) {
                            orList = orList.concat(config.addition);
                        } else if (config.force instanceof Array) {
                            orList = config.force;
                            forceInserted = true;
                        }
                    }
                }

                if (forceInserted) { continue; }

                // if ( r.match(/;$/) && (x + 1) === splited.length ) {
                if (r.match(/;$/)) {
                    r = r.replace(/;$/, '');
                    orList.push({ reg: createRegexp(r, 1, 1, 3, 3), text: t, key: k, value: r });
                } else {
                    orList.push({ reg: createRegexp(r, 1, 1, 3), text: t, key: k, value: r });
                }
            }
        } else if (typeof keyChange === 'function') {
            const config = keyChange('', '', v, andList, orList);
            if (config.addition instanceof Array) {
                orList = orList.concat(config.addition);
            } else if (config.force instanceof Array) {
                orList = config.force;
            }
        }

        if (orList.length) {
            andList.push(orList);
        }
    }
    return andList.length ? andList : null;
};

const getContentPin = (cnt, by = 'id', pin = {}) => {
    if (!cnt) { return pin; }

    if (cnt instanceof Array) {
        cnt.forEach(d => { getContentPin(d, by, pin); });
    } else {
        pin[cnt[by]] = pin[cnt[by]] ? [cnt].concat(pin[cnt[by]]) : cnt;
    }
    return pin;
};

const closeWidget = (props, state, setState, eventType) =>{
    const update = {...state, matchedList: [], focusIndex: -1};
    const clonedMatchedList = JSON.parse(JSON.stringify(update.matchedList || []));
    const searchFieldRef = state.searchFieldRef ? state.searchFieldRef.current : null;

    if (eventType === 'blur' && searchFieldRef && searchFieldRef.value && !state.multiple) {
        const text = searchFieldRef.value.trim();
        const { current, matchedList } = update;
        const pin = getContentPin(matchedList, 'name');
        if (pin[text] && pin[text].id !== (current || {}).id) {
            update.current = pin[text];
        } else if (!pin[text] && current) {
            update.current = null;
        }
    }

    setState(update);

    if (eventType === 'blur' && typeof props.change === 'function' && !state.multiple) {
        const value = (searchFieldRef || {}).value || '';
        const current = update.current || { name: value };
        const found = clonedMatchedList.find( (d) => d.name.toLowerCase() === value.toLowerCase());
        const force = props.change(current, (found ? [found] : clonedMatchedList), value);

        if ( force && searchFieldRef ) {
            searchFieldRef.value = force.name;
            setState({...update, current: force });
        }
    }
};

const search = (props, state, setState, text ) => {
    const { selection, searchKeys, maxSearch } = state;
    if (!(selection || []).length) { return; }

    if (typeof text !== 'string' && state.searchFieldRef.current) {
        text = state.searchFieldRef.current.value || '';
    }

    text = `${text || ''}`.trim();

    const filter = createSearchFilter(text, null, null, true);
    const matchedList = [];
    let i = 0;
    while (i < selection.length && matchedList.length < maxSearch) {
        const data = selection[i++];
        const test = filter ? verifySearchFilter(filter, data, searchKeys) : true;
        if (!test) { continue; }

        matchedList.push({ ...data });
    }

    setState({...state, matchedList, focusIndex: -1 });
};

const changeFocus = (props, state, setState, up ) => {
    const { focusIndex, matchedList } = state;
    const { length } = matchedList || [];
    if (length === 0) {
        setState({ ...state, focusIndex: -1 });
    } else if (focusIndex === -1) {
        setState({ ...state, focusIndex: up ? length - 1 : 0 });
    } else {
        let index = focusIndex + (up ? -1 : 1);
        if (index < 0) {
            index = length - 1;
        } else if (index >= length) {
            index = 0;
        }
        setState({ ...state, focusIndex: index });
    }
};

const updateCurrent = (props, state, setState, data, startup, switcher) => {
    if ( !data.id ) { return false; }

    if ( state.multiple ) {
        const cloned = JSON.parse(JSON.stringify(data));
        let current = JSON.parse(JSON.stringify(state.current || []));
        const currentPin = getContentPin( current );

        if ( switcher ) {
            const found = current.find( (d) => d.id === cloned.id );
            found.switched = !found.switched;                
        } else if ( data.static ) {
            const found = current.find( (d) => d.id === cloned.id );
            found.disabled = !found.disabled;
        } else if ( currentPin[data.id] ) {
            current = current.filter( (d) => d.id !== cloned.id );
        } else {
            current.push(cloned);
        }

        setState({...state, current});

        if (!startup && typeof props.change === 'function') {
            const action = props.change(current);
            if ( action === 'close-widget' ) {
                setState({...state, matchedList: [], focusIndex: -1});
                return true;
            }
        }
    } else {
        setState({ ...state, current: data });
        if ( state.searchFieldRef ) {
            state.searchFieldRef.current.value = data.name || '';
        }
        if (!startup && typeof props.change === 'function') {
            props.change(data);
        }
    }

    return false;
};

const click = (props, state, setState, e, key, data) => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if ( key === 'select' && data ) {
        const mode = updateCurrent(props, state, setState, data);
        if ( !mode && state.searchFieldRef ) {
            state.searchFieldRef.current.focus();
        }
    } else if ( key === 'clear' && state.searchFieldRef && state.searchFieldRef.current ) {
        state.searchFieldRef.current.value = '';
        state.searchFieldRef.current.focus();
    }
};

const focus = (props, state, setState, e) => {
    const { target } = e;
    const value = target.value || '';
    clearTimeout(state.timer.close || 0);
    search(props, state, setState, value);
}

const blur = (props, state, setState) => {
    clearTimeout(state.timer.close || 0);
    state.timer.close = setTimeout(() => {
        closeWidget(props, state, setState, 'blur');
    }, 200);
};

const keyDown = (props, state, setState, e) => {
    if (e.shiftKey) { return; }

    const code = e.keyCode;
    if (code === 9 || code === 32 || code === 13) {
        if (code === 13) { e.preventDefault(); }

        const { focusIndex, matchedList, current } = state;
        const focus = matchedList[focusIndex];
        if (focus && focus.id !== (current || {}).id) {
            updateCurrent(props, state, setState, focus);
        }
    } else if (code === 38 || code === 40) {        
        changeFocus(props, state, setState, (code === 38));
    }
};

const keyUp = (props, state, setState, e) => {
    const code = e.keyCode;
    if (/^(9|32|13|38|40)$/.test(`${code}`)) {
        // do nothing. keydown has handled the action
    } else {
        const text = (e.target || {}).value || '';
        clearTimeout(state.timer.close || 0);
        clearTimeout(state.timer.search || 0);
        state.timer.search = setTimeout(() => {
            search(props, state, setState, text);
        }, 300);
    }
};

const Suggestion = ( props ) => {
    const [state, setState] = useState({
        id: generateId('suggestion'),
        multiple: !!props.multiple,
        searchFieldRef: useRef<any>(null),
        placeholder: props.placeholder || '',
        timer: {},
        current: props.current,
        matchedList: [],
        focusIndex: -1,
        maxSearch: props.maxSearch || 10,
        searchKeys: props.searchKeys || ['id', 'name'],
        optionView: props.optionView || ['name'],
        selection: props.selection || [],
        pin: getContentPin(props.selection),
    });

    useEffect(() => {
        const test = JSON.stringify(state.selection || []) === JSON.stringify(props.selection || []) &&
            JSON.stringify(state.current || []) === JSON.stringify(props.current || []);
        if ( test ) { return; }

        setState({...state, current: props.current, selection: props.selection, pin: getContentPin(props.selection)});
    }, [state, setState, props]);

    const currentPin = getContentPin(state.current);

    return (
        <div role="application" className={classNames('suggestion-wrapper', {'-multiple': state.multiple})}>
            {!!props.label && <label htmlFor={`${state.id}-search-field`} className="input-label">{props.label}</label>}
            <div className="suggestion-field-holder">
                <input
                    id={`${state.id}-search-field`}
                    type="search"
                    ref={state.searchFieldRef}
                    aria-haspopup="true"
                    autoComplete="off"
                    spellCheck="false"
                    autoCapitalize="off"
                    autoCorrect="off"
                    placeholder={state.placeholder}
                    className={classNames('textfield', '-search-field')}
                    onClick={(e) => { click(props, state, setState, e, 'toggle-open'); }}
                    onFocus={(e) => { focus(props, state, setState, e); }}
                    onBlur={(e) => { blur(props, state, setState, e); }}
                    onKeyDown={(e) => { keyDown(props, state, setState, e); }}
                    onKeyUp={(e) => { keyUp(props, state, setState, e); }}
                />
                <a href="#" role="button" title="Klart tekstfelt" className="suggestion-clear-btn" onClick={(e) => {click(props, state, setState, e, 'clear'); } }/>
            </div>

            {(state.matchedList || []).length > 0 && <ul className="drop-down-widget" role="listbox">
                {state.matchedList.map((d, i) => (
                    <li key={`dropw-down-item-${i}`} role="option" aria-selected={!!currentPin[d.id]} 
                        onClick={ (e) => { click(props, state, setState, e, 'select', d); }}
                        className={classNames('option-item', {
                            '-active': !!currentPin[d.id],
                            '-focus': i === state.focusIndex,
                        })}
                    >
                        {state.optionView.map((key, j) => (
                            <span key={`dopw-down-item-${i}-${j}`} className={classNames('item', `-${key}`, { '-empty': !!d[key] })}>
                                {d[key] || ''}
                            </span>
                        ))}
                    </li>
                ))}
            </ul>}
        </div>    
    );
};

export default Suggestion;
