import { post, put } from "@eika-infrastruktur/request";
// eslint-disable-next-line import/no-cycle
import { VIS_FEILMELDINGS_KVITTERING, VIS_KVITTERING_LASTER } from './modals';
import { postLog } from './app';

export const RAPPORTER_SERVERKALL_FEIL = 'RAPPORTER_SERVERKALL_FEIL';
export const VIS_OPPRETT_AKSJESPAREKONTO_MODAL = 'VIS_OPPRETT_AKSJESPAREKONTO_MODAL';
export const AUTENTISERINGS_SJEKK = 'AUTENTISERINGS_SJEKK';

const endpointUrl = '/sparing-min-side-web/rest/resource';

export const utforOrdre = (data, url, method, onSuccess) => {
    return dispatch => {
        dispatch({
            type: VIS_KVITTERING_LASTER
        });

        (method === 'PUT' ? put(url, data) : post(url, data)).then(data => {
            onSuccess(data, dispatch);
        }).catch(() =>
            dispatch({
                    type: VIS_FEILMELDINGS_KVITTERING,
                    meldingFraServer: data.melding,
                    serverData: {status: data.status},
            }));
    };
};

export const processError = (type, response, dispatch, api) => {
    const data = {
        type,
        errorCode: response.status,
        msg: response.message || response.statusText
    };

    dispatch(postLog(response, {data, api}));
    dispatch({type: RAPPORTER_SERVERKALL_FEIL, data});
    return data;
};

export const logClientSideError = (message, stack) => {
    return () => {
        post(`${endpointUrl}/log/clienterror`, { message, stack });
    };
};

export const processResponse = (type, response, dispatch, additionalData) => {
    const data = additionalData ? { data: response, ...additionalData } : response;
    dispatch({ type, data });
    return data;
};

export const isAuthenticated = (bankregnr, body) => {
    const url = `${endpointUrl}/authenticated`;

    // rewritten to use lib post but logic kept as is
    return dispatch => {
        post(url, body, {headers: {'X-BankRegNr': bankregnr}}).then(data => {
                dispatch({
                    type: AUTENTISERINGS_SJEKK,
                    data: { ok: !data.anonym, kundeIdUrl: data.kundeidUrl }
                })
        }).catch((error) => {
            if (error.code === '401') {
                dispatch({
                    type: AUTENTISERINGS_SJEKK,
                    data: { ok: true }
                })
            }
            processError(AUTENTISERINGS_SJEKK, error, dispatch);
        });
    };
};
