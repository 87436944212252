import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Input } from '@eika/input';
import { Loader } from '@eika/loader';
import { Modal } from '@eika/modal';
import { Link, LinkType } from '@eika/link';
import Grid from '../../common/Grid/Grid';
import Message from '../../common/Message/Message';
import DrawDayTable from '../../common/DrawDayTable/DrawDayTable';
import FundDetail from '../../common/FundDetail/FundDetail';
import FundSuggestion from '../../common/FundSuggestion/FundSuggestion';
import EditProfile from '../../common/EditProfile/EditProfile';
import FundCalculator from '../../common/FundCalculator/FundCalculator';
import {
    FondHandelSelgSelectType,
    FondHandelTerm,
    FondHandelFinish,
    FondHandelSelectPlacement,
    FondHandelNewPlacement,
    FondHandelByttForm,
    FondHandelSelgForm,
    FondHandelUnderBehandling,
    FondHandelAutomaticIncreaseSaving,
    FondHandelAISsavingPlan,
} from './FondHandelComponents';

import {
    OverviewOptionList,
    FooterButton,
    Spinner,
    ComingSoon,
    AksjesparekontoTekst,
} from '../../common/share/ShareComponents';
import { setElementFocus, getPageAppending, isLoading } from '../../common/util/Functions';
import { getContactUsUrl } from '../../common/util/BusinessFunctions';
import { getFondOrderStorage } from '../../common/util/FondFunctions';
import { usePrevious, useEventListener } from '../../common/util/Hook';
import KjennDinKunde from '../KjennDinKunde/KjennDinKunde';
import {
    getParam,
    getPlacementAccountList,
    save,
    back,
    next,
    changeEmail,
    closeDialog,
    blurAmount,
    setAmount,
    blurEmail,
    setEmail,
    setPlacementName,
    blurBankAccount,
    setBankAccount,
    callbackKjennDinKunde,
    click,
    setAIS,
    setAISvalue,
    blurAISvalue,
    getStatusConfig,
    hasFundBefore,
    fundOwnedByClientOnAccount,
} from './util/FondHandelFunctions';
import { initState, useEffectPlacementCallback } from './util/FondHandelInitializeFunctions';
import { getAISvalueInfo } from './util/FondHandelAutomatiskOkingSparingFunctions';
import Constant from '../../static/data/Constant';

import { Props, State } from './FondHandelDomain';
import './FondHandel.scss';
import FondHandelKjopForm from './components/FondHandelKjopForm';
import FondHandelKjopSelectType from './components/FondHandelKjopSelectType';
import FondHandelVelgFond from './components/FondHandelVelgFond';
import useMutualFundStore from '../../stores/MutualFundStore';

export default (props: Props): JSX.Element => {
    const { setPaymentType, entry } = useMutualFundStore();
    const kalkulator = useSelector((state: any) => state.Kalkulator);
    const reducer = useSelector((state: any) => {
        return ['App', 'KjennDinKunde', 'Modals', 'Ordre', 'Pensjon', 'Fond', 'Bank', 'SpareProfil'].reduce(
            (p: { [k: string]: any }, k: string) => {
                return { ...p, [k]: (state || {})[k] || {} };
            },
            {}
        );
    });

    const [state, setState] = useState<State>({
        error: {},
        mode: useRef<any>({ ipsPin: {} }),
        prevent: false,
        isExpanded: false,
        reducer,
        frontpage: props.frontpage || {
            name: 'Fond og aksjer',
            url: /localhost/i.test(window.location.href)
                ? '/#/portefolje'
                : `${(window.location.href.match(/(.*)#/) || [])[1]}#/portefolje`,
        },
    });

    const param = getParam(props);
    const orderStorage = getFondOrderStorage(param.order);
    const appending = getPageAppending(props);
    const previousStep = usePrevious(param.step);
    const previousReducer = usePrevious(reducer);
    const previousDialog = usePrevious(state.dialog);
    const { appTexts } = props;
    const {
        force,
        fund,
        bankAccount,
        placement,
        typeList,
        error,
        bankRelation,
        fundSuggestionUrl,
        selectedSuggetion,
        dialog,
        disabledFundAndPlacementSelection,
    } = state;

    const sperre = (placement?.pin || {})[orderStorage.initedPlacement]?.sperre || '';
    const prevent =
        state.prevent ||
        /MA_BEHANDLES_MANUELT/i.test((reducer.KjennDinKunde || {}).kundevurdering) ||
        (sperre === Constant.fullSperret ? sperre.toLowerCase() : '') ||
        (sperre === Constant.salgSperret &&
        (orderStorage.action === Constant.actionSelg || orderStorage.action === Constant.actionUttak)
            ? sperre.toLowerCase()
            : '');

    const loading = !placement?.inited || isLoading(props, reducer, 'fund');
    const placementAccountList = getPlacementAccountList(props, state, orderStorage);
    const aisValueInfo = getAISvalueInfo(props, state, orderStorage, kalkulator?.fondKalkulator?.aisPlan);
    const portfolioData = useSelector((state: any) => state.Fond.portefoljeData);
    useEffect(() => {
        return () => {
            clearInterval(state.mode.current.avtalegiroTimer || 0);
            state.mode.current.unmounted = true;
        };
    }, [state.mode]);

    useEffect(() => {
        if (JSON.stringify(reducer) === JSON.stringify(previousReducer)) {
            return;
        }
        setState({ reducer, ...initState(props, state, reducer) });
    }, [previousReducer, reducer, state, props]);

    useEffect(() => {
        if (orderStorage.done) {
            clearTimeout(state.mode.current.doneTimer || 0);
            state.mode.current.doneTimer = setTimeout(() => {
                back(1);
            }, 100);
        } else {
            if (previousStep !== param.step && orderStorage.history?.lengthList) {
                orderStorage.history.lengthList.push({ id: window.history.length });
                const backStepUpdate =
                    previousStep === param.step + 1 ||
                    previousStep === param.step + 2 ||
                    previousStep === param.step + 3 ||
                    (previousStep === 104 && previousStep === 3);

                if (backStepUpdate) {
                    orderStorage.history.back = (orderStorage.history.back || 0) + 1;
                } else {
                    orderStorage.history.back = 0;
                }
            }

            if (orderStorage.receipt) {
                const stepBack =
                    param.status && orderStorage.history.avtalegiroLength
                        ? window.history.length - orderStorage.history.avtalegiroLength
                        : 6;
                const avtalegiroStatus = param.status || orderStorage.avtalegiroStatus;
                if (avtalegiroStatus && !state.mode.current.stepBack) {
                    save(
                        props,
                        state,
                        setState,
                        {
                            ...getStatusConfig(avtalegiroStatus),
                            stepBack,
                            history: orderStorage.history,
                        },
                        true
                    );
                }
            } else if (
                orderStorage.action === Constant.actionKjop &&
                param.step === 7 &&
                !(state.bankAccount?.pin ?? {})[orderStorage.bankAccount]
            ) {
                const splitedPathname = props.history.location.pathname.split('/');
                splitedPathname.pop();
                props.history.push({ pathname: splitedPathname.concat('6').join('/') });
            } else if (param.step === 8) {
                if (reducer.Ordre?.signeringsUrl && !orderStorage.finished) {
                    const info: any = {
                        signeringsUrl: reducer.Ordre.signeringsUrl,
                        ordreId: reducer.Ordre.ordreId,
                        receipt: null,
                        failed: false,
                        finished: false,
                    };
                    if (reducer.Modals?.kvitteringConfig?.vis && reducer.Modals?.kvitteringsInnhold) {
                        info.failed =
                            reducer.Modals.kvitteringsInnhold.type === 'feilmelding'
                                ? reducer.Modals.kvitteringsInnhold.melding || true
                                : false;
                        info.finished = reducer.Modals.kvitteringsInnhold.type !== 'feilmelding';
                        if (info.finished) {
                            info.receipt = {
                                config: reducer.Modals.kvitteringConfig,
                                content: reducer.Modals.kvitteringsInnhold,
                            };
                        }
                    }
                    for (const key in info) {
                        orderStorage[key] = info[key];
                    }
                    save(props, state, setState, info, true);
                    // save(props, state, setState, info, true, !!reducer.Modals?.kvitteringConfig?.vis );
                } else if (reducer.Modals?.kvitteringConfig && reducer.Modals?.kvitteringsInnhold) {
                    const receipt = {
                        config: reducer.Modals.kvitteringConfig,
                        content: reducer.Modals.kvitteringsInnhold,
                    };
                    save(props, state, setState, { receipt, history: orderStorage.history }, true);
                }
            } else if (orderStorage.signeringsUrl && previousStep === 8) {
                save(
                    props,
                    state,
                    setState,
                    {
                        history: orderStorage.history,
                        signeringsUrl: '',
                        ordreId: '',
                        failed: false,
                        canceled: false,
                        visSignering: 0,
                    },
                    true
                );
            } else {
                save(props, state, setState, { history: orderStorage.history }, true);
            }
        }

        return () => {
            clearTimeout(state.mode.current.doneTimer || 0);
        };
    }, [props, state, param.step, param.status, orderStorage, reducer, previousStep]);

    useEffect(() => {
        if (state.dialog === 'newBankAccount' && previousDialog !== 'newBankAccount') {
            setElementFocus('newBankAccountField');
        } else if (state.dialog !== 'viewaccountagreement' && previousDialog === 'viewaccountagreement') {
            setElementFocus('linkAccountAgreement');
        } else if (state.dialog !== 'viewkeyinfo' && previousDialog === 'viewkeyinfo') {
            setElementFocus('linkKeyInfo');
        }
    }, [previousDialog, state.dialog]);

    useEffect(() => {
        if (!state.placement || state.placement.inited) {
            return;
        }

        const placement = state.placement;
        const ipsMissingSparing = placement.ipsAccount.filter((d: any) => !d.sparing);
        let ipsCount = ipsMissingSparing.length;
        if (!ipsCount) {
            return;
        }

        ipsMissingSparing.forEach((d: any) => {
            if (state.mode.current.ipsPin[d.portfolioBaseCode]) {
                d.sparing = state.mode.current.ipsPin[d.portfolioBaseCode];
                if (--ipsCount <= 0) {
                    useEffectPlacementCallback(props, placement);

                    placement.inited = true;
                    setState({ ...state, placement, force: new Date().getTime() });
                }
            } else {
                props.action.getAarligSparebelop(d.portfolioBaseCode, (result: any) => {
                    state.mode.current.ipsPin[d.portfolioBaseCode] = result;
                    d.sparing = result;
                    if (--ipsCount <= 0) {
                        useEffectPlacementCallback(props, placement);

                        placement.inited = true;
                        setState({ ...state, placement, force: new Date().getTime() });
                    }
                });
            }
        });
    }, [state, setState, props]);

    // Kiet: en hack pga left- og right- appending som påvirker @eika/modal
    useEffect(() => {
        if (param.step === 1 && /left|right/i.test(appending || '')) {
            setTimeout(() => {
                if (state.mode.current.unmounted) {
                    return;
                }
                const node = document.getElementById('fond-handel-step-fund-selecting');
                if (node) {
                    node.setAttribute('class', 'step-wapper');
                }
            }, 1000);
        }
    }, [param.step, appending, state, setState]);

    useEventListener((e: MessageEvent) => {
        const status = e && typeof e.data === 'string' ? (e.data.match(/\?status=(\w+)/i) || [])[1] : '';
        if (!status) {
            return;
        }
        const statusConfig = getStatusConfig(status);
        save(props, state, setState, statusConfig, true);
        setTimeout(() => {
            setState({ ...state, force: new Date().getTime() });
        }, 100);
    });

    const disableMonthlySavingIfBFund = () => {
        const currentFund = fund.pin[props.match.params.isin || param.fundId || orderStorage.fund];

        if (!currentFund) return false;

        const clientOwnsFund = fundOwnedByClientOnAccount(
            portfolioData,
            orderStorage.placement && placement ? placement.pin[orderStorage.placement].portfolioBaseCode : '',
            currentFund.isin
        );

        if (clientOwnsFund) return false;

        if (
            currentFund.mutualFundClassReference === null ||
            currentFund.mutualFundClassReference.isin === currentFund.isin
        ) {
            return false;
        }

        return true;
    };

    if (prevent || loading) {
        return (
            <div className="sgw-content content-max-width">
                <div className={`step-wapper ${appending}`}>
                    {loading ? (
                        <Spinner type="app" />
                    ) : (
                        <Message
                            text={
                                typeof prevent === 'string'
                                    ? appTexts.get(`${prevent}SperretKontoText`)
                                    : appTexts.get('kundevurderingMaBehandlesManueltText')
                            }
                            title={
                                typeof prevent === 'string'
                                    ? appTexts.get(`${prevent}SperretKontoTitle`)
                                    : appTexts.get('kundevurderingMaBehandlesManueltTitle')
                            }
                            type="error-hand"
                        >
                            <div className="center paragraph -only-top">
                                <Link href={getContactUsUrl()} linkType={LinkType.BUTTON_STANDARD_POSITIVE}>
                                    Kontakt oss
                                </Link>
                            </div>
                        </Message>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div
            className={classNames(
                `sgw-content fond-handel-wrapper -step-${param.step} -${orderStorage.action || 'kjop-fond'}`.replace(
                    /\./g,
                    '-'
                ),
                orderStorage.style,
                {
                    '-force-updated': force,
                    '-disabled-fund-and-placement-selection': disabledFundAndPlacementSelection,
                    '-quick-repurchase': !!state.fundPanelView,
                }
            )}
        >
            {param.step !== 8 && orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <FondHandelUnderBehandling
                        click={(e, k, d, f) => {
                            click(props, state, setState, e, k, d, f);
                        }}
                    />
                </div>
            )}
            {param.step === 0 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    {orderStorage.action === Constant.actionSelg ? (
                        <FondHandelSelgSelectType
                            click={(e, k, d, f) => {
                                click(props, state, setState, e, k, d, f);
                            }}
                            typeList={typeList || []}
                            selected={orderStorage.type}
                            error={error}
                        />
                    ) : (
                        <FondHandelKjopSelectType
                            click={(e, k, d, f) => {
                                setPaymentType(d.id);
                                click(props, state, setState, e, k, d, f);
                            }}
                            typeList={typeList || []}
                            disableMonthly={disableMonthlySavingIfBFund()}
                        />
                    )}
                </div>
            )}

            {param.step === 1 && !orderStorage.receipt && (
                <div id="fond-handel-step-fund-selecting" className={`step-wapper ${appending}`}>
                    <FondHandelVelgFond
                        {...props}
                        fundSuggestionUrl={fundSuggestionUrl}
                        fundPanelView={state.fundPanelView}
                        fundList={fund?.list}
                        orderStorage={orderStorage}
                        error={state.error}
                        click={(e, k, d, f) => {
                            click(props, state, setState, e, k, d, f);
                        }}
                    />

                    {orderStorage.action === Constant.actionBytt && (
                        <Grid
                            namespace="sparing-content"
                            list={[
                                {
                                    layout: 'six',
                                    element: (
                                        <>
                                            <div className="paragraph">
                                                <Message
                                                    type="InfoMessage"
                                                    icon
                                                    text="Listen viser Eika-fond av samme type (Aksjefond) som fondet du bytter fra. Det er kun disse det er mulig å bytte til."
                                                />
                                            </div>
                                            <div className="paragraph">
                                                <Message
                                                    type="InfoMessage"
                                                    icon
                                                    text="Du kan sammenligne våre priser med andre selskaper på Finansportalen."
                                                    clickableItem={
                                                        <Link href="https://www.finansportalen.no/" target="_blank">
                                                            Gå til Finansportalen.no
                                                        </Link>
                                                    }
                                                />
                                            </div>
                                        </>
                                    ),
                                },
                            ]}
                        />
                    )}
                </div>
            )}
            {param.step === 2 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-header"
                        list={[
                            {
                                layout: 'twelve',
                                element: <h1 className="center">{fund.pin[param.fundId].navn}</h1>,
                            },
                        ]}
                    />

                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'eight',
                                element: (
                                    <div className="fund-detail-landing-page">
                                        <FundDetail
                                            {...props}
                                            data={fund.pin[param.fundId]}
                                            click={(e) => {
                                                click(props, state, setState, e, 'use-fund', fund.pin[param.fundId]);
                                            }}
                                        >
                                            <FooterButton
                                                fixForMobile
                                                click={(e) => {
                                                    click(
                                                        props,
                                                        state,
                                                        setState,
                                                        e,
                                                        'use-fund',
                                                        fund.pin[param.fundId]
                                                    );
                                                }}
                                                next="Velg dette fondet"
                                            />
                                        </FundDetail>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {param.step === 3 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    {(orderStorage.action === Constant.actionKjop ||
                        orderStorage.action === Constant.actionEndreSpareavtale) && (
                        <FondHandelKjopForm
                            {...props}
                            click={(e, k, d, f) => {
                                click(props, state, setState, e, k, d, f, portfolioData, entry);
                            }}
                            next={(e, k) => {
                                next(props, state, setState, e, k);
                            }}
                            setAmount={(e) => {
                                setAmount(props, state, setState, e, portfolioData, entry);
                            }}
                            blurAmount={() => {
                                blurAmount(props, state, setState, portfolioData, entry);
                            }}
                            setAIS={(e: boolean) => {
                                setAIS(props, state, setState, e);
                            }}
                            setAISvalue={(e) => {
                                setAISvalue(props, state, setState, e);
                            }}
                            blurAISvalue={() => {
                                blurAISvalue(props, state, setState);
                            }}
                            aisValueInfo={aisValueInfo}
                            orderStorage={orderStorage}
                            state={state}
                            aisSavingPlan={!!kalkulator?.fondKalkulator?.aisPlan}
                        />
                    )}
                    {orderStorage.action === Constant.actionBytt && (
                        <FondHandelByttForm
                            click={(e, k, d, f) => {
                                click(props, state, setState, e, k, d, f);
                            }}
                            next={(e, k) => {
                                next(props, state, setState, e, k);
                            }}
                            setEmail={(e) => {
                                setEmail(props, state, setState, e);
                            }}
                            blurEmail={() => {
                                blurEmail(props, state, setState);
                            }}
                            setAmount={(e) => {
                                setAmount(props, state, setState, e);
                            }}
                            blurAmount={() => {
                                blurAmount(props, state, setState);
                            }}
                            orderStorage={orderStorage}
                            state={state}
                        />
                    )}
                    {(orderStorage.action === Constant.actionSelg || orderStorage.action === Constant.actionUttak) && (
                        <FondHandelSelgForm
                            click={(e, k, d, f) => {
                                click(props, state, setState, e, k, d, f);
                            }}
                            next={(e, k) => {
                                next(props, state, setState, e, k);
                            }}
                            setEmail={(e) => {
                                setEmail(props, state, setState, e);
                            }}
                            blurEmail={() => {
                                blurEmail(props, state, setState);
                            }}
                            setAmount={(e) => {
                                setAmount(props, state, setState, e);
                            }}
                            blurAmount={() => {
                                blurAmount(props, state, setState);
                            }}
                            orderStorage={orderStorage}
                            state={state}
                        />
                    )}
                </div>
            )}
            {param.step === 3.5 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'eight',
                                element: (
                                    <div className="ais-standalone-wrapper">
                                        <h1 className="paragraph">Automatisk øking</h1>
                                        <FondHandelAutomaticIncreaseSaving
                                            namespace="stand-alone"
                                            aisValueInfo={aisValueInfo}
                                            aisFrequency={state.aisFrequency}
                                            orderStorage={orderStorage}
                                            error={state.error}
                                            click={(e, k, d, f) => {
                                                click(props, state, setState, e, k, d, f);
                                            }}
                                            setAIS={(e: boolean) => {
                                                setAIS(props, state, setState, e);
                                            }}
                                            setAISvalue={(e) => {
                                                setAISvalue(props, state, setState, e);
                                            }}
                                            blurAISvalue={() => {
                                                blurAISvalue(props, state, setState);
                                            }}
                                            aisSavingPlan={!!kalkulator?.fondKalkulator?.aisPlan}
                                        />
                                        <FooterButton
                                            click={(e) => {
                                                click(props, state, setState, e, 'after-step-ais');
                                            }}
                                            cancel="Avbryt"
                                        />

                                        <div style={{ display: 'none' }}>
                                            <FundCalculator
                                                {...props}
                                                isin={state.fund.pin[orderStorage.fund]?.isin}
                                                onetime={
                                                    orderStorage.type === Constant.typeManedligSparing
                                                        ? 0
                                                        : orderStorage.amount
                                                }
                                                monthly={
                                                    orderStorage.type === Constant.typeManedligSparing
                                                        ? orderStorage.amount
                                                        : 0
                                                }
                                                aisValue={orderStorage.ais ? orderStorage.aisValue : undefined}
                                                aisFrequency={orderStorage.ais ? orderStorage.aisFrequency : undefined}
                                                drawDay={orderStorage.drawDay}
                                                increaseImmediately={
                                                    orderStorage.action === Constant.actionEndreSpareavtale
                                                }
                                            />
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {param.step === 4 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <FondHandelSelectPlacement
                        click={(e, k, d, f) => {
                            click(props, state, setState, e, k, d, f);
                        }}
                        fund={fund}
                        orderStorage={orderStorage}
                        error={error}
                        placementAccountList={placementAccountList}
                    />
                </div>
            )}
            {param.step === 5 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <FondHandelNewPlacement
                        click={(e, k, d, f) => {
                            click(props, state, setState, e, k, d, f);
                        }}
                        setPlacementName={(e) => {
                            setPlacementName(props, state, setState, e);
                        }}
                        fund={fund}
                        placement={placement}
                        bankRelation={bankRelation}
                        orderStorage={orderStorage}
                        error={error}
                    />
                </div>
            )}
            {param.step === 6 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <FondHandelTerm
                        click={(e, k, d, f) => {
                            click(props, state, setState, e, k, d, f);
                        }}
                        setEmail={(e) => {
                            setEmail(props, state, setState, e);
                        }}
                        blurEmail={() => {
                            blurEmail(props, state, setState);
                        }}
                        fund={fund}
                        placement={placement}
                        bankAccount={bankAccount}
                        orderStorage={orderStorage}
                        aisValueInfo={aisValueInfo}
                        error={error}
                        hasFundBefore={hasFundBefore(props, state)}
                        appTexts={appTexts}
                    />
                </div>
            )}
            {param.step === 7 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'eight',
                                element: (
                                    <div className="fund-handle-ahv-wrapper">
                                        <KjennDinKunde
                                            {...props}
                                            callback={(e?: any) => {
                                                callbackKjennDinKunde(props, state, setState, e);
                                            }}
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {param.step === 8 && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'six',
                                element: (
                                    <div className="fond-handel-finish-wrapper">
                                        {orderStorage.receipt ||
                                        ((reducer.Modals || {}).kvitteringConfig &&
                                            (reducer.Modals || {}).kvitteringsInnhold) ? (
                                            <FondHandelFinish
                                                click={(e, k, d, f) => {
                                                    click(props, state, setState, e, k, d, f);
                                                }}
                                                orderStorage={orderStorage}
                                                appTexts={appTexts}
                                                receipt={
                                                    orderStorage.receipt || {
                                                        config: (reducer.Modals || {}).kvitteringConfig,
                                                        content: (reducer.Modals || {}).kvitteringsInnhold,
                                                    }
                                                }
                                                status={{
                                                    finished: /^ok$/i.test(param.status) || !!orderStorage.finished,
                                                    canceled: /^cancel$/i.test(param.status) || !!orderStorage.canceled,
                                                    failed: /^error$/i.test(param.status) || !!orderStorage.failed,
                                                }}
                                            />
                                        ) : (
                                            <Loader />
                                        )}
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {(param.step === 100 || param.step === 200) && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'eight',
                                element: (
                                    <>
                                        <h1 className="paragraph-small">Legg til epost</h1>
                                        <EditProfile
                                            title=""
                                            kundeInfo={{ epost: orderStorage.email }}
                                            display={{ email: 1 }}
                                            submit={(e) => {
                                                changeEmail(props, state, setState, e);
                                            }}
                                            close={() => {
                                                back(1);
                                            }}
                                            submitButton="Bruk"
                                            fixButton={false}
                                        />
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {param.step === 101 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'eight',
                                element: (
                                    <DrawDayTable
                                        title="Velg trekkdato"
                                        selected={orderStorage.drawDay}
                                        click={(e, k, d, f) => {
                                            click(props, state, setState, e, k, d, f);
                                        }}
                                        action="draw-day-selected"
                                        description="På valgt dag i måneden blir beløpet trukket fra din konto hver måned."
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {(param.step === 102 || param.step === 202) && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'eight',
                                element: (
                                    <>
                                        <h1 className="paragraph">Velg belastningskonto</h1>
                                        <OverviewOptionList
                                            list={bankAccount.list}
                                            click={(e, k, d, f) => {
                                                click(props, state, setState, e, k, d, f);
                                            }}
                                            action="bankAccount-selected"
                                            type="list-item -no-arrow"
                                        />
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {(param.step === 103 || param.step === 203) && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'eight',
                                element: (
                                    <div className="new-bank-account-wrapper">
                                        <h1 className="paragraph">Legg til bankkonto</h1>
                                        <div className="input-holder -new-bank-account">
                                            <Input
                                                type="tel"
                                                label="Kontonummer"
                                                required
                                                error={error.newBankAccount}
                                                onChange={(e) => {
                                                    setBankAccount(props, state, setState, e);
                                                }}
                                                onBlur={() => {
                                                    blurBankAccount(props, state, setState);
                                                }}
                                            />
                                        </div>
                                        <FooterButton
                                            click={(e, k, d, f) => {
                                                click(props, state, setState, e, k, d, f);
                                            }}
                                            action="use-new-bank-account"
                                            next="Bruk"
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {param.step === 104 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'eight',
                                element: (
                                    <div className="ais-standalone-wrapper">
                                        <h1 className="paragraph">Automatisk øking</h1>
                                        <FondHandelAutomaticIncreaseSaving
                                            aisValueInfo={aisValueInfo}
                                            aisFrequency={state.aisFrequency}
                                            orderStorage={orderStorage}
                                            error={state.error}
                                            click={(e, k, d, f) => {
                                                click(props, state, setState, e, k, d, f);
                                            }}
                                            setAIS={(e: boolean) => {
                                                setAIS(props, state, setState, e);
                                            }}
                                            setAISvalue={(e) => {
                                                setAISvalue(props, state, setState, e);
                                            }}
                                            blurAISvalue={() => {
                                                blurAISvalue(props, state, setState);
                                            }}
                                            aisSavingPlan={!!kalkulator?.fondKalkulator?.aisPlan}
                                        />
                                        <FooterButton
                                            click={(e, k, d, f) => {
                                                click(props, state, setState, e, k, d, f);
                                            }}
                                            action="ais-selected"
                                            next={orderStorage.ais ? 'Bruk' : 'Tilbake'}
                                        />

                                        <div style={{ display: 'none' }}>
                                            <FundCalculator
                                                {...props}
                                                isin={state.fund.pin[orderStorage.fund]?.isin}
                                                onetime={
                                                    orderStorage.type === Constant.typeManedligSparing
                                                        ? 0
                                                        : orderStorage.amount
                                                }
                                                monthly={
                                                    orderStorage.type === Constant.typeManedligSparing
                                                        ? orderStorage.amount
                                                        : 0
                                                }
                                                aisValue={orderStorage.ais ? orderStorage.aisValue : undefined}
                                                aisFrequency={orderStorage.ais ? orderStorage.aisFrequency : undefined}
                                                drawDay={orderStorage.drawDay}
                                                increaseImmediately={
                                                    orderStorage.action === Constant.actionEndreSpareavtale
                                                }
                                            />
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {param.step === 105 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'eight',
                                element: (
                                    <div className="ais-standalone-wrapper">
                                        <h1 className="paragraph">Hyppighet</h1>
                                        <OverviewOptionList
                                            list={state.aisFrequency.list.filter((d: any) => !!d.category)}
                                            click={(e, k, d, f) => {
                                                click(props, state, setState, e, k, d, f);
                                            }}
                                            action="aisFrequency-selected-second-level"
                                            type="list-item -no-arrow"
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {param.step === 106 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'eight',
                                element: (
                                    <div className="ais-standalone-wrapper">
                                        <h1 className="paragraph">Måned</h1>
                                        <OverviewOptionList
                                            list={state.aisFrequency.list.filter((d: any) => !d.category)}
                                            click={(e, k, d, f) => {
                                                click(props, state, setState, e, k, d, f);
                                            }}
                                            action="aisFrequency-selected-second-level"
                                            type="list-item -no-arrow"
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
            {param.step === 110 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending} fund-handle-suggestion-wrapper`}>
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'twelve',
                                element: <h1>Fondsveilder</h1>,
                            },
                        ]}
                    />
                    <FundSuggestion
                        {...props}
                        fundList={fund.list}
                        selectedId={selectedSuggetion}
                        suggestedCallback={(fund) => {
                            click(props, state, setState, null, 'fund-suggested', fund);
                        }}
                    />
                </div>
            )}
            {param.step === 900 && !orderStorage.receipt && (
                <div className={`step-wapper ${appending}`}>
                    <div className="content-max-width">
                        <ComingSoon />
                    </div>
                </div>
            )}
            <Modal
                id="fund-calculator"
                appNamespace="sparing-react-app"
                onClose={() => {
                    closeDialog(props, state, setState);
                }}
                show={!!dialog}
            >
                {dialog === 'newBankAccount' && (
                    <div className="input-holder -new-bank-account new-bank-account-dialog">
                        <Input
                            type="tel"
                            label="Kontonummer"
                            required
                            error={error.newBankAccount}
                            onChange={(e) => {
                                setBankAccount(props, state, setState, e);
                            }}
                            onBlur={() => {
                                blurBankAccount(props, state, setState);
                            }}
                            // ref={(ref) =>{ this.newBankAccountField = ref;}}
                        />
                        <FooterButton
                            click={(e, k, d, f) => {
                                click(props, state, setState, e, k, d, f);
                            }}
                            action="use-new-bank-account"
                            next="Bruk"
                        />
                    </div>
                )}
                {dialog === 'aisSavingPlan' && (
                    <FondHandelAISsavingPlan data={kalkulator?.fondKalkulator} orderStorage={orderStorage} />
                )}
                {dialog === 'viewaccountagreement' && <AksjesparekontoTekst />}
                {dialog === 'viewkeyinfo' && <FundDetail {...props} data={fund.pin[orderStorage.fund]} />}
                {dialog === 'viewAboutPlacementAccount' && <AksjesparekontoTekst />}
                {dialog === 'avtalegiroURLerror' && (
                    <Message type="error-server" text="Ops! Noe gikk galt. Vennligst prøv igjen senerer." />
                )}

                {dialog === 'confirmToDeleteFundAgreement' && (
                    <div className="confirm-to-delete-fund-agreement center">
                        <div className="delete-fond-agreement-message h2 center paragraph">Er du sikker?</div>
                        <div className="paragraph-large">Din månedlige sparing vil avsluttes.</div>
                        <FooterButton
                            namespace="center"
                            click={(e, k, d, f) => {
                                click(props, state, setState, e, k, d, f);
                            }}
                            action="yes-delete-fond-agreement"
                            next="Ja, slett"
                            cancel="Nei"
                            cancelAction="close-dialog"
                        />
                    </div>
                )}

                {dialog === 'foreign-currency' && (fund.pin || {})[orderStorage.fund] && (
                    <section aria-labelledby="forreign-currency-title" className="forreign-currency-wrapper">
                        <h2 aria-describedby="forreign-currency-description" id="forreign-currency-title">
                            {appTexts.get('foreignCurrency')}
                        </h2>
                        <p id="fee-detail-description">
                            {appTexts.get('foreignCurrencyInfo', { fundName: fund.pin[orderStorage.fund].navn })}
                        </p>
                    </section>
                )}
            </Modal>
        </div>
    );
};
