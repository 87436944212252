/**
 * Created by vagrant on 01.03.17.
 */

import React from 'react';
import PropTypes from 'prop-types';

const textKeyGenerator = textKey => {
    let counter = 1;

    const getNextTextKey = () => {
        const key = `${textKey}${counter}`;
        counter += 1;
        return key;
    };

    return {
        getNextTextKey
    };
};

const getAllTexts = (textKey, texts) => {
    const textKeyGen = textKeyGenerator(textKey);
    const items = [];

    let keyExists;
    do {
        const key = textKeyGen.getNextTextKey();
        keyExists = texts.has(key);

        if (texts.has(key)) {
            items.push({ key, value: texts.get(key) });
        }
    } while (keyExists);

    return items;
};

const getDefaultText = (key, texts) => <span>{texts.get(key)}</span>;

const renderBulletPointHtml = items => items.map(item => <li key={item.key}>{item.value}</li>);

const TextList = props => (
    <ul className={`text-list ${props.bulletpoints ? 'bullets' : 'paragraphs'}`}>
        {props.texts.has(`${props.textKey}1`)
            ? renderBulletPointHtml(getAllTexts(props.textKey, props.texts))
            : getDefaultText(props.textKey, props.texts)}
    </ul>
);

TextList.propTypes = {
    texts: PropTypes.object.isRequired,
    textKey: PropTypes.string.isRequired,
    bulletpoints: PropTypes.bool
};

export default TextList;
