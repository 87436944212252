/**
 * Created by vagrant on 01.03.17.
 */

import React, {Component} from 'react';

class FullstendigeVilkaar extends Component {
    render = () => (
        <div className="vilkaar-tekst">
            <h2 className="h3 centered">Avtale om bruk av Min Side Sparing</h2>
            <h3 className="h4">1. Innledning</h3>
            <p>
                Denne avtalen (heretter «Avtalen») er inngått mellom Eika Kapitalforvaltning AS, org. nr. (979 561 261)
                og brukeren av Min Side Sparing (heretter «Brukeren» eller «Du/Deg»).
                Ved å ta i bruk Min Side Sparing og/eller bruke den informasjon som der finnes, godkjenner Brukeren
                Avtalen.
            </p>
            <p>
                Eika Kapitalforvaltning AS («Eika Kapitalforvaltning») har rett til å endre Avtalen uten å informere
                Brukeren i forkant. Slike endringer vil likevel bli informert i Min Side Sparing i etterkant av
                endringene, og Brukeren vil uansett ha full rett til når som helst å trekke seg fra Avtalen ved å
                avslutte avtaleforholdet.
            </p>
            <p>
                Kontaktdataene som avgis i Min Side Sparing vil ikke importeres til nettbanken. Ved endringer av e-post,
                telefonnummer, adresse etc. må Brukeren derfor registrere dette i nettbanken i tillegg til i Min Side
                Sparing. Ved å avgi kontaktdata i Min Side Sparing aksepterer Brukeren at Eika Kapitalforvaltning kan
                kommunisere med Brukeren gjennom de aktuelle kanaler.
            </p>
            <p>
                Brukeren har mulighet til når som helst å begrense den informasjonsinnhentingen Eika Kapitalforvaltning
                forestår gjennom avtaleforholdet, samt forhold knyttet til personvern/markedsføring, jf. nedenfor, ved å
                gjøre ønskede endringer i «om meg» i Min Side Sparing.
            </p>

            <h3 className="h4">2. Kostnader</h3>
            <p>Det påløper ingen kostnader ved bruk av Min Side Sparing.</p>
            <p>
                Ved tegning/innløsning av fondsandeler i Min Side Sparing vil ordinær tegnings-/innløsningsprovisjon
                påløpe.
            </p>
            <h3 className="h4">3. Forhold knyttet til personvern</h3>
            <p>
                Formålet med Min Side Sparing (og derigjennom Avtalen) er å gi deg en samlet oversikt over din sparing,
                og det er derfor nødvendig å innhente opplysninger om deg og din sparing fra andre
                leverandører/selskaper.
            </p>
            <p>
                For å oppfylle avtalen vil Eika Kapitalforvaltning derfor, eventuelt med din bistand, innhente slike
                opplysninger fra din Eika-bank (behandlingsansvarlig for opplysninger om dine innskuddskonti),
                fra andre selskaper i Eika Gruppen AS, fra VPS (behandlingsansvarlig for opplysninger om dine
                VPS-konti),
                fra andre fondsleverandører (behandlingsansvarlige for opplysninger om dine øvrige fondsinvesteringer),
                fra Norsk Pensjon og fra andre pensjonsleverandører (behandlingsansvarlige for opplysninger om din
                pensjonssparing).
                Eika Kapitalforvaltning er selv behandlingsansvarlig for opplysninger om dine investeringer i Eika-fond.
            </p>
            <p>Eika Kapitalforvaltning vil behandle følgende personopplysninger om deg:</p>
            <ul>
                <li>Opplysninger om din identitet</li>
                <li>Opplysninger om dine bankkonti i din Eika-bank</li>
                <li>Opplysninger om dine beholdninger på VPS-konti</li>
                <li>Opplysninger om dine beholdninger hos (enkelte) andre fondsleverandører</li>
                <li>Opplysninger om dine pensjonsavtaler</li>
                <li>Opplysninger om din fondssparing i fond forvaltet av Eika Kapitalforvaltning</li>
                <li>
                    Andre opplysninger som kan være nødvendig for å opprettholde eller avslutte avtaleforholdet om bruk
                    av Min Side Sparing
                </li>
            </ul>

            <p>
                Tillegg om Norsk Pensjon:
                For å gi deg oversikt over din pensjon må det innhentes opplysninger fra andre. Ingen av opplysningene
                lagres. De vil kun være tilgjengelige for deg og bare fram til du logger deg ut. Ved å ta appen i bruk
                godkjenner du at
            </p>
            <ul>
                <li>
                    Norsk Pensjon henter dine opplysninger fra pensjonsinnretninger.
                </li>
                <li>
                    Norsk Pensjon henter inn fra NAV, en simulering av din alderspensjon fra folketrygden, samt om du
                    har
                    offentlig tjenestepensjon
                </li>
            </ul>
            <p>
                Eika Kapitalforvaltning vil behandle opplysninger om deg i samsvar med Avtalen, personopplysningsloven
                og annet relevant regelverk. Personopplysningene vil bli benyttet til:
            </p>
            <ul>
                <li>Vise deg informasjon om din totale sparing/beholdning</li>
                <li>Identitetskontroll</li>
                <li>Risikostyring og kundeklassifisering</li>
                <li>Tilpasse produkter og tjenester i forhold til kundeklassifisering</li>
                <li>Behandle henvendelser fra deg</li>
            </ul>
            <p>For øvrig gjelder Eika Kapitalforvaltnings personvernspolicy som ligger tilgjengelig på www.eika.no.</p>
            <h3 className="h4">4. Innhold i Min Side Sparing</h3>
            <p>
                Ved å ta i bruk Min Side Sparing vil du gis en omfattende oversikt over din sparing/beholdning i dine
                ulike finansielle instrumenter og i bankinnskudd. Du vil blant annet få oversikt over:
            </p>
            <ul>
                <li>Dine beholdninger i fond/aksjer (som kan vises i Min Side Sparing)</li>
                <li>Din arbeidsgivers sparing (dvs. pensjonssparing)</li>
                <li>Din banksparing</li>
                <li>Tegne/innløse/bytte fond forvaltet av Eika Kapitalforvaltning</li>
                <li>Opprette/endre/stanse spareavtaler</li>
                <li>Følge utviklingen av dine investeringer</li>
            </ul>

            <h3 className="h4">5. Ansvarsbegrensning</h3>
            <p>
                Informasjonen i Min Side Sparing må ikke under noen omstendighet oppfattes som et råd eller tilbud om å
                kjøpe eller selge finansielle instrumenter. Informasjonen er heller ikke å betrakte som skattemessige
                korrekte opplysninger, verken historiske eller oppdaterte beholdninger.
            </p>

            <p>
                Min Side Sparing inneholder tjenester og innhold fra enkelte eksterne aktører (eksempelvis VPS, Norsk
                Pensjon, leverandør av pensjonskalkulator, etc.). Det garanteres ikke at slike tjenester/innhold er
                fullstendig, riktig eller oppdatert. Eika Kapitalforvaltning kan derfor ikke holdes ansvarlig som følge
                av mangler ved tjenester/innhold levert av eksterne aktører. Eika Kapitalforvaltning er for øvrig heller
                ikke ansvarlig for innhold eller vilkår i eksterne sider som er linket fra Min Side Sparing. For øvrig
                kan Eika Kapitalforvaltning ikke holdes ansvarlig for tap som følge av feil ved- eller feilbruk av Min
                Side Sparing.
            </p>

            <h3 className="h4">6. Tilgjengelighet</h3>
            <p>
                I perioder for vedlikehold, oppgraderinger eller ved uventede feil må Brukeren påregne at Min Side
                Sparing kan bli midlertidig utilgjengelig. I slike perioder vil kjøp og salg av fondsandeler/andre
                finansielle instrumenter kunne skje gjennom andre handelskanaler. Eika Kapitalforvaltning er ikke
                ansvarlig for tap som følge av manglende tilgjengelighet eller andre feil ved Min Side Sparing.
            </p>

            <h3 className="h4">7. Immaterielle rettigheter</h3>
            <p>
                Eika Kapitalforvaltning har alle immaterielle rettigheter til Min Side Sparing og innhold produsert av
                Eika Kapitalforvaltning. Innhold fra andre aktører enn Eika Kapitalforvaltning som er publisert i
                tjenesten vil være beskyttet av disse aktørenes opphavsrett.
            </p>
            <p>
                Eika Kapitalforvaltning, eller en tredjepart, beholder eierskap, opphavsrett og øvrige immaterielle
                rettigheter til informasjonen i Min Side Sparing. Varemerker, logoer og andre kjennetegn som forekommer
                i Min Side Sparing kan ikke reproduseres, publiseres eller brukes på annen måte uten etter avtale med
                Eika Kapitalforvaltning og/eller tredjepart.
            </p>
            <h3 className="h4">8. Heving av avtaleforholdet</h3>
            <p>
                Eika Kapitalforvaltning kan heve Avtalen dersom det foreligger vesentlig mislighold av Avtalen. Som
                vesentlig mislighold skal anses:
            </p>
            <ul>
                <li>at brukeren, uten rimelig grunn, gir andre tilgang til sin profil på Min Side Sparing</li>
                <li>
                    at Eika Kapitalforvaltning får mistanke om at brukeren er involvert i hvitvasking eller annen
                    ulovlig virksomhet
                </li>
                <li>at brukeren på annen måte misbruker Min Side Sparing eller forutsetningene for avtalen</li>
            </ul>
            <p>
                Avtalen heves ved at Brukerens tilgang til Min Side Sparing stenges. Eika Kapitalforvaltning forbeholder
                seg retten til å kreve erstatning for tap som følge av slikt mislighold.
            </p>
            <h3 className="h4">9. Verneting</h3>
            <p>Denne Avtalen skal reguleres av norsk rett. Oslo tingrett skal være verneting ved eventuelle tvister.</p>
        </div>
    );
}

export default FullstendigeVilkaar;
