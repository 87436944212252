import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../common/text-input';
import Format from '../../format-functions';
import HelperFunctions from '../../helper-functions';
import Checkbox from '../../checkbox';
import NedTrekksMeny from '../../ned-trekks-meny';
import Modal from '../../modal';

class KjopFondAISskjema extends Component {
    static propTypes = {
        spareBelop: PropTypes.number,
        texts: PropTypes.object,
        ais: PropTypes.bool,
        aisFrequency: PropTypes.string,
        aisValue: PropTypes.any,
        aisValueError: PropTypes.string,
        aisValueUnitPercent: PropTypes.bool,
        aisPlan: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            showAISplan: false,
            aisFrequencyList: HelperFunctions.getAISfrequencyList(),
        };
    }

    render = () => {
        const firstIncrease = (this.props.aisPlan || []).find( (data) => data.deposit !== this.props.spareBelop);

        return <div className={`fond-skjema-seksjon -ais -${this.props.ais ? 'selected' : 'none'}`}>
            <Checkbox id="ais" name="ais" checked={this.props.ais} onChange={()=>{ this.props.changeAis((!this.props.ais))}}>
                <>
                    <span className="text">{this.props.texts.get('automatiskOkingSparing')}</span>                    
                    <span className="description">{this.props.texts.get('automatiskOkingSparingBeskrivelse')}</span>                    
                </>
            </Checkbox>

            <div className="fond-skjema-seksjon -ais-frequency">
                <label>
                    <div className="label-text">{this.props.texts.get('automatiskOkingSparingHyppighet')}</div>
                    <NedTrekksMeny
                        id="aisFrequency"
                        options={this.state.aisFrequencyList}
                        defaultValue={this.props.aisFrequency}
                        onChange={( e )=>{ this.props.changeFrequency(e); }}
                    />
                </label>
            </div>

            <div className="fond-skjema-seksjon -ais-value">
                <div className="ais-plan-value-holder">
                    <TextInput
                        maxLength={4}
                        id="aisValue"
                        label={this.props.texts.get((this.props.aisValueUnitPercent ? 'aisOkingProsent' : 'aisOkingKroner'))}
                        onChange={ (e) => {
                            const text = e.target.value.replace( /\s+/g, '');
                            if ( this.props.aisValueUnitPercent ) {
                                this.props.changeValue( text );
                            } else {
                                const value = parseInt( (text || 0), 10 );
                                this.props.changeValue(`${isNaN(value) ? '' : value}`);
                            }
                        }}
                        value={`${this.props.aisValue || ''}`}
                    />
                    { !this.props.aisValueError && !!firstIncrease && <div className="ais-plan-value-info">
                        <span>{`Beløpet økes til ${firstIncrease.deposit} kr i ${firstIncrease.info}.`}</span>
                    </div>}                    
                </div>
                { !!this.props.aisValueError && <div aria-live="polite" className="hjelpe-tekster">
                    <div className="hjelpe-tekst">{this.props.aisValueError}</div> 
                </div>}

                { !this.props.aisValueError && !!(this.props.aisPlan || []).length && <div className="ais-plan-link-holder">
                    <a href="#" role="button" onClick={(e) => { this.click(e, 'toggle-ais-plan'); }}>Spareplan</a>
                </div>}
            </div>

            <Modal visModal={this.state.showAISplan}>
                <>
                    <a href="#" role="button" className="ais-plan-closer" onClick={(e) => { this.click(e, 'toggle-ais-plan'); }}>
                        <span>Lukk vindu</span>
                    </a>                
                    <div className="ais-plan-wrapper">                
                        <h1 className="h3">Spareplan</h1>
                        <table>
                            <caption>Automatisk øking spareplan</caption>
                            <thead>
                                <tr>
                                    <th>Når økning skjer</th>
                                    <th>Månedlig beløp</th>
                                </tr>
                            </thead>
                            <tbody>
                                { (this.props.aisPlan || []).map( (data, i) => {
                                    return i > 10 || data.deposit === this.props.spareBelop ? null : <tr key={`plan-row-${i}`}>
                                        <td>{data.dateText}</td>
                                        <td>{Format.currency(data.deposit)}</td>
                                    </tr>
                                }) }
                            </tbody>
                        </table>
                    </div>
                </>
            </Modal>
        </div>
    }

    click = (e, key='') => {
        if ( e && e.preventDefault ) { e.preventDefault(); }

        if ( key === 'toggle-ais-plan') {
            this.setState({ showAISplan: !this.state.showAISplan });
        }
    }
    
}

export default KjopFondAISskjema; 
