import React, { useState, useEffect, useRef, MouseEvent } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { StandardButton } from '@eika/button';
import { Link } from '@eika/link';
import { Modal } from '@eika/modal';
import { IconTypes } from '@eika/icon';
import Constant from '../../../static/data/Constant';
import { usePrevious } from '../../../common/util/Hook';
import Message from '../../../common/Message/Message';
import Card from '../../../common/Card/Card';
import Grid from '../../../common/Grid/Grid';
import AppMessage from '../../../common/AppMessage/AppMessage';
import ActionButtonListView, { ActionButtonListItemProps } from '../../../common/ActionButtonListView/ActionButtonListView';
import { 
    HeaderLink, SummaryCard, SummaryHeader, Spinner, Commercial, LinkList, LinkListItemProps, AboutTaxFreeInfo,
    AboutFundAccountIndividuellPensjonssparingKonto, AboutFundAccountVerdipapirkonto, AboutFundAccountAksjesparekonto,
    SummaryTotalValue,
} from '../../../common/share/ShareComponents';
import { formatAmount, openSmartspart, getPageAppending, sortList, scrollBodyTop, getOffset } from '../../../common/util/Functions';
import { getNewFondOrder, getKontiTypeName } from '../../../common/util/FondFunctions';
import { getAPPactionList, getFundCardConfig, getKlientCardConfig, getLabelList, isPortfolioOnlyView } from '../../../common/util/BusinessFunctions';
import { getCurrentAccount, getErrorToPerformSettInnPenger, getFondOrdrePendingMessage } from '../util/Functions';
import { AppTexts, RouteLocation, RouteHistory, RouteMatch } from '../../../domain/Types';
import './Konti.scss';

interface Props {
    appTexts: AppTexts;
    action: any;
    history: RouteHistory;
    location: RouteLocation;
    match: RouteMatch;
};

interface State {
    reducer: any;
    appending: string;
    actionList?: ActionButtonListItemProps[];
    content?: any;
    account?: any;
    ordreInfo?: any;
    attention?: {type: string; text: string};
    labelList?: any;
    detailRef: any;
    timer: {[k:string]: any},
    linkList: LinkListItemProps[],
    modal: string;
};

const click = ( props: Props, state: State, setState: (s: State) => void, e: MouseEvent | null, key=''): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === Constant.actionSettInnPenger || key === Constant.actionApneSmartspar ) {
        openSmartspart( key );
    } else if ( key === Constant.actionKjopTilBarn ) {
        props.history.push(`${props.location.pathname}/comingsoon`);        
        window.location.href =  `${window.location.href.replace(/\/+$/g,'')}/comingsoon`;
    } else if ( key === Constant.actionKjop ) {
        if ( state.account?.disponert ){
            props.history.push(`${props.location.pathname}/disponert`);            
        } else {
            const order = getNewFondOrder();
            props.history.push(`${props.location.pathname}/fondhandel/${order}/step0?action=${Constant.piwikActionBuy}`);
        }
    } else if ( key === Constant.actionKjopEngang ) {
        const error = getErrorToPerformSettInnPenger(state.account, Constant.typeEngangsinnkudd);
        if ( error ) {
            props.history.push(`${props.location.pathname}/stopp/${error}`);
        } else if ( state.account?.disponert ){
            props.history.push(`${props.location.pathname}/disponert`);            
        } else {
            const { match={} } = props;
            const { accountId } = match.params || {};
            const order = getNewFondOrder({
                type: Constant.typeEngangsinnkudd,
                placement: accountId,
            });
            props.history.push(`${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionOnetime}`);
        }
    } else if ( key === Constant.actionKjopSpareavtale ) {
        const error = getErrorToPerformSettInnPenger(state.account, Constant.typeManedligSparing);
        if ( error ) {
            props.history.push(`${props.location.pathname}/stopp/${error}`);
        } else if ( state.account?.disponert ){
            props.history.push(`${props.location.pathname}/disponert`);            
        } else {
            const { match={} } = props;
            const { accountId } = match.params || {};
            const order = getNewFondOrder({
                type: Constant.typeManedligSparing,
                placement: accountId,
            });
            props.history.push(`${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionMonthly}`);
        }
    } else if ( (key === 'scroll-to-detail' || key === 'konto-detaljer') && state.detailRef?.current ){
        const offset = getOffset( state.detailRef.current );
        scrollBodyTop( (offset[1] - 50), 400 ); 

        state.detailRef.current.classList.add('scale');
        clearTimeout((state.timer.shake || 0 ));
        state.timer.shake = setTimeout( () => {
            state.detailRef.current.classList.remove('scale');
        }, 2600);
    } else if ( key === 'open-account-info' || key === 'open-about-tax-free' ) {
        setState({...state, modal: key});
    } else if ( key === Constant.actionCloseModal ) {
        setState({...state, modal: ''});        
    }
};

const getAccountDetail = ( account: any, taxFree?: string, saving?: any ): any => {
    if ( !account ){ return; }

    return {
        title: 'Kontodetaljer',
        name: getKontiTypeName(account.portefoljeType),
        sectionList: [
            account.disponert ? {
                title: 'Kontotilganger',
                configList: [
                    { 
                        data: { textList: [[
                            {text: 'Kontoeier', type: 'font-normal'},
                            {text: account.eier },
                        ]],}
                    }, {
                        data: { textList: [[
                            {text: 'Tilgang', type: 'font-normal'},
                            {text: account.disposisjonType === 'FULL' ? 'Full' : 'Innsyn' },
                        ]],}                       
                    }
                ]
            } : null,
            {
                title: 'Detaljer',
                configList: [
                    { 
                        data: account.eier ? { textList: [[
                            {text: 'Kontoeier', type: 'font-normal'},
                            {text: account.eier},
                        ]],} : null    
                    }, {
                        action: isPortfolioOnlyView(account.portefoljeType) ? undefined : `open-account-info`,
                        data: { textList: [[
                            {text: 'Kontotype', type: 'font-normal'},
                            {text: getKontiTypeName(account.portefoljeType) },                        
                        ]],}
                    }, {
                        data: { textList: [[
                            {text: 'Kontonummer', type: 'font-normal'},
                            {text: account.portfolioBaseCode},
                        ]],}                       
                    }, {
                        // url: `${window.location.href.replace(/\/+$/g,'')}/comingsoon`,
                        action: 'open-about-tax-free',
                        data: taxFree ? { textList: [[
                            {text: 'Skattefritt uttak', type: 'font-normal'},
                            {text: taxFree},
                        ]],} : null                       
                    }, {
                        data: account.bank.banknavn ? { textList: [[
                            {text: 'Bank', type: 'font-normal'},
                            {text: account.bank.banknavn},
                        ]],} : null
                    }
                ].concat( 
                    saving ? [
                        {
                            data: { textList: [[
                                {text: 'Spart hittil i år', type: 'font-normal'},
                                {text: formatAmount(saving.current, true)},
                            ]],}                       
                        }, {
                            data: { textList: [[
                                {text: 'Maksbeløp per år', type: 'font-normal'},
                                {text: formatAmount(saving.max, true) },
                            ]],}                       
                        }
                    ] : [],
                ).filter( (d: any) => !!d && !!d.data )
            }
        ].filter((d: any) => !!d),
    }
};

const getContent = ( props: Props, reducer: any ): any => {
    const account = getCurrentAccount( props, reducer );
    if ( !account ) { return; }

    const { appTexts } = props;
    const sparebelop = reducer.Fond?.portefoljeData?.sparebelop ?? {};
    const ordreInfo = getFondOrdrePendingMessage( { 
        count: (account.ordreList || []).length,
        error: (account.ordreList || []).filter( (d: any) => d.statusGroup === Constant.orderStatusNoMoney ).length,
    });

    if ( account.portefoljeType === Constant.typeIps && !sparebelop[account.portfolioBaseCode]) {
        props.action.getAarligSparebelop( account.portfolioBaseCode );
        return;
    } 

    let hasSpareavtale = false;
    const url = window.location.href.replace(/\/+$/g,'');
    const fondList: any = [];
    const summary: any = [
        { key: 'totalVerdi', field: 'initialValue' },
        { key: 'innskudd', field: 'kostpris' },
        { key: 'urealisertGevinst', field: 'verdistigning'},
    ].reduce( (p: {[k: string]: null | number | string}, c: {key: string; field: string}) => {
        p[c.key] = account[c.field];
        return p;
    }, { warning: '', innskuddName: 'Kostpris' });

    (account.fond || []).concat( account.eksterneFond || []).forEach( (f: any) => { 
        const fond = {
            totalVerdi: f.totalVerdi,
            url: `${url}/${f.isin}`,
            data: getFundCardConfig(f),
            type: 'spare-link-panel -white -collection', 
        };
        fondList.push( fond );
        hasSpareavtale = hasSpareavtale || (f.spareavtaler || []).length > 0;
    });

    const klientList = account.portefoljeType === Constant.typeAsk ? [{
        url: `${url}/klientkonto`,
        data: getKlientCardConfig(account),
        type: 'spare-link-panel -grey -collection',         
    }] : [];

    const saving: any = account.portefoljeType === Constant.typeIps ? {
        max: (sparebelop[account.portfolioBaseCode] || {}).maksbelop || Constant.ipsMaxYearlyAmount,
        current: (sparebelop[account.portfolioBaseCode] || {}).aarligSparebelop || 0,
    } : null;

    if ( saving ) { 
        saving.percent = (saving.current / saving.max) * 100; 
        if ( saving.percent > 100 ) { saving.percent = 100; }
    }
    
    const taxFree = account.portefoljeType === Constant.typeAsk ? 
        formatAmount( account.skattefrittUttak , true ) : undefined;

    summary.totalVerdi = formatAmount( summary.totalVerdi, true );

    return {
        content: {
            panel: [
                fondList.length ? { id: 'mine-fond', name: appTexts.get((account.portefoljeType === Constant.typeVps ? 'vpsFond' : 'mineFond')), list: sortList(fondList, 'totalVerdi', true, true)} : null,
                klientList.length ? { id: 'klientkonto', name: appTexts.get('tilknyttetKonto'), list: sortList(klientList, 'verdi', true, true)} : null, 
            ].filter( (d) => !!d ),
            summary, 
            saving,
            taxFree,
            detail: getAccountDetail( account, taxFree, saving ),
            hasSpareavtale
        },
        labelList: getLabelList(account, {eier: true, ips: true}, undefined, true),
        attention: account.sperre ? {type: 'tag -blocked', text: appTexts.get(`${account.sperre.toLowerCase()}SperretKonto`)} : undefined,
        ordreInfo,
        account,
        actionList: account.disponert || isPortfolioOnlyView(account.portefoljeType) ? undefined : getAPPactionList(),
        linkList: isPortfolioOnlyView(account.portefoljeType) ? [] : [
            {
                id: 'orde-historikk',
                url: `${window.location.href.replace(/\/+$/g,'')}/ordre`,
                name: appTexts.get('Ordrehistorikk'),
                icon: IconTypes.BEVEGELSER_36,                
                dataTrackId: Constant.trackIdProduct,
                dataTrackLinkname: appTexts.get('ordreHistorikk', undefined, true),            
            }
        ],
    };
};

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Fond'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const [state, setState] = useState<State>({
        linkList: [],
        appending: getPageAppending( props ),
        reducer,
        detailRef: useRef<any>(null),
        timer: {},
        modal: '',
    });

    const previousReducer = usePrevious(reducer);
    const productName = state.account?.navn ?? '';

    useEffect(() => {
        if ( JSON.stringify(reducer) === JSON.stringify(previousReducer) ) { return; }
        setState({...state, reducer, ...getContent( props, reducer )});
    }, [previousReducer, reducer, state, props]);

    return (
        <div className="konti-wrapper">
            <Grid namespace="sparing-top" list={[{
                layout: 'twelve',
                element: <HeaderLink text="Fond og aksjer"/>
            }]}/>

            <Grid namespace="sparing-header" list={[{
                layout: 'twelve',
                element: <>
                    { (state.labelList || []).length > 0 && <Card type="plain" data={{labelList: state.labelList}}/> }
                    <h1>{productName || props.appTexts.get('konti')}</h1>
                    { !!state.content?.detail && <div className="header-info-link-wrapper">
                        <a href="#" onClick={(e) =>{ click(props, state, setState, e, 'scroll-to-detail'); }}>{state.content?.detail?.name}</a>
                    </div>}
                </>
            }]}/>

            <div className={`konti-content ${state.appending}`}>
                <Grid namespace="sparing-content" list={[{
                    layout: 'seven',
                    element: <>
                        { !state.content ? <Spinner namespace="paragraph-large" type="portefoljer" />: <> 

                            <AppMessage appMessage={reducer?.App?.serviceMessage} context="PORTFOLIO">
                                { state.ordreInfo?.count > 0 && state.ordreInfo?.url && 
                                    <Message role="presentation" {...state.ordreInfo}>
                                        <div className="paragraph -only-top">
                                            <Link href={state.ordreInfo.url}>{props.appTexts.get('ordreHistorikk')}</Link>
                                        </div>
                                    </Message>
                                }
                            </AppMessage>

                            { !!state.attention && <div className="paragraph">
                                <Message role="presentation" {...state.attention}/>
                            </div>}

                            { !!state.content.summary && <section className={`paragraph ${state.account?.portefoljeType === Constant.typeVps ? 'vps-summary-section' : 'info-summary-section'}`}>
                                <div className="wallpaper-wrapper only-for-desktop">
                                    <div className="max-width-600">
                                        { state.content.summary.totalVerdi !== undefined && 
                                            <SummaryTotalValue 
                                                namespace="paragraph"
                                                label={props.appTexts.get('totalVerdi')}
                                                value={state.content.summary.totalVerdi}
                                                message={state.content.summary.warning}
                                            />
                                        }   
                                        { !isPortfolioOnlyView(state.account?.portefoljeType) && <SummaryCard {...state.content.summary}>
                                            <div className="paragraph-extra-small -only-top">
                                                { state.account?.portefoljeType === Constant.typeIps && !!state.content.hasSpareavtale ?
                                                    <StandardButton data-track-id={Constant.trackIdBuy} data-track-linkname="engangsbeleop" onClick={(e)=>{ click(props, state, setState, e, Constant.actionKjopEngang)}}>
                                                        Engangsbeløp
                                                    </StandardButton>
                                                    :
                                                    <StandardButton data-track-id={Constant.trackIdBuy} data-track-linkname="spar-i-fond" onClick={(e)=>{ click(props, state, setState, e, Constant.actionKjop)}}>
                                                        Spar i fond
                                                    </StandardButton>
                                                }
                                            </div>
                                        </SummaryCard> }
                                    </div>
                                </div>

                                <SummaryHeader {...state.content.summary} displayDetail namespace="only-for-mobile paragraph-small" />
                            </section> }

                            { !isPortfolioOnlyView(state.account?.portefoljeType) && <div className="paragraph only-for-mobile center">
                                { state.account?.portefoljeType === Constant.typeIps && !!state.content.hasSpareavtale ?
                                    <StandardButton data-track-id={Constant.trackIdBuy} data-track-linkname="engangsbeleop" onClick={(e)=>{ click(props, state, setState, e, Constant.actionKjopEngang)}}>
                                        Engangsbeløp
                                    </StandardButton>
                                    :
                                    <StandardButton data-track-id={Constant.trackIdBuy} data-track-linkname="spar-i-fond" onClick={(e)=>{ click(props, state, setState, e, Constant.actionKjop)}}>
                                        Spar i fond
                                    </StandardButton>
                                }
                            </div> }

                            <LinkList list={state.linkList} namespace="max-width-600 paragraph-large" click={(e: any, k: string) => { click(props, state, setState, e, k); }}/>

                            { (state.content.panel || []).map( (src: any, j: number) => {
                                return <section key={src.id} aria-labelledby={`${src.id}-title`} className="collection-wrapper paragraph-large">
                                    { !!src.name && <div id={`${src.id}-title`} className="section-title paragraph-extra-small">{src.name}</div> }
                                    <div className="collection-content">
                                        { (src.list || []).map( (data: any, k: number) => <Card key={`panel-${src.id}-${j}-${k}`} {...data} /> )}
                                    </div>
                                </section>
                            })}

                            { !!state.content.detail && <div className={classNames('konti-detail-wrapper', {
                                'paragraph-large -only-top': (state.content.panel || []).length === 0,
                            })}>
                                <h2 ref={state.detailRef} id="konti-detail-title" data-value={state.content.detail.title} className="paragraph">
                                    <span>{state.content.detail.title}</span>
                                </h2>
                                { state.content.detail.sectionList.map( (section: any, i: number) => (
                                    <section key={`account-detail-section-${i}`} aria-labelledby={`account-detail-section-${i}`} className={classNames('detail-section-wrapper', {
                                        'paragraph-large -only-top': i > 0
                                    })}>
                                        <div id={`account-detail-section-${i}`} className="section-title paragraph-extra-small">{section.title}</div>
                                        { section.configList.map( (config: any, j: number) => (
                                            <Card key={`account-detail-section-${i}-${j}`} 
                                                {...config} 
                                                type={config.type || 'list-item'} 
                                                click={ config.action ? (e: MouseEvent, k='' )=> { click(props,state, setState, e, k); } : undefined}
                                            />
                                        ))}
                                    </section>
                                ))}
                            </div>}
                        </>}
                    </>
                }, {
                    layout: 'four',
                    element: <>
                         <ActionButtonListView 
                            namespace="paragraph-large only-for-desktop wallpaper cell-2" 
                            list={state.actionList} 
                            click={(e:MouseEvent, k='') => { click(props, state, setState, e, k); }} 
                        />

                        <Commercial onlyForDesktop type="saving">
                            { !isPortfolioOnlyView(state.account?.portefoljeType) && <>
                                <StandardButton className="open-smartspart-btn" data-track-id={Constant.trackIdBigLinkCard} data-track-linkname="start-sparing" onClick={(e)=>{ click(props, state, setState, e, Constant.actionKjopSpareavtale )}}>
                                    Start sparing 
                                </StandardButton>
                            </>}
                        </Commercial>
                    </>
                }]}/>
            </div>

            <Modal id="konti-modal"  appNamespace="sparing-react-app"  onClose={() => { click(props, state, setState, null, Constant.actionCloseModal); }}  show={!!state.modal} >
                { state.modal === 'open-account-info' && !!state.account && <>
                    {state.account.portefoljeType === Constant.typeAsk && <AboutFundAccountAksjesparekonto /> }
                    {state.account.portefoljeType === Constant.typeVpk && <AboutFundAccountVerdipapirkonto /> }
                    {state.account.portefoljeType === Constant.typeIps && <AboutFundAccountIndividuellPensjonssparingKonto /> }
                </>}

                {  state.modal === 'open-about-tax-free' && !!state.account && <AboutTaxFreeInfo amount={state.content.taxFree}/>}

            </Modal>
        </div>
    );
};