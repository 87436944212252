import React, { useEffect, useState } from 'react';

interface Props {
    loading?: boolean;
};

interface State {
    finish: boolean;
    loading: boolean;
};

export default (props: Props): JSX.Element => {
    const [state, setState] = useState<State>({
        finish: false,
        loading: props.loading || false
    }); 

    useEffect(() => {
        if ( !props.loading && state.loading ) {
            if ( !state.finish ) {
                setState({...state, finish: true });                    
                setTimeout( () => {
                    setState({finish: false, loading: false });                    
                }, 1000);
            }
        } else if ( props.loading && !state.loading ) {
            setState({...state, loading: true });                    
        }
    }, [props.loading, state]);

    return (
        <div style={{border: '1px solid transparent', width: '110px', margin: '0 auto'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="110" viewBox="0 0 110 110">
                <g id="abacus-wrapper">
                    { state.finish && <animate attributeName="opacity" values="0;1" dur=".6s" fill="freeze" repeatCount="indefinite"/> }

                    <g id="abacus-stand">
                        <path d="M5,88 5,98 105,98 105,88z" strokeWidth="2" stroke="#333" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeDasharray="220" strokeDashoffset="-220">
                            <animate begin="0" attributeName="stroke-dashoffset" from="-220" to="0" dur=".6s" fill="freeze"/>
                        </path>

                        <path d="M15,10 15,88" strokeWidth="2" stroke="#333" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeDasharray="78" strokeDashoffset="78" >
                            <animate begin="0" attributeName="stroke-dashoffset" from="78" to="0" dur=".6s" fill="freeze"/>
                        </path>
                        <path d="M95,10 95,88" strokeWidth="2" stroke="#333" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeDasharray="78" strokeDashoffset="-78">
                            <animate begin="0" attributeName="stroke-dashoffset" from="-78" to="0" dur=".6s" fill="freeze"/>
                        </path>
                        
                        <path d="M15,20 95,20" strokeWidth="2" stroke="#333" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeDasharray="80" strokeDashoffset="-80">
                            <animate begin="0" attributeName="stroke-dashoffset" from="-80" to="0" dur=".6s" fill="freeze"/>
                        </path>
                        <path d="M15,45 95,45" strokeWidth="2" stroke="#333" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeDasharray="80" strokeDashoffset="80">
                            <animate begin="0" attributeName="stroke-dashoffset" from="80" to="0" dur=".6s" fill="freeze"/>
                        </path>
                        <path d="M15,70 95,70" strokeWidth="2" stroke="#333" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeDasharray="80" strokeDashoffset="-80">
                            <animate begin="0" attributeName="stroke-dashoffset" from="-80" to="0" dur=".6s" fill="freeze"/>
                        </path>
                    </g>

                    <g id="abacus-bullet-row-1">
                        <circle id="bullet-1-1" cx="23" cy="20" r="6" strokeWidth="2" stroke="#333" fill="#fff" strokeDasharray="38" strokeDashoffset="-38">
                            <animate begin=".3s" attributeName="stroke-dashoffset" from="-38" to="0" dur=".6s" fill="freeze"/>
                            { state.loading && <animate begin="0" attributeName="cx" from="23" to="59" dur="2.5s" keyTimes="0; .2; .3; .4; .6; 1" values="23; 23; 59; 59; 23; 23" fill="freeze" repeatCount="indefinite"/> }
                        </circle>
                        <circle id="bullet-1-2" cx="37" cy="20" r="6" strokeWidth="2" stroke="#333" fill="#fff" strokeDasharray="38" strokeDashoffset="-38">
                            <animate begin=".3s" attributeName="stroke-dashoffset" from="-38" to="0" dur=".6s" fill="freeze"/>
                            { state.loading && <animate begin="0" attributeName="cx" from="37" to="73" dur="2.5s" keyTimes="0; .2; .7; .8; 1" values="37; 73; 73; 37; 37" fill="freeze" repeatCount="indefinite"/> }
                        </circle>
                        <circle id="bullet-1-3" cx="51" cy="20" r="6" strokeWidth="2" stroke="#333" fill="#fff" strokeDasharray="38" strokeDashoffset="-38">
                            <animate begin=".3s" attributeName="stroke-dashoffset" from="-38" to="0" dur=".6s" fill="freeze"/>
                            { state.loading && <animate begin="0" attributeName="cx" from="51" to="87" dur="2.5s" keyTimes="0; .2; .7; .8; 1" values="51; 87; 87; 51; 51" fill="freeze" repeatCount="indefinite"/> }
                        </circle>
                    </g>

                    <g id="abacus-bullet-row-2">
                        <circle id="bullet-1-1" cx="23" cy="45" r="6" strokeWidth="2" stroke="#333" fill="#fff" strokeDasharray="38" strokeDashoffset="-38">
                            <animate begin=".3s" attributeName="stroke-dashoffset" from="-38" to="0" dur=".6s" fill="freeze"/>
                            { state.loading && <animate begin="0" attributeName="cx" from="23" to="59" dur="3.5s" keyTimes="0; .4; .5; .6; 1" values="23; 23; 59; 59; 23" fill="freeze" repeatCount="indefinite"/> }
                        </circle>
                        <circle id="bullet-2-1" cx="73" cy="45" r="6" strokeWidth="2" stroke="#333" fill="#fff" strokeDasharray="38" strokeDashoffset="-38">
                            <animate begin=".3s" attributeName="stroke-dashoffset" from="-38" to="0" dur=".6s" fill="freeze"/>
                            { state.loading && <animate begin="0" attributeName="cx" from="73" to="23" dur="3.5s" keyTimes="0; .1; .4; .5; 1" values="73; 37; 37; 73; 73" fill="freeze" repeatCount="indefinite"/> }
                        </circle>
                        <circle id="bullet-2-2" cx="87" cy="45" r="6" strokeWidth="2" stroke="#333" fill="#fff" strokeDasharray="38" strokeDashoffset="-38">
                            <animate begin=".3s" attributeName="stroke-dashoffset" from="-38" to="0" dur=".6s" fill="freeze"/>
                        </circle>
                    </g>

                    <g id="abacus-bullet-row-3">
                        <circle id="bullet-3-1" cx="23" cy="70" r="6" strokeWidth="2" stroke="#333" fill="#fff" strokeDasharray="38" strokeDashoffset="-38">
                            <animate begin=".3s" attributeName="stroke-dashoffset" from="-38" to="0" dur=".6s" fill="freeze"/>
                            { state.loading && <animate begin="0" attributeName="cx" from="23" to="73" dur="3s" keyTimes="0; .2; .4; .7; 1" values="23; 73; 73; 23; 23" fill="freeze" repeatCount="indefinite"/> }
                        </circle>

                        <circle id="bullet-3-2" cx="37" cy="70" r="6" strokeWidth="2" stroke="#333" fill="#fff" strokeDasharray="38" strokeDashoffset="-38">
                            <animate begin=".3s" attributeName="stroke-dashoffset" from="-38" to="0" dur=".6s" fill="freeze"/>
                            { state.loading && <animate begin="0" attributeName="cx" from="37" to="87" dur="3s" keyTimes="0; .2; .7; .8; 1" values="37; 87; 87; 37; 37" fill="freeze" repeatCount="indefinite"/> }
                        </circle>
                    </g>
                </g>
            </svg>
        </div>
    );
};