import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Card extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        header: PropTypes.string,
        gradient: PropTypes.object,
        fullWidth: PropTypes.bool,
        transparent: PropTypes.bool,
        classes: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            style: props.gradient
                ? { borderImage: `linear-gradient(90deg,${props.gradient.start},${props.gradient.end}) 1 100%` }
                : null,
            cssClass: props.fullWidth ? 'full-width' : null,
            gradientClass: props.gradient ? 'gradient-card' : null,
            transparentClass: props.transparent ? 'transparent' : null
        };
    }

    visHeader = header =>
        header ? (
            <header className="card-header">
                <h2>{header}</h2>
            </header>
        ) : null;

    render = () => (
        <article className={`card ${this.state.cssClass} ${this.state.gradientClass} ${this.props.classes}`}>
            <div className={`card-content ${this.state.transparentClass}`} style={this.state.style}>
                {this.visHeader(this.props.header)}
                {this.props.children}
            </div>
        </article>
    );
}

export default Card;
