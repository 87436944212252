import {IconTypes} from '@eika/icon';
import {createCookie, createRegexp, formatAmount, getAvkastning, getURLquery, isWebview, readCookie} from './Functions';
import {getKontiTypeName, getNewFondOrder} from './FondFunctions';
import {ActionButtonListItemProps} from '../ActionButtonListView/ActionButtonListView';
import Constant from '../../static/data/Constant';
import {StringObject} from '../../domain/Types';

/******************************************************************************
 Fordi kunde allerede pensjonist og ikke det er irrelevant å hente
 arbeidsgiver pensjon til kunde?
 ******************************************************************************/
export const shallGetArbeidsgiversPensjonssparing = (kundeInfo: any): boolean => {
    return kundeInfo && kundeInfo.alder ? (kundeInfo.alder < Constant.pensionCustomerMaxAge) : false;
};

/******************************************************************************
 ******************************************************************************/
export const shallShowAHV = (kjennDinKunde?: any, portefoljeData?: any): boolean => {
    const {kundevurdering, oppfolgingSkjemaForKunde} = kjennDinKunde || {};
    if ((oppfolgingSkjemaForKunde || {}).erklaringtype) {
        return true;
    }

    return !(kundevurdering || '').match(/^(ok|ma_behandles_manuelt)$/i) && (portefoljeData || {}).totalVerdi > 0;

};

/******************************************************************************
 ******************************************************************************/
export const getAPPactionList = (config?: {
    disabledOneTime?: boolean,
    disabledPeriodic?: boolean
}): ActionButtonListItemProps[] => {
    return [
        {
            id: Constant.actionKjopEngang,
            name: config?.disabledOneTime ? 'Ikke tilgjengelig for engangskjøp' : 'Engangsbeløp',
            icon: IconTypes.PENGESEKK_36,
            dataTrackId: Constant.trackIdAction,
            dataTrackLinkname: 'engangsbelop',
            disabled: config?.disabledOneTime
        }, {
            id: Constant.actionKjopSpareavtale,
            name: config?.disabledPeriodic ? 'Ikke tilgjengelig for månedlig sparing' : 'Månedlig sparing',
            icon: IconTypes.SPAREGRIS_36,
            dataTrackId: Constant.trackIdAction,
            dataTrackLinkname: 'manedlig-sparing',
            disabled: config?.disabledPeriodic
        },
    ];
};

/******************************************************************************
 ******************************************************************************/
export const getTransactionLabelList = (data: any): any => {
    const list = [];
    if (!data) {
        return;
    }

    if (data.statusGroup === Constant.orderStatusFailed) {
        list.push({id: `lable-${data.id}-failed`, name: 'Feilet', type: 'error'});
    }

    if (data.statusGroup === Constant.orderStatusNoMoney) {
        list.push({id: `lable-${data.id}-no-money`, name: 'Mangler dekning', type: 'error'});
    }

    return list;
}

export const getLabelList = (data: any, wanted?: any, onlyFullSperre = false, large = false): any => {
    const list = [];
    if (!data) {
        return;
    }

    if ((data.ordreList || []).length && (!wanted || wanted.orderList)) {
        list.push({id: `lable-${data.id}-order`, name: 'Ordre på vei', type: 'pending', large});
    }

    if (data.isIPS && (!wanted || wanted.ips)) {
        list.push({id: `lable-${data.id}-${list.length}`, name: 'Pensjon', type: 'pension', large});
    }

    if (data.eier && (!wanted || wanted.eier)) {
        list.push({
            id: `lable-${data.id}-${list.length}`,
            name: data.eier,
            ariaLabel: `Kontoeier ${data.eier}`,
            type: 'dispose',
            large
        });
    }

    // data.sperre = 'SALG';
    if (data.sperre && (!wanted || wanted.sperre)) {
        const textOption: any = {
            full: 'Kontoen er sperret',
            salg: 'Kontoen er sperret for salg og uttak',
        };

        if (!onlyFullSperre || data.sperre === Constant.fullSperret) {
            list.push({
                id: `lable-${data.id}-${list.length}`,
                name: (textOption[data.sperre.toLowerCase()] || data.sperre),
                type: 'blocked',
                large
            });
        }
    }

    return list.filter((d) => !!d.name);
};

/******************************************************************************
 ******************************************************************************/
export const getKlientCardConfig = (data: any): any => {
    return {
        textList: [
            [{
                text: 'Klientkonto',
                ariaLabel: `${data.navn || 'Klientkonto tilknyttet til ASK'}`,
                title: `${data.navn || 'Klientkonto tilknyttet til ASK'}`,
            }, {
                text: formatAmount(data.kontantBeholdning, true),
                ariaLabel: data.kontantBeholdning === null ? 'Ingen verdi' : `Verdi ${data.kontantBeholdning}`,
                title: data.kontantBeholdning === null ? 'Ingen verdi' : `Verdi ${data.kontantBeholdning || 0}`,
            }], {
                type: 'font-normal -font-seagreen-medium -small-size',
                text: ['Bankkonto', data.bank?.banknavn].filter((t?: string) => !!t).join(', '),
            }, {
                namespace: 'message-info',
                type: 'font-normal -font-seagreen-medium -small-size -message-info',
                text: 'Skattefritt å overføre gevinst til denne',
            }
        ],
    };
};

/******************************************************************************
 ******************************************************************************/
export const getAccountCardConfig = (data: any): any => {
    const description = [getKontiTypeName(data.portefoljeType), data.bank?.banknavn]
        .filter((t?: string) => !!t).join(', ');

    return {
        textList: [
            [{
                text: data.navn,
                ariaLabel: `Portefølje ${data.navn}`,
                title: `Portefølje ${data.navn}`,
            }, {
                type: 'nowrap',
                text: formatAmount(data.initialValue, true),
                ariaLabel: data.initialValue === null ? 'Ingen totalverdi' : `Totalverdi ${data.initialValue}`,
                title: data.initialValue === null ? 'Ingen totalverdi' : `Totalverdi ${data.initialValue}`,
            }], {
                type: 'font-normal -font-seagreen-medium -small-size',
                text: description,
                ariaLabel: `${description}`,
            }
        ],
        labelList: getLabelList(data),
    };
};

/******************************************************************************
 ******************************************************************************/
export const getFundCardConfig = (data: any, ignorePortefolje = false): any => {
    const portefolje = data.portefolje;
    const description = portefolje ? [
        ignorePortefolje ? '' : (portefolje.navn || getKontiTypeName(portefolje.portefoljeType)),
        portefolje.bank?.banknavn
    ].filter((t?: string) => !!t).join(', ') : '';

    return {
        textList: [
            [{
                text: data.navn,
                ariaLabel: `Fond ${data.navn}`,
                title: `Fond ${data.navn}`,
            }, {
                text: formatAmount(data.totalVerdi, true),
                ariaLabel: data.totalVerdi === null ? 'Ingen totalverdi' : `Totalverdi ${data.totalVerdi}`,
                title: data.totalVerdi === null ? 'Ingen totalverdi' : `Totalverdi ${data.totalVerdi}`,
                type: 'nowrap'
            }], {
                type: 'font-normal -font-seagreen-medium -small-size',
                text: description,
                ariaLabel: `${description}`,
            }
        ],
        rigthContent: data.urealisertGevinst === undefined && data.innskudd === undefined ? undefined : {
            type: 'return-pie',
            config: {
                arrow: true,
                value: getAvkastning(data.totalVerdi, data.innskudd),
                ariaLabel: data.urealisertGevinst === null ? 'Ingen gevinst' : `Gavins ${formatAmount(data.urealisertGevinst, true)}`,
                title: data.urealisertGevinst === null ? 'Ingen gevinst' : `Gavins ${formatAmount(data.urealisertGevinst, true)}`,
                // value: data.avkastning,
                // ariaLabel: `Avkastning ${data.avkastning} %`.replace( /./g, ',' ),
                // title: `Avkastning ${data.avkastning} %`,
            }
        },
        labelList: getLabelList(data),
        dataTrackId: Constant.trackIdProduct,
        dataTrackLinkname: 'fond-kort',
    };
};

/******************************************************************************
 ******************************************************************************/
export const getAvtaleCardConfig = (avtale: any): any => {
    return {
        textList: [
            [{
                text: avtale.navn,
                ariaLabel: `Fond ${avtale.navn}`,
                title: `Fond ${avtale.navn}`,
            }, {
                text: `${formatAmount(avtale.trekkBelop)} kr`,
                ariaLabel: `Trekkbelop ${avtale.trekkBelop}`,
                title: `Trekkbeløp ${avtale.trekkBelop}`,
                type: 'trekk-belop',
                innerElement: true,
            }], {
                text: `Den ${avtale.trekkDag}. hver måned`,
                ariaLabel: `Trekk dato den ${avtale.trekkDag}. hver måned`,
                title: `Trekk dato den ${avtale.trekkDag}. hver måned`,
            }
        ],
        /*
        rigthContent: {
            type: 'span',
            config: {
                value: `${formatAmount(avtale.trekkBelop)} kr`,
                ariaLabel: `Trekkbelop ${avtale.trekkBelop}`,
                title: `Trekkbeløp ${avtale.trekkBelop}`,
                namespace: 'trekk-belop',
            }
        },
        */
    };
};

/******************************************************************************
 aciont=kjop-fond&id=1212&fund={isin}&placement={portefoljeId}&amount=100
 ******************************************************************************/
export const verifyLinking = (config?: StringObject): void => {
    const param = config || getURLquery(window.decodeURIComponent(window.location.href));
    if (!param.id || !param.action) {
        return;
    }

    const reg = createRegexp(param.id, 1, 1);
    const value = readCookie('VL');
    if (value.match(reg)) {
        return;
    }

    const list = value.split(',').filter((v: string) => !!v);
    list.push(param.id);
    createCookie('VL', list.join(','), 2);

    let url = window.location.href.replace(/\?.*/g, '').replace(/\/+$/g, '');
    if (!url.match(/#/)) {
        url += '#';
    }

    if (param.action === Constant.actionKjop) {
        const data = ['fund', 'placement', 'amount', 'fromOpenPage'].reduce((pin: any, key: string) => {
            if (param[key]) {
                pin[key] = param[key];
                if (key === 'amount') {
                    pin[key] = parseInt(pin[key].replace(/[\D\s]+/g, ''), 10);
                    if (isNaN(pin[key])) {
                        delete (pin[key]);
                    }
                }
            }
            return pin;
        }, {});

        if (data.fund) {
            data.skipFundSelecting = true;
        }

        const order = getNewFondOrder(data);
        window.location.href = `${url}/fondhandel/${order}/step0?action=${Constant.piwikActionBuy}`;
    } else if (param.action === Constant.actionVisPensjonskalkulator) {
        window.location.href = `${url}/pensjonsberegning`;
    }
};

export const isEikaDomain = (): boolean => {
    const origin = window.location.origin;
    const place = origin.replace(/.*\/\/(www\.)?/ig, '').replace(/eika.no.*/ig, '').replace(/\s+/g, '');
    return /eika/i.test(place) || !place;
};

export const getSparingOgPensjonUrl = (): string => {
    const isEika = isEikaDomain();
    return`${window.location.origin}/${isEika ? 'oversikt/sparing#/' : 'nettbank/sparing#/'}`;
};

/******************************************************************************
 ******************************************************************************/
export const getAppName = (): string => {
    if (isEikaDomain()) {
        return 'MSS';
    }
    return isWebview() ? 'MOBILBANK' : 'NETTBANK';
};

export const getSigningUrlCallback = (url = ''): string => {
    const origin = window.location.origin;
    const param = [
        'lc=no',
        `exit=${encodeURIComponent(`${origin}/sparing-min-side-web/signing-success.html`)}`,
        `rejectstatusurl=${encodeURIComponent(`${origin}/sparing-min-side-web/signing-cancel.html`)}`,
        `status=${encodeURIComponent(`${origin}/sparing-min-side-web/signing-error.html`)}`,
    ];
    return /\?/.test(url) ? `${url}&${param.join('&')}` : `${url}?${param.join('&')}`;
}

/******************************************************************************
 ******************************************************************************/
export const getContactUsUrl = (): string => {
    const isEika = isEikaDomain();
    return `${window.location.origin}/${isEika ? 'kontaktoss' : 'kontakt'}`;
};

export const openContactUs = (): void => {
    const href = getContactUsUrl();
    const link = document.createElement('a');
    link.setAttribute('href', href);
    link.setAttribute('style', 'position:fixed;bottom:0;right:0;z-index:-1;');
    link.setAttribute('target', '_blank');

    document.body.appendChild(link);
    setTimeout(() => {
        link.click();
        // @ts-ignore
        link.parentNode.removeChild(link);
    }, 200);
};

/******************************************************************************
 ******************************************************************************/
export const isPortfolioOnlyView = (type: string): boolean => {
    return type === Constant.typeIpa || type === Constant.typeGps || type === Constant.typeIbp || type === Constant.typeVps;
};

