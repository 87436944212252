import React, { useEffect, useState, useRef, MouseEvent } from 'react';
import {RouteComponentProps} from 'react-router';
import { useSelector } from 'react-redux';
import { StandardButton } from '@eika/button';
import { Link, LinkType } from '@eika/link';
import { Modal, ModalSize } from '@eika/modal';
import Constant from '../../static/data/Constant';
import { usePrevious } from '../../common/util/Hook';
import Card from '../../common/Card/Card';
import Message from '../../common/Message/Message';
import Chart from '../../common/Chart/Chart';
import Grid from '../../common/Grid/Grid';
import SigningIframe from '../../common/SigningIframe/SigningIframe';
import { HeaderLink, Spinner, Commercial, SummaryTotalValue, FooterButton } from '../../common/share/ShareComponents';
import AppMessage from '../../common/AppMessage/AppMessage';
import { getAPPactionList, shallGetArbeidsgiversPensjonssparing, getSigningUrlCallback } from '../../common/util/BusinessFunctions';
import { ActionButtonListItemProps } from '../../common/ActionButtonListView/ActionButtonListView';
import { formatAmount, getColor, openSmartspart, getPageAppending, getSmartsparURL, readCookie, createCookie } from '../../common/util/Functions';
import { getNewFondOrder } from '../../common/util/FondFunctions';
import { MultiObject, StringObject } from '../../domain/Types';
import { SigningConfig } from './EPKmove/EPKmoveDomain';
// @ts-ignore
import EPKriskProfileList from '../../static/data/EPKriskProfileList';
import { EPKmoveOnSigningFinish, EPKmoveOnSigningFailed, EPKmoveOnSigningRejected } from './EPKmove/EPKmoveComponents';
import './Arbeidsgiver.scss';
import {RouterConfig} from '../../app/Router';

type Props = RouteComponentProps & RouterConfig

interface State {
    appending: string;
    actionList: ActionButtonListItemProps[];
    content: any;
    panelView?: StringObject;
    reducer: MultiObject;
    epkMoveCard?: {id: string; action: string; type: string; data: any; },
    epkUnmovable: {[k: string]: string};
    signingOnLaoding: boolean;
    dialog: string;
    signingUrl: string;
    mode: any;
}

const closeDialog = ( state: State, setState: (s: State) => void ): void => {
    if ( state.dialog === 'signing' ) {
        clearInterval((state.mode.current.configTimer || 0));
    }
    setState({...state, dialog: ''});
};

const watchSigning = ( props: Props, state: State, setState: (s: State) => void ): void => {
    clearInterval((state.mode.current.configTimer || 0));
    state.mode.current.configTimer = setInterval( () => {
        props.action.getEPKsigningConfig((signingConfig: SigningConfig) => {
            if ( signingConfig?.signing?.status !== Constant.finishedSigning ) { return; }

            createCookie('isEpkInTransitToEika', '1', 1);
            props.action.getEPKsigningConfig(signingConfig);
            closeDialog( state, setState );
        });
    }, 3000);
};

const getOnMovingEPKorderURL = ( risikoprofilId = 0 ): string => {
    const profile = EPKriskProfileList.find( (d: any) => d.riskProfileId === risikoprofilId );
    return profile ? [
        window.location.href.replace(/\/+$/g, ''),
        window.encodeURIComponent(profile.key.toLowerCase().replace(/\s+/g, '')),
        'pensjonskonto',
        '0',
    ].join('/') : '';
};

const click = ( props: Props, state: State, setState: (s: State) => void, e: any, key='', data?: any ): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === Constant.actionSettInnPenger || key === Constant.actionApneSmartspar ) {
        openSmartspart( key, data, getSmartsparURL() );
    } else if ( key === 'change-panel-view' && data ) {
        setState({...state, panelView: {...state.panelView, selected: data.id}});
    } else if ( key === 'kjop-fond' ) {
        const order = getNewFondOrder();
        props.history.push(`${props.location.pathname}/fondhandel/${order}/step0?action=${Constant.piwikActionBuy}`);
    } else if ( key === Constant.actionKjopEngang ) {
        const order = getNewFondOrder({type: Constant.typeEngangsinnkudd});
        props.history.push(`${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionOnetime}`);
    } else if ( key === Constant.actionKjopSpareavtale ) {
        const order = getNewFondOrder({type: Constant.typeManedligSparing});
        props.history.push(`${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionMonthly}`);
    } else if ( key === Constant.actionMoveEPK ) {
        const order = getNewFondOrder({type: Constant.actionMoveEPK});
        props.history.push(`${props.location.pathname}/epkmove/${order}/0`);
    } else if ( key === Constant.actionSignNow && data?.signeringsurl ) {
        const update: State = {...state, dialog: 'signing', signingUrl: getSigningUrlCallback(data.signeringsurl)};
        setState(update);
        watchSigning(props, update, setState );
    } else if ( key === Constant.actionCancelSigning && data?.id ) {
        setState({...state, signingOnLaoding: true });
        props.action.cancelEPKsigning( data.id, () => {
            props.action.getEPKmoveData();
            props.action.getEPKsigningConfig();
            setTimeout( () => { setState({...state, signingOnLaoding: false }); }, 2000 );
        });
    } else if ( key === 'close-dialog' ) {
        props.action.getEPKsigningConfig();
        closeDialog(state, setState);
    }
};

const updateSigningStatus = (props: Props, state: State, setState: (s: State) => void, status: string, reducer: any ): void => {
    if ( status === Constant.signingCancel && reducer?.Epk?.signingConfig?.signing ) {
        const id = reducer.Epk.signingConfig.signing.id;
        setState({...state, signingOnLaoding: true });
        props.action.cancelEPKsigning( id, () => {
            props.action.getEPKmoveData();
            props.action.getEPKsigningConfig();
            setTimeout( () => { setState({...state, signingOnLaoding: false, dialog: status}); }, 2000 );
        });
    } else if ( status === Constant.signingSuccess ) {
        createCookie('isEpkInTransitToEika', '1', 1);
        setState({...state, dialog: status});
    }
}
const getItem = ( data: any, baseUrl='', color='#333' ): any => {
    if (!data) { return; }

    const description = (data.productDescription || '-').trim();
    const provider = (data.employerOrganizationName || data.pensionProviderName || description).trim();
    const amount = Math.round((data.balance || 0));
    return {
        url: `${baseUrl}/pensjon/${description}/${amount}/${provider}/-`,
        textList: [
            [
                { text: description, ariaLabel: `Produkt ${description}`},
                amount ? {
                    text: `${formatAmount(amount, true)} kr`,
                    ariaLabel: `Innskuddssaldo ${amount} kr`,
                    type: 'nowrap'
                } : null,
            ].filter( (n) => !!n ),
            { text: provider, ariaLabel: `Firma ${provider}`},
            amount ? {type: 'chart-legend-icon', style: {color}} : null,
        ].filter( (n) => !!n ),
        balance: amount,
    };
}

const getContent = ( props: Props, reducer: any ): any => {
    const { appTexts } = props;
    const { arbeidsgiversPensjonsSparing } = reducer.Pensjon || {};
    const baseUrl = window.location.href.replace(/\/+$/g, '');

    const content: any = {
        collection: [],
        chart: { type: 'pie', data: [], textList: [appTexts.get('totalverdi')], animation: false},
        totalverdi: 0,
        loading: arbeidsgiversPensjonsSparing === undefined,
        hasOtherPensionIn: false,
        otherList: [],
        currentList: [],
        zeroPublicSectorList: [],
    };

    (arbeidsgiversPensjonsSparing || []).forEach( (d: any) => {
        if ( !d ) { return; }

        const { color } = Constant.regPublicSector.test(d.category) && !d.balance ?
            {color: '#333'} : (getColor(content.chart.data.length, 'employer') ?? {color: '#333'});

        let item
        if ( Constant.regCurrentPension.test(d.category) || d.category === Constant.individuelleOrdninger ) {
            item = getItem( d, baseUrl, color );
            if ( ! item ) { return; }

            let risikoprofil = `${d.riskProfile || ''}`.replace(/eika\s+selvvalgt/i, '').trim();
            if (/forsiktig/i.test(risikoprofil)) { risikoprofil = 'Lav'; }

            // no riskprofile if it cant be an array
            // if (risikoprofil) {
            //     item.labelList = [{ text: risikoprofil, type: 'note' }];
            // }

            if ( d.source ===  Constant.tradex ) {
                item.url = [
                    baseUrl,
                    window.encodeURIComponent((risikoprofil).toLowerCase().replace(/\s+/g, '')),
                    d.productDescription,
                    item.balance,
                ].join('/');
            } else if (d.source === Constant.storebrand && !!d.agreementNumber) {
                item.url = `${baseUrl}/storebrand/${d.agreementNumber}`;
            }

            content.currentList.push( item );
        } else if ( Constant.regPublicSector.test(d.category) && !d.balance ) {
            item = getItem( d, baseUrl, color );
            if ( ! item ) { return; }

            item.url = `${baseUrl}/offentligsektor/${d.pensionProviderName}`;
            content.zeroPublicSectorList.push(item);
        } else {
            item = getItem( d, baseUrl, color );
            if ( ! item ) { return; }

            content.hasOtherPensionInfo = true;
            content.otherList.push(item);
        }

        content.chart.data.push({value: item.balance, color});
        content.totalverdi += (item.balance || 0);
    });

    const list = content.currentList.concat( content.otherList );
    if (list.length ) {
        content.collection.push({name: props.appTexts.get('pensjonFraArbeidsgiver'), list });
    }

    if ( content.zeroPublicSectorList.length ) {
        content.collection.push({
            type: 'public-sector',
            name: props.appTexts.get('publicSectorPension'),
            list: [{
                id: 'zeroPensionFromPublicSectorMessage',
                icon: true,
                type: 'AttentionMessage',
                text: props.appTexts.get('zeroPensionFromPublicSectorLabel'),
            }].concat(content.zeroPublicSectorList),
        });
    }

    content.totalverdi = Math.round(content.totalverdi);
    content.chart.textList.unshift(formatAmount((content.totalverdi || 0), true));
    if ( !content.hasOtherPensionInfo && !content.totalverdi ){
        content.chart = null;
    }

    return content;
};

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Pensjon', 'SpareProfil', 'Epk'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const [state, setState] = useState<State>({
        actionList: getAPPactionList(),
        content: getContent( props, reducer ),
        appending: getPageAppending( props ),
        reducer,
        epkMoveCard:  {
            id: 'epk-move-card',
            action: Constant.actionMoveEPK,
            type: 'spare-link-panel -commercial -lightbulb -epk-move',
            data: {text: ['Flytt til Eika Egen Pensjonskonto', 'Se hvilken risikoprofil vi anbefaler']},
        },
        signingOnLaoding: false,
        dialog: '',
        signingUrl: '',
        epkUnmovable: {
            NOT_ABLE_TO_MOVE_RESERVED: 'Din pensjon kan ikke flyttes til Eika egen pensjonskonto, siden du har reservert deg mot flytting.',
            NOT_ABLE_TO_MOVE_EKF_PENSION: 'Din pensjon kan ikke flyttes til Eika egen pensjonskonto, siden du allerede har din pensjon i Eika via jobben din.',
            NOT_ABLE_TO_MOVE_ONLY_PKB: 'Din pensjon kan ikke flyttes til Eika egen pensjonskonto, siden du har bare Passiv Pensjonskapitalbevis.',
        },
        mode: useRef<any>({}),
    });

    const previousPensjon = usePrevious(reducer.Pensjon);
    const hasEPKtoMove = reducer.Epk.moveStatus?.movable;
    const epkUnmovableCode = reducer.Epk.moveStatus?.notMovableReasonCode ?? '';

    useEffect(() => {
        if ( JSON.stringify(reducer.Pensjon) === JSON.stringify(previousPensjon) ) { return; }
        setState({...state, reducer, content: getContent( props, reducer )});
    }, [previousPensjon, reducer, state, props]);

    useEffect(() => {
        props.action.getEPKmoveData();
        props.action.getEPKsigningConfig();
        props.action.getEPKmoveStatus();

        return () => {
            clearInterval((state.mode.current.configTimer || 0));
        };
    }, [props.action, state.mode]);

    return (
        <div className={`arbeidsgiver-wrapper -${hasEPKtoMove ? 'has-epk-to-move' : 'basic'}`}>
            <Grid namespace='sparing-top' list={[{
                layout: 'twelve',
                element: <HeaderLink text='Sparing' />
            }]}/>

            <Grid namespace='sparing-header' list={[{
                layout: 'twelve',
                element: <h1>{props.appTexts.get('pensjon')}</h1>
            }]}/>

            <div className={`arbeidsgiver-bodys ${state.appending}`}>
                <Grid namespace='sparing-content' list={[{
                    layout: 'seven',
                    element: <>
                        { state.content && !state.content.loading ? <div className='arbeidsgiver-content'>
                                { !!state.content.message && <div className={state.content.chart ? 'base-info' : 'paragraph'}>
                                    <Message {...state.content.message}/>
                                </div> }

                                <AppMessage appMessage={reducer?.App?.serviceMessage} context='PENSION'>
                                    { reducer.Epk?.signingConfig?.signing?.status === Constant.readyForSigning && reducer.Epk?.signingConfig?.signing?.signeringsurl &&
                                        !reducer.Epk?.signingConfig?.isEpkInTransitToEika && !readCookie('isEpkInTransitToEika') &&
                                            <Message role='presentation' type='pending' namespace='signing-message' title={props.appTexts.get('epkPendingSigningTitle')} text={props.appTexts.get('epkPendingSigningText')}>
                                                <div className='paragraph -only-top'>
                                                     { state.signingOnLaoding ?
                                                        <div className='signing-on-loading-wrapper'><Spinner /></div> :
                                                        <FooterButton
                                                            click={(e:any, k='', ) =>{ click(props, state, setState, e, k, reducer.Epk?.signingConfig?.signing ); }}
                                                            action={Constant.actionSignNow}
                                                            next={props.appTexts.get('sign')}
                                                            cancelAction={Constant.actionCancelSigning}
                                                            cancel='Avbestill'
                                                        />
                                                    }
                                                </div>
                                            </Message>
                                    }
                                    { (!!reducer.Epk?.signingConfig?.isEpkInTransitToEika || !!readCookie('isEpkInTransitToEika')) &&
                                        <Message role='presentation' type='pending' namespace='signing-message' title={props.appTexts.get('epkOnMovingTitle')} text={props.appTexts.get('epkOnMovingText')}>
                                            <Link href={getOnMovingEPKorderURL((reducer.Epk?.signingConfig?.epkFlyttConfigs || [])[0]?.risikoprofilId)}>
                                                {props.appTexts.get('ordreHistorikk')}
                                            </Link>
                                        </Message>
                                    }
                                </AppMessage>

                                <section className='paragraph only-for-desktop'>
                                    <div className='wallpaper-wrapper'>
                                        <div className='max-width-600'>
                                            <SummaryTotalValue
                                                namespace='paragraph-large'
                                                label={props.appTexts.get('totalVerdi')}
                                                value={formatAmount(state.content.totalverdi)}
                                            />

                                            { (state.content.collection || []).map( (src: any, i: number) => {
                                                return <div key={src.id || `pensjon-collection-${i}`} className={`collection-wrapper -${src.type || 'basic'}`}>
                                                    { !!src.name && <h2 className='section-title'>{src.name}</h2> }

                                                    { (src.list || []).length > 0 && <ul className='content-list-wrapper -small-gap'>
                                                        { (src.list || []).map( (data: any, j: number) =>(
                                                            <li key={data.key || data.id || `pensjon-collection-item-${i}-${j}`}>
                                                                { /message/i.test(data.type) ? <Message {...data}/> : <Card data={data} /> }
                                                            </li>
                                                        ))}
                                                    </ul>}

                                                    { !!src.message && <Message {...src.message}/> }
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </section>

                                <section className='only-for-mobile'>
                                    { !!state.content.chart && <Chart {...(state.content.chart || {})} /> }

                                    { !!hasEPKtoMove && <div className='paragraph-large'>
                                        <Card
                                            {...(state.epkMoveCard || {})}
                                            click={(e: any) => { click(props, state, setState, e, state.epkMoveCard?.action ); }}
                                        />
                                    </div>}

                                    { !!state.epkUnmovable[epkUnmovableCode] && <div className='paragraph-large'>
                                        <Card
                                            type='epk-unmovable-card'
                                            data={{text: ['Eika egen pensjonskonto', state.epkUnmovable[epkUnmovableCode]]}}/>
                                    </div>}


                                    { (state.content.collection || []).map( (src: any, i: number) => {
                                        return <div key={src.id || `pensjon-collection-${i}`} className={`collection-wrapper -${src.type || 'basic'}`}>
                                            { !!src.name && <h2 className='section-title'>{src.name}</h2> }

                                            { (src.list || []).length > 0 && <ul className='content-list-wrapper -small-gap'>
                                                { (src.list || []).map( (data: any, j: number) =>(
                                                    <li key={data.key || data.id || `pensjon-collection-item-${i}-${j}`}>
                                                        { /message/i.test(data.type) ? <Message {...data}/> : <Card data={data} /> }
                                                    </li>
                                                ))}
                                            </ul>}

                                            { !!src.message && <Message {...src.message}/> }
                                        </div>
                                    })}
                                </section>
                            </div> : <div className='arbeidsgiver-content'><Spinner type='arbeidsgiver'/></div>
                        }
                    </>
                }, {
                    layout: 'four',
                    element: <>
                        { !!hasEPKtoMove && <div className='paragraph-large only-for-desktop'>
                            <Card
                                {...(state.epkMoveCard || {})}
                                type={`${state.epkMoveCard?.type || ''} -border`}
                                click={(e: any) => { click(props, state, setState, e, state.epkMoveCard?.action ); }}
                            />
                        </div>}
                        { !!state.epkUnmovable[epkUnmovableCode] && <div className='paragraph-large only-for-desktop'>
                            <Card
                                type='epk-unmovable-card'
                                data={{text: ['Eika egen pensjonskonto', state.epkUnmovable[epkUnmovableCode]]}}/>
                        </div>}

                        { shallGetArbeidsgiversPensjonssparing( reducer?.SpareProfil?.kundeInfo ) &&
                            <Commercial namespace='paragraph-large' type='pension-calculator' text='I Smartspar kan du regne ut hvor mye du vil ha per måned som pensjonist' title='Se hva du får i pensjon'>
                                <Link href={`${window.location.href.replace(/\/+$/g, '')}/pensjonsberegning`} linkType={LinkType.BUTTON_SMALL_POSITIVE}>
                                    Pensjonskalkultor
                                </Link>
                            </Commercial>
                        }

                        <div className='only-for-mobile'>
                            <Commercial type='move-epk' text='Samle pensjonen slik at du får færre gebyrer og bedre oversikt.' title='Flytt til Eika Pensjonskonto'>
                                <StandardButton className='open-smartspart-btn' onClick={(e)=>{ click(props, state, setState, e, Constant.actionApneSmartspar, 'action=visPensjonssparing')}}>
                                    { props.appTexts.get('aapneSmartspar') }
                                </StandardButton>
                            </Commercial>
                        </div>
                    </>
                }]}/>
            </div>

            <Modal modalSize={ModalSize.LARGE} id='epk-move-modal' appNamespace='sparing-react-app' onClose={() => { closeDialog(state, setState); }} show={!!state.dialog}>
                { state.dialog === 'signing' && state.signingUrl &&
                    <SigningIframe url={state.signingUrl} callback={ (status: string) => { updateSigningStatus(props, state, setState, status, reducer); }} />
                }

                { state.dialog === Constant.signingSuccess &&
                    <EPKmoveOnSigningFinish
                        {...props}
                        orderStorage={{email: reducer.SpareProfil?.kundeInfo?.epost }}
                        click={(e:MouseEvent, k='') =>{ click(props, state, setState, e, k ); }}
                        nextText={props.appTexts.get('finish')}
                        btnAction='close-dialog'
                    />
                }

                { state.dialog === Constant.signingError  && <EPKmoveOnSigningFailed {...props} nextText={props.appTexts.get('lukk')} btnAction='close-dialog' click={(e:MouseEvent, k='') =>{ click(props, state, setState, e, k ); }} /> }
                { state.dialog === Constant.signingCancel && <EPKmoveOnSigningRejected {...props} nextText={props.appTexts.get('lukk')} btnAction='close-dialog' click={(e:MouseEvent, k='') =>{ click(props, state, setState, e, k ); }} /> }
            </Modal>
        </div>
    );
};