import React from 'react';
import KjennDinKundeForm from '../components/kjenndinkunde/KjennDinKundeForm';
import '../styles/kjenndinkunde-webview.scss';

const postMessageToRNWebView = (message) => window?.ReactNativeWebView?.postMessage(JSON.stringify(message));

const KjennDinKundeRNWebView = () => {
    const handleSubmit = (result) => {
        postMessageToRNWebView({ action: 'submit_ok', result });
    };

    const handleError = (error) => {
        postMessageToRNWebView({ action: 'submit_error', error });
    };

    const handleInitialFetchDone = () => {
        postMessageToRNWebView({ action: 'initial_fetch_done' });
    };

    return (
        <div id="kjenndinkunde-standalone" className="kjenndinkunde-standalone">
            <KjennDinKundeForm
                rotNodeId="#kjenndinkunde-standalone"
                onSubmit={handleSubmit}
                onError={handleError}
                hideSpinner
                isChild={false}
                onInitialFetchDone={handleInitialFetchDone}
                standalone
            />
        </div>
    );
};

export default KjennDinKundeRNWebView;
