// @ts-nocheck // kiet fix it later

// import {Animated} from 'react-native';

const initGraphBarInfo = (state, info, multiple) => {
    let prePercent = 0; let colorIndex = 0; const
        length = info.list.length;
    info.width = state.concatnation && multiple && multiple.count ?
        (state.axis.x.max / multiple.count) : (state.axis.x.max / length);

    info.list.forEach((data, i) => {
        data.type = 'bar-path';
        data.percent = data.value / info.highest;
        data.width = info.width - (state.barSpace * 2);
        data.height = state.axis.y.max * data.percent;
        data.x = (info.width * i) + state.barSpace + state.padding.left;
        data.y = state.axis.y.max + state.padding.top;
        data.center = [data.x + (data.width / 2), data.y - (data.height / 2)];
        data.duration = state.duration;
        data.className = 'graph-bar graph-source';

        if (multiple && (multiple.count > 1 || state.concatnation) && typeof (multiple.index) === 'number') {
            if (state.concatnation) {
                data.color = data.color || state.color.list[colorIndex++];
                data.x = (info.width * multiple.index) + state.barSpace + state.padding.left;
                data.y -= state.axis.y.max * prePercent;
                data.height = (state.axis.y.max * (data.percent + prePercent)) - (state.axis.y.max * prePercent);
                data.center = [data.x + (data.width / 2), data.y - (data.height / 2)];

                data.duration /= length;
                data.delay = data.duration * i;

                prePercent += data.percent;
                data.className += ' bar-concatnation';
            } else {
                data.width /= multiple.count;
                data.x += (multiple.index * data.width);
                data.className += ' bar-multiple';
            }

            data.multiple = multiple;
        } else {
            data.color = data.color || state.color.list[info.color.i++];
        }

        data.style = {
            fill: data.color,
            stroke: state.color.default,
            strokeWidth: 1
        };

        data.animateFrom = [
            'M',
            [data.x, data.y].join(','),
            [data.x, data.y].join(','),
            [data.x + data.width, data.y].join(','),
            [data.x + data.width, data.y].join(',')
        ].join(' ');

        data.animateTo = [
            'M',
            [data.x, data.y].join(','),
            [data.x, data.y - data.height].join(','),
            [data.x + data.width, data.y - data.height].join(','),
            [data.x + data.width, data.y].join(',')
        ].join(' ');

        data.path = data.animateTo;

        if (state.animation) {
            data.animateAttributeName = 'd';
        } else {
            delete (data.animateFrom);
            delete (data.animateTo);
        }
    });
};

export default initGraphBarInfo;
