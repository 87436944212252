import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ExpandableCard from '../expandable-card';
import ValidateFunctions from '../validation-functions';
import Formattering from '../format-functions';
import NumberInput from '../common/number-input';

import * as AccountTypes from '../../domain/account-types';
import TextInput from '../common/text-input';
import ValidationMessages from '../common/validation-messages';

class LeggTilKontoFraAnnenBank extends Component {
    static propTypes = {
        texts: PropTypes.object,
        action: PropTypes.func,
        aktiv: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            navnPaaKonto: '',
            belopPaaKonto: 0,
            leggTilKontoFraAnnenBank: {
                expanded: false,
                onExpand: this.onExpand
            }
        };
    }

    onExpand = () =>
        this.setState({
            leggTilKontoFraAnnenBank: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                expanded: !this.state.leggTilKontoFraAnnenBank.expanded,
                onExpand: this.onExpand
            }
        });

    navnPaaKonto = event =>
        this.setState({
            navnPaaKonto: event.target.value,
            navnValidert: ValidateFunctions.tekstenErMellom(
                Formattering.fjernUnodvendigeMellomrom(event.target.value),
                2,
                25
            )
        });

    belopPaaKonto = event => this.setState({ belopPaaKonto: parseInt(event.target.value || 0, 10) });

    leggTilKonto = () => {
        if (ValidateFunctions.tekstenErMellom(this.state.navnPaaKonto, 2, 25)) {
            const data = {
                navn: this.state.navnPaaKonto,
                verdi: parseInt(this.state.belopPaaKonto, 10),
                bankkontoType: AccountTypes.EGENDEFINERT,
                aktiv: true
            };

            this.tilbakeStillKomponent();
            this.props.action(data);
        } else {
            this.setState({
                visHjelpeTekst: true
            });
        }
    };

    tilbakeStillKomponent = () => {
        this.state = {
            ...this.state,
            navnPaaKonto: '',
            belopPaaKonto: 0,
            visHjelpeTekst: false
        };
        this.onExpand();
    };

    render = () => (
        <ExpandableCard
            header={this.props.texts.get('leggTilKontoFraAnnenBank')}
            expandItem={this.state.leggTilKontoFraAnnenBank}
            aktiv={this.props.aktiv}
            ikon="ikon-penn-36"
        >
            <div>
                <div className="fond-skjema-seksjon">
                    <TextInput
                        id="navnPaaKonto"
                        label={this.props.texts.get('navnPaaKonto')}
                        value={this.state.navnPaaKonto}
                        onChange={this.navnPaaKonto}
                    />

                    <ValidationMessages
                        showValidationMessages={this.state.visHjelpetekst}
                        validationMessages={[
                            {
                                condition: !this.state.navnValidert,
                                message: this.props.texts.get('kontoNavnHjelpeTekst')
                            }
                        ]}
                    />
                </div>
                <div className="fond-skjema-seksjon">
                    <NumberInput
                        id="belopPaaKonto"
                        label={this.props.texts.get('belopPaaKonto')}
                        value={this.state.belopPaaKonto}
                        onChange={this.belopPaaKonto}
                    />
                </div>
                <div className="expandable-card-footer">
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button className="btn btn-flat" onClick={this.tilbakeStillKomponent}>
                        {this.props.texts.get('avbryt')}
                    </button>
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button className="btn btn-primary" onClick={this.leggTilKonto}>
                        {this.props.texts.get('leggTilKonto')}
                    </button>
                </div>
            </div>
        </ExpandableCard>
    );
}

export default LeggTilKontoFraAnnenBank;
