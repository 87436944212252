import React, { useEffect, useState } from 'react';
import { StandardButton } from '@eika/button';
import Chart from '../../../common/Chart/Chart';
import Card from '../../../common/Card/Card';
import Slider from '../../../common/Slider/Slider';
import { Spinner } from '../../../common/share/ShareComponents';
import { getPageAppending } from '../../../common/util/Functions';
import { getNewFondOrder } from '../../../common/util/FondFunctions';
import Constant from '../../../static/data/Constant';
import './HurtigKjop.scss';

interface Props {
    // eslint-disable-next-line
    history: any;
    action: any;
};

interface State {
    appending: string;
    progess: any;
    bar: any;
    note: any;
    info: any;
};

const click = ( props: Props, state: State, setState: (s: State) => void, e: any, key='' ): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === 'hurtig-gjenkjop' ) {
        const order = getNewFondOrder({
            myFund: true, // fund id list,
            placement: '2407080',
            bankAccount: '05403229298'
        });
        props.history.push(`hurtigkjop/fondhandel/${order}/step0?action=${Constant.piwikActionBuy}`);  
    }
};

export default (props: Props): JSX.Element => {
    // eslint-disable-next-line
    const [state, setState] = useState<State>({
        appending: getPageAppending( props ),
        progess: {
            type: 'progress',
            data: [{value: 40, color: '#97c5ca'}],
            textList: [
                {text: '42%', type: 'value-swing -positive'}, 
                {text: '59 076', type: 'font-bold'}, 
                {text: 'Totalt verdiøkning', type: 'font-normal'}
            ],
            config: { noText: true, progressStroke: [6, 50], progressBaseColor: '#97c5ca' },
        },
        info: {
            url: `${window.location.href.replace(/\/+$/g, '')}/dinefond`,
            textList: [{text:'DINE FOND', type: 'dine-fond-text'}, {text: '224 370 kr', type: 'dine-fond-value'}],
        },
        bar: {
            type: 'bar',
            barSpace: 6,
            animation: true,
            view: [750, 300],
            data: [
                {value: 10, color: '#e0e7e7' },
                {value: 20, color: '#e0e7e7' },
                {value: 40, color: '#e0e7e7' },
                {value: 40, color: '#bfcdce' },
                {value: 5, color: '#bfcdce' },
                {value: 5, color: '#bfcdce' },
                {value: 20, color: '#4e7476' },
                {value: 30, color: '#4e7476' },
                {value: 10, color: '#4e7476' },
                {value: 5, color: '#00383d' },
                {value: 50, color: '#00383d' },
                {value: 20, color: '#00383d' },
            ],
            padding: { top: 10, left: 10, bottom: 40, right: 60 },
            colorList: ['#00383d'],
            xAxis: { grid: 5, text: [{text: 'Feb 2021'},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: 'Feb 2022'},] },
            // yAxis: { grid: 0, separation: 5, unit: ' kr' }
            yAxis: { grid: 0, separationLine: false, separation: 5, color: '#000', unit: '%', toRight: true, trimNumber: 0, size: 18, stroke: 'rgba(255,255,255,0)' },
        },
        note: {
            url: `${window.location.href.replace(/\/+$/g, '')}/note`,
            textList: [
                {text:'Sist sparte du 100 kr', type: 'note-text'}, 
                {text: 'Spar i fond i KLP', type: 'note-text'},
                {text: 'Aksjeglobal Index V igjen!', type: 'note-text'}
            ],
        },
    });
    
    useEffect(() => {
        props.action.setPageTitle('Hurtig gjenkjøp');
    }, [props.action]);

    const loading = false;

    return (
        <div className="hurtig-kjop-wrapper">
            <section aria-label="Hurtig kjøp informasjon" className="sgw-content">
                <div className="content-max-width">                    
                    <div className={`fond-content ${state.appending}`}>
                        { loading ? <Spinner type="fond" /> : <>
                            <div className="graph-progress-wrapper">
                                <Chart {...state.progess }/>
                            </div>

                            <div className="sett-inn-penger-wrapper" style={{display: 'none'}}>
                                <StandardButton onClick={(e)=>{ click(props, state, setState, e, 'hurtig-gjenkjop'); }}>Spar i fond</StandardButton>
                            </div>

                            <div className="dine-fond-wrapper">
                                <Card data={state.info}>
                                    <div className="char-bar-wrapper">
                                        <Chart {...state.bar }/>
                                    </div>
                                </Card>
                            </div>

                            <div className="note-wrapper">
                                <Card data={state.note} />
                            </div>


                            <Slider label="test" round={10} change={()=> {}} adjustmentButton={[
                                {step: 10, label: 'Mink 100'},
                                {step: 10, label: 'Økt 100'},
                            ]}/>
                        </>}
                    </div>
                </div>
            </section>
        </div>
    );
};