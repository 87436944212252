/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import IndividuellPensjonsparingVilkaar from '../../components/vilkaar/individuell-pensjonssparings-vilkaar-dokument';
import { settSideTittel } from '../../actions/header';
import Card from '../../components/card';

require('../../styles/spare-profil.scss');

class IpsProfil extends Component {
    static propTypes = {
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
    };

    UNSAFE_componentWillMount = () => this.props.settSideTittel(this.props.texts.get('ipsProfil'));

    render = () => (
        <Card>
            <IndividuellPensjonsparingVilkaar />
        </Card>
    );
}

export default connect(
    (state) => {
        return {
            ...state.Texts,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
        };
    }
)(IpsProfil);
