import React, { useState } from "react";
import { Button } from "@eika/button";
import { Checkbox } from "@eika/checkbox";
import { TermContext } from "./term-context";
import FinanceLaw from "./finance-law";
import { Term } from "../../domain/Types";
import GDPR from "./gdpr";
import GeneralTerms from "./general-terms";
import "./consents.scss";

type Props = {
	children: React.ReactNode;
	terms: Term[];
	submitTerms: () => void;
};

export default function Consents(props: Props) {
	const [checked, setChecked] = useState(false);
	const handleCheck = () => setChecked(!checked);

	return (
		<TermContext.Provider value={props.terms}>
			<article className="samtykker-wrapper -term-of-use">
				<div className="content-max-width">
					{props.children}
					<Checkbox
						checked={checked}
						onChange={handleCheck}
						label="Jeg bekrefter å ha lest vilkårene, og aksepterer disse som
						gjeldende for mitt kundeforhold med Eika Kapitalforvalting AS."
					/>
					<div className="btn-accept-terms">
						<Button
							title="Jeg godtar"
							onClick={() => props.submitTerms()}
							disabled={!checked}
						>
							Jeg samtykker
						</Button>
					</div>
				</div>
			</article>
		</TermContext.Provider>
	);
}

Consents.GeneralTerms = GeneralTerms;
Consents.FinanceLaw = FinanceLaw;
Consents.GDPR = GDPR;
