import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import Message from '../Message/Message';
import { getDrawDayList } from '../util/Functions';
import './DrawDayTable.scss';

interface Props {
    click: (e: MouseEvent, a: string, d?: any, f?: any) => void;
    selected: string;
    action: string;
    title: string;
    description: string;
};

export default ({click, selected, action, title, description }: Props): JSX.Element => {
    const list = getDrawDayList();
    return (
        <div className="draw-day-table-wrapper">
            { !!title && <h1 className="paragraph">{title}</h1> }

            <ul>
                {list.map( (data) => {
                    const attr = {
                        'aria-selected': data.id === selected,
                        'aria-label': data.ariaLabel || data.text,
                    };

                    return <li key={`draw-day-${data.id}`} role="presentation">
                        <a href="#" role="button" {...attr}
                            className={classNames('day-btn', {'-active': data.id === selected})}
                            onClick={ (e) => {click(e, action, data);}}
                        ><span>{data.id}</span></a>
                    </li>
                })}
            </ul>

            { !!description && <Message type="info-message" role="" text={description} />}
        </div>
    );
};