import React, { MouseEvent, useMemo } from 'react';
import { Icon, IconTypes } from '@eika/icon';
import { Link } from '@eika/link';
import classnames from 'classnames';
import '@eika/link-panel-style';
import { LargeLinkPanelCardProps } from '@eika/link-panel';
import { StandardButton } from '@eika/button';

interface HeaderLevelProps {
    headerLevel: 'h2' | 'h3';
    text: string;
}

type LargeLinkCardWithActionProps = LargeLinkPanelCardProps & { action?: (e: MouseEvent) => void };

const LargeLinkCardHeader: React.FC<HeaderLevelProps> = ({ headerLevel, text }) => {
    const HeaderTag = `${headerLevel}` as 'h2' | 'h3';

    return <HeaderTag className="sgw-largelink-card__header">{text}</HeaderTag>;
};

export const LargeLinkCardWithAction: React.FC<LargeLinkCardWithActionProps> = ({
    url,
    buttonText,
    buttonType = 'positive',
    buttonInverted = false,
    headerLevel = 'h3',
    title,
    text,
    icon,
    background = 'light',
    className,
    children,
    action,
    ...rest
}) => {
    const labelId = useMemo(() => `_${Math.random().toString(36).substring(2, 9)}`, []);
    return (
        <article
            className={classnames(
                'sgw-largelink-card',
                `style--${background}`,
                { 'with--illustration': !!children },
                className
            )}
            {...rest}
            aria-labelledby={labelId}
        >
            {children && <div className="sgw-largelink-card__children">{children}</div>}
            <div className="sgw-largelink-card__wrapper">
                <div className="sgw-largelink-card__content">
                    {icon && (
                        <span className="card-icon__wrapper">
                            <Icon type={icon} className="card-icon icon--36" />
                        </span>
                    )}
                    <LargeLinkCardHeader headerLevel={headerLevel} text={title} />
                    <p className="typography-paragraph">{text}</p>
                </div>
                {url && buttonText && (
                    <div className="sgw-largelink-card__cta">
                        <Link
                            href={url}
                            linkType={buttonType === 'negative' ? 'button-negative' : 'button-positive'}
                            inverted={buttonInverted}
                            size="large"
                        >
                            {buttonText}
                        </Link>
                    </div>
                )}
                {url && !buttonText && (
                    <div className="sgw-largelink-card__cta">
                        <Link
                            href={url}
                            linkType="icon"
                            inverted={buttonInverted}
                            size="large"
                            icon={IconTypes.PIL_HOEYRE}
                        />
                    </div>
                )}
                {action && (
                    <div className="sgw-largelink-card__cta">
                        <StandardButton
                            onClick={action}
                            buttonType={buttonType === 'negative' ? 'button-negative' : 'button-positive'}
                            inverted={buttonInverted}
                            size="large"
                        >
                            {buttonText}
                        </StandardButton>
                    </div>
                )}
            </div>
        </article>
    );
};
export default LargeLinkCardWithAction;
