import React, { FC, useState } from 'react';
import { StandardButton } from '@eika/button';
import { ButtonToggles, RadioButtonPanels } from '@eika/radio-button';
import { Link } from '@eika/link';
import { Modal } from '@eika/modal';
import { IconTypes } from '@eika/icon';
import FlexTextHolder from '../FlexTextHolder/FlexTextHolder';
import FundDetail from '../FundDetail/FundDetail';
import CalculatorLayout from '../../components/Kalkulator/KalkulatorLayout';
import { generateId } from '../util/Functions';
import Message from '../Message/Message';
import './FundSuggestion.scss';

interface Props {
    appTexts: any;
    fundList?: any;
    formState?: string;
    suggestedCallback?: (d: any) => void;
    title?: string;
    appMessage?: JSX.Element | null;
    hideTextCard?: boolean;
}

interface State {
    id: string;
    inputList: any;
    suggestion: any;
    storage: any;
    fondListUrl: string;
    suggested?: any;
    selected?: string;
    fund?: any;
}

const updateStateSelected = (state: State, selected: string): void => {
    const suggested = state.suggestion[selected];
    if (suggested && state.storage[suggested]) {
        state.suggested = state.storage[suggested];
        if (state.suggested.optionList) {
            state.selected = '';
        } else {
            state.selected = suggested;
        }
    }
};

const changeOption = (props: Props, state: State, setState: (s: State) => void, data: any): void => {
    const inputList = JSON.parse(JSON.stringify(state.inputList));
    const found = inputList.find((input: any) => input.name === data.name);
    if (!found) {
        return;
    }

    delete found.error;
    found.selected = data.value;
    const update = { ...state, inputList };

    const id = inputList.map((d: any) => d.selected).join('-');
    updateStateSelected(update, id);
    setState(update);
};

const click = (props: Props, state: State, setState: (s: State) => void, e: any, key = '', data?: any): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if (key === 'selected-option' && data) {
        changeOption(props, state, setState, data);
    } else if (key === 'use-suggested-option' && data) {
        setState({ ...state, selected: data.value });
    } else if (key === 'about-fund' && data) {
        setState({ ...state, fund: data.fund });
    } else if (key === 'close-modal') {
        setState({ ...state, fund: null });
    } else if (key === 'use-fund' && typeof props.suggestedCallback === 'function') {
        const formState = state.inputList.map((d: any) => d.selected).join('-');
        const isin = state.selected || (data ? data.value || data.fund?.isin : '');
        if (isin) {
            props.suggestedCallback({ isin, formState });
        }
    }
};

export const getFondListUrl = () => {
    const url = window.location.href;
    if (/kjopfond\/veileder/i.test(url)) {
        return url.replace('veileder', 'alle');
    }
    return `${url.split('#')[0]}#/portefolje/fondliste`;
};

const initState = (props: Props) => {
    const selected = (props.formState || '').split('-');
    const pin = (props.fundList || []).reduce((p: { [k: string]: any }, fund: any) => {
        p[fund.isin] = fund;
        return p;
    }, {});

    const state: State = {
        id: generateId('fund-suggestion'),
        fondListUrl: getFondListUrl(),
        inputList: [
            {
                name: 'year',
                type: 'RadioButtonPanels',
                legend: 'Hvor lenge vil du spare?',
                selected: selected[0] || '',
                optionList: [
                    {
                        value: '1',
                        label: 'Under 2 år',
                    },
                    {
                        value: '2',
                        label: '2-5 år',
                    },
                    {
                        value: '3',
                        label: 'over 5 år',
                    },
                ],
                error: '',
                requiredMessage: 'Valget er påkrevd',
            },
            {
                name: 'risk',
                type: 'RadioButtonPanels',
                legend: 'Hva er mest viktig for deg?',
                selected: selected[1] || '',
                optionList: [
                    {
                        value: '1',
                        // label: props.appTexts.get('mestMuligVerdiOkning'),
                        // description: props.appTexts.get('mestMuligVerdiOkningBeskrivelse'),
                        label: 'Mest mulig verdiøkning',
                        description:
                            'For å oppnå dette forstår jeg at det kan gå mye opp og ned underveis. Går det mye ned så stoler jeg på at det går bra på lang sikt.',
                    },
                    {
                        value: '2',
                        // label: props.appTexts.get('enKombinasjon'),
                        // description: props.appTexts.get('enKombinasjonBeskrivelse'),
                        label: 'En kombinasjon',
                        description:
                            'Jeg vil ha noe høyere verdistigning enn på sparekonto og aksepterer at verdien går noe opp og ned underveis.',
                    },
                    {
                        value: '3',
                        // label: props.appTexts.get('stabilitet'),
                        // description: props.appTexts.get('stabilitetBeskrivelse'),
                        label: 'Stabilitet',
                        description:
                            'At verdien på sparingen går lite opp og ned underveis. Jeg har ikke mage til å sitte igjennom turbulente tider.',
                    },
                ],
                error: '',
                requiredMessage: 'Valget er påkrevd',
            },
        ],
        suggestion: {
            '1-1': 'NO0010165335', // Eika balansert
            '1-2': 'NO0010172521', // Eika pengemarked => Eika Rente+
            '1-3': 'NO0010172521',
            '2-1': 'NO0010165335',
            '2-2': 'NO0010165335',
            '2-3': 'NO0010172521',
            '3-1': 'NO0010003999', // Eika spar
            '3-2': 'NO0010165335',
            '3-3': 'NO0010165335',
        },
        storage: {
            NO0010165335: {
                id: 'eika-middle-risk-fund',
                title: 'Kombinasjonsfond',
                description:
                    'Kombinasjonsfond passer for deg som ønsker å spare i aksjer og rentebærende verdipapirer.',
                hint: (
                    <p className="suggested-description">
                        {/* eslint-disable-next-line no-irregular-whitespace */}
                        Under finner du det fondet Eika Kapitalforvaltning forvalter i denne kategorien. Du finner
                        informasjon om flere kombinasjonsfond i{' '}
                        <Link className="fund-list-link" href={getFondListUrl()}>
                            fondslisten
                        </Link>
                        .
                    </p>
                ),
                optionList: [
                    {
                        value: 'NO0010165335',
                        textList: ['Eika Balansert', 'Kombinasjonsfond med middels risiko.'],
                        information: [
                            {
                                text: 'Fondet passer for deg som ønsker å spare i aksjer og rentebærende verdipapirer.',
                                type: 'checked',
                            },
                            {
                                text: 'Eika Balansert er et kombinasjonsfond som investerer i aksjer og rentepapirer hovedsakelig utstedt av nordiske selskaper ',
                                type: 'checked',
                            },
                            {
                                text: 'Fondet investerer i det nordiske aksjemarkedet som kjennetegnes av høy risiko, men også av mulighet til høy avkastning. Fondet investerer også i det norske rentemarkedet hvor kredittrisikoen med utstederne vil være den fremtredende risikoen. Fondets renteplasseringer har middels risiko og avkastningsmuligheter.',
                                type: 'checked',
                            },
                            {
                                element: (
                                    <span className="text -checked">
                                        Eika Balansert er et kombinasjonsfond forvaltet av Eika Kapitalforvaltning. Du
                                        finner informasjon om flere kombinasjonsfond på&nbsp;
                                        <Link className="fund-list-link" href={getFondListUrl()}>
                                            fondslisten
                                        </Link>
                                        .
                                    </span>
                                ),
                            },
                        ],
                        fund: pin.NO0010165335,
                    },
                ],
            },
            NO0010172521: {
                id: 'eika-low-risk-fund',
                title: 'Rentefond',
                description: 'Rentefond passer for deg som vektlegger trygghet og stabilitet.',
                hint: (
                    <p className="suggested-description">
                        Under finner du rentefondet med lav minstetegning som Eika Kapitalforvaltning forvalter i denne
                        kategorien. Du finner informasjon om flere rentefond i{' '}
                        <Link className="fund-list-link" href={getFondListUrl()}>
                            fondslisten
                        </Link>
                        .
                    </p>
                ),
                optionList: [
                    {
                        value: 'NO0010172521',
                        textList: ['Eika Rente+', 'Rentefond med lav risiko'],
                        information: [
                            { text: 'Fondet passer for deg som vektlegger trygghet og stabilitet', type: 'checked' },
                            { text: 'Eika Rente+ er et rentefond med lav risiko.', type: 'checked' },
                            {
                                text: 'Fondet har lav risiko og passer for deg som har kort tidshorisont på sparingen din.',
                                type: 'checked',
                            },
                            {
                                element: (
                                    <span className="text -checked">
                                        Eika Rente+ er et rentefond forvaltet av Eika Kapitalforvaltning. Du finner
                                        informasjon om flere rentefond på&nbsp;
                                        <Link className="fund-list-link" href={getFondListUrl()}>
                                            fondslisten
                                        </Link>
                                        .
                                    </span>
                                ),
                            },
                        ],
                        fund: pin.NO0010172521,
                    },
                ],
            },
            NO0010003999: {
                id: 'eika-high-risk-fund',
                title: 'Aksjefond',
                description:
                    'Aksjefond passer for deg som er opptatt av god langsiktig avkastning og som ønsker å spare i mer enn 5 år.',
                hint: (
                    <p className="suggested-description">
                        Under finner du de tre fondene de fleste av våre kunder kjøper i denne kategorien. Du finner
                        informasjon om flere aksjefond på{' '}
                        <Link className="fund-list-link" href={getFondListUrl()}>
                            fondslisten
                        </Link>
                        . Eika Spar og Eika Global er aktivt forvaltede fond forvaltet av Eika Kapitalforvaltning. KLP
                        AksjeGlobal Indeks P er et indeksfond forvaltet av KLP.
                    </p>
                ),
                optionList: [
                    {
                        value: 'NO0010003999',
                        textList: ['Eika Spar', 'Aksjefond med høy risiko'],
                        information: [
                            'Norge, Norden og globalt',
                            'Passer for deg som vil ta del i fremtidig verdiskaping i Norge, Norden og resten av verden.',
                        ].map((text: string) => {
                            return { text, type: 'checked' };
                        }),
                        // eslint-disable-next-line
                        fund: pin['NO0010003999'],
                    },
                    {
                        value: 'NO0010075476',
                        textList: ['Eika Global', 'Aksjefond med høy risiko'],
                        information: [
                            'Hele verden',
                            'Passer for deg som ønsker å investere i globale velutviklede aksjemarkeder.',
                        ].map((text: string) => {
                            return { text, type: 'checked' };
                        }),
                        // eslint-disable-next-line
                        fund: pin['NO0010075476'],
                    },
                    {
                        value: 'NO0010776040',
                        textList: ['KLP AksjeGlobal Indeks P', 'Aksjefond med høy risiko'],
                        information: [
                            'Hele verden',
                            'Passer for deg som ønsker å investere i et globalt indeksfond. Fondet har lavere forvaltningshonorar.',
                        ].map((text: string) => {
                            return { text, type: 'checked' };
                        }),
                        // eslint-disable-next-line
                        fund: pin['NO0010776040'],
                    },
                ],
            },
        },
    };

    for (const isin in state.storage) {
        if (!pin[isin]) {
            continue;
        }

        if (state.storage[isin].name && pin[isin].navn) {
            state.storage[isin].name = pin[isin].navn;
        }

        (state.storage[isin].optionList || []).forEach((opt: any) => {
            if (!opt.value || !opt.textList || !pin[opt.value] || !pin[isin].navn) {
                return;
            }
            opt.textList[0] = pin[opt.value].navn;
        });
    }

    if (selected) {
        updateStateSelected(state, selected.join('-'));
    }
    return state;
};

export const FundSuggestionHelperCard: FC = () => (
    <div className="buy-fund-header">
        <div className="fund-header-content">
            <div>
                <h1>Få hjelp til å velge type fond</h1>
                <p>
                    Få en generell anbefaling på hvilke typer fond du kan velge. Anbefalingen er basert på hvor lenge du
                    skal spare og hvilken risiko du ønsker å ta. I anbefalingen bruker vi fond forvaltet av Eika
                    Kapitalforvaltning og ett indeksfond forvaltet av KLP som eksempler.
                </p>
            </div>
        </div>
    </div>
);

export default (props: Props): JSX.Element => {
    const [state, setState] = useState<State>({
        ...initState(props),
    });

    return (
        <div className="buy-fund-wrapper -fund-guideline">
            {!props.hideTextCard && <FundSuggestionHelperCard />}
            <CalculatorLayout
                noTopBack
                id={state.id}
                namespace="fund-suggestion-wrapper"
                appending="none"
                loading={false}
                inputList={[]}
                error={{}}
                value={{}}
                click={() => {}}
                changeInputValue={() => {}}
                changeCheckbox={() => {}}
                changeSlider={() => {}}
                title=""
                tool={
                    <div className="fund-suggestion-selected-fund-wrapper">
                        {props.appMessage}
                        <div className="fund-suggestion-title">Fondsveileder</div>
                        <div className="fond-list-link-wrapper">
                            <Link href={state.fondListUrl}>Se alle fond</Link>
                        </div>

                        {state.inputList.map((input: any) => {
                            return (
                                <div key={input.name} className={`fund-suggestion-input-holder -${input.name}`}>
                                    {input.name === 'year' ? (
                                        <ButtonToggles
                                            label={input.legend}
                                            onChange={(e) => {
                                                click(props, state, setState, null, `selected-option`, {
                                                    value: e,
                                                    name: input.name,
                                                });
                                            }}
                                            selectedOption={input.selected}
                                            options={input.optionList}
                                            required
                                            errorMessage={input.error}
                                        />
                                    ) : (
                                        <RadioButtonPanels
                                            label={input.legend}
                                            name={input.name}
                                            onChange={(e) => {
                                                click(props, state, setState, null, `selected-option`, {
                                                    value: e,
                                                    name: input.name,
                                                });
                                            }}
                                            selectedOption={input.selected}
                                            options={input.optionList}
                                            required
                                            errorMessage={input.error}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                }
                result={
                    <>
                        {!state.suggested && (
                            <div className="empty-fund-suggested-wrapper">
                                <div className="content-text">
                                    Basert på dine svar kan vi gi deg en generell anbefaling på hvilke type fond som kan
                                    passe for din sparing.
                                </div>
                            </div>
                        )}

                        {!!state.suggested && (
                            <article
                                aria-labelledby="suggested-label"
                                className={`fund-suggested-wrapper -${state.suggested.id}`}
                            >
                                <div className="fund-suggested-content">
                                    <p id="suggested-label">Vår generelle anbefaling</p>
                                    <h2
                                        className="fund-suggested-title"
                                        id="suggested-title"
                                        aria-describedby="suggested-description"
                                    >
                                        {state.suggested.title}
                                    </h2>
                                    <p id="suggested-description">{state.suggested.description}</p>

                                    <div className="fond-list-link-wrapper">
                                        <Link href={state.fondListUrl}>Gå til fondslisten</Link>
                                    </div>

                                    {state.suggested.hint}

                                    {!state.suggested.optionList && (
                                        <>
                                            <div className="paragraph-large -only-top">
                                                <StandardButton
                                                    onClick={(e) => {
                                                        click(props, state, setState, e, 'use-fund', state.suggested);
                                                    }}
                                                >
                                                    Om fondet
                                                </StandardButton>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </article>
                        )}

                        {!!state.suggested && state.suggested.optionList && (
                            <div className="fund-suggestion-option-list-wrapper">
                                <ul className="fund-suggestion-option-list">
                                    {state.suggested.optionList.map((data: any, i: number) => {
                                        return (
                                            <li
                                                key={`fund-suggestion-option-item-${i}`}
                                                className="fund-suggestion-option-item"
                                            >
                                                {(data.textList || []).length > 0 && (
                                                    <div className="fund-suggestion-text-holder">
                                                        <FlexTextHolder text={data.textList} />
                                                    </div>
                                                )}

                                                {(data.information || []).length > 0 && (
                                                    <div className="fund-suggestion-information-holder">
                                                        <FlexTextHolder text={data.information} />
                                                    </div>
                                                )}

                                                <div className="multiple-option-tool-holder">
                                                    <StandardButton
                                                        onClick={(e) => {
                                                            click(props, state, setState, e, 'use-fund', data);
                                                        }}
                                                    >
                                                        Om fondet
                                                    </StandardButton>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </>
                }
            >
                <div className="fund-disclaimer-wrapper max-width-700 -center paragraph-large -include-top">
                    <Message
                        role="application"
                        type="InfoMessage"
                        icon={IconTypes.INFO_24}
                        text={props.appTexts.get('fundSuggestionDisclaimer')}
                    />
                </div>

                <Modal
                    id="fund-overview-filter"
                    appNamespace="sparing-react-app"
                    onClose={() => {
                        click(props, state, setState, null, 'close-modal');
                    }}
                    show={!!state.fund}
                >
                    <FundDetail {...props} data={state.fund} />
                </Modal>
            </CalculatorLayout>
        </div>
    );
};
