/**
 * Created by vagrant on 01.03.17.
 */

import React from 'react';

const GdprSamtykke = () => (
    <div className="vilkaar-tekst">
        <h2 className="h3 centered">Personvern</h2>
        <p>
            Eika Kapitalforvaltning er ansvarlig for behandling av personopplysningene som oppgis og hentes inn. Vi
            trenger dine opplysninger for å kunne oppfylle lovpålagte krav (eksempelvis til kundekontroll) og for å
            kunne gjennomføre avtalt transaksjon med deg.
        </p>
        <p>
            Vi lagrer opplysningene så lenge det er påkrevet i lov eller forskrift eller så lenge det kan oppstå et
            erstatningskrav knyttet til transaksjonen. Når du har gjennomført transaksjoner hos oss, kan vi bruke
            opplysninger om hvilke produkter du har for å tilpasse vår kommunikasjon med deg på våre nettsider.
        </p>

        <h3 className="h4">Spørsmål om personvern?</h3>
        <p>
            Informasjon om dine rettigheter rundt blant annet innsyn, samtykke, dataportabilitet, klage og sletting
            finner du på vår&nbsp;
            <a href="https://www.eika.no/personvern-cookies" target="blank" rel="noopener noreferrer" className="link font-size-regular">
                personvernside
            </a>
            .
        </p>
        <p>
            Vår personvernsrådgiver hjelper deg gjerne og kan kontaktes på e-post:&nbsp;
            <a href="mailto:personvern.forvaltning@eika.no" className="link font-size-regular">personvern.forvaltning@eika.no</a>
        </p>
    </div>
);

export default GdprSamtykke;
