import { ProcessJsonResponse, utforOrdre, checkIfJson } from './common';
import { VIS_KVITTERING } from './modals';

export const LAST_PORTEFOLJE = 'LAST_PORTEFOLJE';
export const VIS_AKSJESPAREKONTO_POPUP = 'VIS_AKSJESPAREKONTO_POPUP';
export const LAST_MORNINGSTAR_DATA = 'LAST_MORNINGSTAR_DATA';
export const HENT_GYLDIGE_PORTEFOLJER = 'HENT_GYLDIGE_PORTEFOLJER';
export const KJOP_UTFORT = 'KJOPS_STEG_FERDIG';
export const AVTALE_GIRO = 'AVTALE_GIRO';
export const HENT_HISTORIKK = 'HENT_HISTORIKK';
export const HENT_AARLIG_SPARE_BELOP = 'HENT_AARLIG_SPARE_BELOP';
export const OPPDATER_PORTEFOLJER = 'OPPDATER_PORTEFOLJER';
export const HENT_SKATTEMESSIGKOSTPRIS = 'HENT_SKATTEMESSIGKOSTPRIS';
export const HENT_ESTIMAT = 'HENT_ESTIMAT';
export const DONE = 'DONE';
export const LOADING = 'LOADING';
export const FAILURE = 'FAILURE';
export const HENT_PROGNOSE = 'HENT_PROGNOSE';
export const HENT_PORTEFOLJE = 'HENT_PORTEFOLJE';
export const HENT_DISPONERTE_PORTEFOLJE = 'HENT_DISPONERTE_PORTEFOLJE';
export const SET_VPS = 'SET_VPS';

const baseUrlFond = '/sparing-min-side-web/rest/resource/fond';
const nyttInnskuddUrl = `${baseUrlFond}/nyttinnskudd`;
const endreMndSparingUrl = `${baseUrlFond}/endrespareavtale`;
const byttFondUrl = `${baseUrlFond}/byttfond`;
const kjopFondUrl = `${baseUrlFond}/kjopfond`;
const morningStarUrl = `${baseUrlFond}/morningstar`;
// const portefoljeUrl = `${baseUrlFond}/portefolje`;
const visAksjesparekontoPopupUrl = `${baseUrlFond}/portefolje/visaksjesparekontopopup`;
const gyldigePortefoljerUrl = `${baseUrlFond}/gyldigeportefoljer`;
const historikkUrl = `${baseUrlFond}/utvikling`;
const aarligSparebelopUrl = `${baseUrlFond}/sparedata`;
const disponertePortefoljerUrl = `${baseUrlFond}/disponertePortefoljer`;
const hentPortefoljeUrl = `${baseUrlFond}/beholdning`;
const endrePortefoljeNavnUrl = `${baseUrlFond}/portefoljer`;
const flyttFondUrl = `${baseUrlFond}/flyttFond`;
const hentSkattemessigKostprisUrl = `${baseUrlFond}/skattemessigKostpris`;
const hentEstimatUrl = `${baseUrlFond}/estimat`;
const hentPrognoserUrl = `${baseUrlFond}/prognose`;

export const hentGyldigePortefoljer = (isin) => {
    return (dispatch) => {
        fetch(`${gyldigePortefoljerUrl}/${isin}`, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(HENT_GYLDIGE_PORTEFOLJER, data, dispatch, { isin })
        );
    };
};

export const getVPS = () => async (dispatch) => {
    let result = null;
    const url = `${baseUrlFond}/vps`;
    try {
        result = await fetch( url ).then( (response) => {
            return response.status === 200 ? response.json() : null;
        }).catch( () => null );
    } catch ( error ) {
        result = null;        
    }

    const vps = (result || []).filter( (d) => {
        if ( !d ) { return false; }

        if (!d.navn) { d.navn = 'VPS-konto'; }

        d.id = d.kontonummer;
        d.portfolioBaseCode = d.kontonummer;
        d.type = 'VPS';
        d.initialValue = d.verdi;
        d.totalVerdi = d.verdi;

        if ( d.totaltInnskudd === undefined ) { d.totaltInnskudd = 0; }
        if ( d.verdistigning === undefined ) { d.verdistigning = 0; }

        d.eksterneFond = d.verdipapirer || [];
        (d.eksterneFond || []).forEach( (f) => {
            f.portefolje = JSON.parse(JSON.stringify(d));
            f.navn = f.fondsnavn;
            f.totalVerdi = f.markedsverdi;
        });
        return true;
    });

    if ( vps.length ){ dispatch({ type: SET_VPS, data: vps }); }
};

export const lastPortefolje = () => async (dispatch) => {
    let result = null;
    let debitAccount = [];
    const url = `${baseUrlFond}/totalbeholdning/bank?addReachoutData=true&addPortfolioData=true&addDisponentPortfolios=true`;

    try {
        debitAccount = await fetch( `${baseUrlFond}/chargeable-accounts` ).then( (response) => {
            return response.status === 200 ? response.json() : [];
        }).catch( () => [] );
    } catch ( error ) {
        debitAccount = [];        
    }

    try {
        result = await fetch( url ).then( (response) => {
            return response.status === 200 ? response.json() : null;
        }).catch( () => null );


    } catch ( error ) {
        result = null;        
    }


    [
        {key: 'egneKundeforhold', type: LAST_PORTEFOLJE}, 
        {key: 'disponerteKundeforhold', type: HENT_DISPONERTE_PORTEFOLJE},
    ].forEach( (config) => {
        let sum = null;
        const portefoljer = ((result ||{})[config.key] || []).reduce( (list, data) => {
            (data.totalBeholdningPortefoljeList || []).forEach( (portfolio) => {
                const source = {...portfolio};
                if ( config.key === 'disponerteKundeforhold' ) {
                    source.eier = [data.fornavn || '', data.etternavn || ''].join(' ').trim();
                }

                if ( typeof(source.initialValue) === 'number' ) {
                    sum = (sum || 0) + (source.initialValue || 0);
                }

                const ipsNotAllowMonthlySaving = source.portefoljeType === 'IPS' && !!(source.totalBeholdningFondList || []).find( (fond) => {
                    return (fond.spareavtaler || []).length > 0;
                });

                list.push({
                    ...source, 
                    bank: {
                        ...source.bank,
                        bankregnr: source.bank.bankregnr.value,
                    },
                    type: source.portefoljeType,
                    urealisertGevinst: source.verdistigning,
                    innskudd: source.totaltInnskudd,
                    totalVerdi: source.initialValue,
                    fond: (source.totalBeholdningFondList || []).map( (fond) => {
                        return {
                            ...fond,
                            ...(fond.fondInfo || {}),
                            maanedligSparing: (fond.spareavtaler || []).reduce( (sum, avtale) => {
                                sum += (avtale?.trekkBelop);
                                return sum;
                            }, 0),
                            tillatteBrukervalg: [
                                'ENDRE_SPARING',
                                ipsNotAllowMonthlySaving ? '' : 'NY_SPARING',
                                'NYTT_INNSKUDD',
                                'SALG',
                                /^eika\s/i.test( (fond.fondInfo || {}).navn ) ? 'BYTT' : '',
                            ].filter( (v) => !!v),
                            andeler: null,
                        };
                    }) 
                });
            });
            return list;

        }, []);

        if ( config.key === 'egneKundeforhold' ) {
            dispatch({ type: config.type, data: {
                portefoljer,
                innskudd: 0,
                harASKKompatibleFondIEVPK: false,
                kontantBeholdning: 0,
                portefoljeTilknyttetAnnenBank: false,
                totalVerdi: sum,
                urealisertGevinst: 0,
                visOpprettAksjesparekontoModal: false,
                kontoer: debitAccount,
            } });
        } else if ( config.key === 'disponerteKundeforhold' ) {
            dispatch({ type: config.type, data: portefoljer});
        }
    });
};

export const setLoading = () => {
    return { type: LOADING };
};

export const hentPortefolje = (portefoljeId) => {
    const url = `${hentPortefoljeUrl}/disponertPortefolje/${portefoljeId}`;
    return (dispatch) => {
        fetch(url, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(HENT_PORTEFOLJE, data, dispatch, { portefoljeId })
        );
    };
};

export const hentDisponertePortefoljer = () => {
    return (dispatch) => {
        fetch(disponertePortefoljerUrl, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(HENT_DISPONERTE_PORTEFOLJE, data, dispatch)
        );
    };
};

export const hentSkattemessigKostpris = (portfolioBaseCode, bankregnr) => {
    const config = {
        credentials: 'same-origin',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        dispatch({
            type: HENT_SKATTEMESSIGKOSTPRIS,
            data: {
                portfolioBaseCode,
                status: LOADING,
            },
        });

        fetch(`${hentSkattemessigKostprisUrl}/${portfolioBaseCode}/${bankregnr}`, config).then((response) => {
            if (response.ok && checkIfJson(response)) {
                response.json().then((data) => {
                    dispatch({
                        type: HENT_SKATTEMESSIGKOSTPRIS,
                        data: {
                            ...data,
                            status: DONE,
                            portfolioBaseCode,
                        },
                    });
                });
            } else {
                dispatch({
                    type: HENT_SKATTEMESSIGKOSTPRIS,
                    data: {
                        status: FAILURE,
                        portfolioBaseCode,
                    },
                });
            }
        });
    };
};

export const lastMorningstarData = () => {
    return (dispatch) => {
        fetch(morningStarUrl, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(LAST_MORNINGSTAR_DATA, data, dispatch)
        );
    };
};

const paaVellykketFondsOrdre = (data, dispatch) => {
    const { avtalegiroUrl, avtalegiroObject, ordreResultater } = data;

    dispatch({
        type: VIS_KVITTERING,
        avtalegiroUrl,
        avtalegiroObject,
        ordreResultater,
    });

    if (avtalegiroUrl || avtalegiroObject) {
        dispatch({
            type: AVTALE_GIRO,
            ...data,
        });
    }
};

export const nyttInnskudd = (data) => {
    return utforOrdre(data, nyttInnskuddUrl, 'POST', paaVellykketFondsOrdre);
};

export const endreMndSparing = (data) => {
    return utforOrdre(data, endreMndSparingUrl, 'PUT', paaVellykketFondsOrdre);
};

const mapPortefoljeNavnToArray = (portefoljeNavn) => {
    const array = [];
    for (const key in portefoljeNavn) {
        if (Object.prototype.hasOwnProperty.call(portefoljeNavn, key) && portefoljeNavn[key] !== undefined) {
            array.push({
                id: key,
                name: portefoljeNavn[key].name,
                bankregnr: { value: portefoljeNavn[key].bankregnr },
            });
        }
    }
    return array;
};

export const endrePortefoljeNavn = (portefoljeNavn) => {
    const array = mapPortefoljeNavnToArray(portefoljeNavn);

    const config = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(array),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        fetch(endrePortefoljeNavnUrl, config).then((data) => {
            if (checkIfJson(data)) {
                data.json().then((data) => {
                    dispatch({
                        type: OPPDATER_PORTEFOLJER,
                        data,
                    });
                });
            }
        });
    };
};

const oppdaterPortefoljer = (data, dispatch) => {
    dispatch({
        type: VIS_KVITTERING,
    });

    dispatch({
        type: OPPDATER_PORTEFOLJER,
        data,
    });
};

export const flyttFond = (nomineeValg) => {
    return utforOrdre(nomineeValg, flyttFondUrl, 'PUT', oppdaterPortefoljer);
};

export const hentEstimat = (isin, monthlyDeposit, duration) => {
    const config = {
        credentials: 'same-origin',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        return fetch(`${hentEstimatUrl}/${isin}/${monthlyDeposit}/${duration}`, config).then((response) => {
            if (response.ok && checkIfJson(response)) {
                response.json().then((data) => {
                    dispatch({
                        type: HENT_ESTIMAT,
                        data,
                    });
                });
            } else {
                // dispatch({});
            }
        });
    };
};

export const hentPrognose = (data) => {
    const config = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        return fetch(hentPrognoserUrl, config).then((response) => {
            if (response.ok && checkIfJson(response)) {
                response.json().then((data) => {
                    dispatch({
                        type: HENT_PROGNOSE,
                        data,
                    });
                });
            } else {
                // dispatch({});
            }
        });
    };
};

export const byttFond = (data) => {
    return utforOrdre(data, byttFondUrl, 'POST', paaVellykketFondsOrdre);
};

export const kjopFond = (kjopsOrdre) => {
    return utforOrdre(kjopsOrdre, kjopFondUrl, 'POST', paaVellykketFondsOrdre);
};

export const hentAarligSparebelop = (portfolioBaseCode) => {
    const url = `${aarligSparebelopUrl}/${portfolioBaseCode}`;

    return (dispatch) => {
        fetch(url, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(HENT_AARLIG_SPARE_BELOP, data, dispatch, { portfolioBaseCode })
        );
    };
};

export const hentHistorikk = (portfolioBaseCode, isin) => {
    const url = `${historikkUrl}/${portfolioBaseCode}/${isin}`;

    return (dispatch) => {
        fetch(url, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(HENT_HISTORIKK, data, dispatch, { portfolioBaseCode, isin })
        );
    };
};

export const hentVisAksjesparekontoPopup = () => {
    return (dispatch) => {
        fetch(visAksjesparekontoPopupUrl, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(VIS_AKSJESPAREKONTO_POPUP, data, dispatch)
        );
    };
};

export const getFondHistorikkUtvikling = (isin, length, type, callback) => async () => {
    let result = null;
    const url = `${baseUrlFond}/historisk/${isin}/${length}/${type}`;
    try {
        result = await fetch( url ).then( (response) => {
            return response.status === 200 ? response.json() : null;
        }).catch( () => null );
    } catch ( error ) {
        result = null;        
    }

    if ( callback ) { callback(result); }
};


export const getFondKalkulator = ( data, callback ) => async () => {
    let result = null;
    const { isin='', monthly=0, onetime=0, frequency='', point=5 } = data;
    if ( !isin || (!monthly && !onetime) ) {
        return typeof(callback) === 'function' ? callback( result ) : null;
    }

    const start = parseInt(`${monthly}`, 10) + parseInt(`${onetime}`, 10);
    const param = [
        `maanedligSparing=${monthly}`,
        `frekvens=${/^DAGLIG|MAANEDLIG|AARLIG^/.test(frequency) ? frequency : 'AARLIG'}`,
        `punkter=${point}`
    ];

    const base = '/sparing-min-side-web/rest/resource';
    const url = `${base}/kalkulator/forventet-utvikling/${isin}/${start}?${param.join('&')}`;

    try {
        result = await fetch( url ).then( (response) => {
            if ( response.status !== 200 ) { return null; }
            return response.json();
        }).catch( () => null );
    } catch ( error ) {
        result = null;
    }

    if ( callback ) { callback(result); }
};
