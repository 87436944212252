// import React from 'react';
export default () => {
    return null;
}
/*
import { Route, HashRouter, Switch } from 'react-router-dom';

import KjopFond from './fond/kjop-fond/kjop-fond';
import KjopFondVeiviser from './fond/kjop-fond/kjop-fond-veiviser';
import KjopFondLoginCheck from './fond/kjop-fond/kjop-fond-login-check';
import FondKjopAapneSiderKvittering from './fond/fond-kjoep-aapne-sider-kvittering';

import KjoepFond from './fond-kjoep';
import KjopFondInnganger from './fond/kjop-fond/kjop-fond-innganger';
import EKFfondKalkulator from './EKFfond/EKFfondKalkulator';

export default (ownProps) => {
    return (
        <HashRouter>
            <KjoepFond>
                <Switch>
                    <Route exact path="/" component={(props) => <KjopFondInnganger {...ownProps} {...props} standalone />} />
                    <Route exact path="/kjopveileder" component={(props) => <KjopFondVeiviser {...ownProps} {...props} />} />
                    <Route
                        exact
                        path="/kjopFond/:fondIsin"
                        component={(props) => {
                            return (
                                <KjopFondLoginCheck {...ownProps} {...props}>
                                    <KjopFond {...ownProps} {...props} standalone/>
                                </KjopFondLoginCheck>
                            );
                        }}
                    />
                    <Route
                        path="/kjopFondSparemaal/:fondIsin"
                        component={(props) => {
                            return (
                                <KjopFondLoginCheck {...ownProps} {...props}>
                                    <KjopFond {...ownProps} {...props} standalone />
                                </KjopFondLoginCheck>
                            );
                        }}
                    />
                    <Route
                        exact
                        path="/kjopfond"
                        component={(props) => <KjopFondInnganger {...ownProps} {...props} standalone/>}
                    />
                    <Route
                        exact
                        path="/kjopfond/fond/alle"
                        component={(props) => <KjopFondInnganger {...ownProps} {...props} standalone/>}
                    />
                    <Route
                        exact
                        path="/fond"
                        component={(props) => <FondKjopAapneSiderKvittering {...ownProps} {...props} />}
                    />
                    <Route
                        exact
                        path="/sparekalkulator"
                        component={(props) => <EKFfondKalkulator {...ownProps} {...props} />}
                    />
                </Switch>
            </KjoepFond>
        </HashRouter>
    );
};
*/
