import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HelperFunctions from '../../helper-functions';
import VelgKonto from '../velg-konto';
import Checkbox from '../../checkbox';
import Epost from '../../common/epost';
import Format from '../../format-functions';
import ValidationMessages from '../../common/validation-messages';
import NumberInput from '../../common/number-input';
import SelgSparemaal from './selg-sparemaal';
import { KLIENTKONTO } from '../../../domain/account-types';

class SelgFondSkjema extends Component {
    static propTypes = {
        portefoljeId: PropTypes.string.isRequired,
        bankregnr: PropTypes.string.isRequired,
        kontoer: PropTypes.array.isRequired,
        texts: PropTypes.object.isRequired,
        fond: PropTypes.object.isRequired,
        portefoljeType: PropTypes.string.isRequired,
        epost: PropTypes.string.isRequired,
        settEpost: PropTypes.func.isRequired,
        opprettSalgsOrdre: PropTypes.func.isRequired,
        settKvitteringsInnhold: PropTypes.func.isRequired,
        resetForm: PropTypes.func.isRequired,
        sparemaal: PropTypes.array.isRequired,
        foreignCurrencyForceToSellAll: PropTypes.bool,
        fundCurrency: PropTypes.string,
    };

    constructor(props) {
        super(props);


        const standardVerdier = {
            engangsBelop: props.foreignCurrencyForceToSellAll ? (props.fond.totalVerdi || 0) : 0,
            selgHeleBeholdningen: !!props.foreignCurrencyForceToSellAll,
            prosentDuKanSelge: 0.85,
            visHjelpetekst: false,
            harKlientKonto: props.portefoljeType === 'ASK' || props.portefoljeType === 'IPS',
            sparemaalValidation: true
        };

        this.state = {
            standardVerdier,
            ...standardVerdier
        };
    }

    settBelop = event => this.setState({ engangsBelop: HelperFunctions.clamp(event.target.value, 0, 99999999) });

    endreCheckbox = (checked, id) => this.setState({ [id]: checked });

    // eslint-disable-next-line react/no-access-state-in-setstate
    resetForm = () => this.setState({ ...this.state.standardVerdier });

    avbryt = () => {
        this.resetForm();
        this.props.resetForm();
    };

    velgKonto = konto => this.setState(konto);

    submitForm = () => {
        if (this.validateFormInput() && this.state.sparemaalValidation) {
            if (this.state.epost) {
                this.props.settEpost(this.state.epost);
            }
            this.sendOrdre();
        } else {
            this.setState({
                visHjelpetekst: true
            });
        }
    };

    sendOrdre = () => {
        const data = this.createSelgFondDTO();
        this.createReceiptContent();

        this.props.opprettSalgsOrdre(data);

        this.resetForm();
    };

    createSelgFondDTO = () => ({
        portefoljeId: this.props.portefoljeId,
        isin: this.props.fond.isin,
        engangsBelop: this.state.selgHeleBeholdningen ? 0 : this.state.engangsBelop,
        kontonummer: this.state.kontonummer,
        bankkontoType: this.state.bankkontoType,
        selgHeleBeholdningen: this.state.selgHeleBeholdningen,
        epost: this.state.epost,
        bankregnr: { value: this.props.bankregnr },
        sparemaal: this.state.sparemaalsOrdre
    });

    createReceiptContent = () => {
        const kvittering = {
            type: 'selgFond',
            lasteTekst: this.props.texts.get('verifisererOrdre'),
            fondNavn: this.props.fond.navn,
            engangsBelop: this.state.selgHeleBeholdningen ? this.props.fond.totalVerdi : this.state.engangsBelop,
            kontonummer: this.state.kontonummer
        };

        this.props.settKvitteringsInnhold(kvittering);
    };

    visBelopMelding = () => {
        if ( !this.state.visBelopMelding ) { return null; }

        const key = this.props.foreignCurrencyForceToSellAll ? 'foreignCurrencyHasToSellTotalValue' : 'duKanIkkeSelgeHeleBeholdningen';
        return <div className="hjelpe-tekst">{this.props.texts.get(key)}</div>
    }

    sjekkSum = (conditional, messageKey) => (
        <ValidationMessages
            showValidationMessages={this.state.visHjelpetekst}
            validationMessages={[
                {
                    condition: !(conditional || this.state.selgHeleBeholdningen),
                    message: this.props.texts.get(messageKey)
                }
            ]}
        />
    );

    sjekkAtSumErMindreEnnX = () => {
        const validAmount = this.state.engangsBelop ? this.isAmountValid() : true;
        const key = this.props.foreignCurrencyForceToSellAll ? 'foreignCurrencyHasToSellTotalValue' : 'duKanIkkeSelgeHeleBeholdningen';
        return this.sjekkSum( validAmount, key);
    }

    sjekkAtSumErHoyereEnnX = () => this.sjekkSum(this.state.engangsBelop > 0, 'duMaaSkriveInnEnSum');

    isAmountValid = () => {
        return this.props.foreignCurrencyForceToSellAll ? 
            (this.state.engangsBelop === this.props.fond.totalVerdi) :
            (this.state.engangsBelop <= this.props.fond.totalVerdi * this.state.prosentDuKanSelge);
    }

    visBelopInput = () => (
        <NumberInput
            id="velgBelop"
            label={this.props.texts.get('velgBelop')}
            value={this.state.engangsBelop}
            onChange={this.settBelop}
            disabled={this.state.selgHeleBeholdningen}
        />
    );

    validateFormInput = () => {
        if ( this.state.engangsBelop <= 0 && !this.state.selgHeleBeholdningen ) { return false; }

        const validAmount = this.isAmountValid();
        return (validAmount || this.state.selgHeleBeholdningen) &&
            this.state.kontonummer &&
            (this.state.epost || this.props.epost) &&
            (this.state.bankkontoType === KLIENTKONTO || this.state.salgUnderstand);
    }

    checkBoxHtml = (tekst, id, func, checked, validation) => (
        <Checkbox id={id} name={id} onChange={func} checked={checked}>
            <div>
                <div>{tekst}</div>
                {validation ? validation() : ''}
            </div>
        </Checkbox>
    );

    visEpost = () => (
        <Epost
            label={this.props.texts.get('epost')}
            show={!this.props.epost}
            onChange={epost => this.setState(epost)}
            showValidationMessage={this.state.visHjelpetekst}
            validationMessage={this.props.texts.get('skrivEnGyldigEpostAdresse')}
        />
    );

    sparemaalStatus = (status, sparemaalsOrdre) => {
        this.setState({
            sparemaalValidation: status,
            sparemaalsOrdre
        });
    };

    render() {
        const { fundCurrency } = this.props;
        const { visHjelpetekst=false, bankkontoType } = this.state;
        const selgHeleBeholdningen = HelperFunctions.VariableInjection(this.props.texts.get('selgHeleBeholdningen'), {
            beholdning: Format.number(this.props.fond.totalVerdi)
        });

        return fundCurrency ? <div>
            <div className="fond-skjema-seksjon selg-fond-belop">
                {this.visBelopInput()}
                {this.checkBoxHtml(
                    selgHeleBeholdningen,
                    'selgHeleBeholdningen',
                    this.endreCheckbox,
                    this.state.selgHeleBeholdningen
                )}
                {this.sjekkAtSumErMindreEnnX()}
                {this.sjekkAtSumErHoyereEnnX()}
            </div>
            <div className="fond-skjema-seksjon">
                <VelgKonto
                    id="kontonummer"
                    tittel={this.props.texts.get('hvorSkalViOverforePenger')}
                    leggTilAnnenKonto
                    kontoer={HelperFunctions.filtrerUtAndreKlientKontoer(
                        this.props.kontoer,
                        this.props.portefoljeId
                    )}
                    onChange={konto => this.setState(konto)}
                    texts={this.props.texts}
                    visHjelpetekst={visHjelpetekst}
                />
            </div>
            <SelgSparemaal
                fond={this.props.fond}
                portefoljeId={this.props.portefoljeId}
                sparemaal={this.props.sparemaal}
                selgHeleBeholdningen={this.state.selgHeleBeholdningen}
                texts={this.props.texts}
                sellValue={this.state.engangsBelop}
                sendStatus={this.sparemaalStatus}
                visHjelpetekst={visHjelpetekst}
            />
            {this.visEpost()}

            { bankkontoType !== KLIENTKONTO  && this.checkBoxHtml(
                'Jeg forstår at salg av fond kan utløse skatt ved gevinst.',
                'salgUnderstand',
                this.endreCheckbox,
                this.state.salgUnderstand
            )}

            { bankkontoType !== KLIENTKONTO && !!visHjelpetekst && !this.state.salgUnderstand && <div className="hjelpe-tekst" aria-live="polite">
                Vennligst bekreft at du har forstått
            </div>}

            <div className="expandable-card-footer">
                <button type="button" className="btn btn-flat" onClick={this.avbryt}>
                    {this.props.texts.get('avbryt')}
                </button>
                <button type="button" className="btn btn-primary" onClick={this.submitForm}>
                    {this.props.texts.get('bekreftSalg')}
                </button>
            </div>
        </div> : <div className="message-wrapper -error" aria-live="polite">
            <div className="paragraph">
                {this.props.texts.get('preventFundSellMissingMorningstarCurrency')}
            </div>
            <div className="paragraph">
                {this.props.texts.get('tryAgainOrContact')}
            </div>
        </div>;
    }
}

export default SelgFondSkjema;
