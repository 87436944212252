import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';

import HelperFunctions from '../helper-functions';
import NumberInput from '../common/number-input';
import ValgKnapper from '../valg-knapper';
import FondAnbefaling from '../common/FondAnbefaling';
import { hentAnbefaling } from '../common/anbefalings-matrise';

class SparemaalKalkulator extends Component {
    static propTypes = {
        texts: PropTypes.object,
        sparemaal: PropTypes.object,
        morningstar: PropTypes.array,
        hentEstimat: PropTypes.func,
        fondEstimat: PropTypes.array
    };

    constructor(props) {
        super(props);

        this.state = {
            ekspanderendeKort: {
                expanded: false,
                onExpand: this.onExpand
            },
            monthlySavings: 0,
            risiko: null
        };
    }

    showRecommended = () => {
        if (!this.state.risiko) {
            return null;
        }
        if (this.props.morningstar) {
            return (
                <FondAnbefaling
                    texts={this.props.texts}
                    tidshorisont={this.props.sparemaal.savingPeriod}
                    risikoprofil={this.state.risiko}
                    monthlyDeposit={this.state.monthlySavings}
                    morningstar={this.props.morningstar}
                    hentEstimat={this.props.hentEstimat}
                    fondEstimat={this.props.fondEstimat}
                    visAnbefalingsTekst
                    visEstimat
                />
            );
        }

        return <Loading title={this.props.texts.get('lasterSparemaal')} />;
    };

    onExpand = () =>
        this.setState({
            ekspanderendeKort: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.ekspanderendeKort,
                // eslint-disable-next-line react/no-access-state-in-setstate
                expanded: !this.state.ekspanderendeKort.expanded
            }
        });

    handleEndreValg = valg => {
        if (valg) {
            const isin = hentAnbefaling(this.props.sparemaal.savingPeriod, valg);
            const fond = HelperFunctions.hentFondBasertPaaIsin(isin, this.props.morningstar);

            this.setState({
                risiko: valg,
                minimumsbelop: fond.minimumsBelopPeriodiskKjop
            });
        }
    };

    hentSparemaalSparebelopIMndLabel = () => {
        const label = this.props.texts.get('sparemaalSparebelopIMnd');
        if (this.state.minimumsbelop) {
            const belop = this.props.texts
                .get('sparemaalSparebelopIMndMinimum')
                .replace('{belop}', this.state.minimumsbelop);
            return `${label} ${belop}`;
        }
        return label;
    };

    render = () => (
        <div className="sparemaal-kalkulator">
            <div className="fond-skjema-seksjon">
                <ValgKnapper
                    tittel={`${this.props.texts.get('sparemaalVelgRisiko')}`}
                    valgMuligheter={[
                        { id: '0', tekst: 'Lav' },
                        { id: '1', tekst: 'Middels' },
                        { id: '2', tekst: 'Høy' }
                    ]}
                    valg={this.state.risiko}
                    endreValg={this.handleEndreValg}
                />
            </div>
            <div className="fond-skjema-seksjon">
                <NumberInput
                    id="monthly-savings"
                    value={this.state.monthlySavings}
                    onChange={event => this.setState({ monthlySavings: parseInt(event.target.value, 10) })}
                    label={this.hentSparemaalSparebelopIMndLabel()}
                />
            </div>

            {this.showRecommended()}
        </div>
    );
}

export default SparemaalKalkulator;
