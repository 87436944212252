import { LAST_PORTEFOLJE } from '../actions/fond';

import {
    VIS_KVITTERING,
    VIS_KVITTERING_LASTER,
    LUKK_KVITTERING,
    SETT_KVITTERINGS_INNHOLD,
    VIS_FEILMELDINGS_KVITTERING,
    SKJUL_OPPRETT_AKSJESPAREKONTO_MODAL,
    SKJUL_ENDRE_OM_MEG_MODAL,
} from '../actions/modals';

const initialState = {
    kvitteringConfig: {
        vis: false,
        laster: false,
    },
    visOpprettAksjesparekontoModal: true,
    visEndreOmMegModal: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LAST_PORTEFOLJE:
            return {
                ...state,
                visOpprettAksjesparekontoModal: action.data.visOpprettAksjesparekontoModal,
            };
        case SKJUL_OPPRETT_AKSJESPAREKONTO_MODAL:
            return {
                ...state,
                visOpprettAksjesparekontoModal: false,
            };
        case SKJUL_ENDRE_OM_MEG_MODAL:
            return {
                ...state,
                visEndreOmMegModal: false,
            };
        case VIS_KVITTERING:
            return {
                ...state,
                kvitteringConfig: {
                    vis: true,
                    laster: false,
                    avtalegiroUrl: action.avtalegiroUrl,
                    avtalegiroObject: action.avtalegiroObject,
                    skjulLukkKnapp: !!action.avtalegiroObject,
                },
                kvitteringsInnhold: {
                    ...state.kvitteringsInnhold,
                    ordreResultater: action.ordreResultater,
                },
            };
        case VIS_FEILMELDINGS_KVITTERING:
            return {
                ...state,
                kvitteringConfig: {
                    vis: true,
                    laster: false,
                },
                kvitteringsInnhold: {
                    type: 'feilmelding',
                    melding: action.meldingFraServer,
                },
            };

        case SETT_KVITTERINGS_INNHOLD:
            return {
                ...state,
                kvitteringsInnhold: action.innhold,
            };
        case LUKK_KVITTERING:
            window.location.reload();
            return {
                ...state,
                kvitteringConfig: {
                    vis: false,
                    laster: false,
                },
            };
        case VIS_KVITTERING_LASTER:
            return {
                ...state,
                kvitteringConfig: {
                    vis: true,
                    laster: true,
                },
            };
        default:
            return state;
    }
};
