/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IframeResizer from '../IframeResizer';

class MorningStarIFrame extends Component {
    static propTypes = {
        fondId: PropTypes.string
    };

    render() {
        const iframeResizerOptions = {
            checkOrigin: false,
            warningTimeout: 0
        };

        return (
            <IframeResizer
                iframeResizerOptions={iframeResizerOptions}
                iframeResizerUrl={false}
                id={this.props.fondId}
                className="morning-star-iframe"
                key={this.props.fondId}
                src={`https://lt.morningstar.com/njtrrc6eo8/snapshot/default.aspx?tab=0
                &SecurityToken=${this.props.fondId}]2]1]FONOR%24%24ALL_434
                &Id=${this.props.fondId}&ClientFund=1&CurrencyId=NOK&theme=green`}
            />
        );
    }
}

export default MorningStarIFrame;
