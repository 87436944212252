import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HelperFunctions from '../helper-functions';
import ExpandableCard from '../expandable-card';
import Epost from '../common/epost';
import ValidationMessages from '../common/validation-messages';
import VelgKonto from './velg-konto';
import NumberInput from '../common/number-input';
import KjennDinKundeForm from '../kjenndinkunde/KjennDinKundeForm';
import Modal from '../modal';
import * as kundevurderingConst from '../../domain/kundevurdering';
import KundevurderingStopp from '../KundevurderingStopp';
import FeeMessage from '../FeeMessage';
import ForeignCurrencyMessage from '../ForeignCurrencyMessage';

class NyttInnskudd extends Component {
    static propTypes = {
        portefoljeId: PropTypes.string.isRequired,
        morningstar: PropTypes.array.isRequired,
        kontoer: PropTypes.array.isRequired,
        texts: PropTypes.object.isRequired,
        fond: PropTypes.object.isRequired,
        expandItem: PropTypes.object.isRequired,
        action: PropTypes.func.isRequired,
        settKvitteringsInnhold: PropTypes.func.isRequired,
        epost: PropTypes.string.isRequired,
        settEpost: PropTypes.func.isRequired,
        bankregnr: PropTypes.string.isRequired,
        kundevurdering: PropTypes.string,
        sperre: PropTypes.string,        
    };

    constructor(props) {
        super(props);

        const standardVerdier = {
            engangsBelop: 0,
            kontonummer: undefined,
            visHjelpetekst: false,
        };

        this.state = {
            visAHV: false,
            ahvError: null,
            standardVerdier,
            ...standardVerdier
        };
    }

    endreEngangsBelop = event => {
        const engangsBelop = HelperFunctions.clamp(event.target.value, 0, 99999999);

        this.setState({
            engangsBelop
        });
    };

    submitForm = () => {
        if (this.validateFormInput()) {
            this.setState({ visAHV: true });
        } else {
            this.setState({
                visHjelpetekst: true
            });
        }
    };

    closeAHV = () => {
        this.setState({visAHV: false, ahvError: null});
    };

    submitAHV = ( result ) => {
        if ( /ok/i.test( result ) ) {
            if (this.state.epost) {
                this.props.settEpost(this.state.epost);
            }            
            this.createReceiptContent();
            const data = this.createNyttInnskuddDTO();
            this.setState({ visAHV: false });
            this.resetForm();
            this.props.action(data);
        } else if ( result === 'MA_BEHANDLES_MANUELT' ) {
            this.setState({ahvError:{
                title: this.props.texts.get('ma_behandles_manuelt_tittel'),
                text: this.props.texts.get('ma_behandles_manuelt_tekst'),
                link: {
                    text: this.props.texts.get('ma_behandles_manuelt_link'),
                    url: `${window.location.origin}/ansatte`
                }
            }});
        }
    };

    createNyttInnskuddDTO = () => ({
        portefoljeId: this.props.portefoljeId,
        isin: this.props.fond.isin,
        engangsBelop: this.state.engangsBelop,
        kontonummer: this.state.kontonummer,
        epost: this.state.epost,
        bankkontoType: this.state.bankkontoType,
        bankregnr: { value: this.props.bankregnr }
    });

    createReceiptContent = () => {
        const kvittering = {
            type: 'nyttEngangsBelop',
            lasteTekst: this.props.texts.get('gjorEttNyttInnskudd'),
            fond: this.props.fond.navn,
            engangsBelop: this.state.engangsBelop,
            kontonummer: this.state.kontonummer
        };

        this.props.settKvitteringsInnhold(kvittering);
    };

    resetForm = () => {
        // eslint-disable-next-line react/no-access-state-in-setstate
        this.setState({ ...this.state.standardVerdier });
        this.props.expandItem.onExpand(this.props.expandItem.id);
    };

    validateFormInput = () =>
        this.state.kontonummer &&
        (this.state.epost || this.props.epost) &&
        this.state.engangsBelop >= this.props.fond.minimumsBelopEngangsKjop;

    visEpost = () => (
        <Epost
            label={this.props.texts.get('epost')}
            show={!this.props.epost}
            onChange={epost => this.setState(epost)}
            showValidationMessage={this.state.visHjelpetekst}
            validationMessage={this.props.texts.get('skrivEnGyldigEpostAdresse')}
        />
    );

    render() {
        const kortTittel = HelperFunctions.VariableInjection(this.props.texts.get('nyttInnskuddIFond'), {
            fond: this.props.fond.navn
        });
        const leggTilAnnenKontoValg = true;

        return (
            <ExpandableCard header={kortTittel} expandItem={this.props.expandItem} ikon="ikon-sparing-24">
                { this.props.kundevurdering === kundevurderingConst.MA_BEHANDLES_MANUELT || this.props.sperre ?
                    <KundevurderingStopp
                        {...this.props} 
                        title={this.props.sperre ? this.props.texts.get(`${this.props.sperre.toLowerCase()}SperretKontoTitle`) : ''}
                        text={this.props.sperre ? this.props.texts.get(`${this.props.sperre.toLowerCase()}SperretKontoText`) : ''}
                    />
                    :
                    <>
                        <div>
                            <div className="fond-skjema-seksjon">
                                <NumberInput
                                    id="endreEngangsBelop"
                                    label={this.props.texts.get('velgBelop')}
                                    subLabel={`Minimum ${this.props.fond.minimumsBelopEngangsKjop} NOK`}
                                    value={this.state.engangsBelop}
                                    onChange={this.endreEngangsBelop}
                                />
                                <ValidationMessages
                                    showValidationMessages={this.state.visHjelpetekst}
                                    validationMessages={[
                                        {
                                            condition: !(
                                                this.state.engangsBelop >= this.props.fond.minimumsBelopEngangsKjop
                                            ),
                                            message: this.props.texts.get('skrivInnEnGyldigSumOverMinimumsbelop')
                                        }
                                    ]}
                                />
                            </div>
                            <div className="fond-skjema-seksjon">
                                <VelgKonto
                                    id="kontonummer"
                                    tittel={this.props.texts.get('velgKonto')}
                                    leggTilAnnenKonto={leggTilAnnenKontoValg}
                                    kontoer={HelperFunctions.filtrerUtAndreKlientKontoer(
                                        this.props.kontoer,
                                        this.props.portefoljeId
                                    )}
                                    onChange={konto => this.setState(konto)}
                                    texts={this.props.texts}
                                    visHjelpetekst={this.state.visHjelpetekst}
                                />
                            </div>
                            {this.visEpost()}
                        </div>

                        <FeeMessage fond={(this.props.morningstar || []).find((d) => d.isin === this.props.fond.isin)} />
                        <ForeignCurrencyMessage texts={this.props.texts} fond={(this.props.morningstar || []).find((d) => d.isin === this.props.fond.isin)}/>

                        <div className="expandable-card-footer">
                            <button type="button" className="btn btn-flat" onClick={this.resetForm}>
                                {this.props.texts.get('avbryt')}
                            </button>
                            <button type="button" className="btn btn-primary" onClick={this.submitForm}>
                                {this.props.texts.get('bekreftNyttInnskudd')}
                            </button>
                        </div>
                        <Modal visModal={this.state.visAHV} lukkModal={this.closeAHV}>
                            { this.state.ahvError ? 
                                <div className="ahv-error-message">
                                    <h1 className="h3" aria-live="polite">{this.state.ahvError.title}</h1>
                                    <p>{this.state.ahvError.text}</p>
                                    <a href={this.state.ahvError.link.url} className="btn btn-action">{this.state.ahvError.link.text}</a>
                                </div> 
                                :
                                <KjennDinKundeForm rotNodeId="#sparing-react-app" onSubmit={this.submitAHV} /> 
                            }
                        </Modal>
                    </>
                }
            </ExpandableCard>
        );
    }
}

export default NyttInnskudd;
