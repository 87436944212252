import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GrafFunksjoner from './graf-funksjoner';

class GrafLinje extends Component {
    static propTypes = {
        values: PropTypes.array,
        bounds: PropTypes.object,
        height: PropTypes.number,
        color: PropTypes.string,
        thickness: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            color: props.color || 'black',
            thickness: props.thickness || 1
        };
    }

    createPointsForLineChart = array => {
        const adjustedValues = GrafFunksjoner.createArrayOfValuesAdjustedToViewBox(
            array,
            this.props.bounds,
            this.props.height
        );
        const coordinateObjects = GrafFunksjoner.flipValuesToAdjustToSvgOriginOfZero(adjustedValues, this.props.height);

        return this.createStringForSvg(coordinateObjects);
    };

    createStringForSvg = coordinateObjects => {
        return coordinateObjects
            .map(c => {
                return `${c.x},${c.y}`;
            })
            .join(' ');
    };

    render() {
        const values = this.createPointsForLineChart(this.props.values);

        return <polyline fill="none" stroke={this.state.color} strokeWidth={this.state.thickness} points={values} />;
    }
}

export default GrafLinje;
