/**
 * Created by vagrant on 22.02.17.
 */
import React from 'react';
import PropTypes from 'prop-types';

const TextInput = props => (
    <div className="text-input">
        <label className="full-width label-left">
            <div className="label-text">{props.label}</div>
            {props.subLabel ? <div className="sub-label">{props.subLabel} NOK</div> : ''}
            <input
                id={props.id}
                className="form-control"
                type="text"
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                disabled={props.disabled}
                placeholder={props.placeholder}
                {...(props.maxLength ? {maxLength: props.maxLength} : {})}
            />
        </label>
        {props.children}
    </div>
);

TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    subLabel: PropTypes.string,
    children: PropTypes.object,
    placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool
};

export default TextInput;
