// @ts-nocheck // kiet fix it later
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spinner, FooterButton } from '../../common/share/ShareComponents';
import FundDetail from '../../common/FundDetail/FundDetail';
import Message from '../../common/Message/Message';
import { scrollBodyTop, getURLquery } from '../../common/util/Functions';
import { getNewFondOrder } from '../../common/util/FondFunctions';
import { getSparingOgPensjonUrl } from '../../common/util/BusinessFunctions';

interface Props {
	action: any;
    appTexts: any;
    match: any;
};

const useSelectedFund = ( props: Props ): void => {
    const { match={} } = props;
    const isin = match.params?.isin;
    if ( !isin ) { return; }

    // SPASP-573: if the domain is not www, reload the page that include www.
    const origin = window.location.href;
    if ( !/http(s)?:\/\/www./i.test(origin) && !/\.(test|qa)\.eika/i.test(origin) ) {
        window.location.href = `${origin.replace('://', '://www.')}?autodirect=1`;
    } else {
        getNewFondOrder({fund: isin, fromOpenPage: true, id: `${(new Date()).getTime()}`});
        const url = getSparingOgPensjonUrl();
        window.location.href = url;
    }
};

export default (props: Props): JSX.Element => {
	const { action={}, match={} } = props;
    const { morningstar } = useSelector((state: any) => state?.Fond ?? {});
    useEffect(() => {
        if ( morningstar !== undefined ) { return; }

        if ( getURLquery().autodirect ) {
            useSelectedFund( props );
        }
        action.getFondList();
    }, [action, morningstar, props]);

    useEffect(() => { scrollBodyTop(0); }, []);

    /* eslint-disable */
    const fund = morningstar === null ? null : (
        morningstar ? morningstar.find( (d: any) => d.isin === match?.params?.isin) : undefined
    );
    /* eslint-enable */

    return <div className="buy-fund-wrapper -suggested-fund">
    	{ /* eslint-disable */ }
    	{ fund === undefined ? <Spinner /> : (fund ?
            <FundDetail {...props} data={fund} click={(e) => {useSelectedFund(props);}}>
                <FooterButton fixForMobile next="Velg dette fondet" click={() => { useSelectedFund(props); }}/>
            </FundDetail>
            :
            <Message type="error-server" text="Ops! Noe gikk galt. Vennligst prøv igjen senerer."/>
    	)}
    	{ /* eslint-enable */ }
    </div>
};