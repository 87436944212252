import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import { settSideTittel } from '../../actions/header';
import OrdreKvittering from '../../components/fond/ordre-kvittering';

import { endrePensjonsValg } from '../../actions/spareprofil';
import { opprettIPS } from '../../actions/pensjon';
import { lukkKvittering, settKvitteringsInnhold } from '../../actions/modals';
import StartIndividuellPensjonsSparing from '../../components/ips/start-individuell-pensjonssparing';
import OpprettIndividuellPensjonsSparing from '../../components/ips/opprett-individuell-pensjonssparing';
import IntroIndividuellPensjonsSparing from '../../components/ips/intro-individuell-pensjonssparing';
import IndividuellPensjonsSparingProfil from '../../components/ips/individuell-pensjonssparing-profil';
import IndividuellPensjonsSparingAnbefaling from '../../components/ips/individuell-pensjonssparing-anbefaling';
import * as kundevurderingConst from '../../domain/kundevurdering';
import KundevurderingStopp from '../../components/KundevurderingStopp';
import Modal from '../../components/modal';

import Feilmelding from '../../components/feilmelding';
import { hentKundeForhold } from '../../actions/bank';

require('../../styles/individuell-pensjons-sparing.scss');

class IndividuellPensjonsSparing extends Component {
    static propTypes = {
        texts: PropTypes.object,
        portefoljeData: PropTypes.object,
        pensjonsvalg: PropTypes.object,
        settSideTittel: PropTypes.func,
        kvitteringConfig: PropTypes.object,
        kvitteringsInnhold: PropTypes.object,
        lukkKvittering: PropTypes.func,
        settKvitteringsInnhold: PropTypes.func,
        opprettIPS: PropTypes.func,
        endrePensjonsValg: PropTypes.func,
        config: PropTypes.object,
        kundeInfo: PropTypes.object,
        errors: PropTypes.object,
        morningstar: PropTypes.array,
        hentKundeForhold: PropTypes.func,
        kundeforhold: PropTypes.array,
        kundevurdering: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    UNSAFE_componentWillMount = () => this.initializeComponentState(this.props);

    UNSAFE_componentWillReceiveProps = (newProps) => this.initializeComponentState(newProps);

    setContainerHeader = (harIPS) =>
        harIPS
            ? this.props.settSideTittel(this.props.texts.get('individuellPensjonsSparing'))
            : this.props.settSideTittel(this.props.texts.get('sparTilPensjon'));

    initializeComponentState = (props) => {
        if (props.portefoljeData && props.pensjonsvalg) {
            const { portefoljer } = props.portefoljeData;
            const { pensjonsvalg } = props;
            const state = {};

            if (!props.kundeforhold) {
                this.props.hentKundeForhold();
            }

            state.harIPS = this.harIPS(portefoljer);
            state.sattPensjonsvalg = !!(pensjonsvalg.ipsUttaksAlder && pensjonsvalg.ipsRisikoprofil);

            if (state.harIPS) {
                const IPS = portefoljer.find((p) => p.type === 'IPS');
                const harIPSFond = IPS.fond.length > 0;

                state.IPS = IPS;
                state.harIPSFond = harIPSFond;
            }

            this.setContainerHeader(state.harIPS);
            this.setState(state);
        }
    };

    harIPS = (portefoljer) => {
       return portefoljer.some((p) => p.type === 'IPS');
    }

    opprettIPS = (bankregnr) => {
        this.props.opprettIPS(bankregnr);

        const kvittering = {
            type: 'opprettIPS',
            lasteTekst: this.props.texts.get('oppretterIPSLasteTekst'),
        };

        this.props.settKvitteringsInnhold(kvittering);
    };

    sjekkForServerFeil = (errors) => errors.LAST_PORTEFOLJE || errors.HENT_KUNDEFORHOLD;

    showNext = (page) =>
        this.setState({
            show: page,
        });

    settIpsProfil = (ipsProfil) => {
        this.props.endrePensjonsValg(ipsProfil);
        this.setState({
            show: 'IndividuellPensjonsSparingAnbefaling',
        });
    };

    individuellPensjonsSparing = () => {
        if (this.state.harIPS) {
            if (this.state.show === 'IndividuellPensjonsSparingProfil') {
                return (
                    <IndividuellPensjonsSparingProfil
                        texts={this.props.texts}
                        settIpsProfil={this.settIpsProfil}
                        config={this.props.config}
                        pensjonsvalg={this.props.pensjonsvalg}
                    />
                );
            }

            if (this.state.show === 'IndividuellPensjonsSparingAnbefaling') {
                return (
                    <IndividuellPensjonsSparingAnbefaling
                        texts={this.props.texts}
                        pensjonsValg={this.props.pensjonsvalg}
                        alder={this.props.kundeInfo.alder}
                        portefoljeId={this.state.IPS.id}
                        morningstar={this.props.morningstar}
                    />
                );
            }

            return (
                <StartIndividuellPensjonsSparing
                    texts={this.props.texts}
                    settOppIpsProfil={() => {
                        if ( this.props.kundevurdering === kundevurderingConst.MA_BEHANDLES_MANUELT ) {
                            this.setState({stop: true});
                        } else {
                            this.showNext('IndividuellPensjonsSparingProfil');
                        }
                    }}
                />
            );
        }

        if (!this.state.harIPS) {
            if (this.state.show === 'OpprettIndividuellPensjonsSparing') {
                return (
                    <OpprettIndividuellPensjonsSparing
                        texts={this.props.texts}
                        opprettIPS={this.opprettIPS}
                        kundeforhold={this.props.kundeforhold}
                    />
                );
            }
        }

        return (
            <IntroIndividuellPensjonsSparing
                texts={this.props.texts}
                gaaTilNeste={() => {
                    if ( this.props.kundevurdering === kundevurderingConst.MA_BEHANDLES_MANUELT ) {
                        this.setState({stop: true});
                    } else {
                        this.showNext('OpprettIndividuellPensjonsSparing');
                    }
                }}
            />
        );
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors) ) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.props.portefoljeData && this.props.kundeforhold) {
            return (
                <div className="individuell-pensjons-sparing">
                    <OrdreKvittering
                        texts={this.props.texts}
                        kvitteringConfig={this.props.kvitteringConfig}
                        lukkKvittering={this.props.lukkKvittering}
                        kvitteringsInnhold={this.props.kvitteringsInnhold}
                    />
                    {this.individuellPensjonsSparing()}
                    
                    <Modal visModal={this.state.stop} lukkModal={()=>{ this.setState({stop: false})}}>
                        <KundevurderingStopp texts={this.props.texts} type="stand-alone" />
                    </Modal>
                </div>
            );
        }

        return <Loading title={this.props.texts.get('lasterFondsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Fond,
            ...state.Feilmeldinger,
            ...state.Bank,
            ...state.Modals,
            ...state.SpareProfil,
            ...state.Texts,
            kundevurdering: state.KjennDinKunde?.kundevurdering,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            lukkKvittering: () => {
                dispatch(lukkKvittering());
            },
            settKvitteringsInnhold: (innhold) => {
                dispatch(settKvitteringsInnhold(innhold));
            },
            opprettIPS: (bankregnr) => {
                dispatch(opprettIPS(bankregnr));
            },
            endrePensjonsValg: (endring) => {
                dispatch(endrePensjonsValg(endring));
            },
            hentKundeForhold: () => {
                dispatch(hentKundeForhold());
            },
        };
    }
)(IndividuellPensjonsSparing);
