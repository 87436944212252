// @ts-nocheck // kiet fix it later

import React from 'react';
import classnames from 'classnames';
import { formatAmount } from '../../util/Functions';

const getBlackboardContent = (data) => {
    return data ? [
        data.text || '',
        data.value || typeof(data.value) === 'number' ? `${formatAmount(data.value)} ${data.unit || ''}`: ''
    ].join(' ').replace(/\s/g, ' ') : '';
};

// eslint-disable-next-line
const BlackboardCnt = ({ data, title, list }) => {
    return (
        <div className="blackboard-cnt">
            { !!title && !!title.replace(/\s+/g, '') && <div className="headline">{title}</div> }
            {(list || []).map((d) => {
                const style = classnames('multiple-bar-row', {
                    '-active': d.id === data.id
                });

                return (<div className={style} data-color={d.color} key={d.id}>
                    <span className="color" style={{ backgroundColor: d.color }} />
                    <span className="text">{d.text}</span>
                </div>);
            })}
        </div>
    );
};

/** *****************************************************************************/
export const getPieBlackboardContent = (data) => {
    return getBlackboardContent(data);
};

/** *****************************************************************************/
export const getLineBlackboardContent = (data, state) => {
    const list = state.graph.list.map((d) => {
        if (d.type !== 'line-point-none' || d.cx !== data.cx) { return null; }
        return { id: d.id, text: d.blackboardText || getBlackboardContent(d), color: d.color };
    }).filter(d => !!d);

    const xTextList = (((state.axis || {}).x || {}).list || []).filter(d => d.type === 'text');
    const xPoint = xTextList[data.index] || {};
    const title = xPoint.title || xPoint.text || '';
    return <BlackboardCnt data={data} list={list.reverse()} title={title} />;
    // return list.length > 1 ? <BlackboardCnt data={data} list={list.reverse()} title={title} /> :
    //     getBlackboardContent(data);
};

/** *****************************************************************************/
export const getBarBlackboardContent = (data, state) => {
    const multiple = data.multiple;
    if (!state || !state.graph || !multiple) { return getBlackboardContent(data); }

    const list = state.graph.list.map((d) => {
        if (multiple.index !== (d.multiple || {}).index) { return null; }
        return { id: d.id, text: getBlackboardContent(d), color: d.color };
    }).filter(d => !!d);

    const xTextList = (((state.axis || {}).x || {}).list || []).filter(d => d.type === 'text');
    const xPoint = xTextList[multiple.index] || {};
    const title = xPoint.title || xPoint.text || '';
    return list.length ? <BlackboardCnt data={data} list={list.reverse()} title={title} /> : null;
};
