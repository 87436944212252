/**
 * Created by vagrant on 22.02.17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ValidationMessages extends Component {
    static propTypes = {
        validationMessages: PropTypes.arrayOf(PropTypes.object),
        showValidationMessages: PropTypes.bool
    };

    showValidationMessages = validationMessages => {
        return validationMessages
            .filter(v => v.condition)
            .map(v => (
                <div key={v.message} className="hjelpe-tekst">
                    {v.message}
                </div>
            ));
    };

    render() {
        if (this.props.showValidationMessages) {
            return <div className="hjelpe-tekster">{this.showValidationMessages(this.props.validationMessages)}</div>;
        }

        return null;
    }
}

export default ValidationMessages;
