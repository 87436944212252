// @ts-nocheck // kiet fix it later
import React from 'react';
import KjennDinKundeBarnRNWebView from './KjennDinKundeBarnRNWebView';
import KjennDinKundeRNWebView from './KjennDinKundeRNWebView';

import './AHV.scss';

interface Props {
    type?: string;
}

export default (props: Props): JSX.Element => {
    return <div className={`ahv-wrapper -${props.type || 'adult'} kjenn-din-kunde-wrapper`}>
        { props.type === 'child' ?  
            <KjennDinKundeBarnRNWebView {...props} /> : 
            <KjennDinKundeRNWebView {...props}/>
        }
    </div>
};