import React, { useState, MouseEvent } from 'react';
import classNames from 'classnames';
import Message from '../Message/Message';
import FlexTextHolder, { FlexText } from '../FlexTextHolder/FlexTextHolder';
import './RadioOption.scss';

interface RadioData {
    value: string;
    textList?: string[] | string[][] | FlexText[] | FlexText[][];
    information?: string[] | string[][] | FlexText[] | FlexText[][];
    source?: string[] | string[][] | FlexText[] | FlexText[][];
    description?: string[] | string[][] | FlexText[] | FlexText[][];
    note?: string[] | string[][] | FlexText[] | FlexText[][];
    disabled?: string;
    attentionMessage?: string;
};

interface Props {
    legend: string;
    type?: string;
    list?: RadioData[];
    selected?: string;
    name: string;
    change: (e: MouseEvent | null, k: string, d?: any) => void;
    error?: string;
    required?: boolean;
};

interface State {
    expanded: string;
};

export const click = ( props: Props, state: State, setState: (s: State) => void, e: MouseEvent, key='', data?: any ): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === 'toggle-note' && data ) {
        setState({...state, expanded: data.value === state.expanded ? '' : data.value});
    }
};

export default (props: Props): JSX.Element | null => {
    const {type='basic', legend, name, list, selected, change, error} = props;
    const [state, setState] = useState<State>({
        expanded: ''
    });

    return (list ?? []).length ? <div className={classNames(`radio-option-wrapper -${type}`, {
        '-has-error': !!error,
    })}>
    	<fieldset>
    		<legend>{legend}</legend>
    		<ul className="radio-option-list">
    			{ (list || []).map( (data: RadioData, i: number) => {

    				return <li key={`radio-option-item-${i}`} className={classNames(`radio-option-item`, {
    					'-checked': data.value === selected,
                        '-expanded': data.value === state.expanded,
                        '-disabled': !!data.disabled,
                        '-has-detail': !!data.attentionMessage,
    				})}>
    					<div className="radio-option-input-holder">
    						<input id={data.value} value={data.value} required={!!props.required} disabled={!!data.disabled} name={name} type="radio" checked={data.value === selected} onChange={()=>{change(null, 'select-radio-option', data); }} />
    						<label htmlFor={data.value}>
    							<FlexTextHolder text={data.textList} spanType='radio-option-text' />
    						</label>
    					</div>

                        { (typeof(data.disabled) === 'string' || !!data.attentionMessage) && <Message type="AttentionMessage" icon text={data.attentionMessage || data.disabled}/> }

                        { (data.information || []).length > 0 && <div className="radio-option-information-holder">
                            <FlexTextHolder text={data.information} />
                        </div>}

                        { (data.source || []).length > 0 && <div className="radio-option-source-holder">
                            <FlexTextHolder text={data.source} />
                        </div>}

                        { (data.description || []).length > 0 && <div className="radio-option-description-holder">
                            <FlexTextHolder text={data.description} />
                        </div>}

                        { (data.note || []).length > 0 && <div className="radio-option-note-holder">
                            <div className="radio-option-note-tool">                            
                                <a href="#" role="button" className="expander-btn"
                                    aria-controls={`radio-option-note-${data.value}`}
                                    onClick={(e)=>{ click(props, state, setState, e, 'toggle-note', data);}}
                                >Om kontoen</a>
                            </div>
                            <div id={`radio-option-note-${data.value}`} aria-hidden={data.value !== state.expanded} className="radio-option-note-content">
                                <FlexTextHolder text={data.note} />
                            </div>
                        </div>}
    				</li>
    			})}
    		</ul>
            { !!error && <div aria-live="polite" className="error-message-wrapper"><Message type="error" text={error} /></div> }
    	</fieldset>
    </div> : null;
};

