// @ts-nocheck // kiet fix it later
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { lastMorningstarData, getFondKalkulator } from '../../actions/fond';
// eslint-disable-next-line
import { formatDateToText, formatAmount, createRegexp, getURLquery, roundUpValue } from './Functions';
// eslint-disable-next-line
import { FundRiskBullet, FundDevelopmentList, FundPriceList, FundESGinfo, FundChartTabel } from './ShareComponents';
// eslint-disable-next-line
import Chart from './Chart/Chart';
import Suggestion from './Suggestion/Suggestion';
import Slider from './Slider/Slider';
import TabList from './TabList/TabList';

import './style/EKFfondKalkulator.scss';

const getChartData = ( fond, response={} ) => {
    const info = { 
        chartData: [],
        yearList: [],
        highest: 0,
        description: `${fond.navn} renteutbetaling på ${response.aarligAvkastningUtenKostnader || '-'} %`.replace('.', ','),
        // groupId: ['aarligAvkastningUtenKostnader', 'forvaltningshonorar'].map((key) => (response[key] || 'x')).join('-')
        groupId: ['aarligAvkastningUtenKostnader'].map((key) => (response[key] || 'x')).join('-')
    };

    (response.utenInflasjonUtenKostnad || []).forEach( (d) => {
        const year = d.dato.substring(0,4);
        info.yearList.push(year);

        // ['verdi', 'nedreGrense', 'ovreGrense'].forEach( (key, j) => { 
        ['verdi'].forEach( (key, j) => { 
            const value = roundUpValue( (d[key] || 0), 100 );
            if ( info.highest < value ) { info.highest = value; }
            if ( !info.chartData[j] ) { info.chartData[j] = []; }

            // eslint-disable-next-line
            const detail = key === 'nedreGrense' ? 'nedre grense' : (key === 'ovreGrense' ? 'øvre grense' : 'forventet');
            const text = `${fond.navn} ${detail}`;
            info.chartData[j].push({value, text, detail, name: fond.navn});
        }); 
    });

    return info; 
};

const getLineConfig = (color, current=true) => {
    if ( current ) {
        return {lineWidth: 5, color };
    }
    return {lineWidth: 3, color: color.replace(/1\)/, '.3)' ) };    
};

const getChart = ( selected )  => {
    const note = { pin: {}, grouping: {id: {}, order: []}, index: 0, colorList: [
        'rgba(52, 175, 217, 1)',
        'rgba(190, 124, 173, 1)',
        'rgba(102, 188, 121, 1)',
        'rgba(240, 197, 92, 1)', //'#f0c55c', // yellow
        'rgba(243, 80, 114, 1)', //'#f35072', // red
        'rgba(216, 144, 59, 1)', //'#d8903b', // orange
        'rgba(233, 163, 191, 1)', //'#e9a3bf', // pink
        'rgba(28, 201, 157, 1)', // '#1cc99d', // green
        'rgba(82, 183, 242, 1)', //'#52b7f2', // blue
        'rgba(134, 117, 244, 1)', //'#8675f4', // purple
        'rgba(228, 75, 221, 1)',
        'rgba(121, 245, 236, 1)',
        'rgba(227, 8, 108, 1)',
        'rgba(26, 234, 225, 1)'
    ]};
    
    const chart = {
        animation: true,
        view: [800, 400],
        data: [],
        type: 'spline',
        padding: { top: 50, left: 10, bottom: 50, right: 100 },
        // xAxis: { grid: 5, text: [], showMultipleBackboard: true, gridColor: '#f0f0f0'},
        // yAxis: { grid: 0, separation: 0, unit: ' kr' },
        xAxis: { grid: 1, text: [], size: 16, lineColor: '#000' },
        yAxis: { grid: 0, separationLine: true, separation: 5, color: '#ccc', unit: ' kr', toRight: true, trimNumber: 0, size: 16 },
        roundup: 100,
        highest: 0,
        multipleBlackboard: true,
        table: { head: [{text: 'År'}], body: [] },
        description: [],
    };

    selected.list.forEach( (fond) => {
        if ( fond.forceColor && fond.result?.groupId ) { note.pin[fond.result.groupId] = fond.forceColor; }
    });

    selected.list.forEach( (fond) => {
        const { result } = fond;
        if ( !result || !result.chartData ) { return; }

        let color = note.pin[result.groupId];
        if ( !color ) {
            color = note.colorList[(note.index%note.colorList.length)];
            note.index++;
            note.pin[result.groupId] = color;
        }

        fond.color = color;

        if ( note.grouping.id[result.groupId] ) {
            note.grouping.id[result.groupId].push( fond );
        } else{
            note.grouping.id[result.groupId] = [fond];
            note.grouping.order.push(result.groupId);
        }

        if ( chart.xAxis.text.length === 0 && (result.yearList || []).length ) {
            const last = result.yearList.length - 1;
            result.yearList.forEach( (year, j) =>{
                chart.xAxis.text[j] = {text: (j === 0 || j === last ? year : ' '), title: `År ${year}`, value: year};
            });
        }

        result.chartData.forEach( (list, j ) => {
            list.forEach( (data) => {
                const config = getLineConfig(color, (j===0));
                for ( const key in config ) {
                    data[key] = config[key];
                }
            });

            chart.table.head.push({text: [list[0].name, list[0].detail].filter((t) => !!t) });
            chart.data.push( list );
        });

        if ( chart.highest < result.highest ) { chart.highest = result.highest; }

        if ( fond.result && fond.result.description ) { chart.description.push(fond.result.description); }

    });

    chart.highest = roundUpValue(chart.highest*1.01, (chart.yAxis.separation * 100));
    chart.xAxis.text.forEach( (x, i) => {
        const row = [{text: x.value}];
        chart.data.forEach( (data) => {
            row.push({text: `${formatAmount((data[i]?.value || ''))} kr`});
        });
        chart.table.body.push(row);
    });

    selected.list = note.grouping.order.reduce( (list, groupId) => {
        return list.concat( note.grouping.id[groupId] );
    }, []);

    return chart;
};

const displayCalculation = (props, state, setState)  => {
    const { selected = {pin: {}, list: []}} = state;
    let count = selected.list.length;
    if ( !count ) { return setState({...state, chart: null, loading: false }); }

    const pin = {};
    for ( const key in state.value ) {
        pin[key] = parseInt(`${state.value[key] || 0}`.replace(/\s+/g, ''), 10);
        if ( key === 'point' ) {
            pin[key] += 1;
        }
    }

    const paramId = ['onetime', 'monthly', 'point'].map( (key) => (pin[key] || 'x') ).join('-');
    const get = (fond) => {
        if ( fond.result?.paramId === paramId ) {
            if ( --count ) { return; }
            setState({...state, loading: false, chart: getChart( selected )});
        } else {
            state.dispatch(getFondKalkulator({...pin, isin: fond.isin}, ( response ) => {
                fond.result = { paramId, response, ...getChartData(fond, response) };
                if ( --count ) { return; }
                setState({...state, loading: false, chart: getChart( selected )});
            }));
        }
    };

    selected.list.forEach( (fond) => { get(fond); });
};

const changeInputValue = ( props, state, setState, e) => {
    const update = {...state };
    const name = e.currentTarget.name;
    const value = e.currentTarget.value || '';
    let anyError = false;
    update.value[name] = value;

    clearTimeout((update.timer.displayCalculation || 0));

    state.inputList.forEach( (input) => {
        if ( input.name !== name ) { return; }
        const error = (input.validation || []).find( (validation) => {
            let out = false;
            if ( validation.rule === 'number') {
                out = !`${value}`.replace( /\s/g, '' ).match( /^(?:[1-9]\d*|\d)$/ );
            } else if ( validation.rule === 'lessThanOrLike' && validation.value ) {
                const number = parseInt( `${value}`, 10 );
                out = number && !isNaN(number) ? (number < validation.value) : true;
            } else if ( validation.rule === 'greatThanOrLike' && validation.value ) {
                const number = parseInt( `${value}`, 10 );
                out = number && !isNaN(number) ? (number > validation.value) : true;
            }
            return out;
        });

        update.error[name] = error ? error.message : '';
        anyError = anyError || !!update.error[name];
    });

    if ( anyError === false ) {
        update.timer.displayCalculation = setTimeout( () => {
            displayCalculation( props, update, setState );
        }, 500);
    }

    setState(update);
};

const changeSlider = (props, state, setState, e, input ) => {
    changeInputValue(props, state, setState, { currentTarget: {
        value: `${e.current[0] || ''}`,
        name: input.name,
    }});
}

const changeSuggestion = ( props, state, setState, selectedList ) => {
    const selected = {pin: {}, list: []};
    selectedList.forEach( (item) => {
        const data = {...(state.selected.pin[item.isin] || {}), ...item};
        selected.pin[data.isin] = data;
        selected.list.push( data );
    });

    const update = {...state, loading: true, selected};
    setState( update );
    displayCalculation( props, update, setState );
    return 'close-widget';
};

const click = ( props, state, setState, e, key, data ) => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === 'toggle-info' && data && data.isin ) {
        setState({...state, expanded: state.expanded === data.isin ? '' : data.isin });
    } else if ( key === 'change-graph-view' && data && data.id && data.fond ) {
        const list = JSON.parse(JSON.stringify(state.list));
        const fond = list.find(( fond) => fond.isin === data.fond.isin );
        if ( fond ) {
            fond.view.option.selected = data.id;
            setState({...state, list});
        }
    } else if ( key === 'remove-selected' && data ) {
        const selectedList = state.selected.list.filter( (d) => d.isin !== data.isin );
        changeSuggestion( props, state, setState, selectedList );
    } else if ( key === 'change-panel-view' ) {
        setState({...state, panelView: {...state.panelView, selected: data.id}});
    } else if ( (key === 'adjustment-decrease' || key === 'adjustment-increase')  && data ) {
        const value = parseInt( `${state.value[data.name] || 0}`.replace(/\s+/g, ''), 10) + (data.step * (key === 'adjustment-increase' ? 1 : -1));
        if ( isNaN(value) || (data.interval && (data.interval[0] > value || data.interval[1] < value)) ) { return; }
        changeInputValue( props, state, setState, {currentTarget: {
            value: `${value}`, name: data.name
        }});             
    } else if ( key === 'toggle-about-calculation' ) {
        setState({...state, dialogBox: key === state.dialogBox ? '' : key});
    } else if ( key === 'close-dialog-box' ) {        
        setState({...state, dialogBox: ''});
    }
};


const initViewList = (props, state, setState, morningstar)  => {
    const bankSparing = {id: 'BANKSPARING', isin: 'BANKSPARING', navn: 'Bank sparing', forceColor: 'rgba(153, 153, 153, 1)'};
    const optionList = morningstar.filter( (fond) => fond.isin && fond.navn ).map( (fond) => { 
        return {
            id: fond.isin,
            isin: fond.isin,
            navn: fond.navn,
            // eslint-disable-next-line
            risiko: fond.risiko ? `${fond.risiko >= 5 ? 'Høy' : (fond.risiko >=3 ? 'Middels' : 'Lav')} risiko` : '',
            type: fond.aksjesparekontoKompatibel ? 'aksje' : '',
        }; 
    });

    optionList.push(bankSparing);
    const selected = {pin: {[bankSparing.isin]: bankSparing}, list: [bankSparing]};
    
    optionList.filter( (fond) => !!fond.navn.match(/^eika\s+(spar|kknorge)$/i) ).forEach( (fond) => {
        if ( selected.pin[fond.isin] ) { return; }
        selected.pin[fond.isin] = fond;
        selected.list.push( fond );
    });

    displayCalculation( props, { ...state, inited: true, optionList, selected }, setState );
};

const getInputList = () => {
    return [{ 
        name: 'onetime',
        label: 'Engangsbløp',
        unit: 'kr', 
        round: 100,
        interval: [100, 5000000],
        adjustment: [
            {step: 100, label: 'Mink 100'},
            {step: 100, label: 'Økt 100'},
        ],
        validation: [{
            rule: 'number',
            message: 'Vennligst oppgir et helt tall engangsbløp',
        }, {
            rule: 'lessThanOrLike',
            value: 100,
            message: 'Minst engangsbløp må være 100 kr',
        }, {
            rule: 'greatThanOrLike',
            value: 5000000,
            message: 'Maks engangsbløp er 5 000 000 kr',
        }]
    }, {
        name: 'monthly',
        label: 'Månedlig sparing',
        unit: 'kr',
        round: 100,
        interval: [100, 100000],
        adjustment: [
            {step: 100, label: 'Mink 100'},
            {step: 100, label: 'Økt 100'},
        ],
        validation: [{
            rule: 'number',
            message: 'Vennligst oppgir et helt tall månedlig sparebeløp',
        }, {
            rule: 'lessThanOrLike',
            value: 100,
            message: 'Minst månedlig sparebeløp må være 100 kr',
        }, {
            rule: 'greatThanOrLike',
            value: 100000,
            message: 'Maks månedlig sparebeløp er 100 000 kr',
        }]
    }, {
        name: 'point',
        label: 'Spareperiode',
        unit: 'år',
        round: 1,
        interval: [1, 50],
        adjustment: [
            {step: 1, label: 'Mink 1 år'},
            {step: 1, label: 'Økt 1 år'},
        ],
         validation: [{
            rule: 'number',
            message: 'Vennligst oppgir et helt tall antall år',
        }, {
            rule: 'lessThanOrLike',
            value: 1,
            message: 'Minst Spareperiode 1 år',
        }, {
            rule: 'greatThanOrLike',
            value: 50,
            message: 'Maks Spareperiode er 50 år',
        }]
    }];
};


const EKFfondKalkulator = ( props ) => {
    const dispatch = useDispatch();
    const { morningstar } = useSelector((state) => state.Fond) || {};
    const [state, setState] = useState({
        domain: (window.location.origin || '').match( /localhost/i ) ? 'https://eika.no' : window.location.origin,
        loading: true,
        view: 'chart',
        inputList: getInputList(),
        value: { onetime: 1000, monthly: 500, point: 20, },
        error: {},
        panelView: {
            selected: 'chart',
            list: [
                {'id': 'chart', name: 'Graf' },
                {'id': 'table', name: 'Tabell' },
            ],
        },
        dialogBox: '',
        timer: {},
        dispatch,
    });

    useEffect(() => {
        dispatch(lastMorningstarData());
    }, [dispatch]);

    useEffect(() => {
        if  ( state.optionList || !morningstar  ) { return; }
        initViewList(props, state, setState, morningstar);
    }, [props, morningstar, state, setState]);

    return (
        <div id="sparing-react-app" className={classNames('ekf-fond-kalkulator', {
            '-loading': state.loading            
        })}>
            <h1>Sparekalkulator</h1>
            <div className="ekf-fond-content">
                <div className="tool-wrapper">
                    <ul className="input-wrapper">
                        { state.inputList.map( (input) => {
                            return <li key={`input-hoolder-${input.name}`} className={classNames('input-holder', {
                                '-has-error': !!state.error[input.name]
                            })}>
                                <label htmlFor={`input-${input.name}`}>{input.label}</label>

                                <div className="relative">
                                    { !!(input.adjustment || [])[0] && <a href="#" role="button"
                                            title={input.adjustment[0].label} 
                                            aria-label={input.adjustment[0].label}
                                            className="adjustment-button -decrease"
                                            onClick={(e)=>{ click(props, state, setState, e, 'adjustment-decrease', {...input, ...input.adjustment[0]} ); }}
                                    />}

                                    <div className="input-content">
                                        <input id={`input-${input.name}`} name={input.name} type="text" value={state.value[input.name] || ''}
                                            onChange={(e) => { changeInputValue(props, state, setState, e); }}
                                        />
                                        {!!input.unit  && <div className="unit">{input.unit}</div>}
                                    </div>

                                    { !!(input.adjustment || [])[1] && <a href="#" role="button"
                                            title={input.adjustment[1].label} 
                                            aria-label={input.adjustment[1].label}
                                            className="adjustment-button -increase"
                                            onClick={(e)=>{ click(props, state, setState, e, 'adjustment-increase', {...input, ...input.adjustment[1]} ); }}
                                    />}
                                </div>

                                { false && <Slider current={[parseInt(`${state.value[input.name] || 0}`, 10)]} 
                                    interval={input.interval} 
                                    unit={input.unit} 
                                    label={input.label} 
                                    round={input.round || 1}
                                    adjustmentButton={input.adjustment}
                                    change={(e)=>{ changeSlider(props, state, setState, e, input); }}
                                /> }

                                { !!state.error[input.name] && <div className="input-error">{state.error[input.name]}</div> }
                            </li>
                        })}
                    </ul>
                </div>

                <div className="summary-wrapper">
                    <div className="tool-search">
                        <Suggestion label="Velg fond" multiple
                            searchKeys={['id','navn', 'risiko', 'type']}
                            optionView={['navn']} 
                            selection={state.optionList}
                            current={state.selected?.list || []}
                            change={(e)=> {  return changeSuggestion(props, state, setState, e ); }}
                        />

                        { (state.selected?.list || []).length > 0 && <ul className="selected-item-wrapper">
                            { (state.selected?.list || []).map( (item)=> {
                                return <li key={`selected-item-${item.isin}`} className="selected-item">
                                    <span className="item-name">{item.navn}</span>
                                    <span className="border-bottom" style={{backgroundColor: item.color || '#999'}}/>
                                    <a href="#" role="button" title={`Slett fond ${item.navn}`}
                                        style={{color: item.color || '#999'}} 
                                        onClick={(e)=>{ click(props, state, setState, e, 'remove-selected', item);}}
                                        className="cross-btn"
                                    ><div className="aria-visible">{`Slett valgte fond ${item.navn}`}</div></a>
                                </li>
                            })}
                        </ul>}
                    </div>

                    <section aria-labelledby="result-title" className="result-wrapper">
                        <h2 id="result-title">Forventet utvikling</h2>
                        <div className="panel-wrapper">
                            <div className="panel-option">
                                <TabList small list={state.panelView.list} 
                                    click={(e: any, k='', d: any)=>{ click(props, state, setState, e, k, d); }}
                                    current={state.panelView.selected} 
                                    action="change-panel-view"
                                />
                            </div>
                        </div>

                        { !state.loading && !state.chart && <div aria-live="polite" className="no-result-message">Ingen resultat</div> }
                        { !!state.chart && state.panelView.selected === 'chart' && <Chart {...state.chart} /> }
                        { !!state.chart && !!state.chart.table && state.panelView.selected === 'table' && <table className="fund-list-table">
                            <caption className="aria-visible">{state.chart.table.title || 'Forventet utvikling'}</caption>
                            <thead>
                                <tr>
                                    {state.chart.table.head.map( (d, i) => {
                                        return <th key={`th-${i}`} scope="col" className={`column-${i} ${d.style || ''}`}>
                                            { (d.text instanceof Array ? d.text : [d.text]).map( (t,j) => {
                                                return <span key={`th-${i}-${j}`}>{t}</span>
                                            }) }
                                        </th>
                                    })}
                                </tr>
                            </thead> 
                            <tbody>
                                { state.chart.table.body.map( (d, i) => {
                                    return <tr key={`tr-${i}`}>
                                        { d.map( (t, j) => {
                                            return j === 0 ?  
                                                <th key={`td-${i}-${j}`} className={t.style || ''} scope="row"><span>{t.text}</span></th>:
                                                <td key={`td-${i}-${j}`} className={t.style || ''}><span>{t.text}</span></td>
                                        })}
                                    </tr>
                                })}
                            </tbody>
                        </table>}

                        <div className="infomation-wrapper">
                            { !!state.chart && <a href="#" role="button" className="about-calculation-link" onClick={(e)=> { click(props, state, setState, e, 'toggle-about-calculation'); }}>Om utregningen</a> }
                        </div>
                    </section>
                </div>
            </div>

            { !! state.dialogBox && <div aria-labelledby="dialog-box-title" className="dialog-box-wrapper">
                <div className="dialog-box-frame flex">
                    <div className="dialog-box-header flex-header">
                        <a href="#" role="button" title="Lukk vindu" className="dialog-box-close-btn" onClick={ (e) => { click(props, state, setState, e, 'close-dialog-box'); }}>
                            <span className="aria-visible">Lukk dialogBox vindu</span>
                        </a>
                    </div>
                    <div className="dialog-box-body flex-body">
                        <h1 id="dialog-box-title" className="calculation-title">Kalkulatoren forutsetter årlig:</h1>
                        <ul className="calculation-description">
                            { (state.chart?.description ?? []).map( (t, i) => {
                                return <li key={`calculation-description-${i}`}>{t}</li>
                            })}
                        </ul>
                        <div className="calculation-text">
                            Fremtidig avkastning vil blant annet avhenge av markedsutviklingen, forvalterens dyktighet, fondets risikoprofil og tegnings- og forvaltningshonorar. Avkastningen kan bli negativ som følge av kurstap.
                        </div>
                    </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <div className="dialogBox-bg-closer" onClick={ (e) => { click(props, state, setState, e, 'close-dialog-box'); }}/>
            </div>}

        </div>
    );
};

export default EKFfondKalkulator;
