/**
 * Created by vagrant on 01.03.17.
 */

import { Component } from 'react';

class OmBeregningene extends Component {
    render() {
        // MSS doesn't exist longer. We cannot delete react-app folder yet,
        // because Sitecore is missing the new component for our stand alone component
        // in the open pages.
        return null;  
    }
}

export default OmBeregningene;
