// @ts-nocheck // kiet fix it later

import React, { useEffect } from 'react';
import { getNewFondOrder } from '../../common/util/FondFunctions';
import { getSparingOgPensjonUrl } from '../../common/util/BusinessFunctions';

import './style/EKFspareLinking.scss';

/* eslint-disable */
export const openSmartspart = (key='', param='', href=''): void => {
    const iOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod');
    const android = navigator.userAgent.match('Android');

    if ( iOS || android ) {
        const isSafari = navigator.vendor && 
            navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;

        const url = `smartspar://home?${param || 'action=none'}`;
        const appstore = iOS ? ( isSafari ?
            'https://itunes.apple.com/app/id1450266656' : 'https://apps.apple.com/no/app/smartspar/id1450266656' 
        ) : 'https://play.google.com/store/apps/details?id=no.eika.smartspar';

        const state: any = { 'timer': 0, stop: false, count: isSafari ? 2 : 1};
        const blur = () => {
            if ( --state.count ) { return; }

            clearTimeout( state.timer );
            state.stop = true;
        };
        window.removeEventListener('blur', blur)
        window.addEventListener('blur', blur);

        state.timer = setTimeout( () => {
            if (state.stop) { return; }
            if ( isSafari ) {
                window.location.replace(appstore);
            } else {
                window.location.href = appstore;
                // window.location = appstore;
            }
        //}, 2000);
        //}, (isSafari ? 2000 : 500));
        }, (iOS ? 3000 : 1000));

        window.location.href = url;
        // window.location = url;
    } else {
        const url = href || `${window.location.href.replace(/\/+$/g,'')}/${key.replace(/\-/g,'')}`;
        window.location.href = url;
    }
}
/* eslint-enable */

export const getURLquery = ( url?: string ): any => {
    const matched =  (url || window.location.href).replace( /#(\/)?$/, '' )
        .replace(/\?+/g, '?').match(/^([\w.\-\s_#%/:]+)\?(.*)/);
    if ( !matched || !matched[2] ) { return {}; }

    const splited = (decodeURIComponent(matched[2]) || '').replace(/#\?/g, '&').split('&');
    return splited.reduce( (query: any, text: any) => {
        const data = (text || '').match(/(\w+)=(.*)/);
        if ( !data || !data[1] || !data[2] ) { return query; }

        if ( query[data[1]] ) {
            query[data[1]] = (
                query[data[1]] instanceof Array ? query[data[1]] : [query[data[1]]]
            ).concat( data[2] );
        } else {
            query[data[1]] = data[2];
        }
        return query;
    }, {});
};

const getSmartsparURL = (): string => {
    const origin = window.location.origin;
    const extention = /(https|http)?:\/\/(www\.)?eika/i.test(origin) ? 
        'smartspar' : 'spare/smartspar';
    return /localhost/i.test(window.location.href) ? '' : `${origin}/${extention}`;
};

const openMobilbankApp = ( param: { [k: string]: string } ): void => {
    const url = `${decodeURIComponent(param.deeplink)}?status=${param.status}`;
    window.location.href = url;
};

const redirectToAvtalegiro = ( param: { [k: string]: string } ): void => {
    if ( !param.uri ) { return; }

    param.returnUrl = `${window.location.href}?MBavtalegiro=${param.kid}`;

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = param.uri;
    form.style = 'display:none';

    for ( const key in param ) {
        if ( key && key !== 'uri' ) {
            const input = document.createElement("input");
            input.name = key;
            input.value = param[key] || '';
            form.appendChild(input);
        }
    }

    document.body.appendChild(form);
    if ( !param.kietvetran ) { form.submit(); }
};

const veriryAvtalegiro = ( param={} ): void => {
    if ( param.status ) {
        openMobilbankApp( param );
    } else {
        redirectToAvtalegiro( param );
    }
};

const init = ( bankregnr='' ): void => {
    const smartsparURL = getSmartsparURL();
    const isMobile = window.orientation > -1;
    const param = getURLquery();
    
    if ( param.MBavtalegiro ){
        veriryAvtalegiro( param );
    } else if ( param.kiet ) {
        // test
    } else {

        if ( !param.action && param.fund ) { 
            param.action = 'kjop-fond';
        }

        const queryList = [`id=${(new Date()).getTime()}`, `bankregnr=${bankregnr}`];
        for ( const key in param ){
            queryList.push(`${key}=${param[key]}`);
        }

        if ( isMobile ) {
            openSmartspart( '', queryList.join('&'), smartsparURL );
        } else if ( param.action === 'vis-pensjonssparing' ) {
            // Currently there is no action to create IPS-account and EPK on online-bank.
            // We temporary navigate the user to Smartspar net page.
            window.location.href = getSmartsparURL();
        } else if ( param.fund ) {
            getNewFondOrder({...param, fromOpenPage: true, id: `${(new Date()).getTime()}`});
            window.location.href = getSparingOgPensjonUrl();
        }
    }
};

const EKFspareLinking = ( props: {config: any} ): JSX.Element => {
    const bankregnr = props.config?.bankregnr || '';
    const param = getURLquery();

    useEffect(() => {
        init( bankregnr );
    }, [bankregnr]);

    return (
        <div id="sparing-react-app" className="ekf-spare-linking-wrapper">
            { !!param.status && !!param.MBavtalegiro ?
                <button type="button" className="mb-closer" onClick={ ()=> { openMobilbankApp(param); }}>Lukk vindu</button> : 
                <div aria-live="polite" className="loader"/>}
        </div>
    );
};

export default EKFspareLinking;
