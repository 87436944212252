import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import Validate from '../validation-functions';
import HelperFunctions from '../helper-functions';
import Format from '../format-functions';
import TextInput from '../common/text-input';
import ValidationMessages from '../common/validation-messages';

require('../../styles/endrePortefoljeNavn.scss');

class EndrePortefoljeNavn extends Component {
    static propTypes = {
        texts: PropTypes.object,
        portefoljer: PropTypes.array,
        endrePortefoljeNavn: PropTypes.func,
        visBankNavn: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            ikkeEksternePortefoljer: props.portefoljer.some(portefolje => portefolje.type !== 'VPS'),
            visEndrePortefoljeNavnSkjema: false,
            loading: false,
            portefoljeEndringer: {}
        };
    }

    UNSAFE_componentWillReceiveProps = newProps => {
        if (newProps.portefoljer) {
            this.setState({
                loading: false,
                visEndrePortefoljeNavnSkjema: false
            });
        }
    };

    visEndrePortefoljeNavnSkjema = () =>
        this.setState({
            visEndrePortefoljeNavnSkjema: true
        });

    validerPortefoljeNavn = portefoljeEndringer => {
        for (const key in portefoljeEndringer) {
            if (Object.prototype.hasOwnProperty.call(portefoljeEndringer, key)) {
                if (!this.validateInputField(key)) {
                    return false;
                }
            }
        }

        return true;
    };

    lagrePortefoljeNavnEndringer = () => {
        if (this.validerPortefoljeNavn(this.state.portefoljeEndringer)) {
            this.props.endrePortefoljeNavn(this.state.portefoljeEndringer);

            this.setState({
                visHjelpetekst: false,
                loading: true
            });
        } else {
            this.setState({
                visHjelpetekst: true
            });
        }
    };

    avbryt = () =>
        this.setState({
            visHjelpetekst: false,
            visEndrePortefoljeNavnSkjema: false,
            portefoljeEndringer: {}
        });

    endrePortefoljeNavn = (event, defaultNavn) => {
        const { id } = event.target;
        const name = event.target.value === defaultNavn ? undefined : event.target.value;
        const { bankregnr } = HelperFunctions.hentPortefoljeBasertPaaId(id, this.props.portefoljer).bank;

        this.setState({
            portefoljeEndringer: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.portefoljeEndringer,
                [id]: { name, bankregnr }
            }
        });
    };

    visBankNavn = banknavn => (this.props.visBankNavn ? <div className="bank-navn">{banknavn}</div> : null);

    endrePortefoljeNavnFeltHtml = portefolje => (
        <div key={portefolje.id} className="fond-skjema-seksjon">
            {this.visBankNavn(portefolje.bank.banknavn)}
            <TextInput
                id={portefolje.id}
                label={this.props.texts.get('endreFondsKontoNavn')}
                defaultValue={portefolje.navn}
                onChange={event => {
                    this.endrePortefoljeNavn(event, portefolje.navn);
                }}
            />

            <div className="portfolio-base-code">
                {portefolje.portfolioBaseCode} ({Format.currency(portefolje.totalVerdi)})
            </div>
            <ValidationMessages
                showValidationMessages={this.state.visHjelpetekst}
                validationMessages={[
                    {
                        condition: !this.validateInputField(portefolje.id),
                        message: this.props.texts.get('portefoljenavnetMaaHaMellomEttOgFemtiTegn')
                    }
                ]}
            />
        </div>
    );

    validateInputField = id => {
        const portefoljeEndring = this.state.portefoljeEndringer[id];

        if (portefoljeEndring) {
            const { name } = portefoljeEndring;
            const empty = name === '';
            const toLong = name && name.length > 50;
            const noIllegalLetters = Validate.alphaNumericValidation(name);

            return !empty && !toLong && noIllegalLetters;
        }

        return true;
    };

    toggleSaveChangesButton = () => {
        const disabled = HelperFunctions.isEmpty(this.state.portefoljeEndringer);

        return (
            <button
                type="button"
                disabled={disabled}
                className="btn btn-primary"
                onClick={this.lagrePortefoljeNavnEndringer}
            >
                {this.props.texts.get('endre')}
            </button>
        );
    };

    endrePortefoljeNavnSkjemaHtml = portefoljer => {
        if (this.state.visEndrePortefoljeNavnSkjema) {
            return (
                <div className="endre-portefolje-navn-kort">
                    {portefoljer.map(this.endrePortefoljeNavnFeltHtml)}
                    <div className="expandable-card-footer">
                        <button type="button" className="btn btn-flat" onClick={this.avbryt}>
                            {this.props.texts.get('avbryt')}
                        </button>

                        {this.toggleSaveChangesButton()}
                    </div>
                </div>
            );
        }

        return null;
    };

    render() {
        if (this.state.ikkeEksternePortefoljer) {
            if (this.state.loading) {
                return <Loading title={this.props.texts.get('endrerPortefoljeNavn')} />;
            }

            return (
                <div className="endre-portefolje-navn">
                    <button
                        type="button"
                        className="endre-portefolje-navn-knapp"
                        onClick={this.visEndrePortefoljeNavnSkjema}
                    >
                        <div className="endre-portefolje-navn-knapp-inner">
                            <div className="ikon-penn-24" />
                            <div>{this.props.texts.get('endrePortefoljeNavn')}</div>
                        </div>
                    </button>
                    {this.endrePortefoljeNavnSkjemaHtml(this.props.portefoljer)}
                </div>
            );
        }
        return <div />;
    }
}

export default EndrePortefoljeNavn;
