import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HelperFunctions from '../../helper-functions';
import Epost from '../../common/epost';
import ValidationMessages from '../../common/validation-messages';
import VelgKonto from '../velg-konto';
import Checkbox from '../../checkbox';
import NumberInput from '../../common/number-input';
import * as kundevurderingConst from '../../../domain/kundevurdering';
import KundevurderingStopp from '../../KundevurderingStopp';

class KlientKontoUttakSkjema extends Component {
    static propTypes = {
        portefolje: PropTypes.object,
        kontoer: PropTypes.array,
        texts: PropTypes.object,
        epost: PropTypes.string,
        endreKundeInfo: PropTypes.func,
        opprettUttaksOrdre: PropTypes.func,
        settKvitteringsInnhold: PropTypes.func,
        kundevurdering: PropTypes.string
    };

    constructor(props) {
        super(props);

        const standardVerdier = {
            engangsBelop: 0,
            visHjelpetekst: false
        };

        this.state = {
            standardVerdier,
            ...standardVerdier
        };
    }

    resetForm = () => {
        // eslint-disable-next-line react/no-access-state-in-setstate
        this.setState({ ...this.state.standardVerdier });
    };

    submitForm = () => {
        if (this.validateFormInput()) {
            if (this.state.epost) {
                this.props.endreKundeInfo({ epost: this.state.epost });
            }

            this.createReceiptContent();

            const data = this.createUttakDTO();
            this.resetForm();
            this.props.opprettUttaksOrdre(data);
            // this.props.history.push(`/portefolje/${this.props.portefolje.id}`);
        } else {
            this.setState({
                visHjelpetekst: true
            });
        }
    };

    createUttakDTO = () => ({
        engangsBelop: this.state.engangsBelop,
        portefoljeId: this.props.portefolje.id,
        kontonummer: this.state.kontonummer,
        bankregnr: { value: this.props.portefolje.bank.bankregnr },
        epost: this.state.epost || this.props.epost
    });

    createReceiptContent = () =>
        this.props.settKvitteringsInnhold({
            type: 'uttak',
            lasteTekst: this.props.texts.get('verifisererOrdre'),
            portefoljeNavn: this.props.portefolje.navn,
            engangsBelop: this.state.engangsBelop,
            kontonummer: this.state.kontonummer,
            kontonavn: this.state.kontonavn
        });

    visBelopMelding = () =>
        this.state.visBelopMelding ? (
            <div className="hjelpe-tekst">{this.props.texts.get('duKanIkkeSelgeHeleBeholdningen')}</div>
        ) : null;

    validateFormInput = () =>
        this.state.kontonummer &&
        this.state.engangsBelop > 0 &&
        this.state.engangsBelop <= this.props.portefolje.kontantBeholdning &&
        (this.state.epost || this.props.epost) &&
        this.state.salgUnderstand;

    visEpost = () => (
        <Epost
            label={this.props.texts.get('epost')}
            show={!this.props.epost}
            onChange={epost => this.setState(epost)}
            showValidationMessage={this.state.visHjelpetekst}
            validationMessage={this.props.texts.get('skrivEnGyldigEpostAdresse')}
        />
    );

    aktiverOverforKnapp = () =>
        this.validateFormInput() ? (
            <button type="button" className="btn btn-primary" onClick={this.submitForm}>
                {this.props.texts.get('overfor')}
            </button>
        ) : (
            <button type="button" className="btn btn-primary" onClick={this.submitForm}>
                {this.props.texts.get('overfor')}
            </button>
        );

    endreUttak = event =>
        this.setState({
            engangsBelop: HelperFunctions.clamp(event.target.value, 0, this.props.portefolje.kontantBeholdning)
        });

    checkBoxHtml = (tekst, id, func, checked, validation) => (
        <Checkbox id={id} name={id} onChange={func} checked={checked}>
            <div>
                <div>{tekst}</div>
                {validation ? validation() : ''}
            </div>
        </Checkbox>
    );

    endreCheckbox = (checked, id) => this.setState({ [id]: checked });

    render = () => {
        const { visHjelpetekst=false } = this.state;
        
        return this.props.kundevurdering === kundevurderingConst.MA_BEHANDLES_MANUELT || this.props.portefolje.sperre ?
            <KundevurderingStopp
                {...this.props} 
                title={this.props.portefolje.sperre ? this.props.texts.get(`${this.props.portefolje.sperre.toLowerCase()}SperretKontoTitle`) : ''}
                text={this.props.portefolje.sperre ? this.props.texts.get(`${this.props.portefolje.sperre.toLowerCase()}SperretKontoText`) : ''}
            />
            :
            <div>
                <div>
                    <div className="fond-skjema-seksjon">
                        <NumberInput
                            id="uttak"
                            label={this.props.texts.get('hvorMyeVilDuTaUt')}
                            value={this.state.engangsBelop}
                            onChange={this.endreUttak}
                        />

                        <ValidationMessages
                            showValidationMessages={visHjelpetekst}
                            validationMessages={[
                                {
                                    condition: !(
                                        this.state.engangsBelop > 0 &&
                                        this.state.engangsBelop <= this.props.portefolje.kontantBeholdning
                                    ),
                                    message: this.props.texts.get('skrivInnEnGyldigSum')
                                }
                            ]}
                        />
                    </div>
                    <div className="fond-skjema-seksjon">
                        <VelgKonto
                            id="kontonummer"
                            tittel={this.props.texts.get('tilKonto')}
                            kontoer={HelperFunctions.filtrerUtKlientKontoer(this.props.kontoer)}
                            onChange={konto => this.setState(konto)}
                            texts={this.props.texts}
                            visHjelpetekst={visHjelpetekst}
                        />
                    </div>
                    {this.visEpost()}

                    {this.checkBoxHtml(
                        'Jeg forstår at salg av fond kan utløse skatt ved gevinst.',
                        'salgUnderstand',
                        this.endreCheckbox,
                        this.state.salgUnderstand
                    )}

                    { !!visHjelpetekst && !this.state.salgUnderstand && <div className="hjelpe-tekst" aria-live="polite">
                        Vennligst bekreft at du har forstått
                    </div>}
                </div>
                <div className="expandable-card-footer">
                    <button type="button" className="btn btn-flat" onClick={this.resetForm}>
                        {this.props.texts.get('avbryt')}
                    </button>
                    {this.aktiverOverforKnapp()}
                </div>
            </div>
    };
}

export default KlientKontoUttakSkjema;
