import { get } from "@eika-infrastruktur/request";
import { getAISplan } from '../common/util/FondFunctions';

export const SET_FOND_KALKULATOR = 'SET_FOND_KALKULATOR';

/*******************************************************************************
*******************************************************************************/
export const getFondKalkulator = ( data, callback ) => async ( dispatch ) => {
    let result = null;
    const { isin='', monthly=0, onetime=0, frequency='', point=5 } = data;
    if ( !isin || (!monthly && !onetime) ) {
        return typeof(callback) === 'function' ? callback( result ) : 
            dispatch({ type: SET_FOND_KALKULATOR, data: result });
    }

    const monthlyAmount = parseInt(`${monthly || 0}`, 10);
    const start = monthlyAmount + parseInt(`${onetime || 0}`, 10);
    const param = [
        `maanedligSparing=${monthly}`,
        `frekvens=${/^DAGLIG|MAANEDLIG|AARLIG^/.test(frequency) ? frequency : 'AARLIG'}`,
        `punkter=${point}`
    ];

    const base = '/sparing-min-side-web/rest/resource';
    const url = `${base}/kalkulator/forventet-utvikling/${isin}/${start}?${param.join('&')}`;

    try {
        result = await get( url ).catch( () => null );
    } catch ( error ) {
        result = null;
    }

    if ( monthlyAmount && data.aisValue && data.aisFrequency ) {
        result.aisPlan = getAISplan({
            ...data,
            amount: monthlyAmount,
            performance: (result.aarligAvkastningUtenKostnader / 100),
            aisFrequency: data.aisFrequency,
            year: 30,
            aisValue: parseFloat( data.aisValue ),
        });
    }

    return typeof(callback) === 'function' ? 
        callback( result ) : dispatch({ type: SET_FOND_KALKULATOR, data: result });
};

export const setFondKalkulator = ( result ) => async ( dispatch ) => {
    return dispatch({ type: SET_FOND_KALKULATOR, data: result });
};

/*******************************************************************************
*******************************************************************************/
const initialState = {
    fondKalkulator: null,    
};

export default (state = initialState, action) => {
    switch (action.type) {   
        case SET_FOND_KALKULATOR: {
            return {
                ...state,
                fondKalkulator: action.data
            };
        }        
        default:
            return state;
    }
};