import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../common/text-input';
import NumberInput from '../common/number-input';
import ValidationMessages from '../common/validation-messages';
import ValidationFunctions from '../validation-functions';
import ActionButton from '../action-button';
import HelperFunctions from '../helper-functions';
import SliderInput from '../common/slider-input';
import ValgKnapper from '../valg-knapper';

class Sparemaalskjema extends Component {
    static propTypes = {
        texts: PropTypes.object,
        onSubmit: PropTypes.func,
        sparemaal: PropTypes.object,
        submitButtonText: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            goalUndefinedButtonActive: this.props.sparemaal.goal === 0,
            showValidationTexts: false,
            goalNumberInput: this.props.sparemaal.goal === 0 ? '' : this.props.sparemaal.goal,
            sparemaal: {
                ...this.props.sparemaal,
                savingPeriod: this.props.sparemaal.savingPeriod || 1
            }
        };
    }

    validateName = name => ValidationFunctions.tekstenErMellom(name, 1, 25);

    validateGoal = goal =>
        ValidationFunctions.onlyDigits(goal) && ValidationFunctions.verdiErMellom(goal, 0, 100000000);

    updateSparemaalState = change =>
        this.setState({
            sparemaal: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.sparemaal,
                ...change
            }
        });

    updateSparemaalGoal = goal =>
        this.setState({
            sparemaal: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.sparemaal,
                goal: HelperFunctions.clamp(goal, 0, 99999999)
            },
            goalNumberInput: HelperFunctions.clamp(goal, 0, 99999999)
        });

    validateSparemaal = sparemaal =>
        this.validateName(sparemaal.name) &&
        (this.validateGoal(sparemaal.goal) || sparemaal.goal === 0) &&
        sparemaal.type;

    onSubmit = () => {
        const sparemaal = {
            ...this.state.sparemaal,
            goal: this.state.goalUndefinedButtonActive ? 0 : this.state.sparemaal.goal
        };

        const sparemaalValidated = this.validateSparemaal(sparemaal);
        this.setState({
            showValidationTexts: !sparemaalValidated
        });

        if (sparemaalValidated) {
            this.props.onSubmit(sparemaal);
        }
    };

    visSpareVarighet = () =>
        HelperFunctions.VariableInjection(this.props.texts.get('sparemaalSparingIAntallAar'), {
            antall: {
                value: `${this.state.sparemaal.savingPeriod}
            ${this.state.sparemaal.savingPeriod >= 20 ? '+' : ''}`,
                class: 'tall'
            }
        });

    render = () => (
        <div className="sparemaal-skjema">
            <div className="fond-skjema-seksjon">
                <TextInput
                    id="name"
                    label={this.props.texts.get('sparemaalNavn')}
                    onChange={event => this.updateSparemaalState({ name: event.target.value })}
                    value={this.state.sparemaal.name}
                />

                <ValidationMessages
                    showValidationMessages={this.state.showValidationTexts}
                    validationMessages={[
                        {
                            condition: !this.validateName(this.state.sparemaal.name),
                            message: this.props.texts.get('sparemaalnavnIkkeGyldig')
                        }
                    ]}
                />
            </div>

            <div className="fond-skjema-seksjon hvor-mye-vil-du-spare">
                <NumberInput
                    id="goal"
                    value={this.state.goalNumberInput || ''}
                    onChange={event => this.updateSparemaalGoal(event.target.value)}
                    label={this.props.texts.get('sparemaalHvorMyeVilDuSpare')}
                    disabled={this.state.goalUndefinedButtonActive}
                >
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button
                        style={
                            this.state.goalUndefinedButtonActive ? { color: 'white', backgroundColor: '#004F59' } : {}
                        }
                        className="btn sparemaal-fix"
                        onClick={() =>
                            this.setState({
                                // eslint-disable-next-line react/no-access-state-in-setstate
                                goalUndefinedButtonActive: !this.state.goalUndefinedButtonActive,
                                // eslint-disable-next-line react/no-access-state-in-setstate
                                goalNumberInput: this.state.goalUndefinedButtonActive ? this.state.sparemaal.goal : 0
                            })
                        }
                    >
                        {this.props.texts.get('sparemaalVetIkke')}
                    </button>
                </NumberInput>
                <ValidationMessages
                    showValidationMessages={this.state.showValidationTexts}
                    validationMessages={[
                        {
                            condition: !(
                                this.validateGoal(this.state.sparemaal.goal) || this.state.goalUndefinedButtonActive
                            ),
                            message: this.props.texts.get('sparemaalUgyldigSparebelop')
                        }
                    ]}
                />
            </div>

            <div className="fond-skjema-seksjon">
                <ValgKnapper
                    tittel={`${this.props.texts.get('sparemaalType')}`}
                    valgMuligheter={[
                        { id: 'pensjon', tekst: 'Pensjon', ikon: 'ikon-palme-24' },
                        { id: 'bolig', tekst: 'bolig', ikon: 'ikon-hus-24' },
                        { id: 'barna', tekst: 'barna', ikon: 'ikon-ung-24' },
                        { id: 'buffer', tekst: 'buffer', ikon: 'ikon-lommebok-24' },
                        { id: 'ferie', tekst: 'Ferie', ikon: 'ikon-forsikringsbevis-reise-24' },
                        { id: 'bareSparer', tekst: 'Bare sparer', ikon: 'ikon-sparegris-24' }
                    ]}
                    valg={this.state.sparemaal.type}
                    endreValg={valg => this.updateSparemaalState({ type: valg })}
                />
                <ValidationMessages
                    showValidationMessages={this.state.showValidationTexts}
                    validationMessages={[
                        {
                            condition: !this.state.sparemaal.type,
                            message: this.props.texts.get('sparemaalIkkeValgtType')
                        }
                    ]}
                />
            </div>

            <div className="fond-skjema-seksjon">
                <div className="jeg-onsker-spare-i">{this.visSpareVarighet()}</div>
                <SliderInput
                    id="varighet"
                    min={1}
                    max={20}
                    step={1}
                    value={this.state.sparemaal.savingPeriod}
                    onChange={event =>
                        this.setState({
                            // eslint-disable-next-line react/no-access-state-in-setstate
                            sparemaal: { ...this.state.sparemaal, savingPeriod: parseInt(event.target.value, 10) }
                        })
                    }
                />
            </div>

            <div className="fond-skjema-seksjon">
                <ActionButton text={this.props.submitButtonText} action={this.onSubmit} />
            </div>
        </div>
    );
}

export default Sparemaalskjema;
