import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OverskredetAntallKall extends Component {
    render() {
        const { onClick, texts } = this.props;
        return (
            <div>
                <div className="header">{texts.get('overskredetAntallKallHeader')}</div>
                <div className="ingress">{texts.get('overskredetAntallKallIngress')}</div>
                <button type="button" className="btn btn-primary" onClick={onClick}>
                    {texts.get('overskredetAntallKallKnapp')}
                </button>
            </div>
        );
    }
}

OverskredetAntallKall.propTypes = {
    onClick: PropTypes.func,
    texts: PropTypes.object
};

export default OverskredetAntallKall;
