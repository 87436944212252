import { get, put, post, del } from "@eika-infrastruktur/request";
import {processError, processResponse} from './common';
import { postLog } from './app';

export const LEGG_TIL_KONTO_FRA_ANNEN_BANK = 'LEGG_TIL_KONTO_FRA_ANNEN_BANK';
export const HENTE_KONTOER = 'HENTE_KONTOER';
export const HENT_KUNDEFORHOLD = 'HENT_KUNDEFORHOLD';

const bankUrl = '/sparing-min-side-web/rest/resource/bank';
const setAktivKontoUrl = `${bankUrl}/konto/settaktiv`;
const kontoUrl = `${bankUrl}/konto`;
const bankKundeForholdUrl = `${bankUrl}/kundeforhold`;

export const leggTilKontoFraAnnenBank = (konto) => {
    return (dispatch) => {
        post(kontoUrl, konto).then((data) => {
                processResponse(HENTE_KONTOER, data, dispatch);
        }).catch((error) => processError(HENTE_KONTOER, error, dispatch));
    };
};

export const lagreStatusForKonto = (id, aktiv) => {
    return (dispatch) => {
        put(`${setAktivKontoUrl}/${id}`, aktiv).then((data) => {
                processResponse(HENTE_KONTOER, data, dispatch);
        }).catch((error) => processError(HENTE_KONTOER, error, dispatch));
    };
};

export const slettKonto = (id) => {
    return (dispatch) => {
        del(`${kontoUrl}/${id}`).then((data) => {
            processResponse(HENTE_KONTOER, data, dispatch);
        }).catch((error) => processError(HENTE_KONTOER, error, dispatch));
    };
};

export const endreKonto = (id, text, value) => {
    return (dispatch) => {
        put(`${kontoUrl}/${id}`, { navn: text, verdi: value }).then((data) => {
                processResponse(HENTE_KONTOER, data, dispatch);
        }).catch((error) => processError(HENTE_KONTOER, error, dispatch));
    };
};

export const hentKontoer = () => (dispatch) => {
    const api = kontoUrl;
    return get(api).then((data) =>{
        return processResponse(HENTE_KONTOER, data, dispatch, undefined, api);
    }).catch( (error) => {
        dispatch(postLog(error, {api, function: 'hentKontoer'}));
    });
};

export const hentKundeForhold = () => (dispatch) => {
    const api = bankKundeForholdUrl;
    return get(api).then((data) =>{
        return processResponse(HENT_KUNDEFORHOLD, data, dispatch, undefined, api);
    }).catch( (error) => {
        dispatch(postLog(error, {api, function: 'hentKundeForhold'}));
    });
};

const initialState = {
    kundeforhold: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HENT_KUNDEFORHOLD:
            return { ...state, kundeforhold: action.data || [] };
        default:
            return state;
    }
};
