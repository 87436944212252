/* Kiet test component - not jet in use */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import CountdownImage from './CountdownImage';
import './Countdown.scss';

interface Item {
    number: number;
    delay?: number;
    begin?: number;
};

interface DigitItem {
    name: string;
    list: Item[],
};

interface Props {
    noImage?: boolean;
    namespace?: string;
    seconds?: number;
    h1?: string;
    h2?: string;
    description?: string;
    finishText?: string;
    finishCallback?: () => void;
    textList?: {text: string, type?: string}[];
};

const getSeconds = ( seconds?: number): number => {
    const interval = [10, 999];
    if ( !seconds || seconds < interval[0] ) { return interval[0]; }
    return seconds > interval[1] ? interval[1] : seconds;
};
 
const getDigitList = (seconds?: number): DigitItem[] => {
    const nameList: string[] = ['hundred-place', 'tenth-place', 'single-place'].reverse();
    const digit = `${getSeconds(seconds)}`.split('').map( (i: string) => parseInt(i, 10) );
    if ( digit.length < 2 ) { digit.unshift(0); }
    if ( digit.length < 3 ) { nameList.pop(); }

    const digitList: DigitItem[] = [];
    digit.reverse().forEach( (size: number, i: number) => {
        const factor = i ? (10 ** i) : 0;
        const joinedDigit = digit.filter( (s: number, j: number) => !!s && j < i ).reverse().join('');
        const delayCounter = parseInt( (joinedDigit.replace(/^0+/g, '') || '0'), 10);
        const length = ((i+1) !== digit.length ? 10 : size) + (i > 0 ? 1 : 0);
        const item: DigitItem = {
            name: nameList.shift() || '',
            list: Array.from({ length }).map( (x: any, j: number) => {
                const count = size - j;
                const number = count < 0 ? (10 + count) : count;
                const begin = j === 0 && i > 0 ? (i * 10) : 0;
                let delay = j;
                if ( i > 0 ) {
                    let addition = 1;
                    if ( j === 0 ) {
                        addition = i > 1 ? -1 :0;
                    }
                    delay = (j < 2 ? 0 : ((j-1) * factor)) + delayCounter + addition;
                }
                return { number, begin, delay };
            }),
        };


        digitList.push(item);
    });
    return digitList.reverse();
};

export default ({seconds, h1, h2, description, finishText, finishCallback, namespace, noImage, textList}: Props): JSX.Element => {
    const [finisMessage, setFinishMessage] = useState<string>('');
    const digitList = getDigitList( seconds );

    useEffect(() => {
        const timer = setTimeout(() => {
            setFinishMessage( finishText || '' );
            if ( finishCallback ) { finishCallback(); }
        }, ((getSeconds(seconds) * 1000)));

        return () => {
            clearTimeout(timer);
        };
    }, [seconds, finishText, finishCallback]);

    return <div className={classNames('countdown-wrapper', namespace)}>
        { !!h1 && <h1>{h1}</h1> }
        { !!h2 && <h2>{h2}</h2> }
        { !!description && <p className="countdown-description">{description}</p> }

        { !noImage && <CountdownImage/> }

        { (textList || []).map( (d: {text: string; type?: string}, i: number) => (
            <div key={`text-item-${i}`} className={`text-item -${d.type || 'basic'}`}>{d.text}</div>
        ))}

        { finisMessage ? <div aria-live="polite" className="finish-message">{finisMessage}</div> : <>
            <ul className="countdown-holder">
                {digitList.map( (digit: DigitItem) => (
                    <li key={digit.name} className={classNames('digit-box', digit.name)}>
                        { digit.list.map( (item: Item) => (
                            <span
                                key={`${digit.name}-${item.number}-${item.begin || 'x'}`} 
                                className={classNames(digit.name, !!item.delay && `-delay-${item.delay}`, !!item.begin && `-begin-${item.begin}`)}
                            >{item.number}</span>
                        )) }
                    </li>
                ))}
            </ul>
            <div className="digit-label">sekunder igjen</div>
        </>}
    </div>
};
