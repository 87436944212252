import React from 'react';
import classNames from 'classnames';
import './Sprouts.scss';

interface Props {
    active: number;
    max?: number;
    label?: string;
};

export default ({ max, active, label}: Props): JSX.Element => {
    const length = max || active || 0;
    return <span 
        aria-label={label || `Miljø og etikk score på ${active} av ${max || 5}`} 
        data-value={`${active} av ${max || 5}`} 
        className={`sprouts-wrapper -length-${length} -${active ? 'active' : 'empty'}`}
    >  
        { Array.from({ length }).map((x: any, i:number) => {
            return <span key={`sprouts-icon-${i}`} aria-hidden="true" className={classNames('sprouts-item', {
                '-active': i < active,
            })} />
        })}
    </span>;
};
