import React, { useState, MouseEvent } from 'react';
import { StandardButton, LinkButton} from '@eika/button';
import { Link } from '@eika/link';
import { IconTypes } from '@eika/icon';
import { useSelector } from "react-redux";
import Grid from '../Grid/Grid';
import { HeaderLink, ComingSoon, VilkaarTekst, IPSavtaleTeskt, AksjesparekontoTekst, DisponertPage, NornePage } from '../share/ShareComponents';
import {openSmartspart, getPageAppending, getSmartsparURL} from '../util/Functions';
import Constant from '../../static/data/Constant';

import './BlankPage.scss';

interface Props {
    page: string;
    headerLink?: string;
};

const click = ( e: MouseEvent, key='' ): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === 'aapne-smartspar' ) {
        openSmartspart( key, '', getSmartsparURL() );
    }
};

export default (props: Props): JSX.Element => {
    const [appending] = useState<string>(getPageAppending(props));
    // @ts-ignore state is not typed anywhere so we have to guess what exists on it
    const norneURL = useSelector(state => state.App.norneDto.url);

    const link = (norneURL.length > 0) ? norneURL : Constant.urlNorne;

    return (
         <div className="blank-page-wrapper sgw-content">
            { !!props.headerLink && <Grid namespace="sparing-top" list={[{
                layout: 'twelve',
                element: <HeaderLink text={props.headerLink} />
            }]}/> }

            <div className={`blank-page-content ${appending || 'none-appending'}`}>
                <Grid namespace="sparing-content" list={[{
                    layout: 'six',
                    element: <>
                        { props.page === 'norne' && <NornePage> 
                            <>
                                <div className="center">
                                    <Link icon={IconTypes.EKSTERN_24} href={link} target="blank" iconOnRightSide>Logg inn hos Norne</Link>
                                </div>
                            </>
                        </NornePage> }
                        { props.page === 'disponert' && <DisponertPage> 
                            <div className="paragraph-small -include-top center">
                                <LinkButton icon={IconTypes.MOBIL_24} onClick={(e: MouseEvent) => { click(e, 'aapne-smartspar');}}>Gjør det i Smartspar</LinkButton>
                            </div>
                        </DisponertPage> }
                        { props.page === 'coming-soon' && <ComingSoon /> }
                        { props.page === 'aksjesparekonto-tekst' && <AksjesparekontoTekst /> }
                        { props.page === 'vilkaar-tekst' && <VilkaarTekst /> }
                        { props.page === 'ips-avtale-tekst' && <IPSavtaleTeskt /> }
                        { props.page === 'webview-prevent' && <div className="webview-prevent-wrapper"> 
                            <ComingSoon title="Kommer snart..." text="Vi holder fortsatt på med utviklingen av månedlig spareavtale i mobilbanken. Inntil videre kan du gå til Smartspar appen eller sparing på nett for å opprette månedlig sparing." />

                            <div className="paragraph-large -include-top  center">
                                <StandardButton onClick={(e: MouseEvent) => { click(e, 'aapne-smartspar')}}>Gå til Smartspar</StandardButton>
                            </div>

                            <div className="paragraph-large center">
                                <Link href="https://www.eika.no/oversikt/sparing">Gå til sparing på nett</Link>
                            </div>
                        </div>}
                    </>
                }]}/>
            </div>
        </div>
    );
};