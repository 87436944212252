import React, { useState } from 'react';
import Constant from '../static/data/Constant';

// eslint-disable-next-line
export default ({ texts, fond }) => {    
    const [show, setShow] = useState(false);    
    return texts && fond && fond.currency && fond.currency !== Constant.nok ? (
        <div className={`message-wrapper -info -icon expander-wrapper -${show ? 'open' : 'close'}`}>
            <a href="#" role="button" className="expander-btn" onClick={(e) => { e.preventDefault(); setShow(!show);}}>
                {texts.get('foreignCurrency')}
            </a>
            <div className="expander-content">
                { texts.get('foreignCurrencyInfo', {fundName: fond.navn})}
            </div>
        </div>
    ) : null;
};