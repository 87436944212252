const Constant = {
    fundLowestMinimumFee: 100,
    ipsMaxYearlyAmount: 15000,
    pensionCustomerMaxAge: 75,
    pensionCustomerMinAge: 62,
    monthlyMaxAmount: 900000,
    onetimeMaxAmount: 10000000,

    tradex: 'TRADEX',
    storebrand: 'STOREBRAND',

    individuelleOrdninger: 'INDIVIDUELLE_ORDNINGER',

    // currency
    nok: 'NOK',

    // access
    allowedToSell: 'SELL',
    allowedToBuy: 'BUY',

    // type
    typeKvatalSparing: 'QUARTERLY',
    typeArligSparing: 'ANNUAL',
    typeManedligSparing: 'MONTHLY',
    typeEngangsinnkudd: 'ONCE',

    // typeManedligSparing: 'monthly',
    // typeEngangsinnkudd: 'once',

    typeBankkonto: 'BANKKONTO',
    typeKlientkonto: 'KLIENTKONTO',
    typeAsk: 'ASK',
    typeVpk: 'VPK',
    typeIps: 'IPS',
    typeVps: 'VPS',
    typeIpa: 'IPA',
    typeIbp: 'IBP',
    typeGps: 'GPS',
    typeNyBankkonto: 'NEW_BANK_ACCOUNT',
    typeAmount: 'AMOUNT',
    typePercent: 'PERCENTAGE',
    typeGDPR: 'gdpr',

    isinBanksparing: 'BANKSPARING',

    // about
    aboutASK: 'om-aksjesparekonton',
    aboutVPK: 'om-verdipapirkonto',
    aboutIPS: 'om-individuell-pensjonssparing',
    aboutTaxFree: 'om-skatte-fri',

    // sperring
    fullSperret: 'FULL',
    salgSperret: 'SALG',

    // order status 
    orderStatusDone: 'DONE',
    orderStatusFailed: 'FAILED',
    orderStatusPending: 'PENDING',
    orderStatusNoMoney: 'NO_MONEY',

    // order status 
    orderTypeKjop: 'KJOP',
    orderTypeKjopBytt: 'KJOP_BYTT',
    orderTypeSalgBytt: 'SALG_BYTT',
    orderTypeUttak: 'SALG',

    // kundenhandling
    actionApneSmartspar: 'aapne-smartspar',
    actionSettInnPenger: 'sett-inn-penger',
    actionVelgFond: 'velg-fond',
    actionKjop: 'kjop-fond',
    actionKjopEngang: 'kjop-fond-engang',
    actionKjopSpareavtale: 'kjop-fond-spareavtale',
    actionSelg: 'selg-fond',
    actionUttak: 'uttak',
    actionBytt: 'bytt-fond',
    actionFlyttEPK: 'flytt-epk',
    actionEndreSpareavtale: 'editagreement',
    actionSlettSpareavtale: 'slett-spareavtale',
    actionByttEPKrisikoProfil: 'bytt-epk-risiko-profil',

    actionKjopTilBarn: 'kjop-til-barn',
    actionVisFondVeileder: 'vis-fond-veilder',
    actionVisSparekalkulator: 'vis-sparekalkulator',
    actionVisPensjonskalkulator: 'vis-pensjonskalkulator',
    actionVisFondListe: 'vis-fond-liste',

    actionCloseModal: 'close-modal',
    actionOpenModal: 'open-modal',
    actionOpenAboutTaxFree: 'open-about-tax-free',

    actionMoveEPK: 'move-epk',
    actionChangeEPKriskProfile: 'change-epk-risk-profile',
    actionDisplayEPKriskProfileInfo: 'display-epk-risk-profile-info',
    actionDisplayEPKhistoryDevelopmentGraph: 'display-epk-history-development-graph',
    actionConfirmMoveEPK: 'confirm-move-epk',
    actionConfirmChangeEPKriskProfile: 'confirm-change-epk-risk-profile',
    actionSignLater: 'sign-later',
    actionSignNow: 'sign-now',
    actionCancelSigning: 'cancel-signing',
    actionEPKmoveStutus: 'OPPRETTET',
    actionEPKmoveFailed: 'epk-move-failed',

    // Norwegian national character converting is following of
    // https://www.sprakradet.no/svardatabase/sporsmal-og-svar/a-o-og-a-i-internasjonal-sammenheng/
    trackIdBuy: 'sparing-cta-kjop',
    trackIdAction: 'sparing-action',
    trackIdProduct: 'sparing-produkt',
    trackIdMonthly: 'sparing-manedlig',
    trackIdBigLinkCard: 'sparing-markedskort',
    trackIdService: 'sparing-tjenester',

    // signering
    finishedSigning: 'FERDIG_SIGNERT',
    readyForSigning: 'KLAR_FOR_SIGNERING',
    signingSuccess: 'signingSuccess',
    signingCancel: 'signingCancel',
    signingError: 'signingError',

    // uttak fra klientkonto på ventendeordre fondIsin verdi
    klientkontoFondIsin: 'EIKAKFASK000',

    urlNorne: 'https://portal.norne.no',
    urlVPSinvestor: 'https://investor.vps.no/garm/auth/login',
    urlStorebrand: 'https://www.storebrand.no/',
    // urlStorebrandLogin: 'https://www.storebrand.no/kundeprofil/logins',
    urlStorebrandLogin: 'https://www.storebrand.no/min-sparing/partner/forside?partnerId=eika',

    // regexp
    regPublicSector: /offentlig(\s+)?tjenestepensjon/i,
    regCurrentPension: /(egen(\s+)?pensjonskonto|innskuddspensjon)/i, 

    // fund fee
    platformFee: 'PLATFORM_FEE',
    ongoingCharge: 'ONGOING_CHARGE',
    kickback: 'KICKBACK',
    yearlyCost: 'YEARLY_COST',

    termsOfUse: 'TERMS_OF_USE',
    termsGDPR: 'TERMS_GDPR',
    termFinanceLaw: 'FINANCE_LAW',

    piwikActionMonthly: 'monthly',
    piwikActionOnetime: 'onetime',
    piwikActionBuy: 'buy',
    piwikActionSell: 'sell',
    piwikActionSwitch: 'switch',
    piwikActionEditAgreement: 'editagreement',
    piwikActionWithdrawal: 'withdrawal',
};
export default Constant;