import { Fund } from 'src/domain/Fund';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type EntryType = 'FUNDLIST' | 'FUNDLISTLINK' | 'ONCE' | 'MONTHLY' | 'FUND' | 'NONE';
type PaymentType = 'ONCE' | 'MONTHLY' | 'NONE';

type State = {
    entry: EntryType;
    paymentType: PaymentType;
    currentFund: Fund | undefined;
};

type Action = {
    setEntry: (entry: EntryType) => void;
    setPaymentType: (paymentType: PaymentType) => void;
    setCurrentFund: (currentFund: Fund) => void;
};

const initialState: State = {
    entry: 'NONE',
    paymentType: 'NONE',
    currentFund: undefined,
};

const useMutualFundStore = create<State & Action>()(
    persist(
        (set) => ({
            ...initialState,
            setEntry: (entry: EntryType) => set(() => ({ entry })),
            setPaymentType: (paymentType: PaymentType) => set(() => ({ paymentType })),
            setCurrentFund: (currentFund: Fund) => set(() => ({ currentFund })),
        }),
        {
            name: 'mutual-fund-store',
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);

export default useMutualFundStore;
