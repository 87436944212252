// eslint-disable-next-line import/no-cycle
import { ProcessJsonResponse } from './common';

export const VIS_KVITTERING = 'VIS_KVITTERING';
export const VIS_KVITTERING_LASTER = 'VIS_KVITTERING_LASTER';
export const LUKK_KVITTERING = 'LUKK_KVITTERING';
export const SETT_KVITTERINGS_INNHOLD = 'SETT_KVITTERINGS_INNHOLD';
export const VIS_FEILMELDINGS_KVITTERING = 'VIS_FEILMELDINGS_KVITTERING';
export const SKJUL_OPPRETT_AKSJESPAREKONTO_MODAL = 'SKJUL_OPPRETT_AKSJESPAREKONTO_MODAL';
export const SKJUL_AKSJESPAREKONTO_POPUP = 'SKJUL_AKSJESPAREKONTO_POPUP';
export const SKJUL_ENDRE_OM_MEG_MODAL = 'SKJUL_ENDRE_OM_MEG_MODAL';
export const VIS_SMARTSPAR_POPUP = 'VIS_SMARTSPAR_POPUP';
export const SKJUL_SMARTSPAR_POPUP = 'SKJUL_SMARTSPAR_POPUP';

const baseUrlFond = '/sparing-min-side-web/rest/resource';

const skjulOpprettAksjesparekontoModalUrl = `${baseUrlFond}/fond/registrerAskMeldingKlikk`;
const smartsparPopupUrl = `${baseUrlFond}/smartspar/popup`; // TODO finn riktig Url

export const settKvitteringsInnhold = (innhold) => {
    return {
        type: SETT_KVITTERINGS_INNHOLD,
        innhold,
    };
};

export const skjulEndreOmMegModal = () => {
    return (dispatch) => {
        dispatch({
            type: SKJUL_ENDRE_OM_MEG_MODAL,
        });
    };
};

export const lukkKvittering = () => {
    return (dispatch) => {
        dispatch({
            type: LUKK_KVITTERING,
        });
    };
};

export const skjulOpprettAksjesparekontoModal = () => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
    };

    return (dispatch) => {
        fetch(skjulOpprettAksjesparekontoModalUrl, config).then(() => {
            dispatch({
                type: SKJUL_OPPRETT_AKSJESPAREKONTO_MODAL,
            });
        });
    };
};

export const skjulAksjesparekontoPopup = () => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
    };

    return (dispatch) => {
        fetch(skjulOpprettAksjesparekontoModalUrl, config).then(() => {
            dispatch({
                type: SKJUL_AKSJESPAREKONTO_POPUP,
            });
        });
    };
};

export const hentVisSmartSparPopup = () => {
    const config = {
        credentials: 'same-origin',
        method: 'GET',
    };

    return (dispatch) => {
        fetch(smartsparPopupUrl, config).then((data) => ProcessJsonResponse(VIS_SMARTSPAR_POPUP, data, dispatch));
    };
};

export const registrerSmartSparPopupVisning = () => {
    const config = {
        credentials: 'same-origin',
        method: 'POST',
    };

    return () => {
        fetch(smartsparPopupUrl, config);
    };
};

export const skjulSmartSparPopup = () => (dispatch) =>
    dispatch({
        type: SKJUL_SMARTSPAR_POPUP,
    });
