import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Input } from '@eika/input';
import { Checkbox } from '@eika/checkbox';
import { LinkButton, SmallButton } from '@eika/button';
import { formatAmount } from '../util/Functions';
import { usePrevious } from '../util/Hook';
import Message from '../Message/Message';
import { FormElementFocusAndBlurEvent } from '../../domain/Types';
import './AmountField.scss';

interface Props {
    id?: string;
    label?: string;
    sublabel?: string;
    value?: string | number;
    errorMessageClickableItem?: any;
    checkbox?: { label: string; checked: boolean; change: (e: any) => void; error?: boolean | string };
    buttonList?: { label: string; click: () => void; asLink?: boolean }[];
    displayFailed?: boolean;
    error?: string;
    attention?: string;
    onChange?: (e: any) => void;
    onBlur?: (e?: FormElementFocusAndBlurEvent) => void;
    onFocus?: (e?: FormElementFocusAndBlurEvent) => void;
    standard?: boolean;
    disabled?: boolean;
    unit?: string;
    maxLength?: number;
    nextFundClass?: any;
    click?: () => void;
    history?: any;
}

interface State {
    value: string;
    position: number;
    focus: boolean;
    field?: any;
}

const getCursorPosition = (field: any): number => {
    let position = 0;
    if (!field) {
        return position;
    }

    if ('selectionStart' in field) {
        // Standard-compliant browsers
        position = field.selectionStart;
        // @ts-ignore
    } else if (document.selection) {
        // @ts-ignore
        const sel = document.selection.createRange();
        // @ts-ignore
        const selLen = document.selection.createRange().text.length;
        sel.moveStart('character', -field.value.length);
        position = sel.text.length - selLen;
    }
    return position;
};

const setCursorPosition = (field: any, pos = 0): void => {
    if (!field) {
        return;
    }

    if (pos < 0) {
        pos = 0;
    }

    if (field.setSelectionRange) {
        // Modern browsers
        field.setSelectionRange(pos, pos);
    } else if (field.createTextRange) {
        // IE8 and below
        const range = field.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
};

const change = (props: Props, state: State, setState: (s: State) => void, e: any): void => {
    const current = e.target.value;
    const value = formatAmount(e.target.value);
    let position = getCursorPosition(state.field.current);

    if (current.length === position) {
        position = value.length;
    } else if (value.length > current.length) {
        position += value.length - current.length;
    } else if (value.length < current.length) {
        position -= current.length - value.length;
    }

    setState({ ...state, value, position });
    if (typeof props.onChange === 'function') {
        // eslint-disable-next-line
        props.onChange({ target: { value } });
    }
};

const AmountField = (props: Props): JSX.Element => {
    const {
        id = 'amount',
        label = 'Beløp',
        sublabel,
        errorMessageClickableItem,
        checkbox,
        standard,
        unit,
        buttonList = [],
        displayFailed,
        ...rest
    } = props;

    const [state, setState] = useState<State>({
        value: props.value ? formatAmount(props.value) || '' : '',
        position: 0,
        field: useRef<any>(null),
        focus: false,
    });
    const previousPropsValue = usePrevious(props.value);
    const previousStateValue = usePrevious(state.value);

    const { nextFundClass } = rest;

    useEffect(() => {
        if (previousPropsValue !== props.value) {
            setState({ ...state, value: props.value ? formatAmount(props.value) || '' : '' });
        }

        if (previousStateValue !== state.value && state.focus) {
            // componentDidUpdate
            setCursorPosition(state.field.current, state.position);
        }
    }, [props.value, previousPropsValue, previousStateValue, state, setState]);

    return (
        <div
            data-label={label}
            data-sublabel={sublabel || ''}
            className={classNames(
                `amount-field-wrapper -length-${(state.value || '').length} -${standard ? 'standard' : 'amount'}`,
                {
                    '-unit-percent': unit === '%',
                }
            )}
        >
            <div className="amount-field-holder">
                <Input
                    id={id}
                    label={label}
                    sublabel={sublabel}
                    type="tel"
                    {...rest}
                    value={state.value}
                    placeholder={state.focus ? '' : '0'}
                    onChange={(e: any) => {
                        change(props, state, setState, e);
                    }}
                    onFocus={(e) => {
                        setState({ ...state, focus: true });
                        if (typeof props.onFocus === 'function') {
                            props.onFocus(e);
                        }
                    }}
                    onBlur={(e) => {
                        setState({ ...state, focus: false });
                        if (typeof props.onBlur === 'function') {
                            props.onBlur(e);
                        }
                    }}
                    ref={state.field}
                    disabled={!!props.disabled}
                />
            </div>

            {!!rest.error && (
                <Message type="ErrorMessage" text={rest.error} icon={false} clickableItem={errorMessageClickableItem} />
            )}

            {checkbox && checkbox.label && checkbox.change && (
                <div
                    className={classNames('checkbox-holder', {
                        '-error': !!checkbox.error,
                    })}
                >
                    <Checkbox
                        onValueChange={(e) => {
                            checkbox.change(e);
                        }}
                        checked={!!checkbox.checked}
                        label={checkbox.label}
                    />
                </div>
            )}

            {(buttonList || []).length > 0 && (
                <div
                    className={classNames('button-holder', {
                        '-error': !!displayFailed,
                    })}
                >
                    {' '}
                    {buttonList.map((btn: { label: string; click: () => void; asLink?: boolean }, i) => {
                        return btn.asLink ? (
                            <LinkButton key={`amount-tool-btn-${i}`} onClick={btn.click}>
                                {btn.label}
                            </LinkButton>
                        ) : (
                            <SmallButton key={`amount-tool-btn-${i}`} buttonType="negative" onClick={btn.click}>
                                {btn.label}
                            </SmallButton>
                        );
                    })}
                </div>
            )}

            {nextFundClass && +props.value! >= rest.nextFundClass.shareClassThreshold && (
                <Message
                    type="WarningMessage"
                    text="Beløpet er høyt nok til at du kan få lavere årlig kostnad."
                    clickableItem={
                        <SmallButton buttonType="negative" onClick={() => rest.click!()}>
                            Bytt til {nextFundClass.name}
                        </SmallButton>
                    }
                />
            )}
            {!!rest.attention && <Message type="AttentionMessage" text={rest.attention} icon />}
        </div>
    );
};

export default AmountField;
