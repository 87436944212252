import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import { settSideTittel } from '../../actions/header';
import { getSparemaal, updateSparemaal } from '../../actions/sparemaal';
import { hentPrognose } from '../../actions/fond';
import Feilmelding from '../../components/feilmelding';
import LinkCard from '../../components/link-card';
import ProgresjonsGraf from '../../components/sparemaal/progresjons-graf-sparemaal';
import Format from '../../components/format-functions';
import SparemaalIkoner from '../../components/sparemaal/sparemaal-ikoner';
import ExpandableCard from '../../components/expandable-card';
import RedigerSparemaal from './rediger-sparemaal';

class DetaljerSparemaal extends Component {
    static propTypes = {
        match: PropTypes.object,
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        errors: PropTypes.object,
        getSparemaal: PropTypes.func,
        updateSparemaal: PropTypes.func,
        sparemaal: PropTypes.array,
        fondsPrognose: PropTypes.object,
        hentPrognose: PropTypes.func,
        portefoljeData: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            redigerExpandItem: {
                expanded: false,
                onExpand: this.onExpand,
            },
        };
    }

    UNSAFE_componentWillMount = () => {
        const { sparemaal, getSparemaal, match, settSideTittel, texts } = this.props;
        const { params } = match;
        if (!sparemaal) {
            getSparemaal();
        } else {
            this.init(params.id, sparemaal, !!sparemaal);
        }

        settSideTittel(texts.get('sparemaalTittel'));
    };

    UNSAFE_componentWillReceiveProps = (newProps) =>
        this.init(newProps.match.params.id, newProps.sparemaal, newProps.sparemaal !== this.props.sparemaal);

    onExpand = () => {
        const { redigerExpandItem } = this.state;
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            ...this.state,
            redigerExpandItem: {
                ...redigerExpandItem,
                expanded: !redigerExpandItem.expanded,
            },
        });
    };

    updateSparemaal = (data) => {
        this.props.updateSparemaal(data);
        this.setState({
            sparemaal: data,
        });
    };

    hentSpareAvtale = (id) => {
        const spareavtale = {};

        return (
            this.props.portefoljeData.portefoljer.some(
                (portefolje) =>
                    portefolje.fond &&
                    portefolje.fond.some(
                        (f) =>
                            f.spareavtaler &&
                            f.spareavtaler.some((s) => {
                                if (s.id.toString() === id) {
                                    spareavtale.trekkBelop = s.trekkBelop;
                                    spareavtale.isin = f.isin;
                                    return true;
                                }

                                return false;
                            })
                    )
            ) && spareavtale
        );
    };

    hentFondsVerdi = (portefoljeId, isin) =>
        this.props.portefoljeData.portefoljer
            .find((portefolje) => portefolje.id === portefoljeId)
            .fond.find((f) => f.isin === isin).totalVerdi;

    hentIsinOgPortefoljeId = (key) => {
        const ids = key.split('-');

        return {
            portefoljeId: ids[0],
            isin: ids[1],
        };
    };

    getIncludedFundValues = (includedFunds, fundData) => {
        for (const key in includedFunds) {
            if (Object.prototype.hasOwnProperty.call(includedFunds, key)) {
                const id = this.hentIsinOgPortefoljeId(key);
                const totalVerdi = this.hentFondsVerdi(id.portefoljeId, id.isin);

                if (totalVerdi) {
                    if (fundData[id.isin]) {
                        fundData[id.isin].totalVerdi = totalVerdi;
                    } else {
                        fundData[id.isin] = {
                            totalVerdi,
                        };
                    }
                }
            }
        }
    };

    getIncludedSpareavtaleValues = (includedSpareavtaler, fundData) => {
        for (const key in includedSpareavtaler) {
            if (Object.prototype.hasOwnProperty.call(includedSpareavtaler, key)) {
                const spareAvtale = this.hentSpareAvtale(key);

                if (spareAvtale) {
                    if (fundData[spareAvtale.isin]) {
                        fundData[spareAvtale.isin].maanedligSparing =
                            (fundData[spareAvtale.isin].maanedligSparing || 0) + spareAvtale.trekkBelop;
                    } else {
                        fundData[spareAvtale.isin] = {
                            maanedligSparing: spareAvtale.trekkBelop,
                        };
                    }
                }
            }
        }
    };

    mapSpareavtaleValuesToArray = (fundData) => {
        const array = [];

        for (const key3 in fundData) {
            if (Object.prototype.hasOwnProperty.call(fundData, key3)) {
                array.push({
                    isin: key3,
                    maanedligSparing: fundData[key3].maanedligSparing,
                    totalVerdi: fundData[key3].totalVerdi,
                });
            }
        }

        return array;
    };

    getPrognoseInput = (includedFunds, includedSpareavtaler) => {
        const fundData = {};

        this.getIncludedFundValues(includedFunds, fundData);
        this.getIncludedSpareavtaleValues(includedSpareavtaler, fundData);

        return this.mapSpareavtaleValuesToArray(fundData);
    };

    init = (id, sparemaalListe, sparemaalFinnes) => {
        if (sparemaalFinnes) {
            const sparemaal = this.fetchSparemaalFromId(sparemaalListe, id);

            if (this.props.portefoljeData) {
                this.props.hentPrognose({
                    prognoseInput: this.getPrognoseInput(sparemaal.includedFunds, sparemaal.includedSpareavtaler),
                    antallMaaneder: sparemaal.savingPeriod * 12,
                });
            }

            this.setState({
                sparemaal,
            });
        }
    };

    fetchSparemaalFromId = (sparemaal, id) => {
        let result;
        return (
            sparemaal.some((s) => {
                result = s;
                return s.id && s.id.toString() === id;
            }) && result
        );
    };

    sjekkForServerFeil = (errors) => errors.HENT_SPAREMAAL;

    beregnAvkastningsProsent = (initialWorth, currentWorth) => Format.percent((1 - initialWorth / currentWorth) * 100);

    visPrognoseGraf = (fondsPrognose) => {
        if (fondsPrognose) {
            return (
                <ProgresjonsGraf
                    name={this.state.sparemaal.name}
                    values={fondsPrognose.values}
                    yAxisMaxValue={7000}
                    texts={this.props.texts}
                    avkastning={this.state.sparemaal.totalMonthlySaving}
                    goal={this.state.sparemaal.goal}
                    varighet={this.state.sparemaal.savingPeriod}
                    saldo={this.state.sparemaal.currentWorth}
                    avkastningsProsent={this.beregnAvkastningsProsent(
                        this.state.sparemaal.initialWorth,
                        this.state.sparemaal.currentWorth
                    )}
                />
            );
        }

        return null;
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }
        if (this.state.sparemaal) {
            return (
                <div className="sparemaal-detaljer">
                    {this.visPrognoseGraf(this.props.fondsPrognose)}
                    <LinkCard link={`sparemaal/kjopFond/${this.state.sparemaal.id}`}>
                        <div className="sparemaal-card">
                            <SparemaalIkoner id="innskudd" />
                            <div className="column">
                                <span>{this.props.texts.get('sparemaalInnskudd')}</span>
                            </div>
                        </div>
                    </LinkCard>
                    <LinkCard link={`sparemaal/${this.state.sparemaal.id}/sparemiks`}>
                        <div className="sparemaal-card">
                            <SparemaalIkoner id="sparemiks" />
                            <div className="column">
                                <span>{this.props.texts.get('sparemaalSparemiks')}</span>
                            </div>
                        </div>
                    </LinkCard>
                    <ExpandableCard
                        header={this.props.texts.get('rediger')}
                        expandItem={this.state.redigerExpandItem}
                        ikon="ikon-Innstillinger-36"
                    >
                        <RedigerSparemaal
                            texts={this.props.texts}
                            errors={this.props.errors}
                            expandItem={this.state.redigerExpandItem}
                            updateSparemaal={this.props.updateSparemaal}
                            settSideTittel={this.props.settSideTittel}
                            sparemaal={this.state.sparemaal}
                        />
                    </ExpandableCard>
                </div>
            );
        }
        return <Loading title={this.props.texts.get('lasterPensjonsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Feilmeldinger,
            ...state.Texts,
            ...state.Sparemaal,
            ...state.Fond,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            getSparemaal: () => {
                dispatch(getSparemaal());
            },
            hentPrognose: (data) => {
                dispatch(hentPrognose(data));
            },
            updateSparemaal: (data) => {
                dispatch(updateSparemaal(data));
            },
        };
    }
)(DetaljerSparemaal);
