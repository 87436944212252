import React, { useState } from 'react';
import { IconTypes } from '@eika/icon';
import { ErrorLabel } from '@eika/label';
import { Input } from '@eika/input';
import { validate } from '../util/Validations';
import { FooterButton } from '../share/ShareComponents';
import { MultiObject, FormElementChangeEvent } from '../../domain/Types';
import './EditProfile.scss';

interface Props {
    kundeInfo: any;
    // eslint-disable-next-line
    submit?: (s: MultiObject)=> void;
    // eslint-disable-next-line
    close?: ()=> void;
    title?: string;
    display?: MultiObject;
    fixButton?: boolean;
    submitButton?: string;
    closeButton?: string;
};

interface State {
    success: string;
    error: string;
    viewList: any;
    title: string;
};

const test = ( state: State, setState: (s: State) => void, currentViewList=null, id='' ): boolean => {
    let error = false;
    const viewList = currentViewList || JSON.parse(JSON.stringify(state.viewList));
    viewList.forEach( (view: any) => {
        if ( id && view.id !== id ) { return; }

        delete( view.error );

        if ( view.required && !validate('required', view.value) ) {
            view.error = view.message.required;
        } else if ( !validate(view.id, view.value) ) {
            view.error = view.message.invalid;
        }

        if ( view.error ) { error = true; }
    });

    if ( error || id ) { setState({...state, viewList}); } 
    return error;
}

const change = ( state: State,  setState: (s: State) => void, e: FormElementChangeEvent, id='' ): void => {
    const viewList = JSON.parse(JSON.stringify(state.viewList));
    const view = viewList.find( (d: any) => d.id === id );
    if ( ! view ) { return; }

    view.value = e.target.value || '';
    if ( view.error ) {
        test( state, setState, viewList, id );
    } else {
        setState({...state, viewList});
    }
}

const submit = (props: Props, state: State, setState: (s: State) => void): void => {
    if ( test(state, setState) === true ) { return; }

    const data: MultiObject = state.viewList.reduce( (prev: any, view: any) =>{
        const value = `${view.value || ''}`.replace( /\s+/g, '');
        if ( value ) { prev[view.name] = value; }
        return prev;
    }, {});

    if ( props.submit ) { props.submit(data); }
}

const click = ( props: Props, state: State, setState: (s: State) => void, e: MouseEvent, key='' ): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === 'submit' ) {
        submit(props, state, setState );
    } else if ( key === 'close' ) {
        if ( props.close ) { props.close(); }
    }
}

const initState = ( kundeInfo={mobil:'', epost: ''}, display: any ): any => {
    return { viewList: [
        {
            id: 'email',
            name: 'epost',
            label: 'E-post',
            type: 'email',
            error: '',
            value: kundeInfo.epost || '',
            required: true,
            message: {
                required: 'E-postadresse er påkrevd',
                invalid: 'Skriv en gyldig e-postadresse',
            }
        }, {
            id: 'mobile',
            name: 'mobil',
            label: 'Mobilnummer',
            type: 'tel',
            error: '',
            value: kundeInfo.mobil || '',
            required: true,
            message: {
                required: 'Mobilnummer er påkrevd',
                invalid: 'Skriv et gyldig mobilnummer',
            }
        }
    ].filter( (view) => !display || !!display[view.id] )};
};

export default (props: Props): JSX.Element => {
    const [state, setState] = useState<State>({
        ...initState(props.kundeInfo, props.display),
        error: '',
        success: '',
        title: props.title === undefined ? 'Endre profil' : (props.title || ''),
    }); 

    return (
        <div className="edit-profile-wrapper">
            { !!state.title && <h1>{state.title}</h1> }

            { !!state.error && <ErrorLabel icon={IconTypes.UTROPSTEGN_24} text={state.error}/> }

            { state.success ? <div className="content -success">{state.success}</div> : 
                <div className="content">
                    { (state.viewList ?? []).map((view: any) =>{
                        return <div key={view.id} className="input-wrapper">
                            <Input type={view.type} label={view.label} value={view.value} required={view.required} error={view.error} 
                                onChange={(e: FormElementChangeEvent)=>{ change(state, setState, e, view.id); }}
                            />
                        </div>
                    })}
                </div>
            }

            <div className="content-footer -fix">
                { state.success ? 
                    <FooterButton click={(e: MouseEvent)=>{click(props, state, setState, e, 'close');}} next={props.closeButton || 'Lukk'} fix={props.fixButton !== false} /> :
                    <FooterButton click={(e: MouseEvent)=>{click(props, state, setState, e, 'submit');}} next={props.submitButton || 'Ferdig'} fix={props.fixButton !== false} />
                }
            </div>
        </div>
    );
};