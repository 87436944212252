import { get, post } from '@eika-infrastruktur/request';
import { postLog } from './app';

export const HENT_LISTE_OVER_CRS_LAND = 'HENT_LISTE_OVER_CRS_LAND';
export const KUNDEVURDERING = 'KUNDEVURDERING';
export const KJENNDINKUNDE_SKJEMA_FOR_KUNDE = 'KJENNDINKUNDE_SKJEMA_FOR_KUNDE';
export const KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE = 'KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE';
export const KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN = 'KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN';
export const KJENNDINKUNDE_LOADING = 'KJENNDINKUNDE_LOADING';

export const SET_LISTE_OVER_CRS_LAND = 'SET_LISTE_OVER_CRS_LAND';
export const SET_KUNDEVURDERING = 'SET_KUNDEVURDERING';
export const SET_KJENNDINKUNDE_SKJEMA_FOR_KUNDE = 'SET_KJENNDINKUNDE_SKJEMA_FOR_KUNDE';
export const SET_KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN = 'SET_KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN';
export const KJENNDINKUNDE_KAN_UTFYLLE_AHV = 'KJENNDINKUNDE_KAN_UTFYLLE_AHV';
export const SET_OPPFOLGINGSKJEMA_FOR_KUNDE = 'SET_OPPFOLGINGSKJEMA_FOR_KUNDE';
const baseUrl = '/sparing-min-side-web/rest/resource';

export const getListeOverCRSLand = (callback) => async (dispatch) => {
    let result = null;
    const url = `${baseUrl}/kjenndinkunde/crsland`;
    try {
        result = await get(url).catch((error) => {
            dispatch(postLog(error, { api: url, function: 'getListeOverCRSLand - fetch-catch' }));
            return null;
        });
    } catch (error) {
        dispatch(postLog(error, { api: url, function: 'getListeOverCRSLand - try-catch' }));
        result = null;
    }

    return typeof callback === 'function'
        ? callback(result)
        : dispatch({ type: SET_LISTE_OVER_CRS_LAND, data: result });
};

export const setListeOverCRSLand = (data) => async (dispatch) => {
    dispatch({ type: SET_LISTE_OVER_CRS_LAND, data });
};

export const hentListeOverCRSLand = () => {
    return (dispatch) => {
        dispatch(getListeOverCRSLand());
    };
};

/******************************************************************************
 ******************************************************************************/
export const getKundevurdering = (barnFnr, callback) => async (dispatch) => {
    let result = null;
    const url = barnFnr
        ? `${baseUrl}/kjenndinkunde/kundevurdering/barn/${barnFnr}`
        : `${baseUrl}/kjenndinkunde/kundevurdering`;
    try {
        result = await get(url).catch((error) => {
            dispatch(postLog(error, { api: url, function: 'getKundevurdering - fetch-catch' }));
            return null;
        });
    } catch (error) {
        dispatch(postLog(error, { api: url, function: 'getKundevurdering - try-catch' }));
        result = null;
    }
    return typeof callback === 'function' ? callback(result) : dispatch({ type: SET_KUNDEVURDERING, data: result });
};

export const setKundevurdering = (data) => async (dispatch) => {
    dispatch({ type: SET_KUNDEVURDERING, data });
};

export const hentKundevurderingBarn = (fnr) => {
    return (dispatch) => {
        dispatch(getKundevurdering(fnr));
    };
};
/******************************************************************************
 ******************************************************************************/
export const getSkjemaForKunde = (callback) => async (dispatch) => {
    let result = null;
    const url = `${baseUrl}/kjenndinkunde`;

    try {
        result = await get(url).catch((error) => {
            dispatch(postLog(error, { api: url, function: 'getSkjemaForKunde - fetch-catch' }));
            return null;
        });
    } catch (error) {
        dispatch(postLog(error, { api: url, function: 'getSkjemaForKunde - try-catch' }));
        result = null;
    }
    return typeof callback === 'function'
        ? callback(result)
        : dispatch({ type: SET_KJENNDINKUNDE_SKJEMA_FOR_KUNDE, data: result });
};

export const setSkjemaForKunde = (data) => async (dispatch) => {
    dispatch({ type: SET_KJENNDINKUNDE_SKJEMA_FOR_KUNDE, data });
};

export const hentSkjemaForKunde = () => {
    return (dispatch) => {
        dispatch(getSkjemaForKunde());
    };
};

/******************************************************************************
 ******************************************************************************/
export const getSkjemaForKundeBarn = (tradexCustomerId, callback, barnFnr) => async (dispatch) => {
    let result = null;
    const url = barnFnr
        ? `${baseUrl}/kjenndinkunde/barn/${barnFnr}`
        : `${baseUrl}/kjenndinkunde/barn/cid/${tradexCustomerId}`;

    try {
        result = await get(url).catch(() => null);
    } catch (error) {
        result = null;
    }

    return typeof callback === 'function'
        ? callback(result)
        : dispatch({ type: SET_KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN, data: result });
};

export const setSkjemaForKundeBarn = (data) => async (dispatch) => {
    dispatch({ type: SET_KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN, data });
};

export const hentSkjemaForKundeBarn = (fnr) => {
    return (dispatch) => {
        dispatch(getSkjemaForKundeBarn(undefined, undefined, fnr));
    };
};

/******************************************************************************
 ******************************************************************************/
export const kanUtfylleSkjemaForBarn = (barnFnr) => async (dispatch) => {
    let result = null;
    if (!barnFnr) {
        return dispatch({ type: KJENNDINKUNDE_KAN_UTFYLLE_AHV, data: result });
    }

    const url = `${baseUrl}/kjenndinkunde/kan-opprette-sparemal-barn/${barnFnr}`;
    try {
        result = await get(url).catch(() => null);
    } catch (error) {
        result = null;
    }
    dispatch({ type: KJENNDINKUNDE_KAN_UTFYLLE_AHV, data: result });
};

/******************************************************************************
 ******************************************************************************/
export const sendKjennDinKundeSvar = (data, bankregnr, fnrBarn, tradexCustomerId, callback) => async (dispatch) => {
    let result = null;
    let url;
    if (tradexCustomerId) {
        url = `${baseUrl}/kjenndinkunde/registrer/barn/cid/${tradexCustomerId}`;
    } else if (fnrBarn) {
        url = `${baseUrl}/kjenndinkunde/registrer/barn/${fnrBarn}`;
    } else {
        url = `${baseUrl}/kjenndinkunde/registrersvar`;
    }

    try {
        result = await post(url, data).catch(() => {
            return null;
        });
    } catch (error) {
        result = null;
    }

    return typeof callback === 'function' ? callback(result) : dispatch({ type: SET_KUNDEVURDERING, result });
};

export const sendKjennDinKundeSvarMSS = async (dispatch, data, bankregnr, fnrBarn, tradexCustomerId) => {
    let result = null;
    let url;
    if (tradexCustomerId) {
        url = `${baseUrl}/kjenndinkunde/registrer/barn/cid/${tradexCustomerId}`;
    } else if (fnrBarn) {
        url = `${baseUrl}/kjenndinkunde/registrer/barn/${fnrBarn}`;
    } else {
        url = `${baseUrl}/kjenndinkunde/registrersvar`;
    }

    try {
        result = await post(url, data).catch(() => {
            return null;
        });
    } catch (error) {
        result = null;
    }

    if (fnrBarn) {
        dispatch({ type: SET_KUNDEVURDERING, data: result ? 'OK' : 'FAILED', fnrBarn });
        return { data: result ? 'OK' : 'FAILED', fnrBarn };
    }

    dispatch({ type: SET_KUNDEVURDERING, result });
    return result;
};

/******************************************************************************
 ******************************************************************************/
export const getOppfolgingSkjemaForKunde = (callback) => async (dispatch) => {
    let result = null;
    const url = `${baseUrl}/kjenndinkunde/oppfolging`;

    try {
        result = await get(url).catch((error) => {
            dispatch(postLog(error, { api: url, function: 'getOppfolgingSkjemaForKunde - fetch-catch' }));
            return null;
        });
    } catch (error) {
        dispatch(postLog(error, { api: url, function: 'getOppfolgingSkjemaForKunde - try-catch' }));
        result = null;
    }

    return typeof callback === 'function'
        ? callback(result)
        : dispatch({ type: SET_OPPFOLGINGSKJEMA_FOR_KUNDE, data: result });
};

export const setOppfolgingSkjemaForKunde = (data) => async (dispatch) => {
    dispatch({ type: SET_OPPFOLGINGSKJEMA_FOR_KUNDE, data });
};

export const hentOppfolgingSkjemaForKunde = () => {
    return (dispatch) => {
        dispatch(getOppfolgingSkjemaForKunde());
    };
};

/******************************************************************************
 ******************************************************************************/
const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LISTE_OVER_CRS_LAND:
            return { ...state, crsLand: action.data };
        case SET_KUNDEVURDERING:
            return { ...state, kundevurdering: action.data };
        case SET_KJENNDINKUNDE_SKJEMA_FOR_KUNDE:
            return { ...state, skjemaForKunde: action.data };
        case SET_KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN:
            return { ...state, skjemaForKundeBarn: action.data };
        case KJENNDINKUNDE_KAN_UTFYLLE_AHV:
            return { ...state, kanUtfylleAHV: action.data };
        case SET_OPPFOLGINGSKJEMA_FOR_KUNDE:
            return { ...state, oppfolgingSkjemaForKunde: action.data };
        default:
            return state;
    }
};
