import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Validate from '../validation-functions';
import NumberInput from '../common/number-input';
import ValidationMessages from '../common/validation-messages';
import Epost from '../common/epost';

class EndreOmMeg extends Component {
    static propTypes = {
        texts: PropTypes.object,
        kundeInfo: PropTypes.object,
        endreKundeInfo: PropTypes.func,
        avbryt: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = props.kundeInfo
            ? {
                  epost: props.kundeInfo.epost,
                  mobil: props.kundeInfo.mobil,
                  visHjelpetekst: false
              }
            : {
                  visHjelpetekst: false
              };
    }

    endre = event => this.setState({ [event.target.id]: event.target.value });

    endreKundeInfo = () =>
        Validate.email(this.state.epost) && Validate.phoneNumber(this.state.mobil)
            ? this.props.endreKundeInfo({
                  epost: this.state.epost,
                  mobil: this.state.mobil
              })
            : this.setState({
                  visHjelpetekst: true
              });

    render = () => (
        <div>
            <div className="fond-skjema-seksjon">
                <Epost
                    label={this.props.texts.get('epost')}
                    show
                    onChange={epost => this.setState(epost)}
                    epost={this.props.kundeInfo.epost}
                    showValidationMessage={this.state.visHjelpetekst}
                    validationMessage={this.props.texts.get('skrivEnGyldigEpostAdresse')}
                />
            </div>
            <div className="fond-skjema-seksjon">
                <NumberInput
                    id="mobil"
                    label={this.props.texts.get('mobilNummer')}
                    value={this.state.mobil || ''}
                    onChange={this.endre}
                />

                <ValidationMessages
                    showValidationMessages={this.state.visHjelpetekst}
                    validationMessages={[
                        {
                            condition: !Validate.phoneNumber(this.state.mobil),
                            message: this.props.texts.get('skrivEttGyldigMobilNummer')
                        }
                    ]}
                />
            </div>
            <div className="expandable-card-footer">
                {/* eslint-disable-next-line react/button-has-type */}
                <button className="btn btn-flat" onClick={this.props.avbryt}>
                    {this.props.texts.get('avbryt')}
                </button>

                {/* eslint-disable-next-line react/button-has-type */}
                <button className="btn btn-primary" onClick={this.endreKundeInfo}>
                    {this.props.texts.get('endre')}
                </button>
            </div>
        </div>
    );
}

export default EndreOmMeg;
