import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from 'react-collapse';

require('../styles/expandable-card.scss');

class ExpandCard extends Component {
    static propTypes = {
        children: PropTypes.object,
        header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        expandItem: PropTypes.object,
        aktiv: PropTypes.bool,
        ikon: PropTypes.string,
        hidden: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);

        this.state = {
            aktiv: props.aktiv || props.aktiv === undefined
        };
    }

    toggle() {
        if (this.state.aktiv) {
            this.props.expandItem.onExpand(this.props.expandItem.id);
        }
    }

    render() {
        const { header, children } = this.props;
        const listClassNames = classNames('expandable-list-item', { expanded: this.props.expandItem.expanded });

        const ikonClassNames = classNames('expandable-icon', this.props.ikon);

        return (
            <div
                style={this.state.aktiv ? {} : { opacity: 0.5 }}
                className={`expandable-card ${this.props.hidden && 'hide'}`}
            >
                <div className={listClassNames} role="tab">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <div
                        className="expandable-list-item-heading"
                        onClick={this.toggle}
                        style={this.props.ikon ? { paddingLeft: '40px' } : {}}
                    >
                        {this.props.ikon ? <i className={ikonClassNames} /> : ''}
                        {header}
                        <button type="button" className="ikon-liten-pil-ned" />
                    </div>
                    <Collapse isOpened={!!this.props.expandItem.expanded}>
                        <div className="list-group-item-body">{children}</div>
                    </Collapse>
                </div>
            </div>
        );
    }
}

export default ExpandCard;
