import { MouseEvent } from 'react';
import Constant from '../../../static/data/Constant';
import { validate } from '../../../common/util/Validations';
import { formatAmount, sortList, roundUpValue, createCookie } from '../../../common/util/Functions';
import { getFondOrderStorage, setFondOrderStorage } from '../../../common/util/FondFunctions';
import { getAppName, openContactUs } from '../../../common/util/BusinessFunctions';
import { FormElementChangeEvent } from '../../../domain/Types';
import { Props, State, SigningConfig } from './EPKmoveDomain';
// @ts-ignore
import EPKriskProfileList from '../../../static/data/EPKriskProfileList';

export const getParam = ( props: Props ): any => {
    const { match } = props || {};
    const param = {...(match.params || {}), search: {}};
    for ( const key in param ) {
        if ( /^[0-9]+$/i.test(param[key]) ) {
            param[key] = parseInt( param[key], 10 );
        }
    }

    if ( param.step ) { param.step = parseFloat( param.step ); }
    return param;
};

export const getOrderStorage = ( props: Props, currentParam?: any ): any => {
    const param = currentParam || getParam( props );
    return getFondOrderStorage( param.order );
};


export const displayError = ( state: State, setState: (s: State) => void, action='submit', currentError?: any): void => {
    const error = currentError || JSON.parse(JSON.stringify(state.error || {}));
    setState({...state, signingMode: '', error: {...error, [Constant.actionEPKmoveFailed]: {
        action,
    }}});
};

const watchSigning = ( props: Props, state: State, setState: (s: State) => void ): void => {
    clearInterval((state.mode.current.configTimer || 0));
    state.mode.current.configTimer = setInterval( () => {
        props.action.getEPKsigningConfig((signingConfig: any) => {
            if ( signingConfig?.signing?.status !== Constant.finishedSigning ) { return; }
            createCookie('isEpkInTransitToEika', '1', 1);
            clearInterval(state.mode.current.configTimer);
            setState({...state, signingMode: 'finish', dialog: ''});
        }); 
    }, 3000);
};

/** ************************************************************************
 ************************************************************************* */
export const verify = ( props: Props, state: State, setState: (s: State) => void, id?: any, ignoreUpdate?: boolean, currentStorage?: any ): boolean => {
    const { error={}, stepFieldKeyList={} } = state;
    const param = getParam( props );
    const orderStorage = currentStorage || getOrderStorage(props, param);
    const cloned = JSON.parse(JSON.stringify(error));
    // eslint-disable-next-line
    const pin = typeof(id) === 'string' ? {[id]: 1} : (typeof(id) === 'object' ? id : null);
    const keyList = stepFieldKeyList[`${param.step}`] || [];
    const keyPin = keyList.reduce( (prev: any, key: string) => {  
        prev[key] = 1;
        return prev;
    }, {});

    for ( const key in (pin || {}) ) {
        if ( !keyPin[key] ) { keyList.push(key); }
    }

    keyList.forEach( (key: string) => {
        if ( pin && !pin[key] ) { return; }

        delete( cloned[key] );
        if ( key === 'email' && !validate('email', orderStorage[key] )) {
            cloned[key] = 'Epost er ugyldig';
        }
    });

    if (!ignoreUpdate) {
        setState({...state, error: cloned});
    }

    delete(cloned.overwriting);
    return JSON.stringify(cloned) === '{}';
};

/**************************************************************************
**************************************************************************/
export const back = ( length=1, props?: Props ): void => {
    if ( props ) {
        setTimeout( () => {
            const param = getParam( props );
            const orderStorage = getOrderStorage( props );
            orderStorage.history.back = (orderStorage.history.back || 0) + length;
            setFondOrderStorage( param.order, orderStorage );
        }, 500);
    }
    window.history.go((length * -1));
};

export const cancel = ( props: Props, backAddition=0 ): void => {
    const orderStorage = getOrderStorage( props );
    const { history={} } = orderStorage;
    const sorted = sortList( JSON.parse(JSON.stringify((history.lengthList || []))), 'id', false, true);
    const length = sorted[0].id;
 
    if ( length && props.history.length ) {
        const delta = orderStorage.history.back || 0;
        const alfa = JSON.stringify((orderStorage.history || {}).lengthList) !== JSON.stringify(sorted) ? 1 : 0;
        const step = window.history.length - length - alfa - delta + backAddition;

        if ( step <= 0 ) {
            window.location.href = orderStorage.history.href;
        } else {
            orderStorage.done = true;
            back( step );
        }
    }  else if ( (orderStorage.history || {}).href ) {
        window.location.href = orderStorage.history.href;
    }
};

export const finish = ( props: Props ): void => {
    const orderStorage = getOrderStorage( props );
    const finishBack = orderStorage.finishBack || 0;
    cancel( props, finishBack );
};

export const save = ( props: Props, state: State, setState: (s: State) => void, data={}, ignoreValidation=false, forceUpdate=false ): void => {
    const param = getParam( props );
    const orderStorage = { ...getOrderStorage(props, param), ...data };
    setFondOrderStorage( param.order, orderStorage );

    if ( !ignoreValidation ) {
        const pin: any = {};
        for ( const key in data ) { pin[key] = 1; }
        verify(props, state, setState, pin, undefined, orderStorage);
    } else if ( forceUpdate ) {
        setState({...state, force: (new Date()).getTime()});
    }
};

export const submit = ( props: Props, state: State, setState: (s: State) => void): void => {
    const orderStorage = getOrderStorage(props);
    const data: any = {
        appName: getAppName(),
        bankregnr: {value: orderStorage.bankregnr},
        epost: {value: orderStorage.email },
        epkFlyttConfigs: (orderStorage.epkData || []).map( (d: any) => {
            return d.reserved || !d.epkId ? null :
                {risikoprofilId: orderStorage.riskProfileId, oldEpkId: d.epkId };
        }).filter( (d: any) => !!d),
    };
    
    if ( !data.epkFlyttConfigs.length ) { 
        const param = getParam( props );
        // eslint-disable-next-line
        return next(props,state, setState, (param.step - 1)); 
    }
 
    const error = JSON.parse(JSON.stringify(state.error || {}));
    const base = {...state, error, signingMode: 'preparing'};
    setState(base);

    clearInterval((state.mode.current.configTimer || 0));
    props.action.postMoveEPK( data, (moved: {status: string} | null) => {
        if ( moved?.status !== Constant.actionEPKmoveStutus ) {
            return displayError( state, setState, 'submit' );
        }

        setState({...base, signingMode: 'pending'});
        const note = {timesUp: (90*1000), loop: 3000, count: 0};
        note.count = parseInt( `${note.timesUp / note.loop}`, 10);

        state.mode.current.configTimer = setInterval( () => {
            props.action.getEPKsigningConfig((signingConfig: SigningConfig) => {
                /* eslint-disable */
                const signingUrl = signingConfig?.signing?.status === Constant.readyForSigning ? 
                    (signingConfig?.signing?.signeringsurl || null) : (--note.count ? '' : null);
                /* eslint-enable */

                if ( signingUrl === null ) {
                    clearInterval((state.mode.current.configTimer || 0));
                    return displayError( state, setState, 'submit' );
                }

                if ( !signingUrl ) { return; }

                clearInterval(state.mode.current.configTimer);
                save(props, state, setState, {signingUrl, signingId: signingConfig?.signing?.id}, true);
                const update = {...base, signingMode: 'ready', dialog: 'signing'};
                setState(update);
                watchSigning(props, update, setState);
            }); 
        }, note.loop);
    });
};

export const next = ( props: Props, state: State, setState: (s: State) => void, step?: number): void => {
    if ( !step && !verify(props, state, setState) ) { return; }

    const param = getParam( props );
    // eslint-disable-next-line
    const orderStorage = getOrderStorage( props, param );
    const { history } = props;
    const splitedPathname = history.location.pathname.split('/');
    const current = splitedPathname.pop();

    if ( step === undefined ) {
        step = param.step + 1;
    } else if ( step === null ) {
        step = parseFloat( `${current || 0}`);
    }

    if ( step === 3 && !/^ok$/i.test(state.aml) ){
        return setState({...state, dialog: 'aml'});
    }

    history.push({
        pathname: splitedPathname.concat( `${step}` ).join('/'), 
    });

    if ( step === 3 && !orderStorage.receipt ) { 
        const shallEndreSpareProfil = (orderStorage.email && orderStorage.initedEmail !== orderStorage.email);
        if ( shallEndreSpareProfil ) {
            props.action.endreSpareProfil({
                epost: orderStorage.email || orderStorage.initedEmail,
                mobil: orderStorage.mobil || orderStorage.initedMobil,
            });
        }        
        submit( props, state, setState );
    }
};

/** ************************************************************************
 ************************************************************************* */
export const changeEmail = (  props: Props, state: State, setState: (s: State) => void, data: any ): void => {
    save(props, state, setState, {email: data.epost});
    back( 1, props );
};

export const changeBank = ( props: Props, state: State, setState: (s: State) => void, data: any ): void => {
    if ( !data?.value ) { return; }
    save(props, state, setState, {bankregnr: data.value, banknavn: data.label });
    back( 1, props );
};

export const closeDialog = ( props: Props, state: State, setState: (s: State) => void ): State => {
    if ( state.dialog === 'signing' ) {
        clearInterval((state.mode.current.configTimer || 0));
    }    

    const update = {...state, dialog: '', suggestedFund: null};
    setState( update );
    return update;
};

/** ************************************************************************
 ************************************************************************* */
export const blurEmail = ( props: Props, state: State, setState: (s: State) => void ): void => {
    const orderStorage = getOrderStorage( props );
    verify(props, state, setState, {'email': orderStorage.email});        
};

export const setEmail = ( props: Props, state: State, setState: (s: State) => void, e: FormElementChangeEvent ) : void=> {
    const ignoreValidation = !state.error?.email;
    save(props, state, setState, {email: e.target.value}, ignoreValidation);
    setState({...state, force: (new Date()).getTime()});
};

export const downloadRiskPorfilePDF = (riskProfileId?: number): void => {
    const info = EPKriskProfileList.find( (d:any) => d.riskProfileId === riskProfileId );
    if ( !info?.pdf ) { return; }

    const element = document.createElement('a');
    element.setAttribute('href', info.pdf);
    element.setAttribute('download', `${info.title}.pdf`.trim().replace(/\s+/g, '-'));

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

/** ************************************************************************
 ************************************************************************* */
export const getHistoryGraphChartConfig = (historyGraphList?: any, portfolioValue=100000, animation = true): any => {
    if ( !(historyGraphList instanceof Array) ) { historyGraphList = []; }

    const riskText = ['Lav risiko', 'Moderat risiko', '100% aksjer / Livssyklus'];
    const colorList = ['#968678', '#34AFD9', '#56A356'];
    const chartDataList: any = historyGraphList.map((utvikling: any) => {
        const pointList = ((utvikling?.lineChartData?.collection ?? [])[0]?.pointSeries ?? [])[0]?.points ?? [];
        const pin: any = {};
        const list = pointList.reverse().map((point: any) => {
            const dateSplited = (point?.x ?? '').split('-');
            if (!dateSplited[0] || pin[dateSplited[0]]) { return null; }
            pin[dateSplited[0]] = 1;

            const percent = parseFloat(`${point.y ?? 0}`);
            const value = Math.round(((portfolioValue * percent) / 100));
            return { value, year: parseInt(dateSplited[0], 10) };
        }).filter((data: any) => !!data).sort((dataA: any, dataB: any) => {
            if (dataA.year === dataB.year) { return 0; }
            return dataA.year < dataB.year ? -1 : 1;
        });
        return list;
    });

    const chartConfig: any = {
        type: 'spline',
        view: [750, 450],
        data: [],
        highest: 0,
        animation,
        padding: { top: 10, left: 10, bottom: 40, right: 90 },
        colorList,
        xAxis: { grid: 5, text: [], size: 22 },
        yAxis: { grid: 0, separationLine: false, separation: 5, color: '#000', toRight: true, trimNumber: 0, size: 22 },
        description: [{
            name: riskText[0],
            color: colorList[0],
            value: '',
        }, {
            name: riskText[1],
            color: colorList[1],
            value: '',
        }, {
            name: riskText[2],
            color: colorList[2],
            note: '(Livssyklus før nedtrapping)',
            value: '',
        }],
    };

    chartDataList.forEach((cnt: any, j: number) => {
        const last = (cnt ?? []).length - 1;
        const data = (cnt ?? []).map((d: any, i: number) => {
            if (chartConfig.highest < d.value) { chartConfig.highest = d.value; }

            if (j === 0) {
                chartConfig.xAxis.text.push({
                    strokeWidth: i === 0 || i === last ? '1' : '0',
                    strokeDasharray: '2',
                    title: `${d.year || ''}`,
                    text: i === 0 || i === last ? `${d.year}` : ' ',
                });
            }

            if ( i === last ) {
                chartConfig.description[j].value = formatAmount(d.value, true);
            }

            return {value: d.value, text: riskText[j]};
        });
        chartConfig.data.push(data);
    });

    chartConfig.highest = roundUpValue((chartConfig.highest*1.05), (chartConfig.xAxis.grid * 1000));
    chartConfig.description = chartConfig.description.reverse();
    return chartConfig;
};

/** ************************************************************************
 ************************************************************************* */
export const cancelSigning = (props: Props, state: State ): void => {
    clearInterval((state.mode.current.configTimer || 0));

    const orderStorage = getOrderStorage(props);
    props.action.cancelEPKsigning( orderStorage.signingId , () => {
        props.action.getEPKmoveData();
        props.action.getEPKsigningConfig();
        cancel( props );
    });
}

export const signLater = (props: Props, state: State ): void => {
    clearInterval((state.mode.current.configTimer || 0));
    const note = {timesUp: (90*1000), loop: 9000, count: 0};
    note.count = parseInt( `${note.timesUp / note.loop}`, 10);

    state.mode.current.signLater = setInterval( () => {
        props.action.getEPKsigningConfig((signingConfig: SigningConfig) => {
            /* eslint-disable */
            const signingUrl = signingConfig?.signing?.status === Constant.readyForSigning ? 
                (signingConfig?.signing?.signeringsurl || null) : (--note.count ? '' : null);
            /* eslint-enable */

            if ( signingUrl === '' ) { return; }
            clearInterval(state.mode.current.signLater);
            if ( signingUrl ) { props.action.setEPKsigningConfig(signingConfig); }
        }); 
    }, note.loop);
    props.action.setEPKmoveData( null );
    cancel( props );
}

export const callbackKjennDinKunde = ( props: Props, state: State, setState: (s: State) => void, response: any ): void => {
    if ( /^ok$/i.test(response.result) ) {
        submit( props, {...state, aml: 'ok', dialog: ''}, setState );
    } else if ( /^MA_BEHANDLES_MANUELT$/i.test(response.result) ) {
        setState({...state, prevent: true});
    }
};

/** ************************************************************************
 ************************************************************************* */
export const click = ( props: Props, state: State, setState: (s: State) => void, e: MouseEvent | null, key='', data?: any ): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === 'next' ) {
        next( props, state, setState, (typeof (data) === 'number' ? data : undefined) );
    } else if ( key === 'cancel' || key === 'finish' ) {
        cancel( props );
    } else if ( key === 'submit' ) {
        submit( props, state, setState );
    } else if ( key === 'contact-us' ){
        openContactUs();
    } else if ( key === Constant.actionDisplayEPKriskProfileInfo ) {
        setState({...state, dialog: key, riskProfileId: data});
    } else if ( key === Constant.actionDisplayEPKhistoryDevelopmentGraph ) {
        setState({...state, subDialog: key});        
    } else if ( key === 'select-risk-profile' ) {
        save(props, state, setState, {riskProfileId: data}, true );
        next(props, state, setState);
    } else if ( key === 'download-risk-profile-pdf' ) {
        downloadRiskPorfilePDF( state.riskProfileId );
    } else if ( key === 'select-email' ) {
        next( props, state, setState, 100);
    } else if ( key === 'select-bank' ) {
        next( props, state, setState, 101);
    } else if ( key === Constant.actionConfirmMoveEPK ) {
        next( props, state, setState );        
    } else if ( key === 'signing-model-close' ) {
        setState({...state, dialog: ''});
    } else if ( key === 'sign-now' ) {
        const update = {...state, dialog:'signing'};
        setState(update);
        watchSigning(props, update, setState);                
    } else if ( key === Constant.actionCancelSigning ) {
        cancelSigning(props, state);
    } else if ( key === Constant.actionSignLater ) {
        signLater(props, state);        
    } else if ( key === 'update-signing-status' && data ){
        if ( data === Constant.signingCancel ) {
            cancelSigning(props, state);
        } else if ( data === Constant.signingSuccess ) {
            createCookie('isEpkInTransitToEika', '1', 1);
        } 
        clearInterval((state.mode.current.configTimer || 0)); 
        setState({...state, signingMode: data, dialog: ''}); 
    } else if ( key === 'what-is-epk' ) {
        setState({...state, dialog: key});
    }
};

