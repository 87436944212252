/**
 * Created by vagrant on 01.03.17.
 */

class ValidationFunctions {
    static email = email => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    };

    static phoneNumber = number => {
        return /^((?:\+|00)\d{1,3})?\d{8}$/.test(number);
    };

    static alphaNumericValidation = str => {
        return /^[a-zA-Z0-9 ',.\-ÆæØøÅå]*$/.test(str);
    };

    static gyldigKontonummer = kontonummer => {
        if (kontonummer) {
            const k = ValidationFunctions.fjern(kontonummer, '.', '');
            return ValidationFunctions.tekstenErLengde(k, 11);
        }

        return false;
    };

    static fjern = (string, search, replacement) => {
        return string.split(search).join(replacement);
    };

    static verdiErMellom = (verdi, min, max) => {
        return verdi >= min && verdi <= max;
    };

    static tekstErLengreEnn = (tekst, lengde) => {
        return tekst.replace(/ /g, '').length > lengde;
    };

    static tekstErKortereEnn = (tekst, lengde) => {
        return tekst.replace(/ /g, '').length < lengde;
    };

    static tekstenErMellom = (tekst, minLengde, maxLengde) => {
        return (
            ValidationFunctions.tekstErKortereEnn(tekst, maxLengde) &&
            ValidationFunctions.tekstErLengreEnn(tekst, minLengde)
        );
    };

    static tekstenErLengde = (tekst, lengde) => {
        return tekst.replace(/ /g, '').length === lengde;
    };

    static sjekkOmMinstEnAvkryssningBoksErSatt = avkryssningsbokser => {
        for (const index in avkryssningsbokser) {
            if (Object.prototype.hasOwnProperty.call(avkryssningsbokser, index)) {
                if (avkryssningsbokser[index]) {
                    return true;
                }
            }
        }

        return false;
    };

    static validerNavn = navn => {
        return /^[A-å]{2,}( [A-å]{2,})+$/i.test(navn.trim());
    };

    static validerFodselsNummer = fodselsNummer => {
        return /^\d{11}$/.test(fodselsNummer);
    };

    static onlyDigits = string => {
        return /^\d*$/.test(string);
    };

    static sjekkOmAlleAvkryssningBokserErSatt = avkryssningsbokser => {
        for (const index in avkryssningsbokser) {
            if (Object.prototype.hasOwnProperty.call(avkryssningsbokser, index)) {
                if (!avkryssningsbokser[index]) {
                    return false;
                }
            }
        }

        return true;
    };
}

export default ValidationFunctions;
