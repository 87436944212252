import React, { useEffect, useState, useRef, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ButtonToggles } from '@eika/radio-button';
import { Modal, SubModal, ModalSize } from '@eika/modal';
import { Link, LinkType } from '@eika/link';
import { LinkButton } from '@eika/button';
import EditProfile from '../../../common/EditProfile/EditProfile';
import {
    EPKmoveDescription, EPKriskProfileOverview, EPKriskProfileInfo, 
    EPKhistoryDevelopmentGraph, EPKmoveConfirmation, EPKmoveOnSigning,
    EPKmoveInProcess, WhatIsEPK
} from './EPKmoveComponents';
import { Spinner, FooterButton } from '../../../common/share/ShareComponents';
import { getPageAppending } from '../../../common/util/Functions';
import { validateEmail } from '../../../common/util/Validations';
import { usePrevious } from '../../../common/util/Hook';
import { getParam, closeDialog, getOrderStorage, click, next, back, blurEmail, setEmail, changeEmail, changeBank, callbackKjennDinKunde } from './EPKmoveFunctions';
import { setFondOrderStorage } from '../../../common/util/FondFunctions';
import Constant from '../../../static/data/Constant';
import Grid from '../../../common/Grid/Grid';
import SigningIframe from '../../../common/SigningIframe/SigningIframe';
import Message from '../../../common/Message/Message';
import KjennDinKunde from '../../KjennDinKunde/KjennDinKunde';

import { StringObject } from '../../../domain/Types';
import { Props, State, BankRelation } from './EPKmoveDomain';
import './EPKmove.scss';

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Bank', 'Epk', 'KjennDinKunde', 'SpareProfil'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });
    
    const [state, setState] = useState<State>({
        mode: useRef<any>({ipsPin: {}}),
        prevent: false,
        signingMode: '',
        dialog: '',
        subDialog: '',
        riskProfileId: 0,
        portfolioValue: 100000,
        aml: '',
        stepFieldKeyList: {
            '2': ['email']
        },
    });

    const param = getParam( props );
    const orderStorage = getOrderStorage( props, param );
    const appending = getPageAppending( props );
    const previousSpareProfil = usePrevious(reducer.SpareProfil);
    const previousBank = usePrevious(reducer.Bank);
    const previousEpk = usePrevious(reducer.Epk);
    const { dialog, subDialog, force } = state;
    const moveFailed: any = (state.error || {})[Constant.actionEPKmoveFailed];
    const prevent = state.prevent || /MA_BEHANDLES_MANUELT/i.test((reducer.KjennDinKunde || {}).kundevurdering);

    useEffect(() => {
        const ignore = JSON.stringify(reducer.SpareProfil) === JSON.stringify(previousSpareProfil) &&
            JSON.stringify(reducer.Bank) === JSON.stringify(previousBank) &&
            JSON.stringify(reducer.Epk) === JSON.stringify(previousEpk);            
        if ( ignore ) { return; }

        const preSavedData: any = {};
        const { kundeInfo={} } = reducer.SpareProfil || {};
        const { kundeforhold=[] } = reducer.Bank || {};

        if ( !orderStorage.email && validateEmail(kundeInfo.epost) ) {
            preSavedData.email = kundeInfo.epost;
            preSavedData.initedEmail = kundeInfo.epost;
        }

        if ( !orderStorage.mobil && kundeInfo.mobil ) {
            preSavedData.mobil = kundeInfo.mobil;
            preSavedData.initedMobil = kundeInfo.mobil;
        }

        const bank = kundeforhold[0] || {};
        if ( bank.bankregnr && !orderStorage.bankregnr ) {
            preSavedData.bankregnr = bank.bankregnr || '0501';
            preSavedData.banknavn  = bank.banknavn || 'Eika Kapitalforvaltning';
            preSavedData.bankList = kundeforhold.map( (d: StringObject) => {
                return {value: `${d.bankregnr || ''}`, label: `${d.banknavn || d.bankregnr || ''}`};
            }).filter( (d: BankRelation) => !!d.value);
        }

        if ( reducer.Epk.epkData && !orderStorage.epkData ) {
            preSavedData.epkData = (reducer.Epk.epkData.epks || []).concat((reducer.Epk.epkData.pkbs || [])).map( (d: any) =>{
                const sum = (d.activeBalance || 0) + (d.passiveBalance || 0);
                const name = d.name ?? d.navn ?? d.pensionProvider?.name ?? d.pensionProvider?.navn ?? d.pensionProvider ?? '-';
                const type = d.type || '-';
                return { sum, name, type, epkId: d.epkId, pkbId: d.pkbId, reserved: d.reserved || false };                
            });
        }

        if ( JSON.stringify(preSavedData) !== '{}' ) {
            setFondOrderStorage( param.order, { ...getOrderStorage(props, param), ...preSavedData } );
        }
    }, [reducer.Bank, previousBank, reducer.SpareProfil, previousSpareProfil, previousEpk, reducer.Epk, orderStorage, param, props]);

    useEffect(() => {
        return () => {
            clearTimeout( (state.mode.current.doneTimer || 0) );            
        };
    }, [props, state, param.step, orderStorage]);

    useEffect(() => {
        props.action.getEPKsigningConfig();
    }, [props.action, state.mode]);

    useEffect(() => {
        if ( reducer.Epk.epkData !== undefined ) { return; }
        props.action.setEPKmoveData( null );        
        props.action.getEPKmoveData();

        return () => {
            clearInterval( (state.mode.current.configTimer || 0) );
        };
    }, [props.action, reducer.Epk, state.mode]);

    useEffect(() => {
        if ( state.subDialog === Constant.actionDisplayEPKhistoryDevelopmentGraph ) {
            if ( reducer.Epk.historyGraph !== undefined ) { return; }
            props.action.setDevelopmentHistoryGraph( null );
            props.action.getDevelopmentHistoryGraph({portfolioValue: state.portfolioValue});
        }

        if ( !state.signingMode ) {
            if ( reducer.Epk?.signingConfig?.signing?.status === Constant.readyForSigning ) {
                setState({...state, signingMode: 'ready', dialog: 'signing'});
            } else if ( reducer.Epk?.signingConfig?.signing?.status === Constant.finishedSigning && reducer.Epk.epkData ) {
                const hasEPKtoMove = (reducer.Epk.epkData?.epks || []).length || (reducer.Epk.epkData?.pkbs || []).length;
                if ( !hasEPKtoMove ) { setState({...state, signingMode: Constant.signingSuccess}); }
            }
        }

        if ( !state.aml && !!reducer.KjennDinKunde?.kundevurdering) {
            setState({...state, aml: reducer.KjennDinKunde.kundevurdering});
        }

    }, [props.action, state, reducer.Epk, reducer.KjennDinKunde]);

    return (
        <div className={classNames(`epk-move-wrapper -step-${param.step} -${orderStorage.action || 'moving'}`.replace( /\./g, '-'), orderStorage.style, {
            '-force-updated': force, 
            '-on-show-modal': !!dialog,
            '-on-show-sub-modal': !!subDialog,
        })}>

            { !!prevent &&
                <Message 
                    text={props.appTexts.get('kundevurderingMaBehandlesManueltText') } 
                    title={props.appTexts.get('kundevurderingMaBehandlesManueltTitle') } 
                    type="error-hand"
                >
                    <div className="center paragraph -only-top prevent-action-holder">
                        <LinkButton onClick={(e) => { click(props, state, setState, e, 'cancel')}}>Avbryt</LinkButton>
                        <Link href={`${window.location.origin}/nettbank/Hjelp-og-kontakt`} linkType={LinkType.BUTTON_STANDARD_POSITIVE}>Kontakt oss</Link>
                    </div>
                </Message>
            }

            { !!moveFailed && !prevent && <div className="epk-move-failed-wrapper max-width-600 -center paragraph-large -include-top">
                <Message type="ErrorMessage" text={props.appTexts.get('ops')} />
                <FooterButton 
                    click={(e:MouseEvent, k='', d?: any) =>{ click(props, state, setState, e, k, d ); }} 
                    action={moveFailed.action}
                    next={moveFailed.action ? (moveFailed.next || 'Prøv igjen') : ''}
                    cancelAction="cancel" 
                    cancel="Avbryt" 
                />
            </div> }

            { param.step !== 3 && !prevent && !state.signingMode && !moveFailed && orderStorage.receipt && <div className={`step-wapper ${appending}`}>
                <EPKmoveInProcess click={(e: MouseEvent, k='') =>{ click(props, state, setState, e, k);}}/>
            </div> }

            { param.step === 0 && !prevent && !state.signingMode && !moveFailed && !orderStorage.receipt && reducer.Epk.epkData && <div id="epk-move-step-0" className={`step-wapper ${appending}`}>
                <EPKmoveDescription {...props} click={(e:MouseEvent, k='') =>{ click(props, state, setState, e, k); }} >
                    <FooterButton 
                        fixForMobile 
                        click={() =>{ next(props, state, setState ); }} 
                        action="move-pension-to-eika"
                        next={props.appTexts.get('movePensionToEika')}
                    />
                </EPKmoveDescription>
            </div> }

            { param.step === 1 && !prevent && !state.signingMode && !moveFailed && !orderStorage.receipt && reducer.Epk.epkData && <div id="epk-move-step-1" className={`step-wapper ${appending}`}>
                <EPKriskProfileOverview {...props} click={(e:MouseEvent, k='', d?: any) =>{ click(props, state, setState, e, k, d ); }}  />
            </div>}

            { param.step === 2 && !prevent && !state.signingMode && !moveFailed && !orderStorage.receipt && reducer.Epk.epkData && <div id="epk-move-step-2" className={`step-wapper ${appending}`}>
                <EPKmoveConfirmation 
                    {...props} 
                    {...state}
                    orderStorage={orderStorage}
                    setEmail={(e) =>{ setEmail(props, state, setState, e); }} 
                    blurEmail={() =>{ blurEmail(props, state, setState); }}
                    click={(e:MouseEvent, k='', d?: any) =>{ click(props, state, setState, e, k, d ); }}  
                />
            </div>}

            { (param.step === 3 || state.signingMode) && !prevent && !moveFailed && <div id="epk-move-step-3" className={`step-wapper ${appending}`}>
                <EPKmoveOnSigning 
                    {...props}
                    {...state} 
                    orderStorage={orderStorage}
                    click={(e:MouseEvent, k='') =>{ click(props, state, setState, e, k ); }} 
                />
            </div>}            

            { param.step === 100 && !prevent && !moveFailed && !orderStorage.receipt && <div className={`step-wapper ${appending}`}>
                <Grid namespace="sparing-header" list={[{
                    layout: 'twelve',
                    element: <h1>Legg til epost</h1>
                }]}/>
                <Grid namespace="sparing-content" list={[{
                    layout: 'six',
                    element: <>
                        <EditProfile title="" 
                            kundeInfo={{epost: orderStorage.email}} 
                            display={{email: 1}} 
                            submit={(e)=> { changeEmail(props, state, setState, e); }} 
                            close={()=>{ back(1); }} 
                            submitButton="Bruk"
                            fixButton={false}
                        /> 
                    </>
                }]}/>
            </div>}

            { param.step === 101 && !prevent && !moveFailed && !orderStorage.receipt && <div className={`step-wapper ${appending}`}>
                <Grid namespace="sparing-header" list={[{
                    layout: 'twelve',
                    element: <h1>Velg bank</h1>
                }]}/>
                <Grid namespace="sparing-content" list={[{
                    layout: 'six',
                    element: <div className="select-bank-wrapper">
                        <ButtonToggles
                            label="Hvilken bank skal Egen Pensjonskonto knyttes mot?"
                            // name="radio-bankregnr"
                            onChange={(e)=>{ changeBank(props, state, setState, orderStorage.bankList.find( (d: {value: string}) => d.value === e)); }}
                            selectedOption={orderStorage.bankregnr}
                            options={orderStorage.bankList}
                            required
                        />     
                    </div>
                }]}/>
            </div>}

            <Modal modalSize={ModalSize.LARGE} id="epk-move-modal" appNamespace="sparing-react-app" onClose={() => { closeDialog(props, state, setState); }} show={!!dialog}>
                { dialog === Constant.actionDisplayEPKriskProfileInfo && !prevent && <div className="risk-profile-info-wrapper">
                    <EPKriskProfileInfo {...props} {...state} click={(e:MouseEvent, k='', d?: any) =>{ click(props, state, setState, e, k, d ); }} />
                </div> }

                { dialog === 'signing' && !prevent && <div className="epk-signing-wrapper">
                    { orderStorage.signingUrl ? 
                        <SigningIframe 
                            url={orderStorage.signingUrl} 
                            callback={(status: string)=> { click(props, state, setState, null, 'update-signing-status', status);}} 
                        /> : <>
                            <Message type="ErrorMessage" text={props.appTexts.get('ops')} />
                            <FooterButton click={(e:MouseEvent, k='', d?: any) =>{ click(props, state, setState, e, k, d ); }} cancelAction="signing-model-close" cancel="Avbryt" />
                        </>
                    }
                </div> }

                { dialog === 'aml' && <div className="epk-move-aml-wrapper">
                    <KjennDinKunde {...props} callback={(e?: any) =>{ callbackKjennDinKunde(props, state, setState, e);}} />
                </div>}

                { dialog === 'what-is-epk' && <div className="what-is-epk-wrapper">
                    <WhatIsEPK {...props}/>
                </div>}

                <SubModal modalSize={ModalSize.LARGE} id="epk-move-submobal" appNamespace="sparing-react-app" onClose={() => { setState({...state, subDialog: ''}); }} show={!!subDialog}>
                    {subDialog === Constant.actionDisplayEPKhistoryDevelopmentGraph && !prevent && <div className="development-history-graph-wrapper">
                        { reducer.Epk.historyGraph === undefined ? <Spinner type="linkPanel" /> : 
                            <EPKhistoryDevelopmentGraph {...props} {...state} historyGraph={reducer.Epk.historyGraph}/>
                        }
                    </div>}
                </SubModal>
            </Modal>
        </div>
    );
};

