/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';

import FondsUtlisting from '../../components/fond/fonds-utlisting';
import EksternFondsUtlisting from '../../components/fond/ekstern-fonds-utlisting';
import DonutChart from '../../components/charts/donut-chart';
import Legend from '../../components/legends/dot-legend';
import HelperFunctions from '../../components/helper-functions';
import ColorFunctions from '../../components/color-functions';
import { settSideTittel } from '../../actions/header';
import { lukkKvittering, settKvitteringsInnhold } from '../../actions/modals';
import { hentAarligSparebelop, hentSkattemessigKostpris } from '../../actions/fond';
import ActionButton from '../../components/action-button';
import Format from '../../components/format-functions';
import AppMessage from '../../components/AppMessage';
import KlientKontoKort from '../../components/fond/klient-konto-kort';
import OrdreKvittering from '../../components/fond/ordre-kvittering';
import SpartHittilIAar from '../../components/pensjon/spart-hittil-iaar';
import * as PortefoljeTypes from '../../domain/portefolje-types';
import Card from '../../components/card';
import SkattemessigKostpris from '../../components/fond/skattemessig-kostpris';
import KundevurderingStopp from '../../components/KundevurderingStopp';

class FondsOversikt extends Component {
    static propTypes = {
        portefoljeData: PropTypes.object,
        portefolje: PropTypes.object,
        chartData: PropTypes.array,
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        kvitteringConfig: PropTypes.object,
        kvitteringsInnhold: PropTypes.object,
        lukkKvittering: PropTypes.func,
        hentAarligSparebelop: PropTypes.func,
        hentSkattemessigKostpris: PropTypes.func,
        skattemessigKostpris: PropTypes.object,
        isDisponertKonto: PropTypes.bool,
    };

    UNSAFE_componentWillMount = () =>
        this.props.settSideTittel(
            this.props.portefolje.navn,
            this.props.portefoljeData && this.props.portefoljeData.portefoljeTilknyttetAnnenBank
                ? this.props.portefolje.bank.banknavn
                : undefined
        );

    settSideTittel = (portefoljeNavn, visBanknavn, banknavn) =>
        this.props.settSideTittel(portefoljeNavn, visBanknavn ? banknavn : undefined);

    sjekkForServerFeil = (errors) => errors.LAST_PORTEFOLJE;

    visKlientKonto = (index, portefolje) =>
        portefolje.type === PortefoljeTypes.ASK ? (
            <KlientKontoKort
                kontantBeholdning={portefolje.kontantBeholdning}
                texts={this.props.texts}
                portefoljeId={portefolje.id}
                gradient={ColorFunctions.hentGradient(index)}
                linkDisabled={!!this.props.isDisponertKonto}
            />
        ) : null;

    visSpartHittilIAar = (portefolje) =>
        portefolje.type === PortefoljeTypes.IPS && this.props.portefoljeData ? (
            <div>
                <SpartHittilIAar
                    texts={this.props.texts}
                    belop={this.props.portefoljeData.sparebelop[portefolje.portfolioBaseCode]}
                    hentAarligSparebelop={this.props.hentAarligSparebelop}
                    portfolioBaseCode={portefolje.portfolioBaseCode}
                />
                <div className="ips-sparing-tittel">{this.props.texts.get('dinIpsSparing')}</div>
            </div>
        ) : null;

    visASKMetadata = (portefolje) =>
        portefolje.type === PortefoljeTypes.ASK ? (
            <Card transparent>
                <div>
                    <div>
                        <span className="tekst">{this.props.texts.get('verdistigning')}</span>
                        <span className="verdi"> {Format.currency(portefolje.verdistigning)}</span>
                    </div>
                </div>
            </Card>
        ) : null;

    visKjopFondKnapp = () => (
        <ActionButton
            action={this.props.portefolje.type === PortefoljeTypes.IPS ? 'individuellpensjonssparing' : 'kjopFond'}
            text={this.props.texts.get('kjopFond')}
        />
    );

    visAskInfoBoks = () => {
        const bnr =
            this.props.portefolje.bank.bankregnr.value != null
                ? this.props.portefolje.bank.bankregnr.value
                : this.props.portefolje.bank.bankregnr;

        return this.props.portefolje.type === PortefoljeTypes.ASK ? (
            <SkattemessigKostpris
                feilTekst={this.props.texts.get('klientKontoDuKanTaUtInntilFeilet')}
                hentSkattemessigKostpris={this.props.hentSkattemessigKostpris}
                infoTekst={this.props.texts.get('naarDuSelgerFondIAksjesparekontoen')}
                portfolioBaseCode={this.props.portefolje.portfolioBaseCode}
                skattemessigKostpris={this.props.skattemessigKostpris}
                bankregnr={bnr}
            />
        ) : null;
    };

    render() {
        const {
            portefolje,
            isDisponertKonto,
            texts,
            kvitteringConfig,
            lukkKvittering,
            kvitteringsInnhold,
            chartData,
        } = this.props;

        if (portefolje) {
            const fond = portefolje.fond || [];
            const eksterneFond = portefolje.eksterneFond || [];
            const totalVerdiFond = typeof(portefolje.totalVerdi) === 'number' && typeof(portefolje.kontantBeholdning) === 'number' ?
                (portefolje.totalVerdi - portefolje.kontantBeholdning) : null;
            const beregnetAvkastning = HelperFunctions.beregnAvkastning(totalVerdiFond, portefolje.innskudd);

            return (
                <div className="portfolio-wrapper">
                    <AppMessage appMessage={this.props.serviceMessage} context="PORTFOLIO" />

                    <OrdreKvittering
                        texts={texts}
                        kvitteringConfig={kvitteringConfig}
                        lukkKvittering={lukkKvittering}
                        kvitteringsInnhold={kvitteringsInnhold}
                    />

                    { !!portefolje.sperre &&
                        <KundevurderingStopp texts={texts} title={null} text={texts.get(`${portefolje.sperre.toLowerCase()}SperretKonto`)} type="tag" />
                    }

                    <DonutChart data={chartData}>
                        <div className="donut-labels">
                            <div className="label-section">
                                <div className="text">{texts.get('totalVerdi')}</div>
                                <div className="value">{Format.currency(portefolje.totalVerdi)}</div>
                            </div>
                        </div>
                        <div className="donut-labels">
                            <div className="label-section">
                                <div className="text">Avkastning</div>
                                <div className="value">{Format.percent(typeof(beregnetAvkastning) === 'number' ? (beregnetAvkastning * 100) : null)}</div>
                            </div>
                        </div>
                    </DonutChart>
                    
                    <Legend data={chartData} />
                    {this.visSpartHittilIAar(portefolje)}
                    {this.visASKMetadata(portefolje)}

                    <FondsUtlisting
                        startIndex={0}
                        portefoljeId={portefolje.id}
                        portefoljeType={portefolje.type}
                        fonds={fond}
                        texts={texts}
                        isDisponert={isDisponertKonto}
                    />
                    <EksternFondsUtlisting startIndex={fond.length} verdipapirer={eksterneFond} texts={texts} />
                    {this.visKlientKonto(fond.length + eksterneFond.length, portefolje)}
                    {this.visAskInfoBoks()}
                    {!isDisponertKonto && portefolje?.sperre !== 'FULL' && portefolje?.type !== 'VPS' && this.visKjopFondKnapp()}
                </div>
            );
        }

        return <Loading title={texts.get('lasterFondsApp')} />;
    }
}

export const mapKontoOgPortefoljeToFondsVisningsObject = (portefolje, kontoer, texts) => {
    if (portefolje === null) {
        return [];
    }
    if (portefolje.type === PortefoljeTypes.VPS) {
        return portefolje.eksterneFond.map((fond, index) => {
            return {
                text: fond.navn,
                value: fond.totalVerdi,
                color: ColorFunctions.hentFarge(index),
                active: true,
            };
        });
    }
    if (portefolje.type === PortefoljeTypes.ASK) {
        const klientKonto = kontoer
            .filter((konto) => konto.portefoljeId === portefolje.id)
            .map((konto, index) => ({
                text: texts.get('tilknyttetBankkonto'),
                value: konto.verdi,
                color: ColorFunctions.hentFarge(index + portefolje.fond.length),
                active: true,
            }));
        const fonds = portefolje.fond.map((fond, index) => ({
            text: fond.navn,
            value: fond.totalVerdi,
            color: ColorFunctions.hentFarge(index),
            active: true,
        }));
        return fonds.concat(klientKonto);
    }
    return [
        {
            text: texts.get('innskudd'),
            value: portefolje.innskudd,
            color: ColorFunctions.hentFarge(0),
            active: true,
        },
        {
            text: texts.get('verdistigning'),
            value: portefolje.verdistigning,
            color: ColorFunctions.hentFarge(1),
            active: true,
        },
    ];
};

export default connect(
    (state, ownProps) => {
        const kontoer = (state.Fond.portefoljeData || {}).kontoer || [];
        const chartData = mapKontoOgPortefoljeToFondsVisningsObject(ownProps.portefolje, kontoer, state.Texts.texts);

        return {
            ...ownProps,
            ...state.Texts,
            ...state.Fond,
            ...state.Feilmeldinger,
            ...state.Modals,
            ...state.Header,
            chartData,
        };
    },
    (dispatch) => {
        return {
            lukkKvittering: () => {
                dispatch(lukkKvittering());
            },
            settKvitteringsInnhold: (innhold) => {
                dispatch(settKvitteringsInnhold(innhold));
            },
            settSideTittel: (sideTittel, undertittel) => {
                dispatch(settSideTittel(sideTittel, undertittel));
            },
            hentAarligSparebelop: (portfolioBaseCode) => {
                dispatch(hentAarligSparebelop(portfolioBaseCode));
            },
            hentSkattemessigKostpris: (portfolioBaseCode, bankregnr) => {
                dispatch(hentSkattemessigKostpris(portfolioBaseCode, bankregnr));
            },
        };
    }
)(FondsOversikt);
