/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ValidationMessages from './validation-messages';
import TextInput from './text-input';
import Validate from '../validation-functions';

class Epost extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        show: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        showValidationMessage: PropTypes.bool.isRequired,
        validationMessage: PropTypes.string.isRequired,
        epost: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            epost: props.epost || ''
        };
    }

    settEpost = epost => {
        this.setState({ epost });

        if (Validate.email(epost)) {
            this.props.onChange({ epost });
        } else {
            this.props.onChange({ epost: '' });
        }
    };

    render() {
        if (this.props.show) {
            return (
                <div className="fond-skjema-seksjon">
                    <TextInput
                        id="epost"
                        label={this.props.label}
                        value={this.state.epost}
                        onChange={event => this.settEpost(event.target.value)}
                    />
                    <ValidationMessages
                        showValidationMessages={this.props.showValidationMessage}
                        validationMessages={[
                            {
                                condition: !Validate.email(this.state.epost),
                                message: this.props.validationMessage
                            }
                        ]}
                    />
                </div>
            );
        }

        return <div />;
    }
}

export default Epost;
