import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../checkbox';
import IndividuellPensjonsparingVilkaar from '../vilkaar/individuell-pensjonssparings-vilkaar-dokument';
import ExpandableCard from '../expandable-card';
import VelgKundeForhold from '../bank/velg-kundeforhold';
import ValidationMessages from '../common/validation-messages';

class OpprettIndividuellPensjonsSparing extends Component {
    static propTypes = {
        texts: PropTypes.object,
        opprettIPS: PropTypes.func,
        kundeforhold: PropTypes.array
    };

    constructor(props) {
        super(props);

        this.state = {
            expandable: {
                id: 0,
                expanded: false,
                onExpand: this.onExpand
            },
            visHjelpeTekst: false
        };
    }

    onExpand = () =>
        this.setState({
            expandable: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.expandable,
                // eslint-disable-next-line react/no-access-state-in-setstate
                expanded: !this.state.expandable.expanded
            }
        });

    godkjennVilkaar = checked =>
        this.setState({
            godkjennVilkaarForIps: checked
        });

    velgKundeforhold = bankregnr =>
        this.setState({
            bankregnr
        });

    hentBankregNr = () =>
        this.props.kundeforhold.length === 1 ? this.props.kundeforhold[0].bankregnr : this.state.bankregnr;

    opprettIPS = () => {
        const bankregnr = this.hentBankregNr();
        if (this.state.godkjennVilkaarForIps && bankregnr) {
            this.props.opprettIPS({ value: bankregnr });
        } else {
            this.setState({
                visHjelpeTekst: true
            });
        }
    };

    visOpprettIPSkonto = () => (
        <button type="button" className="btn btn-call-to-action" onClick={this.opprettIPS}>
            {this.props.texts.get('opprettIPSKonto')}
        </button>
    );

    visVelgKundeforhold = kundeforhold => {
        if (kundeforhold.length > 1) {
            return (
                <VelgKundeForhold
                    kundeforhold={kundeforhold}
                    label={this.props.texts.get('velgBank')}
                    defaultValue={this.props.texts.get('ikkeValgt')}
                    onChange={this.velgKundeforhold}
                    showValidationMessages={this.state.visHjelpeTekst}
                    validationMessages={[
                        {
                            condition: !this.state.bankregnr,
                            message: this.props.texts.get('velgKundeforhold')
                        }
                    ]}
                />
            );
        }

        return null;
    };

    render = () => (
        <div className="opprett-ips">
            <div className="opprett-ips-tittel">{this.props.texts.get('opprettIndividuellPensjonsSparing')}</div>
            <div className="opprett-ips-tekst">
                <div>{this.props.texts.get('opprettIndividuellPensjonsSparingTekst')}</div>
                <ul>
                    <li>{this.props.texts.get('ipsPunktEn')}</li>
                    <li>{this.props.texts.get('ipsPunktTo')}</li>
                    <li>{this.props.texts.get('ipsPunktTre')}</li>
                    <li>{this.props.texts.get('ipsPunktFire')}</li>
                    <li>{this.props.texts.get('ipsPunktFem')}</li>
                    <li>{this.props.texts.get('ipsPunktSeks')}</li>
                    <li>{this.props.texts.get('ipsPunktSyv')}</li>
                    <li>{this.props.texts.get('ipsPunktAatte')}</li>
                </ul>

                <ExpandableCard
                    header={`${this.props.texts.get('avtaleOmIndividuellSparingTilPensjon')}`}
                    expandItem={this.state.expandable}
                >
                    <IndividuellPensjonsparingVilkaar />
                </ExpandableCard>

                <Checkbox id="godkjennVilkaarForIps" name="godkjennVilkaarForIps" onChange={this.godkjennVilkaar}>
                    <div>{this.props.texts.get('godkjennVilkaarForIps')}</div>
                </Checkbox>

                <ValidationMessages
                    showValidationMessages={this.state.visHjelpeTekst}
                    validationMessages={[
                        {
                            condition: !this.state.godkjennVilkaarForIps,
                            message: this.props.texts.get('duMaaGodkjenneIpsVilkaar')
                        }
                    ]}
                />
            </div>

            {this.visVelgKundeforhold(this.props.kundeforhold)}
            {this.visOpprettIPSkonto()}
        </div>
    );
}

export default OpprettIndividuellPensjonsSparing;
