import Constant from '../../../static/data/Constant';
import { formatAmount, createRegexp, getColor, getAvkastning, capitalize } from '../../../common/util/Functions';
import { getKontiTypeName } from '../../../common/util/FondFunctions';

/** ****************************************************************************
 ***************************************************************************** */
export const getLabelList = (data: any,  appTexts: any): any => {
    const list = [];

    if ( (data.ordreList || []).length ) {
        list.push({id: `lable-${data.id}-order`, name: 'Ordre på vei', type: 'pending'});        
    }

    if ( data.isIPS ) {
        list.push({id: `lable-${data.id}-${list.length}`, name: 'Pensjon', type: 'pension'}); 
    }

    if ( data.eier ) {
        const eier = (data.eier || '').split( /\s+/g);
        list.push({id: `lable-${data.id}-${list.length}`, name: capitalize(eier[0] || ''), ariaLabel: `Kontoeier ${data.eier}`});
    }

    if ( data.sperre ) {
        list.push({id: `lable-${data.id}-${list.length}`, name: appTexts.get(`${data.sperre.toLowerCase()}SperretKonto`), type: 'blocked'}); 
    }

    return list.filter( (d) => !!d.name);
};

/** ****************************************************************************
 ***************************************************************************** */
export const getAccountTextList = (data: any, index=0): any => {
    const colorConfig = getColor(index);

    // const list = [[data.navn, formatAmount((Math.round(data.totalVerdi)))]];
    // const avkastning = getAvkastning( data.totalVerdi, data.totaltInnskudd, true );
    const totalInnskudd = (data.fond || []).reduce( (p: number, d: any) =>{
        return p + ((d || {}).innskudd || 0);
    }, 0);
    const avkastning = getAvkastning( data.totalVerdi, totalInnskudd, true );
    const verdistigning = Math.abs((data.verdistigning || 0))
    const list: any = [[{
        // eslint-disable-next-line
        type: `value-swing -${data.verdistigning ? (data.verdistigning < 0 ? 'negative' : 'positive') : 'none'}`,
        text: `${formatAmount((Math.round(verdistigning)))}`,
        ariaLabel: `${data.verdistigning < 0 ? 'Verdistigning' : 'Verdinedgang'} ${data.verdistigning}`,
        title: `${data.verdistigning < 0 ? 'Verdistigning' : 'Verdinedgang'} ${data.verdistigning}`,
    }, {
        type: 'value-return',
        text: avkastning,
        ariaLabel: `Avkastning ${avkastning}`,
        title: `Avkastning ${avkastning}`,
    }, {
       text: `${formatAmount((Math.round(data.totalVerdi || 0)))}`,
       ariaLabel: `TotalVerdi ${data.totalVerdi || 0}`,
       title: `TotalVerdi ${data.totalVerdi || 0}`,
    }]];

    list.push({type: 'name', text: data.navn, ariaLabel: `Kontonavn ${data.navn}`});

    const type = getKontiTypeName(data.type) || '';
    const reg = type ? createRegexp( type, 1, 1 ) : null;
    const typeIsInName = reg ? reg.test(data.navn) : false;

    const accountNote = [type, data.portfolioBaseCode].filter((v) => !!v); 
    list.push({text: typeIsInName ? accountNote[1] : accountNote.join(' - '), ariaLabel: `Kontotype ${accountNote.join(', ')}`, type: 'small-size'});
    list.push({type: 'chart-legend-icon', style: {color: colorConfig.color}});
    return list;
};

export const getFundTextList = (data: any, index=0, account?: any): any => {
    const colorConfig = getColor(index);
    const gevinst = data.urealisertGevinst || data.verdistigning;
    const avkastning = getAvkastning( data.totalVerdi, data.innskudd );
    const list: any = [[{
        // eslint-disable-next-line
        type: `value-swing -${gevinst ? (gevinst < 0 ? 'negative' : 'positive') : 'none'}`,
        // @ts-ignore
        text: `${formatAmount((Math.round(Math.abs(avkastning || 0))))} %`,
        ariaLabel: `${gevinst < 0 ? 'Verdistigning' : 'Verdinedgang'} ${avkastning} %`,
        title: `${gevinst < 0 ? 'Verdistigning' : 'Verdinedgang'} ${avkastning} %`,
    /*
    }, {
        type: 'value-return',
        text: avkastning,
        ariaLabel: `Avkastning ${avkastning}`,
        title: `Avkastning ${avkastning}`,
    */
    }, {
       text: `${formatAmount((Math.round(data.totalVerdi || 0)))}`,
       ariaLabel: `TotalVerdi ${data.totalVerdi}`,
       title: `TotalVerdi ${data.totalVerdi}`,
    }]];

    list.push({type: 'name', text: data.navn, ariaLabel: `Fond ${data.navn}`});
    if ( account && account.navn ) { list.push({text: account.navn,  ariaLabel: `Plassering ${account.navn}`, type: 'small-size'}); }
    list.push({type: 'chart-legend-icon', style: {color: colorConfig.color}});
    return list;
};

export const getCurrentAccount = ( props: any, reducer: any ): any => {
    const { match={} } = props || {};
    // if ( (reducer.Ordre || {}).pending || !(reducer.Ordre || {}).alleVentendeTransaksjoner ) { return; }

    const find = ( list: any ): any => {
        return (list || []).find( (d: any) => d.id && (match.params || {}).accountId && `${d.id}` ===  `${(match.params || {}).accountId}` );
    };

    const { portefoljeData, disponertePortefoljer } = reducer.Fond || {};
    let account = find( portefoljeData?.portefoljer );
    if ( account ) { return account; }

    account = find( disponertePortefoljer );
    if ( account ) { return {...account, disponert: true }; }

    return null;
};

export const getErrorToPerformSettInnPenger = (src: any, type=Constant.typeManedligSparing): string => {
    if ( /ips/i.test( src?.type ) ) {
        if ( src.sparing && src.sparing.maksbelop && src.sparing.aarligSparebelop && src.sparing.maksbelop >= src.sparing.aarligSparebelop ) {
            return `ips-max-saving?maksbelop=${src.sparing.maksbelop}`;
        } 

        const hasAgreement = (src.spareavtaler || []).length > 0 || 
            (src.fond || []).find( (f: any) => (f.spareavtaler || []).length > 0 );
        if ( type === Constant.typeManedligSparing && hasAgreement ) {
            return 'ips-only-one-saving-agreement';
        } 
    }

    return '';
};

export const getErrorToPerformSalgByttUttak = (src: any, clientAccount=false): string => {
    if ( 
        (!clientAccount && !src?.totalVerdi && !src?.verdi) ||
        (clientAccount && !src?.kontantBeholdning)
    ) { return 'no-value'; }

    if ( (src?.ordreList || []).find( (d: any) => /(bytt|salg)/i.test(d.type) )) { return 'in-order'; }
    return '';
};

export const getFondOrdrePendingMessage = (src: any): any => { 
    const count = src?.count || 0;
    const error = src?.error || 0;
    if ( !count ) { return; }

    const out: any = { 
        url: `${window.location.href.replace(/\/+$/g,'')}/ordre`,
        type: 'pending',
        text: `Du har ${count} ${count > 1 ? 'ordrer' : 'ordre'} under behandling`,
        count,
        error,
    };

    if ( error === count ) {
        out.text = `Noe gikk galt på ordren${count > 1 ? 'e': ''}.`;
        out.type += ' -error';
    } else if ( error ) {
        out.text = [out.text, `Noe gikk galt på en eller flere av ordrene dine.`];
        out.type += ' -error';
    }

    return out;
};

