import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LinkList } from '@eika/link-list';
import { usePrevious } from '../../common/util/Hook';
import Card from '../../common/Card/Card';
import { Spinner } from '../../common/share/ShareComponents';
import { formatAmount, getPageAppending } from '../../common/util/Functions';
import { AppTexts } from '../../domain/Types';
import './Profil.scss';

interface Props {
    appTexts: AppTexts;
};

interface State {
    content: any;
    appending: string;
    reducer: any;
};

const getContent = ( props: Props, reducer: any ): any => {
    const { kundeInfo, pensjonsvalg } = reducer.SpareProfil || {};
    if ( !kundeInfo || !pensjonsvalg ) { return; }
    return {
        info: [
            { id: 'name', text: [['Navn', ['fornavn', 'etternavn'].map( (k: string) => kundeInfo[k] || '').join(' ')]] },
            { id: 'address', text: [['Adresse', ['adresselinje1', 'adresselinje2'].map( (k: string) => kundeInfo[k] || '').filter( (v) => !!v ).join(', ')]] },
            { id: 'city', text: [['Sted', ['postnr', 'poststed'].map( (k: string) => kundeInfo[k] || '').join(' ')]] },
            { id: 'mobile', text: [['Mobil', `${kundeInfo.mobil || ''}`]] },
            { id: 'email', text: [['E-post', `${kundeInfo.epost || ''}`]] },
        ],
        sparing: [
            { id: 'income', text: [['Brutto inntekt', `${formatAmount(pensjonsvalg.bruttoInntekt || 0)} kr`]] },
            { id: 'pension-age', text: [['Pensjonsalder', `${pensjonsvalg.pensjonsAlder || 67} år`]] }, 
            { id: 'ips-outlet', text: [['IPS uttaksalder', `${pensjonsvalg.ipsUttaksAlder || 67} år`]] },                          
        ],
        about: [
            { id: 'vilkaar', text: 'Vilkår', url: `${window.location.href.replace(/\/+$/g,'')}/vilkaar`},
            { id: 'ipsavtale', text: 'IPS avtale', url: `${window.location.href.replace(/\/+$/g,'')}/ipsavtale`},
        ]
    };
}

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['SpareProfil'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const [state, setState] = useState<State>({
        content: getContent( props, reducer ),
        appending: getPageAppending( props ),
        reducer,
    }); 

    const previousReducer = usePrevious(reducer);
    useEffect(() => {
        if ( JSON.stringify(reducer) === JSON.stringify(previousReducer) ) { return; }
        setState({...state, reducer, content: getContent( props, reducer )});
    }, [previousReducer, reducer, state, props]);

    return (
        <div className="profil-wrapper">
            <section aria-label={props.appTexts.get('profile')} className="sgw-content">
                <div className="content-max-width">
                    <div className={`Profil-content ${state.appending}`}>
                        { state.content ? <>
                                { (state.content.info || []).length > 0 && <article aria-labelledby="kunde-data-title" className="kunde-data-wrapper">
                                    <h2 id="kunde-data-title">Kunde</h2>
                                    { state.content.info.map( (data: any) => <Card key={data.id} data={{text: data.text}} type="list-item" />) }
                                </article>}

                                { (state.content.sparing || []).length > 0 && <article aria-labelledby="kunde-sparing-title" className="kunde-sparing-wrapper">
                                    <h2 id="kunde-sparing-title">Sparing</h2>
                                    { state.content.sparing.map( (data: any) => <Card key={data.id} data={{text: data.text}} type="list-item" />) }
                                </article>}

                                { (state.content.about || []).length > 0 && <article aria-labelledby="kunde-info-title" className="kunde-info-wrapper">
                                    <h2 className="aria-visible" id="kunde-info-title">Informasjon</h2>
                                    <LinkList>
                                        { state.content.about.map( (data: any) => <LinkList.Item key={data.id} href={data.url} >{data.text}</LinkList.Item>) }
                                    </LinkList>
                                </article>}

                            </> : <Spinner type="profile"/>
                        }
                    </div>
                </div>
            </section>
        </div>
    );
};
