// @ts-nocheck // kiet fix it later
import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { generateId } from '../Functions';
import { initState, mousedown, mousemove, mouseup, linePointClearTimer, renewGraph, bindGraphEvent } from './util/ChartInitialization';
import { usePrevious } from '../Hook';

import './Chart.scss';

// eslint-disable-next-line
const ChartTSpan = ({ tspan }) => {
    return (<React.Fragment>
        { (tspan instanceof Array ? tspan : [tspan]).map((data, i) => {
            return !data || !data.text ? null : <tspan
                key={`tspan-${data.id || i}`}
                dy={data.dy !== undefined ? data.dy : '1.4em'}
                x={data.x !== undefined ? data.x : '0'}
                dominantBaseline={data.dominantBaseline || 'middle'} textAnchor={data.textAnchor || 'middle'}
                {...data.style}
            >{data.text}</tspan>;
        }) }
    </React.Fragment>);
};

// eslint-disable-next-line
const ChartGraph = ({ data, animate, symbol }) => {
    let graph = null;

    if (!data) { return graph; }

    const invalid = ['x', 'y', 'cx', 'cy'].find(key => typeof (data[key]) !== 'undefined' && isNaN(data[key]));
    if (invalid) { return graph; }

    if (data.type === 'line-polygon') {
        graph = (<polygon points={data.points} {...data.style} >
            <animate attributeName={(data.animate || {}).attributeName || 'opacity'} 
                from={(data.animate || {}).from || 0}
                to={(data.animate || {}).to || data.animateTo || 1}
                dur={typeof (data.duration) === 'number' ? (`${data.duration / 1000}s`) : (data.duration || '.6s')}
                fill="freeze" 
            />
        </polygon>);
    } else if (data.type === 'rect') {
        graph = <rect x={data.x} y={data.y} rx={data.rx || 0} width={data.width} height={data.height} {...data.style}>
            { (animate !== false ? (data.animationList || []) : []).map( (animation, i) =>(
                <animate
                    key={`rect-animation-${i}`}
                    fill="freeze"
                    begin={typeof (data.delay) === 'number' ? (`${data.delay / 1000}s`) : (data.delay || '0s')}
                    dur={typeof (data.duration) === 'number' ? (`${data.duration / 1000}s`) : (data.duration || '.6s')}
                    {...animation}
                />
            )) }
        </rect>;
    } else if (
        data.type === 'pie' ||
        data.type === 'progress' ||
        data.type === 'bar-path' ||
        data.type === 'path' ||
        (symbol && data.symbol && data.path)
    ) {
        graph = (
            <path id={data.id} {...data.style} d={data.animateAttributeName !== 'd' ? data.path : ''} className={data.className || ''}>
                { animate !== false && (data.animateFrom || data.animateTo) && <animate
                    fill="freeze"
                    attributeName={data.animateAttributeName || 'stroke-dashoffset'}
                    begin={typeof (data.delay) === 'number' ? (`${data.delay / 1000}s`) : (data.delay || '0s')}
                    dur={typeof (data.duration) === 'number' ? (`${data.duration / 1000}s`) : (data.duration || '.6s')}
                    from={data.animateFrom !== undefined ? data.animateFrom : data.dash}
                    to={data.animateTo !== undefined ? data.animateTo : (data.dash * 2)}
                />}
            </path>
        );
    } else if (data.type === 'text') {
        graph = (<g>
            <text
                id={data.id} x={data.x} y={data.y} {...data.style}
                dominantBaseline={data.dominantBaseline || 'middle'} textAnchor={data.textAnchor || 'middle'}
            >{data.tspan ? <ChartTSpan tspan={data.tspan} /> : data.text}</text>
            { animate !== false && data.animate && <animate
                attributeName={data.animation.attributeName || 'fill-opacity'}
                attributeType="CSS" from="0" to="1" dur={data.duration} fill="freeze"
            />
            }
        </g>);
    }

    return graph;
};

// eslint-disable-next-line
const Blackboard = ({ data }) => {
    return data && data.content ?  (
        <div className="chart-blackboard opacity-reveal" style={data.style || {}}>
            { data.content }
        </div>
    ) : null;
};

// eslint-disable-next-line
const LinePoint = ({ data={}, clearTimer=()=>{}, click=()=>{}}) => {
    const { style = {}, color = '#333' } = data;
    return (
        <div
            className="chart-line-point opacity-reveal"
            style={style}
            onMouseOver={clearTimer}
            onClick={(e) => { click(e, 'click-line-point', data); }}
        ><span className="inner" style={{ borderColor: color }} onMouseOver={clearTimer} /></div>
    );
};

// eslint-disable-next-line
const PointText = ({ data={}, clearTimer=()=>{}, click=()=>{}}) => {
    const { style = {}, text, note, down } = data;
    return (
        <div
            className={classnames('chart-point-text opacity-reveal', {'-down': !! down})}
            style={style}
            onMouseOver={clearTimer}
            onClick={(e) => { click(e, 'click-line-point', data); }}
        > 
            <div className="line" style={{backgroundColor: style.backgroundColor}}/>
            <div className="inner" style={{backgroundColor: style.backgroundColor}}>
                <span className="text">{text}</span>
                {!!note && <span className="note">{note}</span>}
            </div>
        </div>
    );
};

export default (props): JSX.Element => {
    const [state, setState] = useState<any>({
        id: props.id || generateId('chart'),
        blackboard: null,
        linePointList: null,
        timer: useRef<any>({}),
        binded: useRef<any>({}),
        svgRef: useRef<any>(null),
        wrapperRef: useRef<any>(null),
        ...initState(props)
    }); 
    const previousData = usePrevious(props.data || []);
    const { id, viewBox, axis, graph, blackboard, linePointList, style, elementIntervalPosition, staticSize,} = state;
    const { type, symbol, textList, category } = props;
    const wrapper = classnames('chart-wrapper', `-${type}`, `-${category || 'basic'}` );

    useEffect(() => {
        if ( JSON.stringify(previousData) !== JSON.stringify(props.data || []) ) {
            renewGraph( props, state, setState );
        }
        bindGraphEvent(state, setState);
    }, [previousData, props, state, setState]);
   
    return (
        <div aria-hidden="true" id={`${id}-wrapper`} className={wrapper} ref={state.wrapperRef} style={staticSize || {}}
            onMouseDown={(e)=>{ mousedown(state, setState, e); }}
            onMouseMove={(e)=>{ mousemove(state, setState, e); }}
            onMouseUp={(e)=>{ mouseup(state, setState, e); }} 
        >
            { graph ? <>
                    <svg id={id} xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} version="1.1"
                        className={`svg-chart ${style || 'basic'}`} ref={state.svgRef}
                    >
                        { axis && (((axis.x || {}).list || []).length > 0 || ((axis.y || {}).list || []).length > 0) && <g className="axis-wrapper">
                            {((axis.x || {}).list || []).map((data, i) => (
                                data ? <ChartGraph key={`x-${data.id || i}`} data={data} animate={false} /> : null
                            ))}
                            {((axis.y || {}).list || []).map((data, i) => (
                                data ? <ChartGraph key={`y-${data.id || i}`} data={data} animate={false} /> : null
                            ))}
                        </g>
                        }

                        { graph && graph.list && graph.list.length > 0 && <g className="graph-wrapper">
                            { graph.list.filter( (d) => !!d).map((data, i) => (
                                data.transform ? <g transform={data.transform} key={`graph-${data.id || i}`}>
                                    <ChartGraph data={data} symbol={symbol} />
                                </g> : <ChartGraph key={`graph-${data.id || i}`} data={data} symbol={symbol}/>
                            )) }
                        </g>
                        }
                    </svg>

                    <Blackboard data={blackboard}/>

                    { (linePointList || []).length > 0 &&
                            <React.Fragment>
                                {linePointList.map( (d)=>(
                                    <LinePoint key={`t-line-point-${d.id}`} data={d} clearTimer={()=>{ linePointClearTimer(state, setState); }}/>
                                ))}
                            </React.Fragment>
                    }
                    { (graph.pointTextList || []).length > 0 &&
                            <React.Fragment>
                                {graph.pointTextList.map( (d)=>(
                                    <PointText key={`n-line-point-${d.id}`} data={d}/>
                                ))}
                            </React.Fragment>
                    }

                    { (elementIntervalPosition || []).length > 1 &&
                            <React.Fragment>
                                {elementIntervalPosition.map( d => (
                                    <div key={d.id} id={d.id} className={d.classname} style={d.style || {}}>                                       
                                        <span>{d.text || ''}</span>
                                    </div>
                                ))}
                            </React.Fragment>
                    }
                </> : null
            }

            { (textList || []).length > 0 && <div className="chart-text-wrapper">
                <div>
                    { (textList || []).map( (text,i) => {
                        const d = typeof(text) === 'string' ? {text} : text;
                        const attr = (d || {}).ariaLabel ? {'aria-label': d.ariaLabel} : {};
                        return d ? <div key={`chart-text-${i}`} {...attr} className={`chart-text -${d.type || 'normal'}`}>
                            {d.text}
                        </div> : null
                    })}
                </div>
            </div>}
        </div>
    );
};