import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NedTrekksMeny from '../ned-trekks-meny';
import HelperFunctions from '../helper-functions';
import RadioButtons from '../radio-buttons';

class IndividuellPensjonsSparingProfil extends Component {
    static propTypes = {
        texts: PropTypes.object,
        settIpsProfil: PropTypes.func,
        config: PropTypes.object,
        pensjonsvalg: PropTypes.object
    };

    constructor(props) {
        super(props);

        if (props.config && props.pensjonsvalg) {
            const ipsProfil = {
                ipsRisikoprofil: (props.pensjonsvalg.ipsRisikoprofil || 1).toString(),
                ipsUttaksAlder: props.pensjonsvalg.ipsUttaksAlder || 67
            };

            this.state = {
                ipsUttaksAldre: this.createPensionAgeInterval(props.config),
                risikoprofiler: this.createIpsRisiko(),
                ipsProfil
            };
        } else {
            this.state = {};
        }
    }

    velgRisiko = risikoValg =>
        this.setState({
            ipsProfil: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.ipsProfil,
                ipsRisikoprofil: risikoValg.id
            }
        });

    velgUttaksAlder = ipsUttaksAlder =>
        this.setState({
            ipsProfil: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.ipsProfil,
                ipsUttaksAlder
            }
        });

    lagreIpsProfil = () => this.props.settIpsProfil(this.state.ipsProfil);

    createIpsRisiko = () => [
        {
            navn: this.props.texts.get('hoyRisiko'),
            id: '2',
            tekst: this.props.texts.get('hoyRisikoBeskrivelse')
        },
        {
            navn: this.props.texts.get('middelsRisiko'),
            id: '1',
            tekst: this.props.texts.get('middelsRisikoBeskrivelse')
        },
        {
            navn: this.props.texts.get('lavRisiko'),
            id: '0',
            tekst: this.props.texts.get('lavRisikoBeskrivelse')
        }
    ];

    createPensionAgeInterval = config =>
        HelperFunctions.genererListeMedTall(config.minimumsAlder, config.maximumsAlder);

    render = () => (
        <div className="start-ips ips-spare-profil">
            <div className="start-ips-tittel">{this.props.texts.get('faaAnbefalteFond')}</div>
            <div className="start-ips-tekst">{this.props.texts.get('faaAnbefalingBasertPaa')}</div>
            <div className="spareprofil-seksjon">
                <div className="pensjons-dropdown">
                    <NedTrekksMeny
                        id="ipsUttaksAlder"
                        label={this.props.texts.get('ipsUttaksAlder')}
                        options={this.state.ipsUttaksAldre}
                        defaultValue={this.state.ipsProfil.ipsUttaksAlder}
                        onChange={this.velgUttaksAlder}
                    />
                </div>
                <RadioButtons
                    legend={this.props.texts.get('velgRisiko')}
                    items={this.state.risikoprofiler}
                    onChange={this.velgRisiko}
                    defaultValue={this.state.ipsProfil.ipsRisikoprofil.toString()}
                />
                <div className="action-button">
                    <button type="button" className="btn btn-call-to-action" onClick={this.lagreIpsProfil}>
                        {this.props.texts.get('seAnbefalteFond')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default IndividuellPensjonsSparingProfil;
