import React from 'react';
import ActionButton from '../action-button';

const redirect = (url, formParameters, method) => {
    const form = document.createElement('form');
    form.method = method;
    form.action = url;
    Object.keys(formParameters).forEach(name => {
        const input = document.createElement("input");
        input.name = name;
        input.value = formParameters[name];
        form.appendChild(input);
    })
    document.body.appendChild(form);
    form.submit();
};

export const redirectToAvtalegiro = (avtalegiroUrl, avtalegiroObject) => {
    const returnUrl = `${window.location.href}?length=${window.history.length}`;
    // window.location.reload();
    if (avtalegiroObject) {
        const formParameters = avtalegiroObject.formParameters;
        formParameters.returnUrl = returnUrl;
        redirect(avtalegiroObject.uri, formParameters, 'post');
    } else {
        window.location.href = `${avtalegiroUrl}${encodeURIComponent(returnUrl)}`;
    }
};

export const visAvtalegiroKnapp = (text, avtalegiroUrl, avtalegiroObject) => {
    if (avtalegiroUrl || avtalegiroObject) {
        return <ActionButton action={() => redirectToAvtalegiro(avtalegiroUrl, avtalegiroObject)} text={text} />;
    }
    return '';
};
