import React from 'react';
import PropTypes from 'prop-types';

const StartIndividuellPensjonsSparing = props => (
    <div className="start-ips">
        <div className="start-ips-tittel">{props.texts.get('startIPSSparingNaa')}</div>
        <div className="start-ips-tekst">{props.texts.get('startIPSSparingNaaTekst')}</div>

        <div className="action-button">
            <button type="button" className="btn btn-call-to-action" onClick={props.settOppIpsProfil}>
                {props.texts.get('sparIIPS')}
            </button>
        </div>

        <div className="infoboks box">
            <div className="infoboks-icon-wrapper">
                <span className="ikon-info-24 infoboks-icon" />
            </div>
            <div className="infoboks-text">
                <p>{props.texts.get('dersomDuSparer')}</p>
            </div>
        </div>
    </div>
);

StartIndividuellPensjonsSparing.propTypes = {
    texts: PropTypes.object.isRequired,
    settOppIpsProfil: PropTypes.func.isRequired
};

export default StartIndividuellPensjonsSparing;
