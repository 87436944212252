// @ts-nocheck // kiet fix it later
import React from 'react';
import {Provider} from 'react-redux';
import loadContainers from '@eika-infrastruktur/ui-engine-render';
import configureStore from './app/configureStore';
import Router from './app/Router';
import AHV from './standalone/AHV/AHV';
import KjopRouter from './standalone/Kjop/KjopRouter';
import EKFfondVisning from './standalone/MSSopenComponent/EKFfondVisning';
import Kalkulator from './standalone/Kalkulator/Kalkulator';
import EKFspareLinking from './standalone/MSSopenComponent/EKFspareLinking';
import EKFmarkedsforingVarsel from './standalone/MSSopenComponent/EKFmarkedsforingVarsel';
import EKFpubliseringsdato from './standalone/MSSopenComponent/EKFpubliseringsdato';


const store = configureStore();
const globalCssNamespace = 'sparing-min-side';
const renderApp = (MyApp: any, namespace: string, param = {}) => {
    loadContainers(namespace, (props) => {
        if (props && props.config) {
            store.dispatch({type: 'LOAD_TEXTS', data: props.config});
        }
        return (
            <Provider store={store}>
                <div className={globalCssNamespace}>
                    <MyApp {...param} {...props} />
                </div>
            </Provider>
        );
    });
};

if (module.hot) {
    module.hot.accept('./app/App', () => {
        // eslint-disable-next-line global-require
        const NewApp = require('./app/App').default;

        renderApp(NewApp, 'sparing-min-side');
    });
}

renderApp(Router, 'sparing-min-side');
renderApp(AHV, 'sparing-min-side.kjenndinkunde', {type: 'adult'});
renderApp(AHV, 'sparing-min-side.kjenndinkunde-barn', {type: 'child'});

renderApp(KjopRouter, 'sparing-min-side.kjoep-fond');

renderApp(EKFspareLinking, 'sparing-min-side.ekf-spare-linking');

renderApp(Kalkulator, 'sparing-min-side.ekf-fond-kalkulator', {type: 'fund'});

renderApp(EKFpubliseringsdato, 'sparing-min-side.ekf-publiseringsdato');
renderApp(EKFmarkedsforingVarsel, 'sparing-min-side.ekf-markedsforing-varsel');
renderApp(EKFmarkedsforingVarsel, 'sparing-min-side.ekf-markedsforing-varsel-inversert-topp', {invertedTop: true});

renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning');
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-graf-samling', {samling: true});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-alpha', {fond: 'eika alpha'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-balansert', {fond: 'eika balansert a'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-egenkapitalbevis', {fond: 'eika egenkapitalbevis a'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-global', {fond: 'eika global a'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-kreditt-a', {fond: 'eika kreditt a'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-kreditt-b', {fond: 'eika kreditt b'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-norge', {fond: 'eika norge a'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-norden', {fond: 'eika norden a'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-spar', {fond: 'eika spar a'});