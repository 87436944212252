import { get } from "@eika-infrastruktur/request";
import { FETCHSIGNAL } from './index';
import { utforOrdre } from './common';
import { VIS_KVITTERING } from './modals';

export const IPS_OPPRETTET = 'IPS_OPPRETTET';
export const LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA = 'LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA';
export const OEK_MND_SPARING = 'OEK_MND_SPARING';
export const HENT_FORVENTET_PENSJONSTALL = 'HENT_FORVENTET_PENSJONSTALL';
export const LASTER_PENSJONS_ESTIMATER = 'LASTER_PENSJONS_ESTIMATER';
export const PENSJON_PROGNOSE_PENDING = 'PENSJON_PROGNOSE_PENDING';
export const PENSJON_PROGNOSE_OK = 'PENSJON_PROGNOSE_OK';
export const PENSJON_PROGNOSE_ERROR = 'PENSJON_PROGNOSE_ERROR';
export const MAX_ANTALL_KALL_OVERSKREDET = 'MAX_ANTALL_KALL_OVERSKREDET';
export const ACKNOWLEDGE_ERROR = 'ACKNOWLEDGE_ERROR';
export const SET_EPK_TRANSACTION = 'SET_EPK_TRANSACTION';
export const SET_TRANSACTION = 'SET_TRANSACTION';

const resourceUrl = '/sparing-min-side-web/rest/resource';
const pensjonUrl = `${resourceUrl}/pensjon`;
const fondUrl = `${resourceUrl}/fond`;
const spareUrl = `${resourceUrl}/spare`;
const opprettIPSUrl = `${fondUrl}/portefoljer/ips`;

const paaVellykketIPSOpprettelse = (data, dispatch) => {
    dispatch({
        type: IPS_OPPRETTET,
        portefoljer: data.portefolje
    });

    dispatch({
        type: VIS_KVITTERING,
        ordreResultater: data.ordreResultater
    });
};

export const opprettIPS = data => {
    return utforOrdre(data, opprettIPSUrl, 'POST', paaVellykketIPSOpprettelse);
};

export const getForventetPensjon = ( data, callback ) => async (dispatch) => {
    let result = null;

    try {
        if ( FETCHSIGNAL.getForventetPensjon ) { FETCHSIGNAL.getForventetPensjon.abort(); }
        FETCHSIGNAL.getForventetPensjon = new AbortController();
    } catch ( error ) {
        FETCHSIGNAL.getForventetPensjon = {};
    }

    const { pensjonsAlder, bruttoInntekt } = data;
    const url = `${pensjonUrl}/forventetPensjon/onsketalder/${pensjonsAlder}/bruttoaarsinntekt/${bruttoInntekt}?inkluderFond=true`.replace(/\s+/g, '');
    try {
        result = await get(url, {signal: FETCHSIGNAL.getForventetPensjon.signal}).catch( () => null );
    } catch ( error ) {
        result = null;        
    }

    return typeof(callback) === 'function' ? 
        callback( result ): dispatch({ type: HENT_FORVENTET_PENSJONSTALL, data: result });
};

export const getPensjonVedOktMndSparing = ( data, callback ) => async (dispatch) => {
    let result = null;

    try {
        if ( FETCHSIGNAL.getPensjonVedOktMndSparing ) { FETCHSIGNAL.getPensjonVedOktMndSparing.abort(); }
        FETCHSIGNAL.getPensjonVedOktMndSparing = new AbortController();
    } catch ( error ) {
        FETCHSIGNAL.getPensjonVedOktMndSparing = {};
    }

    const { pensjonsAlder, sparing } = data;
    const url = `${pensjonUrl}/beregnpensjonvedoktmndsparing/onsketalder/${pensjonsAlder}/oktmndsparing/${sparing}`.replace(/\s+/g, '');
    try {
        result = await get(url, {signal: FETCHSIGNAL.getPensjonVedOktMndSparing.signal}).catch( () => null );
    } catch ( error ) {
        result = null;        
    }

    return typeof(callback) === 'function' ? 
        callback( result ): dispatch({ type: OEK_MND_SPARING, data: result });
};

export const lastarbeidsgiversPensjonssparing = (callback) => async (dispatch) => {
    let result = null;
    const url = `${spareUrl}/employer-pension-v3`;

    try {
        result = await get( url ).catch( () => null );
    } catch ( error ) {
        result = null;        
    }

    return typeof(callback) === 'function' ? 
        callback( result ): dispatch({ type: LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA, data: result });
};

export const getTransactions = (config, callback) => async (dispatch) => {
    if ( !config ) { config = {}; }

    let result = null;
    const dispatchTypes = {
        epk: SET_EPK_TRANSACTION,
    };

    if ( !config.fromDate || !config.toDate ){
        return typeof(callback) === 'function' ? 
            callback( result ) : dispatch({ type: dispatchTypes[config.id] || SET_TRANSACTION, data: {result, loading: false} });
    }

    dispatch({ type: dispatchTypes[config.id] || SET_TRANSACTION, data: {loading: true} });    
    const param = (config.param || []).concat([
        `fromDate=${config.fromDate.toISOString().slice(0, 10)}`,
        `toDate=${config.toDate.toISOString().slice(0, 10)}`,
    ]);

    const url = `${resourceUrl}/transactions?${param.join('&')}`;
    try {
        result = await get( url ).catch( () => null );
    } catch ( error ) {
        result = null;        
    }

    return typeof(callback) === 'function' ? 
        callback( result ) : dispatch({ type: dispatchTypes[config.id] || SET_TRANSACTION, data: {result, loading: false} });
};

export const lastPrognoseForAvtale = (avtalenummer, polisenummer) => {
    return dispatch => {
        dispatch({ type: PENSJON_PROGNOSE_PENDING });
        return get(`${pensjonUrl}/prognoser/${avtalenummer}/${polisenummer}`)
            .then(response => {
                            dispatch({
                                type: PENSJON_PROGNOSE_OK,
                                data: response
                            });
            })
            .catch(err => {
                dispatch({
                    type: PENSJON_PROGNOSE_ERROR,
                    error: err
                });
            });
    };
};

export const acknowledgeError = () => {
    return dispatch => {
        dispatch({ type: ACKNOWLEDGE_ERROR });
    };
};

const initialState = {
    lasterPensjonsEstimater: false,
    beregnetPensjonError: undefined
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HENT_FORVENTET_PENSJONSTALL:
            return {
                ...state,
                lasterPensjonsEstimater: false,
                pensjonsData: action.data
            };
        case OEK_MND_SPARING:
            return {
                ...state,
                ...action.data
            };
        case LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA:
            return {
                ...state,
                arbeidsgiversPensjonsSparing: action.data
            };
        case LASTER_PENSJONS_ESTIMATER:
            return {
                ...state,
                lasterPensjonsEstimater: true
            };
        case PENSJON_PROGNOSE_PENDING:
            return {
                ...state,
                prognoser: {
                    status: 'Pending',
                }
            };
        case PENSJON_PROGNOSE_OK:
            return {
                ...state,
                prognoser: {
                    status: 'Ok',
                    values: action.data.prognose,
                    uttaksAlder: action.data.pensjonsalder
                }
            };
        case PENSJON_PROGNOSE_ERROR:
            return {
                ...state,
                prognoser: {
                    status: 'Error'
                }
            };
        case MAX_ANTALL_KALL_OVERSKREDET:
            return {
                ...state,
                beregnetPensjonError: {
                    message: action.message,
                    name: action.name,
                    acknowledged: false
                }
            };
        case ACKNOWLEDGE_ERROR:
            return {
                ...state,
                beregnetPensjonError: {
                    ...state.error,
                    acknowledged: true
                }
            };
        case SET_EPK_TRANSACTION:
            return {
                ...state,
                epkTransaction: {
                    ...action.data
                }
            };
        default:
            return state;
    }
};
