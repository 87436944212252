/**
 * Created by vagrant on 01.03.17.
 */
class FormatFunctions {
    static splitText = (text, split) => {
        let i = (text || '').length % split;
        const list = i ? [text.substr(0, i)] : [];
        for (i; i < text.length; i += split) {
            list.push(text.substr(i, split));
        }
        return list;
    };

    static percent = prosent => {
        if ( typeof(prosent) !== 'number' ) { return '-'; }
        return `${FormatFunctions.number(prosent.toFixed(2), false)} %`;
    };

    static number = (value, round=true) => {
        const text = (`${value}`).replace(/[\s]+/g, '') || '0';
        const number = parseFloat(text.replace(/,/g, '.'));
        const digit = `${round ? Math.round(number) : number}`.split('.').map( (v) => parseFloat(v) );
        const decimal = digit[1] ? `,${digit[1]}` : '';
        return `${digit[0] < 0 ? '-' : ''}${FormatFunctions.splitText(`${Math.abs(digit[0])}`, 3).join(' ')}${decimal}`;
    };

    static fjernUnodvendigeMellomrom = tekst => {
        return tekst.replace(/\s\s+/g, ' ').trim();
    };

    static currency = number => {
        return number === null ? '-' : `${FormatFunctions.number(number)},-`;
    };
}

export default FormatFunctions;
