// @ts-nocheck // kiet fix it later
// import {Animated} from 'react-native';
import { generateId } from '../../util/Functions';
import { createSymbolPath, getChartText } from './ChartFunction';

const catmullRom2bezier = (pointList, cubicSize) => {
    const result = [];
    const cubic = cubicSize || 6;
    for (let i = 0; i < pointList.length - 1; i++) {
        const p = [[pointList[Math.max(i - 1, 0)][0], pointList[Math.max(i - 1, 0)][1]]];

        p.push([pointList[i][0], pointList[i][1]]);
        p.push([pointList[i + 1][0], pointList[i + 1][1]]);
        p.push([
            pointList[Math.min(i + 2, pointList.length - 1)][0],
            pointList[Math.min(i + 2, pointList.length - 1)][1]
        ]);

        // Catmull-Rom to Cubic Bezier conversion matrix
        //    0         1         0        0
        //  -1/cubic    1      1/cubic     0
        //    0      1/cubic      1     -1/cubic
        //    0         0         1        0

        /* eslint-disable */
        const bp = [[(-p[0][0] + cubic * p[1][0] + p[2][0]) / cubic, (-p[0][1] + cubic * p[1][1] + p[2][1]) / cubic]];
        bp.push([(p[1][0] + cubic * p[2][0] - p[3][0]) / cubic, (p[1][1] + cubic * p[2][1] - p[3][1]) / cubic]);
        /* eslint-enable */
        bp.push([p[2][0], p[2][1]]);
        result.push(bp);
    }
    return result;
};

const initGraphLinePath = (state, info) => {
    const color = info.list[0].color || state.color.default;
    const dash = parseInt(info.linePath.dash, 10) * (state.type === 'spline' ? 1.5 : 1);
    const fill = state.fill || info.list[0].fill;
    let { pointList } = info.linePath;
    const data = {
        type: 'path',
        path: `M ${pointList.join(' L ')}`,
        style: {
            fill: 'none',
            stroke: info.list[0].lineColor || state.lineColor || color,
            strokeWidth: info.list[0].lineWidth || state.lineWidth || 6,
            strokeDasharray: dash
        }
    };

    if (state.type === 'spline') {
        const catmull = catmullRom2bezier(info.linePath.pointDataList);
        data.path = `M${info.linePath.pointList[0]}`;
        for (let i = 0; i < catmull.length; i++) {
            data.path += ` C${catmull[i][0][0]},${catmull[i][0][1]} ${catmull[i][1][0]},${catmull[i][1][1]} ${catmull[i][2][0]},${catmull[i][2][1]}`;
        }
    }

    if (state.animation) {
        data.duration = info.linePath.duration;
        data.animateFrom = dash;
        data.animateTo = 0;
        data.animation = {
            config: {
                inputRange: [0, 1],
                outputRange: [data.animateFrom, data.animateTo]
            },
            attributeName: 'stroke-dashoffset'
        };
    }

    const list = [data];
    if ( fill ) {
        const first = info.list[0];
        const last = info.list[info.list.length - 1];
        const bottom = (state.axis.y.zero || state.axis.y.max) + state.padding.top;
        pointList = pointList.concat([[last.cx, bottom].join(','), [first.cx, bottom].join(',')]);

        const fillData = {
            id: generateId('line-polygon'),
            type: 'line-polygon',
            points: pointList.join(' '),
            duration: info.linePath.duration,
            style: {
                fill: color,
                opacity: typeof(fill) === 'number' ? fill : 0.4,
                stroke: 'transparent',
                strokeWidth: 0
            }
        };

        if (state.animation) {
            fillData.animate = {'to': fillData.style.opacity, 'attributeName': 'fill-opacity'};
        }
        info.list.unshift(fillData);
    }

    info.list = list.concat(info.list);
};

const initGraphLinePointText = (state, info) => {
    info.pointTextList = [];
    info.linePath.pointText.forEach(data => {
        const src = data.pointText;

        if ( !src || !src.text ) { return; }

        const gap = 100;
        info.list.push(
            getChartText({
                x: data.center[0],
                y: data.center[1] - gap,
                text: src.text,
                size: src.size                
            })
        );
    });
};

const createInvisibleRec = (data, info, index) => {
    const cloned = JSON.parse(JSON.stringify(data));
    cloned.id = generateId('invible-reg');

    const pathPointX = cloned.cx - info.width / 2;
    const xMin = pathPointX < info.linePath.xLeft ? info.linePath.xLeft : pathPointX;
    const xMax = pathPointX + info.width > info.linePath.xRight ? info.linePath.xRight : pathPointX + info.width;

    cloned.pointList = [
        [xMin, info.linePath.yTop].join(','),
        [xMax, info.linePath.yTop].join(','),
        [xMax, info.linePath.yBottom].join(','),
        [xMin, info.linePath.yBottom].join(','),
        [xMin, info.linePath.yTop].join(',')
    ];

    cloned.type = 'path';
    cloned.path = `M ${cloned.pointList.join(' L ')}`;
    cloned.style = {
        fill: 'transparent',
        stroke: 'none',
        strokeWidth: 0
    };

    cloned.className = 'graph-rec-invisble graph-source';
    cloned.index = index;
    return cloned;
};

const initGraphLineInfo = (state, info) => {
    info.width = (state.axis.x.max - state.lineSpace * 2) / (info.list.length - 1);
    info.linePath = {
        pointList: [],
        pointDataList: [],
        prePoint: null,
        dash: 0,
        duration: state.duration,
        pointText: [],
        xLeft: state.padding.left,
        xRight: state.padding.left + state.axis.x.max,
        yTop: state.padding.top,
        yBottom: state.padding.top + state.axis.y.max
    };

    const invisibleRecList = [];
    info.list.forEach((data, i) => {
        data.type = data.point === true ? 'path' : 'line-point-none';    
        data.percent = (data.value || 0) / info.distance;
        data.height = (state.axis.y.max || 0) * data.percent;
        data.width = info.width - state.lineSpace * 2;
        data.cx = info.width * i + state.lineSpace + state.padding.left;
        data.cy = (state.axis.y.zero || state.axis.y.max || 0) - data.height + state.padding.top;
        data.center = [data.cx, data.cy];
        data.radius = (state.symbol || {}).radius || state.lineRadius;
        data.duration = info.linePath.duration;
        data.color = data.color || state.color.background;
        data.path = createSymbolPath(data);
        data.style = {
            fill: data.color,
            stroke: state.color.default,
            strokeWidth: 2
        };

        info.linePath.pointDataList.push([data.cx, data.cy]);
        info.linePath.pointList.push([data.cx, data.cy].join(','));
        if (info.linePath.prePoint) {
            let x = data.cx - info.linePath.prePoint.cx;
            let y = data.cy - info.linePath.prePoint.cy;
            if (x < 0) {
                x *= -1;
            }
            if (y < 0) {
                y *= -1;
            }
            info.linePath.dash += Math.sqrt(x * x + y * y);
        }
        info.linePath.prePoint = data;

        if (data.pointText ) {
            info.pointTextList.push({ ...data, ...data.pointText });
            // info.linePath.pointText.push(data);
        }

        if ( state.hover ) { invisibleRecList.push(createInvisibleRec(data, info, i)); }
    });

    if (info.list.length > 1) {
        initGraphLinePath(state, info);
    }

    if (info.linePath.pointText.length) { initGraphLinePointText(state, info); }

    info.list = info.list.concat(invisibleRecList);
};

export default initGraphLineInfo;
