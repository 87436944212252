import React, { useState, useEffect, useRef, MouseEvent } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { StandardButton, LinkButton, SmallButton } from '@eika/button';
import { Link } from '@eika/link';
import { IconTypes } from '@eika/icon';
import Constant from '../../static/data/Constant';
import { usePrevious } from '../../common/util/Hook';
import Message from '../../common/Message/Message';
import Card from '../../common/Card/Card';
import Grid from '../../common/Grid/Grid';
import Carousel from '../../common/Carousel/Carousel';
import AppMessage from '../../common/AppMessage/AppMessage';
import OtherServiceButtonListView from '../../common/OtherServiceButtonListView/OtherServiceButtonListView';
import ActionButtonListView, {
    ActionButtonListItemProps,
} from '../../common/ActionButtonListView/ActionButtonListView';
import {
    HeaderLink,
    Spinner,
    Commercial,
    SectionSpareavtale,
    LinkList,
    SummaryTotalValue,
} from '../../common/share/ShareComponents';
import {
    formatAmount,
    openSmartspart,
    getPageAppending,
    sortList,
    generateId,
    eraseCookie,
} from '../../common/util/Functions';
import { getNewFondOrder, getAgreementOrderSettting, getFondOrderStorage } from '../../common/util/FondFunctions';
import {
    shallGetArbeidsgiversPensjonssparing,
    getAPPactionList,
    getFundCardConfig,
    verifyLinking,
} from '../../common/util/BusinessFunctions';
import { getAvtaleCardList } from '../Spareavtale/util/Functions';
import { AppTexts, RouteLocation, RouteHistory } from '../../domain/Types';
import './SparingSammendrag.scss';
import useMutualFundStore from '../../stores/MutualFundStore';

interface Props {
    appTexts: AppTexts;
    history: RouteHistory;
    location: RouteLocation;
    action: any;
}

interface State {
    appending: string;
    actionList: ActionButtonListItemProps[];
    content: any;
    tradeTypeList: { id: string; text: string; key?: string }[];
    reducer: any;
}

export default (props: Props): JSX.Element => {
    const { setEntry, setPaymentType } = useMutualFundStore();
    const { Bank, ...reducer } = useSelector((state: any) => {
        return ['App', 'Fond', 'Pensjon', 'SpareProfil', 'Bank', 'Epk'].reduce((p: { [k: string]: any }, k: string) => {
            return { ...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const getSammendragSummary = (portefoljeData: any, disponertePortefoljer: any): any => {
        const summary: any = {
            dag: new Date().getDate(),
            maxFondList: 3,
            fondList: [],
            avtaleList: getAvtaleCardList(portefoljeData, disponertePortefoljer, undefined, props.action.postLog).list,
            totalVerdi: null,
            linkList: [
                {
                    id: 'aksjer-og-fond',
                    url: `${window.location.href.replace(/\/+$/g, '')}/portefolje`,
                    name: 'Se alle dine fond',
                    icon: IconTypes.BUET_PIL_HOEYRE_20,
                    type: 1,
                    dataTrackId: Constant.trackIdProduct,
                    dataTrackLinkname: 'se-alle-dine-fond',
                },
                {
                    id: 'orde-historikk',
                    url: `${window.location.href.replace(/\/+$/g, '')}/ordre`,
                    name: 'Ordrehistorikk',
                    icon: IconTypes.BEVEGELSER_36,
                    type: 1,
                    dataTrackId: Constant.trackIdProduct,
                    dataTrackLinkname: 'ordrehistorikk',
                },
                {
                    id: 'fond-oversikt',
                    url: `${window.location.href.replace(/\/+$/g, '')}/fondvisning`,
                    name: 'Fondslisten',
                    icon: IconTypes.BUET_PIL_HOEYRE_20,
                    type: 0,
                    dataTrackId: Constant.trackIdProduct,
                    dataTrackLinkname: 'fondslisten',
                },
                {
                    id: 'fond-veileder',
                    url: `${window.location.href.replace(/\/+$/g, '')}/fondvisning?suggestion=${generateId(
                        'fs',
                        true
                    )}`,
                    name: 'Få hjelp til å velge type fond',
                    icon: IconTypes.BUET_PIL_HOEYRE_20,
                    type: 0,
                    dataTrackId: Constant.trackIdProduct,
                    dataTrackLinkname: 'faa-hjelp-til-aa-velge-fond',
                },
            ],
        };

        try {
            const portefoljeList = (portefoljeData?.portefoljer ?? []).concat(disponertePortefoljer || []);
            const fondList = portefoljeList.reduce((list: any, portefolje: any) => {
                if (typeof portefolje.initialValue === 'number') {
                    summary.totalVerdi = (summary.totalVerdi || 0) + portefolje.initialValue;
                }
                list = list.concat(portefolje.fond);
                return list;
            }, []);

            const sorted = sortList(fondList, 'totalVerdi', true, true);
            sorted.forEach((data: any, i: number) => {
                if (!data || !data.portefolje) {
                    return;
                }

                if (summary.maxFondList > summary.fondList.length) {
                    summary.fondList.push({
                        key: `fund-${data.isin}-${i}`,
                        url: `${window.location.href.replace(/\/+$/g, '')}/portefolje/${data.portefolje.id}/${
                            data.isin
                        }`,
                        ...getFundCardConfig(data),
                    });
                }
            });
        } catch (error) {
            props.action.postLog(error, { portefoljeData, disponertePortefoljer, function: 'getSammendragSummary' });
        }

        summary.totalVerdi = formatAmount(summary.totalVerdi, true);
        summary.linkList = summary.linkList.filter(
            (d: any) => (summary.fondList.length && d.type) || (!summary.fondList.length && !d.type)
        );
        return summary;
    };
    const getContent = (reducer: any): any => {
        const { appTexts } = props || {};
        const { portefoljeData, disponertePortefoljer, kontoer } = reducer.Fond || {};
        const { arbeidsgiversPensjonsSparing } = reducer.Pensjon || {};
        const { kundeInfo, pensjonsvalg } = reducer.SpareProfil || {};

        const content: any = {
            totalVerdi: null,
            panelList: [
                {
                    id: 'aksjer-og-fond',
                    type: 'spare-link-panel',
                    warning: '',
                    data: {
                        url: `${window.location.href.replace(/\/+$/g, '')}/portefolje`,
                        textList: [appTexts.get('fondogAksjer')],
                        dataTrackId: Constant.trackIdProduct,
                        dataTrackLinkname: appTexts.get('fondogAksjer', undefined),
                    },
                },
                shallGetArbeidsgiversPensjonssparing(kundeInfo)
                    ? {
                          id: 'pensjon-fra-arbeidsgiver',
                          type: 'spare-link-panel',
                          url: `${window.location.href.replace(/\/+$/g, '')}/arbeidsgiver`,
                          warning: '',
                          data: {
                              textList: [appTexts.get('pensjonFraArbeidsgiver')],
                              dataTrackId: Constant.trackIdProduct,
                              dataTrackLinkname: appTexts.get('pensjonFraArbeidsgiver', undefined),
                          },
                      }
                    : null,
            ].filter((panel: any) => !!panel),
            summary: null,
        };

        try {
            content.panelList.forEach((d: any) => {
                d.value = 0;
                d.loading = true;
                d.failed = false;
                if (d.id === 'aksjer-og-fond') {
                    if (portefoljeData && disponertePortefoljer) {
                        d.loading = false;
                        content.summary = getSammendragSummary(portefoljeData, disponertePortefoljer);
                        d.value = content.summary.totalVerdi;
                        // d.value = parseInt( `${content.summary.totalVerdi || 0}`.replace( /[a-z\s]+/ig,  ''), 10);
                    } else if (portefoljeData === null) {
                        d.failed = true;
                        d.name = appTexts.get('fondogAksjerUtilgjengligMelding');
                        d.data.textList = [appTexts.get('fondogAksjerUtilgjengligMelding')];
                    }
                } else if (d.id === 'banksparing') {
                    if (kontoer) {
                        d.value = kontoer.reduce((a: any, b: any) => a + (b.aktiv ? b.verdi || 0 : 0), 0);
                        d.loading = false;
                    } else if (kontoer === null) {
                        d.failed = true;
                    }
                } else if (d.id === 'pensjon-fra-arbeidsgiver') {
                    if (arbeidsgiversPensjonsSparing) {
                        d.value = arbeidsgiversPensjonsSparing.reduce((a: any, b: any) => a + (b.balance ?? 0), 0);
                        d.loading = false;
                    } else if (arbeidsgiversPensjonsSparing === null) {
                        d.failed = true;
                        d.name = appTexts.get('pensjonFraArbeidsgiverUtilgjengligMelding');
                        d.data.textList = [appTexts.get('pensjonFraArbeidsgiverUtilgjengligMelding')];
                    }
                } else if (d.id === 'spareprofil') {
                    if (kundeInfo && pensjonsvalg) {
                        d.loading = false;
                    } else if (kundeInfo === null || pensjonsvalg === null) {
                        d.failed = true;
                    }
                }

                /* eslint-disable */
                d.value = d.value === null ? null : typeof d.value === 'number' ? Math.round(d.value) : d.value;
                /* eslint-enable */

                if (d.failed) {
                    d.loading = false;
                    d.type = 'spare-link-panel -failed';
                    delete d.url;
                } else if (d.id === 'spareprofil') {
                    if (!d.loading) {
                        d.text = [['fornavn', 'etternavn'], 'epost', 'mobil']
                            .map((key) => {
                                return (key instanceof Array ? key : [key])
                                    .map((k) => kundeInfo[k])
                                    .filter((v) => !!v)
                                    .join(' ');
                            })
                            .filter((v) => !!v)
                            .join(', ');
                    }
                } else if (d.value !== null) {
                    d.text = `${formatAmount(d.value)}`;
                    d.data.textList[1] = `${formatAmount(d.value)}`;
                }
            });
        } catch (error) {
            props.action.postLog(error, {
                portefoljeData,
                disponertePortefoljer,
                arbeidsgiversPensjonsSparing,
                function: 'Sparing sammendrag - getContent',
            });
        }

        if (!content.panelList.find((d: any) => d.loading)) {
            content.totalVerdi = formatAmount(content.panelList.reduce((a: any, b: any) => a + (b.value || 0), 0));
        }

        return content;
    };

    const eikaInnskuddspensjonRedirect = useRef<boolean>(/\?eika-innskuddspensjon/i.test(window.location.href)).current;
    const [state, setState] = useState<State>({
        tradeTypeList: reducer.App.isOnTest
            ? [
                  { id: Constant.typeEngangsinnkudd, text: 'Sett inn et engangsbeløp' },
                  { id: Constant.typeManedligSparing, text: 'Spar et fast beløp hver måned' },
              ]
            : [
                  { id: Constant.typeEngangsinnkudd, key: 'sett-inn-penger', text: 'Sett inn et engangsbeløp' },
                  { id: Constant.typeManedligSparing, key: 'sett-inn-penger', text: 'Spar et fast beløp hver måned' },
              ],
        actionList: getAPPactionList(),
        content: getContent(reducer),
        appending: getPageAppending(props),
        reducer,
    });

    const menuList: ActionButtonListItemProps[] = [
        {
            id: Constant.actionVisSparekalkulator,
            icon: IconTypes.KALKULATOR_24,
            name: 'Spare- kalkulator',
            dataTrackId: Constant.trackIdAction,
            dataTrackLinkname: 'spare-kalkulator',
        },
        {
            id:
                (reducer?.SpareProfil?.kundeInfo?.alder || 0) < Constant.pensionCustomerMaxAge
                    ? Constant.actionVisPensjonskalkulator
                    : '',
            icon: IconTypes.KALKULATOR_24,
            name: 'Pensjon- kalkulator',
            dataTrackId: Constant.trackIdAction,
            dataTrackLinkname: 'pensjonskalkulator',
        },
        {
            id: Constant.actionVisFondVeileder,
            icon: IconTypes.LYSPAERE_24,
            name: 'Fonds- veileder',
            dataTrackId: Constant.trackIdAction,
            dataTrackLinkname: 'fondsveileder',
        },
        {
            id: Constant.actionVisFondListe,
            icon: IconTypes.SOEK_24,
            name: 'Fondslisten',
            dataTrackId: Constant.trackIdAction,
            dataTrackLinkname: 'fondslisten',
        },
    ].filter((data: ActionButtonListItemProps) => !!data.id);

    const verifyInitAction = (): void => {
        const config = getFondOrderStorage('FK0');
        eraseCookie('FK0');
        verifyLinking(config);
    };

    const previousReducer = usePrevious(reducer);
    useEffect(() => {
        if (JSON.stringify(reducer) === JSON.stringify(previousReducer)) {
            return;
        }

        if (eikaInnskuddspensjonRedirect && reducer.Pensjon?.arbeidsgiversPensjonsSparing) {
            const storebrand = reducer.Pensjon?.arbeidsgiversPensjonsSparing.find(
                (d: { source: string; agreementNumber: string }) => {
                    return d.source === Constant.storebrand && !!d.agreementNumber;
                }
            );

            if (storebrand) {
                window.location.href = window.location.href.replace(
                    /\?.*/,
                    `#/arbeidsgiver/storebrand/${storebrand.agreementNumber}`
                );
            } else {
                window.location.href = window.location.href.replace(/\?.*/, '');
            }
        }
        setState({ ...state, reducer, content: getContent(reducer) });
        //eslint-disable-next-line
    }, [previousReducer, reducer, state, props, eikaInnskuddspensjonRedirect]);

    useEffect(() => {
        props.action.getEPKsigningConfig();
    }, [props.action]);

    useEffect(() => {
        verifyLinking();
        verifyInitAction();
    }, []);

    const click = (e: MouseEvent, key = '', data?: any): void => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        /* eslint-disable */
        const linking = `${window.location.origin}/spare/TEST-minsidesparing`;
        const href = window.location.href;
        /* eslint-enable */

        if (
            key === Constant.actionSettInnPenger ||
            key === Constant.actionApneSmartspar ||
            data?.key === Constant.actionSettInnPenger
        ) {
            openSmartspart(key, data);
        } else if (key === Constant.actionKjopTilBarn) {
            props.history.push('/comingsoon');
        } else if (key === Constant.actionVisSparekalkulator) {
            props.history.push('/fondsberegning');
        } else if (key === Constant.actionVisPensjonskalkulator) {
            props.history.push('/pensjonsberegning');
        } else if (key === Constant.actionVisFondListe) {
            setEntry('FUNDLIST');
            setPaymentType('NONE');
            props.history.push('/fondliste');
        } else if (key === Constant.actionVisFondVeileder) {
            props.history.push(`/fondvisning?suggestion=${generateId('fs', true)}`);
        } else if (key === Constant.actionKjop) {
            const order = getNewFondOrder();
            props.history.push(`/fondhandel/${order}/step0?action=${Constant.piwikActionBuy}`);
        } else if (key === 'trade-type-selected' && data) {
            if (data.id === Constant.typeManedligSparing && state.reducer?.App?.isOnWebview) {
                props.history.push(`${props.location.pathname}/`);
            } else {
                const order = getNewFondOrder({ type: data.id });
                props.history.push(`/fondhandel/${order}/step1?action=${Constant.piwikActionMonthly}`);
            }
        } else if (key === Constant.actionKjopEngang) {
            setEntry('ONCE');
            setPaymentType('ONCE');
            const order = getNewFondOrder({ type: Constant.typeEngangsinnkudd });
            props.history.push(`/fondhandel/${order}/step1?action=${Constant.piwikActionOnetime}`);
        } else if (key === Constant.actionKjopSpareavtale) {
            setEntry('MONTHLY');
            setPaymentType('MONTHLY');
            const order = getNewFondOrder({ type: Constant.typeManedligSparing });
            props.history.push(`/fondhandel/${order}/step1?action=${Constant.piwikActionMonthly}`);
        } else if (key === Constant.actionEndreSpareavtale && data) {
            const order = getNewFondOrder({
                ...getAgreementOrderSettting(data),
                action: Constant.actionEndreSpareavtale,
                type: Constant.typeManedligSparing,
                // finishBack: 1,
            });
            props.history.push(`/fondhandel/${order}/step3?action=${Constant.piwikActionEditAgreement}`);
        } else if (key === 'get-epk-signing-config') {
            props.action.getEPKsigningConfig();
        }
    };

    if (eikaInnskuddspensjonRedirect) {
        return (
            <div className="sparing-sammendrag-wrapper max-width-400 -center paragraph-large -only-top">
                <Spinner type="app" />
            </div>
        );
    }

    // @ts-ignore
    return (
        <div className="sparing-sammendrag-wrapper -with-new-api">
            <Grid
                namespace="sparing-top"
                list={[
                    {
                        layout: 'twelve',
                        element: <HeaderLink text="Nettbank forsiden" url={`${window.location.origin}/nettbank`} />,
                    },
                ]}
            />

            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>{props.appTexts.get('minSparingTittel')}</h1>,
                    },
                ]}
            />

            <div className={`sparing-sammendrag-content ${state.appending}`}>
                <Grid
                    namespace="sparing-content"
                    list={[
                        {
                            layout: 'seven',
                            element: (
                                <>
                                    <AppMessage appMessage={reducer?.App?.serviceMessage} context="HOME">
                                        {reducer.Epk?.signingConfig?.signing?.status === Constant.readyForSigning &&
                                            reducer.Epk?.signingConfig?.signing?.signeringsurl && (
                                                <Message
                                                    role="presentation"
                                                    type="pending"
                                                    namespace="signing-message"
                                                    text={props.appTexts.get('epkPendingSigningText2')}
                                                >
                                                    {/* appTexts.get is typed to receive 2 args, here it gets 3 */}
                                                    {/* @ts-ignore as it runs and I don't know how or why I won't touch it now */}
                                                    <Link
                                                        data-tack-id={Constant.trackIdProduct}
                                                        data-track-linkname={props.appTexts.get(
                                                            'signNow',
                                                            undefined,
                                                            true
                                                        )}
                                                        href={`${window.location.href.replace(
                                                            /\/+$/g,
                                                            ''
                                                        )}/arbeidsgiver`}
                                                    >
                                                        {props.appTexts.get('signNow')}
                                                    </Link>
                                                </Message>
                                            )}
                                    </AppMessage>

                                    <ActionButtonListView
                                        namespace="paragraph only-for-mobile flex-center flex-nowrap"
                                        list={state.actionList}
                                        click={(e: MouseEvent, k = '', d?: any) => {
                                            click(e, k, d);
                                        }}
                                    />

                                    <ActionButtonListView
                                        namespace="only-for-mobile collection-small cell-3"
                                        list={menuList}
                                        click={(e: MouseEvent, k = '', d?: any) => {
                                            click(e, k, d);
                                        }}
                                        expandable={menuList.length > 3}
                                    />

                                    {!!state.content.summary && (
                                        <div
                                            className={classNames(
                                                `sammendag-summary paragraph -count-${state.content.summary.fondList.length}`,
                                                {
                                                    'only-for-desktop':
                                                        (state.content.summary?.fondList || []).length > 0,
                                                }
                                            )}
                                        >
                                            <div className="wallpaper-wrapper">
                                                <div className="max-width-600">
                                                    {state.content.summary.totalVerdi !== undefined &&
                                                        state.content.summary.fondList.length > 0 && (
                                                            <SummaryTotalValue
                                                                namespace="paragraph-large"
                                                                label="Fond og aksjer"
                                                                value={state.content.summary.totalVerdi}
                                                                message={state.content.summary.warning}
                                                            />
                                                        )}

                                                    {state.content.summary.fondList.length ? (
                                                        <div>
                                                            {state.content.summary.fondList.map(
                                                                (data: any, i: number) => {
                                                                    return (
                                                                        <Card
                                                                            key={`summary-fund-item-${i}`}
                                                                            data={data}
                                                                            type="spare-link-panel -white -collection"
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <article className="start-up-wrapper">
                                                            <div className="start-up-title paragraph-extra-small">
                                                                Fond og aksjer
                                                            </div>

                                                            <div className="start-up-description paragraph">
                                                                Med fond kan du få mer verdiøkning jo lenger du sparer.
                                                            </div>

                                                            <SmallButton
                                                                buttonType="negative"
                                                                data-tack-id={Constant.trackIdBuy}
                                                                data-track-linkname="kom-i-gang"
                                                                onClick={(e: MouseEvent) => {
                                                                    click(e, 'kjop-fond');
                                                                }}
                                                            >
                                                                Kom i gang
                                                            </SmallButton>
                                                        </article>
                                                    )}
                                                </div>
                                            </div>

                                            <LinkList
                                                list={state.content.summary.linkList}
                                                namespace="max-width-600 paragraph -include-top"
                                            />
                                        </div>
                                    )}

                                    <section aria-label="Sammendag oversikt" className="paragraph max-width-600">
                                        <ul className="content-list-wrapper">
                                            {state.content.panelList.map((data: any) => (
                                                <li
                                                    key={data.id}
                                                    className={classNames('list-item', {
                                                        'only-for-mobile':
                                                            data.id === 'aksjer-og-fond' &&
                                                            !data.loading &&
                                                            (state.content.summary?.fondList || []).length > 0,
                                                    })}
                                                >
                                                    {data.loading && !data.failed ? (
                                                        <Spinner type="linkPanel" />
                                                    ) : (
                                                        <Card {...data}>
                                                            {!!data.warning && (
                                                                <div className="paragraph-extra-small -only-top">
                                                                    <Message type="warning" text={data.warning} icon />
                                                                </div>
                                                            )}
                                                        </Card>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </section>

                                    <OtherServiceButtonListView namespace="max-width-600 only-for-desktop" />
                                </>
                            ),
                        },
                        {
                            layout: 'four',
                            element: (
                                <>
                                    <ActionButtonListView
                                        namespace="only-for-desktop wallpaper cell-2 show-double"
                                        list={state.actionList.concat(menuList)}
                                        click={(e: MouseEvent, k = '', d?: any) => {
                                            click(e, k, d);
                                        }}
                                        expandable={state.actionList.length + menuList.length > 4}
                                    />

                                    <div className="paragraph-large">
                                        {!state.content.summary && <Spinner type="linkPanel" />}

                                        {!!state.content.summary && (
                                            <>
                                                <div
                                                    className={`${
                                                        (state.content.summary.avtaleList || []).length
                                                            ? 'only-for-desktop'
                                                            : 'agreement-info'
                                                    }`}
                                                >
                                                    <SectionSpareavtale
                                                        list={state.content.summary.avtaleList || []}
                                                        max={2}
                                                        click={(e: MouseEvent, k: string, d: any) => {
                                                            click(e, Constant.actionEndreSpareavtale, d.detail);
                                                        }}
                                                    >
                                                        {(state.content.summary.avtaleList || []).length ? (
                                                            <Link
                                                                href={`${window.location.href.replace(
                                                                    /\/+$/g,
                                                                    ''
                                                                )}/spareavtale`}
                                                                icon={IconTypes.BUET_PIL_HOEYRE_20}
                                                                data-track-id={Constant.trackIdMonthly}
                                                                // @ts-ignore
                                                                data-track-linkname={props.appTexts.get(
                                                                    'seAlleSpareavtaler',
                                                                    undefined,
                                                                    true
                                                                )}
                                                            >
                                                                {props.appTexts.get('seAlleSpareavtaler')}
                                                            </Link>
                                                        ) : (
                                                            <LinkButton
                                                                data-track-id={Constant.trackIdMonthly}
                                                                // @ts-ignore
                                                                data-track-linkname={props.appTexts.get(
                                                                    'startMaanedligSparing',
                                                                    undefined,
                                                                    true
                                                                )}
                                                                onClick={(e: MouseEvent) => {
                                                                    click(e, Constant.actionKjopSpareavtale);
                                                                }}
                                                            >
                                                                {props.appTexts.get('startMaanedligSparing')}
                                                            </LinkButton>
                                                        )}
                                                    </SectionSpareavtale>
                                                </div>

                                                {(state.content.summary.avtaleList || []).length > 0 && (
                                                    <div className="only-for-mobile">
                                                        <div className="paragraph-extra-small section-title">
                                                            {props.appTexts.get('maanedligSparing')}
                                                        </div>
                                                        <Carousel
                                                            list={(state.content.summary.avtaleList || []).map(
                                                                (d: any, i: number) => (
                                                                    <Card
                                                                        namespace="fond-spareavtale-card -ellipsis"
                                                                        key={`sammendrag-summary-avtale-item-${i}`}
                                                                        data={d.data}
                                                                        // @ts-ignore
                                                                        click={(e?: MouseEvent) => {
                                                                            click(
                                                                                e,
                                                                                Constant.actionEndreSpareavtale,
                                                                                d.detail
                                                                            );
                                                                        }}
                                                                    />
                                                                )
                                                            )}
                                                        />
                                                        <div className="paragraph-extra-small -only-top">
                                                            <Link
                                                                data-track-id={Constant.trackIdMonthly}
                                                                // @ts-ignore
                                                                data-track-linkname={props.appTexts.get(
                                                                    'seAlleSpareavtaler',
                                                                    undefined,
                                                                    true
                                                                )}
                                                                href={`${window.location.href.replace(
                                                                    /\/+$/g,
                                                                    ''
                                                                )}/spareavtale`}
                                                                icon={IconTypes.BUET_PIL_HOEYRE_20}
                                                            >
                                                                {props.appTexts.get('seAlleSpareavtaler')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <Commercial onlyForDesktop type="saving">
                                        <StandardButton
                                            data-track-id={Constant.trackIdBigLinkCard}
                                            data-track-linkname="start-sparing"
                                            className="open-smartspart-btn"
                                            onClick={(e: MouseEvent) => {
                                                click(e, Constant.actionKjopSpareavtale);
                                            }}
                                        >
                                            Start sparing
                                        </StandardButton>
                                    </Commercial>

                                    <OtherServiceButtonListView
                                        kundeforhold={Bank?.kundeforhold}
                                        norneBankWithoutAgreement={reducer?.App?.norneBankWithoutAgreement}
                                        namespace="only-for-mobile"
                                    />
                                </>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    );
};
