import { get, post } from "@eika-infrastruktur/request";
import { isWebview, isOnTest, isMobile, isAndroid } from '../common/util/Functions';
import { getAppName } from '../common/util/BusinessFunctions';

export const IS_ON_WEBVIEW = 'IS_ON_WEBVIEW';
export const IS_ON_MOBILE = 'IS_ON_MOBILE';
export const IS_ON_ANDROID = 'IS_ON_ANDROID';
export const IS_ON_TEST_ENVIRONMENT = 'IS_ON_TEST_ENVIRONMENT';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_FOND_HANDEL_INITIALIZATION = 'SET_FOND_HANDEL_INITIALIZATION';
export const SET_SERVICE_MESSAGE = 'SET_SERVICE_MESSAGE';
export const SET_NORNE_URL = 'SET_NORNE_URL';

export const isOnWebview = () => async (dispatch) => {
    return dispatch({ type: IS_ON_WEBVIEW, data: isWebview() });
};

export const isOnMobile = () => async (dispatch) => {
    return dispatch({ type: IS_ON_MOBILE, data: isMobile() });
};

export const isOnAndroid = () => async (dispatch) => {
    return dispatch({ type: IS_ON_ANDROID, data: isAndroid() });
};

export const isOnTestEnvironment = () => async (dispatch) => {
    return dispatch({ type: IS_ON_TEST_ENVIRONMENT, data: isOnTest() });
};

export const setPageTitle = (text) => async (dispatch) => {
    return dispatch({ type: SET_PAGE_TITLE, data: text || '' });
};

export const setFondHandelInitialization = (data) => async (dispatch) => {
    return dispatch({ type: SET_FOND_HANDEL_INITIALIZATION, data });
};

/******************************************************************************
 ******************************************************************************/
export const postLog =
    (data = {}, detail = {}) =>
    async () => {
        if (!data || data instanceof Array || typeof data !== 'object') {
            data = {};
        }

        const header =
            !data.headers || typeof data.headers.get !== 'function'
                ? {}
                : [
                      { name: 'rcid', key: 'X-Eika-Requestcorrelationid' },
                      { name: 'bigip-bypass-error-handling', key: 'X-Bigip-Bypass-Errorhandling' },
                  ].reduce((pin, src) => {
                      pin[src.name] = data.headers.get(src.key);
                      return pin;
                  }, {});

        const { message } = data;
        const log = {
            stack: data.stack || detail.stack || 'none',
            message: JSON.stringify({
                text: message || '',
                ...header,
                ...detail,
                app: getAppName(),
                userAgent: navigator.userAgent,
                screen: window.location.href,
            }),
        };

        const url = '/sparing-min-side-web/rest/resource/log/clienterror';
        try {
            await post(url, log);
        } catch (error) {
            // post the error with postLog :P
        }
    };

/******************************************************************************
 ******************************************************************************/
export const getServiceMessage =
    (app = 'MOBILBANK') =>
    async (dispatch) => {
        let result = null;

        const url = `/sparing-min-side-web/rest/resource/messages/${app}`;
        try {
            result = await get(url)
                .catch(() => null);
        } catch (error) {
            result = null;
        }

        return dispatch({ type: SET_SERVICE_MESSAGE, data: result });
    };

export const setServiceMessage = (data) => async (dispatch) => {
    return dispatch({ type: SET_SERVICE_MESSAGE, data });
};

/******************************************************************************
 ******************************************************************************/
export const getNorneURL = () => async (dispatch) => {
    const url = '/sparing-min-side-web/rest/resource/norne/url';
    try {
        const response = await get(url);
        const data = await response;
        return dispatch({ type: SET_NORNE_URL, data });
    } catch (e) {
        dispatch(postLog(e, { api: url, function: 'getNorneURL - fetch-catch' }));
    }
};

/******************************************************************************
 ******************************************************************************/

const initialState = {
    isOnWebview: false,
    isOnTest: false,
    isOnMobile: false,
    isOnAndroid: false,
    pageTitle: '',
    fondHandelInitialization: null,
    serviceMessage: null,
    norneBankWithoutAgreement: undefined,
    norneDto: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case IS_ON_ANDROID:
            return { ...state, isOnAndroid: !!action.data };
        case IS_ON_MOBILE:
            return { ...state, isOnMobile: !!action.data };
        case IS_ON_WEBVIEW:
            return { ...state, isOnWebview: !!action.data };
        case IS_ON_TEST_ENVIRONMENT:
            return { ...state, isOnTest: !!action.data };
        case SET_PAGE_TITLE:
            return { ...state, pageTitle: action.data };
        case SET_FOND_HANDEL_INITIALIZATION:
            return { ...state, fondHandelInitialization: action.data };
        case SET_SERVICE_MESSAGE:
            return { ...state, serviceMessage: action.data };
        case SET_NORNE_URL:
            return { ...state, norneDto: action.data };
        default:
            return state;
    }
};
