import React, { Component } from 'react';
import PropTypes from 'prop-types';

require('../styles/dropdown.scss');

class NedTrekksMeny extends Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        disabled: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            options: this.generateOptionList(props.options),
            active: false,
            toggleClicked: false,
            currentOption: this.findDefaultValue(props.options, props.defaultValue)
        };

        window.addEventListener('click', this.onWindowClick);
    }

    findDefaultValue = (options, defaultValue) => {
        if (typeof options[0] === 'object') {
            return (options.find(option => option.id === defaultValue) || options[0]).tekst;
        }
        return options instanceof Array ? (options.find( (opt) => opt === defaultValue) || options[0]) : options[0];
    };

    generateOptionList = options => {
        return options.map(option => {
            if (typeof option === 'object') {
                return (
                    <li key={option.tekst}>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <button
                            onClick={() => {
                                this.onOptionClick(option.tekst, option.id);
                            }}
                        >
                            {option.tekst}
                        </button>
                    </li>
                );
            }
            return (
                <li key={option}>
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button
                        onClick={() => {
                            this.onOptionClick(option, option);
                        }}
                    >
                        {option}
                    </button>
                </li>
            );
        });
    };

    componentWillUnmount() {
        window.removeEventListener('click', this.onWindowClick);
    }

    onWindowClick = () => {
        if (!this.state.toggleClicked) {
            this.setState({ active: false });
        }

        this.setState({
            toggleClicked: false
        });
    };

    onToggleClick = event => {
        event.preventDefault();
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            active: !this.state.active,
            toggleClicked: true
        });
    };

    onOptionClick = (text, value) => {
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            active: !this.state.active,
            toggleClicked: true,
            currentOption: text
        });

        this.props.onChange(value, text);
    };

    render() {
        const style = this.state.active ? { display: 'block' } : { display: 'none' };
        const onToggleClick = this.props.disabled ? null : this.onToggleClick;
        const disabled = this.props.disabled ? 'true' : '';

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div className="input-group" onClick={onToggleClick}>
                {this.props.label ? (
                    <label className="label-text" htmlFor={this.props.id}>
                        {this.props.label}
                    </label>
                ) : (
                    ''
                )}
                <div className="dropdown primary">
                    <button
                        disabled={disabled}
                        className="btn dropdown-toggle override"
                        type="button"
                        id={this.props.id}
                    >
                        <span className="option">{this.state.currentOption}</span>
                        <span className="caret override">
                            <i className="ikon-liten-pil-ned" />
                        </span>
                    </button>
                    <ul className="dropdown-menu" style={style}>
                        {this.state.options}
                    </ul>
                </div>
            </div>
        );
    }
}

export default NedTrekksMeny;
