import React from 'react';
import PropTypes from 'prop-types';
import NedTrekksMeny from '../ned-trekks-meny';
import ValidationMessages from '../common/validation-messages';

const VelgKundeForhold = props => (
    <div className="kundeforhold">
        <NedTrekksMeny
            label={props.label}
            options={props.kundeforhold.map(k => ({ tekst: k.banknavn, id: k.bankregnr }))}
            onChange={props.onChange}
            id="kundeforhold"
            defaultValue={props.defaultValue}
        />
        <ValidationMessages
            showValidationMessages={props.showValidationMessages}
            validationMessages={props.validationMessages}
        />
    </div>
);

VelgKundeForhold.propTypes = {
    kundeforhold: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    validationMessages: PropTypes.arrayOf(PropTypes.object),
    showValidationMessages: PropTypes.bool
};

export default VelgKundeForhold;
