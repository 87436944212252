import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberInput from '../../common/number-input';
import HelperFunctions from '../../helper-functions';
import ValidationMessages from '../../common/validation-messages';

class SelgSparemaal extends Component {
    static propTypes = {
        sparemaal: PropTypes.array,
        selgHeleBeholdningen: PropTypes.bool,
        texts: PropTypes.object,
        sellValue: PropTypes.number,
        fond: PropTypes.object,
        visHjelpetekst: PropTypes.bool
    };

    constructor(props) {
        super(props);

        const sparemaal = props.sparemaal.filter(s => s.includedFunds[this.getUniqueId(props)]);

        this.state = {
            validation: {
                status: true
            },
            sparemaal,
            totalSparemaalWorth: this.getSparemaalTotalWorth(sparemaal, props),
            ordre: {
                ...this.setSparemaalValues(sparemaal)
            }
        };
    }

    UNSAFE_componentWillReceiveProps = newProps => {
        if (
            this.props.sellValue !== newProps.sellValue ||
            this.props.selgHeleBeholdningen !== newProps.selgHeleBeholdningen
        ) {
            this.setState({
                // eslint-disable-next-line react/no-access-state-in-setstate
                validation: this.setSparemaalStatus(this.state, newProps)
            });
        }
    };

    setSparemaalValues = sparemaal => {
        const values = {};

        // eslint-disable-next-line no-return-assign
        sparemaal.forEach(s => (values[s.id] = 0));

        return values;
    };

    getUniqueId = props => `${props.portefoljeId}-${props.fond.isin}`;

    getSparemaalFundWorth = (sparemaal, props) => sparemaal.includedFunds[this.getUniqueId(props)];

    getSparemaalTotalWorth = (sparemaal, props) =>
        sparemaal.reduce((acc, sparemaal) => this.getSparemaalFundWorth(sparemaal, props) + acc, 0);

    addValue = (event, maxValue) => {
        const value = HelperFunctions.clamp(parseInt(event.target.value, 10), 0, maxValue);

        let state = {
            ...this.state,
            ordre: {
                ...this.state.ordre,
                [event.target.id]: value
            }
        };

        state = {
            ...state,
            validation: this.setSparemaalStatus(state, this.props)
        };

        this.setState(state);
    };

    setSparemaalStatus = (state, props) => {
        const remainder = this.getRemainder(state, props.sellValue);

        let validation = { state: true, message: '' }; // Empty string needs to be there. Dont ask

        if (!props.selgHeleBeholdningen) {
            if (this.props.sellValue < this.totalSparemaalSellValue(state, state.sparemaal)) {
                validation = {
                    state: false,
                    message: this.props.texts.get('DenSamledeVerdienAvSparemaaleneKanIkkeVeareHoyereEnnTotalverdien')
                };
            } else if (remainder > 0) {
                validation = {
                    state: false,
                    message: this.props.texts.get('ForAaKunneTaUtSummenDuOnskerSaaMaaDuSelgeMerFraSparemaleneDine')
                };
            }
        }

        props.sendStatus(validation.state, state.ordre);

        return validation;
    };

    totalSparemaalSellValue = (state, sparemaal) =>
        sparemaal.reduce((acc, sparemaal) => state.ordre[sparemaal.id] + acc, 0);

    getRemainder = (state, sellValue) => {
        const unmarkedAmount = this.props.fond.totalVerdi - state.totalSparemaalWorth;
        const remainder = sellValue - unmarkedAmount - this.totalSparemaalSellValue(state, state.sparemaal);
        return Math.max(remainder, 0);
    };

    createInputFieldForSparemaal = sparemaal => {
        const value = this.getSparemaalFundWorth(sparemaal, this.props);

        return (
            <div key={sparemaal.id}>
                <NumberInput
                    label={sparemaal.name}
                    subLabel={`${this.props.texts.get('velgBelop')}: ${value}`}
                    onChange={event => this.addValue(event, value)}
                    id={sparemaal.id}
                    value={this.state.ordre[sparemaal.id]}
                    disabled={this.props.selgHeleBeholdningen}
                />
            </div>
        );
    };

    mapSparemaalToInputField = () => this.state.sparemaal.map(this.createInputFieldForSparemaal);

    render() {
        if (this.state.sparemaal.length) {
            return (
                <div className="fond-skjema-seksjon">
                    {this.mapSparemaalToInputField()}
                    <div>Gjenstående: {this.getRemainder(this.state, this.props.sellValue)}</div>
                    <ValidationMessages
                        showValidationMessages={this.props.visHjelpetekst}
                        validationMessages={[
                            {
                                condition: !this.state.validation.status,
                                message: this.state.validation.message
                            }
                        ]}
                    />
                </div>
            );
        }
        return <div />;
    }
}

export default SelgSparemaal;
