/**
 * Created by vagrant on 15.02.17.
 */

import React from 'react';
import PropTypes from 'prop-types';
import DonutChart from './donut-chart';
import Legend from '../legends/checkbox-legend';

const DonutChartWithCheckboxLegend = props => (
    <div>
        <DonutChart data={props.data} loading={props.loading}>
            {props.children}
        </DonutChart>

        <div className="avkryssningsboks-tekst">{props.legendLabel}</div>
        <div className="avkryssningsboks-label">{props.legendText}</div>

        <Legend
            data={props.data}
            toggleActive={props.toggleActive}
            onDelete={props.onDelete}
            changeContent={props.changeContent}
            config={props.config}
        />
    </div>
);

DonutChartWithCheckboxLegend.propTypes = {
    data: PropTypes.array.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    legendLabel: PropTypes.string,
    legendText: PropTypes.string,
    toggleActive: PropTypes.func,
    onDelete: PropTypes.func,
    changeContent: PropTypes.func,
    config: PropTypes.object,
    loading: PropTypes.bool
};

export default DonutChartWithCheckboxLegend;
