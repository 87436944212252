import React from "react";
import { Term } from "src/domain/Types";
import './consents.scss';

export const TermContext = React.createContext<Term[] | undefined>(
	undefined,
);

export const useTerms = () => {
	const context = React.useContext(TermContext);

	if (!context) {
		throw new Error("useTerms must be used within a TermProvider");
	}

	return context;
};
