// eslint-disable-next-line import/no-cycle
import { VIS_FEILMELDINGS_KVITTERING, VIS_KVITTERING, VIS_KVITTERING_LASTER } from './modals';

export const RAPPORTER_SERVERKALL_FEIL = 'RAPPORTER_SERVERKALL_FEIL';
export const VIS_OPPRETT_AKSJESPAREKONTO_MODAL = 'VIS_OPPRETT_AKSJESPAREKONTO_MODAL';
export const AUTENTISERINGS_SJEKK = 'AUTENTISERINGS_SJEKK';

const endpointUrl = '/sparing-min-side-web/rest/resource';

export const checkIfJson = (response) => {
    const contentType = response.headers.get('content-type');
    return !!(contentType && contentType.indexOf('application/json') !== -1);
};

export const utforOrdre = (data, url, method, onSuccess) => {
    const config = {
        credentials: 'same-origin',
        method,
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        dispatch({
            type: VIS_KVITTERING_LASTER,
        });

        fetch(url, config).then((data) => {
            if (data.ok) {
                if (checkIfJson(data)) {
                    data.json().then((data) => {
                        onSuccess(data, dispatch);
                    });
                } else {
                    dispatch({
                        type: VIS_KVITTERING,
                    });
                }
            } else {
                dispatch({
                    type: VIS_FEILMELDINGS_KVITTERING,
                    meldingFraServer: data.melding,
                });
            }
        });
    };
};

const ProcessError = (type, response, dispatch) => {
    const data = {
        type,
        errorCode: response.status,
        msg: response.message || response.statusText,
    };

    dispatch({
        type: RAPPORTER_SERVERKALL_FEIL,
        data,
    });
    return data;
};

export const logClientSideError = (message, stack) => {
    const config = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify({ message, stack }),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return () => {
        fetch(`${endpointUrl}/log/clienterror`, config);
    };
};

const ProcessEmptyReponse = (type, dispatch, additionalData) => {
    const data = additionalData ? { undefined, ...additionalData } : undefined;
    dispatch({
        type,
        data,
    });
    return data;
};

const ProcessValidJsonReponse = async (type, response, dispatch, additionalData) => {
    const jsonResponse = await response.json();
    const data = additionalData ? { data: jsonResponse, ...additionalData } : jsonResponse;
    dispatch({
        type,
        data,
    });
    return data;
};

export const ProcessJsonResponse = (type, response, dispatch, additionalData, forceEmptyReponseWhenNotOk) => {
    if (response.ok) {
        const statusCode = response.status;
        if (statusCode === 204) {
            return ProcessEmptyReponse(type, dispatch, additionalData);
        }
        return ProcessValidJsonReponse(type, response, dispatch, additionalData);
    } 

    if ( forceEmptyReponseWhenNotOk ) {        
        return ProcessEmptyReponse(type, dispatch, additionalData);
    }
    
    const error = ProcessError(type, response, dispatch);
    return { error };
};

export const isAuthenticated = (bankregnr, body) => {
    const url = `${endpointUrl}/authenticated`;

    return (dispatch) => {
        fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-BankRegNr': bankregnr,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then((data) => {
            if (data.status === 401 || data.ok) {
                data.json().then((data) => {
                    dispatch({
                        type: AUTENTISERINGS_SJEKK,
                        data: { ok: !data.anonym, kundeIdUrl: data.kundeidUrl },
                    });
                });
            } else {
                ProcessError(AUTENTISERINGS_SJEKK, data, dispatch);
            }
        });
    };
};
