import React from 'react';
import classNames from 'classnames';
import { ErrorLabel, SuccessLabel, InfoLabel, Label, WarningLabel } from '@eika/label';
import { InfoMessage, WarningMessage, SuccessMessage, ErrorMessage } from '@eika/attention-message';
import { IconTypes } from '@eika/icon';
import './Message.scss';

export interface Props {
    children?: JSX.Element | JSX.Element[] | boolean;
    icon?: boolean | IconTypes;
    title?: string | string[];
    titleType?: string;
    text?: string | string[];
    role?: string;
    type?: string;
    clickableItem?: any;
    namespace?: string;
    tag?: JSX.Element | JSX.Element[] | boolean | string;
    polite?: boolean;
    link?: any;
};

export default (props: Props): JSX.Element | null => {
    const { children, icon=false, title='', titleType='h2', text='', role='presentation', type='article', clickableItem, namespace, tag, polite} = props;
    const attr = polite ? { 'aria-live': 'polite'} : {};

    if ( type === 'error' && text ) {
        return <ErrorLabel icon={icon ? IconTypes.UTROPSTEGN_24 : undefined} text={text}/>
    }

    if ( type === 'info' && text ) {
        return <InfoLabel icon={icon ? IconTypes.INFO_24 : undefined} text={text}/>
    }

    if ( type === 'success' && text ) {
        // @ts-ignore
        return <SuccessLabel icon={icon ? IconTypes.SUCCESS_SMALL : undefined} text={text} />
    }

    if ( type === 'warning' && text ) {
        // @ts-ignore
        return <WarningLabel icon={icon ? IconTypes.UTROPSTEGN_24 : undefined} text={text}/>
    }

    if ( type === 'label' && text ) {
        return <Label icon={icon ? IconTypes.FORDELER_24 : undefined} text={text}/>
    }

    if ( type === 'InfoMessage' ) {
        // eslint-disable-next-line
        return <InfoMessage {...attr} text={text} icon={icon === false ? undefined : (typeof(icon) === 'boolean' ? IconTypes.INFO_24 : icon)} clickableItem={clickableItem}/>
    }

    if ( type === 'WarningMessage' ) {
        // eslint-disable-next-line
        return <WarningMessage {...attr} text={text} icon={icon === false ? undefined : (typeof(icon) === 'boolean' ? IconTypes.INFO_24 : icon)} clickableItem={clickableItem}/>
    }

    if ( type === 'SuccessMessage' ) {
        return <SuccessMessage {...attr} text={text} icon={icon === false ? undefined : IconTypes.CHECKMARK_LISTE_24} clickableItem={clickableItem}/>
    }

    if ( type === 'ErrorMessage' ) {
        // eslint-disable-next-line
        return <ErrorMessage {...attr} text={text} icon={icon === false ? undefined : (typeof(icon) === 'boolean' ? IconTypes.INFO_24 : icon)} clickableItem={clickableItem}/>
    }

    if ( type === 'AttentionMessage' ) {
        return <div className="message-wrapper -attention">
            { /* eslint-disable-next-line */ }
            <InfoMessage {...attr} text={text} icon={icon === false ? undefined : (typeof(icon) === 'boolean' ? IconTypes.INFO_24 : icon)} clickableItem={clickableItem}/>
        </div>
    }

    if ( !text && !children ) { return null; }

    const style = classNames('message-wrapper', `-${type}`, namespace);
    // @ts-ignore
    return <article {...attr} role={role} className={style} >
        <div className="message-content">
            { typeof(tag) === 'string' ? <div className="tag-item">{tag}</div> : tag }

            { !!title && <>
                { (title instanceof Array ? title : [title]).map( (t: string, i: number) => {
                    const key = `message-title-${i}`;
                    if ( titleType === 'h1' ) { return <h1 key={key} className="message-title">{t}</h1>; }
                    if ( titleType === 'h2' ) { return <h2 key={key} className="message-title">{t}</h2>; }
                    if ( titleType === 'h3' ) { return <h3 key={key} className="message-title">{t}</h3>; }
                    return <div key={key} className="message-title">{t}</div>;
                })}
            </>}

            { (text instanceof Array ? text : [text]).map( (t: string, i: number) => {
                // eslint-disable-next-line
                return t ? <p key={`message-text-${i}`} className="message-text lead">{t}</p> : null;
            }) }

            { children }
        </div>
    </article>;
};
