import React from 'react';
import classNames from 'classnames';
import './GrowMoney.scss';

interface Props { 
    on?: boolean;
    namespace?: string;
};

export default ({on, namespace}: Props): JSX.Element => {
    return <div aria-hidden="true" className={classNames('grow-money-wrapper', namespace, {'-active': !!on})}>
        <div className="flower-pot"/>
        <div className="flower-money"/>        
    </div>
};
