import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import { connect } from 'react-redux';

import OrdreKvittering from '../../components/fond/ordre-kvittering';
import { settSideTittel } from '../../actions/header';
import {
    fullforOrdre,
    resetOrdreProsess,
    KUNNE_IKKE_OPPRETTE_ORDRE,
    KUNNE_IKKE_OPPRETTE_SIGNERING,
    onFeiletSignering,
} from '../../actions/signerings-ordre';
import OpprettUttaksOrdre from '../../actions/uttak';
import { endreKundeInfo } from '../../actions/spareprofil';
import { settKvitteringsInnhold, lukkKvittering } from '../../actions/modals';
import SkattemessigKostpris from '../../components/fond/skattemessig-kostpris';

import { hentSkattemessigKostpris } from '../../actions/fond';

import { ASK } from '../../domain/portefolje-types';

import AppMessage from '../../components/AppMessage';
import Format from '../../components/format-functions';
import LinkCard from '../../components/link-card';
import Feilmelding from '../../components/feilmelding';
import UttaksSkjema from '../../components/fond/klient-konto-uttak/klient-konto-uttak-skjema';
import Signering from '../../components/common/signerings-iframe';
import ExpandableCard from '../../components/expandable-card';
import HelperFunctions from '../../components/helper-functions';
import * as kundevurderingConst from '../../domain/kundevurdering';
import KundevurderingStopp from '../../components/KundevurderingStopp';


const VIS_UTTAKS_SKJEMA = 'VIS_UTTAKS_SKJEMA';
const LASTER_SIGNERING = 'LASTER_SIGNERING';
const OPPRETTER_UTTAKS_ORDRE = 'OPPRETTER_UTTAKS_ORDRE';
const VIS_SIGNERING_IFRAME = 'VIS_SIGNERING_IFRAME';

require('../../styles/klient-konto.scss');

class KlientKonto extends Component {
    static propTypes = {
        portefoljeData: PropTypes.object,
        texts: PropTypes.object,
        params: PropTypes.object,
        settSideTittel: PropTypes.func,
        errors: PropTypes.object,
        endreKundeInfo: PropTypes.func,
        settKvitteringsInnhold: PropTypes.func,
        kvitteringConfig: PropTypes.object,
        kvitteringsInnhold: PropTypes.object,
        lukkKvittering: PropTypes.func,
        OpprettUttaksOrdre: PropTypes.func,
        kundeInfo: PropTypes.object,
        signeringsUrl: PropTypes.string,
        ordreId: PropTypes.string,
        fullforOrdre: PropTypes.func,
        onFeiletSignering: PropTypes.func,
        feilmelding: PropTypes.string,
        router: PropTypes.object,
        pollingIds: PropTypes.object,
        resetOrdreProsess: PropTypes.func,
        skattemessigKostpris: PropTypes.object,
        hentSkattemessigKostpris: PropTypes.func,
        kundevurdering: PropTypes.string,
    };

    constructor(props) {
        super(props);

        const standardValues = {
            uttak: 0,
            visHjelpetekst: false,
            kontonummer: undefined,
        };

        this.state = {
            expandedCard: {
                expanded: false,
                onExpand: this.onExpand,
            },
            ...standardValues,
            currentState: VIS_UTTAKS_SKJEMA,
        };
    }

    UNSAFE_componentWillMount = () => {
        if (this.props.portefoljeData) {
            const portefolje = this.hentPortefoljeBasertPaaId(
                this.props.portefoljeData,
                this.props.match.params.portefoljeId
            );
            this.setState({
                portefolje,
            });
        }
    };

    componentWillUnmount = () => {
        const pollingId = this.props.pollingIds[this.props.ordreId];
        if (pollingId) {
            clearTimeout(pollingId);
        }
    };

    UNSAFE_componentWillReceiveProps = (newProps) => {
        let newState = {};

        if (this.props.ordreId !== newProps.ordreId && newProps.ordreId) {
            newState = {
                currentState: LASTER_SIGNERING,
            };
        }

        if (this.props.signeringsUrl !== newProps.signeringsUrl && newProps.signeringsUrl) {
            newState = {
                currentState: VIS_SIGNERING_IFRAME,
            };
        }

        const portefolje = this.hentPortefoljeBasertPaaId(newProps.portefoljeData, newProps.match.params.portefoljeId);

        this.setState({
            portefolje,
            ...newState,
        });
    };

    hentPortefoljeBasertPaaId = (portefoljeData, portefoljeId) => {
        if (portefoljeData) {
            const portefolje = HelperFunctions.hentPortefoljeBasertPaaId(portefoljeId, portefoljeData.portefoljer);

            const clientAccountTitle = this.props.texts.get('tilknyttetbankKonto');
            const iPortefolje = HelperFunctions.VariableInjection(this.props.texts.get('iPortefolje'), {
                portefoljeNavn: portefolje.navn,
            });

            if ( this.props.sideTittel !== clientAccountTitle ){
                this.props.settSideTittel(clientAccountTitle, iPortefolje);
            }

            return portefolje;
        }

        return undefined;
    };

    onExpand = () => {
        const { expandedCard } = this.state;
        this.setState({
            expandedCard: {
                expanded: !expandedCard.expanded,
                onExpand: this.onExpand,
            },
        });
    };

    opprettUttaksOrdre = (data) => {
        this.setState({
            currentState: OPPRETTER_UTTAKS_ORDRE,
        });

        this.props.OpprettUttaksOrdre(data);
    };

    fullforOrdre = () => {
        const isSuccess = (data) => !!data.ordreResultater.length;
        this.props.fullforOrdre(this.props.ordreId, isSuccess);
        this.resetForm();
    };

    handleFeiletSignering = (code) => {
        this.props.onFeiletSignering(code);
        this.resetForm();
    };

    resetForm = () => {
        this.state.expandedCard.onExpand();
        this.setState({
            currentState: VIS_UTTAKS_SKJEMA,
        });

        this.props.resetOrdreProsess();
    };

    visFeilmelding = (feilmelding) => (
        <div>
            <div>{this.props.texts.get(feilmelding)}</div>
            <div className="expandable-card-footer">
                <button type="button" className="btn btn-flat" onClick={this.resetForm}>
                    {this.props.texts.get('lukk')}
                </button>
            </div>
        </div>
    );

    visExpandableCardContent = () => {
        if (this.state.currentState === OPPRETTER_UTTAKS_ORDRE) {
            if (this.props.feilmelding === KUNNE_IKKE_OPPRETTE_ORDRE) {
                return this.visFeilmelding(KUNNE_IKKE_OPPRETTE_ORDRE);
            }

            return <Loading title={this.props.texts.get('oppretterUttaksOrdre')} />;
        }

        if (this.state.currentState === LASTER_SIGNERING) {
            if (this.props.feilmelding === KUNNE_IKKE_OPPRETTE_SIGNERING) {
                return this.visFeilmelding(KUNNE_IKKE_OPPRETTE_SIGNERING);
            }

            return <Loading title={this.props.texts.get('lasterSignering')} />;
        }

        if (this.state.currentState === VIS_SIGNERING_IFRAME) {
            return (
                <Signering
                    signeringsUrl={this.props.signeringsUrl}
                    fullforSignertHandling={this.fullforOrdre}
                    onFeiletSignering={this.handleFeiletSignering}
                />
            );
        }

        if (this.state.currentState === VIS_UTTAKS_SKJEMA) {
            return (
                <UttaksSkjema
                    epost={this.props.kundeInfo.epost}
                    endreKundeInfo={this.props.endreKundeInfo}
                    kontoer={this.props.portefoljeData.kontoer}
                    opprettUttaksOrdre={this.opprettUttaksOrdre}
                    portefolje={this.state.portefolje}
                    texts={this.props.texts}
                    settKvitteringsInnhold={this.props.settKvitteringsInnhold}
                    router={this.props.router}
                    kundevurdering={this.props.kundevurdering}
                />
            );
        }

        return '';
    };

    sjekkForServerFeil = (errors) => errors.LAST_PORTEFOLJE;

    visKlientKontoHandlinger = (portefolje) => {
        if (portefolje.kontantBeholdning) {
            return (
                <div>
                    { this.props.kundevurdering !== kundevurderingConst.MA_BEHANDLES_MANUELT && portefolje.sperre !== 'FULL' && <div>
                        <LinkCard link={`kjopFond/portefolje/${portefolje.id}`}>
                            <div className="title med-ikon">
                                <span className="ikon-graflinje-36" />
                                {this.props.texts.get('kjopFond')}
                            </div>
                        </LinkCard>
                    </div> }

                    <ExpandableCard
                        header={this.props.texts.get('taUtPenger')}
                        expandItem={this.state.expandedCard}
                        ikon="ikon-betale-24"
                    >
                        {this.visExpandableCardContent()}
                    </ExpandableCard>
                </div>
            );
        }
        return null;
    };

    visSkattemessigKostpris = () => {
        if (this.state.portefolje.type === ASK) {
            return (
                <SkattemessigKostpris
                    infoTekst={this.props.texts.get('klientKontoDuKanTaUtInntil')}
                    feilTekst={this.props.texts.get('klientKontoDuKanTaUtInntilFeilet')}
                    portfolioBaseCode={this.state.portefolje.portfolioBaseCode}
                    skattemessigKostpris={this.props.skattemessigKostpris}
                    hentSkattemessigKostpris={this.props.hentSkattemessigKostpris}
                    bankregnr={this.state.portefolje.bank.bankregnr}
                />
            );
        }

        return null;
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.portefolje) {
            return (
                <div>
                    <AppMessage appMessage={this.props.serviceMessage} context="CLIENT_ACCOUNT" />

                    <OrdreKvittering
                        texts={this.props.texts}
                        kvitteringConfig={this.props.kvitteringConfig}
                        lukkKvittering={this.props.lukkKvittering}
                        kvitteringsInnhold={this.props.kvitteringsInnhold}
                    />

                    { !!this.state.portefolje?.sperre && <KundevurderingStopp 
                            texts={this.props.texts} 
                            title={null} 
                            text={this.props.texts.get(`${this.state.portefolje.sperre.toLowerCase()}SperretKonto`)} 
                            type="tag"
                            polite={false}
                        />
                    }

                    <div className="klient-konto">
                        <div className="saldo">
                            <span className="tekst">{this.props.texts.get('saldo')}</span>
                            <span className="verdi">{Format.currency(this.state.portefolje.kontantBeholdning)}</span>
                        </div>
                        {this.visKlientKontoHandlinger(this.state.portefolje)}
                    </div>
                    {this.visSkattemessigKostpris()}
                </div>
            );
        }

        return <Loading title={this.props.texts.get('lasterFondsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Fond,
            ...state.Modals,
            ...state.Feilmeldinger,
            ...state.SpareProfil,
            ...state.Ordre,
            ...state.Texts,
            ...state.Header,
            kundevurdering: state.KjennDinKunde?.kundevurdering,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel, underTittel) => {
                dispatch(settSideTittel(sideTittel, underTittel));
            },
            OpprettUttaksOrdre: (data) => {
                dispatch(OpprettUttaksOrdre(data));
            },
            lukkKvittering: () => {
                dispatch(lukkKvittering());
            },
            fullforOrdre: (ordreId, isSuccess) => {
                dispatch(fullforOrdre(ordreId, isSuccess));
            },
            onFeiletSignering: (errorCode) => {
                dispatch(onFeiletSignering(errorCode));
            },
            settKvitteringsInnhold: (innhold) => {
                dispatch(settKvitteringsInnhold(innhold));
            },
            endreKundeInfo: (endring) => {
                dispatch(endreKundeInfo(endring));
            },
            resetOrdreProsess: () => {
                dispatch(resetOrdreProsess());
            },
            hentSkattemessigKostpris: (portfolioBaseCode, bankregnr) => {
                dispatch(hentSkattemessigKostpris(portfolioBaseCode, bankregnr));
            },
        };
    }
)(KlientKonto);
