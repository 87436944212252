import React, { Component } from 'react';
import PropTypes from 'prop-types';

class IframeResizer extends Component {
    static propTypes = {
        id: PropTypes.string,
        src: PropTypes.string,
        title: PropTypes.string,
        className: PropTypes.string,
        minHeight: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {
            style: props.minHeight ? {
                height: `${props.minHeight}px`,
            } : {},
        };
    }

    render() {
        const { src, title='Sparing', id='sparing-iframe-resizer', className='sparing-iframe-resizer' } = this.props;
        const { style } = this.state;

        /* eslint-disable */
        return src ?
            <iframe 
                onLoad={(e) => { this.loadIframe(e); }} 
                id={id} 
                src={src} 
                title={title} 
                style={style} 
                className={`iframe ${className || 'normal'}`} 
                ref={ (ref) => { this.iframe = ref; }}
            ></iframe> : null;
        /* eslint-enable */
    }

    loadIframe = () => {
        if ( !this.iframe ) { return; }

        let doc = null;
        const content = this.iframe.contentDocument || this.iframe.contentWindow;
        try {
            doc = content.document || content;
        } catch ( error ){
            doc = null;
        }

        if ( !doc ) { return; }

        const height = doc.body.scrollHeight;
        if ( !height ) { return; }

        this.setState({style: { height: `${height + 20}px`}});
    }
}

export default IframeResizer;