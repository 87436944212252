import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Spinner } from '../common/share/ShareComponents';
import KjennDinKunde from '../components/KjennDinKunde/KjennDinKunde';
import { shallShowAHV, isEikaDomain, getAppName } from '../common/util/BusinessFunctions';

import Message from '../common/Message/Message';
import Grid from '../common/Grid/Grid';
import { Term } from '../domain/Types';

import '../styles/utils.scss';
import './App.scss';
import Consents from '../components/Consents/consents';

interface Props {
    children: any;
    action: any;
    appTexts: any;
}


export default (props: Props): JSX.Element => {
    const [hasConsented, setHasConsented] = useState(false);
    const { appTexts, children, action } = props;
    const reducer = useSelector((state: any) => {
        return ['App', 'SpareProfil', 'Fond', 'KjennDinKunde'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const { portefoljeData } = reducer.Fond || {};
    const { isOnTest, isOnWebview, isOnMobile } = reducer.App || {};
    const { kundeInfo, samtykker } = reducer.SpareProfil || {};
    // @ts-ignore - redux is not typed, might be why it's super hacky above here
    const terms: Term[] = useSelector((state) => state.SpareProfil.terms);
    const guideline = /\/(guideline|alternative|fundoverview)/i.test(window.location.href);

    const handleSubmit = (props: Props, terms: Term[]): void => {
        const acceptedTerms = terms.map((term: Term) => {
            return {
                ...term,
                value: true,
            };
        });
        props.action.submitAllTerms(acceptedTerms);
        setHasConsented(true);
    };

    useEffect(() => {
        action.setPageTitle(appTexts.get('appLaster'));
        if ( guideline ) { return; }

        const appName = getAppName();
        action.isOnAndroid();
        action.isOnTestEnvironment();
        action.isOnWebview();
        action.isOnMobile();
        action.getServiceMessage( appName );
        action.getTerms( appName );
        action.getNorneURL();
        action.hentKundeForhold();

        action.getVPS();
        action.hentKundeValg();

        action.getDebitAccount();
        action.getPortefolje();

        action.lastSpareProfil();
        action.lastMorningstarData();
        action.lastSamtykke();
        action.getKundevurdering();
        action.getOppfolgingSkjemaForKunde();        
        action.getListeOverCRSLand();
        action.getSkjemaForKunde();
        action.lastarbeidsgiversPensjonssparing();
        action.getStorebrandProfilesEnabled();
        // action.getEPKsigningConfig();

        /* Font-scale testing */
        // window.document.documentElement.style.fontSize = '48px';
        // window.document.documentElement.classList.add('font-scale-large');
    }, [action, appTexts, guideline, hasConsented]);

    if (!guideline && (!kundeInfo || !samtykker || !terms) ) {
        return <div className='sgw-content sparing-react-app -app-spinner-start-up'>
            <div className='content-max-width app-spinner-holder'>
                <Spinner type='app' />
            </div>
        </div>;
    }

    if (!guideline && kundeInfo && typeof(kundeInfo.alder) !== 'number' ) {
        return <div id='sparing-react-app'>
            <Grid list={[{
                layout: 'twelve',
                element: <Message text={appTexts.get('ops')} type='error-server' polite/>
            }]}/>
        </div>
    }

    if (!guideline && kundeInfo && kundeInfo.alder < 18) {
        return <div id='sparing-react-app'>
            <Grid list={[{
                layout: 'twelve',
                element: <Message text={appTexts.get('under18aar')} type='error-server' polite/>
            }]}/>
        </div>
    }

    if (terms.length !== 0 && !hasConsented) {
		return (
			<div id='sparing-react-app'>
				<Consents terms={terms} submitTerms={() => handleSubmit(props, terms)}>
					<Consents.GeneralTerms />
					<Consents.GDPR/>
					<Consents.FinanceLaw />
				</Consents>
			</div>
		);
    } 

    if (!guideline && shallShowAHV(reducer.KjennDinKunde, portefoljeData) ) {
        return <div className='sgw-content'>
            <div className='content-max-width'>
                <KjennDinKunde {...props} appTexts={appTexts} />
            </div>
        </div>
    }
    
    return <div id='sparing-react-app' className={classNames('sparing-react-app', {
        '-is-on-test': !!isOnTest,
        '-is-on-webview': !!isOnWebview || /localhost/i.test(window.location.href),
        '-is-on-mobile': !!isOnMobile,
        '-is-eika-domain': isEikaDomain(),
    })}>
        {children}
    </div>;
};
