import { get, post, put } from "@eika-infrastruktur/request";
// eslint-disable-next-line import/no-cycle
import {processError, processResponse} from './common';
import { LAST_PORTEFOLJE } from './fond';

export const VIS_KVITTERING = 'VIS_KVITTERING';
export const VIS_KVITTERING_LASTER = 'VIS_KVITTERING_LASTER';
export const LUKK_KVITTERING = 'LUKK_KVITTERING';
export const SETT_KVITTERINGS_INNHOLD = 'SETT_KVITTERINGS_INNHOLD';
export const VIS_FEILMELDINGS_KVITTERING = 'VIS_FEILMELDINGS_KVITTERING';
export const SKJUL_OPPRETT_AKSJESPAREKONTO_MODAL = 'SKJUL_OPPRETT_AKSJESPAREKONTO_MODAL';
export const SKJUL_AKSJESPAREKONTO_POPUP = 'SKJUL_AKSJESPAREKONTO_POPUP';
export const SKJUL_ENDRE_OM_MEG_MODAL = 'SKJUL_ENDRE_OM_MEG_MODAL';
export const VIS_SMARTSPAR_POPUP = 'VIS_SMARTSPAR_POPUP';
export const SKJUL_SMARTSPAR_POPUP = 'SKJUL_SMARTSPAR_POPUP';

const baseUrlFond = '/sparing-min-side-web/rest/resource';

const skjulOpprettAksjesparekontoModalUrl = `${baseUrlFond}/fond/registrerAskMeldingKlikk`;
const smartsparPopupUrl = `${baseUrlFond}/smartspar/popup`; // TODO finn riktig Url

export const settKvitteringsInnhold = innhold => {
    return {
        type: SETT_KVITTERINGS_INNHOLD,
        innhold
    };
};

export const skjulEndreOmMegModal = () => {
    return dispatch => {
        dispatch({
            type: SKJUL_ENDRE_OM_MEG_MODAL
        });
    };
};

export const lukkKvittering = () => {
    return dispatch => {
        dispatch({
            type: LUKK_KVITTERING
        });
    };
};

export const skjulOpprettAksjesparekontoModal = () => {
    return dispatch => {
        put(skjulOpprettAksjesparekontoModalUrl, {}).then(() => {
            dispatch({
                type: SKJUL_OPPRETT_AKSJESPAREKONTO_MODAL
            });
        });
    };
};

export const skjulAksjesparekontoPopup = () => {
    return dispatch => {
        put(skjulOpprettAksjesparekontoModalUrl, {}).then(() => {
            dispatch({
                type: SKJUL_AKSJESPAREKONTO_POPUP
            });
        });
    };
};

export const hentVisSmartSparPopup = () => {
    return dispatch => {
        get(smartsparPopupUrl)
            .then(data => processResponse(VIS_SMARTSPAR_POPUP, data, dispatch))
            .catch((error) => processError(VIS_SMARTSPAR_POPUP, error, dispatch));
    };
};

export const registrerSmartSparPopupVisning = () => {
    return () => {
        post(smartsparPopupUrl, {});
    };
};

export const skjulSmartSparPopup = () => dispatch =>
    dispatch({
        type: SKJUL_SMARTSPAR_POPUP
    });



const initialState = {
    kvitteringConfig: {
        vis: false,
        laster: false
    },
    visOpprettAksjesparekontoModal: true,
    visEndreOmMegModal: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LAST_PORTEFOLJE:
            return {
                ...state,
                visOpprettAksjesparekontoModal: !!(action.data || {}).visOpprettAksjesparekontoModal
            };
        case SKJUL_OPPRETT_AKSJESPAREKONTO_MODAL:
            return {
                ...state,
                visOpprettAksjesparekontoModal: false
            };
        case SKJUL_ENDRE_OM_MEG_MODAL:
            return {
                ...state,
                visEndreOmMegModal: false
            };
        case VIS_KVITTERING:
            return {
                ...state,
                kvitteringConfig: {
                    vis: true,
                    laster: false,
                    avtalegiroUrl: action.avtalegiroUrl,
                    avtalegiroObject: action.avtalegiroObject,
                    skjulLukkKnapp: !!action.avtalegiroObject
                },
                kvitteringsInnhold: {
                    ...state.kvitteringsInnhold,
                    ordreResultater: action.ordreResultater
                }
            };
        case VIS_FEILMELDINGS_KVITTERING:
            return {
                ...state,
                kvitteringConfig: {
                    vis: true,
                    laster: false
                },
                kvitteringsInnhold: {
                    type: 'feilmelding',
                    melding: action.meldingFraServer,
                    serverData: action.serverData,
                }
            };

        case SETT_KVITTERINGS_INNHOLD:
            return {
                ...state,
                kvitteringsInnhold: action.innhold
            };
        case LUKK_KVITTERING:
            window.location.reload();
            return {
                ...state,
                kvitteringConfig: {
                    vis: false,
                    laster: false
                }
            };
        case VIS_KVITTERING_LASTER:
            return {
                ...state,
                kvitteringConfig: {
                    vis: true,
                    laster: true
                }
            };
        default:
            return state;
    }
};
