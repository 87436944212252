// @ts-nocheck // kiet fix it later
import { generateId, sortList } from '../../Functions';

const getTrianglePointList = (center, radius, note) => {
    return [
        [note.left, note.bottom].join(','),
        [center[0], note.top].join(','),
        [note.right, note.bottom].join(','),
        [note.left, note.bottom].join(',')
    ];
};

const getTriangleDownPointList = (center, radius, note) => {
    return [
        [note.left, note.top].join(','),
        [center[0], note.bottom].join(','),
        [note.right, note.top].join(','),
        [note.left, note.top].join(',')
    ];
};

const getTriangleLeftPointList = (center, radius, note) => {
    return [
        [note.left, note.bottom].join(','),
        [note.left, note.top].join(','),
        [note.right, note.bottom].join(','),
        [note.left, note.bottom].join(',')
    ];
};

const getTriangleRightPointList = (center, radius, note) => {
    return [
        [note.left, note.bottom].join(','),
        [note.right, note.top].join(','),
        [note.right, note.bottom].join(','),
        [note.left, note.bottom].join(',')
    ];
};

const getSquarePointList = (center, radius, note) => {
    return [
        [note.left, note.bottom].join(','),
        [note.left, note.top].join(','),
        [note.right, note.top].join(','),
        [note.right, note.bottom].join(','),
        [note.left, note.bottom].join(',')
    ];
};

const getSquareSingleCrossPointList = (center, radius, note) => {
    return [
        [note.left, note.bottom].join(','),
        [note.left, note.top].join(','),
        [note.right, note.top].join(','),
        [note.right, note.bottom].join(','),
        [note.left, note.bottom].join(','),
        [note.right, note.top].join(',')
    ];
};

const getSquareDoubleCrossPointList = (center, radius, note) => {
    return [
        [note.left, note.bottom].join(','),
        [note.left, note.top].join(','),
        [note.right, note.top].join(','),
        [note.right, note.bottom].join(','),
        [note.left, note.bottom].join(','),
        [note.right, note.top].join(','),
        [note.left, note.top].join(','),
        [note.right, note.bottom].join(',')
    ];
};

export const getPolarToCartesian = ({ x = 0, y = 0, radius = 0, angle = 0 }) => {
    const angleInRadians = (angle - 90) * (Math.PI / 180.0);
    return {
        x: x + (radius * Math.cos(angleInRadians)),
        y: y + (radius * Math.sin(angleInRadians))
    };
};

export const getCirclePath = ({ x = 0, y = 0, radius = 0, startAngle = 0, endAngle = 0 }) => {
    if (endAngle >= 360) { endAngle = 359.999; }

    const start = getPolarToCartesian({ x, y, radius, angle: endAngle });
    const end = getPolarToCartesian({ x, y, radius, angle: startAngle });
    const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
    return [
        'M', start.x, start.y,
        'A', radius, radius, 0, arcSweep, 0, end.x, end.y
    ].join(' ');
};


export const getChartText = ({
    id = '', type = 'text', x = 0, y = 0, size = '', weight = '', text = '', color = '', textAnchor = '', baseline = '', ...rest
}) => {
    const prefix = `chart-${Math.floor((Math.random() * 100) + 1)}`;
    const fontSize = parseInt(`${size || 16}` , 10);
    const xPosition = parseInt(`${x || 0}` , 10);
    const yPosition = parseInt(`${y || 0}` , 10) + (rest.adjustAfterFontsize ? (fontSize / 3) : 0);

    return {
        id: id || generateId(prefix),
        type,
        text,
        x: xPosition,
        y: yPosition,
        textAnchor: textAnchor || 'middle',
        dominantBaseline: baseline || 'central',
        classname: 'chart-text',
        style: {
            fill: color || '#444',
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: weight || 'normal',
            fontSize,
        },
        ...rest
    };
};

export const createSymbolPath = ({ center = [], radius = 0, symbol = '' }) => {
    if (isNaN(center[0]) || isNaN(center[1]) || !radius) { return ''; }

    let pointList = [];
    const note = {
        top: center[1] - radius,
        bottom: center[1] + radius,
        left: center[0] - radius,
        right: center[0] + radius
    };

    if (symbol === 'triangle') {
        pointList = getTrianglePointList(center, radius, note);
    } else if (symbol === 'triangle-down') {
        pointList = getTriangleDownPointList(center, radius, note);
    } else if (symbol === 'triangle-left') {
        pointList = getTriangleLeftPointList(center, radius, note);
    } else if (symbol === 'triangle-right') {
        pointList = getTriangleRightPointList(center, radius, note);
    } else if (symbol === 'square') {
        pointList = getSquarePointList(center, radius, note);
    } else if (symbol === 'square-single-cross') {
        pointList = getSquareSingleCrossPointList(center, radius, note);
    } else if (symbol === 'square-double-cross' || symbol === 'square-cross') {
        pointList = getSquareDoubleCrossPointList(center, radius, note);
    } else {
        return getCirclePath({
            x: center[0], y: center[1], radius, startAngle: 0, endAngle: 360
        });
    }

    return pointList.length > 1 ? `M ${pointList.join(' L ')}` : '';
};


/** **************************************************************************
****************************************************************************/
export const getPositionLeftPercentByXpx = (cx, state) => {
    const { axis = {}, padding = {}, view=[0,0] } = state;
    if (!cx || !(axis.x || {}).max) { return 0; }

    const { left = 0, right = 0 } = padding;
    const width = axis.x.max + left + right;
    const diff = view[0] ? (view[0] - width) : 0;
    const v = (cx / (width + diff)) * 100;
    return Math.round(v);
};

export const getPositionTopPercentByYpx = (cy, state) => {
    const { axis = {}, padding = {}, view=[0,0] } = state;
    if (!cy || !(axis.y || {}).max) { return 0; }

    const { top = 0, bottom = 0 } = padding;
    const height = axis.y.max + top + bottom;
    const diff = view[1] ? (view[1] - height) : 0;
    const v = (cy / (height + diff)) * 100;
    return Math.round(v);
};

export const getXaxisTitle = (index, state) => {
    const { axis } = state;
    if ( isNaN(index) || !axis || !axis.x  || !axis.x.text  || !axis.x.text[index]) { return ''; }
    return `${axis.x.text[index].title || axis.x.text[index].text}`;
};

export const getXaxisElementInterval = (interval, state) => {
    const { axis, padding = {} } = state;
    if (!axis || !axis.x) { return null; }

    const textList = axis.x.textList || [];
    if (!textList.length) { return null; }

    textList.forEach((d) => {
        d.leftPositionPercent = getPositionLeftPercentByXpx(d.x, state);
    });

    let list = interval.map((index) => {
        const cx = (textList[index] || {}).x;
        return cx ? {
            id: generateId('x-axis-interval-p'),
            index,
            classname: 'chart-x-axis-selection',
            text: getXaxisTitle(index, state),
            style: {
                left: `${getPositionLeftPercentByXpx(cx, state)}%`,
                top: `${getPositionTopPercentByYpx(padding.top, state)}%`
            }
        } : null;
    }).filter(d => !!d);

    if (list.length < 2) { return null; }
    list = sortList(list, 'index', null, true);
    list.push({
        id: generateId('x-axis-interval-w'),
        classname: 'chart-x-axis-interval',
        style: {
            top: `${getPositionTopPercentByYpx(padding.top, state)}%`,
            bottom: `${getPositionTopPercentByYpx(padding.bottom, state)}%`,
            left: list[0].style.left,
            right: `${100 - parseInt(list[1].style.left, 10)}%`
        }
    });

    return list;
};
