import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FondsInformasjon from './fond/fonds-informasjon';

require('../styles/dropdown.scss');

class NedTrekksMenyFond extends Component {
    static propTypes = {
        texts: PropTypes.object,
        fond: PropTypes.array,
        onChange: PropTypes.func,
        id: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            options: this.generateOptionList(props.fond),
            active: false,
            toggleClicked: false
        };

        window.addEventListener('click', this.onWindowClick);
    }

    generateOptionList = fondsListe => {
        return fondsListe.map(fond => {
            return (
                <li key={fond.isin} className="dropdown-liste-item">
                    <button
                        type="button"
                        onClick={() => {
                            this.onOptionClick(fond.navn, fond.isin);
                        }}
                    >
                        <FondsInformasjon fond={fond} texts={this.props.texts} />
                    </button>
                </li>
            );
        });
    };

    UNSAFE_componentWillMount = () => {
        this.setDefaultValue(this.props.texts.get('velgFond'));
    };

    componentWillUnmount() {
        window.removeEventListener('click', this.onWindowClick);
    }

    onWindowClick = () => {
        if (!this.state.toggleClicked) {
            this.setState({ active: false });
        }

        this.setState({
            toggleClicked: false
        });
    };

    onToggleClick = event => {
        event.preventDefault();
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            active: !this.state.active,
            toggleClicked: true
        });
    };

    setDefaultValue = text => {
        this.setState({
            currentOption: text
        });
    };

    onOptionClick = (text, value) => {
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            active: !this.state.active,
            toggleClicked: true,
            currentOption: text
        });

        this.props.onChange(value, text);
    };

    render() {
        const style = this.state.active ? { display: 'block' } : { display: 'none' };
        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div className="input-group" onClick={this.onToggleClick}>
                <div className="dropdown primary">
                    <button className="btn dropdown-toggle override" type="button" id={this.props.id}>
                        <span className="option">{this.state.currentOption}</span>
                        <span className="caret override">
                            <i className="ikon-liten-pil-ned" />
                        </span>
                    </button>
                    <ul className="dropdown-menu" style={style}>
                        {this.state.options}
                    </ul>
                </div>
            </div>
        );
    }
}

export default NedTrekksMenyFond;
