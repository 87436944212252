import { IconTypes } from '@eika/icon';
import { Link } from '@eika/link';
import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '../../../common/Grid/Grid';
import RadioOption from '../../../common/RadioOption/RadioOption';
import { FooterButton, Spinner } from '../../../common/share/ShareComponents';
import TabList from '../../../common/TabList/TabList';
import { formatAmount } from '../../../common/util/Functions';
import Constant from '../../../static/data/Constant';

import FundOverview from '../../../common/FundOverview/FundOverview';
import useMutualFundStore from '../../../stores/MutualFundStore';

interface FondHandelVelgFondProps {
    click: (e: any, k: any, d?: any, f?: any) => void;
    fundPanelView: any;
    orderStorage: any;
    fundList: any;
    appTexts: any;
    fundSuggestionUrl?: string;
    error?: any;
}

const FondHandelVelgFond = ({
    click,
    fundSuggestionUrl,
    fundPanelView,
    orderStorage,
    fundList,
    error,
    ...rest
}: FondHandelVelgFondProps): JSX.Element => {
    const { setCurrentFund, setEntry } = useMutualFundStore();
    const { search } = useLocation();
    return fundList ? (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: (
                            <>
                                {orderStorage.action === 'bytt-fond' ? (
                                    <h1 className="paragraph">Hvilket fond vil du bytte til?</h1>
                                ) : (
                                    <h1>Velg fond</h1>
                                )}

                                {!!fundPanelView && (
                                    <div className="max-width-400 -center paragraph">
                                        <TabList
                                            list={fundPanelView.list}
                                            click={(e: any, k = '', d: any) => {
                                                click(e, k, d);
                                            }}
                                            current={fundPanelView.selected}
                                            action="change-fund-panel-view"
                                        />
                                    </div>
                                )}

                                {(!fundPanelView || fundPanelView.selected === 'other-fund') &&
                                    orderStorage.action !== 'bytt-fond' &&
                                    !orderStorage.clientAccount &&
                                    fundSuggestionUrl && (
                                        <div
                                            className={classNames('paragraph center', {
                                                '-include-top': orderStorage.action !== 'bytt-fond' && !fundPanelView,
                                            })}
                                        >
                                            <Link href={fundSuggestionUrl} icon={IconTypes.LYSPAERE_24}>
                                                Få hjelp til å velge type fond
                                            </Link>
                                        </div>
                                    )}
                            </>
                        ),
                    },
                ]}
            />

            {(!fundPanelView || fundPanelView.selected === 'other-fund') && (
                <Grid
                    namespace="sparing-content"
                    list={[
                        {
                            layout: 'twelve',
                            element: (
                                <FundOverview
                                    {...rest}
                                    fundList={fundList}
                                    displayRightArrow
                                    selectCallback={(fund) => {
                                        setCurrentFund(fund.fund);
                                        click(null, 'fund-selected', fund);
                                    }}
                                    monthly={orderStorage.type === Constant.typeManedligSparing}
                                    onetime={orderStorage.type === Constant.typeEngangsinnkudd}
                                    filter={orderStorage.fundFilter}
                                />
                            ),
                        },
                    ]}
                />
            )}

            {fundPanelView?.selected === 'my-fund' && (
                <Grid
                    namespace="sparing-content"
                    list={[
                        {
                            layout: 'six',
                            element: (
                                <div className="my-fund-wapper">
                                    <RadioOption
                                        name="myFund"
                                        selected={orderStorage.setMyFund?.value}
                                        change={(e, k, d) => {
                                            setCurrentFund(d);
                                            setEntry('FUND');
                                            click(e, 'set-my-fund', d);
                                        }}
                                        legend="Velg et fond du har fra før"
                                        error={error?.setMyFund}
                                        required
                                        list={fundPanelView.myFundList.map((d: any) => {
                                            if (d.isIPS) {
                                                delete d.disabled;
                                                if (
                                                    d.sparing &&
                                                    d.sparing.maksbelop &&
                                                    d.sparing.aarligSparebelop &&
                                                    d.sparing.maksbelop >= d.sparing.aarligSparebelop
                                                ) {
                                                    d.disabled = `Summen har nådd maksbeløp ${formatAmount(
                                                        d.sparing.maksbelop
                                                    )} kr per år`;
                                                } else if (
                                                    orderStorage.type === Constant.typeManedligSparing &&
                                                    d.hasSpareavtale
                                                ) {
                                                    d.disabled =
                                                        'Kontoen kan ikke velges fordi det kan bare være en spareavtale i en IPS-konto.';
                                                }
                                            }
                                            if (search?.includes('onetime') && !d.allowedTradeTypes.includes('BUY')) {
                                                d.disabled = 'Ikke tilgjengelig for engangskjøp';
                                            }
                                            if (
                                                search?.includes('monthly') &&
                                                !d.allowedTradeTypes.includes('PERIODIC')
                                            ) {
                                                d.disabled = 'Ikke tilgjengelig for månedlig sparing';
                                            }
                                            return d;
                                        })}
                                    />

                                    <div className="paragraph-large">
                                        <FooterButton click={click} cancel="Avbryt" action="use-my-fund" />
                                    </div>
                                </div>
                            ),
                        },
                    ]}
                />
            )}
        </>
    ) : (
        <Spinner />
    );
};

export default FondHandelVelgFond;
