import { ProcessJsonResponse } from './common';

export const LAST_SPARE_PROFIL_DATA = 'LAST_SPARE_PROFIL_DATA';
export const ENDRE_SPARE_PROFIL = 'ENDRE_SPARE_PROFIL';
export const SET_TERMS = 'SET_TERMS';
export const LAST_SAMTYKKE = 'LAST_SAMTYKKE';
export const LAST_SAMTYKKE_VALG = 'LAST_SAMTYKKE_VALG';
export const HENT_KUNDE_VALG = 'HENT_KUNDE_VALG';
export const ENDRE_KUNDE_VALG = 'ENDRE_KUNDE_VALG';

const spareUrl = '/sparing-min-side-web/rest/resource/spare';
const spareProfilUrl = `${spareUrl}/spareprofil`;

const samtykkeUrl = '/sparing-min-side-web/rest/resource/samtykke';
const hentSamtykkerUrl = `${samtykkeUrl}/samtykker`;
const endreSamtykkeUrl = `${samtykkeUrl}/kundesamtykke`;
const kundeValgUrl = '/sparing-min-side-web/rest/resource/kunde/kundevalg';
const endreSamtykkeTypeUrl = `${samtykkeUrl}/kundesamtykke/type`;

export const lastSpareProfil = () => (dispatch) =>
    fetch(spareProfilUrl, { credentials: 'same-origin' }).then((data) =>
        ProcessJsonResponse(LAST_SPARE_PROFIL_DATA, data, dispatch)
    );

export const hentKundeValg = () => (dispatch) =>
    fetch(kundeValgUrl, { credentials: 'same-origin' }).then((data) =>
        ProcessJsonResponse(HENT_KUNDE_VALG, data, dispatch)
    );

export const lastSamtykke = () => (dispatch) =>
    fetch(hentSamtykkerUrl, { credentials: 'same-origin' }).then((data) =>
        ProcessJsonResponse(LAST_SAMTYKKE, data, dispatch)
    );

export const endreSpareProfil = (endring) => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(endring),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) =>
        fetch(spareProfilUrl, config).then((data) => ProcessJsonResponse(LAST_SPARE_PROFIL_DATA, data, dispatch));
};

export const endreKundeValg = (valg) => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(valg),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        fetch(kundeValgUrl, config);

        dispatch({
            type: ENDRE_KUNDE_VALG,
            valg,
        });
    };
};

export const endrePensjonsValg = (endring) => endreSpareProfil(endring, 'pensjonsvalg');

export const endreKundeInfo = (endring) => endreSpareProfil(endring, 'kundeInfo');

export const endreSamtykke = (id, endring) => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(endring),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return () => fetch(`${endreSamtykkeUrl}/${id}`, config);
};

export const endreSamtykkeType = (samtykke) => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(samtykke),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) =>
        fetch(endreSamtykkeTypeUrl, config).then((data) => ProcessJsonResponse(LAST_SAMTYKKE_VALG, data, dispatch));
};

export const endreAlleSamtykker = () => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
        ContentType: 'application/json',
        body: JSON.stringify(true),
    };

    return (dispatch) =>
        fetch(endreSamtykkeUrl, config)
            .then((data) => ProcessJsonResponse(LAST_SAMTYKKE_VALG, data, dispatch));
};

/******************************************************************************
******************************************************************************/
export const getTerms = (application='MSS') => async (dispatch) => {
    let result = [];

    const url = `/sparing-min-side-web/rest/resource/mandatory-consents/tasks?app=${application}`;

    try {
        result = await fetch( url ).then( (response) => {
            if ( response.status !== 200 ) { return []; }
            return response.json() || [];
        }).catch( () => [] );
    } catch ( error ) {
        result = [];
    }
    
    result.forEach( (d) => { d.originSparingApplication = application; });
    return dispatch({ type: SET_TERMS, result });
};

export const postTerms = ( terms,  term ) => async (dispatch) => {
    let result = false;
    const config = {
        credentials: 'same-origin',
        method: 'POST',
        ContentType: 'application/json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify([term]),
    };

    let cloned = JSON.parse(JSON.stringify(terms));
    let found = cloned.find( (d) => d.consentCode === term.consentCode ) || {};
    found.done = null;

    dispatch({ type: SET_TERMS, result: cloned });
    const url = '/sparing-min-side-web/rest/resource/user-consents';
    try {
        result = await fetch( url, config).then( (response) => {
            return response.status === 200 || response.status === 204;
        }).catch( () => false );
    } catch ( error ) {
        result = false;
    }

    cloned = JSON.parse(JSON.stringify(terms));
    found = cloned.find( (d) => d.consentCode === term.consentCode ) || {};
    found.done = result;
    return dispatch({ type: SET_TERMS, result: cloned });
};

