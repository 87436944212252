import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from '../../common/share/ShareComponents';
import Message from '../../common/Message/Message';
import FundSuggestion, { FundSuggestionHelperCard } from '../../common/FundSuggestion/FundSuggestion';
import { createCookie, eraseCookie, readCookie } from '../../common/util/Functions';

interface Props {
    action: any;
    appTexts: any;
}

// cookie name for keeping submitted form state between rerenders (not used externally)
const keyId = 'fs-open-page';

export default (props: Props): JSX.Element => {
    const { action } = props;
    const { morningstar } = useSelector((state: any) => state?.Fond ?? {});

    // cookie for form state storage
    const persistentFormState = useMemo(() => {
        const id = readCookie(keyId);
        eraseCookie(keyId);
        return id;
    }, []);

    useEffect(() => {
        if (morningstar !== undefined) {
            return;
        }
        action.getFondList();
    }, [action, morningstar]);

    return (
        <div className="buy-fund-wrapper -fund-guideline">
            <FundSuggestionHelperCard />
            {morningstar === undefined && <Spinner />}
            {morningstar === null && (
                <Message type="error-server" text="Ops! Noe gikk galt. Vennligst prøv igjen senerer." />
            )}

            {persistentFormState !== undefined && !!morningstar && (
                <FundSuggestion
                    {...props}
                    formState={persistentFormState}
                    fundList={morningstar}
                    // callback for keeping state of form on getting back with navigation
                    suggestedCallback={({ isin, formState }) => {
                        if (formState) {
                            createCookie(keyId, formState, 0.1);
                        }
                        window.location.href = window.location.href.replace(/ *\/kjopFond.+/gi, `/kjopFond/${isin}`);
                    }}
                    hideTextCard
                />
            )}
        </div>
    );
};
