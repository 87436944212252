import React, { useState, useRef, MouseEvent, TouchEvent } from 'react';
import { generateId } from '../util/Functions';
import './Carousel.scss';

interface Props {
    list: JSX.Element[];
};

interface Storage {
    isDown: boolean;
    startX: number;
    scrollLeft: number;
    speed: number;  
};


interface State {
    id: string;
    slider: React.RefObject<HTMLUListElement>;
    storage: React.RefObject<Storage>;
};

const touchEnd = ( state: State ): void => {
    if ( !state.storage || !state.storage.current || !state.storage.current.isDown ){ return; }
    if ( !state.slider || !state.slider.current ){ return; }
    state.storage.current.isDown = false;
    state.slider.current.classList.remove('active');
}

const touchStart = ( state: State, e: MouseEvent | TouchEvent ): void => {
    if ( !state.storage || !state.storage.current || !state.storage.current.isDown ){ return; }
    if ( !state.slider || !state.slider.current ){ return; }

    state.storage.current.isDown = true;
    // @ts-ignore
    state.storage.current.startX = e.pageX - state.slider.current.offsetLeft;
    state.storage.current.scrollLeft = state.slider.current.scrollLeft;
    state.slider.current.classList.add('active');
}

const touchMove = ( state: State, e: MouseEvent | TouchEvent ): void => {
    if ( !state.storage || !state.storage.current || !state.storage.current.isDown ){ return; }
    if ( !state.slider || !state.slider.current ){ return; }

    e.preventDefault();
    // @ts-ignore
    const x = e.pageX - state.slider.current.offsetLeft;

    const walk = (x - state.storage.current.startX) * state.storage.current.speed;
    state.slider.current.scrollLeft = state.storage.current.scrollLeft - walk;
}

// eslint-disable-next-line
const scroll = (state: State): void => {
    // if ( state.storage.current.isDown ){ return; }
    // state.storage.current.onScroll = true;
};

export default (props: Props): JSX.Element | null => {
    const [state] = useState<State>({
        id: generateId('carousel'),
        slider: useRef<HTMLUListElement>(null),
        storage: useRef<Storage>({
            isDown: false,
            startX: 0,
            scrollLeft: 0,
            speed: 3,
        })
    }); 

    return (props.list || []).length > 0 ? <div className="carousel-wrapper">
        {/* eslint-disable */ }
        <ul 
            role="slider"
            id={state.id}
            className="gallery"
            ref={state.slider as React.RefObject<HTMLUListElement>}
            onScroll={() => { scroll(state); }}
            onTouchEnd={()=>{ touchEnd(state); }}
            onTouchStart={(e)=> { touchStart(state, e); }}
            onMouseMove={(e)=> { touchMove(state, e); }}
            onMouseUp={()=>{ touchEnd(state); } }
            onMouseLeave={()=> { touchEnd(state); }}
            onMouseDown={(e)=> { touchStart(state, e); }}
        >
            { props.list.map( (item: JSX.Element, i: number) => (
                item ? <li key={`carousel-item-${i}`} role="presentation">{item}</li> : null
            ))}
        </ul>
        {/* eslint-enable */ }
    </div> : null;
};