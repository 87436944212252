import { ButtonTypes, SmallButton } from '@eika/button';
import { IconTypes } from '@eika/icon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../../common/Card/Card';
import DropdownMenu from '../../../common/DropdownMenu/DropdownMenu';
import FundCalculator from '../../../common/FundCalculator/FundCalculator';
import Grid from '../../../common/Grid/Grid';
import Message from '../../../common/Message/Message';
import { FooterButton, Spinner } from '../../../common/share/ShareComponents';
import { formatAmount } from '../../../common/util/Functions';
import Constant from '../../../static/data/Constant';
import { FondHandelAutomaticIncreaseSaving } from '../FondHandelComponents';
import AmountField from '../../../common/AmountField/AmountField';
import { fundOwnedByClientOnAccount, getMinimumAmount } from '../util/FondHandelFunctions';
import { setFondOrderStorage } from '../../../common/util/FondFunctions';

interface FondHandelKjopFormProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    next: (e?: any, k?: any) => void;
    setAmount: (e?: any) => void;
    blurAmount: () => void;
    setAIS: (v: boolean) => void;
    setAISvalue: (e?: any) => void;
    blurAISvalue: () => void;
    orderStorage: any;
    aisValueInfo?: any;
    state?: any;
    appTexts: any;
    action: any;
    aisSavingPlan?: boolean;
}

const FondHandelKjopForm = (props: FondHandelKjopFormProps): JSX.Element => {
    const {
        click,
        next,
        setAmount,
        blurAmount,
        orderStorage,
        setAIS,
        setAISvalue,
        blurAISvalue,
        state,
        aisValueInfo,
        aisSavingPlan,
        ...rest
    } = props;
    const { fund, bankAccount, error = {}, drawDayList, placement, aisFrequency } = state ?? {};
    const history = useHistory();
    // @ts-ignore
    const { order } = useParams();
    const [fond, setFond] = useState((fund?.pin || {})[orderStorage.fund]);
    const konti = placement.pin[orderStorage.placement] || {};
    const to = [fond?.navn, konti.navn || fond?.type].filter((t) => !!t);
    const atLeast =
        (orderStorage.type === Constant.typeManedligSparing
            ? fond?.minimumsBelopPeriodiskKjop
            : fond?.minimumsBelopEngangsKjop) || 100;
    const aisText =
        !orderStorage.ais || !orderStorage.aisFrequency || !orderStorage.aisValue
            ? 'Ingen'
            : [
                  `${formatAmount(orderStorage.aisValue)} ${
                      aisFrequency.pin[orderStorage.aisFrequency].amount ? 'kr' : '%'
                  }`,
                  aisFrequency.pin[orderStorage.aisFrequency].text,
              ].join(' ');
    const fundData = useSelector((state: any) => state.Fond);
    const portfolioData = fundData.portefoljeData;
    const currentAccount =
        orderStorage.placement && placement ? placement.pin[orderStorage.placement].portfolioBaseCode : '';

    const clientOwnsCurrentFundOnCurrentAccount = fundOwnedByClientOnAccount(portfolioData, currentAccount, fond.isin);

    const minimumAmountFirstTimePurchase = getMinimumAmount(orderStorage, fond, false);
    const minimumAmount = getMinimumAmount(orderStorage, fond, true);
    const showFirstTimePurchaseAmount =
        !clientOwnsCurrentFundOnCurrentAccount && minimumAmountFirstTimePurchase > minimumAmount;
    const nextMutualFundClass = fond.nextMutualFundClassReference;

    const setNextFundClass = () => {
        setFond(fundData.morningstarPin[nextMutualFundClass.isin]);
        orderStorage.fund = nextMutualFundClass.isin;
        setFondOrderStorage(order, orderStorage);
    };

    return fond ? (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: (
                            <h1>
                                {
                                    /* eslint-disable */
                                    orderStorage.action === Constant.actionEndreSpareavtale
                                        ? 'Endre månedlig beløp'
                                        : orderStorage.type == Constant.typeEngangsinnkudd
                                        ? 'Sett inn engangsbeløp'
                                        : 'Spar et fast månedlig beløp'
                                    /* eslint-enable */
                                }
                            </h1>
                        ),
                    },
                ]}
            />

            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'seven',
                        element: (
                            <form
                                noValidate
                                className="form-wrapper fond-handel-amount-form"
                                onSubmit={() => {
                                    next();
                                }}
                            >
                                <div className="paragraph">
                                    {orderStorage.type === Constant.typeManedligSparing && (
                                        <div className="input-holder -select-draw-day only-for-desktop -form -select-draw-day-header">
                                            <DropdownMenu
                                                textList={
                                                    orderStorage.drawDay
                                                        ? [`Hver ${orderStorage.drawDay}. i måned`]
                                                        : ['']
                                                }
                                                selected={orderStorage.drawDay}
                                                optionList={drawDayList}
                                                label="Trekkdato"
                                                error={error.drawDay}
                                                click={click}
                                                action="select-draw-day"
                                                view="draw-day"
                                                message={{
                                                    text: 'På valgt dag i måneden blir beløpet trukket fra din konto hver måned.',
                                                    type: 'info-message -icon',
                                                    role: 'presentation',
                                                }}
                                                defaultText="Velg dato"
                                            />
                                        </div>
                                    )}
                                    <div className="fond-handel-amount-field-holder">
                                        <AmountField
                                            label={
                                                orderStorage.type === Constant.typeManedligSparing
                                                    ? 'Månedlig beløp'
                                                    : 'Velg beløp'
                                            }
                                            sublabel={`Minstebeløp ${
                                                showFirstTimePurchaseAmount
                                                    ? formatAmount(minimumAmountFirstTimePurchase)
                                                    : formatAmount(atLeast)
                                            } kr`}
                                            attention={orderStorage.amountAttention}
                                            id="amount"
                                            error={error.amount}
                                            value={orderStorage.amount}
                                            onChange={(e?: any) => {
                                                setAmount(e);
                                            }}
                                            onBlur={() => {
                                                blurAmount();
                                            }}
                                            maxLength={orderStorage.type === Constant.typeManedligSparing ? 7 : 10}
                                            nextFundClass={nextMutualFundClass}
                                            click={setNextFundClass}
                                            history={history}
                                        />
                                        {konti.portefoljeType !== Constant.typeIps &&
                                            (orderStorage.action === Constant.actionEndreSpareavtale ||
                                                (orderStorage.action === Constant.actionKjop &&
                                                    orderStorage.type === Constant.typeManedligSparing)) && (
                                                <FondHandelAutomaticIncreaseSaving
                                                    aisValueInfo={aisValueInfo}
                                                    aisFrequency={aisFrequency}
                                                    orderStorage={orderStorage}
                                                    error={error}
                                                    click={(e, k, d, f) => {
                                                        click(e, k, d, f);
                                                    }}
                                                    setAIS={(e: boolean) => {
                                                        setAIS(e);
                                                    }}
                                                    setAISvalue={(e) => {
                                                        setAISvalue(e);
                                                    }}
                                                    blurAISvalue={() => {
                                                        blurAISvalue();
                                                    }}
                                                    aisSavingPlan={aisSavingPlan}
                                                />
                                            )}
                                    </div>
                                    {orderStorage.type === Constant.typeManedligSparing &&
                                        orderStorage.action === Constant.actionEndreSpareavtale &&
                                        konti.portefoljeType !== Constant.typeIps && (
                                            <>
                                                <Card
                                                    click={click}
                                                    action="select-ais"
                                                    type="list-item -as-rowButton"
                                                    namespace="only-for-mobile -form -select-ais"
                                                    data={{
                                                        error: error.ais,
                                                        textList: [
                                                            [
                                                                { text: 'Automatisk øking', type: 'field-label' },
                                                                { text: aisText, type: 'field-value' },
                                                            ],
                                                        ],
                                                    }}
                                                />
                                            </>
                                        )}
                                    {orderStorage.type === Constant.typeManedligSparing && (
                                        <Card
                                            click={click}
                                            action="select-draw-day"
                                            type="list-item -as-rowButton"
                                            namespace="only-for-mobile -form -select-draw-day"
                                            data={{
                                                error: error.drawDay,
                                                textList: [
                                                    [
                                                        { text: 'Trekkdato', type: 'field-label' },
                                                        {
                                                            text: orderStorage.drawDay
                                                                ? `Den ${orderStorage.drawDay}. hver måned`
                                                                : 'Velg trekkdato',
                                                            type: 'field-value',
                                                        },
                                                    ],
                                                ],
                                            }}
                                        />
                                    )}
                                    <Message type="AttentionMessage" text="Fondet oppdateres i løpet av 3-6 dager." />
                                    <Card
                                        click={click}
                                        action="select-bankAccount"
                                        type="rowButton"
                                        namespace="only-for-mobile -form"
                                        data={{
                                            id: 'fondhandel-bankAccount',
                                            fieldLabel: 'Fra',
                                            textList: (bankAccount.pin[orderStorage.bankAccount] || {}).textList
                                                ? [
                                                      bankAccount.pin[orderStorage.bankAccount].textList[0],
                                                      bankAccount.pin[orderStorage.bankAccount].textList[2] ||
                                                          bankAccount.pin[orderStorage.bankAccount].textList[1],
                                                  ]
                                                : ['Velg belastningskonto'],
                                            error: error.bankAccount,
                                            optionList: bankAccount?.list,
                                            selected: orderStorage.bankAccount,
                                            defaultText: 'Fra hvilken belastningskonto',
                                        }}
                                    />
                                    <Card
                                        click={click}
                                        action=""
                                        disabled
                                        type="rowButton"
                                        namespace="only-for-mobile -form"
                                        data={{
                                            fieldLabel: 'Til',
                                            textList: to,
                                            icon: 'PIN_KODE_24',
                                        }}
                                    />
                                    <div className="only-for-desktop -form">
                                        <ul className="table-wrapper -split-50-50">
                                            <li>
                                                <Card
                                                    type="dropdownMenu"
                                                    action="select-account"
                                                    click={(e: any, k: any, d?: any) => {
                                                        click(e, 'bankAccount-selected', d, 'desktop');
                                                    }}
                                                    data={{
                                                        fieldLabel: 'Belastningskonto',
                                                        selectedLead: 'Fra',
                                                        optionList: bankAccount?.list,
                                                        selected: orderStorage.bankAccount,
                                                        defaultText: 'Velg belastningskonto',
                                                        minRow: 2,
                                                        error: error.bankAccount,
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <Card
                                                    type="dropdownMenu"
                                                    action="select-fund"
                                                    click={() => {}}
                                                    data={{
                                                        fieldLabel: 'Fond',
                                                        selectedLead: 'Til',
                                                        optionList: [{ id: orderStorage.fund, text: to }],
                                                        selected: orderStorage.fund,
                                                        disabled: true,
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    {orderStorage.action === Constant.actionEndreSpareavtale && (
                                        <div className="delete-fond-agreement-btn-wrapper">
                                            {/* @ts-ignore */}
                                            <SmallButton
                                                icon={IconTypes.SLETT_24}
                                                buttonType={ButtonTypes.SMALL_NEGATIVE}
                                                onClick={(e) => {
                                                    click(e, 'delete-fond-agreement');
                                                }}
                                            >
                                                Slett
                                            </SmallButton>
                                        </div>
                                    )}
                                </div>

                                {konti.portefoljeType !== Constant.typeIps &&
                                orderStorage.action !== Constant.actionEndreSpareavtale &&
                                orderStorage.type === Constant.typeManedligSparing ? (
                                    <>
                                        <FooterButton namespace="only-for-desktop" click={click} cancel="Avbryt" />
                                        <FooterButton
                                            namespace="only-for-mobile"
                                            action="next-is-ais"
                                            click={click}
                                            cancel="Avbryt"
                                        />
                                    </>
                                ) : (
                                    <FooterButton click={click} cancel="Avbryt" />
                                )}
                            </form>
                        ),
                    },
                    {
                        layout: 'four',
                        element: (
                            <div className="only-for-desktop paragraph -only-top">
                                <FundCalculator
                                    {...rest}
                                    isin={fond.isin}
                                    onetime={
                                        orderStorage.type === Constant.typeManedligSparing ? 0 : orderStorage.amount
                                    }
                                    monthly={
                                        orderStorage.type === Constant.typeManedligSparing ? orderStorage.amount : 0
                                    }
                                    aisValue={orderStorage.ais ? orderStorage.aisValue : undefined}
                                    aisFrequency={orderStorage.ais ? orderStorage.aisFrequency : undefined}
                                    drawDay={orderStorage.drawDay}
                                    increaseImmediately={orderStorage.action === Constant.actionEndreSpareavtale}
                                    // message={orderStorage.ais ? {type: 'AttentionMessage', text: 'Automatisk økning er ikke tatt med i utregningen, vi jobber med saken!', icon: true} : undefined}
                                />
                            </div>
                        ),
                    },
                ]}
            />
        </>
    ) : (
        <Spinner />
    );
};

export default FondHandelKjopForm;
