import { combineReducers } from 'redux';

import Feilmeldinger from './feilmeldinger';
import Texts from './texts';
import App from '../actions/app';
import Kalkulator from '../actions/kalkulator';
import Fond from '../actions/fond';
import KjennDinKunde from '../actions/kjenndinkunde';
import SpareProfil from '../actions/spareprofil';
import Modals from '../actions/modals';
import Pensjon from '../actions/pensjon';
import Ordre from '../actions/ordre';
import Sparemaal from '../actions/sparemaal';
import Bank from '../actions/bank';
import Epk from '../actions/epk';
import Storebrand from '../actions/storebrand';
import Features from '../actions/features';

export default combineReducers({
    Feilmeldinger,
    Texts,
    App,
    Fond,
    Pensjon,
    KjennDinKunde,
    SpareProfil,
    Modals,
    Ordre,
    Sparemaal,
    Bank,
    Kalkulator,
    Epk,
    Storebrand,
    Features,
});
