const FinanceLaw = {
    title: 'Kundeavtale om handel med andeler i verdipapirfond gjennom eika kapitalforvaltning as',
    poinList: [
        [
            {text: '1 Om avtaleforholdet', style: { fontWeight: '700'}},
            'Denne avtalen med vedlegg («Avtalen») inngås mellom Eika Kapitalforvaltning org nr 979 561 261 (Forvaltningsselskapet) og Kunden (Kunden) og regulerer de tjenester som Forvaltningsselskapet leverer til Kunden i forbindelse med Kundens tegning, innløsning og bytte av andelsklasser, herunder etablering og endring av spareavtaler (samlet eller hver for seg omtalt som «Handel»), i verdipapirfond forvaltet eller distribuert av Forvaltningsselskapet.',
            'Avtalen omfatter Handel som gjennomføres av Kunden i alle Forvaltningsselskapets kanaler, herunder, Handel gjennom Forvaltningsselskapets elektroniske handelsplattformer samt handel gjennom Forvaltningsselskapets manuelle bestillingskanaler. Avtalen er felles og likelydende for alle kunder som handler direkte gjennom Forvaltningsselskapet. Avtalen er tilgjengelig på www.eika.no og kan bestilles kostnadsfritt ved å kontakte Forvaltningsselskapet.',
            'Lov av 18. desember 2020 nr. 146 om finansavtaler (finansavtaleloven) gjelder for tjenester som omfattes av denne Avtalen som ytes til Kunden dersom denne er forbruker. Med forbruker menes fysisk person når avtalen har et formål som hovedsakelig ligger utenfor personens forretnings- eller yrkesvirksomhet, jf. finansavtaleloven § 1-4 (“Forbruker”).',
            'Dersom Kunden ikke er Forbruker (“Ikke-forbruker”) reguleres forholdet mellom Forvaltningsselskapet og Ikke-forbrukeren av Avtalen og finansavtalelovens bestemmelser skal ikke komme til anvendelse med mindre det fremkommer uttrykkelig av loven at bestemmelsen ikke kan fravikes ved avtale.',
        ], [
            {text: '2 Avtaleinngåelse og avtalevilkår', style: { fontWeight: '700'}},
            {text: '2.1 Avtaleinngåelse', style: { fontWeight: '600'}},
            'Avtalen anses som inngått når Kunden har signert den elektronisk eller fysisk, eller på annen måte har samtykket til inngåelse av Avtalen. Avtalen vil gjelde enhver Handel Kunden gjennomfører, og omfatter den beholdningen av verdipapirfond som Kunden til enhver tid innehar som er forvaltet eller distribuert av Forvaltningsselskapet («Verdipapirfond»).',
            {text: '2.2 Avtalevilkår', style: { fontWeight: '600'}},
            'Ved inngåelsen av denne Avtalen aksepterer Kunden de til enhver tid gjeldende alminnelige forretningsvilkår, som er vedlagt denne Avtalen som Vedlegg 1 («Forretningsvilkår»). I den grad det er motstrid mellom bestemmelsene i denne Avtalen og Forretningsvilkårene skal Avtalen gå foran.',
            'Eventuelle avtaler Kunden inngår med Forvaltningsselskapet om Individuell pensjonssparing, Aksjesparekonto eller Egen Pensjonskonto reguleres av særskilte avtalevilkår som gjelder i tillegg til denne avtalen. I den grad det er motstrid mellom bestemmelsene i denne Avtalen og avtaler om Individuell pensjonssparing, Aksjesparekonto eller Egen Pensjonskonto skal Avtalen gå foran.',
            'Forretningsvilkår, varsler og informasjon fra Forvaltningsselskapet under det løpende avtaleforholdet, som Kunden har krav på vil være tilgjengelig på norsk med mindre annet er avtalt. ',
            'Forvaltningsselskapet vil tilgjengeliggjøre relevant dokumentasjon om det enkelte Verdipapirfond forut for Kundens tegning, innløsning og/eller bytte av fondsandeler, herunder blankett for tegning, innløsning eller bytte av fond, nøkkelinformasjonsdokument, prospekt og vedtekter (samlet omtalt som «Fondsdokumentene»), som også er tilgjengelig på Forvaltningsselskapets nettsider www.eika.no.',
            'Fondsdokumentene er ikke en del av denne Avtalen. Kunden er bundet av de regler og vilkår som til enhver tid gjelder for det enkelte Verdipapirfond, slik det følger av regelverket for verdipapirfond og Fondsdokumentene. ',
            'Denne Avtalen omfatter alle senere Handler i Verdipapirfond forvaltet eller distribuert av Forvaltningsselskapet som vil bli bekreftet gjennom separate bekreftelser. ',
            {text: '2.3 Ikrafttredelse', style: { fontWeight: '600'}},
            'Avtalen og Forretningsvilkårene trer i kraft 1. juli 2023. Den gjelder også for kundeforhold etablert før ikrafttredelsestidspunktet. ',
        ], [
            {text: '3 Handel med fondsandeler', style: { fontWeight: '700'}},
            'Forvaltningsselskapet distribuerer fondsandeler i verdipapirfond forvaltet av Forvaltningsselskapet og andre forvaltere (Eksterne Fondsforvaltere).',
            'Tegning av andeler skjer ved at det gis fullmakt til Forvaltningsselskapet eller tilknyttet agent om å belaste Kundens bankkonto. Etter at ordre om tegning, innløsning eller bytte er registrert, vil Kunden motta sluttseddel fra Forvaltningsselskapet. Det er kun ved slik sluttseddel at ordren vil anses for å være bekreftet mottatt av Forvaltningsselskapet. Leveringstiden for fondsandeler fra Eksterne Fondsforvaltere vil variere for det enkelte verdipapirfond og den Eksterne Fondsforvalterens rutiner.',
        ], [
            {text: '4 Endring av fondsutvalg og løpende spareavtaler', style: { fontWeight: '700'}},
            'Forvaltningsselskapet formidler verdipapirfond forvaltet av andre fondsforvaltere. En oversikt over disse verdipapirfondene finnes til enhver tid på Forvaltningsselskapets nettside www.eika.no. Forvaltningsselskapet forbeholder seg retten til å foreta endringer i sitt til enhver tid gjeldende utvalg av verdipapirfond, herunder hvilke andelsklasser som tilbys. Forvaltningsselskapet kan uten nærmere begrunnelse beslutte å avvikle eller endre avtaler med handelsplasser, forvaltningsselskaper og /eller enkelte verdipapirfond.',
        ], [
            {text: '5 Kostnader og vederlag', style: { fontWeight: '700'}},
            'For Fondsselskapets distribusjon av fond fra andre fondsforvaltere betaler Kunden plattformhonorar. En oversikt over gjeldene satser for plattformhonorar følger nedenfor:',
            {text: 'Aktivt forvaltet aksjefond', style: {fontWeight: '600', paddingTop: 10, paddingLeft: 20}},
            {text: '0,6 % p.a plattformhonorar', style: {paddingLeft: 20, marginTop: -10}},
            {text: 'Indeksfond', style: {fontWeight: '600', paddingTop: 10, paddingLeft: 20}},
            {text: '0,3 % p.a plattformhonorar', style: {paddingLeft: 20, marginTop: -10}},
            {text: 'Kombinasjonsfond (min 30% aksjeandel)', style: {fontWeight: '600', paddingTop: 10, paddingLeft: 20}},
            {text: '0,6 % p.a plattformhonorar', style: {paddingLeft: 20, marginTop: -10}},
            {text: 'Rentefond', style: {fontWeight: '600', paddingTop: 10, paddingLeft: 20}},
            {text: '0,3 % p.a plattformhonorar', style: {paddingLeft: 20, marginTop: -10, marginBottom: 20}},
            'Forvaltningshonorar og eventuelle andre kostnader i medhold av verdipapirfondloven reguleres ikke av Avtalen, men av Fondsdokumentene for det enkelte Verdipapirfond. Gjennomføres handel i fond ved hjelp av andre aktører eller benyttes annen kontofører enn Forvaltningsselskapet, kan disse belaste egne gebyrer og honorar i tillegg.',            
        ], [
            {text: '6. Endringer i avtalen', style: { fontWeight: '700'}},
            'Forvaltningsselskapet kan gjøre endringer i Avtalen. Kunden vil bli varslet om slike endringer. Dersom endringene ikke er til ugunst for Kunden gjelder endringen fra tidspunktet kundene får melding om endringene. Ved eventuelle endringer til ugunst for Kunden gjelder en varslingsplikt på to måneder før endringene settes i verk. Dette gjelder likevel ikke endringer i Avtalen som skyldes endret lovgivning, myndighetspraksis eller pålegg fra offentlige myndigheter. Slike endringer vil bli varslet, men vil kunne settes i verk umiddelbart. Kunden kan varsle Forvaltningsselskapet om at en endring til skade for Kunden avvises.',
            'Dersom Kunden motsetter seg endringene, må Kunden melde fra til Forvaltningsselskapet før det oppgitte tidspunktet for ikrafttredelse av endringene. Kunden anses å samtykke til en foreslått endring av Avtalen ved å forholde seg passiv. Dersom Kunden ikke gir varsel innen 2 måneder slik Forvaltningsselskapet har angitt anses endringen som bindende for Kunden. Dersom Kunden avviser endringen, vil dette gi Forvaltningsselskapet rett til å si opp Avtalen. Virkningene av oppsigelse av Avtalen er nærmere beskrevet i punkt 7 under.',
            'Endringsbestemmelsene nevnt over i dette punkt 6 gjelder for Kunden som er Forbruker. For Ikke- forbruker kan Forvaltningsselskapet også foreta endringer i Avtalen ensidig. Dersom endringen er til ugunst vil Forvaltningsselskapet sende varsel om endringen, som vil tre i kraft på tidspunktet angitt i varselet.',
        ], [
            {text: '7 Varighet, oppsigelse og avvikling', style: { fontWeight: '700'}},
            'Avtalen gjelder til den blir sagt opp av Forvaltningsselskapet eller Kunden. Kunden kan si opp denne Avtalen med umiddelbar virkning.',
            'Forvaltningsselskapet kan si opp denne Avtalen med 2 måneder skriftlig varsel dersom det foreligger saklig grunn. Som saklig grunn regnes blant annet; at Kunden har (i) misligholdt denne Avtalen eller Forretningsvilkårene, eller (ii) avvist endringer som beskrevet ovenfor i punkt 6 over.',
            'Forvaltningsselskapet kan si opp Avtalen straks dersom Kunden har opptrådt i strid med lov, forskrift eller andre regler fastsatt av tilsynsmyndigheter, eller dersom det foreligger en ikke ubegrunnet mistanke om slik adferd. Videre kan Forvaltningsselskapet si opp Avtalen straks ved Kundens gjentatte eller vesentlige brudd på punkt (i) ovenfor.',
            'Uten hensyn til reglene om oppsigelse i dette punktet kan Forvaltningsselskapet sperre videre bruk av tjenesten eller på annen måte avvikle denne Avtalen, eller avstå fra å gjennomføre allerede mottatte eller fremtidige ordre så langt dette er nødvendig for at Forvaltningsselskapet skal kunne oppfylle sine plikter fastsatt i lov, forskrift eller pålegg fra offentlig myndighet eller domstol. Kunden vil i så fall motta varsel om sperring.',
            'Ved oppsigelse av Avtalen skal partene omgående gjøre opp alle sine forpliktelser overfor hverandre. Kunden må innløse eventuelt flytte samtlige fondsandeler og avslutte sine løpende spareavtaler, innen utløpet av oppsigelsesperioden. Ved oppsigelse fra Kunden må Kunden innløse sine fondsandeler innen 30 dager fra oppsigelsestidpunktet. Manglende oppfyllelse av disse plikter gir Forvaltningsselskapet rett til, uten Kundens samtykke, å innløse Fondsandelene tilhørende Kunden og overføre innløsningsbeløpet til Kundens bankkonto, herunder avvikle Kundens løpende spareavtaler. Som et alternativ til innløsning av fondsandeler kan Forvaltningsselskapet etter eget skjønn velge å deponere Kundens fondsandeler i henhold til lov om deponering. ',
            'Ved innløsning av andeler i verdipapirfond vil gevinst beskattes, og tap kommer til fradrag i beregnet skatt. Kunden har risiko for alle skattemessige konsekvenser som følger av en oppsigelse av Avtalen, uavhengig av hvilken part som sier opp Avtalen.',
            'Uavhengig av måten Avtalen blir sagt opp på er Kunden forpliktet til å betale Plattformhonorar frem til Kundens beholdning av Verdipapirfond er flyttet til en ny distributør eller er innløst.',
        ], [
            {text: '8 Lovvalg, verneting og tvisteløsning', style: { fontWeight: '700'}},
            'Ethvert krav eller enhver tvist mellom partene som springer ut av eller står i forbindelse med denne Avtalen eller Forretningsvilkårene, eller handel av andeler i Verdipapirfond fra Forvaltningsselskapet, skal løses etter norsk lov og med Oslo tingrett som rett verneting.',
            'Ved behov for å klage kan Kunden kontakte Forvaltningsselskapet via Forvaltningsselskapets hjemmeside www.eika.no.',
            'Oppstår det tvist mellom Kunden og Forvaltningsselskapet, kan Kunden bringe saken inn for Finansklagenemnda for uttalelse når nemnda er kompetent i tvisten og Kunden har saklig interesse i å få nemndas uttalelse. For at Finansklagenemnda skal behandle en eventuell tvist, må Kunden først inngi en klage til Forvaltningsselskapet, som må få en rimelig frist til å ta standpunkt til klagen.',
            'Henvendelser til Finansklagenemnda sendes Finansklagenemnda, Postboks 53, Skøyen 0212 Oslo, tlf. 23 13 19 60. For nærmere informasjon og klageskjema se www.finkn.no.',
        ]
    ],
    linkList: [
        { href: 'https://eika.no/-/media/fellesfiler/fond/Eika-kapitalforvaltning-kundeavtale.pdf', text: 'Kundeavtalen', target: 'download' },
        { href: 'https://eika.no/-/media/fellesfiler/fond/Forretningsvilkar.pdf', text: 'Forretningsvilkår', target: 'download' },
    ],
    confirmationList: [
        { id: 'finance-law-x', checked: false, text: 'Jeg bekrefter å ha lest kundeavtalen og forretningsvilkårene, og jeg aksepterer disse som gjeldende for mitt kundeforhold med Eika Kapitalforvalting AS.' },
        // { id: 'finance-law-y', checked: false, text: 'Jeg godtar vilkårene' },
    ],
    config: { pointList: 'flat' },
};
export default FinanceLaw;