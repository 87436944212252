import { MouseEvent } from 'react';
import Constant from '../../../static/data/Constant';
import {
    validate,
    validateGreatThanOrLike,
    validateLessThanOrLike,
    validateAmount,
    validateBankAccount,
} from '../../../common/util/Validations';
import {
    formatBankAccount,
    formatAmount,
    setElementFocus,
    scrollBodyTop,
    sortList,
    createCookie,
    parseNumber,
    getURLquery,
} from '../../../common/util/Functions';
import { getFondOrderStorage, setFondOrderStorage } from '../../../common/util/FondFunctions';
import { openContactUs } from '../../../common/util/BusinessFunctions';
import { getAISvalueRequiredMessage, getAISvalueInvalidMessage } from './FondHandelAutomatiskOkingSparingFunctions';
import { submit, displayAvtalegiro } from './FondHandelSubmitFunctions';
import { FormElementChangeEvent, FormEelementEvenTarget, BooleanObject } from '../../../domain/Types';
import { Props, State } from '../FondHandelDomain';

export const getParam = (props: Props): any => {
    const { match } = props || {};
    const param: Record<string, any> = { ...(match.params || {}), search: {} };

    (window.location.href || '').split('?').forEach((path: string) => {
        const content = path.replace(/#.*/, '');
        content.split('&').forEach((text: string) => {
            const splited = text.split('=');
            param[splited[0]] = splited[1] || '';
        });
    });

    if (param.step) {
        param.step = parseFloat(param.step.replace(/[a-z]/gi, ''));
    }
    return param;
};

export const getOrderStorage = (props: Props, currentParam?: any): any => {
    const param = currentParam || getParam(props);
    return getFondOrderStorage(param.order);
};

/** ************************************************************************
 ************************************************************************* */
export const getStatusConfig = (status = ''): BooleanObject => {
    return {
        finished: /^ok$/i.test(status),
        canceled: /^cancel$/i.test(status),
        failed: /^error$/i.test(status),
    };
};

export const hasFundBefore = (props: Props, state: State, currentStorage?: any): boolean => {
    const orderStorage = currentStorage || getOrderStorage(props);
    if (!orderStorage.placement || !orderStorage.fund) {
        return false;
    }

    const { placement = {}, fund = {} } = state;
    const isin = ((fund.pin || {})[orderStorage.fund] || {}).isin;
    if (!isin || !(placement.pin || {})[orderStorage.placement]) {
        return false;
    }

    const fundList = ((placement.pin || {})[orderStorage.placement] || {}).fond || [];
    return !!fundList.find((f: any) => {
        return f && f.fondInfo && f.fondInfo.isin === isin;
    });
};

export function fundOwnedByClientOnAccount(
    portfolioData: any,
    currentAccount: string,
    currentFundIsin: string
): boolean {
    if (portfolioData && currentAccount && currentFundIsin) {
        const res = portfolioData.portefoljer
            .filter((p: any) => p.portfolioBaseCode === currentAccount)
            .flatMap((p: any) => p.totalBeholdningFondList)
            .filter((f: any) => f?.fondInfo.isin === currentFundIsin || f?.fondInfo.id === currentFundIsin);
        return res.length > 0;
    }
    return false;
}

export function getMinimumAmount(orderStorage: any, currentFund: any, clientOwnsFund?: boolean) {
    if (orderStorage.action === Constant.actionUttak || orderStorage.action === Constant.actionSelg) {
        return undefined;
    }

    if (currentFund) {
        if (!clientOwnsFund && orderStorage.type !== Constant.typeManedligSparing) {
            return currentFund.minimumOrderAmountFirstTimePurchase;
        }
        if (orderStorage.type === Constant.typeManedligSparing) {
            return currentFund.minimumsBelopPeriodiskKjop;
        }

        return currentFund.minimumsBelopEngangsKjop;
    }
    return 0;
}

/** ************************************************************************
 ************************************************************************* */
export const verify = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    id?: any,
    ignoreUpdate?: boolean,
    currentStorage?: any,
    portfolioData?: any,
    entry?: string
): boolean => {
    const { error = {}, stepFieldKeyList = {}, messageText = {}, fund = {} } = state;
    const param = getParam(props);
    const orderStorage = currentStorage || getOrderStorage(props, param);
    const cloned = JSON.parse(JSON.stringify(error));
    // eslint-disable-next-line
    const pin = typeof id === 'string' ? { [id]: 1 } : typeof id === 'object' ? id : null;
    const keyList = stepFieldKeyList[`${param.step}`] || [];
    const keyPin = keyList.reduce((prev: any, key: string) => {
        prev[key] = 1;
        return prev;
    }, {});

    for (const key in pin || {}) {
        if (!keyPin[key]) {
            keyList.push(key);
        }
    }

    keyList.forEach((key: string) => {
        if (pin && !pin[key]) {
            return;
        }
        if (key === 'drawDay' && orderStorage.type !== Constant.typeManedligSparing) {
            return;
        }
        if (key === 'ais') {
            return;
        }

        delete cloned[key];
        delete cloned.amountWithdrawalError;

        if (!orderStorage[key]) {
            if (
                (key === 'bankAccount' || key === 'newBankAccount' || key === 'salgUnderstand') &&
                orderStorage.type === Constant.typeKlientkonto
            ) {
                return;
            }
            if (key === 'aisValue') {
                const msg = getAISvalueRequiredMessage(props, state, orderStorage);
                if (msg) {
                    cloned[key] = msg;
                }
            } else if (key === 'termPlacement' && hasFundBefore(props, state)) {
                // do nothing...
            } else {
                cloned[key] = (messageText[key] || {}).required || 'Valget er påkrevd';
            }
        } else if (key === 'email' && !validate('email', orderStorage[key])) {
            cloned[key] = (messageText[key] || {}).invalid || 'Epost er ugyldig';
        } else if (key === 'newBankAccount') {
            if (!validate('bankAccount', orderStorage[key])) {
                if (orderStorage.type !== Constant.typeKlientkonto) {
                    cloned[key] = (messageText[key] || {}).invalid || 'Kontonummer er ugyldig';
                }
            } else {
                delete cloned.bankAccount;
            }
        } else if (key === 'bankAccount') {
            const bankAccount = (state.bankAccount?.pin ?? {})[orderStorage[key]];

            if (
                !bankAccount ||
                (!validate('bankAccount', bankAccount.kontonummer) &&
                    bankAccount.bankkontoType !== Constant.typeKlientkonto)
            ) {
                cloned[key] = (messageText[key] || {}).required || 'Bankkonto er påkrevd';
            }
        } else if (key === 'amount' && (fund.pin[orderStorage.fund] || orderStorage.action === Constant.actionUttak)) {
            const currentFundIsin = props.match.params.isin || param.fundId || orderStorage.fund;
            const currentFund = state.fund.pin[currentFundIsin];

            const isFirstTimePurchase = entry !== 'FUND' && orderStorage.type !== Constant.typeManedligSparing;

            const min = getMinimumAmount(orderStorage, currentFund, entry === 'FUND');
            const max =
                orderStorage.maxAmount ||
                (orderStorage.type === Constant.typeManedligSparing
                    ? Constant.monthlyMaxAmount
                    : Constant.onetimeMaxAmount);

            const amount = parseInt(`${orderStorage.amount}`.replace(/\s+/g, ''), 10);
            if (!validateAmount(orderStorage.amount)) {
                cloned[key] = 'Ugyldig beløp.';
            } else if (isFirstTimePurchase && min && !validateGreatThanOrLike(amount, min)) {
                cloned[key] = `Min. ${formatAmount(min)} kr ved førstegangs kjøp`;
            } else if (min && !validateGreatThanOrLike(amount, min)) {
                cloned[key] = `Min. ${formatAmount(min)} kr`;
            } else if (max && !validateLessThanOrLike(amount, max)) {
                cloned[key] = `Maks. ${formatAmount(max)} kr`;
            } else if (
                /(kjop-fond|editagreement)/i.test(orderStorage.action) &&
                (state.bankAccount?.pin ?? {})[orderStorage.bankAccount]
            ) {
                const bankAccount = (state.bankAccount?.pin ?? {})[orderStorage.bankAccount];

                if (bankAccount.bankkontoType === Constant.typeKlientkonto && amount > (bankAccount.verdi || 0)) {
                    cloned.bankAccount = 'klientkonto har ikke nok dekning';
                } else if (cloned.bankAccount === 'klientkonto har ikke nok dekning') {
                    delete cloned.bankAccount;
                }
            } else if (
                /(bytt|selg)-fond/i.test(orderStorage.action) &&
                orderStorage.maxAmount &&
                orderStorage.maxAmount !== amount
            ) {
                if (orderStorage.foreignCurrencyForceToSellAll && orderStorage.maxAmount > amount) {
                    cloned[key] = props.appTexts.get('foreignCurrencyHasToSellTotalValue');
                    cloned.amountWithdrawalError = true;
                } else {
                    const percent = 85;
                    const limit = parseInt(`${orderStorage.maxAmount * (percent / 100)}`, 10); // runde tallet ned
                    if (amount > limit) {
                        const action = orderStorage.action === Constant.actionBytt ? 'bytte' : 'ta ut';
                        cloned[key] = `Dersom du vil ${action} mer enn ${formatAmount(
                            limit
                        )} kr (${percent} % av fondet), må du bytte hele fondet (${formatAmount(
                            orderStorage.maxAmount
                        )} kr).`;
                        cloned.amountWithdrawalError = true;
                    }
                }
            }

            if (orderStorage.type === Constant.typeManedligSparing && orderStorage.aisValue && orderStorage.ais) {
                const msg = getAISvalueInvalidMessage(props, state, orderStorage);
                if (msg) {
                    cloned.aisValue = msg;
                } else {
                    delete cloned.aisValue;
                }
            }
        } else if (key === 'aisValue' && orderStorage.type === Constant.typeManedligSparing) {
            const msg = getAISvalueInvalidMessage(props, state, orderStorage);
            if (msg) {
                cloned[key] = msg;
            }
        }
    });

    if (!ignoreUpdate) {
        setState({ ...state, error: cloned });
    }

    delete cloned.overwriting;
    return JSON.stringify(cloned) === '{}';
};

/**************************************************************************
 * == Bank account handler ==
 **************************************************************************/
export const getPlacementAccountList = (props: Props, state: State, currentStorage?: any): any => {
    const { placement, fund } = state;
    const orderStorage = currentStorage || getOrderStorage(props);

    (placement?.list || []).forEach((d: any) => delete d.disabled);

    /* eslint-disable */
    const list =
        orderStorage.fund && fund && fund.pin[orderStorage.fund]
            ? fund.pin[orderStorage.fund].aksjesparekontoKompatibel
                ? placement.askAccount
                : placement.vpkAccount
            : placement?.list || [];
    /* eslint-enable */

    const amount = parseInt(`${orderStorage.amount || 0}`.replace(/\s+/, ''), 10);
    list.forEach((d: any) => {
        if (d.sperre) {
            d.attentionMessage = props.appTexts.get(`${d.sperre.toLowerCase()}SperretKonto`);
            d.disabled = d.sperre === Constant.fullSperret;
        } else {
            d.disabled = d.sparing?.maksbelop && d.sparing?.maksbelop < amount;
            const isIPS = d.portefoljeType === Constant.typeIps;
            if (orderStorage.ais && isIPS) {
                d.disabled = 'Kontoen kan ikke velges, det er ikke tillatt med automatisk økning på IPS-konto.';
            } else if (d.disabled && isIPS) {
                d.disabled = `Summen er over grense ${formatAmount(d.sparing.maksbelop)} kr per år.`;
            } else if (isIPS && orderStorage.type === Constant.typeManedligSparing) {
                d.disabled = (d.fond || []).find((f: any) => {
                    return (f.spareavtaler || []).length > 0;
                })
                    ? 'Kontoen kan ikke velges fordi det kan bare være en spareavtale i en IPS-konto.'
                    : false;
            }
        }
    });

    return list;
};

export const setupBankAccountOptionList = (props: Props, state: State, orderStorage: any): void => {
    const bankAccount = state.bankAccount;
    if (!bankAccount) {
        return;
    }

    if (!orderStorage) {
        orderStorage = getOrderStorage(props);
    }

    const fund = (state.fund?.pin || {})[orderStorage.fund] || {};
    const bankAccountData = bankAccount.pin[orderStorage.bankAccount] || {};
    const isSelectedBankAccountIsKlientkonto = bankAccountData.bankkontoType === Constant.typeKlientkonto;
    const verifyPortefoljeId =
        orderStorage.myFund || orderStorage.setMyFund ? orderStorage.placement : orderStorage.history?.init?.placement;

    bankAccount.list = (bankAccount.original || [])
        .map((data: any) => {
            if (data.bankkontoType === Constant.typeKlientkonto) {
                // klientkonto kan velges kun engangsinnskudd
                if (orderStorage.type !== Constant.typeEngangsinnkudd) {
                    return;
                }

                // klientkonto kan velges kun når valgt fond er aksjesparekontoKompatibel
                if (!fund.aksjesparekontoKompatibel) {
                    return;
                }

                // Hvis portefølje er allered valgt,
                if (verifyPortefoljeId && `${verifyPortefoljeId}` !== `${data.portefoljeId}`) {
                    return;
                }
            }
            return data;
        })
        .filter((data: any) => !!data);

    bankAccount.pin = {};
    bankAccount.list.forEach((data: any, i: number) => {
        bankAccount.pin[`${i}`] = data;
    });

    if (isSelectedBankAccountIsKlientkonto) {
        const param = getParam(props);

        if (orderStorage.type !== Constant.typeEngangsinnkudd) {
            delete orderStorage.bankAccount;
        }

        orderStorage.placement = `${bankAccountData.portefoljeId}`;
        setFondOrderStorage(param.order, orderStorage);
    }
};

export const pushBankAccount = (bankAccount: { pin: any; list: any }, accountNumber: string): string => {
    if (!validateBankAccount(accountNumber)) {
        return '';
    }

    const newAccount = bankAccount.list.pop();
    const id = `${bankAccount.list.length}`;
    bankAccount.pin[id] = {
        id,
        kontonummer: `${accountNumber}`.replace(/\s+/g, ''),
        selection: formatBankAccount(accountNumber),
        textList: [formatBankAccount(accountNumber)],
    };
    bankAccount.list.push(bankAccount.pin[id]);
    bankAccount.list.push(newAccount);
    return id;
};

/**************************************************************************
 **************************************************************************/
export const back = (length = 1, props?: Props): void => {
    if (props) {
        setTimeout(() => {
            const param = getParam(props);
            const orderStorage = getOrderStorage(props);
            orderStorage.history.back = (orderStorage.history.back || 0) + length;
            setFondOrderStorage(param.order, orderStorage);
        }, 500);
    }
    window.history.go(length * -1);
};

export const cancel = (props: Props, backAddition = 0): void => {
    const orderStorage = getOrderStorage(props);
    const { history = {} } = orderStorage;
    const sorted = sortList(JSON.parse(JSON.stringify(history.lengthList || [])), 'id', false, true);
    const length = sorted[0].id;

    setTimeout(() => {
        props.action.setFondKalkulator(undefined);
    }, 100);

    if (length && props.history.length) {
        const delta = orderStorage.history.back || 0;
        const alfa = JSON.stringify((orderStorage.history || {}).lengthList) !== JSON.stringify(sorted) ? 1 : 0;
        const stepBack = window.location.href.match(/status/) ? 2 : orderStorage.stepBack || 0;
        const step = window.history.length - length - stepBack + alfa - delta + backAddition;

        if (step <= 0) {
            window.location.href = orderStorage.history.href;
        } else {
            const param = getParam(props);
            orderStorage.done = true;
            setFondOrderStorage(param.order, orderStorage);
            back(step);
        }
    } else if ((orderStorage.history || {}).href) {
        window.location.href = orderStorage.history.href;
    }
};

export const finish = (props: Props, state: State): void => {
    const orderStorage = getOrderStorage(props);
    const { init = {} } = orderStorage.history || {};
    const { reducer = {} } = state;

    let finishBack = orderStorage.finishBack || 0;
    if (init.placement && init.fund) {
        props.action.setVentendeTransaksjoner({
            portfolioId: init.placement,
            isin: init.fund,
            data: undefined,
        });
    }

    props.action.setSigneringUrl('');
    props.action.settKvitteringsInnhold(undefined);

    if (orderStorage.type === Constant.typeManedligSparing && !reducer.App?.isOnWebview) {
        if (!orderStorage.avsluttSpareavtale) {
            finishBack += /localhost/.test(window.location.href) ? 2 : 1;
        }
    } else {
        props.action.setPortefolje(undefined);
        props.action.getPortefolje();
        props.action.getListeOverCRSLand();
        props.action.getSkjemaForKunde();
    }

    clearInterval(state.mode.current.avtalegiroTimer || 0);
    cancel(props, finishBack);
};

export const save = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    data = {},
    ignoreValidation = false,
    forceUpdate = false,
    portfolioData?: any,
    entry?: string
): void => {
    const param = getParam(props);
    const orderStorage = { ...getOrderStorage(props, param), ...data };
    setFondOrderStorage(param.order, orderStorage);

    if (!ignoreValidation) {
        const pin: any = {};
        for (const key in data) {
            pin[key] = 1;
        }
        verify(props, state, setState, pin, undefined, orderStorage, portfolioData, entry);
    } else if (forceUpdate) {
        setState({ ...state, force: new Date().getTime() });
    }
};

export const next = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    step?: number,
    search?: any,
    portfolioData?: any,
	  entry?: string
): void => {
    if (!step && !verify(props, state, setState, undefined, undefined, undefined, portfolioData, entry)) {
        return;
    }

    const param = getParam(props);
    const orderStorage = getOrderStorage(props, param);
    const { history } = props;
    const splitedPathname = history.location.pathname.split('/');
    const current = splitedPathname.pop();

    if (!step) {
        if (!search) {
            search = {};
        }
        search.action = orderStorage.type === 'monthly' ? Constant.piwikActionMonthly : Constant.piwikActionOnetime;
    }

    if (step === undefined) {
        step = param.step + 1;
    } else if (step === null) {
        step = parseFloat(`${current || 0}`);
    }

    const init = orderStorage.history?.init || {};

    if (step === 1 && init.fund && init.skipFundSelecting) {
        step = 3;
    }

    if (step === 3 && orderStorage.action === Constant.actionVelgFond) {
        save(props, state, setState, {
            value: { ...(orderStorage.value || {}), isin: state.fund.pin[orderStorage.fund].isin },
        }, false, false, undefined, entry);
        createCookie('velgFond', param.order);
        return cancel(props);
    }

    if (
        step === 4 &&
        state.bankAccount.pin[orderStorage.bankAccount]?.bankkontoType === Constant.typeKlientkonto &&
        orderStorage.action !== Constant.actionSelg &&
        orderStorage.action !== Constant.actionUttak
    ) {
        step = 6;
    }
    if (
        step === 4 &&
        (orderStorage.action === Constant.actionBytt ||
            orderStorage.action === Constant.actionSelg ||
            orderStorage.action === Constant.actionUttak)
    ) {
        step = 8;
    } else if (step === 4 && ((init.fund && init.placement) || orderStorage.setMyFund)) {
        step = 6;
    } else if (
        step === 1 &&
        (orderStorage.action === Constant.actionSelg || orderStorage.action === Constant.actionUttak)
    ) {
        step = 3;
    } else if (step === 1 && orderStorage.action === Constant.actionKjop && orderStorage.setMyFund) {
        const fund = (state.fundPanelView?.myFundList || []).find(
            (d: any) => d.value === orderStorage.setMyFund.value && !!d.disabled
        );
        if (fund) {
            save(props, state, setState, { setMyFund: '' }, true);
        }
    } else if (
        step === 1 &&
        orderStorage.action === Constant.actionKjop &&
        orderStorage.initFund &&
        orderStorage.initPlacement
    ) {
        step = 3;
    } else if (step === 4 && orderStorage.action === Constant.actionKjop) {
        const placementAccountList = getPlacementAccountList(props, state, orderStorage) || [];
        if (!placementAccountList.length) {
            step = 5; // Go to create new ASK / VPK placement.
        } else if (!orderStorage.setMyFund) {
            if (
                orderStorage.placement &&
                state.placement.pin[orderStorage.placement] &&
                state.placement.pin[orderStorage.placement].disabled
            ) {
                save(props, state, setState, { placement: '' }, true);
            }
        }
    }

    if (step === 3 && orderStorage.action === Constant.actionKjop) {
        setupBankAccountOptionList(props, state, orderStorage);
    }

    const query = { ...(search || {}), ...getURLquery() };
    if (query.action === 'buy' && orderStorage.type) {
        query.action = orderStorage.type === 'ONCE' ? 'onetime' : 'monthly';
    }

    const searchList: string[] = [];
    for (const key in query) {
        searchList.push(`${key}=${query[key]}`);
    }

    history.push({
        pathname: splitedPathname.concat(`step${step}`).join('/'),
        search: searchList.length ? `?${searchList.join('&')}` : '',
    });

    if (step === 8 && !orderStorage.receipt) {
        submit(props, state, setState);
    }
};

/** ************************************************************************
 ************************************************************************* */
export const changeEmail = (props: Props, state: State, setState: (s: State) => void, data: any): void => {
    // const param = getParam( props );
    save(props, state, setState, { email: data.epost });
    // next( props, state, setState, (param.step === 200 ? 3 : 6));
    back(1, props);
};

export const toggleTerm = (props: Props, state: State, setState: (s: State) => void, key = ''): void => {
    const orderStorage = getOrderStorage(props);
    save(props, state, setState, { [key]: !orderStorage[key] });
};

export const displaySuggestedFund = (props: Props, state: State, setState: (s: State) => void, data?: any): void => {
    if (!data) {
        return;
    }

    const fund = data.fund || state.fund.pin[data.isin];
    // eslint-disable-next-line
    if (!fund) {
        return;
    } // shall display error message

    setState({ ...state, selectedSuggetion: data.selectedId });
    next(props, state, setState, 2, { view: 'fundselected', fundId: fund.id });

    scrollBodyTop(0);
};

export const useFund = (props: Props, state: State, setState: (s: State) => void, data: any): void => {
    const { fund } = state;
    const param = getParam(props);
    const orderStorage = getOrderStorage(props, param);
    const info: any = {
        fund: data.id,
        fundFilter: null,
        setMyFund: null,
        amountAttention: null,
        maxAmount: orderStorage.initedMaxAmount || null,
    };

    // force to revalidate minimum value at amount
    if (orderStorage.amount) {
        info.amount = orderStorage.amount;
    }

    if (orderStorage.placement) {
        const placementAccountList = getPlacementAccountList(props, state, { ...orderStorage, ...info });
        if (!placementAccountList.find((d: any) => d.id === orderStorage.placement)) {
            setTimeout(() => {
                save(props, state, setState, { placement: '' }, true, true);
            }, 100);
        }
    }

    if (fund.pin[info.fund] && fund.pin[info.fund].isin) {
        props.action.hentGyldigePortefoljer(fund.pin[info.fund].isin);
    }

    save(props, state, setState, info);
    delete state.error?.setMyFund;
    setState({ ...state });
    next(props, state, setState, 3);
};

export const setMyFund = (props: Props, state: State, setState: (s: State) => void, data: any): void => {
    const info: any = {
        fund: data.fundId,
        placement: data.placement,
        setMyFund: { value: data.value || '' },
    };

    save(props, state, setState, info, true, true);
    delete state.error?.setMyFund;
    setState({ ...state });
};

export const useMyFund = (props: Props, state: State, setState: (s: State) => void): void => {
    const param = getParam(props);
    const orderStorage = getOrderStorage(props, param);
    if (!orderStorage.setMyFund) {
        return setState({
            ...state,
            error: {
                ...(state.error || {}),
                setMyFund: 'Vennligst velg et av dine fond, eller velg et fond fra listen over alle fond',
            },
        });
    }

    const placement = (state.placement?.pin ?? {})[orderStorage.placement] || {};
    if (placement.sparing?.maksbelop) {
        const data: any = { maxAmount: placement.sparing.maksbelop };
        const left = data.maxAmount - (placement.sparing.aarligSparebelop || 0);
        if (left === data.maxAmount) {
            data.amountAttention = `Du kan spare inntil ${formatAmount(data.maxAmount)} kr i året på IPS-kontoen din.`;
        } else if (left < 100) {
            data.amountAttention = `Du kan spare inntil ${formatAmount(
                data.maxAmount
            )} kr i året på IPS-kontoen din. Du kan spare inntil ${formatAmount(
                left
            )} kr mer i år før kontoen blir full.`;
            data.maxAmount = left;
        }

        save(props, state, setState, data);
    } else {
        save(props, state, setState, { maxAmount: null, amountAttention: null }, true);
    }

    next(props, state, setState, 3);
};

export const setNewBankAccount = (props: Props, state: State, setState: (s: State) => void): void => {
    const orderStorage = getOrderStorage(props);
    if (!verify(props, state, setState, 'newBankAccount')) {
        return;
    }

    const value = `${orderStorage.newBankAccount || ''}`.replace(/\s+/g, '');
    const cloned = JSON.parse(JSON.stringify(state.bankAccount));
    // eslint-disable-next-line
    const selected = pushBankAccount(cloned, value);
    state.bankAccount = cloned;

    // eslint-disable-next-line
    click(props, state, setState, null, 'bankAccount-selected', { id: selected });
};

export const selectType = (props: Props, state: State, setState: (s: State) => void, data: any): void => {
    const orderStorage = getOrderStorage(props);
    if (orderStorage.action === Constant.actionSelg || orderStorage.action === Constant.actionUttak) {
        const saveData = { type: data.id || data.value, bankAccount: '' };
        if (saveData.type === Constant.typeKlientkonto) {
            saveData.bankAccount =
                (((state.placement.pin || {}).klientkonto || {})[orderStorage.placement] || {}).kontonummer || '';
        }
        save(props, state, setState, saveData, true, true);
    } else {
        const { fund } = state;
        if (
            data.id === Constant.typeManedligSparing &&
            fund.pin[orderStorage.fund] &&
            !fund.pin[orderStorage.fund].minimumsBelopPeriodiskKjop
        ) {
            setTimeout(() => {
                save(props, state, setState, { fund: '' }, true, true);
            }, 100);
        }

        save(props, state, setState, { type: data.id });
        next(props, state, setState);
    }
};

export const closeDialog = (props: Props, state: State, setState: (s: State) => void): State => {
    const { dialog } = state;
    const update = { ...state, dialog: '', suggestedFund: null };
    setState(update);
    if (dialog === 'newBankAccount') {
        setElementFocus('fondhandel-bankAccount');
    } else if (dialog === 'viewFund') {
        setElementFocus('selected-fund-detail');
    }
    return update;
};

/** ************************************************************************
 ************************************************************************* */
export const blurAmount = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    portefoljeData?: any,
    entry?: string
): void => {
    const orderStorage = getOrderStorage(props);
    verify(props, state, setState, { amount: orderStorage.amount }, undefined, undefined, portefoljeData, entry);
};

export const setAmount = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    e: FormElementChangeEvent,
    portfolioData?: any,
    entry?: string
): void => {
    const ignoreValidation = !state.error.amount;
    save(
        props,
        state,
        setState,
        { amount: parseFloat(`${e.target.value}`.replace(/\s+/g, '')) },
        ignoreValidation,
        true,
        portfolioData,
        entry
    );
};

export const blurEmail = (props: Props, state: State, setState: (s: State) => void): void => {
    const orderStorage = getOrderStorage(props);
    verify(props, state, setState, { email: orderStorage.email });
};

export const setPlacementName = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    e: FormElementChangeEvent
): void => {
    save(props, state, setState, { placementName: e.target.value || '' });
    setState({ ...state, force: new Date().getTime() });
};

export const setEmail = (props: Props, state: State, setState: (s: State) => void, e: FormElementChangeEvent): void => {
    const ignoreValidation = !state.error.email;
    save(props, state, setState, { email: e.target.value }, ignoreValidation);
    setState({ ...state, force: new Date().getTime() });
};

export const blurBankAccount = (props: Props, state: State, setState: (s: State) => void): void => {
    const orderStorage = getOrderStorage(props);
    verify(props, state, setState, { newBankAccount: orderStorage.newBankAccount });
};

export const setBankAccount = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    e: FormElementChangeEvent
): void => {
    const ignoreValidation = !state.error.newBankAccount;
    save(props, state, setState, { newBankAccount: e.target.value }, ignoreValidation);
};

export const setBankRegNr = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    e: FormElementChangeEvent | FormEelementEvenTarget
): void => {
    const ignoreValidation = !state.error.bankregnr;
    save(props, state, setState, { bankregnr: e.target.value }, ignoreValidation);
    setState({ ...state, force: new Date().getTime() });
};

export const setAIS = (props: Props, state: State, setState: (s: State) => void, ais = false): void => {
    save(props, state, setState, { ais });
    setState({ ...state, force: new Date().getTime() });
};

export const blurAISvalue = (props: Props, state: State, setState: (s: State) => void): void => {
    const orderStorage = getOrderStorage(props);
    verify(props, state, setState, { aisValue: orderStorage.aisValue });
};

export const setAISvalue = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    e: FormElementChangeEvent
): void => {
    const ignoreValidation = !state.error.aisValue;
    const forceUpdate = !!e.target.value && !state.error.aisValue;
    save(props, state, setState, { aisValue: parseNumber(e.target.value, true) }, ignoreValidation, forceUpdate);
};

export const callbackKjennDinKunde = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    response: any
): void => {
    if (/^ok$/i.test(response.result)) {
        next(props, state, setState);
    } else if (/^MA_BEHANDLES_MANUELT$/i.test(response.result)) {
        setState({ ...state, prevent: true });
    }
};

export const setBankAccountSelected = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    data: any,
    desktop?: any
): void => {
    const param = getParam(props);
    const orderStorage = getOrderStorage(props);
    if (data.id === Constant.typeNyBankkonto) {
        if (desktop) {
            save(props, state, setState, { bankAccount: orderStorage.bankAccount || '' }, true);
            setState({ ...state, dialog: 'newBankAccount' });
        } else {
            next(props, state, setState, 103);
        }
    } else {
        const info: any = { bankAccount: data.id };
        if (orderStorage.action === Constant.actionSelg) {
            info.type = state.bankAccount.pin[data.id]?.category || Constant.typeBankkonto;
        }

        // Når belastningkonto er klientkont, må plassering være dens aksjesparekonto
        if (
            state.bankAccount.pin[info.bankAccount]?.bankkontoType === Constant.typeKlientkonto &&
            orderStorage.action !== Constant.actionSelg
        ) {
            info.placement = `${state.bankAccount.pin[info.bankAccount].portefoljeId}`;
            delete state.error?.placement;
        }

        save(props, state, setState, info);
        delete state.error?.bankAccount;
        setState({ ...state, dialog: '' });
        if (param.step > 100) {
            back(param.step === 102 ? 1 : 2, props);
        }
    }
};

export const setAISfrequencySelected = (props: Props, state: State, setState: (s: State) => void, data: any): void => {
    const orderStorage = getOrderStorage(props);
    const param: any = { aisFrequency: data.id };

    if (param.aisFrequency === Constant.typeArligSparing) {
        const month = state.aisFrequency.list.find((d: any) => !d.category);
        if (month) {
            param.aisFrequency = month.id;
        }
    }

    let ignorValide = false;
    if (
        state.aisFrequency.pin[orderStorage.aisFrequency]?.amount !== state.aisFrequency.pin[param.aisFrequency]?.amount
    ) {
        param.aisValue = '';
        ignorValide = true; //!orderStorage.aisValue;
    }
    save(props, state, setState, param, ignorValide, true);
};

export const setAISselected = (props: Props, state: State, setState: (s: State) => void): void => {
    const orderStorage = getOrderStorage(props);
    if (orderStorage.ais) {
        const verified = verify(props, state, setState, { aisValue: orderStorage.aisValue }, undefined, orderStorage);
        if (!verified) {
            return;
        }
    }
    back(1, props);
};

/** ************************************************************************
 ************************************************************************* */
export const click = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    e: MouseEvent | null,
    key = '',
    data?: any,
    desktop?: any,
    portfolioData?: any,
	  entry?: any
): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if (key === 'next') {
        next(props, state, setState, typeof data === 'number' ? data : undefined, undefined, portfolioData, entry);
    } else if (key === 'next-is-ais') {
        if (verify(props, state, setState, undefined, undefined, undefined, portfolioData)) {
            next(props, state, setState, 3.5);
        }
    } else if (key === 'after-step-ais') {
        if (verify(props, state, setState)) {
            next(props, state, setState, 4);
        }
    } else if (key === 'cancel') {
        cancel(props);
    } else if (key === 'finish') {
        finish(props, state);
    } else if (key === 'submit') {
        submit(props, state, setState);
    } else if (key === 'select-draw-day') {
        if (data && data.id) {
            save(props, state, setState, { drawDay: data.id });
        } else {
            next(props, state, setState, 101);
        }
    } else if (key === 'select-email') {
        next(props, state, setState, 100);
    } else if (key === 'fund-selected' && data) {
        const fund = data.fund || data;
        const param = getParam(props);
        const query: any = { view: 'fundselected', fundId: fund.id };
        if (param.fromStep) {
            query.fromStep = param.fromStep;
        }

        if (data.filter) {
            save(props, state, setState, { fundFilter: data.filter });
        }

        next(props, state, setState, 2, query);
    } else if ((key === 'draw-day-selected' || key === 'draw-day-selected-new-float') && data) {
        save(props, state, setState, { drawDay: data.id });
        if (key === 'draw-day-selected-new-float') {
            next(props, state, setState);
        } else {
            back(1, props);
        }
    } else if (key === 'select-bankAccount') {
        next(props, state, setState, 102);
    } else if (key === 'ais-selected') {
        setAISselected(props, state, setState);
    } else if (key === 'select-ais') {
        next(props, state, setState, 104);
    } else if (key === 'select-ais-frequency') {
        next(props, state, setState, 105);
    } else if (key === 'select-ais-frequency-monthly') {
        next(props, state, setState, 106);
    } else if ((key === 'aisFrequency-selected' || key === 'aisFrequency-selected-second-level') && data) {
        setAISfrequencySelected(props, state, setState, data);
        if (key === 'aisFrequency-selected-second-level') {
            back(1, props);
        }
    } else if (key === 'bankAccount-selected' && data) {
        setBankAccountSelected(props, state, setState, data, desktop);
    } else if (key === 'use-new-bank-account') {
        setNewBankAccount(props, state, setState);
    } else if (key === 'new-placement') {
        next(props, state, setState, 5);
    } else if (key === 'use-new-placement' && data) {
        save(props, state, setState, { placement: data.id });
        next(props, state, setState, 6);
    } else if (key === 'use-placement') {
        save(props, state, setState, { placement: data.id });
    } else if (key === 'use-placement-next') {
        const param = getParam(props);
        const orderStorage = getOrderStorage(props, param);
        if (/^\d{4,}$/.test(orderStorage.placement)) {
            next(props, state, setState, 6);
        } else {
            setState({ ...state, error: { ...(state.error || {}), placement: 'Vennligst velg en av alternativene.' } });
        }
    } else if (key === 'change-fund') {
        next(props, state, setState, 1, data ? { fromStep: data.id || data } : undefined);
    } else if (key === 'use-fund' && data) {
        // eslint-disable-next-line
        useFund(props, state, setState, data);
    } else if (key === 'type-selected' && data) {
        selectType(props, state, setState, data);
    } else if (key === 'bank-relation-selected' && data) {
        setBankRegNr(props, state, setState, { target: { value: data.id || '' } });
    } else if (key === 'toggle-term-placement') {
        toggleTerm(props, state, setState, 'termPlacement');
    } else if (key === 'toggle-term-fund') {
        toggleTerm(props, state, setState, 'termFund');
    } else if (key === 'toggle-fund-expand-card') {
        setState({ ...state, isExpanded: !state.isExpanded });
    } else if (key === 'toggle-salg-understand') {
        const orderStorage = getOrderStorage(props);
        save(props, state, setState, { salgUnderstand: !orderStorage.salgUnderstand });
    } else if (key === 'open-foreign-currency-info') {
        setState({ ...state, dialog: 'foreign-currency' });
    } else if (key === 'open-account-agreement') {
        setState({ ...state, dialog: 'viewaccountagreement' });
    } else if (key === 'open-key-info') {
        setState({ ...state, dialog: 'viewkeyinfo' });
    } else if (key === 'open-view-fund' && data) {
        setState({ ...state, dialog: 'viewFund', suggestedFund: data });
    } else if (key === 'fund-suggested' && data) {
        displaySuggestedFund(props, state, setState, data);
    } else if (key === 'close-dialog') {
        closeDialog(props, state, setState);
    } else if (key === 'reset-amount') {
        save(props, state, setState, { amount: 0 }, true, true);
    } else if (key === 'use-whole-balance' || key === 'use-max-amount') {
        const orderStorage = getOrderStorage(props);
        if (orderStorage.maxAmount) {
            if (key === 'use-max-amount') {
                save(props, state, setState, { amount: orderStorage.maxAmount });
            } else {
                save(props, state, setState, {
                    amount: orderStorage.maxAmount,
                    wholeBalance: !orderStorage.wholeBalance,
                });
            }
        }
    } else if (key === 'change-fund-panel-view' && data && data.id) {
        setState({
            ...state,
            fundPanelView: {
                ...state.fundPanelView,
                selected: data.id,
            },
        });
    } else if (key === 'set-my-fund') {
        setMyFund(props, state, setState, data);
    } else if (key === 'use-my-fund') {
        // eslint-disable-next-line
        useMyFund(props, state, setState);
    } else if (key === 'open-calkulator') {
        next(props, state, setState, 200);
    } else if (key === 'create-avtalegiro') {
        displayAvtalegiro(props, state, setState);
    } else if (key === 'to-last-step') {
        next(props, state, setState, 8);
    } else if (key === 'retry-selg-fond' || key === 'retry-uttak') {
        submit(props, state, setState, {
            failed: false,
            canceled: false,
            signeringStatus: false,
            signeringsUrl: '',
            finished: false,
            visSignering: 0,
        });
    } else if (key === 'contact-us') {
        openContactUs();
    } else if (key === 'delete-fond-agreement') {
        setState({ ...state, dialog: 'confirmToDeleteFundAgreement' });
    } else if (key === 'yes-delete-fond-agreement') {
        save(props, state, setState, { avsluttSpareavtale: 1 }, true);
        const updated = closeDialog(props, state, setState);
        next(props, updated, setState, 8);
    } else if (key === 'open-ais-saving-plan') {
        setState({ ...state, dialog: 'aisSavingPlan' });
    }
};
