/**
 * Created by vagrant on 01.03.17.
 */

class ColorFunctions {
    static hentFarge = index => {
        const farger = [
            '#84BD01',
            '#3396B9',
            '#6E6259',
            '#004F5A',
            '#5FC3E6',
            '#AB5895',
            '#D5CFCB',
            '#918073',
            '#CFA5C3',
            '#408500',
            '#B1A297'
        ];

        return index === 0 || index ? farger[index % farger.length] : '#d2d2d2';
    };

    static hentGradient = index => {
        const gradient = [
            { start: '#84bd01', end: '#9ED71B' },
            { start: '#3396b9', end: '#4DB0D3' },
            { start: '#6A5C57', end: '#796D66' },
            { start: '#004f5a', end: '#1A6974' },
            { start: '#5fc3e6', end: '#79DDFF' },
            { start: '#ab5895', end: '#C572AF' },
            { start: '#d5cfcb', end: '#EFE9E5' },
            { start: '#918073', end: '#AB9A8D' },
            { start: '#cfa5c3', end: '#E9BFDD' },
            { start: '#408500', end: '#5A9F1A' },
            { start: '#b1a297', end: '#CBBCB1' }
        ];
        return gradient[index % gradient.length];
    };

    static hentRoedFarge = () => {
        return '#ac333b';
    };

    static hentGraaFarge = () => {
        return 'rgba(128, 128, 128, 0.25)';
    };
}

export default ColorFunctions;
