import {
    LAST_PORTEFOLJE,
    LAST_MORNINGSTAR_DATA,
    HENT_GYLDIGE_PORTEFOLJER,
    KJOP_UTFORT,
    LOADING,
    AVTALE_GIRO,
    HENT_HISTORIKK,
    HENT_AARLIG_SPARE_BELOP,
    OPPDATER_PORTEFOLJER,
    HENT_SKATTEMESSIGKOSTPRIS,
    HENT_ESTIMAT,
    HENT_PROGNOSE,
    HENT_PORTEFOLJE,
    HENT_DISPONERTE_PORTEFOLJE,
    SET_VPS,
} from '../actions/fond';

import { IPS_OPPRETTET } from '../actions/pensjon';

const initialState = {
    kjopStegStatus: {
        done: true,
        success: true,
    },
    loading: false,
    gyldigePortefoljer: {},
    disponertePortefoljer: [],
    harASKKompatibleFondIEVPK: false,
    skattemessigKostpris: {},
    portefoljeFraBeholdning: {},
    lastetDisponertePortefoljer: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HENT_HISTORIKK: {
            const { data } = action;
            const historikk = data.data ? data.data : null;

            return {
                ...state,
                portefoljeData: {
                    ...state.portefoljeData,
                    historikk: {
                        ...state.portefoljeData.historikk,
                        [`${data.portfolioBaseCode}${data.isin}`]: historikk,
                    },
                },
            };
        }
        case HENT_PORTEFOLJE: {
            return {
                ...state,
                loading: false,
                portefoljeFraBeholdning: action.data.data,
            };
        }
        case LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case HENT_DISPONERTE_PORTEFOLJE: {
            return {
                ...state,
                lastetDisponertePortefoljer: true,
                disponertePortefoljer: action.data,
            };
        }
        case OPPDATER_PORTEFOLJER: {
            return {
                ...state,
                portefoljeData: {
                    ...state.portefoljeData,
                    ...action.data,
                },
            };
        }
        case HENT_AARLIG_SPARE_BELOP: {
            return {
                ...state,
                portefoljeData: {
                    ...state.portefoljeData,
                    sparebelop: {
                        ...state.portefoljeData.sparebelop,
                        [`${action.data.portfolioBaseCode}`]: action.data.data.aarligSparebelop,
                    },
                },
            };
        }
        case HENT_GYLDIGE_PORTEFOLJER:
            return {
                ...state,
                gyldigePortefoljer: {
                    ...state.gyldigePortefoljer,
                    portefoljeTilknyttetAnnenBank: action.data.data.portefoljeTilknyttetAnnenBank,
                    [action.data.isin]: action.data.data.portefoljer,
                },
            };
        case SET_VPS:
            return {
                ...state,
                vspList: action.data ,
                portefoljeData: action.data ? {
                    ...(state.portefoljeData || {}),
                    portefoljer: ((state.portefoljeData ||{}).portefoljer || []).concat( action.data ).filter( (d) => !!d ),
                } : state.portefoljeData
            };
        // Temporary fix for getting VPS account.
        // Those accounts will later appear in the new API.
        /* eslint-disable */
        case LAST_PORTEFOLJE:
            return {
                ...state,
                portefoljeData: action.data ? {
                    ...action.data,
                    portefoljer: (action.data.portefoljer || []).concat( (state.vspList || []) ).filter( (d) => !!d), 
                    historikk: {},
                    sparebelop: {}
                } : (state.vpsList ? { portefoljer: state.vspList, sparebelop: {}, historikk: {}, } : null),
            };
        /*
        case LAST_PORTEFOLJE:
            return {
                ...state,
                portefoljeData: {
                    ...action.data,
                    historikk: {},
                    sparebelop: {},
                },
            };
        */
        case LAST_MORNINGSTAR_DATA:
            return {
                ...state,
                morningstar: action.data,
            };
        case KJOP_UTFORT: {
            if (action.portefoljer) {
                return {
                    ...state,
                    kjopsStatus: {
                        ...action.kjopsStatus,
                    },
                    portefoljeData: action.portefoljer,
                };
            }

            return {
                ...state,
                kjopsStatus: {
                    ...action.kjopsStatus,
                },
            };
        }
        case AVTALE_GIRO:
            return {
                ...state,
                avtalegiroUrl: action.avtalegiroUrl,
                avtalegiroObject: action.avtalegiroObject,
            };
        case IPS_OPPRETTET: {
            return {
                ...state,
                portefoljeData: action.portefoljer,
            };
        }
        case HENT_SKATTEMESSIGKOSTPRIS: {
            const { portfolioBaseCode } = action.data;

            return {
                ...state,
                skattemessigKostpris: {
                    ...state.skattemessigKostpris,
                    [portfolioBaseCode]: action.data,
                },
            };
        }
        case HENT_ESTIMAT: {
            return {
                ...state,
                fondEstimat: action.data,
            };
        }
        case HENT_PROGNOSE: {
            return {
                ...state,
                fondsPrognose: action.data,
            };
        }
        default:
            return state;
    }
};
