import Dictionary from '../static/data/Dictionary';

const LOAD_TEXTS = 'LOAD_TEXTS';

/* eslint-disable */
const createRegexp = (text='', g=0, i=0, b=0, f=0, ignorReplacing=false ) => {
    if (text === '*') { return /.*/; }
    const v = ignorReplacing ? text : text
        .replace(/\*/, '.*')
        .replace(/\+/g, '\\+')
        .replace(/\(/g, '\\(')
        .replace(/\)/g, '\\)')
        .replace(/\?/g, '\\?')
        .replace(/\-/g, '\\-')
        .replace(/\[/g, '\\[')
        .replace(/\]/g, '\\]')
        .replace(/\$/g, '\\$');

    const m = g && i ? 'gi' : g || i ? (g ? 'g' : 'i') : '';
    const s = b ? (b === 2 ? '^' : b === 3 ? '(^|/|\\s+|,|\\()' : '(^|/|\\s+)') : '';
    const e = f ? (f === 2 ? '$' : f === 3 ? '($|/|\\s+|,|\\))' : '($|/|\\s+)') : '';
    return new RegExp(`${s}(${v})${e}`, m);
};
/* eslint-enable */

const initialState = {
    setText: (textsInput) => {
        const texts = textsInput;

        const toLowerCase = (key) => {
            return key && key.toString().toLocaleLowerCase();
        };

        const has = (key) => {
            return !!texts[toLowerCase(key)];
        };

        const get = (key='', values={}) => {
            const lowerCase = toLowerCase(key);
            let output = (texts || {})[key] || (texts || {})[lowerCase] || (Dictionary.norsk || {})[key] || key;
            for ( const variable in values) {
                const reg = createRegexp(`{${variable}}`, 1, 1 );
                output = output.replace( reg, values[variable] );
            }
            return output.trim();
        };

        return { has, get,};
    },
};

const changeKeyToLowerCase = (newTexts, key, texts) => {
    if (newTexts[key.toLowerCase()]) {
        // eslint-disable-next-line no-console
        console.warn(`Duplicate key: ${key}`);
    }

    newTexts[key.toLowerCase()] = texts[key];
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_TEXTS: {
            let texts = {};
            if (action.data) {
                if (action.data.data) {
                    if (action.data.data.text) {
                        texts = action.data.data.text;
                    }
                }
            }
            return {
                ...state,
                texts: state.setText(
                    Object.keys(texts).reduce(
                        // eslint-disable-next-line no-sequences
                        (newTexts, key) => (changeKeyToLowerCase(newTexts, key, texts), newTexts),
                        {}
                    )
                ),
            };
        }
        default:
            return state;
    }
};
