import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';

import 'unfetch/polyfill';
import '../components/polyfills/find';
import '../components/polyfills/includes';
import Feilmelding from '../components/feilmelding';

import { logClientSideError } from '../actions/common';

import {
    endreAlleSamtykker,
    endreSamtykkeType,
    hentKundeValg,
    lastSamtykke,
    lastSpareProfil,
    getTerms,
    postTerms
} from '../actions/spareprofil';

import { lastMorningstarData, lastPortefolje, getVPS } from '../actions/fond';
import { lastarbeidsgiversPensjonssparing } from '../actions/pensjon';

import { hentKundevurdering, hentOppfolgingSkjemaForKunde, setOppfolgingSkjemaForKunde } from '../actions/kjenndinkunde';

import { hentKontoer } from '../actions/bank';
import { MAKS_ALDER_FOR_PENSJONSBEREGNING } from '../domain/pensjons-constants';
import MaaGodkjennes from '../components/vilkaar/maa-godkjennes';
import Terms from '../components/vilkaar/Terms';
import * as VILKAARTYPES from '../domain/vilkaar-types';
import { settSideTittel, getServiceMessage } from '../actions/header';
import * as kundevurderingConst from '../domain/kundevurdering';
import KjennDinKundeForm from '../components/kjenndinkunde/KjennDinKundeForm';
import AvtalegiroModal from '../components/avtalegiro/avtalegiro-modal';
import Constant from '../static/data/Constant';

require('es6-promise/auto');

const kjennDinKundeIsBesvart = (kdkVurdering) =>
    [kundevurderingConst.OK, kundevurderingConst.MA_BEHANDLES_MANUELT].includes(kdkVurdering);

class Sparing extends Component {
    static propTypes = {
        children: PropTypes.object,
        lastSpareProfil: PropTypes.func,
        hentKontoer: PropTypes.func,
        hentKundeValg: PropTypes.func,
        lastPortefolje: PropTypes.func,
        lastMorningstarData: PropTypes.func,
        lastSamtykke: PropTypes.func,
        endreAlleSamtykker: PropTypes.func,
        texts: PropTypes.object,
        errors: PropTypes.object,
        logClientSideError: PropTypes.func,
        endreSamtykkeType: PropTypes.func,
        samtykker: PropTypes.object,
        settSideTittel: PropTypes.func,
        kundeInfo: PropTypes.object,
        hentKundevurdering: PropTypes.func,
        kundevurdering: PropTypes.string,
        oppfolging: PropTypes.string,
        portefoljeData: PropTypes.shape({
            totalVerdi: PropTypes.number,
        }),
        lastarbeidsgiversPensjonssparing: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            harLastetAppDataSomKreverSamtykke: false,
        };
    }

    componentDidMount = () => {
        this.props.getTerms();
        this.props.hentKontoer();
        this.props.hentKundeValg();
        this.props.getVPS();
        this.props.lastPortefolje();
        this.props.lastSpareProfil();
        this.props.lastMorningstarData();
        this.props.lastSamtykke();
        this.props.hentKundevurdering();
        this.props.hentSkjemaForOppfolging();
        this.props.getServiceMessage();

        this.props.settSideTittel(this.props.texts.get('appLaster'));

        window.addEventListener('error', (e) => this.props.logClientSideError(e.error.message, e.error.stack));
    };

    lastAppDataSomKreverSamtykke = (newProps) => {
        if (newProps.kundeInfo && newProps.kundeInfo.alder < MAKS_ALDER_FOR_PENSJONSBEREGNING) {
            this.props.lastarbeidsgiversPensjonssparing();
            return true;
        }
        return false;
    };

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.samtykker) {
            if (this.alleSamtykkerGodtatt(newProps) && !this.state.harLastetAppDataSomKreverSamtykke) {
                this.setState({
                    harLastetAppDataSomKreverSamtykke: this.lastAppDataSomKreverSamtykke(newProps),
                });
            }

            if (!this.alleSamtykkerGodtatt(newProps)) {
                this.props.settSideTittel(this.props.texts.get('godtaVilkaar'));
            }
        }
    };

    alleSamtykkerGodtatt = (props) => props.samtykker && props.samtykker.godtattAlle && this.gdprGodtatt(props);

    gdprGodtatt = (props) => {
        const gdpr = props.samtykker.valg.find((valg) => valg.samtykkeType === VILKAARTYPES.GDPR);
        return gdpr && gdpr.samtykket;
    };

    harFondBeholdning = () => (this.props.portefoljeData || {}).totalVerdi > 0;

    sjekkForServerFeil = (errors) => errors.LAST_SPARE_PROFIL_DATA || errors.LAST_SAMTYKKE;

    besvarAhv = () => (
        <div id="sparing-react-app">
            <KjennDinKundeForm
                rotNodeId="#sparing-react-app"
                loadingText={this.props.texts.get('lasterSparingsApp')}
                onSubmit={() => {
                    this.props.setSkjemaForOppfolging( null );
                }}
                onFail={() => {
                    window.location.reload();
                }}
            />
        </div>
    );

    postTerms = () => {
        if ( !this.state.term || !this.props.terms ) { return; }
        this.props.postTerms( this.props.terms, this.state.term );
    }

    render() {
        const { errors, texts, kundeInfo, kundevurdering, oppfolging, children } = this.props;
        const { term, condistionList=[] } = this.state;

        if (this.sjekkForServerFeil(errors)) {
            return (
                <div id="sparing-react-app">
                    <Feilmelding feil={errors} texts={texts} />
                </div>
            );
        }
        if (!kundeInfo) {
            return (
                <div id="sparing-react-app">
                    <Loading title={this.props.texts.get('henterKundeinformasjon')} />
                </div>
            );
        }
        if (kundeInfo.alder < 18) {
            return (
                <div id="sparing-react-app">
                    <span>{texts.get('under18aar')}</span>
                </div>
            );
        }

        if ( term ) {
            return <div id="sparing-react-app">
                {
                    term.consentCode === Constant.termOfuse || term.consentCode === Constant.termOfGDPR ? 
                        <MaaGodkjennes 
                            {...this.props}
                            nyKunde={!(this.props.samtykker?.valg ?? []).find((d) => !!d.samtykket)}
                            headline={condistionList.length > 1 ? `Samtykke ${term.index + 1} av ${condistionList.length}` : ''}
                        />
                        :
                        <Terms 
                            {...this.props} 
                            term={term} 
                            submit={this.postTerms} 
                            headline={condistionList.length > 1 ? `Samtykke ${term.index + 1} av ${condistionList.length}` : ''}
                        />                  
                }
            </div>
        }

        if (oppfolging || (!kjennDinKundeIsBesvart(kundevurdering) && this.harFondBeholdning()) ) {
            return this.besvarAhv();
        }

        return <div id="sparing-react-app">
            {children}
            <AvtalegiroModal/>
        </div>;
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        const { samtykker, terms, samtykkeTekster } = nextProps || {};

        if ( nextState.termList === undefined && samtykker && samtykkeTekster ) {
            nextState.termList = [];

            if ( !samtykker.godtattAlle ) {
                nextState.termList.push({consentCode: Constant.termOfuse, done: false});
            }

            const needGDPR = samtykkeTekster.find( (d) => d.samtykkeType === 'gdpr' );
            const selectedGDPR = (samtykker.valg || []).find( (d) => d.samtykkeType === 'gdpr' );
            if ( needGDPR && !selectedGDPR ) {
                nextState.termList.push({consentCode: Constant.termOfGDPR, done: false});        
            }
        }

        nextState.condistionList = (nextState.termList || []).map( (term) => {
            const data = {...term};
            if ( data.consentCode === Constant.termOfGDPR ) {
                data.done = this.gdprGodtatt(nextProps);
            } else if ( data.consentCode === Constant.termOfuse ) {
                data.done = samtykker.godtattAlle;                
            }
            return data;
        }).concat( terms ).filter( (d) => !!d && !!d.consentCode).map( (d, i) => {
            return {...d, index: i, value: true};
        });

        nextState.term = nextState.condistionList.find( (d) => !d.done);
    }
}

export default connect(
    (state) => {
        return {
            ...state.SpareProfil,
            ...state.Feilmeldinger,
            ...state.Texts,
            kundevurdering: state.KjennDinKunde.kundevurdering,
            oppfolging:
                state.KjennDinKunde.oppfolgingSkjemaForKunde &&
                state.KjennDinKunde.oppfolgingSkjemaForKunde.erklaringtype
                    ? state.KjennDinKunde.oppfolgingSkjemaForKunde.erklaringtype
                    : undefined,
            ...state.Fond,
        };
    },
    (dispatch) => {
        return {
            endreAlleSamtykker: () => {
                dispatch(endreAlleSamtykker());
            },
            lastSpareProfil: () => {
                dispatch(lastSpareProfil());
            },
            hentKontoer: () => {
                dispatch(hentKontoer());
            },
            getVPS: () => {
                dispatch(getVPS());
            },
            lastPortefolje: () => {
                dispatch(lastPortefolje());
            },
            lastMorningstarData: () => {
                dispatch(lastMorningstarData());
            },
            lastSamtykke: () => {
                dispatch(lastSamtykke());
            },
            getTerms: () => {
                dispatch(getTerms());
            },
            postTerms: (terms, term) => {
                dispatch(postTerms(terms, term));
            },
            hentKundeValg: () => {
                dispatch(hentKundeValg());
            },
            logClientSideError: (message, stack) => {
                dispatch(logClientSideError(message, stack));
            },
            endreSamtykkeType: (samtykke) => {
                dispatch(endreSamtykkeType(samtykke));
            },
            settSideTittel: (tittel) => {
                dispatch(settSideTittel(tittel));
            },
            hentKundevurdering: () => {
                dispatch(hentKundevurdering());
            },
            hentSkjemaForOppfolging: () => {
                dispatch(hentOppfolgingSkjemaForKunde());
            },
            setSkjemaForOppfolging: ( data ) => {
                dispatch(setOppfolgingSkjemaForKunde(data));
            },
            lastarbeidsgiversPensjonssparing: () => {
                dispatch(lastarbeidsgiversPensjonssparing());
            },
            getServiceMessage: (app) => {
                dispatch(getServiceMessage(app));
            },
        };
    }
)(Sparing);
