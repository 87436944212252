import React, { Component } from 'react';
import LinkCard from '../link-card';
import Format from '../format-functions';
import Helper from '../helper-functions';
// import avtaleUtlistingType from '../../domain/prop-types/pensjonsPropTypes';

class AvtaleUtlisting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            avtaler: undefined
        };
    }

    componentDidMount() {
        const { avtaler, gradient, texts } = this.props;
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ avtaler: this.listUtAvtaler(avtaler, gradient, texts) });
    }

    visAvtalekort = (avtale, gradient, texts) => {
        return <LinkCard key={Helper.guid()} gradient={gradient} link={
            /* eslint-disable */
            avtale.avtaleinnhold && avtale.id ? `arbeidsgiversSparing/avtale/${avtale.id}` : (
                avtale.riskProfile ? 
                    `arbeidsgiversSparing/${window.encodeURIComponent(avtale.riskProfile.toLowerCase().replace(/\s+/g,''))}?innskuddssaldo=${avtale.innskuddssaldo}&produkt=${avtale.produktbetegnelse}` 
                    :
                    undefined
            )
            /* eslint-enable */
        }>
            <>
                {!!avtale.riskProfile && <div className="profile-holder">
                    <span className="profile-label">{avtale.riskProfile}</span>    
                </div>}
                <div className="card-content-table">
                    <div className="card-content-table-cell">
                        <div>{avtale.produktbetegnelse}</div>
                        {avtale.firmanavn && <div>{avtale.firmanavn}</div>}
                        {avtale.selskapsnavn && <div>{avtale.selskapsnavn}</div>}
                        {avtale.message && <span className={`message -${avtale.message.type || 'info'}`}>{avtale.message.text}</span>}                        
                    </div>
                    <div className="card-content-table-cell">
                        { (!!avtale.innskuddssaldo || !avtale.message) && <>
                            <div className="tekst">{texts.get('verdi')}</div>
                            <div className="verdi">{Format.currency(avtale.innskuddssaldo)}</div>
                        </>}
                    </div>
                </div>
            </>
        </LinkCard>
    };

    listUtAvtaler = (avtaler, gradient, texts) => avtaler.map(avtale => this.visAvtalekort(avtale, gradient, texts));

    render() {
        const { avtaler } = this.state;
        const { tittel } = this.props;
        return (
            <div className="avtale-utlisting">
                <div className="title">{tittel}</div>
                {avtaler}
            </div>
        );
    }
}

//AvtaleUtlisting.propTypes = avtaleUtlistingType;

export default AvtaleUtlisting;
