import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RadioButtons from '../../radio-buttons';
import Checkbox from '../../checkbox';
import Modal from '../../modal';
import AksjesparekontoVilkaar from '../../vilkaar/aksjesparekonto-vilkaar';
import ValidationMessages from '../../common/validation-messages';
import VelgKundeForhold from '../../bank/velg-kundeforhold';

class KjopFondVelgPortefolje extends Component {
    static propTypes = {
        aksjesparekontokompatibel: PropTypes.bool,
        portefoljer: PropTypes.array,
        velgPortefolje: PropTypes.func,
        opprettPortefolje: PropTypes.func,
        godtaOpprettPortefoljeVilkaar: PropTypes.func,
        texts: PropTypes.object,
        visHjelpetekst: PropTypes.bool,
        kundeforhold: PropTypes.array,
        velgKundeforhold: PropTypes.func,
        valgtKundeforhold: PropTypes.bool,
        portefoljeTilknyttetAnnenBank: PropTypes.bool,
        portefoljeData: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            portefoljeValg: this.genererPortefoljeValg(props.portefoljer, props.portefoljeData)
        };
    }

    velgPortefolje = (portefoljeValg, state) => {
        const portefolje = this.props.portefoljer.find(p => p.id === portefoljeValg.id);

        this.props.velgPortefolje(portefolje.id, portefolje.bank.bankregnr);

        return {
            ...state,
            opprettPortefolje: false,
            maaGodtaVilkaar: false
        };
    };

    opprettPortefolje = (portefoljeValg, state) => {
        const type = portefoljeValg.opprettPortefolje;
        const maaGodtaVilkaar = type === 'ASK';

        this.props.opprettPortefolje(
            type,
            maaGodtaVilkaar,
            this.props.kundeforhold.length === 1 ? this.props.kundeforhold[0].bankregnr : undefined
        );

        return {
            ...state,
            maaGodtaVilkaar,
            type,
            opprettPortefolje: true
        };
    };

    registrerPortefoljeValg = portefoljeValg => {
        let state = {
            portefoljeValgt: true,
            godtattVilkaar: false
        };

        state = portefoljeValg.opprettPortefolje
            ? this.opprettPortefolje(portefoljeValg, state)
            : this.velgPortefolje(portefoljeValg, state);

        this.setState(state);
    };

    godtaVilkaar = checked => {
        this.setState({ godtattVilkaar: checked });
        this.props.godtaOpprettPortefoljeVilkaar(checked);
    };

    toggleVilkaar = () =>
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            visVilkaarTekst: !this.state.visVilkaarTekst
        });

    visVelgKundeforhold = kundeforhold => {
        if (kundeforhold.length > 1 && this.state.opprettPortefolje) {
            return (
                <VelgKundeForhold
                    kundeforhold={kundeforhold}
                    label={this.props.texts.get('velgBank')}
                    defaultValue={this.props.texts.get('ikkeValgt')}
                    onChange={this.props.velgKundeforhold}
                    showValidationMessages={this.props.visHjelpetekst}
                    validationMessages={[
                        {
                            condition: !this.props.valgtKundeforhold,
                            message: this.props.texts.get('velgKundeforhold')
                        }
                    ]}
                />
            );
        }

        return null;
    };

    visOpprettKontoVilkaar = () =>
        this.state.maaGodtaVilkaar ? (
            <div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <div onClick={this.toggleVilkaar} className="underline">
                    {this.props.texts.get('lesOpprettASKVilkaareneHer')}
                </div>
                <Checkbox
                    id={this.state.type}
                    name={this.state.type}
                    state={this.state.godtattVilkaar}
                    onChange={this.godtaVilkaar}
                >
                    <div>
                        <div>{this.props.texts.get('jegGodtarVilkaarene')}</div>
                        <ValidationMessages
                            showValidationMessages={this.props.visHjelpetekst}
                            validationMessages={[
                                {
                                    condition: this.state.maaGodtaVilkaar && !this.state.godtattVilkaar,
                                    message: this.props.texts.get('lesOpprettASKVilkaareneHer')
                                }
                            ]}
                        />
                    </div>
                </Checkbox>
            </div>
        ) : null;

    appendBankName = list =>
        list.map(item => ({
            ...item,
            navn: (
                <div>
                    <span>{item.navn}</span>
                    <span className="bank-navn">&nbsp;({item.bank.banknavn})</span>
                </div>
            )
        }));

    genererPortefoljeValg = (portefoljer, portefoljeData) => {
        const portefoljeValg = (this.props.portefoljeTilknyttetAnnenBank
            ? this.appendBankName(portefoljer)
            : portefoljer.slice()
        ).filter( (data) => this.props.aksjesparekontokompatibel ? (data.type !== 'VPK') : (data.type !== 'ASK') );

        const sperre = (portefoljeData?.portefoljer ?? []).reduce( (pin, data) => {
            pin[data.id] = data.sperre === 'FULL';
            return pin;
        }, {});

        portefoljeValg.forEach( (data) => {
            if ( sperre[data.id] ) {
                data.disabled = true;
                data.navn += ' (sperret)';
            }
        });

        if (this.props.aksjesparekontokompatibel) {
            portefoljeValg.unshift({
                id: 'OpprettASK',
                opprettPortefolje: 'ASK',
                navn: this.props.texts.get('opprettAksjesparekonto')
            });
        } else {
            portefoljeValg.unshift({
                id: 'OpprettVPK',
                opprettPortefolje: 'VPK',
                navn: this.props.texts.get('opprettEikaVerdiPapirKonto')
            });
        }

        return portefoljeValg;
    };

    render = () => (
        <div>
            <Modal visModal={this.state.visVilkaarTekst} lukkModal={this.toggleVilkaar}>
                <AksjesparekontoVilkaar />
            </Modal>

            <div className="fond-skjema-seksjon">
                <RadioButtons
                    legend={this.props.texts.get('hvorVilDuLeggeAksjene')}
                    items={this.state.portefoljeValg}
                    onChange={this.registrerPortefoljeValg}
                />
                <ValidationMessages
                    showValidationMessages={this.props.visHjelpetekst}
                    validationMessages={[
                        {
                            condition: !this.state.portefoljeValgt,
                            message: this.props.texts.get('duMaaVelgePortefolje')
                        }
                    ]}
                />
                {this.visVelgKundeforhold(this.props.kundeforhold)}
                {this.visOpprettKontoVilkaar()}
            </div>
        </div>
    );
}

export default KjopFondVelgPortefolje;
