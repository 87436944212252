import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Format from '../format-functions';
import ValidateFunctions from '../validation-functions';
import NumberInput from '../common/number-input';
import TextInput from '../common/text-input';
import ValidationMessages from '../common/validation-messages';

require('../../styles/checkboxLegend.scss');

class CheckboxLegend extends Component {
    static propTypes = {
        item: PropTypes.object,
        onDelete: PropTypes.func,
        changeContent: PropTypes.func,
        cancelChange: PropTypes.func,
        config: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            text: props.item.text,
            value: props.item.value
        };
    }

    changeContent = id => {
        const text = Format.fjernUnodvendigeMellomrom(this.state.text);

        if (ValidateFunctions.tekstenErMellom(text, 2, 25)) {
            this.props.changeContent(id, text, this.state.value);
        } else {
            this.setState({
                visHjelpeTekst: true
            });
        }
    };

    visHjelpeTekst = () =>
        !this.state.textValidated && this.state.visHjelpeTekst ? (
            <div className="hjelpe-tekst">{this.props.config.kontoNavnHjelpeTekst}</div>
        ) : null;

    changeText = event =>
        this.setState({
            text: event.target.value,
            textValidated: ValidateFunctions.tekstenErMellom(event.target.value, 2, 25)
        });

    changeValue = event => {
        const number = parseInt(event.target.value || 0, 10);
        this.setState({ value: isNaN(number) || typeof(number) !== 'number' ? 0 : number });
    }

    render = () => (
        <div className="rediger-kategori" key={this.props.item.id}>
            <TextInput
                id="legendText"
                label={this.props.config.endreNavn}
                onChange={this.changeText}
                value={this.state.text}
            />

            <ValidationMessages
                showValidationMessages={this.state.visHjelpetekst}
                validationMessages={[
                    {
                        condition: this.state.textValidated,
                        message: this.props.config.kontoNavnHjelpeTekst
                    }
                ]}
            />

            <NumberInput
                id="belopPaaKonto"
                label={this.props.config.endreVerdi}
                value={this.state.value}
                onChange={this.changeValue}
            />

            <button type="button" className="btn btn-flat" onClick={this.props.cancelChange}>
                Avbryt
            </button>
            <button type="button" className="btn btn-primary" onClick={() => this.props.onDelete(this.props.item.id)}>
                Slett
            </button>
            <button type="button" className="btn btn-primary" onClick={() => this.changeContent(this.props.item.id)}>
                Lagre
            </button>
        </div>
    );
}

export default CheckboxLegend;
