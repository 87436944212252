import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Format from '../format-functions';
import EditerbarCheckboxLegendField from './editer-checkbox-legend-field';

require('../../styles/checkboxLegend.scss');

class CheckboxLegend extends Component {
    static propTypes = {
        data: PropTypes.array,
        toggleActive: PropTypes.func,
        onDelete: PropTypes.func,
        changeContent: PropTypes.func,
        config: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            content: this.createLegendFields(props.data)
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const config = {
            editId: this.state.editId
        };
        this.setState({
            content: this.createLegendFields(nextProps.data, config)
        });
    }

    createLegendFields = (data, config = {}) => {
        return data.map(item => {
            if (this.showInLegend(item)) {
                if (item.id === config.editId && item.id !== undefined) {
                    return (
                        <EditerbarCheckboxLegendField
                            key={item.id}
                            item={item}
                            onDelete={this.props.onDelete}
                            changeContent={this.changeContent}
                            cancelChange={this.cancelChange}
                            config={this.props.config}
                        />
                    );
                }
                const mergedConfig = {
                    loading: (item.id === config.loadingId && item.id !== undefined) || item.loading,
                    disabled: (item.id === config.disabledId && item.id !== undefined) || item.disabled
                };

                return this.createLegendField(item, mergedConfig);
            }

            return '';
        });
    };

    showInLegend = item => {
        return item.showInLegend === undefined || item.showInLegend;
    };

    cancelChange = () => {
        this.setState({
            editId: undefined,
            content: this.createLegendFields(this.props.data)
        });
    };

    changeContent = (id, text, value) => {
        const config = {
            loadingId: id,
            disabledId: id
        };

        this.setState({
            editId: undefined,
            content: this.createLegendFields(this.props.data, config)
        });

        this.props.changeContent(id, text, value);
    };

    createLegendField = (item, config) => {
        const bgcolor = item.active
            ? {
                  backgroundColor: item.color,
                  border: `1px solid ${item.color}`
              }
            : null;

        const disabledClass = config.disabled ? 'disabled' : '';

        return (
            <div key={item.id} className={`legend-container ${disabledClass}`}>
                {item.toggleable ? this.checkBoxHtml(item, bgcolor, config) : this.dotHtml(item, bgcolor)}
                {item.editable ? this.editerbarKategoriHtml(item) : this.kategoriVerdiHtml(item)}
            </div>
        );
    };

    settEditerKategori = id => {
        const config = {
            editId: id
        };

        this.setState({
            editId: id,
            content: this.createLegendFields(this.props.data, config)
        });
    };

    editerbarKategoriHtml = item => {
        return (
            <button
                type="button"
                className="value editable"
                onClick={() => {
                    this.settEditerKategori(item.id);
                }}
            >
                <div className="ikon-penn-24" />
                {/* eslint-disable-next-line no-restricted-globals */}
                <div>{item.value === null || isNaN(item.value) ? '-' : Format.currency(item.value)}</div>
            </button>
        );
    };

    kategoriVerdiHtml = item => {
        return (
            // eslint-disable-next-line no-restricted-globals
            <div className="value">{item.value === null || isNaN(item.value) ? '-' : Format.currency(item.value)}</div>
        );
    };

    toggleActive = (id, active) => {
        const config = {
            loadingId: id
        };

        this.setState({
            content: this.createLegendFields(this.props.data, config)
        });

        this.props.toggleActive(id, active);
    };

    changeOnKeyPress = id => {
        this[id].click();
    };

    checkBoxHtml = (item, bgcolor, config) => {
        if (config.loading) {
            return (
                <div className="checkbox label-left loading">
                    <label>
                        <div className="load-indicator" />
                        {item.text}
                    </label>
                </div>
            );
        }

        return (
            <div className="checkbox label-left">
                <input
                    disabled={config.disabled}
                    name={item.text}
                    id={item.id}
                    type="checkbox"
                    onChange={() => {
                        this.toggleActive(item.id, !item.active);
                    }}
                    checked={item.active}
                    ref={input => {
                        this[item.id] = input;
                    }}
                    onKeyPress={() => {
                        this.changeOnKeyPress(item.id);
                    }}
                />
                <label htmlFor={item.id}>
                    <div className="before" style={bgcolor} />
                    {item.text}
                </label>
            </div>
        );
    };

    dotHtml = (item, bgcolor) => {
        return (
            <div className="legend-wrapper">
                <div className="circle" style={bgcolor} />
                <div className="label-container">
                    <span className="text">{item.text} </span>
                </div>
            </div>
        );
    };

    render() {
        if (this.props.data) {
            return <div className="checkbox-legend">{this.state.content}</div>;
        }

        return '';
    }
}

export default CheckboxLegend;
