import { VIS_KVITTERING, VIS_KVITTERING_LASTER } from './modals';
import {
    VIS_FEILMELDING,
    KUNNE_IKKE_OPPRETTE_ORDRE,
    IKKE_EIER_AV_KONTONUMMER,
} from './signerings-ordre';

const baseUrlFond = '/sparing-min-side-web/rest/resource/fond';
const selgFondKlientKontoUrl = `${baseUrlFond}/selgfond/klientkonto`;

const paaOrdreUtfort = (data, dispatch) => {
    dispatch({
        type: VIS_KVITTERING,
        ordreResultater: data.ordreResultater,
    });
};

const STATUS_NOT_ACCEPTABLE = 406;

const sendSalgsOrdre = (url, onSuccess, config, dispatch) => {
    fetch(url, config).then((data) => {
        if (data.ok) {
            data.json().then((data) => {
                onSuccess(data, dispatch);
            });
        } else {
            const feilmelding =
                data.status === STATUS_NOT_ACCEPTABLE ? IKKE_EIER_AV_KONTONUMMER : KUNNE_IKKE_OPPRETTE_ORDRE;
            dispatch({ type: VIS_FEILMELDING, feilmelding });
        }
    });
};

const OpprettSalgsOrdre = (data) => {
    const config = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        dispatch({type: VIS_KVITTERING_LASTER,});
        sendSalgsOrdre(selgFondKlientKontoUrl, paaOrdreUtfort, config, dispatch);
    }
};

export default OpprettSalgsOrdre;
