// @ts-nocheck // kiet fix it later
import { generateId } from '../../Functions';
import { createSymbolPath, getChartText } from './ChartFunction';

const initLegendInfo = (state, info) => {
    const config = state.legend || {};
    if (  !(config.text instanceof Array) ) { return; }

    const tspanList = [];
    const center = [0,0];
    let dx = 0;
    let yHeight = 0;

    const textList = config.text.reduce( (p,d) => {
        if ( d.text instanceof Array ) {
            p = p.concat( d.text.map((t,i) => {
                return {...d, text: t, symbolColor: i ? 'none' : d.symbolColor, deltaDy: i ? 0 : (d.deltaDy || 0)};
            }));
        } else {
            p.push(d);
        }

        return p;
    }, []);

    textList.forEach((data, i) => {
        const size = parseInt(`${config.textSize || data.size || 30}`, 10);
       
        const lineHeight = size * 1.5;
        let x = dx || config.xStart || center[0];
        let dy = (i === 0 ? (lineHeight / 2) : lineHeight) + (data.deltaDy || 0);
        const r = data.radius || (size/3) || 10;

        if ( config.horizontal ) { dy = 0; }

        if (data.symbol ) {
            //let y = (config.yStart || center[0]) + (lineHeight / 2) + (dy*i);
            //let y = (config.yStart || center[0]) + yHeight + lineHeight + (i === 0 ? ((lineHeight/2)*-1) : 0) - (r/6);
            let y = (config.yStart || center[0]) + yHeight + lineHeight + (i === 0 ? ((lineHeight/2)*-1) : 0) + (data.deltaDy || 0);
            y -= (data.minusSymbolRadius ? r : (r/6));

            if ( config.horizontal ) {
                y = (config.yStart || center[0]);
                y -= (data.minusSymbolRadius ? r : 0);
            }

            info.list.push({
                id: generateId('legend-symbol'),
                type: 'path',
                path: createSymbolPath({ ...data, radius: r, center: [x, y] }),
                style: {
                    fill: data.symbolColor || data.color,
                    stroke: data.symbolColor || state.color.default,
                    strokeWidth: data.symbolColor === 'none' ? 0 : 2,
                }
            });
            x += (r*2);
        }

        const tspan = getChartText({
            textAnchor: config.textAnchor || 'start',
            baseline: config.baseline || 'middle',
            text: data.text,
            type: 'tspan',
            dy,
            size,
            x,
            color: data.textColor || data.color,
            weight: data.weight,
        });

        tspanList.push(tspan);

        if ( config.horizontal ) {
            dx += ((data.text.length * size) / 1.5) + 20 + (config.xSpace || 0);
            if ( data.symbol ) {
                dx += (r*2);
            }
        }

        yHeight += dy;
    });

    info.list.push(getChartText({
        x: config.xStart || center[0],
        y: config.yStart || center[1],
        textAnchor: 'start',
        baseline: 'text-top',
        tspan: tspanList,
    }));
};

export default initLegendInfo;
