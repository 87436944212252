import React from 'react';
import PropTypes from 'prop-types';
import Card from '../card';

require('../../styles/grunnbelop-kort.scss');

export const GrunnBelopAvkastning = props => {
    return (
        <div className="kolonne">
            <div className="prosent">{`${props.prosent}`}</div>
            <div>{props.sats}</div>
        </div>
    );
};

GrunnBelopAvkastning.propTypes = {
    prosent: PropTypes.string.isRequired,
    sats: PropTypes.string.isRequired
};

const GrunnBelopKort = props => (
    <div className="grunnbelop-kort">
        <div className="kort-tittel">{props.tittel}</div>
        <Card>
            <div>
                <div className="kolonner">
                    {props.satser.map(sats => {
                        return <GrunnBelopAvkastning key={sats.sats} prosent={sats.prosent} sats={sats.sats} />;
                    })}
                </div>
                <div className="small-text">{props.forventetInnbetaling}</div>
            </div>
        </Card>
    </div>
);

GrunnBelopKort.propTypes = {
    satser: PropTypes.array.isRequired,
    tittel: PropTypes.string.isRequired,
    forventetInnbetaling: PropTypes.object.isRequired
};

export default GrunnBelopKort;
