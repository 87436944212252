import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Feilmelding extends Component {
    static propTypes = {
        texts: PropTypes.object,
        feil: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            feilmeldingForKunde: true
        };
    }

    kundeFeilMeldingHtml = () => <div className="error-message">{this.props.texts.get('feilmeldingsTekst')}</div>;

    feilMeldingHtml = feilmelding => (
        <div key={feilmelding.type} className="error-message">
            <div className="error-type">{feilmelding.type}</div>
            <span className="error-code">{feilmelding.errorCode}</span>
            <span className="error-msg">{feilmelding.msg}</span>
        </div>
    );

    feilMeldingerHtml = feilmeldinger => {
        const feilmeldingHtml = [];

        for (const key in feilmeldinger) {
            if (Object.prototype.hasOwnProperty.call(feilmeldinger, key)) {
                const feilmelding = feilmeldinger[key];
                feilmeldingHtml.push(this.feilMeldingHtml(feilmelding));
            }
        }

        return feilmeldingHtml;
    };

    render = () => (
        <article
            className={`card with-icon ${this.state.feilmeldingForKunde ? 'card-info' : 'card-danger'}`}
            role="alert"
        >
            <div className="card-content">
                <header className="card-header">
                    <h2>{this.props.texts.get('feilmeldingsTittel')}</h2>
                </header>
                <div className="card-body">
                    <i />
                    {this.state.feilmeldingForKunde
                        ? this.kundeFeilMeldingHtml()
                        : this.feilMeldingerHtml(this.props.feil)}
                </div>
            </div>
        </article>
    );
}

export default Feilmelding;
