import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@eika/link';
import { IconTypes } from '@eika/icon';
// @ts-ignore
import KDKForm from '@eika-felles/kdk-form';
import Message from '../../common/Message/Message';
import { Spinner } from '../../common/share/ShareComponents';
import { AppTexts, StringObject } from '../../domain/Types';
import './KjennDinKunde.scss';

interface Props {
    appTexts: AppTexts;
    action: any;
    callback?: (e: {action: string; result?: StringObject}) => void;
    title?: string;
    description?: string;
};

interface State {
    error: any;
    onSubmit: boolean;
    pending?: any;
};

const onSubmit = (props: Props, state: State, setState: (s: State) => void, reducer: any, scheema: any, result: any ): void => {
    setState({...state, onSubmit: true});    
    const cloned = JSON.parse(JSON.stringify(scheema)); 
    cloned.skjema.skjemaSporsmal = result;

    props.action.sendKjennDinKundeSvar( cloned, null, null, null, (response: any): void => {
        if (response.error) {
            setState({...state, onSubmit: false, error: {text: response.error}});
            if ( typeof(props.callback) === 'function' ) {
                props.callback({action: 'submit_error', result: response.error });
            }
        } else if ( typeof(props.callback) === 'function' ) {
            props.callback({action: 'submit_ok', result: response.gjeldendeVurdering || response });
        } else if ( (reducer.KjennDinKunde.oppfolgingSkjemaForKunde || {}).skjema ) {
            props.action.setOppfolgingSkjemaForKunde( null );
        } else {
            props.action.setKundevurdering((response.gjeldendeVurdering || response));
        }
    });
};

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['KjennDinKunde'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const [state, setState] = useState<State>({
        error: null,
        onSubmit: false,
        pending: useRef<any>({}),
    });

    const { KjennDinKunde={} } = reducer;
    const countryList = KjennDinKunde.crsLand;
    const scheema = KjennDinKunde.skjemaForKundeBarn ||
        (KjennDinKunde.oppfolgingSkjemaForKunde || {}).skjema ? KjennDinKunde.oppfolgingSkjemaForKunde : KjennDinKunde.skjemaForKunde;
    
    const loading = state.onSubmit || !scheema || !countryList;

    useEffect( () => {
        if ( countryList || state.pending.current.countryList ) { return; }
        state.pending.current.countryList = 1;
        props.action.getListeOverCRSLand();
    }, [countryList, state.pending, props.action]);

    useEffect( () => {
        if ( scheema || state.pending.current.scheema ) { return; }
        state.pending.current.scheema = 1;
        props.action.getSkjemaForKunde();
    }, [scheema, state.pending, props.action]);

    useEffect( () => {
        if ( typeof(props.callback) !== 'function' || !countryList || !scheema || state.pending.current.inited ) { return; }
        state.pending.current.inited = 1;
        props.callback({action: 'initial_fetch_done'});
    }, [scheema, countryList, state.pending, props]);

    return (
        <article id="sparing-kjenn-din-kunde" className="sgw-content kjenn-din-kunde-wrapper">
            <h1>{props.title ?? props.appTexts.get('ahvLovpaalagtKundekontroll')}</h1>
            <p>{props.description ?? props.appTexts.get('ahvLovpaalagtKundekontrollBeskrivelse')} </p>
            
            <div className="paragraph-large">
                <Link href="https://eika.no/Sikkerhet/Antihvitvask" target="_blank" icon={IconTypes.INFO_24}>
                    Om hvitvaskningsloven
                </Link>
            </div>

            { !!(state.error || {}).text && <div className="paragraph">
                    <Message text={state.error.text} type="error" /> 
                </div>
            }

            { loading ? <div className="paragraph"><Spinner type="kjenn-din-kunde"/></div> : <div className="kjenn-din-kunde-content">
                <KDKForm
                    questions={scheema}
                    landOptionLists={{
                        alleLand: (countryList ?? []).map( (country: any) => ({ landIso: country.svarId, landNavn: country.tekst })),
                        excludeListe: { CRS: [] }
                    }}
                    onSubmit={(e: any) => { onSubmit(props, state, setState, reducer, scheema, e); }}
                    // onAbort={this.onAbort}
                    rotNodeId="#sparing-kjenn-din-kunde"
                    displayNesteButton
                    displayAbortButton
                    appNamespace="min-side-web"
                />
            </div>}
        </article>
    );
};
