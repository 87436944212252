/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import { flyttFond } from '../../actions/fond';
import { settSideTittel } from '../../actions/header';
import { settKvitteringsInnhold } from '../../actions/modals';
import Feilmelding from '../../components/feilmelding';
import ActionButton from '../../components/action-button';
import Checkbox from '../../components/checkbox';
import TextInput from '../../components/common/text-input';

require('../../styles/nominee.scss');

class Nominee extends Component {
    static propTypes = {
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        errors: PropTypes.object,
        portefoljeData: PropTypes.object,
        flyttFond: PropTypes.func,
        settKvitteringsInnhold: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            nomineeValg: [],
            nomineeValgNavn: [],
            bankValg: [],
            bankValgNavn: [],
        };
    }

    UNSAFE_componentWillMount() {
        this.props.settSideTittel(this.props.texts.get('arbeidsgiversPensjonsparingTittel'));

        if (this.props.portefoljeData) {
            this.setState({
                eksterneFond: this.hentAlleEksterneFond(this.props.portefoljeData.portefoljer),
            });
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.portefoljeData) {
            this.setState({
                eksterneFond: this.hentAlleEksterneFond(newProps.portefoljeData.portefoljer),
            });
        }
    };

    hentAlleEksterneFond = (portefoljer) => {
        return portefoljer
            .filter((p) => p.eksterneFond)
            .map((p) => {
                return p.eksterneFond
                    .filter((f) => f.type === 'AF' && !f.flyttingBestilt)
                    .map((a) => {
                        return { ...a, portfolioBaseCode: p.portfolioBaseCode };
                    });
            })
            .reduce((a, b) => {
                return a.concat(b);
            });
    };

    fondCheckboxes = (fond) => {
        return fond.map((f) =>
            this.checkBoxHtml(f.navn, f.isin, (checked, isin, name) => {
                this.velgFond(checked, isin, name, f.portfolioBaseCode);
            })
        );
    };

    removeItemFromArray = (array, item, func) => {
        const index = func
            ? array.findIndex((i) => {
                  return func(i, item);
              })
            : array.indexOf(item);
        array.splice(index, 1);
        return array;
    };

    compareObjectOnKey = (object, item) => {
        return object.isin === item.isin && object.portfolioBaseCode === item.portfolioBaseCode;
    };

    velgFond = (checked, isin, name, portfolioBaseCode) => {
        const { nomineeValg, nomineeValgNavn } = this.state;
        const nomineeValgCopy = checked
            ? [...nomineeValg, { isin, portfolioBaseCode }]
            : this.removeItemFromArray(nomineeValg, { isin, portfolioBaseCode }, this.compareObjectOnKey);

        const nomineeValgNavnCopy = checked
            ? [...nomineeValgNavn, name]
            : this.removeItemFromArray(nomineeValgNavn, name);

        this.setState({
            nomineeValgCopy,
            nomineeValgNavnCopy,
        });
    };

    velgBank = (checked, bank, navn) => {
        const { bankValg, bankValgNavn } = this.state;
        const bankValgCopy = checked ? [...bankValg, bank] : this.removeItemFromArray(bankValg, bank);

        const bankValgNavnCopy = checked ? [...bankValgNavn, navn] : this.removeItemFromArray(bankValgNavn, navn);

        this.setState({
            bankValgCopy,
            bankValgNavnCopy,
        });
    };

    checkBoxHtml = (tekst, id, onChange) => {
        return (
            <Checkbox key={id} id={id} name={tekst} onChange={onChange}>
                <div>{tekst}</div>
            </Checkbox>
        );
    };

    sjekkForServerFeil = (errors) => {
        return errors.LAST_PORTEFOLJE;
    };

    andreTilbydere = (event) => {
        const andreTilbydere = event.target.value;

        this.setState({
            andreTilbydere,
        });
    };

    flyttFond = () => {
        const nominee = this.createNomineeDTO(this.state);
        this.createReceiptContent(this.state);
        this.props.flyttFond(nominee);
    };

    createNomineeDTO = (state) => {
        return {
            nominee: state.nomineeValg,
            bankValg: state.bankValg,
            andreTilbydere: state.andreTilbydere,
        };
    };

    createReceiptContent = (state) => {
        const kvittering = {
            type: 'nominee',
            lasteTekst: this.props.texts.get('overforerFond'),
            nomineeValgNavn: state.nomineeValgNavn,
            bankValgNavn: state.bankValgNavn,
        };

        this.props.settKvitteringsInnhold(kvittering);
    };

    enableFlyttFondKnapp = (state) => {
        const disableFondKnapp = !(state.nomineeValg.length || state.bankValg.length || state.andreTilbydere);
        const pilKnapp = true;

        if (disableFondKnapp) {
            return (
                <ActionButton
                    disabled={disableFondKnapp}
                    text={this.props.texts.get('flyttFond')}
                    action="fond"
                    arrow={pilKnapp}
                />
            );
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div onClick={this.flyttFond}>
                <ActionButton text={this.props.texts.get('flyttFond')} action="fond" arrow={pilKnapp} />
            </div>
        );
    };

    visNomineeFond = () => {
        if (this.state.eksterneFond) {
            return (
                <div>
                    <div className="title">{this.props.texts.get('fondDuHarPaaVPS')}</div>

                    <fieldset>
                        <div>{this.fondCheckboxes(this.state.eksterneFond)}</div>
                    </fieldset>
                </div>
            );
        }
        return '';
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.eksterneFond) {
            return (
                <div className="nominee">
                    <div className="top-banner title">{this.props.texts.get('velgFondDuVilFlytte')}</div>
                    <div className="content">
                        <div>
                            {this.visNomineeFond()}

                            <div className="title">{this.props.texts.get('fondIAndreBanker')}</div>

                            <fieldset>
                                <div>
                                    {this.checkBoxHtml('DNB', 'DNB', this.velgBank)}
                                    {this.checkBoxHtml('Storebrand', 'Storebrand', this.velgBank)}
                                    {this.checkBoxHtml('Nordea', 'Nordea', this.velgBank)}
                                    {this.checkBoxHtml('Skagen', 'Skagen', this.velgBank)}
                                </div>
                            </fieldset>
                        </div>

                        <TextInput
                            id="epost"
                            label={this.props.texts.get('fondFraAndreTilbydere')}
                            onChange={this.andreTilbydere}
                        />

                        {this.enableFlyttFondKnapp(this.state)}
                    </div>
                </div>
            );
        }
        return <Loading title={this.props.texts.get('lasterArbeidsgiversPensjonsSparing')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Fond,
            ...state.Feilmeldinger,
            ...state.Texts,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            flyttFond: (fondsListe) => {
                dispatch(flyttFond(fondsListe));
            },
            settKvitteringsInnhold: (innhold) => {
                dispatch(settKvitteringsInnhold(innhold));
            },
        };
    }
)(Nominee);
