import { VIS_FEILMELDING, KUNNE_IKKE_OPPRETTE_ORDRE } from './signerings-ordre';
import { VIS_KVITTERING } from './modals';

const baseUrlFond = '/sparing-min-side-web/rest/resource/fond';
const ordreUrl = `${baseUrlFond}/ordre`;

const OpprettUttaksOrdre = data => {
    const config = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return dispatch => {
        const url = `${ordreUrl}/klientkontouttak`;
        fetch(url, config).then(data => {
            if (data.ok) {
                data.json().then(data => {
                    dispatch({
                        type: VIS_KVITTERING,
                        ordreResultater: data.ordreResultater,
                    });
                });
            } else {
                dispatch({
                    type: VIS_FEILMELDING,
                    feilmelding: KUNNE_IKKE_OPPRETTE_ORDRE
                });
            }
        });
    };
};

export default OpprettUttaksOrdre;
