/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

require('../styles/radio-buttons.scss');

class RadioButtons extends Component {
    static propTypes = {
        items: PropTypes.any,
        /*
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                navn: PropTypes.string.isRequired,
                tekst: PropTypes.string
            })
        ).isRequired,
        */
        onChange: PropTypes.func.isRequired,
        legend: PropTypes.string,
        defaultValue: PropTypes.string,
        errorMessage: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: props.defaultValue
        };
    }

    handleOptionChange = event => {
        this.setState({
            selectedOption: event.target.id
        });

        const item = this.props.items.find(item => item.id === event.target.id);
        this.props.onChange(item);
    };

    showRadioButtonText = (show, text) => (show && text ? <div className="description">{text}</div> : null);

    renderRadioButtons = items =>
        items.map(item => {
            const checked = this.state.selectedOption === item.id;

            return (
                <div className="radiobutton" key={item.id}>
                    <input
                        id={item.id}
                        type="radio"
                        value={item.id}
                        checked={checked}
                        onChange={this.handleOptionChange}
                        disabled={!!item.disabled}
                    />
                    <label htmlFor={item.id}>
                        <div>{item.navn}</div>
                        {this.showRadioButtonText(checked, item.tekst)}
                    </label>
                </div>
            );
        });

    render = () => (
        <div>
            <fieldset className="radio-buttons">
                {this.props.legend ? <legend>{this.props.legend}</legend> : ''}
                {this.renderRadioButtons(this.props.items)}
                {this.props.errorMessage && <div className="hjelpe-tekst">{this.props.errorMessage}</div>}
            </fieldset>
        </div>
    );
}

export default RadioButtons;
