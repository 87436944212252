import { get } from "@eika-infrastruktur/request";

export const SET_STOREBRAND_TRANSACTION = 'SET_STOREBRAND_TRANSACTION';
export const SET_STOREBRAND_PENION_PAYOUT = 'SET_STOREBRAND_PENION_PAYOUT';
export const SET_STOREBRAND_INFO = 'SET_STOREBRAND_INFO';

export const getStorebrandInfo = (callback) => async (dispatch) => {
    let result = null;
    const url = '/sparing-min-side-web/rest/resource/pension/storebrand';

    try {
        result = await get(url)
            .catch(() => null);
    } catch (error) {
        result = null;
    }

    let pin = null;
    if (result) {
        pin = {};
        ['epks', 'pkbs'].forEach((key) => {
            (result[key] ?? []).forEach((info) => {
                pin[info.contractId] = info;
            });
        });
    }

    return typeof callback === 'function' ? callback(result) : dispatch({ type: SET_STOREBRAND_INFO, data: pin });
};

/******************************************************************************
 ******************************************************************************/
export const getStorebrandTransaction = (config, callback) => async (dispatch) => {
    let result = null;
    if (!config.id || !config.fromDate || !config.toDate) {
        return typeof callback === 'function'
            ? callback(result)
            : dispatch({ type: SET_STOREBRAND_TRANSACTION, data: result });
    }

    const interval = [config.fromDate.toISOString().slice(0, 10), config.toDate.toISOString().slice(0, 10)];
    const url = `/sparing-min-side-web/rest/resource/pension/storebrand/${config.id}/transactions/${interval[0]}/${interval[1]}`;

    try {
        result = await get(url)
            .catch(() => null);
    } catch (error) {
        result = null;
    }

    return typeof callback === 'function'
        ? callback(result)
        : dispatch({ type: SET_STOREBRAND_TRANSACTION, data: result });
};

export const setStorebrandTransaction = (data) => async (dispatch) => {
    return dispatch({ type: SET_STOREBRAND_TRANSACTION, data });
};

/******************************************************************************
 ******************************************************************************/
export const getStorebrandPensionPayout = (config, callback) => async (dispatch) => {
    let result = null;
    if (!config.id || !config.age) {
        return typeof callback === 'function'
            ? callback(result)
            : dispatch({ type: SET_STOREBRAND_PENION_PAYOUT, data: result });
    }

    const url = `/sparing-min-side-web/rest/resource/pension/storebrand/${config.id}/estimatedPayouts/${config.age}`;
    try {
        result = await get(url)
            .catch(() => null);
    } catch (error) {
        result = null;
    }

    return typeof callback === 'function'
        ? callback(result)
        : dispatch({ type: SET_STOREBRAND_PENION_PAYOUT, data: result });
};

export const setStorebrandPensionPayout = (data) => async (dispatch) => {
    return dispatch({ type: SET_STOREBRAND_PENION_PAYOUT, data });
};

/******************************************************************************
 ******************************************************************************/

const initialState = {
    signingConfig: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_STOREBRAND_INFO:
            return { ...state, info: action.data };
        case SET_STOREBRAND_TRANSACTION:
            return { ...state, transaction: action.data };
        case SET_STOREBRAND_PENION_PAYOUT:
            return { ...state, payout: action.data };
        default:
            return state;
    }
};
