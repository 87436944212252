// @ts-nocheck // kiet fix it later

import { useEffect, useRef } from 'react';

/** ************************************************************************
 ************************************************************************* */
export const usePrevious = (value: any): any => {
 	const reference = useRef<any>(null);
 	useEffect(() => {
   		reference.current = value;
  	}, [value]);
  	return reference.current;
};

/** ************************************************************************
 ************************************************************************* */
export const test = (): any => {
	return null;
};
