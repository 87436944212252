import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { hentAnbefaling, anbefalingstekstKey } from './anbefalings-matrise';
import HelperFunctions from '../helper-functions';
import EstimeringsGraf from '../sparemaal/estimerings-graf-sparemaal';
import TextList from '../text-list';
import KjopFondInngang from '../fond/kjop-fond/kjop-fond-inngang';
import ActionButton from '../action-button';
import InfoBoks from '../infoboks';
import { getKjopFondNextPage } from './Functions';

const FondAnbefaling = (props) => {
    const {
        texts,
        tidshorisont,
        risikoprofil,
        morningstar,
        visEstimat,
        hentEstimat,
        monthlyDeposit,
        fondEstimat,
        header,
        visAnbefalingsTekst,
        portefoljeId
    } = props;
    const [expanded, setExpanded] = useState(false);
    const isin = hentAnbefaling(tidshorisont, risikoprofil);
    const fond = HelperFunctions.hentFondBasertPaaIsin(isin, morningstar);
    const ekspanderendeKort = { expanded, onExpand: () => setExpanded(!expanded) };

    useEffect(() => {
        if (visEstimat && hentEstimat) {
            hentEstimat(isin, monthlyDeposit, tidshorisont * 12);
        }
    }, [hentEstimat, isin, monthlyDeposit, tidshorisont, visEstimat]);

    function showEstimateGraph() {
        if (visEstimat && fondEstimat) {
            return (
                <EstimeringsGraf
                    values={fondEstimat}
                    innskudd={(monthlyDeposit || 0) * tidshorisont * 12}
                    tidshorisont={tidshorisont}
                    texts={texts}
                />
            );
        }
        return null;
    }

    /*
    function visIngress() {
        if (visAnbefalingsTekst) {
            const key = anbefalingstekstKey[isin];
            if (key) {
                return (
                    <div className="ingress">
                        <p>{texts.get(`${key}Header`)}</p>
                        <TextList texts={texts} textKey={key} />
                    </div>
                );
            }
        }
        return null;
    }
    */

    function genererKjopsLenke() {
        const url = monthlyDeposit
            ? `kjopFondSparemaal/ \
                ${fond.isin} \
                ${portefoljeId ? `/${portefoljeId}` : ''} \
                ${monthlyDeposit ? `/${monthlyDeposit}` : ''}`.replace(/\s/g, '')
            : `kjopFond/ \
                ${fond.isin} \
                ${portefoljeId ? `/${portefoljeId}` : ''} \
                ${monthlyDeposit ? `/${monthlyDeposit}` : ''}`.replace(/\s/g, '');

        return getKjopFondNextPage( url );
    }

    return (
        <div className="anbefalte-fond">
            {showEstimateGraph()}
            {header && <h1 className="h2">{header}</h1>}

            { /* eslint-disable-next-line react/no-danger */ }
            <div className="paragraph -largest" dangerouslySetInnerHTML={{ __html: texts.get('faaAnbefalingGenerell', {contactUs: `${window.location.origin}/kontaktoss`}) }} />

            { !!anbefalingstekstKey[isin] && <div className="paragraph -medium">
                <p><strong>{texts.get(`${anbefalingstekstKey[isin]}Header`)}</strong></p>
                { !!visAnbefalingsTekst && <div>
                    <TextList texts={texts} textKey={anbefalingstekstKey[isin]} bulletpoints />
                </div> }
            </div>}

            <KjopFondInngang texts={texts} fond={fond} expandItem={ekspanderendeKort} portefoljeId={portefoljeId} />

            <ActionButton text={texts.get('kjopDetteFondet')} action={genererKjopsLenke()} arrow />

            <div className="se-alle-fond paragraph -large">
                <Link className="link font-size-regular" to={getKjopFondNextPage(`kjopFond/${portefoljeId ? `portefolje/${portefoljeId}` : 'fond/alle'}`)}>
                    {texts.get('seAlleFond')}
                </Link>
            </div>

            <div className="paragraph -medium -only-top">
                <InfoBoks infoTekst={texts.get('anbefaltFondInfo')} info />
            </div>

            <div className="paragraph -medium -only-top">
                <InfoBoks infoTekst={texts.get('anbefaltFondNotat')} info />
            </div>
        </div>
    );
};

export default FondAnbefaling;
