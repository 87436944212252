// @ts-nocheck // kiet fix it later

import { generateId } from '../../Functions';
// import { createSymbolPath, getCirclePath, getPolarToCartesian, getChartText } from './ChartFunction';
import { getCirclePath, getPolarToCartesian, getChartText } from './ChartFunction';

const initGraphPieShadow = (state, info, list) => {
    list.forEach((data) => {
        data.id += '-shadow';
        data.path = getCirclePath(data);
        data.style.stroke = '#fff';
        // data.style.strokeWidth += 2;
        data.isShadow = true;
    });

    info.list = list.concat(info.list);
};

/*
Kiet: temporary ignor chart icon for supporting of WCAG
const initGraphPieInfoText = (state, info) => {
    const length = info.list.length; let
        index = 0;
    const height = info.list[0].radius - info.list[0].stroke;
    const gap = 20; const
        space = (height * 2) / length;

    for (let i = 0; i < length; i++) {
        const data = info.list[i]; let color = ''; const radius = 6; let
            anchor = 'middle';
        // eslint-disable-next-line
        if (!data || !data.x || !data.y) { continue; }

        let x = data.x; const y = data.y - ((height - ((space) * index)) + gap);
        const delta = 0;

        if (data.symbol && data.center && data.center[0] && data.center[1]) {
            x -= (data.radius - data.stroke) / 2;
            color = '#444';
            anchor = 'start';
            info.list.push({
                type: 'path',
                path: createSymbolPath({ ...data, radius }),
                style: {
                    fill: 'none',
                    stroke: color,
                    strokeWidth: 1
                }
            });

            info.list.push({
                type: 'path',
                path: createSymbolPath({ ...data, center: [x, (y - radius)], radius }),
                style: {
                    fill: 'none',
                    stroke: color,
                    strokeWidth: 1
                }
            });

            x += radius + 5;
        }

        info.list.push(getChartText({
            x: x - delta,
            y,
            text: data.text,
            textAnchor: anchor,
            color: color || data.color || data.style.stroke
        }));
        index++;
    }
};
*/

const initGraphPieInfoProgress = (state, info) => {
    const config = state.config || {};
    const current = info.list[0];

    if ((((state.previous || {}).graph || {}).list || [])[1]) {
        const previous = state.previous.graph.list[1];
        current.animateFrom = previous.animateTo;
    }

    const cloned = JSON.parse(JSON.stringify(current));
    cloned.id = generateId('progress');
    cloned.value = 100;
    cloned.degree = 360;
    cloned.percent = 1;
    cloned.path = getCirclePath({
        ...cloned, startAngle: 0, endAngle: 360
    });

    cloned.dash = 0;
    cloned.style.stroke = state.color.default;
    cloned.style.strokeDasharray = cloned.dash;
    cloned.animate = false;
    info.list.unshift(cloned);

    if ( info.noText ) { return; }
    // ,
    const text = getChartText({
        x: current.x,
        y: current.y + 20,
        text: `${current.value}%`,
        color: config.textColor || current.textColor || current.color,
        size: config.textSize || 160,
        extension: { duration: current.duration }
    });

    if (state.animation) {
    // text.animation = {'value': new Animated.Value(0), 'attributeName': 'fill-opacity'};
    }

    if ( !config.text ) { info.list.push(text); }
};

const initGraphPieInfo = (state, info) => {
    let sumDegree = 0;
    const shadowList = [];
    const config = state.config || {};

    info.list.forEach((data) => {
        data.type = state.type || 'pie';
        data.duration = state.duration;
        data.percent = data.value && info.sum ? (data.value / info.sum) : 0;
        data.degree = 360 * data.percent;
        data.stroke = state.pieStroke || 50;
        data.radius = state.pieRadius || 100;
        data.x = config.xStart || (state.axis.x.max / 2) + state.padding.left;
        data.y = config.yStart || (state.axis.y.max / 2) + state.padding.bottom;
        data.dash = data.radius * 2 * Math.PI;
        data.startAngle = sumDegree;
        data.endAngle = data.degree + sumDegree;

        const point = getPolarToCartesian({
            ...data, angle: ((data.degree / 2) + sumDegree)
        });

        data.center = [point.x, point.y];
        data.path = state.shadow && data.value !== 100 ? getCirclePath({
            ...data,
            startAngle: (data.startAngle + (data.degree === 360 || data.degree === 0 ?  0 : 0.5)),
            endAngle: (data.endAngle - (data.degree === 360 || data.degree === 0 ?  0 : 0.5))
        }) : getCirclePath(data);

        data.color = data.color || state.color.list[info.color.i++];
        data.style = {
            fill: 'none',
            stroke: data.color,
            strokeWidth: data.stroke,
            strokeDasharray: data.dash,
            strokeLinecap: 'butt'
        };

        if (state.animation) {
            data.animateFrom = data.dash * -1;
            data.animateTo = 0;

            if (data.type === 'pie' ) {
                data.style.strokeDashoffset = data.dash;
            } else {
                data.style.strokeDashoffset = data.dash * data.percent * -1;
            }
        }

        if (data.animateFrom !== undefined && data.animateTo !== undefined) {
            data.animation = {
                // 'value': new Animated.Value(0),
                config: {
                    inputRange: [0, 1],
                    outputRange: [data.animateFrom, data.animateTo]
                },
                attributeName: 'stroke-dashoffset'
            };
        }

        data.className = 'graph-pie graph-source';

        if (state.shadow) {
            shadowList.push(JSON.parse(JSON.stringify(data)));
        }

        sumDegree += data.degree;
    });

    if (state.type === 'progress') {
        initGraphPieShadow(state, info, shadowList);
        initGraphPieInfoProgress(state, info);
    } else {
        // Kiet: temporary ignor chart icon for supporting of WCAG
        // initGraphPieInfoText(state, info);
        // initGraphPieShadow(state, info, shadowList);
    }

    if ( config.text ) {
        const current = info.list[0];
        const text = getChartText({
            x: current.x,
            y: current.y,
            text: config.text,
            color: config.textColor || '#000',
            size: config.textSize || '200px',
            extension: { duration: current.duration }
        });
        info.list.push( text );
    }
};

export default initGraphPieInfo;
