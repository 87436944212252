const ColorConfig = {
    basic: [
        {color: 'rgba(196, 147, 183, 1)', bgColor: 'rgba(196, 147, 183, .2)', hexa: '#C493B7'},
        {color: 'rgba(87, 87, 87, 1)', bgColor: 'rgba(87, 87, 87, .2)', hexa: '#575757'},
        {color: 'rgba(208, 204, 200, 1)', bgColor: 'rgba(208, 204, 200, .2)', hexa: '#D0CCC8'},
        {color: 'rgba(138, 199, 220, 1)', bgColor: 'rgba(138, 199, 220, .2)', hexa: '#8AC7DC'},
        {color: 'rgba(199, 220, 192, 1)', bgColor: 'rgba(199, 220, 192, .2)', hexa: '#C7DCC0'},
        {color: 'rgba(0, 56, 61, 1)', bgColor: 'rgba(0, 56, 61, .2)', hexa: '#00383D'},
        {color: 'rgba(249, 202, 79, 1)', bgColor: 'rgba(249, 202, 79, .2)', hexa: '#F9CA4F'},
    ],
    fundSwingAndValue: [
        {color: 'rgba(0, 56, 61, 1)', bgColor: 'rgba(0, 56, 61, .2)', hexa: '#00383D'},
        {color: 'rgba(196, 147, 183, 1)', bgColor: 'rgba(196, 147, 183, .2)', hexa: '#C493B7'},
    ],
    employer: [
        {color: 'rgba(138, 199, 220, 1)', bgColor: 'rgba(138, 199, 220, .2)', hexa: '#8AC7DC'},
        {color: 'rgba(196, 147, 183, 1)', bgColor: 'rgba(196, 147, 183, .2)', hexa: '#C493B7'},
        {color: 'rgba(208, 204, 200, 1)', bgColor: 'rgba(208, 204, 200, .2)', hexa: '#D0CCC8'},
        {color: 'rgba(87, 87, 87, 1)', bgColor: 'rgba(87, 87, 87, .2)', hexa: '#575757'},
        {color: 'rgba(199, 220, 192, 1)', bgColor: 'rgba(199, 220, 192, .2)', hexa: '#C7DCC0'},
        {color: 'rgba(0, 56, 61, 1)', bgColor: 'rgba(0, 56, 61, .2)', hexa: '#00383D'},
        {color: 'rgba(249, 202, 79, 1)', bgColor: 'rgba(249, 202, 79, .2)', hexa: '#F9CA4F'},        
    ],
};
export default ColorConfig;
