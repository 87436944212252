import Loading from '@eika-infrastruktur/ui-kort-laster';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { settSideTittel } from '../../../actions/header';

import HelperFunctions from '../../../components/helper-functions';
import Feilmelding from '../../../components/feilmelding';
import Format from '../../../components/format-functions';
import DateFormatting from '../../../components/date-functions';

import UtlistningsKort from '../../../components/danica/utlistnings-kort';
import GrunnBelopKort from '../../../components/danica/grunnbelop-kort';

class AvtaleDetaljer extends Component {
    static propTypes = {
        params: PropTypes.object,
        texts: PropTypes.object,
        arbeidsgiversPensjonsSparing: PropTypes.array,
        errors: PropTypes.object,
        settSideTittel: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    UNSAFE_componentWillMount() {
        const { id } = this.props.match.params;
        this.props.settSideTittel(this.props.texts.get('omPensjonsavtalenDin'));
        this.finnAvtale(this.props.arbeidsgiversPensjonsSparing, id);
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        const { id } = newProps.match.params;

        if (!this.state.avtale) {
            this.finnAvtale(newProps.arbeidsgiversPensjonsSparing, id);
        }
    };

    finnAvtale = (arbeidsgiversPensjonsSparing, id) => {
        if (arbeidsgiversPensjonsSparing && id) {
            const avtale = HelperFunctions.finnAvtale(arbeidsgiversPensjonsSparing, id);

            this.setState({
                avtale,
            });

            this.props.settSideTittel(this.props.texts.get('omPensjonsavtalenDin'), avtale.firmanavn);
        }
    };

    sjekkForServerFeil = (errors) => {
        return errors.LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA;
    };

    visGrunnBelop = (grunnBelop) => {
        if (grunnBelop) {
            const forventetUtbetaling = HelperFunctions.VariableInjection(
                this.props.texts.get('forventetInnbetaling'),
                { innbetaling: Format.currency(grunnBelop.aarligInnbetaling) }
            );

            return (
                <GrunnBelopKort
                    satser={grunnBelop.satser}
                    tittel={this.props.texts.get('dinSpareplan')}
                    forventetInnbetaling={forventetUtbetaling}
                    texts={this.props.texts}
                />
            );
        }

        return null;
    };

    listUtAvtaleDetaljer = (avtaleDetaljer) => {
        return (avtaleDetaljer || []).map((avtaleDetalj) => {
            const format = (text) => {
                const parsedtext = HelperFunctions.tryMapText(text, this.props.texts); // tries find in texts
                return DateFormatting.skrivUtDatoDDMMMMYYYY(parsedtext, this.props.texts); // tries map to date format
            };

            return (
                <div key={avtaleDetalj.tittel} className="utlistningskort">
                    <div className="kort-tittel">{this.props.texts.get(avtaleDetalj.tittel)}</div>
                    <UtlistningsKort liste={avtaleDetalj.avtaleKort} format={format} texts={this.props.texts} />
                </div>
            );
        });
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.avtale) {
            return (
                <div>
                    {this.visGrunnBelop(this.state.avtale.avtaleinnhold.grunnbelop)}
                    {this.listUtAvtaleDetaljer(this.state.avtale.avtaleinnhold.avtaleDetaljer)}
                </div>
            );
        }

        return <Loading title={this.props.texts.get('lasterPensjonsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Pensjon,
            ...state.Feilmeldinger,
            ...state.Texts,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel, undertittel) => {
                dispatch(settSideTittel(sideTittel, undertittel));
            },
        };
    }
)(AvtaleDetaljer);
