import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import FondsOversikt from './fonds-oversikt';
import Feilmelding from '../../components/feilmelding';
import { hentPortefolje, setLoading } from '../../actions/fond';
import { settSideTittel } from '../../actions/header';

class DisponertFondOversiktController extends Component {
    static propTypes = {
        errors: PropTypes.object,
        portefoljeFraBeholdning: PropTypes.object,
        portefoljeId: PropTypes.string,
        hentPortefolje: PropTypes.func,
        settSideTittel: PropTypes.func,
        setLoading: PropTypes.func,
        texts: PropTypes.object,
        loading: PropTypes.bool,
    };

    componentDidMount = () => {
        this.props.setLoading();
        this.props.hentPortefolje(this.props.portefoljeId);
        this.props.settSideTittel(this.props.texts.get('lasterFondsApp'));
    };

    sjekkForServerFeil = (errors) => errors.LAST_PORTEFOLJE;

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (
            this.props.portefoljeFraBeholdning &&
            Object.entries(this.props.portefoljeFraBeholdning).length !== 0 &&
            !this.props.loading
        ) {
            return <FondsOversikt portefolje={this.props.portefoljeFraBeholdning} isDisponertKonto />;
        }
        return <Loading title={this.props.texts.get('lasterFondsApp')} />;
    }
}

export default connect(
    (state, ownProps) => {
        const { portefoljeId } = ownProps.match.params;
        return {
            ...state.Fond,
            ...state.Texts,
            ...state.Feilmeldinger,
            portefoljeId,
        };
    },
    (dispatch) => {
        return {
            hentPortefolje: (portefoljeId) => {
                dispatch(hentPortefolje(portefoljeId));
            },
            setLoading: () => {
                dispatch(setLoading());
            },
            settSideTittel: (sideTittel, undertittel) => {
                dispatch(settSideTittel(sideTittel, undertittel));
            },
        };
    }
)(DisponertFondOversiktController);
