import React, { useMemo } from 'react';
import classNames from 'classnames';

import { ListPanelProps } from '@eika/link-panel';
import '@eika/link-panel-style';

const ListPanelWithContent = ({
    url,
    title,
    textOne,
    textTwo,
    textThree,
    labels,
    ariaLabel,
    className,
    icon,
    as,
    children,
    ...rest
}: ListPanelProps): JSX.Element => {
    const hasTextContent = textOne || labels || textTwo || textThree;
    const labelId = useMemo(() => `_${Math.random().toString(36).substring(2, 9)}`, []);
    const Component = as ?? 'article';

    return (
        <Component
            className={classNames(
                'sgw-list-panel',
                { 'align-center': !hasTextContent },
                { 'has-icon': Boolean(icon) },
                className
            )}
            aria-labelledby={labelId}
        >
            <a className="sgw-list-panel__link" href={url} aria-label={ariaLabel} {...rest}>
                <span>{labels}</span>
                <span className={classNames('sgw-list-panel__content-wrapper', className)}>
                    <span id={labelId} className="sgw-list-panel-title title">
                        {title}
                    </span>
                    {textOne && <span className="sgw-list-panel-sub-text-one sub-text-one">{textOne}</span>}
                    {(textTwo || textThree) && (
                        <div className="sgw-link-panel__second-line second-line">
                            {textTwo && <span className="sgw-list-panel-sub-text-two sub-text-two">{textTwo}</span>}
                            {textThree && (
                                <span className="sgw-list-panel-sub-text-three sub-text-three">{textThree}</span>
                            )}
                        </div>
                    )}
                </span>
                {children}
            </a>
        </Component>
    );
};

export default ListPanelWithContent;
