import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from '@eika/link';
import { StandardButton, ButtonTypes } from '@eika/button';
import { Input } from '@eika/input';
import AmountField from '../../common/AmountField/AmountField';
import Message from '../../common/Message/Message';
import { Spinner } from '../../common/share/ShareComponents';
import LocalStorage from '../../common/util/LocalStorage';
import Card from '../../common/Card/Card';
import Carousel from '../../common/Carousel/Carousel';
import Abacus from '../../common/SVG/Abacus';
import GiftCard from '../../common/GiftCard/GiftCard';
import { getDrawDayList } from '../../common/util/Functions';
import Sprouts from '../../common/Sprouts/Sprouts';
import Slider from '../../common/Slider/Slider';
import Chart from '../../common/Chart/Chart';
import Countdown from '../../common/Countdown/Countdown';
import GrowMoney from '../../common/GrowMoney/GrowMoney';

import HistoryDevelopmentGraph from '../../static/data/HistoryDevelopmentGraph';
import { getHistoryGraphChartConfig } from '../Arbeidsgiver/EPKmove/EPKmoveFunctions';

import './Guideline.scss';

interface Props {
    history: any;
    location: any;
};

interface State {
    messageList: any;
    fundOverviewSelected: any;
    email: any;
    abacusConfig: any;
    dropdownMenu: any;
    drawDayList: any;
    gift: number;
    chartBar: any;
    chartProgess: any;
    chartLine: any;
    epkHistoryGraph: any;
    growMoney: boolean;
};

const renderAbacus = ( state: State, setState: (s: State) => void, key='', data?: any ) => {
    const { abacusConfig } = state;

    if ( key === 'redraw-abacus' ) {
        setState({...state, abacusConfig: {...abacusConfig, active: false }});
        setTimeout( () => {  
            setState({...state, abacusConfig: {...abacusConfig, active: true }});
        }, 200);
    } else if ( key === 'set-abacus-loading' && data ) {
        setState({...state, abacusConfig: {...abacusConfig, loading: true }});            
        setTimeout( () => {
            setState({...state, abacusConfig: {...abacusConfig, loading: false }});
        }, (data*1000));
    }
};

const openFundOverview = ( props: Props ) => {
    props.history.push('/fundoverview');
}   

const changeEmail = ( state: State, setState: (s: State) => void, value='' ) => {
    setState({...state, 'email': value || ''});
};

const click = (props: Props, state: State, setState: (s: State) => void, e: any, key='', data?: any): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if ( key === 'open-fund-overview' ) {
        openFundOverview( props );            
    } else if ( key === 'redraw-abacus' || key === 'set-abacus-loading' ) {
        renderAbacus( state, setState, key, data );
    } else if ( key === 'select-account' && data ) {
        const menu = JSON.parse(JSON.stringify(state.dropdownMenu));
        menu.selected = data.id;
        setState({...state, dropdownMenu: menu});
    } else if ( key === 'toggle-grow-money' ) {
        setState({...state, growMoney: !state.growMoney});
    }
};

export default (props: Props): JSX.Element => {
    const [state, setState] = useState<State>({
        messageList: [
            {type: 'error', text: 'Error message', icon: true},
            {type: 'error', text: 'Error message', icon: false},
            {type: 'success', text: 'Success message', icon: false},
            {type: 'InfoMessage', text: 'InfoMessage message', icon: false},
            {type: 'WarningMessage', text: 'WarningMessage message', icon: false},
            {type: 'SuccessMessage', text: 'SuccessMessage message', icon: false},
            {type: 'ErrorMessage', text: 'ErrorMessage message', icon: false},
            {type: 'AttentionMessage', text: 'AttentionMessage message', icon: false},            
            {type: 'pending', text: 'Pending message', icon: false},
        ],
        fundOverviewSelected: LocalStorage.get('fundOverviewSelected'),
        email: '',
        abacusConfig: {active: true, loading: false},
        dropdownMenu: {
            selected: 'en',
            list: [
                {
                    id: 'en',
                    tag: [{text: 'skattefri', type: 'tag -tax-free'}],
                    text: [
                        [{text: 'Behold i Aksjesparekontoen', type: 'font-semibold'}],
                        ['Anbefales dersom du ikke skal bruke pengene nå.'],
                    ]
                }, {
                    id: 'to',
                    tag: [{text: 'skatte 122 111', type: 'tag -tax'}],
                    text: [
                        [{text: 'Sparekonto', type: 'font-semibold'}],
                        ['yyyy yy yyyyyy'],
                    ]
                }, {
                    id: 'tre',
                    text: [
                        ['Ole konto', '100 kr'],
                        ['zzzz zz zzzzz'],
                    ]
                }, {
                    id: 'fire',
                    text: [
                        ['Dole konto', '100 kr'],
                        ['aaaa aa aaaaa'],
                    ]
                }, {
                    id: 'fem',
                    text: [
                        ['Doff konto', '100 kr'],
                        ['bbbb bb bbbb'],
                    ]
                }, {
                    id: 'seks',
                    text: [
                        ['Onkel skrue konto', '100 kr'],
                        ['cccc cc ccccc'],
                    ]
                }, {
                    id: 'sju',
                    text: [
                        ['Magiga konto', '100 kr'],
                        ['dddd dd ddddd'],
                    ]
                }, {
                    id: 'atte',
                    text: ['Ny konto'],
                    category: 'new-account',
                }
            ]
        },
        drawDayList: getDrawDayList(),
        chartBar: {
            type: 'bar',
            barSpace: 6,
            animation: true,
            view: [750, 300],
            data: [
                {value: 10, color: '#e0e7e7' },
                {value: 20, color: '#e0e7e7' },
                {value: 40, color: '#e0e7e7' },
                {value: -40, color: '#bfcdce' },
                {value: 5, color: '#bfcdce' },
                {value: 5, color: '#bfcdce' },
                {value: 20, color: '#4e7476' },
                {value: 30, color: '#4e7476' },
                {value: -10, color: '#4e7476' },
                {value: 5, color: '#00383d' },
                {value: 50, color: '#00383d' },
                {value: 20, color: '#00383d' },
            ],
            padding: { top: 10, left: 10, bottom: 40, right: 60 },
            colorList: ['#00383d'],
            xAxis: { grid: 5, text: [{text: 'Feb 2021'},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: 'Feb 2022'},] },
            yAxis: { grid: 0, separationLine: false, separation: 5, color: '#000', unit: '%', toRight: true, trimNumber: 0, size: 18, stroke: 'rgba(255,255,255,0)' },
        },
        chartProgess: {
            type: 'progress',
            view: [1200, 1200],
            data: [30],
            config: { noText: true, progressStroke: [6, 50], progressBaseColor: '#4e7476' },
            colorList: ['#00383d'],
            textList: [
                {text: '42%', type: 'value-swing -positive'}, 
                {text: '59 076', type: 'font-bold'}, 
                {text: 'Totalt verdiøkning', type: 'font-normal'}
            ],
        },
        chartLine: {
            type: 'spline',
            barSpace: 6,
            animation: true,
            view: [750, 300],
            data: [
                [
                    {value: 100},
                    {value: 200},
                    {value: 300},
                    {value: 400},
                    {value: 100},
                    {value: 900},
                ], [
                    {value: 300},
                    {value: 400},
                    {value: 100},
                    {value: 300},
                    {value: 500},
                    {value: 30},
                ]
            ],
            padding: { top: 10, left: 30, bottom: 40, right: 60 },
            colorList: ['#00383d', '#ff0000'],
            xAxis: { grid: 5, text: [{text: 'Feb 2021'},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: 'Feb 2022'},] },
            yAxis: { grid: 0, separationLine: false, separation: 5, color: '#000', unit: ' kr', toRight: true, trimNumber: 0, size: 18, stroke: 'rgba(255,255,255,0)' },
        },
        epkHistoryGraph: getHistoryGraphChartConfig(HistoryDevelopmentGraph),
        gift: 1,
        growMoney: false,
    }); 

    const { messageList, email, fundOverviewSelected, abacusConfig, dropdownMenu, drawDayList } = state;
    const style = classNames('guideline-wrapper sgw-content');

    return <article className={style}>

        <div className="content -hide">
            <h1 className="paragraph center">AØS</h1>
            <GrowMoney on={state.growMoney}/>
            <div className="paragraph-small -include-top center">
                <a href="#" role="button" onClick={ (e) => { click(props, state, setState, e, 'toggle-grow-money') }}>Toggle animation</a>
            </div>
        </div>            

        <div className="content -hide">
            <Countdown seconds={90} h2="Loarem ipsum"/>
        </div>            

        <div className="content -hide_">
            <Chart {...state.chartLine}/>
        </div>            
        <div className="content -hide">
            <Chart {...state.chartBar}/>
        </div>            
        <div className="content -hide">
            <Chart {...state.chartProgess}/>
        </div>            

        <div className="content -hide_">
            <Chart {...state.epkHistoryGraph}/>
        </div>            

        <div className="content -amount-field -hide">
            { false && <Slider current={[50]} interval={[0,20000]} unit="kr" label="Test 1" step={200} adjustmentButton={[
                {step: 100, label: 'Mink 100'},
                {step: 100, label: 'Økt 100'},
            ]}/> }

            { false && <Slider current={[50]} interval={[0,300]} label="Test 2"/> }

            <Slider current={[500, 20000]} interval={[0,30000]} disabled={[true]} label="Test 3" field={['Fra', 'Til']} showPlusWhenValueOnMax/>
        </div>            

        <div className="content -gift-card -hide">
            { state.gift === 1 && <Message type="gift-message" text="Du har fått gave" role="">
                <div className="action">
                    <StandardButton onClick={()=>{ setState({...state, gift: 2})}}>Åpne</StandardButton>
                    <StandardButton onClick={()=>{ setState({...state, gift: 3})}} buttonType={ButtonTypes.STANDARD_NEGATIVE}>Avbryt</StandardButton>
                </div>
            </Message> }

            { state.gift === 2 && <GiftCard 
                title="Kjære barne barn"
                text="Gratulerer med dagen! Jeg håper alle dine bursdagsønsker og drømmer blir til virkelighet."
                signing="Bestemor, Annette Nilsen"
                callback={(open: boolean) => { 
                    if ( open ) { return; }
                    setTimeout( () => { setState({...state, gift: 3}); }, 1500);
                }}
            /> }
        </div>            

        <div className="content -amount-field -hide">
            <Spinner type="linkPanel"/>
        </div>            

        <div className="content -amount-field -hide">
            <AmountField/>
        </div>            

        <div className="content -Sprouts -hide">
            <Sprouts max={5} active={3}/>
        </div>            

        <div className="content -dropdown-menu -hide-x">
            <Card type="dropdownMenu" action="select-account" click={(e: any, k: any, d?: any) => { click(props, state, setState, e, k, d); }} data={{
                fieldLabel: 'Konto',
                selectedLead: 'Til', 
                optionList: dropdownMenu.list,
                selected: dropdownMenu.selected,
                defaultText: 'velg en',
                // searchField: true,
                linkNextToLabel: {text: 'Lorem ipsum', click: () => {}}
            }}/>
        </div>

        <div className="content -dropdown-menu -hide">
            <Card type="dropdownMenu" action="select-drawday" click={(e: any, k: any, d?: any) => { click(props, state, setState, e, k, d); }} data={{
                fieldLabel: 'Velg trekkdag',
                optionList: drawDayList,
                selected: '10',
                defaultText: 'velg en',
                view: 'draw-day'
            }}/>
        </div>

        <div className="content -message -hide">
            { messageList.map( (d: any, i: number) => (
                <Message key={`message-${i}`} {...d} />
            ))}
        </div>

        <div className="content -abacus -hide">
            <h1 style={{textAlign: 'center'}}>100 234 kr</h1>

            <div style={{minHeight: '120px'}}>                   
                { abacusConfig.active && <Abacus loading={abacusConfig.loading} /> }
            </div>

            <div style={{margin: '10px 0 0 0'}}>
                <div style={{margin: '10px 0 0 0', display: 'block'}}>
                    <StandardButton onClick={(e)=>{click(props, state, setState, e,'redraw-abacus')}}>
                        Simulerer side starter opp
                    </StandardButton>
                </div>
                <div style={{margin: '10px 0 0 0'}}>
                    <StandardButton onClick={(e)=>{click(props, state, setState, e,'set-abacus-loading', 4)}}>
                        Simulerer 4 sekunder kakulering 
                    </StandardButton>
                </div>
            </div>
        </div>

        <div className="content -fund-overview -hide">
            <StandardButton onClick={(e)=>{click(props, state, setState, e,'open-fund-overview')}}>
                {fundOverviewSelected?.navn ??  'Velg fond'}
            </StandardButton>
        </div>

        <div className="content -button -hide">
            <Link href="#link">Lenke</Link>
        </div>

        <div className="content -button -hide">
            <StandardButton onClick={(e)=>{click(props, state, setState, e,'open-modal')}}>Open modal</StandardButton>                   
        </div>

        <div className="content -carousel -hide">
            <Carousel list={[
                {id: 'en-test', textList: ['En', '100 kr']},
                {id: 'to-test', textList: ['To', '200kr']},
                {id: 'tre-test', textList: ['Tre', '300kr']},
                {id: 'fire-test', textList: ['fire', '300kr']},
                {id: 'fem-test', textList: ['fem', '300kr']},
                {id: 'seks-test', textList: ['seks', '300kr']},
                {id: 'syv-test', textList: ['syv', '300kr']},
                {id: 'aatte-test', textList: ['åtte', '300kr']},
                {id: 'ni-test', textList: ['ni', '300kr']},
                {id: 'ti-test', textList: ['ti', '300kr']},
            ].map((d) => <Card key={d.id} data={d} />)}/>
        </div>

        <div className="content component-group -hide">
            <Input id="guideline-email" type="email" placeholder="Epost" value={email} onChange={(e) => { changeEmail(state, setState, e.target.value) }} required maxLength={20}/>
        </div>

    </article>;
};