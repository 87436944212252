/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LinkCard from '../link-card';
import Format from '../format-functions';
import ColorFunctions from '../color-functions';

class PortefoljeUtlisting extends Component {
    static propTypes = {
        portefoljeTilknyttetAnnenBank: PropTypes.bool.isRequired,
        portefoljer: PropTypes.array.isRequired,
        texts: PropTypes.object.isRequired,
        header: PropTypes.string.isRequired
    };

    visVerdistigning = verdistigning => {
        if (verdistigning) {
            return (
                <div className="urealisert-gevinst">
                    <span className="tekst">{this.props.texts.get('verdistigning')}</span>
                    <span className="verdi"> {Format.currency(verdistigning)}</span>
                </div>
            );
        }
        return null;
    };

    visEier = eier => {
        if (eier) {
            return (
                <div className="urealisert-gevinst">
                    <span className="tekst">{this.props.texts.get('eier')}</span>
                    <span className="verdi"> {eier}</span>
                </div>
            );
        }
        return null;
    };

    printPortefoljeType = type => <div className="portefolje-type">{this.props.texts.get(type)}</div>;

    visPortefoljeTilhoringhet = (portefoljeTilknyttetAnnenBank, bankNavn, eier) =>
        (portefoljeTilknyttetAnnenBank || eier) && <div className="bank-navn">{bankNavn}</div>;

    portefoljePath = portefolje =>
        portefolje.eier ? `disponertePortefolje/${portefolje.id}` : `portefolje/${portefolje.id}`;

    listUtPortefoljer = portefoljer =>
        portefoljer.map((portefolje, index) => {
            const portefoljePath =
                portefolje.type === 'IPS' && portefolje.fond.length === 0
                    ? 'individuellpensjonssparing'
                    : this.portefoljePath(portefolje);
            return (
                <LinkCard key={portefolje.id} link={portefoljePath} gradient={ColorFunctions.hentGradient(index)}>
                    <div>
                        {this.visPortefoljeTilhoringhet(
                            this.props.portefoljeTilknyttetAnnenBank,
                            portefolje.bank.banknavn,
                            portefolje.eier
                        )}
                        <div className="card-content-table">
                            <div className="card-content-table-cell">
                                <div className="title">
                                    <div className="portefolje-navn">{portefolje.navn}</div>
                                </div>
                                {this.printPortefoljeType(portefolje.type)}
                                {this.visVerdistigning(portefolje.verdistigning)}
                                {this.visEier(portefolje.eier)}
                            </div>
                            <div className="card-content-table-cell">
                                <div className="total-verdi">
                                    <div className="tekst">{this.props.texts.get('verdi')}</div>
                                    <div className="verdi">{Format.currency(portefolje.totalVerdi)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LinkCard>
            );
        });

    render = () => {
        const { portefoljer } = this.props;

        if (portefoljer.length) {
            return (
                <div>
                    <header>{this.props.texts.get(this.props.header)}</header>
                    {this.listUtPortefoljer(this.props.portefoljer)}
                </div>
            );
        }

        return null;
    };
}

export default PortefoljeUtlisting;
