import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

require('../styles/link-card.scss');

class LinkCard extends Component {
    static propTypes = {
        link: PropTypes.string,
        children: PropTypes.object,
        gradient: PropTypes.object,
        icon: PropTypes.string,
        isDisabled: PropTypes.bool
    };

    constructor(props) {
        super(props);

        const state = {};

        if (props.gradient) {
            state.style = {
                borderImage: `linear-gradient(90deg,${props.gradient.start},${props.gradient.end}) 1 100%`
            };
        }

        state.icon = props.icon ? props.icon : 'ikon-liten-pil-hoeyre';
        this.state = state;
    }

    onClick = (isDisabled, event) => (isDisabled ? event.preventDefault() : null);

    render() {
        let { style } = this.state;

        if (this.props.link === undefined) {
            return (
                <div className="card link-card">
                    <div className="card-content" style={style}>
                        {this.props.children}
                    </div>
                </div>
            );
        }

        style = { ...style, paddingRight: '40px' };
        const styleLink = { pointerEvents: 'none' };

        return (
            <Link
                className="card link-card"
                to={`/${this.props.link}`}
                onClick={e => this.onClick(this.props.isDisabled, e)}
                style={this.props.isDisabled ? styleLink : {}}
            >
                <div className="card-content" style={style}>
                    {this.props.children}
                    {!this.props.isDisabled && <i className={this.state.icon} />}
                </div>
            </Link>
        );
    }
}

export default LinkCard;
