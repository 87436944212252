export const defaultDates = [1, 5, 10, 15, 20, 25];

const faaDatoValg = trekkDato => {
    const datoer = defaultDates.slice(); // copy the array
    if (trekkDato && !datoer.find(d => d === trekkDato)) {
        datoer.push(trekkDato);
        datoer.sort((a, b) => a - b);
    }
    return datoer;
};

const finnNesteTrekkDato = trekkDag => {
    const date = new Date();
    if (trekkDag > new Date().getDate()) {
        const mnd = (date.getMonth() % 12) + 1;
        return `${trekkDag}/${mnd} ${date.getFullYear()}`;
    }
    const mnd = ((date.getMonth() + 1) % 12) + 1;

    const year = date.getMonth() === 11 ? date.getFullYear() + 1 : date.getFullYear();

    return `${trekkDag}/${mnd} ${year}`;
};

const finnNavnPaaManed = (month, texts) => {
    switch (parseInt(month, 10)) {
        case 1:
            return texts.get('jan');
        case 2:
            return texts.get('feb');
        case 3:
            return texts.get('mar');
        case 4:
            return texts.get('apr');
        case 5:
            return texts.get('mai');
        case 6:
            return texts.get('jun');
        case 7:
            return texts.get('jul');
        case 8:
            return texts.get('aug');
        case 9:
            return texts.get('sep');
        case 10:
            return texts.get('okt');
        case 11:
            return texts.get('nov');
        case 12:
            return texts.get('des');
        default:
            return month;
    }
};

const skrivUtDatoDDMMMMYYYY = (date, texts) => {
    if (/^\d\d\d\d-\d\d-\d\d$/.test(date)) {
        const dateArr = date.split('-');
        const monthName = finnNavnPaaManed(dateArr[1], texts);
        const day = parseInt(dateArr[2], 10);
        const year = parseInt(dateArr[0], 10);

        return `${day}. ${monthName} ${year}`;
    }

    return date;
};

const skrivUtDatoFraString = date => {
    const dateArray = date.split('-')
    return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`
};

const skrivUtDato = (date, texts) => {
    const dateArr = date.split('.');
    const monthName = finnNavnPaaManed(dateArr[0], texts);

    return `${monthName} ${dateArr[1]}`;
};

export default {
    faaDatoValg,
    finnNesteTrekkDato,
    skrivUtDato,
    finnNavnPaaManed,
    skrivUtDatoDDMMMMYYYY,
    skrivUtDatoFraString
};
