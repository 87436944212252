/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import { settSideTittel } from '../../actions/header';
import Feilmelding from '../../components/feilmelding';
import LinkCard from '../../components/link-card';
import AppMessage from '../../components/AppMessage';

require('../../styles/spare-profil.scss');

class SpareProfil extends Component {
    static propTypes = {
        texts: PropTypes.object,
        kundeInfo: PropTypes.object,
        pensjonsvalg: PropTypes.object,
        samtykker: PropTypes.object,
        errors: PropTypes.object,
        settSideTittel: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount = () => this.props.settSideTittel(this.props.texts.get('minSpareProfil'));

    sjekkForServerFeil = (errors) => errors.LAST_SAMTYKKE || errors.LAST_SPARE_PROFIL_DATA;

    visIpsProfil = () => {
        if (this.props.pensjonsvalg.ipsUttaksAlder && this.props.pensjonsvalg.ipsRisikoprofil) {
            return (
                <LinkCard link="spareprofil/ipsavtale">
                    <div className="title">{this.props.texts.get('ipsProfil')}</div>
                </LinkCard>
            );
        }

        return null;
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.props.kundeInfo && this.props.samtykker) {
            const { kundeInfo } = this.props;

            return (
                <div>
                    <AppMessage appMessage={this.props.serviceMessage} context="PROFILE" />
                    <div className="spare-profil-ingress">{this.props.texts.get('spareProfilIngress')}</div>
                    <LinkCard link="spareprofil/ommeg" icon="ikon-penn-36 big-icon aligned-top-right">
                        <div>
                            <div className="navn">{`${kundeInfo.fornavn} ${kundeInfo.etternavn}`}</div>
                            <div className="adresse">{kundeInfo.adresselinje1}</div>
                            <div className="postnummer">{kundeInfo.postnr}&nbsp;</div>
                            <div className="poststed">{kundeInfo.poststed}</div>
                            <div className="epost">{kundeInfo.epost}</div>
                            <div className="mobil">{kundeInfo.mobil}</div>
                        </div>
                    </LinkCard>
                    <LinkCard link="spareprofil/minpensjon" icon="ikon-penn-36 big-icon">
                        <div className="title">{this.props.texts.get('minPensjonTittel')}</div>
                    </LinkCard>
                    <LinkCard link="spareprofil/samtykker">
                        <div className="title">{this.props.texts.get('vilkaarTittel')}</div>
                    </LinkCard>
                    {this.visIpsProfil()}
                </div>
            );
        }
        return <Loading title={this.props.texts.get('lasterDinSpareProfil')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.SpareProfil,
            ...state.Feilmeldinger,
            ...state.Texts,
            ...state.Header,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
        };
    }
)(SpareProfil);
