// @ts-nocheck // kiet fix it later
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { lastMorningstarData, getFondHistorikkUtvikling } from '../../actions/fond';
import { formatDateToText, getURLquery } from './Functions';
import { FundSprouts, FundChartTabel } from './ShareComponents';
import Chart from './Chart/Chart';

import './style/EKFfondVisning.scss';

const getChartConfig = ( dataList, note={highest: 0, lowest: 0} ) => {
    const chart = {
        animation: true,
        view: [830, 200],
        data: dataList,
        type: 'spline',
        padding: { top: 20, left: 0, bottom: 40, right: 60 },
        xAxis: { grid: 1, text: [], size: 16, lineColor: '#000' },
        yAxis: { grid: 0, separationLine: false, separation: 3, color: 'transparent', unit: '%', toRight: true, trimNumber: 0, size: 16 },
        colorList: ['#34AFD9', '#90D000', '#00383D', '#FFA100', '#EAC7E0', '#B83653', '#FFD600', '#AA77FF'],
    };  

    if ( (note.highest - note.lowest) < chart.yAxis.separation ) {
        chart.yAxis.trimNumber = 100;
        chart.padding.right = 70;
    }
    return chart;
};

const convetPanelDataToChart = ( source, xAxisFormat='MMm yyyy' ) => {
    const note = {
        fondBaseValue: source.list[0]?.verdi || 0,
        date: new Date(source.list[0]?.dato || undefined),
        highest: 0,
        lowest: 0,
        fondDataList: [],
    };

    source.list.forEach( (data) => {
        let exact = 0;
        const fondData = {};
        if ( note.fondBaseValue ) {
            exact = (((data.verdi - note.fondBaseValue) * 100) / note.fondBaseValue);
            fondData.exact = Math.round(exact * 100) / 100;
        } else {
            fondData.exact = 0;
        }
        fondData.verdi = Math.round( fondData.verdi );
        fondData.value = fondData.exact;
        fondData.unit = '%';
        fondData.blackboardText = `${fondData.exact} % ${source.name}`;
        fondData.lineWidth = 5;
        fondData.fill = .25;

        if ( note.highest < fondData.value  ) { note.highest = fondData.value; }
        if ( note.lowest > fondData.value  ) { note.lowest = fondData.value; }

        note.fondDataList.push( fondData );
    });

    const chart = getChartConfig([note.fondDataList], note);
    const last = source.list.length - 1;
    source.list.forEach( (data, i) => {
        const date = new Date(data.dato);
        const text = {text: formatDateToText(date, xAxisFormat), textAnchor: 'start' };
        if ( i === 0 ) {
            text.textAnchor = 'start';
        } else if ( i === last ) {
            text.textAnchor = 'end';
        } else {
            text.size = '0';
            text.lineStroke = '0';
        }
        chart.xAxis.text.push(text);
    });

    const cloned = JSON.parse(JSON.stringify(chart));
    cloned.yAxis.size = 24;
    cloned.xAxis.size = 24;
    cloned.view = [750, 250];

    return {
        large: chart,
        small: cloned, 
        table: {
            title: 'Historisk utvikling',
            head: [{text: 'År'}, {text: `${source.name} i prossent`}],
            body: note.fondDataList.map( (d) => {
                return [
                    {text: formatDateToText((new Date(d.dato)), 'dd. MMm yyyy') },
                    {text: d.prosent },
                ];
            }),
        },
        note,
    };
};

const getMornigstarUrl = ( data ) => {
    if ( !data ) { return ''; }
    return `https://lt.morningstar.com/njtrrc6eo8/snapshot/default.aspx?tab=0&SecurityToken=${data.id}]2]1]FONOR%24%24ALL_434&Id=${data.id}&ClientFund=1&CurrencyId=NOK&theme=green`;
};

const getFundNameList = () => {
    const query = getURLquery();
    if ( query.fond ) {
        return decodeURI(query.fond).replace(/-/g, ' ').split(',').map((v) =>{
            return v.trim();
        });
    }

    const splited = window.location.href.replace(/\/$/, '').split('/');
    return [(splited.pop() || '').replace( /-/, ' ' ).trim()];
};

const click = ( props: Props, state: State, setState: (s: State) => void, e: any, key='', data?: any ): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === 'toggle-info' && data && data.isin ) {
        setState({...state, expanded: state.expanded === data.isin ? '' : data.isin });
    } else if ( key === 'change-graph-view' && data && data.id && data.fond ) {
        const list = JSON.parse(JSON.stringify(state.list));
        const fond = list.find(( fond) => fond.isin === data.fond.isin );
        if ( fond ) {
            fond.view.option.selected = data.id;
            setState({...state, list});
        }
    }
}

const getFondList = (props, morningstar) => {
    // nested ternary
    // eslint-disable-next-line
    const fundNameList = props.fond ?
        props.fond instanceof Array
            ? props.fond : [props.fond]
            : getFundNameList();

    if (!fundNameList) {
      return []; 
    } 

    const typePin = {
        'NO0010165335': 'Kombinasjonsfond', // eika balansert
        'NO0010126030': 'Aksjefond', // Eika Egenkapitalbevis
        'NO0010075476': 'Aksjefond', // Eika Global
        'NO0010687262': 'Rentefond', // Eika Kreditt
        'NO0010199086': 'Aksjefond', // Eika Norge
        'NO0008001880': 'Aksjefond', // Eika Norden
        'NO0010003999': 'Aksjefond', // Eika Spar
    };
   
    return morningstar.filter(
        fundInMorningstar => fundInMorningstar.navn.toLowerCase() === fundNameList[0].toLowerCase()
    ).map(fund => ({
      ...fund,
      type: typePin[fund.isin]
    }));
};

const getFondViewOption = ( fond ) => {
    const utvikling = fond?.utvikling || {};
    const option = {
        list: [
            {
                id: 'lastMonth',
                label: 'Siste måned',
                optLabel: 'Siste måned',
                length: 2,
                type: 'DAILY',
                format: 'dd MMm yyyy',
                value: 0,            
                style: 'development',
                title: 'Fondets utvikling siste måned',
            },
            { 
                id: 'soFarThisYear',
                label: 'hittil i år',
                optLabel: 'Siste 1 år',
                length: (12*1),
                type: 'DAILY',
                format: 'dd MMm yyyy',
                value: utvikling.iAar || 0,
                style: 'development',
                title: 'Fondets utvikling hittil i år',
            }, 
            { 
                id: 'year3',
                label: 'Siste 3 år',
                optLabel: 'Siste 3 år',
                length: (12*3),
                type: 'MONTHLY',
                format: 'dd MMm yyyy',
                value: utvikling.prosentSiste3Aar || 0,
                style: 'development',
                title: 'Fondets utvikling siste 3 år',
            }, 
            utvikling.prosentSiste5Aar !== undefined || !fond ? { 
                id: 'year5',
                label: 'Siste 5 år',
                optLabel: 'Siste 5 år',
                length: (12*5),
                type: 'MONTHLY',
                format: 'dd MMm yyyy',
                value: utvikling.prosentSiste5Aar || 0,
                style: 'development',
                title: 'Fondets utvikling siste 5 år',
            } : null, 
            utvikling.prosentSiste10Aar !== undefined || !fond ? { 
                id: 'year10',
                label: 'Siste 10 år',
                optLabel: 'Siste 10 år',
                length: (12*10),
                type: 'MONTHLY',
                format: 'dd MMm yyyy',
                value: utvikling.prosentSiste10Aar || 0,
                style: 'development',
                title: 'Fondets utvikling siste 10 år',
            } : null,
        ].filter( (d) => !!d),
    };

    // Vis den siste/lengste periode som default
    option.selected = option.list[(option.list.length - 1)].id;
    option.pin = option.list.reduce( (p, d) => {
        p[d.id] = d;
        return p;
    }, {});

    return option;
};

const verifyUtviklingsverdier = (list, opt) => {
    const now = new Date();
    if ( opt.id === 'lastMonth' ) {
        now.setDate(0);
        const pin = Array.from({length: (now.getDate() + 1)}).reduce( (p) =>{
            p[ formatDateToText(now, 'yyyy-mm-dd')] = true;
            now.setDate((now.getDate()-1));
            return p;
        }, {});
        return list.filter( (d) => !!pin[d.dato] );
    } 

    if ( opt.id === 'soFarThisYear' ) {
        now.setDate(1);
        now.setMonth(0);
        const info = { from: formatDateToText(now, 'yyyy-mm-dd'), passed: false };
        return list.filter( (d) => {
            if ( info.passed || d.dato === info.from ) { 
                info.passed = true;
                return true;
            }
            return false;
        });
    }   
    return list;
};

const getFundGraphCollection = (fondList) => {
    const view = {
        option: getFondViewOption(),
    };

    fondList.forEach( (fond) => {
        (fond?.view?.option?.list || []).forEach( (d) => {
            if ( !d.chart || !view.option.pin[d.id] ) { return; }
            const chart = d.chart.large || d.chart.small;
            if ( !chart ) { return; }

            const note = d.chart.note || view.note;
            const list = JSON.parse(JSON.stringify(chart.data));
            list[0].forEach( (data) => { 
                delete(data.fill); 
                data.lineWidth = 3;
            });

            view.option.pin[d.id].data = (view.option.pin[d.id].data || []).concat( list );

            if ( ! view.option.pin[d.id].note ) {
                view.option.pin[d.id].note = {highest: 0, lowest: 0};
            }

            if ( note.lowest < view.option.pin[d.id].note.lowest ) { 
                view.option.pin[d.id].note.lowest = note.lowest; 
            }

            if ( note.highest > view.option.pin[d.id].note.highest ) { 
                view.option.pin[d.id].note.highest = note.highest; 
            }

            if ( ! view.option.pin[d.id].legend ) {
                view.option.pin[d.id].legend = [];
            }

            if ( fond.navn ) {
                view.option.pin[d.id].legend.push({
                    name: fond.navn,
                    url: `${window.location.origin}/spare/${fond.navn.trim().toLowerCase().replace(/\s+/g, '-')}`,
                });
            }

            if ( !view.option.pin[d.id].xAxis ) {
                view.option.pin[d.id].xAxis = chart.xAxis;
            }

        });
    });

    view.option.list.forEach( (d) => {
        const large = getChartConfig( d.data, d.note );
        large.xAxis = d.xAxis;
        large.view = [830, 400];
        large.padding.right += 10;

        d.legend.forEach( (info, i) => {
            info.color = large.colorList[i];
        });

        const small = JSON.parse( JSON.stringify(large) );

        small.yAxis.size = 22;
        small.xAxis.size = 22;
        small.view = [750, 400];

        d.chart = {
            large,
            small,
            note: d.note,
        }
    });

    const collection = {
        style: 'fund-collection-view',
        title: 'Månedsrapport grafer',
        view,
    };
    return collection;
};

const initViewList = (props, state, setState, morningstar, dispatch)  => {
    const fondList = getFondList( props, morningstar );

    if ( !fondList.length ) { 
        return setState({...state, list: fondList, loading: false});
    }

    let fondLength = fondList.length;

    const finish = () => {
        if ( props.samling ) {
            setState({...state, list: [getFundGraphCollection(fondList)], loading: false});
        } else {
            setState({...state, list: fondList, loading: false});
        }
    };

    const initFond = ( fond ) => {
        fond.view = { morningstarUrl: getMornigstarUrl(fond), option: getFondViewOption(fond)};
        let count = fond.view.option.list.length;
        const getGraphData = ( opt ) => {
            if ( opt.id === 'ethical' ) {
                opt.value = `${opt.value} av 5`;
                if ( --count || --fondLength ) { return; }
                finish();
            } else {
                dispatch(getFondHistorikkUtvikling(fond.isin, opt.length, opt.type, ( result ) => {
                    let values = JSON.parse(JSON.stringify((result??[])[0]?.utviklingsverdier ?? []) );
                    if ( opt.id === 'lastMonth' || opt.id === 'soFarThisYear' ) {
                        values = verifyUtviklingsverdier( values, opt );
                    }

                    fond.historikkUtvikling = result;
                    opt.chart = convetPanelDataToChart( {name: fond.navn, list: values}, opt.format);

                    if ( opt.id === 'lastMonth' && !opt.value ) {
                        const chartDataList = opt.chart.large.data[(opt.chart.large.data.length - 1)];
                        opt.value = chartDataList[(chartDataList.length-1)].value;
                    }

                    opt.value = Math.round( (opt.value*100) ) / 100;
                    // eslint-disable-next-line
                    opt.style += opt.value === 0 ? '' : (opt.value > 0 ? ' -increase' : ' -decrease');
                    opt.value = `${opt.value} %`;
                    if ( --count || --fondLength ) { return; }
                    finish();
                }));
            }
        };
        fond.view.option.list.forEach( (opt) => { getGraphData(opt); });
    };

    fondList.forEach( (d) => { initFond(d); });
};

const EKFfondVisning = ( props ) => {
    const dispatch = useDispatch();
    const { morningstar } = useSelector((state) => state.Fond) || {};
    const { texts } = useSelector((state) => state.Texts) || {};
    const [state, setState] = useState({
        expanded: false,
        loading: true,
        list: undefined,
        domain: (window.location.origin || '').match( /localhost/i ) ? 'https://eika.no' : window.location.origin,
    });

    useEffect(() => {
        dispatch(lastMorningstarData());
    }, [dispatch]);

    useEffect(() => {
        if  ( state.list || !morningstar ) { return; }
        initViewList(props, state, setState, morningstar, dispatch);
    }, [props, morningstar, state, setState, dispatch]);

    return (
        <div id="sparing-react-app" className={classNames('ekf-fond-visning', `-view-count-${(state.list || []).length}`, {
            '-loading': state.loading,
            '-collection': !!props.samling,
        })}>
            { !!texts && <>
                { (state.list || []).map( (fond) => {
                    return <div key={`fond-item-${fond.isin}`} className={classNames('fund-item', fond.style)}>
                        <section className="fund-item-head">
                            <div className="max-width-content">
                                { fond.title ? <h1 className="h1">{fond.title}</h1> :
                                    <h1 className="h1">{`Månedsrapport for ${fond.navn || fond.name}`}</h1>
                                }

                                { !!fond.type && <p className="lead">{fond.type}</p>}
                            </div>
                        </section>
                        <section className="fund-item-body">
                            <div className="max-width-content">
                                <div className="view-content">
                                    <div className="fund-option-view-wrapper">
                                        { fond.view.option.list.map( (opt) => {
                                            if ( opt.id !== fond.view.option.selected ) { return null; }

                                            return <div key={`${fond.isin}-${opt.id}-view`}>
                                                { !!opt.title && <h2 aria-describedby={`${fond.isin}-${opt.id}-view-description`}>{opt.title}</h2> }
                                                { !!opt.description && <div id={`${fond.isin}-${opt.id}-view-description`} >{opt.description}</div> }

                                                <div className="fund-drop-down-menu-holder">
                                                    <select className="fund-drop-down-menu" aria-label="Fond historikk uvikling" name="fondHistorikkUtivkling" value={opt.id} onChange={(e)=>{                                                    
                                                        click(props, state, setState, e, 'change-graph-view', {id: e.currentTarget.value, fond});
                                                    }}>
                                                        {fond.view.option.list.map( (d) => {
                                                            return <option key={`${fond.isin}-${opt.id}-${d.id}-opt`} value={d.id}>{d.optLabel || d.label}</option>
                                                        })}
                                                    </select>
                                                </div>

                                                { !!opt.chart && <div className="chart-holder">
                                                    { (opt.chart.legend || []).length > 0 && <ul className="legend-list">
                                                        { opt.chart.legend.map( (d,i) => (
                                                            <li key={`${fond.isin}-${opt.id}-legend-${i}`}>
                                                                <span className="legend-dot" style={{backgroundColor: d.color}}/>
                                                                <span className="legend-name">{d.text}</span>
                                                            </li>
                                                        ))}
                                                    </ul>}

                                                    <div aria-hidden="true" className="view-chart-wrapper -large"><Chart {...opt.chart.large} /></div>
                                                    <div aria-hidden="true" className="view-chart-wrapper -small"><Chart {...opt.chart.small} /></div>
                                                    <div className="chart-table"><FundChartTabel table={opt.chart.table}/></div>

                                                    { (opt.legend || []).length > 0 && <ul className="legend-info">
                                                        {opt.legend.map( (d) => (
                                                            <li key={`legend-${d.name}`}>
                                                                { d.url ? <a href={`${d.url}`}>
                                                                        <span className="color" style={{backgroundColor: d.color}}/>
                                                                        <span className="name">{d.name}</span>
                                                                    </a> : <>
                                                                        <span className="color" style={{backgroundColor: d.color}}/>
                                                                        <span className="name">{d.name}</span>
                                                                </>}
                                                            </li>
                                                        ))}
                                                    </ul>}
                                                </div>}
                                            </div>
                                        })} 
                                    </div>

                                    { fond.eikespirer !== undefined && <div className={`view-fund-info-detail -${fond.eikespirer ? 'double' : 'single'}`}>
                                        <div className="view-fund-info-detail-left">
                                            <ul className="fund-info-wrapper">
                                                { fond.view.option.list.map( (opt) => (
                                                    <li key={`${fond.isin}-${opt.id}-info`} className={`info-item ${opt.style}`} aria-labelledby={`${fond.isin}-${opt.id}-label ${fond.isin}-${opt.id}-value`}>
                                                        <div id={`${fond.isin}-${opt.id}-label`} className="opt-label"><span>{opt.label}</span></div>
                                                        <div aria-hidden="true" className="icon-holder"><span/></div>
                                                        <div id={`${fond.isin}-${opt.id}-value`} aria-labelledby={`${fond.isin}-${opt.id}-label`} className="opt-value"><span>{opt.value}</span></div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        { !! fond.eikespirer && <div className="view-fund-info-detail-right ethical-holder">
                                                <div className="ethical-wrapper">
                                                    <div className="esg-info">
                                                        <div className="esg-info-title">{texts.get('esg')}</div>
                                                        <FundSprouts fond={fond} />
                                                        <h3>{`${fond.eikespirer} av 5`}</h3>
                                                            <div className={`esg-info-text-wrapper -${state.detailAboutESG ? 'expanded' : 'collapsed'}`}>
                                                                <div className="esg-tool-wrapper">
                                                                    <a href="#" role="button" className="esg-link" onClick={(e) => { e.preventDefault(); setState({...state, detailAboutESG: !state.detailAboutESG}); }}>
                                                                        {state.detailAboutESG ? 'Vis mindre' : 'Vis mer'}
                                                                    </a>
                                                                </div>
                                                                { /* eslint-disable-next-line */}
                                                                <div className="esg-range-description paragraph-large" dangerouslySetInnerHTML={{ __html: texts.get('esgRangeDescription') }}/>

                                                                <div className="bold">{texts.get('esgRangeTextLabel')}</div>
                                                                <ul className="esg-range-text-list">
                                                                    { Array.from({length: 5}).map( (x: any, i: number) => {
                                                                        return <li key={`esg-range-text-${i}`}>
                                                                            <span>{texts.get(`esgRangeText${5-i}`)}</span>
                                                                        </li>
                                                                    })}
                                                                </ul>
                                                            </div>
                                                    </div>

                                                    <a href={`${state.domain}/spare/ansvarlige-investeringer`} className="esg-link">
                                                        Om ansvarlige investeringer
                                                    </a>
                                                </div>  
                                            </div>
                                        }
                                    </div> }
                                </div>

                                <div className="view-footer">
                                    { !!fond.view.morningstarUrl && <div className={`expander-holder -${state.expanded === fond.isin ? 'expanded' : 'collapse'}`}>
                                        { /* eslint-disable */ }
                                        <a role="button" href="#" className="expander-btn" 
                                            aria-expanded={`${!state.expanded}`}
                                            aria-controls={`${fond.isin}-morningstar`}
                                            onClick={(e)=>{ click(props, state, setState, e, 'toggle-info', fond); }}
                                        >{`${state.expanded ? 'Skjul' : 'Vis'} detaljert informasjon om fondet`}</a>

                                        <div className="expander-content" id={`${fond.isin}-morningstar`} aria-hidden={`${state.expanded !== fond.isin}`}>
                                            <iframe title={`${fond.navn} detaljer på Morningstar`} src={fond.view.morningstarUrl} className="fond-detail"></iframe>
                                        </div>
                                        { /* eslint-enable */ }
                                    </div>}                             
                                </div>
                            </div>
                        </section>
                    </div>
                })}
            </>}
        </div>
    );
};

export default EKFfondVisning;
