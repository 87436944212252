// @ts-nocheck // kiet fix it later
import { generateId, formatAmount } from '../../Functions';
import { getChartText } from './ChartFunction';

const initXaxisText = (state, list) => {
    //const bottom = state.axis.y.max + state.padding.top;
    const bottom = (state.axis.y.zero || state.axis.y.max || 0) + state.padding.top;

    /* eslint-disable */
    const text = state.axis.x.text instanceof Array ? state.axis.x.text : (
        state.axis.x.text ? [state.axis.x.text] : []
    );
    /* eslint-enable */

    if (state.axis.x.title) {
        const title = typeof(state.axis.x.title) === 'string' ? {text: state.axis.x.title} : state.axis.x.title;
        list.push(getChartText({
            x: state.axis.x.max + state.padding.left,
            y: bottom - 5,
            textAnchor: 'end',
            color: state.axis.x.textColor || state.axis.x.color,
            size: state.axis.x.size,
            ...title
        }));
    }

    if (!text.length || !((state.graph || {}).list || []).length) { return; }

    const lineSize = state.axis.x.lineSize;
    let index = 0; 
    const pin = {};

    state.graph.list.forEach((data, i) => {
        if (!data.center || !text[index]) { return; }
        const x = data.center[0];
        if (isNaN(x) || pin[x]) { return; }
        pin[x] = 1;

        const note = typeof (text[index]) === 'object' ?
            text[index] : { text: text[index] };
        if (!note.text) { return; }

        list.push(getChartText({
            x,
            y: bottom + 30,
            text: note.text,
            title: note.title || '',
            color: note.textColor || state.axis.x.textColor || state.axis.x.color,
            size: note.size || state.axis.x.size || '',
            textAnchor: note.textAnchor,
        }));

        list.push({
            id: generateId(`x-p-${i}`),
            type: 'path',
            path: `M ${x - (lineSize[0] / 2)},${bottom - lineSize[1]} L ${x - (lineSize[0] / 2)},${bottom}`,
            style: {
                stroke: state.axis.x.color || '#444',
                strokeWidth: note.lineStroke || '2',
                fill: 'none'
            }
        });

        index++;
    });
};

const initYaxisText = (state, list) => {
    //const highest = state.graph.highest || 0;
    const highest = state.distance || (state.graph || {}).highest || 0;
    const separation = state.axis.y.separation;
    if (state.axis.y.title) {
        const title = typeof(state.axis.y.title) === 'string' ? {text: state.axis.y.title} : state.axis.y.title;
        list.push(getChartText({
            x: state.padding.left,
            y: state.padding.top - 5,
            textAnchor: 'start',
            color: state.axis.y.textColor || state.axis.y.color,
            ...title
        }));
    }

    if (!separation || state.axis.y.noText) { return; }

    const lineSize = state.axis.y.lineSize;
    const unit = state.axis.y.unit || '';
    const bottom = state.axis.y.max + state.padding.top;
    const value = highest / separation;
    const height = state.axis.y.max / separation;
    const source = state.lowest < 0 ?
        { length: (separation+1), index: -1} : { length: separation, index: 0};
    for (let i = 0; i < source.length; i++) {
        let yValue = (value * (source.index + 1)) + (state.lowest ?? 0);
        if ( state.axis.y.trimNumber ) {
            yValue = Math.round( yValue * state.axis.y.trimNumber ) / state.axis.y.trimNumber;
        } else if ( state.axis.y.trimNumber === 0 ) {
            yValue = Math.round( yValue );
        }
        const y = bottom - (height * (source.index + 1));
        const x = state.axis.y.toRight ?
            (state.axis.x.max + state.padding.left) : state.padding.left;

        source.index++;
        if (isNaN(x) || isNaN(y) || yValue === 0) { continue; }


        list.push(getChartText({
            x: x - (state.axis.y.toRight ? -10 : 5),
            y,            
            textAnchor: state.axis.y.toRight ? 'start' : 'end',
            text: formatAmount(yValue)+(unit || ''),
            //text: formatAmount( parseInt((value * (i + 1)),10) ) + unit,
            color: state.axis.y.textColor || state.axis.y.color,
            size: state.axis.y.size
        }));

        /* eslint-disable */
        const endLine = state.axis.y.separationLine ? (
          state.axis.y.toRight ? state.padding.left :
            (state.view[0] - state.padding.right)
        ) : (x+lineSize[0]);
        /* eslint-enable */

        list.push({
            id: generateId(`y-p-${i}`),
            type: 'path',
            path: `M ${x},${y} L ${endLine},${y}`,
            style: {
                stroke: state.axis.y.color || '#444',
                strokeWidth: '2',
                fill: 'none'
            }
        });
    }
};

const initAxisList = (axis, state) => {
    const list = [];
    if (!state || !state.axis || !state.axis[axis]) { return list; }

    let path = '';
    let delta = 0;
    const count = state.axis[axis].grid || 0;
    const xMax = state.axis.x.max;
    const yMax = state.axis.y.max;
    const gap = axis === 'x' ? parseInt((yMax / count), 10) : parseInt((xMax / count), 10);
    const length = count > 1 ? (count + 1) : count;

    for (let i = 0; i < length; i++) {
        if (axis === 'x') {
            delta = (state.axis.y.zero || yMax) + state.padding.top - (gap * i);
            //path = `M ${(state.padding.left + state.axis.x.leftGap)},${delta} ${xMax + state.padding.left},${delta}`;
            // delta = (yMax + state.padding.top) - (gap * i);
            path = ((state.axis || {}).x || {}).stretch && i === 0 ?
                `M 0,${delta} ${(state.view ?? [])[0]},${delta}` :
                `M ${state.padding.left},${delta} ${xMax + state.padding.left},${delta}`;
        } else if ( state.axis.y.toRight ) {
            delta = xMax - (gap*i) + state.padding.left;
            path = `M ${delta},${state.padding.top} ${ delta},${yMax + state.padding.top}`;        
        } else {
            delta = state.padding.left + (gap * i) + state.axis.x.leftGap;
            path = `M ${delta},${state.padding.top} ${delta},${yMax + state.padding.top}`;
        }

        list.push({
            id: `axis-${axis}-${i}`,
            type: 'path',
            path,
            style: {
                stroke: '#444', // state.axis[axis].color || '#444',
                strokeWidth: '2',
                fill: 'none',
                opacity: i ? '.2' : '1'
            }
        });
    }

    if ( /^(bar|line|spline)/i.test(state.type) ) {
        if ( axis === 'x' ) {
            initXaxisText(state, list);
        } else {
            initYaxisText(state, list);
        }
    }
    return list;
};

export default initAxisList;