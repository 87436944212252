import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers';

const middlewares = [thunk];

export default () => {
    if (module.hot) {
        // DEVELOPMENT
        const logger = createLogger({ collapsed: true });
        const middleware = composeWithDevTools(applyMiddleware(...middlewares, logger));
        const store = createStore(rootReducer, middleware);

        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            // eslint-disable-next-line
            const nextRootReducer = require('../reducers/').default;
            store.replaceReducer(nextRootReducer);
        });

        return store;
    }
    const middleware = composeWithDevTools(applyMiddleware(...middlewares));
    return createStore(rootReducer, middleware);
};
