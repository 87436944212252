import React, { useEffect, useState, useRef, MouseEvent } from 'react';
import classNames from 'classnames';
import { clearSelection, getOffset, generateId, formatAmount } from '../util/Functions';
import AmountField from '../AmountField/AmountField';
import { MultiObject, FormElementFocusAndBlurEvent, FormElementChangeEvent } from '../../domain/Types';

import './Slider.scss';

interface AdjustmentButton {
    step: number;
    label: string;
};

interface Props {
    label: string;
    step?: number;
    interval?: number[];
    // eslint-disable-next-line
    current?: number | number[];
    round?: number;
    unit?: string;
    hideSliderBtnCurrentValue?: boolean;
    disabled?: boolean[];
    // eslint-disable-next-line
    change?: (s?: MouseEvent)=> void;
    // eslint-disable-next-line
    focus?: (s?: MouseEvent)=> void;
    adjustmentButton?: AdjustmentButton[];
    field?: string[];
    showPlusWhenValueOnMax?: boolean;
    // eslint-disable-next-line
    ignoreDelayChangeCallback?: boolean;
};

interface State {
    id: string;
    timer: any;
    mode: any;
    started: any;
    holderRef: React.RefObject<HTMLDivElement> | null;
    sizes: number[];
    interval: number[];
    current: number[];
    round: number;
    force: string | number;
};

const getPoint = (e: any ): number[] => {
    if ( !e ) { return [0, 0]; }

    const data = e.touches ? (e.touches[0] || e) : e;
    const point = [data.clientX || 0, data.clientY || 0];
    return point;
};

const getMove = (state: State, value?: string | number ): number => {
    value = parseInt(`${value || 0}`, 10);
    if ( typeof value !== 'number' ) { return 0; }

    const { interval } = state;
    if ( value < interval[0] ) { return 0; }
    if ( value > interval[1] ) { return 100; }

    const move = (value - interval[0]) / (interval[1] - interval[0]);
    return parseInt(`${move * 100}`, 10);
};

const round = (number=0, round = 0): number => {
    if (!round || isNaN(round) || !number || isNaN(number)) {
        return number;
    }
    return Math.round(number / round) * round;
};

const updateSlider = (state: State, setState: (s: State) => void, move: any, point: any, startup=false, index=0): any => {
    if (!state.holderRef) { return null; }

    const { sizes, interval, current } = state;
    const wrapper = state.holderRef.current as any;
    const track = wrapper.children[1];
    const holder = track.children[0];
    const tail = track.children[1];
    const btn = holder.children[index];

    if ( !btn ) { return null; }

    const info: any = {
        offset: getOffset(track),
        size: [track.clientWidth, track.clientHeight],
        current: JSON.parse(JSON.stringify(current))
    };

    info.move = typeof move === 'number' ? move :
        ((point[0] - info.offset[0]) * 100) / info.size[0];

    if (info.move < sizes[0]) {
        info.move = sizes[0];
    } else if (info.move > sizes[1]) {
        info.move = sizes[1];
    }

    info.move = parseInt(`${info.move}`, 10);
    btn.style.left = `${info.move}%`;
    if ( current.length < 2 ) {
        tail.style.width = `${info.move}%`;
    } else {
        const margin = btn.clientWidth / 2;
        const position = [getOffset(holder.children[0]),getOffset(holder.children[1])].map( (p) => {
            return (((p[0]+margin) - info.offset[0]) * 100) / info.size[0];
        }).sort();
        const sorted = position[0] > position[1] ? position.reverse() : position;
        tail.style.width = 'auto';
        tail.style.left = `${sorted[0] || 0}%`;
        tail.style.right = `${100 - (sorted[1] || 0)}%`;
    }

    if (!startup) {
        info.current[index] = (interval[1] - interval[0]) * (info.move / 100);
        info.current[index] = parseInt(info.current[index], 10) + interval[0];
        setState({...state, current: info.current });
    }

    info.current[index] = round(info.current[index], state.round);
    if ( info.current[index] < interval[0] || info.move === 0 ) {
        info.current[index] = interval[0];
    } else if ( info.current[index] > interval[1] || info.move === 100 ) {
        info.current[index] = interval[1];            
    }

    return info;
};

const updateCurrent = (state: State, setState: (s: State) => void, current: number[], startup=false): void => {
    if ( !(current instanceof Array) ) { return; }
    current.forEach( (value, i) => {
        const move = getMove( state, value );
        updateSlider(state, setState, move, null, startup, i);
    });
};

const downStart = (props: Props, state: State, setState: (s: State) => void, e: any ): void => {
    if ( typeof(props.focus) === 'function' ) { props.focus(e); }
    const { timer, mode } = state;
    clearTimeout( timer.current.onSliding || 0 );
    mode.current.started = getPoint(e);
    mode.current.onSliding = true;
    mode.current.index = parseInt(e.target.getAttribute('data-index') || '0', 10); 
};

const downMove = (props: Props, state: State, setState: (s: State) => void, e: any): void => {
    const { mode, timer } = state;
    clearSelection();
    if ( !mode.current.started ) { return; }

    const index = mode.current.index;
    const point = getPoint(e);
    const info = updateSlider(state, setState, null, point, false, index );
    if ( typeof(props.change) !== 'function' ) { return; }

    if ( props.ignoreDelayChangeCallback) {
        // @ts-ignore
        props.change(info); 
    } else {
        if ( timer.current.changeCallback ) { clearTimeout(timer.current.changeCallback); }
        timer.current.changeCallback = setTimeout( () => {
            // @ts-ignore
            props.change(info);
        }, 1000);
    }
};

const downEnd = (props: Props, state: State, setState: (s: State) => void): void => {
    const { mode, timer } = state;
    mode.current.started = null;
    timer.current.onSliding = setTimeout( () => {
        delete( mode.current.onSliding );
        setTimeout( () => { setState({...state, force: (new Date()).getTime()});}, 300);
    }, 800);
};

const moveOut = (props: Props, state: State, setState: (s: State) => void): void => {
    const { mode, timer } = state;
    if ( ! mode.current.started) { return; }

    clearTimeout(timer.current.move || 0);
    timer.current.move = setTimeout(() => {
        mode.current.started = null;
        delete( mode.current.onSliding );
        setTimeout( () => { setState({...state, force: (new Date()).getTime()});}, 300);
    }, 300);
};

const moveIn = (props: Props, state: State): void  => {
    clearTimeout(state.timer.current.move || 0);
};

const clickedSliderTrack = (props: Props, state: State, setState: (s: State) => void, e: MouseEvent): void  => {
    const bntReg = /slider-btn-holder/i;
    const target: any = e.target;
    const ignore = !target || bntReg.test(target.getAttribute('class')) || bntReg.test(target.parentNode.getAttribute('class')); 
    if ( ignore ) { return; }

    const point = getPoint( e );
    const index = 1;
    updateSlider(state, setState, null, point, false, index );
};

const changeInputRange = (props: Props, state: State, setState: (s: State) => void, e: FormElementChangeEvent): void  => {
    const value = parseInt(`${e.target.value || 0}`, 10);
    const move = getMove( state, value );
    updateSlider(state, setState, move, null, true, 0);
    setState({...state, current: [value]});
};

const adjustValue = (props: Props, state: State, setState: (s: State) => void, increase=false, data: any): void  => {
    const { interval, current } = state;
    let value = current[0] + (data.step * (increase ? 1 : -1));

    if ( value < interval[0] ) { value = interval[0]; }
    if ( value > interval[1] ) { value = interval[1]; }

    const move = getMove( state, value );
    const info = updateSlider(state, setState, move, null, true, 0);
    if ( info.current[0] !== value ) {
        info.current[0] = value;
    }
    
    setState({...state, current: [value]});
    if (typeof props.change === 'function') { props.change(info); }
};

const click = ( props: Props, state: State, setState: (s: State) => void, e: MouseEvent, key='', data?: any ): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === 'decrease' && data ) {
        adjustValue(props, state, setState, false, data);
    } else if ( key === 'increase' && data ) {
        adjustValue(props, state, setState, true, data);
    } else if ( key === 'click-slider-track' ) {
        clickedSliderTrack(props, state, setState, e);
    }
};

const changeAmountFieldValue = ( props: Props, state: State, setState: (s: State) => void, e: FormElementChangeEvent, i=0): void => {
    const value = parseInt( `${e.target.value}`.replace(/\s+/g, ''), 10 );
    clearTimeout( state.timer.current.changeAmountFieldValue || 0 );
    state.timer.current.changeAmountFieldValue = setTimeout( () => {
        if ( isNaN(value) || !value ) { return; }

        const move = getMove( state, value );
        const current = JSON.parse(JSON.stringify(state.current));
        current[i] = value;
        setState({...state, current });
        updateSlider(state, setState, move, null, true, i);
    }, 300);
};

const blurAmountFieldValue = ( props: Props, state: State, setState: (s: State) => void, e?: FormElementFocusAndBlurEvent, i=0): void => {
    const value = parseInt( `${e?.target?.value}`.replace(/\s+/g, ''), 10 );
    if ( isNaN(value) || !value ) { return; }

    const move = getMove( state, value );
    const info = updateSlider(state, setState, move, null, true, i);
    info.current[i] = value;
    setState({...state, current: info.current });
    if (typeof props.change === 'function') { props.change(info); }
};

const initCurrent = (props: Props): number[] => {
    const interval = props.interval || [0, 100];

    /* eslint-disable */
    const current: number[] = props.current instanceof Array ? props.current : 
        (typeof props.current === 'number' ? [props.current] : [0]);
    /* eslint-enable */

    if ( typeof(current[0]) === 'number' && current[0] < interval[0] ) {
        current[0] = interval[0];
    }
    if ( typeof(current[1]) === 'number' && current[1] > interval[1] ) {
        current[1] = interval[1];
    }

    return current;
};


export default (props: Props): JSX.Element => {
    const [state, setState] = useState<State>({
        id: generateId('slider'),
        force: '',
        round: props.round || 0,
        sizes: [0, 100],
        interval: props.interval || [0, 100],
        current: initCurrent(props),
        timer: useRef<MultiObject>({}),
        mode: useRef<MultiObject>({}),
        holderRef: useRef<HTMLDivElement>(null),
        started: useRef<boolean>(false)
    });

    const { interval, current, id, mode, force } = state;
    const style = classNames('slider-wrapper', `-timer-${force}`, {
        '-on-sliding': !!(mode.current || {}).onSliding,
        '-show-slider-btn-current-value': !props.hideSliderBtnCurrentValue,
        '-has-adjustment-button': (props.adjustmentButton || []).length > 0,
        '-has-field': (props.field || []).length > 0,        
    });

    useEffect(() => {
        if ( !state.started.current ) {
            updateCurrent(state, setState, state.current, true);
            state.started.current = true;
        }
    }, [state, setState]);

    return (
        <div role="application" className={style}>
            { (props.field || []).length > 0 && <ul className={`slider-field-wrapper -count-${(props.field || []).length}`}> 
                { (props.field || []).map( ( label: string, i: number) => {
                    return <li key={`slider-field-item-${i}`}>
                        <div className={classNames('slider-field-holder', {
                            '-disalbed': !!(props?.disabled || [])[i],
                            '-on-max': !! props.showPlusWhenValueOnMax && (current[i] >= interval[1]),
                        })}>
                            <AmountField
                                label={label}
                                standard                            
                                id={`slider-field-item-${i}`}
                                value={current[i]}
                                onChange={(e) => { changeAmountFieldValue(props, state, setState, e, i); }}
                                onBlur={(e) => { blurAmountFieldValue(props, state, setState, e, i); }}
                                disabled={!!(props?.disabled || [])[i]}
                            />
                        </div>
                    </li>
                })}
            </ul>}

            <div id={id} ref={state.holderRef as React.RefObject<HTMLDivElement>} className="slider-holder"
                onMouseMove={(e)=> { downMove(props, state, setState, e); }}
                onMouseOut={()=> { moveOut(props, state, setState); }}
                onMouseEnter={()=> { moveIn(props, state); }}
                onTouchMove={(e)=> { downMove(props, state, setState, e); }}
            >
                <input className="slider-input-range" type="range" aria-label={props.label}
                    min={state.interval[0]} max={state.interval[1]} step={props.step || 1} value={state.current[0]}
                    onChange={(e)=>{changeInputRange(props, state, setState, e);}}
                />

                <div aria-hidden="true" className="slider-track" onMouseEnter={()=> { moveIn(props, state); }} onClick={(e) => { click(props, state, setState, e, 'click-slider-track')}}>
                    <span className="slider-btn-holder">
                    { current.map( (value: any, i: number) => {
                        /* eslint-disable-next-line jsx-a11y/interactive-supports-focus */
                        return <span key={`slider-btn-${i}`} data-index={`${i}`} data-unit={props.unit || ''}
                            role="slider"
                            className={classNames('slider-btn', {
                                '-disabled': !!(props?.disabled || [])[i]
                            })}
                            data-value={formatAmount(value)}
                            aria-valuenow={value}
                            aria-valuemax={interval[1]}
                            aria-valuemin={interval[0]}
                            onMouseDown={(e)=> { if (!(props?.disabled || [])[i]) { downStart(props, state, setState, e); } }}
                            onMouseUp={()=> { downEnd(props, state, setState); }}
                            onMouseEnter={()=> { moveIn(props, state); }}
                            onTouchStart={(e)=> { if (!(props?.disabled || [])[i]) { downStart(props, state, setState, e); } }}
                            onTouchEnd={()=>{ downEnd(props, state, setState); }}
                            aria-label={props.label}
                        >{value}</span>
                    })}
                    </span>
                    <span className="slider-tail" onMouseEnter={()=> { moveIn(props, state); }} />
                    { state.interval.map( (value: number, i: number) => {
                        return <span key={`interval-label-${i}`} className={`slider-label -${i ? 'maximum' : 'minimum'}`}>
                            {`${formatAmount(value)} ${props.unit || ''}`}
                        </span>;                    
                    })}

                    { (props.adjustmentButton || []).map( (btn: AdjustmentButton, i: number) => {
                        return <a key={`adjustment-button-${i}`} href="#" role="button" title={btn.label} aria-label={btn.label}
                            className={`adjustment-button -index-${i}`}
                            onClick={(e)=>{ click(props, state, setState, e, (i === 0 ? 'decrease' : 'increase'), btn ); }}
                        />
                    })}
                </div>
            </div>
        </div>
    );
}
