// @ts-nocheck // kiet fix it later
import React, { useEffect } from 'react';
import { Link } from '@eika/link';
import { IconTypes } from '@eika/icon';
import { useSelector } from 'react-redux';
import { Spinner } from '../../common/share/ShareComponents';
import FundOverview from '../../common/FundOverview/FundOverview';
import Message from '../../common/Message/Message';
import { createCookie, readCookie, eraseCookie, scrollBodyTop } from '../../common/util/Functions';

interface Props {
	action: any;
    appTexts: any;
};

const selectFund = ({filter, fund}): void => {
    setTimeout( () => {
        createCookie( 'fl-open-page', JSON.stringify((filter || {})), .1);
    }, 500);
    window.location.href = `${window.location.href.replace(/kjopFond.*/, '').replace(/\/+$/g,'')}/kjopFond/${fund.isin}`;
    return false;
};

export default (props: Props): JSX.Element => {
	const { action } = props;
    const { morningstar } = useSelector((state: any) => state?.Fond ?? {});
    const filter = JSON.parse((readCookie('fl-open-page') || '{}'));

    useEffect(() => {
        if ( morningstar !== undefined ) { return; }
        action.getFondList();
    }, [action, morningstar]);

    useEffect(() => {
        scrollBodyTop( 0 );
        return () => { eraseCookie('fl-open-page'); };        
    }, []);

    return <div className="buy-fund-wrapper -all-fund">
        { morningstar === undefined && <Spinner /> }
        { morningstar === null && <Message type="error-server" text="Ops! Noe gikk galt. Vennligst prøv igjen senerer."/> }

    	{ !!morningstar && <>
            <div className="buy-fund-header">
                <h1>Fondslisten</h1>
                <div className="">
                    <Link icon={IconTypes.BUET_PIL_HOEYRE_20} href={`${window.location.href.replace(/kjopFond.*/, '').replace(/\/+$/g,'')}/kjopFond/veileder`}>
                        Få hjelp til å velge type fond
                    </Link>
                </div>
            </div>
            <FundOverview
                {...props}
                filter={filter}
                useFundText="Kjøp"
                fundList={morningstar} 
                displayRightArrow
                selectCallback={(selected)=>{ return selectFund(selected); }} 
    	    />
        </>}
    </div>
};