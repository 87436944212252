// @ts-nocheck // kiet fix it later
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KjennDinKundeForm from './KjennDinKundeForm';
import { kanUtfylleSkjemaForBarn, getSkjemaForKundeBarn } from '../../actions/kjenndinkunde';
import { getURLquery } from '../../common/util/Functions';

const postMessageToRNWebView = (message) => window?.ReactNativeWebView?.postMessage(JSON.stringify(message));

const checkIfIsVerge = (kanUtfylleAHV) => {
    if (!kanUtfylleAHV) {
        return false;
    }
    if (
        kanUtfylleAHV.foedselsnummerErGyldig &&
        kanUtfylleAHV.barnErUnderAttenAar &&
        !kanUtfylleAHV.eksistererSparemaalPaaBarn &&
        kanUtfylleAHV.innloggetBrukerErVerge
    ) {
        return true;
    }
    return false;
};

const getErrorMessage = (kanUtfylleAHV) => {
    if (!kanUtfylleAHV) {
        return '';
    }
    if (!kanUtfylleAHV.foedselsnummerErGyldig) {
        return 'Vennligst sjekk at du har skrevet inn rett fødselsnummer.';
    }
    if (!kanUtfylleAHV.barnErUnderAttenAar) {
        return 'Dersom barnet er over 18 år, må barnet opprette sparefondet selv, eller så må du spare til barnet i ditt navn.';
    }
    if (kanUtfylleAHV.eksistererSparemaalPaaBarn) {
        return 'Foreløpig kan man bare ha ett sparemål i barnets navn. Dersom du ønsker å ha flere sparemål til barnet, kan det opprettes i ditt navn.';
    }
    if (!kanUtfylleAHV.innloggetBrukerErVerge) {
        return 'Sparefond i barnets navn kan du kun opprette for dine egne barn som er under 18 år.';
    }
    return '';
};

const KjennDinKundeBarnRNWebView = () => {
    const [fnr, setFnr] = useState('');
    const [state, setState] = useState({
        schema: null,
        query: getURLquery(decodeURIComponent(window.location.href)),
        // query: getURLquery('https://eika.test.eika.no/ekf-kjenndinkunde-barn?sparemalId%3D3D14128')        
    });

    const { kanUtfylleAHV, loading } = useSelector((state) => state.KjennDinKunde);
    const dispatch = useDispatch();
    const error = checkIfIsVerge(kanUtfylleAHV) ? '' : getErrorMessage(kanUtfylleAHV, dispatch);

    const handleSubmit = (result) => {
        postMessageToRNWebView({ action: 'submit_ok', result });
    };

    const handleError = (error) => {
        postMessageToRNWebView({ action: 'submit_error', error });
    };

    const handleInitialFetchDone = () => {
        postMessageToRNWebView({ action: 'initial_fetch_done' });
    };

    const changeFnr = (event) => {
        setFnr(event.target.value);
    };

    const submitFnr = () => {
        dispatch(kanUtfylleSkjemaForBarn(fnr || '-'));
    };

    useEffect( () => {
        if ( !state.query || state.schema ) { return; }
        if ( state.query.tradexCustomerId ) {
            // setTimeout( () => { 
            //     setState({
            //        sparemalId: state.query.sparemalId, 
            //         schema: {'eier':{'value':'0501'},'customerId':{'value':'01010756274'},'registreringsprosess':'no.eika.felles.kjenndinkunde.api.sparing.SparingRegistreringsprosess.EKF_AHV_CHILD','gjeldendeVurdering':'OK','erklaringtype':'PRODUKTKJOP','skjema':{'skjematype':'SPARING_PERSON','versjon':'v9','skjemaSporsmal':[{'definisjon':'EKF_PM_ORIGIN_CHILDS_MONEY','sporsmalstekst':'Er dette arv eller gave til barnet?','formaterHTML':false,'eksternId':'q-71','type':'CHECKBOX','synlighet':'BRUKER','utfyllingsregel':{'regel':'PAKREVET'},'instruks':'Vennligst bekret at dette er arv eller gave til barnet.','muligeSvar':[{'svarId':'r-71-1','tekst':'Ja','valgt':false}]}]}} 
            //    }); 
            // }, 4000);

            dispatch(getSkjemaForKundeBarn( state.query.tradexCustomerId, (result) => {
                setState({ tradexCustomerId: state.query.tradexCustomerId, schema: result});
            }));
        } else {
            setState({});
        } 
    }, [state, setState, dispatch]);

    return (
        <div id="kjenndinkunde-standalone" className="kjenndinkunde-standalone -basic">
            {/* eslint-disable */}
            { state.schema || checkIfIsVerge(kanUtfylleAHV) ? <KjennDinKundeForm
                    rotNodeId="#kjenndinkunde-standalone" onSubmit={handleSubmit} onError={handleError} isChild hideSpinner
                    onInitialFetchDone={handleInitialFetchDone}
                    fnrBarn={kanUtfylleAHV && kanUtfylleAHV.foedselsnummer}
                    schema={state.schema}
                    tradexCustomerId={state.tradexCustomerId}
                    standalone
                /> : (
                    state.query ? <div id="kdkform" className="loading -alone" /> : 
                    <div id="kdkform" className={`${loading ? 'loading' : 'normal'}`}>
                        <label htmlFor="barnFnr">Hva er barnets fødselsnummer?</label>
                        <div className={error ? 'has-error' : 'input-holder'}>
                            <input type="tel" id="barnFnr" className="form-control" placeholder="(11 tall)" onChange={changeFnr} value={fnr} aria-invalid={!!error} />
                            {!!error && <div aria-live="polite" className="sgw-label error input-error-message">{error}</div>}
                        </div>
                        <div className="action-holder">
                            <button type="button" className="sgw-button sgw-button-common progress-next button--positive" onClick={submitFnr}>Neste</button>
                        </div>
                    </div>
                )
            }
            {/* eslint-enable */}
        </div>
    );
};

export default KjennDinKundeBarnRNWebView;
