const EikaFondDescriptionList = [
    {
        'isin':'NO0010212350',
        'name':'Eika Alpha',
        'descriptionList':[
            'Fondet passer for deg som vil ta del i fremtidig verdiskapning i Norden.',
            'Eika Alpha er et aksjefond som hovedsakelig investerer i nordiske selskaper notert på børs eller regulerte markedsplasser, og er et nasjonalt fond med særskilt plasseringsstrategi, som har anledning til å investere inntil 15 % i ett selskap. ',
            'Eika Alpha passer for deg som ønsker å spare i aksjemarkedet. Generelt kjennetegnes aksjemarkedet av høy risiko, men også av muligheten for høy avkastning.',
        ],
    },
    {
        'isin':'NO0010165335',
        'name':'Eika Balansert',
        'descriptionList':[
            'Fondet passer for deg som ønsker å spare i aksjer og rentebærende verdipapirer.',
            'Eika Balansert er et kombinasjonsfond som investerer i aksjer og rentepapirer hovedsakelig utstedt av nordiske selskaper.',
            'Fondet investerer i det nordiske aksjemarkedet som kjennetegnes av høy risiko, men også av mulighet til høy avkastning. Fondet investerer også i det norske rentemarkedet hvor kredittrisikoen med utstederne vil være den fremtredende risikoen. Fondets renteplasseringer har middels risiko og avkastningsmuligheter.',
        ],
    },
    {
        'isin':'NO0010126030',
        'name':'Eika Egenkapitalbevis',
        'descriptionList':[
            'Fondet passer for deg som vil ta del i fremtidig verdiskapning innen bank og finans i Norge og i Norden.',
            'Eika Egenkapitalbevis er et aksjefond som investerer i egenkapitalbevis og aksjer utstedt av norske og nordiske banker notert på børs eller regulerte markedsplasser.',
            'Eika Egenkapitalbevis passer for deg som ønsker å spare i aksjemarkedet. Generelt kjennetegnes aksjemarkedet av høy risiko, men også av muligheten for høy avkastning.',
        ],
    },
    {
        'isin':'NO0010075476',
        'name':'Eika Global',
        'descriptionList':[
            'Fondet passer for deg som vil ta del i fremtidig verdiskapning i hele verden.',
            'Eika Global er et aksjefond som investerer i selskaper over hele verden notert på børs eller regulerte markedsplasser. ',
            'Eika Global passer for deg som ønsker å spare i aksjemarkedet. Generelt kjennetegnes aksjemarkedet av høy risiko, men også av muligheten for høy avkastning.',
        ],
    },
    {
        'isin':'NO0010687262',
        'name':'Eika Kreditt',
        'descriptionList':[
            'Fondet passer for deg som ønsker høyere verdistigning på sparepengene dine enn det sparing på bankkonto kan gi.',
            'Fondet investerer i rentebærende verdipapirer i det norske og det globale kredittmarkedet. ',
            'Eika Kreditt passer for deg som ønsker å spare i kredittmarkedet. Dette kjennetegnes av lavere svingninger og lavere risiko enn aksjefond, men høyere svingninger og høyere risiko enn øvrige rentefond.',
        ],
    },
    {
        'isin':'NO0008001880',
        'name':'Eika Norden',
        'descriptionList':[
            'Fondet passer for deg som vil ta del i fremtidig verdiskapning i Norden.',
            'Eika Norden er et aksjefond som hovedsakelig investerer i nordiske selskaper notert på børs eller regulerte markedsplasser.',
            'Eika Norden passer for deg som ønsker å spare i aksjemarkedet. Generelt kjennetegnes aksjemarkedet av høy risiko, men også av muligheten for høy avkastning.',
        ],
    },
    {
        'isin':'NO0010199086',
        'name':'Eika Norge',
        'descriptionList':[
            'Fondet passer for deg som vil ta del i fremtidig verdiskapning i Norge.',
            'Eika Norge er et aksjefond som hovedsakelig investerer i norske selskaper notert på børs eller regulerte markedsplasser.',
            'Eika Norge passer for deg som ønsker å spare i aksjemarkedet. Generelt kjennetegnes aksjemarkedet av høy risiko, men også av muligheten for høy avkastning.',
        ],
    },
    {
        'isin':'NO0010327760',
        'name':'Eika Obligasjon',
        'descriptionList':[
            'Fondet passer for deg som ønsker eksponering mot de delene av det norske obligasjonsmarkedet som har lav kredittrisiko.',
            'Eika Obligasjon er et obligasjonsfond som investerer i sertifikater og obligasjoner utstedt av norske utstedere.',
            'Eika Obligasjon passer for deg som ønsker å spare i obligasjonsmarkedet. Dette kjennetegnes av lave svingninger og lav risiko sammenlignet med aksjefond.',
        ],
    },
    {
        'isin':'NO0010172521',
        'name':'Eika Rente+',
        'descriptionList':[
            'Fondet passer for deg som vektlegger trygghet og stabilitet.',
            'Eika Rente+ er et rentefond med lav risiko. Sparing i dette fondet tilsvarer sparing på sparekonto i bank.',
            'Fondet har lav risiko og passer for deg som har kort tidshorisont på sparingen din.',
        ],
    },
    {
        'isin':'NO0010003999',
        'name':'Eika Spar',
        'descriptionList':[
            'Passer for deg som vil ta del i fremtidig verdiskaping i Norge, Norden og resten av verden.',
            'Eika Spar er et aksjefond som investerer i selskaper over hele verden notert på børs eller regulerte markedsplasser. Normalt er fondet investert med en fordeling på 1/3 norske aksjer, 1/3 nordiske aksjer og 1/3 globale aksjer.',
            'Eika Spar passer for deg som ønsker å spare langsiktig i aksjemarkedet. Generelt kjennetegnes aksjemarkedet av høy risiko, men også av mulighet til høy avkastning.',
        ],
    },
    {
        'isin':'NO0010776040',
        'name':'KLP AksjeGlobal Indeks P',
        'descriptionList':[
            'Passer for deg som ønsker å investere i et globalt indeksfond. Fondet har lavere forvaltningshonorar.'            
        ],
    },
    {
        'isin':'NO0010479066',
        'name':'Eika OMF',
        'descriptionList':[
            'Fondet passer for institusjonelle investorer som ønsker lav kreditt- og renterisiko.',
            'Eika Obligasjon investerer hovedsakelig i obligasjoner med fortrinnsrett (OMF)',
            'Fondet investerer i verdipapirer som oppfyller de til enhver tid gjeldende krav for pantsettelse i Norges Bank og i tråd med gjeldende regelverk for likviditetsreserve (LCR)'
        ],
    },
    {
        'isin':'NO0010095953',
        'name':'Eika Sparebank',
        'descriptionList':[
            'Fondet passer for deg som ønsker høyere verdistigning på sparepengene dine enn sparing på bankkonto kan gi.',
            'Fondet investerer i sertifikater og obligasjoner med mindre enn 1 års durasjon, hovedsakelig utstedt av norske sparebanker i tillegg til rentebærende verdipapirer garantert av den norske stat, fylkeskommuner og kommuner, samt norske finansinstitusjoner.',
            'Eika Sparebank investerer i pengemarkedsinstrumenter, som generelt kjennetegnes av lav risiko. Avkastningen og risikoen i fondet skyldes i hovedsak opp- og nedganger i kredittmarginer og pengemarkedsrenter.'
        ],
    }
];
export default EikaFondDescriptionList;
