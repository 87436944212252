/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import AvtaleUtlisting from '../../components/sparing/avtale-utlisting';
import DonutChart from '../../components/charts/donut-chart';
import Legend from '../../components/legends/dot-legend';
import ColorFunctions from '../../components/color-functions';
import { settSideTittel } from '../../actions/header';
import Feilmelding from '../../components/feilmelding';
import AppMessage from '../../components/AppMessage';
import Format from '../../components/format-functions';
import ActionButton from '../../components/action-button';
import { lastarbeidsgiversPensjonssparing } from '../../actions/pensjon';

require('../../styles/ArbeidsgiversPensjonssparing.scss');

class ArbeidsgiversPensjonssparing extends Component {
    static propTypes = {
        arbeidsgiversPensjonsSparing: PropTypes.array,
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        errors: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            arbeidsgiversPensjonsSparing: props.arbeidsgiversPensjonsSparing ? 
                this.settOppArbeidsGiversPensjonSparingVisning(props) : undefined
        };
    }

    settOppArbeidsGiversPensjonSparingVisning = (props) => {
        const saving = this.convertArbeidsgiversPensjonsSparing(props.arbeidsgiversPensjonsSparing);
        return {
            ...saving,
            dataForDonutChartAndLegend: this.aggregerDataForDonutChartWithCheckboxLegend(saving.list),
            totalVerdi: this.regnUtTotalVerdi(saving.list),
            arbeidsgiversparingAvtaler: this.visAvtaler(saving.list),
        };
    };

    UNSAFE_componentWillMount() {
        if (this.props.arbeidsgiversPensjonsSparing) {
            this.setState({
                arbeidsgiversPensjonsSparing: this.settOppArbeidsGiversPensjonSparingVisning(this.props),
            });
        } else {
            this.props.lastarbeidsgiversPensjonssparing();
        }

        this.props.settSideTittel(this.props.texts.get('arbeidsgiversPensjonsparingTittel'));
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.arbeidsgiversPensjonsSparing) {
            this.setState({
                arbeidsgiversPensjonsSparing: this.settOppArbeidsGiversPensjonSparingVisning(newProps),
            });
        }
    };

    regnUtTotalVerdi = (arbeidsgiversPensjonsSparing) => {
        return (arbeidsgiversPensjonsSparing ?? []).reduce((content, data) => {
            (data.pensjonsrettigheter ?? []).forEach( (rettighet) => {
                content += (rettighet.innskuddssaldo || 0);
            });
            return content;
        }, 0);
    };

    visAvtaler = (arbeidsgiversPensjonsSparing) => {
        let index = 0;
        return (arbeidsgiversPensjonsSparing ?? []).reduce((content, data) => {
            (data.pensjonsrettigheter ?? []).forEach( (rettighet) => {
                content.push(<AvtaleUtlisting
                    key={`${data.navn}-${index}`}
                    texts={this.props.texts}
                    avtaler={[rettighet]}
                    gradient={ColorFunctions.hentGradient(index++)}
                />);
            });

            return content;
        }, []);
    };

    aggregerDataForDonutChartWithCheckboxLegend = (arbeidsgiversPensjonsSparing) => {
        let index = 0;
        return (arbeidsgiversPensjonsSparing ?? []).reduce((content, data) => {
            (data.pensjonsrettigheter ?? []).forEach( (rettighet) => {
                content.push({
                    text: rettighet.produktbetegnelse || rettighet.firmanavn,
                    value: rettighet.innskuddssaldo,
                    color: ColorFunctions.hentFarge(index++),
                    active: true,
                });
            });
            return content;
        }, []);
    };

    sjekkForServerFeil = (errors) => {
        return errors.LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA;
    };

    render() {
        const { arbeidsgiversPensjonsSparing } = this.state;

        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        return arbeidsgiversPensjonsSparing ? <div className="arbeidsgivers-pensjonssparing-wrapper">

            <AppMessage 
                appMessage={this.props.serviceMessage}
                context="PENSION" 
                text={arbeidsgiversPensjonsSparing.zeroPublicSector ? this.props.texts.get('zeroPensionFromPublicSectorMessage') : ''}
                type="attention"
            />

            <DonutChart data={this.state.arbeidsgiversPensjonsSparing.dataForDonutChartAndLegend}>
                <div className="donut-labels">
                    <div className="label-section">
                        <span className="ikon-palme-24" />
                        <div className="text">{this.props.texts.get('verdi')}</div>
                        <div className="value">
                            {Format.currency(this.state.arbeidsgiversPensjonsSparing.totalVerdi)}
                        </div>
                    </div>
                </div>
            </DonutChart>
            <Legend data={this.state.arbeidsgiversPensjonsSparing.dataForDonutChartAndLegend} />
            {this.state.arbeidsgiversPensjonsSparing.arbeidsgiversparingAvtaler}
            <ActionButton
                action="pensjonsKalkulator"
                text={this.props.texts.get('tilPensjonsKalkulatorKnapp')}
            />
        </div> : <Loading title={this.props.texts.get('lasterArbeidsgiversPensjonsSparing')} />;
    }

    convertArbeidsgiversPensjonsSparing = ( arbeidsgiversPensjonsSparing ) => {
        const descriptionPin = {zeroPublicSector: false};
        const currentPensionReg = /(egen(\s+)?pensjonskonto|innskuddspensjon)/i;
        const otherList = (arbeidsgiversPensjonsSparing || []).filter((d) => !currentPensionReg.test(d.navn)).reduce((p, d) => {
            return p.concat((d.pensjonsrettigheter || []).map((n) => {
                const data = { ...n, produkttype: d.navn };

                if ( /offentlig(\s+)?tjenestepensjon/i.test(d.navn) && !d.sum ){
                    data.message = {
                        type: 'attention',
                        text: 'Offentlig pensjon er ikke tilgjengelig',
                    };
                    descriptionPin.zeroPublicSector = true;
                }
                return data;
            }));
        }, []);

        const currentList = (arbeidsgiversPensjonsSparing || []).filter((d) => currentPensionReg.test(d.navn)).reduce((p, d) => {
            return p.concat((d.pensjonsrettigheter || []).map((n) => {
                return { ...n, produkttype: d.navn };
            }));
        }, []).reduce( (current, data) => {
            if (!data || !data.innskuddssaldo) { return current; }

            let risikoprofil = `${data.risikoprofil || ''}`.replace(/eika\s+selvvalgt/i, '').trim();
            if (/forsiktig/i.test(risikoprofil)) {
                risikoprofil = 'Lav';
            }

            if (risikoprofil) {
                risikoprofil = `${risikoprofil} risikoprofil`;
            }

            const found = current.find((d) => d.riskProfile === risikoprofil && d.source === 'TRADEX');
            const description = data.firmanavn;
            if (found) {
                found.innskuddssaldo += data.innskuddssaldo || 0;
                if (!descriptionPin[description]) {
                    found.firmanavn = [found.firmanavn, description].filter( (d) => !!d ).join(',')
                }
            } else {
                current.push({ ...data, riskProfile: risikoprofil });
            }

            descriptionPin[description] = 1;    
            return current;
        }, []).sort( (a, b) => {
            const x = a.risikoprofil || a.avtaleinnhold ? 1 : 0;
            const y = b.risikoprofil || b.avtaleinnhold ? 1 : 0;
            // eslint-disable-next-line
            return x < y ? 1 : x > y ? -1 : 0;
        });

        const output = [{
            pensjonsrettigheter: currentList,
        }, {
            pensjonsrettigheter: otherList,
        }].filter( (d) => d.pensjonsrettigheter.length > 0 );

        return {list: output, zeroPublicSector: descriptionPin.zeroPublicSector};
    }
}

export default connect(
    (state) => {
        return {
            ...state.Pensjon,
            ...state.Feilmeldinger,
            ...state.Texts,
            ...state.Header,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            lastarbeidsgiversPensjonssparing: () => {
                dispatch(lastarbeidsgiversPensjonssparing());
            },
        };
    }
)(ArbeidsgiversPensjonssparing);
