import React, { useState, useEffect, useRef, MouseEvent, RefObject } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { LinkButton, StandardButton, IconButton } from '@eika/button';
import { IconTypes } from '@eika/icon';
import { ErrorLabel } from '@eika/label';
import { Modal, ModalSize } from '@eika/modal';
import { usePrevious } from '../../common/util/Hook';
import Chart from '../../common/Chart/Chart';
import Card from '../../common/Card/Card';
import AppMessage from '../../common/AppMessage/AppMessage';
import TabList, { TabItem } from '../../common/TabList/TabList';
import FundOverview from '../../common/FundOverview/FundOverview';
import { generateId, getPageAppending, formatAmount, readCookie, eraseCookie, getURLquery, roundUpValue, parseNumber, formatDateToText } from '../../common/util/Functions';
import { getNewFondOrder } from '../../common/util/FondFunctions';
import Constant from '../../static/data/Constant';
import KalkultorLayout, { InputValueChangeEvent } from './KalkulatorLayout';
import { AppTexts, RouteLocation, RouteHistory, RouteMatch, MultiObject, StringObject } from '../../domain/Types';

interface PanelView {
    selected: string;
    list: TabItem[];
};

interface Props {
    appTexts: AppTexts;
    action: any;
    history: RouteHistory;
    location: RouteLocation;
    match: RouteMatch;
    standalone?: boolean;
    useSelectedFund?: (isin: string) => void;
};

interface State {
    id: string;
    reducer: any;
    appending: string;
    value: any;
    error: StringObject;
    inputList: any;
    mode: RefObject<MultiObject>;
    loading: boolean;
    panelView: PanelView;
    chartPin?: any;
    selected?: any;
    calculation?: any;
    detail?: any;
};

const getFondTypeName = ( fond: any ): string => {
    if ( !fond ) { return ''; }
    if ( fond.aksjesparekontoKompatibel )  { return 'Aksjefond'; }

    return fond.isin === Constant.isinBanksparing ? 'Banksparing' : 'Fond';
}

const getChartData = ( fond: any, response: any ) => {
    if ( !response ) { response = {}; }

    const info: any = { 
        high:[],
        low: [], 
        expected: [],
        yearList: [],
        description: fond.id === Constant.isinBanksparing ?
            `Sparing på sparekonto med en årlig renteutbetaling på ${response.aarligAvkastningUtenKostnader || '-'} %` :
            `${fond.navn} med en avkastningsforventning på ${response.aarligAvkastningUtenKostnader || '-'} % per år`.replace('.', ','),
        groupId: ['aarligAvkastningUtenKostnader'].map((key) => (response[key] || '-1')).join('-')
    };

    (response.withoutInflationWithoutYearlyCost || []).forEach( (d: any) => {
        const date = new Date(d.dato);
        const year = formatDateToText( date, 'MMm yyyy');

        info.yearList.push(year);
        [
            {key: 'verdi', id: 'expected'},
            {key: 'nedreGrense', id: 'low'},
            {key: 'ovreGrense', id: 'high'},
        ].forEach( (config: any) => { 
            const value = roundUpValue( (d[config.key] || 0), 100 );
            // eslint-disable-next-line
            const detail = config.key === 'nedreGrense' ? 'nedre grense' : (config.key === 'ovreGrense' ? 'øvre grense' : 'forventet');
            const text = `${fond.navn} ${detail}`;
            info[config.id].push({value, detail, text, name: fond.navn, typeName: getFondTypeName( fond )});
        });
    });

    return info; 
};

const getChart = ( selected: any, dataKey='expected' )  => {
    const bgColor: any = {
        'rgba(190, 124, 173, 1)': '#EFDEEA',
        'rgba(52, 175, 217, 1)': '#CCEBF5',
    };

    const note: any = { pin: {}, grouping: {id: {}, order: []}, index: 0, colorList: [
        'rgba(52, 175, 217, 1)',
        'rgba(240, 197, 92, 1)', //'#f0c55c', // yellow
        'rgba(102, 188, 121, 1)',
        'rgba(134, 117, 244, 1)', //'#8675f4', // purple
        'rgba(243, 80, 114, 1)', //'#f35072', // red
        'rgba(216, 144, 59, 1)', //'#d8903b', // orange
        'rgba(28, 201, 157, 1)', // '#1cc99d', // green
        'rgba(82, 183, 242, 1)', //'#52b7f2', // blue
    ]};
    
    const chart: any = {
        animation: true,
        view: [800, 500],
        data: [],
        name: [],
        type: 'spline',
        padding: { top: 50, left: 24, bottom: 50, right: 140 },
        xAxis: { grid: 1, text: [], size: 22, lineColor: '#000' },
        yAxis: { grid: 0, separationLine: true, separation: 5, color: '#ccc', unit: ' kr', toRight: true, trimNumber: 0, size: 20 },
        roundup: 100,
        highest: 0,
        multipleBlackboard: true,
        // eslint-disable-next-line
        table: { head: [{text: 'År'}], body: [], title: `${dataKey === 'high' ? 'Høy' : (dataKey === 'low' ? 'Lav' : 'Forventet')} utvikling` },
        description: [],
        legend: [],
        selected,
    };

    selected.list.forEach( (fond: any) => {
        if ( fond.forceColor && fond.result?.groupId ) { note.pin[fond.result.groupId] = fond.forceColor; }
    });

    selected.list.forEach( (fond: any) => {
        const { result } = fond;
        if ( !result || !result[dataKey] ) { return; }

        let color = note.pin[result.groupId];
        if ( !color ) {
            color = note.colorList[(note.index%note.colorList.length)];
            note.index++;
            note.pin[result.groupId] = color;
        }

        fond.color = color;
        fond.bgColor = bgColor[color] || color.replace( /1\)$/, '.5)');

        if ( note.grouping.id[result.groupId] ) {
            note.grouping.id[result.groupId].push( fond );
        } else{
            note.grouping.id[result.groupId] = [fond];
            note.grouping.order.push(result.groupId);
        }

        if ( chart.xAxis.text.length === 0 && (result.yearList || []).length ) {
            const last = result.yearList.length - 1;
            result.yearList.forEach( (year: string | number, j: number) =>{
                chart.xAxis.text[j] = {text: (j === 0 || j === last ? year : ' '), title: `År ${year}`, value: year};
            });
        }
    });

    selected.list = note.grouping.order.reduce( (list: any, groupId: string) => {
        return list.concat( note.grouping.id[groupId] );
    }, []);

    selected.grouping = note.grouping.order.map( (key: string) => {
        const fond = (note.grouping.id[key] || [])[0];
        if ( !fond ) { return; }

        const percent = `${`${key}`.replace('.', ',')} %`;
        const isBanksparing = fond.isin === Constant.isinBanksparing;
        let description = isBanksparing ? `Rente på ${percent}` : `Forventet verdiøkning ${percent}`;
        let error = '';
        const name = getFondTypeName( fond );

        (fond.result[dataKey] || []).forEach( (data: any) => {
            data.text = `${name} (${percent})`;
            data.color = fond.color;
        });

        chart.table.head.push({text: [name, percent] });
        chart.data.push( (fond.result[dataKey] || []) );

        if ( key === '-1' ) {
            description = '';
            error = 'Beregningen feilet';
            chart.legend.push([{...fond, typeName: getFondTypeName(fond), error: 'Beregningen feilet'}]);
        } else {
            chart.legend.push( (fond.result[dataKey] || []) );
        }
    
        if ( chart.highest < fond.result.highest ) { chart.highest = fond.result.highest; }
        note.grouping.id[key].forEach( (f: any) => {
            if ( f.result && f.result.description ) { chart.description.push(f.result.description); }
        });

        return {
            id: `${fond.isin}`.toLowerCase(),
            name: `${name} ${description.toLowerCase()}`, 
            title: name,
            description, 
            color: fond?.color || '#333',
            bgColor: fond?.bgColor || '#eee',
            list: note.grouping.id[key] || [],
            error,
        };
    }).filter( (data: any) => !!data );

    chart.highest = roundUpValue(chart.highest*1.01, (chart.yAxis.separation * 100));
    chart.xAxis.text.forEach( (x: any, i: number) => {
        const row = [{text: x.value}];
        chart.data.forEach( (data: any) => {
            row.push({text: `${formatAmount((data[i]?.value || ''))} kr`});
        });
        chart.table.body.push(row);
    });

    return chart;
};

const displayCalculation = (props: Props, state: State, setState: (s: State) => void)  => {
    const { selected = {pin: {}, list: []}} = state;
    let count = selected.list.length;
    if ( !count ) { return setState({...state, chartPin: undefined, loading: false }); }

    const pin: any = {};
    for ( const key in state.value ) {
        pin[key] = parseInt(`${state.value[key] || 0}`.replace(/\s+/g, ''), 10);
        if ( key === 'point' ) {
            pin[key] += 1;
        }
    }

    const paramId = ['onetime', 'monthly', 'point'].map( (key: string) => (pin[key] || 'x') ).join('-');
    const get = (fond: any) => {
        if ( fond.result?.paramId === paramId ) {
            if ( --count ) { return; }
            setState({...state, loading: false, chartPin: {
                low: getChart( selected, 'low' ),
                high: getChart( selected, 'high' ),
                expected: getChart( selected, 'expected' ),
            }});
        } else {
            props.action.getFondKalkulator({...pin, isin: fond.isin}, ( response: any ) => {
                fond.result = { paramId, response, ...getChartData(fond, response) };
                if ( --count ) { return; }
                setState({...state, loading: false, chartPin: {
                    low: getChart( selected, 'low' ),
                    high: getChart( selected, 'high' ),
                    expected: getChart( selected, 'expected' ),
                }});
            });
        }
    };

    selected.list.forEach( (fond: any) => { get(fond); });
};

const getInputList = () => {
    return [{ 
        type: 'amount',
        name: 'onetime',
        label: 'Engangsbeløp',
        unit: 'kr', 
        interval: [0, Constant.onetimeMaxAmount],
        maxLength: 10,
        adjustment: [
            {step: 500, label: 'Mink 500'},
            {step: 500, label: 'Økt 500'},
        ],
        validation: [{
            rule: 'number',
            allow: 'empty',
            message: 'Ugyldig verdi.',
        }, {
            rule: 'lessThanOrLike',
            number: Constant.onetimeMaxAmount,
            allow: 'empty',
            message: `Maks. ${formatAmount(Constant.onetimeMaxAmount)} kr`,
        }]
    }, {
        type: 'amount',
        name: 'monthly',
        label: 'Månedlig sparebeløp',
        unit: 'kr',
        interval: [0, Constant.monthlyMaxAmount],
        maxLength: 7,
        adjustment: [
            {step: 200, label: 'Mink 200'},
            {step: 200, label: 'Økt 200'},
        ],
        slider: true,
        validation: [{
            rule: 'number',
            allow: 'empty',
            message: 'Ugyldig verdi.',
        }, {
            rule: 'lessThanOrLike',
            number: Constant.monthlyMaxAmount,
            allow: 'empty',
            message: `Maks. ${formatAmount(Constant.monthlyMaxAmount)} kr`,
        }],
    }, {
        type: 'field',
        name: 'point',
        label: 'Spareperiode',
        unit: 'år',
        round: 1,
        maxLength: 2,
        interval: [1, 90],
        adjustment: [
            {step: 1, label: 'Mink 1 år'},
            {step: 1, label: 'Økt 1 år'},
        ],
         validation: [{
            rule: 'number',
            message: 'Ugyldig verdi.',
        }, {
            rule: 'greatThanOrLike',
            value: 1,
            message: 'Verdi må være lik eller større enn 1 år',
        }, {
            rule: 'lessThanOrLike',
            value: 90,
            message: 'Verdi må være lik eller mindre enn 90 år',
        }]
    }];
};

// eslint-disable-next-line
const changeCheckbox = (props: Props, state: State, setState: (s: State) => void, e: boolean, key: string) => {
    // do nothing
};

const changeInputValue = (props: Props, state: State, setState: (s: State) => void, e: InputValueChangeEvent) => {
    const update = { ...state };
    const name = e.currentTarget.name;
    const value = e.currentTarget.value || '';
    update.value[name] = value;

    if ( update.mode.current ) {
        clearTimeout((update.mode.current.displayCalculation as NodeJS.Timeout));
    }

    update.error.any = '';
    state.inputList.forEach( (input: any) => {
        if ( input.name !== name ) { return; }
        const error = (input.validation || []).find( (validation: any) => {
            let out = false;
            const number = parseNumber( value );
            if ( validation.rule === 'number') {
                if ( validation.allow === 'empty' && !`${value}`.replace( /\s/g, '' ) ) {
                    out = false;
                } else {
                    out = !`${value}`.replace( /\s/g, '' ).match( /^(?:[1-9]\d*|\d)$/ );
                }
            } else if ( validation.rule === 'lessThanOrLike' && validation.number ) {
                if ( validation.allow === 'empty' && (value === '' || number === 0) ) {
                    out = false;
                } else {
                    out = number && !isNaN(number) ? (number > validation.number) : true;
                }
            } else if ( validation.rule === 'greatThanOrLike' && validation.number ) {
                if ( validation.allow === 'empty' && (value === '' || number === 0) ) {
                    out = false;
                } else {
                    out = number && !isNaN(number) ? (number < validation.number) : true;
                }
            }
            return out;
        });

        update.error[name] = error ? error.message : '';
        update.error.any = update.error.any || update.error[name] || '';
    });

    if ( update.error.any === '' && update.mode.current ) {
        update.mode.current.displayCalculation = setTimeout( () => {
            displayCalculation( props, update, setState );
        }, 500);
    }
    setState({...update, loading: !update.error.any, chartPin: undefined});
};

const getIsinList = (state: State): any => {
    return (state.selected?.list || []).filter( (data: any) => !!data && data.isin !== Constant.isinBanksparing );
};

const buyFund = (props: Props, isin: string): void =>  {
    if ( props.standalone ) {
        if ( props.useSelectedFund ) { props.useSelectedFund(isin); }
        return;
    }

    const order = getNewFondOrder({ 
        action: Constant.actionKjop,
        fund: isin,
        finishBack: 1,
        skipFundSelecting: true, 
    });
    props.history.push(`${props.location.pathname}/fondhandel/${order}/step0?action=${Constant.piwikActionBuy}`);
};

const selectFund = ( props: Props, state: State, setState: (s: State) => void): void => {
    if ( props.standalone ) {
        return setState({...state, detail: 'show-fund-overview'});
    } 
    const order = getNewFondOrder({
        type: Constant.typeManedligSparing,
        action: Constant.actionVelgFond,
        value: state.value,
        isin: (state.selected?.list || []).map( (d: any) => d.isin ).join(','),
    });
    props.history.push(`${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionMonthly}`);
};

const click = ( props: Props, state: State, setState: (s: State) => void, e: MouseEvent | null, key='', data?: any): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === Constant.actionCloseModal ) {
        setState({...state, detail: null});
    } else if ( key === 'about-calculation' || key === 'show-table' ) {
        setState({...state, detail: key});
    } else if ( key === 'remove-selected' && data ) {
        const selected = JSON.parse(JSON.stringify(state.selected || {pin: {}, list: [], grouping: []}));
        selected.list = selected.list.filter( (d: any) => d.isin !== data.isin );
        selected.grouping.forEach( (group: any) => {
            group.list = group.list.filter( (d: any) => d.isin !== data.isin );
        });
        selected.grouping = selected.grouping.filter( (group: any) => !!group.list.length );
        const update = {...state, selected, loading: true, chartPin: undefined};

        if ( update.mode.current ) {
            clearTimeout((update.mode.current.displayCalculation as NodeJS.Timeout));
            update.mode.current.displayCalculation = setTimeout( () => {
                 displayCalculation( props, update, setState );
            }, 500);
        }
        setState( update );
    } else if ( key === 'change-panel-view' ) {
        setState({...state, panelView: {...state.panelView, selected: data.id}});
    } else if ( (key === 'adjustment-decrease' || key === 'adjustment-increase')  && data ) {
        const value = parseInt( `${state.value[data.name] || 0}`.replace(/\s+/g, ''), 10) + (data.step * (key === 'adjustment-increase' ? 1 : -1));
        if ( isNaN(value) || (data.interval && (data.interval[0] > value || data.interval[1] < value)) ) { return; }
        changeInputValue( props, state, setState, {currentTarget: {
            value: `${value}`, name: data.name
        }});
    } else if ( key === Constant.actionVelgFond ) {
        selectFund( props, state, setState );
    } else if ( key === Constant.actionKjop ){
        const list = getIsinList( state );
        if ( !list.length ) { return; }

        if ( list.length === 1 ){
            buyFund( props, list[0].isin );
        } else {
            setState({...state, detail: 'show-fund-option'});
        }
    }
};

const initView = ( props: Props, state: State, setState: (s: State) => void, reducer: any, isin='NO0010003999' ): any => {
    const bankSparing = {id: Constant.isinBanksparing, isin: Constant.isinBanksparing, navn: 'Sparekonto', forceColor: 'rgba(190, 124, 173, 1)'};
    const optionList = reducer.Fond.morningstar.filter( (fond: any) => fond.isin && fond.navn ).map( (fond: any) => { 
        return { id: fond.isin, isin: fond.isin, navn: fond.navn, aksjesparekontoKompatibel: !!fond.aksjesparekontoKompatibel }; 
    });

    optionList.push(bankSparing);
    const selected = {pin: {[bankSparing.isin]: bankSparing}, list: [bankSparing]};
    const isinPin = (getURLquery().isin || isin).trim().split(',').reduce((pin: { [k:string]: boolean}, key: string) =>{
        pin[key] = true;
        return pin;
    }, {}); 
    
    optionList.filter( (fond: any) => !!isinPin[fond.isin] ).forEach( (fond: any) => {
        if ( selected.pin[fond.isin] ) { return; }
        selected.pin[fond.isin] = fond;
        selected.list.push( fond );
    });

    displayCalculation( props, { ...state, selected }, setState );
};

const useSelectedFund = ( props: Props, state: State, setState: (s: State) => void, reducer: any, fund: any): boolean => {
    const list = (state.selected?.list || []).map( (d: any) => {
        return d.isin;
    });
    list.push(fund.isin);
    initView( props, {...state, detail: null}, setState, reducer, list.join(','));
    return false;
};


export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Fond'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const previousReducer = usePrevious(reducer);
    const [state, setState] = useState<State>({
        id: generateId('fondsberegning'),
        inputList: getInputList(),
        appending: getPageAppending( props ),
        reducer,
        error: {},
        value: {onetime: 1000, monthly: 500, point: 20, ...getURLquery()},
        mode: useRef<MultiObject>({}),
        loading: true,
        panelView: {
            selected: 'expected',
            list: [
                {'id': 'high',     name: 'Høy'       },
                {'id': 'expected', name: 'Forventet' },
                {'id': 'low',      name: 'Lav'       },
            ],
        },
    });

    const currentChart = (state.chartPin ?? {})[state.panelView.selected];

    useEffect(() => {
        return () => { 
            if ( !state.mode.current ) { return; }
            state.mode.current.unmounted = true; 
        };
    },[state.mode]);

    useEffect(() => {
        const ignore = readCookie('velgFond') || !reducer?.Fond?.morningstarPin ||
            JSON.stringify(reducer.Fond?.morningstarPin) === JSON.stringify(previousReducer?.Fond?.morningstarPin);
        if ( ignore ) { return; }
        initView(props, state, setState, reducer );
    }, [props, state, setState, reducer, previousReducer]);

    useEffect(() => {
        if ( props.standalone ) { return; }

        const order = readCookie('velgFond');
        const info = order ? readCookie(order) : null;
        if ( info ) {
            const data = JSON.parse(info);
            const param: string[] = [];
            for ( const key in (data.value || {}) ) {
                const value = [data[key], data.value[key]].filter( (v: any) => !!v);
                param.push(`${key}=${value.join(',')}`);
            }

            eraseCookie('velgFond');
            eraseCookie('order');

            window.history.go( -1);
            setTimeout( () => {
                props.history.push({
                    pathname: props.location.pathname.replace(/\?.*/, ''),
                    search: `?${param.join('&')}`
                }); 
            }, 100);
        }
    },[props.history, props.standalone, props.location]);

    return <KalkultorLayout
        id={state.id}
        appending={state.appending}
        namespace="fondsberegning-wrapper"
        title="Sparekalkulator"
        loading={state.loading}
        inputList={state.inputList}
        error={state.error}
        value={state.value}
        click={(e: any, k='', d: any) => { click(props, state, setState, e, k, d); }}
        changeInputValue={(e: any) => { changeInputValue(props, state, setState, e); }}
        changeCheckbox={(e: any, k='') => { changeCheckbox(props, state, setState, e, k); }}
        appMessage={<AppMessage appMessage={reducer?.App?.serviceMessage} context="SAVING_CALCULATOR" />}
        tool={<div className="fondsberegning-selected-fund-wrapper">
            <div className="fondsberegning-selected-fund-title">Velg fond</div>
            <ul className="fondsberegning-selected-fund-list flat">
                { (state.selected?.grouping || []).map( (data: any, i: number) => {
                    return <li key={`selected-${i}`} style={{backgroundColor: data.bgColor }} className={classNames('fondsberegning-selected-fund-item', data.id)}>
                        { !!data.title && <div className="fondsberegning-selected-fund-item-title">{data.title}</div> }
                        { !!data.description && <div className="fondsberegning-selected-fund-item-description">{data.description}</div> }                        
                        { !!data.error && <div className="fondsberegning-selected-fund-item-error">
                            <ErrorLabel icon={IconTypes.UTROPSTEGN_24} text={data.error} />
                        </div> }                        

                        { data.list.map( (fond: any, j: number) => (
                            <span key={`selected-${i}-${j}`} style={{borderColor: data.color}} className={classNames('selected-item', data.isin)}>
                                <a 
                                    href="#"
                                    role="button"
                                    className="closer"
                                    onClick={(e) => { click(props, state, setState, e, 'remove-selected', fond) }}
                                    aria-label={`Slett ${fond.navn}`}
                                />
                                <strong>{ fond.navn }</strong>
                            </span>
                        )) }
                    </li>
                }) }
            </ul>

            <div className="paragraph">
               <LinkButton 
                    data-tack-id={Constant.trackIdProduct} 
                    data-track-linkname="legg-til-fond" 
                    onClick={(e)=>{click(props, state, setState, e, Constant.actionVelgFond);}}
                >Legg til fond</LinkButton>
            </div> 
        </div>}
        result={<>
            <h2 id={`${state.id}-summary-title`} className="paragraph">Forventet utvikling</h2>

            { !!currentChart && <div className="panel-wrapper max-width-300  -center">
                <TabList small list={state.panelView.list} 
                    click={(e: any, k='', d: any)=>{ click(props, state, setState, e, k, d); }}
                    current={state.panelView.selected} 
                    action="change-panel-view"
                />
            </div> }

            { !!currentChart && <>
                <Chart {...currentChart} /> 
                <ul className="result-info-list flat">    
                    { currentChart.legend.map( (list: any, i: number) => {
                        return <li key={`result-info-${i}`}>
                            <div className="result-info-item">
                                <div>                               
                                    <span className="point" style={{backgroundColor: list[(list.length - 1)]?.color || '#333'}}/>                                    
                                    <span className="text">{list[(list.length - 1)]?.typeName}</span>
                                </div>
                                {list[(list.length - 1)]?.error ? <div className="error-holder">
                                    <ErrorLabel icon={IconTypes.UTROPSTEGN_24} text={list[(list.length - 1)]?.error} />
                                </div> : <div>
                                    <strong>{formatAmount(list[(list.length - 1)]?.value)}</strong>
                                    <span className="unit"> kr</span>
                                </div>}
                            </div>
                        </li>
                    })}
                </ul>
            </>}

            { !! currentChart && <div className="infomation-wrapper center">
                {  getIsinList( state ).length > 0 && <div className="paragraph-small">
                    <StandardButton 
                        data-tack-id={Constant.trackIdBuy} 
                        data-track-linkname="spar-i-fond"
                        onClick={(e)=>{click(props, state, setState, e, Constant.actionKjop);}}
                    >Spar i fond</StandardButton>
                </div>}
                <div className="paragraph-small">
                    <LinkButton 
                        data-tack-id={Constant.trackIdProduct} 
                        data-track-linkname="om-utregningen" 
                        onClick={(e: any)=>{click(props, state, setState, e, 'about-calculation');}}
                    >Om utregningen</LinkButton>
                </div>
                <div className="paragraph-small">
                    <LinkButton 
                        data-tack-id={Constant.trackIdProduct} 
                        data-track-linkname="se-tabell" 
                        onClick={(e: any)=>{click(props, state, setState, e, 'show-table');}}
                    >Se tabell</LinkButton>
                </div>
            </div> }
        </>}
    >
        <Modal
            modalSize={ModalSize.LARGE} 
            id="pensjonberegning-modal" 
            appNamespace="sparing-react-app" 
            onClose={() => { click(props, state, setState, null, Constant.actionCloseModal); }} 
            show={!!state.detail}
            headerElements={<>
                <div id="dialog-box-title">
                    { /* eslint-disable */ }
                    {state.detail === 'show-table' ? (currentChart?.table?.title || 'Utvikling') : (
                        state.detail === 'show-fund-option' || state.detail === 'show-fund-overview' ? 'Velg fond' : 'Om utregningen'
                    )}
                    { /* eslint-enable */ }
                </div>
                <span>
                    <IconButton type="button" onClick={() => { click(props, state, setState, null, Constant.actionCloseModal); }} icon={IconTypes.KRYSS_24} />
                </span>
            </>}
        >
            { state.detail === 'show-fund-option' && !!currentChart && <article aria-labelledby="dialog-box-title" className="fondsberegning-velg-fond-wrapper max-width-500 -center">
                <ul className="velg-fond-list flat">
                    { getIsinList( state ).map( (t: any, i: number) => {
                        return <li key={`velg-fond-${i}`} className="paragraph">
                            <Card data={{textList: [t.navn]}} type="spare-link-panel" action="buy-fund" click={(e: any) => {
                                e.preventDefault();
                                buyFund(props, t.isin);
                            }} />
                        </li>
                    })}
                </ul>
            </article> }

            { state.detail === 'about-calculation' && !!currentChart && <article aria-labelledby="dialog-box-title" className="fondsberegning-beregning-wrapper">
                <ul className="calculation-description">
                    { (currentChart?.description ?? []).map( (t: string, i: number) => {
                        return <li key={`calculation-description-${i}`}>{t}</li>
                    })}
                    <li>{props.appTexts.get('fundCalculationInflation')}</li>
                </ul>
                { ['fundCalculationText1', 'fundCalculationText2'].map( (key: string) => (
                    <p key={`fund-calculation-text-${key}`} className="calculation-text">
                        {props.appTexts.get(key)}
                    </p>
                ))}
            </article> }
            { state.detail === 'show-table' && !!currentChart && <article aria-labelledby="dialog-box-title" className="fund-table-wrapper">
                <table>
                    <caption className="aria-visible">{currentChart.table.title || 'Utvikling'}</caption>
                    <thead>
                        <tr>
                            {currentChart.table.head.map( (d: any, i: number) => {
                                return <th key={`th-${i}`} scope="col" className={`column-${i} ${d.style || ''}`}>
                                    { (d.text instanceof Array ? d.text : [d.text]).map( (t: string, j: number) => {
                                        return <span key={`th-${i}-${j}`}>{t}</span>
                                    }) }
                                </th>
                            })}
                        </tr>
                    </thead> 
                    <tbody>
                        { currentChart.table.body.map( (d: any, i: number) => {
                            return <tr key={`tr-${i}`}>
                                { d.map( (t: any, j: number) => {
                                    return j === 0 ?  
                                        <th key={`td-${i}-${j}`} className={t.style || ''} scope="row"><span>{t.text}</span></th>:
                                        <td key={`td-${i}-${j}`} className={t.style || ''}><span>{t.text}</span></td>
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
            </article> }

            { state.detail === 'show-fund-overview' &&             
                <FundOverview
                    {...props}
                    fundList={(reducer?.Fond?.morningstar ?? [])} 
                    useSelectedCallback={(selected)=>{ return useSelectedFund(props, state, setState, reducer, selected); }} 
                    fundDetailUrlPath={`${window.location.origin}/spare/fondssparing/kjop#/kjopFond`}
                />
            }
        </Modal>
    </KalkultorLayout>
};