import {Fund, FundUtvikling} from 'src/domain/Fund';
import { formatAmount, capitalize } from '../../util/Functions';
import { getFondUtviklingConfigList } from '../../util/FondFunctions';

export const getFilterConfig = (): any => {
    return {
        sort: {
            label: 'Sorter etter',
            current: '',
            options: [
                { value: 'alphabetical', label: 'Alfabetisk A-Å' },
                { value: 'minAmount', label: 'Lavest minstebeløp' },
                { value: 'esg', label: 'Best score på bærekraft og miljø' },
            ],
        },
        amount: {
            current: [0, 30000],
            interval: [0, 30000],
            unit: 'kr',
            label: 'Minstebeløp',
            step: 100,
            information: {
                title: 'Slik fungerer minstebeløp',
                text: [
                    'Minstebeløp er det laveste beløpet du kan sette inn i et fond. Dette varierer fra fond til fond.',
                ],
            },
            disabled: [true, false],
            field: ['Fra', 'Til'],
            showPlusWhenValueOnMax: true,
        },
        risk: {
            label: 'Risiko',
            current: 'all',
            options: [
                { value: 'all', label: 'Alle' },
                { value: 'low', label: 'Lav' },
                { value: 'middle', label: 'Middels' },
                { value: 'high', label: 'Høy' },
            ],
        },
        askAccount: {
            label: 'Aksjesparekonto',
            options: [
                { value: 'compatable', checked: false, label: 'Vis kun fond som kan plasseres i en aksjesparekonto' },
            ],
            information: {
                title: 'Aksjesparekonto',
                text: 'Denne er kun relevant dersom du skal velge et Aksjefond med høyere risiko.',
            },
        },
        searchText: '',
    };
};

export const getMinAmount = (data: any, monthly = false): string => {
    const amount = monthly
        ? data.minimumsBelopPeriodiskKjop || data.minimumsBelopEngangsKjop
        : data.minimumsBelopEngangsKjop || data.minimumsBelopPeriodiskKjop;
    return amount || 100;
};

export const getRiskKey = (risk: number, wantText = false): string => {
    let key = 'high';
    if (risk < 3) {
        key = 'low';
    } else if (risk < 5) {
        key = 'middle';
    }

    const text: any = { high: 'Høy', middle: 'Middels', low: 'Lav' };
    return wantText ? text[key] : key;
};

export const getFundTextList = (data: Fund, monthly = false): any => {
    const amount = getMinAmount(data, monthly);
    const fundRisk = `${data.aksjesparekontoKompatibel ? 'Aksjefond' : 'Fond'} med ${getRiskKey(
        data.risiko,
        true
    )} risiko`;
    const risk = capitalize(fundRisk.toLowerCase());
    const development = getFondUtviklingConfigList()
        .reverse()
        .map((config: { id: string; name: string }) => {
            const value = (data.utvikling || {})[config.id as keyof FundUtvikling];
            return typeof value === 'number'
                ? { text: config.name.toLowerCase(), value: `${formatAmount(value)} %` }
                : null;
        })
        .find((v: any) => !!v) || { text: '', value: '-' };

    return [
        [
            { type: 'name', text: data.navn, ariaLabel: `Fond ${data.navn}` },
            { type: 'risk', text: risk, ariaLabel: risk },
        ],
        [{ type: 'risk', text: risk, ariaLabel: `Risiko ${risk.toLowerCase()}` }],
        [{ type: 'sprouts', text: data.eikespirer || 0 }],
        [
            [
                {
                    text: 'Årlig kostnad',
                    type: 'unit-description -yearly-cost',
                    id: `${data.isin}-ongoingCharge-description`,
                },
                {
                    text: `${formatAmount(data.yearlyCost, 2)} %`,
                    ariaLabelledby: `${data.isin}-ongoingCharge-description`,
                },
            ],
            [
                {
                    text: 'Minstebeløp',
                    type: 'unit-description -minimum-amount',
                    id: `${data.isin}-amount-description`,
                },
                { text: `${formatAmount(amount)} kr`, ariaLabelledby: `${data.isin}-amount-description` },
                {
                    type: 'label',
                    text:
                        data.minimumOrderAmountFirstTimePurchase && data.minimumOrderAmountFirstTimePurchase > +amount
                            ? `Minimumsbeløp ved førstegangskjøp er ${formatAmount(
                                  data.minimumOrderAmountFirstTimePurchase
                              )} kr`
                            : '',
                },
            ],
            [
                {
                    text: 'Årlig avkastning',
                    type: 'unit-description -development',
                    id: `${data.isin}-development-description`,
                    'data-text': development.text ? capitalize(`${development.text} avkastning`) : '',
                },
                {
                    text: development.value,
                    ariaLabelledby: `${data.isin}-development-description`,
                    'data-text': development.text,
                },
            ],
        ],
    ];
};
