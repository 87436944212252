import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GrafFunksjoner from './graf-funksjoner';

class GrafPunkter extends Component {
    static propTypes = {
        values: PropTypes.array,
        bounds: PropTypes.object,
        height: PropTypes.number
    };

    createPointsForLineChart = array => {
        const adjustedValues = GrafFunksjoner.createArrayOfValuesAdjustedToViewBox(
            array,
            this.props.bounds,
            this.props.height
        );
        const coordinateObjects = GrafFunksjoner.flipValuesToAdjustToSvgOriginOfZero(adjustedValues, this.props.height);

        return this.createCircleDotsForSvg(coordinateObjects);
    };

    createCircleDotsForSvg = coordinateObjects => {
        return coordinateObjects.map(c => {
            return <circle key={`${c.x},${c.y}`} cx={c.x} cy={c.y} r="3" fill="#2588ab" />;
        });
    };

    render() {
        return <g>{this.createPointsForLineChart(this.props.values)}</g>;
    }
}

export default GrafPunkter;
