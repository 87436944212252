import {
    SIGNERINGS_ORDRE_OPPRETTET,
    SIGNERING_OPPRETTET,
    VIS_FEILMELDING,
    SERVER_POLL,
    RESET_ORDRE_PROSESS,
} from '../actions/signerings-ordre';

import { HENT_SISTE_ORDRE, HENT_VENTENDE_ORDRE, HENT_ALLE_VENTENDE_ORDRE } from '../actions/ordre';

const initialState = {
    pollingIds: {},
    ventendeTransaksjoner: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SIGNERING_OPPRETTET: {
            const { data } = action;
            return {
                ...state,
                signeringsUrl: data.signeringsUrl,
            };
        }
        case HENT_SISTE_ORDRE: {
            return {
                ...state,
                sisteTransaksjoner: action.data,
            };
        }
        case HENT_ALLE_VENTENDE_ORDRE: {
            return {
                ...state,
                alleVentendeTransaksjoner: action.data,
            };
        }
        case HENT_VENTENDE_ORDRE: {
            return {
                ...state,
                ventendeTransaksjoner: {
                    ...state.ventendeTransaksjoner,
                    [`${action.data.portfolioId}${action.data.isin}`]: action.data.data,
                },
            };
        }
        case SIGNERINGS_ORDRE_OPPRETTET: {
            const { data } = action;
            return {
                ...state,
                ordreId: data.ordreId,
                feilMelding: undefined,
            };
        }
        case VIS_FEILMELDING: {
            const { feilmelding } = action;
            return {
                ...state,
                feilmelding,
            };
        }
        case SERVER_POLL: {
            const pollingOrderId = action.data;
            return {
                ...state,
                pollingIds: {
                    ...state.pollingIds,
                    ...pollingOrderId,
                },
            };
        }
        case RESET_ORDRE_PROSESS: {
            return {
                pollingIds: {},
            };
        }
        default:
            return state;
    }
};
