export default class LocalStorage {
    static set(key='', value: any): void {
        try {
            window.localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value || {}));
        } catch (e) {
            // do nothing
        }
    }

    static get(key=''): any {
        try {
            const value = window.localStorage.getItem(key);
            try {
                // @ts-ignore
                return JSON.parse(value);
            } catch (e) {
                return value;
            }
        } catch (e) {
            return null;
        }
    }

    static remove(key=''): void {
        try {
            window.localStorage.removeItem(key);
        } catch (e) {
            // do nothing
        }
    }
}
