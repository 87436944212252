import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hentVisSmartSparPopup, skjulSmartSparPopup, registrerSmartSparPopupVisning } from '../actions/modals';
import SmartSparPopUp from '../components/smartspar/smartspar-popup';

class SmartSparPopUpIos extends Component {
    AppStore = {
        link: 'https://itunes.apple.com/no/app/smartspar/id1450266656',
        image: '/-/media/fellesbilder/04-Produkter-og-Tjenester/AppStore.jpg',
    };

    static propTypes = {
        texts: PropTypes.object,
        hentVisSmartSparPopup: PropTypes.func,
        skjulSmartSparPopup: PropTypes.func,
        registrerSmartSparPopupVisning: PropTypes.func,
        visSmartsparPopup: PropTypes.bool,
    };

    render = () => <SmartSparPopUp {...this.props} appStore={this.AppStore} />;
}

export default connect(
    (state) => {
        return {
            ...state.SmartsparPopup,
            ...state.Texts,
        };
    },
    (dispatch) => {
        return {
            skjulSmartSparPopup: () => {
                dispatch(skjulSmartSparPopup());
            },
            hentVisSmartSparPopup: () => {
                dispatch(hentVisSmartSparPopup());
            },
            registrerSmartSparPopupVisning: () => {
                dispatch(registrerSmartSparPopupVisning());
            },
        };
    }
)(SmartSparPopUpIos);
