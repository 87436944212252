import React, { useState, useEffect, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { IconTypes } from '@eika/icon';
import { LinkButton } from '@eika/button';
import Constant from '../../static/data/Constant';
import { usePrevious } from '../../common/util/Hook';
import Card from '../../common/Card/Card';
import Grid from '../../common/Grid/Grid';
import AppMessage from '../../common/AppMessage/AppMessage';
import { HeaderLink, Spinner } from '../../common/share/ShareComponents';
import { getPageAppending, formatAmount } from '../../common/util/Functions';
import { getNewFondOrder, getAgreementOrderSettting } from '../../common/util/FondFunctions';
import { getAvtaleCardList } from './util/Functions';
import { AppTexts, RouteLocation, RouteHistory, RouteMatch } from '../../domain/Types';
import './Spareavtale.scss';

interface Props {
    appTexts: AppTexts;
    action: any;
    history: RouteHistory;
    location: RouteLocation;
    match: RouteMatch;
};

interface State {
    reducer: any;
    appending: string;
    detail: any;
    content?: any;
};

const click = ( props: Props, state: State, setState: (s: State) => void, e: MouseEvent, key='', data?: any): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === 'close-modal' ) {
        setState({...state, detail: null});
    } else if ( key === 'open-detail' && data && data.detail ) {
        setState({...state, detail: data.detail});        
    } else if ( key === Constant.actionKjopSpareavtale ){
        const order = getNewFondOrder({
            type: Constant.typeManedligSparing,
            // finishBack: 1,
        });
        props.history.push(`${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionMonthly}`);
    } else if ( key === Constant.actionEndreSpareavtale && data ){
        const order = getNewFondOrder({ 
            ...getAgreementOrderSettting(data),
            action: Constant.actionEndreSpareavtale,
            type: Constant.typeManedligSparing,
            // finishBack: 1,
        });
        props.history.push(`${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionMonthly}`);
    }
};

const getContent = ( props: Props, reducer: any ): any => {
    const { portefoljeData, disponertePortefoljer, morningstarPin={} } = reducer.Fond || {};
    if ( !portefoljeData || !disponertePortefoljer ) { return; }

    const { match={} } = props;
    const config = match.params?.accountId || match.params?.isin ? {
        isin: match.params?.isin || '',
        portefoljeId: match.params?.accountId || '',
    } : undefined;

    const content: any = {
        description: 'Månedlig sparing totalt',
        fundName: config ? (morningstarPin[config.isin]?.navn || '') : '',
        ...getAvtaleCardList(portefoljeData, disponertePortefoljer, config)
    };

    content.amount = `${formatAmount( content.amount, true )} kr`;
    content.list.push({
        id: Constant.actionKjopSpareavtale,
        data: {
            textList: [
                {text: 'Legg til', type: 'font-bold'},
                {text: 'Ny månedlig spareavtale', type: 'font-normal'},                
            ] 
        }
    });

    return { content };
};

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Fond'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const [state, setState] = useState<State>({
        appending: getPageAppending( props ),
        reducer,
        detail: null,
    });

    const previousReducer = usePrevious(reducer);

    useEffect(() => {
        if ( JSON.stringify(reducer) === JSON.stringify(previousReducer) ) { return; }
        setState({...state, reducer, ...getContent( props, reducer )});
    }, [previousReducer, reducer, state, props]);

    return (
        <div className="spareavtale-wrapper">
            <Grid namespace="sparing-top" list={[{
                layout: 'twelve',
                element: <HeaderLink text="Forrige side"/>
            }]}/>

            <Grid namespace="sparing-header" list={[{
                layout: 'twelve',
                element: <h1>Alle spareavtaler</h1>
            }]}/>
            
            <div className={`spareavtale-content ${state.appending}`}>
                <Grid namespace="sparing-content" list={[{
                    layout: 'six',
                    element: state.content ? <>
                        <AppMessage appMessage={reducer?.App?.serviceMessage} context="SAVING_AGREEMENT" />

                        <div className="paragraph-large">
                            { state.content.amount && <div className="header-total-value">{state.content.amount}</div>}
                            { state.content.description && <div className="header-total-text">{state.content.description}</div>}
                        </div>
                        <div className="spareavtale-list">
                            { state.content.list.map( (data: any, i: number) => {
                                const {detail, ...rest} = data;
                                return detail ? <Card key={`spareavtale-card-${i}`} data={data.fullData} type="spareavtale-card">
                                    { !detail.blocked && <div className="spareavtale-card-link-wrapper center paragraph -only-top">
                                        <LinkButton
                                            icon={IconTypes.PENN_24} 
                                            onClick={(e: MouseEvent) => { click(props,state, setState, e, Constant.actionEndreSpareavtale, detail )}}
                                        >Se/endre</LinkButton>
                                    </div> }
                                </Card> : <Card key={`spareavtale-card-${i}`} type="new-spareavtale-card" {...rest} click={(e: MouseEvent) => { click(props, state, setState, e, Constant.actionKjopSpareavtale ); }} />

                            })}
                        </div>
                    </> : <Spinner type="linkPanel" />
                }]}/>
            </div>
        </div>
    );
};