import { getParam, getOrderStorage, setupBankAccountOptionList, pushBankAccount } from './FondHandelFunctions'; 
import Constant from '../../../static/data/Constant';
import { getFundTextList } from '../../../common/FundOverview/util/Functions';
import { isPortfolioOnlyView } from '../../../common/util/BusinessFunctions';
import { formatBankAccount, formatAmount, getDrawDayList,  sortList, getMonthText } from '../../../common/util/Functions';
import { getFondOrderStorage, setFondOrderStorage, getKontiTypeName, getKontiNoteList } from '../../../common/util/FondFunctions';
import { Props, State } from '../FondHandelDomain';

/** ************************************************************************
 * == Internal functions ===
 ************************************************************************* */
const getBankAccountIndex = ( orderStorage: any, reducer: any, bankAccount: any ): string => {
    const {initedPlacement, placement, id} = orderStorage || {};
    const portfolioId = initedPlacement || placement;
    if ( !portfolioId || !id || !(bankAccount?.list || []).length ) { return ''; }

    const portfolio = (reducer?.Fond?.portefoljeData?.portefoljer || []).find( (portfolio: any) => portfolioId === portfolio.id);
    if ( !portfolio ) { return ''; }

    let agreement: any = null;
    (portfolio.totalBeholdningFondList || []).forEach( (info: any) => {
        if ( agreement ) { return; }

        (info.spareavtaler || []).forEach( (data: any) => {
            if ( agreement ) { return; }
            if ( data.id === id ) { agreement = data; }
        });
    });

    if ( !agreement || !agreement.belastningskonto ) { return ''; }

    const index = bankAccount.list.findIndex( (bank: any) => bank.kontonummer === agreement.belastningskonto);
    return index === -1 ? '': `${index}`;
};

const initFund = ( props: Props, reducer: any, orderStorage: any, placement: any ): any => {
    const fund: any = {pin: {}, list: [], eika: [], other: [], monthly: {eika: [], other: [], list: []}};
    const eikaReg = /^eika/i;

    ((reducer.Fond || {}).morningstar || []).forEach( (d: any) => {
        if ( !d || !d.id || !d.navn ) { return; }
        fund.pin[d.id] = {
            ...d,
            textList: getFundTextList(d),
            type: d.type || (d.aksjesparekontoKompatibel ? 'Aksjefond' : 'Fond'),
        };
        
        if ( d.isin ) {
            fund.pin[d.isin] = fund.pin[d.id];
        }

        // Ved sett inn penger via klientkonto, er kun aksjesparekontoKompatibel som mulig å velge
        if ( orderStorage.clientAccount && !d.aksjesparekontoKompatibel ) {
            return;
        }

        if ( orderStorage.action === Constant.actionBytt ) {
            const allowToSwitch = (d.allowedTradeTypes ?? []).find((key: string) => key === 'SSW');
            if ( !allowToSwitch || d.isin === orderStorage.current ) { return; }

            const currentPlacement = placement?.pin[orderStorage.initedPlacement];
            const maxAmount = orderStorage.maxAmount || orderStorage.initedMaxAmount || 0;
            if ( !currentPlacement || !d.minimumsBelopEngangsKjop || !maxAmount || maxAmount < d.minimumsBelopEngangsKjop ) { 
                return; 
            }

            if ( currentPlacement.portefoljeType === Constant.typeVpk && d.aksjesparekontoKompatibel) {
                return;
            }

            if ( currentPlacement.portefoljeType === Constant.typeAsk && !d.aksjesparekontoKompatibel) {
                return;
            }
        }

        if ( eikaReg.test(d.navn) ) {
            fund.eika.push( fund.pin[d.id] );
            if ( d.minimumsBelopPeriodiskKjop ) {
                fund.monthly.eika.push(fund.pin[d.id]);
            }
        } else {
            fund.other.push( fund.pin[d.id] );
            if ( d.minimumsBelopPeriodiskKjop ) {
                fund.monthly.other.push(fund.pin[d.id]);
            }
        }
    });

    fund.list = fund.eika.concat(fund.other);
    fund.monthly.list = fund.monthly.eika.concat(fund.monthly.other);

    return fund;
};

const initBankAccount = ( props: Props, reducer: any, orderStorage: any  ): any => {
    const bankAccount: any = {pin: {}, list: orderStorage.action !== Constant.actionSelg || orderStorage.type === Constant.typeBankkonto ? [] : [{
        id: '0',
        bankkontoType: Constant.typeKlientkonto,
        category: Constant.typeKlientkonto, 
        tagList: [{text: 'Skattefri', type: 'tag -tax-free'}],
        textList: [
            {text: 'Behold i Aksjesparekontoen', type: 'name -font-semibold'},
            {text: 'Anbefales dersom du ikke skal bruke pengene nå.', type: 'description'},
            {text: orderStorage.placement, type: 'konto-id'},
        ],
        selection: 'Skattefri ved å behold i Aksjesparekontoen - Anbefales dersom du ikke skal bruke pengene nå.',
    }]};

    bankAccount.list.forEach( (account: any, i: number) => { bankAccount.pin[`${i}`] = account; });

    const debitAccountAndKlientAccountList = (reducer.Fond?.debitAccount || [])
        .concat((((reducer.Fond || {}).portefoljeData || {}).portefoljer || []).map( (d: any) => {
            if ( d.portefoljeType !== Constant.typeAsk ) { return; }
            return {
                kontonummer: 'Bankkonto tilknyttet ASK',
                verdi: d.kontantBeholdning || 0,
                navn: 'Eika Aksjesparekonto',
                bankkontoType: Constant.typeKlientkonto,
                portefoljeId: `${d.id}`,
            };
        }));

    debitAccountAndKlientAccountList.forEach( (d: any) => {
        const ignore =  !d || !d.kontonummer ||
            (d.bankkontoType === Constant.typeKlientkonto && orderStorage.action === Constant.actionEndreSpareavtale) ||
            (orderStorage.clientAccount && `${orderStorage.clientAccount}` !== `${d.portefoljeId}`) || 
            (d.bankkontoType === Constant.typeKlientkonto && !/(uttak|selg-fond)/i.test(orderStorage.action) && (d.verdi || 0) < 100) ||
            (d.bankkontoType === Constant.typeKlientkonto && /(uttak|selg-fond)/i.test(orderStorage.action));
        if ( ignore ) { return; }

        const id = `${bankAccount.list.length}`;
        bankAccount.pin[id] = {
            ...d, 
            id,
            category: Constant.typeBankkonto,
            textList: [d.verdi ? [d.navn, `${formatAmount(d.verdi, true)}`] : d.navn, formatBankAccount(d.kontonummer)],
            selection: [formatBankAccount(d.kontonummer), d.navn].filter((v: any) => !!v).join(' - '),
            disabled: d.sperre === Constant.fullSperret ? props.appTexts.get('fullSperretKonto')  : '',
        };

        if ( bankAccount.pin[id].disabled ) {
            bankAccount.pin[id].selection += ' (sperret)';
            if ( bankAccount.pin[id].textList[0][0] ) {
                bankAccount.pin[id].textList[0][0] += ' (sperret)';
            } else if ( bankAccount.pin[id].textList[0] ) {
                bankAccount.pin[id].textList[0] += ' (sperret)';                
            }
        }

        bankAccount.list.push(bankAccount.pin[id]);
    });

    if ( !orderStorage.clientAccount ){ 
        bankAccount.list.push({ id: Constant.typeNyBankkonto, textList: ['Legg til konto'], selection: 'Legg til konto', category: 'new-bank-account' });
    }

    const current = orderStorage.bankAccount;
    if ( orderStorage.bankAccount && current && !bankAccount.pin[current] ) {
        pushBankAccount(bankAccount, current);
    }

    bankAccount.original = JSON.parse(JSON.stringify(bankAccount.list));
    return bankAccount;
};

const initPlacement =( props: Props, reducer: any, orderStorage: any ): any => {
    const placement: any = {
        pin: { klientkonto: {} },
        list: [],
        askAccount: [],
        vpkAccount: [],
        ipsAccount: [],
        newAsk: {
            id: 'ASK',
            name: getKontiTypeName('ask'),
            note: getKontiNoteList('ask').map( (text) => {
                return text ? {text, type: 'checked'} : null;
            }).filter( (d) => !!d )
        },
        newVpk: {
            id: 'VPK',
            name: getKontiTypeName('vpk'),
            note: getKontiNoteList('vpk').map( (text) => {
                return text ? {text, type: 'checked'} : null;
            }).filter( (d) => !!d )
        },
    };

    (((reducer.Fond || {}).portefoljeData || {}).portefoljer || []).sort( (a: any, b: any)=>{
        const x = a.portefoljeType === Constant.typeIps ? 1 : 0;
        const y = b.portefoljeType === Constant.typeIps ? 1 : 0;
        // eslint-disable-next-line
        return x < y ? -1 : x > y ? 1 : 0;
    }).forEach( (d: any) => {
        if ( !d || !d.id || (!d.portfolioBaseCode && !/new-/i.test(d.category)) ) { return; }

        const portefoljeSparing = props.portefoljeSparing ?? {};
        /* eslint-disable */
        const description = d.portefoljeType === Constant.typeIps ? 
            'Denne kontoen kan kun brukes til pensjonssparing, siden pengene blir sperret til pensjonsalder. Du kan spare inntil 15 000 kr i året på denne.' : (
            d.portefoljeType === Constant.typeAsk ? 'Aksjefond og Indeksfond kan legges i denne kontoen.' : 'Rentefond og kombinasjonsfond kan legges i denne kontoen.'
        );
        /* eslint-enable */
        placement.pin[d.id] = {
            ...d,
            value: d.id,
            sparing: portefoljeSparing[d.portfolioBaseCode],
            textList: [d.navn],
            information: [
                d.portefoljeType === Constant.typeIps ? [{text: 'Pensjon', type: 'tag -house', ariaLabel: 'Sparemål pensjon'}] : null,
                [{text: description, type: 'font-normal'}],
            ].filter( (d) => !!d ),
            description: [
                [{text: 'Totalverdi', id: `${d.id}-total-value`}, {text: formatAmount(d.initialValue, true), ariaLabelledby: `${d.id}-total-value`}],
                [{text: 'Kontotype', id: `${d.id}-placement-type`}, {text: getKontiTypeName(d.portefoljeType), ariaLabelledby: `${d.id}-placement-type`}],
                [{text: 'Kontonummer', id: `${d.id}-placement-number`}, {text: d.portfolioBaseCode, ariaLabelledby: `${d.id}-placement-number`}],
            ].filter( (d) => !!d ),
            note: getKontiNoteList(d.type).map( (text) => {
                return text ? {text, type: 'checked'} : null;
            }).filter( (d) => !!d ),
        };

        if ( d.portefoljeType === Constant.typeAsk || d.id === 'ASK' ) {
            placement.askAccount.push(placement.pin[d.id]);
        } else if ( d.portefoljeType === Constant.typeVpk || d.id === 'VPK' ) {
            placement.vpkAccount.push(placement.pin[d.id]);
        } else if ( d.portefoljeType === Constant.typeIps ) {
            placement.vpkAccount.push(placement.pin[d.id]);
            placement.askAccount.push(placement.pin[d.id]);
            placement.ipsAccount.push(placement.pin[d.id]);
        }

        placement.list.push(placement.pin[d.id]);
    });

    (props.portefoljeData?.kontoer || []).forEach( (d: any) => {
        placement.pin.klientkonto[d.portefoljeId] = d; 
    });

    if ( orderStorage.action === Constant.actionSelg || orderStorage.action === Constant.actionUttak ) {
        placement.inited = true;
    } else {
        const ipsMissingSparing = placement.ipsAccount.filter( (d: any) => !d.sparing );
        placement.inited = !ipsMissingSparing.length;
    }
    
    return placement;
};

const initStepFieldKeyList = ( orderStorage: any, placement: any ): {[k: string]: string[]} => {
    if ( orderStorage.action === Constant.actionBytt ) {
        const list = ['amount', 'fund'];
        const currentPlacement = placement?.pin[orderStorage.initedPlacement];
        if ( currentPlacement?.portefoljeType === Constant.typeVpk ) {
            list.push('salgUnderstand');
        }

        return { '3': list };
    }

    if ( orderStorage.action === Constant.actionSelg || orderStorage.action === Constant.actionUttak ) {
        return { 
            '3': ['bankAccount', 'amount', 'fund', 'email', 'salgUnderstand'],
        };
    }
    
    return {
        '3': ['type', 'bankAccount', 'amount', 'fund', 'drawDay', 'aisValue'], // aisFrequency, aisValue 
        '3.5': ['aisValue'],      
        '4': ['placement'],
        '6': ['email', 'bankAccount', 'termPlacement'],
    }
};

const initTypeList = ( props: Props, reducer: any, orderStorage: any, state: State ): any => {
    if ( orderStorage.action === Constant.actionSelg || orderStorage.action === Constant.actionUttak ) {
        const skattefritt = parseInt(`${((state.placement.pin || {})[orderStorage.placement] || {}).skattefrittUttak || 0}`, 10);
        return [
            { 
                value: Constant.typeBankkonto, 
                label: 'Ja', 
                description: `Overfør til en brukskonto${skattefritt ? ` og du kan ta ut inntil ${formatAmount(skattefritt)} kr skattefritt.` : ''}`,
                textList: ['Ja, overfør til en brukskonto'],
                title: 'Overfør til en brukskonto',
                information: [
                    skattefritt ? { text: `Du kan ta ut ${formatAmount(skattefritt, true)} kr skattefritt.`, type: 'checked'} : null,
                    { text: 'Pengene blir overført til en brukskonto som du velger.', type: 'checked'}
                ].filter( (d) => !!d ),
                taxFree: skattefritt ? formatAmount(skattefritt) : '',
            }, { 
                value: Constant.typeKlientkonto,
                label: 'Nei',
                description: 'Oppbevar pengene i Aksjesparekontoen. Da utløses ikke skatt uansett hvor mye du tar ut.',
                textList: ['Nei, oppbevar pengene i Aksjesparekontoen'],
                title: 'Oppbevar pengene i Aksjesparekontoen',
                information: [
                    { text: 'Alle pengene i fondet kan overføres skattefritt, også verdiøkningen.', type: 'checked'},
                    { text: 'Pengene overføres til en konto som er tilknyttet aksjesparekontoen.', type: 'checked'},
                    { text: 'Fra den tilknyttede kontoen kan du kjøpe fond eller ta ut penger senere.', type: 'checked'},
                ]
            },
        ];
    }

    return [
        {id: Constant.typeManedligSparing, text: 'Spar et fast månedlig beløp'},
        {id: Constant.typeEngangsinnkudd, text: 'Sett inn engangsbeløp'},
    ];
};

const initBankRelation = ( props: Props, reducer: any ): any => {
    const relation: any = { pin: {}, list: [] };
    (reducer.Bank?.kundeforhold ?? []).forEach( (bank: any) => {
        if ( !bank.bankregnr ) { return; }
        relation.pin[bank.bankregnr] = {
            ...bank,
            value: bank.bankregnr,
            label: bank.banknavn || bank.bankregnr,
        };
        relation.list.push(relation.pin[bank.bankregnr]);
    });
    return relation;
};

const initFundPanelView = ( props: Props, reducer: any, orderStorage: any, state: State ): any => {
    const { portefoljeData } = reducer.Fond ?? {};
    const out: any = { 
        selected: 'my-fund',
        myFundList: [],
        list: [
            {'id': 'my-fund', name: 'Dine fond' },
            {'id': 'other-fund', name: 'Alle fond' },
        ],
    };

    [
        {id: 'account', content: portefoljeData?.portefoljer ?? []},
        // {id: 'dispose', content: disponertePortefoljer ?? []},
    ].forEach( (src: any) => {
        src.content.forEach( (konti: any) => {
            const ignore = !konti || isPortfolioOnlyView(konti.portefoljeType) ||
                (orderStorage.initedPlacement && `${orderStorage.initedPlacement}` !== `${konti.id}`);
            if ( ignore ) { return; }

            const isIPS = konti.portefoljeType === Constant.typeIps;
            const hasSpareavtale = isIPS ? !!(konti?.fond ?? []).find( (myFund: any) => !!(myFund.spareavtaler || []).length) : false;

            (konti?.fond ?? []).forEach( (myFund: any) => {
                const fund = myFund ? state.fund.pin[myFund.isin] : null;
                if ( !fund ) { return; }

                const sperre = fund.sperre || konti.sperre;
                out.myFundList.push({
                    ...fund,
                    ...myFund,
                    hasSpareavtale,
                    isIPS,
                    value: `${konti.id}-${fund.id}`,
                    fundId: fund.id,
                    placement: konti.id,                    
                    textList: [
                        [   
                            {text: myFund.navn, ariaLabel: `Fond ${myFund.navn}`},
                            {text: formatAmount(myFund.totalVerdi, true), ariaLabel: `Totalt verdi ${formatAmount(myFund.totalVerdi, true)}`}
                        ], [
                            {text: getKontiTypeName(konti.portefoljeType), type: 'placement-type', ariaLabel: `Plasseringskonto type ${getKontiTypeName(konti.portefoljeType)}`},
                        ],
                        (isIPS ? [{text: 'Pensjon', type: 'tag -pension', ariaLabel: 'Sparemål pensjon'}] : null),
                        [
                            {text: formatAmount(myFund.totalVerdi, true), ariaLabel: `Totalt verdi ${formatAmount(myFund.totalVerdi, true)}`, type: 'max-width'},
                        ]
                    ].filter( (d: any) => !!d ),
                    disabled: sperre === Constant.fullSperret,
                    attentionMessage: sperre ? props.appTexts.get(`${sperre.toLowerCase()}SperretKonto`) : undefined,
                });
            });
        });
    });

    out.myFundList = sortList(out.myFundList, 'totalVerdi',  true, true);
    return out.myFundList.length ? out : null;
};

const initAutomaticIncreaseSavingFrequency = (): any => {
    const monthList = getMonthText();
    const list = [
        // {id: Constant.typeKvatalSparing, value: Constant.typeKvatalSparing, name: 'Kvartalsvis', text: 'Kvartalsvis - Fire ganger i året', textList: ['Kvartalsvis', 'Fire ganger i året'], amount: true, category: 'header'},
        {id: Constant.typeKvatalSparing, value: Constant.typeKvatalSparing, name: 'Kvartalsvis', textList: ['Kvartalsvis', 'Fire ganger i året'], amount: true, category: 'header'},
        {id: Constant.typeManedligSparing, value: Constant.typeManedligSparing, text: 'Månedlig', amount: true, category: 'header'},
        {id: Constant.typeArligSparing, value: Constant.typeArligSparing, text: 'Årlig', category: 'header'},
    ].concat( monthList.map( (v: string, i: number) => {
        return {id: `${i}`, value: `${i}`, text: `Hver ${v.toLowerCase()}`, /*name: v.toLowerCase(),*/ category: '' };
    }) );

    return {  list, pin: list.reduce( (pin: { [k: string]: any }, data: any) => {
        pin[data.id] = data;
        return pin;
    }, {})}
};

/** ************************************************************************
 *  === External functions ===
 ************************************************************************* */

export const initState = ( props: Props, ownState: State, reducer: any ): State => {
    const param = getParam( props );
    let orderStorage = getFondOrderStorage( param.order );
    const preSavedData: any = {
        aisValue: orderStorage.aisValue || '',
        aisFrequency: orderStorage.aisFrequency || '0',
        bankAccount: orderStorage.bankAccount || '',
    };

    if ( orderStorage.foreignCurrencyForceToSellAll && orderStorage.maxAmount ) {
        preSavedData.amount = orderStorage.maxAmount;
    }

    const init = (orderStorage.history || {}).init || {};
    const { kundeInfo={} } = reducer.SpareProfil || {};
    const { kundeforhold=[] } = reducer.Bank || {};

    if ( !orderStorage.mobil && kundeInfo.mobil ) {
        preSavedData.mobil = kundeInfo.mobil;
        preSavedData.initedMobil = kundeInfo.mobil;
    }

    if ( !orderStorage.email && kundeInfo.epost ) {
        preSavedData.email = kundeInfo.epost;
        preSavedData.initedEmail = kundeInfo.epost;
    }

    if ( orderStorage.placement !== undefined && !orderStorage.initedPlacement ) {
        preSavedData.initedPlacement = orderStorage.placement || '';
    }

    // For håndtering av bytt-fond
    if ( orderStorage.maxAmount && !preSavedData.initedMaxAmount ) {
        preSavedData.initedMaxAmount = orderStorage.maxAmount;
    }

    if ( orderStorage.type && !preSavedData.initedType ) {
        preSavedData.initedType = orderStorage.type;
    }

    const bank = kundeforhold[0] || {};
    if ( bank.bankregnr && !orderStorage.bankregnr ) {
        preSavedData.bankregnr = bank.bankregnr || '0501';   
    }

    if ( orderStorage.history && !orderStorage.history.length ) {
        preSavedData.history = orderStorage.history;
        preSavedData.history.length = window.history.length;
    }

    const bankAccount = initBankAccount( props, reducer, orderStorage );
    if ( orderStorage.action === Constant.actionEndreSpareavtale && !preSavedData.bankAccount ){
        preSavedData.bankAccount = getBankAccountIndex(orderStorage, reducer, bankAccount );
    }

    if ( JSON.stringify(preSavedData) !== '{}' ) {
        orderStorage = { ...getOrderStorage(props, param), ...preSavedData };
        setFondOrderStorage( param.order, orderStorage );
    }

    const placement = initPlacement( props, reducer, orderStorage );
    const state: State = {
        ...ownState,
        fund: initFund( props, reducer, orderStorage, placement ),
        bankAccount,
        placement,
        bankRelation: initBankRelation(props, reducer), 
        drawDayList: getDrawDayList(),
        aisFrequency: initAutomaticIncreaseSavingFrequency(),
        stepFieldKeyList: initStepFieldKeyList( orderStorage, placement ),
        fundSuggestionUrl: `${window.location.href.replace(/\d+(\?.*)?$/, '110')}?view=fundsuggestion`,
        messageText: {
            type: { required: orderStorage.action === Constant.actionSelg ? 'Vennligst velg salgstype' : 'Vennligst velg innskuddstype'},
            bankAccount: { required: orderStorage.action === Constant.actionSelg || orderStorage.action === Constant.actionUttak ? 'Vennligst velg bankkonto' : 'Vennligst velg belastningskonto' },
            amount: { required: 'Vennligst legg inn beløp', invalid: 'Ugyldig beløp' },
            fund: { required: 'Vennligst velg fond' },
            placement: { required: 'Vennligst velg plassering' },
            email: { required: 'Vennligst skriv inn e-post adresse', invalid: 'Ugyldig e-post adresse' },
            drawDay: { required: 'Vennligst velg trekk dag' },
            newBankAccount: { required: 'Vennligst legg inn kontonummer', invalid: 'Ugyldig kontonummer' },
            termPlacement: { required: 'Vennligst aksepter samtlige dokumenter', invalid: 'Ugyldig plassering' },
            salgUnderstand: { required: 'Vennligst bekreft at du har forstått', },            
        },
        disabledFundAndPlacementSelection: !!init.fund && !!init.placement,
    };

    state.fundPanelView = orderStorage.action === Constant.actionKjop && !orderStorage.clientAccount ?
        initFundPanelView( props, reducer, orderStorage, state ) : null;

    state.typeList = initTypeList( props, reducer, orderStorage, state );
    if ( orderStorage.action === Constant.actionKjop ){
        setupBankAccountOptionList(props, state, orderStorage );
    }
    return state;
};

/** ************************************************************************
 ************************************************************************* */
export const useEffectPlacementCallback = ( props: Props, initedPlacement: any ) => {    
    if ( !initedPlacement ) { return; }
    const orderStorage = getOrderStorage( props );
    if ( !orderStorage || !orderStorage.initedPlacement ) { return; }

    const placement = (initedPlacement.pin || {})[orderStorage.initedPlacement] || {};
    if (!placement.sparing?.maksbelop || orderStorage.action === Constant.actionBytt) { return; }

    const param = getParam( props );
    const config: any = {maxAmount: placement.sparing.maksbelop};
    const left = config.maxAmount - (placement.sparing.aarligSparebelop || 0);
    if ( left === config.maxAmount ) {
        config.amountAttention = `Du kan spare inntil ${formatAmount(config.maxAmount)} kr i året på IPS-kontoen din.`;
    } else if (left < 100 ) {
        config.amountAttention = `Du kan spare inntil ${formatAmount(config.maxAmount)} kr i året på IPS-kontoen din. Du kan spare inntil ${formatAmount(left)} kr mer i år før kontoen blir full.`;
        config.maxAmount = left;                 
    }

    for ( const key in config ) {
        orderStorage[key] = config[key];   
    }
    setFondOrderStorage( param.order, orderStorage );
};
