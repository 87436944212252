import { post } from '@eika-infrastruktur/request';
import { KUNNE_IKKE_OPPRETTE_ORDRE, VIS_FEILMELDING } from './signerings-ordre';
import { VIS_KVITTERING } from './modals';

const baseUrlFond = '/sparing-min-side-web/rest/resource/fond';
const ordreUrl = `${baseUrlFond}/ordre`;

const OpprettUttaksOrdre = (data) => {
    return (dispatch) => {
        const url = `${ordreUrl}/klientkontouttak`;
        post(url, data)
            .then((data) => {
                dispatch({
                    type: VIS_KVITTERING,
                    ordreResultater: data.ordreResultater,
                });
            })
            .catch(() => {
                dispatch({
                    type: VIS_FEILMELDING,
                    feilmelding: KUNNE_IKKE_OPPRETTE_ORDRE,
                });
            });
    };
};

export default OpprettUttaksOrdre;
