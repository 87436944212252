import React from 'react';
import Grid from '../../../common/Grid/Grid';
import Card from '../../../common/Card/Card';

interface FondHandelKjopSelectTypeProps {
    click: (e: any, k: any, d?: any, f?: any) => void;
    typeList: any;
    disableMonthly?: boolean;
}

const FondHandelKjopSelectType = ({ click, typeList, disableMonthly }: FondHandelKjopSelectTypeProps): JSX.Element => {
    return (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>Hva vil du spare?</h1>,
                    },
                ]}
            />

            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'eight',
                        element: (
                            <>
                                <ul className="trade-type-list">
                                    {typeList.map((data: any) => {
                                        if (disableMonthly) {
                                            if (data.id === 'MONTHLY') return;
                                        }
                                        return (
                                            <li key={data.id}>
                                                <Card
                                                    data={data}
                                                    click={click}
                                                    action="type-selected"
                                                    type={`trade -${data.id}`}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        ),
                    },
                ]}
            />
        </>
    );
};

export default FondHandelKjopSelectType;
