import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { formatTrackLinkName } from '../util/Functions';
import Constant from '../../static/data/Constant';
import './TabList.scss';

export interface TabItem {
    id: string;
    name: string;
    control?: string;
};

interface Props {
    list: TabItem[];
    current: string;
    click: (e: MouseEvent, k: string, d: TabItem) => void;
    action: string;
    small?: boolean;
};

export default ({ list = [], click = () => {}, current = '', action = 'change-tab', small=false }: Props): JSX.Element => {
    return (
        <ul className={classNames('tab-list-wrapper', `-count-${list.length}`, { '-small': !!small})} role="tablist">
            {list.map((data: TabItem) => {
                const attr = {
                    id: `tab-${data.id}`,
                    'aria-selected': data.id === current,
                    'aria-controls': data.control || `panel-${data.id}`,
                    'data-tack-id': Constant.trackIdProduct, 
                    'data-track-linkname': formatTrackLinkName(data.name), 
                };

                return <li key={`tab-item-${data.id}`} role="presentation">
                    <a href="#" role="tab" {...attr}
                        className={classNames('tab-btn', {'-active': data.id === current})}
                        onClick={e => {click(e, action, data);}}

                    >{data.name}</a>
                </li>
            })}
        </ul>
    );
};

