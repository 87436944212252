import { useEffect, useRef, useCallback } from 'react';
import {Action} from '../../domain/Types';
import {ReducerKey} from '../../components/Arbeidsgiver/Storebrand/types';

/** ************************************************************************
 ************************************************************************* */
export const usePrevious = (value: any): any => {
 	const reference = useRef<any>(null);
 	useEffect(() => {
   		reference.current = value;
  	}, [value]);
  	return reference.current;
};

/** ************************************************************************
 ************************************************************************* */
export const useEventListener = (callback: (event: MessageEvent) => void) => {
    useEffect(() => {
        window.addEventListener('message', callback); // ios
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.addEventListener('message', callback); // android
        return () => {
            window.removeEventListener('message', callback); // ios
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            document.removeEventListener('message', callback); // android
        };
    }, [callback]);
};

/** ************************************************************************
 ************************************************************************* */
export const useStorebandInfo = (reducer: Partial<Record<ReducerKey, any>>, action: {[k:string]: (...args: any[]) => any}): void => {
    const render = useCallback((): void => {
        if ( reducer.Storebrand.info !== undefined ) { return; }
        action.getStorebrandInfo();
    }, [reducer.Storebrand, action]);

    useEffect(() => { render(); }, [render]);
};

/** ************************************************************************
 ************************************************************************* */
export const useStorebandPensionPayout = (reducer: {Storebrand: any}, action: Action, id=''): void => {
    const render = useCallback((): void => {
        const ignore = !reducer.Storebrand.info ||
            reducer.Storebrand.payout !== undefined;
        if ( ignore || !id ) { return; }

        const age = (reducer.Storebrand.info ?? {})[id]?.retirementAge;
        action.getStorebrandPensionPayout({id, age});
    }, [reducer.Storebrand, action, id]);

    useEffect(() => { render(); }, [render]);
};