const EPKriskProfileList = [{
    riskProfileId: 9,
    key: 'EIKA_FORSIKTIG',
    reg: /(lav|forsiktig)/i,
    suggested: '',
    title: 'Lav risiko',
    hint: '100% rentefond.',
    description: 'Lav risiko er en spareprofil hvor sparemidlene plasseres i rentefond forvaltet av Eika Kapitalforvaltning AS. Gjennom profilen vil din pensjonskapital bli investert i det norske og nordiske rentemarkedet som generelt kjennetegnes av lav til moderat risiko. Avkastning og risiko i profilen avhenger av det generelle rentenivået og hvordan rentemarkedet vurderer risikobildet til det enkelte selskap og markedet generelt.',
    price: {
        title: 'Priser',
        data: [
            [{text: 'Kjøp'}, {text: '0%'}],
            [{text: 'Salg'}, {text: '0%'}],
            [{text: 'Forvaltning'}, {text: '0,35%'}],
            [{text: 'Binding'}, {text: '62 år'}],
        ],
    },
    pdf: 'https://eika.no/-/media/fellesfiler/fond/EPK/Eika-Selvvalgt-Forsiktig-100--renter.pdf',
}, {
    riskProfileId: 8,
    key: 'EIKA_MODERAT',
    reg: /moderat/i,
    suggested: '',
    title: 'Moderat risiko',
    hint: '65% aksjefond, 35% rentefond.',
    description: 'Moderat risiko er en spareprofil hvor sparemidlene plasseres i aksje- og rentefond forvaltet av Eika Kapitalforvaltning AS. Gjennom profilen vil man normalt være investert i 65 % aksjer og 35 % renter. Plasseringene vil rebalanseres til normalvekt minst én gang i året, og uansett ved avvik fra normalvekten med mer enn 5 %. Profilen passer for en investor som ønsker en balansert risiko mellom aksjer og renter.',
    price: {
        title: 'Priser',
        data: [
            [{text: 'Kjøp'}, {text: '0%'}],
            [{text: 'Salg'}, {text: '0%'}],
            [{text: 'Forvaltning'}, {text: '0,58%'}],
            [{text: 'Binding'}, {text: '62 år'}],
        ],
    },
    pdf: 'https://eika.no/-/media/fellesfiler/fond/EPK/Eika-Selvvalgt-Moderat.pdf',
},{
    riskProfileId: 11,
    key: 'EIKA_LIVSSYKLUS',
    reg: /livssyklus/i,
    suggested: 'Din anbefalte risikoprofil',
    title: 'Livssyklus',
    hint: '100% Aksjefond med nedtrapping.',
    description: 'Livssyklus er en spareprofil hvor sparemidlene plasserer i aksje- og rentefond forvaltet av Eika Kapitalforvaltning AS. Gjennom profilen vil man være investert i 100 % aksjer frem til man fyller 60 år. I perioden fra man er 60 år til man fyller 67 år vil risikoen i fondet gradvis reduseres ved at aksjeandelen reduseres til 40 % og renteandelen økes til 60 %.',
    price: {
        title: 'Priser',
        data: [
            [{text: 'Kjøp'}, {text: '0%'}],
            [{text: 'Salg'}, {text: '0%'}],
            [{text: 'Forvaltning'}, {text: '0,7%'}],
            [{text: 'Binding'}, {text: '62 år'}],
        ],
    },
    pdf: 'https://eika.no/-/media/fellesfiler/fond/EPK/Eika-Selvvalgt-Livssyklus.pdf',
},{
    riskProfileId: 10,
    key: 'EIKA_AKSJER100',
    reg: /aksjer100/i,
    suggested: '',
    title: '100% Aksjefond',
    hint: 'Uten nedtrapping.',
    description: '100% Aksjer er en spareprofil hvor 100% av sparemidlene er plassert i aksjer forvaltet av Eika Kapitalforvaltning AS. Profilen har ingen nedtrapping mot pensjonsalder.',
    price: {
        title: 'Priser',
        data: [
            [{text: 'Kjøp'}, {text: '0%'}],
            [{text: 'Salg'}, {text: '0%'}],
            [{text: 'Forvaltning'}, {text: '0,7%'}],
            [{text: 'Binding'}, {text: '62 år'}],
        ],
    },
    pdf: 'https://eika.no/-/media/fellesfiler/fond/EPK/Eika-Selvvalgt-100--aksjer.pdf',
}];

export default EPKriskProfileList;
