/**
 * Created by vagrant on 22.02.17.
 */
import React from 'react';
import PropTypes from 'prop-types';

const SliderInput = props => (
    <div className="slider-input">
        <input
            id={props.id}
            type="range"
            min={props.min}
            max={props.max}
            step={props.step}
            value={props.value}
            onChange={props.onChange}
        />
        {props.children}
    </div>
);

SliderInput.propTypes = {
    id: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.object
};

export default SliderInput;
