import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import ActionButton from '../action-button';
import Card from '../card';
import Checkbox from '../checkbox';
import FinanceLaw from '../../static/data/FinanceLaw';
import Constant from '../../static/data/Constant';

class Terms extends Component {
    static propTypes = {
        texts: PropTypes.object,
        term: PropTypes.object,
        submit: PropTypes.func,
        headline: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            config: this.getConfig(props.term),
        };
    }

    render() {
        const { texts, submit, headline } = this.props; 
        const { config } = this.state;

        return (
            <Card>
                <div className="terms-wrapper">
                    {!!headline && <div className="align-center paragraph -include-top font-size-regular">{headline}</div>}

                    <h1 className="h2 paragraph -large">{config.title}</h1>

                    { !!config.summary && <div className="ingress paragraph">{config.summary}</div> }

                    { (config.poinList || []).length > 0 && <ol className={`point-list -${config.config?.pointList || 'normal'}`}>
                        { config.poinList.map( (cnt, i) => {
                            return <li key={`point-${i}`}>
                                { (cnt instanceof Array ? cnt : [cnt]).map( (text, j) => {                                    
                                    const data = text && text.text ? text : {text};
                                    return <div key={`point-${i}-${j}`} style={data.style} className="paragraph">
                                        {data.text}
                                    </div>;
                                })}
                            </li>
                        })}
                    </ol>}

                    { (config.linkList || []).length > 0 && <div className="paragraph">
                        {config.linkList.map( (link, i) => {
                            return <div key={`link-${i}`} className="paragraph -small">
                                 <a href={link.href} target={link.target || 'blank'} className="link font-size-regular">{link.text}</a>
                            </div>
                        })}
                    </div>}

                    { (config.confirmationList || []).length > 0 && <div className="paragraph">
                        {config.confirmationList.map( (confirmation, i) => {
                            return <div key={`confirmation-${i}`} className="paragraph -small">
                                <Checkbox id={confirmation.id} name={confirmation.id} onChange={()=>{this.changeConfirmation(confirmation);}}>
                                    <span>{confirmation.text}</span>
                                </Checkbox>  
                            </div>
                        })}
                    </div>}

                    <div className="paragraph -only-top">
                        { config.done === null && <Loading/> }
                        { config.done === false && <div className="message-wrapper -error">Ops, noe gikk galt.</div> }                    
                        { config.done !== null && <ActionButton 
                                disabled={(config.confirmationList || []).length > 0 && !!config.confirmationList.find( (d) => !d.checked) } 
                                text={texts.get('jegGodtarVilkaarene')}
                                action={()=>{ submit();}}
                            /> 
                        }
                    </div>
                </div>
            </Card>
        );
    }

    changeConfirmation = (confirmation) => {
        const { config } = this.state;

        if ( !config?.confirmationList || !confirmation?.id ) { return; }
        const list = JSON.parse(JSON.stringify(config.confirmationList));
        const found = list.find((d) => d.id === confirmation.id );
        if ( !found ) { return; }
        found.checked = !found.checked;
        this.setState({config: {...config, confirmationList: list}});        
    }

    getConfig = ( term={} ) => {
        if ( term.consentCode === Constant.termFinanceLaw ) {
            return JSON.parse(JSON.stringify(FinanceLaw));
        }
        return {};        
    }
}

export default Terms;