import React, { Component } from 'react';
import PropTypes from 'prop-types';

class KundevurderingStopp extends Component {
    static propTypes = {
        texts: PropTypes.object,
        type: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        polite: PropTypes.bool
    };

    render() {
        const { type, texts, text, title, polite } = this.props;
        const articleTitle = title === null ? '' : (title || texts.get('kundevurderingMaBehandlesManueltTitle'));
        const articleText = text === null ? '' : (text || texts.get('kundevurderingMaBehandlesManueltText'));
        const attr = polite === false ? {} : {'aria-live': 'polite'};

        return (
            <article className={`kundevurdering-stopp -${type || 'basic'}`} {...attr}>
                { !!articleTitle && <h1>{articleTitle}</h1> }
                { !!articleText && <p>{articleText}</p> }
            </article>
        );
    }
}

export default KundevurderingStopp;
