/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import { createSparemaal, getSparemaal } from '../../actions/sparemaal';
import { hentEstimat } from '../../actions/fond';

import { settSideTittel } from '../../actions/header';
import Feilmelding from '../../components/feilmelding';
import Sparemaalskjema from '../../components/sparemaal/sparemaal-skjema';
import SparemaalKalkulator from '../../components/sparemaal/sparemaal-kalkulator';
import Card from '../../components/card';

class OpprettSparemaal extends Component {
    static propTypes = {
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        createSparemaal: PropTypes.func,
        getSparemaal: PropTypes.func,
        errors: PropTypes.object,
        sparemaal: PropTypes.array,
        morningstar: PropTypes.array,
        hentEstimat: PropTypes.func,
        fondEstimat: PropTypes.array,
    };

    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            sparemaal: {
                name: '',
                goal: undefined,
            },
        };
    }

    UNSAFE_componentWillMount = () => {
        if (!this.props.sparemaal) {
            this.props.getSparemaal();
        }

        this.props.settSideTittel(this.props.texts.get('nyttSparemaal'));
    };

    createSparemaal = (sparemaal) => {
        this.setState({
            sparemaal,
            step: 1,
        });

        this.props.createSparemaal(sparemaal);
    };

    sjekkForServerFeil = (errors) => errors.HENT_SPAREMAAL;

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.step === 0) {
            return (
                <Card transparent>
                    <Sparemaalskjema
                        sparemaal={this.state.sparemaal}
                        onSubmit={this.createSparemaal}
                        texts={this.props.texts}
                        submitButtonText={this.props.texts.get('sparemaalOpprettSparemaal')}
                    />
                </Card>
            );
        }

        if (this.state.step === 1 && this.state.sparemaal) {
            return (
                <Card transparent>
                    <SparemaalKalkulator
                        sparemaal={this.state.sparemaal}
                        texts={this.props.texts}
                        morningstar={this.props.morningstar}
                        hentEstimat={this.props.hentEstimat}
                        fondEstimat={this.props.fondEstimat}
                    />
                </Card>
            );
        }

        return <Loading title={this.props.texts.get('lasterPensjonsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Feilmeldinger,
            ...state.Texts,
            ...state.Sparemaal,
            ...state.Fond,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            createSparemaal: (data) => {
                dispatch(createSparemaal(data));
            },
            getSparemaal: () => {
                dispatch(getSparemaal());
            },
            hentEstimat: (isin, monthlyDeposit, savingPeriod) => {
                dispatch(hentEstimat(isin, monthlyDeposit, savingPeriod));
            },
        };
    }
)(OpprettSparemaal);
