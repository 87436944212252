import React from 'react';
import PropTypes from 'prop-types';
import Format from '../format-functions';
import Checkbox from '../checkbox';
import Card from '../card';

const SpareAvtaleKortCheckbox = props => {
    if (props.andreSparemaal.length > 0) {
        return props.andreSparemaal[0];
    }

    return (
        <Checkbox
            id={`spareavtale-${props.uniqueId}`}
            name="spareavtale"
            onChange={e => props.onChange(e)}
            checked={props.checked}
        >
            <span>{props.texts.get('sparemaalSparemiksInkluderSpareavtale')}</span>
        </Checkbox>
    );
};

SpareAvtaleKortCheckbox.propTypes = {
    texts: PropTypes.object.isRequired,
    uniqueId: PropTypes.number.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    andreSparemaal: PropTypes.array.isRequired
};

const SparemiksSpareAvtaleKort = props => (
    <Card>
        <div className="sparemaal-spareavtale-kort">
            <div className="flex-row">
                <div className="col">
                    <div className="fonds-navn">{props.fondsnavn}</div>
                    <div className="konto-nummer">{props.portefoljenavn}</div>
                </div>
                <div className="col">
                    <div className="per-maaned">{Format.currency(props.mndBelop)}</div>
                    <div className="per-maaned-label">{props.texts.get('sparemaalSparemiksPerMnd')}</div>
                </div>
            </div>
            <div className="flex-row">
                <SpareAvtaleKortCheckbox
                    texts={props.texts}
                    onChange={props.onChange}
                    andreSparemaal={props.andreSparemaal}
                    checked={props.checked}
                    uniqueId={props.uniqueId}
                />
            </div>
        </div>
    </Card>
);

SparemiksSpareAvtaleKort.propTypes = {
    texts: PropTypes.object.isRequired,
    fondsnavn: PropTypes.string.isRequired,
    uniqueId: PropTypes.number.isRequired,
    portefoljenavn: PropTypes.string.isRequired,
    mndBelop: PropTypes.number.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    andreSparemaal: PropTypes.array.isRequired
};

export default SparemiksSpareAvtaleKort;
