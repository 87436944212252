import { get, post } from "@eika-infrastruktur/request";
import {processError, processResponse} from './common';
import {
    SIGNERINGS_ORDRE_OPPRETTET,
    SIGNERING_OPPRETTET,
    VIS_FEILMELDING,
    SERVER_POLL,
    RESET_ORDRE_PROSESS
} from './signerings-ordre';

const baseUrlOrdre = '/sparing-min-side-web/rest/resource/fond/ordre';

export const HENT_SISTE_ORDRE = 'HENT_SISTE_ORDRE';
export const HENT_SISTE_ORDRE_PENDING = 'HENT_SISTE_ORDRE_PENDING';
export const SET_VENTENDE_ORDRE = 'SET_VENTENDE_ORDRE';
export const HENT_VENTENDE_ORDRE = 'HENT_VENTENDE_ORDRE';
export const HENT_VENTENDE_ORDRE_PENDING = 'HENT_VENTENDE_ORDRE_PENDING';
export const HENT_ALLE_VENTENDE_ORDRE = 'HENT_ALLE_VENTENDE_ORDRE';
export const HENT_ALLE_VENTENDE_ORDRE_PENDING = 'HENT_ALLE_VENTENDE_ORDRE_PENDING';
export const SET_SIGNERING_URL = 'SET_SIGNERING_URL';

export const hentVentendeOrdre = (portfolioId, isin) => {
    const url = `${baseUrlOrdre}/ventende/${portfolioId}/${isin}`;
    return dispatch => {
        dispatch({ type: HENT_VENTENDE_ORDRE_PENDING });
        get(url).then(data => {
            processResponse(HENT_VENTENDE_ORDRE, data, dispatch, { portfolioId, isin }, url);
        }).catch((error) => processError(HENT_VENTENDE_ORDRE, error, dispatch));
    };
};

export const hentSisteOrdre = portefoljeData => {
    const portfolioCodes = portefoljeData.portefoljer.map(p => p.portfolioBaseCode);
    const url = `${baseUrlOrdre}/siste?${portfolioCodes.map(p => `portfolioCodes=${p}`).join('&')}`;

    return dispatch => {
        dispatch({ type: HENT_SISTE_ORDRE_PENDING });
        get(url)
            .then(data => processResponse(HENT_SISTE_ORDRE, data, dispatch, undefined, url))
            .catch((error) => processError(HENT_SISTE_ORDRE, error, dispatch));
    };
};

export const hentAlleVentendeOrdre = (portefoljeData, callback) => {
    const { portefoljer } = portefoljeData;
    const data = [];
    portefoljer.map(p => data.push({ portefoljeId: p.id, bankregnr: { value: p.bank.bankregnr } }));

    const url = `${baseUrlOrdre}/hentalleventendeordre`;

    return dispatch => {
        if ( typeof(callback) === 'function' ) {
            post(url, data).then(data => callback(data));
        } else {
            dispatch({ type: HENT_ALLE_VENTENDE_ORDRE_PENDING });
            post(url, data)
                .then(data => processResponse(HENT_ALLE_VENTENDE_ORDRE, data, dispatch, undefined, url))
                .catch((error) => processError(HENT_ALLE_VENTENDE_ORDRE, error, dispatch));
        }
    };
};

export const getSisteOrdre = (src, callback) => async (dispatch) => {
    let result = null;
    const param = (src.portefoljer ?? []).map( (p) => `portfolioId=${p.id}`);
    const url = `/sparing-min-side-web/rest/resource/fond/orders?${param.join('&')}`;

    try {
        result = await get( url ).catch( () => null );
    } catch ( error ) {
        result = null;        
    }

    return typeof(callback) === 'function' ? 
        callback( result ): dispatch({ type: HENT_SISTE_ORDRE, data: result });
};

export const getAlleVentendeOrdre = (src, callback) => async (dispatch) => {
    let result = null;
    const url = `${baseUrlOrdre}/hentalleventendeordre`;
    const data = (src.portefoljer ?? []).reduce( (list, p) => {
        return list.concat({ portefoljeId: p.id, bankregnr: { value: p.bank.bankregnr?.value || p.bank.bankregnr } });
    }, []);

    try {
        result = await post(url, data).catch( () => {
            return null;
        });
    } catch ( error ) {
        result = null;
    }

    return typeof(callback) === 'function' ? 
        callback( result ): dispatch({ type: HENT_ALLE_VENTENDE_ORDRE_PENDING, result });
};

export const setSigneringUrl = (data) => async (dispatch) => {
    dispatch({ type: SET_SIGNERING_URL, data });
};

export const setVentendeTransaksjoner = (data) => async (dispatch) => {
    dispatch({ type: HENT_VENTENDE_ORDRE, data });
};

const initialState = {
    pollingIds: {},
    ventendeTransaksjoner: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HENT_SISTE_ORDRE_PENDING:
        case HENT_VENTENDE_ORDRE_PENDING:
        case HENT_ALLE_VENTENDE_ORDRE_PENDING:
            return {
                ...state,
                pending: true
            };
        case SET_SIGNERING_URL:
        case SIGNERING_OPPRETTET: {
            return {
                ...state,
                signeringsUrl: (action.data || {}).signeringsUrl,
                pending: false
            };
        }
        case HENT_SISTE_ORDRE: {
            return {
                ...state,
                sisteTransaksjoner: action.data,
                pending: false
            };
        }
        case HENT_ALLE_VENTENDE_ORDRE: {
            return {
                ...state,
                alleVentendeTransaksjoner: action.data,
                pending: false
            };
        }
        case SET_VENTENDE_ORDRE:
        case HENT_VENTENDE_ORDRE: {
            return {
                ...state,
                ventendeTransaksjoner: {
                    ...state.ventendeTransaksjoner,
                    [`${action.data.portfolioId}${action.data.isin}`]: action.data.data
                },
                pending: false
            };
        }
        case SIGNERINGS_ORDRE_OPPRETTET: {
            const { data } = action;
            return {
                ...state,
                ordreId: data.ordreId,
                feilMelding: undefined,
                pending: false
            };
        }
        case VIS_FEILMELDING: {
            const { feilmelding } = action;
            return {
                ...state,
                feilmelding,
                pending: false
            };
        }
        case SERVER_POLL: {
            const pollingOrderId = action.data;
            return {
                ...state,
                pollingIds: {
                    ...state.pollingIds,
                    ...pollingOrderId
                },
                pending: false
            };
        }
        case RESET_ORDRE_PROSESS: {
            return {
                pollingIds: {}
            };
        }
        default:
            return state;
    }
};
