import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Format from '../format-functions';
import { getKjopFondNextPage } from '../common/Functions';

require('../../styles/fonds-informasjon.scss');

class FondsInformasjon extends Component {
    static propTypes = {
        fond: PropTypes.object,
        texts: PropTypes.object,
        visKjopKnapp: PropTypes.bool,
        portefoljeId: PropTypes.string,
        sparemaalId: PropTypes.string
    };

    skrivUtUtviklingAvFond = (utvikling, tekst, plassering) => {
        if (!utvikling) return null;

        return (
            <span className={`innholds-gruppe-tredelt ${plassering}`}>
                <span className="utvikling-over-tid">{tekst}&nbsp;</span>
                <span className="uthevet-tekst">{Format.percent(utvikling)}</span>
            </span>
        );
    };

    skrivUtRisiko = (risiko, texts) => {
        const selected = nummer => {
            return nummer === risiko ? 'selected' : '';
        };

        return (
            <div className="risiko">
                <ul>
                    <li className={selected(1)}>1</li>
                    <li className={selected(2)}>2</li>
                    <li className={selected(3)}>3</li>
                    <li className={selected(4)}>4</li>
                    <li className={selected(5)}>5</li>
                    <li className={selected(6)}>6</li>
                    <li className={selected(7)}>7</li>
                </ul>
                <div className="majorRiskTitle clearfix">
                    <div className="floatLeft">{texts.get('lavereRisiko')}</div>
                    <div className="floatRight">{texts.get('hoyereRisiko')}</div>
                </div>
            </div>
        );
    };

    generateKjopslopLink = (portefoljeId, isin, sparemaalId) => {
        if (portefoljeId) {
            return `kjopFond/${isin}/${portefoljeId}`;
        }

        if (sparemaalId) {
            return `sparemaal/kjopFond/${sparemaalId}/${isin}`;
        }

        return `kjopFond/${this.props.fond.isin}`;
    };

    visKjopsKnapp = () => {
        if (this.props.visKjopKnapp) {
            const link = this.generateKjopslopLink(
                this.props.portefoljeId,
                this.props.fond.isin,
                this.props.sparemaalId
            );

            return (
                <div className="action-button btn-call-to-action">
                    <Link to={getKjopFondNextPage(`/${link}`)}>
                        <div className="btn btn-primary">{this.props.texts.get('kjopDetteFondet')}</div>
                    </Link>
                </div>
            );
        }
        return '';
    };

    render() {
        return (
            <div className="fonds-informasjon">
                <div className="tittel">{this.props.fond.navn}</div>
                <div className="innhold">
                    <ul className="utvikling-info-wrapper">
                        {[ 
                            {id: 'iAar', text: this.props.texts.get('hittilIAar')},
                            {id: 'prosentSiste3Aar', text: this.props.texts.get('sisteTreAar')},
                            {id: 'prosentSiste5Aar', text: this.props.texts.get('sisteFemAar')},
                            {id: 'prosentSiste10Aar', text: this.props.texts.get('sisteTiAar')},
                        ].map( (note) => {
                            return this.props.fond.utvikling[note.id] === undefined ? null : <li key={note.id} className="utvikling-info">
                                <div id={`${note.id}-label`} className="utvikling-text">{note.text}</div>
                                <div aria-labelledby={`${note.id}-label`} className="utvikling-value">
                                    { typeof(this.props.fond.utvikling[note.id]) === 'number' ? 
                                        Format.percent(this.props.fond.utvikling[note.id]) : '' 
                                    }
                                </div>
                            </li>
                        })}
                    </ul>
                    {this.skrivUtRisiko(this.props.fond.risiko, this.props.texts)}
                </div>
                {this.visKjopsKnapp()}
            </div>
        );
    }
}

export default FondsInformasjon;
