import React, { Component } from 'react';
import { convertServiceMessageToAppMessage } from '@eika-sparing/utils/lib/serviceMessage/serviceMessage';
import PropTypes from 'prop-types';

class AppMessage extends Component {
    static propTypes = {
        // appMessage: PropTypes.Array,
        appMessage: PropTypes.any,
        context: PropTypes.string,
        text: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,

    };

    constructor(props) {
        super(props);
        this.state = { messageList: this.initAppMessage(props), };
    }

    render = () => {
        const { context, text, title, type } = this.props;
        const { messageList } = this.state;

        return context ? <div className="message-wrapper-collecter">
                {(messageList || []).map( (data, i) => {
                    return <div key={`app-message-${i}`} className={`message-wrapper -app-msg -${(data.type || 'info').toLowerCase() }`}>
                        {!!data.title && <div className="message-title">{data.title}</div>}
                        {!!data.text && <div className="message-text">{data.text}</div>}
                        { (data.other || []).map( (info, j) => <p key={`app-info-${i}-${j}`} className="message-info">{info}</p> ) }
                    </div>
                })}
            </div>
            :
            <div className={`message-wrapper -${type || 'info'}`} >
                {!!title && <div className="message-title">{title}</div>}
                {!!text && <div className="message-text">{text}</div>}
            </div>;
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if ( nextProps.context ) {
            const diff = JSON.stringify((this.props.appMessage || [])) !== JSON.stringify((nextProps.appMessage || [])) ||
                this.props.title !== nextProps.title ||
                this.props.type !== nextProps.type ||
                this.props.text !== nextProps.text;

            if ( diff ) { nextState.messageList = this.initAppMessage( nextProps ); }
        }
    }

    initAppMessage = ( props ) => {
        const { appMessage, context, title, text, type } = props;
        const currentList = convertServiceMessageToAppMessage( appMessage, context );
        if ( text || title ) {
            currentList.push({text, title, type, notAppMessage: true});
        }

        return currentList.length ? currentList : undefined;
    }
    
    /*
    initAppMessage = ( props ) => {
        const { appMessage, context, title, text, type } = props;
        const messageList = (appMessage instanceof Array ? appMessage : [appMessage]).filter( (d) => !!d);
        const contextList = (context instanceof Array ? context : (context || '').split(',')).filter( (d) => !!d)
        const contextPin = contextList.reduce( (pin, text) => {
            pin[text] = true;
            return pin;
        }, {'ALL': 1});

        const getText = ( text ) => {
            const out = typeof(text) === 'string' ? text : ((text || {}).NO || '');
            return out.trim();
        };

        const currentList = messageList.map( (data) => {
            const place = data.context || data.code || '';
            const isSamePlace = (place instanceof Array ? place : place.split(',')).find( (name) => {
                return !!contextPin[name.trim()];
            });

            return isSamePlace ? {
                source: data,
                type: (data.type || 'info').toLowerCase(),
                title: getText( data.title ),
                text: getText((data.details || data.text)),
            } : null;
        }).filter( (data) => !!data);

        if ( text || title ) {
            currentList.push({text, title, type});
        }

        return currentList.length ? currentList : undefined;
    }
    */
    
}

export default AppMessage; 
