import Constant from '../../static/data/Constant';
import {createCookie, eraseCookie, formatDateToText, readCookie} from './Functions';
import {AISplan, MultiObject, StringObject} from '../../domain/Types';
import {Fund} from '../../domain/Fund';

export const eraseAllFondOrder = (): void => {
    let i = 0;
    do {
        const order = `FK${i}`;
        if (!readCookie(order)) {
            return;
        }
        eraseCookie(order);
        i++;
    } while (i < 10);
}

export const getNewFondOrder = (data?: MultiObject): string => {
    const {...rest} = data ?? {};
    const storage = {
        history: {
            length: window.history.length,
            lengthList: [{id: window.history.length}],
            href: window.location.href,
            init: data,
        }, action: Constant.actionKjop, ...(rest || {})
    };

    eraseAllFondOrder();
    let i = 0;
    do {
        const order = `FK${i}`;
        if (!readCookie(order)) {
            createCookie(order, JSON.stringify(storage), .5);
            return order;
        }
        i++;
    } while (i < 10);
    return '';
}

export const getFondOrderStorage = (order = ''): any => {
    const value = readCookie(order);
    return JSON.parse((value || '{}'));
}

export const setFondOrderStorage = (order = '', storage = {}): void => {
    if (!order) {
        return;
    }
    createCookie(order, JSON.stringify(storage), .5);
}

/******************************************************************************
 ******************************************************************************/
export const getAgreementOrderSettting = (data?: any): MultiObject => {
    if (!data) {
        return {};
    }

    const ais = data.automaticIncrease || {};
    const frequency = ais.frequency;
    return {
        placement: data.portefoljeId,
        fund: data.isin,
        drawDay: `${data.trekkDag || 1}`,
        amount: data.trekkBelop || 100,
        id: data.id,
        ais: ais.isActive || undefined,
        aisFrequency: (frequency === Constant.typeArligSparing ? `${(new Date(ais.startDate)).getMonth()}` : frequency) || undefined,
        aisValue: (frequency === Constant.typeArligSparing ? ais.percentage : ais.increaseAmount) || undefined,
        automaticIncrease: data.automaticIncrease,
    };
};

/******************************************************************************
 ******************************************************************************/
export const convertFondHistorikkToPanel = (list: { dato: string, verdi: number }[] = []): any => {
    const panel: any = [];
    const cloned = JSON.parse(JSON.stringify(list));
    const base = cloned[0].verdi || 0;
    const length = cloned.length;
    const maxIndex = parseInt(`${length / 12}`, 10) - 1;
    const leftOver = length % 12;
    const pin: any = [];
    cloned.forEach((data: any, i: number) => {
        if (base) {
            data.prosent = Math.round((((data.verdi - base) * 100) / base));
        } else {
            data.prosent = 0;
        }
        data.verdi = Math.round(data.verdi);
        data.value = data.prosent;
        data.unit = '%';
        data.index = parseInt(`${i / 12}`, 10);
        data.index = i - leftOver < 0 ? maxIndex : (maxIndex - parseInt(`${i / 12}`, 10));
        if (data.index < 0) {
            data.index = 0;
        }

        if (!pin[data.index]) {
            pin[data.index] = [];
        }
        pin[data.index].push(data);
    });

    Array.from({length: (maxIndex + 1)}).forEach((_, i: number) => {
        panel[i] = pin[i].concat((panel[(i - 1)] || []));
    });

    return panel;
};

/******************************************************************************
 ******************************************************************************/
export const getKontiTypeName = (type = ''): string => {
    type = typeof (type) === 'string' ? type.replace(/\d/g, '') : '';
    const accountType: { [k: string]: string } = {
        vpk: 'Verdipapirkonto',
        ask: 'Aksjesparekonto',
        ips: 'Individuell pensjonssparing',
        vps: 'Verdipapirsentralen',
    };

    return accountType[type.toLowerCase()] ?? type;
};

/******************************************************************************
 ******************************************************************************/

export const getActionSubName = (subType = ''): string => {
    const name: Record<string, string> = {
        platform_fee: 'Plattformhonorar',
        platform_fee_one_time: 'Plattformhonorar',
        platform_fee_kickback: 'Returprovisjon',
        kickback_one_time: 'Returprovisjon',
    } as Record<string, string>;

    return name[subType.toLowerCase()] || subType;
};
export const getActionName = (action = '', shortname = false, subType = null): string => {
    const name: { [k: string]: string } = shortname ? {
        kjop: 'Kjøp',
        // salg: 'Salg',
        salg: 'Uttak',
        kjop_bytt: 'Byttet til',
        salg_bytt: 'Byttet fra',
        gigave: 'Gave gitt',
        endretspareavtale: 'Endret spareavtale',
    } : {
        kjop: 'Satt inn penger',
        salg: 'Tok ut penger',
        kjop_bytt: 'Byttet til',
        salg_bytt: 'Byttet fra',
        gigave: 'Gave gitt',
        endretspareavtale: 'Endret spareavtale',
    };

    const actionSubname = subType && getActionSubName(subType);

    if (actionSubname && actionSubname !== 'NONE') {
        return actionSubname;
    }

    return name[action.toLowerCase()] || action;
};

/******************************************************************************
 ******************************************************************************/
export const getKontiNoteList = (type = ''): string[] => {
    const description: { [k: string]: string[] } = {
        vpk: [
            'Kan brukes til alle typer fond',
            'Passer dersom du ønsker å spare i fond med lavere risiko',
            'Kontoen er gratis',
        ],
        ask: [
            'Frihet til å ta ut pengene du satte inn uten å betale skatt',
            'Legg dine Aksjefond og Indeksfond i denne kontoen',
            'Kontoen er gratis',
        ],
    };
    return description[type.toLowerCase()] ?? [];
};

/******************************************************************************
 ******************************************************************************/
export const getFondUtviklingConfigList = (): { id: string, name: string }[] => {
    return [
        {id: 'iAar', name: 'Hittil i år'},
        {id: 'prosentSiste3Aar', name: 'Siste 3 år'},
        {id: 'prosentSiste5Aar', name: 'Siste 5 år'},
        {id: 'prosentSiste10Aar', name: 'Siste 10 år'}
    ];
}

/******************************************************************************
 ******************************************************************************/
export const getAISfrequencyText = (frequency = ''): string => {
    const config: StringObject = {
        'quarterly': 'Kvartalsvis',
        'monthly': 'Månedlig',
        'annual': 'Årlig',
    };
    return config[frequency.toLowerCase()] || '';
};

/******************************************************************************
 ******************************************************************************/
export const getAISbyPercent = (
    years: number,
    amount: number,
    performance: number,
    percentageIncrease: number,
    round = false,
    skipCounter = 0
): AISplan[] => {
    const months = 12;
    const outcomeArr: AISplan[] = [];

    // calc deposits
    let counter = 0;
    // eslint-disable-next-line
    Array.from({length: years}).forEach((x, i) => {
        const deposit = i ? outcomeArr[(outcomeArr.length - 1)].deposit * (1 + percentageIncrease) : amount;
        // eslint-disable-next-line
        Array.from({length: months}).forEach(() => {
            if (skipCounter && skipCounter > counter++) {
                return;
            }

            outcomeArr.push({
                deposit: round ? Math.round(deposit) : deposit,
                depositSum: 0,
                accumulatedSum: 0
            });
        });
    });

    // eslint-disable-next-line
    Array.from({length: skipCounter}).forEach(() => {
        outcomeArr.push({
            deposit: outcomeArr[(outcomeArr.length - 1)].deposit,
            depositSum: 0,
            accumulatedSum: 0
        });
    });

    outcomeArr.forEach((data: AISplan, i: number) => {
        if (i === 0) {
            // summarize deposits & accumelated value
            outcomeArr[0].depositSum = outcomeArr[0].deposit;
            outcomeArr[0].accumulatedSum = outcomeArr[0].deposit;
        } else {
            outcomeArr[i].depositSum = outcomeArr[i].deposit + outcomeArr[i - 1].depositSum;
            outcomeArr[i].accumulatedSum = outcomeArr[i].deposit + outcomeArr[i - 1].accumulatedSum + (outcomeArr[i - 1].accumulatedSum * (performance / 12));

            if (round) {
                outcomeArr[i].accumulatedSum = Math.round(outcomeArr[i].accumulatedSum);
            }
        }
    });

    return outcomeArr;
};

export const getAISbyAmount = (
    years: number,
    amount: number,
    performance: number,
    yearlyIncrements: number,
    incrementAmount: number,
    round = false,
    skipCounter = 0
): AISplan[] => {
    const months = 12;
    const outcomeArr: AISplan[] = [];
    let amountTmp = amount;

    // map to convert yearly increment to modulus applicable.
    // Probably a mathematical way to convert this but hey.
    const incrementModulusMap: any = {
        12: 1,
        4: 3,
        2: 6,
        1: 12
    };

    let counter = 0;
    // @ts-ignore
    const yearlyIncrementsTmp = incrementModulusMap[yearlyIncrements as keyof incrementModulusMap];
    if (!yearlyIncrementsTmp) {
        return outcomeArr;
    }

    // calc deposits
    // eslint-disable-next-line
    Array.from({length: years}).forEach((x, i) => {
        // eslint-disable-next-line
        Array.from({length: months}).forEach((y, j) => {
            if (skipCounter && skipCounter > counter++) {
                return;
            }

            if (!j && !i) {
                // Gotta skip increments first month
            } else if (!(j % yearlyIncrementsTmp)) {
                amountTmp += incrementAmount;
            }

            outcomeArr.push({
                deposit: amountTmp,
                depositSum: 0,
                accumulatedSum: 0
            });
        });
    });

    // eslint-disable-next-line
    Array.from({length: skipCounter}).forEach(() => {
        outcomeArr.push({
            deposit: outcomeArr[(outcomeArr.length - 1)].deposit,
            depositSum: 0,
            accumulatedSum: 0
        });
    });

    outcomeArr.forEach((data: AISplan, i: number) => {
        if (i === 0) {
            // summarize deposits & accumelated value
            // Starting off by setting values for first day
            outcomeArr[0].depositSum = outcomeArr[0].deposit;
            outcomeArr[0].accumulatedSum = outcomeArr[0].deposit;
        } else {
            outcomeArr[i].depositSum = outcomeArr[i].deposit + outcomeArr[i - 1].depositSum;
            outcomeArr[i].accumulatedSum = outcomeArr[i].deposit + outcomeArr[i - 1].accumulatedSum + (outcomeArr[i - 1].accumulatedSum * (performance / 12));

            if (round) {
                outcomeArr[i].accumulatedSum = Math.round(outcomeArr[i].accumulatedSum);
            }
        }
    });
    return outcomeArr;
};

export const getTheFirstDrawDate = (drawDay?: string | number): Date => {
    const date = new Date();
    const addition = 7 * 6; // 6 weeks by setting up of 'avtalegiro', then begins the first draw
    date.setDate((date.getDate() + addition));

    const day = parseInt(`${drawDay || 0}`, 10);
    if (day && day < date.getDate()) {
        date.setDate(1);
        date.setMonth((date.getMonth() + 1));
    }

    if (day) {
        date.setDate(day);
    }
    return date;
};

export const verifyShallIncreaseImmediately = (frequency = '', drawDay?: string | number): boolean => {
    if (frequency === Constant.typeManedligSparing) {
        return true;
    }
    const date = getTheFirstDrawDate((drawDay ?? 1));

    if (frequency === Constant.typeKvatalSparing && (date.getMonth() % 3) === 0) {
        return true;
    }
    return frequency === `${date.getMonth()}`;
};

export const getAISplan = (config: {
    aisValue?: string | number;
    amount?: string | number;
    date?: Date;
    drawDay?: string | number;
    aisFrequency?: string;
    year?: number;
    performance?: number;
    onlyWantDifferentDeposit?: boolean | string;
    increaseImmediately?: boolean | string;
}): AISplan[] => {
    const value = parseInt(`${config.aisValue || 0}`.replace(/\s+/g, '').replace(',', '.'), 10);
    let amount = parseInt(`${config.amount || 0}`.replace(/\s+/g, '').replace(',', '.'), 10);
    let result: AISplan[] = [];

    if (!amount || !value) {
        return result
    }

    let deposit = amount;
    const date = config.date || getTheFirstDrawDate(config.drawDay);
    const frequency = config.aisFrequency || '0'; // default every january
    const year = config.year || 30;
    const performance = config.performance || (6 / 100);

    if (frequency === Constant.typeManedligSparing || frequency === Constant.typeKvatalSparing) {
        const yearlyIncrements = frequency === Constant.typeManedligSparing ? 12 : 4;
        if (config.increaseImmediately && verifyShallIncreaseImmediately(frequency, config.drawDay)) {
            amount += value;
        }

        if (frequency === Constant.typeManedligSparing) { // Monthly
            result = getAISbyAmount(year, amount, performance, yearlyIncrements, value, true);
        } else {
            const skip = date.getMonth() % 3;
            result = getAISbyAmount(year, amount, performance, yearlyIncrements, value, true, skip);
        }
    } else if (/^([0-9]|1[0-1])$/i.test(frequency)) {
        const fMonth = parseInt(`${frequency}`, 10);
        const dMonth = date.getMonth();
        // eslint-disable-next-line
        const skip = dMonth === fMonth ? 0 : (dMonth < fMonth ? (12 - (fMonth - dMonth)) : (dMonth - fMonth));
        const percent = value / 100;

        if (config.increaseImmediately && verifyShallIncreaseImmediately(frequency, config.drawDay)) {
            amount *= (1 + percent);
        }

        result = getAISbyPercent(year, amount, performance, percent, true, skip);
    }

    const cloned = new Date(date.getTime());
    const dateFormat = frequency === Constant.typeKvatalSparing ? 'yyyy - qa' : 'yyyy - MM';
    result.forEach((data: AISplan) => {
        data.dateText = formatDateToText(cloned, dateFormat).toLowerCase();
        // data.monthText = formatDateToText(cloned, 'MM yyyy').toLowerCase();
        data.stamp = cloned.toISOString().replace(/T.*/g, 'T00:00:00');
        cloned.setMonth((cloned.getMonth() + 1));
    });

    if (config.onlyWantDifferentDeposit && result.length) {
        return result.map((data: AISplan): AISplan => {
            if (data.deposit !== deposit) {
                deposit = data.deposit;
            } else {
                delete (data.stamp);
            }
            return data;
        }).filter((data: AISplan) => !!data.stamp);
    }

    return result;
};

export const filterOnetimePeriodic = (fund: Fund, search: string) => {
    if (search?.includes('onetime')) return fund.allowedTradeTypes.includes('BUY')
    if (search?.includes('monthly')) return fund.allowedTradeTypes.includes('PERIODIC')
    return true
}



