import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DonutChartWithCheckboxLegend from '../charts/donut-chart-checkbox-legend';
import NedTrekksMeny from '../ned-trekks-meny';
import HelperFunction from '../helper-functions';
import ColorFunctions from '../color-functions';
import Format from '../format-functions';
import NumberInput from '../common/number-input';
import { pensjonsDataPropType, spareProfilConfigPropType } from '../../domain/prop-types/pensjonsPropTypes';

class PensjonsKalkulator extends Component {
    static propTypes = {
        texts: PropTypes.object,
        pensjonsData: pensjonsDataPropType,
        endreOnsketPensjonsAlder: PropTypes.func,
        endreBruttoAarsInntekt: PropTypes.func,
        endreInkluderFond: PropTypes.func,
        onsketPensjonsAlder: PropTypes.number,
        bruttoAarsInntekt: PropTypes.number,
        bruktBruttoAarsInntektTilBeregning: PropTypes.number,
        config: spareProfilConfigPropType,
        lasterPensjonsEstimater: PropTypes.bool,
        pensjonskalkulatorIncludeFondssparing: PropTypes.bool,
        hentForventetPensjon: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            dataForDonutChartWithCheckboxLegend: this.aggregerDataForDonutChartWithCheckboxLegend(
                props.pensjonsData,
                props.pensjonskalkulatorIncludeFondssparing
            ),
            pensjonsAldre: HelperFunction.genererListeMedTall(props.config.minimumsAlder, props.config.maximumsAlder)
        };
    }

    UNSAFE_componentWillReceiveProps = newProps =>
        this.setState({
            dataForDonutChartWithCheckboxLegend: this.aggregerDataForDonutChartWithCheckboxLegend(
                newProps.pensjonsData,
                newProps.pensjonskalkulatorIncludeFondssparing
            )
        });

    sumAarligPensjon = (pensjonsData, fondSparingActive) =>
        (fondSparingActive ? pensjonsData.sumEgenSparingIFond : 0) +
        pensjonsData.sumIndividuellPensjonsSparing +
        pensjonsData.folketrygdPerAar +
        pensjonsData.privatTjenestePensjonPerAar +
        pensjonsData.offentligTjenestepensjonPerYear +
        pensjonsData.privatAFPPerYear +
        pensjonsData.individuelleOrdningerPerYear;

    createChartEntry = (textKey, value, id, color, config = {}) => ({
        text: this.props.texts.get(textKey),
        value,
        id,
        color,
        skravert: config.skravert,
        toggleable: config.toggleable,
        active: config.active === undefined ? true : config.active,
        showInLegend: config.showInLegend === undefined ? true : config.showInLegend
    });

    aggregerDataForDonutChartWithCheckboxLegend = (pensjon = {}, fondSparingActive) => [
        this.createChartEntry(
            'folkeTrygd',
            pensjon.folketrygdPerAar || 0,
            'folketrygdPerAar',
            ColorFunctions.hentFarge(2)
        ),

        this.createChartEntry(
            // 'tjenestePensjon',
            // pensjon.privatTjenestePensjonPerAar + pensjon.offentligTjenestepensjonPerYear || 0,
            'arbeidsgiverspensjon',
            pensjon.privatTjenestePensjonPerAar || 0,
            'tjenestePensjonPerAar',
            ColorFunctions.hentFarge(3)
        ),

        this.createChartEntry(
            'privatAFP',
            pensjon.privatAFPPerYear || 0,
            'privatAFPPerYear',
            ColorFunctions.hentFarge(4)
        ),

        this.createChartEntry(
            'individuelleOrdninger',
            pensjon.individuelleOrdningerPerYear || 0,
            'individuelleOrdningerPerYear',
            ColorFunctions.hentFarge(5)
        ),

        this.createChartEntry(
            'eikaIpsKonto',
            pensjon.sumIndividuellPensjonsSparing || 0,
            'sumIndividuellPensjonsSparing',
            ColorFunctions.hentFarge(1)
        ),

        this.createChartEntry(
            'egenSparingIFond',
            pensjon.sumEgenSparingIFond || 0,
            'pensjonskalkulatorIncludeFondssparing',
            ColorFunctions.hentFarge(0),
            { active: fondSparingActive, toggleable: true }
        ),

        this.createChartEntry(
            'differanseFraDagensLonn',
            pensjon.differanseFraDagensLoenn,
            'differanseFraDagensLoenn',
            ColorFunctions.hentRoedFarge(),
            { active: pensjon.differanseFraDagensLoenn < 0, skravert: true, showInLegend: false }
        )
    ];

    paaEndringAktiv = (id, inkluderFond) => this.props.endreInkluderFond(id, inkluderFond);

    visProsent = prosent => (prosent > 1000 || prosent === 0 ? '-' : `${prosent}%`);

    colorRedOnNegative = () => {
        const { pensjonsData = {} } = this.props;
        return pensjonsData.differanseFraDagensLoenn < 0 ? { color: ColorFunctions.hentRoedFarge() } : {};
    };

    pieChartText = () => {
        const { dataForDonutChartWithCheckboxLegend } = this.state;
        const { pensjonsData = {}, texts, lasterPensjonsEstimater, pensjonskalkulatorIncludeFondssparing, bruktBruttoAarsInntektTilBeregning } = this.props;
        if (pensjonsData.totalForventetPensjonPerAar && pensjonsData.totalForventetPensjonPerMnd) {
            const totalForventetPensjonPerAar = pensjonskalkulatorIncludeFondssparing ? pensjonsData.totalForventetPensjonPerAar :
                (pensjonsData.totalForventetPensjonPerAar - pensjonsData.sumEgenSparingIFond);
            const totalForventetPensjonPerMnd = totalForventetPensjonPerAar / 12;
            const pensjonIProsentAvDagensLoenn = bruktBruttoAarsInntektTilBeregning ?
                Math.round( ((totalForventetPensjonPerAar*100) / bruktBruttoAarsInntektTilBeregning)) : 0;

            return (
                <div>
                    <div className="centered-text forventet-pensjon">
                        <div className="pensjons-kalkulator-tittel">{texts.get('forventetPensjon')}</div>
                        <div>
                            <div className="per-aar">
                                {Format.currency(totalForventetPensjonPerAar)} per år
                            </div>
                            <div>
                                {Format.currency(totalForventetPensjonPerMnd)} per mnd. før skatt i 10 år
                            </div>
                        </div>
                    </div>
                    <DonutChartWithCheckboxLegend
                        data={dataForDonutChartWithCheckboxLegend}
                        toggleActive={this.paaEndringAktiv}
                        loading={lasterPensjonsEstimater}
                    >
                        <div className="donut-labels">
                            <div className="label-section">
                                <div className="text-label">Du vil få ca</div>
                                <div className="big-donut-number">
                                    {this.visProsent(pensjonIProsentAvDagensLoenn)}
                                </div>
                                <div className="text-label">av dagens lønn</div>
                                <div className="text-label">i pensjon</div>
                            </div>
                        </div>
                    </DonutChartWithCheckboxLegend>
                </div>
            );
        }
        return null;
    };

    renderSummary = () => {
        const { pensjonsData, pensjonskalkulatorIncludeFondssparing, texts, bruktBruttoAarsInntektTilBeregning } = this.props;
        if (pensjonsData) {
            const totalForventetPensjonPerAar = pensjonskalkulatorIncludeFondssparing ? pensjonsData.totalForventetPensjonPerAar :
                (pensjonsData.totalForventetPensjonPerAar - pensjonsData.sumEgenSparingIFond);
            const differanseFraDagensLoenn = totalForventetPensjonPerAar - (bruktBruttoAarsInntektTilBeregning || 0);


            return (
                <div className="checkbox-legend">
                    <div className="legend-container aarlig-pensjon-sum">
                        <div className="legend-wrapper">
                            <div className="label-container">
                                <span className="text">Årlig pensjon</span>
                            </div>
                        </div>
                        <div className="value">
                            {Format.currency(
                                this.sumAarligPensjon(pensjonsData, pensjonskalkulatorIncludeFondssparing)
                            )}
                        </div>
                    </div>

                    <div className="legend-container differanse-fra-dagens">
                        <div className="legend-wrapper">
                            <div className="circle" style={{ backgroundColor: ColorFunctions.hentRoedFarge() }} />
                            <div className="label-container">
                                <span className="text">{texts.get('differanseFraDagensLonn')}</span>
                            </div>
                        </div>
                        <div className="value" style={this.colorRedOnNegative()}>
                            {Format.currency(differanseFraDagensLoenn)}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    render() {
        const { pensjonsAldre } = this.state;
        const {
            texts,
            config,
            onsketPensjonsAlder,
            endreOnsketPensjonsAlder,
            bruttoAarsInntekt,
            endreBruttoAarsInntekt,
            hentForventetPensjon,
            pensjonsData
        } = this.props;

        return (
            <div className="pension-calculator-holder">

                <div className="message-wrapper -attention -icon-black">
                    {this.props.texts.get('pensionCalculatorMessage')}
                </div>

                { pensjonsData.offentligTjenestepensjonPerYear === -1 && <div className="message-wrapper -attention">
                    {this.props.texts.get('zeroPensionFromPublicSectorMessage')}
                </div> }

                <div className="pensjons-input-felter">
                    <div className="pensjons-dropdown">
                        <NedTrekksMeny
                            label={texts.get('naarVilDuGaaAv')}
                            id="naarVilDuGaaAv"
                            options={pensjonsAldre}
                            defaultValue={onsketPensjonsAlder}
                            onChange={endreOnsketPensjonsAlder}
                            disabled={config.minimumsAlder > config.maximumsAlder}
                        />
                    </div>
                    <div className="brutto-aarsinntekt-felt">
                        <NumberInput
                            id="bruttoAarsInntekt"
                            label={texts.get('bruttoAarsinntektIAar')}
                            value={bruttoAarsInntekt}
                            onChange={endreBruttoAarsInntekt}
                        />
                    </div>
                </div>

                { onsketPensjonsAlder === 62 && <div className="info-message">
                    Får å få folketrygden så tidlig, må du ha tjent nok. Dersom du har jobbet deltid eller lignende, er det ikke sikkert du kan få folketrygd før du fyller 67 år.
                </div> }

                <div className="rekalkulerTop">
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button onClick={hentForventetPensjon} className="btn btn-info">
                        Beregn!
                    </button>
                </div>
                {this.pieChartText()}
                {this.renderSummary()}
            </div>
        );
    }
}

export default PensjonsKalkulator;
