import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loading from '@eika-infrastruktur/ui-kort-laster';
import Feilmelding from '../../components/feilmelding';
import Sparemaalskjema from '../../components/sparemaal/sparemaal-skjema';
import Card from '../../components/card';

class RedigerSparemaal extends Component {
    static propTypes = {
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        expandItem: PropTypes.object,
        errors: PropTypes.object,
        sparemaal: PropTypes.object.isRequired,
        updateSparemaal: PropTypes.func,
    };

    UNSAFE_componentWillMount = () => {
        this.props.settSideTittel(this.props.texts.get('sparemaalTittel'));
    };

    updateSparemaal = (data) => {
        this.props.updateSparemaal(data);
        this.props.expandItem.onExpand(this.props.expandItem.id);
    };

    sjekkForServerFeil = (errors) => errors.HENT_SPAREMAAL;

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.props.sparemaal) {
            return (
                <Card transparent>
                    <Sparemaalskjema
                        sparemaal={this.props.sparemaal}
                        onSubmit={this.updateSparemaal}
                        texts={this.props.texts}
                        submitButtonText={this.props.texts.get('sparemaalRedigerSparemaal')}
                    />
                </Card>
            );
        }
        return <Loading title={this.props.texts.get('lasterPensjonsApp')} />;
    }
}

export default RedigerSparemaal;
