import Constant from '../../../static/data/Constant';
import { validateAmount } from '../../../common/util/Validations';
import { roundUpValue, formatAmount, formatDateToText } from '../../../common/util/Functions';
import { AISplan } from '../../../domain/Types';
import { Props, State } from '../FondHandelDomain';

export const getAISvalueRequiredMessage = ( props: Props, state: State, orderStorage: any): string => {
    if ( !orderStorage.ais ) { return '';}

    return state.aisFrequency.pin[orderStorage.aisFrequency].amount ?
        'Økenede beløp er påkrevd' :
        'Økenede prosent er påkrevd';
};

export const getAISsource = ( orderStorage: any ): any => {
    if ( !orderStorage ) { orderStorage = {}; }

    return {
        value: parseFloat( `${orderStorage.aisValue || 0}`.replace( /,/g, '.' ) ),
        amount: parseInt( `${orderStorage.amount || 0}`.replace(/\s+/, ''), 10),
        type: orderStorage.aisFrequency === Constant.typeManedligSparing || orderStorage.aisFrequency === Constant.typeKvatalSparing ? 
            Constant.typeAmount : Constant.typePercent,
        frequency: /^[0-9]{1,2}$/i.test( orderStorage.aisFrequency ) ? 
            Constant.typeArligSparing : (orderStorage.aisFrequency || Constant.typeArligSparing).toUpperCase()
    };
};

export const getAISvalueInvalidMessage = ( props: Props, state: State, orderStorage: any ): string => {
    if ( !orderStorage.ais || !state.aisFrequency?.pin ) { return ''; }

    const { value, amount } = getAISsource( orderStorage );
    if ( !validateAmount(value) ) {
        return 'Ugyldig økende beløp';
    }

    if ( state.aisFrequency.pin[orderStorage.aisFrequency].amount  ) {
        return value < 1 ? 'Ugyldig beløp. Det minste økende beløp er 1 kr.' : '';
    } 

    if ( !amount ) { return ''; }

    const min = Math.round( (((1/amount) * 100)*100) ) / 100;
    return value < min ? `Ugyldig prosent. Det minste økende prosent er ${min}` : '';
};

export const getAISvalueInfo = ( props: Props, state: State, orderStorage: any, plan: any ): any => {
    if ( !orderStorage.ais || !state.aisFrequency?.pin ) { return; }

    const { value, amount } = getAISsource( orderStorage );
    if ( !value || !amount || getAISvalueInvalidMessage(props, state, orderStorage) ) { return; }

    const frequency = state.aisFrequency.pin[orderStorage.aisFrequency];
    if ( !frequency ) { return; }

    const firstChanged = (plan || []).find( (d: any) => d.deposit !== amount );
    const date = new Date( (firstChanged || {}).stamp );
    const now = new Date();
    const dateFormat = now.getFullYear() === date.getFullYear() ? 'MM' : 'MM yyyy';

    const output = {
        summary: `${formatAmount(value)} ${frequency.amount ? 'kr': '%'} ${(frequency.name || frequency.text).toLowerCase()}`,
        text: firstChanged ? `Beløpet økes til ${formatAmount(firstChanged.deposit)} kr i ${formatDateToText(date, dateFormat).toLowerCase()}` : '',
    };

    return output;
};

export const convertAISplanToTable = ( plan?: AISplan[], deposit=0 ): any => {
    return {
        order: [
            {id: 'dateText', name: 'Når økning skjer'},
            {id: 'deposit', name: 'Månedlig beløp'},         
            // {id: 'accumulatedSum', name: 'Summen kan bli'},            
        ],
        body: (plan || []).map( (d: AISplan) => {
            if ( d.deposit === deposit ) { return; }
            deposit = d.deposit;
            return {...d, deposit: formatAmount(d.deposit, true), accumulatedSum: formatAmount(roundUpValue(d.accumulatedSum, 100))};
        }).filter( (d: any) => !!d),
    };
};
