import { post } from '@eika-infrastruktur/request';
import { VIS_KVITTERING, VIS_KVITTERING_LASTER } from './modals';
import { IKKE_EIER_AV_KONTONUMMER, KUNNE_IKKE_OPPRETTE_ORDRE, VIS_FEILMELDING } from './signerings-ordre';

const baseUrlFond = '/sparing-min-side-web/rest/resource/fond';
const selgFondKlientKontoUrl = `${baseUrlFond}/selgfond/klientkonto`;

const paaOrdreUtfort = (data, dispatch) => {
    dispatch({
        type: VIS_KVITTERING,
        ordreResultater: data.ordreResultater,
    });
};

const STATUS_NOT_ACCEPTABLE = 406;

const sendSalgsOrdre = (url, onSuccess, data, dispatch) => {
    post(url, data)
        .then((data) => {
            onSuccess(data, dispatch);
        })
        .catch((error) => {
            const feilmelding =
                error.code === STATUS_NOT_ACCEPTABLE ? IKKE_EIER_AV_KONTONUMMER : KUNNE_IKKE_OPPRETTE_ORDRE;
            dispatch({ type: VIS_FEILMELDING, feilmelding });
        });
};

const OpprettSalgsOrdre = (data) => {
    return (dispatch) => {
        dispatch({ type: VIS_KVITTERING_LASTER });
        sendSalgsOrdre(selgFondKlientKontoUrl, paaOrdreUtfort, data, dispatch);
    };
};

export default OpprettSalgsOrdre;
