import {
    LAST_SPARE_PROFIL_DATA,
    LAST_SAMTYKKE,
    LAST_SAMTYKKE_VALG,
    ENDRE_SPARE_PROFIL,
    HENT_KUNDE_VALG,
    ENDRE_KUNDE_VALG,
    SET_TERMS
} from '../actions/spareprofil';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_TERMS:
            return { ...state, terms: action.result, };
        case LAST_SPARE_PROFIL_DATA:
            return {
                ...state,
                ...action.data,
            };
        case LAST_SAMTYKKE:
            return {
                ...state,
                samtykkeTekster: action.data,
            };
        case LAST_SAMTYKKE_VALG:
            return {
                ...state,
                samtykker: action.data,
            };

        case ENDRE_SPARE_PROFIL:
            return {
                ...state,
                [action.subObjectName]: {
                    ...state[action.subObjectName],
                    ...action.endring,
                },
            };
        case HENT_KUNDE_VALG:
            return {
                ...state,
                kundevalg: action.data,
            };
        case ENDRE_KUNDE_VALG:
            return {
                ...state,
                kundevalg: {
                    ...state.kundevalg,
                    ...action.valg,
                },
            };
        default:
            return state;
    }
};
