import { ProcessJsonResponse } from './common';

export const HENT_SPAREMAAL = 'HENT_SPAREMAAL';
export const OPPRETT_SPAREMAAL = 'OPPRETT_SPAREMAAL';

export const BE_OM_GAVE = 'BE_OM_GAVE';
export const MOTTA_GAVE = 'MOTTA_GAVE';

const getSparemaalUrl = '/sparing-min-side-web/rest/resource/sparemaal';
const createSparemaalUrl = '/sparing-min-side-web/rest/resource/sparemaal';
const updateSparemaalUrl = '/sparing-min-side-web/rest/resource/sparemaal';

export const getSparemaal = () => {
    return (dispatch) => {
        fetch(getSparemaalUrl, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(HENT_SPAREMAAL, data, dispatch)
        );
    };
};

export const createSparemaal = (data) => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        fetch(createSparemaalUrl, config).then((data) => {
            if (data.ok) {
                ProcessJsonResponse(OPPRETT_SPAREMAAL, data, dispatch);
            }
        });
    };
};

export const updateSparemaal = (data) => {
    const config = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return (dispatch) => {
        fetch(updateSparemaalUrl, config).then((data) => {
            if (data.ok) {
                ProcessJsonResponse(HENT_SPAREMAAL, data, dispatch);
            }
        });
    };
};

// export const recieveGift = () => {};
// export const giveGift = (data) => {};
