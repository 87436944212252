import React from 'react';
import PropTypes from 'prop-types';
import Formattering from '../format-functions';

require('../../styles/risiko-beskrivelse.scss');

export const RisikoAvkastning = props => {
    const classNames = props.uthevet ? 'liste-element uthevet' : 'liste-element';
    const formatertVerdi = Formattering.currency(props.estimertVerdi);

    return (
        <div className={classNames}>
            <div className="venstre-kol kol-tittel">{props.risikoTekst}</div>
            <div className="hoyre-kol kol-tittel">{formatertVerdi}</div>
        </div>
    );
};

RisikoAvkastning.propTypes = {
    risikoTekst: PropTypes.string.isRequired,
    estimertVerdi: PropTypes.string.isRequired,
    uthevet: PropTypes.bool
};

const RisikoBeskrivelse = props => (
    <div className="risiko-beskrivelse">
        <div className="beskrivelses-tekst">{props.beskrivelse}</div>
        <div>
            {props.estimater.map(risikoEstimat => (
                <RisikoAvkastning
                    key={risikoEstimat.risikoTekst}
                    risikoTekst={risikoEstimat.risikoTekst}
                    estimertVerdi={risikoEstimat.estimertVerdi}
                    uthevet={risikoEstimat.uthevet}
                />
            ))}
        </div>
    </div>
);

RisikoBeskrivelse.propTypes = {
    beskrivelse: PropTypes.string.isRequired,
    estimater: PropTypes.arrayOf(PropTypes.object)
};

export default RisikoBeskrivelse;
