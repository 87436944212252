import React from 'react';
import {Provider} from 'react-redux';
import loadContainers from '@eika-infrastruktur/ui-engine-render';

import configureStore from './configureStore';
import Router from './containers/router';
import KjoepFondRouter from './containers/kjoep-router';
import HeaderContainer from './containers/header';
import HeaderNativeContainer from './containers/header-native';
import SmartSparPopUpIos from './containers/smartspar-popup-ios';
import SmartSparPopUpAndroid from './containers/smartspar-popup-android';
import KjennDinKundeRNWebView from './containers/KjennDinKundeRNWebView';
import KjennDinKundeBarnRNWebView from './containers/KjennDinKundeBarnRNWebView';
import EKFfondVisning from './containers/EKFfond/EKFfondVisning';
import EKFfondKalkulator from './containers/EKFfond/EKFfondKalkulator';
import EKFspareLinking from './containers/EKFfond/EKFspareLinking';
import EKFmarkedsforingVarsel from './containers/EKFfond/EKFmarkedsforingVarsel';
import EKFpubliseringsdato from './containers/EKFfond/EKFpubliseringsdato';
import EikaInnskuddspensjonLink from './containers/EKFfond/EikaInnskuddspensjonLink';

const store = configureStore();

require('./polyfill/string.js');
require('./styles/main.scss');

const globalCssNamespace = 'sparing-min-side';

const renderApp = (MyApp, namespace, param = {}) => {
    loadContainers(namespace, props => {
        if (props && props.config) {
            store.dispatch({type: 'LOAD_TEXTS', data: props.config});
        }
        return (
            <Provider store={store}>
                <div className={globalCssNamespace}>
                    <MyApp {...param} {...props} />
                </div>
            </Provider>
        );
    });
};

if (module.hot) {
    module.hot.accept('./containers/app', () => {
        // eslint-disable-next-line global-require
        const NewApp = require('./containers/app').default;

        renderApp(NewApp, 'sparing-min-side');
    });

    module.hot.accept('./containers/kjoep-router', () => {
        // eslint-disable-next-line global-require
        const NewApp = require('./containers/kjoep-router').default;

        renderApp(NewApp, 'sparing-min-side.kjoep-fond');
    });
}

renderApp(HeaderNativeContainer, 'min-side-sparing.header-native');
renderApp(HeaderContainer, 'min-side-sparing.header');
renderApp(Router, 'sparing-min-side');
renderApp(KjoepFondRouter, 'sparing-min-side.kjoep-fond');
renderApp(SmartSparPopUpAndroid, 'sparing-min-side.smartspar-popup-android');
renderApp(SmartSparPopUpIos, 'sparing-min-side.smartspar-popup-ios');
renderApp(KjennDinKundeRNWebView, 'sparing-min-side.kjenndinkunde');
renderApp(KjennDinKundeBarnRNWebView, 'sparing-min-side.kjenndinkunde-barn');

renderApp(EKFspareLinking, 'sparing-min-side.ekf-spare-linking');

renderApp(EKFfondKalkulator, 'sparing-min-side.ekf-fond-kalkulator');
renderApp(EKFpubliseringsdato, 'sparing-min-side.ekf-publiseringsdato');
renderApp(EKFmarkedsforingVarsel, 'sparing-min-side.ekf-markedsforing-varsel');
renderApp(EKFmarkedsforingVarsel, 'sparing-min-side.ekf-markedsforing-varsel-inversert-topp', {invertedTop: true});

renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning');
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-alpha', {fond: 'eika alpha'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-balansert', {fond: 'eika balansert'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-egenkapitalbevis', {fond: 'eika egenkapitalbevis'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-global', {fond: 'eika global a'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-kreditt-a', {fond: 'eika kreditt a'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-norge', {fond: 'eika norge'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-norden', {fond: 'eika norden'});
renderApp(EKFfondVisning, 'sparing-min-side.ekf-fond-visning-eika-spar', {fond: 'eika spar'});

renderApp(EikaInnskuddspensjonLink, 'sparing-min-side.eika-innskuddspensjon-link');

