import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XAxis, YAxis, CartesianGrid, Area, AreaChart, ResponsiveContainer, ReferenceDot } from 'recharts';
import Format from '../format-functions';
import DotLegend from '../legends/dot-legend';
import HelperFunctions from '../helper-functions';

require('../../styles/estimerings-graf.scss');

class EstimeringsGraf extends Component {
    static propTypes = {
        texts: PropTypes.object,
        values: PropTypes.array,
        innskudd: PropTypes.number,
        tidshorisont: PropTypes.number
    };

    getValuesSize = () => this.props.values.length - 1;

    getLastUpper = () => this.props.values[this.props.values.length - 1].upper;

    getLastLower = () => this.props.values[this.props.values.length - 1].lower;

    getLastExpected = () => this.props.values[this.props.values.length - 1].expected;

    adjustForStacking = values =>
        values.map(item => ({
            key: item.key,
            upper: item.upper - item.lower,
            expected: item.expected,
            lower: item.lower
        }));

    createDataForLegend = () => {
        const svingninger = HelperFunctions.VariableInjection(
            this.props.texts.get('estimeringsgrafLegendSvingninger'),
            { fraBelop: Format.currency(this.getLastLower()), tilBelop: Format.currency(this.getLastUpper()) }
        );

        return [
            {
                text: `${this.props.texts.get('estimeringsgrafLegendForventet')} ${Format.currency(
                    this.getLastExpected()
                )}`,
                color: '#004F5A'
            },
            {
                text: svingninger,
                color: '#79DDFF'
            }
        ];
    };

    OmXaarKanDetteBli = aar => HelperFunctions.VariableInjection(this.props.texts.get('OmXaarKanDetteBli'), { aar });

    render = () => (
        <div className="estimerings-graf">
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                    data={this.adjustForStacking(this.props.values)}
                    margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
                >
                    <XAxis dataKey="key" interval="preserveStartEnd" minTickGap={50} tick={{ fontSize: 12 }} />
                    <YAxis
                        orientation="right"
                        type="number"
                        tickFormatter={Format.currency}
                        domain={[0, this.getLastUpper() * 1.2]}
                        tick={{ fontSize: 12 }}
                    />

                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#ffffff" stopOpacity={1} />
                            <stop offset="95%" stopColor="#ffffff" stopOpacity={1} />
                        </linearGradient>
                    </defs>

                    <CartesianGrid strokeDasharray="3 3" />

                    <Area
                        type="monotone"
                        dataKey="lower"
                        stackId="1"
                        fillOpacity={0.6}
                        stroke="transparent"
                        fill="transparent"
                    />

                    <Area type="monotone" dataKey="upper" stackId="1" stroke="transparent" fill="#ffffff" />

                    <Area
                        type="monotone"
                        dataKey="expected"
                        stackId="2"
                        stroke="#004F5A"
                        strokeWidth={5}
                        fill="transparent"
                    />

                    <ReferenceDot
                        x={this.getValuesSize()}
                        y={this.getLastExpected()}
                        r={9}
                        fill="#004F5A"
                        stroke="#004F5A"
                        alwaysShow
                    />
                    <ReferenceDot
                        x={this.getValuesSize()}
                        y={this.getLastUpper()}
                        r={9}
                        fill="#79DDFF"
                        stroke="#79DDFF"
                        alwaysShow
                    />
                    <ReferenceDot
                        x={this.getValuesSize()}
                        y={this.getLastLower()}
                        r={9}
                        fill="#79DDFF"
                        stroke="#79DDFF"
                        alwaysShow
                    />
                </AreaChart>
            </ResponsiveContainer>
            <div className="estimerings-graf-info">
                <div>{this.OmXaarKanDetteBli(this.props.tidshorisont)}</div>
                <div className="total-sum">{Format.currency(this.getLastExpected())}</div>
                <div>
                    {this.props.texts.get('forventetAvkastning')}:{' '}
                    {Format.currency(this.getLastExpected() - this.props.innskudd)}
                </div>
                <div>
                    {this.props.texts.get('innskudd')}: {Format.currency(this.props.innskudd)}
                </div>
            </div>
            <DotLegend data={this.createDataForLegend()} />
        </div>
    );
}

export default EstimeringsGraf;
