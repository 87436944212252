import React from 'react';
import PropTypes from 'prop-types';
import LinkCard from '../link-card';
import Format from '../format-functions';
import ColorFunctions from '../color-functions';

export const DefaultFond = props => (
    <div className="card-content-table">
        <div className="card-content-table-cell">
            <div className="title">{props.fond.navn}</div>
            <div className="urealisert-gevinst">
                <span className="tekst">{props.texts.get('urealisertGevinst')}</span>
                <span className="verdi"> {Format.currency(props.fond.urealisertGevinst)}</span>
            </div>
        </div>
        <div className="card-content-table-cell">
            <div className="total-verdi">
                <div className="tekst">{props.texts.get('verdi')} </div>
                <div className="verdi">{Format.currency(props.fond.totalVerdi)}</div>
            </div>
        </div>
    </div>
);

DefaultFond.propTypes = {
    fond: PropTypes.object,
    texts: PropTypes.object
};

export const AskFond = props => (
    <div className="card-content-table">
        <div className="card-content-table-cell">
            <div className="title">{props.fond.navn}</div>
            <div className="urealisert-gevinst">
                <span className="tekst">{props.texts.get('manedligSparing')}</span>
                <span className="verdi">
                    {' '}
                    {Format.currency(props.fond.maanedligSparing ? props.fond.maanedligSparing : 0)}
                </span>
            </div>
        </div>
        <div className="card-content-table-cell">
            <div className="total-verdi">
                <div className="tekst">{props.texts.get('verdi')} </div>
                <div className="verdi">{Format.currency(props.fond.totalVerdi)}</div>
            </div>
        </div>
    </div>
);

AskFond.propTypes = {
    fond: PropTypes.object,
    texts: PropTypes.object
};

const FondsUtlisting = props => (
    <div>
        {props.fonds.map((fond, index) => (
            <LinkCard
                key={fond.isin}
                isDisabled={props.isDisponert}
                link={`fond/${props.portefoljeId}/${fond.isin}`}
                gradient={ColorFunctions.hentGradient(props.startIndex + index)}
            >
                {props.portefoljeType === 'ASK' ? (
                    <AskFond fond={fond} texts={props.texts} />
                ) : (
                    <DefaultFond fond={fond} texts={props.texts} />
                )}
            </LinkCard>
        ))}
    </div>
);

FondsUtlisting.propTypes = {
    portefoljeId: PropTypes.string.isRequired,
    portefoljeType: PropTypes.string.isRequired,
    fonds: PropTypes.arrayOf(PropTypes.object),
    texts: PropTypes.object,
    startIndex: PropTypes.number,
    isDisponert: PropTypes.bool
};

export default FondsUtlisting;
