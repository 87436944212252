import { VIS_SMARTSPAR_POPUP, SKJUL_SMARTSPAR_POPUP } from '../actions/modals';

const initialState = {
    visSmartsparPopup: false,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case VIS_SMARTSPAR_POPUP:
            return {
                ...state,
                visSmartsparPopup: action.data,
            };
        case SKJUL_SMARTSPAR_POPUP:
            return {
                ...state,
                visSmartsparPopup: false,
            };
        default:
            return state;
    }
};
