/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import PropTypes from 'prop-types';
import HelperFunctions from '../../components/helper-functions';
import Format from '../../components/format-functions';
import { settSideTittel } from '../../actions/header';
import Feilmelding from '../../components/feilmelding';
import DateFunctions from '../../components/date-functions';
import visRiktigTekstBasertPaaOrdreTyper from './fond-common';

require('../../styles/ventendeTransaksjoner.scss');

class VentendeTransaksjoner extends Component {
    static propTypes = {
        texts: PropTypes.object,
        errors: PropTypes.object,
        settSideTittel: PropTypes.func,
        ventendeTransaksjoner: PropTypes.array,
    };

    settSideTittel = () => this.props.settSideTittel(this.props.texts.get('ventendeTransaksjoner'), 'test');

    transaksjonUtlisting = (ventendeTransaksjoner) => (
        <div>
            {ventendeTransaksjoner.map((transaksjon) => (
                <div
                    key={`ventendeTransaksjoner-${DateFunctions.skrivUtDatoFraString(
                        transaksjon.transaksjonsdato
                    )}`}
                    className="ventende-transaksjon"
                >
                    <div>{this.props.texts.get(transaksjon.type)}</div>
                    <div>{Format.currency(transaksjon.beloep)}</div>
                </div>
            ))}
        </div>
    );

    sjekkForServerFeil = (errors) => errors.HENT_VENTENDE_ORDRE;

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.props.ventendeTransaksjoner) {
            const antall = this.props.ventendeTransaksjoner.length;
            const headerTekst = visRiktigTekstBasertPaaOrdreTyper(this.props.ventendeTransaksjoner, this.props.texts);

            if (antall) {
                return (
                    <div className="ventendeTransaksjoner">
                        <div className="ventende-transaksjon-header">{headerTekst}</div>
                        <div className="ventende-transaksjon-ingress">
                            {HelperFunctions.VariableInjection(this.props.texts.get('ventendeTransaksjonerIngress'), {
                                antall,
                            })}
                        </div>
                        {this.transaksjonUtlisting(this.props.ventendeTransaksjoner)}
                    </div>
                );
            }

            return <div className="ingen-transaksjoner">{this.props.texts.get('ingenTransaksjoner')}</div>;
        }

        return <Loading title={this.props.texts.get('lasterVentendeTransaksjoner')} />;
    }
}

export default connect(
    (state, ownProps) => {
        const { portefoljeId, fondIsin } = ownProps.match.params;

        return {
            ventendeTransaksjoner: state.Ordre.ventendeTransaksjoner[`${portefoljeId}${fondIsin}`],
            ...state.Feilmeldinger,
            ...state.Texts,
            ...ownProps,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel, undertittel) => {
                dispatch(settSideTittel(sideTittel, undertittel));
            },
        };
    }
)(VentendeTransaksjoner);
