import React, { MouseEvent, useState } from 'react';
import { WarningLabel } from '@eika/label';
import { generateId } from '../util/Functions';
import './CardExpander.scss';

export interface CardExpanderProps {
    title: string;
    static?: boolean;
    riskProfile?: string | string[];
    children?: JSX.Element | JSX.Element[];
}

interface State {
    id: string;
    open: boolean;
}

const toggleExpander = (state: State, setState: (s: State) => void, e: MouseEvent): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }
    setState({ ...state, open: !state.open });
};

export default (props: CardExpanderProps): JSX.Element => {
    const [state, setState] = useState<State>({
        id: generateId('card-expander'),
        open: !!props.static,
    });

    return (
        <div
            className={`card-expander-wrapper -${state.open ? 'open' : 'close'} -${
                props.static ? 'static' : 'expander'
            }`}
        >
            <section className="header-section" aria-labelledby={`${state.id}-title`}>
                <div className="header-section-content">
                    <div>
                        <h2 id={`${state.id}-title`}>{props.title || ''}</h2>
                        {!!props.riskProfile && (
                            <div className="paragraph-small -only-top -row">
                                {(Array.isArray(props.riskProfile) ? props.riskProfile : [props.riskProfile]).map(
                                    (label) => (
                                        <WarningLabel className="risk-profile-tag" text={label} key={label} />
                                    )
                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        <a
                            href="#"
                            role="button"
                            className="expander-btn"
                            onClick={(e: MouseEvent) => {
                                toggleExpander(state, setState, e);
                            }}
                        >
                            <span className="aria-visible">{state.open ? 'Lukke detaljer' : 'Åpne detaljer'}</span>
                        </a>
                    </div>
                </div>
            </section>
            {state.open && <>{props.children}</>}
        </div>
    );
};
