// @ts-nocheck // kiet fix it later
import React, { useEffect } from 'react';
import { openSmartspart, getURLquery } from '../../common/util/Functions';

const init = (): void => {
    const base = /localhost/i.test(window.location.href) ? '' :  'https://eika.no/smartspar';
    const query = getURLquery();
    // const query = getURLquery('test?action=settInnPenger');
    // const query = getURLquery('test?action=visPensjonssparing');
    // const query = getURLquery('test?action=visPensjonskalkulator');
    // const query = getURLquery('test?action=opprettNyttSparemaal');    
    // const query = getURLquery('test?action=settInnPenger&isin=1234');
    // const query = getURLquery('test?action=settInnPenger&portfolioBaseCode=124');

    if ( query.action ) {
        const param = ['action', 'portfolioBaseCode', 'isin', 'category'].map( (key: string) => {
            return query[key] ? `${key}=${query[key]}` : '';
        }).filter((v: string) => !!v).join('&');

        openSmartspart('', param, base );
    } else if ( base ) {
        window.location.href = base;
    }
};

export default (): JSX.Element => {
    useEffect(() => { init(); }, []);
    return <div className="smartspar-linking-wrapper"/>;
};