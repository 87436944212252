import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XAxis, YAxis, CartesianGrid, LineChart, Line, ResponsiveContainer, ReferenceDot } from 'recharts';
import Format from '../format-functions';

require('../../styles/progresjons-graf.scss');

class ProgresjonsGraf extends Component {
    static propTypes = {
        texts: PropTypes.object,
        values: PropTypes.array,
        yAxisMaxValue: PropTypes.number,
        goal: PropTypes.number,
        avkastning: PropTypes.number,
        saldo: PropTypes.number,
        avkastningsProsent: PropTypes.string,
        name: PropTypes.string,
        varighet: PropTypes.number
    };

    getLastActualIndex = () => {
        let lastIndex = 0;

        this.props.values.some((item, count) => {
            if (!item.actual) {
                lastIndex = count - 1;
            }

            return !item.actual;
        });
        return lastIndex;
    };

    // Hacket til for å ikke herpe løsningen når prognose feiler
    getLastActual = () => {
        if (this.props.values[this.getLastActualIndex()]) {
            return this.props.values[this.getLastActualIndex()].actual;
        }
        return 0;
    };

    getLastEstimated = () => {
        if (this.props.values.length > 0) {
            return this.props.values[this.props.values.length - 1].estimated;
        }
        return 0;
    };

    getLastIndex = () => {
        if (this.props.values.length > 0) {
            return this.props.values.length - 1;
        }
        return 0;
    };

    render() {
        return (
            <div className="progresjons-graf">
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={this.props.values} margin={{ top: 5, right: 5, left: 0, bottom: 5 }}>
                        <XAxis dataKey="key" interval="preserveStartEnd" minTickGap={50} tick={{ fontSize: 12 }} />
                        <YAxis
                            orientation="right"
                            type="number"
                            tickFormatter={Format.currency}
                            domain={[0, this.props.yAxisMaxValue]}
                            tick={{ fontSize: 12 }}
                        />

                        <CartesianGrid strokeDasharray="3 3" />

                        <Line type="monotone" dataKey="estimated" dot={false} stroke="#004F5A" />

                        <Line type="monotone" dataKey="actual" dot={false} stroke="#004F5A" strokeWidth={5} />

                        <ReferenceDot
                            x={this.getLastActualIndex()}
                            y={this.getLastActual()}
                            r={9}
                            fill="#004F5A"
                            alwaysShow
                        />
                        <ReferenceDot
                            x={this.getLastIndex()}
                            y={this.getLastEstimated()}
                            r={9}
                            stroke="#004F5A"
                            fill="#f2f1ef"
                            alwaysShow
                        />
                    </LineChart>
                </ResponsiveContainer>
                <div className="progresjons-graf-info">
                    <div>
                        <div className="progresjons-graf-navn">{this.props.name}</div>
                        <div>{this.props.texts.get('sparemaalVarighet').replace('{aar}', this.props.varighet)}</div>
                        <div>
                            {this.props.texts.get('sparemaalMaal')} {Format.currency(this.props.goal)}
                        </div>
                        <div>
                            {this.props.avkastningsProsent} ({Format.currency(this.props.avkastning)})
                        </div>
                    </div>
                    <div className="progresjons-graf-tall">{Format.currency(this.props.saldo)}</div>
                </div>
            </div>
        );
    }
}

export default ProgresjonsGraf;
