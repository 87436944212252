import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DisabledButton = props => (
    <div className="action-button">
        <div disabled="disabled" className={`btn btn-primary ${props.classes}`}>
            {props.text}
        </div>
    </div>
);

DisabledButton.propTypes = {
    text: PropTypes.string.isRequired,
    classes: PropTypes.string.isRequired
};

const NavigationButton = props => (
    <Link to={`/${props.link}`} className="action-button">
        <div className={`btn btn-primary ${props.classes}`}>{props.text}</div>
    </Link>
);

NavigationButton.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    classes: PropTypes.string.isRequired
};

const FuncButton = props => (
    <div className="action-button">
        <button type="button" className={`btn btn-call-to-action ${props.classes}`} onClick={props.action}>
            {props.text}
        </button>
    </div>
);

FuncButton.propTypes = {
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    classes: PropTypes.string.isRequired
};

class ActionButton extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        secondary: PropTypes.bool,
        arrow: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            classes: `${props.secondary ? 'secondary' : ''} ${props.arrow ? 'btn-call-to-action' : 'btn-primary'}`
        };
    }

    render() {
        if (this.props.disabled) {
            return <DisabledButton text={this.props.text} classes={this.state.classes} />;
        }

        if (typeof this.props.action === 'function') {
            return <FuncButton text={this.props.text} classes={this.state.classes} action={this.props.action} />;
        }

        if (typeof this.props.action === 'string') {
            return (
                <NavigationButton
                    text={this.props.text}
                    secondary={this.state.secondary}
                    link={this.props.action}
                    classes={this.state.classes}
                />
            );
        }

        return null;
    }
}

ActionButton.defaultProps = {
    disabled: false,
    secondary: false,
    arrow: false
};

export default ActionButton;
