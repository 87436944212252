import { createRegexp, formatTrackLinkName } from '../common/util/Functions';
import Dictionary from '../static/data/Dictionary';

const LOAD_TEXTS = 'LOAD_TEXTS';

const initialState = {
    setText: textsInput => {
        const texts = textsInput;

        const toLowerCase = (key='') => {
            return key && key.toString().toLocaleLowerCase();
        };

        const has = (key='') => {
            return !!texts[toLowerCase(key)];
        };

        const get = (key='', values={}, isTrackLinkname=false) => {
            let output = (texts || {})[key] || (Dictionary.norsk || {})[key] || key;
            for ( const variable in values) {
                const reg = createRegexp(`{${variable}}`, 1, 1 );
                output = output.replace( reg, values[variable] );
            }
            return isTrackLinkname ? formatTrackLinkName(output) : output.trim();
        };
        return { has, get};
    }
};

const changeKeyToLowerCase = (newTexts, key, texts) => {
    if (newTexts[key.toLowerCase()]) {
        // eslint-disable-next-line no-console
        console.warn(`Duplicate key: ${key}`);
    }

    newTexts[key.toLowerCase()] = texts[key];
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_TEXTS: {
            let texts = {};
            if (action.data) {
                if (action.data.data) {
                    if (action.data.data.text) {
                        texts = action.data.data.text;
                    }
                }
            }
            return {
                ...state,
                texts: state.setText(
                    Object.keys(texts).reduce(
                        // eslint-disable-next-line no-sequences
                        (newTexts, key) => (changeKeyToLowerCase(newTexts, key, texts), newTexts),
                        {}
                    )
                )
            };
        }
        default:
            return state;
    }
};
