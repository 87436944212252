import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Epost from '../../common/epost';
import Format from '../../format-functions';
import Checkbox from '../../checkbox';
import HelperFunctions from '../../helper-functions';
import FeeMessage from '../../FeeMessage';
import ForeignCurrencyMessage from '../../ForeignCurrencyMessage';

class KjopFondOppsummering extends Component {
    static propTypes = {
        texts: PropTypes.object,
        plassering: PropTypes.string,
        kjopsOrdre: PropTypes.object,
        kjopFond: PropTypes.func,
        visEpostFelt: PropTypes.bool,
        epost: PropTypes.string,
        settEpost: PropTypes.func,
        alder: PropTypes.number,
        fondProduktarkUrl: PropTypes.string,
        fond: PropTypes.object,
    };

    static defaultProps = {
        alder: undefined
    };

    constructor(props) {
        super(props);

        this.state = {
            harForstaattNoekkelInformasjon: false,
            visHjelpetekst: false // Hack to get around that the submit button is
            // greyed out until values are set so there will never be any help-texts
        };
    }

    mndSparingHtml = kjopsOrdre => {
        if (kjopsOrdre.spareBelop) {
            return (
                <div className="mnd-sparing">
                    <div className="mnd-innskudd">
                        <span>{this.props.texts.get('manedligSparing')}:&nbsp;</span>
                        <span className="bold">{Format.currency(kjopsOrdre.spareBelop)}</span>
                    </div>
                    <div className="konto-nr">
                        <span>{this.props.texts.get('kontoNummer')}:&nbsp;</span>
                        <span className="bold">{kjopsOrdre.sparebelopKonto}</span>
                    </div>
                    <div className="trekkdato">
                        <span>{this.props.texts.get('fastTrekkDatoManedligSparing')}:&nbsp;</span>
                        <span className="bold">{kjopsOrdre.trekkDag}.</span>
                    </div>
                    <div className="ais-holder">
                        <span>Automatisk øking:&nbsp;</span>
                        <span className="bold">{HelperFunctions.getAISsummaryText(kjopsOrdre)}</span>
                    </div>                    
                </div>
            );
        }

        return null;
    };

    visKontoNummerForEngangsInnskudd = innskuddsKonto => {
        if (innskuddsKonto) {
            return (
                <div className="konto-nr">
                    <span>{this.props.texts.get('kontoNummer')}:&nbsp;</span>
                    <span className="bold">{innskuddsKonto}</span>
                </div>
            );
        }

        return undefined;
    };

    engangsInnskuddHtml = kjopsOrdre => {
        if (kjopsOrdre.engangsBelop) {
            return (
                <div className="engangskjop">
                    {this.visKontoNummerForEngangsInnskudd(kjopsOrdre.innskuddsKonto)}
                    <div className="innskudd">
                        <span>{this.props.texts.get('innskudd')}:&nbsp;</span>
                        <span className="bold">{Format.currency(kjopsOrdre.engangsBelop)}</span>
                    </div>
                </div>
            );
        }

        return null;
    };

    oppdaterCheckbox = (checked, name) =>
        this.setState({
            [name]: checked
        });

    isDisabled = () => {
        const { harForstaattNoekkelInformasjon } = this.state;
        const { epost, alder } = this.props;
        return !epost || !alder || !harForstaattNoekkelInformasjon || alder < 18;
    };

    aktiverBekreftOgBestillKnapp = () => {
        const { kjopFond, texts } = this.props;
        return (
            <button type="button" className="btn btn-call-to-action" onClick={kjopFond} disabled={this.isDisabled()}>
                {texts.get('bekreftOgBestill')}
            </button>
        );
    };

    render = () => {
        const { visHjelpetekst } = this.state;
        const { alder, texts, kjopsOrdre, plassering, visEpostFelt, settEpost, fondProduktarkUrl } = this.props;

        if (!alder || alder < 18) {
            return (
                <div className="ordre-oppsummering">
                    <div className="fond-og-portefolje">
                        <div className="kjopt-fond">
                            <span>{texts.get('under18aar')}</span>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="ordre-oppsummering">
                <div className="fond-og-portefolje">
                    <div className="kjopt-fond">
                        <span>{texts.get('kjoptFond')}:&nbsp;</span>
                        <span className="bold">{kjopsOrdre.fondsNavn}</span>
                    </div>
                    <div className="kjopt-fond">
                        <span>{texts.get('portefoljePlassering')}:&nbsp;</span>
                        <span className="bold">{plassering}</span>
                    </div>
                </div>
                {this.engangsInnskuddHtml(kjopsOrdre)}
                {this.mndSparingHtml(kjopsOrdre)}

                { !!fondProduktarkUrl && <div className="link-wrapper">
                    <a href={fondProduktarkUrl} target="_blank">
                        {`Nøkkelinformasjonen for ${kjopsOrdre.fondsNavn}`}
                    </a>
                </div> }

                <FeeMessage {...this.props} />
                <ForeignCurrencyMessage {...this.props} />

                <Checkbox
                    name="harForstaattNoekkelInformasjon"
                    id="harForstaattNoekkelInformasjon"
                    onChange={this.oppdaterCheckbox}
                ><span>{texts.get('harForstattNokkelInformasjon')}</span></Checkbox>

                <Epost
                    label={texts.get('epost')}
                    show={visEpostFelt}
                    onChange={settEpost}
                    showValidationMessage={visHjelpetekst}
                    validationMessage={texts.get('skrivEnGyldigEpostAdresse')}
                />

                <div className="footer">{this.aktiverBekreftOgBestillKnapp()}</div>
            </div>
        );
    };
}

export default KjopFondOppsummering;
