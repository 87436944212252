/** ****************************************************************************
 ***************************************************************************** */
export const getValidatedAISvalue = (aisValue, aisValueUnitPercent=false, sparebelop=0 ) => {
    const increaseIntervalAmout = [1, null];
    const text = `${aisValue}`.replace(/\s+/g, '').replace(/^0+/g, '').replace( /,/g, '.');
    const output = { 
        value: aisValueUnitPercent ? parseFloat( text ) : parseInt( text, 10 ),
        error: '',
    };

    if ( !output.value || isNaN(output.value) ) {
        output.error = 'AIS_VALUE_REQUIRED';
    } else if ( isNaN(output.value) ) {
        output.error = 'AIS_VALUE_INVALID';
        output.value = '';
    } else if ( !sparebelop && aisValueUnitPercent ){
        output.error = 'AIS_VALUE_MISSING_MONTHLY_AMOUNT';        
    } else {
        const amount = aisValueUnitPercent ? Math.floor(((sparebelop*output.value) /100)) : output.value;
        if ( increaseIntervalAmout[0] && increaseIntervalAmout[0] > amount ){
            output.error = 'AIS_VALUE_TOO_LOW';
            output.factor = aisValueUnitPercent ? 
                `${Math.ceil((((increaseIntervalAmout[0]/sparebelop)*10000))) / 100} %`: 
                `${increaseIntervalAmout[0]} kr`;

        } else if ( increaseIntervalAmout[1] && increaseIntervalAmout[1] > amount ){
            output.error = 'AIS_VALUE_TOO_HIGH';
        }
    }

    return output;
};

export const test = (text, everyWord=false ) => {
    let i = 0;
    return `${text || ''}`.replace(/(^|\s)\S/g, (t) => {
        return everyWord || i++ === 0 ? t.toUpperCase() : t;
    });
};


