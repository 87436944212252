import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import Modal from '../modal';
import Format from '../format-functions';
import HelperFunctions from '../helper-functions';
import { visAvtalegiroKnapp } from '../avtalegiro/avtalegiro-helper-functions';

const ENKEL_ORDRE = 'ENKEL_ORDRE';
const SPAREPLAN = 'SPAREPLAN';
const OPPRETT_PORTEFOLJE = 'OPPRETT_PORTEFOLJE';

require('../../styles/fonds-kvittering.scss');

class OrdreKvittering extends Component {
    static propTypes = {
        texts: PropTypes.object,
        kvitteringConfig: PropTypes.object,
        lukkKvittering: PropTypes.func,
        kvitteringsInnhold: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            kvitteringsInnhold: props.kvitteringsInnhold,
            kvitteringConfig: props.kvitteringConfig
        };
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        nextState.kvitteringsInnhold = nextProps.kvitteringsInnhold;
        nextState.kvitteringConfig = nextProps.kvitteringConfig;
    }

    showSuccessHeader = successTitle => (
        <div className="kvittering-topp ikon">
            <span className="kvittering-ikon ikon-usp-checkmark-36" />
            {successTitle}
        </div>
    );

    showCorrectHeader = (ordreResultater, successTitle) => {
        const success = ordreResultater.every(ordreResultat => ordreResultat.success);
        const failure = ordreResultater.every(ordreResultat => !ordreResultat.success);

        if (success) {
            return this.showSuccessHeader(successTitle);
        }

        if (failure) {
            return (
                <div className="kvittering-topp ikon">
                    <span className="kvittering-ikon ikon-utropstegn-36" />
                    {this.props.texts.get('noeGikkGalt')}
                </div>
            );
        }

        return <div className="kvittering-topp">{this.props.texts.get('bareDelerAvBestillingenGikkGjennom')}</div>;
    };

    visOrdreResultat = (oppsummering, ordreType, ordreResultater) => {
        const success = ordreResultater.every(ordreResultat => ordreResultat.success);
        const ordreResultat = ordreResultater.find(ordreResultat => ordreResultat.ordreType === ordreType);

        if (!ordreResultat) {
            return '';
        }

        if (success) {
            return oppsummering;
        }

        if (ordreResultat.success) {
            return (
                <div className="ordre-resultat">
                    <span className="ikon-usp-checkmark-24" />
                    <span className="ordre-melding">{this.props.texts.get(ordreResultat.melding)}</span>
                    {oppsummering}
                </div>
            );
        }

        return (
            <div className="ordre-resultat">
                <span className="ikon-utropstegn-24" />
                <span className="ordre-melding">{this.props.texts.get(ordreResultat.melding)}</span>
            </div>
        );
    };

    byttFondKvittering = innhold => {
        const oppsummering = (
            <div>
                <div className="rad">
                    {this.props.texts.get('byttetFra')}: <span className="tall"> {innhold.byttetFra}</span>
                </div>
                <div className="rad">
                    {this.props.texts.get('verdi')}:{' '}
                    <span className="tall">{Format.currency(innhold.engangsBelop)}</span>
                </div>
                <div className="rad">
                    {this.props.texts.get('byttetTil')}: <span className="tall">{innhold.byttetTil}</span>
                </div>
                <div className="rad">
                    {this.props.texts.get('belop')}:{' '}
                    <span className="tall">{Format.currency(innhold.engangsBelop)}</span>
                </div>
                <div className="rad">
                    Spareavtale, {innhold.byttetFra}:
                    <span className="tall">
                        &nbsp;
                        {innhold.flyttSpareavtale
                            ? this.props.texts.get('flyttet')
                            : this.props.texts.get('ikkeFlyttet')}
                    </span>
                </div>
            </div>
        );

        return (
            <div>
                {this.showCorrectHeader(innhold.ordreResultater, this.props.texts.get('byttFondKvitteringTittel'))}

                <div className="kvittering-innhold">
                    {this.visOrdreResultat(oppsummering, ENKEL_ORDRE, innhold.ordreResultater)}
                </div>
            </div>
        );
    };

    selgFondKvittering = innhold => (
        <div>
            {this.showSuccessHeader(this.props.texts.get('selgFondKvitteringTittel'))}
            <div className="kvittering-innhold">
                <div>
                    <div className="rad">
                        {this.props.texts.get('solgtFond')}:<span className="tall">&nbsp;{innhold.fondNavn}</span>
                    </div>
                    <div className="rad">
                        {this.props.texts.get('tilKonto')}:<span className="tall">{innhold.kontonummer}</span>
                    </div>
                    <div className="rad">
                        {this.props.texts.get('verdi')}:
                        <span className="tall">&nbsp;{Format.currency(innhold.engangsBelop)}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    endreMndSparingKvittering = (innhold, kvitteringsConfig) => {
        const oppsummering = (
            <div>
                <div className="kvittering-innhold">
                    <div className="rad">
                        {this.props.texts.get('nyttMndSpareBelop')}:
                        <span className="tall">{Format.currency(innhold.spareBelop)}</span>
                    </div>
                    <div className="rad">
                        {this.props.texts.get('kontoForTrekk')}:<span className="tall">{innhold.kontonummer}</span>
                    </div>
                    <div className="rad">
                        {this.props.texts.get('forsteTrekkDato')}:<span className="tall">{innhold.trekkDag}</span>
                    </div>
                </div>
                <div className="kvittering-bunn">
                    {visAvtalegiroKnapp(
                        this.props.texts.get('opprettAvtaleGiro'),
                        kvitteringsConfig.avtalegiroUrl,
                        kvitteringsConfig.avtalegiroObject
                    )}
                </div>
            </div>
        );

        const avsluttSpareavtaleOppsummering = (
            <div className="kvittering-innhold">{this.props.texts.get('spareAvtaleAvsluttet')}</div>
        );

        return (
            <div>
                {this.showCorrectHeader(innhold.ordreResultater, innhold.kvitteringsHeader)}

                <div className="kvittering-innhold">
                    {this.visOrdreResultat(
                        innhold.avsluttSpareavtale ? avsluttSpareavtaleOppsummering : oppsummering,
                        SPAREPLAN,
                        innhold.ordreResultater
                    )}
                </div>
            </div>
        );
    };

    nyttInnskuddKvittering = innhold => {
        const oppsummering = (
            <div>
                <div className="rad">
                    {this.props.texts.get('nyttInnskuddI')} {innhold.fond}:
                    <span className="tall"> {Format.currency(innhold.engangsBelop)}</span>
                </div>
                <div className="rad">
                    {this.props.texts.get('kontoForTrekk')}: <span className="tall">{innhold.kontonummer}</span>
                </div>
            </div>
        );

        return (
            <div>
                {this.showCorrectHeader(innhold.ordreResultater, this.props.texts.get('nyttInnskuddKvitteringsTittel'))}

                <div className="kvittering-innhold">
                    {this.visOrdreResultat(oppsummering, ENKEL_ORDRE, innhold.ordreResultater)}
                </div>
            </div>
        );
    };

    kjopFondKvittering = innhold => {
        const visKontoNummerForEngangsInnskudd = innskuddsKonto => {
            if (innskuddsKonto) {
                return (
                    <div className="konto-nr">
                        <span>{this.props.texts.get('kontoNummer')}:&nbsp;</span>
                        <span className="bold">{innskuddsKonto}</span>
                    </div>
                );
            }

            return undefined;
        };

        const oppsummeringEnkeltOrdre = (
            <div className="engangskjop">
                <div className="kjopt-fond">
                    <span>{this.props.texts.get('kjoptFond')}:&nbsp;</span>
                    <span className="bold">{innhold.kjopsOrdre.fondsNavn}</span>
                </div>
                <div className="innskudd">
                    <span>{this.props.texts.get('innskudd')}:&nbsp;</span>
                    <span className="bold">{Format.currency(innhold.kjopsOrdre.engangsBelop)}</span>
                </div>
                {visKontoNummerForEngangsInnskudd(innhold.kjopsOrdre.innskuddsKonto)}
            </div>
        );

        const oppsummeringSpareAvtale = (
            <div className="mnd-sparing">
                <div className="mnd-innskudd">
                    <span>{this.props.texts.get('manedligSparing')}:&nbsp;</span>
                    <span className="bold">{Format.currency(innhold.kjopsOrdre.spareBelop)}</span>
                </div>
                <div className="konto-nr">
                    <span>{this.props.texts.get('kontoNummer')}:&nbsp;</span>
                    <span className="bold">{innhold.kjopsOrdre.sparebelopKonto}</span>
                </div>
                <div className="trekkdato">
                    <span>{this.props.texts.get('fastTrekkDatoManedligSparing')}:&nbsp;</span>
                    <span className="bold">{innhold.kjopsOrdre.trekkDag}.</span>
                </div>
            </div>
        );

        return (
            <div>
                {this.showCorrectHeader(
                    innhold.ordreResultater,
                    `${this.props.texts.get('duHarKjopt')} ${innhold.kjopsOrdre.fondsNavn}`
                )}

                <div className="kvittering-innhold">
                    {this.visOrdreResultat(oppsummeringEnkeltOrdre, ENKEL_ORDRE, innhold.ordreResultater)}
                    {this.visOrdreResultat(oppsummeringSpareAvtale, SPAREPLAN, innhold.ordreResultater)}
                </div>
                <div className="kvittering-bunn">
                    {visAvtalegiroKnapp(
                        this.props.texts.get('opprettAvtaleGiro'),
                        this.props.kvitteringConfig.avtalegiroUrl,
                        this.props.kvitteringConfig.avtalegiroObject
                    )}
                </div>
            </div>
        );
    };

    aksjesparekontoKvittering = innhold => {
        const oppsummering = (
            <div className="rad">
                {this.props.texts.get('gratulererMedNyAksjesparekonto')}
                <span className="bold">{this.props.texts.get('minAksjeOgFondsparing')}</span>
            </div>
        );

        return (
            <div>
                {this.showCorrectHeader(innhold.ordreResultater, this.props.texts.get('aksjesparekontoOpprettet'))}

                <div className="kvittering-innhold">
                    {this.visOrdreResultat(oppsummering, SPAREPLAN, innhold.ordreResultater)}
                </div>
            </div>
        );
    };

    uttakKvittering = innhold => {
        const uttakFra = HelperFunctions.VariableInjection(this.props.texts.get('uttakFra'), {
            portefolje: { value: innhold.portefoljeNavn, class: 'tall' }
        });

        const oppsummering = (
            <div>
                <div className="rad">{uttakFra}</div>
                <div className="rad">
                    {this.props.texts.get('tilKonto')}:<span className="tall">&nbsp;{innhold.kontonummer}</span>
                </div>
                <div className="rad">
                    {this.props.texts.get('verdi')}:
                    <span className="tall">&nbsp;{Format.currency(innhold.engangsBelop)}</span>
                </div>
            </div>
        );

        return (
            <div>
                {this.showCorrectHeader(innhold.ordreResultater, this.props.texts.get('pengerTattUt'))}

                <div className="kvittering-innhold">
                    {this.visOrdreResultat(oppsummering, ENKEL_ORDRE, innhold.ordreResultater)}
                </div>
            </div>
        );
    };

    opprettIPSKvittering = innhold => {
        const oppsummering = (
            <div>
                <div>{this.props.texts.get('ipsOpprettetKvitteringsTekst')}</div>
                <div>{this.props.texts.get('avtalenFinnerDuPaMinSpareProfil')}</div>
            </div>
        );

        return (
            <div>
                {this.showCorrectHeader(innhold.ordreResultater, this.props.texts.get('IPSOpprettet'))}

                <div className="kvittering-innhold">
                    {this.visOrdreResultat(oppsummering, OPPRETT_PORTEFOLJE, innhold.ordreResultater)}
                </div>
            </div>
        );
    };

    feilmeldingsKvittering = innhold => {
        const melding = innhold.melding || this.props.texts.get('kanIkkeFullforeOperasjonenNaa');

        return (
            <div>
                <div className="kvittering-topp ikon">
                    <span className="kvittering-ikon ikon-utropstegn-36" />
                    En feil oppstod!
                </div>
                <div className="kvittering-innhold">
                    <div className="rad">{melding}</div>
                </div>
            </div>
        );
    };

    listUtNomineeFond = nomineeNavn => {
        return nomineeNavn.map((nominee, index) => {
            const key = nominee + index;
            return (
                <div key={key} className="nominee-navn">
                    {nominee}
                </div>
            );
        });
    };

    nomineeKvittering = innhold => {
        return (
            <div>
                <div className="kvittering-topp ikon">
                    <span className="kvittering-ikon ikon-usp-checkmark-36" />
                    {this.props.texts.get('fondetBlirFlyttetIlopetAvFaaDager')}
                </div>
                <div className="kvittering-innhold">
                    <div className="rad">{this.props.texts.get('duHarBestiltFlyttingAv')}</div>
                    <div className="nominee-liste">{this.listUtNomineeFond(innhold.nomineeValgNavn)}</div>
                    <div className="rad">{this.props.texts.get('detkanTaOpptilXDagerForDeErFlyttet')}</div>
                    <div className="rad">{this.props.texts.get('FondeneVilBliLagtI')}</div>
                </div>
            </div>
        );
    };

    velgKvitteringsType = (kvitteringsInnhold, kvitteringsConfig) => {
        const ordre = (kvitteringsInnhold.ordreResultater || [])[0] || {};
        if ( !ordre.success && (kvitteringsInnhold.type || '').match(/(endreMndSparing|nyttEngangsBelop|kjopFond|selgFond)/i) ) {
            return (
                <div>
                    <div className="kvittering-topp ikon">
                        <span className="kvittering-ikon ikon-utropstegn-36" />
                        {this.props.texts.get(ordre.melding)}                        
                    </div>
                    <div className="kvittering-innhold" aria-live="polite">
                        {this.props.texts.get(`${ordre.melding}_MELDING`)}
                    </div>
                </div>
            );            
        }

        switch (kvitteringsInnhold.type) {
            case 'byttFond':
                return this.byttFondKvittering(kvitteringsInnhold);
            case 'feilmelding':
                return this.feilmeldingsKvittering(kvitteringsInnhold);
            case 'selgFond':
                return this.selgFondKvittering(kvitteringsInnhold);
            case 'endreMndSparing':
                return this.endreMndSparingKvittering(kvitteringsInnhold, kvitteringsConfig);
            case 'nyttEngangsBelop':
                return this.nyttInnskuddKvittering(kvitteringsInnhold);
            case 'kjopFond':
                return this.kjopFondKvittering(kvitteringsInnhold, kvitteringsConfig);
            case 'opprettetAksjesparekonto':
                return this.aksjesparekontoKvittering(kvitteringsInnhold);
            case 'nominee':
                return this.nomineeKvittering(kvitteringsInnhold);
            case 'uttak':
                return this.uttakKvittering(kvitteringsInnhold);
            case 'opprettIPS':
                return this.opprettIPSKvittering(kvitteringsInnhold);
            default:
                return null;
        }
    };

    skjulLukkKnapp = skjul => {
        if (!skjul) {
            return (
                <div className="kvittering-bunn">
                    <button type="button" className="btn btn-primary" onClick={this.props.lukkKvittering}>
                        {this.props.texts.get('lukk')}
                    </button>
                </div>
            );
        }

        return '';
    };

    genererKvittering = (kvitteringsInnhold, kvitteringsConfig) => {
        return (
            <div className="kvitterings-modal">
                {this.velgKvitteringsType(kvitteringsInnhold, kvitteringsConfig)}
                {this.skjulLukkKnapp(kvitteringsConfig.skjulLukkKnapp)}
            </div>
        );
    };

    kvitteringLaster = lasteTekst => {
        return <Loading title={lasteTekst} />;
    };

    kvittering = () => {
        if (this.state.kvitteringConfig.laster) {
            return this.kvitteringLaster(this.state.kvitteringsInnhold.lasteTekst);
        }
        return this.genererKvittering(this.state.kvitteringsInnhold, this.state.kvitteringConfig);
    };

    render() {
        if (this.state.kvitteringConfig.vis) {
            return <Modal visModal={this.state.kvitteringConfig.vis}>{this.kvittering()}</Modal>;
        }
        return null;
    }
}

export default OrdreKvittering;
