// @ts-nocheck // kiet fix it later
import React from 'react';

import './style/EKFmarkedsforingVarsel.scss';

const EKFmarkedsforingVarsel = ({invertedTop}) => {
    return <div className={`ekf-markedsforing-varsel-wrapper -${invertedTop ? 'inverted-top' : 'basic'}`}>
        Innholdet på denne siden er markedsføring
    </div>;
};

export default EKFmarkedsforingVarsel;
