import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExpandableCard from '../expandable-card';

class StandaloneExpandableCard extends Component {
    static propTypes = {
        children: PropTypes.object.isRequired,
        header: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            // eslint-disable-next-line react/no-unused-state
            onExpand: this.onExpand
        };
    }

    onExpand = () => {
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            expanded: !this.state.expanded
        });
    };

    render() {
        return (
            <ExpandableCard expandItem={this.state} header={this.props.header}>
                {this.props.children}
            </ExpandableCard>
        );
    }
}

export default StandaloneExpandableCard;
