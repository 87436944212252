import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, LinkType } from '@eika/link';
import Message from '../Message/Message';
import Grid from '../Grid/Grid';
import { HeaderLink } from '../share/ShareComponents';
import { formatAmount, getPageAppending, getURLquery } from '../util/Functions';
import { getContactUsUrl } from '../util/BusinessFunctions';
import Constant from '../../static/data/Constant';

import './StopView.scss';
import { RouterConfig } from '../../app/Router';

export default (
    props: RouteComponentProps<{
        type?: string;
        title?: string;
        amount?: string;
        note?: string;
    }> &
        RouterConfig
): JSX.Element => {
    const { match, appTexts, page, header, length } = props;
    const { type, title, amount, note } = match.params || {};
    const query = getURLquery();
    const [appending] = useState<string>(getPageAppending(props));

    return (
        <section aria-label="Stopp handling" className="stop-view-wrapper sgw-content">
            {!!header && (
                <Grid
                    namespace="sparing-top"
                    list={[
                        {
                            layout: 'twelve',
                            element: <HeaderLink text={header} length={length} />,
                        },
                    ]}
                />
            )}

            {!!title && (
                <Grid
                    namespace="sparing-header"
                    list={[
                        {
                            layout: 'twelve',
                            element: <h1>{title}</h1>,
                        },
                    ]}
                />
            )}

            <div className={`stop-view-content ${appending || 'none-appending'}`}>
                <Grid
                    namespace="sparing-content"
                    list={[
                        {
                            layout: 'six',
                            element: (
                                <div className="max-width-600 -center">
                                    {page === 'offentligsektor' && (
                                        <div className="public-sector-info">
                                            <p className="h2">{appTexts.get('publicSectorPension')}</p>
                                            <p>{appTexts.get('zeroPensionFromPublicSectorMessage')}</p>
                                        </div>
                                    )}

                                    {page === 'pensjon' && (
                                        <div className="pension-info">
                                            <div className="h2">{`${formatAmount(amount, true)} kr`}</div>
                                            {!!type && <div className="type">{type}</div>}
                                            {!!note && note !== '-' && <div className="note">{note}</div>}
                                        </div>
                                    )}

                                    {type === 'in-order' && (
                                        <Message
                                            polite
                                            type="error-hand"
                                            title={appTexts.get('ordreUttakStoppTitle')}
                                            text={appTexts.get('ordreUttakStoppText')}
                                        />
                                    )}
                                    {type === 'no-value' && (
                                        <Message polite type="error-hand" text={appTexts.get('ordreUttakIngenVerdi')} />
                                    )}

                                    {type === 'ips-max-saving' && (
                                        <Message
                                            polite
                                            type="error-hand"
                                            title={appTexts.get('ipsStoppFullTitle')}
                                            text={appTexts.get('ipsStoppFullText', {
                                                maksbelop: formatAmount(query.maksbelop || Constant.ipsMaxYearlyAmount),
                                            })}
                                        />
                                    )}

                                    {type === 'ips-only-one-saving-agreement' && (
                                        <Message
                                            polite
                                            type="error-hand"
                                            title={appTexts.get('ipsStoppMaksEnSpareavtaleTitle')}
                                            text={appTexts.get('ipsStoppMaksEnSpareavtaleText')}
                                        />
                                    )}

                                    {type === 'sell-missing-currency' && (
                                        <Message
                                            polite
                                            type="error-hand"
                                            text={[
                                                appTexts.get('preventFundSellMissingMorningstarCurrency'),
                                                appTexts.get('tryAgainOrContact'),
                                            ]}
                                        >
                                            <div className="paragraph -include-top center">
                                                <Link
                                                    href={getContactUsUrl()}
                                                    linkType={LinkType.BUTTON_STANDARD_POSITIVE}
                                                >
                                                    {appTexts.get('kontaktOss')}
                                                </Link>
                                            </div>
                                        </Message>
                                    )}

                                    {type === 'ma-behandles-manuelt' && (
                                        <Message
                                            polite
                                            type="error-hand"
                                            text={appTexts.get('kundevurderingMaBehandlesManueltText')}
                                            title={appTexts.get('kundevurderingMaBehandlesManueltTitle')}
                                        >
                                            <div className="center paragraph -only-top">
                                                <Link
                                                    href={getContactUsUrl()}
                                                    linkType={LinkType.BUTTON_STANDARD_POSITIVE}
                                                >
                                                    {appTexts.get('kontaktOss')}
                                                </Link>
                                            </div>
                                        </Message>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
        </section>
    );
};
