/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';

class AksjesparekontoVilkaar extends Component {
    render() {
        return (
            <div className="vilkaar-tekst">
                <h2 className="h3 centered">Avtale om aksjesparekonto</h2>
                <p>
                    Mellom Kunde (heretter benevnt «Kunde») og Eika Kapitalforvaltning AS (heretter benevnt «Eika»),
                    hvor Kunde og Eika i fellesskap heretter er benevnt «Partene», er det inngått følgende avtale om
                    aksjesparekonto (heretter «Avtalen»):
                </p>
                <h3 className="h4">1. Avtalens ikrafttredelse</h3>
                <p>
                    Avtalen trer i kraft når aksjesparekontoen er opprettet i Kundens navn og Kundens fondsbeholdning er
                    flyttet over til kontoen. All handel som skjer før nevnte tidspunkt vil skattlegges på ordinært vis.
                </p>
                <h3 className="h4">2. Informasjon før inngåelse av Avtalen</h3>
                <p>
                    Reglene om aksjesparekonto krever at tilbyder, før avtale inngås, må gi kunden skriftlige
                    opplysninger om sentrale vilkår i avtaleforholdet. Det skal blant annet gis opplysninger om hvilke
                    midler som kan inngå på aksjesparekontoen, hvilke skatteregler som gjelder, hvilke kostnader som er
                    knyttet til kontoen, at det ikke vil påløpe renter på eventuelle innestående kontanter på kontoen og
                    om forholdet til innskuddsgarantiordningen. Kunden bekrefter ved sin aksept av Avtalen å ha blitt
                    gjort kjent med overnevnte forhold før Avtalen ble inngått.
                </p>
                <p>
                    Samtidig er Kunden kjent med at en konvertering til aksjesparekonto ikke nødvendigvis er
                    hensiktsmessig dersom Kunden planlegger å gi bort sine fondsandeler i gave, eller dersom hun/han har
                    latente tap på sine investeringer.
                </p>
                <h3 className="h4">3. Avtaleinngåelsen</h3>
                <p>
                    Avtalen kan enten inngås ved fysisk signering på «blankett for avtale om konvertering til
                    aksjesparekonto» (hvor denne Avtalen inngår som vedlegg), eller ved digital inngåelse. I sistnevnte
                    tilfeller vil Kunden, som da vil være innlogget, akseptere Avtalen ved avkrysning av at vedkommende
                    har lest, akseptert og godtatt Avtalen. Avtalen vil sendes på e-post til kunden samt lagres i
                    nettposten.
                </p>
                <h3 className="h4">4. Om aksjesparekontoen – diverse</h3>
                <p>
                    Med aksjesparekonto menes en investeringskonto som kan opprettes av personlige skattytere. Innenfor
                    kontoen kan Kunden investere i børsnoterte aksjer og egenkapitalbevis i selskap hjemmehørende i land
                    innenfor EØS og andeler i aksjefond hjemmehørende i land innenfor EØS. Med aksjefond menes
                    verdipapirfond med mer enn 80 prosent aksjeandel ved inntektsårets begynnelse. Det kan ikke
                    opptjenes renter på innestående kontante midler på aksjesparekontoen.
                </p>
                <p>
                    Ved realisasjon av aksjer, egenkapitalbevis og andeler i verdipapirfond innenfor kontoen vil
                    gevinster ikke komme til beskatning, og tap heller ikke til fradrag. Gevinsten vil først bli
                    beskattet når Kundens uttak fra kontoen overstiger innskuddet, og tap først vil komme til fradrag
                    når innestående på konto er lavere enn innskuddet ved kontoens opphør.
                </p>
                <p>
                    Aksjesparekonto hos Eika vil omfatte Eikas egenforvaltede aksjefond. Etter hvert vil eksterne
                    aksjefond bli inkludert, i tillegg til at det på sikt er aktuelt å tilby enkeltaksjer i samarbeid
                    med en tredjepart. På Eikas nettsider vil det til enhver tid være en oppdatert oversikt over hvilke
                    produkter som er tilgjengelig for aksjesparekonto i Eika.
                </p>
                <p>
                    Reglene knyttet til aksjesparekonto fremgår av skatteloven med tilhørende forskrift. Dersom reglene
                    i fremtiden endres, vil også vilkårene i herværende Avtale endres tilsvarende.
                </p>
                <p>
                    Kontanter på aksjesparekontoen vil inngå på Eikas klientkonto hos Danske Bank. For det tilfellet at
                    Danske Bank skulle gå konkurs vil innskuddsgarantiordningen gi Eika dekning med totalt NOK 2
                    millioner. Det gjøres oppmerksom på Eika har flere konti hos Danske Bank og at samtlige av Eikas
                    kunder som har aksjesparekonto, benytter den samme klientkontoen. Dette innebærer at
                    innskuddsgarantiordningen for den enkelte kunde ikke vil gi dekning av betydning.
                </p>
                <p>
                    For å kunne gjennomføre overføring av finansielle instrumenter til aksjesparekontoen vil Eika
                    innhente – og kontohaver er forpliktet til å gi – nødvendige opplysninger, herunder om inngangsverdi
                    og ubenyttet skjerming. Eika vil kunne utveksle kundeopplysninger med tredjeparter om utførelse av
                    oppgaver i forbindelse med kontoen.
                </p>
                <p>
                    Eika vil beregne et skjermingsfradrag på grunnlag av Kundens laveste innskudd på kontoen i løpet av
                    året, tillagt eventuell ubenyttet skjerming fra tidligere år.
                </p>
                <p>
                    Utbytte fra finansielle instrumenter vil ikke inngå i aksjesparekontoen, men utbetales til Kunden og
                    oppgis til direkte beskatning på Kundens hånd.
                </p>
                <h3 className="h4">5. Føring av kontoen</h3>
                <p>
                    Eika vil som tilbyder av aksjesparekontoen ivareta føringen av kontoen. Kunden vil kunne foreta
                    handel gjennom Eikas ulike handelsplattformer, eller manuelt ved bruk av blanketter og ved hjelp av
                    Kundens lokale sparebank.
                </p>
                <p>
                    I aksjesparekonten skal Kunden til enhver tid kunne se: a) innskudd på kontoen, b) oversikt over
                    finansielle instrumenter på kontoen, c) kontantbeholdning, d) ubenyttet skjermingsfradrag fra
                    tidligere år, e) skjermingsgrunnlag, f) beregnet skjermingsfradrag for skattleggingsperioden, g)
                    skattepliktig uttak fra kontoen i løpet av skattleggingsperioden, h) kontoen markedsverdi ved
                    årsskiftet og i) mottatt utbytte.
                </p>
                <h3 className="h4">6. Kostnader</h3>
                <p>
                    Opprettelse av aksjesparekonto medfører ingen kostnader for Kunden, og Kunden vil heller ikke
                    belastes løpende utgifter knyttet til drift av kontoen.
                </p>
                <p>
                    Ved flytting og/eller oppsigelse av aksjesparekontoen kan Eika belaste Kunden for utgifter knyttet
                    til flyttingen/oppsigelsen. Slike kostnader kan likevel ikke overstige Eikas kostnader forbundet med
                    slik flytting/oppsigelse. Nærmere informasjon om kostnader vil bli publisert på Eikas nettsider.
                </p>
                <p>
                    Det gjøres oppmerksom på at ordinære kostnader knyttet til kjøp og salg av fondsandeler
                    (forvaltningshonorar, tegnings- og innløsningshonorar), slik de er beskrevet i fondenes
                    nøkkelinformasjon og prospekter, vil påløpe i tillegg.
                </p>
                <h3 className="h4">7. Oppbevaring av fondsandeler og kundemidler</h3>
                <p>
                    Eika fører selv andelseierregisteret for Eikas egenforvaltede fond. Føring av øvrige fondsandeler
                    gjøres i eksterne andelseierregistre og Eika mottar løpende rapportering fra disse og foretar
                    daglige avstemminger.
                </p>
                <p>
                    Ved investeringer i eksterne fond vil Eika stå oppført som nominee for Kunden, dvs. at Eika vil være
                    registrert som forvalter av Kundens fondsandeler i det eksterne fondets andelseierregister.
                    Informasjon og rapportering fra slike eksterne fondsleverandører til sine kunder vil videreformidles
                    til Kunden fra Eika.
                </p>
                <p>
                    Kontante midler innenfor aksjesparekontoen oppbevares på klientkonto i Eikas navn, for tiden i
                    Danske Bank, og er dermed adskilt fra Eikas egne midler. Eika står fritt til å endre innskuddsbank.
                    Kunden vil ikke opparbeide seg renter på kontante midler.
                </p>
                <h3 className="h4">8. Rapportering</h3>
                <p>
                    Eika skal minimum to ganger i året gi Kunden informasjon om beholdningen av finansielle instrumenter
                    og kontanter på aksjesparekontoen, samt om Kundens samlede innskudd på kontoen. Det skal samtidig
                    opplyses at det ikke påløper renter på en eventuell kontantbeholdning.
                </p>
                <p>
                    Som tilbyder av aksjesparekontoen vil Eika være ansvarlig for å utføre skatterapportering til norske
                    skattemyndigheter i tråd med de til enhver tid gjeldende regler.
                </p>
                <h3 className="h4">9. Avvikling og flytting</h3>
                <p>
                    Kunden kan når som helst begjære aksjesparekontoen avviklet eller overført til en annen tilbyder.
                    Dersom Kunden ønsker å avvikle kontoen skal Eika kontaktes, mens dersom Kunden ønsker å flytte
                    kontoen skal ny tilbyder kontaktes. Ny tilbyder vil deretter kontakte Eika for gjennomføring av
                    flyttingen.
                </p>
                <p>
                    Ved begjæring fra en annen tilbyder om flytting skal Eika innen 10 virkedager overføre alle
                    finansielle instrumenter og kontanter på kontoen til den nye tilbyderen. Oppgjør for salg av
                    verdipapirer med oppgjørstid ut over 10 dager, overføres til ny tilbyder senest fem dager etter
                    oppgjørstidspunktet. Eika skal også overføre informasjon som nevnt i forskrift FOR-2017-06-21-853 §
                    10-21-4, og annen informasjon som er nødvendig for at den nye tilbyderen skal kunne foreta korrekt
                    innrapportering til skattemyndighetene ved utgangen av skattleggingsperioden.
                </p>
                <h3 className="h4">10. Ansvar</h3>
                <p>
                    Kunden erkjenner at informasjonen innenfor aksjesparekontoen ikke under noen omstendighet skal
                    oppfattes som et råd eller tilbud om å kjøpe eller selge finansielle instrumenter. Ved behov for
                    investeringsrådgivning kan Kundens lokale sparebank eller Eika kontaktes.
                </p>
                <p>
                    Verdipapirfondenes nøkkelinformasjon og prospekter ligger tilgjengelig i Eikas handelsløsninger og
                    nettsider. Kunden må sette seg grundig inn i disse dokumentene før investeringsbeslutning foretas.
                </p>
                <p>
                    Det gjøres oppmerksom på at historisk avkastning ikke er noen garanti for fremtidig avkastning, og
                    at fremtidig avkastning blant annet vil avhenge av markedsutviklingen, forvalterens dyktighet,
                    fondets risikoprofil og forvaltningshonorar. Avkastningen kan også bli negativ som følge av kurstap.
                </p>
                <h3 className="h4">11. Personopplysninger og forretningsvilkår</h3>
                <p>
                    Eika vil behandle personopplysninger om Kunden i forbindelse med avtaleforholdet. Formålet med
                    behandlingen er å oppfylle Eikas avtalemessige forpliktelser overfor Kunden, samt andre lovpålagte
                    rapporteringsforpliktelser. Ved inngåelse av Avtalen samtykker Kunden til dette.
                </p>
                <p>
                    Det vises for øvrig til Eikas forretningsvilkår, som Kunden ved inngåelse av Avtalen anses å ha
                    akseptert slik de til enhver tid er utformet. Forretningsvilkårene er tilgjengelig på Eikas
                    nettsider.
                </p>
                <h3 className="h4">12. Tvisteløsning</h3>
                <p>
                    Enhver tvist som springer ut av Avtalen skal løses etter norsk rett og med Oslo tingrett som
                    verneting.
                </p>
                <p>
                    Kunden kan bringe en eventuell tvist inn for Finansklagenemnda til uttalelse. Se Eikas nettside for
                    nærmere informasjon om klagegangen.
                </p>
            </div>
        );
    }
}

export default AksjesparekontoVilkaar;
