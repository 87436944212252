import React from 'react';

export default (): JSX.Element => {
    return <div className="countdown-image-wrapper">
        <div className="countdown-image-cloud-face">
            <svg viewBox="0 0 216 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="cloud-face">
                    <path d="M137.95 49.95C131.35 39.76 119.87 33 106.81 33C89.8198 33 75.4998 44.42 71.1298 59.99C60.7298 62.58 53.0098 71.94 53.0098 83.13C53.0098 96.31 63.7198 107 76.9198 107C81.7998 107 86.3298 105.53 90.1098 103.03C95.1298 105.56 100.8 107 106.81 107C112.02 107 116.99 105.92 121.49 103.98C125.35 105.91 129.7 107 134.31 107C150.16 107 163.01 94.18 163.01 78.35C163.01 63.76 152.08 51.73 137.96 49.94L137.95 49.95Z" fill="#D2EAF4"/>
                    <path d="M134.3 108C129.8 108 125.48 107.02 121.45 105.08C116.8 107.02 111.87 108 106.8 108C100.97 108 95.38 106.71 90.17 104.18C86.19 106.68 81.62 108 76.91 108C63.17 108 52 96.84 52 83.13C52 71.89 59.5 62.12 70.32 59.17C75.09 43.14 90.01 32 106.8 32C119.61 32 131.44 38.35 138.53 49.02C153.08 51.09 164 63.62 164 78.36C164 94.71 150.68 108.01 134.3 108.01V108ZM121.48 102.98C121.63 102.98 121.79 103.02 121.93 103.09C125.8 105.02 129.96 106.01 134.31 106.01C149.58 106.01 162.01 93.61 162.01 78.36C162.01 64.48 151.62 52.69 137.83 50.94C137.54 50.9 137.28 50.74 137.12 50.49C130.43 40.16 119.1 34 106.82 34C90.72 34 76.44 44.8 72.1 60.26C72 60.61 71.73 60.87 71.38 60.96C61.16 63.5 54.02 72.62 54.02 83.13C54.02 95.74 64.3 106 76.93 106C81.44 106 85.81 104.68 89.57 102.2C89.87 102 90.25 101.98 90.57 102.14C95.65 104.7 101.11 106 106.82 106C111.77 106 116.58 105.01 121.1 103.06C121.23 103.01 121.36 102.98 121.5 102.98H121.48Z" fill="#00383D"/>
                    <path d="M122 70.6104C119.24 70.6104 117 68.3704 117 65.6104C117 65.0604 117.45 64.6104 118 64.6104C118.55 64.6104 119 65.0604 119 65.6104C119 67.2604 120.35 68.6104 122 68.6104C123.65 68.6104 125 67.2604 125 65.6104C125 65.0604 125.45 64.6104 126 64.6104C126.55 64.6104 127 65.0604 127 65.6104C127 68.3704 124.76 70.6104 122 70.6104Z" fill="#00383D"/>
                    <path d="M107.57 89.5C104.83 89.5 102.26 88.3 100.86 86.38C100.53 85.93 100.63 85.31 101.08 84.98C101.53 84.65 102.15 84.75 102.48 85.2C103.51 86.62 105.46 87.5 107.57 87.5C109.79 87.5 111.85 86.51 112.82 84.97C113.11 84.5 113.73 84.36 114.2 84.66C114.67 84.95 114.81 85.57 114.51 86.04C113.16 88.18 110.5 89.51 107.57 89.51V89.5Z" fill="#00383D"/>
                    <path d="M94 70.6104C91.24 70.6104 89 68.3704 89 65.6104C89 65.0604 89.45 64.6104 90 64.6104C90.55 64.6104 91 65.0604 91 65.6104C91 67.2604 92.35 68.6104 94 68.6104C95.65 68.6104 97 67.2604 97 65.6104C97 65.0604 97.45 64.6104 98 64.6104C98.55 64.6104 99 65.0604 99 65.6104C99 68.3704 96.76 70.6104 94 70.6104Z" fill="#00383D"/>
                    <path d="M135 82.6104C137.209 82.6104 139 80.8195 139 78.6104C139 76.4012 137.209 74.6104 135 74.6104C132.791 74.6104 131 76.4012 131 78.6104C131 80.8195 132.791 82.6104 135 82.6104Z" fill="#E2B3B5"/>
                    <path d="M135 83.6104C132.24 83.6104 130 81.3704 130 78.6104C130 75.8504 132.24 73.6104 135 73.6104C137.76 73.6104 140 75.8504 140 78.6104C140 81.3704 137.76 83.6104 135 83.6104ZM135 75.6104C133.35 75.6104 132 76.9604 132 78.6104C132 80.2604 133.35 81.6104 135 81.6104C136.65 81.6104 138 80.2604 138 78.6104C138 76.9604 136.65 75.6104 135 75.6104Z" fill="#00383D"/>
                    <path d="M81 82.6104C83.2091 82.6104 85 80.8195 85 78.6104C85 76.4012 83.2091 74.6104 81 74.6104C78.7909 74.6104 77 76.4012 77 78.6104C77 80.8195 78.7909 82.6104 81 82.6104Z" fill="#E2B3B5"/>
                    <path d="M81 83.6104C78.24 83.6104 76 81.3704 76 78.6104C76 75.8504 78.24 73.6104 81 73.6104C83.76 73.6104 86 75.8504 86 78.6104C86 81.3704 83.76 83.6104 81 83.6104ZM81 75.6104C79.35 75.6104 78 76.9604 78 78.6104C78 80.2604 79.35 81.6104 81 81.6104C82.65 81.6104 84 80.2604 84 78.6104C84 76.9604 82.65 75.6104 81 75.6104Z" fill="#00383D"/>
                    <path d="M90.0002 60.0001C89.5802 60.0001 89.1902 59.7301 89.0502 59.3201C88.8802 58.8001 89.1602 58.2301 89.6802 58.0501L95.6802 56.0501C96.2102 55.8701 96.7702 56.1601 96.9502 56.6801C97.1202 57.2001 96.8402 57.7701 96.3202 57.9501L90.3202 59.9501C90.2202 59.9901 90.1102 60.0001 90.0002 60.0001Z" fill="#00383D"/>
                    <path d="M125 60.0001C124.9 60.0001 124.79 59.9801 124.68 59.9501L118.68 57.9501C118.16 57.7801 117.87 57.2101 118.05 56.6801C118.22 56.1601 118.79 55.8701 119.31 56.0501L125.31 58.0501C125.83 58.2201 126.12 58.7901 125.94 59.3201C125.8 59.7401 125.41 60.0001 124.99 60.0001H125Z" fill="#00383D"/>
                </g>
            </svg>
        </div>

        <svg viewBox="0 0 216 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="cloud-ring">
                <path d="M107 10C96.51 10 88 13.58 88 18C88 22.42 96.51 26 107 26C117.49 26 126 22.42 126 18C126 13.58 117.49 10 107 10ZM107 22C99.85 22 94.05 20.21 94.05 18C94.05 15.79 99.85 14 107 14C114.15 14 119.95 15.79 119.95 18C119.95 20.21 114.15 22 107 22Z" fill="#F6C187"/>
                <path d="M107 27C95.79 27 87 23.05 87 18C87 12.95 95.79 9 107 9C118.21 9 127 12.95 127 18C127 23.05 118.21 27 107 27ZM107 11C98.49 11 89 13.88 89 18C89 22.12 98.49 25 107 25C115.51 25 125 22.12 125 18C125 13.88 115.51 11 107 11ZM107 23C100.07 23 93.05 21.28 93.05 18C93.05 14.72 100.07 13 107 13C113.93 13 120.95 14.72 120.95 18C120.95 21.28 113.93 23 107 23ZM107 15C99.58 15 95.05 16.94 95.05 18C95.05 19.06 99.59 21 107 21C114.41 21 118.95 19.06 118.95 18C118.95 16.94 114.41 15 107 15Z" fill="#00383D"/>
            </g>
            <g id="cloud-left-hand">
                <path d="M74.3995 134C69.9395 133.94 65.9995 128.1 65.9995 119.95C65.9995 116.42 68.1495 113.38 71.2895 112.15C72.7895 111.56 74.3995 112.74 74.3995 114.33C74.3995 115.28 73.8795 116.2 72.9995 116.56C71.7095 117.1 70.7995 118.36 70.7995 119.82C70.7995 121.99 72.7795 123.7 75.0495 123.31C76.4995 123.06 77.6895 121.89 77.9395 120.47C78.2495 118.71 77.2495 117.14 75.7395 116.54C74.8995 116.21 74.3995 115.35 74.3995 114.45V114.34C74.3995 112.69 76.1195 111.57 77.6595 112.21C80.6795 113.46 83.9995 115.11 83.9995 121.01C83.9995 130.46 79.0895 134.06 74.3995 134Z" fill="#D2EAF4"/>
                <path d="M74.5197 135C74.5197 135 74.4297 135 74.3797 135C69.1197 134.93 64.9897 128.32 64.9897 119.95C64.9897 116.06 67.3098 112.63 70.9098 111.22C71.8998 110.83 73.0197 110.96 73.9197 111.57C74.0897 111.69 74.2597 111.82 74.3997 111.97C74.5398 111.83 74.6997 111.71 74.8597 111.6C75.7997 110.97 76.9897 110.86 78.0297 111.29C80.6297 112.37 84.9798 114.18 84.9798 121.01C84.9798 125.93 83.6697 129.82 81.1797 132.27C79.3897 134.03 77.0297 135 74.4997 135H74.5197ZM72.0897 113C71.9397 113 71.7897 113.03 71.6497 113.08C68.8197 114.19 66.9997 116.88 66.9997 119.95C66.9997 127.95 70.8498 132.95 74.4098 133C75.8598 133.03 77.9597 132.66 79.7897 130.85C81.8797 128.78 82.9897 125.38 82.9897 121.01C82.9897 115.92 80.3997 114.43 77.2697 113.13C76.8397 112.95 76.3698 113 75.9798 113.26C75.6098 113.51 75.3897 113.9 75.3897 114.34V114.45C75.3897 114.97 75.6797 115.44 76.0997 115.61C78.1397 116.42 79.2997 118.49 78.9197 120.64C78.5897 122.47 77.0697 123.97 75.2197 124.29C73.8497 124.53 72.4598 124.16 71.4098 123.28C70.3798 122.41 69.7897 121.16 69.7897 119.82C69.7897 117.99 70.8897 116.35 72.5997 115.63C73.0697 115.43 73.3897 114.91 73.3897 114.32C73.3897 113.88 73.1697 113.47 72.7897 113.21C72.5697 113.06 72.3197 112.98 72.0798 112.98L72.0897 113ZM74.3797 116.83C74.0897 117.11 73.7597 117.34 73.3797 117.49C72.4197 117.89 71.7998 118.81 71.7998 119.83C71.7998 120.57 72.1297 121.27 72.6997 121.76C73.2997 122.27 74.0797 122.47 74.8797 122.33C75.9197 122.15 76.7697 121.31 76.9597 120.3C77.1797 119.09 76.5197 117.93 75.3797 117.48C75.0097 117.33 74.6697 117.11 74.3897 116.83H74.3797Z" fill="#00383D"/>
                <path d="M74.05 134H70.19C65.12 134 61 128.4 61 123H62.74" fill="#D2EAF4"/>
                <path d="M74.05 135H70.19C64.36 135 60 128.67 60 123C60 122.45 60.45 122 61 122H62.74C63.29 122 63.74 122.45 63.74 123C63.74 123.55 63.29 124 62.74 124H62.06C62.52 128.37 65.91 133 70.19 133H74.05C74.6 133 75.05 133.45 75.05 134C75.05 134.55 74.6 135 74.05 135Z" fill="#00383D"/>
                <path d="M68.5897 124.57C66.1797 124.57 64.9798 123.95 63.5498 122.3C62.5798 121.18 62.4398 119.65 63.0798 118.42C63.5098 117.58 63.2998 116.55 62.6798 115.83C61.6298 114.62 59.6098 114.75 58.8498 116.15C57.2598 119.08 57.9998 123.52 60.3298 126.21C65.7098 132.41 66.1798 134 70.9998 134" fill="#D2EAF4"/>
                <path d="M70.9999 135C66.4499 135 65.2599 133.58 61.7199 129.38C61.0999 128.64 60.3899 127.8 59.5699 126.86C56.9199 123.81 56.2199 118.89 57.9699 115.66C58.4699 114.73 59.3799 114.13 60.4599 114.01C61.5899 113.89 62.6899 114.32 63.4299 115.16C64.3599 116.24 64.5699 117.69 63.9699 118.87C63.4999 119.78 63.6399 120.86 64.3099 121.64C65.5999 123.13 66.5699 123.56 68.5999 123.56C69.1499 123.56 69.5999 124.01 69.5999 124.56C69.5999 125.11 69.1499 125.56 68.5999 125.56C65.9899 125.56 64.4799 124.88 62.7999 122.95C61.5899 121.55 61.3499 119.59 62.1999 117.95C62.4299 117.51 62.3199 116.92 61.9299 116.47C61.6299 116.12 61.1699 115.95 60.6899 116C60.2699 116.05 59.9199 116.27 59.7399 116.61C58.3799 119.11 58.9899 123.12 61.0999 125.54C61.9299 126.49 62.6399 127.33 63.2599 128.08C66.6799 132.14 67.3899 132.99 71.0099 132.99C71.5599 132.99 72.0099 133.44 72.0099 133.99C72.0099 134.54 71.5599 134.99 71.0099 134.99L70.9999 135Z" fill="#00383D"/>
                <path d="M67.3198 128.06C64.9998 127.99 61.0998 126.61 59.5898 125.08C58.5598 124.04 58.2998 122.52 58.8198 121.24C59.1698 120.36 58.8798 119.35 58.2198 118.68C57.1098 117.56 55.1698 117.85 54.5498 119.3C53.2498 122.35 54.4098 126.65 56.7998 129.23C61.8098 134.65 72.0098 133.96 72.0098 133.96" fill="#D2EAF4"/>
                <path d="M70.7899 135C67.7899 135 60.2999 134.51 56.0499 129.92C53.2699 126.91 52.2299 122.18 53.6099 118.92C54.0299 117.94 54.8699 117.26 55.9299 117.06C57.0199 116.85 58.1399 117.2 58.9099 117.98C59.8899 118.98 60.2099 120.4 59.7299 121.61C59.3499 122.56 59.5699 123.65 60.2799 124.37C61.5299 125.63 65.0899 126.99 67.3299 127.05C67.8799 127.07 68.3199 127.53 68.2999 128.08C68.2799 128.63 67.8499 129.04 67.2699 129.05C64.7499 128.97 60.6199 127.55 58.8599 125.77C57.5799 124.48 57.1899 122.55 57.8699 120.85C58.0599 120.38 57.8999 119.79 57.4899 119.37C57.1799 119.06 56.7399 118.92 56.2999 119C56.0699 119.04 55.6499 119.19 55.4399 119.68C54.3599 122.23 55.2599 126.12 57.4999 128.54C62.1299 133.55 71.8099 132.96 71.9099 132.95C72.4499 132.92 72.9399 133.33 72.9799 133.88C73.0199 134.43 72.5999 134.91 72.0499 134.95C71.9699 134.95 71.4999 134.98 70.7699 134.98L70.7899 135Z" fill="#00383D"/>
                <path d="M70.9999 133H74.5399L76.9999 130L68.9199 128.98L70.9999 133Z" fill="#D2EAF4"/>
                <path d="M72.11 129C72.11 129 71.2 125.56 74.65 123.4L72.11 129Z" fill="#D2EAF4"/>
                <path d="M72.1099 130C71.6699 130 71.2699 129.71 71.1499 129.26C70.7699 127.83 70.8999 124.58 74.1199 122.55C74.5899 122.26 75.1999 122.4 75.4999 122.86C75.7899 123.33 75.6499 123.94 75.1899 124.24C72.4399 125.97 73.0599 128.64 73.0899 128.75C73.2199 129.28 72.8999 129.83 72.3699 129.96C72.2899 129.98 72.1999 129.99 72.1199 129.99L72.1099 130Z" fill="#00383D"/>
            </g>
            <g id="cloud-right-hand">
                <path d="M139.6 134C144.06 133.94 148 128.1 148 119.95C148 116.42 145.85 113.38 142.71 112.15C141.21 111.56 139.6 112.74 139.6 114.33C139.6 115.28 140.12 116.2 141 116.56C142.29 117.1 143.2 118.36 143.2 119.82C143.2 121.99 141.22 123.7 138.95 123.31C137.5 123.06 136.31 121.89 136.06 120.47C135.75 118.71 136.75 117.14 138.26 116.54C139.1 116.21 139.6 115.35 139.6 114.45V114.34C139.6 112.69 137.88 111.57 136.34 112.21C133.32 113.46 130 115.11 130 121.01C130 130.46 134.91 134.06 139.6 134Z" fill="#D2EAF4"/>
                <path d="M139.48 135C136.95 135 134.59 134.03 132.8 132.27C130.32 129.82 129 125.93 129 121.01C129 114.17 133.35 112.37 135.95 111.29C136.99 110.86 138.18 110.97 139.12 111.6C139.29 111.71 139.44 111.84 139.58 111.97C139.73 111.82 139.89 111.69 140.06 111.57C140.95 110.96 142.08 110.83 143.07 111.22C146.67 112.63 148.99 116.06 148.99 119.95C148.99 128.32 144.87 134.93 139.6 135C139.6 135 139.51 135 139.46 135H139.48ZM137.26 113.03C137.08 113.03 136.9 113.07 136.73 113.14C133.6 114.44 131.01 115.93 131.01 121.02C131.01 125.39 132.12 128.79 134.21 130.86C136.05 132.67 138.16 133.06 139.59 133.01C143.16 132.96 147 127.96 147 119.96C147 116.9 145.17 114.2 142.35 113.09C141.97 112.94 141.55 112.99 141.2 113.23C140.83 113.48 140.6 113.9 140.6 114.34C140.6 114.93 140.92 115.46 141.39 115.65C143.1 116.36 144.2 118.01 144.2 119.84C144.2 121.17 143.61 122.43 142.58 123.3C141.53 124.18 140.15 124.55 138.77 124.31C136.92 123.99 135.39 122.49 135.07 120.66C134.69 118.5 135.84 116.43 137.89 115.63C138.31 115.46 138.6 115 138.6 114.47V114.36C138.6 113.92 138.39 113.53 138.01 113.28C137.78 113.12 137.51 113.05 137.25 113.05L137.26 113.03ZM139.62 116.83C139.34 117.11 139 117.33 138.63 117.48C137.48 117.93 136.83 119.1 137.05 120.3C137.23 121.32 138.09 122.15 139.13 122.33C139.93 122.47 140.7 122.27 141.31 121.76C141.89 121.28 142.22 120.57 142.22 119.83C142.22 118.81 141.6 117.89 140.64 117.49C140.26 117.33 139.93 117.11 139.64 116.83H139.62Z" fill="#00383D"/>
                <path d="M139.95 134H143.81C148.88 134 153 128.4 153 123H151.26" fill="#D2EAF4"/>
                <path d="M143.81 135H139.95C139.4 135 138.95 134.55 138.95 134C138.95 133.45 139.4 133 139.95 133H143.81C148.09 133 151.48 128.37 151.94 124H151.26C150.71 124 150.26 123.55 150.26 123C150.26 122.45 150.71 122 151.26 122H153C153.55 122 154 122.45 154 123C154 128.67 149.64 135 143.81 135Z" fill="#00383D"/>
                <path d="M145.41 124.57C147.82 124.57 149.02 123.95 150.45 122.3C151.42 121.18 151.56 119.65 150.92 118.42C150.49 117.58 150.7 116.55 151.32 115.83C152.37 114.62 154.39 114.75 155.15 116.15C156.74 119.08 156 123.52 153.67 126.21C148.29 132.41 147.82 134 143 134" fill="#D2EAF4"/>
                <path d="M143 135C142.45 135 142 134.55 142 134C142 133.45 142.45 133 143 133C146.62 133 147.33 132.15 150.75 128.09C151.38 127.34 152.09 126.5 152.91 125.55C155.01 123.13 155.62 119.12 154.27 116.62C154.08 116.28 153.74 116.05 153.32 116.01C152.84 115.96 152.38 116.13 152.08 116.48C151.69 116.93 151.58 117.52 151.81 117.96C152.65 119.6 152.42 121.56 151.2 122.96C149.53 124.89 148.01 125.57 145.4 125.57C144.85 125.57 144.4 125.12 144.4 124.57C144.4 124.02 144.85 123.57 145.4 123.57C147.43 123.57 148.39 123.14 149.69 121.65C150.36 120.87 150.5 119.79 150.03 118.88C149.42 117.7 149.63 116.25 150.57 115.17C151.3 114.33 152.41 113.9 153.54 114.02C154.62 114.14 155.53 114.74 156.03 115.67C157.78 118.9 157.07 123.81 154.43 126.87C153.61 127.81 152.91 128.65 152.29 129.39C148.75 133.59 147.56 135.01 143 135.01V135Z" fill="#00383D"/>
                <path d="M146.68 128.06C149 127.99 152.9 126.61 154.41 125.08C155.44 124.04 155.7 122.52 155.18 121.24C154.83 120.36 155.12 119.35 155.78 118.68C156.89 117.56 158.83 117.85 159.45 119.3C160.75 122.35 159.59 126.65 157.2 129.23C152.19 134.65 141.99 133.96 141.99 133.96" fill="#D2EAF4"/>
                <path d="M143.21 135C142.48 135 142.02 134.97 141.93 134.97C141.38 134.93 140.96 134.46 141 133.9C141.04 133.34 141.52 132.95 142.06 132.97C142.15 132.97 151.85 133.56 156.47 128.56C158.71 126.14 159.62 122.24 158.53 119.7C158.32 119.21 157.9 119.07 157.67 119.02C157.24 118.94 156.79 119.07 156.48 119.39C156.06 119.81 155.91 120.4 156.1 120.87C156.78 122.56 156.39 124.49 155.11 125.79C153.35 127.57 149.21 128.99 146.7 129.07H146.67C146.13 129.07 145.69 128.64 145.67 128.1C145.65 127.55 146.09 127.09 146.64 127.07C148.89 127 152.45 125.65 153.69 124.39C154.41 123.66 154.63 122.58 154.24 121.63C153.75 120.42 154.07 118.99 155.06 118C155.84 117.21 156.96 116.87 158.04 117.08C159.1 117.28 159.94 117.96 160.36 118.94C161.75 122.21 160.7 126.94 157.93 129.94C153.69 134.53 146.19 135.02 143.19 135.02L143.21 135Z" fill="#00383D"/>
                <path d="M143 133H139.46L137 130L145.08 128.98L143 133Z" fill="#D2EAF4"/>
                <path d="M141.89 129C141.89 129 142.8 125.56 139.35 123.4L141.89 129Z" fill="#D2EAF4"/>
                <path d="M141.89 130C141.81 130 141.72 129.99 141.63 129.97C141.1 129.83 140.78 129.29 140.92 128.75C140.95 128.62 141.56 125.97 138.82 124.24C138.35 123.95 138.21 123.33 138.51 122.86C138.8 122.39 139.42 122.25 139.89 122.55C143.11 124.57 143.24 127.82 142.86 129.26C142.74 129.71 142.34 130 141.89 130Z" fill="#00383D"/>
            </g>
        </svg>
    </div>
};
