import { Label, LabelTypes } from '@eika/label';
import React from 'react';
import { Fund } from 'src/domain/Fund';
import { Icon, IconTypes } from '@eika/icon';
import Sprouts from '../Sprouts/Sprouts';
import './FundListCard.scss';
import { formatAmount } from '../util/Functions';

type Props = {
    data: Fund;
    click: (e: React.MouseEvent | null, key: string, data: Fund) => void | undefined;
};

export default function FundListCard({ data, click }: Props) {
    const getRiskText = (fund: Fund) => {
        if (fund.risiko >= 5) {
            return 'Høy risiko';
        }
        if (fund.risiko >= 3) {
            return 'Middels risiko';
        }
        return 'Lav risiko';
    };

    return (
        <div className="fund-list-card" onClick={(e) => click(e, 'fund-selected', data)}>
            <div className="base-info">
                <div>
                    <div className="row">
                        <div className="title">{data.navn}</div>
                        <div className="sprouts">
                            {data.eikespirer ? (
                                <Sprouts max={5} active={data.eikespirer} />
                            ) : (
                                <Icon type={IconTypes.SPOERSMAALSTEGN_36} className="no-sprouts" />
                            )}
                        </div>
                    </div>
                </div>
                <div>{getRiskText(data)}</div>
            </div>
            <div className="detail-info">
                <div className="row">
                    <div className="detail-row">
                        <div className="detail-item">
                            <div className="detail-item-label">Årlig kostnad</div>
                            <div className="detail-item-value">{`${data.yearlyCost} %`}</div>
                        </div>
                        <div className="detail-item">
                            <div className="detail-item-label">Minstebeløp</div>
                            <div className="detail-item-value">
                                {`${formatAmount(data.minimumsBelopEngangsKjop)} kr`}
                            </div>
                        </div>
                        <div className="detail-item">
                            <div className="detail-item-label">Avkastning siste 10 år</div>
                            <div className="detail-item-value">
                                {data.utvikling.prosentSiste10Aar ? (
                                    <>
                                        <span>{`${data.utvikling.prosentSiste10Aar} %`}</span>
                                        <span className="small-10aar">
                                            <small>siste 10 år</small>
                                        </span>
                                    </>
                                ) : (
                                    <Icon type={IconTypes.MINUS_24} />
                                )}
                            </div>
                        </div>
                    </div>
                    <Icon className="fundlist-icon" type={IconTypes.LITEN_PIL_HOEYRE} />
                </div>
                {data.minimumOrderAmountFirstTimePurchase > data.minimumsBelopEngangsKjop && (
                    <div>
                        <Label
                            className="fondlist-label"
                            text={`Minimumsbeløp ved førstegangskjøp: ${formatAmount(
                                data.minimumOrderAmountFirstTimePurchase
                            )} kr`}
                            type={LabelTypes.DEFAULT_SMALL}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
