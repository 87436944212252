/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HelperFunctions from '../helper-functions';
import Format from '../format-functions';

import { DONE, LOADING, FAILURE } from '../../actions/fond';

class SkattemessigKostpris extends Component {
    static propTypes = {
        portfolioBaseCode: PropTypes.string.isRequired,
        bankregnr: PropTypes.string.isRequired,
        infoTekst: PropTypes.string.isRequired,
        feilTekst: PropTypes.string.isRequired,
        hentSkattemessigKostpris: PropTypes.func.isRequired,
        skattemessigKostpris: PropTypes.object,
        subInfoTekst: PropTypes.string
    };

    UNSAFE_componentWillMount = () =>
        this.triggeHentSkattemessigKostpris(this.props.portfolioBaseCode, this.props.bankregnr, true);

    UNSAFE_componentWillReceiveProps = newProps => {
        if (this.props.portfolioBaseCode === newProps.portfolioBaseCode && this.props.bankregnr === newProps.bankregnr)
            return;
        this.triggeHentSkattemessigKostpris(newProps.portfolioBaseCode, newProps.bankregnr, false);
    };

    triggeHentSkattemessigKostpris = (portfolioBaseCode, bankregnr) => {
        const hasValue = Object.keys(this.props.skattemessigKostpris).includes(portfolioBaseCode);
        if (!hasValue) {
            this.props.hentSkattemessigKostpris(portfolioBaseCode, bankregnr);
        }
    };

    visSkattemessigKostpris = () => {
        if (this.props.portfolioBaseCode) {
            const status = this.hentSkattemessigKostprisStatus(this.props.portfolioBaseCode);
            return this.hentSkatteMessigKostprisTekst(status, this.props.portfolioBaseCode);
        }

        return null;
    };

    hentSkattemessigKostprisStatus = portfolioBaseCode => {
        if (Object.keys(this.props.skattemessigKostpris).includes(portfolioBaseCode)) {
            const skattemessigKostpris = this.props.skattemessigKostpris[portfolioBaseCode];
            return skattemessigKostpris.status;
        }

        return LOADING;
    };

    hentSkatteMessigKostprisTekst = (status, portfolioBaseCode) => {
        if (status === FAILURE) {
            return this.props.feilTekst;
        }

        const skattemessigKostpris =
            status === DONE
                ? Format.currency(this.props.skattemessigKostpris[portfolioBaseCode].skattemessigKostpris)
                : '-';

        return HelperFunctions.VariableInjection(this.props.infoTekst, {
            belop: { value: skattemessigKostpris, class: 'tall' }
        });
    };

    render = () => (
        <div className="infoboks box selg-fond">
            <div className="infoboks-icon-wrapper">
                <span className="ikon-lyspaere-24 infoboks-icon" />
            </div>
            <div className="infoboks-text">
                <p>{this.visSkattemessigKostpris()}</p>
                {this.props.subInfoTekst && <p>{this.props.subInfoTekst}</p>}
            </div>
        </div>
    );
}

export default SkattemessigKostpris;
