/**
 * Created by vagrant on 01.03.17.
 */

import React from 'react';
import PropTypes from 'prop-types';

const KjopFondSnakkMedRaadgiver = props => (
    <div>
        <div className="title">{props.texts.get('snakkMedRaadgiverTittel')}</div>
        <div className="tekst">{props.texts.get('snakkMedRaadgiverTekst')}</div>
    </div>
);

KjopFondSnakkMedRaadgiver.propTypes = {
    texts: PropTypes.object.isRequired
};

export default KjopFondSnakkMedRaadgiver;
