// @ts-nocheck // kiet fix it later

import React from 'react';
import classNames from 'classnames';

/** ************************************************************************
 ************************************************************************* */
export const FundChartTabel = ({ table }) => {
    return table ? <table className="fund-list-table">
        <caption className="aria-visible">{table.title || 'Fond'}</caption>
        <thead>
            <tr>
                {table.head.map( (d, i) => {
                    return <th key={`th-${i}`} scope="col" className={`column-${i} ${d.style || ''}`}>
                        { (d.text instanceof Array ? d.text : [d.text]).map( (t,j) => {
                            return <span key={`th-${i}-${j}`}>{t}</span>
                        }) }
                    </th>
                })}
            </tr>
        </thead> 
        <tbody>
            { table.body.map( (d, i) => {
                return <tr key={`tr-${i}`}>
                    { d.map( (t, j) => {
                        return j === 0 ?  
                            <th key={`td-${i}-${j}`} className={t.style || ''} scope="row"><span>{t.text}</span></th>:
                            <td key={`td-${i}-${j}`} className={t.style || ''}><span>{t.text}</span></td>
                    })}
                </tr>
            })}
        </tbody>
    </table> : null;
};


/** ************************************************************************
 ************************************************************************* */
export const FundRiskBullet = ({ fond }) => {
    return fond ? <span title={`Risikonivå ${fond.risiko} av 7`} className="fund-risk-bullet-wrapper">
        <b>{`Risikonivå ${fond.risiko} av 7`}</b>
        { Array.from({ length: 7 }).map( (x, i) => {
            // eslint-disable-next-line
            return <span aria-hidden="true" key={`risk-level-btn-${i}`} className={ i < fond.risiko ? ((i+1) === fond.risiko ? 'active -current' : ' active') : ''} data-value={i+1} />
        })}
    </span> : null;
};

/** ************************************************************************
 ************************************************************************* */
export const FundDevelopmentList = ({ fond, ignore={} }) => {
    const list = [
        {key: 'iAar', text: 'Hittil i år'}, 
        {key: 'prosentSiste3Aar', text: 'Siste 3 år'}, 
        {key: 'prosentSiste5Aar', text: 'Siste 5 år'}
    ].filter( (config) => !ignore[config.key] );

    return fond && list.length ? <ul className={`fund-development-list-wrapper -count-${list.length}`}>
        { list.map( (config) => (
            <li className="fund-development" key={config.key}>
                <div aria-labelledby={`${fond.isin}-${config.key}`} className={`value -${((fond.utvikling || {})[config.key] || 0) < 0 ? 'negative' : 'positive'}`}>
                    {`${(fond.utvikling || {})[config.key] || '0'}%`}
                </div>
                <div id={`${fond.isin}-${config.key}`} className="text">{config.text}</div>
            </li>
        ))}
    </ul> : null;
};

export const FundPriceList = ({ fond, ignore={} }) => {
    const list = [
        {key: 'yearlyCost', text: 'Forvaltning'}, 
        {key: 'maxFrontEndLoad', text: 'Kjøp'},
        {key: 'maximumExitFee', text: 'Salg'}
    ].filter( (config) => !ignore[config.key] );

    return fond && list.length ? <ul className={`fund-development-list-wrapper -count-${list.length}`}>
        { list.map( (config) => (
            <li className="fund-development -price" key={config.key}>
                <div aria-labelledby={`${fond.isin}-${config.key}`} className={`value -${config.key} -${((fond.utvikling || {})[config.key] || 0) < 0 ? 'negative' : 'positive'}`}>
                    {`${fond[config.key] || '0'}%`}
                </div>
                <div id={`${fond.isin}-${config.key}`} className="text">{config.text}</div>
            </li>
        ))}
    </ul> : null;
};

/** ************************************************************************
 ************************************************************************* */
export const FundSprouts = ({ fond, max=5 }) => {
    return fond ? <span aria-label={`Miljø og etikk score på ${fond.eikespirer} av ${max}`} className={`sprouts-wrapper -length-5 -${fond.eikespirer ? 'active' : 'empty'}`}>  
        { Array.from({ length: max }).map((x, i) => {
            return <span key={`sprouts-icon-${i}`} aria-hidden="true" className={classNames('sprouts-item', {
                '-active': i < fond.eikespirer,
            })} />
        })}
    </span> : null;
};

/** ************************************************************************
 ************************************************************************* */
export const FundESGinfo = ({ fond }) => {
    return fond.eikespirer ? <div className="esg-info">
        <FundSprouts fond={fond} />
        <h3>{`${fond.eikespirer} av 5`}</h3>
        <div className="text">Eikas etiske merking sammenligner ESG-score på fondene i vår fondsliste.</div>
    </div> : null; 
};
/*
export const FundESGinfo = ({ fond }) => {
    return fond.eikespirer ? <div className="esg-info">
        <FundSprouts fond={fond} />
        <h3>{`${fond.eikespirer} av 5`}</h3>
        <div className="text">Eikas etiske merking sammenligner ESG-score på fondene i vår fondsliste.</div>
    </div> : <div className="esg-info -empty">
        <h3>Score finnes ikke</h3>
        <div className="text">Vi har desverre ikke informasjon om score for miljø og etikk for alle rente- og obligasjonsfond.</div>
    </div>; 
};
*/
