import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from '../card';
import ActionButton from '../action-button';
import Format from '../format-functions';
import SliderInput from '../common/slider-input';

class SparingPerMaanedSlider extends Component {
    static defaultProps = {
        timer: {},
    };

    static propTypes = {
        texts: PropTypes.object,
        bruttoAarsInntekt: PropTypes.number,
        nyPensjonVedOktSparing: PropTypes.number,
        endreMndSparing: PropTypes.func,
        beregnPensjonVedOktMndSparing: PropTypes.func,
        pensjonPerMnd: PropTypes.number,
        oktMndSparing: PropTypes.number,
        timer: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            loadingClass: ''
        };
    }

    UNSAFE_componentWillReceiveProps = newProps => {
        if (
            this.props.nyPensjonVedOktSparing !== newProps.nyPensjonVedOktSparing ||
            newProps.oktMndSparing === this.props.oktMndSparing
        ) {
            this.setState({
                loadingClass: ''
            });
        }
    };

    prosentAvDagensLonn = () => {
        const { bruttoAarsInntekt, pensjonPerMnd, nyPensjonVedOktSparing } = this.props;
        return bruttoAarsInntekt === 0 
            ? '--'
            : `${Math.round(((pensjonPerMnd + nyPensjonVedOktSparing) / (bruttoAarsInntekt / 12)) * 100)}%`;
    };

    beregnPensjonVedMndSparing = (event) => {
        const { beregnPensjonVedOktMndSparing, endreMndSparing, timer } = this.props;
        endreMndSparing(event.target.value);
        clearTimeout((timer.beregnPensjonVedMndSparing || 0));
        timer.beregnPensjonVedMndSparing = setTimeout( () => {
            this.setState({ loadingClass: 'loaded-fading' });
            beregnPensjonVedOktMndSparing();
        }, 300);
    };

    render() {
        const { loadingClass } = this.state;
        const { texts, nyPensjonVedOktSparing, oktMndSparing } = this.props;
        return (
            <Card header={texts.get('bleDetMindreEnnDuTrodde')}>
                <div className="sparing-per-maaned">
                    <div className="sparing-seksjon">
                        Du kan få
                        <div className={`pensjons-tall ${loadingClass}`}>{Format.currency(nyPensjonVedOktSparing)}</div>
                        mer per måned
                    </div>
                    <div className="sparing-seksjon">
                        da vil du få
                        <div className={`pensjons-tall ${loadingClass}`}>{this.prosentAvDagensLonn()}</div>
                        av dagens lønn
                    </div>
                    Dersom du sparer:
                    <span className="pensjons-tall slider-color">{Format.currency(oktMndSparing)}</span>
                    per måned i fondet Eika Spar
                    <SliderInput
                        id="slider1"
                        min={0}
                        max={9999}
                        step={100}
                        value={oktMndSparing}
                        onChange={e => this.beregnPensjonVedMndSparing(e)}
                    />
                    <ActionButton action="veiviser/kjopFond?ignorlength=1" text={texts.get('startMaanedligSparing')} />
                </div>
            </Card>
        );
    }
}

export default SparingPerMaanedSlider;
