export const SETT_SIDE_TITTEL = 'SETT_SIDE_TITTEL';
export const SET_SERVICE_MESSAGE = 'SET_SERVICE_MESSAGE';

export const settSideTittel = (sideTittel, underTittel) => {
    return {
        type: SETT_SIDE_TITTEL,
        sideTittel,
        underTittel,
    };
};

/******************************************************************************
******************************************************************************/
export const getServiceMessage = ( app='MSS' ) => async (dispatch) => {
    let result = null;

    const url = `/sparing-min-side-web/rest/resource/messages/${app}`;
    try {
        result = await fetch( url ).then( (response) => {
            if ( response.status !== 200 ) { return null; }
            return response.json();
        }).catch( () => null );
    } catch ( error ) {
        result = false;
    }
    
    return dispatch({ type: SET_SERVICE_MESSAGE, data: result });
};
