import React from 'react';
import PropTypes from 'prop-types';

import LinkCard from '../link-card';
import Format from '../format-functions';

require('../../styles/klient-konto.scss');

const KlientKontoKort = props => (
    <div className="klient-konto-kort">
        <LinkCard link={`klientkonto/${props.portefoljeId}`} gradient={props.gradient} isDisabled={!!props.linkDisabled}>
            <div className="card-content-table">
                <div className="card-content-table-cell">
                    <div className="title">{props.texts.get('tilknyttetBankkonto')}</div>
                    <div className="urealisert-gevinst">
                        <div className="tekst">
                            <span className="ikon-info-24" />
                            {props.texts.get('tilgjengeligForKjopEllerUttak')}
                        </div>
                    </div>
                </div>
                <div className="card-content-table-cell">
                    <div className="total-verdi">
                        <div className="tekst">{props.texts.get('saldo')}</div>
                        <div className="verdi">{Format.currency(props.kontantBeholdning)}</div>
                    </div>
                </div>
            </div>
        </LinkCard>
    </div>
);

KlientKontoKort.propTypes = {
    texts: PropTypes.object.isRequired,
    portefoljeId: PropTypes.string.isRequired,
    kontantBeholdning: PropTypes.number.isRequired,
    gradient: PropTypes.object,
    // eslint-disable-next-line react/no-typos
    linkDisabled: PropTypes.any
};

export default KlientKontoKort;
