import HelperFunctions from '../../components/helper-functions';

const visRiktigTekstBasertPaaOrdreTyper = (ventendeTransaksjoner, texts) => {
    const hentTekstKey = (ventendeTransaksjoner) => {
        const harKjop = ventendeTransaksjoner.some((a) => a.type === 'KJOP');
        const harSalg = ventendeTransaksjoner.some((a) => a.type === 'SALG');

        if (harKjop && harSalg) {
            return 'ordreVenterPaaOgBliUtfort';
        }

        if (harKjop) {
            return 'paaveiTilFondetDitt';
        }

        return 'paaveiUtAvFondetDitt';
    };

    const antall = ventendeTransaksjoner.length;
    const textKey = hentTekstKey(ventendeTransaksjoner);

    return HelperFunctions.VariableInjection(texts.get(textKey), { antall });
};

export default visRiktigTekstBasertPaaOrdreTyper;
