import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';

import {
    IKKE_EIER_AV_KONTONUMMER,
    KUNNE_IKKE_OPPRETTE_ORDRE,
    KUNNE_IKKE_OPPRETTE_SIGNERING
} from '../../../actions/signerings-ordre';
import { KLIENTKONTO } from '../../../domain/account-types';
import SelgFondSkjema from './selg-fond-skjema';
import SigneringsIframe from '../../common/signerings-iframe';
import ExpandableCard from '../../expandable-card';
import HelperFunctions from '../../helper-functions';
import SkattemessigKostpris from '../skattemessig-kostpris';
import * as kundevurderingConst from '../../../domain/kundevurdering';
import KundevurderingStopp from '../../KundevurderingStopp';

import { ASK } from '../../../domain/portefolje-types';

const VIS_SALGS_SKJEMA = 'VIS_SALGS_SKJEMA';
const LASTER_SIGNERING = 'LASTER_SIGNERING';
const OPPRETTER_SALGSORDRE = 'OPPRETTER_SALGSORDRE';
const VIS_SIGNERING_IFRAME = 'VIS_SIGNERING_IFRAME';

const KAN_IKKE_SELGE_FOND_UTEN_BEHOLDNING = 'KAN_IKKE_SELGE_FOND_UTEN_BEHOLDNING';

class SelgFond extends Component {
    static propTypes = {
        portefoljeId: PropTypes.string.isRequired,
        portfolioBaseCode: PropTypes.string.isRequired,
        kontoer: PropTypes.array.isRequired,
        texts: PropTypes.object.isRequired,
        fond: PropTypes.object.isRequired,
        expandItem: PropTypes.object.isRequired,
        settKvitteringsInnhold: PropTypes.func.isRequired,
        portefoljeType: PropTypes.string.isRequired,
        settEpost: PropTypes.func.isRequired,
        opprettSalgsOrdre: PropTypes.func.isRequired,
        fullforOrdre: PropTypes.func.isRequired,
        onFeiletSignering: PropTypes.func.isRequired,
        resetOrdreProsess: PropTypes.func.isRequired,
        skattemessigKostpris: PropTypes.object.isRequired,
        hentSkattemessigKostpris: PropTypes.func.isRequired,
        bankregnr: PropTypes.string.isRequired,
        sparemaal: PropTypes.array.isRequired,
        epost: PropTypes.string,
        signeringsUrl: PropTypes.string,
        ordreId: PropTypes.string,
        feilmelding: PropTypes.string,
        kundevurdering: PropTypes.string,
        sperre: PropTypes.string,
        foreignCurrencyForceToSellAll: PropTypes.bool,
        fundCurrency: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentState: VIS_SALGS_SKJEMA
        };
    }

    UNSAFE_componentWillReceiveProps = newProps => {
        if (this.props.ordreId !== newProps.ordreId && newProps.ordreId) {
            this.setState({
                currentState: LASTER_SIGNERING
            });
        }

        if (this.props.signeringsUrl !== newProps.signeringsUrl && newProps.signeringsUrl) {
            this.setState({
                currentState: VIS_SIGNERING_IFRAME
            });
        }
    };

    opprettSalgsOrdre = data => {
        if (data.bankkontoType === KLIENTKONTO) {
            this.resetForm();
        } else {
            this.setState({
                currentState: OPPRETTER_SALGSORDRE
            });
        }

        this.props.opprettSalgsOrdre(data);
    };

    fullforOrdre = () => {
        this.props.fullforOrdre(this.props.ordreId);
        this.resetForm();
    };

    handleFeiletSignering = code => {
        this.props.onFeiletSignering(code);
        this.resetForm();
    };

    resetForm = () => {
        this.props.expandItem.onExpand(this.props.expandItem.id);
        this.setState({
            currentState: VIS_SALGS_SKJEMA
        });

        this.props.resetOrdreProsess();
    };

    visFeilmelding = feilmelding => {
        return (
            <div>
                <div>{this.props.texts.get(feilmelding)}</div>
                <div className="expandable-card-footer">
                    <button type="button" className="btn btn-flat" onClick={this.resetForm}>
                        {this.props.texts.get('lukk')}
                    </button>
                </div>
            </div>
        );
    };

    visInfoBoks = () => {
        if (this.props.portefoljeType === ASK) {
            return (
                <SkattemessigKostpris
                    infoTekst={this.props.texts.get('duKanTaUtInntil')}
                    subInfoTekst={this.props.texts.get('infotekstSalgFond')}
                    feilTekst={this.props.texts.get('skattemessigKostprisFeilet')}
                    portfolioBaseCode={this.props.portfolioBaseCode}
                    skattemessigKostpris={this.props.skattemessigKostpris}
                    hentSkattemessigKostpris={this.props.hentSkattemessigKostpris}
                    bankregnr={this.props.bankregnr}
                />
            );
        }

        return null;
    };

    visExpandableCardContent = () => {
        if (this.state.currentState === OPPRETTER_SALGSORDRE) {
            if (this.props.feilmelding === KUNNE_IKKE_OPPRETTE_ORDRE) {
                return this.visFeilmelding(KUNNE_IKKE_OPPRETTE_ORDRE);
            }
            if (this.props.feilmelding === IKKE_EIER_AV_KONTONUMMER) {
                return this.visFeilmelding('Du står ikke oppført som eier av kontonummeret oppgitt.');
            }

            return <Loading title={this.props.texts.get('oppretterSalgsOrdre')} />;
        }

        if (this.state.currentState === LASTER_SIGNERING) {
            if (this.props.feilmelding === KUNNE_IKKE_OPPRETTE_SIGNERING) {
                return this.visFeilmelding(KUNNE_IKKE_OPPRETTE_SIGNERING);
            }

            return <Loading title={this.props.texts.get('lasterSignering')} />;
        }

        if (this.state.currentState === VIS_SIGNERING_IFRAME) {
            return (
                <SigneringsIframe
                    signeringsUrl={this.props.signeringsUrl}
                    fullforSignertHandling={this.fullforOrdre}
                    onFeiletSignering={this.handleFeiletSignering}
                />
            );
        }

        if (this.state.currentState === VIS_SALGS_SKJEMA) {
            if ( this.props.kundevurdering === kundevurderingConst.MA_BEHANDLES_MANUELT || this.props.sperre ) {
                return <KundevurderingStopp
                    {...this.props} 
                    title={this.props.sperre ? this.props.texts.get(`${this.props.sperre.toLowerCase()}SperretKontoTitle`) : ''}
                    text={this.props.sperre ? this.props.texts.get(`${this.props.sperre.toLowerCase()}SperretKontoText`) : ''}
                />
            }

            if (this.props.fond.totalVerdi === 0) {
                return this.visFeilmelding(KAN_IKKE_SELGE_FOND_UTEN_BEHOLDNING);
            }

            return <div>
                <SelgFondSkjema
                    epost={this.props.epost}
                    fond={this.props.fond}
                    kontoer={this.props.kontoer}
                    opprettSalgsOrdre={this.opprettSalgsOrdre}
                    portefoljeId={this.props.portefoljeId}
                    portefoljeType={this.props.portefoljeType}
                    settEpost={this.props.settEpost}
                    texts={this.props.texts}
                    settKvitteringsInnhold={this.props.settKvitteringsInnhold}
                    resetForm={this.resetForm}
                    bankregnr={this.props.bankregnr}
                    sparemaal={this.props.sparemaal}
                    foreignCurrencyForceToSellAll={this.props.foreignCurrencyForceToSellAll}
                    fundCurrency={this.props.fundCurrency}
                />
                {this.visInfoBoks()} 
            </div>          
        }

        return '';
    };

    render() {
        const kortTittel = HelperFunctions.VariableInjection(this.props.texts.get('selgFond'), {
            fond: this.props.fond.navn
        });

        return (
            <ExpandableCard header={kortTittel} expandItem={this.props.expandItem} ikon="ikon-betale-24">
                {this.visExpandableCardContent()}
            </ExpandableCard>
        );
    }
}

export default SelgFond;
