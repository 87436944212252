/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import DonutChartWithCheckboxLegend from '../../components/charts/donut-chart-checkbox-legend';
import LeggTilKontoFraAnnenBank from '../../components/sparing/legg-til-konto-fra-annen-bank';
import { settSideTittel } from '../../actions/header';
import ColorFunctions from '../../components/color-functions';
import Feilmelding from '../../components/feilmelding';
import AppMessage from '../../components/AppMessage';
import Format from '../../components/format-functions';
import * as AccountTypes from '../../domain/account-types';

import { leggTilKontoFraAnnenBank, lagreStatusForKonto, slettKonto, endreKonto } from '../../actions/bank';

class MinBankSparing extends Component {
    static propTypes = {
        kontoer: PropTypes.array,
        texts: PropTypes.object,
        leggTilKontoFraAnnenBank: PropTypes.func,
        settSideTittel: PropTypes.func,
        lagreStatusForKonto: PropTypes.func,
        slettKonto: PropTypes.func,
        endreKonto: PropTypes.func,
        errors: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    UNSAFE_componentWillMount = () => {
        this.props.settSideTittel(this.props.texts.get('minBankSparing'));

        if (this.props.kontoer) {
            this.setState({
                kontoer: this.aggregerDataForDonutChartWithCheckboxLegend(this.props.kontoer),
            });
        }
    };

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.kontoer) {
            this.setState({
                kontoer: this.aggregerDataForDonutChartWithCheckboxLegend(newProps.kontoer),
            });
        }
    };

    regnUtTotal = (kontoer) => {
        return kontoer.reduce((a, b) => a + (b.active ? b.value : 0), 0);
    };

    aggregerDataForDonutChartWithCheckboxLegend = (kontoer) => {
        return kontoer.map((konto, index) => {
            return {
                text: `${konto.navn}${konto.kontonummer ? ` (${konto.kontonummer})` : ''}`,
                value: konto.verdi,
                color: ColorFunctions.hentFarge(index),
                active: konto.aktiv,
                toggleable: true,
                editable: konto.bankkontoType === AccountTypes.EGENDEFINERT,
                id: konto.id,
            };
        });
    };

    leggTilNyKonto = (nyKonto) => {
        const uferdigKonto = {
            text: nyKonto.navn,
            value: nyKonto.verdi,
            active: false,
            toggleable: true,
            disabled: true,
            loading: true,
            id: 'utkast',
        };

        this.state.kontoer.push(uferdigKonto);

        this.setState({
            venterPaaIdTilNyKonto: true,
        });

        this.props.leggTilKontoFraAnnenBank(nyKonto);
    };

    sjekkForServerFeil = (errors) => {
        return errors.HENTE_KONTOER;
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.kontoer) {
            return (
                <div className="bank-saving-wrapper">
                    <AppMessage appMessage={this.props.serviceMessage} context="BANK_SAVING" />

                    <DonutChartWithCheckboxLegend
                        data={this.state.kontoer}
                        legendLabel={this.props.texts.get('visKontoIOversikt')}
                        legendText={this.props.texts.get('minBankSparingTekst')}
                        toggleActive={this.props.lagreStatusForKonto}
                        onDelete={this.props.slettKonto}
                        changeContent={this.props.endreKonto}
                        config={{
                            endreNavn: this.props.texts.get('endreKontoNavn'),
                            kontoNavnHjelpeTekst: this.props.texts.get('endreKontoNavnHjelpeTekst'),
                            endreVerdi: this.props.texts.get('endreKontoVerdi'),
                        }}
                    >
                        <div className="donut-labels">
                            <div className="label-section">
                                <span className="ikon-sparing-36" />
                                <div className="text">{this.props.texts.get('totalSparing')}</div>
                                <div className="value">{Format.currency(this.regnUtTotal(this.state.kontoer))}</div>
                            </div>
                        </div>
                    </DonutChartWithCheckboxLegend>
                    <LeggTilKontoFraAnnenBank
                        texts={this.props.texts}
                        action={this.leggTilNyKonto}
                        aktiv={!this.state.venterPaaIdTilNyKonto}
                    />
                </div>
            );
        }
        return <Loading title={this.props.texts.get('lasterBankData')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Bank,
            ...state.Feilmeldinger,
            ...state.Texts,
            ...state.Header
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            leggTilKontoFraAnnenBank: (data) => {
                dispatch(leggTilKontoFraAnnenBank(data));
            },
            lagreStatusForKonto: (id, aktiv) => {
                dispatch(lagreStatusForKonto(id, aktiv));
            },
            slettKonto: (id) => {
                dispatch(slettKonto(id));
            },
            endreKonto: (id, text, value) => {
                dispatch(endreKonto(id, text, value));
            },
        };
    }
)(MinBankSparing);
