import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';

import Card from '../../card';

import GrafLinje from './graf-linje';
import GrafPunkter from './graf-punkter';
import HelperFunctions from '../../helper-functions';
import GrafFunksjoner from './graf-funksjoner';
import InnskuddsGrafIkon from '../../../images/graf1.png';
import PrestasjonsGrafIkon from '../../../images/graf2.png';
import Format from '../../format-functions';
import DateFunctions from '../../date-functions';

require('../../../styles/lineChart.scss');

class PrestasjonsGraf extends Component {
    static propTypes = {
        texts: PropTypes.object,
        isin: PropTypes.string,
        portfolioBaseCode: PropTypes.string,
        hentHistorikk: PropTypes.func,
        historikk: PropTypes.object,
        height: PropTypes.number,
        xAxisLineMargin: PropTypes.number,
        tittel: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            height: props.height || 150,
            xAxisLineMargin: props.xAxisLineMargin || 15
        };
    }

    UNSAFE_componentWillReceiveProps = newProps => {
        if (newProps.historikk) {
            this.initialiserGraf(newProps);
        }
    };

    UNSAFE_componentWillMount() {
        if (this.props.historikk) {
            this.initialiserGraf(this.props);
        } else {
            this.props.hentHistorikk(this.props.portfolioBaseCode, this.props.isin);
        }
    }

    initialiserGraf = props => {
        const { historikk } = props;

        const innskuddsVerdier = historikk.manedligInnskuddsVerdier;
        const totalVerdier = historikk.manedligTotalVerdier;

        const width = Math.min(Math.max(0, document.documentElement.clientWidth - 40), 568);

        const padding = 5;
        const numberOfYAxisIntervals = GrafFunksjoner.getYAxisIntervals(this.state.height, this.state.xAxisLineMargin);
        const bounds = this.finnMinOgMaks(innskuddsVerdier, totalVerdier, numberOfYAxisIntervals);

        // eslint-disable-next-line react/no-access-state-in-setstate
        const viewBox = `0 0 ${width} ${this.state.height}`;
        // eslint-disable-next-line react/no-access-state-in-setstate
        const xAxisLines = this.createXAxisGridLines(this.state.height, this.state.xAxisLineMargin, padding, width);
        // eslint-disable-next-line react/no-access-state-in-setstate
        const xAxisLabels = this.createYAxisLabels(this.state.height, this.state.xAxisLineMargin, bounds, padding);

        const beholdningsGrafer = this.genererGraferForTotalBeholdning(totalVerdier, innskuddsVerdier, padding, width);

        const innskudd = this.calculateInnskuddPoints(innskuddsVerdier, padding, width);

        this.setState({
            viewBox,
            xAxisLines,
            xAxisLabels,
            beholdningsGrafer,
            innskudd,
            bounds
        });
    };

    createYAxisLabels = (height, lineMargin, bounds) => {
        const numberOfLines = GrafFunksjoner.getYAxisIntervals(height, lineMargin);
        const labels = [];

        let yAxisLabelPlacement = 12;
        let value = bounds.max;

        for (let i = 0; i < numberOfLines; i += 1) {
            const key = `xAxisLabel-${i}`;

            labels.push(
                <text key={key} x="0" y={yAxisLabelPlacement}>
                    {Format.number(value)}
                </text>
            );

            yAxisLabelPlacement += 15;
            value -= bounds.interval;
        }

        return labels;
    };

    createXAxisGridLines = (height, lineMargin, padding, width) => {
        const numberOfLines = GrafFunksjoner.getYAxisIntervals(height, lineMargin);
        const lines = [];

        let yAxis = lineMargin / 2;

        for (let i = 0; i < numberOfLines; i += 1) {
            const key = `xAxisLine-${i}`;

            lines.push(
                <g key={key} className="grid">
                    <line x1={padding} y1={yAxis} x2={width - padding} y2={yAxis} />
                </g>
            );

            yAxis += lineMargin;
        }

        return lines;
    };

    calculateIntervalBetweenPoints = (width, numberOfXAxisPoints, padding) => {
        return (width - padding * 2) / (numberOfXAxisPoints - 1);
    };

    beregnKryssningsPunkt = (grafA, grafB, interval, positionXAxis) => {
        const deltaA = (grafA.a2 - grafA.a1) / interval;
        const deltaB = (grafB.b2 - grafB.b1) / interval;
        const intersection = (grafA.a1 - grafB.b1) / (deltaB - deltaA);
        return { x: positionXAxis - interval + intersection, y: grafA.a1 + deltaA * intersection };
    };

    genererGraferForTotalBeholdning = (_totalBeholdning, _innskudd, padding, width) => {
        const interval = this.calculateIntervalBetweenPoints(width, _totalBeholdning.length, padding);
        const graphs = [];
        let graph = [];
        let positionXAxis = padding;

        let prevGainState = _totalBeholdning[0] > _innskudd[0];

        for (let i = 0; i < _totalBeholdning.length; i += 1) {
            const yValue = _totalBeholdning[i] === 0 ? _totalBeholdning[i - 1] : _totalBeholdning[i];
            const gain = yValue > _innskudd[i];
            if (prevGainState === gain) {
                graph.push({
                    x: positionXAxis,
                    y: yValue
                });
            } else {
                const kryssningsPunkt = this.beregnKryssningsPunkt(
                    { a1: _totalBeholdning[i - 1], a2: yValue },
                    { b1: _innskudd[i - 1], b2: _innskudd[i] },
                    interval,
                    positionXAxis
                );
                graph.push(kryssningsPunkt);
                graphs.push({ graf: graph, positiveGain: prevGainState });
                graph = [];
                graph.push(kryssningsPunkt);
                graph.push({
                    x: positionXAxis,
                    y: yValue
                });
            }
            positionXAxis += interval;
            prevGainState = gain;
        }

        graphs.push({ graf: graph, positiveGain: prevGainState });
        return graphs;
    };

    calculateInnskuddPoints = (values, padding, width) => {
        const interval = this.calculateIntervalBetweenPoints(width, values.length, padding);

        let xAxisPosition = padding;

        return values.map((v, index, array) => {
            const point = { x: xAxisPosition, y: v === 0 ? array[index - 1] : v };
            xAxisPosition += interval;
            return point;
        });
    };

    finnMinOgMaks = (innskudd, beholdning, numberOfYAxisIntervals) => {
        const lowest = Math.min(HelperFunctions.getMinValue(beholdning), HelperFunctions.getMinValue(innskudd));

        const highest = Math.max(HelperFunctions.getMaxValue(innskudd), HelperFunctions.getMaxValue(beholdning));

        const min = GrafFunksjoner.lowerBound(lowest);
        const interval = GrafFunksjoner.getYAxisIntervalValue(numberOfYAxisIntervals, min, highest);
        const max = min + interval * (numberOfYAxisIntervals - 1);

        const diff = max - min;
        const padding = diff / (numberOfYAxisIntervals - 1) / 2;
        return {
            min,
            max,
            paddedMax: max + padding,
            paddedMin: min - padding,
            interval
        };
    };

    getGraphKey = i => {
        return `graf-${i}`;
    };

    render() {
        if (this.props.historikk) {
            return (
                <div className="prestasjons-graf-kort">
                    <div className="din-utvikling-tittel">{this.props.tittel}</div>
                    <Card>
                        <div>
                            <div className="presentasjons-graf-tittel">Verdi</div>
                            <div className="prestasjons-graf">
                                <svg viewBox="0 0 40 150" className="labels">
                                    {this.state.xAxisLabels}
                                </svg>
                                <svg viewBox={this.state.viewBox} className="graph" preserveAspectRatio="none">
                                    {this.state.xAxisLines}

                                    {this.state.beholdningsGrafer.map((graf, index) => {
                                        return (
                                            <GrafLinje
                                                key={this.getGraphKey(index)}
                                                values={graf.graf}
                                                bounds={{
                                                    max: this.state.bounds.paddedMax,
                                                    min: this.state.bounds.paddedMin
                                                }}
                                                height={this.state.height}
                                                color={graf.positiveGain ? '#85be02' : '#d1051e'}
                                            />
                                        );
                                    })}

                                    <GrafLinje
                                        key="innskudd"
                                        values={this.state.innskudd}
                                        bounds={{ max: this.state.bounds.paddedMax, min: this.state.bounds.paddedMin }}
                                        height={this.state.height}
                                        color="#2588ab"
                                        thickness="2"
                                    />

                                    <GrafPunkter
                                        values={this.state.innskudd}
                                        bounds={{ max: this.state.bounds.paddedMax, min: this.state.bounds.paddedMin }}
                                        height={this.state.height}
                                    />
                                </svg>
                            </div>
                            <div className="presentasjons-graf-footer">
                                <div className="presentasjons-graf-datoer">
                                    <div className="start-dato">
                                        {HelperFunctions.capitalizeFirstLetter(
                                            DateFunctions.skrivUtDato(
                                                this.props.historikk.periode.startDato,
                                                this.props.texts
                                            )
                                        )}
                                    </div>
                                    <div className="slutt-dato">
                                        {HelperFunctions.capitalizeFirstLetter(
                                            DateFunctions.skrivUtDato(
                                                this.props.historikk.periode.sluttDato,
                                                this.props.texts
                                            )
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <img alt="Innskuddsgraf" src={InnskuddsGrafIkon} />
                                    {this.props.texts.get('dineInnskudd')}
                                </div>
                                <div>
                                    <img alt="prestasjons graf" src={PrestasjonsGrafIkon} />
                                    {this.props.texts.get('prestasjonsgrafTapGevinst')}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        }

        if (this.props.historikk === undefined) {
            return (
                <div className="prestasjons-graf-kort">
                    <Loading />
                </div>
            );
        }

        return <div />;
    }
}

export default PrestasjonsGraf;
