// @ts-nocheck // kiet fix it later

/** ****************************************************************************
 ***************************************************************************** */
export const generateId = (prefix=''): string => {
    return `${prefix || 'app'}-${new Date().getTime()}-${Math.floor(Math.random() * 10000 + 1)}-${Math.floor(
        Math.random() * 10000 + 1
    )}`;
};

export const capitalize = (text=''): string => {
    if (typeof text !== 'string') { return ''; }

    const reg = /^(\s+)?([a-zæøå])/;
    const result = text.match(reg);
    if (result && result[2]) {
        text = text.replace(result[2], result[2].toUpperCase());
    }
    return text;
};

export const getWindowSize = () => {
    const size = [0, 0];
    if (!window.innerWidth) {
        // IE
        if (!(document.documentElement.clientWidth === 0)) {
            size[0] = document.documentElement.clientWidth;
            size[1] = document.documentElement.clientHeight;
        } else {
            size[0] = document.body.clientWidth;
            size[1] = document.body.clientHeight;
        }
    } else {
        size[0] = window.innerWidth;
        size[1] = window.innerHeight;
    }
    return size;
};

/** ****************************************************************************
 ***************************************************************************** */
export const getURLquery = ( url?: string ): any => {
    const matched =  (url || window.location.href).replace(/\?+/g, '?').match(/^([\w.\-\s_#%/:]+)\?(.*)/);
    if ( !matched || !matched[2] ) { return {}; }

    const splited = (decodeURIComponent(matched[2]) || '').replace(/#\?/g, '&').split('&');
    return splited.reduce( (query, text) => {
        const data = (text || '').match(/(\w+)=(.*)/);
        if ( !data || !data[1] || !data[2] ) { return query; }

        if ( query[data[1]] ) {
            query[data[1]] = (
                query[data[1]] instanceof Array ? query[data[1]] : [query[data[1]]]
            ).concat( data[2] );
        } else {
            query[data[1]] = data[2];
        }
        return query;
    }, {});
};

/** ****************************************************************************
 ***************************************************************************** */
export const isIE = () => {
    let m = null;
    if (navigator.appName.match('Microsoft Internet Explorer')) {
        m = navigator.appVersion.match(/MSIE\s([\d.]+)/);
    } else if (navigator.appName.match('Netscape')) {
        m = navigator.appVersion.match(/rv:([\d.]+)/);
    }
    return m && m[1] ? parseFloat(m[1]) : 0;
};

export const isEdge = () => {
    let m = null;
    if (navigator.userAgent.indexOf('Edge') > -1) {
        m = navigator.userAgent.match(/(^|\s)edge\/(\d+)/i);
    }
    return m && m[2] ? parseFloat(m[2]) : 0;
};

/** ****************************************************************************
  getOffset
***************************************************************************** */
export const getOffset = (target: any): number[] => {
    const size = [0, 0];
    if (target) {
        do {
            size[0] += target.offsetLeft || 0;
            size[1] += target.offsetTop || 0;
            target = target.offsetParent;
        } while (target);
    }
    return size;
};

/** ****************************************************************************
 ***************************************************************************** */
export const clearSelection = (): void => {
    // @ts-ignore
    if (window.getSelection) {
        // @ts-ignore
        window.getSelection().removeAllRanges();
    // @ts-ignore
    } else if (document.selection) {
        // @ts-ignore
        document.selection.empty();
    }
};

/** ****************************************************************************
 ***************************************************************************** */
export const sortList = (list: any, field?: string | string[], decreasing=false, numberTest=false): any => {
    if ( ! (list || []).length || ! field ) { return []; }

    const keys = field instanceof Array ? field : [field];
    const length = keys.length;
    let i = 0;
    // @ts-ignore
    return list ? list.sort((a, b) => {
        let z = 0;
        let x = '';
        let y = '';
        for (i = 0; i < length; i++) {
            // @ts-ignore
            x = numberTest ? parseFloat(`${a[keys[i]] || '0'}`) : `${a[keys[i]] || ''}`;
            // @ts-ignore
            y = numberTest ? parseFloat(`${b[keys[i]] || '0'}`) : `${b[keys[i]] || ''}`;

              // eslint-disable-next-line
            z = x < y ? -1 : x > y ? 1 : 0;
            if (z !== 0) { i = length; }
        }

        return z * (decreasing ? -1 : 1);
    }) : [];
};


/** ****************************************************************************
  Event
***************************************************************************** */
export const addEvent = (callback: (e: any) => void, target: any, type=''): void => {
    if (target) {
        if (typeof target.addEventListener !== 'undefined') {
            target.addEventListener(type, callback, false);
        } else if (typeof target.attachEvent !== 'undefined') {
            target.attachEvent(`on${type}`, callback);
        }
    }
};

export const removeEvent = ( myFunction: (e: any)=>void, target: any, type='' ): void => {
    if ( target ) {
        if (typeof target.removeEventListener !== 'undefined') {
            target.removeEventListener(type, myFunction);
        } else if (typeof target.detachEvent !== 'undefined') {
            target.detachEvent( `on${type}`, myFunction);
            //target.detachEvent(('on'+type), myFunction);
        }
    }
};

/** ****************************************************************************
 ***************************************************************************** */
export const isDesktop = (): boolean => {
    const size  = getWindowSize();
    return size[0] > 520; 
};

/** ****************************************************************************
 ***************************************************************************** */
export const setElementFocus = ( id='' ): void => {
    const node = id ? document.getElementById( id ) : null;
    if ( node ) { node.focus(); }
};

/** ****************************************************************************
 ***************************************************************************** */
export const isOnTest = (): boolean => {
    return /(localhost:|test.|127.0.0.1:8989)/i.test( window.location.href );
};

/** ****************************************************************************
 ***************************************************************************** */
export const isServerError = (error: any): boolean => {
    return (error || {}).LAST_SPARE_PROFIL_DATA || (error || {}).LAST_SAMTYKKE;
};

/** ****************************************************************************
 ***************************************************************************** */
/* eslint-disable */
export const createRegexp = (text='', g=0, i=0, b=0, f=0, ignorReplacing=false ): any => {
    if (text === '*') { return /.*/; }
    const v = ignorReplacing ? text : text
        .replace(/\*/, '.*')
        .replace(/\+/g, '\\+')
        .replace(/\(/g, '\\(')
        .replace(/\)/g, '\\)')
        .replace(/\?/g, '\\?')
        .replace(/\-/g, '\\-')
        .replace(/\[/g, '\\[')
        .replace(/\]/g, '\\]')
        .replace(/\$/g, '\\$');

    const m = g && i ? 'gi' : g || i ? (g ? 'g' : 'i') : '';
    const s = b ? (b === 2 ? '^' : b === 3 ? '(^|/|\\s+|,|\\()' : '(^|/|\\s+)') : '';
    const e = f ? (f === 2 ? '$' : f === 3 ? '($|/|\\s+|,|\\))' : '($|/|\\s+)') : '';
    return new RegExp(`${s}(${v})${e}`, m);
};
/* eslint-enable */

/** ****************************************************************************
=== FORMATATION ===
***************************************************************************** */
export const splitText = (text='', split=0): string[] => {
    let i = (text || '').length % split;
    const list = i ? [text.substr(0, i)] : [];
    for (i; i < text.length; i += split) {
        list.push(text.substr(i, split));
    }
    return list;
};

export const formatAmount = (value?: string | number, round=false): string => {
    if (!value) { return `${value ?? ''}`; }

    let text = (`${value}`).replace(/[\s]+/g, '');
    let number = parseFloat(text.replace(/,/g, '.'));

    if ( round ) {
        number = Math.round(number);
        text = `${number}`;
    }

    const isNegative = number < 0;
    if (isNegative) {
        number *= -1;
        text = text.replace(/-/g, '');
    }

    //if (number < 10000) { return `${value}`.replace( '.', ',' ); }

    let end = '';
    if (/(\.|,)\d+/.test(text)) {
        // @ts-ignore
        end = text.match(/((\.|,)\d+)/)[0];
        text = `${parseInt((`${number}`), 10)}`;
    }

    const output = (isNegative ? '-' : '') + (splitText(text, 3).join(' ') + end);
    return output.replace( '.', ',' );
};

export const formatBankAccount = (value=''): string => {
    if (!value) { return value; }
    const text = `${value}`.replace(/[\s-]+/g, '');
    if ( !/^[\s0-9]+$/.test(text) ) { return value; }
    return text.length <= 6 ? text :
        `${text.substring(0, 4)} ${text.substring(4, 6)} ${text.substring(6)}`;
};

export const formatDateToText = (date = new Date(), format = 'dd.mm.yyyy', ignorAddingZero = false): string => {
    const dayShortname = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];
    const monthShortname = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];
    const monthName = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];
    const month = date.getMonth();
    const list = [
        { reg: createRegexp('yyyy'), value: date.getFullYear() },
        { reg: createRegexp('mm'), value: (month + 1) },
        { reg: createRegexp('dd'), value: date.getDate() },
        { reg: createRegexp('hh'), value: date.getHours(), addZero: true },
        { reg: createRegexp('min'), value: date.getMinutes(), addZero: true },
        { reg: createRegexp('msec'), value: date.getMilliseconds(), addZero: true },
        { reg: createRegexp('sec'), value: date.getSeconds(), addZero: true },
        { reg: createRegexp('MMm'), value: monthShortname[month] },
        { reg: createRegexp('MM'), value: monthName[month] },
        { reg: createRegexp('day'), value: dayShortname[date.getDay()] },
    ];

    list.forEach((info) => {
        if (typeof info.value === 'number' && info.value < 10 && (info.addZero || !ignorAddingZero)) {
            info.value = `0${info.value}`;
        }
    });

    let output = format;
    list.forEach((info) => {
        if (!info.reg.test(output)) { return; }
        // @ts-ignore
        output = output.replace(info.reg, info.value);
    });
    return output;
};

/** ****************************************************************************
 ***************************************************************************** */
 export const getAvkastning = (totalVerdi=0, innskudd=0, wantText=false): string | number => {
    const value = totalVerdi && innskudd ? ((totalVerdi - innskudd) * 100) / innskudd : 0;
    return wantText ? `${formatAmount(value, true)} %` : Math.round(value);
 };

/******************************************************************************
  === cookie ===
******************************************************************************/
export const createCookie = ( name='', value='', day=1 ): void => {
    if ( ! name ) return;
    const cookie = [ `${name}=${value || ''}`];
    const d = new Date();
    const expires = day || 1000;
    d.setTime( d.getTime() + (expires*24*60*60*1000) );
    cookie.push( `expires=${d.toUTCString()}` );
    // cookie.push( `expires=${d.toGMTString()}` );
    cookie.push( 'path=/' );
    document.cookie = cookie.join('; ');
}

export const readCookie = ( name='' ): string => {
    const nameEQ =  `${name}=`;
    const ca = document.cookie.split(';');
    for ( let i=0; i<ca.length; i++ ) {
        let c = ca[i];
        while (c.charAt(0) === ' ') { c = c.substring(1,c.length); }

        if ( c.indexOf(nameEQ) === 0 ) {
            return c.substring(nameEQ.length,c.length);
        }
    }
    return '';
}

export const eraseCookie = ( name='' ): void => {
    return createCookie( name, '', -1 );
}

/** ****************************************************************************
 ***************************************************************************** */
export const roundUpValue = (value=0, unit=1): number => {
    if ( ! value || typeof(value) !== 'number' ) { return 0; }
    const mod = value % unit;
    value += (unit - mod);
    return value;
}

/** ****************************************************************************
 ***************************************************************************** */
/* eslint-disable */
export const openSmartspart = (key='', param=''): void => {
    const iOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod');
    const android = navigator.userAgent.match('Android');

    if ( iOS || android ) {
        const isSafari = navigator.vendor && 
            navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;

        const url = `smartspar://home?${param || 'action=none'}`;
        const appstore = iOS ? ( isSafari ?
            'https://itunes.apple.com/app/id1450266656' : 'https://apps.apple.com/no/app/smartspar/id1450266656' 
        ) : 'https://play.google.com/store/apps/details?id=no.eika.smartspar';

        const state: any = { 'timer': 0, stop: false, count: isSafari ? 2 : 1};
        const blur = () => {
            if ( --state.count ) { return; }

            clearTimeout( state.timer );
            state.stop = true;
        };
        window.removeEventListener('blur', blur)
        window.addEventListener('blur', blur);

        state.timer = setTimeout( () => {
            if (state.stop) { return; }
            if ( isSafari ) {
                window.location.replace(appstore);
            } else {
                window.location.href = appstore;
                // window.location = appstore;
            }

        //}, 2000);
        }, (isSafari ? 2000 : 500));

        window.location.href = url;
        // window.location = url;
    } else {
        const url = `${window.location.href.replace(/\/+$/g,'')}/${key.replace(/\-/g,'')}`;
        window.location.href = url;
    }
}
/* eslint-enable */

/** ****************************************************************************
 ***************************************************************************** */
export const getDrawDayList = (): {id: string; text: string; ariaLabel: string}[] => {
    return Array.from({ length: 28 }).map((x,i) => {
        const text = `Den ${i+1}. i hver måned`;
        return {id: `${i+1}`, text, ariaLabel: text};
    });
};

/** ****************************************************************************
 ***************************************************************************** */
export const scrollBodyTop = (where=0, duration=0): void => {
    // eslint-disable-next-line no-restricted-globals
    const top = where && !isNaN(where) && where > 0 ? where : 0;
    if ( duration ) {
        const render = (to, element) => {
            const start = element.scrollTop;
            const change = to - start;
            const startDate = + new Date();

            const easeInOutQuad = (t, b, c, d) => {
                let t2 = t;
                t2 /= d / 2;
                if (t2 < 1) { return (c / 2) * t2 * t2 + b; }

                t2 -= 1;
                return (-c / 2) * (t2 * (t2 - 2) - 1) + b;
            };

            const animateScroll = () => {
                const currentDate = +new Date();
                const currentTime = currentDate - startDate;
                element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
                if (currentTime < duration) {
                    requestAnimationFrame(animateScroll);
                } else {
                    element.scrollTop = to;
                }
            };
            animateScroll();
        }

        render(top, (document.scrollingElement || document.documentElement) );
        render(top, document.body);
    } else {
        document.body.scrollTop = top;
        document.documentElement.scrollTop = top;
    }
}

export const getPageAppending = (props: any): string => {
    const { storage={}, location={} } = props ?? {};
    const currentPath = location.pathname;
    const previousPath = storage.pathname;

    let move = 'none-appending';
    if ( previousPath && previousPath !== currentPath ) {
        if ( /\/\d{1,3}$/.test(currentPath) && /\/\d{1,3}$/.test(previousPath) ) {
            const currentNumber = parseInt(((currentPath.match( /(\d+)$/ ) || [])[1] || '0'), 10);
            const previousNumber = parseInt(((previousPath.match( /(\d+)$/ ) || [])[1] || '0'), 10);
            if ( previousNumber < currentNumber ) {
                move = 'left-appending';
            } else if ( previousNumber > currentNumber ) {
                move = 'right-appending';
            }
        } else {
            const reg = previousPath ? new RegExp(`^${previousPath.replace(/\//g,'\\/')}`,'i') : null;
            if ( reg && currentPath.match(reg) ) {
                move = 'left-appending';
            } else {
                move = 'right-appending';
            }
        }
    }

    if ( move === 'left-appending' ) {
        scrollBodyTop(0);
    }

    storage.pathname = currentPath;
    return move;
};

/** ****************************************************************************
 ***************************************************************************** */
/* eslint-disable */
export const debug = ( text='', value: any ): void => {
    const d = new Date();
    const id = 'my-debuggin-widget';
    let debug = document.getElementById(id); 
    let v = ''; 
    if ( !debug ) {
        const style = 'position:fixed;bottom:0;right:0;z-index:1000;border:1px solid red; '+
            'overflow:scroll;font-size:10px;line-height:11px;height:150px; width:210px; background-color:#fff';
        debug = document.createElement('div');
        debug.setAttribute('id', id);
        debug.setAttribute('style', style);
        document.body.appendChild( debug );
    }

    const p = debug.innerHTML || '';
    const t = d.getMinutes() + ':' + d.getSeconds();
    if ( value != null ) {
        if ( typeof(value) !== 'object' ) {
            v = value;
        } else if( value instanceof Array ) {
            v = value.join('<br/>');
        } else {
            const data = [];
            for ( const k in value ) { data.push( k + ' : ' + value[k]); }
            v = data.join( '<br/>' );
        }
    }

    debug.innerHTML = t + '<br/>' + text + '<br/>' + v + '<div>&nbsp;</div>' + p;
}
/* eslint-enable */
