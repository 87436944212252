import React from "react";
import { Term } from "src/domain/Types";
import { useTerms } from "./term-context";
import './consents.scss';

export default function GDPR() {
	const terms = useTerms();

	if (terms === undefined || terms === null) {
		return null;
	}

	if (terms.some((term: Term) => term.consentCode === "GDPR_08_2024")) {
		return (
			<div className="vilkaar-tekst">
				{/* TODO: find header class from general terms or finance law, this looks wrong */}
				<h2 className="h3 centered">Personvern</h2>
				<p>
					Eika Kapitalforvaltning er ansvarlig for behandling av
					personopplysningene som oppgis og hentes inn. Vi trenger dine
					opplysninger for å kunne oppfylle lovpålagte krav (eksempelvis til
					kundekontroll) og for å kunne gjennomføre avtalt transaksjon med deg.
				</p>
				<p>
					Vi lagrer opplysningene så lenge det er påkrevet i lov eller forskrift
					eller så lenge det kan oppstå et erstatningskrav knyttet til
					transaksjonen. Når du har gjennomført transaksjoner hos oss, kan vi
					bruke opplysninger om hvilke produkter du har for å tilpasse vår
					kommunikasjon med deg på våre nettsider.
				</p>

				<h3 className="h4">Spørsmål om personvern?</h3>
				<p>
					Informasjon om dine rettigheter rundt blant annet innsyn, samtykke,
					dataportabilitet, klage og sletting finner du på vår&nbsp;
					<a
						href="https://www.eika.no/personvern-cookies"
						target="_blank"
						rel="noopener noreferrer"
					>
						personvernside
					</a>
					.
				</p>
				<p>
					Vår personvernsrådgiver hjelper deg gjerne og kan kontaktes på
					e-post:&nbsp;
					<a href="mailto:personvern.forvaltning@eika.no">
						personvern.forvaltning@eika.no
					</a>
				</p>
			</div>
		);
	}
	return null;
}

