import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { StandardButton, LinkButton, ButtonTypes } from '@eika/button';
import { InfoMessage } from '@eika/attention-message';
import { MarketCard } from '@eika/card';
import { Loader } from '@eika/loader';
import { Link, LinkType } from '@eika/link';
import { IconTypes } from '@eika/icon';
import Skeleton from 'react-loading-skeleton'
import Card from '../Card/Card';
import Message from '../Message/Message';
import FlexTextHolder from '../FlexTextHolder/FlexTextHolder';
import { formatAmount } from '../util/Functions';
import { getContactUsUrl } from '../util/BusinessFunctions';
import Constant from '../../static/data/Constant';

import 'react-loading-skeleton/dist/skeleton.css';
import './ShareComponents.scss';

/** ************************************************************************
 ************************************************************************* */
interface HeaderLinkProps {
    text: string;
    title?: string;
    length?: number;
    url?: string;
    keepQuery?: boolean;
};

export const HeaderLink = ({length=1, text, title='', url='', keepQuery=false }: HeaderLinkProps): JSX.Element => {
    const splited = window.location.href.split('/');
    const query = (window.location.href.match( /.*(\?.*)/ ) || [])[1] || '';

    Array.from({length}).forEach( () => splited.pop() );
    return <div className="header-link-wrapper">
        <a href={`${url || splited.join('/')}${keepQuery ? query : ''}`} className="header-link-item" title={title}>{text}</a>
    </div>
};

/** ************************************************************************
 ************************************************************************* */
interface FooterButtonProps {
    click?: (e: any, k: string, d?: any, f?: any) => void;
    next?: string;
    action?: string;
    fix?: boolean;
    fixForMobile?: boolean;
    cancel?: string;
    cancelAction?: string;
    link?: boolean;
    nextNegative?: boolean;
    namespace?: string;
};

export const FooterButton = (props: FooterButtonProps): JSX.Element => {
    const { 
        click, fix=false, fixForMobile=false, next='Neste', action='next', 
        cancel, link=false, nextNegative=false, cancelAction='cancel', namespace,
    } = props;

    return <div className={classNames('footer-button-wrapper', namespace, {
        '-fix': fix,
        '-fix-for-mobile': fixForMobile,
    })}>
        { !!cancel && !!click && <LinkButton onClick={(e) => { click(e, cancelAction)}}>{cancel}</LinkButton> }
        { !!next && <>
            { !!link && <Link href={action} linkType={LinkType.BUTTON_STANDARD_POSITIVE} icon={IconTypes.EKSTERN_24}>{next}</Link> }
            { !!click && <StandardButton buttonType={nextNegative ? ButtonTypes.STANDARD_NEGATIVE : undefined} onClick={(e) => { click(e, action)}}>{next}</StandardButton> }
        </>}
    </div>
};

/** ************************************************************************
 ************************************************************************* */
interface ReturnPieProps {
    value?: number | string;
    arrow?: boolean;
    title?: string;
    namespace?: string;
};

export const ReturnPie = ({ value=0, arrow, title='', namespace }: ReturnPieProps): JSX.Element => {
    const percent = typeof(value) !== 'number' ? 0 : Math.round( value );
    return <span aria-hidden="true" title={title} data-value={percent} className={classNames(`return-pie -percent-${percent < 0 ? (100 + percent) : percent}`, namespace, {
        '-negative': percent < 0,
        '-positive': percent > 0,
        '-zero': percent === 0,
        '-view-arrow': !!arrow, 
    })}><span/></span>;
};

/** ************************************************************************
 ************************************************************************* */

interface SummaryCardProps {
    /* eslint-disable */
    totalVerdi?: number | string;
    avkastning?: number | string;
    innskudd?: number | string;
    urealisertGevinst?: number | string;
    namespace?: string;
    children?: any;
    warning?: string;
    innskuddName?: string;
    displayDetail?: boolean;
    /* eslint-enable */

};

export const SummaryCard = (props: SummaryCardProps): JSX.Element => {
    return <ul className={classNames('summary-card-wrapper', props.namespace)}>
        { !!props.avkastning && <li className="pie-holder">
            <ReturnPie value={props.avkastning} namespace="summary" arrow />
        </li> } 
        <li className="info-holder">
            { [
                // {id: 'avkastning', name: '', namespace: 'avkastning', unit: '%', label: 'Avkastning'},
                {id: 'innskudd', name: props.innskuddName || 'Innskudd', namespace: 'innskudd', unit: ''},
                {id: 'urealisertGevinst', name: 'Verdiøkning', namespace: 'gevins', unit: ''},
            ].map( (d:any) => {
                // @ts-ignore
                const value = formatAmount(props[d.id], true);
                return <div key={d.id} className={`info-item -${d.namespace}`} aria-label={d.label ? `${d.label} ${value} ${d.unit}`.trim() : ''}>
                    { d.name ? <>
                        { `${d.name} `}
                        <strong>{ `${value} ${d.unit}`.trim() }</strong>
                    </> : <> { `${value} ${d.unit}`.trim() }</> }
                </div>
            })}

            { props.children }
        </li>
    </ul>
};

export const SummaryHeader = (props: SummaryCardProps): JSX.Element => {
    const totalValue = formatAmount( props.totalVerdi, true);
    const gain = +(props.urealisertGevinst|| 0)
    const parsedGain = formatAmount(gain, true);

    return <ul className={classNames('summary-header-wrapper', props.namespace)}>
        <li aria-label={`Totalverdi ${totalValue}`}>
            <div id="summary-header-totalverdi-label">Totalverdi</div>
            <div aria-labelledby="summary-header-totalverdi-label" className="summary-header-value">{totalValue}</div>
        </li>
        {parsedGain !== '0' && <li aria-label={`gevinst ${gain}`}>
            <div className={classNames('info-item', '-gevinst', '-with-arrow', {'-positive': +gain >= 0, '-negative': +gain < 0 })}
                 aria-label={`gevinst ${gain}`}>
                {parsedGain}
            </div>
        </li> }

        { !!props.displayDetail && <li className="info-holder">
            { [
                {id: 'innskudd', name: props.innskuddName || 'Innskudd', namespace: 'innskudd', unit: ''},
                {id: 'urealisertGevinst', name: 'Verdiøkning', namespace: 'gevins', unit: ''},
            ].map( (d:any) => {
                // @ts-ignore
                const value = formatAmount(props[d.id], true);
                return <div key={d.id} className={`info-item -${d.namespace}`} aria-label={d.label ? `${d.label} ${value} ${d.unit}`.trim() : ''}>
                    { d.name ? <>
                        { `${d.name} `}
                        <strong>{ `${value} ${d.unit}`.trim() }</strong>
                    </> : <> { `${value} ${d.unit}`.trim() }</> }
                </div>
            })}
        </li> }
    </ul>
};

/** ************************************************************************
 ************************************************************************* */
interface SummaryTotalValueProps {
    label: string;
    value: string;
    message?: string;
    namespace?: string;
    click?: (e: MouseEvent) => void;
};

export const SummaryTotalValue = ({label, value, message, namespace, click}: SummaryTotalValueProps): JSX.Element => {
    const content = <div className={`total-value ${namespace || 'normal'}`}>
        <span id="summary-totalt-verdi-label" className={`total-value-label -${click ? 'info-text-link' : 'info-text-label'}`}>{label}</span>
        { value === null && !!message ?
            <Message type="WarningMessage" text={message} icon /> :
            <span aria-labelledby="summary-totalt-verdi-label">{value}</span>
        }
    </div>;

    return click ? <a href="#" role="button" onClick={(e: MouseEvent) => { click(e); }}>
        {content}
    </a> : content;
};

/** ************************************************************************
 ************************************************************************* */
export interface LinkListItemProps {
    id: string;
    name: string; 
    type?: number;
    icon?: IconTypes;
    url?: string;
    dataTrackId?: string;
    dataTrackLinkname?: string;
};

interface LinkListProps {
    list?: LinkListItemProps[];
    namespace?: string;
    click?: (e: any, k: string) => void;
};

export const LinkList = ({ list, namespace, click=()=>{} }: LinkListProps): JSX.Element | null => {
    return (list || []).length ? <div className={classNames('link-list-wrapper', namespace)}>
        <ul className="link-list">
            { (list || []).map( (d: LinkListItemProps) => {
                const attr = {icon: d.icon} as Record<string, string>;
                if ( d.dataTrackId ) {
                    attr['data-track-id'] = d.dataTrackId;
                }
                if ( d.dataTrackLinkname ) {
                    attr['data-track-linkname'] = d.dataTrackLinkname;
                }

                return <li key={d.id}>
                    {d.url ? <Link {...attr} href={d.url}>{d.name}</Link> : <LinkButton {...attr} onClick={(e) => { click(e, d.id)}}>{d.name}</LinkButton>}
                </li>
            })}
        </ul>
    </div> : null;
};

/** ************************************************************************
 ************************************************************************* */
interface SectionSpareavtaleProps {
    list?: any;
    max?: number;
    children?: any;
    click?: (e: any, k: string, d: any) => void;
    fullData?: boolean;
    extentionCountText?: string;
};

export const SectionSpareavtale = ({ list, children, max, click, fullData, extentionCountText='' }: SectionSpareavtaleProps): JSX.Element => {
    if ( (list || []).length === 0  ) {
        return <Message type="tips" text="Et lite beløp hver måned kan bli mye over tid.">{children}</Message>;
    }

    const text = [{ 
        element: <span className="text section-title">Månedlig sparing</span> 
    }, { 
        element: max && list.length > max ? 
            <span className="text font-normal opacity -level-6">{`${max} av ${list.length} ${extentionCountText}`.trim()}</span> : null,
    }].filter((d: {[k: string]: JSX.Element | null }) => !!d.element );

    return <>
        <FlexTextHolder namespace="paragraph-extra-small" text={[text]}/>
        <ul className="content-list-wrapper -extra-small">
            { list.map( (d: any, i: number) => {
                return max && i >= max  ? null : <li key={`sammendrag-summary-avtale-item-${i}`} className="item">
                    <Card 
                        namespace={fullData ? 'full-spareavtale-card' : 'fond-spareavtale-card' }
                        key={`sammendrag-summary-avtale-item-${i}`}
                        data={fullData ? d.fullData : d.data}
                        click={ fullData ? undefined : (e) => { if (click) { click(e, 'selected-agreement', d); }}}
                    >
                        { fullData && !d.fullData.blocked && click && <div className="center paragraph -only-top">
                            <LinkButton icon={IconTypes.PENN_24} onClick={(e: any) => { click(e, 'selected-agreement', d);} }>Se/endre</LinkButton>
                        </div> }                        
                    </Card>
                </li>
            })}
        </ul>
        { children }
    </>; 
};

/** ************************************************************************
 ************************************************************************* */
interface OverviewOptionListProps {
    click: (e: any, k: string, d?: any, f?: any) => void;
    list: any;
    action?: string;
    type?: string;
    title?: string;
    description?: string;
};

export const OverviewOptionList = ({ click, list, action, type, title, description }: OverviewOptionListProps): JSX.Element => {
    return <div className="overview-option-list">
        { !!title && <h1>{title}</h1> }
        {list.map( (data: any) => {
            return <Card key={data.id} data={data} click={(e: any) => { click(e, (action ?? ''), data);}} type={type} noIcon />
        }) }
        { !!description && <InfoMessage text={description} />}
    </div>
};

/** ************************************************************************
 ************************************************************************* */
interface SpinnerProps {
    type?: string;
    namespace?: string;
    height?: number;
    width?: number;
    count?: number;
    borderRadius?: number;
};

export const Spinner = ({ type='', namespace, ...rest }: SpinnerProps): JSX.Element => {
    const basic = {baseColor: '#f5f4f2', highlightColor: '#fff'};
    const config: any = {
        card: {height: 97, borderRadius: 8},
        action: {height: 97, borderRadius: 8},
        link: {height: 40, borderRadius: 8},
        linkPanel: {height: 97, borderRadius: 24},
        header: {height: 40, borderRadius: 2},
        'header-light': {height: 40, borderRadius: 2},
    };
    const style = classNames(`spinner-wrapper -${type || 'basic'}`, namespace)
    return <div className={style} style={config[type]}>
        { type ? <>
                { type === 'card' && <Skeleton {...basic} {...config.card} {...rest} /> }
                { type === 'action' && <Skeleton {...basic} {...config.action} {...rest} /> }
                { type === 'link' && <Skeleton {...basic} {...config.link} {...rest} /> }
                { type === 'linkPanel' && <Skeleton {...basic}  {...config.linkPanel} {...rest} /> }
                { type === 'header' && <Skeleton {...config.header} {...rest} /> }
                { type === 'header-light' && <Skeleton {...basic} {...config.header} {...rest} /> }

                { (type === 'portefoljer' || type === 'konti' || type === 'arbeidsgiver' || type === 'app' ||  type === 'ordre') && <> 
                    <Spinner type="header-light"/>
                    <Spinner type="card"/>
                    <Spinner type="card"/>
                </>}

                { type === 'fond' && <> 
                    <Spinner type="header-light"/>
                    <ul className="spinner-row">
                        <li><Spinner type="header-light"/></li>
                        <li><Spinner type="header-light"/></li>
                    </ul>
                    <Spinner type="card"/>
                    <Spinner type="card"/>
                </>}
                { type === 'kjenn-din-kunde' && <>                     
                    <Spinner type="card"/>
                    <Spinner type="card"/>
                    <Spinner type="card"/>
                    <Spinner type="card"/>
                 </>}

                { type === 'link-list' && <>
                    { Array.from({length: 3}).map((x: any, i: number) => <Spinner key={`spinner-link-${i}`} type="link"/> )}                     
                </>}

                { type === 'action-list' && <>
                    { Array.from({length: 2}).map((x: any, i: number) => <Spinner key={`spinner-action-${i}`} type="action"/> )}                     
                </>}
            </> : <Loader />
        }
    </div>;
};

/** ************************************************************************
 ************************************************************************* */
interface TradButtonListViewProps {
    list: {id: string; text: string}[];
    click: (e: any, k: string, d?: any) => void;
    onlyForMobile?: boolean;
    onlyForDesktop?: boolean;
};

export const TradButtonListView = ({ list, click, onlyForMobile, onlyForDesktop }: TradButtonListViewProps): JSX.Element | null => {
    return (list || []).length ? <div className={classNames('_sgw-content trade-type-list-wrapper', {
        'only-for-mobile': !!onlyForMobile,
        'only-for-desktop': !!onlyForDesktop
    })}>
        <ul className="trade-type-list content-list-wrapper only-for-test">
            { list.map( (data: any) => (
                <li key={data.id}>
                    <Card data={data} click={(e:any, k='', d?: any) =>{click(e, k, d); }} action="trade-type-selected" type={`trade -${data.id}`} />
                </li>
            ))}
        </ul>
    </div> : null;
};

/** ************************************************************************
 ************************************************************************* */
interface CommercialProps {
    children?: any;
    title?: string;
    text?: string;
    type?: string;
    namespace?: string;
    onlyForMobile?: boolean;
    onlyForDesktop?: boolean;
};

export const Commercial = ({ children, type='basic', title, text, namespace, onlyForMobile, onlyForDesktop }: CommercialProps): JSX.Element => {
    const header = title ?? 'Spar litt hver måned'; 
    const body = text ?? 'Det er lettere å lykkes med sparingen hvis du starter fast månedlig sparing.';
    return <div className={classNames('commercial-wrapper', `-${type}`, namespace, {
        'only-for-mobile': !!onlyForMobile,
        'only-for-desktop': !!onlyForDesktop
    })}>
        <MarketCard title={header}>
            <div>{body}</div>
            {children}
        </MarketCard>
    </div>
}

/** ************************************************************************
 ************************************************************************* */
interface EffortAndSwingLegendProps {
    list: any;
    namespace?: string;
};

export const EffortAndSwingLegend = ({ list, namespace }: EffortAndSwingLegendProps): JSX.Element | null => {
    return (list || []).length ? <div className={classNames('effort-and-swing-legend-warpper', namespace)}>
        {  list.map( (info: any) => (
                info.data ? <div key={info.id} className={info.id}>
                    <div className="chart-legend-icon" style={{color: info.data.colorConfig.color}}>
                        <span id={`label-${info.id}`} style={{backgroundColor: info.data.colorConfig.bgColor}}>{info.label}</span>
                    </div>
                    <div aria-labelledby={`label-${info.id}`} className={`text -${info.data.type || 'value-info'}`}>
                        {info.data.text}
                    </div>
                </div> : null
        ))}
    </div> : null;
}

/** ************************************************************************
 ************************************************************************* */
export const VilkaarTekst = (): JSX.Element => {
    return <article aria-labelledby="vilkaar-tekst-title" className="vilkaar-tekst-wrapper">
        <h1 id="vilkaar-tekst-title">Avtale om bruk av Min Side Sparing</h1>
        <h2>1. Innledning</h2>
        <p>
            Denne avtalen (heretter «Avtalen») er inngått mellom Eika Kapitalforvaltning AS, org. nr. (979 561 261)
            og brukeren av Min Side Sparing (heretter «Brukeren» eller «Du/Deg»).
            Ved å ta i bruk Min Side Sparing og/eller bruke den informasjon som der finnes, godkjenner Brukeren
            Avtalen.
        </p>
        <p>
            Eika Kapitalforvaltning AS («Eika Kapitalforvaltning») har rett til å endre Avtalen uten å informere
            Brukeren i forkant. Slike endringer vil likevel bli informert i Min Side Sparing i etterkant av
            endringene, og Brukeren vil uansett ha full rett til når som helst å trekke seg fra Avtalen ved å
            avslutte avtaleforholdet.
        </p>
        <p>
            Kontaktdataene som avgis i Min Side Sparing vil ikke importeres til nettbanken. Ved endringer av e-post,
            telefonnummer, adresse etc. må Brukeren derfor registrere dette i nettbanken i tillegg til i Min Side
            Sparing. Ved å avgi kontaktdata i Min Side Sparing aksepterer Brukeren at Eika Kapitalforvaltning kan
            kommunisere med Brukeren gjennom de aktuelle kanaler.
        </p>
        <p>
            Brukeren har mulighet til når som helst å begrense den informasjonsinnhentingen Eika Kapitalforvaltning
            forestår gjennom avtaleforholdet, samt forhold knyttet til personvern/markedsføring, jf. nedenfor, ved å
            gjøre ønskede endringer i «om meg» i Min Side Sparing.
        </p>

        <h2>2. Kostnader</h2>
        <p>Det påløper ingen kostnader ved bruk av Min Side Sparing.</p>
        <p>
            Ved tegning/innløsning av fondsandeler i Min Side Sparing vil ordinær tegnings-/innløsningsprovisjon
            påløpe.
        </p>
        <h2>3. Forhold knyttet til personvern</h2>
        <p>
            Formålet med Min Side Sparing (og derigjennom Avtalen) er å gi deg en samlet oversikt over din sparing,
            og det er derfor nødvendig å innhente opplysninger om deg og din sparing fra andre
            leverandører/selskaper.
        </p>
        <p>
            For å oppfylle avtalen vil Eika Kapitalforvaltning derfor, eventuelt med din bistand, innhente slike
            opplysninger fra din Eika-bank (behandlingsansvarlig for opplysninger om dine innskuddskonti),
            fra andre selskaper i Eika Gruppen AS, fra VPS (behandlingsansvarlig for opplysninger om dine
            VPS-konti),
            fra andre fondsleverandører (behandlingsansvarlige for opplysninger om dine øvrige fondsinvesteringer),
            fra Norsk Pensjon og fra andre pensjonsleverandører (behandlingsansvarlige for opplysninger om din
            pensjonssparing).
            Eika Kapitalforvaltning er selv behandlingsansvarlig for opplysninger om dine investeringer i Eika-fond.
        </p>
        <p>Eika Kapitalforvaltning vil behandle følgende personopplysninger om deg:</p>
        <ul>
            <li>Opplysninger om din identitet</li>
            <li>Opplysninger om dine bankkonti i din Eika-bank</li>
            <li>Opplysninger om dine beholdninger på VPS-konti</li>
            <li>Opplysninger om dine beholdninger hos (enkelte) andre fondsleverandører</li>
            <li>Opplysninger om dine pensjonsavtaler</li>
            <li>Opplysninger om din fondssparing i fond forvaltet av Eika Kapitalforvaltning</li>
            <li>
                Andre opplysninger som kan være nødvendig for å opprettholde eller avslutte avtaleforholdet om bruk
                av Min Side Sparing
            </li>
        </ul>

        <p>
            Tillegg om Norsk Pensjon:
            For å gi deg oversikt over din pensjon må det innhentes opplysninger fra andre. Ingen av opplysningene
            lagres. De vil kun være tilgjengelige for deg og bare fram til du logger deg ut. Ved å ta appen i bruk
            godkjenner du at
        </p>
        <ul>
            <li>
                Norsk Pensjon henter dine opplysninger fra pensjonsinnretninger.
            </li>
            <li>
                Norsk Pensjon henter inn fra NAV, en simulering av din alderspensjon fra folketrygden, samt om du
                har
                offentlig tjenestepensjon
            </li>
        </ul>
        <p>
            Eika Kapitalforvaltning vil behandle opplysninger om deg i samsvar med Avtalen, personopplysningsloven
            og annet relevant regelverk. Personopplysningene vil bli benyttet til:
        </p>
        <ul>
            <li>Vise deg informasjon om din totale sparing/beholdning</li>
            <li>Identitetskontroll</li>
            <li>Risikostyring og kundeklassifisering</li>
            <li>Tilpasse produkter og tjenester i forhold til kundeklassifisering</li>
            <li>Behandle henvendelser fra deg</li>
        </ul>
        <p>For øvrig gjelder Eika Kapitalforvaltnings personvernspolicy som ligger tilgjengelig på www.eika.no.</p>
        <h2>4. Innhold i Min Side Sparing</h2>
        <p>
            Ved å ta i bruk Min Side Sparing vil du gis en omfattende oversikt over din sparing/beholdning i dine
            ulike finansielle instrumenter og i bankinnskudd. Du vil blant annet få oversikt over:
        </p>
        <ul>
            <li>Dine beholdninger i fond/aksjer (som kan vises i Min Side Sparing)</li>
            <li>Din arbeidsgivers sparing (dvs. pensjonssparing)</li>
            <li>Din banksparing</li>
            <li>Tegne/innløse/bytte fond forvaltet av Eika Kapitalforvaltning</li>
            <li>Opprette/endre/stanse spareavtaler</li>
            <li>Følge utviklingen av dine investeringer</li>
        </ul>

        <h2>5. Ansvarsbegrensning</h2>
        <p>
            Informasjonen i Min Side Sparing må ikke under noen omstendighet oppfattes som et råd eller tilbud om å
            kjøpe eller selge finansielle instrumenter. Informasjonen er heller ikke å betrakte som skattemessige
            korrekte opplysninger, verken historiske eller oppdaterte beholdninger.
        </p>

        <p>
            Min Side Sparing inneholder tjenester og innhold fra enkelte eksterne aktører (eksempelvis VPS, Norsk
            Pensjon, leverandør av pensjonskalkulator, etc.). Det garanteres ikke at slike tjenester/innhold er
            fullstendig, riktig eller oppdatert. Eika Kapitalforvaltning kan derfor ikke holdes ansvarlig som følge
            av mangler ved tjenester/innhold levert av eksterne aktører. Eika Kapitalforvaltning er for øvrig heller
            ikke ansvarlig for innhold eller vilkår i eksterne sider som er linket fra Min Side Sparing. For øvrig
            kan Eika Kapitalforvaltning ikke holdes ansvarlig for tap som følge av feil ved- eller feilbruk av Min
            Side Sparing.
        </p>

        <h2>6. Tilgjengelighet</h2>
        <p>
            I perioder for vedlikehold, oppgraderinger eller ved uventede feil må Brukeren påregne at Min Side
            Sparing kan bli midlertidig utilgjengelig. I slike perioder vil kjøp og salg av fondsandeler/andre
            finansielle instrumenter kunne skje gjennom andre handelskanaler. Eika Kapitalforvaltning er ikke
            ansvarlig for tap som følge av manglende tilgjengelighet eller andre feil ved Min Side Sparing.
        </p>

        <h2>7. Immaterielle rettigheter</h2>
        <p>
            Eika Kapitalforvaltning har alle immaterielle rettigheter til Min Side Sparing og innhold produsert av
            Eika Kapitalforvaltning. Innhold fra andre aktører enn Eika Kapitalforvaltning som er publisert i
            tjenesten vil være beskyttet av disse aktørenes opphavsrett.
        </p>
        <p>
            Eika Kapitalforvaltning, eller en tredjepart, beholder eierskap, opphavsrett og øvrige immaterielle
            rettigheter til informasjonen i Min Side Sparing. Varemerker, logoer og andre kjennetegn som forekommer
            i Min Side Sparing kan ikke reproduseres, publiseres eller brukes på annen måte uten etter avtale med
            Eika Kapitalforvaltning og/eller tredjepart.
        </p>
        <h2>8. Heving av avtaleforholdet</h2>
        <p>
            Eika Kapitalforvaltning kan heve Avtalen dersom det foreligger vesentlig mislighold av Avtalen. Som
            vesentlig mislighold skal anses:
        </p>
        <ul>
            <li>at brukeren, uten rimelig grunn, gir andre tilgang til sin profil på Min Side Sparing</li>
            <li>
                at Eika Kapitalforvaltning får mistanke om at brukeren er involvert i hvitvasking eller annen
                ulovlig virksomhet
            </li>
            <li>at brukeren på annen måte misbruker Min Side Sparing eller forutsetningene for avtalen</li>
        </ul>
        <p>
            Avtalen heves ved at Brukerens tilgang til Min Side Sparing stenges. Eika Kapitalforvaltning forbeholder
            seg retten til å kreve erstatning for tap som følge av slikt mislighold.
        </p>
        <h2>9. Verneting</h2>
        <p>Denne Avtalen skal reguleres av norsk rett. Oslo tingrett skal være verneting ved eventuelle tvister.</p>
    </article>
};

/** ************************************************************************
 ************************************************************************* */
export const IPSavtaleTeskt = (): JSX.Element => {
    return <article aria-labelledby="ips-avtale-tekst-title" className="ips-avtale-tekst-wrapper">
        <h1 id="ips-avtale-tekst-title">Avtale om individuell sparing til pensjon</h1>
        <p>
            Mellom Kunde (heretter «Kunden») og Eika Kapitalforvaltning AS, org. nr. 979 561 261, (heretter
            «Eika»), hvor Kunde og Eika i fellesskap er benevnt «Partene», er det inngått følgende avtale
            (heretter «Avtalen») om individuell sparing til pensjon (heretter «IPS»), jf. skatteloven § 6-47 (1)
            d) med tilhørende forskrift («IPS-forskriften»):
        </p>
        <h2>1. Om Partene og Avtalen</h2>
        <p>
            Eika er et forvaltningsselskap for verdipapirfond med registrert adresse i Parkveien 61, 0254 Oslo,
            som er underlagt tilsyn av Finanstilsynet. Eika er et datterselskap av Eika Gruppen AS, som igjen er
            eid av bankene i Eika-alliansen.
        </p>
        <p>Kunden er over 18 år, og har inngått Avtalen som privatperson.</p>
        <p>
            Denne Avtale skal gi Kunden rett til alderspensjon i samsvar med bestemmelsene i IPS-forskriften §§
            6-47-1 til 6-47-17.
        </p>
        <h2>2. Informasjon før inngåelse av Avtalen</h2>
        <p>
            Reglene om IPS krever at tilbyder, før avtale inngås, må gi kunden skriftlige opplysninger om
            binding av pensjonskapitalen i avtaleperioden, om at avtalen gir rett til alderspensjon, om
            eventuell forsikring er knyttet til avtalen, samtlige kostnader knyttet til avtalen, reglene for
            utbetaling av ytelser, samt relevante skatteregler.
        </p>
        <p>
            Kunden bekrefter ved sin aksept av Avtalen å blant annet ha blitt gjort kjent med følgende før
            Avtalen ble inngått:
        </p>
        <ul>
            <li>
                Avtalen gir Kunden rett til alderspensjon i henhold til reglene i skatteloven § 6-47 (1) d) med
                tilhørende forskrift.
            </li>
            <li>
                Kunden kan spare opptil kr. 40 000 per år. Det innbetalte beløp kan kreves fradratt i Kundens
                alminnelige inntekt.
            </li>
            <li>Midlene er bundet frem til uttak, jf. nedenfor.</li>
            <li>
                Avtalen kan inngås av personer som er fylt 18 år, og det kan spares i ordningen inntil fylte 75
                år. Kunden kan tidligst ta ut alderspensjon fra fylte 62 år. Alderspensjon skal utbetales i et
                fastsatt antall år fra uttak av pensjonen og minst til fylte 80 år, men ikke mindre enn 10 år.
            </li>
            <li>Uttak skal skattlegges som alminnelig inntekt.</li>
            <li>Verdien på kontoen er unntatt formuesskatt.</li>
            <li>Det er ikke knyttet forsikringer til Avtalen</li>
            <li>
                Ordinære kostnader knyttet til kjøp og salg av finansielle instrumenter (typisk forvaltnings-,
                tegnings- og innløsningshonorar for fond) vil påløpe.
            </li>
        </ul>

        <h2>3. Avtaleinngåelsen</h2>
        <p>
            Avtalen kan inngås ved fysisk signering på «blankett for avtale om individuell sparing til pensjon»
            (hvor denne Avtale inngår som vedlegg), eller ved digital inngåelse. I sistnevnte tilfelle vil
            Kunden, som da vil være innlogget, akseptere Avtalen ved avkrysning av at vedkommende har lest,
            akseptert og godtatt Avtalen. Avtalen vil sendes på e-post til Kunden samt lagres i nettposten.
        </p>
        <h2>4. Kartlegging av Kundens behov og ønske om risiko – rådgivning </h2>
        <p>
            I forbindelse med Avtalen vil Eika gi Kunden et råd om sammensetningen av porteføljen basert på en
            kartlegging av Kundens behov, ønske om risiko og tiden frem til uttak av pensjonen. Innenfor det
            produktspekter Eika tilbyr velger Kunden selv hvilke aktiva som skal inngå i porteføljen, og dermed
            også om rådet skal følges eller ikke.
        </p>
        <p>
            Når Kunden har nådd en alder med få år igjen til uttak av pensjonen vil Eika – om nødvendig – gi
            Kunden et råd om risikoreduserende tiltak.
        </p>
        <h2>5. Årlige innbetalinger under ordningen </h2>
        <p>
            Samlet årlig innbetaling av innskudd, vederlag for administrasjon av avtalen og vederlag for
            forvaltning av årets innskudd kan ikke overstige kr. 40 000. Innbetalinger kan ikke trekkes av
            pensjonskapitalen.
        </p>
        <p>Kunden kan selv velge størrelse på- og tidspunkt for innbetaling.</p>
        <p>Kunden kan fortsette innbetaling inntil fylte 75 år.</p>
        <h2>6. Vilkårene for forvaltning</h2>
        <p>
            Kunden velger selv hvilke aktiva som skal inngå i portefølje innenfor det produktspekter som Eika
            tilbyr, og har til enhver tid rett til å endre sammensetningen av porteføljen. Kostnadene ved
            forvaltning-, tegning- og innløsning fremgår nedenfor under punkt 8.
        </p>
        <p>
            Kunden vil kunne foreta handel gjennom Eikas ulike handelsplattformer, eller manuelt ved bruk av
            blanketter og ved hjelp av Kundens lokale sparebank.
        </p>
        <h2>7. Vilkår for utbetaling</h2>
        <p>
            Kunden kan tidligst ta ut alderspensjon ved fylte 62 år. Dersom Kunden har rett til uføreytelser fra
            folketrygden kan Kunden likevel kreve at Eika straks begynner utbetaling. Den nærmere fastsettelse
            av slike ytelser skal skje i henhold til reglene i IPS-forskriften.
        </p>
        <p>
            Kunden skal gi Eika melding om når alderspensjonen skal utbetales. Med mindre Kunden gir melding om
            annet kommer pensjonen uansett til utbetaling ved fylte 75 år. Uttak av pensjon er ikke til hinder
            for at Kunden fortsetter innbetaling av innskudd i henhold til Avtalen.
        </p>
        <p>
            Alderspensjonen skal utbetales i et fastsatt antall år fra uttak av pensjonen og minst til Kunden
            har fylt 80 år, men likevel aldri i mindre enn 10 år. Kunden kan dog bestemme at pensjonen skal
            utbetales i en lengre periode. Både Kunden og Eika kan likevel kreve at utbetalingstiden settes ned
            til det antall hele år som er nødvendig for at samlet årlig alderspensjon utgjør om lag 20 prosent
            av folketrygdens grunnbeløp.
        </p>
        <p>
            Utbetalingsperiodens lengde kan endres ved fylte 67 år, og for øvrig etter avtale mellom Kunden og
            Eika. Kunden kan uansett på ethvert tidspunkt etter uttak endre uttaksgraden til fullt uttak av
            pensjon.
        </p>
        <p>
            Alderspensjonen i det enkelte år fastsettes med sikte på at den ikke skal utgjøre en større andel av
            pensjonskapitalen enn den andelen det aktuelle året utgjør av den gjenværende utbetalingsperioden.
            Det kan avtales at alderspensjonen etter 10 års utbetaling skal settes ned, men ikke med mer enn 50
            prosent.
        </p>
        <p>
            Kunden kan kreve at utbetalingen skal utsettes/ikke utbetales i den utstrekning Kunden mottar lønn.
        </p>
        <h2>8. Kostnader ved Avtalen</h2>
        <p>
            Det påløper ingen kostnader ved opprettelse, drift og administrasjon av Avtalen. Eika står fritt
            likevel fritt til på ethvert tidspunkt å innføre slike kostnader etter forutgående varsel til
            Kunden.
        </p>
        <p>
            Transaksjons- og/eller forvaltningskostnader slik de til enhver tid gjelder for de enkelte
            finansielle instrumenter som tilbys, vil påløpe. Verdipapirfondene som tilbys innenfor ordningen vil
            ha et forvaltningshonorar mellom 0,2%-2%, et tegningshonorar mellom 0%-2% og et innløsningshonorar
            mellom 0%- 0,5%. For en nærmere oversikt over kostnadene knyttet til det enkelte finansielle
            instrument vises det til www.eika.no. Kunden vil under enhver omstendighet få nærmere informasjon om
            kostnadene før handler gjennomføres.
        </p>

        <h2>9. Flytting, endring og oppsigelse av Avtalen </h2>
        <p>
            Kunden kan flytte Avtalen med tilhørende midler til en annen institusjon. Flytting skal skje etter
            forsikringsvirksomhetsloven § 6-13 og 6-14 (6).
        </p>
        <p>
            Kunden kan når som helst si opp/avbryte Avtalen. Eika skal i så fall utstede pensjonskapitalbevis
            etter reglene i innskuddspensjonsloven § 6-2. Alderspensjonskapitalen i henhold til slike
            pensjonskapitalbevis kan overføres til en annen IPS-avtale som Kunden har, og inngå i
            alderspensjonskapitalen knyttet til denne avtalen.
        </p>

        <h2>10. Ved Kundens død</h2>
        <p>
            Retten til pensjonskapitalen faller ikke bort ved Kundens død. Pensjonskapitalen i henhold til
            Avtalen skal da benyttes til barnepensjon eller i tilfelle etterlattepensjon til ektefelle,
            registrert partner eller samboer etter reglene i innskuddspensjonsloven § 7-7. Pensjonskapital som
            ikke benyttes til slike ytelser utbetales som et engangsbeløp til dødsboet.
        </p>
        <h2>11. Overdragelse/pantsettelse mv., samt motregning</h2>
        <p>
            Pensjonskapitalen og retten til utbetaling av ytelser etter Avtalen kan ikke overdras eller
            pantsettes eller på annen måte benyttes til dekning av Kundens kreditorer. Kundens rettigheter etter
            Avtalen kan heller ikke gjenkjøpes av Eika.
        </p>
        <p>
            Motregning kan ikke skje i midler som inngår i pensjonskapitalen. Ved utbetaling av ytelser kan det
            likevel foretas motregning med krav som utspringer av Avtalen.
        </p>
        <h2>12. Ansvar </h2>
        <p>
            Kunden erkjenner at informasjonen som gis innenfor Avtalen ikke under noen omstendighet skal
            oppfattes som et råd eller tilbud om å kjøpe eller selge finansielle instrumenter, med unntak av der
            hvor det eksplisitt fremgår at et råd faktisk ytes. Ved behov for investeringsrådgivning kan Kundens
            lokale sparebank eller Eika kontaktes.
        </p>
        <p>
            Verdipapirfondenes nøkkelinformasjon og prospekter ligger tilgjengelig i Eikas handelsløsninger og
            nettsider. Kunden må sette seg grundig inn i disse dokumentene før investeringsbeslutning foretas.
        </p>
        <p>
            Det gjøres oppmerksom på at historisk avkastning ikke er noen garanti for fremtidig avkastning, og
            at fremtidig avkastning blant annet vil avhenge av markedsutviklingen, forvalters dyktighet, fondets
            risikoprofil og forvaltningshonorar. Avkastningen kan bli negativ som følge av kurstap.
        </p>
        <h2>13. Personopplysninger og forretningsvilkår </h2>
        <p>
            Eika Kapitalforvaltning foretar sammenstilling, lagring og utlevering av innsamlede og registrerte
            personopplysninger, og er behandlingsansvarlig for disse. Formålet med behandlingen av
            personopplysninger er blant annet å kunne oppfylle forpliktelsene i herværende Avtale, herunder å
            kunne kommunisere med Kunden, samt å oppfylle opplysningsplikten i henhold til gjeldende regler, for
            eksempel overfor ligningsmyndighetene. Kunden kan be om innsyn i registrerte personopplysninger samt
            kreve retting av eventuelle feil ved henvendelse til kundens lokalbank eller Eika. Kunden kan også,
            på nærmere vilkår, kreve opplysningene slettet. Opplysningene vil bli lagret så lenge det er
            nødvendig for å oppfylle formålet med behandlingen.
        </p>
        <p>
            Det vises for øvrig til Eikas forretningsvilkår, som Kunden ved inngåelse av Avtalen anses å ha
            akseptert slik de til enhver tid er utformet. Forretningsvilkårene er tilgjengelig på Eikas
            nettsider.
        </p>
        <h2>14. Tvisteløsning </h2>
        <p>
            Enhver tvist som springer ut av Avtalen skal løses etter norsk rett og med Oslo tingrett som
            verneting.
        </p>
        <p>
            Kunden kan bringe en eventuell tvist inn for Finansklagenemnda til uttalelse. Se Eikas nettside for
            nærmere informasjon om klageadgangen.
        </p>
    </article>
};

/** ************************************************************************
 ************************************************************************* */
export const AksjesparekontoTekst = (): JSX.Element => {
    return <article aria-labelledby="aksjesparekonto-avtale-tekst-title" className="ips-avtale-tekst-wrapper">
        <h1 id="aksjesparekonto-avtale-tekst-title">Avtale om aksjesparekonto</h1>
        <p>
            Mellom Kunde (heretter benevnt «Kunde») og Eika Kapitalforvaltning AS (heretter benevnt «Eika»), hvor Kunde og Eika i fellesskap heretter er benevnt «Partene», er det inngått følgende avtale om aksjesparekonto (heretter «Avtalen»):
        </p>

        <h2>1. Avtalens ikrafttredelse</h2>
        <p>Avtalen trer i kraft når aksjesparekontoen er opprettet i Kundens navn og Kundens fondsbeholdning er flyttet over til kontoen. All handel som skjer før nevnte tidspunkt vil skattlegges på ordinært vis.</p>

        <h2>2. Informasjon før inngåelse av Avtalen</h2>
        <p>Reglene om aksjesparekonto krever at tilbyder, før avtale inngås, må gi kunden skriftlige opplysninger om sentrale vilkår i avtaleforholdet. Det skal blant annet gis opplysninger om hvilke midler som kan inngå på aksjesparekontoen, hvilke skatteregler som gjelder, hvilke kostnader som er knyttet til kontoen, at det ikke vil påløpe renter på eventuelle innestående kontanter på kontoen og om forholdet til innskuddsgarantiordningen. Kunden bekrefter ved sin aksept av Avtalen å ha blitt gjort kjent med overnevnte forhold før Avtalen ble inngått.</p>

        <p>Samtidig er Kunden kjent med at en konvertering til aksjesparekonto ikke nødvendigvis er hensiktsmessig dersom Kunden planlegger å gi bort sine fondsandeler i gave, eller dersom hun/han har latente tap på sine investeringer.</p>

        <h2>3. Avtaleinngåelsen</h2>
        <p>Avtalen kan enten inngås ved fysisk signering på «blankett for avtale om konvertering til aksjesparekonto» (hvor denne Avtalen inngår som vedlegg), eller ved digital inngåelse. I sistnevnte tilfeller vil Kunden, som da vil være innlogget, akseptere Avtalen ved avkrysning av at vedkommende har lest, akseptert og godtatt Avtalen. Avtalen vil sendes på e-post til kunden samt lagres i nettposten.</p>

        <h2>4. Om aksjesparekontoen – diverse</h2>
        <p>Med aksjesparekonto menes en investeringskonto som kan opprettes av personlige skattytere. Innenfor kontoen kan Kunden investere i børsnoterte aksjer og egenkapitalbevis i selskap hjemmehørende i land innenfor EØS og andeler i aksjefond hjemmehørende i land innenfor EØS. Med aksjefond menes verdipapirfond med mer enn 80 prosent aksjeandel ved inntektsårets begynnelse. Det kan ikke opptjenes renter på innestående kontante midler på aksjesparekontoen.</p>

        <p>Ved realisasjon av aksjer, egenkapitalbevis og andeler i verdipapirfond innenfor kontoen vil gevinster ikke komme til beskatning, og tap heller ikke til fradrag. Gevinsten vil først bli beskattet når Kundens uttak fra kontoen overstiger innskuddet, og tap først vil komme til fradrag når innestående på konto er lavere enn innskuddet ved kontoens opphør.</p>

        <p>Aksjesparekonto hos Eika vil omfatte Eikas egenforvaltede aksjefond. Etter hvert vil eksterne aksjefond bli inkludert, i tillegg til at det på sikt er aktuelt å tilby enkeltaksjer i samarbeid med en tredjepart. På Eikas nettsider vil det til enhver tid være en oppdatert oversikt over hvilke produkter som er tilgjengelig for aksjesparekonto i Eika.</p>

        <p>Reglene knyttet til aksjesparekonto fremgår av skatteloven med tilhørende forskrift. Dersom reglene i fremtiden endres, vil også vilkårene i herværende Avtale endres tilsvarende.</p>

        <p>Kontanter på aksjesparekontoen vil inngå på Eikas klientkonto hos Danske Bank. For det tilfellet at Danske Bank skulle gå konkurs vil innskuddsgarantiordningen gi Eika dekning med totalt NOK 2 millioner. Det gjøres oppmerksom på Eika har flere konti hos Danske Bank og at samtlige av Eikas kunder som har aksjesparekonto, benytter den samme klientkontoen. Dette innebærer at innskuddsgarantiordningen for den enkelte kunde ikke vil gi dekning av betydning.</p>

        <p>For å kunne gjennomføre overføring av finansielle instrumenter til aksjesparekontoen vil Eika innhente – og kontohaver er forpliktet til å gi – nødvendige opplysninger, herunder om inngangsverdi og ubenyttet skjerming. Eika vil kunne utveksle kundeopplysninger med tredjeparter om utførelse av oppgaver i forbindelse med kontoen.</p>

        <p>Eika vil beregne et skjermingsfradrag på grunnlag av Kundens laveste innskudd på kontoen i løpet av året, tillagt eventuell ubenyttet skjerming fra tidligere år.</p>

        <p>Utbytte fra finansielle instrumenter vil ikke inngå i aksjesparekontoen, men utbetales til Kunden og oppgis til direkte beskatning på Kundens hånd.</p>

        <h2>5. Føring av kontoen</h2>
        <p>Eika vil som tilbyder av aksjesparekontoen ivareta føringen av kontoen. Kunden vil kunne foreta handel gjennom Eikas ulike handelsplattformer, eller manuelt ved bruk av blanketter og ved hjelp av Kundens lokale sparebank.</p>

        <p>I aksjesparekonten skal Kunden til enhver tid kunne se: a) innskudd på kontoen, b) oversikt over finansielle instrumenter på kontoen, c) kontantbeholdning, d) ubenyttet skjermingsfradrag fra tidligere år, e) skjermingsgrunnlag, f) beregnet skjermingsfradrag for skattleggingsperioden, g) skattepliktig uttak fra kontoen i løpet av skattleggingsperioden, h) kontoen markedsverdi ved årsskiftet og i) mottatt utbytte.</p>

        <h2>6. Kostnader</h2>
        <p>Opprettelse av aksjesparekonto medfører ingen kostnader for Kunden, og Kunden vil heller ikke belastes løpende utgifter knyttet til drift av kontoen.</p>

        <p>Ved flytting og/eller oppsigelse av aksjesparekontoen kan Eika belaste Kunden for utgifter knyttet til flyttingen/oppsigelsen. Slike kostnader kan likevel ikke overstige Eikas kostnader forbundet med slik flytting/oppsigelse. Nærmere informasjon om kostnader vil bli publisert på Eikas nettsider.</p>

        <p>Det gjøres oppmerksom på at ordinære kostnader knyttet til kjøp og salg av fondsandeler (forvaltningshonorar, tegnings- og innløsningshonorar), slik de er beskrevet i fondenes nøkkelinformasjon og prospekter, vil påløpe i tillegg.</p>

        <h2>7. Oppbevaring av fondsandeler og kundemidler</h2>
        <p>Eika fører selv andelseierregisteret for Eikas egenforvaltede fond. Føring av øvrige fondsandeler gjøres i eksterne andelseierregistre og Eika mottar løpende rapportering fra disse og foretar daglige avstemminger.</p>

        <p>Ved investeringer i eksterne fond vil Eika stå oppført som nominee for Kunden, dvs. at Eika vil være registrert som forvalter av Kundens fondsandeler i det eksterne fondets andelseierregister. Informasjon og rapportering fra slike eksterne fondsleverandører til sine kunder vil videreformidles til Kunden fra Eika.</p>

        <p>Kontante midler innenfor aksjesparekontoen oppbevares på klientkonto i Eikas navn, for tiden i Danske Bank, og er dermed adskilt fra Eikas egne midler. Eika står fritt til å endre innskuddsbank. Kunden vil ikke opparbeide seg renter på kontante midler.</p>

        <h2>8. Rapportering</h2>
        <p>Eika skal minimum to ganger i året gi Kunden informasjon om beholdningen av finansielle instrumenter og kontanter på aksjesparekontoen, samt om Kundens samlede innskudd på kontoen. Det skal samtidig opplyses at det ikke påløper renter på en eventuell kontantbeholdning.</p>

        <p>Som tilbyder av aksjesparekontoen vil Eika være ansvarlig for å utføre skatterapportering til norske skattemyndigheter i tråd med de til enhver tid gjeldende regler.</p>

        <h2>9. Avvikling og flytting</h2>
        <p>Kunden kan når som helst begjære aksjesparekontoen avviklet eller overført til en annen tilbyder. Dersom Kunden ønsker å avvikle kontoen skal Eika kontaktes, mens dersom Kunden ønsker å flytte kontoen skal ny tilbyder kontaktes. Ny tilbyder vil deretter kontakte Eika for gjennomføring av flyttingen.</p>

        <p>Ved begjæring fra en annen tilbyder om flytting skal Eika innen 10 virkedager overføre alle finansielle instrumenter og kontanter på kontoen til den nye tilbyderen. Oppgjør for salg av verdipapirer med oppgjørstid ut over 10 dager, overføres til ny tilbyder senest fem dager etter oppgjørstidspunktet. Eika skal også overføre informasjon som nevnt i forskrift FOR-2017-06-21-853 § 10-21-4, og annen informasjon som er nødvendig for at den nye tilbyderen skal kunne foreta korrekt innrapportering til skattemyndighetene ved utgangen av skattleggingsperioden.</p>

        <h2>10. Ansvar</h2>
        <p>Kunden erkjenner at informasjonen innenfor aksjesparekontoen ikke under noen omstendighet skal oppfattes som et råd eller tilbud om å kjøpe eller selge finansielle instrumenter. Ved behov for investeringsrådgivning kan Kundens lokale sparebank eller Eika kontaktes.</p>

        <p>Verdipapirfondenes nøkkelinformasjon og prospekter ligger tilgjengelig i Eikas handelsløsninger og nettsider. Kunden må sette seg grundig inn i disse dokumentene før investeringsbeslutning foretas.</p>

        <p>Det gjøres oppmerksom på at historisk avkastning ikke er noen garanti for fremtidig avkastning, og at fremtidig avkastning blant annet vil avhenge av markedsutviklingen, forvalterens dyktighet, fondets risikoprofil og forvaltningshonorar. Avkastningen kan også bli negativ som følge av kurstap.</p>

        <h2>11. Personopplysninger og forretningsvilkår</h2>
        <p>Eika vil behandle personopplysninger om Kunden i forbindelse med avtaleforholdet. Formålet med behandlingen er å oppfylle Eikas avtalemessige forpliktelser overfor Kunden, samt andre lovpålagte rapporteringsforpliktelser. Ved inngåelse av Avtalen samtykker Kunden til dette.</p>

        <p>Det vises for øvrig til Eikas forretningsvilkår, som Kunden ved inngåelse av Avtalen anses å ha akseptert slik de til enhver tid er utformet. Forretningsvilkårene er tilgjengelig på Eikas nettsider.</p>

        <h2>12. Tvisteløsning</h2>
        <p>Enhver tvist som springer ut av Avtalen skal løses etter norsk rett og med Oslo tingrett som verneting.</p>

        <p>Kunden kan bringe en eventuell tvist inn for Finansklagenemnda til uttalelse. Se Eikas nettside for nærmere informasjon om klagegangen.</p>
    </article>
};

/** ************************************************************************
 ************************************************************************* */
export const ComingSoon = ({ title, text} : {title?: string; text?: string}): JSX.Element => {
    return <article aria-labelledby="coming-soon-title" className="coming-soon-wrapper">
        <h1 id="coming-soon-title">{title || 'Vi pusser opp'}</h1>
        <p>{text || 'Sparing er ikke helt på plass her i appen ennå. I mellomtiden må du logge inn på Smartspar eller den gamle mobilbanken for å administrere sparingen din.'}</p>
    </article>
};

/** ************************************************************************
 ************************************************************************* */
export const DisponertPage = ({ title, text, children} : {title?: string; text?: string, children?: JSX.Element}): JSX.Element => {
    return <article aria-labelledby="disponert-page-title" className="disponert-page-wrapper">
        <h1 id="disponert-page-title">{title || 'Vi pusser opp'}</h1>
        <p>{text || 'Sparing i disponerte kontoer for barn er desverre ikke mulig enda. Kontakt oss så hjelper vi deg gjerne. Eller gå til Smartspar appen for å gjøre det der.'}</p>

        <div className="paragraph -include-top center">
            <Link href={getContactUsUrl()} linkType={LinkType.BUTTON_STANDARD_POSITIVE}>
                Kontakt oss
            </Link>
        </div>

        { children }
    </article>
};

/** ************************************************************************
 ************************************************************************* */
export const NornePage = ({ title, text, children} : {title?: string; text?: string, children?: JSX.Element}): JSX.Element => {
    return <article aria-labelledby="norne-page-title" className="norne-page-wrapper">
        <h1 id="norne-page-title">{title || 'Spar i aksjer hos Norne'}</h1>
        <p>{text || 'Du kan spare i aksjer hos vår samarbeidspartner Norne.'}</p>

        { children }
    </article>
};

/** ************************************************************************
 ************************************************************************* */
export const AboutFundAccountAksjesparekonto = (): JSX.Element => {
    return <article aria-labelledby="about-fund-account-title" className="about-fund-account-wrapper -aksjesparekonto">
        <h1 id="about-fund-account-title" className="paragraph">Om Aksjesparekonto</h1>
        <p className="paragraph">Aksjefond og Indeksfond kan legges i denne kontoen.</p>

        <FlexTextHolder text={[
            'Frihet til å ta ut pengene du satte inn uten å betale skatt.',
            'Kontoen er en mappe for fondene dine.',
            'Kontoen er gratis.'
        ].map( (text: string) => { return {text, type: 'checked -font-normal'} })}/>

        <h2 className="paragraph-extra-small -include-top">Skattefritt uttak av innskudd</h2>
        <p className="paragraph">Det du har satt inn, skatter du ikke av.</p>
        <h2 className="paragraph-extra-small -include-top">Kjøp og selg skattefritt</h2>
        <p className="paragraph">Bytter du fond innenfor kontoen, så skatter du ikke.</p>
        <h2 className="paragraph-extra-small -include-top">Tilknyttet konto</h2>
        <p className="paragraph">Ved uttak og overføring til tilknyttet konto, skatter du ikke uansett hvor mye du tar ut.</p>
        <p className="paragraph">Men, dersom du har tatt ut gevinsten og senere overfører til en brukskonto, vil du skatte av gevinsten.</p>
    </article>
};

/** ************************************************************************
 ************************************************************************* */
export const AboutFundAccountVerdipapirkonto = (): JSX.Element => {
    return <article aria-labelledby="about-fund-account-title-vpk" className="about-fund-account-wrapper -verdipapirkonto">
        <h1 id="about-fund-account-title-vpk" className="paragraph">Om Verdipapirkonto</h1>        
        <p className="paragraph">Rentefond og kombinasjonsfond kan legges i denne kontoen.</p>

        <FlexTextHolder text={[
            'Passer når du vil spare i fond med lavere risiko.',
            'Kontoen er en mappe for fondene dine.',
            'Kontoen er gratis.'
        ].map( (text: string) => { return {text, type: 'checked -font-normal'} })}/>

        <h2 className="paragraph-extra-small -include-top">Skatt ved salg av fond</h2>
        <p className="paragraph">Med en Verdipapirkonto får du skatt på gevinst med en gang du selger fondet.</p>
    </article>
};

/** ************************************************************************
 ************************************************************************* */
export const AboutFundAccountIndividuellPensjonssparingKonto = (): JSX.Element => {
    return <article aria-labelledby="about-fund-account-title-ips" className="about-fund-account-wrapper -individuell-pensjonssparing-konto">
        <h1 id="about-fund-account-title-ips" className="paragraph">Om IPS-konto</h1>
        <p className="paragraph">
            {`Du kan spare inntil ${formatAmount(Constant.ipsMaxYearlyAmount)} kr i året på denne kontoen.`}
        </p>

        <FlexTextHolder text={[
            'Sperret til pensjonsalder.',
            'Kontoen er en mappe for fondene dine.',
            'Kontoen er gratis.'
        ].map( (text: string) => { return {text, type: 'checked -font-normal'} })}/>

        <h2 className="paragraph-extra-small -include-top">Kun til pensjonssparing</h2>
        <p className="paragraph">En IPS-konto brukes kun til pensjon. Du kan ikke ta ut pengene før pensjonsalder. </p>
        <h2 className="paragraph-extra-small -include-top">Utsatt skatt</h2>
        <p className="paragraph">Du skatter ikke så lenge du sparer. Men, når du tar ut pengene som pensjonist, skatter du av dette som alminnelig inntekstskatt.</p>
    </article>
};


/** ************************************************************************
 ************************************************************************* */
export const AboutTaxFreeInfo = ({amount=0}): JSX.Element => {
    return <article aria-labelledby="about-tax-free-title" className="about-tax-free-info-wrapper -individuell-pensjonssparing-konto">
        <h1 id="about-tax-free-title" className="paragraph">Skattefritt uttak</h1>

        <div className="total-value paragraph-large center">
            <span id="tax-free-verdi-label" className="total-value-label">Skattefritt uttak</span>
            <span aria-labelledby="tax-free-verdi-label">{`${formatAmount(amount)} kr`}</span>            
        </div>

        <Message
            role="presentation"
            type="InfoMessage"
            icon
            text="Dette er så mye du kan ta ut fra Aksjesparekontoen uten at det utløser skatt."
        />
    </article>    
};


