import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';
import { LinkButton, StandardButton } from '@eika/button';
import { Link } from '@eika/link';
import { IconTypes } from '@eika/icon';
import { usePrevious } from '../../../common/util/Hook';
import Constant from '../../../static/data/Constant';
import Card from '../../../common/Card/Card';
import Grid from '../../../common/Grid/Grid';
import Carousel from '../../../common/Carousel/Carousel';
import Message from '../../../common/Message/Message';
import ActionButtonListView, {
    ActionButtonListItemProps,
} from '../../../common/ActionButtonListView/ActionButtonListView';
import FlexTextHolder from '../../../common/FlexTextHolder/FlexTextHolder';
import {
    HeaderLink,
    LinkList,
    LinkListItemProps,
    ReturnPie,
    SectionSpareavtale,
    Spinner,
    SummaryCard,
    SummaryHeader,
    SummaryTotalValue,
} from '../../../common/share/ShareComponents';
import AppMessage from '../../../common/AppMessage/AppMessage';
import {
    formatAmount,
    getAvkastning,
    getPageAppending,
    openSmartspart,
    sortList,
} from '../../../common/util/Functions';
import {
    getAgreementOrderSettting,
    getFondUtviklingConfigList,
    getKontiTypeName,
    getNewFondOrder,
} from '../../../common/util/FondFunctions';
import { getAPPactionList, getLabelList, isPortfolioOnlyView } from '../../../common/util/BusinessFunctions';
import {
    getCurrentAccount,
    getErrorToPerformSalgByttUttak,
    getErrorToPerformSettInnPenger,
    getFondOrdrePendingMessage,
} from '../util/Functions';
import { getAvtaleCardList } from '../../Spareavtale/util/Functions';
import './Fond.scss';
import { RouterConfig } from '../../../app/Router';
import useMutualFundStore from '../../../stores/MutualFundStore';

type Props = RouteComponentProps<{ accountId?: string; isin?: string }> & RouterConfig;

interface State {
    appending: string;
    actionList?: ActionButtonListItemProps[];
    content: any;
    disponert?: boolean;
    menuList: any;
    panelView: any;
    fund: any;
    account: any;
    agreementType: string;
    started: any;
    reducer: any;
    ordreInfo?: any;
    attention?: { type: string; text: string };
    infoLink?: { url: string; name: string };
    linkList?: LinkListItemProps[];
    labelList?: any;
    morningstar?: any;
}

export default (props: Props): JSX.Element => {
    const { setEntry, setPaymentType, setCurrentFund } = useMutualFundStore();
    const reducer = useSelector((state: any) => {
        return ['App', 'Fond'].reduce((p: { [k: string]: any }, k: string) => {
            return { ...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    function getContent(reducer: any): any {
        const account = getCurrentAccount(props, reducer);
        if (!account) {
            return;
        }

        const { portefoljeData, disponertePortefoljer } = reducer.Fond || {};
        const { match, appTexts } = props;
        const { isin = '', accountId = '' } = match.params || {};
        const fund = (account.fond || [])
            .concat(account.eksterneFond || [])
            .find((d: any) => d.isin && isin && `${d.isin}` === `${isin}`);
        if (!fund) {
            return;
        }

        const fundSingleBuyPossible = fund.allowedTradeTypes?.includes('BUY');
        const fundPeriodicBuyPossible = fund.allowedTradeTypes?.includes('PERIODIC');

        const url = window.location.href.replace(/\/+$/g, '');
        const morningstar = (reducer.Fond?.morningstar || []).find((d: any) => d.isin === isin);
        const ordreInfo = getFondOrdrePendingMessage({
            count: (fund.ordreList || []).length,
            error: (fund.ordreList || []).filter((d: any) => d.statusGroup === Constant.orderStatusNoMoney).length,
        });

        const content: any = {
            summary: [
                { key: 'totalVerdi', field: 'totalVerdi' },
                { key: 'innskudd', field: 'innskudd' },
                { key: 'urealisertGevinst', field: 'urealisertGevinst' },
            ].reduce(
                (p: { [k: string]: null | number | string }, c: { key: string; field: string }) => {
                    p[c.key] = fund[c.field];
                    return p;
                },
                { warning: '' }
            ),
            avtaleList: getAvtaleCardList(portefoljeData, disponertePortefoljer, {
                isin,
                portefoljeId: accountId,
            }).list,
            utvikling: isPortfolioOnlyView(account?.portefoljeType)
                ? undefined
                : {
                      name: 'Om fondets historikk',
                      description: 'Grafene nedenfor viser årlig gjennomsnitt av fondets generelle utvikling.',
                      link: { url: `${url}/fondVisning`, text: 'Om fondet', icon: IconTypes.BUET_PIL_HOEYRE_20 },
                      list: morningstar
                          ? getFondUtviklingConfigList()
                                .map((config: any) => {
                                    return {
                                        ...config,
                                        arrow: true,
                                        value: (morningstar.utvikling || {})[config.id] || 0,
                                        realValue: (morningstar.utvikling || {})[config.id],
                                    };
                                })
                                .filter((d: any) => !!d)
                          : [],
                  },
            allowOnetimeAmount: !!fund?.fondInfo?.minimumsBelopEngangsKjop,
            allowMonthlyAmount: !!fund?.fondInfo?.minimumsBelopPeriodiskKjop,
        };

        content.summary.avkastning = getAvkastning(content.summary.totalVerdi, content.summary.innskudd);
        content.summary.totalVerdi = formatAmount(content.summary.totalVerdi, true);

        if (content.avtaleList.length) {
            content.avtaleList = sortList(content.avtaleList, 'piority', false, true);
        }

        const allowedToSell = !!(morningstar?.allowedTradeTypes || []).find(
            (type: string) => type === Constant.allowedToSell
        );
        const noPermission =
            !allowedToSell ||
            account.disponert ||
            isPortfolioOnlyView(account.portefoljeType) ||
            fund.totalVerdi < Constant.fundLowestMinimumFee;
        const regularPortfolio =
            account.portefoljeType === Constant.typeIps ||
            account.portefoljeType === Constant.typeVpk ||
            account.portefoljeType === Constant.typeAsk;
        return {
            ordreInfo,
            content,
            attention: fund.sperre
                ? { type: 'tag -blocked', text: appTexts.get(`${fund.sperre.toLowerCase()}SperretKonto`) }
                : undefined,
            morningstar,
            fund,
            account,
            disponert: account.disponert,
            labelList: getLabelList(account, { eier: true, ips: true }),
            infoLink: { url: url.replace(`/${isin}`, ''), name: getKontiTypeName(account.portefoljeType) },
            actionList:
                account.disponert || isPortfolioOnlyView(account.portefoljeType)
                    ? undefined
                    : getAPPactionList({
                          disabledOneTime: !fundSingleBuyPossible,
                          disabledPeriodic: !fundPeriodicBuyPossible,
                      }),
            linkList: [
                isPortfolioOnlyView(account.portefoljeType)
                    ? null
                    : {
                          id: 'orde-historikk',
                          url: `${window.location.href.replace(/\/+$/g, '')}/ordre`,
                          name: appTexts.get('ordreHistorikk'),
                          icon: IconTypes.BEVEGELSER_36,
                          dataTrackId: Constant.trackIdProduct,
                          dataTrackLinkname: appTexts.get('ordreHistorikk', undefined),
                      },
                noPermission || account.portefoljeType === Constant.typeIps
                    ? null
                    : {
                          id: Constant.actionSelg,
                          name: appTexts.get('taUtPenger'),
                          dataTrackId: Constant.trackIdProduct,
                          dataTrackLinkname: appTexts.get('taUtPenger', undefined),
                      },
                noPermission || !/^eika\s/i.test(fund.navn) || !regularPortfolio
                    ? null
                    : {
                          id: Constant.actionBytt,
                          name: appTexts.get('menyByttFond'),
                          dataTrackId: Constant.trackIdProduct,
                          dataTrackLinkname: appTexts.get('menyByttFond', undefined),
                      },
            ].filter((d: any) => !!d),
        };
    }

    const [state, setState] = useState<State>({
        //@ts-ignore
        ...getContent(reducer),
        appending: getPageAppending(props),
        started: useRef<boolean>(false),
        reducer,
    });

    const previousReducer = usePrevious(reducer);
    const productName = state.fund?.navn ?? '';

    const fundPeriodicBuyPossible = state?.fund?.fondInfo?.allowedTradeTypes?.includes('PERIODIC');

    useEffect(() => {
        if (JSON.stringify(reducer) === JSON.stringify(previousReducer)) {
            return;
        }
        // @ts-ignore
        setState({ ...state, reducer, ...getContent(reducer) });
        setCurrentFund(state.fund);
        //eslint-disable-next-line
    }, [previousReducer, reducer, state, props]);

    const click = (e: MouseEvent | null, key = '', data?: any): void => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        if (key === Constant.actionSettInnPenger || key === Constant.actionApneSmartspar) {
            openSmartspart(key, data);
        } else if (key === Constant.actionKjopTilBarn) {
            props.history.push(`${props.location.pathname}/comingsoon`);
        } else if (key === 'change-panel-view' && data) {
            setState({ ...state, panelView: { ...state.panelView, selected: data.id } });
        } else if (key === Constant.actionKjop) {
            setEntry('FUND');
            const { accountId = '', isin = '' } = props.match.params || {};
            const config = { placement: accountId, fund: isin, initFund: isin, initPlacement: accountId };
            if (state.account?.disponert) {
                props.history.push(`${props.location.pathname}/disponert`);
            } else if (state.content?.allowMonthlyAmount && state.content?.allowOnetimeAmount) {
                const order = getNewFondOrder(config);
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step0?action=${Constant.piwikActionBuy}`
                );
            } else if (state.content?.allowMonthlyAmount) {
                const order = getNewFondOrder({ ...config, type: Constant.typeManedligSparing });
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionMonthly}`
                );
            } else if (state.content?.allowOnetimeAmount) {
                const order = getNewFondOrder({ ...config, type: Constant.typeEngangsinnkudd });
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionOnetime}`
                );
            }
        } else if (key === Constant.actionKjopEngang) {
            setEntry('FUND');
            setPaymentType('ONCE');
            const error = getErrorToPerformSettInnPenger(state.account, Constant.typeEngangsinnkudd);
            if (error) {
                props.history.push(`${props.location.pathname}/stopp/${error}`);
            } else if (state.account?.disponert) {
                props.history.push(`${props.location.pathname}/disponert`);
            } else {
                const { accountId = '', isin = '' } = props.match.params || {};
                const order = getNewFondOrder({
                    type: Constant.typeEngangsinnkudd,
                    placement: accountId,
                    fund: isin,
                });
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionOnetime}`
                );
            }
        } else if (key === Constant.actionKjopSpareavtale) {
            setEntry('FUND');
            setPaymentType('MONTHLY');
            const error = getErrorToPerformSettInnPenger(state.account, Constant.typeManedligSparing);
            if (error) {
                props.history.push(`${props.location.pathname}/stopp/${error}`);
            } else if (state.account?.disponert) {
                props.history.push(`${props.location.pathname}/disponert`);
            } else {
                const { accountId = '', isin = '' } = props.match.params || {};
                const order = getNewFondOrder({
                    type: Constant.typeManedligSparing,
                    placement: accountId,
                    fund: isin,
                });
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionMonthly}`
                );
            }
        } else if (key === Constant.actionEndreSpareavtale && data) {
            if (state.account?.disponert) {
                props.history.push(`${props.location.pathname}/disponert`);
            } else {
                const order = getNewFondOrder({
                    ...getAgreementOrderSettting(data),
                    action: Constant.actionEndreSpareavtale,
                    type: Constant.typeManedligSparing,
                    // finishBack: 1,
                });
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionEditAgreement}`
                );
            }
        } else if (key === Constant.actionBytt) {
            const error = getErrorToPerformSalgByttUttak(state.fund);
            if (error) {
                props.history.push(`${props.location.pathname}/stopp/${error}`);
            } else {
                const { accountId = '', isin = '' } = props.match.params || {};
                const order = getNewFondOrder({
                    placement: accountId,
                    current: isin,
                    maxAmount: Math.round(state.fund.totalVerdi),
                    action: Constant.actionBytt,
                });
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionSwitch}`
                );
            }
        } else if (key === Constant.actionSelg) {
            const error = getErrorToPerformSalgByttUttak(state.fund);
            if (error) {
                props.history.push(`${props.location.pathname}/stopp/${error}`);
            } else if (state.account?.disponert) {
                props.history.push(`${props.location.pathname}/disponert`);
            } else if (!state.morningstar || !state.morningstar.currency) {
                props.history.push(`${props.location.pathname}/stopp/sell-missing-currency`);
            } else {
                const { accountId, isin } = props.match.params || {};
                const isVPK = state.account.portefoljeType === 'VPK';
                const order = getNewFondOrder({
                    placement: accountId || '',
                    fund: isin || '',
                    maxAmount: Math.round(state.fund.totalVerdi),
                    action: Constant.actionSelg,
                    type: isVPK ? Constant.typeBankkonto : '',
                    foreignCurrencyForceToSellAll: state.morningstar.currency !== Constant.nok,
                });
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionSell}`
                );
            }
        }
    };

    return (
        <div className="fond-wrapper">
            <Grid
                namespace="sparing-top"
                list={[
                    {
                        layout: 'twelve',
                        element: <HeaderLink text="Konti" />,
                    },
                ]}
            />

            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: (
                            <>
                                {(state.labelList || []).length > 0 && (
                                    <Card type="plain" data={{ labelList: state.labelList }} />
                                )}
                                <h1>{productName || props.appTexts.get('fond')}</h1>
                                {!!state.infoLink && (
                                    <div className="header-info-link-wrapper">
                                        <Link icon={IconTypes.BUET_PIL_HOEYRE_20} href={state.infoLink.url}>
                                            {state.infoLink.name}
                                        </Link>
                                    </div>
                                )}
                            </>
                        ),
                    },
                ]}
            />

            <div
                className={classNames(`fond-content ${state.appending}`, {
                    '-not-allow-montly': !state.content?.allowMonthlyAmount,
                    '-not-allow-onetime': !state.content?.allowOnetimeAmount,
                })}
            >
                {isPortfolioOnlyView(state.account?.portefoljeType) ? (
                    <Grid
                        list={[
                            {
                                layout: 'twelve',
                                element: (
                                    <div className="vps-fond-wrapper">
                                        <div className="vps-fond-content">
                                            <div
                                                aria-labelledby="sparing-fund-total-text"
                                                className="header-total-value"
                                            >
                                                {state.content.summary.totalVerdi}
                                            </div>
                                            <div id="sparing-fund-total-text" className="header-total-text">
                                                {props.appTexts.get('totalverdi')}
                                            </div>
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    />
                ) : (
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'seven',
                                element: (
                                    <>
                                        {!state.content ? (
                                            <Spinner namespace="paragraph-large" type="fond" />
                                        ) : (
                                            <>
                                                <AppMessage appMessage={reducer?.App?.serviceMessage} context="FUND">
                                                    {state.ordreInfo?.count > 0 && state.ordreInfo?.url && (
                                                        <Message role="presentation" {...state.ordreInfo}>
                                                            <div className="paragraph -only-top">
                                                                <Link
                                                                    data-tack-id={Constant.trackIdProduct}
                                                                    data-track-linkname={props.appTexts.get(
                                                                        'ordreHistorikk',
                                                                        undefined
                                                                    )}
                                                                    href={state.ordreInfo.url}
                                                                >
                                                                    {props.appTexts.get('ordreHistorikk')}
                                                                </Link>
                                                            </div>
                                                        </Message>
                                                    )}
                                                </AppMessage>

                                                {!!state.attention && (
                                                    <div className="paragraph">
                                                        <Message role="presentation" {...state.attention} />
                                                    </div>
                                                )}

                                                {!!state.content.summary && (
                                                    <section className="paragraph">
                                                        <div className="wallpaper-wrapper only-for-desktop">
                                                            <div className="max-width-600">
                                                                {state.content.summary.totalVerdi !== undefined && (
                                                                    <SummaryTotalValue
                                                                        namespace="paragraph"
                                                                        label={props.appTexts.get('totalVerdi')}
                                                                        value={state.content.summary.totalVerdi}
                                                                        message={state.content.summary.warning}
                                                                    />
                                                                )}
                                                                <SummaryCard {...state.content.summary}>
                                                                    {!isPortfolioOnlyView(
                                                                        state.account?.portefoljeType
                                                                    ) && (
                                                                        <div className="paragraph-extra-small -only-top">
                                                                            {state.account?.portefoljeType ===
                                                                                Constant.typeIps &&
                                                                            (state.content.avtaleList || []).length >
                                                                                0 ? (
                                                                                <StandardButton
                                                                                    data-tack-id={Constant.trackIdBuy}
                                                                                    data-track-linkname="engangsbeleop"
                                                                                    onClick={(e) => {
                                                                                        click(
                                                                                            e,
                                                                                            Constant.actionKjopEngang
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Engangsbeløp
                                                                                </StandardButton>
                                                                            ) : (
                                                                                <StandardButton
                                                                                    data-tack-id={Constant.trackIdBuy}
                                                                                    data-track-linkname="spar-i-fond"
                                                                                    onClick={(e) => {
                                                                                        click(e, Constant.actionKjop);
                                                                                    }}
                                                                                >
                                                                                    Spar i fond
                                                                                </StandardButton>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </SummaryCard>
                                                            </div>
                                                        </div>

                                                        <SummaryHeader
                                                            {...state.content.summary}
                                                            displayDetail
                                                            namespace="only-for-mobile paragraph-small"
                                                        />
                                                    </section>
                                                )}

                                                {!isPortfolioOnlyView(state.account?.portefoljeType) && (
                                                    <div className="paragraph only-for-mobile center">
                                                        {state.account?.portefoljeType === Constant.typeIps &&
                                                        (state.content.avtaleList || []).length > 0 ? (
                                                            <StandardButton
                                                                data-tack-id={Constant.trackIdBuy}
                                                                data-track-linkname="engangsbeleop"
                                                                onClick={(e) => {
                                                                    click(e, Constant.actionKjopEngang);
                                                                }}
                                                            >
                                                                Engangsbeløp
                                                            </StandardButton>
                                                        ) : (
                                                            <StandardButton
                                                                data-tack-id={Constant.trackIdBuy}
                                                                data-track-linkname="spar-i-fond"
                                                                onClick={(e) => {
                                                                    click(e, Constant.actionKjop);
                                                                }}
                                                            >
                                                                Spar i fond
                                                            </StandardButton>
                                                        )}
                                                    </div>
                                                )}

                                                <LinkList
                                                    list={state.linkList}
                                                    namespace="max-width-600 paragraph-large"
                                                    click={(e: any, k: string) => {
                                                        click(e, k);
                                                    }}
                                                />

                                                {!!state.content.utvikling && (
                                                    <section
                                                        aria-labelledby="font-utvikling-title"
                                                        className="fond-utvikling-wrapper"
                                                    >
                                                        <div className="paragraph -include-top">
                                                            <h2 id="font-utvikling-title">
                                                                {state.content.utvikling.name}
                                                            </h2>
                                                            {!!state.content.utvikling.description && (
                                                                <div className="paragraph-small -only-top">
                                                                    {state.content.utvikling.description}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <ul className="fond-utvikling-list fund-development-return-list">
                                                            {state.content.utvikling.list.map((d: any) => (
                                                                <li key={d.id} className="fond-utvikling-item">
                                                                    <FlexTextHolder
                                                                        text={[
                                                                            [
                                                                                {
                                                                                    element: (
                                                                                        <span className="pie-holder">
                                                                                            <ReturnPie {...d} />
                                                                                        </span>
                                                                                    ),
                                                                                },
                                                                                {
                                                                                    text:
                                                                                        d.realValue === null ||
                                                                                        d.realValue === undefined
                                                                                            ? '-'
                                                                                            : `${d.value} %`.replace(
                                                                                                  '.',
                                                                                                  ','
                                                                                              ),
                                                                                    type: 'value-info',
                                                                                },
                                                                                { text: d.name, type: 'value-note' },
                                                                            ],
                                                                        ]}
                                                                    />
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        <div className="paragraph -only-top fond-link-wrapper">
                                                            <Link
                                                                data-tack-id={Constant.trackIdProduct}
                                                                data-track-linkname={state.content.utvikling.link.text
                                                                    .replace(/\s+/g, '-')
                                                                    .toLowerCase()}
                                                                href={state.content.utvikling.link.url}
                                                                icon={state.content.utvikling.link.icon}
                                                            >
                                                                {state.content.utvikling.link.text}
                                                            </Link>
                                                        </div>
                                                    </section>
                                                )}
                                            </>
                                        )}
                                    </>
                                ),
                            },
                            {
                                layout: 'four',
                                element: (
                                    <>
                                        <ActionButtonListView
                                            namespace="paragraph-large only-for-desktop wallpaper cell-2"
                                            list={state.actionList}
                                            click={(e: any, k = '', d?: any) => {
                                                click(e, k, d);
                                            }}
                                        />

                                        {!isPortfolioOnlyView(state.account?.portefoljeType) && (
                                            <div className="paragraph-large">
                                                {!state.content && <Spinner type="linkPanel" />}

                                                {!!state.content && (
                                                    <>
                                                        <div
                                                            className={`${
                                                                (state.content.avtaleList || []).length
                                                                    ? 'only-for-desktop'
                                                                    : 'agreement-info'
                                                            }`}
                                                        >
                                                            <SectionSpareavtale
                                                                list={state.content.avtaleList || []}
                                                                max={1}
                                                                fullData
                                                                click={(e: any, k: string, d: any) => {
                                                                    click(e, Constant.actionEndreSpareavtale, d.detail);
                                                                }}
                                                            >
                                                                {(state.content.avtaleList || []).length &&
                                                                fundPeriodicBuyPossible ? (
                                                                    <Link
                                                                        href={`${window.location.href.replace(
                                                                            /\/+$/g,
                                                                            ''
                                                                        )}/spareavtale`}
                                                                        icon={IconTypes.BUET_PIL_HOEYRE_20}
                                                                    >
                                                                        {props.appTexts.get('seAlleSpareavtaler')}
                                                                    </Link>
                                                                ) : (
                                                                    <>
                                                                        {!!state.account && (
                                                                            <LinkButton
                                                                                onClick={(e) => {
                                                                                    click(
                                                                                        e,
                                                                                        Constant.actionKjopSpareavtale
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {props.appTexts.get(
                                                                                    'startMaanedligSparing'
                                                                                )}
                                                                            </LinkButton>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </SectionSpareavtale>
                                                        </div>

                                                        {(state.content.avtaleList || []).length > 0 && (
                                                            <div className="only-for-mobile">
                                                                <div className="paragraph-extra-small section-title">
                                                                    {props.appTexts.get('maanedligSparing')}
                                                                </div>
                                                                <Carousel
                                                                    list={(state.content.avtaleList || []).map(
                                                                        (d: any, i: number) => (
                                                                            <Card
                                                                                namespace="fond-spareavtale-card -ellipsis"
                                                                                key={`sammendrag-summary-avtale-item-${i}`}
                                                                                data={d.data}
                                                                                click={(e) => {
                                                                                    click(
                                                                                        e,
                                                                                        Constant.actionEndreSpareavtale,
                                                                                        d.detail
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )
                                                                    )}
                                                                />
                                                                <div className="paragraph-extra-small -only-top">
                                                                    <Link
                                                                        href={`${window.location.href.replace(
                                                                            /\/+$/g,
                                                                            ''
                                                                        )}/spareavtale`}
                                                                        icon={IconTypes.BUET_PIL_HOEYRE_20}
                                                                    >
                                                                        {props.appTexts.get('seAlleSpareavtaler')}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                    />
                )}
            </div>
        </div>
    );
};
