import React, { useState, useEffect } from 'react';
import Modal from '../modal';
import ActionButton from '../action-button';
import { getURLquery } from '../common/Functions';

const AVTALEGIRO_STATUS_OK = '?status=ok';
const AVTALEGIRO_STATUS_CANCEL = '?status=cancel';
const AVTALEGIRO_STATUS_ERROR = '?status=error';

const AvtalegiroModal = () => {
    const [state, setState] = useState({
        showModal: false,
        message: '',
        backStep: 0,
        type: '',
    });

    useEffect(() => {
        if (window.location.href.includes(AVTALEGIRO_STATUS_OK)) {
            setState({ showModal: true, message: 'Avtalegiro opprettet', backStep: 7, type: 'success'});
        } else if (window.location.href.includes(AVTALEGIRO_STATUS_CANCEL)) {
            setState({ showModal: true, message: 'Signering av Avtalegiro ble avbrutt', backStep: 2, 'type': 'cancel'});
        } else if (window.location.href.includes(AVTALEGIRO_STATUS_ERROR)) {
            setState({ showModal: true, message: 'Signering av Avtalegiro feilet', backStep: 7, type: 'error'});
        }
    }, []);

    const refreshPage = () => {
        setState({ showModal: false, message: ''});

        if ( !window.location.href.match(/oversikt\/sparing/i) && !window.location.href.match(/localhost/i) ) {           
            if ( state.type === 'success' ) {
                window.location.href = `${window.location.origin}/spare`;
            } else {
                window.location.href = `${window.location.origin}/spare/fondssparing`;
            }
        } else {
            let query = getURLquery();
            if ( ! query.length ) {
                query = getURLquery( query.status );
            }

            if ( query.length ) {
                const length = parseInt( query.length, 10 );
                const diff = window.history.length - length;
                const back = diff < state.backStep ? state.backStep : diff;
                // if (confirm(`back: ${back}`) !== true) { return; }
                window.history.go((back * -1));
            } else {
                window.history.go(state.backStep);
            }
        }
    };

    return (
        <Modal visModal={state.showModal}>
            <>
                <div aria-live="polite" style={{textAlign: 'center', padding: '20px', fontWeight: '600', fontSize: '20px'}}>{state.message}</div>
                <ActionButton action={refreshPage} text="Oppdater siden" />
            </>
        </Modal>
    );
};

export default AvtalegiroModal;
