import React, { Component } from 'react';
import PropTypes from 'prop-types';

require('../styles/valg-knapper.scss');

class ValgKnapper extends Component {
    static propTypes = {
        tittel: PropTypes.string,
        valgMuligheter: PropTypes.array.isRequired,
        valg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        endreValg: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            valgMuligheter: this.genererValgKnapper(props.valgMuligheter, props.valg)
        };
    }

    settValg = valg => {
        this.setState({
            valgMuligheter: this.genererValgKnapper(this.props.valgMuligheter, valg)
        });

        this.props.endreValg(valg);
    };

    genererValgKnapper = (valgMuligheter, naavearendeValg) => {
        return valgMuligheter.map(valg => {
            const style = valg.id === naavearendeValg ? { color: 'white', backgroundColor: '#004F59' } : {};

            return (
                // eslint-disable-next-line react/button-has-type
                <button
                    key={valg.tekst}
                    className={`btn valg-knapp ${valg.ikon ? 'med-ikon' : ''}`}
                    style={style}
                    onClick={() => {
                        this.settValg(valg.id);
                    }}
                >
                    {this.visIkon(valg)}
                    <span>{valg.tekst}</span>
                </button>
            );
        });
    };

    visIkon = valg => (valg.ikon ? <span className={valg.ikon} /> : null);

    render = () => (
        <div className="valg-knapper">
            <div className="button-label">{this.props.tittel}</div>
            <div className="valg-knappe-rad">{this.state.valgMuligheter}</div>
        </div>
    );
}

export default ValgKnapper;
