/**
 * Created by vagrant on 22.02.17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Format from '../format-functions';

require('../../styles/dotLegend.scss');

class DotLegend extends Component {
    static propTypes = {
        data: PropTypes.array,
        visVerdi: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            innhold: this.renderLegendFields(props.data, props.visVerdi === undefined ? true : props.visVerdi)
        };
    }

    UNSAFE_componentWillReceiveProps = nextProps =>
        this.setState({
            innhold: this.renderLegendFields(
                nextProps.data,
                nextProps.visVerdi === undefined ? true : nextProps.visVerdi
            )
        });

    visVerdi = (verdi, tekst ) => {
        return <div className="dot-legend-row">
            <span className="text">{tekst}</span>
            { verdi !== undefined && <span className="value">: {Format.currency(verdi)}</span> }
        </div>
    }

    /*
    visVerdi = (verdi, tekst, visVerdi) =>
        visVerdi ? (
            <div>
                <span className="text">{tekst}</span>
                {verdi || verdi === 0 ? <span className="value">: {Format.currency(verdi)}</span> : null}
            </div>
        ) : (
            <span className="text">{tekst}</span>
        );
    */

    createLegendField = (value, text, color, visVerdi, index) => {
        const bgcolor = {
            backgroundColor: color
        };

        return (
            <div key={`${text}-${index}`} className="legend-container">
                <div className="legend-wrapper">
                    <div className="circle" style={bgcolor} />
                    <div className="label-container">{this.visVerdi(value, text, visVerdi)}</div>
                </div>
            </div>
        );
    };

    renderLegendFields = (data, visVerdi) =>
        data.map((l, index) => {
            return this.createLegendField(l.value, l.text, l.color, visVerdi, index);
        });

    render = () => <div className="dot-legend">{this.state.innhold}</div>;
}

export default DotLegend;
