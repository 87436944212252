/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import DonutChartWithCheckboxLegend from '../../components/charts/donut-chart-checkbox-legend';
import LinkCard from '../../components/link-card';
import ActionButton from '../../components/action-button';
import OrdreKvittering from '../../components/fond/ordre-kvittering';
import { endreKundeValg, endreKundeInfo } from '../../actions/spareprofil';
import { settSideTittel } from '../../actions/header';
import { lukkKvittering, skjulEndreOmMegModal } from '../../actions/modals';
import Feilmelding from '../../components/feilmelding';
import AppMessage from '../../components/AppMessage';
import Format from '../../components/format-functions';
import ColorFunctions from '../../components/color-functions';
import EndreOmMeg from '../../components/spareprofil/endre-om-meg';
import Modal from '../../components/modal';
import { MAKS_ALDER_FOR_PENSJONSBEREGNING } from '../../domain/pensjons-constants';

import { getKjopFondNextPage } from '../../components/common/Functions';

class SparingSammendrag extends Component {
    static propTypes = {
        kontoer: PropTypes.array,
        portefoljeData: PropTypes.object,
        arbeidsgiversPensjonsSparing: PropTypes.array,
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        kvitteringConfig: PropTypes.object,
        kvitteringsInnhold: PropTypes.object,
        lukkKvittering: PropTypes.func,
        endreKundeValg: PropTypes.func,
        endreKundeInfo: PropTypes.func,
        skjulEndreOmMegModal: PropTypes.func,
        errors: PropTypes.object,
        config: PropTypes.object,
        kundevalg: PropTypes.object,
        kundeInfo: PropTypes.object,
        visEndreOmMegModal: PropTypes.bool,
        lastetDisponertePortefoljer: PropTypes.bool,
        disponertePortefoljer: PropTypes.array,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    UNSAFE_componentWillMount() {
        this.props.settSideTittel(this.props.texts.get('minSparingTittel'));

        const visEndreOmMeg = !(this.props.kundeInfo && this.props.kundeInfo.mobil && this.props.kundeInfo.epost);

        const spareData = this.aggregerSpareData(
            this.props.texts,
            this.props.arbeidsgiversPensjonsSparing,
            this.props.portefoljeData,
            this.props.kontoer,
            this.props.kundevalg,
            this.props.kundeInfo,
        );

        this.setState({
            visEndreOmMeg,
            spareData,
            total: this.regnUtTotal(spareData),
        });
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        const visEndreOmMeg = !(newProps.kundeInfo && newProps.kundeInfo.mobil && newProps.kundeInfo.epost);

        const spareData = this.aggregerSpareData(
            newProps.texts,
            newProps.arbeidsgiversPensjonsSparing,
            newProps.portefoljeData,
            newProps.kontoer,
            newProps.kundevalg,
            newProps.kundeInfo,
        );

        this.setState({
            visEndreOmMeg,
            spareData,
            total: this.regnUtTotal(spareData),
        });
    };

    visAksjeLenkeBasertPaaPortefoljer = (portefoljer, disponertePortefoljer) => {
        const disponertePortefoljerLength = disponertePortefoljer ? disponertePortefoljer.length : 0;
        const antallPortefoljer = portefoljer.length + disponertePortefoljerLength;

        if (antallPortefoljer === 0) {
            return (
                <LinkCard link="kjopFond" gradient={ColorFunctions.hentGradient(0)}>
                    <div className="title med-ikon">
                        <span className="ikon-graflinje-36" />
                        {this.props.texts.get('kjopFond')}
                    </div>
                </LinkCard>
            );
        }

        return (
            <LinkCard link="fond" gradient={ColorFunctions.hentGradient(0)}>
                <div className="title med-ikon">
                    <span className="ikon-graflinje-36" />
                    {this.props.texts.get('minAksjeOgFondSparing')}
                </div>
            </LinkCard>
        );
    };

    visArbeidsgiversSparingLenkeBasertPaaSamtykke = () => {
        if (this.props.kundeInfo.alder < MAKS_ALDER_FOR_PENSJONSBEREGNING) {
            return (
                <LinkCard link="arbeidsgiversSparing" gradient={ColorFunctions.hentGradient(2)}>
                    <div className="title med-ikon">
                        <span className="ikon-palme-24" />
                        {this.props.texts.get('arbeidsgiversSparing')}
                    </div>
                </LinkCard>
            );
        }
        return null;
    };

    regnUtArbeidsgiversPensjonsSparingTotal = (arbeidsgiversPensjonsSparing) =>
        arbeidsgiversPensjonsSparing.reduce((a, b) => a + b.sum, 0);

    regnUtTotal = (data) => data.reduce((a, b) => a + (b.active ? b.value || 0 : 0), 0);

    regnUtTotalFraKontoer = (data) => data.reduce((a, b) => a + (b.aktiv ? b.verdi || 0 : 0), 0);

    aggregerSpareData = (texts, arbeidsgiversPensjonsSparing, portefoljer, kontoer, kundevalg, kundeInfo) => {
        return [
            {
                text: texts.get('verdiAksjerOgFond'),
                value: portefoljer ? portefoljer.totalVerdi : null,
                color: ColorFunctions.hentFarge(0),
                id: 'frontPageIncludeFond',
                toggleable: true,
                active: this.checkIfActive(kundevalg, 'frontPageIncludeFond'),
                disabled: !portefoljer,
            },
            {
                text: texts.get('saldoBankSparing'),
                value: kontoer ? this.regnUtTotalFraKontoer(kontoer) : null,
                color: ColorFunctions.hentFarge(1),
                id: 'frontPageIncludeSparing',
                toggleable: true,
                active: this.checkIfActive(kundevalg, 'frontPageIncludeSparing'),
                disabled: !kontoer,
            },
            kundeInfo?.alder && kundeInfo?.alder >= 75 ? null : {
                text: texts.get('arbeidsgiversSparingKort'),
                value: arbeidsgiversPensjonsSparing
                    ? this.regnUtArbeidsgiversPensjonsSparingTotal(arbeidsgiversPensjonsSparing)
                    : null,
                color: ColorFunctions.hentFarge(2),
                id: 'frontPageIncludePensjon',
                toggleable: true,
                active: this.checkIfActive(kundevalg, 'frontPageIncludePensjon'),
                disabled: !arbeidsgiversPensjonsSparing,
            },
        ].filter( (data) => !!data);
    }

    checkIfActive = (kundevalg, sjekkBoksId) => kundevalg && kundevalg[sjekkBoksId];

    sjekkSpareData = () => {
        const data = this.state.spareData;

        return (
            data[0]?.value ||
            data[0]?.value === 0 ||
            data[1]?.value ||
            data[1]?.value === 0 ||
            data[2]?.value ||
            data[2]?.value === 0
        );
    };

    sjekkForServerFeil = (errors) => errors.LAST_PORTEFOLJE || errors.LAST_SPARE_DATA || errors.HENTE_KONTOER;

    endreAktiv = (id, aktiv) => this.props.endreKundeValg({ [id]: aktiv });

    visPensjonskalkulatorKnapp = (config) => {
        if (
            config.minimumsAlder <= config.maximumsAlder &&
            this.props.kundeInfo.alder < MAKS_ALDER_FOR_PENSJONSBEREGNING
        ) {
            return (
                <ActionButton action="pensjonsKalkulator" text={this.props.texts.get('tilPensjonsKalkulatorKnapp')} />
            );
        }
        return null;
    };

    avbrytEndreOmMeg = () => this.props.skjulEndreOmMegModal();

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (
            this.props.kundeInfo &&
            this.props.lastetDisponertePortefoljer &&
            this.sjekkSpareData() &&
            this.props.portefoljeData &&
            this.props.config &&
            this.props.kundevalg
        ) {
            return (
                <div className="sparing-sammendrag-wrapper">
                    <AppMessage appMessage={this.props.serviceMessage} context="HOME" />

                    <OrdreKvittering
                        texts={this.props.texts}
                        kvitteringConfig={this.props.kvitteringConfig}
                        lukkKvittering={this.props.lukkKvittering}
                        kvitteringsInnhold={this.props.kvitteringsInnhold}
                    />

                    <Modal visModal={this.props.visEndreOmMegModal && this.state.visEndreOmMeg}>
                        <div>
                            <div className="header">{this.props.texts.get('viTrengerLittInformasjon')}</div>
                            <div className="ingress">{this.props.texts.get('viTrengerLittInformasjonIngress')}</div>
                            <EndreOmMeg
                                texts={this.props.texts}
                                endreKundeInfo={this.props.endreKundeInfo}
                                kundeInfo={this.props.kundeInfo}
                                avbryt={this.avbrytEndreOmMeg}
                            />
                        </div>
                    </Modal>

                    <DonutChartWithCheckboxLegend data={this.state.spareData} toggleActive={this.endreAktiv}>
                        <div className="donut-labels">
                            <div className="label-section">
                                <span className="ikon-sparegris-36" />
                                <div className="text">{this.props.texts.get('totalSparing')}</div>
                                <div className="value">{Format.currency(this.state.total)}</div>
                            </div>
                        </div>
                    </DonutChartWithCheckboxLegend>

                    {this.visAksjeLenkeBasertPaaPortefoljer(
                        this.props.portefoljeData.portefoljer,
                        this.props.disponertePortefoljer
                    )}
                    <LinkCard link="minbanksparing" gradient={ColorFunctions.hentGradient(1)}>
                        <div className="title med-ikon">
                            <span className="ikon-sparing-36" />
                            {this.props.texts.get('minBankSparing')}
                        </div>
                    </LinkCard>
                    {this.visArbeidsgiversSparingLenkeBasertPaaSamtykke()}
                    {this.visPensjonskalkulatorKnapp(this.props.config)}
                    
                    <ActionButton action={getKjopFondNextPage('veiviser/kjopFond', true)} text={this.props.texts.get('kjopFond')} />

                    <LinkCard link="spareprofil">
                        <div className="title med-ikon">
                            <span className="ikon-innstillinger-36" />
                            {this.props.texts.get('minSpareProfil')}
                        </div>
                    </LinkCard>
                </div>
            );
        }
        return <Loading title={this.props.texts.get('lasterSparingsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Fond,
            ...state.Modals,
            ...state.Feilmeldinger,
            ...state.Bank,
            ...state.Pensjon,
            ...state.SpareProfil,
            ...state.Texts,
            ...state.Header,
        };
    },
    (dispatch) => {
        return {
            lukkKvittering: () => {
                dispatch(lukkKvittering());
            },
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            endreKundeValg: (valg) => {
                dispatch(endreKundeValg(valg));
            },
            endreKundeInfo: (endring) => {
                dispatch(endreKundeInfo(endring));
            },
            skjulEndreOmMegModal: () => {
                dispatch(skjulEndreOmMegModal());
            },
        };
    }
)(SparingSammendrag);
