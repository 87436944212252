import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';

import ColorFunctions from '../color-functions';

require('../../styles/donutChart.scss');

class DonutChart extends Component {
    static propTypes = {
        data: PropTypes.array,
        children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        size: PropTypes.number,
        barThickness: PropTypes.number,
        loading: PropTypes.bool
    };

    constructor(props) {
        super(props);
        const size = this.props.size || 200;
        const barThickness = this.props.barThickness || 25;
        const sumValue = this.getSumValue(this.props.data);
        const circleData = {
            circumference: (size - barThickness) * Math.PI,
            size,
            barThickness,
            radius: size / 2 - barThickness / 2,
            offset: size / 2
        };

        this.state = {
            childIndex: 0,
            circleData,
            labelArea: size - barThickness * 2,
            sections: this.createDonut(this.props.data, sumValue, circleData)
        };
    }

    UNSAFE_componentWillReceiveProps = newProps => {
        const sumValue = this.getSumValue(newProps.data);

        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            sections: this.createDonut(newProps.data, sumValue, this.state.circleData)
        });
    };

    createDonut = (data, sumValue, circleData) => {
        if (sumValue === 0) {
            return this.createEmptyDonut(data, sumValue, circleData);
        }

        return this.createDonutPies(data, sumValue, circleData);
    };

    createEmptyDonut = (data, sumValue, circleData) => {
        const emptyCircleData = { ...circleData, barThickness: circleData.barThickness / 2 };

        return [this.createDonutPie(ColorFunctions.hentGraaFarge(), emptyCircleData, circleData.circumference, 0)];
    };

    getSumValue = data => {
        return data.reduce((a, b) => a + Math.abs(b.value !== undefined && b.active ? b.value : 0), 0);
    };

    createCrossHatchedStrokeDasharray = (start, slutt, interval, circumference) => {
        let counter = 0;
        const strokeDashArray = [0, start];
        while (counter < slutt) {
            strokeDashArray.push(interval);
            counter += interval;
        }
        strokeDashArray.push(slutt);
        strokeDashArray.push(circumference);
        return strokeDashArray;
    };

    createStrokeDasharray = (start, slutt, circumference) => {
        return [0, start, slutt, Math.ceil(circumference)];
    };

    createDonutPie = (farge, circleData, donutSectionSize, currentDegree, skravert, index) => {
        const donutSectionSizeWithPadding = donutSectionSize > 2 ? donutSectionSize - 2 : 0;

        const style = {
            strokeWidth: circleData.barThickness,
            stroke: skravert ? 'rgba(255, 255, 255, 0.2)' : farge,
            strokeDasharray: skravert
                ? this.createCrossHatchedStrokeDasharray(
                      currentDegree,
                      donutSectionSizeWithPadding,
                      2,
                      circleData.circumference
                  )
                : this.createStrokeDasharray(currentDegree, donutSectionSizeWithPadding, circleData.circumference)
        };

        return (
            <circle
                key={`${skravert ? 'skravert-' : ''}${farge}-${currentDegree}-${index}`}
                r={circleData.radius}
                cx={circleData.offset}
                cy={circleData.offset}
                className="pie"
                style={style}
            />
        );
    };

    createDonutPies = (data, sumValue, circleData) => {
        const sections = [];
        let currentDegree = 0;

        data.forEach((pie, index) => {
            if (pie.value !== undefined) {
                const donutSectionSize =
                    (Math.abs(pie.active ? pie.value : 0) / sumValue) * Math.floor(circleData.circumference);

                sections.push(
                    this.createDonutPie(pie.color, circleData, donutSectionSize, currentDegree, false, index)
                );

                if (pie.skravert) {
                    sections.push(this.createDonutPie(pie, circleData, donutSectionSize, currentDegree, true, index));
                }

                currentDegree += donutSectionSize;
            }
        });

        return sections;
    };

    nextChild = () => {
        if (Array.isArray(this.props.children) && !this.props.loading) {
            // eslint-disable-next-line react/no-access-state-in-setstate
            const newIndex = this.state.childIndex + 1;
            this.setState({
                childIndex: newIndex % this.props.children.length
            });
        }
    };

    showChildrenContent = () => {
        if (this.props.loading) {
            return <Loading />;
        }

        if (Array.isArray(this.props.children)) {
            return this.props.children[this.state.childIndex];
        }
        return this.props.children;
    };

    render() {
        const donutClassNames = this.props.loading ? 'loading' : '';

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div className="donut-chart" onClick={this.nextChild}>
                <div className="donut-label-wrapper" style={{ width: this.state.labelArea }}>
                    {this.showChildrenContent()}
                </div>
                <svg
                    width={this.state.circleData.size}
                    height={this.state.circleData.size}
                    className={`${donutClassNames} chart`}
                >
                    {this.state.sections}
                </svg>
            </div>
        );
    }
}

export default DonutChart;
