import { Component } from 'react';

class KjopFondInnganger extends Component {
    render = () => {
        return null;
    }   
}

export default KjopFondInnganger;


/*
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import { Link } from 'react-router-dom';

import KjopFondInngang from '../../../components/fond/kjop-fond/kjop-fond-inngang';
import { settSideTittel } from '../../../actions/header';
import Feilmelding from '../../../components/feilmelding';
import Helper from '../../../components/helper-functions';
import TextInput from '../../../components/common/text-input';
import * as kundevurderingConst from '../../../domain/kundevurdering';
import KundevurderingStopp from '../../../components/KundevurderingStopp';

class KjopFondInnganger extends Component {
    static propTypes = {
        match: PropTypes.object,
        morningstar: PropTypes.array,
        portefoljeData: PropTypes.object,
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        errors: PropTypes.object,
        kundevurdering: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = this.createState(props.morningstar, props.portefoljeData);
    }

    UNSAFE_componentWillMount = () => {
        this.props.settSideTittel(this.props.texts.get('kjopFondTittel'));

        this.setState(this.createState(this.props.morningstar, this.props.portefoljeData));
    };

    UNSAFE_componentWillReceiveProps = (newProps) =>
        this.setState(this.createState(newProps.morningstar, newProps.portefoljeData));

    createState = (morningstar, portefoljeData) => {
        const { match } = this.props;
        const { params } = match;
        const { portefoljeId } = params;
        if (morningstar) {
            const filterOnASK = this.filterOnAsk(portefoljeId, portefoljeData);

            return {
                eikaFondsKort: this.genererUtEkspanderendeKortListe(morningstar, true, filterOnASK),
                eksterneFondsKort: this.genererUtEkspanderendeKortListe(morningstar, false, false),
            };
        }

        return {};
    };

    filterOnAsk = (portefoljeId, portefoljeData) =>
        !!(
            portefoljeId &&
            portefoljeData &&
            Helper.hentPortefoljeBasertPaaId(this.props.match.params.portefoljeId, portefoljeData.portefoljer).type ===
                'ASK'
        );

    genererUtEkspanderendeKortListe = (morningstar, filterOnEika, filterOnASK) =>
        morningstar
            .filter((fond) => fond.aksjesparekontoKompatibel || !filterOnASK)
            .filter((fond) => (filterOnEika ? fond.navn.startsWith('Eika') : !fond.navn.startsWith('Eika')))
            .map((fond) => {
                return {
                    fond,
                    hidden: false,
                    ekspanderendeKort: {
                        id: fond.isin,
                        expanded: false,
                        onExpand: this.onExpand,
                    },
                };
            });

    genererUtFondsKjopInnganger = (fondsKort) =>
        fondsKort.map((e) => (
            <KjopFondInngang
                key={e.ekspanderendeKort.id}
                texts={this.props.texts}
                hidden={e.hidden}
                fond={e.fond}
                expandItem={e.ekspanderendeKort}
                portefoljeId={this.props.match.params.portefoljeId}
                sparemaalId={this.props.match.params.sparemaalId}
                viskjopsKnapp
            />
        ));

    onExpand = (isin) => {
        const { eikaFondsKort, eksterneFondsKort } = this.state;
        this.setState({
            eikaFondsKort: this.updateExpandedProps(eikaFondsKort, isin),
            eksterneFondsKort: this.updateExpandedProps(eksterneFondsKort, isin),
        });
    };

    updateExpandedProps = (fondskort, isin) =>
        fondskort.map((e) => ({
            fond: e.fond,
            hidden: e.hidden,
            ekspanderendeKort: {
                ...e.ekspanderendeKort,
                expanded: e.ekspanderendeKort.id === isin ? !e.ekspanderendeKort.expanded : false,
            },
        }));

    sjekkForServerFeil = (errors) => errors.LAST_MORNINGSTAR_DATA;

    genererUtFondsKjøpLister = (label, fondsListe) => {
        if (fondsListe.length === 0) return null;

        return (
            <div>
                <h2 className="h3 horizontal-margin-space">{label}</h2>
                {this.genererUtFondsKjopInnganger(fondsListe)}
            </div>
        );
    };

    oppdaterSok = (event) => {
        const { eikaFondsKort, eksterneFondsKort } = this.state;
        const sokeord = event.target.value;
        const textReg = sokeord ? Helper.createRegexp(sokeord, 0, 1, 1) : null;

        this.setState({
            eikaFondsKort: this.filtrerPaaSokeOrd(eikaFondsKort, textReg),
            eksterneFondsKort: this.filtrerPaaSokeOrd(eksterneFondsKort, textReg),
        });
    };

    filtrerPaaSokeOrd = (fondsKortListe, textReg) => {
        return fondsKortListe.map((fondsKort) => {
            let hidden = false;
            if ( textReg && !textReg.test(fondsKort.fond.navn) ) {
                const alternative = (fondsKort.fond.navn || '').match( /(\+|\spluss)/i ) ? [
                    fondsKort.fond.navn.replace( /(\+|\spluss)/i, '+' ), 
                    fondsKort.fond.navn.replace( /(\+|\spluss)/i, 'pluss' ),
                ] : null;

                if ( !alternative || !alternative.find( (v) => textReg.test(v)) ) {
                    hidden = true;
                }
            }
            return { ...fondsKort, hidden };
        });
    }

    visIngenTreff = (fondsKortListe) =>
        fondsKortListe.every((f) => f.hidden) && <div className="default-margin">- Ingen treff -</div>;

    render() {
        return null;
    }

    render() {
        if ( this.props.kundevurdering === kundevurderingConst.MA_BEHANDLES_MANUELT ) {
            return <KundevurderingStopp {...this.props} type="stand-alone" />            
        }

        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.eikaFondsKort && this.state.eksterneFondsKort) {
            return (
                <div className="fond-kjops-innganger">
                    <div className="horizontal-margin-space">
                        <h1 className="h2">{this.props.texts.get('fondListeTittel')}</h1>                        
                    </div>
                    <div className="horizontal-margin-space paragraph -large -include-top">
                        { this.props.standalone ? 
                            <Link to="/kjopveileder" className="link font-size-regular">
                               {this.props.texts.get('faaFundVeilder')}
                            </Link>
                            :
                            <a href="#" className="link font-size-regular" onClick={(e) => {
                                if ( e && e.preventDefault() ) { e.preventDefault(); }
                                window.history.back();
                            }}>{this.props.texts.get('faaFundVeilder')}</a>
                        }
                    </div>

                    <TextInput id="fond-sok" onChange={this.oppdaterSok} placeholder="Søk på fond..." />

                    <div className="hide-shadow-layer" />
                    <div className="shadow-layer" />

                    {this.genererUtFondsKjøpLister('Eika-fond', this.state.eikaFondsKort)}
                    {this.visIngenTreff(this.state.eikaFondsKort)}
                    {this.genererUtFondsKjøpLister('Fond fra andre leverandører', this.state.eksterneFondsKort)}
                    {this.visIngenTreff(this.state.eksterneFondsKort)}
                </div>
            );
        }

        return <Loading title={this.props.texts.get('lasterFondsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Fond,
            ...state.Feilmeldinger,
            ...state.Texts,
            kundevurdering: state.KjennDinKunde?.kundevurdering,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
        };
    }
)(KjopFondInnganger);
*/
