import { get, put } from "@eika-infrastruktur/request";
import {processError, processResponse} from './common';

export const HENT_SPAREMAAL = 'HENT_SPAREMAAL';
export const OPPRETT_SPAREMAAL = 'OPPRETT_SPAREMAAL';

export const BE_OM_GAVE = 'BE_OM_GAVE';
export const MOTTA_GAVE = 'MOTTA_GAVE';

const getSparemaalUrl = '/sparing-min-side-web/rest/resource/sparemaal';
const createSparemaalUrl = '/sparing-min-side-web/rest/resource/sparemaal';
const updateSparemaalUrl = '/sparing-min-side-web/rest/resource/sparemaal';

export const getSparemaal = () => {
    return dispatch => {
        get(getSparemaalUrl).then(data =>
            processResponse(HENT_SPAREMAAL, data, dispatch)
        ).catch((error) => processError(HENT_SPAREMAAL, error, dispatch));
    };
};

export const createSparemaal = data => {
    return dispatch => {
        put(createSparemaalUrl, data)
            .then(data => {processResponse(OPPRETT_SPAREMAAL, data, dispatch)})
            .catch((error) => processError(OPPRETT_SPAREMAAL, error, dispatch));
    };
};

export const updateSparemaal = data => {
    return dispatch => {
        put(updateSparemaalUrl, data).then(data => {
            processResponse(HENT_SPAREMAAL, data, dispatch);
        }).catch((error) => processError(HENT_SPAREMAAL, error, dispatch));
    };
};

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case OPPRETT_SPAREMAAL:
        case HENT_SPAREMAAL:
            return {
                ...state,
                sparemaal: action.data
            };
        default:
            return state;
    }
};
