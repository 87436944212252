import React, { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@eika/modal';
import { StandardButton, ButtonTypes } from '@eika/button';
import { formatAmount, formatDateToText, getPageAppending } from '../../../common/util/Functions';
import { getTransactionLabelList } from '../../../common/util/BusinessFunctions';
import Constant from '../../../static/data/Constant';
// @ts-ignore	
import EPKriskProfileList from '../../../static/data/EPKriskProfileList';
// @ts-ignore
import getEikaRiskProfileInfomation from '../util';
import { usePrevious } from '../../../common/util/Hook';
import Grid from '../../../common/Grid/Grid';
import Card from '../../../common/Card/Card';
import CardExpander from '../../../common/CardExpander/CardExpander';
import AppMessage from '../../../common/AppMessage/AppMessage';
import { HeaderLink, Spinner, SummaryTotalValue } from '../../../common/share/ShareComponents';
import { ViewTransactionContent } from '../share/ShareComponents';
import { SigningConfig } from '../EPKmove/EPKmoveDomain';
import '../Arbeidsgiver.scss';
import './ArbeidsgiverRisikoprofil.scss';

interface Props {
    appTexts: any;
    action: any;
    match?: { params?: { risikoprofil?: string}};
};

interface Content {
	totalValue?: number;
	product?: string;
	category?: string;
	riskProfile?: string;
	portfolioBaseCode?: string[];
	detail?: any;
};

interface Transaction {
	order?: string[];
	pin: { [k: string]: any };
	hasMore: boolean;
};

interface State {
    appending: string;
    open: boolean;
	loading: boolean;
	now: number,
	modal: boolean;
    content?: Content;
   	transaction?: Transaction;
   	pendingTransaction?: Transaction
};

const getPendingTransactions = (content: Content, signingConfig: SigningConfig): Transaction | undefined => {
	const moveDate = signingConfig.signing ? new Date( signingConfig.signing.updated ) : null;
	const year = `${moveDate ? moveDate.getFullYear() : '-'}`;

	return signingConfig.isEpkInTransitToEika ? {
		order: [year],
		pin: {[year]: [
			{
				stamp: moveDate ? moveDate.getTime() : -1,
				data: {
					textList: [[			
				        {text: [
							moveDate ? formatDateToText( moveDate, 'dd.mm' ) : '-',
				        ].filter( (d) => !!d ), type: 'date', ariaLabel: `Den ${moveDate ? formatDateToText( moveDate, 'dd. MM yyyy' ) : '-'}`},
				        {text: [
				            {text: 'Pensjon på vei', type: 'fund-name'},
				            {text: 'Ordre i prosess: Eika Egen Pensjonskonto', type: 'fund-action'},
				        ], type: 'info'},
				        {text:  content.riskProfile || '', type: 'amount', ariaLabel:  content.riskProfile ? `Risikoprofil ${content.riskProfile}` : ''},
				    ]]
				},
			}
		]},
		hasMore: false,
	} : undefined ;
}

const getTransactions = ( props: Props, state: State, setState: (s: State) => void ): void => {
	const portfolioBaseCode = state.content?.portfolioBaseCode || [];
	if ( portfolioBaseCode.length === 0 ) {
		return setState({...state, loading: false });
	}

	setState({...state, loading: true});
	const aDay = 1000 * 60 * 60 * 24;
	const toDate = new Date(state.now);
	const fromDate = new Date( (state.now - (aDay * 365)));
	const config = {
		id: 'epk',
		param: portfolioBaseCode.map( (code: string) => `portfolioBaseCode=${code}` ),
		toDate,
		fromDate: new Date( (fromDate.getTime() - (aDay * 90))),
	};

	const now = fromDate.getTime();
	props.action.getTransactions( config, (result: any) => {
		const transaction: Transaction = { pin: state.transaction?.pin ?? {}, hasMore: false };
		if ( !result || !result.transactions ) { return setState({...state, loading: false, transaction }); }

		let list: any = [];
		for ( const key in result.transactions ) {
			list = list.concat( result.transactions[key] );
		}

		if ( list.length === 0 ) { return setState({...state, loading: false, transaction }); }

		list.forEach( (d: any) => {
			if ( !d || !d.date ) { return; }

			const date = new Date(d.date);
			if ( date.getTime() < now ){
				transaction.hasMore = true;
				return;
			}

			const year = `${date.getFullYear()}`;
			if ( !transaction.pin[year] ) { transaction.pin[year] = []; }

			const amount = d.amount || 0;
			const data = {
		        textList: [[
		            {text: [
						formatDateToText( date, 'dd.mm' ),
		            ].filter( (d) => !!d ), type: 'date', ariaLabel: `Den ${formatDateToText( date, 'dd. MM yyyy' )}`},
		            {text: [
		                {text: d.fundName, type: 'fund-name', ariaLabel: `Fond ${d.fundName}`},
		                {text: (d.type || '').toLowerCase() === 'buy' ? 'Kjøp' : 'Salg', type: 'fund-action'},
		            ], type: 'info'},
		            {text: `${formatAmount(amount)}`, type: 'amount', ariaLabel: `${amount} kr`},
		        ]],
		        labelList: getTransactionLabelList( d ),
			};
			transaction.pin[year].push({data, stamp: date.getTime()});
		});

		transaction.order = Object.keys(transaction.pin).sort().reverse();
		transaction.order.forEach( (year: string ) => {
			transaction.pin[year] = transaction.pin[year].sort((a: any, b: any): number => {
		        const x = a.stamp;
		        const y = b.stamp;
		        if (x === y) { return 0; }
		        return x < y ? 1 : -1;
		    });
		});
		setState({...state, now, loading: false, transaction });
	});
}

const getContent = ( props: Props, state: State, setState: (s: State) => void, reducer: any ): void => {
	const content: Content = { ...(state.content ?? {}) };
    const { arbeidsgiversPensjonsSparing=[] } = reducer.Pensjon ?? {};
    if ( content.riskProfile ) { return; }

    const { match={} } = props;
	const data = arbeidsgiversPensjonsSparing.find( (d: any) => d.source ===  Constant.tradex ) ||
		EPKriskProfileList.find( (d: any) => d.reg.test(match.params?.risikoprofil ) );
   
	if ( data ) {
	    content.riskProfile = data.title || `${data.riskProfile || ''}`.replace(/eika\s+selvvalgt/i, '').trim();
	    if ( content.riskProfile && /forsiktig/i.test(content.riskProfile)) { 
	    	content.riskProfile  = 'Lav'; 
	    }

	    content.totalValue = Math.round( (data.balance || 0) );
	    content.product = data.productDescription ?? '';
	    content.category = data.category ?? '';
	    content.portfolioBaseCode = [
	    	data.sourceSpecificInfo?.EpkAvtaleinnhold?.portfolioBaseCodeEpkAktiv,
	    	data.sourceSpecificInfo?.EpkAvtaleinnhold?.portfolioBaseCodeEpkPassiv,
	    ].filter( (code='') => !!code);
	}

	const info = getEikaRiskProfileInfomation().find((d: any) => d.reg.test(content.riskProfile) );
	content.detail = { title: 'Din risikoprofil', riskProfile: content.riskProfile };
	if ( info ) {
		['price', 'profile', 'text'].forEach( (key: string) => {
			content.detail[key] = (info[key as keyof { price: string[], profile: string[], text: string[] }] || []).map( (d: any, i: number) => {
				return  { data: { textList: [
					(d instanceof Array ? d : [d]).map( (text='') => {
						return  { text, type: i || key !== 'price' ? 'font-normal' : 'font-bold'};
					})
				]}}
			});
		});
	}
	
	const updated = {...state, content, pendingTransaction: getPendingTransactions( content, reducer.Epk?.signingConfig )};
	if ( content.portfolioBaseCode && content.portfolioBaseCode.length ) {
		getTransactions( props, updated, setState );
	} else {
		setState({...updated,loading: false});
	}
};

const click = ( props: Props, state: State, setState: (s: State) => void, e: MouseEvent | null, key=''): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

   	if ( key === 'toggle-expander') {
	    setState({...state, open: !state.open});
   	} else if ( key === 'toggle-account-info' ) {
	    setState({...state, modal: !state.modal});
   	} else if ( key === 'show-more' ) {
		getTransactions( props, state, setState );
   	}
}

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Pensjon', 'Epk'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const [state, setState] = useState<State>({
    	open: false,
		loading: true,
		modal: false,
		now: (new Date()).getTime(),
        appending: getPageAppending( props ),
    });

    const previousReducer = usePrevious(reducer);
    useEffect(() => {
    	//const ignore = (state.content && reducer.Epk?.signingConfig) || 
    	// 	JSON.stringify(reducer) === JSON.stringify(previousReducer); 

    	const ignore = !reducer.Epk?.signingConfig ||
    		JSON.stringify(reducer) === JSON.stringify(previousReducer); 
        if ( ignore ) { return; }
        props.action.setPageTitle(props.appTexts.get('Pensjonssparing'));
        getContent(props, state, setState, reducer);
   }, [previousReducer, reducer, state, props]);

    useEffect(() => {
    	if ( reducer.Epk?.signingConfig ) { return; }
        props.action.getEPKsigningConfig();
    }, [props.action, reducer.Epk?.signingConfig]);

	return <div className="arbeidsgiver-risikoprofil-wrapper">
        <Grid namespace="sparing-top" list={[{
            layout: 'twelve',
            element: <HeaderLink length={3} text="Pensjon fra arbeidsgiver" />
        }]}/>

        <Grid namespace="sparing-header" list={[{
            layout: 'twelve',
            element: <>
            	<h1>{state.content?.product || 'Egen pensjonskonto'}</h1>
                { !!state.content?.category && <div className="header-info-link-wrapper">
                    <a href="#" onClick={(e) =>{ click(props, state, setState, e, 'toggle-account-info'); }}>{state.content.category}</a>
                </div>}            	
            </>
        }]}/>

		<div className={`arbeidsgiver-profil-common-wrapper arbeidsgiver-risikoprofil-content ${state.appending || 'none-appending'}`}>
            <Grid namespace="sparing-content" list={[{
                layout: 'seven',
                element: <>
                    <AppMessage appMessage={reducer?.App?.serviceMessage} context="EPK_PROFILE" />

                    { !!state.content?.detail && <div className="only-for-mobile paragraph">
                        <SummaryTotalValue 
                            namespace="paragraph-large center"
                            label={props.appTexts.get('totalSaved')}
                            value={formatAmount(state.content.totalValue)}
                        />

                        <CardExpander title={state.content.detail.title} riskProfile={state.content.detail.riskProfile}>
		                    <section className="description-section paragraph -only-top" aria-label="Beskrivelse">
			                   { (state.content.detail.text || []).map( (config: any, i: number) => (
			                        <Card key={`detail-text-${i}`} {...config} type="list-item"/>
			                    ))}
			                </section>
		                    <section className="profile-section paragraph -only-top" aria-label="Spareprofil">
			                   { (state.content.detail.profile || []).map( (config: any, i: number) => (
			                        <Card key={`detail-profile-${i}`} {...config} type="list-item" />
			                    ))}
			                </section>
		                    <section className="price-section paragraph -only-top" aria-label="Priser">
			                   { (state.content.detail.price || []).map( (config: any, i: number) => (
			                        <Card key={`detail-price-${i}`} {...config} type="list-item" />
			                    ))}
			                 </section>                        	

                        </CardExpander>
		            </div>}

                   	{ !!state.content && <section className="paragraph only-for-desktop">
                        <div className="wallpaper-wrapper">
                            <div className="max-width-600">
                                <SummaryTotalValue 
                                    namespace="paragraph-large"
                                    label={props.appTexts.get('totalSaved')}
                                    value={formatAmount(state.content.totalValue)}
                                />

                                <ViewTransactionContent order={state.pendingTransaction?.order || []} storage={state.pendingTransaction?.pin || {}} type="pending" />
                                <ViewTransactionContent order={state.transaction?.order || []} storage={state.transaction?.pin || {}} />

                        		{ !!state.transaction?.hasMore && !state.loading && <div className="paragraph -include-top center">
		                        	<StandardButton buttonType={ButtonTypes.STANDARD_NEGATIVE} onClick={(e) => { click(props, state, setState, e, 'show-more');}}>
		                        		{props.appTexts.get('showMore')}
		                        	</StandardButton>
		                    	</div>}

		                        { !state.transaction?.hasMore && !state.loading && <div className="transaction-footer">
		                        	{ (state.transaction?.order || []).length || (state.pendingTransaction?.order || []).length ? 'Ingen flere transaksjoner.' : 'Ingen transaksjoner.'}
		                    	</div>}
                            </div>
                        </div>
                    </section> }

                    <div className="only-for-mobile paragraph-large -only-top">
                        <ViewTransactionContent order={state.pendingTransaction?.order || []} storage={state.pendingTransaction?.pin || {}} type="pending" />
                        <ViewTransactionContent order={state.transaction?.order || []} storage={state.transaction?.pin || {}} />

                        { !!state.transaction?.hasMore && !state.loading && <div className="paragraph -include-top center">
                        	<StandardButton buttonType={ButtonTypes.STANDARD_NEGATIVE} onClick={(e) => { click(props, state, setState, e, 'show-more');}}>
                        		{props.appTexts.get('showMore')}
                        	</StandardButton>
                    	</div>}

                        { !state.transaction?.hasMore && !state.loading && <div className="transaction-footer">
                        	{ (state.transaction?.order || []).length ? 'Ingen flere transaksjoner.' : 'Ingen transaksjoner.'}
                    	</div>}
                    </div>

		            { !!state.loading && <Spinner type="linkPanel" /> }
                </>
            }, {
                layout: 'four',
                element: <>
                	<div className="only-for-desktop">
	                    { !!state.content?.detail && 
	                    	<CardExpander static title={state.content.detail.title} riskProfile={state.content.detail.riskProfile}>
			                    <section className="description-section paragraph -only-top" aria-label="Beskrivelse">
				                   { (state.content.detail.text || []).map( (config: any, i: number) => (
				                        <Card key={`detail-text-${i}`} {...config} type="list-item"/>
				                    ))}
				                </section>
			                    <section className="profile-section paragraph -only-top" aria-label="Spareprofil">
				                   { (state.content.detail.profile || []).map( (config: any, i: number) => (
				                        <Card key={`detail-profile-${i}`} {...config} type="list-item" />
				                    ))}
				                </section>
			                    <section className="price-section paragraph -only-top" aria-label="Priser">
				                   { (state.content.detail.price || []).map( (config: any, i: number) => (
				                        <Card key={`detail-price-${i}`} {...config} type="list-item" />
				                    ))}
				                 </section>
				            </CardExpander> 
				       	}

    		            { !!state.loading && !state.content?.detail && <Spinner type="linkPanel" /> }
			        </div>
                </>
            }]}/>
        </div>

        <Modal id="epk-profile-modal" appNamespace="sparing-react-app" onClose={() => { setState({...state, modal: false}); }} show={!!state.modal} >
        	<h2 className="h2 paragraph">{props.appTexts.get('aboutEPK')}</h2>
        	<div className="paragraph">{props.appTexts.get('aboutEPKdescription1')}</div>
        	<div className="paragraph">{props.appTexts.get('aboutEPKdescription2')}</div>
        	<div className="paragraph">{props.appTexts.get('aboutEPKdescription3')}</div>
        </Modal>
    </div>
};

