import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as AccountTypes from '../domain/account-types';

require('../styles/dropdown.scss');

class NedTrekksMenyKonto extends Component {
    static propTypes = {
        texts: PropTypes.object,
        kontoer: PropTypes.array,
        onChange: PropTypes.func,
        id: PropTypes.string,
        disabled: PropTypes.bool,
        defaultValue: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            options: this.generateOptionList(props.kontoer),
            active: false,
            toggleClicked: false
        };

        window.addEventListener('click', this.onWindowClick);
    }

    UNSAFE_componentWillReceiveProps = newProps => {
        this.setState({
            options: this.generateOptionList(newProps.kontoer)
        });
    };

    componentDidMount = () => {
        if ( !this.props.defaultValue ) { return; }
        const account = (this.props.kontoer || []).find( (d) => d.kontonummer === this.props.defaultValue );
        if ( !account ) { return; }
        this.onOptionClick( account.navn, account.kontonummer, false );
    };


    generateOptionList = kontoer => {
        return kontoer.map(konto => {
            if (konto.type === AccountTypes.EGENDEFINERT) {
                return (
                    <li className="dropdown-liste-item" key={konto.type}>
                        <button
                            type="button"
                            onClick={() => {
                                this.onOptionClick(konto.navn, konto.type);
                            }}
                        >
                            <div>{konto.navn}</div>
                        </button>
                    </li>
                );
            }

            return (
                <li className="dropdown-liste-item" key={konto.kontonummer}>
                    <button
                        type="button"
                        onClick={() => {
                            this.onOptionClick(konto.navn, konto.kontonummer);
                        }}
                    >
                        <div>{konto.navn}</div>
                        <div>{konto.kontonummer}</div>
                        <div>{konto.beholdning}</div>
                    </button>
                </li>
            );
        });
    };

    UNSAFE_componentWillMount = () => {
        this.setDefaultValue(this.props.texts.get('velgKonto'));
    };

    componentWillUnmount() {
        window.removeEventListener('click', this.onWindowClick);
    }

    onWindowClick = () => {
        if (!this.state.toggleClicked) {
            this.setState({ active: false });
        }

        this.setState({
            toggleClicked: false
        });
    };

    onToggleClick = event => {
        event.preventDefault();
        if (!this.props.disabled) {
            this.setState({
                // eslint-disable-next-line react/no-access-state-in-setstate
                active: !this.state.active,
                toggleClicked: true
            });
        }
    };

    setDefaultValue = text => {
        this.setState({
            currentOption: text
        });
    };

    onOptionClick = (text, value, active) => {
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            active: typeof(active) === 'boolean' ? active : !this.state.active,
            toggleClicked: true,
            currentOption: text
        });

        this.props.onChange(text, value);
    };

    render() {
        const style = this.state.active ? { display: 'block' } : { display: 'none' };
        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div className="input-group" onClick={this.onToggleClick}>
                <div className="dropdown primary">
                    <button
                        disabled={this.props.disabled}
                        className="btn dropdown-toggle override"
                        type="button"
                        id={this.props.id}
                    >
                        <span className="option">{this.state.currentOption}</span>
                        <span className="caret override">
                            <i className="ikon-liten-pil-ned" />
                        </span>
                    </button>
                    <ul className="dropdown-menu" style={style}>
                        {this.state.options}
                    </ul>
                </div>
            </div>
        );
    }
}

export default NedTrekksMenyKonto;
