import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Route, Switch, HashRouter as Router, Redirect} from 'react-router-dom';
import {RouteComponentProps} from 'react-router';
import {
    endreAlleSamtykker,
    endreSamtykkeType,
    hentKundeValg,
    lastSamtykke,
    lastSpareProfil,
    endreSpareProfil,
    getTerms,
    postTerms,
    submitAllTerms
} from '../actions/spareprofil';
import {
    lastMorningstarData, getPortefolje, setPortefolje, getVPS,
    kjopFond, byttFond, hentGyldigePortefoljer,
    hentSkattemessigKostpris, getAarligSparebelop, setAarligSparebelop,
    getFondHistorikk, endreMndSparing, getDebitAccount,
} from '../actions/fond';
import { getFondKalkulator, setFondKalkulator } from '../actions/kalkulator';
import {
    getKundevurdering, setKundevurdering, getListeOverCRSLand, setListeOverCRSLand,
    getSkjemaForKunde, setSkjemaForKunde, sendKjennDinKundeSvar,
    getOppfolgingSkjemaForKunde, setOppfolgingSkjemaForKunde
} from '../actions/kjenndinkunde';
import { hentKontoer, hentKundeForhold } from '../actions/bank';
import { lastarbeidsgiversPensjonssparing, lastPrognoseForAvtale, getForventetPensjon, getPensjonVedOktMndSparing, getTransactions } from '../actions/pensjon';
import {
    isOnMobile,
    isOnAndroid,
    isOnWebview,
    isOnTestEnvironment,
    setPageTitle,
    setFondHandelInitialization,
    getServiceMessage,
    setServiceMessage,
    postLog,
    getNorneURL
} from '../actions/app';
import { hentVentendeOrdre, getAlleVentendeOrdre, getSisteOrdre, setSigneringUrl, setVentendeTransaksjoner } from '../actions/ordre';
import { settKvitteringsInnhold } from '../actions/modals';
import OpprettSalgsOrdre from '../actions/selg-fond';
import OpprettUttaksOrdre from '../actions/uttak';
import { fullforOrdre } from '../actions/signerings-ordre';
import {
    getEPKsigningConfig, setEPKsigningConfig, getEPKmoveData, setEPKmoveData, postMoveEPK,
    changeEPKriskProfile, getDevelopmentHistoryGraph, setDevelopmentHistoryGraph, cancelEPKsigning,
    getEPKmoveStatus
} from '../actions/epk';

import {
    getStorebrandInfo,
    getStorebrandTransaction, setStorebrandTransaction,
    getStorebrandPensionPayout, setStorebrandPensionPayout,
} from '../actions/storebrand';

import App from './App';
import Guideline from '../components/Guideline/Guideline';
import Profil from '../components/Profil/Profil';
import FundOverview from '../common/FundOverview/FundOverview';
import BlankPage from '../common/BlankPage/BlankPage';
import StopView from '../common/StopView/StopView';
import SparingSammendrag from '../components/SparingSammendrag/SparingSammendrag';
import Portefolje from '../components/Portefolje/Portefolje';
import Fond from '../components/Portefolje/Fond/Fond';
import Konti from '../components/Portefolje/Konti/Konti';
import Klientkonto from '../components/Portefolje/Klientkonto/Klientkonto';
import FondHandel from '../components/FondHandel/FondHandel';
import Arbeidsgiver from '../components/Arbeidsgiver/Arbeidsgiver';
import EPKmove from '../components/Arbeidsgiver/EPKmove/EPKmove';
import ArbeidsgiverRisikoprofil from '../components/Arbeidsgiver/ArbeidsgiverRisikoprofil/ArbeidsgiverRisikoprofil';
import Ordre from '../components/Ordre/Ordre';
import Pensjonsberegning from '../components/Kalkulator/Pensjonsberegning';
import Fondsberegning from '../components/Kalkulator/Fondsberegning';
import HurtigKjop from '../components/Portefolje/HurtigKjop/HurtigKjop';
import FondVisning from '../components/FondVisning/FondVisning';
import Spareavtale from '../components/Spareavtale/Spareavtale';
import StorebrandInfoPage from '../components/Arbeidsgiver/Storebrand/StorebrandInfoPage';
import SavingProfilePage from '../components/Arbeidsgiver/Storebrand/SavingsProfiles/SavingProfilePage';
import StorebrandAgreementPage from '../components/Arbeidsgiver/Storebrand/StorebrandAgreementPage';

import {Action, AppTexts, MultiObject, Term} from '../domain/Types';
import SwitchSavingProfilePage from '../components/Arbeidsgiver/Storebrand/SavingsProfiles/SwitchSavingProfilePage';
import SavingProfileDetailsPage from '../components/Arbeidsgiver/Storebrand/SavingsProfiles/SavingProfileDetailsPage';
import {getStorebrandProfilesEnabled} from '../actions/features';

type RouterProps = {
    config: {
        bankregnr: string,
        data: {
            text: Record<string, string>
        }
    };
    portefoljeData: { kontoer?: unknown[]};
    portefoljeSparing: MultiObject;
    kundeforhold: { bankregnr: string }[];
    page?: string;
    header?: string;
    length?: number;
};

export type RouterConfig = RouterProps & {
    action: Action
    storage: {pathname?: string},
    appTexts: AppTexts
}

export default (props: RouterProps): JSX.Element => {
    const dispatch = useDispatch();

    const config: RouterConfig = {
        ...props,
        action: {
            dispatch,
            setPageTitle: (text: string) => {dispatch(setPageTitle(text)); },
            isOnTestEnvironment: () => {dispatch(isOnTestEnvironment()); },
            isOnWebview: () => {dispatch(isOnWebview()); },
            isOnAndroid: () => {dispatch(isOnAndroid()); },
            isOnMobile: () => {dispatch(isOnMobile()); },
            getServiceMessage: (app?: string) => {dispatch(getServiceMessage(app)); },
            setServiceMessage: (data: any) => {dispatch(setServiceMessage(data)); },
            getNorneURL: () => {dispatch(getNorneURL()); },
            hentKundeForhold: () => {dispatch(hentKundeForhold()); },
            hentKontoer: () => {dispatch(hentKontoer()); },
            endreAlleSamtykker: () => { dispatch(endreAlleSamtykker()); },
            lastarbeidsgiversPensjonssparing: () => { dispatch(lastarbeidsgiversPensjonssparing()); },
            lastSpareProfil: () => { dispatch(lastSpareProfil()); },
            getVPS: () => { dispatch(getVPS()); },
            getPortefolje: (callback?: (p: any) => void) => { dispatch(getPortefolje(callback)); },
            setPortefolje: (data: any) => { dispatch(setPortefolje(data)); },
            getAarligSparebelop: (portfolioBaseCode: string, callback?: (p: any) => void) => { dispatch(getAarligSparebelop(portfolioBaseCode, callback)); },
            setAarligSparebelop: (portfolioBaseCode: string, data: any) => { dispatch(setAarligSparebelop(portfolioBaseCode, data)); },
            lastMorningstarData: () => { dispatch(lastMorningstarData()); },
            lastSamtykke: () => { dispatch(lastSamtykke()); },
            hentKundeValg: () => { dispatch(hentKundeValg()); },
            endreSamtykkeType: (samtykke: any) => { dispatch(endreSamtykkeType(samtykke)); },
            getKundevurdering: (barnFnr: string, callback?: (p: any) => void) => { dispatch(getKundevurdering(barnFnr, callback));},
            setKundevurdering: (data: any) => { dispatch(setKundevurdering(data));},
            getOppfolgingSkjemaForKunde: (callback?: (p: any) => void) => { dispatch(getOppfolgingSkjemaForKunde(callback));},
            setOppfolgingSkjemaForKunde: (data: any) => { dispatch(setOppfolgingSkjemaForKunde(data));},
            getListeOverCRSLand: (callback?: (p: any) => void) => { dispatch(getListeOverCRSLand(callback));},
            setListeOverCRSLand: (data: any) => { dispatch(setListeOverCRSLand(data));},
            getSkjemaForKunde: (callback?: (p: any) => void) => { dispatch(getSkjemaForKunde(callback));},
            setSkjemaForKunde: (data: any) => { dispatch(setSkjemaForKunde(data));},
            lastPrognoseForAvtale: (avtalenummer: string, polisenummer: string) => { dispatch(lastPrognoseForAvtale(avtalenummer, polisenummer)); },
            hentVentendeOrdre: (accountId: string | number, isin: string) => { dispatch(hentVentendeOrdre(accountId, isin)); },
            getAlleVentendeOrdre: (portefoljeData: any, callback?: (p: any) => void) => { dispatch(getAlleVentendeOrdre(portefoljeData, callback)); },
            getSisteOrdre: (portefoljeData: any, callback?: (p: any) => void) => { dispatch(getSisteOrdre(portefoljeData, callback)); },
            endreSpareProfil: (data: any) => { dispatch(endreSpareProfil(data)); },
            endreMndSparing: (data: any) => { dispatch(endreMndSparing(data)); },
            kjopFond: (data: any) => { dispatch(kjopFond(data)); },
            byttFond: (data: any) => { dispatch(byttFond(data)); },
            opprettSalgsOrdre: (data: any) => { dispatch(OpprettSalgsOrdre(data)); },
            OpprettUttaksOrdre: (data: any) => { dispatch(OpprettUttaksOrdre(data)); },
            settKvitteringsInnhold: (data: any) => { dispatch(settKvitteringsInnhold(data)); },
            hentGyldigePortefoljer: (isin: string) => { dispatch(hentGyldigePortefoljer(isin)); },
            hentSkattemessigKostpris: (portfolioBaseCode: string, bankregnr: string) => { dispatch(hentSkattemessigKostpris(portfolioBaseCode, bankregnr)); },
            setSigneringUrl: (data: any) => { dispatch(setSigneringUrl(data)); },
            fullforOrdre: (ordreId: string | number) => { dispatch(fullforOrdre(ordreId)); },
            setVentendeTransaksjoner: (data: any) => { dispatch(setVentendeTransaksjoner(data)); },
            sendKjennDinKundeSvar: (data: any, bankregnr?: string, fnrBarn?: string, tradexCustomerId?: string, callback?: (p: any)=>void) => {dispatch(sendKjennDinKundeSvar(data, bankregnr, fnrBarn, tradexCustomerId, callback)); },
            getFondHistorikk: (isin?: string, length?: number, type?: string, callback?: (p:any) => void) => { dispatch(getFondHistorikk(isin, length, type, callback)); },
            getFondKalkulator: (data: any, callback?: (p:any) => void) => { dispatch(getFondKalkulator(data, callback)); },
            setFondKalkulator: (data: any ) => { dispatch(setFondKalkulator(data)); },
            setFondHandelInitialization: (data: any) => { dispatch(setFondHandelInitialization(data)); },
            getForventetPensjon: (data: any, callback?: (p:any) => void) => { dispatch(getForventetPensjon(data, callback)); },
            getPensjonVedOktMndSparing: (data: any, callback?: (p:any) => void) => { dispatch(getPensjonVedOktMndSparing(data, callback)); },
            getDebitAccount: (callback?: (p: any) => void) => { dispatch(getDebitAccount(callback));},
            getTerms: (application?: string) => { dispatch(getTerms(application)); },
            postTerms: (terms: Term[], term: Term) => { dispatch(postTerms(terms, term)); },
            submitAllTerms: (terms: Term[]) => { dispatch(submitAllTerms(terms)) },
            getTransactions: (config: any, callback: (p:any) => void) => { dispatch(getTransactions(config, callback)); },
            getEPKsigningConfig: ( callback?: (p:any) => void ) => { dispatch(getEPKsigningConfig(callback)); },
            setEPKsigningConfig: (data: any) => { dispatch(setEPKsigningConfig(data)); },
            getEPKmoveStatus: ( callback?: (p:any) => void ) => { dispatch(getEPKmoveStatus(callback)); },
            postLog: (data={}, detail={}) => { dispatch(postLog(data, detail)); },
            getEPKmoveData: (callback?: (p: any) => void) => { dispatch(getEPKmoveData(callback)); },
            setEPKmoveData: (data: any) => { dispatch(setEPKmoveData(data)); },
            postMoveEPK: (data: any, callback?: (p: any) => void) => { dispatch(postMoveEPK(data, callback)); },
            changeEPKriskProfile: (riskProfileId: number, callback?: (p: any) => void) => { dispatch(changeEPKriskProfile(riskProfileId, callback)); },
            getDevelopmentHistoryGraph: (data: any, callback?: (p: any) => void) => { dispatch(getDevelopmentHistoryGraph(data, callback)); },
            setDevelopmentHistoryGraph: (data: any) => { dispatch(setDevelopmentHistoryGraph(data)); },
            cancelEPKsigning: (signingId: string | number, callback?: (p: any) => void) => { dispatch(cancelEPKsigning(signingId, callback)); },
            getStorebrandTransaction: (data: any, callback?: (p: any) => void) => { dispatch(getStorebrandTransaction(data, callback)); },
            setStorebrandTransaction: (data: any) => { dispatch(setStorebrandTransaction(data)); },
            getStorebrandPensionPayout: (data: any, callback?: (p: any) => void) => { dispatch(getStorebrandPensionPayout(data, callback)); },
            setStorebrandPensionPayout: (data: any) => { dispatch(setStorebrandPensionPayout(data)); },
            getStorebrandInfo: (callback?: (p: any) => void) => { dispatch(getStorebrandInfo(callback)); },
            getStorebrandProfilesEnabled: () => { dispatch(getStorebrandProfilesEnabled()); }
        },
        storage: {},
        appTexts: useSelector((state: any) => {
            return state?.Texts?.texts;
        })
    };

    const storebrandProfilesEnabled = useSelector((state: any) => {
        return !!state?.Features?.storebrandProfilesEnabled;
    })

    return <App {...config}>
        <Router>
            <Switch>
                <Route exact path='/' component={(componentProps: RouteComponentProps) => <SparingSammendrag {...config} {...componentProps} />} />
                <Route exact path='/hurtigkjop' component={(componentProps: RouteComponentProps) => <HurtigKjop {...config} {...componentProps} />} />

                <Route exact path='/arbeidsgiver' component={(componentProps: RouteComponentProps) => <Arbeidsgiver {...config} {...componentProps} />} />
                <Route exact path='/arbeidsgiver/epkmove/:order/:step' component={(componentProps: RouteComponentProps) => <EPKmove {...config} {...componentProps} />} />
                <Route exact path='/arbeidsgiver/offentligsektor/:title' component={(componentProps: RouteComponentProps) => <StopView {...config} {...componentProps} page='offentligsektor' header='Pensjon' length={2} />} />
                <Route exact path='/arbeidsgiver/pensjon/:title/:amount/:type/:note' component={(componentProps: RouteComponentProps) => <StopView {...config} {...componentProps} page='pensjon' header='Pensjon' length={5} />} />
                <Route exact path='/arbeidsgiver/pensjonsberegning' component={(componentProps: RouteComponentProps) => <Pensjonsberegning {...config} {...componentProps} />} />
                <Route exact path='/arbeidsgiver/pensjonsberegning/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/arbeidsgiver/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/arbeidsgiver/aapnesmartspar' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />}/>
                <Route exact path='/arbeidsgiver/settinnpenger' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />}/>
                <Route exact path='/arbeidsgiver/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />} />
                <Route exact path='/arbeidsgiver/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />

                <Route exact path='/arbeidsgiver/storebrand/:id/agreement' component={(componentProps: RouteComponentProps<{id?: string}>) => <StorebrandAgreementPage {...config} {...componentProps} />} />

                <Route exact path='/arbeidsgiver/storebrand/:id/spareprofile' component={(componentProps: RouteComponentProps<{id?: string}>) =>
                    storebrandProfilesEnabled ?  <SavingProfilePage {...config} {...componentProps} /> : <Redirect to={`/arbeidsgiver/storebrand/${componentProps.match.params.id}`}/>}
                />
                <Route exact path='/arbeidsgiver/storebrand/:id/spareprofile/switch' component={(componentProps: RouteComponentProps<{id?: string}>) =>
                    storebrandProfilesEnabled ?  <SwitchSavingProfilePage {...config} {...componentProps} /> : <Redirect to={`/arbeidsgiver/storebrand/${componentProps.match.params.id}`}/>}
                />
                <Route exact path='/arbeidsgiver/storebrand/:id/spareprofile/:isin' component={(componentProps: RouteComponentProps<{id?: string}>) =>
                    storebrandProfilesEnabled ?  <SavingProfileDetailsPage {...config} {...componentProps} /> : <Redirect to={`/arbeidsgiver/storebrand/${componentProps.match.params.id}`}/>}
                />

                <Route exact path='/arbeidsgiver/storebrand/:id' component={(componentProps: RouteComponentProps) => <StorebrandInfoPage {...config} {...componentProps} />} />

                <Route exact path='/arbeidsgiver/:risikoprofil/:produkt/:innskuddssaldo' component={(componentProps: RouteComponentProps) => <ArbeidsgiverRisikoprofil {...config} {...componentProps} />} />

                <Route exact path='/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />

                <Route exact path='/ordre' component={(componentProps: RouteComponentProps) => <Ordre {...config} {...componentProps} />} />

                <Route exact path='/spareavtale/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/spareavtale/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/spareavtale' component={(componentProps: RouteComponentProps) => <Spareavtale {...config} {...componentProps} />} />

                <Route exact path='/portefolje' component={(componentProps: RouteComponentProps) => <Portefolje {...config} {...componentProps} />} />
                <Route exact path='/portefolje/fondsberegning' component={(componentProps: RouteComponentProps) => <Fondsberegning {...config} {...componentProps} />} />
                <Route exact path='/portefolje/ordre' component={(componentProps: RouteComponentProps) => <Ordre {...config} {...componentProps} />} />
                <Route exact path='/portefolje/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/aapnesmartspar' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/settinnpenger' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />

                <Route exact path='/portefolje/fondliste/:isin/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/fondliste/:isin/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/portefolje/fondliste/:isin' component={(componentProps: RouteComponentProps) => <FondVisning {...config} {...componentProps}/>}/>
                <Route exact path='/portefolje/fondliste' component={(componentProps: RouteComponentProps) => <FondVisning {...config} {...componentProps} root />}/>
                <Route exact path='/portefolje/fondvisning/:isin/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/fondvisning/:isin/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/portefolje/fondvisning/:isin' component={(componentProps: RouteComponentProps) => <FondVisning {...config} {...componentProps}/>}/>
                <Route exact path='/portefolje/fondvisning' component={(componentProps: RouteComponentProps) => <FondVisning {...config} {...componentProps} root/>}/>
                <Route exact path='/portefolje/pensjonsberegning' component={(componentProps: RouteComponentProps) => <Pensjonsberegning {...config} {...componentProps} />} />
                <Route exact path='/portefolje/pensjonsberegning/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
´
                <Route exact path='/portefolje/:accountId/ordre' component={(componentProps: RouteComponentProps) => <Ordre {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/:accountId/aksjesparekonto' component={(componentProps: RouteComponentProps) => <BlankPage page='aksjesparekonto-tekst' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/:accountId/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/:accountId/aapnesmartspar' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/settinnpenger' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/comingsoon' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/disponert' component={(componentProps: RouteComponentProps) => <BlankPage page='disponert' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/klientkonto/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/:accountId/klientkonto/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/klientkonto/aksjesparekonto' component={(componentProps: RouteComponentProps) => <BlankPage page='aksjesparekonto-tekst' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/klientkonto/stopp/:type' component={(componentProps: RouteComponentProps) => <StopView {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/klientkonto/disponert' component={(componentProps: RouteComponentProps) => <BlankPage page='disponert' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/klientkonto' component={(componentProps: RouteComponentProps<{accountId?: string}>) => <Klientkonto {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/:accountId/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/stopp/:type' component={(componentProps: RouteComponentProps) => <StopView {...config} {...componentProps} />} />

                <Route exact path='/portefolje/:accountId/:isin/spareavtale/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/:accountId/:isin/spareavtale/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/:isin/spareavtale' component={(componentProps: RouteComponentProps) => <Spareavtale {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/:isin/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/:accountId/:isin/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/:isin/stopp/:type' component={(componentProps: RouteComponentProps) => <StopView {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/:isin/settinnpenger' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/:isin/ordre' component={(componentProps: RouteComponentProps) => <Ordre {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/:isin/settinnpenger' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/:isin/tautpenger' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/:isin/aksjesparekonto' component={(componentProps: RouteComponentProps) => <BlankPage page='aksjesparekonto-tekst' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/:accountId/:isin/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/portefolje/:accountId/:isin/fondvisning' component={(componentProps: RouteComponentProps) => <FondVisning {...config} {...componentProps} onlyInfo />} />
                <Route exact path='/portefolje/:accountId/:isin/disponert' component={(componentProps: RouteComponentProps) => <BlankPage page='disponert' {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId/:isin' component={(componentProps: RouteComponentProps<{ accountId?: string; isin?: string; }>) => <Fond {...config} {...componentProps} />} />
                <Route exact path='/portefolje/:accountId' component={(componentProps: RouteComponentProps) => <Konti {...config} {...componentProps} />} />

                <Route exact path='/profil' component={(componentProps: RouteComponentProps) => <Profil {...config} {...componentProps} />}/>
                <Route exact path='/profil/vilkaar' component={(componentProps: RouteComponentProps) => <BlankPage page='vilkaar-tekst' {...config} {...componentProps} />}/>
                <Route exact path='/profil/ipsavtale' component={(componentProps: RouteComponentProps) => <BlankPage page='ips-avtale-tekst' {...config} {...componentProps} />}/>

                <Route exact path='/guideline' component={(componentProps: RouteComponentProps) => <Guideline {...config} {...componentProps} />}/>
                <Route exact path='/fundoverview' component={(componentProps: RouteComponentProps) => <FundOverview {...config} {...componentProps} root />}/>

                <Route exact path='/fondliste/:isin/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/fondliste/:isin/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/fondliste/:isin' component={(componentProps: RouteComponentProps) => <FondVisning {...config} {...componentProps}/>}/>
                <Route exact path='/fondliste' component={(componentProps: RouteComponentProps) => <FondVisning {...config} {...componentProps} root/>}/>
                <Route exact path='/fondvisning/:isin/fondhandel/:order/:step/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/fondvisning/:isin/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/fondvisning/:isin' component={(componentProps: RouteComponentProps) => <FondVisning {...config} {...componentProps}/>}/>
                <Route exact path='/fondvisning' component={(componentProps: RouteComponentProps) => <FondVisning {...config} {...componentProps} root/>}/>
                <Route exact path='/pensjonsberegning' component={(componentProps: RouteComponentProps) => <Pensjonsberegning {...config} {...componentProps} />} />
                <Route exact path='/pensjonsberegning/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />
                <Route exact path='/fondsberegning' component={(componentProps: RouteComponentProps) => <Fondsberegning {...config} {...componentProps} />} />
                <Route exact path='/fondsberegning/fondhandel/:order/:step' component={(componentProps: RouteComponentProps) => <FondHandel {...config} {...componentProps} />} />

                <Route exact path='/aapnesmartspar' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />}/>
                <Route exact path='/settinnpenger' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />}/>
                <Route exact path='/vilkaar' component={(componentProps: RouteComponentProps) => <BlankPage page='vilkaar-tekst' {...config} {...componentProps} />}/>
                <Route exact path='/ipsavtale' component={(componentProps: RouteComponentProps) => <BlankPage page='ips-avtale-tekst' {...config} {...componentProps} />}/>

                <Route exact path='/webviewprevent' component={(componentProps: RouteComponentProps) => <BlankPage page='webview-prevent' {...config} {...componentProps} />}/>
                <Route exact path='/norne' component={(componentProps: RouteComponentProps) => <BlankPage page='norne' {...config} {...componentProps} />} />
                <Route exact path='/signingsuccess' component={() => <div>{window.parent.postMessage('signingSuccess', '*')}</div>} />
                <Route exact path='/signingcancel' component={() => <div>{window.parent.postMessage('signingCancel', '*')}</div>} />
                <Route exact path='/signingerror' component={() => <div>{window.parent.postMessage('signingError', '*')}</div>} />
                <Route exact path='/*' component={(componentProps: RouteComponentProps) => <BlankPage page='coming-soon' {...config} {...componentProps} />}/>
            </Switch>
        </Router>
    </App>
};
