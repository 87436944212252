import React from 'react';
import PropTypes from 'prop-types';
import Card from '../card';
import Helper from '../helper-functions';

require('../../styles/utlistningskort.scss');

export const ListeElement = props => {
    const farget = !(props.index % 2 === 0) ? 'farget' : '';
    const classNames = props.listeElement.isHeader ? 'kol-tittel' : '';

    const printColText = (texts, format, text) => {
        if (texts.has(text)) {
            return texts.get(text);
        }

        if (format) {
            return format(text);
        }

        return text;
    };

    return (
        <div className={`liste-element ${farget}`}>
            <div className={`venstre-kol ${classNames}`}>
                {printColText(props.texts, props.format, props.listeElement.kol1)}
            </div>
            <div className={`hoyre-kol ${classNames}`}>
                {printColText(props.texts, props.format, props.listeElement.kol2)}
            </div>
        </div>
    );
};

ListeElement.propTypes = {
    listeElement: PropTypes.object,
    index: PropTypes.number,
    format: PropTypes.func,
    texts: PropTypes.object
};

const UtlistningsKort = props => (
    <Card fullWidth={props.fullWidth}>
        <div>
            {props.liste.map((listeElement, index) => (
                <ListeElement
                    listeElement={listeElement}
                    key={Helper.guid()}
                    index={index}
                    format={props.format}
                    texts={props.texts}
                />
            ))}
        </div>
    </Card>
);

UtlistningsKort.propTypes = {
    liste: PropTypes.arrayOf(PropTypes.object),
    fullWidth: PropTypes.bool,
    format: PropTypes.func,
    texts: PropTypes.object
};

export default UtlistningsKort;
