// @ts-nocheck // kiet fix it later

export const getURLquery = ( url?: string ): any => {
    const matched =  (url || window.location.href).replace(/\?+/g, '?').match(/^([\w.\-\s_#%/:]+)\?(.*)/);
    if ( !matched || !matched[2] ) { return {}; }

    const splited = (decodeURIComponent(matched[2]) || '').replace(/#\?/g, '&').split('&');
    return splited.reduce( (query, text) => {
        const data = (text || '').match(/(\w+)=(.*)/);
        if ( !data || !data[1] || !data[2] ) { return query; }

        if ( query[data[1]] ) {
            query[data[1]] = (
                query[data[1]] instanceof Array ? query[data[1]] : [query[data[1]]]
            ).concat( data[2] );
        } else {
            query[data[1]] = data[2];
        }
        return query;
    }, {});
};

export const getKjopFondNextPage = ( path: string, ignoreCounting=false ): string => {
    const query = getURLquery();
    if ( !ignoreCounting ) {
        query.count = parseInt( query.count || 0, 10)+1;
    }

    const length = query.length || window.history.length || 0;
    const param = query.ignorlength ? [] : [`length=${length}`];
    for ( const key in query ) {
        if ( key !== 'length') {
            param.push( `${key}=${query[key]}` );
        }
    }

    return `${path}?${param.join('&')}`;
}
