import React, {MouseEvent, useState} from 'react';
import classNames from 'classnames';
import {ActionButton} from '@eika/button';
import {IconTypes} from '@eika/icon';
import {generateId} from '../util/Functions';
import './ActionButtonListView.scss';

export interface ActionButtonListItemProps {
    id: string;
    name: string;
    icon: IconTypes;
    param?: string;
    dataTrackId?: string;
    dataTrackLinkname?: string;
    disabled?: boolean;
}

interface Props {
    list?: ActionButtonListItemProps[];
    click: (e: MouseEvent, k: string) => void;
    namespace?: string;
    expandable?: boolean;
}

interface State {
    id: string;
    expanded: boolean;
}

export default ({list, click, namespace, expandable}: Props): JSX.Element | null => {
    const [state, setState] = useState<State>({
        id: generateId('action-btn-list-view'),
        expanded: false,
    });

    return (list || []).length ? <div id={state.id} className={classNames('action-button-list-wrapper', namespace, {
        '-expandable': !!expandable,
        '-expanded': state.expanded,
    })}>
        {!!expandable && <div className='expander-btn-wrapper'>
            <a
                className='expander-btn'
                href='#'
                role='button'
                data-text={state.expanded ? 'Vis færre' : 'Vis mer'}
                aria-controls={state.id}
                aria-expanded={state.expanded} onClick={(e: MouseEvent) => {
                e.preventDefault();
                setState({...state, expanded: !state.expanded});
            }}
            ><span
                className='aria-visible'>{state.expanded ? 'Kollapse meny liste' : 'Ekspandere meny liste'}</span></a>
        </div>}

        <ul className='action-button-list'>
            {(list || []).map((d: ActionButtonListItemProps) => {
                const attr: { icon: IconTypes } & Record<string, string | boolean> = {
                    icon: d.icon,
                    disabled: !!d.disabled
                };
                if (d.dataTrackId) {
                    attr['data-track-id'] = d.dataTrackId;
                }
                if (d.dataTrackLinkname) {
                    attr['data-track-linkname'] = d.dataTrackLinkname;
                }

                return d && d.id ? <li key={d.id} className={`tool-item -${d.id}`}>
                    <ActionButton className={d.disabled ? '-disabled' : ''} {...attr} onClick={(e) => {
                        if (!d.disabled) {
                            click(e, d.id);
                        }
                    }}>
                        <span className='block tool-item-name'>{d.name}</span>
                    </ActionButton>
                </li> : null;
            })}
        </ul>
    </div> : null;
};

