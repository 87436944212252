/**
 * Created by vagrant on 01.03.17.
 */

import Loading from '@eika-infrastruktur/ui-kort-laster';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { settSideTittel } from '../../actions/header';
import { hentAlleVentendeOrdre, hentSisteOrdre } from '../../actions/ordre';
import ColorFunctions from '../../components/color-functions';
import DateFunctions from '../../components/date-functions';
import Feilmelding from '../../components/feilmelding';
import Format from '../../components/format-functions';
import AppMessage from '../../components/AppMessage';
import LinkCard from '../../components/link-card';

require('../../styles/sisteTransaksjoner.scss');

class SisteTransaksjoner extends Component {
    static propTypes = {
        portefoljeData: PropTypes.object,
        texts: PropTypes.object,
        errors: PropTypes.object,
        settSideTittel: PropTypes.func,
        sisteTransaksjoner: PropTypes.array,
        alleVentendeTransaksjoner: PropTypes.array,
        hentSisteOrdre: PropTypes.func,
        hentAlleVentendeOrdre: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {
        this.props.settSideTittel(this.props.texts.get('transaksjoner'));

        if (this.props.portefoljeData) {
            this.hentOrdre();
        }
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.portefoljeData && this.props.portefoljeData) {
            this.hentOrdre();
        }
    }

    hentOrdre = () => {
        this.props.hentSisteOrdre(this.props.portefoljeData);
        this.props.hentAlleVentendeOrdre(this.props.portefoljeData);
    };

    sjekkForServerFeil = (errors) => errors.HENT_SISTE_ORDRE;

    transaksjonUtlisting = (items) =>
        items.map((item) => (
            <LinkCard
                key={btoa(JSON.stringify(item))}
                gradient={ColorFunctions.hentGradient(item.type === 'SALG' ? 5 : 1)}
            >
                <div>
                    <div className="bank-navn">{item.bank.banknavn}</div>
                    <div className="card-content-table">
                        <div className="card-content-table-cell">
                            <div className="title">
                                <div className="portefolje-navn">{item.portefoljeNavn}</div>
                            </div>
                            <div className="portefolje-type">{item.fondNavn}</div>
                            <div className="urealisert-gevinst">
                                <span className="tekst">
                                    {DateFunctions.skrivUtDatoFraString(item.transaksjonsdato)}
                                </span>
                            </div>
                        </div>
                        <div className="card-content-table-cell">
                            <div className="total-verdi">
                                <div className="tekst">{this.props.texts.get(item.type)}</div>
                                <div className="verdi">{item.beloep > 0 ? Format.currency(item.beloep) : ''}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </LinkCard>
        ));

    renderTransaksjoner = (items) => {
        if (items.length > 0) {
            return this.transaksjonUtlisting(items);
        }
        return <div className="ingen-transaksjoner">{this.props.texts.get('ingenTransaksjoner')}</div>;
    };

    render() {
        const { sisteTransaksjoner, alleVentendeTransaksjoner } = this.props;
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (sisteTransaksjoner || alleVentendeTransaksjoner) {
            return (
                <div className="transaction-wrapper">
                    <AppMessage appMessage={this.props.serviceMessage} context="TRANSACTION" />

                    <div className="title">{this.props.texts.get('sisteTransaksjoner')}</div>
                    {sisteTransaksjoner ? (
                        <div className="sisteTransaksjoner">{this.renderTransaksjoner(sisteTransaksjoner)}</div>
                    ) : (
                        <Loading title={this.props.texts.get('lasterSisteTransaksjoner')} />
                    )}
                    <div className="title">{this.props.texts.get('ventendeTransaksjoner')}</div>
                    {alleVentendeTransaksjoner ? (
                        <div className="sisteTransaksjoner">{this.renderTransaksjoner(alleVentendeTransaksjoner)}</div>
                    ) : (
                        <Loading title={this.props.texts.get('lasterVentendeTransaksjoner')} />
                    )}
                </div>
            );
        }

        return <Loading title={this.props.texts.get('lasterSisteTransaksjoner')} />;
    }
}

export default connect(
    (state, ownProps) => ({
        ...state.Ordre,
        ...state.Feilmeldinger,
        ...state.Texts,
        ...state.Fond,
        ...state.Header,
        ...ownProps,
    }),
    (dispatch) => ({
        settSideTittel: (sideTittel, undertittel) => dispatch(settSideTittel(sideTittel, undertittel)),
        hentSisteOrdre: (portefoljeData) => dispatch(hentSisteOrdre(portefoljeData)),
        hentAlleVentendeOrdre: (portefoljeData) => dispatch(hentAlleVentendeOrdre(portefoljeData)),
    })
)(SisteTransaksjoner);
