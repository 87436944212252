import React from 'react';
import PropTypes from 'prop-types';
import { XAxis, YAxis, CartesianGrid, LineChart, Line, ResponsiveContainer } from 'recharts';
import Format from '../format-functions';

const PrognoseGraf = props => (
    <ResponsiveContainer width="100%" height={300}>
        <LineChart data={props.values} margin={{ top: 5, right: 5, left: 0, bottom: 5 }}>
            <XAxis dataKey="key" interval="preserveStartEnd" minTickGap={50} tick={{ fontSize: 12 }} />
            <YAxis
                type="number"
                tickFormatter={Format.currency}
                domain={[0, props.yAxisMaxValue]}
                tick={{ fontSize: 12 }}
            />

            <CartesianGrid stroke="#f5f5f5" />

            <Line type="monotone" dataKey="upper" dot={false} stroke="#79DDFF" strokeWidth={2} />
            <Line
                type="monotone"
                dataKey="expected"
                dot={false}
                stroke="#004F5A"
                strokeDasharray="3 3"
                strokeWidth={2}
            />
            <Line type="monotone" dataKey="lower" dot={false} stroke="#79DDFF" strokeWidth={2} />
        </LineChart>
    </ResponsiveContainer>
);

PrognoseGraf.propTypes = {
    values: PropTypes.array,
    yAxisMaxValue: PropTypes.number
};

export default PrognoseGraf;
