// @ts-nocheck // kiet fix it later
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import { Route, Switch, HashRouter as Router } from 'react-router-dom';

// @ts-ignore
import { getFondList, getFondHistorikk } from '../../actions/fond';

import AllFund from './AllFund';
import FundGuideline from './FundGuideline';
import SuggestedFund from './SuggestedFund';
import SmartsparLinking from './SmartsparLinking';

import './KjopRouter.scss';

export default (): JSX.Element => {
    const dispatch = useDispatch();
    const config = {
        appTexts: useSelector((state: any) => state?.Texts?.texts),
        action: {
            dispatch,
            getFondList: (callback?: (p:any) => void) => { dispatch(getFondList(callback)); },
            getFondHistorikk: (isin?: string, length?: number, type?: string, callback?: (p:any) => void) => { dispatch(getFondHistorikk(isin, length, type, callback)); },
        },
    };

    return <div className="kjop-router-wrapper">    
        <Router>
            <Switch>
                <Route exact path="/" component={(e: any) => <AllFund {...config} {...e} />} />               
                <Route exact path="/kjopfond/veileder" component={(e: any) => <FundGuideline {...config} {...e} />} />                
                <Route exact path="/kjopfond/fond/veileder" component={(e: any) => <FundGuideline {...config} {...e} />} />                
                <Route exact path="/kjopfond" component={(e: any) => <AllFund {...config} {...e} />} />  
                <Route exact path="/kjopfond/alle" component={(e: any) => <AllFund {...config} {...e} />} />                
                <Route exact path="/kjopfond/fond/alle" component={(e: any) => <AllFund {...config} {...e} />} />                
                <Route exact path="/kjopFond/:isin" component={(e: any) => <SuggestedFund {...config} {...e} />} />             
                <Route exact path="/kjopFond/fond/:isin" component={(e: any) => <SuggestedFund {...config} {...e} />} />             
                <Route exact path="/linking" component={(e: any) => <SmartsparLinking {...config} {...e} />} />
            </Switch>
        </Router>
    </div>
};