/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ExpandableCard from '../expandable-card';
import HelperFunctions from '../helper-functions';
import Epost from '../common/epost';
import DateFunctions from '../date-functions';
import Validate from '../validation-functions';
import Checkbox from '../checkbox';
import VelgKonto from './velg-konto';
import NumberInput from '../common/number-input';
import DateDropdown from '../common/date-dropdown';
import ValidationMessages from '../common/validation-messages';
import TextInput from '../common/text-input';
import FormatFunctions from '../format-functions';
import KjennDinKundeForm from '../kjenndinkunde/KjennDinKundeForm';
import Modal from '../modal';
import * as kundevurderingConst from '../../domain/kundevurdering';
import KundevurderingStopp from '../KundevurderingStopp';
import KjopFondAISskjema from './kjop-fond/kjop-fond-ais-skjema';
import { getValidatedAISvalue } from './kjop-fond/kjop-fond-utils';
import FeeMessage from '../FeeMessage';
import ForeignCurrencyMessage from '../ForeignCurrencyMessage';

class EndreMaanedligSparing extends Component {
    static propTypes = {
        portefoljeId: PropTypes.string.isRequired,
        morningstar: PropTypes.array.isRequired,
        kontoer: PropTypes.array.isRequired,
        texts: PropTypes.object.isRequired,
        fond: PropTypes.object.isRequired,
        expandItem: PropTypes.object.isRequired,
        action: PropTypes.func.isRequired,
        settKvitteringsInnhold: PropTypes.func.isRequired,
        epost: PropTypes.string,
        settEpost: PropTypes.func.isRequired,
        bankregnr: PropTypes.string.isRequired,
        spareavtale: PropTypes.object,
        kundevurdering: PropTypes.string,
        sperre: PropTypes.string,
        isIPS: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const trekkDag = (props.spareavtale && props.spareavtale.trekkDag) || 1;
        const belop = props.spareavtale ? props.spareavtale.trekkBelop : '';
        const ais = (props.spareavtale || {}).automaticIncrease || {};
        const aisPercent = ais.frequency !== 'MONTHLY' && ais.frequency !== 'QUARTERLY';

        const standardValues = {
            maanedligSparing: belop,
            trekkDag,
            kontonummer: props.spareavtale ? props.spareavtale.belastningskonto : undefined,
            visHjelpetekst: false,
            avsluttSpareavtale: false,
            spareavtalenavn: props.spareavtale ? props.spareavtale.navn : undefined,

            // automatisk øking sparing
            ais: !!ais.isActive,
            aisFrequency: aisPercent ? `${(new Date( (ais.startDate || null) )).getMonth()}` : ais.frequency,
            aisValue: aisPercent ? ais.percentage : ais.increaseAmount,
            aisValueError: '',
            aisValueUnitPercent: aisPercent,
            aisPlan: [],
        };

        this.state = {
            visAHV: false,
            ahvError: null,
            standardValues,
            ...standardValues,
            datoValg: DateFunctions.faaDatoValg(trekkDag)
        };
    }

    endreMaanedligSparing = event => {
        const maanedligSparing = HelperFunctions.clamp(event.target.value, 0, 999999);

        this.setState({
            maanedligSparing
        });
    };

    submitForm = () => {
        const validAvsluttSpareavtale = this.state.avsluttSpareavtale && (this.state.epost || this.props.epost);
        if ( validAvsluttSpareavtale ) {
            this.submitAHV( 'ok' );
        } else {
            const aisValidated = this.state.ais ?
                getValidatedAISvalue( this.state.aisValue, this.state.aisValueUnitPercent, this.state.maanedligSparing ) : {};

            const valid = !aisValidated.error &&
                this.state.maanedligSparing >= this.props.fond.minimumsBelopPeriodiskKjop &&
                Validate.gyldigKontonummer(this.state.kontonummer) &&
                (this.state.epost || this.props.epost);

            if ( valid ) {
                this.setState({
                    visAHV: true,
                    aisValue: aisValidated.value,
                    aisValueError: '',
                });

            } else {
                this.setState({
                    visHjelpetekst: true,
                    aisValueError: aisValidated.error ? this.props.texts.get(aisValidated.error, {faktor: aisValidated.factor}) : '',
                });
            }
        }
    };

    closeAHV = () => {
        this.setState({visAHV: false, ahvError: null});
    };

    submitAHV = ( result ) => {
        if ( /ok/i.test( result ) ) {
            if (this.state.epost) {
                this.props.settEpost(this.state.epost);
            }

            this.createReceiptContent();

            const data = this.createSpareAvtaleDTO();
            this.setState({ visAHV: false });
            this.resetForm();
            this.props.action(data);
        } else if ( result === 'MA_BEHANDLES_MANUELT' ) {
            this.setState({ahvError:{
                title: this.props.texts.get('ma_behandles_manuelt_tittel'),
                text: this.props.texts.get('ma_behandles_manuelt_tekst'),
                link: {
                    text: this.props.texts.get('ma_behandles_manuelt_link'),
                    url: `${window.location.origin}/ansatte`
                }
            }});
        }
    };

    createSpareAvtaleDTO = () => {
        const { ais, aisFrequency, aisValue, aisPlan, aisValueUnitPercent, maanedligSparing } = this.state;
        const firstIncrease = (aisPlan || []).find( (d) => d.deposit !== maanedligSparing );

        const dto = {
            portefoljeId: this.props.portefoljeId,
            isin: this.props.fond.isin,
            epost: this.state.epost,
            bankregnr: { value: this.props.bankregnr },
            id: this.props.spareavtale ? this.props.spareavtale.id : null,
            spareavtalenavn: this.state.spareavtalenavn,

            /* eslint-disable */
            automaticIncrease: firstIncrease && ais ? {
                isActive: true,
                startDate: firstIncrease.stamp || '',
                ...(aisValueUnitPercent ? {
                    increaseValueType: 'PERCENTAGE',
                    percentage: aisValue,
                    frequency: 'ANNUAL',
                } : {
                    increaseValueType: 'AMOUNT',
                    increaseAmount: aisValue,
                    frequency: aisFrequency,
                })
            } : ( (this.props.spareavtale || {}).automaticIncrease ? {...this.props.spareavtale.automaticIncrease, isActive: false} : undefined), 
            /* eslint-enable */
        };

        return this.state.avsluttSpareavtale ? {
              ...dto,
              avsluttSpareavtale: this.state.avsluttSpareavtale,
              trekkDag: this.props.spareavtale.trekkDag,
              spareBelop: this.props.spareavtale.trekkBelop
          } : {
              ...dto,
              spareBelop: this.state.maanedligSparing,
              kontonummer: this.state.kontonummer,
              trekkDag: this.state.trekkDag
          };
    };

    createReceiptContent = () => {
        const kvittering = {
            type: 'endreMndSparing',
            avsluttSpareavtale: this.state.avsluttSpareavtale,
            lasteTekst: this.props.spareavtale
                ? this.props.texts.get('endreMaanedligSparing')
                : this.props.texts.get('nySpareavtale'),
            spareBelop: this.state.maanedligSparing,
            kontonummer: this.state.kontonummer,
            trekkDag: DateFunctions.finnNesteTrekkDato(this.state.trekkDag),
            kvitteringsHeader: this.state.avsluttSpareavtale
                ? this.props.texts.get('avsluttMndSpareAvtaleKvitteringsTittel')
                : this.props.texts.get('nyttMndSpareBelopKvitteringsTittel')
        };

        this.props.settKvitteringsInnhold(kvittering);
    };

    resetForm = () => {
        const { standardValues } = this.state;
        this.setState({ ...standardValues });
        this.props.expandItem.onExpand(this.props.expandItem.id);
    };

    visEpost = () => (
        <Epost
            label={this.props.texts.get('epost')}
            show={!this.props.epost}
            onChange={epost => this.setState(epost)}
            showValidationMessage={this.state.visHjelpetekst}
            validationMessage={this.props.texts.get('skrivEnGyldigEpostAdresse')}
        />
    );

    header = (texts, spareavtale) =>{
        const ais = (spareavtale || {}).automaticIncrease;
        const name = spareavtale
            ? `${
                  spareavtale.spareavtalenavn ? spareavtale.spareavtalenavn : texts.get('endreMaanedligSparing')
              }: ${FormatFunctions.currency(spareavtale.trekkBelop)}`
            : texts.get('nySpareavtale');

        if ( !ais ) { return name; }
        
        /* eslint-disable */
        const note = ais.frequency === 'MONTHLY' ? 'månedlig' : (ais.frequency === 'QUARTERLY' ? 'kvatalsvis' : 'årlig');
        /* eslint-enable */
        const addition = `Beløp økes ${note}`;

        return `${name} (${addition})`;

    }

    submitText = () => {
        if (this.props.spareavtale) {
            return this.state.avsluttSpareavtale
                ? this.props.texts.get('bekreftAvsluttSpareavtale')
                : this.props.texts.get('oppdater');
        }
        return this.props.texts.get('opprettNy');
    };

    render() {
        const leggTilAnnenKontoValg = true;


        return (
            <ExpandableCard
                header={this.header(this.props.texts, this.props.spareavtale)}
                expandItem={this.props.expandItem}
                ikon="ikon-penn-36"
            >
                { this.props.kundevurdering === kundevurderingConst.MA_BEHANDLES_MANUELT || this.props.sperre ?
                    <KundevurderingStopp
                        {...this.props} 
                        title={this.props.sperre ? this.props.texts.get(`${this.props.sperre.toLowerCase()}SperretKontoTitle`) : ''}
                        text={this.props.sperre ? this.props.texts.get(`${this.props.sperre.toLowerCase()}SperretKontoText`) : ''}
                    />
                    :
                    <div className="endre-mnd-sparing">
                        <div>
                            <div className="fond-skjema-seksjon">
                                <NumberInput
                                    id="endreMaanedligSparing"
                                    label={this.props.texts.get('velgBelop')}
                                    value={this.state.maanedligSparing}
                                    onChange={this.endreMaanedligSparing}
                                    subLabel={`Minimum ${this.props.fond.minimumsBelopPeriodiskKjop} NOK`}
                                    placeholder={this.props.fond.minimumsBelopPeriodiskKjop}
                                    disabled={this.state.avsluttSpareavtale}
                                />
                                <ValidationMessages
                                    showValidationMessages={this.state.visHjelpetekst}
                                    validationMessages={[
                                        {
                                            condition: !(
                                                this.state.maanedligSparing >= this.props.fond.minimumsBelopPeriodiskKjop
                                            ),
                                            message: this.props.texts.get('skrivInnEnGyldigSumOverMinimumsbelop')
                                        }
                                    ]}
                                />
                            </div>

                            <div className="fond-skjema-seksjon">
                                <VelgKonto
                                    id="kontonummer"
                                    tittel={this.props.texts.get('kontoForMaanedligTrekk')}
                                    leggTilAnnenKonto={leggTilAnnenKontoValg}
                                    kontoer={HelperFunctions.filtrerUtKlientKontoer(this.props.kontoer)}
                                    onChange={obj => this.setState(obj)}
                                    texts={this.props.texts}
                                    visHjelpetekst={this.state.visHjelpetekst}
                                    disabled={this.state.avsluttSpareavtale}
                                    defaultValue={this.state.standardValues.kontonummer || ''}
                                >
                                    <DateDropdown
                                        id="trekkDag"
                                        label={this.props.texts.get('trekkDato')}
                                        onChange={trekkDag => this.setState({ trekkDag })}
                                        options={this.state.datoValg}
                                        defaultValue={this.state.trekkDag}
                                        disabled={this.state.avsluttSpareavtale}
                                    />
                                </VelgKonto>
                            </div>

                            { !this.props.isIPS && <KjopFondAISskjema 
                                {...this.props}
                                {...this.state}
                                spareBelop={this.state.maanedligSparing}
                                changeAis={ (v) => { 
                                    if ( v ) {
                                        this.setState({ais: v}); 
                                    } else {
                                        this.setState({ais: v, aisValueError: ''}); 
                                    }
                                }}
                                changeValue={ (v) => { this.setState({aisValue: v, aisValueError: ''}); }}
                                changeFrequency={ (v) => { 
                                    const aisValueUnitPercent = v !== 'QUARTERLY' && v !== 'MONTHLY';
                                    const aisValue = this.state.aisValueUnitPercent === aisValueUnitPercent ? this.props.aisValue : 0;
                                    this.setState({aisFrequency: v, aisValueUnitPercent, aisValue});
                                }}
                            /> }

                            {this.props.spareavtale && (
                                <div className="fond-skjema-seksjon">
                                    <div className="full-width label-left">
                                        <TextInput
                                            id="spareavtaleNavn"
                                            label={this.props.texts.get('spareavtaleNavn')}
                                            value={this.state.spareavtalenavn}
                                            onChange={event => this.setState({ spareavtalenavn: event.target.value })}
                                            disabled={this.state.avsluttSpareavtale}
                                        />
                                    </div>
                                </div>
                            )}
                            {this.visEpost()}
                            {this.props.spareavtale && (
                                <div className="fond-skjema-seksjon">
                                    <Checkbox
                                        id="avsluttspareavtale"
                                        name={this.props.texts.get('avsluttSpareavtale')}
                                        onChange={checked => this.setState({ avsluttSpareavtale: checked })}
                                        checked={this.state.avsluttSpareavtale}
                                    >
                                        <div>{this.props.texts.get('avsluttSpareavtale')}</div>
                                    </Checkbox>
                                </div>
                            )}
                        </div>

                        <FeeMessage fond={(this.props.morningstar || []).find((d) => d.isin === this.props.fond.isin)} />
                        <ForeignCurrencyMessage texts={this.props.texts} fond={(this.props.morningstar || []).find((d) => d.isin === this.props.fond.isin)} />

                        <div className="expandable-card-footer">
                            <button type="button" className="btn btn-flat" onClick={this.resetForm}>
                                {this.props.texts.get('avbryt')}
                            </button>
                            <button type="button" className="btn btn-primary" onClick={this.submitForm}>
                                {this.submitText()}
                            </button>
                        </div>
                        <Modal visModal={this.state.visAHV} lukkModal={this.closeAHV}>
                            { this.state.ahvError ? 
                                <div className="ahv-error-message">
                                    <h1 className="h3" aria-live="polite">{this.state.ahvError.title}</h1>
                                    <p>{this.state.ahvError.text}</p>
                                    <a href={this.state.ahvError.link.url} className="btn btn-action">{this.state.ahvError.link.text}</a>
                                </div> 
                                :
                                <KjennDinKundeForm rotNodeId="#sparing-react-app" onSubmit={this.submitAHV} /> 
                            }
                        </Modal>
                    </div>
                }
            </ExpandableCard>
        );
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        nextState.aisPlan = nextState.ais && nextState.maanedligSparing && nextState.aisValue && nextState.aisFrequency && !nextState.aisValueError ? 
            HelperFunctions.getAISplan({
                ...nextState,
                drawDay: nextState.trekkDag || 1,
                amount: nextState.maanedligSparing,
                onlyWantDifferentDeposit: true,
                increaseImmediately: !!nextProps.spareavtale,
            }) : [];
    }
}

export default EndreMaanedligSparing;
