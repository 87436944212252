import React from 'react';
import PropTypes from 'prop-types';

const IntroIndividuellPensjonsSparing = props => (
    <div className="opprett-ips">
        <div className="opprett-ips-tittel">{props.texts.get('opprettIndividuellPensjonsSparing')}</div>
        <div className="opprett-ips-tekst">
            <div>{props.texts.get('opprettIndividuellPensjonsSparingTekst')}</div>
            <ul>
                <li>{props.texts.get('opprettIndividuellPensjonsSparingEn')}</li>
                <li>{props.texts.get('opprettIndividuellPensjonsSparingTo')}</li>
                <li>{props.texts.get('opprettIndividuellPensjonsSparingTre')}</li>
            </ul>
        </div>
        <div className="action-button">
            <button
                type="button"
                className="btn btn-call-to-action"
                onClick={() => {
                    props.gaaTilNeste();
                }}
            >
                {props.texts.get('inngaAvtaleOmIps')}
            </button>
        </div>
    </div>
);

IntroIndividuellPensjonsSparing.propTypes = {
    texts: PropTypes.object.isRequired,
    gaaTilNeste: PropTypes.func.isRequired
};

export default IntroIndividuellPensjonsSparing;
