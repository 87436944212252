/**
 * Created by vagrant on 22.02.17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../card';
import ExpandableCard from '../expandable-card';
import FullstendigeVilkaar from './fullstendige-vilkaar';

require('../../styles/vilkaar.scss');

class Vilkaar extends Component {
    static propTypes = {
        texts: PropTypes.object,
        endreAlleSamtykker: PropTypes.func,
        nyKunde: PropTypes.bool,
        headline: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            expandable: {
                id: 0,
                expanded: false,
                onExpand: this.onExpand
            }
        };
    }

    onExpand = () => {
        this.setState({
            expandable: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.expandable,
                // eslint-disable-next-line react/no-access-state-in-setstate
                expanded: !this.state.expandable.expanded
            }
        });
    };

    render() {
        const { headline } = this.props;
        return (
            <div>
                <Card>
                    <div className="vilkaar">
                        {!!headline && <div className="align-center paragraph -include-top font-size-regular">{headline}</div>}

                        { !this.props.nyKunde && <div className="informasjon">{this.props.texts.get('sammendragAvAvtaleArsak')}</div> }

                        <h1 className="h2">{this.props.texts.get('sammendragAvAvtaleTittel')}</h1>
                        <div className="ingress">{this.props.texts.get('sammendragAvAvtaleIngress')}</div>
                        <ul className="">
                            <li>{this.props.texts.get('sammendragAvAvtalePunkt1')}</li>
                            <li>{this.props.texts.get('sammendragAvAvtalePunkt2')}</li>
                            <li>{this.props.texts.get('sammendragAvAvtalePunkt3')}</li>
                            <li>{this.props.texts.get('sammendragAvAvtalePunkt4')}</li>
                        </ul>

                        <ExpandableCard header={this.props.texts.get('fullstendigeVilkaar')} expandItem={this.state.expandable}>
                            <FullstendigeVilkaar />
                        </ExpandableCard>

                        <div className="footer">
                            {/* eslint-disable-next-line react/button-has-type */}
                            <button className="btn btn-call-to-action" onClick={this.props.endreAlleSamtykker}>
                                {this.props.texts.get('jegGodtarVilkaarene')}
                            </button>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

export default Vilkaar;
