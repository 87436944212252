import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import { Link } from 'react-router-dom';

import AppMessage from '../../components/AppMessage';
import DonutChart from '../../components/charts/donut-chart';
import Legend from '../../components/legends/dot-legend';
import SelgFond from '../../components/fond/selg-fond/selg-fond';
import NyttInnskudd from '../../components/fond/nytt-innskudd';
import ByttFond from '../../components/fond/bytt-fond';
import EndreMaanedligSparing from '../../components/fond/endre-maanedlig-sparing';
import ActionButton from '../../components/action-button';
import HelperFunctions from '../../components/helper-functions';
import ColorFunctions from '../../components/color-functions';
import PrestasjonsGraf from '../../components/charts/graf/prestasjons-graf';
import OrdreKvittering from '../../components/fond/ordre-kvittering';
import * as OrderTypes from '../../domain/order-types';
import * as PortefoljeTypes from '../../domain/portefolje-types';
import { getSparemaal } from '../../actions/sparemaal';

import { byttFond, endreMndSparing, hentHistorikk, hentSkattemessigKostpris, nyttInnskudd } from '../../actions/fond';

import OpprettSalgsOrdre from '../../actions/selg-fond';
import { fullforOrdre, onFeiletSignering, resetOrdreProsess } from '../../actions/signerings-ordre';
import { lukkKvittering, settKvitteringsInnhold } from '../../actions/modals';
import { settSideTittel } from '../../actions/header';
import { endreKundeInfo } from '../../actions/spareprofil';
import Feilmelding from '../../components/feilmelding';
import Format from '../../components/format-functions';
import PrestasjonsGrafDisclaimer from '../../components/vilkaar/prestasjonsgraf-disclaimer';
import StandaloneExpandableCard from '../../components/common/standalone-expandable-card';
import GdprSamtykke from '../../components/vilkaar/gdpr-samtykke';
import { hentVentendeOrdre } from '../../actions/ordre';
import visRiktigTekstBasertPaaOrdreTyper from './fond-common';
import InfoBoks from '../../components/infoboks';
import KundevurderingStopp from '../../components/KundevurderingStopp';
import Constant from '../../static/data/Constant';

class FondDetaljer extends Component {
    static propTypes = {
        portefoljeData: PropTypes.object,
        kundeInfo: PropTypes.object,
        morningstar: PropTypes.array,
        texts: PropTypes.object,
        kvitteringConfig: PropTypes.object,
        kvitteringsInnhold: PropTypes.object,
        lukkKvittering: PropTypes.func,
        errors: PropTypes.object,
        hentHistorikk: PropTypes.func,
        ordreId: PropTypes.string,
        pollingIds: PropTypes.object,
        sparemaal: PropTypes.array,
        getSparemaal: PropTypes.func,
        match: PropTypes.object,
        hentVentendeOrdre: PropTypes.func,
        ventendeTransaksjoner: PropTypes.object,
        // eslint-disable-next-line react/no-unused-prop-types
        disponertePortefoljer: PropTypes.array,
        // eslint-disable-next-line
        kundevurdering: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentWillUnmount = () => {
        const pollingId = this.props.pollingIds[this.props.ordreId];
        if (pollingId) {
            clearTimeout(pollingId);
        }
    };

    UNSAFE_componentWillMount = () => {
        const { sparemaal, match, getSparemaal, hentVentendeOrdre } = this.props;
        const { params } = match;
        const { portefoljeId, fondIsin } = params;

        if (!sparemaal) {
            getSparemaal();
        }

        hentVentendeOrdre(portefoljeId, fondIsin);

        this.setState(this.initialiserFondDetaljerSide(this.props));
    };

    UNSAFE_componentWillReceiveProps = (newProps) => this.setState(this.initialiserFondDetaljerSide(newProps));

    initialExpandableItems = (spareavtaler) => {
        if (this.state.items.length < 1) {
            const items = [];
            spareavtaler.map((spareavtale, index) => {
                items.push({
                    id: index,
                    expanded: false,
                    onExpand: this.onExpand,
                });
                return items;
            });

            items.push(
                {
                    // NySparing
                    id: items.length,
                    expanded: false,
                    onExpand: this.onExpand,
                },
                {
                    // NyttInnskudd
                    id: items.length + 1,
                    expanded: false,
                    onExpand: this.onExpand,
                },
                {
                    // ByttFond
                    id: items.length + 2,
                    expanded: false,
                    onExpand: this.onExpand,
                },
                {
                    // Selg
                    id: items.length + 3,
                    expanded: false,
                    onExpand: this.onExpand,
                }
            );

            this.setState({ items });
        }
    };

    initialiserFondDetaljerSide = (props) => {
        const { portefoljeData, disponertePortefoljer, match, morningstar, texts } = props;
        const { params } = match;
        const { portefoljeId, fondIsin } = params;
        if (portefoljeData && disponertePortefoljer) {
            const allePortefoljer = portefoljeData.portefoljer.concat(disponertePortefoljer);
            const fondData = this.settOppFondsVisning(fondIsin, portefoljeId, allePortefoljer, morningstar, texts);
            const portefolje = HelperFunctions.hentPortefoljeBasertPaaId(portefoljeId, allePortefoljer);
            const morningstarFund = morningstar.find( (d) => d.isin === fondIsin );
            props.settSideTittel(fondData.fond.navn, portefolje.navn);

            this.initialExpandableItems(fondData.fond.spareavtaler);
            return {
                fondData,
                portefolje,
                foreignCurrencyForceToSellAll: morningstarFund.currency !== Constant.nok,
                fundCurrency: morningstarFund ? morningstarFund.currency : '',
            };
        }
        return {};
    };

    onExpand = (id) =>
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            ...this.state,
            // eslint-disable-next-line react/no-access-state-in-setstate
            items: this.state.items.map((item) => {
                return { ...item, expanded: item.id === id ? !item.expanded : false };
            }),
        });

    settOppFondsVisning = (fondIsin, portefoljeId, portefoljer, morningstar, texts) => {
        const fond = HelperFunctions.hentFondBasertPaaIsinOgPortefoljeId(
            fondIsin,
            portefoljeId,
            portefoljer,
            morningstar
        );

        const gevinst = fond.urealisertGevinst;
        const gevinstTekst = gevinst >= 0 ? texts.get('gevinst') : texts.get('tap');
        const gevinstFarge = gevinst >= 0 ? ColorFunctions.hentFarge(1) : ColorFunctions.hentRoedFarge();

        return {
            fond,
            chartData: [
                {
                    text: texts.get('innskudd'),
                    value: fond.innskudd,
                    color: ColorFunctions.hentFarge(0),
                    active: true,
                },
                {
                    text: gevinstTekst,
                    value: gevinst,
                    color: gevinstFarge,
                    active: true,
                },
            ],
        };
    };

    visPresentasjonsgraf = (fond, portefolje) => {
        if (fond && fond.isin && portefolje) {
            const historikk = this.props.portefoljeData.historikk[`${portefolje.portfolioBaseCode}${fond.isin}`];

            return (
                <div>
                    <PrestasjonsGraf
                        texts={this.props.texts}
                        isin={fond.isin}
                        portfolioBaseCode={portefolje.portfolioBaseCode}
                        historikk={historikk}
                        hentHistorikk={this.props.hentHistorikk}
                        tittel={`Din utvikling i ${fond.navn}`}
                    />
                    <PrestasjonsGrafDisclaimer texts={this.props.texts} />
                </div>
            );
        }

        return null;
    };

    sjekkForServerFeil = (errors) => errors.LAST_MORNINGSTAR_DATA || errors.LAST_PORTEFOLJE;

    visKjopFondKnapp = (portefolje) => {
        const { type } = portefolje;

        if (type === PortefoljeTypes.IPS) {
            return <ActionButton action="individuellpensjonssparing" text={this.props.texts.get('sparIAndreFond')} />;
        }

        if (!(type === PortefoljeTypes.GPS || type === PortefoljeTypes.IPA)) {
            return <ActionButton action="veiviser/kjopFond" text={this.props.texts.get('sparIAndreFond')} />;
        }

        return null;
    };

    visEndreSparing = (state, props) => (
        <div>
            {state.fondData.fond.spareavtaler.map((spareavtale, index) => {
                return (
                    <EndreMaanedligSparing
                        key={spareavtale.id}
                        morningstar={props.morningstar}
                        spareavtale={spareavtale}
                        portefoljeId={props.match.params.portefoljeId}
                        texts={props.texts}
                        kontoer={props.portefoljeData.kontoer}
                        action={props.endreMndSparing}
                        fond={state.fondData.fond}
                        expandItem={state.items[index]}
                        settKvitteringsInnhold={props.settKvitteringsInnhold}
                        epost={props.kundeInfo.epost}
                        settEpost={(epost) => {
                            props.endreKundeInfo({ epost });
                        }}
                        bankregnr={state.portefolje.bank.bankregnr}
                        kundevurdering={props.kundevurdering}
                        sperre={state.portefolje?.sperre}
                        isIPS={state.portefolje?.type === 'IPS'}
                    />
                );
            })}
        </div>
    );

    visNySparing = (state, props) => (
        <div style={{display: this.state.fondData.fond.minimumsBelopPeriodiskKjop ? "block" : "none"}}>
            <EndreMaanedligSparing
                portefoljeId={props.match.params.portefoljeId}
                morningstar={props.morningstar}
                texts={props.texts}
                kontoer={props.portefoljeData.kontoer}
                action={props.endreMndSparing}
                fond={state.fondData.fond}
                expandItem={state.items[state.items.length - 4]} // Hard coded reference to initialExpandableItems
                settKvitteringsInnhold={props.settKvitteringsInnhold}
                epost={props.kundeInfo.epost}
                settEpost={(epost) => {
                    props.endreKundeInfo({ epost });
                }}
                bankregnr={state.portefolje.bank.bankregnr}
                nySparing
                kundevurdering={props.kundevurdering}
                sperre={state.portefolje?.sperre === 'FULL' ? state.portefolje?.sperre : ''}
                isIPS={state.portefolje?.type === 'IPS'}
            />
        </div>
    );

    visNyttInnskudd = (state, props) => (
        <NyttInnskudd
            portefoljeId={props.match.params.portefoljeId}
            morningstar={props.morningstar}
            texts={props.texts}
            kontoer={props.portefoljeData.kontoer}
            fond={state.fondData.fond}
            expandItem={state.items[state.items.length - 3]} // Hard coded reference to initialExpandableItems
            action={props.nyttInnskudd}
            settKvitteringsInnhold={props.settKvitteringsInnhold}
            epost={props.kundeInfo.epost}
            settEpost={(epost) => {
                props.endreKundeInfo({ epost });
            }}
            bankregnr={state.portefolje.bank.bankregnr}
            kundevurdering={props.kundevurdering}
            sperre={state.portefolje?.sperre === 'FULL' ? state.portefolje?.sperre : ''}
        />
    );

    visBytt = (state, props) => (
        <ByttFond
            portefoljeId={props.match.params.portefoljeId}
            texts={props.texts}
            morningstar={props.morningstar}
            fond={state.fondData.fond}
            expandItem={state.items[state.items.length - 2]} // Hard coded reference to initialExpandableItems
            action={props.byttFond}
            settKvitteringsInnhold={props.settKvitteringsInnhold}
            portefoljeType={state.portefolje.type}
            epost={props.kundeInfo.epost}
            settEpost={(epost) => {
                props.endreKundeInfo({ epost });
            }}
            bankregnr={state.portefolje.bank.bankregnr}
            kundevurdering={props.kundevurdering}
            sperre={state.portefolje?.sperre}
        />
    );

    visSalg = (state, props) => (
        <SelgFond
            portefoljeId={props.match.params.portefoljeId}
            portfolioBaseCode={state.portefolje.portfolioBaseCode}
            texts={props.texts}
            kontoer={props.portefoljeData.kontoer}
            fond={state.fondData.fond}
            expandItem={state.items[state.items.length - 1]} // Hard coded reference to initialExpandableItems
            settKvitteringsInnhold={props.settKvitteringsInnhold}
            portefoljeType={state.portefolje.type}
            epost={props.kundeInfo.epost}
            settEpost={(epost) => {
                props.endreKundeInfo({ epost });
            }}
            ordreId={props.ordreId}
            opprettSalgsOrdre={props.OpprettSalgsOrdre}
            signeringsUrl={props.signeringsUrl}
            fullforOrdre={props.fullforOrdre}
            onFeiletSignering={props.onFeiletSignering}
            feilmelding={props.feilmelding}
            resetOrdreProsess={props.resetOrdreProsess}
            skattemessigKostpris={props.skattemessigKostpris}
            hentSkattemessigKostpris={props.hentSkattemessigKostpris}
            bankregnr={state.portefolje.bank.bankregnr}
            sparemaal={props.sparemaal}
            kundevurdering={props.kundevurdering}
            sperre={state.portefolje?.sperre}
            foreignCurrencyForceToSellAll={state.foreignCurrencyForceToSellAll}
            fundCurrency={state.fundCurrency}
        />
    );

    visTillatteBrukerHandlinger = (tillatteBrukervalg, state, props) =>
        tillatteBrukervalg ? (
            <>
                <div>
                    {tillatteBrukervalg.some((n) => n === OrderTypes.NY_SPARING) ? this.visNySparing(state, props) : null}
                    {tillatteBrukervalg.some((n) => n === OrderTypes.NYTT_INNSKUDD)
                        ? this.visNyttInnskudd(state, props)
                        : null}
                    {tillatteBrukervalg.some((n) => n === OrderTypes.BYTT) ? this.visBytt(state, props) : null}
                    {tillatteBrukervalg.some((n) => n === OrderTypes.SALG) ? this.visSalg(state, props) : null}
                </div>
                {!state.fondData.fond.minimumsBelopPeriodiskKjop && <InfoBoks infoTekst="Dette fondet støtter ikke fast månedlig sparing" icon="ikon-utropstegn-24"/>}
            </>
        ) : null;

    visSpareAvtaler = (state, props, fond) =>
        fond.spareavtaler &&
        fond.spareavtaler.length &&
        fond.tillatteBrukervalg.some((n) => n === OrderTypes.ENDRE_SPARING) ? (
            <div>
                <div className="dine-spareavtaler-tittel">{props.texts.get('dineSpareavtaler')}</div>
                {this.visEndreSparing(state, props)}
            </div>
        ) : null;

    visVentendeTransaksjoner = (fondIsin, portefoljeId) => {
        const ventendeTransaksjoner =
            this.props.ventendeTransaksjoner && this.props.ventendeTransaksjoner[`${portefoljeId}${fondIsin}`];

        if (ventendeTransaksjoner && ventendeTransaksjoner.length > 0) {
            const tekst = visRiktigTekstBasertPaaOrdreTyper(ventendeTransaksjoner, this.props.texts);

            return (
                <Link className="expandable-card" to={`/fond/ventendetransaksjoner/${portefoljeId}/${fondIsin}`}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <div className="expandable-list-item-heading" onClick={this.toggle} style={{ paddingLeft: '40px' }}>
                        <i className="expandable-icon ikon-info-24" />
                        <div className="ventende-transaksjoner-kort">{tekst}</div>
                    </div>
                </Link>
            );
        }

        return null;
    };

    render() {
        const { fondData, portefolje } = this.state;
        const {
            errors,
            texts,
            morningstar,
            kundeInfo,
            sparemaal,
            kvitteringConfig,
            lukkKvittering,
            kvitteringsInnhold,
            match,
        } = this.props;
        const { params } = match;
        const { portefoljeId } = params;
        if (this.sjekkForServerFeil(errors)) {
            return <Feilmelding feil={errors} texts={texts} />;
        }

        if (fondData && morningstar && kundeInfo && sparemaal) {
            const { fond } = fondData;
            const mndSparing = (fond.spareavtaler || []).reduce( (sum, avtale) => {
                sum += (avtale.trekkBelop || 0);
                return sum;
            }, 0);

            const duSparer = HelperFunctions.VariableInjection(texts.get('duSparerPerMaaned'), {
                maanedligSparing: { value: Format.number(mndSparing), class: 'number' },
            });

            const avkastning = HelperFunctions.beregnAvkastning(fond.totalVerdi, fond.innskudd);

            return (
                <div className="fonds-detaljer-side">
                    <AppMessage appMessage={this.props.serviceMessage} context="FUND" />

                    <OrdreKvittering
                        texts={texts}
                        kvitteringConfig={kvitteringConfig}
                        lukkKvittering={lukkKvittering}
                        kvitteringsInnhold={kvitteringsInnhold}
                    />

                    { !!portefolje?.sperre && <KundevurderingStopp 
                            texts={texts} 
                            title={null} 
                            text={texts.get(`${portefolje.sperre.toLowerCase()}SperretKonto`)} 
                            type="tag"
                            polite={false}
                        />
                    }

                    <DonutChart data={fondData.chartData}>
                        <div className="donut-labels">
                            <div className="label-section">
                                <div className="text">{texts.get('verdi')}</div>
                                <div className="value">{Format.currency(fond.totalVerdi)}</div>
                            </div>
                        </div>
                        <div className="donut-labels">
                            <div className="label-section">
                                <div className="text">Avkastning</div>
                                <div className="value">
                                    {Format.percent(typeof(avkastning) === 'number' ? (avkastning * 100) : null)}
                                </div>
                            </div>
                        </div>
                    </DonutChart>

                    <Legend data={fondData.chartData} />
                    <div className="duSparer">{duSparer}</div>
                    {this.visVentendeTransaksjoner(fond.isin, portefoljeId)}
                    {this.visTillatteBrukerHandlinger(fond.tillatteBrukervalg, this.state, this.props)}
                    {this.visSpareAvtaler(this.state, this.props, fond)}
                    {!!fond.totalVerdi && <InfoBoks infoTekst={texts.get('infotekstVerdiFond')} />}
                    {this.visPresentasjonsgraf(fond, portefolje)}
                    <StandaloneExpandableCard header={texts.get('personvern')}>
                        <GdprSamtykke />
                    </StandaloneExpandableCard>
                    {this.visKjopFondKnapp(portefolje)}
                </div>
            );
        }

        return <Loading title={texts.get('lasterFondsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Fond,
            ...state.Modals,
            ...state.Feilmeldinger,
            ...state.SpareProfil,
            ...state.Ordre,
            ...state.Texts,
            ...state.Sparemaal,
            ...state.Header,
            kundevurdering: state.KjennDinKunde?.kundevurdering,
        };
    },
    (dispatch) => {
        return {
            lukkKvittering: () => {
                dispatch(lukkKvittering());
            },
            hentVentendeOrdre: (portefoljeId, isin) => {
                dispatch(hentVentendeOrdre(portefoljeId, isin));
            },
            settKvitteringsInnhold: (innhold) => {
                dispatch(settKvitteringsInnhold(innhold));
            },
            nyttInnskudd: (data) => {
                dispatch(nyttInnskudd(data));
            },
            endreMndSparing: (data) => {
                dispatch(endreMndSparing(data));
            },
            byttFond: (data, kvittering) => {
                dispatch(byttFond(data, kvittering));
            },
            settSideTittel: (sideTittel, underTittel) => {
                dispatch(settSideTittel(sideTittel, underTittel));
            },
            endreKundeInfo: (endring) => {
                dispatch(endreKundeInfo(endring));
            },
            hentHistorikk: (portfolioBaseCode, isin) => {
                dispatch(hentHistorikk(portfolioBaseCode, isin));
            },
            OpprettSalgsOrdre: (data) => {
                dispatch(OpprettSalgsOrdre(data));
            },
            fullforOrdre: (ordreId) => {
                dispatch(fullforOrdre(ordreId));
            },
            onFeiletSignering: (errorCode) => {
                dispatch(onFeiletSignering(errorCode));
            },
            resetOrdreProsess: () => {
                dispatch(resetOrdreProsess());
            },
            hentSkattemessigKostpris: (portfolioBaseCode, bankregnr) => {
                dispatch(hentSkattemessigKostpris(portfolioBaseCode, bankregnr));
            },
            getSparemaal: () => {
                dispatch(getSparemaal());
            },
        };
    }
)(FondDetaljer);
