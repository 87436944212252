import React, {MouseEvent, useEffect, useMemo, useRef, useState} from 'react';
import {ButtonToggles} from '@eika/radio-button';
import {ExternalLinkLabel} from '@eika/label';
import {IconTypes} from '@eika/icon';
import {Link} from '@eika/link';
import {IconButton, LinkButton} from '@eika/button';
import {Modal, ModalSize, SubModal} from '@eika/modal';
import Card, {Props as CardProps} from '../Card/Card';
import Message from '../Message/Message';
import Sprouts from '../Sprouts/Sprouts';
import {formatAmount, formatDateToText} from '../util/Functions';
import {getFondUtviklingConfigList} from '../util/FondFunctions';
import {isEikaDomain} from '../util/BusinessFunctions';
import {FooterButton} from '../share/ShareComponents';
import Chart from '../Chart/Chart';
import FlexTextHolder from '../FlexTextHolder/FlexTextHolder';
import Constant from '../../static/data/Constant';
import './FundDetail.scss';
import {Fund, FundUtvikling} from '../../domain/Fund';
import safeArray from '../../components/Arbeidsgiver/util/SafeArray';
import {AppTexts} from '../../domain/Types';

interface Props {
    appTexts: any;
    setOnShowingDetailModal?: (b?: boolean) => void;
    children?: JSX.Element;
    data?: Fund;
    click?: (e: MouseEvent, k: string) => void;
    action?: { [k: string]: (...args: any[]) => any };
    selectFundText?: string;
}

interface ExternalLink {
    url: string;
    linkText: string;
    text: string;
}

interface FeeInfo {
    description: string;
    listItem: CardProps[];
}

type GraphDataApi = [{

    SecurityId: string
    frequency: 'DAILY' | 'MONTHLY' | 'ANNUAL'
    securityId: string
    timePeriod: number
    utviklingsverdier: {
        dato: string
        verdi: number
    }[]
}]

const convetPanelDataToChart = (list: any, info: any): any => {
        const xAxisFormat = info.format || '';
        const note = {
            base: list[0]?.verdi || 0,
            date: new Date(list[0]?.dato || undefined),
            highest: 0,
            lowest: 0,
            missing: info.length - list.length,
        };

        Array.from({length: note.missing ? note.missing + 2 : 0}).forEach(() => {
            if (info.type === 'DAILY') {
                note.date.setDate(note.date.getDate() - 1);
            } else {
                note.date.setMonth(note.date.getMonth() - 1);
            }

            list.unshift({verdi: note.base, dato: note.date.getTime()});
        });

        list.forEach((data: any) => {
            if (note.base) {
                const exact = ((data.verdi - note.base) * 100) / note.base;
                data.exact = Math.round(exact * 100) / 100;
            } else {
                data.exact = 0;
            }
            data.value = data.exact;
            data.unit = '%';

            if (note.highest < data.value) {
                note.highest = data.value;
            }
            if (note.lowest > data.value) {
                note.lowest = data.value;
            }
        });

        const chart: any = {
            animation: true,
            view: [750, 300],
            data: [list],
            type: 'line',
            padding: {top: 20, left: 0, bottom: 30, right: 70},
            fill: 0.2,
            xAxis: {grid: 1, text: [], size: 20, color: '#00383D', textColor: '#4D7477'},
            yAxis: {
                grid: 0,
                separationLine: false,
                separation: 5,
                color: 'transparent',
                unit: '%',
                toRight: true,
                trimNumber: 0,
                size: 18,
                textColor: '#4D7477',
            },
            colorList: ['#34AFD9'],
        };

        if (note.highest - note.lowest < chart.yAxis.separation) {
            chart.yAxis.trimNumber = 100;
            chart.padding.right = 67;
            list.forEach((d: any) => {
                d.value = d.exact;
            });
        }

        const last = list.length - 1;
        list.forEach((data: any, i: number) => {
            const date = new Date(data.dato);
            const text: any = {text: formatDateToText(date, xAxisFormat), textAnchor: 'start'};
            if (i === 0) {
                text.textAnchor = 'start';
            } else if (i === last) {
                text.textAnchor = 'end';
            } else {
                text.size = '0';
                text.lineStroke = '0';
            }
            chart.xAxis.text.push(text);
        });
        return chart;
    }
;

const initChartPanel = (setChartPanel: (s: any) => void, action: any, data: any, unmounted: any): void => {
    const panel: any = {
        view: {},
        optionList: [
            {value: 'month1', label: '1 måned', length: 1, type: 'DAILY', format: 'dd MMm yyyy', roundDecimal: 0},
            {value: 'year1', label: '1 år', length: 12, type: 'DAILY', format: 'dd MMm yyyy', roundDecimal: 1000},
            {value: 'year3', label: '3 år', length: 12 * 3, type: 'DAILY', format: 'dd MMm yyyy', roundDecimal: 100},
            {value: 'year5', label: '5 år', length: 12 * 5, type: 'DAILY', format: 'dd MMm yyyy', roundDecimal: 10},
        ],
        selected: 'year5',
    };

    let count = panel.optionList.length;
    const get = (option: any): void => {
        action.getFondHistorikk(data.isin, option.length, option.type, (result?: GraphDataApi) => {
            option.list = result?.[0].utviklingsverdier
            if (!option.list) return;

            if (--count || unmounted.current) {
                return;
            }

            // SPASP-7439 removed faulty system for copying data point from first optionList if the data for graph was missing the last date.
            // that means it is possible to have different end dates for different time periods (it should be taken care of in the backend)

            panel.optionList = panel.optionList.filter((opt: any) => {
                if (!opt.list.length) {
                    return false;
                }

                const info: any = {list: [], previous: 0, last: opt.list.length - 1};
                opt.list.forEach((d: any, i: number) => {
                    if (opt.roundDecimal) {
                        d.verdi = Math.round(d.verdi * opt.roundDecimal) / opt.roundDecimal;
                    }

                    if (i === 0 || i === info.last || d.verdi !== info.previous) {
                        info.previous = d.verdi;
                        info.list.push(d);
                    }
                });

                panel.view[opt.value] = convetPanelDataToChart(info.list, opt);
                return true;
            });

            setChartPanel(panel.optionList.length ? panel : null);
        });
    };
    panel.optionList.forEach((option: any) => {
        get(option);
    });


};

const changeChartPanelView = (chartPanel: any, setChartPanel: (s: any) => void, e: any): void => {
    setChartPanel({...chartPanel, selected: e});
};

const getExternalLinkList = (fund: Fund | undefined, appTexts: AppTexts): ExternalLink[] =>
    fund
        ? [
            {
                text: '',
                linkText: appTexts.get('linkToPriipsKiid'),
                url: fund.produktarkUrl,
            },
            {
                text: '',
                linkText: appTexts.get('linkSeMorningstarFondDetaljer'),
                url: fund.morningstarUrl,
            },
            {
                text: '',
                linkText: appTexts.get('linkCompareFundsAtFinansportalen'),
                url: 'https://forbrukerradet.no/finansportalen',
            },
            // SPASP-5612
            // {
            //     text: '',
            //     linkText: appTexts.get('linkOmEikaEtiskeMerking'),
            //     url: 'https://eika.no/spare/barekraftige-investeringer',
            // },
        ]
        : [];

const getOtherExternalLinkList = (appTexts: any): ExternalLink[] => {
    const domain = window.location.origin.replace(/^http(s)?:\/\/(wwww.)?/i, '');
    return [
        {
            text: '',
            linkText: appTexts.get('fundPlatformFeeLinkText', {domain}),
            url: `${window.location.origin}/spare/plattformhonorar`,
        },
        {
            text: '',
            linkText: appTexts.get('fundPriceLinkText', {domain}),
            url: `${window.location.origin}/priser`,
        },
    ];
};

const getFeeInfo = (data: any, appTexts: any): FeeInfo => {
    const isEikaFund = /^eika\s+/i.test(data.navn);
    return {
        description: appTexts.get(isEikaFund ? 'fundFeeEikaDescription' : 'fundFeeDescription'),
        listItem: [
            {
                namespace: Constant.platformFee,
                type: 'list-item',
                data: {
                    textList: [
                        [
                            {text: appTexts.get('platformFee'), type: 'font-normal', id: `fund-fee-${data.isin}-a`},
                            {
                                text: `${formatAmount(data.platformFee || 0, 2)} %`,
                                type: 'font-semibold',
                                ariaLabelledby: `fund-fee-${data.isin}-a`,
                            },
                        ],
                    ],
                },
            },
            {
                namespace: Constant.ongoingCharge,
                type: 'list-item',
                data: {
                    textList: [
                        [
                            {text: appTexts.get('ongoingCharge'), type: 'font-normal', id: `fund-fee-${data.isin}-b`},
                            {
                                text: `${formatAmount(data.ongoingCharge || 0, 2)} %`,
                                type: 'font-semibold',
                                ariaLabelledby: `fund-fee-${data.isin}-b`,
                            },
                        ],
                    ],
                },
            },
            {
                namespace: data.kickback && data.kickback > 0 ? Constant.kickback : '',
                type: 'list-item',
                data: {
                    textList: [
                        [
                            {text: appTexts.get('kickback'), type: 'font-normal', id: `fund-fee-${data.isin}-c`},
                            {
                                text: `${data.kickback ? '- ' : ''}${formatAmount(data.kickback, 2)} %`,
                                type: 'font-semibold',
                                ariaLabelledby: `fund-fee-${data.isin}-c`,
                            },
                        ],
                    ],
                },
            },
            {
                namespace: Constant.yearlyCost,
                type: 'list-item',
                data: {
                    textList: [
                        [
                            {
                                text: appTexts.get('yearlyCostTotal'),
                                type: 'font-semibold',
                                id: `fund-fee-${data.isin}-d`,
                            },
                            {
                                text: `= ${formatAmount(data.yearlyCost || 0, 2)} %`,
                                type: 'font-semibold',
                                ariaLabelledby: `fund-fee-${data.isin}-d`,
                            },
                        ],
                    ],
                },
            },
        ].filter((item: CardProps) => !!item.namespace),
    };
};

const toggleFundFeeDetail = (
    e: any,
    dialog: string,
    setDialog: (s: string) => void,
    setOnShowingDetailModal?: (s: boolean) => void
): void => {
    if (e && e.preventDefault) {
        e.preventDefault();
    }
    const show = !dialog;
    setDialog(dialog);
    if (setOnShowingDetailModal) {
        setOnShowingDetailModal(show);
    }
};

const toggleFundOngoingDetail = (e: any, dialog: boolean, setDialog: (s: boolean) => void): void => {
    if (e && e.preventDefault) {
        e.preventDefault();
    }
    setDialog(!dialog);
};

// eslint-disable-next-line
export default ({
                    appTexts,
                    data,
                    children,
                    click,
                    action,
                    selectFundText,
                    setOnShowingDetailModal = () => {
                    },
                }: Props): JSX.Element => {
    const [chartPanel, setChartPanel] = useState<any>(undefined);
    const [dialog, setDialog] = useState<string>('');
    const [detailAboutESG, setDetailAboutESG] = useState<boolean>(false);
    const [showFeeOngoingCharge, setShowFeeOngoingCharge] = useState<boolean>(false);
    const unmounted = useRef<boolean>(false);
    const externalLinkList = getExternalLinkList(data, appTexts);
    const otherExternalLinkList = getOtherExternalLinkList(appTexts);
    const feeInfo = getFeeInfo(data, appTexts);

    useEffect(() => {
        if (chartPanel !== undefined || !action || !action.getFondHistorikk || !data?.isin || unmounted.current) {
            return;
        }
        setChartPanel(null);
        initChartPanel(setChartPanel, action, data, unmounted);
    }, [chartPanel, setChartPanel, action, data, unmounted]);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, [unmounted]);

    const riskDescription = useMemo(() => {
        if (!data) return '';
        const getRisk = () => {
            switch (true) {
                case data.risiko >= 5:
                    return 'høy';
                case data.risiko >= 3:
                    return 'medium';
                default:
                    return 'lav';
            }
        };
        return `${data.aksjesparekontoKompatibel ? 'Aksjefond' : 'Fond'} med ${getRisk()} risiko`;
    }, [data?.aksjesparekontoKompatibel, data?.risiko]);

    return (
        <>
            {data && (
                <div className="fund-detail">
                    <h1>{data.navn}</h1>
                    <p className="lead">{riskDescription}</p>
                    <p className="lead">{`Risikonivå ${data.risiko} av 7`}</p>

                    <div aria-hidden="true" className="fund-risk-bullet-wrapper">
                        {Array.from({length: 7}).map((_, i) => (
                            <span key={`risk-level-btn-${i}`} className={i < data.risiko ? 'active' : ''}/>
                        ))}
                    </div>

                    {typeof click === 'function' && (
                        <div className="only-for-desktop">
                            <FooterButton
                                click={click}
                                next={selectFundText || 'Velg dette fondet'}
                                action="use-fund"
                            />
                        </div>
                    )}

                    {!!chartPanel && (
                        <div aria-hidden="true" className="fund-return-panel">
                            {!!chartPanel.view[chartPanel.selected] && (
                                <Chart namespace="normal" {...chartPanel.view[chartPanel.selected]} />
                            )}
                            <div className="view-option-wrapper">
                                <ButtonToggles
                                    label="Velg periode å vise i grafen"
                                    onChange={(e: any) => {
                                        changeChartPanelView(chartPanel, setChartPanel, e);
                                    }}
                                    selectedOption={chartPanel.selected}
                                    options={chartPanel.optionList.map((opt: any) => {
                                        return {value: opt.value, label: opt.label};
                                    })}
                                />
                            </div>
                        </div>
                    )}

                    <section
                        aria-labelledby={`${data.isin}-development`}
                        className="development fund-development-wrapper"
                    >
                        <h2 id={`${data.isin}-development`} className="paragraph-extra-small">
                            {appTexts.get('fondHistoriskUtvikling')}
                        </h2>
                        <p className="lead" style={{margin: '0 0 32px 0'}}>
                            Gjennomsnittlig utvikling per år.
                        </p>

                        <div className="fund-development-holder">
                            <ul className="flat fund-development-list fund-development-return-list">
                                {getFondUtviklingConfigList().map((config) => {
                                    const value = data.utvikling?.[config.id as keyof FundUtvikling] || null;
                                    const isNegative = (value || 0) < 0;
                                    const progress = isNegative
                                        ? 100 + parseInt(`${value}`, 10)
                                        : parseInt(`${value || 0}`, 10);
                                    // eslint-disable-next-line
                                    const arrow = value === null ? 'none' : isNegative ? 'negative' : 'positive';

                                    return (
                                        <li className="fund-development" key={config.id}>
                                            <div className="fund-development-item">
                                                <span
                                                    aria-hidden="true"
                                                    className={`development-status -percent-${progress} -${arrow}`}
                                                >
                                                    <span/>
                                                </span>
                                                <div
                                                    aria-labelledby={config.id}
                                                    className={`value -${isNegative ? 'negative' : 'positive'}`}
                                                >
                                                    {value === null ? '-' : `${value} %`.replace('.', ',')}
                                                </div>
                                                <div id={config.id} className="text">
                                                    {config.name}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </section>

                    {(data.descriptionList || []).length > 0 && (
                        <section aria-labelledby={`${data.isin}-description`} className="description">
                            <h2 id={`${data.isin}-description`} className="paragraph">
                                {appTexts.get('omFondet')}
                            </h2>
                            <FlexTextHolder
                                text={safeArray(data.descriptionList).map((text: string) => {
                                    return {text, type: 'checked -font-normal'};
                                })}
                            />
                        </section>
                    )}

                    {!!data.eikespirer && (
                        <section aria-labelledby={`${data.isin}-esg`} className="esg-info">
                            <Message role="" type="esg">
                                <>
                                    <h2 className="paragraph-small">{appTexts.get('esg')}</h2>
                                    <Sprouts max={5} active={data.eikespirer}/>

                                    <div
                                        className={`esg-info-text-wrapper -${
                                            detailAboutESG ? 'expanded' : 'collapsed'
                                        }`}
                                    >
                                        <div className="esg-tool-wrapper">
                                            <LinkButton
                                                onClick={() => {
                                                    setDetailAboutESG(!detailAboutESG);
                                                }}
                                            >
                                                {detailAboutESG ? 'Vis mindre' : 'Vis mer'}
                                            </LinkButton>
                                        </div>
                                        {/* eslint-disable-next-line */}
                                        <div
                                            className="esg-range-description paragraph-large"
                                            // eslint-disable-next-line
                                            dangerouslySetInnerHTML={{__html: appTexts.get('esgRangeDescription')}}
                                        />

                                        <div className="bold">{appTexts.get('esgRangeTextLabel')}</div>
                                        <ul className="esg-range-text-list">
                                            {Array.from({length: 5}).map((x: any, i: number) => {
                                                return (
                                                    <li key={`esg-range-text-${i}`}>
                                                        <span>{appTexts.get(`esgRangeText${5 - i}`)}</span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    {!data.externalFund && (
                                        <div className="esg-link-wrapper paragraph -only-top">
                                            <Link
                                                href={`${
                                                    isEikaDomain() ? 'https://eika.no' : window.location.origin
                                                }/spare/ansvarlige-investeringer`}
                                                iconOnRightSide
                                                icon={IconTypes.EKSTERN_24}
                                            >
                                                {appTexts.get('esgLinkToEikaText')}
                                            </Link>
                                        </div>
                                    )}
                                </>
                            </Message>
                        </section>
                    )}

                    <section aria-labelledby={`${data.isin}-price`} className="price">
                        <h2 id={`${data.isin}-price`} className="paragraph-small">
                            Priser
                        </h2>
                        <Card
                            type="list-item fund-fee-item"
                            click={(e) => {
                                toggleFundFeeDetail(e, 'fund-fee', setDialog, setOnShowingDetailModal);
                            }}
                            data={{
                                textList: [
                                    [
                                        {text: 'Årlig kostnad', type: 'font-normal'},
                                        {text: `${formatAmount(data.yearlyCost, 2)} %`, type: 'font-semibold'},
                                    ],
                                ],
                            }}
                        />
                        <Card
                            type="list-item"
                            data={{
                                textList: [
                                    [
                                        {text: 'Provisjon ved kjøp av fond', type: 'font-normal'},
                                        {text: `${formatAmount(data.maxFrontEndLoad, 2)} %`, type: 'font-semibold'},
                                    ],
                                ],
                            }}
                        />
                        <Card
                            type="list-item"
                            data={{
                                textList: [
                                    [
                                        {text: 'Provisjon ved salg av fond', type: 'font-normal'},
                                        {text: `${formatAmount(data.maximumExitFee, 2)} %`, type: 'font-semibold'},
                                    ],
                                ],
                            }}
                        />
                        {data.currency !== Constant.nok && (
                            <Card
                                type="list-item fund-fee-item"
                                click={(e) => {
                                    toggleFundFeeDetail(e, 'foreign-currency', setDialog, setOnShowingDetailModal);
                                }}
                                data={{
                                    textList: [
                                        [
                                            {text: appTexts.get('foreignCurrency'), type: 'font-normal'},
                                            {text: data.currency, type: 'font-semibold'},
                                        ],
                                    ],
                                }}
                            />
                        )}
                    </section>

                    <section aria-labelledby={`${data.isin}-mininum-price`} className="price">
                        <h2 id={`${data.isin}-mininum-price`} className="paragraph-small">
                            Minstebeløp ved sparing
                        </h2>
                        {data.minimumOrderAmountFirstTimePurchase && (
                            <Card
                                type="list-item"
                                data={{
                                    textList: [
                                        [
                                            {text: 'Førstegangskjøp', type: 'font-normal'},
                                            {
                                                text: `${formatAmount(data.minimumOrderAmountFirstTimePurchase)} kr`,
                                                type: 'font-semibold',
                                            },
                                        ],
                                    ],
                                }}
                            />
                        )}
                        <Card
                            type="list-item"
                            data={{
                                textList: [
                                    [
                                        {text: 'Engangsbeløp', type: 'font-normal'},
                                        {
                                            text: `${formatAmount(data.minimumsBelopEngangsKjop)} kr`,
                                            type: 'font-semibold',
                                        },
                                    ],
                                ],
                            }}
                        />
                        <Card
                            type="list-item"
                            data={{
                                textList: [
                                    [
                                        {text: 'Månedlig sparing', type: 'font-normal'},
                                        {
                                            text: `${formatAmount(data.minimumsBelopPeriodiskKjop)} kr`,
                                            type: 'font-semibold',
                                        },
                                    ],
                                ],
                            }}
                        />
                    </section>

                    {externalLinkList.length > 0 && (
                        <ul className="flat external-link-list">
                            {externalLinkList.map((d: ExternalLink, i: number) => {
                                return (
                                    <li key={`external-link-${i}`}>
                                        <ExternalLinkLabel {...d} />
                                    </li>
                                );
                            })}
                        </ul>
                    )}

                    {children}

                    <Modal
                        modalSize={ModalSize.LARGE}
                        id="fund-overview-filter"
                        appNamespace="sparing-react-app"
                        onClose={() => {
                            setDialog('');
                            setOnShowingDetailModal(false);
                        }}
                        show={!!dialog}
                        headerElements={
                            <>
                                <span/>
                                <span>
                                    <IconButton
                                        type="button"
                                        onClick={() => {
                                            setDialog('');
                                            setOnShowingDetailModal(false);
                                        }}
                                        icon={IconTypes.KRYSS_24}
                                    />
                                </span>
                            </>
                        }
                    >
                        {dialog === 'fund-fee' && (
                            <section aria-labelledby="fee-detail-title" className="fee-detail-wrapper">
                                <h2 aria-describedby="fee-detail-description" id="fee-detail-title">
                                    {appTexts.get('yearlyCost')}
                                </h2>
                                <p id="fee-detail-description">{feeInfo.description}</p>
                                <div className="paragraph -include-top">
                                    {feeInfo.listItem.map((item: CardProps, i: number) => {
                                        return item.namespace === Constant.ongoingCharge ? (
                                            <Card
                                                key={`fee-info-item-${i}`}
                                                {...item}
                                                type="list-item fund-fee-item"
                                                click={(e) => {
                                                    toggleFundOngoingDetail(
                                                        e,
                                                        showFeeOngoingCharge,
                                                        setShowFeeOngoingCharge
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <Card key={`fee-info-item-${i}`} {...item} />
                                        );
                                    })}
                                </div>
                                {otherExternalLinkList.length > 0 && (
                                    <ul className="flat external-link-list paragraph-large -include-top">
                                        {otherExternalLinkList.map((d: ExternalLink, i: number) => {
                                            return (
                                                <li key={`external-link-${i}`}>
                                                    <ExternalLinkLabel {...d} />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </section>
                        )}

                        {dialog === 'foreign-currency' && (
                            <section aria-labelledby="foreign-currency-title" className="foreign-currency-wrapper">
                                <h2 aria-describedby="foreign-currency-description" id="foreign-currency-title">
                                    {appTexts.get('foreignCurrency')}
                                </h2>
                                <p id="fee-detail-description">
                                    {appTexts.get('foreignCurrencyInfo', {fundName: data.navn})}
                                </p>
                            </section>
                        )}

                        <SubModal
                            modalSize={ModalSize.LARGE}
                            id="fund-overview-filter-submobal"
                            appNamespace="sparing-react-app"
                            onClose={() => {
                                setShowFeeOngoingCharge(false);
                            }}
                            show={showFeeOngoingCharge}
                        >
                            <section
                                aria-labelledby="fee-ongoing-charge-title"
                                className="fee-ongoing-charge-information"
                            >
                                <h2 id="fee-ongoing-charge-title">{appTexts.get('ongoingCharge')}</h2>
                                <p>{appTexts.get('ongoingChargeInfoDescription1')}</p>
                                <p>{appTexts.get('ongoingChargeInfoDescription2')}</p>
                                <p>{appTexts.get('ongoingChargeInfoDescription3')}</p>

                                {/* Eika Alpha */}
                                {data.isin === 'NO0010212350' && (
                                    <div className="paragraph -only-top">
                                        <h3>{appTexts.get('eikaAlphaYearCostTitle')}</h3>
                                        <p>{appTexts.get('eikaAlphaYearCostText1')}</p>
                                        <p>{appTexts.get('eikaAlphaYearCostText2')}</p>
                                        <p>{appTexts.get('eikaAlphaYearCostText3')}</p>
                                    </div>
                                )}
                            </section>
                        </SubModal>
                    </Modal>
                </div>
            )}
        </>
    );
};
