const BankList = [
	{id: '0411', name: 'Eika Kredittbank - NAF', homepage: 'https://eika.no/'},
	{id: '0413', name: 'Agrikjøp', homepage: 'https://agrikjop.no/'},
	{id: '0501', name: 'Eika Kapitalforvaltning', homepage: 'https://eika.no/'},
	{id: '0502', name: 'Eika Forsikring', homepage: 'https://eika.no/'},
	{id: '0503', name: 'Eika Boligkreditt AS', homepage: 'https://eika.no/'},
	{id: '0504', name: 'Eika Depotservice', homepage: 'https://eika.no/'},
	{id: '0770', name: 'Eika (NOTE: not a actual bank)', homepage: 'https://eika.no/'},
	{id: '1020', name: 'Eidsberg Sparebank', homepage: 'https://esbank.no/'},
	{id: '1050', name: 'Marker Sparebank', homepage: 'https://marker-sparebank.no/'},
	{id: '1100', name: 'Askim & Spydeberg Sparebank', homepage: 'https://asbank.no/'},
	{id: '1105', name: 'Berg Sparebank', homepage: 'https://berg-sparebank.no/'},
	{id: '1140', name: 'Trøgstad Sparebank', homepage: 'https://tsbank.no/'},
	{id: '1271', name: 'Aurskog Sparebank', homepage: 'https://aurskog-sparebank.no/'},
	{id: '1280', name: 'Høland & Setskog Sparebank', homepage: 'https://hsbank.no/'},
	{id: '1286', name: 'LillestrømBanken', homepage: 'https://lillestrombanken.no/'},
	{id: '1310', name: 'Strømmen Sparebank', homepage: 'https://strommensparebank.no/'},
	{id: '1321', name: 'Blaker Sparebank', homepage: 'https://blakersparebank.no/'},
	{id: '1440', name: 'Jernbanepersonalets bank og forsikring', homepage: 'https://jbf.no/'},
	{id: '1450', name: 'Fornebubanken', homepage: 'https://fornebusparebank.no/'},
	{id: '1720', name: 'Bien Sparebank', homepage: 'https://bien.no/'},
	{id: '1830', name: 'Grue Sparebank', homepage: 'https://gruesparebank.no/'},
	{id: '1870', name: 'Odal Sparebank', homepage: 'https://odal-sparebank.no/'},
	{id: '1885', name: 'Tolga-Os Sparebank', homepage: 'https://tos.no/'},
	{id: '2050', name: 'Totens Sparebank', homepage: 'https://totenbanken.no/'},
	{id: '2140', name: 'Etnedal Sparebank', homepage: 'https://etnedalsparebank.no/'},
	{id: '2153', name: 'Valdres Sparebank', homepage: 'https://valdressparebank.no/'},
	{id: '2230', name: 'Hønefoss Sparebank', homepage: 'https://skuesparebank.no/'},
	{id: '2351', name: 'Skue Sparebank', homepage: 'https://skuesparebank.no/'},
	{id: '2500', name: 'Andebu Sparebank', homepage: 'https://andebu-sparebank.no/'},
	{id: '2510', name: 'Larvikbanken', homepage: 'https://larvikbanken.no/'},
	{id: '2601', name: 'Skagerrak Sparebank', homepage: 'https://skagerraksparebank.no/'},
	{id: '2606', name: 'NORDirektebank', homepage: 'https://nordirekte.no/'},
	{id: '2620', name: 'Tinn Sparebank', homepage: 'https://tinnbank.no/'},
	{id: '2630', name: 'Sparebanken DIN', homepage: 'https://sparebankendin.no/'},
	{id: '2635', name: 'Drangedal Sparebank', homepage: 'https://drangedalsparebank.no/'},
	{id: '2699', name: 'Hjartdal og Gransherad Sparebank', homepage: 'https://hjartdalbanken.no/'},
	{id: '2880', name: 'Birkenes Sparebank', homepage: 'https://birkenes-sparebank.no/'},
	{id: '2890', name: 'Valle Sparebank', homepage: 'https://valle-sparebank.no/'},
	{id: '2895', name: 'Arendal og Omegns Sparekasse', homepage: 'https://sparekassa.no/'},
	{id: '2901', name: 'Evje og Hornnes Sparebank', homepage: 'https://eh-sparebank.no/'},
	{id: '2907', name: 'Østre Agder Sparebank', homepage: 'https://oasparebank.no/'},
	{id: '3080', name: 'Kvinesdal Sparebank', homepage: 'https://kvinesdalsparebank.no/'},
	{id: '3260', name: 'Sandnes Sparebank', homepage: 'https://sandnes-sparebank.no/'},
	{id: '3290', name: 'Jæren Sparebank', homepage: 'https://jaerensparebank.no/'},
	{id: '3353', name: 'Hjelmeland Sparebank', homepage: 'https://hjelmeland-sparebank.no/'},
	{id: '3525', name: 'Tysnes Sparebank', homepage: 'https://tysnes-sparebank.no/'},
	{id: '3730', name: 'Sogn Sparebank', homepage: 'https://sognbank.no/'},
	{id: '3910', name: 'Sparebanken Møre', homepage: 'https://www.sbm.no/'},
	{id: '4035', name: 'Sunndal Sparebank', homepage: 'https://sunndal-sparebank.no/'},
	{id: '4040', name: 'Surnadal Sparebank', homepage: 'https://sunndal-sparebank.no/'},
	{id: '4060', name: 'Ørskog Sparebank', homepage: 'https://orskogsparebank.no/'},
	{id: '4075', name: 'Romsdalsbanken', homepage: 'https://romsdalsbanken.no/'},
	{id: '4111', name: 'Rindal Sparebank', homepage: 'https://rindalsbanken.no/'},
	{id: '4230', name: 'MelhusBanken', homepage: 'https://melhusbanken.no/'},
	{id: '4266', name: 'Oppdalsbanken', homepage: 'https://oppdalsbanken.no/'},
	{id: '4270', name: 'Orkla Sparebank', homepage: 'https://orklasparebank.no/'},
	{id: '4280', name: 'RørosBanken', homepage: 'https://rorosbanken.no/'},
	{id: '4285', name: 'Selbu Sparebank', homepage: 'https://selbusparebank.no/'},
	{id: '4290', name: 'Ørland Sparebank', homepage: 'https://orland-sparebank.no/'},
	{id: '4295', name: 'Bjugn Sparebank', homepage: 'https://bjugn-sparebank.no/'},
	{id: '4312', name: 'Hemne Sparebank', homepage: 'https://hemnesparebank.no/'},
	{id: '4333', name: 'Soknedal Sparebank', homepage: 'https://soknedal-sparebank.no/'},
	{id: '4336', name: 'Stadsbygd Sparebank', homepage: 'https://stbank.no/'},
	{id: '4345', name: 'Åfjord Sparebank', homepage: 'https://afjord-sparebank.no/'},
	{id: '4355', name: 'Haltdalen Sparebank', homepage: 'https://haltdalensparebank.no/'},
	{id: '4358', name: 'Nidaros Sparebank', homepage: 'https://nidaros-sparebank.no/'},
	{id: '4448', name: 'Grong Sparebank', homepage: 'https://grong-sparebank.no/'},
	{id: '4465', name: 'Hegra Sparebank', homepage: 'https://hegrasparebank.no/'},
	{id: '4484', name: 'Aasen Sparebank', homepage: 'https://aasen-sparebank.no/'},
	{id: '4520', name: 'Sparebanken Narvik', homepage: 'https://sn.no/'},
	{id: '4605', name: 'Ofoten Sparebank', homepage: 'https://68nord.no/'},
	{id: '4609', name: 'Gildeskål Sparebank', homepage: 'https://gildeskaal-sparebank.no/'},
	{id: '4730', name: 'Sparebank 68° Nord', homepage: 'https://68nord.no/'},
	{id: '9581', name: 'Voss Veksel- og Landmandsbank', homepage: 'https://vekselbanken.no/'},
	{id: '9615', name: 'Bank2', homepage: 'https://bank2.no/'},
	{id: '9811', name: 'Eika Kredittbank', homepage: 'https://eika.no/'},
	{id: '9812', name: 'Sparesmart.no/', homepage: 'https://sparesmart.no/'},
	{id: '9900', name: 'yA Bank', homepage: 'https://ya.no/'}
];

export default BankList;