import React, { useState, MouseEvent } from 'react';
// @ts-ignore
import { convertServiceMessageToAppMessage } from '@eika-sparing/utils/src/serviceMessage/serviceMessage';
import { SmallButton } from '@eika/button';
import { Link } from '@eika/link';
import Message, { Props as MessageProps} from '../Message/Message';
import {createCookie, readCookie} from '../util/Functions';
import { ServiceMessage } from '../../domain/Types';

import './AppMessage.scss';

interface Props {
    appMessage?: ServiceMessage[];
    context?: string;
    children?: JSX.Element | JSX.Element[] | boolean;
};

interface State {
    open: any;
};

const click = (state: State, setState: (s: State) => void, e: MouseEvent, data: any): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if ( data?.link?.idForHidding ) {
        createCookie(data.link.idForHidding, '1', (24*180));
        window.location.href = data.link.url;
        setState({...state, open: {...state.open, [data.link.idForHidding] : 1}});
    }
}

export default ({appMessage, context, children}: Props): JSX.Element | null => {
    const list = convertServiceMessageToAppMessage( appMessage, context );
    const [state, setState] = useState<State>({ open: {}});

    return list.length || children ? <div className="service-message-wrapper">
        { list.map( (data: MessageProps, i: number) => {
            if ( data.link?.idForHidding && (readCookie(data.link?.idForHidding) || state.open[data.link?.idForHidding]) ) {
                return null;
            }

            // eslint-disable-next-line
            return <Message key={`service-message-${i}`} {...data} type={`info-message -${data.type}`}>                
                { data.link && <div className="paragraph-small -only-top">
                    { data.link.idForHidding ?
                        <SmallButton buttonType="negative" onClick={(e: MouseEvent)=>{ click(state, setState, e, data)}}>{data.link.text}</SmallButton> :
                        <Link href={data.link.url}>{data.link.text}</Link>
                    }
                </div> }
            </Message>
        })}
        {children}
    </div> : null;
};