const Dictionary = {
    norsk: {
        Publiseringsdato: 'xx.dd.yyyy',
        aksjesparekontoOpprettet: 'Aksjesparekonto opprettet!',
        aksjespareKontoPasserForAlle: 'Aksjesparekonto passer for alle som sparer i aksjefond.',
        arbeidsgiversparingAvtaler: 'Din nåværende arbeidsgivers sparing',
        arbeidsgiversPensjonssparing: 'Din arb. givers pensjonsparing',
        arbeidsgiversPensjonsparingTittel: 'Arbeidsgivers pensjonsparing',
        avtaleNummer: 'Avtalenummer',
        arbeidsgiver: 'Arbeidsgiver',
        arbeidsgiverSparingOgPensjon: 'Arbeidsgivers sparing og pensjon',
        arbeidsgiversSparingKort: 'Arb. givers pensjonssparing',
        arbeidsgiversSparing: 'Arbeidsgivers pensjonssparing',
        avDagensLonnIPensjon: 'av dagens lonn i pensjon',
        avbryt: 'Avbryt',
        arvEllerGaver: 'Arv eller gaver',
        annet: 'Annet',
        avsluttSpareavtale: 'Avslutt spareavtale',
        aksjesparekonto: 'Aksjesparekonto',
        avsluttet: 'Avsluttet',
        aksjerFondOgSparing: 'Aksjer, fond og sparing',
        bekreftSalg: 'Bekreft salg',
        belopPaaKonto: 'Beløp på konto',
        bekreftNyttInnskudd: 'Bekreft nytt innskudd',
        bekreftNyttSpareBelop: 'Bekreft nytt sparebeløp',
        bekreftAvsluttSpareavtale: 'Bekreft avslutt spareavtale',
        bekreftOverforing: 'Bekreft overføring',
        bekreftFolgende: 'Før du kan bestille må du bekrefte folgende:',
        bekreftOgBestill: 'Bekreft og bestill',
        belop: 'Beløp',
        bruttoAarsinntektIAar: 'Brutto årsinntekt i år',
        byttetFra: 'Byttet fra',
        byttetTil: 'Byttet til',
        byttFondKvitteringTittel: 'Du har byttet fond',
        byttForKr: 'Bytt for kr',
        byttHeleBeholdning: 'Bytt hele beholdningen: {beholdning},-',
        hvaErDittBostedsLand: 'Hvilket land bor du i',
        minBankSparing: 'Min banksparing',
        mineEiendeler: 'Mine eiendeler',
        duSparerPerMaaned: 'Du sparer {maanedligSparing},- per måned i dette fondet',
        dineAksjefond: 'Dine aksjefond',
        duHarKjopt: 'Du har kjøpt ',
        duKanIkkeSelgeHeleBeholdningen:
            'Du kan bare legge inn 85% av totalverdien, evt velge Selg hele beholdningen',
        endreMaanedligSparing: 'Endre månedlig sparing',
        nySpareavtale: 'Ny spareavtale',
        spareavtaleNavn: 'Navn',
        endreKontoNavnHjelpeTekst: 'Kontonavnet må være mellom 3 og 25 tegn',
        epost: 'E-post',
        etternavn: 'Etternavn',
        eier: 'Eier',
        eikaSparPromo: 'I Eika Spar har vi samlet våre favorittaksjer fra fondene i Norge, Norden og Global',
        egenkapitalFraEiendomsSalg: 'Egenkapital fra eiendomssalg',
        egenerklaeringAntihvitvask: 'Egenerklæring, anti hvitvask',
        endrerMndSparing: 'Endrer mnd sparing',
        endre: 'Endre',
        forventetPensjon: 'Forventet årlig pensjon',
        fastTrekkDatoManedligSparing: 'Fast trekkdato månedlig sparing',
        fripoliserMedInvestering: 'Fripoliser med investering',
        fripoliserMedInvesteringsValgAvtaler: 'Fripoliser med investeringsvalg',
        forsteTrekkDato: 'Første trekkdato',
        eikaIpsKonto: 'Eika IPS-konto',
        utviklingSisteFemAar: '{prosent} siste 5 år',
        utviklingiAar: '{prosent} i år',
        utviklingSisteTreAar: '{prosent} siste 3 år',
        portefoljePlassering: 'Plassering',
        fornavn: 'Fornavn',
        avkastning: 'Avkastning',
        formal: 'Er formålet med kundeforholdet noe annet enn å muliggjøre fondssparing?',
        fodselsNummer: 'Fødselsnummer',
        faktaOmAksjesparekontoTittel: 'Fakta om aksjesparekonto',
        fripoliserMedInvesteringsValg: 'Fripoliser m investeringsvalg',
        fullfor: 'Fullfør',
        fullstendigeVilkaar: 'Fullstendinge vilkår',
        flyttSpareAvtale: 'Flytt min spareavtale',
        flyttet: 'Flyttet',
        forklarPengenesOpprinnelse: 'Forklar pengenesopprinnelse',
        forklarMedMerEnnAntallTegn: 'Forklar med minst 20 tegn',
        ikkeFlyttet: 'Ikke flyttet',
        individuellPensjonsSparing: 'Individuell pensjonssparing',
        godtaSkattVedSalg: 'Jeg godtar at jeg må skatte av salgene',
        godtarSamtykker: 'Godtar samtykker',
        gjorEttNyttInnskudd: 'Gjør ett nytt innskudd',
        gratulererMedNyAksjesparekonto: 'Gratulerer med ny aksjesparekonto, du finner den i oversikten',
        gevinstTap: 'Gevinst/Tap',
        gevinst: 'Gevinst',
        gaaVidere: 'Gå videre',
        skrivInnEnGyldigSumOverMinimumsbelop: 'Skriv inn en gyldig sum over minimumsbeløpet',
        skrivInnEnGyldigSum: 'Skriv inn ett gyldig beløp',
        hittilIAar: 'Hittil i år:',
        hoy: 'Høy',
        seAlleFond: 'Se alle fond',
        anbefalteFond: 'Anbefalte fond',
        middels: 'Middels',
        bleDetMindreEnnDuTrodde: 'Ble det mindre enn du trodde?',
        belopetErForLite: 'Beløpet er for lite',
        hvorVilDuLeggeAksjene: 'Hvor vil du legge aksjene?',
        skrivEnGyldigEpostAdresse: 'Skriv en gyldig epost adresse',
        skrivEttGyldigMobilNummer: 'Skriv ett gyldig telefonnummber',
        sisteTiAar: 'Siste 10 år:',
        sisteFemAar: 'Siste 5 år:',
        sisteTreAar: 'Siste 3 år:',
        sparTilPensjon: 'Spar til pensjon',
        tidligereArbeidsgiversSparing: 'Nåværende/tidligere arbeidsgivers sparing',
        tap: 'Tap',
        taUtPenger: 'Ta ut penger',
        tilgjengeligForKjopEllerUttak: 'Tilgjengelig for kjøp eller uttak',

        iPortefolje: 'i {portefoljeNavn}',
        seHvaEkstraSparingKanGjore: '{ekstraSparing} ekstra per måned i ett aksjefond',
        selgFond: 'Selg {fond}',
        selgFondKvitteringTittel: 'Du har solgt!',
        selgHeleBeholdningen: 'Selg hele beholdningen: {beholdning},-',
        skatteplikt: 'Jeg er ikke amerikansk statsborger, eller skattepliktig til andre land enn Norge',
        skrivInnEttGyldigKontoNummer: 'Skriv inn ett gyldig kontonummer; 11 siffer.',
        kontoForMaanedligTrekk: 'Konto for mnd. trekk',
        kontoForBelastning: 'Konto for belastning',
        kontoForTrekk: 'Konto for trekk',
        kjoptFond: 'Kjøpt fond',
        kjopDetteFondet: 'Kjøp dette fondet',
        ipsUttaksAlder: 'Ips uttaksalder',
        sparIIPS: 'Spar i IPS',
        lavRisiko: 'Lav risiko',
        lavRisikoBeskrivelse:
            'Jeg er opptatt av trygghet, men jeg ønsker noe høyere ' +
            'avkastning enn jeg kan få på bankkonto. Jeg aksepterer at verdien på sparepengene ' +
            'kan svinge litt i verdi underveis i spareperioden.',
        middelsRisiko: 'Medium risiko',
        middelsRisikoBeskrivelse:
            'Jeg vil ha en kombinasjon av trygghet og avkastning. ' +
            'Jeg aksepterer derfor at verdien på sparepengene svinger noe mer underveis i spareperioden. ',
        hoyRisiko: 'Høy risiko',
        hoyRisikoBeskrivelse:
            'Jeg er opptatt av høyest mulig avkastning på sparepengene mine. ' +
            'Jeg aksepterer at verdien på sparepengene kan svinge mye underveis i spareperioden.',
        ipsPunktEn: 'Kunden kan spare opptil kr. 15 000 per år, og kreve fradrag for beløpet.',
        ipsPunktTo: 'Kunden velger selv størrelsen på- og tidspunktet for innbetalingene.',
        ipsPunktTre: 'Midlene er bundet frem til uttak.',
        ipsPunktFire:
            'Det kan spares i ordningen fra fylte 18 år til fylte 75 år. Uttak, som kan starte fra fylte 62 år,' +
            '  må fordeles over minst 10 år, og minst til fylte 80 år.',
        ipsPunktFem: 'Uttak skattlegges som alminnelig inntekt.',
        ipsPunktSeks: 'Verdien på kontoen er unntatt formuesskatt.',
        ipsPunktSyv: 'Det er ikke knyttet forsikringer eller garantier til avtalen.',
        ipsPunktAtte: 'Ordinære kostnader ved kjøp og salg av finansielle instrumenter vil påløpe.',
        inngaAvtaleOmIps: 'Inngå avtale om IPS-konto',
        avtalenFinnerDuPaMinSpareProfil: 'Avtalen finner du på "Min spareprofil"',
        sparTilPensjonMedIPS: 'Spar til pensjon med IPS',
        seAnbefalteFond: 'Se anbefalte fond',
        faaAnbefalteFond: 'Få anbefalte fond',
        faaAnbefalingBasertPaa: 'Få anbefaling basert på pensjonsalder og risiko',
        kontoTilknyttetAsk: 'Konto tilknyttet ASK',
        klientKonto: 'Klient konto',
        lavereRisiko: 'Lavere Risiko',
        lasterFondsApp: 'Laster fondsapp...',
        lasterPensjonsApp: 'Laster pensjonsapp...',
        lasterSparingsApp: 'Laster sparingsapp...',
        lasterDinSpareProfil: 'Laster inn din profil',
        lasterBankData: 'Laster din bank data',
        leggTilKontoFraAnnenBank: 'Legg til konto fra annen bank',
        leggTilKonto: 'Legg til konto',
        lasterArbeidsgiversPensjonsSparing: 'Laster arbeidsgiverspensjonsparing',
        lonn: 'Lønn',
        leggTilAnnenKonto: 'Legg til annen konto',
        lav: 'Lav',
        lukk: 'Lukk',
        spartHittilIaar: 'Spart hittil i år: ',
        maksPerAarIps: 'Maks per år: 15 000,-',
        braJobbet: 'Bra jobbet, du har spart {belop} i år, og maksimert skattefordelene dine med IPS',
        oppretterIPSLasteTekst: 'Oppretter IPS',
        ipsOpprettetKvitteringsTekst:
            'Du har opprettet konto for individuell pensjonssparing (IPS) og kan starte' +
            ' egen sparing til pensjon',
        lesOpprettASKVilkaareneHer: 'Les om aksjesparekonto vilkårene',
        lesOpprettIPSVilkaareneHer: 'Les om individuell pensjonssparing vilkårene',
        hoyereRisiko: 'Hoyere Risiko',
        harDuRettPaaOffentligTjenestePensjon: 'Har du rett på offentlig tjenestepensjon?',
        harDuAFP: 'Har du AFP i private sektor?',
        engangsbelopEllerMaanedlig: 'Vil du kjøpe for et engangsbeløp eller et fast beløp hver måned?',
        engangsbelop: 'Engangsbeløp',
        belopMinimum: 'Beløp (Minimum 100 kr)',
        fastBelop: 'Fast beløp hver måned',
        innskudd: 'Innskudd',
        viBerDegLese: 'Vi ber deg om å lese avtalen i sin helhet ved å trykke her:',
        avtaleOmIndividuellSparingTilPensjon: 'Avtale om individuell sparing til pensjon',
        innskuddFond: 'Innskudd fond',
        innskuttBelop: 'Innskutt beløp',
        individuelleOrdninger: 'Individuelle ordninger',
        iHvilketLandErDuFodt: 'I hvilket land er du født?',
        innskuddOgMaanedligSparing: 'Innskudd og månedlig sparing',
        ikkeAvsluttet: 'Ikke avsluttet',
        informasjonOmSparing: 'Informasjon om sparing',
        informasjonOmPensjon: 'Informasjon om pensjon',
        urealisertGevinst: 'Urealisert gevinst',
        urealisertGevinstKort: 'Ureal. gevinst',
        utforerKjop: 'Utfører kjøp',
        utsettSkatt: 'Utsett skatt på gevinst når du bytter eller selger fond',
        totalVerdi: 'Total verdi',
        verdistigning: 'Verdistigning',
        maanedligSparing: 'Månedlig sparing',
        maanedligSparingKort: 'Mnd sparing',
        minAksjeOgFondSparing: 'Min aksje- og fondssparing',
        minSparingTittel: 'Min sparing',
        minAksjeOgFondsparingTittel: 'Min aksje- og fondsparing',
        minBankSparingTekst:
            'Her er oversikten over dine konti. Velg hvilke du vil ha med i visningen av dine totale banksparing',
        minSpareProfil: 'Min spareprofil',
        minPensjonTittel: 'Min pensjon',
        minPensjonTekst:
            'Her kan du redigere din spareprofil slik at vi kan gi deg mer presise tall på hvilken pensjon du får.',
        mobilNummer: 'Mobilnummer',
        manedligSparing: 'Månedlig sparing',
        minAksjeOgFondsparing: 'min aksje- og fondsparing',
        nei: 'Nei',
        navn: 'Navn',
        navnPaaKonto: 'Navn på konto',
        nyttInnskuddIFond: 'Nytt innskudd i {fond}',
        nyttMndSpareBelop: 'Nytt månedlig sparebelop',
        nyttMndSpareBelopKvitteringsTittel: 'Nytt månedlig sparebelop!',
        nyttInnskuddI: 'Nytt innskudd i ',
        nyttInnskuddKvitteringsTittel: 'Nytt innskudd!',
        naarVilDuGaaAv: 'Når vil du gå av?',
        omMeg: 'Om meg',
        overfor: 'Overfør',
        onsketPensjonsAlder: 'Ønsket pensjonsalder:',
        opprettAksjesparekonto: 'Opprett aksjesparekonto',
        opprettIndividuellPensjonsSparingsKonto: 'Opprett individuell pensjonssparingskonto',
        opprettEikaVerdiPapirKonto: 'Opprett Eika verdipapirkonto',
        oppretterIPS: 'Oppretter IPS',
        duHarOpprettetIPS: 'Du har opprettet IPS',
        IPSOpprettet: 'IPS opprettet',
        ipsProfil: 'Ips profil',
        lasterDinIpsProfil: 'Laster ips profil',
        startIPSSparingNaa: 'Start IPS sparing nå',
        startIPSSparingNaaTekst:
            'Du kan nå begynne å spare i din IPS konto ved å kjøpe fond. Penger som du sparer ' +
            'på IPS konto ved å kjøpe fond. ' +
            'Penger som du sparer på IPS kontoen din vil være bundet til pensjon og kan tas ut tidligst når du er 62 år',
        dersomDuSparer: 'Dersom du sparer 15 000,- per år får du igjen 3 600,- på skatten (24%)',
        dinIpsSparing: 'Din IPS sparing',
        lasterSpareTall: 'Laster sparetall',
        oppsummeringOgBekreftelse: 'Oppsummering og bekreftelse',
        lasterFondsHistorikk: 'Laster fondshistorikk',
        opprettAvtaleGiro: 'Opprett avtalegiro',
        opprettIndividuellPensjonsSparing: 'Opprett Individuell pensjonssparing (IPS)',
        opprettIndividuellPensjonsSparingTekst:
            'IPS er en gunstig måte å spare til pensjon på. ' +
            'Du kan spare inntil 15.000,- per år og trekke dette fra på skatten.',
        opprettIndividuellPensjonsSparingEn: 'Få inntil 9 6gi00,- i skattefordel per år',
        opprettIndividuellPensjonsSparingTo: 'Pengene kan ikke tas ut før du er 62 år',
        opprettIndividuellPensjonsSparingTre: 'Du kan kjøpe fond og bytte fond selv',
        godkjennVilkaarForIps: 'Godkjenn vilkår for opprettelse av Eika IPS',
        opprettIPSKonto: 'Opprett IPS-konto',
        skrivInnFornavnOgEtternavn: 'Skriv inn fornavn og etternavn',
        skrivInnEttGyldigFodselsnummer: 'Skriv inn ett gyldig fødselsnummer',
        saldoinkpensjon: 'Saldo inklusive pensjon',
        sparIAndreFond: 'Spar i andre fond',
        saldo: 'Saldo',
        saldoBankSparing: 'Saldo banksparing',
        solgtFond: 'Solgt fond',
        sparing: 'Sparing',
        spareProfil: 'Spare profil',
        spareProfilIngress:
            'Vi er her for å hjelpe deg med gode sparetips og økonomisk rådgivning. Jo' +
            ' mer vi vet om din økonomiske situasjon, jo bedre råd kan vi gi deg!',
        statsborgerskap: 'Statsborgerskap:',
        sekundaertStatsborgerskap: 'Har du flere statsborgerskap?',
        snakkMedRaadgiverTittel: 'Prat med vår rådgiver for å ordne med fondsparing',
        snakkMedRaadgiverTekst:
            'Det ser ut som vi trenger litt ekstra informasjon før vi kan ' +
            'gjennomføre fondshandelen. Snakk med våre rådgivere for å få assisstanse',
        sjekkSamtykker: 'Sjekker samtykker',
        sammendragAvAvtaleTittel: 'Sammendrag av avtale om bruk av Min Side Sparing',
        sammendragAvAvtaleIngress:
            'Ved å ta i bruk Min Side Sparing får du en samlet oversikt over din sparing og ' +
            'hva du kan forvente å få i pensjon. Løsningen inneholder blant annet:',
        sammendragAvAvtalePunkt1: 'Dine beholdninger i fond/aksjer',
        sammendragAvAvtalePunkt2: 'Mulighet for å kjøpe/selge/bytte fond',
        sammendragAvAvtalePunkt3: 'Din banksparing',
        sammendragAvAvtalePunkt4: 'Din nåværende og tidligere arbeidsgivers pensjonssparing',
        sammendragAvAvtaleArsak: 'Vi har oppdatert brukervilkårene våre, endringene omhandler i sin helhet samtykke om innhenting av pensjonsdataene dine.',
        samleAlleAksjefond: 'Samle alle dine aksjefond og aksjer på ett sted',
        sjekkerOrdre: 'Sjekker ordre',
        sparIFond: 'Spar i fond',
        skrivInnKontonummer: 'Skriv inn kontonummer',
        sjekkerInnlogging: 'Sjekker innlogging',
        jegSamtykkerTilAt: 'Jeg samtykker til at:',
        jegGodtarVilkaarene: 'Jeg godtar vilkårene',
        ja: 'Ja',
        taUtInnskuttBelopUtenAaSkatte: 'Ta ut innskudd beløp uten å skatte',
        total: 'Total',
        totalSaldo: 'Total saldo',
        trekkDato: 'Trekk dato',
        individuellPensjonssparingsKonto: 'Individuell pensjonssparingskonto',
        eikaVerdipapirKonto: 'Eika verdipapirkonto',
        trekkes: 'Trekkes: ',
        trygd: 'Trygd',
        ny: 'Ny',
        viTrengerLittInformasjon: 'Vi trenger litt informasjon...',
        viTrengerLittInformasjonIngress: 'Vi trenger litt informasjon om deg. Vær snill og fyll ut:',
        ikkeValgt: 'Ikke valgt',
        totalSparing: 'Total sparing',
        tilPensjonsKalkulatorKnapp: 'Pensjonskalkulator, se hva du får!',
        trekkDatoInnskudd: 'Trekkdato innskudd',
        tilleggsInformasjon: 'Tilleggsinformasjon',
        tjenestePensjon: 'Tjenestepensjon',
        tilKonto: 'Til konto',
        tilknyttetBankkonto: 'Tilknyttet bankkonto',
        fond: 'Fond',
        fullforerOrdre: 'Fullfører ordre',
        feilmeldingsTekst: 'Vi jobber med å få systemene til å fungere igjen. Prøv igjen senere, eller ring oss. ',
        prestasjonsGrafDisclaimerTittel: 'Prestasjonsgraf disclaimer',
        pensjon: 'Pensjon',
        pengerTattUt: 'Penger tatt ut',
        pensjonsAlder: 'Pensjonsalder',
        pensjonsKapitalBevis: 'Pensjonskapitalbevis',
        paaVegneAvAndreForklaring: 'Forklar hvorfor du handler påvegne av noen andre',
        pensjonskalulatorTittel: 'Pensjonskalkulator',
        privatAFP: 'Privat AFP',
        pengenesOpprinnelse: 'Hva er pengenes opprinnelse?',
        politiskEksponert:
            'Er du, et av dine nære familiemedlemmer, eller en av dine kjente medarbeidere, ' +
            'en politisk eksponert person? En politisk eksponert person er en person som innehar, ' +
            'eller har innehatt, en høytstående stilling eller verv.',
        paaVegneAvAndre: 'Utføres transaksjonen på vegne av andre enn deg selv?',
        risikoProfil: 'Risikoprofil',
        ASK: 'Aksjesparekonto',
        IPS: 'Individuell pensjonsparing',
        IPA: 'Individuell pensjons A',
        GPS: 'Hva gps står for',
        VPK: 'Verdipapirkonto',
        EVPK: 'Eika verdipapirkonto',
        fondDuHarPaaVPS: 'Fond du har i VPS',
        fondIAndreBanker: 'Fond i andre banker',
        fondFraAndreTilbydere: 'Fond fra andre tilbydere',
        velgFondDuVilFlytte: 'Velg fond du vil flytte',
        endrePortefoljeNavn: 'Endre portefoljenavn',
        endreFondsKontoNavn: 'Endre fondskontonavn',
        mineKontoer: 'Mine Kontoer',
        kontoerJegDisponerer: 'Kontoer jeg disponerer',
        endrerPortefoljeNavn: 'Endrer portefoljenavn',
        portefoljenavnetMaaHaMellomEttOgFemtiTegn: 'Portefoljenavnet må ha mellom 1 og 50 tegn',
        risikoProfilLangsiktig: 'Risko på pensjonsparing i fond',
        renteOgKombinasjonsFondTittel: 'Dine rente- og kombinasjonsfond*',
        renteOgKombinasjonsFondFotNote:
            'Rente- og kombinasjonsfond kvalifiserer ikke for flytting til ' +
            'aksjesparekonto og vil bli stående på dine verdipapirkonti',
        folkeTrygd: 'Folketrygd',
        feilmeldingsTittel: 'Ops, noe har gått galt...',
        egenSparingIFond: 'Egen sparing i fond',
        endreKontoNavn: 'Endre kontonavn',
        endreKontoVerdi: 'Endre kontoverdi',
        differanseFraDagensLonn: 'Differanse fra dagens lønn',
        duMaaVelgeKonto: 'Du må velge konto',
        duMaaGodta: 'Du må godta',
        duMaaVelgePortefolje: 'Du må velge portefolje',
        duMaaBekrefteAlle: 'Du må bekrefte alle for å gå videre',
        duMaaVelgeEn: 'Du må velge minst en',
        duMaaVelgeFond: 'Du må velge ett fond',
        duKanTaUtInntil: 'Du kan ta ut inntil {belop} skattefritt.',
        duMaaGodkjenneVilkaareneForOpprettingAvAksjespareKonto:
            'Du må godkjenne vilkårene for å kunne opprette en ny aksjesparekonto',
        duMaaGodkjenneVilkaareneForOpprettingAvIPS: 'Du må godkjenne vilkårene for å kunne opprette en IPS',
        viTrengerInformasjonOmDenDuKjoeperPaaVegneAv: 'Vi trenger informasjon om den du handler fond på vegne av',
        viTrengerInformasjonOmPep: 'Vi trenger informasjon om din relasjon til den politisk eksponert personen',
        viTrengerInformasjonOmPepRelasjon: 'Beskriv relasjon til vedkommende',
        viTrengerInformasjonOmPepVerv: 'Beskriv vervet til vedkommende',
        verdi: 'Verdi',
        verdiAlleFond: 'Verdi alle fond',
        velgKonto: 'Velg konto',
        flyttFond: 'Flytt fond',
        overforerFond: 'Overfører fond',
        fondetBlirFlyttetIlopetAvFaaDager: 'Fondet blir flyttet iløpet av noen dager',
        duHarBestiltFlyttingAv: 'Du har bestilt flytting av:',
        detkanTaOpptilXDagerForDeErFlyttet: 'Det kan ta opptil 10 dager før de er flyttet.',
        FondeneVilBliLagtI:
            'Fondene vil bli lagt på en ny Eika Verdipapirkonto. Derfra kan du ' +
            'flytte de over på Aksjesparekonto eller andre Verdipapirkontoer',
        velgBelop: 'Velg beløp',
        velgFond: 'Velg fond',
        flyttingBestilt: 'Flytting bestilt',
        velgRisiko: 'Velg risiko',
        velgFondDuVilBytteTil: 'Velg fond du vil bytte til',
        velgFondNavn: 'Velg {fond}',
        velgDato: 'Velg dato',
        velgLand: 'Velg land',
        velgPortefolje: 'Velg portefølje',
        vetIkke: 'Vet ikke',
        verdiAksjerOgFond: 'Verdi aksjer og fond',
        visKontoIOversikt: 'Vis konto i oversikt',
        vaareFond: 'Våre fond',
        kontantBeholdning: 'Kontantbeholdning',
        kjopFond: 'Kjøp fond',
        kjopFondTittel: 'Kjøp fond',
        klientKontoInfo: 'Dette er info om klientkonto og hvor pengene dine ender opp når du selger dette fondet',
        omBeregningene: 'Om beregningene',
        dineInnskudd: 'Dine innskudd',
        prestasjonsgrafTapGevinst: 'Tap/Gevinst',
        skrivInnMindreEnnEllerVelgByttHeleBeholdningen:
            'Du kan bare bytte for mindre enn 85% eller velge bytt hele beholdningen',
        kjopFondNavnTittel: 'Kjøp {fond}',
        kjopFondKvitteringsTekst:
            'Du vil kunne se ditt nye fond i din fondsoversikt innen 24 timer.' +
            ' Vilkår og betingelser er sendt til din registrerte e-post adresse',
        kontoNummer: 'Kontonummer',
        kanIkkeFullforeOperasjonenNaa: 'Noe har gått galt. Vi kunne ikke fullføre operasjonen nå.',
        kontoNavnHjelpeTekst: 'Kontonavn må være på mellom 3 og 25 tegn',
        hvorSkalViOverforePenger: 'Hvor skal vi overfore penger',
        herErdineAksjefond: 'Her er dine aksjefond, disse bør du samle på en aksjesparekonto (ASK)',
        hvilketLandSkatterDuTil: 'Hvilket land skatter du til',
        skatterTilAnnetLandEnnNorgeWarning:
            'På grunn av at du skatter til et annet land må du kontakte banken for kundeopprettelse.',
        hvorMyeVilDuTaUt: 'Hvor mye vil du ta ut?',
        harForstattNokkelInformasjon: 'Jeg har lest og forstått nøkkelinformasjon for valgt fond',
        harBlittOverfortTilDinKonto: 'Kroner {belop},- har blitt overført til din {kontonavn}: {kontonummer}',
        vilkaarTittel: 'Vilkår',
        bareDelerAvBestillingenGikkGjennom: 'Det var bare deler av bestillingen din som gikk gjennom',
        noeGikkGalt: 'Vi kunne ikke gjennomføre ordren din',
        FOND_SELG_UOPPGJORTE_TRANSAKSJONER: 'Uoppgjorte transaksjoner',
        FOND_SELG_UOPPGJORTE_TRANSAKSJONER_MELDING: 'Det er ikke mulig å selge et fond med uoppgjorte transaksjoner. Vennligst prøv på nytt senere.',
        FOND_BYTTE_UOPPGJORTE_TRANSAKSJONER: 'Uoppgjorte transaksjoner',
        FOND_BYTTE_UOPPGJORTE_TRANSAKSJONER_MELDING: 'Det er ikke mulig å bytte et fond med uoppgjorte transaksjoner. Vennligst prøv på nytt senere.',
        FOND_BYTTE_MINDRE_ENN_MINIMUMSBELOP: 'FOND_BYTTE_MINDRE_ENN_MINIMUMSBELOP',
        FOND_KJOP_MINDRE_ENN_MINIMUMSBELOP: 'FOND_KJOP_MINDRE_ENN_MINIMUMSBELOP',
        FOND_BYTTE_OVER_85_PROSENT: 'FOND_BYTTE_OVER_85_PROSENT',
        KUNDE_IKKE_EIER_AV_KONTO: 'KUNDE_IKKE_EIER_AV_KONTO',
        FOND_PERIODISK_MINDRE_ENN_MINIMUMSBELOP: 'FOND_PERIODISK_MINDRE_ENN_MINIMUMSBELOP',
        TRADEX_UKJENT_FEIL: 'Ukjent feil',
        TRADEX_UKJENT_FEIL_MELDING: 'Ops, noe har gått galt.',
        SPAREPLAN_TRADEX_UKJENT_FEIL: 'SPAREPLAN_TRADEX_UKJENT_FEIL',
        BYTT_GJENNOMFORT: 'BYTT_GJENNOMFORT',
        KJOP_GJENNOMFORT: 'KJOP_GJENNOMFORT',
        SALG_AV_HELE_BEHOLDNING: 'SALG_AV_HELE_BEHOLDNING',
        SALG_AV_HELE_BEHOLDNING_OG_SPAREPLAN_GJENNOMFORT: 'SALG_AV_HELE_BEHOLDNING_OG_SPAREPLAN_GJENNOMFORT',
        SALG_OG_AVSLUTT_SPAREPLAN_GJENNOMFORT: 'SALG_OG_AVSLUTT_SPAREPLAN_GJENNOMFORT',
        ENDRE_SPAREPLAN_GJENNOMFORT: 'ENDRE_SPAREPLAN_GJENNOMFORT',
        OPPRETT_SPAREPLAN_GJENNOMFORT: 'OPPRETT_SPAREPLAN_GJENNOMFORT',
        KJOP_OG_OPPRETT_SPAREPLAN_GJENNOMFORT: 'KJOP_OG_OPPRETT_SPAREPLAN_GJENNOMFORT',
        KJOP_OG_ENDRET_SPAREPLAN_GJENNOMFORT: 'KJOP_OG_ENDRET_SPAREPLAN_GJENNOMFORT',
        UTTAK_FRA_ASK_GJENNOMFORT: 'UTTAK_FRA_ASK_GJENNOMFORT',
        ENKELT_ORDRE_GJENNOMFORT: 'ENKELT_ORDRE_GJENNOMFORT',
        SPAREPLAN_ENDRET: 'SPAREPLAN_ENDRET',
        SALG_GJENNOMFORT: 'SALG_GJENNOMFORT',
        test1: 'test1',
        test2: 'test2',
        test3: 'test3',
        samleAlleFondeneDineHer: 'Samle alle fondene dine her',
        aksjerOgEgenkapitalbevis: 'Aksjer og Egenkapitalbevis',
        lasterSignering: 'Laster signering',
        oppretterSalgsOrdre: 'Oppretter salgsordre',
        KUNNE_IKKE_FULLFORE_KJOP: 'KUNNE_IKKE_FULLFORE_KJOP',
        KUNNE_IKKE_OPPRETTE_SIGNERING: 'Kunne ikke opprette signeringsdokument',
        FERDIG_SIGNERT: 'FERDIG_SIGNERT',
        KUNNE_IKKE_OPPRETTE_ORDRE: 'KUNNE_IKKE_OPPRETTE_ORDRE',
        KAN_IKKE_SELGE_FOND_UTEN_BEHOLDNING: 'KAN_IKKE_SELGE_FOND_UTEN_BEHOLDNING',
        jan: 'januar',
        feb: 'februar',
        mar: 'mars',
        apr: 'april',
        mai: 'mai',
        jun: 'juni',
        jul: 'juli',
        aug: 'august',
        sep: 'september',
        okt: 'oktober',
        nov: 'november',
        des: 'desember',
        avsluttMndSpareAvtaleKvitteringsTittel: 'Spareavtalen er avsluttet',
        duMaaSkriveInnEnSum: 'Skriv inn en sum høyere enn 0',
        spareAvtaleAvsluttet: 'Din spareavtale er avsluttet',
        huskAtDetUtlosesSkatt:
            'Husk at det utløses skatt om uttaket fra aksjesparekonto er høyere enn ' +
            'innskutt beløp tillagt eventuell skjerming',
        oppretterUttaksOrdre: 'Oppretter uttaksordre',
        gjorUttak: 'Gjør uttak',
        verifisererOrdre: 'Sjekker ordre',
        KUNNE_IKKE_FULLFORE_ORDRE: 'KUNNE_IKKE_FULLFORE_ORDRE',
        uttakFra: 'Uttak fra {portefolje}',
        utbetaling: 'Utbetaling',
        omPensjonsordningen: 'Om pensjonsordningen',
        forsikringsDekning: 'Forsikringsdekninger',
        dato: 'Dato',
        sum: 'Sum',
        innskuddsfritak: 'Innskuddsfritak',
        uforepensjon: 'Uførepensjon',
        aarlingSparing: 'Årlig sparing',
        lasterSisteTransaksjoner: 'Laster siste transaksjoner',
        lasterVentendeTransaksjoner: 'Laster ventende transaksjoner',
        ventendeTransaksjoner: 'Ventende transaksjoner',
        sisteTransaksjoner: 'Siste transaksjoner',
        transaksjoner: 'Transaksjoner',
        stillingsprosent: 'Stillingsprosent',
        investeringsValg: 'Investeringsvalg',
        utbetalingsstart: 'Utbetalingsvalg',
        utbetalingsOpphorer: 'Utbetaling opphører',
        forventetAarligUtbetaling: 'Forventet årlig utbetaling',
        sendtTilDanica: 'Du vil bli sendt til Danica sine nettsider der du kan bytte risikoprofil.',
        forventetInnbetaling: 'Dette gir en forventet årlig innbetaling på {innbetaling}',
        grunnbelopOgGrunnbelop: '{start} og {slutt}',
        dinSpareplan: 'Din spareplan',
        avLonnMellom: 'av lønn mellom',
        omPensjonsAvtalenDin: 'Om pensjonsavtalen din',
        dinValgteRisiko: 'Din valgte risiko',
        naarDuSelgerFondIAksjesparekontoen:
            'Når du selger fond i aksjesparekontoen, kan du bruke pengene til å ' +
            'kjøpe andre fond eller ta ut innskudd skattefritt. Det er kun gevinst som beskattes. Du kan ta ut {belop}',
        klientKontoDuKanTaUtInntil:
            'Du kan ta ut inntil {belop} skattefritt. For å få tilgang til beløpet så ' +
            'må du selge fond slik at pengene blir tilgjengelig på denne tilknyttede bankkontoen.',
        skattemessigKostprisFeilet: 'Vi klarte ikke hente ditt skattemessige kostbelop',
        klientKontoDuKanTaUtInntilFeilet:
            'Du kan ta ut opp til ett visst beløp før du må betale skatt. Desverre' +
            ' klarte vi ikke hente ut dette beløpet nå. Prøv igjen senere eller kontakt din rådgiver om du er usikker',
        dinValgteRisikoProfil: 'Din valgte risikoprofil',
        omPensjonsavtalenDin: 'Om pensjonsavtalen din',
        beskrivelse_EIKA_FORSIKTIG:
            'Jeg er opptatt av trygghet, men jeg ønsker noe høyere ' +
            'avkastning enn jeg kan få på bankkonto. Jeg aksepterer at verdien på sparepengene ' +
            'kan svinge litt i verdi underveis i spareperioden.',
        beskrivelse_EIKA_MODERAT:
            'Jeg vil ha en kombinasjon av trygghet og avkastning. ' +
            'Jeg aksepterer derfor at verdien på sparepengene svinger noe mer underveis i spareperioden. ',
        beskrivelse_EIKA_OFFENSIV:
            'Jeg er opptatt av høyest mulig avkastning på sparepengene mine. ' +
            'Jeg aksepterer at verdien på sparepengene kan svinge mye underveis i spareperioden.',
        beskrivelse_EIKA_100:
            'Jeg er opptatt av mest mulig avkastning på sparepengene mine. ' +
            'Jeg aksepterer at verdien på sparepengene kan svinge mye underveis i perioden.',
        EIKA_FORSIKTIG: 'Lav risiko',
        EIKA_MODERAT: 'Moderat risiko',
        EIKA_OFFENSIV: 'Høy risiko',
        EIKA_100: 'Eika 100% aksjer',
        medValgtRisiko: 'Med valgt risiko er det sannsynlig med svingninger fra {low} til {high}',
        estimertVerdiNaarDuEr: 'Estimert verdi når du er {alder} år: {verdi}',
        idag: 'Idag',
        henterKundeinformasjon: 'Henter kundeinformasjon',
        aar: 'år',
        hoyAvkastning: 'HØY avkastning',
        lavAvkastning: 'LAV avkastning',
        forventetAvkastning: 'FORVENTET avkastning',
        sisteInnskudd: 'Siste innskudd',
        dinEksisterendeRisikoprofil: 'Din eksisterende risikoprofil',
        aapenProfil: 'Åpen profil',
        avtaleInfoAapenProfil:
            'Du har valgt en risikoprofil vi ikke kan beregne prognose for. Bruk grafen til å beregne ' +
            'blant de forhåndsdefinerte profilene under.',
        nyttSparemaal: 'Nytt sparemål',
        navnPaaSparemaalet: 'Navn på sparemålet',
        hvorMyeVilDuSpare: 'Hvor mye har du som mål å spare?',
        oppdaterSparemaal: 'Oppdater sparemål',
        viAnbefalerEikaPengemarkedHeader: 'Vi anbefaler Eika Spar.',
        viAnbefalerEikaPengemarked1:
            'Eika Spar er et bredt norsk/internasjonalt aksjefond med fokus på store, etablerte selskaper',
        viAnbefalerEikaPengemarked2:
            'Dette fondet passer spesielt for deg som vil spre risikoen over flere regioner, da vi i ' +
            'dette fondet har tatt inn våre favorittaskjer fra våre norske, nordiske og globale fond.',
        viAnbefalerEikaPengemarked3:
            'Dersom du har forbrukslån, anbefaler vi at du prioriterer å betale ned på de første',
        estimeringsgrafLegendForventet: 'Forventet',
        estimeringsgrafLegendSvingninger: 'Svingninger fra {fraBelop} til {tilBelop}',
        OmXaarKanDetteBli: 'Om {aar} år kan dette bli',
        infotekstBsuOgForbrukslaan:
            'Har du kredittkortgjeld eller annen dyr forbruksgjeld anbefaler vi at du nedbetaler dette ' +
            'før du sparer i fond. Er du under 34 år kan det lønne seg å fylle opp BSU kontoen før du sparer i fond. Da ' +
            'får du skattefradrag og vår beste rente.',
        oppdater: 'Oppdater',
        opprettNy: 'Opprett ny',
        vilkaar: 'Brukeravtal teksten er her',
        sparemaalTittel: 'Sparemål',
        dineSparemaalTittel: 'Dine sparemål',
        sparemaalNavn: 'Navn',
        sparemaalHvorMyeVilDuSpare: 'Hvor mye vil du spare?',
        sparemaalVetIkke: 'Vet ikke',
        sparemaalOversiktVetIkke: 'Vet ikke',
        sparemaalType: 'Type',
        sparemaalSparingIAntallAar: 'Jeg ønsker å spare i {antall} år',
        sparemaalOpprettSparemaal: 'Opprett sparemål',
        sparemaalRedigerSparemaal: 'Rediger sparemål',
        sparemaalVelgRisiko: 'Velg risiko',
        sparemaalSparebelopIMnd: 'Hvor mye vil du spare i måneden?',
        sparemaalSparebelopIMndMinimum: '(Minimum {belop} kr)',
        sparemaalInnskudd: 'Innskudd',
        sparemaalSparemiks: 'Sparemiks',
        sparemaalMaal: 'Mål',
        sparemaalSparemiksVelgFondTittel: 'Velg fond',
        sparemaalSparemiksVelgFondIngress: 'Her kan du øremerke beholdning fra dine fond til hvert enkelt sparemål',
        sparemaalSparemiksVelgSparekontoerTittel: 'Velg sparekontoer',
        sparemaalSparemiksVelgSparekontoerIngress:
            'Her kan du øremerke beholdning fra dine konti til hvert enkelt sparemål',
        sparemaalSparemiksVelgSpareavtalerTittel: 'Velg spareavtaler',
        sparemaalSparemiksVelgSpareavtalerIngress: 'Her kan du øremerke dine spareavtaler til hvert sparemål',
        sparemaalSparemiksHeleFondetOremerket: 'Hele fondet er øremerket for',
        sparemaalSparemiksFondOremerketTil: '{sum} er øremerket for',
        sparemaalSparemiksKontoOremerketTil: 'Denne kontoen er øremerket for',
        sparemaalSparemiksOremerkHeleKontoen: 'Øremerk hele kontoen',
        sparemaalSparemiksSpareavtaleOremerketTil: 'Denne spareavtalen er øremerket for',
        sparemaalSparemiksOremerket: 'Øremerket',
        sparemaalSparemiksInkluderSpareavtale: 'Inkluder spareavtale',
        sparemaalSparemiksPerMnd: 'pr. mnd',
        sparemaalVarighet: 'Varighet: {aar} år',
        appLaster: 'Laster inn applikasjon',
        smartsparPopupTittel:
            'Nyhet! Appen Smartspar gjør det enkelt å spare og gi fond i gave. Last ned Smartspar nå.',
        smartsparPunktEn: 'Gi barna fond i gave – en gave som vokser med barnet',
        smartsparPunktTo: 'Lag dine egne sparemål og få full oversikt over all din sparing',
        smartsparPunktTre: 'Ingen forkunnskaper nødvendig – vi hjelper deg å velge fond',
        smartsparLenke: 'Les mer om Smartspar!',
        seTransaksjoner: 'Se transaksjoner',
        paaveiTilFondetDitt: '{antall} belop er på vei til fondet ditt',
        paaveiUtAvFondetDitt: '{antall} belop er på vei ut av fondet ditt',
        ordreVenterPaaOgBliUtfort: '{antall} ordre venter på å bli utført',
        KJOP: 'Enkelt kjøp',
        SALG: 'Salg',
        KJOP_BYTT: 'Byttet fond',
        SALG_BYTT: 'Byttet fond',
        ingenTransaksjoner: 'Du har ingen transaksjoner',
        under18aar: 'Man må være 18 år for å benytte denne tjenesten',
        infotekstVerdiFond:
            'Verdien som vises er basert på fondets siste kjente kurs. Den vil normalt være to dager gammel.',
        infotekstSalgFond:
            'Husk at verdien du ser her er basert på fondets siste kurs, som normalt er to dager gammel.' +
            ' Verdien ved salg kan både bli høyere eller lavere enn denne verdien basert på markedsutviklingen.',
        overskredetAntallKallHeader: 'Kalkulatoren trenger en pause',
        overskredetAntallKallIngress:
            'Oppslaget mot Norsk Pensjon koster penger for hver gang du regner på nytt, ' +
            'derfor har vi dessverre\n måttet legge på en begrensning på hvor mange ganger du kan regne ut ditt ' +
            'pensjonstall. Du kan beregne pensjonstallet ditt 10 ganger per dag. Dersom du beregner samme kombinasjon ' +
            'to ganger så vil dette ikke telle inn på begrensningen.',
        overskredetAntallKallKnapp: 'OK',
        ma_behandles_manuelt_link: 'Kontakt din rådgiver',
        ma_behandles_manuelt_tittel: 'Hei, kundekontrollen sier at du må kontakte banken din',
        ma_behandles_manuelt_tekst: 'Vennligst ta kontakt med din rådgiver for å komme videre med sparingen din. Den lovpålagte kundekontrollen er en del av myndighetenes tiltak for å forhindre hvitvasking av penger.',

        fondVisningMoningStarInfo: 'Mer om dette fondet på Morningstar',
        sparekalkulator: 'Sparekalkulator',

        kundevurderingMaBehandlesManueltTitle: 'Hei, vennligst kontakt banken din for å komme videre',
        kundevurderingMaBehandlesManueltText: 'Den lovpålagte kundekontrollen er en del av myndighetenes tiltak for å hindre hvitvasking av penger. Din kundekontroll viser at du må kontakte banken din før du kan fortsette.',
        fullSperretKonto: 'Kontoen er sperret',
        salgSperretKonto: 'Kontoen er sperret for salg og uttak',
        fullSperretKontoTitle: 'Hei, denne kontoen er sperret',
        fullSperretKontoText: 'Kontakt din rådgiver for mer informasjon.',
        salgSperretKontoTitle: 'Hei, denne kontoen er sperret for salg og uttak',
        salgSperretKontoText: 'Kontakt din rådgiver for mer informasjon.',
        salgForstaelse: 'Jeg forstår at salg av fond kan utløse skatt ved gevinst.',
        salgForstaelseError: 'Vennligst bekreft at du har forstått',        

        automatiskOkingSparing: 'Legg til automatisk økning',
        automatiskOkingSparingBeskrivelse: 'Øk sparebeløpet i fremtiden. Velg selv hvor mye.',
        automatiskOkingSparingHyppighet: 'Hyppighet',
        aisOkingProsent: 'Øk prosent',
        aisOkingKroner: 'Øk krone',

        AIS_VALUE_REQUIRED: 'Vennligst legg inn automatisk øke verdi',
        AIS_VALUE_INVALID: 'Verdien er ugyldig',
        AIS_VALUE_TOO_LOW: 'Verdien er for lav. Det må minst være {faktor}',
        AIS_VALUE_TOO_HIGH: 'Verdien er for høyt',
        arbeidsgiverspensjon: 'Arbeidsgivers pensjon',
        totalVerdiMangelMelding: 'Vi beklager, grunnet problemer hos en underleverandør mangler fondsverdier. Vennligst prøv igjen litt senere.',
        zeroPensionFromPublicSectorMessage: 'Vi kan se at du har offentlig tjenestepensjon, men har dessverre ikke tilgang til detaljer om oppspart pensjon for kommunalt/statlig ansatte.',
        customerHasDanicaCustomRiskProfile: 'Du har en egendefinert risikoprofil hos Danica, gå til Danica for å se detaljer om denne.',
        pensionCalculatorMessage: 'På grunn av manglende data fra Norsk Pensjon, kan utregningen for øyeblikket være mangelfull.',
        marketingDisclaimer: 'Innholdet på denne siden er markedsføring',

        kjopFondVeiviserSkjemaTittel: 'Fondsveileder',
        faaAnbefaling: 'Basert på dine svar kan vi gi deg en generell anbefaling på hvilket risikonivå som kan passe. I eksempelet bruker vi Eika-fond. ',
        naarTrengerDuSparepengene: 'Hvor lenge vil du spare?',
        'om0-2aar': '0-2 år',
        'om2-5aar': '2-5 år',
        'omMer5aar': '5+ år',
        hvaErMestViktigForDeg: 'Hva er viktigst for deg?',
        mestMuligVerdiOkning: 'Mest mulig verdiøkning',
        enKombinasjon: 'En kombinasjon',
        stabilitet: 'Stabilitet',
        viAnbefalerEikaBalansertHeader: 'Vi anbefaler Eika Balansert',

        faaFundVeilder: 'Få hjelp til å velge fond',
        fondListeTittel: 'Velg fond',
        viAnbefaler: 'Vi anbefaler {fond}.',
        faaAnbefalingGenerell: 'Dette er en generell anbefaling for sparing i Eika-fond. Anbefalingen må ikke forstås som personlig investeringsrådgiving. Ønsker du personlig rådgivning om sparing, <a href="{contactUs}" class="link font-size-regular"> kan du ta kontakt med oss for personlig rådgiving.</a>',
        anbefaltFondInfo: 'Anbefaling over skal illustrere anbefalt aktiva allokering for sparing i verdipapirfond forvaltet av Eika Kapitalforvaltning. Det vil si hvordan man bør fordele sparepengene sine i aksjefond og/eller rentefond. Anbefalingen er basert det som er opplyst i forhold til hvor stor risiko man tåler og hvor lenge man skal spare. Denne anbefalingen må ikke forstås som investeringsrådgivning. Ønsker du personlige rådgivning ifm sparing i våre verdipapirfond, må du ta kontakt med en av våre dyktige investeringsrådgivere i din lokale Eika-bank',
        anbefaltFondNotat: 'Historisk avkastning er ingen garanti for framtidig avkastning. Framtidig avkastning vil bl.a. avhenge av markedsutviklingen, forvalters dyktighet, verdipapirfondets risiko, samt kostnader ved tegning, forvaltning og innløsning. Avkastningen kan bli negativ som følge av kurstap.',
        foreignCurrencyHasToSellTotalValue: 'Du kan kun ta ut hele beløpet i fond med utenlandsk valuta selv. Dersom du ønsker å ta ut deler av beløpet, kan du kontakte Eika Kapitalforvaltning for hjelp.',
        foreignCurrency: 'Utenlandsk valuta',
        foreignCurrencyInfo: 'Fondet {fundName} er i utenlandsk valuta. Dersom du senere vil selge deler av et fond i utenlandsk valuta, kan du kontakte oss så hjelper vi deg. Dersom du vil ta ut hele fondet, kan du gjøre det selv.',
        preventFundSellMissingMorningstarCurrency: 'Grunnet manglende opplysninger fra Morningstar er det ikke mulig å selge dette fondet nå.',
        tryAgainOrContact: 'Prøv igjen senere, eller kontakt Eika Kapitalforvaltning',
        esg: 'Bærekraftsvurdering',
        esgRangeDescription: 'Alle fond i fondslisten som publiseres på nettsiden vår har fått en bærekraftsscore av <a class="link" href="https://www.morningstar.com/" target="blank">Morningstar</a>. Denne bærekraftsscoren er basert på hvordan de underliggende selskapene fondene har investert i  påvirker miljømessige forhold, (f.eks. co2-utslipp, bærekraftig bruk av ressurser etc.), sosiale forhold, (f.eks. arbeidsforhold, menneskerettigheter etc.) og selskapsstyring (f.eks. hvordan det sikres at eiere og ledelse forvalter ressurser på best mulig måte). Vi benytter denne Bærekraftsscoren for rangering av fondene på fondslisten vår, der flere eikespirer betyr bedre score på bærekraft.',
        esgRangeTextLabel: 'Rangeringen gjøres etter følgende kriterier:',
        esgRangeText1: 'Fond som er blant fondene på fondslisten vår, som er de med 10 % dårligst bærekraftsscore, får 1 eikespire.',
        esgRangeText2: 'Fondene blant de neste 22,5 % på fondslisten vår, får 2 eikespirer.',
        esgRangeText3: 'Fondene blant de neste 35 % på fondslisten vår, får 3 eikespirer.',
        esgRangeText4: 'Fondene blant de neste 22,5 % på fondslisten vår, får 4 eikespirer.',
        esgRangeText5: 'Fondene blant de 10 % med best bærekraftsscore på fondslisten vår, får 5 eikespirer.',

    }
};
export default Dictionary;
