import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import { settSideTittel } from '../../actions/header';
import { endrePensjonsValg } from '../../actions/spareprofil';
import Feilmelding from '../../components/feilmelding';
import NedTrekksMeny from '../../components/ned-trekks-meny';
import ValgKnapper from '../../components/valg-knapper';
import HelperFunctions from '../../components/helper-functions';
import RadioButtons from '../../components/radio-buttons';
import NumberInput from '../../components/common/number-input';

require('../../styles/spare-profil.scss');

class Vilkaar extends Component {
    static propTypes = {
        texts: PropTypes.object,
        pensjonsvalg: PropTypes.object,
        endrePensjonsValg: PropTypes.func,
        config: PropTypes.object,
        errors: PropTypes.object,
        settSideTittel: PropTypes.func,
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const state = {
            knappeValg: [
                { tekst: props.texts.get('ja'), id: 1 },
                { tekst: props.texts.get('nei'), id: 0 },
                { tekst: props.texts.get('vetIkke'), id: 2 },
            ],
            risikoValg: [
                { tekst: props.texts.get('hoy'), id: 2 },
                { tekst: props.texts.get('middels'), id: 1 },
                { tekst: props.texts.get('lav'), id: 0 },
            ],
            ipsRisikoprofil: [
                {
                    navn: this.props.texts.get('hoyRisiko'),
                    id: '2',
                    tekst: this.props.texts.get('hoyRisikoBeskrivelse'),
                },
                {
                    navn: this.props.texts.get('middelsRisiko'),
                    id: '1',
                    tekst: this.props.texts.get('middelsRisikoBeskrivelse'),
                },
                {
                    navn: this.props.texts.get('lavRisiko'),
                    id: '0',
                    tekst: this.props.texts.get('lavRisikoBeskrivelse'),
                },
            ],
        };

        if (props.pensjonsvalg) {
            this.state = {
                ...state,
                pensjonsvalg: this.initDefaultValues(props.pensjonsvalg),
                ...this.initFormData(props),
            };
        } else {
            this.state = state;
        }
    }

    UNSAFE_componentWillMount = () => this.props.settSideTittel(this.props.texts.get('minPensjonTittel'));

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.config && newProps.pensjonsvalg) {
            this.setState({
                ...this.initFormData(newProps),
                pensjonsvalg: this.initDefaultValues(newProps.pensjonsvalg),
            });
        }
    };

    initDefaultValues = (pensjonsvalg) => ({
        bruttoInntekt: pensjonsvalg.bruttoInntekt || 4000000,
        pensjonsAlder: pensjonsvalg.pensjonsAlder || 67,
        harAFP: pensjonsvalg.harAFP || 0,
        rettPaaOffentligTjenestePensjon: pensjonsvalg.rettPaaOffentligTjenestePensjon || 0,
        risikoprofil: pensjonsvalg.risikoprofil || 1,
        ipsRisikoprofil: pensjonsvalg.ipsRisikoprofil || 1,
        ipsUttaksAlder: pensjonsvalg.ipsUttaksAlder || 67,
    });

    initFormData = (props) => ({
        pensjonsAldre: this.createPensionAgeInterval(props.config),
        ipsUttaksAldre: this.createPensionAgeInterval(props.config),
    });

    createPensionAgeInterval = (config) =>
        HelperFunctions.genererListeMedTall(config.minimumsAlder, config.maximumsAlder);

    endrePensjonsValg = (endring, validering) => {
        const { pensjonsvalg } = this.state;
        if (validering) {
            if (validering(endring)) {
                this.setState({
                    pensjonsvalg: {
                        ...pensjonsvalg,
                        ...endring,
                    },
                });
            }
        } else {
            // if no validation sent, do no validation
            this.setState({
                pensjonsvalg: {
                    ...pensjonsvalg,
                    ...endring,
                },
            });
        }
    };

    sjekkForServerFeil = (errors) => errors.LAST_SPARE_PROFIL_DATA;

    mapRisikoProfilTilRisikoValg = (risikoProfil, risikoValg) => risikoProfil.find((r) => r.id === risikoValg).tekst;

    visIpsProfil = () => {
        if (this.state.ipsUttaksAldre) {
            return (
                <div className="ips-profil">
                    <div className="ips-spare-profil-tittel">{this.props.texts.get('ipsProfil')}</div>
                    <div className="ips-spare-profil">
                        <div className="min-pensjon">
                            <div className="spareprofil-seksjon">
                                <div className="spare-profil-dropdown">
                                    <NedTrekksMeny
                                        id="ipsUttaksAlder"
                                        label={this.props.texts.get('ipsUttaksAlder')}
                                        options={this.state.ipsUttaksAldre}
                                        defaultValue={this.state.pensjonsvalg.ipsUttaksAlder}
                                        onChange={(ipsUttaksAlder) => this.endrePensjonsValg({ ipsUttaksAlder })}
                                    />
                                </div>
                                <RadioButtons
                                    items={this.state.ipsRisikoprofil}
                                    onChange={(risikoValg) =>
                                        this.endrePensjonsValg({ ipsRisikoprofil: risikoValg.id })
                                    }
                                    defaultValue={this.state.pensjonsvalg.ipsRisikoprofil.toString()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };

    oppdaterPensjonsValg = () => {
        this.props.endrePensjonsValg(this.state.pensjonsvalg);
        this.props.history.goBack();
    };

    avbryt = () => {
        this.props.history.goBack();
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.pensjonsvalg && this.props.config) {
            const { config } = this.props;

            return (
                <div className="spare-profil">
                    <div>
                        <div className="min-pensjon">
                            <div className="spareprofil-seksjon">
                                <div className="spare-profil-dropdown">
                                    <NedTrekksMeny
                                        id="pensjonsAlder"
                                        label={this.props.texts.get('pensjonsAlder')}
                                        options={this.state.pensjonsAldre}
                                        defaultValue={this.state.pensjonsvalg.pensjonsAlder}
                                        onChange={(pensjonsAlder) => this.endrePensjonsValg({ pensjonsAlder })}
                                        disabled={config.minimumsAlder > config.maximumsAlder}
                                    />
                                </div>

                                <div className="brutto-aarsinntekt">
                                    <NumberInput
                                        id="bruttoAarsinntektIAar"
                                        label={this.props.texts.get('bruttoAarsinntektIAar')}
                                        value={this.state.pensjonsvalg.bruttoInntekt}
                                        onChange={(event) =>
                                            this.endrePensjonsValg({
                                                bruttoInntekt: HelperFunctions.clamp(event.target.value, 0, 9999999),
                                            })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="spareprofil-seksjon">
                                <ValgKnapper
                                    tittel={this.props.texts.get('harDuAFP')}
                                    valgMuligheter={this.state.knappeValg}
                                    valg={this.state.pensjonsvalg.harAFP}
                                    endreValg={(harAFP) => {
                                        this.endrePensjonsValg({ harAFP });
                                    }}
                                />
                            </div>

                            <div className="spareprofil-seksjon">
                                <ValgKnapper
                                    tittel={this.props.texts.get('harDuRettPaaOffentligTjenestePensjon')}
                                    valgMuligheter={this.state.knappeValg}
                                    valg={this.state.pensjonsvalg.rettPaaOffentligTjenestePensjon}
                                    endreValg={(rettPaaOffentligTjenestePensjon) => {
                                        this.endrePensjonsValg({ rettPaaOffentligTjenestePensjon });
                                    }}
                                />
                            </div>

                            <div className="risiko-dropdown spareprofil-seksjon">
                                <NedTrekksMeny
                                    id="risikoprofil"
                                    label={this.props.texts.get('risikoProfilLangsiktig')}
                                    options={this.state.risikoValg}
                                    onChange={(risikoprofil) => this.endrePensjonsValg({ risikoprofil })}
                                    defaultValue={this.mapRisikoProfilTilRisikoValg(
                                        this.state.risikoValg,
                                        this.state.pensjonsvalg.risikoprofil
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {this.visIpsProfil()}

                    <div className="spareprofil-footer">
                        <button type="button" className="btn btn-flat" onClick={this.avbryt}>
                            {this.props.texts.get('avbryt')}
                        </button>

                        <button type="button" className="btn btn-primary" onClick={this.oppdaterPensjonsValg}>
                            {this.props.texts.get('endre')}
                        </button>
                    </div>
                </div>
            );
        }
        return <Loading title={this.props.texts.get('lasterDinSpareProfil')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.SpareProfil,
            ...state.SpareProfil.config,
            ...state.Feilmeldinger,
            ...state.Texts,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            endrePensjonsValg: (endring) => {
                dispatch(endrePensjonsValg(endring));
            },
        };
    }
)(Vilkaar);
