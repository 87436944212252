import { get } from "@eika-infrastruktur/request";
import { processResponse } from './common';
import { postLog } from './app';

export const SET_STOREBRAND_SAVING_PROFILE_ENABLED = 'SET_STOREBRAND_SAVING_PROFILE_ENABLED';

const storebrandProfilesFeatureUrl =
    '/sparing-min-side-web/rest/resource/features/sparing-min-side-web-storebrand-spareprofil-enabled';

export const getStorebrandProfilesEnabled = () => (dispatch) => {
    return get(storebrandProfilesFeatureUrl)
        .then((data) => {
            return processResponse(SET_STOREBRAND_SAVING_PROFILE_ENABLED, data, dispatch);
        })
        .catch((error) => {
            dispatch(postLog(error, { storebrandProfilesFeatureUrl, function: 'getStorebrandProfilesEnabled' }));
        });
};

const initialState = {
    storebrandProfilesEnabled: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_STOREBRAND_SAVING_PROFILE_ENABLED: {
            return {
                ...state,
                storebrandProfilesEnabled: action.data,
            };
        }
        default:
            return state;
    }
};
