import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Fund } from 'src/domain/Fund';
import { useLocation } from 'react-router-dom';
import FundDetail from '../FundDetail/FundDetail';
import { FooterButton } from '../share/ShareComponents';
import FundListCard from './FundListCard';

import './FundList.scss';
import useMutualFundStore from '../../stores/MutualFundStore';
import { filterOnetimePeriodic } from '../util/FondFunctions';

interface FundListProps {
    result: Fund[][];
    selected: Fund;
    fundDetailUrlPath?: string;
    useFundText?: string;
    click: (data: Fund) => void;
}

const FundItem: React.FC<{
    data: Fund;
    selected: Fund;
    fundDetailUrlPath?: string;
    useFundText?: string;
    click: (e: React.MouseEvent | null, key: string, data: Fund) => void;
    removeBFundsFromList?: boolean;
}> = ({ data, selected, fundDetailUrlPath, useFundText, click, removeBFundsFromList }) => {
    // check if fund is a B+ fund
    if (removeBFundsFromList && data.mutualFundClassReference && data.mutualFundClassReference.isin !== data.isin) {
        return <></>;
    }

    return (
        <li
            key={data.id}
            className={classNames('fund-item', { '-selected': selected.id === data.id })}
            role="option"
            aria-selected={selected.id === data.id}
        >
            <FundListCard data={data} click={(e, k, d) => click(e, k, d)} />
            {selected.id === data.id && !fundDetailUrlPath && (
                <FundDetail data={data} appTexts="">
                    <FooterButton click={(e) => click(e, 'use-selected-fund', data)} next={useFundText ?? 'Bruk'} />
                </FundDetail>
            )}
        </li>
    );
};
const FundList: React.FC<FundListProps> = ({ result, selected, fundDetailUrlPath, useFundText, click }) => {
    const { search } = useLocation();
    const filterCallback = useCallback((fund: Fund) => filterOnetimePeriodic(fund, search), [search]);
    const { setEntry, paymentType } = useMutualFundStore();

    const removeBFundsFromList = () => {
        if (paymentType === 'MONTHLY') {
            return true;
        }
        return false;
    };

    const clickHandler = (data: Fund) => {
        setEntry('FUNDLIST');
        click(data);
    };

    if (!result || result.length === 0) {
        return <p>Ingen fond funnet.</p>;
    }
    return (
        <section className="result-wrapper" aria-label="Liste over fond">
            {result.map((list, i) => (
                <ul
                    className={`flat -${!list[0]?.externalFund ? 'is-eika' : 'is-other'}`}
                    key={`info-list-${i}`}
                    role="listbox"
                >
                    <div className="list-header">
                        {!list[0].externalFund ? (
                            <h3 className="header-el-length-2"> Eika fond</h3>
                        ) : (
                            <h3 className="header-el-length-2">Fond fra andre Fondsforvaltere</h3>
                        )}
                        <div className="header-el-length-1">Etisk</div>
                        <div className="header-el-length-3">
                            <div>Årlig kostnad</div>
                            <div>Minstebeløp</div>
                            <div>Årlig avkastning</div>
                            <div />
                        </div>
                    </div>
                    {list.filter(filterCallback).map((data) => {
                        return (
                            <FundItem
                                key={data.id}
                                data={data}
                                selected={selected}
                                fundDetailUrlPath={fundDetailUrlPath}
                                useFundText={useFundText}
                                click={() => clickHandler(data)}
                                removeBFundsFromList={removeBFundsFromList()}
                            />
                        );
                    })}
                </ul>
            ))}
        </section>
    );
};

export default FundList;
