/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';

import { settSideTittel } from '../../actions/header';
import { getSparemaal } from '../../actions/sparemaal';
import Format from '../../components/format-functions';

import Feilmelding from '../../components/feilmelding';
import LinkCard from '../../components/link-card';
import ActionButton from '../../components/action-button';
import SparemaalIkoner from '../../components/sparemaal/sparemaal-ikoner';

require('../../styles/sparemaal.scss');

class OversiktSparemaal extends Component {
    static propTypes = {
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        getSparemaal: PropTypes.func,
        errors: PropTypes.object,
        sparemaal: PropTypes.array,
    };

    UNSAFE_componentWillMount = () => {
        if (!this.props.sparemaal) {
            this.props.getSparemaal();
        }

        this.props.settSideTittel(this.props.texts.get('sparemaalTittel'));
    };

    visSparemaalKort = (sparemaal) => (
        <LinkCard key={sparemaal.id} link={`sparemaal/${sparemaal.id}`}>
            <div className="sparemaal-card">
                <SparemaalIkoner id={sparemaal.type} />

                <div className="sparemaal-card-content">
                    <div className="column">{sparemaal.name}</div>
                    <div className="column">
                        {sparemaal.goal === 0
                            ? this.props.texts.get('sparemaalOversiktVetIkke')
                            : Format.currency(sparemaal.goal)}
                    </div>
                </div>
            </div>
        </LinkCard>
    );

    visSparemaalListe = (sparemaal) => sparemaal.map((s) => this.visSparemaalKort(s));

    sjekkForServerFeil = (errors) => errors.HENT_SPAREMAAL;

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.props.sparemaal) {
            return (
                <div className="sparemaal-oversikt">
                    <div className="sparemaal-oversikt-tittel">{this.props.texts.get('dineSparemaalTittel')}</div>
                    {this.visSparemaalListe(this.props.sparemaal)}
                    <ActionButton action="sparemaal/opprett" text={this.props.texts.get('sparemaalOpprettSparemaal')} />
                </div>
            );
        }
        return <Loading title={this.props.texts.get('lasterPensjonsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Feilmeldinger,
            ...state.Texts,
            ...state.Sparemaal,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            getSparemaal: () => {
                dispatch(getSparemaal());
            },
        };
    }
)(OversiktSparemaal);
