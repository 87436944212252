import React, { Component } from 'react';
import PropTypes from 'prop-types';

import VelgKonto from '../velg-konto';
import DateFunctions from '../../date-functions';
import Modal from '../../modal';
import AksjesparekontoVilkaar from '../../vilkaar/aksjesparekonto-vilkaar';
import NumberInput from '../../common/number-input';
import ValidationMessages from '../../common/validation-messages';
import HelperFunctions from '../../helper-functions';
import DateDropdown from '../../common/date-dropdown';
import Format from '../../format-functions';
import KjopFondAISskjema from './kjop-fond-ais-skjema';

class KjopFondKjopsSkjema extends Component {
    static propTypes = {
        kontoer: PropTypes.array,
        texts: PropTypes.object,
        oppdaterKjopFondData: PropTypes.func,
        minimumsBelopEngangsKjop: PropTypes.number,
        minimumsBelopPeriodiskKjop: PropTypes.number,
        visHjelpetekst: PropTypes.bool,
        klientKonto: PropTypes.bool,
        lockedToPortfolio: PropTypes.bool,
        spareBelop: PropTypes.number,

        ais: PropTypes.bool,
        aisFrequency: PropTypes.string,
        aisValue: PropTypes.any,
        aisValueError: PropTypes.string,
        aisValueUnitPercent: PropTypes.bool,
        isIPS: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            engangsBelop: 0,
            spareBelop: props.spareBelop || 0,
            datoValg: DateFunctions.faaDatoValg(),
            kontoer: HelperFunctions.filtrerUtKlientKontoer(this.props.kontoer),
        };
    }

    settBelop = (belopstype, value) => this.oppdater({ [belopstype]: parseInt(value || 0, 10) });

    velgTrekkDato = trekkDag => this.oppdater({ trekkDag });

    oppdater = obj => {
        this.setState({
            ...obj
        });
        this.props.oppdaterKjopFondData(obj);
    };

    velgKonto = kontoValg => {
        const removePortefoljeId = kontoValg => {
            delete kontoValg.portefoljeId;
            return kontoValg;
        };

        kontoValg = this.props.lockedToPortfolio ? removePortefoljeId(kontoValg) : kontoValg;

        this.props.oppdaterKjopFondData(kontoValg);
    };

    validerBelopEngangsKjop = () =>
        this.state.engangsBelop >= this.props.minimumsBelopEngangsKjop ||
        (this.state.engangsBelop === 0 && this.state.spareBelop > 0);

    validerBelopPeriodiskKjop = () =>
        this.state.spareBelop >= this.props.minimumsBelopPeriodiskKjop ||
        (this.state.spareBelop === 0 && this.state.engangsBelop > 0);

    showAccountDropdown = (id, valueExist, tittel) => (
        <div className="fond-skjema-seksjon">
            <VelgKonto
                id={id}
                tittel={tittel}
                texts={this.props.texts}
                kontoer={this.state.kontoer}
                onChange={this.velgKonto}
                visHjelpetekst={!!(this.props.visHjelpetekst && valueExist)}
                leggTilAnnenKonto={!!'true'}
            />
        </div>
    );

    showInputValueValidation = () => (
        <ValidationMessages
            showValidationMessages={this.props.visHjelpetekst}
            validationMessages={[
                {
                    condition: true,
                    message: this.props.texts.get('belopetErForLite')
                }
            ]}
        />
    );

    showSpareBelopForm = () => (
        <div style={{display: this.props.minimumsBelopPeriodiskKjop ? "block" : "none"}}>
            <div className="fond-skjema-seksjon mnd-sparing">
                <NumberInput
                    id="belopMndSparing"
                    label={`${this.props.texts.get('belop')} (minimum ${Format.currency(
                        this.props.minimumsBelopPeriodiskKjop
                    )} kr)`}
                    onChange={event => this.settBelop('spareBelop', event.target.value)}
                    value={this.state.spareBelop}
                />
                {!this.validerBelopPeriodiskKjop() && this.showInputValueValidation()}
            </div>
            <div className="fond-skjema-seksjon">
                <div className="velg-konto">
                    <DateDropdown
                        id="trekkDato"
                        label={this.props.texts.get('trekkDato')}
                        onChange={this.velgTrekkDato}
                        options={this.state.datoValg}
                    />
                </div>
            </div>
            {this.showAccountDropdown(
                'sparebelopKonto',
                !!this.state.spareBelop,
                this.props.texts.get('kontoForMaanedligTrekk')
            )}

            { !!this.state.spareBelop && !this.props.isIPS && <KjopFondAISskjema 
                {...this.props}
                spareBelop={this.state.spareBelop}
                changeAis={ (v) => { this.props.oppdaterKjopFondData({ais: v}); }}
                changeValue={ (v) => { this.props.oppdaterKjopFondData({aisValue: v, aisValueError: ''}); }}
                changeFrequency={ (v) => { 
                    const aisValueUnitPercent = v !== 'QUARTERLY' && v !== 'MONTHLY';
                    const aisValue = this.props.aisValueUnitPercent === aisValueUnitPercent ? this.props.aisValue : 0;
                    this.props.oppdaterKjopFondData({aisFrequency: v, aisValueUnitPercent, aisValue});
                }}
            /> }
        </div>
    );

    render = () => (
        <div>
            <Modal visModal={this.state.visVilkar} lukkModal={this.toggleVilkaar}>
                <AksjesparekontoVilkaar />
            </Modal>

            <div className="kjop-fond engang-maanedlig" style={{display: this.props.minimumsBelopPeriodiskKjop ? "block" : "none"}}>{this.props.texts.get('engangsbelopEllerMaanedlig')}</div>

            <div className="kjop-fond hyppighet">{this.props.texts.get('engangsbelop')}</div>

            <div className="fond-skjema-seksjon">
                <NumberInput
                    id="engangsBelop"
                    label={`${this.props.texts.get('belop')} (minimum ${Format.currency(
                        this.props.minimumsBelopEngangsKjop
                    )} kr)`}
                    onChange={event => this.settBelop('engangsBelop', event.target.value)}
                    value={this.state.engangsBelop}
                />
                {!this.validerBelopEngangsKjop() && this.showInputValueValidation()}
            </div>
            {!this.props.klientKonto
                ? this.showAccountDropdown(
                      'innskuddsKonto',
                      !!this.state.engangsBelop,
                      this.props.texts.get('kontoforInnskudd')
                  )
                : null}

            <div className="horizontal-line" style={{display: this.props.minimumsBelopPeriodiskKjop ? "block" : "none"}} />

            <div className="kjop-fond hyppighet" style={{display: this.props.minimumsBelopPeriodiskKjop ? "block" : "none"}}>{this.props.texts.get('maanedligSparing')}</div>

            {!this.props.klientKonto ? this.showSpareBelopForm() : null}
        </div>
    );
}

export default KjopFondKjopsSkjema; 
