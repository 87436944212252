import React from 'react';

import './style/EikaInnskuddspensjonLink.scss';

const EikaInnskuddspensjonLink = () => {
    const origin = window.location.origin;
    const place = origin.replace( /.*\/\/(www\.)?/ig, '').replace( /eika.no.*/ig, '' ).replace( /\s+/g, '');
    const isEikaDomain = /eika/i.test(place) || !place;

    const path = 'sparing?eika-innskuddspensjon';
    const url = `${window.location.origin}/${isEikaDomain ? 'oversikt' : 'nettbank'}/${path}`;

    return (
        <div className="eika-innskuddspensjon-link-wrapper">
            <a href={url} className="eika-innskuddspensjon-link">Sjekk utviklingen på din pensjon</a>
        </div>
    );
};

export default EikaInnskuddspensjonLink;
