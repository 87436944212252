import Loading from '@eika-infrastruktur/ui-kort-laster';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../../../components/card';
import PrognoseGraf from '../../../components/pensjon/prognose-graf';
import RisikoBeskrivelse from '../../../components/danica/risiko-beskrivelse';
import HelperFunctions from '../../../components/helper-functions';
import DotLegend from '../../../components/legends/dot-legend';
import Format from '../../../components/format-functions';
import { settSideTittel } from '../../../actions/header';
import { lastPrognoseForAvtale } from '../../../actions/pensjon';

import Feilmelding from '../../../components/feilmelding';
import RadioButtons from '../../../components/radio-buttons';

class DinValgteRisikoProfil extends Component {
    static propTypes = {
        match: PropTypes.object,
        texts: PropTypes.object,
        errors: PropTypes.object,
        settSideTittel: PropTypes.func,
        arbeidsgiversPensjonsSparing: PropTypes.array,
        lastPrognoseForAvtale: PropTypes.func,
        prognoser: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        const { id } = this.props.match.params;

        this.props.settSideTittel(this.props.texts.get('dinValgteRisiko'));
        this.finnAvtale(this.props.arbeidsgiversPensjonsSparing, id);

        if (this.props.prognoser) {
            this.settOppRisikoValg(this.props.prognoser);
        }
    }

    velgValgtRisiko = (prognoser) => {
        const keys = Object.keys(prognoser);
        return keys.find((key) => !!prognoser[key].aktiv) || '';
    };

    UNSAFE_componentWillReceiveProps = (newProps) => {
        const { id } = newProps.match.params;
        if (!this.state.avtale) {
            this.finnAvtale(newProps.arbeidsgiversPensjonsSparing, id);
        }

        if (newProps.prognoser !== this.props.prognoser) {
            this.settOppRisikoValg(newProps.prognoser);
        }
    };

    settOppRisikoValg = (prognoser) => {
        if (prognoser.status === 'Ok') {
            const valg = this.velgValgtRisiko(prognoser.values);
            this.setState({
                risikoProfiler: this.createRiskProfiles(prognoser.values),
                graf: prognoser.values[valg],
                valg,
            });
        }
    };

    finnAvtale = (arbeidsgiversPensjonsSparing, id) => {
        if (arbeidsgiversPensjonsSparing && id) {
            const avtale = HelperFunctions.finnAvtale(arbeidsgiversPensjonsSparing, id);
            this.setState({
                avtale,
            });

            this.props.settSideTittel(this.props.texts.get('dinValgteRisiko'), avtale.firmanavn);
            this.props.lastPrognoseForAvtale(avtale.avtalenummer, avtale.polisenummer);
        }
    };

    createEstimates = (estimates) => {
        return [
            {
                risikoTekst: this.props.texts.get('hoyAvkastning'),
                estimertVerdi: estimates.upperAmount.toString(),
            },
            {
                risikoTekst: this.props.texts.get('forventetAvkastning'),
                estimertVerdi: estimates.expectedAmount.toString(),
                uthevet: true,
            },
            {
                risikoTekst: this.props.texts.get('lavAvkastning'),
                estimertVerdi: estimates.lowerAmount.toString(),
            },
        ];
    };

    createRiskProfiles = (prognoser) => {
        const { texts } = this.props;

        return Object.keys(prognoser).map((key) => {
            const estimat = this.createEstimates(prognoser[key]);

            return {
                navn: texts.get(key),
                id: key,
                tekst: <RisikoBeskrivelse beskrivelse={texts.get(`beskrivelse_${key}`)} estimater={estimat} />,
            };
        });
    };

    sjekkForServerFeil = (errors) => {
        return errors.LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA;
    };

    xAxisLabels = (values, uttaksAlder) => {
        return (values || []).map((a, index) => {
            if (index === 0) {
                return {
                    ...a,
                    key: `${this.props.texts.get('idag')}`,
                };
            }

            if (index === values.length - 1) {
                return {
                    ...a,
                    key: `${uttaksAlder} ${this.props.texts.get('aar')}`,
                };
            }

            return { ...a, key: '' };
        });
    };

    visPrognoseGraf = (valg, graf, prognoser, texts) => {
        if (graf) {
            const values = this.xAxisLabels(graf.values, prognoser.uttaksAlder);

            const dotLegendRisikoTekst = graf.aktiv
                ? `${texts.get(valg)} - ${texts.get('dinEksisterendeRisikoprofil')}<br>${texts.get(
                      'estimertVerdiNaarDuEr'
                  )}`
                : `${texts.get(valg)}<br>${texts.get('estimertVerdiNaarDuEr')}`;

            const dotLegend = [
                {
                    text: HelperFunctions.VariableInjection(dotLegendRisikoTekst, {
                        alder: prognoser.uttaksAlder,
                        verdi: { value: Format.currency(graf.expectedAmount), class: 'tall' },
                    }),
                    color: '#004F5A',
                },
                {
                    text: HelperFunctions.VariableInjection(texts.get('medValgtRisiko'), {
                        low: { value: Format.currency(graf.lowerAmount), class: 'tall' },
                        high: { value: Format.currency(graf.upperAmount), class: 'tall' },
                    }),
                    color: '#79DDFF',
                },
            ];

            const yAxisMaxValue = Math.round(prognoser.values.EIKA_100.upperAmount / 10000) * 10000 + 10000;

            return (
                <div>
                    <PrognoseGraf values={values} yAxisMaxValue={yAxisMaxValue} />
                    <DotLegend data={dotLegend} visVerdi={false} />
                </div>
            );
        }

        return null;
    };

    endreRisiko = (valg) => {
        this.setState({
            graf: this.props.prognoser.values[valg.id],
            valg: valg.id,
        });
    };

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.risikoProfiler && this.props.prognoser) {
            if (this.props.prognoser.status === 'Error') {
                return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
            }

            if (this.props.prognoser.status === 'Ok') {
                const transparent = true;
                const fullWidth = true;

                return (
                    <div>
                        { this.state.graf ? <Card fullWidth={fullWidth}>
                            {this.visPrognoseGraf(
                                this.state.valg,
                                this.state.graf,
                                this.props.prognoser,
                                this.props.texts
                            )}
                        </Card> : <div className="message-wrapper -info">
                            {this.props.texts.get('customerHasDanicaCustomRiskProfile')}
                        </div>}


                        <Card transparent={transparent}>
                            <RadioButtons
                                items={this.state.risikoProfiler}
                                onChange={this.endreRisiko}
                                defaultValue={this.state.valg}
                            />
                        </Card>
                        <div className="align-center">
                            <a
                                href="https://minside.danica.no"
                                className="btn btn-call-to-action"
                            >
                                Bytt min risikoprofil
                            </a>
                            <div className="btn-explaination-text">{this.props.texts.get('sendtTilDanica')}</div>
                        </div>
                    </div>
                );
            }
        }

        return <Loading title={this.props.texts.get('lasterPensjonsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Pensjon,
            ...state.Feilmeldinger,
            ...state.Texts,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel, undertittel) => {
                dispatch(settSideTittel(sideTittel, undertittel));
            },
            lastPrognoseForAvtale: (avtalenummer, polisenummer) => {
                dispatch(lastPrognoseForAvtale(avtalenummer, polisenummer));
            },
        };
    }
)(DinValgteRisikoProfil);
