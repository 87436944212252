import React from 'react';
import classNames from 'classnames';
import { RadioButtonPanels } from '@eika/radio-button';
import { ButtonTypes, LinkButton, SmallButton } from '@eika/button';
import { ToggleSwitch } from '@eika/toggle-switch';
import { Link } from '@eika/link';
import { IconTypes } from '@eika/icon';
import { Checkbox } from '@eika/checkbox';
import { Input } from '@eika/input';
import FlexTextHolder from '../../common/FlexTextHolder/FlexTextHolder';
import RadioOption from '../../common/RadioOption/RadioOption';
import AmountField from '../../common/AmountField/AmountField';
import DropdownMenu from '../../common/DropdownMenu/DropdownMenu';
import Card from '../../common/Card/Card';
import Grid from '../../common/Grid/Grid';
import FundCalculator from '../../common/FundCalculator/FundCalculator';
import Message from '../../common/Message/Message';
import { FooterButton, Spinner } from '../../common/share/ShareComponents';
import { formatAmount, formatBankAccount } from '../../common/util/Functions';
import { getKontiTypeName } from '../../common/util/FondFunctions';
import { convertAISplanToTable } from './util/FondHandelAutomatiskOkingSparingFunctions';
import Constant from '../../static/data/Constant';
import { AISplan } from '../../domain/Types';

/** ************************************************************************
 Step AØS -
 ************************************************************************* */
interface FondHandelAutomaticIncreaseSavingProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    setAIS: (v: boolean) => void;
    setAISvalue: (e?: any) => void;
    blurAISvalue: () => void;
    orderStorage: any;
    aisFrequency: any;
    aisValueInfo?: any;
    error?: any;
    namespace?: string;
    aisSavingPlan?: boolean;
}

export const FondHandelAutomaticIncreaseSaving = (props: FondHandelAutomaticIncreaseSavingProps): JSX.Element => {
    const {
        click,
        setAIS,
        setAISvalue,
        blurAISvalue,
        orderStorage,
        aisFrequency,
        error,
        namespace,
        aisValueInfo,
        aisSavingPlan,
    } = props;
    const allowAmount = !!aisFrequency.pin[orderStorage.aisFrequency]?.amount;

    const aisText =
        !orderStorage.ais || !orderStorage.aisFrequency
            ? ''
            : aisFrequency.pin[orderStorage.aisFrequency].text ||
              (aisFrequency.pin[orderStorage.aisFrequency].textList || [])[0] ||
              '';
    const frequencyYearlyList = aisFrequency?.list.filter((d: any) => !d.category);
    const frequencyHeaderList = aisFrequency?.list.filter((d: any) => !!d.category);
    const frequencyHeaderSelected = frequencyHeaderList.find((d: any) => d.id === orderStorage.aisFrequency)
        ? orderStorage.aisFrequency
        : Constant.typeArligSparing;

    return (
        <section
            aria-labelledby="fond-handel-ais-title"
            className={classNames('fond-handel-automatic-increase-saving-wrapper', namespace, {
                '-active': !!orderStorage.ais,
            })}
        >
            <div className="ais-header">
                <div className="title-holder">
                    <div id="fond-handel-ais-title">Legg til automatisk økning</div>
                    <p>Øk sparebeløpet i fremtiden. Velg selv hvor mye.</p>
                </div>
                <div className="tool-holder">
                    <ToggleSwitch
                        checked={!!orderStorage.ais}
                        onChange={setAIS}
                        ariaLabel="Legg til automatisk økning"
                    />
                </div>
            </div>
            <div className="ais-content">
                <div className="ais-frequency-holder -dropdown-item">
                    <Card
                        type="dropdownMenu"
                        action="select-ais-frequency"
                        click={(e: any, k: any, d?: any) => {
                            click(e, 'aisFrequency-selected', d, 'desktop');
                        }}
                        data={{
                            fieldLabel: 'Hyppighet',
                            optionList: frequencyHeaderList,
                            selected: frequencyHeaderSelected,
                            defaultText: 'velg hyggpighet',
                            error: error.aisFrequency,
                        }}
                    />

                    {frequencyHeaderSelected === Constant.typeArligSparing && (
                        <Card
                            type="dropdownMenu"
                            action="select-ais-frequency"
                            click={(e: any, k: any, d?: any) => {
                                click(e, 'aisFrequency-selected', d, 'desktop');
                            }}
                            data={{
                                fieldLabel: 'Måned',
                                optionList: frequencyYearlyList,
                                selected: orderStorage.aisFrequency,
                                defaultText: 'velg hyggpighet',
                                error: error.aisFrequency,
                            }}
                        />
                    )}
                </div>

                <div className="ais-frequency-holder -list-item">
                    <Card
                        click={click}
                        action="select-ais-frequency"
                        type="list-item -as-rowButton"
                        namespace="only-for-mobile -form -select-ais"
                        data={{
                            textList: [
                                [
                                    { text: 'Hyppighet', type: 'field-label' },
                                    {
                                        text: frequencyHeaderSelected === Constant.typeArligSparing ? 'Årlig' : aisText,
                                        type: 'field-value',
                                    },
                                ],
                            ],
                        }}
                    />
                    {frequencyHeaderSelected === Constant.typeArligSparing && (
                        <Card
                            click={click}
                            action="select-ais-frequency-monthly"
                            type="list-item -as-rowButton"
                            namespace="only-for-mobile -form -select-ais"
                            data={{
                                error: error.ais,
                                textList: [
                                    [
                                        { text: 'Måned', type: 'field-label' },
                                        { text: aisText, type: 'field-value' },
                                    ],
                                ],
                            }}
                        />
                    )}
                </div>

                <div className="ais-value-holder">
                    <AmountField
                        label="Øk beløp med"
                        id="aisValue"
                        error={error.aisValue}
                        value={orderStorage.aisValue}
                        onChange={(e?: any) => {
                            setAISvalue(e);
                        }}
                        onBlur={() => {
                            blurAISvalue();
                        }}
                        maxLength={allowAmount ? undefined : 4}
                        unit={allowAmount ? 'kr' : '%'}
                        standard
                    />
                    {!!aisValueInfo && !!aisValueInfo.text && <div className="ais-value-info">{aisValueInfo.text}</div>}
                </div>

                {!!aisSavingPlan && (
                    <div className="ais-saving-plan-link-wrapper">
                        <LinkButton
                            icon={IconTypes.INFO_24}
                            onClick={(e) => {
                                click(e, 'open-ais-saving-plan');
                            }}
                        >
                            Se spareplan
                        </LinkButton>
                    </div>
                )}
            </div>
        </section>
    );
};

/** ************************************************************************
 Step 0 -
 ************************************************************************* */
interface FondHandelSelgSelectTypeProps {
    click: (e: any, k: any, d?: any, f?: any) => void;
    typeList: any;
    selected?: any;
    error?: any;
}

export const FondHandelSelgSelectType = ({
    click,
    typeList,
    error,
    selected,
}: FondHandelSelgSelectTypeProps): JSX.Element => {
    const note = (typeList.find((d: any) => d.value === selected) || {}).note;
    return (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>Velg konto å overføre til</h1>,
                    },
                ]}
            />

            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'six',
                        element: (
                            <>
                                <div className="paragraph">
                                    {false && (
                                        <RadioOption
                                            type="collector"
                                            name="radio-button-ta-ut-penger"
                                            selected={selected}
                                            change={(e, k, d) => {
                                                click(e, 'type-selected', d);
                                            }}
                                            legend="Skal du bruke pengene nå?"
                                            list={typeList}
                                            error={error?.type}
                                            required
                                        />
                                    )}

                                    <RadioButtonPanels
                                        label="Skal du bruke pengene nå?"
                                        name="radio-button-panels-med-fokus"
                                        onChange={(e) => {
                                            click(e, 'type-selected', { id: e });
                                        }}
                                        selectedOption={selected}
                                        options={typeList}
                                    />
                                </div>

                                {(note || []).length > 0 && (
                                    <ul className="content-list-wrapper -small-gap -checked-list paragraph-large">
                                        {note.map((text: any, i: number) => (
                                            <li key={`note-item-${i}`} className="note-item">
                                                {text}
                                            </li>
                                        ))}
                                    </ul>
                                )}

                                <FooterButton click={click} cancel="Avbryt" />
                            </>
                        ),
                    },
                ]}
            />
        </>
    );
};

/** ************************************************************************
 Step 0-
 ************************************************************************* */
interface FondHandelKjopSelectTypeProps {
    click: (e: any, k: any, d?: any, f?: any) => void;
    typeList: any;
}

export const FondHandelKjopSelectType = ({ click, typeList }: FondHandelKjopSelectTypeProps): JSX.Element => {
    return (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>Hva vil du spare?</h1>,
                    },
                ]}
            />

            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'eight',
                        element: (
                            <>
                                <ul className="trade-type-list">
                                    {typeList.map((data: any) => (
                                        <li key={data.id}>
                                            <Card
                                                data={data}
                                                click={click}
                                                action="type-selected"
                                                type={`trade -${data.id}`}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ),
                    },
                ]}
            />
        </>
    );
};

/** ************************************************************************
 Step 4
 ************************************************************************* */
interface FondHandelSelectPlacementProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    orderStorage: any;
    placementAccountList: any;
    fund: any;
    error?: any;
}

export const FondHandelSelectPlacement = ({
    click,
    orderStorage,
    placementAccountList,
    error,
    fund,
}: FondHandelSelectPlacementProps): JSX.Element => {
    const fond = (fund?.pin ?? {})[orderStorage?.fund] || {};
    const hasIPS = (placementAccountList || []).find((d: any) => d.portefoljeType === Constant.typeIps && !d.disabled);

    return (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1 aria-describedby="fond-handel-my-account">Dine kontoer</h1>,
                    },
                ]}
            />

            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'eight',
                        element: (
                            <>
                                <p className="paragraph" id="fond-handel-my-account">
                                    {`Fondet ditt kan legges i en ${
                                        fond.aksjesparekontoKompatibel ? 'Aksjesparekonto' : 'Verdipapirkonto'
                                    }${
                                        hasIPS ? ' eller IPS-konto' : ''
                                    }. Du kan bruke kontoer til å dele opp sparingen i for eksempel sparing til barn, pensjon eller generell sparing.`}
                                </p>

                                <div className="paragraph">
                                    <form noValidate className="form-wrapper" onSubmit={() => {}}>
                                        <RadioOption
                                            type="placement"
                                            name="placement_fund-abc"
                                            selected={orderStorage.placement}
                                            change={(e, k, d) => {
                                                click(e, 'use-placement', d);
                                            }}
                                            legend="Velg en plasseringskonto"
                                            list={placementAccountList}
                                            error={error?.placement}
                                            required
                                        />
                                    </form>
                                </div>
                                <div className="paragraph">
                                    <LinkButton
                                        icon={IconTypes.PLUSS_24}
                                        onClick={(e) => {
                                            click(e, 'new-placement');
                                        }}
                                    >
                                        {`Legg til ny ${
                                            fond.aksjesparekontoKompatibel ? 'Aksjesparekonto' : 'Verdipapirkonto'
                                        }`}
                                    </LinkButton>
                                </div>

                                <div className="paragraph-large">
                                    <FooterButton click={click} cancel="Avbryt" action="use-placement-next" />
                                </div>
                            </>
                        ),
                    },
                ]}
            />
        </>
    );
};

/** ************************************************************************
 Step 5
 ************************************************************************* */
interface FondHandelNewPlacementProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    setPlacementName: (e: any) => void;
    orderStorage: any;
    fund: any;
    placement: any;
    bankRelation: any;
    error: any;
}

export const FondHandelNewPlacement = ({
    click,
    setPlacementName,
    orderStorage,
    fund,
    placement,
    bankRelation,
    error,
}: FondHandelNewPlacementProps): JSX.Element => {
    const fond = (fund?.pin ?? {})[orderStorage?.fund] || {};
    const config = placement[fond.aksjesparekontoKompatibel ? 'newAsk' : 'newVpk'] || {
        name: 'Verdipapirkonto',
        id: 'VPK',
    };
    const list = placement[fond.aksjesparekontoKompatibel ? 'askAccount' : 'vpkAccount'] || [];

    return (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: (
                            <h1 className="paragraph" aria-describedby="fond-handel-new-account">
                                {list.length ? 'Om din nye konto' : 'Legg til ny konto'}
                            </h1>
                        ),
                    },
                ]}
            />

            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'eight',
                        element: (
                            <>
                                <p id="fond-handel-new-account" className="paragraph">
                                    {`Fondet ditt legges i en ${config.name}. Du kan bruke kontoer til å dele opp sparingen i for eksempel sparing til barn, pensjon eller generell sparing. `}
                                </p>

                                <div className="note-wrapper">
                                    <FlexTextHolder text={config.note} />
                                </div>
                            </>
                        ),
                    },
                ]}
            />

            <Grid
                namespace="fond-handel-footer_ sparing-content"
                list={[
                    {
                        layout: 'eight',
                        element: (
                            <>
                                <div
                                    className="paragraph"
                                    style={{ display: bankRelation.list.length > 2 ? 'block' : 'none' }}
                                >
                                    <RadioButtonPanels
                                        label="Hvilken bank skal denne kontonen knyttes mot?"
                                        name="radio-bankregnr"
                                        onChange={(e) => {
                                            click(null, 'bank-relation-selected', { id: e });
                                        }}
                                        selectedOption={orderStorage.bankregnr}
                                        options={bankRelation.list}
                                        required
                                        errorMessage={error?.type}
                                    />
                                </div>

                                <div className="paragraph-large" style={{ display: 'none' }}>
                                    <Input
                                        label="Gi kontoen et navn"
                                        sublabel="Hva er formålet med sparingen din? Du kan bruke kontoer til å dele opp sparingen i for eksempel sparing til barn, pensjon eller generell sparing. "
                                        value={orderStorage.placementName || ''}
                                        onChange={(e) => {
                                            setPlacementName(e);
                                        }}
                                    />
                                </div>

                                <div className="paragraph-large">
                                    <FooterButton
                                        click={(e, k) => {
                                            click(e, k, config);
                                        }}
                                        cancel="Avbryt"
                                        action="use-new-placement"
                                    />
                                </div>
                            </>
                        ),
                    },
                ]}
            />
        </>
    );
};

/** ************************************************************************
 Step 6
 ************************************************************************* */
interface FondHandelTermProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    setEmail: (e: any) => void;
    blurEmail: () => void;
    orderStorage: any;
    fund: any;
    placement: any;
    bankAccount: any;
    hasFundBefore: boolean;
    appTexts: any;
    aisValueInfo?: any;
    error?: any;
}

export const FondHandelTerm = ({
    click,
    setEmail,
    blurEmail,
    orderStorage,
    fund,
    placement,
    bankAccount,
    error,
    aisValueInfo,
    hasFundBefore,
    appTexts,
}: FondHandelTermProps): JSX.Element => {
    const config = {
        isMonthly: orderStorage.type === Constant.typeManedligSparing,
        amount: `${formatAmount(orderStorage.amount)} kr`,
        fund: fund.pin[orderStorage.fund],
        bankAccount: bankAccount.pin[orderStorage.bankAccount] || {},
        placement: placement.pin[orderStorage.placement] || {
            isNew: true,
            type: orderStorage.placement,
            navn: orderStorage.placementName || `${getKontiTypeName(orderStorage.placement)} (NY)`,
        },
        platformFeeUrl: `${window.location.origin}/spare/plattformhonorar`,
    };

    const note = {
        label: config.isMonthly ? 'Månedlig spareavtale' : 'Engangsbeløp',
        value: `${config.amount} ${config.isMonthly ? 'i måneden' : ''}`.trim(),
    };
    const id = `${orderStorage.fund}`;

    return (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>Se over og fullfør</h1>,
                    },
                ]}
            />

            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'six',
                        element: (
                            <div className={`fund-summary-wrapper -${orderStorage.type}`}>
                                <div className="paragraph info-banner-wrapper -summary">
                                    <div id="fund-summary-label" className="fund-summary-label">
                                        {note.label}
                                    </div>
                                    <div aria-labelledby="fund-summary-label" className="fund-summary-value">
                                        {note.value}
                                    </div>
                                </div>

                                <div className="fund-summary-info-holder paragraph">
                                    {config.isMonthly && (
                                        <Card
                                            type="list-item"
                                            data={{
                                                textList: [
                                                    [
                                                        {
                                                            text: 'Trekkdato',
                                                            type: 'font-normal',
                                                            id: `${id}-draw-day`,
                                                        },
                                                        {
                                                            text: `${orderStorage.drawDay}. hver måned`,
                                                            ariaLabelledby: `${id}-draw-day`,
                                                        },
                                                    ],
                                                ],
                                            }}
                                        />
                                    )}

                                    {config.isMonthly && config.placement.portefoljeType !== Constant.typeIps && (
                                        <Card
                                            type="list-item"
                                            data={{
                                                textList: [
                                                    [
                                                        {
                                                            text: 'Øk beløpet',
                                                            type: 'font-normal',
                                                            id: `${id}-draw-day`,
                                                        },
                                                        { text: aisValueInfo?.summary || 'Av' },
                                                    ],
                                                ],
                                            }}
                                        />
                                    )}

                                    <Card
                                        ignoreAriaLabelGenerating
                                        type="list-item"
                                        data={{
                                            textList: [
                                                [
                                                    {
                                                        text: 'Fra konto',
                                                        type: 'font-normal -max-width',
                                                        id: `${id}-debit-account`,
                                                    },
                                                    {
                                                        element: (
                                                            <span
                                                                aria-labelledby={`${id}-debit-account`}
                                                                className="right-adjustment"
                                                            >
                                                                {!!config.bankAccount.navn && (
                                                                    <span className="block">
                                                                        {config.bankAccount.navn}
                                                                    </span>
                                                                )}
                                                                {!!config.bankAccount.kontonummer && (
                                                                    <span className="text -font-normal -nowrap">
                                                                        {formatBankAccount(
                                                                            config.bankAccount.kontonummer
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        ),
                                                    },
                                                ],
                                            ],
                                        }}
                                    />

                                    <Card
                                        ignoreAriaLabelGenerating
                                        type="list-item"
                                        data={{
                                            textList: [
                                                [
                                                    { text: 'Til fond', type: 'font-normal', id: `${id}-fund` },
                                                    {
                                                        element: (
                                                            <span
                                                                aria-labelledby={`${id}-fund`}
                                                                className="right-adjustment"
                                                            >
                                                                <span className="block">{config.fund.navn}</span>

                                                                {config.fund.currency !== Constant.nok && (
                                                                    <div className="paragraph-small">
                                                                        <LinkButton
                                                                            onClick={() => {
                                                                                click(
                                                                                    null,
                                                                                    'open-foreign-currency-info'
                                                                                );
                                                                            }}
                                                                        >
                                                                            {appTexts.get('foreignCurrency')}
                                                                        </LinkButton>
                                                                    </div>
                                                                )}

                                                                {!!config.placement.portefoljeType &&
                                                                    !!config.placement.portfolioBaseCode && (
                                                                        <span className="block text -font-normal">{`${getKontiTypeName(
                                                                            config.placement.portefoljeType
                                                                        )} ${
                                                                            config.placement.portfolioBaseCode
                                                                        }`}</span>
                                                                    )}

                                                                {!!config.placement.navn && (
                                                                    <span className="block text -font-normal">
                                                                        {config.placement.navn}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        ),
                                                    },
                                                ],
                                            ],
                                        }}
                                    />

                                    {orderStorage.initedEmail ? (
                                        <Card
                                            type="list-item"
                                            data={{
                                                textList: [
                                                    [
                                                        { text: 'Epost', type: 'font-normal', id: `${id}-email` },
                                                        {
                                                            element: (
                                                                <span aria-labelledby={`${id}-email`}>
                                                                    <LinkButton
                                                                        icon={IconTypes.PENN_24}
                                                                        className="icon-right"
                                                                        onClick={(e) => {
                                                                            click(e, 'select-email');
                                                                        }}
                                                                    >
                                                                        {orderStorage.email}
                                                                    </LinkButton>
                                                                </span>
                                                            ),
                                                        },
                                                    ],
                                                ],
                                            }}
                                        />
                                    ) : (
                                        <Card type="list-item">
                                            <Input
                                                type="email"
                                                label="Epost"
                                                value={orderStorage.email}
                                                required
                                                error={error?.email}
                                                onChange={(e) => {
                                                    setEmail(e);
                                                }}
                                                onBlur={() => {
                                                    blurEmail();
                                                }}
                                            />
                                        </Card>
                                    )}

                                    <section aria-labelledby="summary-term-title" className="summary-term-wrapper">
                                        {!hasFundBefore && (
                                            <>
                                                <h1 id="summary-term-title">Les og aksepter følgende:</h1>

                                                {config.placement.isNew && /ask/i.test(config.placement.type) && (
                                                    <div className="paragraph">
                                                        <LinkButton
                                                            onClick={() => {
                                                                click(null, 'open-account-agreement');
                                                            }}
                                                        >
                                                            {`Avtale for ${getKontiTypeName(config.placement.type)}`}
                                                        </LinkButton>
                                                    </div>
                                                )}

                                                <div className="paragraph-large">
                                                    {config.fund.produktarkUrl ? (
                                                        <Link
                                                            href={config.fund.produktarkUrl}
                                                            target="_blank"
                                                        >{`Nøkkelinformasjon for ${config.fund.navn}`}</Link>
                                                    ) : (
                                                        <LinkButton
                                                            onClick={() => {
                                                                click(null, 'open-key-info', null, true);
                                                            }}
                                                        >{`Nøkkelinformasjon for ${config.fund.navn}`}</LinkButton>
                                                    )}
                                                </div>

                                                <div className="paragraph">
                                                    <Checkbox
                                                        onChange={() => {
                                                            click(null, 'toggle-term-placement');
                                                        }}
                                                        checked={!!orderStorage.termPlacement}
                                                        label="Jeg bekreftet å ha lest, forstått og akseptert innholdet i samtlige dokumenter."
                                                        error={error?.termPlacement}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        <FooterButton click={click} cancel="Avbryt" next="Bekreft og kjøp" />
                                    </section>
                                </div>
                            </div>
                        ),
                    },
                ]}
            />
        </>
    );
};

/** ************************************************************************
 Step 8
 ************************************************************************* */
interface FondHandelFinishProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    appTexts: any;
    orderStorage: any;
    status: { finished: boolean; canceled: boolean; failed: boolean };
    receipt?: any;
}

export const FondHandelFinish = ({
    click,
    orderStorage,
    receipt,
    appTexts,
    status,
}: FondHandelFinishProps): JSX.Element | null => {
    if (!receipt) {
        return null;
    }

    const order: any = (receipt.content?.ordreResultater || [])[0] || receipt.content || {};
    const innskudd =
        orderStorage.action === Constant.actionKjop || orderStorage.action === Constant.actionEndreSpareavtale;
    const isMonthly = orderStorage.type === Constant.typeManedligSparing;
    const isDirectDebit =
        (orderStorage.action === Constant.actionEndreSpareavtale && !orderStorage.avsluttSpareavtale) ||
        (orderStorage.action === Constant.actionKjop && isMonthly);

    if (
        order.type === 'feilmelding' ||
        (!order.success && order.melding) ||
        (!order.success && innskudd) ||
        (!order.success && innskudd)
    ) {
        return order.serverData?.status === 500 ? (
            <div className="fond-handel-stop-and-contact-us" aria-live="polite">
                <h1 className="fond-handel-stop-and-contact-us-title">
                    {appTexts.get('fondHandelInternalServerErrorTittel')}
                </h1>
                <p className="lead paragraph">{appTexts.get('fondHandelInternalServerErrorTekst')}</p>
                <FooterButton click={click} next={appTexts.get('kontaktOss')} action="contact-us" cancel="Avbryt" />
            </div>
        ) : (
            <>
                <Message type="ErrorMessage" text={order.melding ? appTexts.get(order.melding) : appTexts.get('ops')} />
                <FooterButton click={click} next={appTexts.get('provIgjen')} action="submit" cancel="Avbryt" />
            </>
        );
    }

    return innskudd && isMonthly && !orderStorage.avsluttSpareavtale && !status.finished && !!receipt.config?.avtalegiroUrl ? (
        <>
            <h1 className="soon-to-be-finished">Snart i mål</h1>

            <p className="paragraph-large">{appTexts.get('avtalegiroBeskrivelse')}</p>

            {!status.finished ? (
                <>
                    {(status.canceled || status.failed) && (
                        <div className="paragraph">
                            <Message
                                type="WarningMessage"
                                icon
                                text={
                                    status.canceled
                                        ? appTexts.get('avtalegiroErBlittAvbryt')
                                        : appTexts.get('opsProvIgjen')
                                }
                            />
                        </div>
                    )}
                    <FooterButton
                        click={click}
                        cancel={appTexts.get('avbryt')}
                        next={appTexts.get('opprettAvtaleGiro')}
                        action="create-avtalegiro"
                    />
                </>
            ) : (
                <FooterButton click={click} next={appTexts.get('tilbakeTilOversikt')} action="finish" />
            )}
        </>
    ) : (
        <div className="fond-handel-success-message-wrapper">
            {isDirectDebit && !!receipt.config?.avtalegiroUrl && (
                <p className="direct-debit-result-message" aria-live="polite">
                    Avtalegiro er opprettet
                </p>
            )}

            {orderStorage.action === Constant.actionEndreSpareavtale && !orderStorage.avsluttSpareavtale && (
                <h1>Spareavtalen er endret</h1>
            )}
            {orderStorage.action === Constant.actionEndreSpareavtale && !!orderStorage.avsluttSpareavtale && (
                <h1>Bekreftelse på slettet spareavtale</h1>
            )}
            {orderStorage.action === Constant.actionKjop && !isMonthly && <h1>Bekreftelse på innskudd</h1>}
            {orderStorage.action === Constant.actionKjop && isMonthly && (
                <h1>Bekreftelse på ny fast månedlig sparing</h1>
            )}
            {orderStorage.action === 'bytt-fond' && <h1>Bekreftelse på bytting av fond</h1>}

            {orderStorage.action === 'selg-fond' && (
                <>
                    {orderStorage.type === 'BANKKONTO' ? (
                        <h1>Bekreftelse på salg av fond</h1>
                    ) : (
                        <h1>Bekreftelse på oppbevaring i Aksjesparekontoen</h1>
                    )}
                </>
            )}
            {orderStorage.action === 'uttak' && <h1>Bekreftelse på uttak</h1>}

            {orderStorage.action === Constant.actionKjop && isMonthly && (
                <p>{`Du har startet sparing på ${formatAmount(orderStorage.amount)} kroner i måneden.`}</p>
            )}
            {orderStorage.action === Constant.actionKjop && !isMonthly && (
                <p>{`Du har satt inn et engangsbeløp på ${formatAmount(
                    orderStorage.amount
                )} kroner. Fondet oppdateres i løpet av 3-6 dager.`}</p>
            )}
            {orderStorage.action === 'selg-fond' && (
                <p>{`Du har solgt ${formatAmount(
                    orderStorage.amount
                )} kroner. Pengene overføres i løpet av 3-6 dager.`}</p>
            )}
            {orderStorage.action === 'uttak' && (
                <p>{`Du har tatt ut ${formatAmount(
                    orderStorage.amount
                )} kroner. Pengene overføres i løpet av 3-6 dager.`}</p>
            )}
            {orderStorage.action === 'bytt-fond' && (
                <p>{`Du har byttet fond for ${formatAmount(
                    orderStorage.amount
                )} kroner. Fondene byttes i løpet av 6 dager.`}</p>
            )}

            {orderStorage.action === Constant.actionEndreSpareavtale && !orderStorage.avsluttSpareavtale && (
                <p>
                    Husk at trekk som allerede ligger i forfallsregisteret ditt, må du slette i mobilbanken din. Det kan
                    derfor ta halvannen måned før endringen skjer.
                </p>
            )}

            {orderStorage.action === Constant.actionEndreSpareavtale && !!orderStorage.avsluttSpareavtale && (
                <p>Du har slettet spareavtalen.</p>
            )}

            {!orderStorage.avsluttSpareavtale && (
                <p className="fond-handel-success-notification">
                    {appTexts.get('kvitteringPaaEpost', { email: orderStorage.email })}
                </p>
            )}

            {isDirectDebit && (
                <div className="paragraph-large">
                    <Message
                        type="AttentionMessage"
                        icon
                        text="Det kan ta et par måneder før spareavtalen trer i kraft."
                    />
                </div>
            )}

            <FooterButton click={click} next={appTexts.get('tilbakeTilOversikt')} action="finish" nextNegative />
        </div>
    );
};

/** ************************************************************************
 Step 3: KJØP FOND / kjop-fond / endre-sparavtale
 ************************************************************************* */
interface FondHandelKjopFormProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    next: (e?: any, k?: any) => void;
    setAmount: (e?: any) => void;
    blurAmount: () => void;
    setAIS: (v: boolean) => void;
    setAISvalue: (e?: any) => void;
    blurAISvalue: () => void;
    orderStorage: any;
    aisValueInfo?: any;
    state?: any;
    appTexts: any;
    action: any;
    aisSavingPlan?: boolean;
}

export const FondHandelKjopForm = (props: FondHandelKjopFormProps): JSX.Element => {
    const {
        click,
        next,
        setAmount,
        blurAmount,
        orderStorage,
        setAIS,
        setAISvalue,
        blurAISvalue,
        state,
        aisValueInfo,
        aisSavingPlan,
        ...rest
    } = props;
    const { fund, bankAccount, error = {}, drawDayList, placement, aisFrequency } = state ?? {};
    const fond = (fund?.pin || {})[orderStorage.fund];
    const konti = placement.pin[orderStorage.placement] || {};
    const to = [fond?.navn, konti.navn || fond?.type].filter((t) => !!t);
    const atLeast =
        (orderStorage.type === Constant.typeManedligSparing
            ? fond?.minimumsBelopPeriodiskKjop
            : fond?.minimumsBelopEngangsKjop) || 100;
    /* eslint-disable */
    const aisText =
        !orderStorage.ais || !orderStorage.aisFrequency || !orderStorage.aisValue
            ? 'Ingen'
            : [
                  `${formatAmount(orderStorage.aisValue)} ${
                      aisFrequency.pin[orderStorage.aisFrequency].amount ? 'kr' : '%'
                  }`,
                  aisFrequency.pin[orderStorage.aisFrequency].text,
              ].join(' ');
    /* eslint-enable */

    return fond ? (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: (
                            <h1>
                                {
                                    /* eslint-disable */
                                    orderStorage.action === Constant.actionEndreSpareavtale
                                        ? 'Endre månedlig beløp'
                                        : orderStorage.type == Constant.typeEngangsinnkudd
                                        ? 'Sett inn engangsbeløp'
                                        : 'Spar et fast månedlig beløp'
                                    /* eslint-enable */
                                }
                            </h1>
                        ),
                    },
                ]}
            />

            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'seven',
                        element: (
                            <form
                                noValidate
                                className="form-wrapper fond-handel-amount-form"
                                onSubmit={() => {
                                    next();
                                }}
                            >
                                <div className="paragraph">
                                    {orderStorage.type === Constant.typeManedligSparing && (
                                        <div className="input-holder -select-draw-day only-for-desktop -form -select-draw-day-header">
                                            <DropdownMenu
                                                textList={
                                                    orderStorage.drawDay
                                                        ? [`Hver ${orderStorage.drawDay}. i måned`]
                                                        : ['']
                                                }
                                                selected={orderStorage.drawDay}
                                                optionList={drawDayList}
                                                label="Trekkdato"
                                                error={error.drawDay}
                                                click={click}
                                                action="select-draw-day"
                                                view="draw-day"
                                                message={{
                                                    text: 'På valgt dag i måneden blir beløpet trukket fra din konto hver måned.',
                                                    type: 'info-message -icon',
                                                    role: 'presentation',
                                                }}
                                                defaultText="Velg dato"
                                            />
                                        </div>
                                    )}
                                    <div className="fond-handel-amount-field-holder">
                                        <AmountField
                                            label={
                                                orderStorage.type === Constant.typeManedligSparing
                                                    ? 'Månedlig beløp'
                                                    : 'Velg beløp'
                                            }
                                            sublabel={`Minstebeløp ${formatAmount(atLeast)} kr`}
                                            attention={orderStorage.amountAttention}
                                            id="amount"
                                            error={error.amount}
                                            value={orderStorage.amount}
                                            onChange={(e?: any) => {
                                                setAmount(e);
                                            }}
                                            onBlur={() => {
                                                blurAmount();
                                            }}
                                            maxLength={orderStorage.type === Constant.typeManedligSparing ? 7 : 10}
                                        />
                                        {konti.portefoljeType !== Constant.typeIps &&
                                            (orderStorage.action === Constant.actionEndreSpareavtale ||
                                                (orderStorage.action === Constant.actionKjop &&
                                                    orderStorage.type === Constant.typeManedligSparing)) && (
                                                <FondHandelAutomaticIncreaseSaving
                                                    aisValueInfo={aisValueInfo}
                                                    aisFrequency={aisFrequency}
                                                    orderStorage={orderStorage}
                                                    error={error}
                                                    click={(e, k, d, f) => {
                                                        click(e, k, d, f);
                                                    }}
                                                    setAIS={(e: boolean) => {
                                                        setAIS(e);
                                                    }}
                                                    setAISvalue={(e) => {
                                                        setAISvalue(e);
                                                    }}
                                                    blurAISvalue={() => {
                                                        blurAISvalue();
                                                    }}
                                                    aisSavingPlan={aisSavingPlan}
                                                />
                                            )}
                                    </div>
                                    {orderStorage.type === Constant.typeManedligSparing &&
                                        orderStorage.action === Constant.actionEndreSpareavtale &&
                                        konti.portefoljeType !== Constant.typeIps && (
                                            <>
                                                <Card
                                                    click={click}
                                                    action="select-ais"
                                                    type="list-item -as-rowButton"
                                                    namespace="only-for-mobile -form -select-ais"
                                                    data={{
                                                        error: error.ais,
                                                        textList: [
                                                            [
                                                                { text: 'Automatisk øking', type: 'field-label' },
                                                                { text: aisText, type: 'field-value' },
                                                            ],
                                                        ],
                                                    }}
                                                />
                                            </>
                                        )}
                                    {orderStorage.type === Constant.typeManedligSparing && (
                                        <Card
                                            click={click}
                                            action="select-draw-day"
                                            type="list-item -as-rowButton"
                                            namespace="only-for-mobile -form -select-draw-day"
                                            data={{
                                                error: error.drawDay,
                                                textList: [
                                                    [
                                                        { text: 'Trekkdato', type: 'field-label' },
                                                        {
                                                            text: orderStorage.drawDay
                                                                ? `Den ${orderStorage.drawDay}. hver måned`
                                                                : 'Velg trekkdato',
                                                            type: 'field-value',
                                                        },
                                                    ],
                                                ],
                                            }}
                                        />
                                    )}
                                    {/* TODO: add message for mutual fund class here */}
                                    <Message type="AttentionMessage" text="Fondet oppdateres i løpet av 3-6 dager." />
                                    <Card
                                        click={click}
                                        action="select-bankAccount"
                                        type="rowButton"
                                        namespace="only-for-mobile -form"
                                        data={{
                                            id: 'fondhandel-bankAccount',
                                            fieldLabel: 'Fra',
                                            textList: (bankAccount.pin[orderStorage.bankAccount] || {}).textList
                                                ? [
                                                      bankAccount.pin[orderStorage.bankAccount].textList[0],
                                                      bankAccount.pin[orderStorage.bankAccount].textList[2] ||
                                                          bankAccount.pin[orderStorage.bankAccount].textList[1],
                                                  ]
                                                : ['Velg belastningskonto'],
                                            error: error.bankAccount,
                                            optionList: bankAccount?.list,
                                            selected: orderStorage.bankAccount,
                                            defaultText: 'Fra hvilken belastningskonto',
                                        }}
                                    />
                                    <Card
                                        click={click}
                                        action=""
                                        disabled
                                        type="rowButton"
                                        namespace="only-for-mobile -form"
                                        data={{
                                            fieldLabel: 'Til',
                                            textList: to,
                                            icon: 'PIN_KODE_24',
                                        }}
                                    />
                                    <div className="only-for-desktop -form">
                                        <ul className="table-wrapper -split-50-50">
                                            <li>
                                                <Card
                                                    type="dropdownMenu"
                                                    action="select-account"
                                                    click={(e: any, k: any, d?: any) => {
                                                        click(e, 'bankAccount-selected', d, 'desktop');
                                                    }}
                                                    data={{
                                                        fieldLabel: 'Belastningskonto',
                                                        selectedLead: 'Fra',
                                                        optionList: bankAccount?.list,
                                                        selected: orderStorage.bankAccount,
                                                        defaultText: 'Velg belastningskonto',
                                                        minRow: 2,
                                                        error: error.bankAccount,
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <Card
                                                    type="dropdownMenu"
                                                    action="select-fund"
                                                    click={() => {}}
                                                    data={{
                                                        fieldLabel: 'Fond',
                                                        selectedLead: 'Til',
                                                        optionList: [{ id: orderStorage.fund, text: to }],
                                                        selected: orderStorage.fund,
                                                        disabled: true,
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    {orderStorage.action === Constant.actionEndreSpareavtale && (
                                        <div className="delete-fond-agreement-btn-wrapper">
                                            {/* @ts-ignore */}
                                            <SmallButton
                                                icon={IconTypes.SLETT_24}
                                                buttonType={ButtonTypes.SMALL_NEGATIVE}
                                                onClick={(e) => {
                                                    click(e, 'delete-fond-agreement');
                                                }}
                                            >
                                                Slett
                                            </SmallButton>
                                        </div>
                                    )}
                                </div>

                                {konti.portefoljeType !== Constant.typeIps &&
                                orderStorage.action !== Constant.actionEndreSpareavtale &&
                                orderStorage.type === Constant.typeManedligSparing ? (
                                    <>
                                        <FooterButton namespace="only-for-desktop" click={click} cancel="Avbryt" />
                                        <FooterButton
                                            namespace="only-for-mobile"
                                            action="next-is-ais"
                                            click={click}
                                            cancel="Avbryt"
                                        />
                                    </>
                                ) : (
                                    <FooterButton click={click} cancel="Avbryt" />
                                )}
                            </form>
                        ),
                    },
                    {
                        layout: 'four',
                        element: (
                            <div className="only-for-desktop paragraph -only-top">
                                <FundCalculator
                                    {...rest}
                                    isin={fund.pin[orderStorage.fund]?.isin}
                                    onetime={
                                        orderStorage.type === Constant.typeManedligSparing ? 0 : orderStorage.amount
                                    }
                                    monthly={
                                        orderStorage.type === Constant.typeManedligSparing ? orderStorage.amount : 0
                                    }
                                    aisValue={orderStorage.ais ? orderStorage.aisValue : undefined}
                                    aisFrequency={orderStorage.ais ? orderStorage.aisFrequency : undefined}
                                    drawDay={orderStorage.drawDay}
                                    increaseImmediately={orderStorage.action === Constant.actionEndreSpareavtale}
                                    // message={orderStorage.ais ? {type: 'AttentionMessage', text: 'Automatisk økning er ikke tatt med i utregningen, vi jobber med saken!', icon: true} : undefined}
                                />
                            </div>
                        ),
                    },
                ]}
            />
        </>
    ) : (
        <Spinner />
    );
};

/** ************************************************************************
 Step 3: SELG FOND
 ************************************************************************* */
interface FondHandelSelgFormProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    next: (e?: any, k?: any) => void;
    setEmail: (e: any) => void;
    blurEmail: () => void;
    setAmount: (e?: any) => void;
    blurAmount: () => void;
    orderStorage: any;
    state?: any;
}

export const FondHandelSelgForm = ({
    click,
    next,
    setEmail,
    blurEmail,
    setAmount,
    blurAmount,
    orderStorage,
    state,
}: FondHandelSelgFormProps): JSX.Element => {
    const { fund, placement, bankAccount, error = {}, typeList = [] } = state ?? {};
    const title =
        (typeList.find((d: any) => d.id === orderStorage.type || d.value === orderStorage.type) || {}).title ||
        'Ta ut penger';
    const from = [
        [
            orderStorage.action === 'uttak' ? 'Klientkonto' : fund.pin[orderStorage.fund]?.navn || '',
            orderStorage.maxAmount ? formatAmount(orderStorage.maxAmount) : '',
        ],
        placement.pin[orderStorage.placement]?.navn,
    ].filter((t) => !!t);
    const amount = parseInt(`${orderStorage.amount}`.replace(/\s+/g, ''), 10);

    return (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>{title}</h1>,
                    },
                ]}
            />

            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'six',
                        element: (
                            <form
                                noValidate
                                className="form-wrapper"
                                onSubmit={() => {
                                    next();
                                }}
                            >
                                <div className="paragraph">
                                    <div className="fond-handel-amount-field-holder">
                                        <AmountField
                                            label={`Beløp${
                                                orderStorage.maxAmount
                                                    ? ` (Maks ${formatAmount(orderStorage.maxAmount)})`
                                                    : ''
                                            }`}
                                            sublabel={`Beløp${
                                                orderStorage.maxAmount
                                                    ? ` (Maks ${formatAmount(orderStorage.maxAmount)})`
                                                    : ''
                                            }`}
                                            id="amount"
                                            error={error.amount}
                                            value={orderStorage.amount}
                                            onChange={(e?: any) => {
                                                setAmount(e);
                                            }}
                                            onBlur={() => {
                                                blurAmount();
                                            }}
                                            displayFailed={!!error?.amountWithdrawalError}
                                            buttonList={[
                                                {
                                                    label:
                                                        !orderStorage.maxAmount || orderStorage.maxAmount === amount
                                                            ? ''
                                                            : `Velg hele beløpet ${formatAmount(
                                                                  orderStorage.maxAmount
                                                              )} kr`,
                                                    click: () => {
                                                        click(null, 'use-max-amount');
                                                    },
                                                    asLink: true,
                                                },
                                                {
                                                    label:
                                                        orderStorage.foreignCurrencyForceToSellAll &&
                                                        error.amountWithdrawalError
                                                            ? 'Kontact oss'
                                                            : '',
                                                    click: () => {
                                                        click(null, 'contact-us');
                                                    },
                                                },
                                            ].filter((d) => !!d.label)}
                                        />
                                    </div>

                                    <Card
                                        click={click}
                                        action=""
                                        disabled
                                        type="rowButton"
                                        namespace="only-for-mobile -form"
                                        data={{
                                            fieldLabel: 'Fra',
                                            textList: from,
                                            icon: 'PIN_KODE_24',
                                        }}
                                    />
                                    <Card
                                        click={click}
                                        action="select-bankAccount"
                                        type="rowButton"
                                        namespace="only-for-mobile -form"
                                        data={{
                                            id: 'fondhandel-bankAccount',
                                            fieldLabel: 'Til',
                                            textList: (bankAccount.pin[orderStorage.bankAccount] || {}).textList
                                                ? [
                                                      bankAccount.pin[orderStorage.bankAccount].textList[0],
                                                      bankAccount.pin[orderStorage.bankAccount].textList[2] ||
                                                          bankAccount.pin[orderStorage.bankAccount].textList[1],
                                                  ]
                                                : ['Velg brukskonto'],
                                            error: error.bankAccount,
                                            optionList: bankAccount?.list,
                                            selected: orderStorage.bankAccount,
                                            defaultText: 'Til hvilken brukskonto',
                                        }}
                                    />
                                    <Card
                                        click={click}
                                        action="select-email"
                                        type="list-item -as-rowButton"
                                        namespace="only-for-mobile -form"
                                        data={{
                                            error: error.email,
                                            textList: [
                                                [
                                                    { text: 'Epost', type: 'field-label' },
                                                    { text: orderStorage.email, type: 'field-value' },
                                                ],
                                            ],
                                        }}
                                    />

                                    <div className="only-for-desktop -form">
                                        <ul className="table-wrapper -split-50-50">
                                            <li>
                                                <Card
                                                    type="dropdownMenu"
                                                    action="select-fund"
                                                    click={() => {}}
                                                    data={{
                                                        fieldLabel: 'Fond',
                                                        selectedLead: 'Fra',
                                                        optionList: [{ id: orderStorage.fund, text: from }],
                                                        selected: orderStorage.fund,
                                                        disabled: true,
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <Card
                                                    type="dropdownMenu"
                                                    action="select-account"
                                                    click={(e: any, k: any, d?: any) => {
                                                        click(e, 'bankAccount-selected', d, 'desktop');
                                                    }}
                                                    data={{
                                                        fieldLabel: 'Konto',
                                                        selectedLead: 'Til',
                                                        optionList: bankAccount?.list,
                                                        selected: orderStorage.bankAccount,
                                                        defaultText: 'velg konto',
                                                        minRow: 2,
                                                        error: error.bankAccount,
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                        <Input
                                            type="email"
                                            label="Epost"
                                            value={orderStorage.email}
                                            required
                                            error={error.email}
                                            onChange={(e) => {
                                                setEmail(e);
                                            }}
                                            onBlur={() => {
                                                blurEmail();
                                            }}
                                        />
                                    </div>

                                    {orderStorage.type !== Constant.typeKlientkonto && (
                                        <div className="paragraph -only-top">
                                            <Checkbox
                                                onChange={() => {
                                                    click(null, 'toggle-salg-understand');
                                                }}
                                                checked={!!orderStorage.salgUnderstand}
                                                label="Jeg forstår at salg av fond kan utløse skatt ved gevinst."
                                                error={error?.salgUnderstand}
                                            />
                                        </div>
                                    )}
                                </div>

                                <FooterButton click={click} cancel="Avbryt" />

                                {orderStorage.action !== 'uttak' && (
                                    <div className="paragraph-large -only-top">
                                        <Message
                                            type="InfoMessage"
                                            icon
                                            text="Fondets siste kjente kurs er normalt to dager gammel. Verdien ved uttak kan bli høyere eller lavere enn denne verdien på grunn av markedsutviklingen."
                                        />
                                    </div>
                                )}
                            </form>
                        ),
                    },
                ]}
            />
        </>
    );
};

/** ************************************************************************
 Step 3: BYTT FOND
 ************************************************************************* */
interface FondHandelByttFormProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    next: (e?: any, k?: any) => void;
    setEmail: (e: any) => void;
    blurEmail: () => void;
    setAmount: (e?: any) => void;
    blurAmount: () => void;
    orderStorage: any;
    state?: any;
}

export const FondHandelByttForm = ({
    click,
    next,
    setEmail,
    blurEmail,
    setAmount,
    blurAmount,
    orderStorage,
    state,
}: FondHandelByttFormProps): JSX.Element => {
    const { fund, placement, error = {} } = state ?? {};
    const from = [fund.pin[orderStorage.current]?.navn, placement.pin[orderStorage.placement]?.navn].filter((t) => !!t);

    const to = [fund.pin[orderStorage.fund]?.navn, placement.pin[orderStorage.placement]?.navn].filter((t) => !!t);
    const amount = parseInt(`${orderStorage.amount}`.replace(/\s+/g, ''), 10);
    const currentPlacement = state.placement?.pin[orderStorage.initedPlacement];

    return (
        <>
            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>Velg beløp</h1>,
                    },
                ]}
            />

            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'six',
                        element: (
                            <form
                                noValidate
                                className="form-wrapper"
                                onSubmit={() => {
                                    next();
                                }}
                            >
                                <div className="paragraph">
                                    <div className="fond-handel-amount-field-holder">
                                        <AmountField
                                            label={`Beløp${
                                                orderStorage.maxAmount
                                                    ? ` (Maks ${formatAmount(orderStorage.maxAmount)})`
                                                    : ''
                                            }`}
                                            sublabel={`Beløp${
                                                orderStorage.maxAmount
                                                    ? ` (Maks ${formatAmount(orderStorage.maxAmount)})`
                                                    : ''
                                            }`}
                                            id="amount"
                                            error={error.amount}
                                            value={orderStorage.amount}
                                            onChange={(e?: any) => {
                                                setAmount(e);
                                            }}
                                            onBlur={() => {
                                                blurAmount();
                                            }}
                                            buttonList={[
                                                {
                                                    label:
                                                        !orderStorage.maxAmount || orderStorage.maxAmount === amount
                                                            ? ''
                                                            : `Velg hele beløpet ${formatAmount(
                                                                  orderStorage.maxAmount
                                                              )} kr`,
                                                    click: () => {
                                                        click(null, 'use-max-amount');
                                                    },
                                                    asLink: true,
                                                },
                                            ].filter((d: any) => !!d.label)}
                                            displayFailed={!!error.amountWithdrawalError}
                                        />
                                    </div>

                                    <Card
                                        click={click}
                                        action=""
                                        disabled
                                        type="rowButton"
                                        namespace="only-for-mobile -form"
                                        data={{
                                            fieldLabel: 'Fra',
                                            textList: from,
                                            icon: 'PIN_KODE_24',
                                        }}
                                    />

                                    <Card
                                        click={click}
                                        action=""
                                        disabled
                                        type="rowButton"
                                        namespace="only-for-mobile -form"
                                        data={{
                                            fieldLabel: 'Til',
                                            textList: to,
                                            icon: 'PIN_KODE_24',
                                        }}
                                    />
                                    <Card
                                        click={click}
                                        action="select-email"
                                        type="list-item -as-rowButton"
                                        namespace="only-for-mobile -form"
                                        data={{
                                            error: error.email,
                                            textList: [
                                                [
                                                    { text: 'Epost', type: 'field-label' },
                                                    { text: orderStorage.email, type: 'field-value' },
                                                ],
                                            ],
                                        }}
                                    />

                                    <div className="only-for-desktop -form">
                                        <ul className="table-wrapper -split-50-50">
                                            <li>
                                                <Card
                                                    type="dropdownMenu"
                                                    action="select-fund"
                                                    click={() => {}}
                                                    data={{
                                                        fieldLabel: 'Fra fond',
                                                        selectedLead: 'Fra fond',
                                                        optionList: [{ id: orderStorage.current, text: from }],
                                                        selected: orderStorage.current,
                                                        disabled: true,
                                                    }}
                                                />
                                            </li>
                                            <li>
                                                <Card
                                                    type="dropdownMenu"
                                                    action="select-fund"
                                                    click={() => {}}
                                                    data={{
                                                        fieldLabel: 'Til fond',
                                                        selectedLead: 'Til fond',
                                                        optionList: [{ id: orderStorage.fund, text: to }],
                                                        selected: orderStorage.fund,
                                                        disabled: true,
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                        <Input
                                            type="email"
                                            label="Epost"
                                            value={orderStorage.email}
                                            required
                                            error={error.email}
                                            onChange={(e) => {
                                                setEmail(e);
                                            }}
                                            onBlur={() => {
                                                blurEmail();
                                            }}
                                        />
                                    </div>

                                    {currentPlacement?.portefoljeType === Constant.typeVpk && (
                                        <div className="paragraph -only-top">
                                            <Checkbox
                                                onChange={() => {
                                                    click(null, 'toggle-salg-understand');
                                                }}
                                                checked={!!orderStorage.salgUnderstand}
                                                label="Jeg forstår at bytte av fond på en verdipapirkonto kan utløse skatt ved gevinst."
                                                error={error?.salgUnderstand}
                                            />
                                        </div>
                                    )}
                                </div>

                                <FooterButton click={click} cancel="Avbryt" />

                                <div className="paragraph-large -only-top">
                                    <Message
                                        type="InfoMessage"
                                        icon
                                        text="Fondets siste kjente kurs er normalt to dager gammel. Verdien ved uttak kan bli høyere eller lavere enn denne verdien på grunn av markedsutviklingen."
                                    />
                                </div>
                            </form>
                        ),
                    },
                ]}
            />
        </>
    );
};

/** ************************************************************************
 Step 0 -
 ************************************************************************* */
interface FondHandelUnderBehandlingProps {
    click: (e: any, k: any, d?: any, f?: any) => void;
}

export const FondHandelUnderBehandling = ({ click }: FondHandelUnderBehandlingProps): JSX.Element => {
    return (
        <>
            <Grid
                namespace="sparing-content"
                list={[
                    {
                        layout: 'eight',
                        element: (
                            <div className="fond-handel-ordre-done-wrapper">
                                <h1>Hei, vennligst fullfør</h1>
                                <p className="paragraph-large">
                                    Du kan desverre ikke gå tilbake på dette steget. For å fullføre, må du gå igjennom
                                    de siste stegene.
                                </p>
                                <FooterButton click={click} next="Fortsett" action="to-last-step" />
                            </div>
                        ),
                    },
                ]}
            />
        </>
    );
};

/** ************************************************************************
 ais-saving-plan
 ************************************************************************* */
interface FondHandelAISsavingPlanProps {
    orderStorage: any;
    data: {
        aisPlan: AISplan[];
        aarligAvkastningUtenKostnader: number;
    };
}

export const FondHandelAISsavingPlan = ({ data, orderStorage }: FondHandelAISsavingPlanProps): JSX.Element => {
    const amount = parseInt(`${orderStorage.amount || 0}`.replace(/\s+/g, ''), 10);
    const table = convertAISplanToTable(data.aisPlan, amount);
    return (
        <div className="ais-saving-plan-wrapper">
            <h2>Spareplan</h2>

            <p style={{ display: 'none' }}>
                {`alkulatoren forutsetter sparing i aksjefond med en avkastningsforventning på ${data.aarligAvkastningUtenKostnader
                    .toString()
                    .replace('.', ',')} % per år.`}
            </p>

            <table className="ais-plan-table">
                <caption className="aria-visible">Automatisk økning sparing plan</caption>
                <thead>
                    <tr>
                        {table.order.map((d: any, j: number) => {
                            return (
                                <th key={`th-${j}`} className={d.id}>
                                    {d.name}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {table.body.map((plan: any, i: number) => {
                        return (
                            <tr key={`tr-${i}`}>
                                {table.order.map((d: any, j: number) => {
                                    return (
                                        <td key={`td-${i}-${j}`} className={d.id}>
                                            {plan[d.id] || ''}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
