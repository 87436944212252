import React, { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@eika/modal';
import { Icon, IconTypes } from '@eika/icon';
import { ExternalLinkLabel } from '@eika/label';
import { RouteComponentProps } from 'react-router';
import { usePrevious } from '../../common/util/Hook';
import Constant from '../../static/data/Constant';
import Message, { Props as MessageProps } from '../../common/Message/Message';
import Card from '../../common/Card/Card';
import Grid from '../../common/Grid/Grid';
import AppMessage from '../../common/AppMessage/AppMessage';
import { HeaderLink, Spinner } from '../../common/share/ShareComponents';
import {
    formatBankAccount,
    formatDateToText,
    generateId,
    getPageAppending,
    sortList,
} from '../../common/util/Functions';
import { getActionName } from '../../common/util/FondFunctions';
import { getTransactionLabelList } from '../../common/util/BusinessFunctions';
import { getCurrentAccount } from '../Portefolje/util/Functions';
import './Ordre.scss';
import { RouterConfig } from '../../app/Router';

type Props = RouteComponentProps<{ accountId?: string; isin?: string }> & RouterConfig;

interface State {
    reducer: any;
    appending: string;
    detail: any;
    content?: any;
}

interface Step {
    text: string;
    icon: IconTypes;
}

export type SubType =
    | 'PLATFORM_FEE'
    | 'PLATFORM_FEE_KICKBACK'
    | 'PLATFORM_FEE_ONE_TIME'
    | 'KICKBACK_ONE_TIME'
    | 'NONE'
    | undefined;

const click = (
    props: Props,
    state: State,
    setState: (s: State) => void,
    e: MouseEvent | null,
    key = '',
    data?: any
): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if (key === Constant.actionCloseModal) {
        setState({ ...state, detail: null });
    } else if (key === Constant.actionOpenModal && data && data.detail) {
        setState({ ...state, detail: data.detail });
    }
};

const getStepList = (props: Props, data: any): Step[] | null => {
    const status = data?.statusGroup;

    if (data.statusGroup === Constant.orderStatusFailed || data.statusGroup === Constant.orderStatusNoMoney) {
        return null;
    }

    return status === Constant.orderStatusDone
        ? [
              { text: 'Registrert', icon: IconTypes.CHECKMARK_LISTE_24 },
              { text: 'Ferdig behandlet', icon: IconTypes.CHECKMARK_LISTE_24 },
              { text: 'Fullført', icon: IconTypes.CHECKMARK_LISTE_24 },
          ]
        : [
              { text: 'Registrert', icon: IconTypes.CHECKMARK_LISTE_24 },
              { text: 'Under behandling', icon: IconTypes.KLOKKE_24 },
              { text: 'Fullført', icon: IconTypes.MOBILBANK_24 },
          ];
};

const getDetailTitle = (props: Props, data: any): string => {
    const status = data?.statusGroup;
    if (data?.transactionSubType && data?.transactionSubType !== 'NONE') {
        switch (data?.transactionSubType) {
            case 'PLATFORM_FEE':
                return props.appTexts.get('platformFeeYearlyPaid');
            case 'PLATFORM_FEE_KICKBACK':
            case 'KICKBACK_ONE_TIME':
                return props.appTexts.get('platformFeeKickbackShortDescription');
            case 'PLATFORM_FEE_ONE_TIME':
                return props.appTexts.get('platformFeePaid');
            default:
                return '';
        }
    }

    if (status === Constant.orderStatusDone) {
        return props.appTexts.get('transactionDone');
    }

    // if ( data.statusGroup === Constant.orderStatusFailed || data.statusGroup === Constant.orderStatusNoMoney  ) {
    if (data.statusGroup === Constant.orderStatusFailed) {
        return props.appTexts.get('transactionFailed');
    }

    return props.appTexts.get('transactionPending');
};

const getDetailNote = (props: Props, data: any): string => {
    const name = data?.fondNavn;
    if (!name) {
        return '';
    }

    if (data.type === Constant.orderTypeUttak) {
        return `Uttak fra ${name}`;
    }

    if (data.type === Constant.orderTypeSalgBytt) {
        return `Byttet fra ${name}`;
    }

    if (data.type === Constant.orderTypeKjopBytt) {
        return `Byttet til ${name}`;
    }

    return `Satt inn i ${name}`;
};

const getDetailMessage = (props: Props, data: any): MessageProps | undefined => {
    if (!data) {
        return;
    }
    const tag = 'Siste hendelse:';

    if (data.transactionSubType && data.transactionSubType !== 'NONE') {
        switch (data.transactionSubType) {
            case 'PLATFORM_FEE':
                return {
                    tag,
                    title: props.appTexts.get('platformFeePaid'),
                    text: props.appTexts.get('platformFeeDescription'),
                    type: 'info-message -transaction-note',
                };
            case 'PLATFORM_FEE_KICKBACK':
            case 'KICKBACK_ONE_TIME':
                return {
                    tag,
                    title: props.appTexts.get('platformFeeKickbackReceived'),
                    text: props.appTexts.get('platformFeeKickbackDescription'),
                    type: 'info-message -transaction-note',
                };
            case 'PLATFORM_FEE_ONE_TIME':
                return {
                    tag,
                    title: props.appTexts.get('platformFeePaid'),
                    text: props.appTexts.get('platformFeeOnetimeDescription'),
                    type: 'info-message -transaction-note',
                };
            default:
                return;
        }
    }

    if (data.statusGroup === Constant.orderStatusFailed || data.statusGroup === Constant.orderStatusNoMoney) {
        return {
            text:
                data.statusGroup === Constant.orderStatusFailed
                    ? props.appTexts.get('transactionFailedMessage')
                    : props.appTexts.get('transactionNoMoneyMessage'),
            type: 'ErrorMessage',
            icon: true,
        };
    }

    let title = props.appTexts.get('transactionDoneMessageTitle');

    if (data.statusGroup === Constant.orderStatusDone) {
        return {
            tag,
            title,
            text: props.appTexts.get('transactionDoneMessage'),
            type: 'info-message -transaction-note',
        };
    }

    title = props.appTexts.get('transactionPendingAmountBuy');

    if (data.type === Constant.orderTypeUttak) {
        title = props.appTexts.get('transactionPendingAmountWithdrawal');
    }

    if (data.type === Constant.orderTypeSalgBytt || data.type === Constant.orderTypeKjopBytt) {
        title = props.appTexts.get('transactionPendingAmountSwitch');
    }

    return {
        tag,
        title,
        text: props.appTexts.get('transactionPendingAmountMessage2'),
        type: 'info-message -transaction-note',
    };
};

const getStatusFromSubType = (subType: string) => {
    if (subType === 'PLATFORM_FEE_KICKBACK' || subType === 'KICKBACK_ONE_TIME') {
        return 'kickback';
    }
    if (subType === 'PLATFORM_FEE' || subType === 'PLATFORM_FEE_ONE_TIME') {
        return 'savings-app';
    }
    return null;
};

const initView = (props: Props, data: any, type: string): void => {
    if (!data) {
        return;
    }
    const id = generateId('ordre');
    const now = new Date();
    const changedDate = new Date(data.statusChangeDate);
    const date = new Date(data.opprettet);
    const amount = data.displayAmount || '';
    const actionName = getActionName(data.type, false, data.transactionSubType);
    const status = data.statusGroup;
    const actionLeadLabel: any = {
        kjop: 'Til fond',
        salg: 'Fra fond',
        kjop_bytt: 'Til fond',
        salg_bytt: 'Fra fond',
    };

    data.stamp = date.getTime();
    data.date = formatDateToText(date, 'dd.mm');
    data.data = {
        textList: [
            [
                {
                    text: [
                        formatDateToText(date, 'dd.mm'),
                        now.getFullYear() === date.getFullYear() ? '' : date.getFullYear(),
                    ].filter((d) => !!d),
                    type: 'date',
                    ariaLabel: `Den ${formatDateToText(date, 'dd. MM yyyy')}`,
                },
                {
                    text: [
                        { text: data.fondNavn, type: 'fund-name', ariaLabel: `Fond ${data.fondNavn}` },
                        {
                            text: type === 'ordre' ? `Ordre under behandling: ${actionName}` : actionName,
                            type: 'fund-action',
                        },
                    ],
                    type: 'info',
                },
                { text: `${amount}`, type: 'amount', ariaLabel: `${amount} kr` },
            ] /*[{text: JSON.stringify(data), },]*/,
        ],
        labelList: getTransactionLabelList(data),
        type: 'ordre',
    };
    data.detail = {
        type: 'ordre',
        /* eslint-disable */
        status:
            getStatusFromSubType(data.transactionSubType) ||
            (status === Constant.orderStatusDone
                ? 'done'
                : status === Constant.orderStatusPending || status === Constant.orderStatusNoMoney
                ? 'pending'
                : 'failed'),
        /* eslint-enable */
        note: getDetailNote(props, data),
        name: data.fondNavn,
        amount: amount ? `${amount} kr` : '',
        message: getDetailMessage(props, data),
        title: getDetailTitle(props, data),
        stepList: getStepList(props, data),
        subType: data.transactionSubType,
        infoList: [
            [
                { text: 'Registrert dato', id: `${id}-registed-date` },
                {
                    text: formatDateToText(date, 'dd.mm.yyyy'),
                    ariaLabelledby: `${id}-registed-date`,
                },
            ],
            [
                { text: 'Sist oppdatert', id: `${id}-changed-date` },
                {
                    text: formatDateToText(changedDate, 'dd.mm.yyyy'),
                    ariaLabelledby: `${id}-changed-date`,
                },
            ],
            amount
                ? [
                      { text: 'Beløp', id: `${id}-amount` },
                      {
                          text: `${amount} kr`,
                          ariaLabelledby: `${id}-amount`,
                      },
                  ]
                : null,
            [
                { text: 'Type', id: `${id}-type` },
                {
                    text: getActionName(data.type, true, data.transactionSubType),
                    ariaLabelledby: `${id}-type`,
                },
            ],
            [
                { text: actionLeadLabel[(data.type || '').toLowerCase()] || 'Fond', id: `${id}-fund` },
                {
                    text: [
                        { text: data.fondNavn, type: 'fund-info-name' },
                        { text: data.portefoljeNavn, type: 'portfolio-info-name' },
                        { text: data.bankNavn, type: 'bank-info-name' },
                    ],
                    ariaLabelledby: `${id}-fund`,
                },
            ],
            // Incorrected data fra Tradex when it's selected client account.
            // Temporary we are hidding this information
            // eslint-disable-next-line
            data.type === Constant.orderTypeKjop && false
                ? [
                      { text: 'Fra konto', id: `${id}-account` },
                      {
                          text: [
                              { text: data.kundeKontoNavn, ariaLabelledby: `${id}-account` },
                              data.customerAccountNumber
                                  ? {
                                        text: formatBankAccount(data.customerAccountNumber),
                                        ariaLabelledby: `${id}-account`,
                                    }
                                  : null,
                          ].filter((d: any) => !!d),
                      },
                  ]
                : null,
            // Incorrected data fra Tradex when it's selected client account.
            // Temporary we are hidding this information
            // eslint-disable-next-line
            data.type === Constant.orderTypeUttak && (data.kundeKontoNavn || data.customerAccountNumber) && false
                ? [
                      { text: 'Til konto', id: `${id}-account` },
                      {
                          text: [
                              data.kundeKontoNavn
                                  ? { text: data.kundeKontoNavn, ariaLabelledby: `${id}-account` }
                                  : null,
                              data.customerAccountNumber
                                  ? {
                                        text: formatBankAccount(data.customerAccountNumber),
                                        ariaLabelledby: `${id}-account`,
                                    }
                                  : null,
                          ].filter((d: any) => !!d),
                      },
                  ]
                : null,
        ].filter((d) => !!d),
    };
};

const getContent = (props: Props, reducer: any): any => {
    if (!reducer.Fond) {
        return;
    }

    const match = props?.match;
    const content: any = {};
    if (match.params?.accountId) {
        const account = getCurrentAccount(props, reducer);
        if (!account) {
            return;
        }
        const { isin } = match.params || {};

        if (isin) {
            const fund = (account.fond || [])
                .concat(account.eksterneFond || [])
                .find((d: any) => d.isin && isin && `${d.isin}` === `${isin}`);
            if (!fund) {
                return;
            }

            content.ordreList = fund.ordreList || [];
            content.transaksjonList = fund.transaksjonList || [];
            content.title = fund.navn;
        } else {
            content.ordreList = account.ordreList || [];
            content.transaksjonList = account.transaksjonList || [];
            content.title = account.navn;
        }
    } else {
        const { portefoljeData, disponertePortefoljer } = reducer.Fond || {};
        if (!portefoljeData || !portefoljeData.portefoljer || !disponertePortefoljer) {
            return;
        }
        portefoljeData.portefoljer.concat(disponertePortefoljer).forEach((d: any) => {
            content.ordreList = (content.ordreList || []).concat(d.ordreList || []);
            content.transaksjonList = (content.transaksjonList || []).concat(d.transaksjonList || []);
        });

        content.title = /portefolje/i.test(props.location?.pathname) ? 'Fond og aksjer' : '';
    }

    if (content.ordreList) {
        content.ordreList.forEach((data: any) => {
            initView(props, data, 'ordre');
        });
        content.ordreList = sortList(content.ordreList, 'stamp', true, true);
    }

    if (content.transaksjonList) {
        content.transaksjonList.forEach((data: any) => {
            initView(props, data, 'transaksjon');
        });
        content.transaksjonList = sortList(content.transaksjonList, 'stamp', true, true);
    }

    return { content };
};

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Fond'].reduce((p: { [k: string]: any }, k: string) => {
            return { ...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const [state, setState] = useState<State>({
        // ...getContent( props, reducer ),
        appending: getPageAppending(props),
        reducer,
        detail: null,
    });

    const previousReducer = usePrevious(reducer);

    useEffect(() => {
        if (JSON.stringify(reducer) === JSON.stringify(previousReducer)) {
            return;
        }
        setState({ ...state, reducer, ...getContent(props, reducer) });
    }, [previousReducer, reducer, state, props]);
    const isPlatformFee = state.detail?.subType === 'PLATFORM_FEE' || state.detail?.subType === 'PLATFORM_FEE_ONE_TIME';

    return (
        <div className="ordre-wrapper">
            <Grid
                namespace="sparing-top"
                list={[
                    {
                        layout: 'twelve',
                        element: <HeaderLink text="Forrige side" />,
                    },
                ]}
            />

            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>{state.content?.title || props.appTexts.get('ordreHistorikk')}</h1>,
                    },
                ]}
            />

            <div className={`ordre-content ${state.appending}`}>
                <Grid
                    namespace="sparing-content"
                    list={[
                        {
                            layout: 'eight',
                            element: state.content ? (
                                <>
                                    <AppMessage appMessage={reducer?.App?.serviceMessage} context="TRANSACTION" />

                                    {state.content.amount && <div className="ordre-amount">{state.content.amount}</div>}
                                    {state.content.desciption && (
                                        <div className="ordre-desciption">{state.content.desciption}</div>
                                    )}

                                    {(state.content.ordreList || []).length > 0 && (
                                        <section aria-labelledby="ordre-title" className="section-wrapper -ordre">
                                            <h2 id="ordre-title" className="paragraph-small">
                                                Pågående
                                            </h2>
                                            <p className="lead">
                                                {props.appTexts.get('transactionPendingAmountMessage')}
                                            </p>
                                            <div className="section-content">
                                                {state.content.ordreList.map((data: any, i: number) => (
                                                    <Card
                                                        key={`order-item-${i}`}
                                                        data={data.data}
                                                        click={(e, k) => {
                                                            click(props, state, setState, e, k, data);
                                                        }}
                                                        action={Constant.actionOpenModal}
                                                        type="spare-link-panel -ordre -collection"
                                                    />
                                                ))}
                                            </div>
                                        </section>
                                    )}

                                    {(state.content.transaksjonList || []).length > 0 && (
                                        <section
                                            aria-labelledby="transaksjon-title"
                                            className="section-wrapper -transaksjon"
                                        >
                                            <h2 id="transaksjon-title" className="paragraph-small">
                                                Fullførte
                                            </h2>
                                            <div className="section-content">
                                                {state.content.transaksjonList.map((data: any, i: number) => (
                                                    <Card
                                                        key={`transaksjon-item-${i}`}
                                                        data={data.data}
                                                        click={(e, k) => {
                                                            click(props, state, setState, e, k, data);
                                                        }}
                                                        action={Constant.actionOpenModal}
                                                        type="spare-link-panel -transaksjon -collection"
                                                    />
                                                ))}
                                            </div>
                                        </section>
                                    )}

                                    {(state.content.transaksjonList || []).length === 0 &&
                                        (state.content.ordreList || []).length === 0 && (
                                            <section className="section-wrapper -empty">
                                                Ingen pågående ordre og fullførte transaksjon.
                                            </section>
                                        )}
                                </>
                            ) : (
                                <Spinner type="ordre" />
                            ),
                        },
                    ]}
                />
            </div>

            <Modal
                id="ordre-detail"
                appNamespace="sparing-react-app"
                onClose={() => {
                    click(props, state, setState, null, Constant.actionCloseModal);
                }}
                show={!!state.detail}
            >
                {state.detail ? (
                    <div className={`ordre-detail-wrapper -${state.detail.type} -${state.detail.status}`}>
                        <h1>{state.detail.title}</h1>

                        {!!state.detail.note && (
                            <div className="ordre-detail-note paragraph">
                                <span>{state.detail.note}</span>
                            </div>
                        )}

                        {!!state.detail.amount && (
                            <div aria-label={`Beløp ${state.detail.amount}`} className="ordre-detail-amount">
                                {state.detail.amount}
                            </div>
                        )}

                        {!!state.detail.stepList && (
                            <ul title="Ordre prosess" className="step-list-wrapper flat">
                                {state.detail.stepList.map((step: Step) => (
                                    <li key={step.text} className="step-item">
                                        <span className="icon-wrapper">
                                            <Icon type={step.icon} />
                                        </span>
                                        <div>{step.text}</div>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {!!state.detail.message && <Message role="presentation" {...state.detail.message} />}

                        <div className="ordre-detail-body">
                            <div className="section-title paragraph-extra-small">Detaljer</div>
                            {state.detail.infoList.map((info: any, i: number) => (
                                <Card key={`ordre-detail-item-${i}`} type="list-item" data={{ textList: [info] }} />
                            ))}
                        </div>
                    </div>
                ) : null}
                {isPlatformFee && (
                    <>
                        <ExternalLinkLabel
                            className="ordre-platform-pricelist"
                            text="Les om plattformhonorar på eika.no"
                            url="https://www.eika.no/spare/plattformhonorar"
                            linkText=""
                        />
                        <br />
                        <ExternalLinkLabel text="Se priser på eika.no" url="https://www.eika.no/priser" linkText="" />
                    </>
                )}
            </Modal>
        </div>
    );
};
