import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import KDKForm from '@eika-felles/kdk-form';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import {
    HENT_LISTE_OVER_CRS_LAND,
    hentKundevurderingBarn,
    hentListeOverCRSLand,
    hentSkjemaForKunde,
    hentSkjemaForKundeBarn,
    KJENNDINKUNDE_SKJEMA_FOR_KUNDE,
    KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN,
    KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE,
    sendKjennDinKundeSvar,
    hentOppfolgingSkjemaForKunde,
} from '../../actions/kjenndinkunde';
import { addEvent, getOffset, scrollBodyTop } from '../../containers/EKFfond/Functions';

require('../../styles/kjenndinkunde-form.scss');

const deepCopyObject = object => JSON.parse(JSON.stringify(object));

const initHackForStandalone = (storage) => {
    storage.current.inited = true;
    const wrapper = document.getElementById('kdkform');
    if ( !wrapper ) { return; }

    const inputList = wrapper.querySelectorAll('input[type="search"]');
    for ( let i = 0; i < inputList.length; i++ ) {
        addEvent( (e) => { 
            if ( !e.currentTarget ) { return; }
            const offset = getOffset( e.currentTarget );
            scrollBodyTop((offset[1] - 40), 600);
         }, inputList[i], 'focus');
    }
};

const KjennDinKundeForm = ({
    bankregnr,
    rotNodeId,
    onSubmit,
    hideSpinner = false,
    isChild = false,
    loadingText = '',
    onError = () => {},
    fnrBarn = '',
    onInitialFetchDone = () => {},
    schema = null,
    tradexCustomerId = '',
    standalone = false,
    onFail = () => {},
}) => {
    const dispatch = useDispatch();
    const { skjemaForKunde, skjemaForKundeBarn, crsLand, kundevurdering, oppfolgingSkjemaForKunde } = useSelector(state => state.KjennDinKunde);
    const errors = useSelector(state => state.Feilmeldinger.errors);
    const [isSubmitting, setSubmitting] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const storage = useRef({});

    const getFormType = () => {
        if (schema) { return schema; }
        if (isChild) { return skjemaForKundeBarn; }

        const data = oppfolgingSkjemaForKunde && oppfolgingSkjemaForKunde.skjema ? oppfolgingSkjemaForKunde : skjemaForKunde;

        /* SPASP-2545: Vi skjuler valget "Verge - ikke relevant", inntil SPASP-21 er avklart */
        (data?.skjema?.skjemaSporsmal ?? []).forEach( (d) => {
            if ( d && d.muligeSvar ) {
                d.muligeSvar = d.muligeSvar.filter( (s) => s.svarId !== 'r-2-100' );
            }
        });

        return data;
    }

    useEffect(() => {
        if (!kundevurdering && fnrBarn.length > 1) {
            dispatch(hentKundevurderingBarn(fnrBarn));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if ( !standalone || storage.current.inited ) { return; }

        const ready = schema || skjemaForKundeBarn || skjemaForKunde || (oppfolgingSkjemaForKunde || {}).oppfolgingSkjemaForKunde;
        if ( !ready ) { return; }

        clearTimeout( storage.current.timer || 0 );
        storage.current.timer = setTimeout( () => { initHackForStandalone( storage ); }, 1000);
    }, [standalone, storage, schema, oppfolgingSkjemaForKunde, skjemaForKunde, skjemaForKundeBarn ]);

    useEffect(() => {
        if ( schema ) {
            // do nothing
        } else if (!oppfolgingSkjemaForKunde) {
            dispatch(hentOppfolgingSkjemaForKunde());
        } else if (isChild && fnrBarn.length > 1 && kundevurdering) {
            // if (kundevurdering === 'IKKE_VURDERT' && !skjemaForKundeBarn) {
            if (!skjemaForKundeBarn) {
                dispatch(hentSkjemaForKundeBarn(fnrBarn));
            }
        } else if (!skjemaForKunde) {
            dispatch(hentSkjemaForKunde());
        }

        if (!crsLand) {
            dispatch(hentListeOverCRSLand());
        }
    }, [
        dispatch,
        isChild,
        fnrBarn,
        kundevurdering,
        hasSubmitted,
        onSubmit,
        crsLand,
        skjemaForKunde,
        skjemaForKundeBarn,
        oppfolgingSkjemaForKunde,
        schema,
        tradexCustomerId
    ]);

    useEffect(() => {
        if ((oppfolgingSkjemaForKunde || skjemaForKunde || skjemaForKundeBarn || schema) && crsLand) {
            onInitialFetchDone();
        }
    }, [skjemaForKunde, skjemaForKundeBarn, oppfolgingSkjemaForKunde, crsLand, onInitialFetchDone, schema]);

    const handleSubmit = async result => {
        setSubmitting(true);
        setHasSubmitted(true);
        const clonedSkjema = deepCopyObject(getFormType()); // ønsker ikke mutere referanse til redux state
        clonedSkjema.skjema.skjemaSporsmal = result;
        let fail = false
        let responsePayload;
        try {
            responsePayload = await sendKjennDinKundeSvar(
                dispatch,
                clonedSkjema,
                bankregnr,
                isChild ? fnrBarn : undefined,
                tradexCustomerId,
            );
        } catch(e) {
            fail = true
        }

        if (responsePayload?.error || fail) {
            onError(responsePayload?.error);
            setSubmitting(false);
            onFail(fail);
        } else if ( onSubmit ) {
            onSubmit(responsePayload);
        }
        // Pga Smartspar submit_ok delay, må la spinner vises
        // setSubmitting(false);
    };

    if (errors[KJENNDINKUNDE_SKJEMA_FOR_KUNDE]) {
        onError({ text: 'en feil har oppstått', type: 'errors.KJENNDINKUNDE_SKJEMA_FOR_KUNDE' });
    }
    if (errors[KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN]) {
        onError({ text: 'en feil har oppstått', type: 'errors.KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN' });
    }
    if (errors[HENT_LISTE_OVER_CRS_LAND]) {
        onError({ text: 'en feil har oppstått', type: 'errors.HENT_LISTE_OVER_CRS_LAND' });
    }
    if (errors[KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE]) {
        onError({text: 'en feil har oppstått', type: 'errors.KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE'});
    }

    if (!getFormType() || !crsLand) {
        return hideSpinner ? <></> : <Loading title={loadingText} />;
    }

    return (
        <article id="kdkform" className={`kdkform ${isSubmitting ? 'on-submit': 'static'} -${isChild ? 'child': 'normal'}`}>
            <h1 className="h1">{isChild ? `Lovpålagt kundekontroll av barnet` : `Lovpålagt kundekontroll`}</h1>
            <p className="lead">Hvitvaskingsloven pålegger bankene å ha kunnskap om sine kunder. Du må derfor svare på disse spørsmålene.</p>
            <div className="link-wrapper">
                <a href="https://eika.no/Sikkerhet/Antihvitvask" target="_blank">Om hvitvaskningsloven</a>
            </div>

            <KDKForm
                questions={getFormType()}
                landOptionLists={{
                    alleLand: crsLand.map(land => ({ landIso: land.svarId, landNavn: land.tekst })),
                    excludeListe: { CRS: [] }
                }}
                onSubmit={handleSubmit}
                rotNodeId={rotNodeId}
                displayNesteButton
                appNamespace="min-side-web"
            />

            { isChild && <div className="info-message">
                Dersom pengene kommer fra din lønn, barnebidrag eller du har penger til overs som du vil sette inn på barnets konto, så anses dette også som en gave.
            </div>}

        </article>
    );
};

KjennDinKundeForm.propTypes = {
    rotNodeId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    onError: PropTypes.func,
    bankregnr: PropTypes.shape({
        value: PropTypes.string
    }),
    loadingText: PropTypes.string,
};

export default KjennDinKundeForm;
