import React, { FC } from 'react';
import './SavingsProfiles.scss';

type RiskLevelProps = {
    level: number;
};
const RiskLevel: FC<RiskLevelProps> = ({ level }) => {
    const pips = Array.from({ length: 7 }).map((_, index) => `pip ${index < level ? '-filled' : '-empty'}`);

    return (
        <div className="risk-level-indicator">
            {pips.map((pip, i) => (
                <div key={i} className={pip} />
            ))}
        </div>
    );
};

export default RiskLevel;
