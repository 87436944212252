import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { generateId, getSmartsparURL, openSmartspart } from '../util/Functions';
import { getContactUsUrl } from '../util/BusinessFunctions';
import Card from '../Card/Card';
import Constant from '../../static/data/Constant';
import { Kundeforhold, StringObject } from '../../domain/Types';

import './OtherServiceButtonListView.scss';

interface Props {
    namespace?: string;
    kundeforhold?: Kundeforhold[];
    norneBankWithoutAgreement?: StringObject;
}

interface SpareLinkPanel {
    id: string;
    type: string;
    data: { text: string[] };
    action?: string;
    url?: string;
    blank?: boolean;
    hide?: boolean;
}

export default ({ namespace }: Props): JSX.Element => {
    // @ts-ignore state is not typed anywhere so we have to guess what exists on it
    const showNorne = useSelector((state) => state.App.norneDto?.showUrl);
    const id = generateId('other-service-button-list');
    const list = [
        {
            id: 'open-smartspar',
            action: 'open-smartspar',
            type: 'spare-link-panel -commercial -smartspar',
            data: {
                text: ['Spareappen Smartspar', 'Appen for fonds-sparing og pensjon'],
                dataTrackId: Constant.trackIdService,
                dataTrackLinkname: 'spareappen-smartspar',
            },
        },
        {
            id: 'help-and-contact',
            url: getContactUsUrl(),
            type: 'spare-link-panel -commercial -advicer',
            data: {
                text: ['Rådgiving om fond- og pensjonssparing', 'Book et møte med en Autorisert Finansiell Rådgiver'],
                dataTrackId: Constant.trackIdService,
                dataTrackLinkname: 'rådgiving-om-fond-og-pensjonssparing',
            },
        },
        {
            id: 'eika-investor',
            url: `${window.location.origin}/EIT/`,
            type: 'list-item -link -external',
            data: {
                text: ['Eika Investortjenester'],
                dataTrackId: Constant.trackIdService,
                dataTrackLinkname: 'eika-investortjenester',
            },
            blank: true,
        },
        {
            id: 'norne',
            url: `${window.location.href.replace(/\/+$/g, '')}/norne`,
            type: 'list-item -link -external',
            data: {
                text: ['Aksjer (Norne)'],
                dataTrackId: Constant.trackIdService,
                dataTrackLinkname: 'aksjer-norne',
            },
        },
        {
            id: 'vps-invester',
            url: Constant.urlVPSinvestor,
            type: 'list-item -link -external',
            data: { text: ['VPS Investortjenester'] },
            dataTrackId: Constant.trackIdService,
            dataTrackLinkname: 'vps-investortjenester',
            blank: true,
        },
    ];

    return (
        <section aria-labelledby={id} className={classNames('other-service-button-list-wrapper', namespace)}>
            <h2 id={id}>Flere sparetjenester</h2>
            <ul className="other-service-button-list flat">
                {list.map((item: SpareLinkPanel, i: number) => {
                    return item.id === 'norne' && !showNorne ? null : (
                        <li key={`other-service-link-${i}`}>
                            <Card
                                {...item}
                                click={
                                    item.action
                                        ? (e: any) => {
                                              if (e && e.preventDefault()) {
                                                  e.preventDefault();
                                              }

                                              if (item.action === 'open-smartspar') {
                                                  openSmartspart('home', '', getSmartsparURL());
                                              }
                                          }
                                        : undefined
                                }
                            />
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};
