import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';

import VerticalProcess from '../../../components/vertical-progress/vertical-process';
import VerticalStep from '../../../components/vertical-progress/VerticalStep';
import { hentGyldigePortefoljer, kjopFond } from '../../../actions/fond';
import { settKvitteringsInnhold } from '../../../actions/modals';
import { hentKundeForhold } from '../../../actions/bank';
import { HENT_LISTE_OVER_CRS_LAND, hentKundevurdering, KUNDEVURDERING } from '../../../actions/kjenndinkunde';
import KjopFondKjopsSkjema from '../../../components/fond/kjop-fond/kjop-fond-kjops-skjema';
import KjopFondVelgPortefolje from '../../../components/fond/kjop-fond/kjop-fond-velg-portefolje';
import HelperFunctions from '../../../components/helper-functions';
import { settSideTittel } from '../../../actions/header';
import Feilmelding from '../../../components/feilmelding';
import { endreKundeInfo } from '../../../actions/spareprofil';
import LinkCard from '../../../components/link-card';
import ColorFunctions from '../../../components/color-functions';
import Format from '../../../components/format-functions';

import KjopFondOppsummering from '../../../components/fond/kjop-fond/kjop-fond-oppsummering';
import KjopFondSnakkMedRaadgiver from '../../../components/fond/kjop-fond/kjop-fond-snakk-med-raadgiver';
import { ASK, IPS, VPK } from '../../../domain/portefolje-types';
import StandaloneExpandableCard from '../../../components/common/standalone-expandable-card';
import GdprSamtykke from '../../../components/vilkaar/gdpr-samtykke';
import * as kundevurderingConst from '../../../domain/kundevurdering';
import KjennDinKundeForm from '../../../components/kjenndinkunde/KjennDinKundeForm';
import { getURLquery } from '../../../components/common/Functions';
import { getValidatedAISvalue } from '../../../components/fond/kjop-fond/kjop-fond-utils';
import KundevurderingStopp from '../../../components/KundevurderingStopp';


require('../../../styles/kjop-fond.scss');

class KjopFond extends Component {
    static propTypes = {
        params: PropTypes.object,
        kundeInfo: PropTypes.object,
        kundevurdering: PropTypes.string,
        portefoljeData: PropTypes.object,
        gyldigePortefoljer: PropTypes.object,
        texts: PropTypes.object,
        kjopStegStatus: PropTypes.object,
        settSideTittel: PropTypes.func,
        kjopFond: PropTypes.func,
        hentGyldigePortefoljer: PropTypes.func,
        settKvitteringsInnhold: PropTypes.func,
        errors: PropTypes.object,
        endreKundeInfo: PropTypes.func,
        hentKundeForhold: PropTypes.func,
        kundeforhold: PropTypes.array,
        router: PropTypes.object,
        hentKundevurdering: PropTypes.func,
        crsLand: PropTypes.array,
        standalone: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const { match } = props;
        const { params } = match;
        const { portefoljeId, sparemaalId, fondIsin, monthlyDeposit } = params;

        this.state = {
            visHjelpeteksterKjopsSkjema: false,
            visHjelpeteksterPortefoljeValg: false,
            lockedToPortfolio: !!portefoljeId,
            // kjennDinKundeFormSubmitted: false,
            kjopsOrdre: {
                sparemaalId,
                isin: fondIsin,
                portefoljeId,
                trekkDag: 1,
                spareBelop: monthlyDeposit ? parseInt(monthlyDeposit, 10) : 0,
                ais: false,
                aisFrequency: '0', // january
                aisValue: 0,
                aisValueError: '',
                aisValueUnitPercent: true,
                aisPlan: [],
            },
        };
    }

    componentDidMount = () => {
        const { match } = this.props;
        const { params } = match;
        const { fondIsin } = params;
        this.props.hentGyldigePortefoljer(fondIsin);
        this.props.hentKundevurdering();
        this.initialiserKjopsProsessState(this.props);
    };

    UNSAFE_componentWillReceiveProps = (newProps) => {
        const { currentStep } = this.state;
        this.initialiserKjopsProsessState(newProps);

        if (newProps.kjopStegStatus.done) {
            if (this.props.kjopStegStatus.done !== newProps.kjopStegStatus.done) {
                const nextStep = currentStep + 1;
                this.setState({ currentStep: nextStep });
                this.gaaTilNesteSteg(nextStep);
            }
        }
    };

    initialiserKjopsProsessState = (props) => {
        const { kjopsProsessData } = this.state;
        const { match, morningstar, portefoljeData, kundevurdering, kundeforhold, texts } = props;
        const { params } = match;
        const { fondIsin } = params;
        const { kjopsOrdre } = this.state;
        if (morningstar && !kjopsProsessData && portefoljeData && kundevurdering) {
            const { portefoljeId } = this.props.match.params;

            const fond = HelperFunctions.hentFondBasertPaaIsin(fondIsin, morningstar);

            if (!kundeforhold) {
                this.props.hentKundeForhold();
            }

            const tittel = HelperFunctions.VariableInjection(this.props.texts.get('kjopFondNavnTittel'), {
                fond: fond.navn,
            });
            this.props.settSideTittel(tittel);

            const klientKonto =
                portefoljeId &&
                portefoljeData.kontoer.find((konto) => konto.portefoljeId === this.props.match.params.portefoljeId);

            this.setState({
                fondsUtvikling: this.hentUtviklingAvFond(fond.utvikling, texts),
                kjopsOrdre: {
                    ...kjopsOrdre,
                    fondsNavn: fond.navn,
                    innskuddsKonto: undefined,
                },
                klientKonto: !!klientKonto,
                kjopsProsessData: this.settOppKjopsProsess(props),
                aksjesparekontokompatibel: fond.aksjesparekontoKompatibel,
                minimumsBelopEngangsKjop: fond.minimumsBelopEngangsKjop,
                minimumsBelopPeriodiskKjop: fond.minimumsBelopPeriodiskKjop,
            });
        }
    };

    hentPortefoljeBankRegnr = (portefoljeId) =>
        HelperFunctions.hentPortefoljeBasertPaaId(portefoljeId, this.props.portefoljeData.portefoljer).bank.bankregnr;

    hentUtviklingAvFond = (fondsUtvikling, texts) => {
        const finnGyldingUtvikling = (fondsUtvikling, texts) => {
            if (fondsUtvikling.prosentSiste5Aar) {
                return {
                    text: texts.get('utviklingSisteFemAar'),
                    value: fondsUtvikling.prosentSiste5Aar,
                };
            }

            if (fondsUtvikling.prosentSiste3Aar) {
                return {
                    text: texts.get('utviklingSisteTreAar'),
                    value: fondsUtvikling.prosentSiste3Aar,
                };
            }

            if (fondsUtvikling.iAar) {
                return {
                    text: texts.get('utviklingiAar'),
                    value: fondsUtvikling.iAar,
                };
            }

            return false;
        };

        const utvikling = finnGyldingUtvikling(fondsUtvikling, texts);

        return utvikling
            ? HelperFunctions.VariableInjection(utvikling.text, { prosent: Format.percent(utvikling.value) })
            : '';
    };

    // shouldShowKjennDinKundeForm = (kundevurdering) => {
    //     return !this.isKundevurderingOk(kundevurdering) || this.state.kjennDinKundeFormSubmitted;
    // };

    shouldShowKjennDinKundeForm = () => { return true; };

    isKundevurderingOk = (kundevurdering) => kundevurdering === kundevurderingConst.OK;

    settOppKjopsProsess = ({ texts, kundevurdering }) => ({
        currentStep: 0,
        steps: [
            {
                title: texts.get('velgBelop'),
                open: true,
                available: false,
                done: false,
            },
            {
                title: texts.get('velgPortefolje'),
                open: false,
                available: false,
                done: false,
            },
            this.shouldShowKjennDinKundeForm(kundevurdering) && {
                title: texts.get('egenerklaeringAntihvitvask'),
                open: false,
                available: false,
                done: false,
            },
            {
                title: texts.get('oppsummeringOgBekreftelse'),
                open: false,
                available: false,
                done: false,
            },
        ].filter(Boolean),
    });

    justerViewPort = (stepIndex) => {
        const element = document.getElementById(`step-${stepIndex}`);
        const elementRect = element.getBoundingClientRect();

        if (element && elementRect.top < 0 && element.scrollIntoView) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    hentPortefoljeNavn = (portefoljeId) =>
        HelperFunctions.hentPortefoljeBasertPaaId(portefoljeId, this.props.portefoljeData.portefoljer).navn;

    gaaTilNesteSteg = (clickIndex) => {
        const { kjopsProsessData } = this.state;
        this.justerViewPort(this.state.kjopsProsessData.currentStep);
        this.setState({
            kjopsProsessData: {
                ...kjopsProsessData,
                currentStep: clickIndex,
                steps: kjopsProsessData.steps.map((step, index) => {
                    return {
                        ...step,
                        open: index === clickIndex,
                        done: index < clickIndex ? true : step.done,
                    };
                }),
            },
        });
    };

    klikkPaaToppTekst = (clickIndex) => {
        const { kjopsProsessData } = this.state;
        if (this.state.kjopsProsessData.steps[clickIndex - 1].available) {
            this.setState({
                kjopsProsessData: {
                    ...kjopsProsessData,
                    steps: kjopsProsessData.steps.map((step, index) => {
                        return {
                            ...step,
                            open: !!(index === clickIndex - 1 && (step.available || step.done)),
                        };
                    }),
                },
            });
        }
    };

    oppdaterKjopsOrdre = (obj) => {
        const { kjopsOrdre } = this.state;
        const source = {...kjopsOrdre, ...obj };

        this.setState({
            kjopsOrdre: {
                ...source,
                aisValueError: source.ais ? (source.aisValueError || '') : '',
                aisPlan: source.ais && source.spareBelop ? HelperFunctions.getAISplan({
                    ...source,
                    drawDay: source.trekkDag || 1,
                    amount: source.spareBelop,
                    onlyWantDifferentDeposit: true,
                }) : []
            },
        });
    };

    fondsKjopOppsummering = (plassering) => {
        const { kundevurdering, errors, texts, kundeInfo, morningstar=[] } = this.props;
        if (kundevurdering || errors[KUNDEVURDERING]) {
            if (kundevurdering === kundevurderingConst.OK) {
                const fond = morningstar.find((d) => d.isin === (this.state.kjopsOrdre || {}).isin);
                return (
                    <KjopFondOppsummering
                        texts={texts}
                        kjopsOrdre={this.state.kjopsOrdre}
                        kjopFond={this.sendKjopsOrdre}
                        visEpostFelt={!kundeInfo.epost}
                        epost={kundeInfo.epost || this.state.epost}
                        settEpost={(epost) => this.setState(epost)}
                        plassering={plassering}
                        alder={kundeInfo.alder}
                        fondProduktarkUrl={(fond || {}).produktarkUrl || ''}
                        fond={fond}
                    />
                );
            }

            return <KjopFondSnakkMedRaadgiver texts={this.props.texts} />;
        }

        return <Loading title={this.props.texts.get('sjekkerOrdre')} />;
    };

    fjernNullVerdier = (kjopsOrdre) => ({
        ...kjopsOrdre,
        spareBelop: kjopsOrdre.spareBelop ? kjopsOrdre.spareBelop : undefined,
        engangsBelop: kjopsOrdre.engangsBelop ? kjopsOrdre.engangsBelop : undefined,
    });

    validerKjopsSteg = (kjopsOrdre) => {
        let validert = false;

        if (kjopsOrdre) {
            const mndSparing = !(
                kjopsOrdre.spareBelop &&
                !(kjopsOrdre.spareBelop >= this.state.minimumsBelopPeriodiskKjop && kjopsOrdre.sparebelopKonto)
            );

            const engangskjop = !(
                kjopsOrdre.engangsBelop &&
                !(
                    kjopsOrdre.engangsBelop >= this.state.minimumsBelopEngangsKjop &&
                    (kjopsOrdre.innskuddsKonto || this.state.klientKonto)
                )
            );

            // Sjekker at enten er ingenting eller alt satt på hver, og sjekker at minst en er satt
            validert = mndSparing && engangskjop && (kjopsOrdre.engangsBelop || kjopsOrdre.spareBelop);


            if ( kjopsOrdre.ais ) {
                const validated = getValidatedAISvalue( kjopsOrdre.aisValue, kjopsOrdre.aisValueUnitPercent, kjopsOrdre.spareBelop );
                if ( validated.error ) {
                    kjopsOrdre.aisValueError = this.props.texts.get(validated.error, {faktor: validated.factor});
                } else {
                    kjopsOrdre.aisValue = validated.value;
                    kjopsOrdre.aisValueError = '';
                }
            }
        }

        this.setState({
            visHjelpeteksterKjopsSkjema: !validert || !kjopsOrdre.aisValueError,
            kjopsOrdre,
        });

        return validert && !kjopsOrdre.aisValueError;
    };

    fullForKjopsSteg = () => {
        let nextStep = 1;
        if (this.validerKjopsSteg(this.state.kjopsOrdre)) {
            if (this.state.kjopsOrdre.portefoljeId) {
                nextStep = 2;
            }
            this.gaaTilNesteSteg(nextStep);
        }
    };

    godtaOpprettPortefoljeVilkaar = (checked) => this.setState({ godtattVilkaar: checked });

    fullForKjennDinKundeEgenerklaring = () => {
        // this.setState({ kjennDinKundeFormSubmitted: true });
        this.gaaTilNesteSteg(3);
    };

    velgPortefolje = (portefoljeId, bankregnr) => {
        const { kjopsOrdre } = this.state;
        this.setState({
            kjopsOrdre: {
                ...kjopsOrdre,
                portefoljeId,
                opprettPortefolje: null,
                bankregnr,
            },
            maaGodtaVilkaar: false,
        });
    };

    opprettPortefolje = (opprettPortefolje, maaGodtaVilkaar, bankregnr) => {
        const { kjopsOrdre } = this.state;
        this.setState({
            kjopsOrdre: {
                ...kjopsOrdre,
                portefoljeId: undefined,
                opprettPortefolje,
                bankregnr,
            },
            godtattVilkaar: false,
            maaGodtaVilkaar,
        });
    };

    velgKundeforhold = (bankregnr) => {
        const { kjopsOrdre } = this.state;
        this.setState({
            kjopsOrdre: {
                ...kjopsOrdre,
                bankregnr,
            },
        });
    };

    validerPortefoljeValg = () =>
        (this.state.kjopsOrdre.portefoljeId || this.state.kjopsOrdre.opprettPortefolje) &&
        (!this.state.maaGodtaVilkaar || this.state.godtattVilkaar) &&
        this.state.kjopsOrdre.bankregnr;

    fullForPortefoljeValg = () =>
        this.validerPortefoljeValg()
            ? this.gaaTilNesteSteg(2)
            : this.setState({ visHjelpeteksterPortefoljeValg: true });

    hentBankRegnrFraPortefolje = (kjopsOrdre) => ({
        value: this.props.match.params.portefoljeId
            ? this.hentPortefoljeBankRegnr(this.props.match.params.portefoljeId)
            : kjopsOrdre.bankregnr,
    });

    sendKjopsOrdre = () => {
        const kvittering = {
            type: 'kjopFond',
            lasteTekst: this.props.texts.get('fullforerOrdre'),
            kjopsOrdre: this.state.kjopsOrdre,
        };

        if (this.state.epost) {
            const { epost } = this.state;
            this.props.endreKundeInfo({ epost });
        }

        const { ais, aisFrequency, aisValue, aisPlan, aisValueUnitPercent, ...rest } = this.state.kjopsOrdre;
        const firstIncrease = (aisPlan || []).find( (d) => d.deposit !== rest.spareBelop );

        let kjopsOrdre = {
            ...rest,
            epost: this.state.epost || this.props.kundeInfo.epost, 
            automaticIncrease: firstIncrease && ais ? {
                isActive: true,
                startDate: firstIncrease.stamp || '',
                ...(aisValueUnitPercent ? {
                    increaseValueType: 'PERCENTAGE',
                    percentage: aisValue,
                    frequency: 'ANNUAL',
                } : {
                    increaseValueType: 'AMOUNT',
                    increaseAmount: aisValue,
                    frequency: aisFrequency,
                })
            } : undefined,         
        };

        for ( const key in kjopsOrdre ) {
            if ( /^ais/.test(key) ) { delete(kjopsOrdre[key]); }
        };

        // kjopsOrdre.automaticIncrease = HelperFunctions.getAISpostData( kjopsOrdre );
        kjopsOrdre.bankregnr = this.hentBankRegnrFraPortefolje(kjopsOrdre);

        kjopsOrdre = this.fjernNullVerdier(kjopsOrdre);

        this.props.settKvitteringsInnhold(kvittering);
        this.props.kjopFond(kjopsOrdre);
        const query = getURLquery();

        if ( this.props.standalone ) {
            this.props.history.push('/fond');
        } else if ( query.length ) {
            const length = parseInt( query.length, 10 );
            const count = parseInt( query.count, 10 );
            const diff = window.history.length - length;
            const back = diff <= count ? (count + 1) : diff;

            window.history.go((back * -1));
        } else {
            this.props.history.push('/');
        }
    };

    mapPortefoljeTypeToName = (type) => {
        switch (type) {
            case ASK:
                return this.props.texts.get('aksjesparekonto');
            case IPS:
                return this.props.texts.get('individuellPensjonssparingsKonto');
            case VPK:
                return this.props.texts.get('eikaVerdipapirKonto');
            default:
                return '--';
        }
    };

    skrivUtPlassering = (kjopsOrdre) => {
        if (kjopsOrdre.portefoljeId) {
            return this.hentPortefoljeNavn(kjopsOrdre.portefoljeId);
        }

        if (kjopsOrdre.opprettPortefolje) {
            const portefoljeNavn = this.mapPortefoljeTypeToName(kjopsOrdre.opprettPortefolje);
            return `${this.props.texts.get('ny')} ${portefoljeNavn}`;
        }

        return this.props.texts.get('ikkeValgt');
    };

    sjekkForServerFeil = (errors) =>
        errors.HENT_GYLDIGE_PORTEFOLJER ||
        errors.LAST_PORTEFOLJE ||
        errors[HENT_LISTE_OVER_CRS_LAND] ||
        errors.LAST_SPARE_PROFIL_DATA ||
        errors.HENT_KUNDEFORHOLD;

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        const gyldigePortefoljer = this.props.gyldigePortefoljer[this.props.match.params.fondIsin];
        const { portefoljeTilknyttetAnnenBank } = this.props.gyldigePortefoljer;

        if (this.props.kundevurdering === kundevurderingConst.MA_BEHANDLES_MANUELT ) {
            return <div className="paragraph -only-top">
                <KundevurderingStopp {...this.props} type="stand-alone" polite />
            </div>
        }

        if (
            this.state.kjopsProsessData &&
            this.props.kundeInfo &&
            this.props.portefoljeData &&
            gyldigePortefoljer &&
            this.props.kundeforhold
        ) {
            const { steps } = this.state.kjopsProsessData;
            const plassering = this.skrivUtPlassering(this.state.kjopsOrdre);

            return (
                <div className="kjop-fond">
                    <LinkCard gradient={ColorFunctions.hentGradient(1)}>
                        <div className="kjop-fond-kort">
                            <div className="fond-navn">{this.state.kjopsOrdre.fondsNavn}</div>
                            <div className="utvikling">{this.state.fondsUtvikling}</div>
                            <div className="plassering">
                                {`${this.props.texts.get('portefoljePlassering')}: ${plassering}`}
                            </div>
                        </div>
                    </LinkCard>

                    <VerticalProcess>
                        <VerticalStep
                            {...steps[0]}
                            onNextClick={this.fullForKjopsSteg}
                            onHeaderClick={this.klikkPaaToppTekst}
                            stepIndex={0}
                        >
                            <KjopFondKjopsSkjema
                                texts={this.props.texts}
                                kontoer={this.props.portefoljeData.kontoer}
                                oppdaterKjopFondData={this.oppdaterKjopsOrdre}
                                minimumsBelopEngangsKjop={this.state.minimumsBelopEngangsKjop}
                                minimumsBelopPeriodiskKjop={this.state.minimumsBelopPeriodiskKjop}
                                visHjelpetekst={this.state.visHjelpeteksterKjopsSkjema}
                                klientKonto={this.state.klientKonto}
                                lockedToPortfolio={this.state.lockedToPortfolio}
                                spareBelop={this.state.kjopsOrdre.spareBelop}
                                ais={this.state.kjopsOrdre.ais}
                                aisFrequency={this.state.kjopsOrdre.aisFrequency}
                                aisValue={this.state.kjopsOrdre.aisValue}
                                aisValueError={this.state.kjopsOrdre.aisValueError}
                                aisValueUnitPercent={this.state.kjopsOrdre.aisValueUnitPercent}
                                aisPlan={this.state.kjopsOrdre.aisPlan}
                                isIPS={ this.props.match?.params?.portefoljeId ? ((this.props.portefoljeData?.portefoljer || []).find ( (d) => {
                                    return `${d.id}` === `${this.props.match?.params?.portefoljeId}`;
                                }) || {}).type === 'IPS' : false}
                            />
                        </VerticalStep>
                        <VerticalStep
                            {...steps[1]}
                            onNextClick={this.fullForPortefoljeValg}
                            onHeaderClick={this.klikkPaaToppTekst}
                            stepIndex={1}
                        >
                            <KjopFondVelgPortefolje
                                texts={this.props.texts}
                                portefoljeTilknyttetAnnenBank={portefoljeTilknyttetAnnenBank}
                                portefoljer={gyldigePortefoljer}
                                aksjesparekontokompatibel={this.state.aksjesparekontokompatibel}
                                velgPortefolje={this.velgPortefolje}
                                opprettPortefolje={this.opprettPortefolje}
                                godtaOpprettPortefoljeVilkaar={this.godtaOpprettPortefoljeVilkaar}
                                visHjelpetekst={this.state.visHjelpeteksterPortefoljeValg}
                                kundeforhold={this.props.kundeforhold}
                                velgKundeforhold={this.velgKundeforhold}
                                valgtKundeforhold={!!this.state.kjopsOrdre.bankregnr}
                                portefoljeData={this.props.portefoljeData}
                            />
                        </VerticalStep>
                        {this.shouldShowKjennDinKundeForm(this.props.kundevurdering) && (
                            <VerticalStep {...steps[2]} onHeaderClick={this.klikkPaaToppTekst} stepIndex={2}>
                                <KjennDinKundeForm
                                    texts={this.props.texts}
                                    bankregnr={this.hentBankRegnrFraPortefolje(this.state.kjopsOrdre)}
                                    onSubmit={this.fullForKjennDinKundeEgenerklaring}
                                    rotNodeId="#sparing-react-app"
                                    displayNesteButton
                                />
                            </VerticalStep>
                        )}
                        <VerticalStep
                            {...steps[this.shouldShowKjennDinKundeForm(this.props.kundevurdering) ? 3 : 2]}
                            onNextClick={undefined}
                            onHeaderClick={this.klikkPaaToppTekst}
                            stepIndex={this.shouldShowKjennDinKundeForm(this.props.kundevurdering) ? 3 : 2}
                        >
                            {this.fondsKjopOppsummering(plassering)}
                        </VerticalStep>
                    </VerticalProcess>
                    <StandaloneExpandableCard header={this.props.texts.get('personvern')}>
                        <GdprSamtykke />
                    </StandaloneExpandableCard>
                </div>
            );
        }

        return <Loading title={this.props.texts.get('lasterFondsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Fond,
            ...state.Bank,
            crsLand: state.KjennDinKunde.crsLand,
            kundevurdering: state.KjennDinKunde.kundevurdering,
            ...state.Feilmeldinger,
            ...state.SpareProfil,
            ...state.Texts,
        };
    },
    (dispatch) => {
        return {
            kjopFond: (fondsOrdre) => {
                dispatch(kjopFond(fondsOrdre));
            },
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            hentGyldigePortefoljer: (fondIsin) => {
                dispatch(hentGyldigePortefoljer(fondIsin));
            },
            settKvitteringsInnhold: (innhold) => {
                dispatch(settKvitteringsInnhold(innhold));
            },
            endreKundeInfo: (endring) => {
                dispatch(endreKundeInfo(endring));
            },
            hentKundeForhold: () => {
                dispatch(hentKundeForhold());
            },
            hentKundevurdering: () => {
                dispatch(hentKundevurdering());
            },
        };
    }
)(KjopFond);
