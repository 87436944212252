/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ExpandableCard from '../../expandable-card';
import MorningStarIFrame from '../morningstar';
import FondsInformasjon from '../fonds-informasjon';

class KjopFondInngang extends Component {
    static propTypes = {
        texts: PropTypes.object,
        fond: PropTypes.object,
        expandItem: PropTypes.object,
        portefoljeId: PropTypes.string,
        viskjopsKnapp: PropTypes.bool,
        sparemaalId: PropTypes.string,
        hidden: PropTypes.bool
    };

    render = () => (
        <ExpandableCard
            hidden={this.props.hidden}
            header={
                <FondsInformasjon
                    texts={this.props.texts}
                    fond={this.props.fond}
                    visKjopKnapp={this.props.viskjopsKnapp}
                    portefoljeId={this.props.portefoljeId}
                    sparemaalId={this.props.sparemaalId}
                />
            }
            expandItem={this.props.expandItem}
        >
            <div className="fond-nedfellbar-meny">
                <MorningStarIFrame fondId={this.props.fond.id} />
            </div>
        </ExpandableCard>
    );
}

export default KjopFondInngang;
