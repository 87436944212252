import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';
import { Modal } from '@eika/modal';
import { LinkButton } from '@eika/button';
import { IconTypes } from '@eika/icon';
import { ErrorLabel } from '@eika/label';
import { formatAmount, formatDateToText, getPageAppending } from '../../../common/util/Functions';
import { usePrevious, useStorebandInfo, useStorebandPensionPayout } from '../../../common/util/Hook';
import Grid from '../../../common/Grid/Grid';
import Card, { Props as CardProps } from '../../../common/Card/Card';
import Message from '../../../common/Message/Message';
import AppMessage from '../../../common/AppMessage/AppMessage';
import { HeaderLink, Spinner } from '../../../common/share/ShareComponents';
import { StorebrandPayout } from '../../../domain/Storebrand';
import '../Arbeidsgiver.scss';
import './Storebrand.scss';
import { ContentData, ReducerKey, WithNestedData } from './types';
import safeArray from '../util/SafeArray';
import { RouterConfig } from '../../../app/Router';

type Props = RouteComponentProps<{ id?: string }> & RouterConfig;

interface Content {
    error: string;
    totalValue?: number;
    product?: string;
    category?: string;
    agreement?: ContentData;
    portfolioBaseCode?: string[];
    detail?: ContentData;
}

interface Transaction {
    order?: string[];
    pin: { [k: string]: any };
    hasMore: boolean;
}

interface State {
    id: string;
    appending: string;
    loading: boolean;
    modal: boolean;
    content?: Content;
    transaction?: Transaction;
    pendingTransaction?: Transaction;
}

const getContent = (
    props: Props,
    state: State,
    setState: React.Dispatch<React.SetStateAction<State>>,
    reducer: any
): void => {
    const content: Content = { error: '', ...(state.content ?? {}) };
    if (content.agreement) {
        return;
    }

    const payout = reducer.Storebrand.payout;
    const info = (reducer.Storebrand.info ?? {})[state.id];

    if (info === null) {
        content.error = props.appTexts.get('opsProvIgjen');
    } else {
        const { savingRateSetup = {}, additional = {} } = info.savingsRate || {};
        const payoutList: StorebrandPayout[] = (payout || []).map((d: StorebrandPayout) => {
            return { ...d, amount: formatAmount(d.amount || 0, true) };
        });

        content.agreement = {
            text: ['Arbeidsgiver', info.employerName],
            arrangement: [
                {
                    data: {
                        textList: [
                            [
                                { text: 'Fra 0.0 G til 7.1 G' },
                                { text: `${formatAmount(savingRateSetup.savingsRatePercent)} %` },
                            ],
                            [
                                {
                                    text: `Sparing av lønn mellom ${formatAmount(
                                        savingRateSetup.bottomSalary,
                                        true
                                    )} kr og ${formatAmount(savingRateSetup.topSalary, true)} kr`,
                                },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                { text: 'Fra 7.1 G til 12.0 G' },
                                { text: `${formatAmount(additional.savingsRatePercent)} %` },
                            ],
                            [
                                {
                                    text: `Tilleggssparing av lønn mellom ${formatAmount(
                                        additional.bottomSalary,
                                        true
                                    )} kr og ${formatAmount(additional.topSalary, true)} kr`,
                                },
                            ],
                        ],
                    },
                },

                {
                    data: {
                        textList: [
                            [
                                {
                                    text: 'Årlig sparing',
                                    type: 'font-normal',
                                },
                                { text: `${formatAmount(info.employerAnnualSavingsAmount, true)} kr`, type: '' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                { text: 'Lønn', type: 'font-normal' },
                                {
                                    text: `${formatAmount(info.salary, true)} kr`,
                                    type: '',
                                },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                {
                                    text: 'Stillingsprosent',
                                    type: 'font-normal',
                                },
                                { text: `${formatAmount(info.employmentPercentage, true)} %`, type: '' },
                            ],
                        ],
                    },
                },
            ],
            payout: [
                {
                    data: {
                        textList: [
                            [
                                { text: 'Investeringsvalg', type: 'font-normal' },
                                { text: info.riskProfile, type: '' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                {
                                    text: 'Utbetalingsstart',
                                    type: 'font-normal',
                                },
                                { text: formatDateToText(new Date(info.payOutStart), 'dd.mm.yyyy'), type: '' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                {
                                    text: 'Utbetalingen opphører',
                                    type: 'font-normal',
                                },
                                { text: formatDateToText(new Date(info.payOutEnd), 'dd.mm.yyyy'), type: '' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                { text: 'Forventet årlig utbetaling fra 67 år', type: 'font-normal' },
                                payoutList.length
                                    ? {
                                          element: (
                                              <LinkButton
                                                  onClick={() => {
                                                      setState((s: State) => ({ ...s, modal: true }));
                                                  }}
                                                  iconOnRightSide
                                              >{`Fra ${payoutList[0].amount}`}</LinkButton>
                                          ),
                                      }
                                    : { text: props.appTexts.get('ops'), type: '' },
                            ],
                        ],
                    },
                },
            ],
            insurance: [
                {
                    data: {
                        textList: [
                            [
                                {
                                    text: 'Forsikringsdekninger',
                                    type: 'font-normal',
                                },
                                { text: 'Innskuddsfritak og uførepensjon', type: '' },
                            ],
                        ],
                    },
                },
            ],
            expectation: payoutList.map((d: StorebrandPayout) => {
                return {
                    data: {
                        textList: [
                            [
                                {
                                    text: `Forventet årlig utbetaling fra ${d.age} år:`,
                                    type: 'font-normal',
                                },
                                { text: `${d.amount} kr`, type: '' },
                            ],
                        ],
                    },
                };
            }),
        };
    }

    setState({ ...state, content, loading: false });
};

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Storebrand'].reduce((p: Record<ReducerKey, any>, k: string) => {
            return { ...p, [k]: (state || {})[k] || {} };
        }, {} as Record<ReducerKey, any>);
    });

    const [state, setState] = useState<State>({
        loading: true,
        modal: false,
        appending: getPageAppending(props),
        id: props?.match?.params.id || '',
    });

    const previousReducer = usePrevious(reducer);
    useEffect(() => {
        const ignore =
            !!state.content ||
            reducer.Storebrand?.info === undefined ||
            reducer.Storebrand?.payout === undefined ||
            JSON.stringify(reducer) === JSON.stringify(previousReducer);
        if (ignore) {
            return;
        }

        getContent(props, state, setState, reducer);
    }, [previousReducer, reducer, state, props]);

    useStorebandPensionPayout(reducer, props.action, state.id);
    useStorebandInfo(reducer, props.action);

    return (
        <div className="storebrand-agreement-wrapper">
            <Grid
                namespace="sparing-top"
                list={[
                    {
                        layout: 'twelve',
                        element: <HeaderLink text="Eika Innskuddspensjon" />,
                    },
                ]}
            />

            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: (
                            <>
                                <h1>Om pensjonsavtalen din</h1>
                            </>
                        ),
                    },
                ]}
            />

            <div
                className={`arbeidsgiver-profil-common-wrapper storebrand-agreement-content ${
                    state.appending || 'none-appending'
                }`}
            >
                <Grid
                    namespace="sparing-content"
                    list={[
                        {
                            layout: 'seven',
                            element: (
                                <>
                                    <AppMessage
                                        appMessage={reducer?.App?.serviceMessage}
                                        context="STOREBRAND_RISK_PROFILE"
                                    />

                                    {!!state.content?.error && (
                                        <ErrorLabel icon={IconTypes.UTROPSTEGN_24} text={state.content.error} />
                                    )}

                                    {!!state.content?.agreement && (
                                        <div className="paragraph">
                                            <Message
                                                type="info-message"
                                                namespace="employer-info-message"
                                                role=""
                                                {...(state.content.agreement as WithNestedData)}
                                            />

                                            {!!(state.content?.agreement as WithNestedData)?.arrangement && (
                                                <section
                                                    aria-labelledby="storebrand-agreement-arrangement"
                                                    className="storebrand-agreement-arrangement-wrapper"
                                                >
                                                    <h2
                                                        id="storebrand-agreement-arrangement"
                                                        className="section-title -type-2"
                                                    >
                                                        Om pensjonsordningen
                                                    </h2>
                                                    {safeArray(
                                                        (state.content?.agreement as WithNestedData)?.arrangement
                                                    ).map((d: CardProps, i: number) => (
                                                        <Card key={`arrangement-item-${i}`} {...d} type="list-item" />
                                                    ))}
                                                </section>
                                            )}

                                            {!!(state.content?.agreement as WithNestedData)?.payout && (
                                                <section
                                                    aria-labelledby="storebrand-agreement-payout"
                                                    className="storebrand-agreement-payout-wrapper"
                                                >
                                                    <h2
                                                        id="storebrand-agreement-payout"
                                                        className="section-title -type-2"
                                                    >
                                                        Utbetaling av pensjon
                                                    </h2>
                                                    {safeArray(
                                                        (state.content?.agreement as WithNestedData)?.payout
                                                    ).map((d: CardProps, i: number) => (
                                                        <Card key={`payout-item-${i}`} {...d} type="list-item" />
                                                    ))}
                                                </section>
                                            )}
                                        </div>
                                    )}

                                    {state.loading && <Spinner type="linkPanel" />}
                                </>
                            ),
                        },
                        {
                            layout: 'four',
                            element: (
                                <>
                                    {!!(state.content?.agreement as WithNestedData)?.insurance && (
                                        <section
                                            aria-labelledby="storebrand-agreement-insurance"
                                            className="storebrand-agreement-insurance-wrapper"
                                        >
                                            <h2 id="storebrand-agreement-insurance" className="section-title -type-2">
                                                Forsikring
                                            </h2>
                                            {safeArray((state.content?.agreement as WithNestedData)?.insurance).map(
                                                (d: CardProps, i: number) => (
                                                    <Card key={`insurance-item-${i}`} {...d} type="list-item" />
                                                )
                                            )}
                                        </section>
                                    )}

                                    {state.loading && <Spinner type="linkPanel" />}
                                </>
                            ),
                        },
                    ]}
                />
            </div>

            <Modal
                id="storebrand-agreement-modal"
                appNamespace="sparing-react-app"
                onClose={() => {
                    setState({ ...state, modal: false });
                }}
                show={state.modal}
            >
                <h2 className="h2 paragraph">{props.appTexts.get('storebrandExpectedPayoutTitle')}</h2>
                <p>{props.appTexts.get('storebrandExpectedPayoutDescription')}</p>
                {!!(state.content?.agreement as WithNestedData)?.expectation && (
                    <div className="storebrand-agreement-expectation-wrapper paragraph -only-top">
                        {safeArray((state.content?.agreement as WithNestedData)?.expectation).map(
                            (d: CardProps, i: number) => (
                                <Card key={`insurance-item-${i}`} {...d} type="list-item" />
                            )
                        )}
                    </div>
                )}
            </Modal>
        </div>
    );
};
