import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '@eika-infrastruktur/ui-kort-laster';
import { Link } from 'react-router-dom';

import { settSideTittel } from '../../actions/header';
import { getSparemaal, updateSparemaal } from '../../actions/sparemaal';

import Feilmelding from '../../components/feilmelding';
import SparemaalUtils from './sparemaal-utils';

import SparemiksFondsKort from '../../components/sparemaal/sparemiks-fondskort';
import { BANKKONTO } from '../../domain/account-types';
import SparemiksKontoKort from '../../components/sparemaal/sparemiks-kontokort';
import SparemiksSpareAvtaleKort from '../../components/sparemaal/sparemiks-spareavtale-kort';
import HelperFunctions from '../../components/helper-functions';
import Format from '../../components/format-functions';
import ActionButton from '../../components/action-button';

require('../../styles/sparemiks.scss');

class Sparemiks extends Component {
    static propTypes = {
        match: PropTypes.object,
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        errors: PropTypes.object,
        getSparemaal: PropTypes.func,
        sparemaal: PropTypes.array,
        portefoljeData: PropTypes.object,
        updateSparemaal: PropTypes.func,
        kontoer: PropTypes.array,
        history: PropTypes.object,
    };

    UNSAFE_componentWillMount = () => {
        if (!this.props.sparemaal) {
            this.props.getSparemaal();
        }

        this.hentSparemaal(this.props.match.params.id, this.props.sparemaal);
        this.props.settSideTittel(this.props.texts.get('sparemaalSparemiks'));
    };

    UNSAFE_componentWillReceiveProps = (newProps) => this.hentSparemaal(newProps.match.params.id, newProps.sparemaal);

    hentSparemaal = (id, sparemaal) => {
        if (sparemaal) {
            const sparemaalUnique = SparemaalUtils.fetchSparemaalFromId(sparemaal, id);
            this.setState({ sparemaal: sparemaalUnique });
            this.props.settSideTittel(`${this.props.texts.get('sparemaalSparemiks')}: ${sparemaalUnique.name}`);
        }
    };

    sjekkForServerFeil = (errors) => errors.HENT_SPAREMAAL;

    editincludedAccounts = (checked, accountNumber) => {
        const { sparemaal } = this.state;
        const { includedAccounts } = sparemaal;

        if (!checked && includedAccounts[accountNumber]) {
            delete includedAccounts[accountNumber];
        } else {
            includedAccounts[accountNumber] = true;
        }
        this.setState(sparemaal);
    };

    adjustIncludedFundShares = (amount, isin, portefoljeId) => {
        const { sparemaal } = this.state;
        const { includedFunds } = sparemaal;

        if (amount === 0 && includedFunds[`${portefoljeId}-${isin}`]) {
            delete includedFunds[`${portefoljeId}-${isin}`];
        } else {
            includedFunds[`${portefoljeId}-${isin}`] = amount;
        }

        this.setState(sparemaal);
    };

    editIncludedSpareavtaler = (checked, uniqueId) => {
        const { sparemaal } = this.state;
        const { includedSpareavtaler } = sparemaal;

        if (!checked && includedSpareavtaler[uniqueId]) {
            delete includedSpareavtaler[uniqueId];
        } else {
            includedSpareavtaler[uniqueId] = true;
        }
        this.setState(sparemaal);
    };

    updateSparemaal = () => {
        this.props.updateSparemaal(this.state.sparemaal);
        this.props.history.push(`/sparemaal/${this.state.sparemaal.id}`);
    };

    oremerketAmount = (isin, portefoljeId) => this.state.sparemaal.includedFunds[`${portefoljeId}-${isin}`] || 0;

    findEntries = (arrayName, identifier) =>
        this.props.sparemaal
            .map((sparemaal) =>
                sparemaal[arrayName][identifier]
                    ? {
                          sparemaal,
                          value: sparemaal[arrayName][identifier],
                      }
                    : undefined
            )
            .filter((el) => el);

    spareAvtaleKnyttetTilSparemaal = (uniqueId) => this.findEntries('includedSpareavtaler', uniqueId);

    fondKnyttetTilSparemaal = (isin, portefoljeId) => this.findEntries('includedFunds', `${portefoljeId}-${isin}`);

    sparekontoKnyttetTilSparemaal = (kontonummer) => this.findEntries('includedAccounts', kontonummer);

    andreOremerkinger = (text, id, name) => (
        <div key={id}>
            <span>{text}&nbsp;</span>
            <Link to={`/sparemaal/${id}/`}>{name}</Link>
        </div>
    );

    oremerketTextForFunds = (value, isAll) =>
        isAll
            ? this.props.texts.get('sparemaalSparemiksHeleFondetOremerket')
            : HelperFunctions.VariableInjection(this.props.texts.get('sparemaalSparemiksFondOremerketTil'), {
                  sum: Format.currency(value),
              });

    sparemiksFondsKort = (isin, id, portefoljenavn, fondsnavn, totalVerdi) => {
        const sparemaal = this.fondKnyttetTilSparemaal(isin, id).filter(
            (s) => s.sparemaal.id !== this.state.sparemaal.id
        );
        const verdi = totalVerdi - sparemaal.reduce((accumulator, currentObj) => currentObj.value + accumulator, 0);
        const andreSparemaal = sparemaal.map((s) =>
            this.andreOremerkinger(
                this.oremerketTextForFunds(s.value, s.value === totalVerdi),
                s.sparemaal.id,
                s.sparemaal.name
            )
        );

        return (
            <SparemiksFondsKort
                key={`fondskort-${isin}-${id}`}
                isin={isin}
                texts={this.props.texts}
                fondsnavn={fondsnavn}
                portefoljeId={id}
                portefoljenavn={portefoljenavn}
                totalverdi={verdi}
                oremerket={this.oremerketAmount(isin, id)}
                andreSparemaal={andreSparemaal}
                onChange={(amount) => this.adjustIncludedFundShares(amount, isin, id)}
            />
        );
    };

    sparemiksSpareAvtaleKort = (portefoljenavn, sparemaalId, navn, trekkBelop) => {
        const sparemaal = this.spareAvtaleKnyttetTilSparemaal(sparemaalId);
        const oremerket = sparemaal.some((s) => s.sparemaal.id === this.state.sparemaal.id);
        const andreSparemal = !oremerket
            ? sparemaal.map((s) =>
                  this.andreOremerkinger(
                      this.props.texts.get('sparemaalSparemiksSpareavtaleOremerketTil'),
                      s.sparemaal.id,
                      s.sparemaal.name
                  )
              )
            : [];

        return (
            <SparemiksSpareAvtaleKort
                key={`spareavtale-${sparemaalId}`}
                texts={this.props.texts}
                fondsnavn={navn}
                portefoljenavn={portefoljenavn}
                uniqueId={sparemaalId}
                mndBelop={trekkBelop}
                andreSparemaal={andreSparemal}
                checked={oremerket}
                onChange={(checked) => this.editIncludedSpareavtaler(checked, sparemaalId)}
            />
        );
    };

    sparemiksKontoKort = (kontonummer, navn, verdi) => {
        const sparemaal = this.sparekontoKnyttetTilSparemaal(kontonummer);
        const oremerket = sparemaal.some((s) => s.sparemaal.id === this.state.sparemaal.id);
        const andreSparemal = !oremerket
            ? sparemaal.map((s) =>
                  this.andreOremerkinger(
                      this.props.texts.get('sparemaalSparemiksKontoOremerketTil'),
                      s.sparemaal.id,
                      s.sparemaal.name
                  )
              )
            : [];

        return (
            <SparemiksKontoKort
                key={kontonummer}
                texts={this.props.texts}
                kontoNavn={navn}
                kontoNummer={kontonummer}
                saldo={verdi}
                andreSparemaal={andreSparemal}
                checked={oremerket}
                onChange={(checked) => this.editincludedAccounts(checked, kontonummer)}
            />
        );
    };

    listUtSparemiksFondsKort = () =>
        this.props.portefoljeData.portefoljer
            .filter((portefolje) => portefolje.fond)
            .map((portefolje) =>
                portefolje.fond
                    .filter((fond) => fond.totalVerdi > 0)
                    .map((fond) =>
                        this.sparemiksFondsKort(fond.isin, portefolje.id, portefolje.navn, fond.navn, fond.totalVerdi)
                    )
            );

    listUtSparemiksKontoKort = () =>
        this.props.kontoer
            .filter((konto) => konto.bankkontoType === BANKKONTO)
            .map((konto) => this.sparemiksKontoKort(konto.kontonummer, konto.navn, konto.verdi));

    listUtSparemiksSpareAvtaleKort = () =>
        this.props.portefoljeData.portefoljer
            .filter((portefolje) => portefolje.fond)
            .map((portefolje) =>
                portefolje.fond
                    .filter((fond) => fond.spareavtaler)
                    .map((fond) =>
                        fond.spareavtaler.map((spareavtale) =>
                            this.sparemiksSpareAvtaleKort(
                                portefolje.navn,
                                spareavtale.id,
                                fond.navn,
                                spareavtale.trekkBelop
                            )
                        )
                    )
            );

    render() {
        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.sparemaal) {
            return (
                <div className="sparemiks">
                    <div className="title">{this.props.texts.get('sparemaalSparemiksVelgFondTittel')}</div>
                    <div className="ingress">{this.props.texts.get('sparemaalSparemiksVelgFondIngress')}</div>
                    <div>{this.listUtSparemiksFondsKort()}</div>
                    <div className="title">{this.props.texts.get('sparemaalSparemiksVelgSparekontoerTittel')}</div>
                    <div className="ingress">{this.props.texts.get('sparemaalSparemiksVelgSparekontoerIngress')}</div>
                    <div>{this.listUtSparemiksKontoKort()}</div>
                    <div className="title">{this.props.texts.get('sparemaalSparemiksVelgSpareavtalerTittel')}</div>
                    <div className="ingress">{this.props.texts.get('sparemaalSparemiksVelgSpareavtalerIngress')}</div>
                    <div>{this.listUtSparemiksSpareAvtaleKort()}</div>
                    <ActionButton
                        text={this.props.texts.get('sparemaalSparemiksBekreft')}
                        action={this.updateSparemaal}
                    />
                </div>
            );
        }
        return <Loading title={this.props.texts.get('lasterPensjonsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Feilmeldinger,
            ...state.Texts,
            ...state.Sparemaal,
            ...state.Fond,
            ...state.Bank,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
            getSparemaal: () => {
                dispatch(getSparemaal());
            },
            updateSparemaal: (data) => {
                dispatch(updateSparemaal(data));
            },
        };
    }
)(Sparemiks);
