import { combineReducers } from 'redux';

import Fond from './fond';
import KjennDinKunde from './kjenndinkunde';
import SpareProfil from './spareprofil';
import Bank from './bank';
import Modals from './modals';
import Feilmeldinger from './feilmeldinger';
import Pensjon from './pensjon';
import Ordre from './ordre';
import Header from './header';
import Texts from './texts';
import Sparemaal from './sparemaal';
import AskPopup from './ask-popup';
import SmartsparPopup from './smartspar-popup';

export default combineReducers({
    Fond,
    Pensjon,
    KjennDinKunde,
    SpareProfil,
    Bank,
    Modals,
    Feilmeldinger,
    Ordre,
    Header,
    Texts,
    Sparemaal,
    AskPopup,
    SmartsparPopup,
});
