import { HENT_SPAREMAAL, OPPRETT_SPAREMAAL } from '../actions/sparemaal';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case OPPRETT_SPAREMAAL:
        case HENT_SPAREMAAL:
            return {
                ...state,
                sparemaal: action.data,
            };
        default:
            return state;
    }
};
