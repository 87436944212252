const anbefalingsmatrise = [
    {
        tidshorisont: { start: 0, end: 2 },
        risiko: {
            0: 'NO0010172521',
            1: 'NO0010172521',
            2: 'NO0010165335'
        }
    },
    {
        tidshorisont: { start: 2, end: 5 },
        risiko: {
            0: 'NO0010172521',
            1: 'NO0010165335',
            2: 'NO0010165335'
        }
    },
    {
        tidshorisont: { start: 5, end: 999 },
        risiko: {
            0: 'NO0010165335',
            1: 'NO0010165335',
            2: 'NO0010003999'
        }
    }
];

export const anbefalingstekstKey = {
    NO0010165335: 'viAnbefalerEikaBalansert',
    NO0010172521: 'viAnbefalerEikaPengemarked',
    NO0010003999: 'viAnbefalerEikaSpar'
};

export const hentAnbefaling = (tidshorisont, risikoprofil) =>
    anbefalingsmatrise.find(
        anbefaling => anbefaling.tidshorisont.start < tidshorisont && tidshorisont <= anbefaling.tidshorisont.end
    ).risiko[risikoprofil];
