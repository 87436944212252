import React from 'react';
import PropTypes from 'prop-types';

const sparemaalIkoner = {
    pensjon: 'ikon-palme-24',
    bolig: 'ikon-hus-24',
    barna: 'ikon-ung-24',
    buffer: 'ikon-lommebok-24',
    ferie: 'ikon-forsikringsbevis-reise-24',
    bareSparer: 'ikon-sparegris-24',
    innskudd: 'ikon-sparing-36',
    sparemiks: 'ikon-innstillinger-36',
    rediger: 'ikon-Innstillinger-36'
};

const SparemaalIkoner = props => (
    <div className="sparemaal-icon">
        <i className={sparemaalIkoner[props.id]} />
    </div>
);

SparemaalIkoner.propTypes = {
    id: PropTypes.string.isRequired
};

export default SparemaalIkoner;
