
/** ****************************************************************************
 ***************************************************************************** */
const getEikaRiskProfileInfomation = () => {
    return [{
        reg: /livssyklus/i,
        profile: [
            ['Risikoprofil', 'Livssyklus'],
            ['Aksjer', '100%'],
        ],
        price: [
            ['Priser'],
            ['Kjøp', '0%'],
            ['Salg', '0%'],
            ['Forvaltning', '0,7%'],
        ],
        title: 'Om Livssyklus risikoprofil',
        name: 'Livssyklus risikoprofil',
        text: [
            'Eika Selvvalgt Livssyklus er en spareprofil hvor sparemidlene plasseres i aksje- og rentefond forvaltet av Eika Kapitalforvaltning AS.',
            'Gjennom profilen vil man være investert i 100 % aksjer frem til man fyller 60 år. I perioden fra man er 60 år til man fyller 67 år vil risikoen i fondet gradvis reduseres ved at aksjeandelen reduseres til 40 % og renteandelen økes til 60 %.',
        ],
    }, {
        reg: /aksjer/i,
        profile: [
            ['Risikoprofil', 'Høy'],
            ['Renter', '0%'],
            ['Aksjer', '100%'],
        ],
        price: [
            ['Priser'],
            ['Kjøp', '0%'],
            ['Salg', '0%'],
            ['Forvaltning', '0,7%'],
        ],
        title: 'Om Høy risikoprofil',
        name: 'Høy risikoprofil',
        text: [
            'Eika Selvvalgt 100% Aksjer er en spareprofil hvor 100% av sparemidlene er plassert i aksjer forvaltet av Eika Kapitalforvaltning AS.',
            'Profilen har ingen nedtrapping mot pensjonsalder og har binding minst til 62 år.',
        ],
    }, {
        reg: /moderat/i,
        profile: [
            ['Risikoprofil', 'Moderat'],
            ['Renter', '35%'],
            ['Aksjer', '65%'],
        ],
        price: [
            ['Priser'],
            ['Kjøp', '0%'],
            ['Salg', '0%'],
            ['Forvaltning', '0,58%'],
        ],
        title: 'Om Moderat risikoprofil',
        name: 'Moderat risikoprofil',
        text: [
            'Eika Selvvalgt Moderat er en spareprofil hvor sparemidlene plasseres i aksje- og rentefond forvaltet av Eika Kapitalforvaltning AS.',
            'Gjennom profilen vil man normalt være investert i 65 % aksjer og 35 % renter. Plasseringene vil rebalanseres til normalvekt minst én gang i året, og uansett ved avvik fra normalvekten med mer enn 5 %. Profilen passer for en investor som ønsker en balansert risiko mellom aksjer og renter.',
        ],
    }, {
        reg: /lav/i,
        profile: [
            ['Risikoprofil', 'Lav'],
            ['Renter', '100%'],
        ],
        price: [
            ['Priser'],
            ['Kjøp', '0%'],
            ['Salg', '0%'],
            ['Forvaltning', '0,35%'],
        ],
        title: 'Om Lav risikoprofil',
        name: 'Lav risikoprofil',
        text: [
            'Eika Selvvalgt Forsiktig er en spareprofil hvor sparemidlene plasseres i rentefond forvaltet av Eika Kapitalforvaltning AS.',
            'Gjennom profilen vil din pensjonskapital bli investert i det norske og nordiske rentemarkedet som generelt kjennetegnes av lav til moderat risiko. Avkastning og risiko i profilen avhenger av det generelle rentenivået og hvordan rentemarkedet vurderer risikobildet til det enkelte selskap og markedet generelt.',
        ],
    }];
};

export default getEikaRiskProfileInfomation