import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hentVisSmartSparPopup, skjulSmartSparPopup, registrerSmartSparPopupVisning } from '../actions/modals';
import SmartSparPopUp from '../components/smartspar/smartspar-popup';

class SmartSparPopUpAndroid extends Component {
    AppStore = {
        link: 'https://play.google.com/store/apps/details?id=no.eika.smartspar',
        image: '/-/media/fellesbilder/04-Produkter-og-Tjenester/GooglePlay.jpg',
    };

    static propTypes = {
        texts: PropTypes.object,
        hentVisSmartSparPopup: PropTypes.func,
        skjulSmartSparPopup: PropTypes.func,
        registrerSmartSparPopupVisning: PropTypes.func,
        visSmartsparPopup: PropTypes.bool,
    };

    render = () => <SmartSparPopUp {...this.props} appStore={this.AppStore} />;
}

export default connect(
    (state) => {
        return {
            ...state.SmartsparPopup,
            ...state.Texts,
        };
    },
    (dispatch) => {
        return {
            skjulSmartSparPopup: () => {
                dispatch(skjulSmartSparPopup());
            },
            hentVisSmartSparPopup: () => {
                dispatch(hentVisSmartSparPopup());
            },
            registrerSmartSparPopupVisning: () => {
                dispatch(registrerSmartSparPopupVisning());
            },
        };
    }
)(SmartSparPopUpAndroid);
