/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LinkCard from '../link-card';
import Format from '../format-functions';
import Card from '../card';
import ColorHelper from '../color-functions';

class FondsUtlisting extends Component {
    static propTypes = {
        verdipapirer: PropTypes.array,
        texts: PropTypes.object,
        startIndex: PropTypes.number
    };

    constructor(props) {
        super(props);

        this.state = {
            fondsListe: this.listUtFond(props.verdipapirer),
            aksjeListe: this.listUtAksjer(props.verdipapirer)
        };
    }

    listUtFond = verdipapirer => {
        if (verdipapirer) {
            return verdipapirer
                .filter(verdipapir => verdipapir.type === 'AF')
                .map((fond, index) => {
                    const gradient = ColorHelper.hentGradient(index + this.props.startIndex);

                    if (fond.flyttingBestilt) {
                        return (
                            <Card gradient={gradient} key={fond.isin}>
                                {this.skrivUtFondsHtml(fond)}
                            </Card>
                        );
                    }

                    return (
                        <LinkCard gradient={gradient} key={fond.isin} link="nominee">
                            {this.skrivUtFondsHtml(fond)}
                        </LinkCard>
                    );
                });
        }
        return '';
    };

    listUtAksjer = verdipapirer => {
        if (verdipapirer) {
            return verdipapirer
                .filter(verdipapir => verdipapir.type !== 'AF')
                .map(aksje => {
                    const gradient = ColorHelper.hentGradient(verdipapirer.indexOf(aksje));

                    return (
                        <Card gradient={gradient} key={aksje.isin}>
                            {this.skrivUtFondsHtml(aksje)}
                        </Card>
                    );
                });
        }
        return '';
    };

    visFondsStatus = fond => {
        if (fond.flyttingBestilt) {
            return (
                <div className="flytting-bestilt">
                    <span className="ikon-info-24" />
                    <span className="tekst">{this.props.texts.get('flyttingBestilt')}</span>
                </div>
            );
        }
        return '';
    };

    skrivUtFondsHtml = fond => {
        return (
            <div className="card-content-table">
                <div className="card-content-table-cell">
                    <div className="title">{fond.navn}</div>
                    {this.visFondsStatus(fond)}
                </div>
                <div className="card-content-table-cell">
                    <div className="total-verdi">
                        <div className="tekst">{this.props.texts.get('verdi')}</div>
                        <div className="verdi">{Format.currency(fond.totalVerdi)}</div>
                    </div>
                </div>
            </div>
        );
    };

    visFond = (fondsListe, texts) => {
        if (fondsListe.length) {
            return (
                <div>
                    <div className="fonds-type-tittel">{texts.get('fond')}</div>
                    {fondsListe}
                </div>
            );
        }
        return '';
    };

    visAksjer = (aksjeListe, texts) => {
        if (aksjeListe.length) {
            return (
                <div>
                    <div className="fonds-type-tittel">{texts.get('aksjerOgEgenkapitalbevis')}</div>
                    {aksjeListe}
                </div>
            );
        }
        return '';
    };

    render() {
        return (
            <div>
                {this.visFond(this.state.fondsListe, this.props.texts)}
                {this.visAksjer(this.state.aksjeListe, this.props.texts)}
            </div>
        );
    }
}

export default FondsUtlisting;
