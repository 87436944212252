import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';
import Feilmelding from '../../components/feilmelding';
import AppMessage from '../../components/AppMessage';
import DonutChart from '../../components/charts/donut-chart';
import Legend from '../../components/legends/dot-legend';
import EndrePortefoljeNavn from '../../components/fond/endre-portefolje-navn';
import PortefoljeUtlisting from '../../components/fond/portefolje-utlisting';
import ActionButton from '../../components/action-button';
import { LUKK_KVITTERING } from '../../actions/modals';
import { endrePortefoljeNavn } from '../../actions/fond';
import OrdreKvittering from '../../components/fond/ordre-kvittering';
import ColorFunctions from '../../components/color-functions';
import Format from '../../components/format-functions';
import { settSideTittel } from '../../actions/header';

const settOppPortefoljeVisning = (portefoljer) => {
    let totalVerdi = null;
    let gevinstTap = null;

    return {
        chartData: portefoljer.map((portefolje, index) => {
            if ( typeof(portefolje.totalVerdi) === 'number' ){
                totalVerdi = (totalVerdi || 0) + portefolje.totalVerdi;
            }

            if ( typeof(portefolje.urealisertGevinst) === 'number' ) {
                gevinstTap = (gevinstTap || 0) + portefolje.urealisertGevinst;
            }

            return {
                text: portefolje.navn,
                value: portefolje.totalVerdi,
                color: ColorFunctions.hentFarge(index),
                active: true
            };
        }),
        totalVerdi,
        gevinstTap
    };
};

const PortefoljeOversikt= () => {
    const { texts } = useSelector((state) => state.Texts);
    const errors = useSelector((state) => state.Feilmeldinger);
    const spareprofil = useSelector((state) => state.SpareProfil);
    const { morningstar, portefoljeData, disponertePortefoljer } = useSelector((state) => state.Fond);
    const kvittering = useSelector((state) => state.Modals);
    const header = useSelector((state) => state.Header);

    const dispatch = useDispatch();
    const lukk = useCallback(() => dispatch({ type: LUKK_KVITTERING }), [dispatch]);
    const endreNavn = useCallback((navn) => dispatch(endrePortefoljeNavn(navn)), [dispatch]);

    const portefoljer = portefoljeData && settOppPortefoljeVisning(portefoljeData.portefoljer);

    /* eslint-disable */
    useEffect(() => {
        dispatch(settSideTittel(texts.get('minAksjeOgFondsparingTittel')));
    }, []);
    /* eslint-enable */

    if (errors.LAST_PORTEFOLJE || errors.LAST_MORNINGSTAR_DATA) {
        return <Feilmelding feil={errors} texts={texts} />;
    }
    if (spareprofil.kundeInfo && morningstar && portefoljer) {
        return (
            <div className="fund-and-stock-wrapper">
                <AppMessage appMessage={(header || {}).serviceMessage} context="FUND_AND_STOCK" />

                <OrdreKvittering
                    texts={texts}
                    kvitteringConfig={kvittering.kvitteringConfig}
                    lukkKvittering={lukk}
                    kvitteringsInnhold={kvittering.kvitteringsInnhold}
                />

                <DonutChart data={portefoljer.chartData}>
                    <div className="donut-labels">
                        <span className="ikon-graflinje-36" />
                        <div className="label-section">
                            <div className="text">{texts.get('totalVerdi')}</div>
                            <div className="value">{Format.currency(portefoljer.totalVerdi)}</div>
                        </div>
                    </div>
                </DonutChart> 
                
                <Legend data={portefoljer.chartData} visVerdi={false} />
                <EndrePortefoljeNavn
                    texts={texts}
                    portefoljer={portefoljeData.portefoljer}
                    endrePortefoljeNavn={endreNavn}
                    visBankNavn={portefoljeData.portefoljeTilknyttetAnnenBank}
                />
                <PortefoljeUtlisting
                    portefoljer={portefoljeData.portefoljer}
                    texts={texts}
                    header="mineKontoer"
                    portefoljeTilknyttetAnnenBank={portefoljeData.portefoljeTilknyttetAnnenBank}
                />

                {disponertePortefoljer && (
                    <PortefoljeUtlisting
                        portefoljer={disponertePortefoljer}
                        texts={texts}
                        header="kontoerJegDisponerer"
                        portefoljeTilknyttetAnnenBank={portefoljeData.portefoljeTilknyttetAnnenBank}
                    />
                )}
                <ActionButton action="veiviser/kjopFond" text={texts.get('kjopFond')} />
                <ActionButton action="fond/sistetransaksjoner" text={texts.get('transaksjoner')} />
            </div>
        );
    }
    return <Loading title={texts.get('lasterFondsApp')} />;
};

export default PortefoljeOversikt;
