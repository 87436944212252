/**
 * Created by vagrant on 01.03.17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SIGNING_CANCEL, SIGNING_ERROR, SIGNING_SUCCESS } from '../../domain/signering-status';
import IframeResizer from '../IframeResizer';

class SigneringsIframe extends Component {
    static propTypes = {
        signeringsUrl: PropTypes.string.isRequired,
        fullforSignertHandling: PropTypes.func,
        onFeiletSignering: PropTypes.func
    };

    createIframeUrl = () => {
        const successCallbackUrl = encodeURIComponent(
            `${window.location.origin}/sparing-min-side-web/signing-success.html`
        );
        const cancelCallbackUrl = encodeURIComponent(
            `${window.location.origin}/sparing-min-side-web/signing-cancel.html`
        );
        const errorCallbackUrl = encodeURIComponent(
            `${window.location.origin}/sparing-min-side-web/signing-error.html`
        );
        return `${this.props.signeringsUrl}
        &lc=no
        &exit=${successCallbackUrl}
        &rejectstatusurl=${cancelCallbackUrl}
        &status=${errorCallbackUrl}
        `;
    };

    createCrossBrowserCompatibleEvent = () => {
        return {
            addEvent: window.addEventListener ? 'addEventListener' : 'attachEvent',
            removeEvent: window.removeEventListener ? 'removeEventListener' : 'detachEvent',
            eventMessage: window.addEventListener ? 'message' : 'onmessage'
        };
    };

    configureIframeEvent = action => {
        const event = this.createCrossBrowserCompatibleEvent();
        const eventer = window[event[action]];
        eventer(event.eventMessage, this.receiveMessage);
    };

    componentDidMount = () => {
        this.configureIframeEvent('addEvent');
    };

    componentWillUnmount = () => {
        this.configureIframeEvent('removeEvent');
    };

    receiveMessage = e => {
        if (e.data === SIGNING_SUCCESS || e.message === SIGNING_SUCCESS) {
            this.props.fullforSignertHandling();
        } else if (e.data === SIGNING_CANCEL || e.message === SIGNING_CANCEL) {
            this.props.onFeiletSignering(SIGNING_CANCEL);
        } else if (e.data === SIGNING_ERROR || e.message === SIGNING_ERROR) {
            this.props.onFeiletSignering(SIGNING_ERROR);
        }
    };

    render() {
        const iframeResizerOptions = {
            checkOrigin: false,
            warningTimeout: 0,
            heightCalculationMethod: 'max',
            scrolling: 'auto',
            minHeight: 480
        };

        return (
            <IframeResizer
                iframeResizerOptions={iframeResizerOptions}
                iframeResizerUrl={false}
                id="signerings-iframe"
                className="iframe"
                src={this.createIframeUrl()}
            />
        );
    }
}

export default SigneringsIframe;
