import { SETT_SIDE_TITTEL, SET_SERVICE_MESSAGE } from '../actions/header';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case SETT_SIDE_TITTEL:
            return {
                ...state,
                sideTittel: action.sideTittel,
                underTittel: action.underTittel,
            };
        case SET_SERVICE_MESSAGE:
            return { ...state, serviceMessage: action.data };
        default:
            return state;
    }
};
