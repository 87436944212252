/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        children: PropTypes.element,
        checked: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            checked: !!props.checked
        };
    }

    change = event => {
        const { checked } = event.target;

        this.setState({
            checked
        });

        this.props.onChange(checked, this.props.id, this.props.name);
    };

    changeOnKeyPress = () => this.inputElement.click();

    render = () => (
        <div className="checkbox label-left" key={this.props.id}>
            <input
                ref={input => {
                    this.inputElement = input;
                }}
                onKeyPress={this.changeOnKeyPress}
                name={this.props.name}
                id={this.props.id}
                type="checkbox"
                onChange={this.change}
                checked={this.state.checked}
            />
            <label htmlFor={this.props.id} className="full-width">
                <div className="before" />
                {this.props.children}
            </label>
        </div>
    );
}

export default Checkbox;
