import React from 'react';
import PropTypes from 'prop-types';
import Format from '../format-functions';
import Checkbox from '../checkbox';
import Card from '../card';

const KontoKortCheckbox = props => {
    if (props.andreSparemaal.length > 0) {
        return props.andreSparemaal[0];
    }

    return (
        <Checkbox id={props.kontoNummer} name="oremerk" onChange={e => props.onChange(e)} checked={props.checked}>
            <span>{props.texts.get('sparemaalSparemiksOremerkHeleKontoen')}</span>
        </Checkbox>
    );
};

KontoKortCheckbox.propTypes = {
    texts: PropTypes.object.isRequired,
    kontoNummer: PropTypes.string.isRequired,
    andreSparemaal: PropTypes.array.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

const SparemiksKontoKort = props => (
    <Card>
        <div className="sparemaal-konto-kort">
            <div className="flex-row">
                <div className="col">
                    <div className="konto-navn">{props.kontoNavn}</div>
                    <div className="konto-nummer">{props.kontoNummer}</div>
                </div>
                <div className="col">
                    <div className="saldo">{Format.currency(props.saldo)}</div>
                    <div className="saldo-label">{props.texts.get('saldo')}</div>
                </div>
            </div>
            <div className="flex-row">
                <KontoKortCheckbox
                    checked={props.checked}
                    andreSparemaal={props.andreSparemaal}
                    onChange={props.onChange}
                    texts={props.texts}
                    kontoNummer={props.kontoNummer}
                />
            </div>
        </div>
    </Card>
);

SparemiksKontoKort.propTypes = {
    texts: PropTypes.object.isRequired,
    kontoNavn: PropTypes.string.isRequired,
    kontoNummer: PropTypes.string.isRequired,
    saldo: PropTypes.number.isRequired,
    andreSparemaal: PropTypes.array.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

export default SparemiksKontoKort;
