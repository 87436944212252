import { formatAmount, sortList, formatDateToText } from '../../../common/util/Functions';
import { getKontiTypeName, getAISfrequencyText } from '../../../common/util/FondFunctions';
import { getLabelList, isPortfolioOnlyView } from '../../../common/util/BusinessFunctions';
import Constant from '../../../static/data/Constant';

export const getAvtaleCardFullConfig = (avtale: any, portefolje: any): any => {
    const trekkDag = avtale.trekkDag || 1;
    const now = new Date();
    const date = new Date();
    
    date.setDate( trekkDag );
    if ( trekkDag < now.getDate() ) {
        date.setMonth((date.getMonth()+1));
    }
    const formate = date.getFullYear() === (new Date()).getFullYear() ? 'dd. MM' : 'dd. MMm yyyy';
    const trekkInfo = avtale.trekkDag === now.getDate() ? 
        {label: 'Trekkdag', name: 'I dag'} : 
        {label: 'Neste trekk', name: `${formatDateToText(date, formate)}`};

    return {
        textList: [
            [
                {text: avtale.navn, type: 'font-bold'}
            ], [
                {text: getKontiTypeName(portefolje.type), type: 'konto-type' }
            ], [
                {text: trekkInfo.label, type: 'label-text'},
                {text: trekkInfo.name, type: 'font-bold'}
            ], [
                {text: 'Trekkbeløp', type: 'label-text'},
                {text: `${formatAmount(avtale.trekkBelop, true)} kr`, type: 'font-bold'}
            ]
        ]        
    }
};

export const getAvtaleCardPartialConfig = (avtale: any): any => {
    return {
        textList: [
            [{
                text: avtale.navn,
                ariaLabel: `Fond ${avtale.navn}`,
                title: `Fond ${avtale.navn}`,
            },{
                text: `${formatAmount(avtale.trekkBelop, true)} kr`,
                ariaLabel: `Trekkbelop ${avtale.trekkBelop}`,
                title: `Trekkbeløp ${avtale.trekkBelop}`,
                type: 'trekk-belop',
                innerElement: true,
            }], {
                text: `Den ${avtale.trekkDag}. hver måned`,
                ariaLabel: `Trekk dato den ${avtale.trekkDag}. hver måned`,                
                title: `Trekk dato den ${avtale.trekkDag}. hver måned`,                
            }
        ],
        dataTrackId: Constant.trackIdMonthly,
        dataTrackLinkname: 'spareavtale-kort',
    };
};

/** ****************************************************************************
 ***************************************************************************** */
export const getAvtaleCardList = (
    portefoljeData: any, 
    disponertePortefoljer: any, 
    config?: {isin: string,  portefoljeId: string | number},
    postLog?: (data: any, detail: any) => void,
): any => {
    const dag = (new Date()).getDate();
    const output = { list: [], amount: 0 };
    const sourceList = [
        {id: 'account', content: portefoljeData?.portefoljer || []},
        {id: 'dispose', content: disponertePortefoljer || []},
    ];

    try {
        sourceList.forEach( (src: any) => {
            src.content.forEach( (portefolje: any) => {
                if ( !portefolje || isPortfolioOnlyView(portefolje.portefoljeType) || (config?.portefoljeId && `${config?.portefoljeId}` !== `${portefolje.id}`) ) { return; }

                const tagList = getLabelList(portefolje, {ips: 1, sperre: 1, eier: 1}, true);
                const blocked = src.id === 'dispose' || portefolje.sperre === Constant.fullSperret;
                (portefolje.fond || []).forEach( (fond: any) => {
                    if ( !fond || (config?.isin && config?.isin !== fond.isin) ) { return; }

                    output.list = output.list.concat((fond.spareavtaler || []).map((avtale: any) =>{
                        // midlertid frontend fiks: SPASP-3341
                        if ( !avtale.navn ) { avtale.navn = fond.navn || ''; }

                        output.amount += (avtale.trekkBelop || 0);
                        const labelList = tagList.concat([
                            avtale.automaticIncrease && avtale.automaticIncrease.isActive && avtale.automaticIncrease.frequency ?
                                {id: 'ais', name: `Beløp økes ${getAISfrequencyText(avtale.automaticIncrease.frequency).toLowerCase()}`, type: 'note'} : null
                        ]).filter( (d: any) => !!d );

                        // if ( src.id === 'dispose' ) {
                        //    labelList.unshift({id: 'disposed', name: 'Disponert konto', type: 'note' });
                        // }

                        return {
                            id: `${avtale.id}`,
                            detail: { 
                                ...avtale,
                                isin: fond.isin,
                                portefoljeId: portefolje.id,
                                blocked,
                            },
                            piority: dag <= avtale.trekkDag ? (avtale.trekkDag - dag) : (avtale.trekkDag + 31 - dag),
                            data: { blocked, labelList, ...getAvtaleCardPartialConfig( avtale ) },
                            fullData: {
                                blocked,
                                labelList: [{id: 'spareavtale-icon', name: 'Spareavtale', type: 'spareavtar-icon'}].concat( labelList ),
                                ...getAvtaleCardFullConfig( avtale, portefolje )
                            }
                        };
                    }));
                })
            });
        });
    } catch ( error ) {
        if ( postLog ) { postLog( error, {sourceList, function: 'getAvtaleCardList'});}
    }

    output.list = sortList( output.list, 'piority', false, true );
    return output
}


