import React, { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { StandardButton } from '@eika/button';
import { Link } from '@eika/link';
import { IconTypes } from '@eika/icon';
import Constant from '../../static/data/Constant';
import { usePrevious } from '../../common/util/Hook';
import TabList, { TabItem } from '../../common/TabList/TabList';
import Card from '../../common/Card/Card';
import Grid from '../../common/Grid/Grid';
import Message from '../../common/Message/Message';
import ActionButtonListView, {
    ActionButtonListItemProps,
} from '../../common/ActionButtonListView/ActionButtonListView';
import {
    Commercial,
    HeaderLink,
    LinkList,
    LinkListItemProps,
    Spinner,
    SummaryHeader,
} from '../../common/share/ShareComponents';
import AppMessage from '../../common/AppMessage/AppMessage';
import LocalStorage from '../../common/util/LocalStorage';
import { formatAmount, generateId, getPageAppending, openSmartspart, sortList } from '../../common/util/Functions';
import { getNewFondOrder } from '../../common/util/FondFunctions';
import {
    getAccountCardConfig,
    getAPPactionList,
    getFundCardConfig,
    getKlientCardConfig,
} from '../../common/util/BusinessFunctions';
import { getFondOrdrePendingMessage } from './util/Functions';
import './Portefolje.scss';
import useMutualFundStore from '../../stores/MutualFundStore';
import { RouterConfig } from '../../app/Router';

interface PanelView {
    selected: string;
    list: TabItem[];
}

type Props = RouteComponentProps & RouterConfig;

interface State {
    appending: string;
    actionList: ActionButtonListItemProps[];
    menuList: ActionButtonListItemProps[];
    reducer: any;
    content: any;
    panelView: PanelView;
    linkList: LinkListItemProps[];
}

const getContent = (props: Props, reducer: any): any => {
    const { appTexts } = props || {};
    const { portefoljeData, disponertePortefoljer } = reducer.Fond || {};
    const ordreSource = { count: 0, error: 0 };
    const content: any = {
        summary: { totalVerdi: null, innskudd: null, urealisertGevinst: null },
        panelList: [
            {
                id: 'view-account',
                content: [],
                totalVerdi: 0,
            },
            {
                id: 'view-fund',
                content: [],
                totalVerdi: 0,
            },
        ],
        ordreSource: { count: 0, error: 0 },
    };

    const mineFond: any = { id: 'mine-fond', name: appTexts.get('mineFond'), list: [] };
    const disponererFond: any = { id: 'disponerer-fond', name: appTexts.get('disponererFond'), list: [] };
    const mineKontoer: any = { id: 'mine-kontoer', name: appTexts.get('mineKontoer'), list: [] };
    const disponererKontoer: any = { id: 'disponerer-kontoer', name: appTexts.get('disponererKontoer'), list: [] };
    const klientKontoer: any = { id: 'klient-kontoer', name: appTexts.get('klientKontoer'), list: [] };
    const vpsKontoer: any = { id: 'vps-kontoer', name: appTexts.get('vpsKontoer'), list: [] };
    const vpsFond: any = { id: 'vps-fond', name: appTexts.get('vpsFond'), list: [] };

    [
        { id: 'account', content: portefoljeData?.portefoljer || [] },
        { id: 'dispose', content: disponertePortefoljer || [] },
    ].forEach((src: any) => {
        src.content.forEach((d: any) => {
            ordreSource.count += (d.ordreList || []).length;
            ordreSource.error += (d.ordreList || []).filter(
                (d: any) => d.statusGroup === Constant.orderStatusNoMoney
            ).length;

            [
                { key: 'totalVerdi', field: 'initialValue' },
                { key: 'urealisertGevinst', field: 'verdistigning' },
            ].forEach((c: { key: string; field: string }) => {
                if (typeof d[c.field] !== 'number') {
                    return;
                }
                content.summary[c.key] = (content.summary[c.key] || 0) + d[c.field];
            });

            const url = `${window.location.href.replace(/\/+$/g, '')}/${d.id}`;
            const account = {
                totalVerdi: d.initialValue,
                url,
                data: getAccountCardConfig(d),
                type: 'spare-link-panel -white -collection',
            };

            (d.fond || []).concat(d.eksterneFond || []).forEach((f: any) => {
                const fond = {
                    totalVerdi: f.totalVerdi,
                    url: `${url}/${f.isin}`,
                    data: getFundCardConfig(f),
                    type: 'spare-link-panel -white -collection',
                };

                content.panelList[1].totalVerdi += fond.totalVerdi || 0;
                if (src.id === 'dispose') {
                    disponererFond.list.push(fond);
                } else if (d.portefoljeType === Constant.typeVps) {
                    vpsFond.list.push(fond);
                } else {
                    mineFond.list.push(fond);
                }
            });

            content.panelList[0].totalVerdi += account.totalVerdi || 0;

            if (src.id === 'dispose') {
                disponererKontoer.list.push(account);
            } else if (d.portefoljeType === Constant.typeVps) {
                vpsKontoer.list.push(account);
            } else {
                mineKontoer.list.push(account);
            }

            if (d.portefoljeType === Constant.typeAsk) {
                content.panelList[1].totalVerdi += d.kontantBeholdning || 0;
                klientKontoer.list.push({
                    url: `${url}/klientkonto`,
                    data: getKlientCardConfig(d),
                    type: 'spare-link-panel -grey -collection',
                });
            }
        });
    });

    mineFond.list = mineFond.list.concat(klientKontoer.list);

    if (mineKontoer.list.length) {
        content.panelList[0].content.push(mineKontoer);
    }
    if (vpsKontoer.list.length) {
        content.panelList[0].content.push(vpsKontoer);
    }
    if (disponererKontoer.list.length) {
        content.panelList[0].content.push(disponererKontoer);
    }
    if (mineFond.list.length) {
        content.panelList[1].content.push(mineFond);
    }
    if (vpsFond.list.length) {
        content.panelList[1].content.push(vpsFond);
    }
    if (disponererFond.list.length) {
        content.panelList[1].content.push(disponererFond);
    }

    content.panelList.forEach((panel: any) => {
        (panel.content || []).forEach((cnt: any) => {
            cnt.list = sortList(cnt.list, 'totalVerdi', true, true);
        });
    });

    content.summary.totalVerdi = formatAmount(content.summary.totalVerdi, true);
    content.ordreInfo = getFondOrdrePendingMessage(ordreSource);

    return content;
};

export default (props: Props): JSX.Element => {
    const { setEntry, setPaymentType } = useMutualFundStore();
    const reducer = useSelector((state: any) => {
        return ['App', 'Fond'].reduce((p: { [k: string]: any }, k: string) => {
            return { ...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const [state, setState] = useState<State>({
        linkList: [
            {
                id: 'orde-historikk',
                url: `${window.location.href.replace(/\/+$/g, '')}/ordre`,
                name: props.appTexts.get('ordreHistorikk', undefined),
                icon: IconTypes.BEVEGELSER_36,
                dataTrackId: Constant.trackIdProduct,
                dataTrackLinkname: props.appTexts.get('ordreHistorikk', undefined),
            },
        ],
        panelView: {
            selected: LocalStorage.get('portefoljer-panel-view') || 'view-fund',
            list: [
                { id: 'view-fund', name: 'Fond' },
                { id: 'view-account', name: 'Kontoer' },
            ],
        },
        actionList: getAPPactionList(),
        menuList: [
            {
                id: Constant.actionVisSparekalkulator,
                icon: IconTypes.KALKULATOR_24,
                name: 'Spare- kalkulator',
                dataTrackId: Constant.trackIdAction,
                dataTrackLinkname: 'spare-kalkulator',
            },
            {
                id: Constant.actionVisFondVeileder,
                icon: IconTypes.LYSPAERE_24,
                name: 'Fonds- veileder',
                dataTrackId: Constant.trackIdAction,
                dataTrackLinkname: 'fondsveileder',
            },
            {
                id: Constant.actionVisFondListe,
                icon: IconTypes.SOEK_24,
                name: 'Fondslisten',
                dataTrackId: Constant.trackIdAction,
                dataTrackLinkname: 'fondslisten',
            },
        ],
        appending: getPageAppending(props),
        content: getContent(props, reducer),
        reducer,
    });

    const previousReducer = usePrevious(reducer);
    const loading = !(reducer.Fond || {}).portefoljeData || !(reducer.Fond || {}).disponertePortefoljer;

    useEffect(() => {
        if (JSON.stringify(reducer) === JSON.stringify(previousReducer)) {
            return;
        }
        setState({ ...state, reducer, content: getContent(props, reducer) });
    }, [previousReducer, reducer, state, props]);

    const click = (e: MouseEvent, key = '', data?: any): void => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        if (key === Constant.actionSettInnPenger || key === Constant.actionApneSmartspar) {
            openSmartspart(key, data);
        } else if (key === Constant.actionVisSparekalkulator) {
            props.history.push(`${props.location.pathname}/fondsberegning`);
        } else if (key === Constant.actionKjopTilBarn) {
            props.history.push(`${props.location.pathname}/comingsoon`);
        } else if (key === Constant.actionVisPensjonskalkulator) {
            props.history.push(`${props.location.pathname}/pensjonsberegning`);
        } else if (key === Constant.actionVisFondListe) {
            setPaymentType('NONE');
            setEntry('FUNDLIST');
            props.history.push(`${props.location.pathname}/fondvisning`);
        } else if (key === Constant.actionVisFondVeileder) {
            props.history.push(`${props.location.pathname}/fondvisning?suggestion=${generateId('fs', true)}`);
        } else if (key === 'change-panel-view' && data) {
            LocalStorage.set('portefoljer-panel-view', data.id);
            setState({ ...state, panelView: { ...state.panelView, selected: data.id } });
        } else if (key === Constant.actionKjop) {
            const order = getNewFondOrder();
            props.history.push(
                `${props.location.pathname}/fondhandel/${order}/step0?action=${Constant.piwikActionBuy}`
            );
        } else if (key === Constant.actionKjopEngang) {
            const order = getNewFondOrder({ type: Constant.typeEngangsinnkudd });
            props.history.push(
                `${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionOnetime}`
            );
        } else if (key === Constant.actionKjopSpareavtale) {
            const order = getNewFondOrder({ type: Constant.typeManedligSparing });
            props.history.push(
                `${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionMonthly}`
            );
        }
    };

    return (
        <div className="portefolje-wrapper">
            <Grid
                namespace="sparing-top"
                list={[
                    {
                        layout: 'twelve',
                        element: <HeaderLink text="Sparing" />,
                    },
                ]}
            />

            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>{props.appTexts.get('fondogAksjer')}</h1>,
                    },
                ]}
            />

            <div className={`portefolje-content ${state.appending}`}>
                <Grid
                    namespace="sparing-content"
                    list={[
                        {
                            layout: 'seven',
                            element: (
                                <>
                                    {loading ? (
                                        <Spinner namespace="paragraph-large" type="portefoljer" />
                                    ) : (
                                        <>
                                            <AppMessage
                                                appMessage={reducer?.App?.serviceMessage}
                                                context="FUND_AND_STOCK"
                                            >
                                                {state.content.ordreInfo?.count > 0 && state.content.ordreInfo?.url && (
                                                    <Message role="presentation" {...state.content.ordreInfo}>
                                                        <div className="paragraph -only-top">
                                                            <Link
                                                                data-tack-id={Constant.trackIdProduct}
                                                                data-track-linkname={props.appTexts.get(
                                                                    'ordreHistorikk',
                                                                    undefined
                                                                )}
                                                                href={state.content.ordreInfo.url}
                                                            >
                                                                {props.appTexts.get('ordreHistorikk')}
                                                            </Link>
                                                        </div>
                                                    </Message>
                                                )}
                                            </AppMessage>

                                            {!!state.content.summary && (
                                                <section className="paragraph-large  wallpaper-wrapper only-for-desktop">
                                                    <div className="wallpaper-header-row">
                                                        <SummaryHeader
                                                            {...state.content.summary}
                                                            namespace="paragraph-small -header-inline"
                                                        />

                                                        <div className="wallpaper-header-column">
                                                            <StandardButton
                                                                data-tack-id={Constant.trackIdBuy}
                                                                data-track-linkname="spar-i-fond"
                                                                onClick={(e) => {
                                                                    click(e, Constant.actionKjop);
                                                                }}
                                                            >
                                                                Spar i fond
                                                            </StandardButton>
                                                            <div className="button-float-right-wrapper">
                                                                <LinkList
                                                                    list={state.linkList}
                                                                    click={(e: any, k: string) => {
                                                                        click(e, k);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            )}

                                            {!!state.content.summary && (
                                                <section className="paragraph-large only-for-mobile">
                                                    <SummaryHeader
                                                        {...state.content.summary}
                                                        namespace="paragraph-small"
                                                    />
                                                    <div className="center">
                                                        <StandardButton
                                                            data-tack-id={Constant.trackIdBuy}
                                                            data-track-linkname="spar-i-fond"
                                                            onClick={(e) => {
                                                                click(e, Constant.actionKjop);
                                                            }}
                                                        >
                                                            Spar i fond
                                                        </StandardButton>
                                                    </div>
                                                </section>
                                            )}

                                            <div className="only-for-mobile paragraph max-portfolio-width">
                                                <ActionButtonListView
                                                    namespace="collection-small cell-3"
                                                    list={state.menuList}
                                                    click={(e: any, k = '', d?: any) => {
                                                        click(e, k, d);
                                                    }}
                                                    expandable={state.menuList.length > 3}
                                                />
                                            </div>

                                            <LinkList
                                                list={state.linkList}
                                                namespace="max-width-600 paragraph-large only-for-mobile"
                                                click={(e: any, k: string) => {
                                                    click(e, k);
                                                }}
                                            />

                                            {state.panelView && (
                                                <div className="paragraph-large max-width-600">
                                                    <div className="fund-switch-wrapper">
                                                        <TabList
                                                            list={state.panelView.list}
                                                            click={(e: MouseEvent, k = '', d: any) => {
                                                                click(e, k, d);
                                                            }}
                                                            current={state.panelView.selected}
                                                            action="change-panel-view"
                                                        />
                                                    </div>

                                                    {(state.content?.panelList || []).map((panel: any, i: number) => {
                                                        return panel &&
                                                            state.panelView.selected === panel.id &&
                                                            (panel.content || []).length ? (
                                                            <div
                                                                key={`panel-${panel.id}`}
                                                                id={`panel-${panel.id}`}
                                                                className={`panel-wrapper -${panel.id}`}
                                                            >
                                                                {panel.content.map((src: any, j: number) => {
                                                                    return (
                                                                        <section
                                                                            key={src.id}
                                                                            aria-labelledby={`${src.id}-title`}
                                                                            className="collection-wrapper paragraph-large"
                                                                        >
                                                                            {!!src.name && (
                                                                                <div
                                                                                    id={`${src.id}-title`}
                                                                                    className="section-title paragraph-extra-small"
                                                                                >
                                                                                    {src.name}
                                                                                </div>
                                                                            )}
                                                                            <div className="collection-content">
                                                                                {(src.list || []).map(
                                                                                    (data: any, k: number) => (
                                                                                        <Card
                                                                                            key={`panel-${panel.id}-${src.id}-${i}-${j}-${k}`}
                                                                                            {...data}
                                                                                        />
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </section>
                                                                    );
                                                                })}
                                                            </div>
                                                        ) : null;
                                                    })}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            ),
                        },
                        {
                            layout: 'four',
                            element: (
                                <>
                                    <ActionButtonListView
                                        namespace="paragraph-large only-for-desktop wallpaper cell-2"
                                        list={state.menuList}
                                        click={(e: any, k = '', d?: any) => {
                                            click(e, k, d);
                                        }}
                                    />

                                    <Commercial onlyForDesktop type="saving">
                                        <StandardButton
                                            className="open-smartspart-btn"
                                            data-track-id={Constant.trackIdBigLinkCard}
                                            data-track-linkname="start-sparing"
                                            onClick={(e) => {
                                                click(e, Constant.actionKjopSpareavtale);
                                            }}
                                        >
                                            Start sparing
                                        </StandardButton>
                                    </Commercial>
                                </>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    );
};
