const HistoryDevelopmentGraph = [
    {
        'barChartData': {
            'collection': [
                {
                    'pointSeries': [
                        {
                            'color': '0x0',
                            'points': [
                                {
                                    'x': '2013-12-31',
                                    'y': '0.9967149766624273'
                                },
                                {
                                    'x': '2014-12-31',
                                    'y': '4.444658318598305'
                                },
                                {
                                    'x': '2015-12-31',
                                    'y': '2.2270661531863745'
                                },
                                {
                                    'x': '2016-12-31',
                                    'y': '0.323586847327606'
                                },
                                {
                                    'x': '2017-12-31',
                                    'y': '1.587139695369033'
                                },
                                {
                                    'x': '2018-12-31',
                                    'y': '0.3649544615090594'
                                },
                                {
                                    'x': '2019-12-31',
                                    'y': '1.2169067097657527'
                                },
                                {
                                    'x': '2020-12-31',
                                    'y': '3.445319589604634'
                                },
                                {
                                    'x': '2021-12-31',
                                    'y': '-0.17210949792080266'
                                },
                                {
                                    'x': '2022-12-31',
                                    'y': '0.0'
                                },
                                {
                                    'x': '2023-09-17',
                                    'y': '0.0'
                                }
                            ],
                            'type': 0
                        }
                    ]
                }
            ],
            'birthYear': 0,
            'showPctValues': false,
            'showXAxisAs': 'AAR'
        },
        'barGraphReference': {},
        'lineChartData': {
            'collection': [
                {
                    'pointSeries': [
                        {
                            'color': '0x0',
                            'points': [
                                {
                                    'x': '2013-09-17',
                                    'y': '100.0'
                                },
                                {
                                    'x': '2013-09-30',
                                    'y': '99.450796785'
                                },
                                {
                                    'x': '2013-10-31',
                                    'y': '100.132371181'
                                },
                                {
                                    'x': '2013-11-30',
                                    'y': '100.769563832'
                                },
                                {
                                    'x': '2013-12-31',
                                    'y': '100.996714983'
                                },
                                {
                                    'x': '2014-01-31',
                                    'y': '101.35619872299999'
                                },
                                {
                                    'x': '2014-02-28',
                                    'y': '101.62077834899999'
                                },
                                {
                                    'x': '2014-03-31',
                                    'y': '101.60424969699999'
                                },
                                {
                                    'x': '2014-04-30',
                                    'y': '101.95407553799998'
                                },
                                {
                                    'x': '2014-05-31',
                                    'y': '102.23429781399997'
                                },
                                {
                                    'x': '2014-06-30',
                                    'y': '102.84987819499997'
                                },
                                {
                                    'x': '2014-07-31',
                                    'y': '103.41787528399998'
                                },
                                {
                                    'x': '2014-08-31',
                                    'y': '103.45002767399998'
                                },
                                {
                                    'x': '2014-09-30',
                                    'y': '103.35418095399999'
                                },
                                {
                                    'x': '2014-10-31',
                                    'y': '103.98226189399998'
                                },
                                {
                                    'x': '2014-11-30',
                                    'y': '104.54323968799997'
                                },
                                {
                                    'x': '2014-12-31',
                                    'y': '105.48567391499996'
                                },
                                {
                                    'x': '2015-01-31',
                                    'y': '106.61145797899997'
                                },
                                {
                                    'x': '2015-02-28',
                                    'y': '106.72186111999997'
                                },
                                {
                                    'x': '2015-03-31',
                                    'y': '106.35309689099998'
                                },
                                {
                                    'x': '2015-04-30',
                                    'y': '106.45543321799997'
                                },
                                {
                                    'x': '2015-05-31',
                                    'y': '106.35547672499997'
                                },
                                {
                                    'x': '2015-06-30',
                                    'y': '106.47504755899998'
                                },
                                {
                                    'x': '2015-07-31',
                                    'y': '106.93448053999998'
                                },
                                {
                                    'x': '2015-08-31',
                                    'y': '107.50222148799998'
                                },
                                {
                                    'x': '2015-09-30',
                                    'y': '107.449900379'
                                },
                                {
                                    'x': '2015-10-31',
                                    'y': '107.54284673800001'
                                },
                                {
                                    'x': '2015-11-30',
                                    'y': '107.625317605'
                                },
                                {
                                    'x': '2015-12-31',
                                    'y': '107.83490968400001'
                                },
                                {
                                    'x': '2016-01-31',
                                    'y': '108.04038277600003'
                                },
                                {
                                    'x': '2016-02-29',
                                    'y': '108.34989483000003'
                                },
                                {
                                    'x': '2016-03-31',
                                    'y': '108.69347640000001'
                                },
                                {
                                    'x': '2016-04-30',
                                    'y': '108.69134966400003'
                                },
                                {
                                    'x': '2016-05-31',
                                    'y': '108.39366970500002'
                                },
                                {
                                    'x': '2016-06-30',
                                    'y': '108.81040280200003'
                                },
                                {
                                    'x': '2016-07-31',
                                    'y': '109.20988064300002'
                                },
                                {
                                    'x': '2016-08-31',
                                    'y': '109.01032009900001'
                                },
                                {
                                    'x': '2016-09-30',
                                    'y': '108.61564259300003'
                                },
                                {
                                    'x': '2016-10-31',
                                    'y': '108.37665507700004'
                                },
                                {
                                    'x': '2016-11-30',
                                    'y': '108.18043271200004'
                                },
                                {
                                    'x': '2016-12-31',
                                    'y': '108.18384928400002'
                                },
                                {
                                    'x': '2017-01-31',
                                    'y': '108.57753587200003'
                                },
                                {
                                    'x': '2017-02-28',
                                    'y': '108.73485883300002'
                                },
                                {
                                    'x': '2017-03-31',
                                    'y': '108.79099875600002'
                                },
                                {
                                    'x': '2017-04-30',
                                    'y': '109.01050270700001'
                                },
                                {
                                    'x': '2017-05-31',
                                    'y': '109.17358804300002'
                                },
                                {
                                    'x': '2017-06-30',
                                    'y': '109.293359886'
                                },
                                {
                                    'x': '2017-07-31',
                                    'y': '109.223620735'
                                },
                                {
                                    'x': '2017-08-31',
                                    'y': '109.33706151300001'
                                },
                                {
                                    'x': '2017-09-30',
                                    'y': '109.60263386399998'
                                },
                                {
                                    'x': '2017-10-31',
                                    'y': '109.525962002'
                                },
                                {
                                    'x': '2017-11-30',
                                    'y': '109.79332282499999'
                                },
                                {
                                    'x': '2017-12-31',
                                    'y': '109.900878122'
                                },
                                {
                                    'x': '2018-01-31',
                                    'y': '109.680421973'
                                },
                                {
                                    'x': '2018-02-28',
                                    'y': '109.29187774699999'
                                },
                                {
                                    'x': '2018-03-31',
                                    'y': '109.03349277699999'
                                },
                                {
                                    'x': '2018-04-30',
                                    'y': '109.294263499'
                                },
                                {
                                    'x': '2018-05-31',
                                    'y': '109.446342769'
                                },
                                {
                                    'x': '2018-06-30',
                                    'y': '109.70752218799998'
                                },
                                {
                                    'x': '2018-07-31',
                                    'y': '109.73482877099998'
                                },
                                {
                                    'x': '2018-08-31',
                                    'y': '109.79656042099998'
                                },
                                {
                                    'x': '2018-09-30',
                                    'y': '109.64324699399998'
                                },
                                {
                                    'x': '2018-10-31',
                                    'y': '109.58176893799998'
                                },
                                {
                                    'x': '2018-11-30',
                                    'y': '109.815422832'
                                },
                                {
                                    'x': '2018-12-31',
                                    'y': '110.30196628499998'
                                },
                                {
                                    'x': '2019-01-31',
                                    'y': '110.57254352499997'
                                },
                                {
                                    'x': '2019-02-28',
                                    'y': '110.74570668699995'
                                },
                                {
                                    'x': '2019-03-31',
                                    'y': '110.71318086799995'
                                },
                                {
                                    'x': '2019-04-30',
                                    'y': '110.40150580799995'
                                },
                                {
                                    'x': '2019-05-31',
                                    'y': '110.58457193299995'
                                },
                                {
                                    'x': '2019-06-30',
                                    'y': '111.40754964099996'
                                },
                                {
                                    'x': '2019-07-31',
                                    'y': '111.19336394699997'
                                },
                                {
                                    'x': '2019-08-31',
                                    'y': '111.88959810099995'
                                },
                                {
                                    'x': '2019-09-30',
                                    'y': '111.67750937499996'
                                },
                                {
                                    'x': '2019-10-31',
                                    'y': '111.80827488399996'
                                },
                                {
                                    'x': '2019-11-30',
                                    'y': '111.60473069199995'
                                },
                                {
                                    'x': '2019-12-31',
                                    'y': '111.64423835299995'
                                },
                                {
                                    'x': '2020-01-31',
                                    'y': '111.75523310399994'
                                },
                                {
                                    'x': '2020-02-29',
                                    'y': '112.01095400399996'
                                },
                                {
                                    'x': '2020-03-31',
                                    'y': '114.41697039199994'
                                },
                                {
                                    'x': '2020-04-30',
                                    'y': '115.23720114699995'
                                },
                                {
                                    'x': '2020-05-31',
                                    'y': '115.72607899099994'
                                },
                                {
                                    'x': '2020-06-30',
                                    'y': '115.72146832399994'
                                },
                                {
                                    'x': '2020-07-31',
                                    'y': '115.86209007899994'
                                },
                                {
                                    'x': '2020-08-31',
                                    'y': '115.70153880699995'
                                },
                                {
                                    'x': '2020-09-30',
                                    'y': '115.89502628999993'
                                },
                                {
                                    'x': '2020-10-31',
                                    'y': '115.95452357899994'
                                },
                                {
                                    'x': '2020-11-30',
                                    'y': '115.66332592999994'
                                },
                                {
                                    'x': '2020-12-31',
                                    'y': '115.49073919499995'
                                },
                                {
                                    'x': '2021-01-31',
                                    'y': '115.32507864999995'
                                },
                                {
                                    'x': '2021-02-28',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2021-03-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2021-04-30',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2021-05-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2021-06-30',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2021-07-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2021-08-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2021-09-30',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2021-10-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2021-11-30',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2021-12-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-01-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-02-28',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-03-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-04-30',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-05-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-06-30',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-07-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-08-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-09-30',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-10-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-11-30',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2022-12-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2023-01-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2023-02-28',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2023-03-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2023-04-30',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2023-05-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2023-06-30',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2023-07-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2023-08-31',
                                    'y': '115.29196865199995'
                                },
                                {
                                    'x': '2023-09-17',
                                    'y': '115.29196865199995'
                                }
                            ],
                            'type': 0
                        }
                    ]
                }
            ],
            'birthYear': 0,
            'showPctValues': false,
            'showXAxisAs': 'AAR'
        },
        'lineGraphReference': {}
    },
    {
        'barChartData': {
            'collection': [
                {
                    'pointSeries': [
                        {
                            'color': '0x0',
                            'points': [
                                {
                                    'x': '2013-12-31',
                                    'y': '5.740114323641854'
                                },
                                {
                                    'x': '2014-12-31',
                                    'y': '4.709751692560626'
                                },
                                {
                                    'x': '2015-12-31',
                                    'y': '4.1715946319112795'
                                },
                                {
                                    'x': '2016-12-31',
                                    'y': '6.574689602804629'
                                },
                                {
                                    'x': '2017-12-31',
                                    'y': '11.390256557906309'
                                },
                                {
                                    'x': '2018-12-31',
                                    'y': '-0.9554046501415892'
                                },
                                {
                                    'x': '2019-12-31',
                                    'y': '10.290654241980413'
                                },
                                {
                                    'x': '2020-12-31',
                                    'y': '4.147071639740951'
                                },
                                {
                                    'x': '2021-12-31',
                                    'y': '14.632799062208846'
                                },
                                {
                                    'x': '2022-12-31',
                                    'y': '-0.7006063374903393'
                                },
                                {
                                    'x': '2023-09-17',
                                    'y': '3.780337529998201'
                                }
                            ],
                            'type': 0
                        }
                    ]
                }
            ],
            'birthYear': 0,
            'showPctValues': false,
            'showXAxisAs': 'AAR'
        },
        'barGraphReference': {},
        'lineChartData': {
            'collection': [
                {
                    'pointSeries': [
                        {
                            'color': '0x0',
                            'points': [
                                {
                                    'x': '2013-09-17',
                                    'y': '100.0'
                                },
                                {
                                    'x': '2013-09-30',
                                    'y': '100.226626301'
                                },
                                {
                                    'x': '2013-10-31',
                                    'y': '103.644228186'
                                },
                                {
                                    'x': '2013-11-30',
                                    'y': '105.01560441500001'
                                },
                                {
                                    'x': '2013-12-31',
                                    'y': '105.740114408'
                                },
                                {
                                    'x': '2014-01-31',
                                    'y': '104.59834575'
                                },
                                {
                                    'x': '2014-02-28',
                                    'y': '106.74259256600001'
                                },
                                {
                                    'x': '2014-03-31',
                                    'y': '107.36136636600001'
                                },
                                {
                                    'x': '2014-04-30',
                                    'y': '109.18892230800002'
                                },
                                {
                                    'x': '2014-05-31',
                                    'y': '112.03546285100002'
                                },
                                {
                                    'x': '2014-06-30',
                                    'y': '113.61343300100003'
                                },
                                {
                                    'x': '2014-07-31',
                                    'y': '113.33480825200002'
                                },
                                {
                                    'x': '2014-08-31',
                                    'y': '113.13751031800001'
                                },
                                {
                                    'x': '2014-09-30',
                                    'y': '113.01007490000002'
                                },
                                {
                                    'x': '2014-10-31',
                                    'y': '110.89849437100001'
                                },
                                {
                                    'x': '2014-11-30',
                                    'y': '109.272706848'
                                },
                                {
                                    'x': '2014-12-31',
                                    'y': '110.72021125200001'
                                },
                                {
                                    'x': '2015-01-31',
                                    'y': '113.31116399500002'
                                },
                                {
                                    'x': '2015-02-28',
                                    'y': '115.32397255500003'
                                },
                                {
                                    'x': '2015-03-31',
                                    'y': '115.49789794000003'
                                },
                                {
                                    'x': '2015-04-30',
                                    'y': '117.57813329700002'
                                },
                                {
                                    'x': '2015-05-31',
                                    'y': '118.16425151100002'
                                },
                                {
                                    'x': '2015-06-30',
                                    'y': '116.55629668100002'
                                },
                                {
                                    'x': '2015-07-31',
                                    'y': '117.77437824200004'
                                },
                                {
                                    'x': '2015-08-31',
                                    'y': '113.54619106500003'
                                },
                                {
                                    'x': '2015-09-30',
                                    'y': '112.28105148600004'
                                },
                                {
                                    'x': '2015-10-31',
                                    'y': '115.69420609300005'
                                },
                                {
                                    'x': '2015-11-30',
                                    'y': '117.09620484200003'
                                },
                                {
                                    'x': '2015-12-31',
                                    'y': '115.33900965700005'
                                },
                                {
                                    'x': '2016-01-31',
                                    'y': '110.47677176800005'
                                },
                                {
                                    'x': '2016-02-29',
                                    'y': '111.79602717100005'
                                },
                                {
                                    'x': '2016-03-31',
                                    'y': '112.49622087100006'
                                },
                                {
                                    'x': '2016-04-30',
                                    'y': '115.36664991400005'
                                },
                                {
                                    'x': '2016-05-31',
                                    'y': '116.32795929600005'
                                },
                                {
                                    'x': '2016-06-30',
                                    'y': '115.08195972700005'
                                },
                                {
                                    'x': '2016-07-31',
                                    'y': '116.26503131000004'
                                },
                                {
                                    'x': '2016-08-31',
                                    'y': '116.79933453600003'
                                },
                                {
                                    'x': '2016-09-30',
                                    'y': '116.98043291800003'
                                },
                                {
                                    'x': '2016-10-31',
                                    'y': '118.42199234300003'
                                },
                                {
                                    'x': '2016-11-30',
                                    'y': '120.17882701200004'
                                },
                                {
                                    'x': '2016-12-31',
                                    'y': '122.92219156400003'
                                },
                                {
                                    'x': '2017-01-31',
                                    'y': '124.05003047000004'
                                },
                                {
                                    'x': '2017-02-28',
                                    'y': '123.84184465300004'
                                },
                                {
                                    'x': '2017-03-31',
                                    'y': '123.62634603300005'
                                },
                                {
                                    'x': '2017-04-30',
                                    'y': '124.72345659700004'
                                },
                                {
                                    'x': '2017-05-31',
                                    'y': '126.08121811400004'
                                },
                                {
                                    'x': '2017-06-30',
                                    'y': '124.95748150600004'
                                },
                                {
                                    'x': '2017-07-31',
                                    'y': '128.33760448200005'
                                },
                                {
                                    'x': '2017-08-31',
                                    'y': '129.13509543300006'
                                },
                                {
                                    'x': '2017-09-30',
                                    'y': '133.6178956990001'
                                },
                                {
                                    'x': '2017-10-31',
                                    'y': '135.98102520500007'
                                },
                                {
                                    'x': '2017-11-30',
                                    'y': '135.09614609400006'
                                },
                                {
                                    'x': '2017-12-31',
                                    'y': '136.92334457300007'
                                },
                                {
                                    'x': '2018-01-31',
                                    'y': '136.46688677900005'
                                },
                                {
                                    'x': '2018-02-28',
                                    'y': '137.15429267200008'
                                },
                                {
                                    'x': '2018-03-31',
                                    'y': '135.57073406900005'
                                },
                                {
                                    'x': '2018-04-30',
                                    'y': '141.20053567100004'
                                },
                                {
                                    'x': '2018-05-31',
                                    'y': '142.84266089700006'
                                },
                                {
                                    'x': '2018-06-30',
                                    'y': '143.3375969980001'
                                },
                                {
                                    'x': '2018-07-31',
                                    'y': '145.08843777700008'
                                },
                                {
                                    'x': '2018-08-31',
                                    'y': '146.15497331500006'
                                },
                                {
                                    'x': '2018-09-30',
                                    'y': '149.25577946200008'
                                },
                                {
                                    'x': '2018-10-31',
                                    'y': '149.4303627090001'
                                },
                                {
                                    'x': '2018-11-30',
                                    'y': '141.56377647500008'
                                },
                                {
                                    'x': '2018-12-31',
                                    'y': '135.61517252300007'
                                },
                                {
                                    'x': '2019-01-31',
                                    'y': '139.35869819000004'
                                },
                                {
                                    'x': '2019-02-28',
                                    'y': '142.46170555300006'
                                },
                                {
                                    'x': '2019-03-31',
                                    'y': '142.22703610500005'
                                },
                                {
                                    'x': '2019-04-30',
                                    'y': '143.86273607700008'
                                },
                                {
                                    'x': '2019-05-31',
                                    'y': '142.95483277000005'
                                },
                                {
                                    'x': '2019-06-30',
                                    'y': '142.72720571800008'
                                },
                                {
                                    'x': '2019-07-31',
                                    'y': '142.0675543940001'
                                },
                                {
                                    'x': '2019-08-31',
                                    'y': '140.60803026200009'
                                },
                                {
                                    'x': '2019-09-30',
                                    'y': '145.07342597300007'
                                },
                                {
                                    'x': '2019-10-31',
                                    'y': '146.2912303230001'
                                },
                                {
                                    'x': '2019-11-30',
                                    'y': '147.17279088900008'
                                },
                                {
                                    'x': '2019-12-31',
                                    'y': '149.5708610770001'
                                },
                                {
                                    'x': '2020-01-31',
                                    'y': '147.8509246590001'
                                },
                                {
                                    'x': '2020-02-29',
                                    'y': '148.6843288100001'
                                },
                                {
                                    'x': '2020-03-31',
                                    'y': '128.3798286500001'
                                },
                                {
                                    'x': '2020-04-30',
                                    'y': '135.6320069120001'
                                },
                                {
                                    'x': '2020-05-31',
                                    'y': '133.1056015970001'
                                },
                                {
                                    'x': '2020-06-30',
                                    'y': '137.89817528500006'
                                },
                                {
                                    'x': '2020-07-31',
                                    'y': '138.61867336900005'
                                },
                                {
                                    'x': '2020-08-31',
                                    'y': '144.33371960600005'
                                },
                                {
                                    'x': '2020-09-30',
                                    'y': '144.11140863500006'
                                },
                                {
                                    'x': '2020-10-31',
                                    'y': '145.75253454900007'
                                },
                                {
                                    'x': '2020-11-30',
                                    'y': '151.4737178020001'
                                },
                                {
                                    'x': '2020-12-31',
                                    'y': '155.77367186900008'
                                },
                                {
                                    'x': '2021-01-31',
                                    'y': '154.97925900400008'
                                },
                                {
                                    'x': '2021-02-28',
                                    'y': '159.00574116400006'
                                },
                                {
                                    'x': '2021-03-31',
                                    'y': '164.21831010800008'
                                },
                                {
                                    'x': '2021-04-30',
                                    'y': '165.98871149300007'
                                },
                                {
                                    'x': '2021-05-31',
                                    'y': '169.02325394600007'
                                },
                                {
                                    'x': '2021-06-30',
                                    'y': '170.46856172800008'
                                },
                                {
                                    'x': '2021-07-31',
                                    'y': '171.8091954090001'
                                },
                                {
                                    'x': '2021-08-31',
                                    'y': '172.5721085700001'
                                },
                                {
                                    'x': '2021-09-30',
                                    'y': '174.7320183890001'
                                },
                                {
                                    'x': '2021-10-31',
                                    'y': '177.6971136020001'
                                },
                                {
                                    'x': '2021-11-30',
                                    'y': '176.5386531160001'
                                },
                                {
                                    'x': '2021-12-31',
                                    'y': '178.5677203090001'
                                },
                                {
                                    'x': '2022-01-31',
                                    'y': '175.8934983190001'
                                },
                                {
                                    'x': '2022-02-28',
                                    'y': '178.64219995800008'
                                },
                                {
                                    'x': '2022-03-31',
                                    'y': '184.5985589100001'
                                },
                                {
                                    'x': '2022-04-30',
                                    'y': '182.43160371900007'
                                },
                                {
                                    'x': '2022-05-31',
                                    'y': '187.21943736800006'
                                },
                                {
                                    'x': '2022-06-30',
                                    'y': '175.43252968200008'
                                },
                                {
                                    'x': '2022-07-31',
                                    'y': '183.7762633270001'
                                },
                                {
                                    'x': '2022-08-31',
                                    'y': '183.3223400610001'
                                },
                                {
                                    'x': '2022-09-30',
                                    'y': '168.63978440200012'
                                },
                                {
                                    'x': '2022-10-31',
                                    'y': '175.9528807420001'
                                },
                                {
                                    'x': '2022-11-30',
                                    'y': '180.5132495170001'
                                },
                                {
                                    'x': '2022-12-31',
                                    'y': '177.31666350300011'
                                },
                                {
                                    'x': '2023-01-31',
                                    'y': '177.96483774500012'
                                },
                                {
                                    'x': '2023-02-28',
                                    'y': '182.5423167100001'
                                },
                                {
                                    'x': '2023-03-31',
                                    'y': '177.7766256600001'
                                },
                                {
                                    'x': '2023-04-30',
                                    'y': '181.0849311470001'
                                },
                                {
                                    'x': '2023-05-31',
                                    'y': '178.27684708600012'
                                },
                                {
                                    'x': '2023-06-30',
                                    'y': '179.84494564100012'
                                },
                                {
                                    'x': '2023-07-31',
                                    'y': '182.9459158890001'
                                },
                                {
                                    'x': '2023-08-31',
                                    'y': '184.0198319080001'
                                },
                                {
                                    'x': '2023-09-17',
                                    'y': '184.0198319080001'
                                }
                            ],
                            'type': 0
                        }
                    ]
                }
            ],
            'birthYear': 0,
            'showPctValues': false,
            'showXAxisAs': 'AAR'
        },
        'lineGraphReference': {}
    },
    {
        'barChartData': {
            'collection': [
                {
                    'pointSeries': [
                        {
                            'color': '0x0',
                            'points': [
                                {
                                    'x': '2013-12-31',
                                    'y': '10.483513758390561'
                                },
                                {
                                    'x': '2014-12-31',
                                    'y': '4.95208246942187'
                                },
                                {
                                    'x': '2015-12-31',
                                    'y': '5.9405596487457135'
                                },
                                {
                                    'x': '2016-12-31',
                                    'y': '12.062071890667792'
                                },
                                {
                                    'x': '2017-12-31',
                                    'y': '19.094272288520784'
                                },
                                {
                                    'x': '2018-12-31',
                                    'y': '-1.840505847563778'
                                },
                                {
                                    'x': '2019-12-31',
                                    'y': '16.509894140573266'
                                },
                                {
                                    'x': '2020-12-31',
                                    'y': '4.564925630040184'
                                },
                                {
                                    'x': '2021-12-31',
                                    'y': '23.353902048304587'
                                },
                                {
                                    'x': '2022-12-31',
                                    'y': '-1.0346004126298602'
                                },
                                {
                                    'x': '2023-09-17',
                                    'y': '5.601345654904599'
                                }
                            ],
                            'type': 0
                        }
                    ]
                }
            ],
            'birthYear': 0,
            'showPctValues': false,
            'showXAxisAs': 'AAR'
        },
        'barGraphReference': {},
        'lineChartData': {
            'collection': [
                {
                    'pointSeries': [
                        {
                            'color': '0x0',
                            'points': [
                                {
                                    'x': '2013-09-17',
                                    'y': '100.0'
                                },
                                {
                                    'x': '2013-09-30',
                                    'y': '101.00245581499999'
                                },
                                {
                                    'x': '2013-10-31',
                                    'y': '107.15608518799999'
                                },
                                {
                                    'x': '2013-11-30',
                                    'y': '109.261644993'
                                },
                                {
                                    'x': '2013-12-31',
                                    'y': '110.48351382800001'
                                },
                                {
                                    'x': '2014-01-31',
                                    'y': '107.84049277200002'
                                },
                                {
                                    'x': '2014-02-28',
                                    'y': '111.864406778'
                                },
                                {
                                    'x': '2014-03-31',
                                    'y': '113.118483029'
                                },
                                {
                                    'x': '2014-04-30',
                                    'y': '116.42376907100002'
                                },
                                {
                                    'x': '2014-05-31',
                                    'y': '121.83662788200002'
                                },
                                {
                                    'x': '2014-06-30',
                                    'y': '124.37698780000001'
                                },
                                {
                                    'x': '2014-07-31',
                                    'y': '123.25174121200001'
                                },
                                {
                                    'x': '2014-08-31',
                                    'y': '122.82499295299999'
                                },
                                {
                                    'x': '2014-09-30',
                                    'y': '122.66596883799998'
                                },
                                {
                                    'x': '2014-10-31',
                                    'y': '117.81472683999998'
                                },
                                {
                                    'x': '2014-11-30',
                                    'y': '114.00217399999998'
                                },
                                {
                                    'x': '2014-12-31',
                                    'y': '115.95474858'
                                },
                                {
                                    'x': '2015-01-31',
                                    'y': '120.01087000199999'
                                },
                                {
                                    'x': '2015-02-28',
                                    'y': '123.92608398'
                                },
                                {
                                    'x': '2015-03-31',
                                    'y': '124.642698981'
                                },
                                {
                                    'x': '2015-04-30',
                                    'y': '128.70083336599998'
                                },
                                {
                                    'x': '2015-05-31',
                                    'y': '129.97302628799997'
                                },
                                {
                                    'x': '2015-06-30',
                                    'y': '126.63754579399999'
                                },
                                {
                                    'x': '2015-07-31',
                                    'y': '128.61427593599998'
                                },
                                {
                                    'x': '2015-08-31',
                                    'y': '119.590160634'
                                },
                                {
                                    'x': '2015-09-30',
                                    'y': '117.11220258399999'
                                },
                                {
                                    'x': '2015-10-31',
                                    'y': '123.84556544099999'
                                },
                                {
                                    'x': '2015-11-30',
                                    'y': '126.56709207199998'
                                },
                                {
                                    'x': '2015-12-31',
                                    'y': '122.84310962499998'
                                },
                                {
                                    'x': '2016-01-31',
                                    'y': '112.91316075399999'
                                },
                                {
                                    'x': '2016-02-29',
                                    'y': '115.24215950599998'
                                },
                                {
                                    'x': '2016-03-31',
                                    'y': '116.298965336'
                                },
                                {
                                    'x': '2016-04-30',
                                    'y': '122.04195015799999'
                                },
                                {
                                    'x': '2016-05-31',
                                    'y': '124.262248882'
                                },
                                {
                                    'x': '2016-06-30',
                                    'y': '121.353516646'
                                },
                                {
                                    'x': '2016-07-31',
                                    'y': '123.32018197100001'
                                },
                                {
                                    'x': '2016-08-31',
                                    'y': '124.58834896600003'
                                },
                                {
                                    'x': '2016-09-30',
                                    'y': '125.34522323600002'
                                },
                                {
                                    'x': '2016-10-31',
                                    'y': '128.46732960100002'
                                },
                                {
                                    'x': '2016-11-30',
                                    'y': '132.177221304'
                                },
                                {
                                    'x': '2016-12-31',
                                    'y': '137.660533837'
                                },
                                {
                                    'x': '2017-01-31',
                                    'y': '139.52252506'
                                },
                                {
                                    'x': '2017-02-28',
                                    'y': '138.948830467'
                                },
                                {
                                    'x': '2017-03-31',
                                    'y': '138.461693304'
                                },
                                {
                                    'x': '2017-04-30',
                                    'y': '140.43641048299997'
                                },
                                {
                                    'x': '2017-05-31',
                                    'y': '142.988848182'
                                },
                                {
                                    'x': '2017-06-30',
                                    'y': '140.621603124'
                                },
                                {
                                    'x': '2017-07-31',
                                    'y': '147.451588228'
                                },
                                {
                                    'x': '2017-08-31',
                                    'y': '148.93312935299997'
                                },
                                {
                                    'x': '2017-09-30',
                                    'y': '157.63315753399996'
                                },
                                {
                                    'x': '2017-10-31',
                                    'y': '162.43608840899995'
                                },
                                {
                                    'x': '2017-11-30',
                                    'y': '160.39896936199995'
                                },
                                {
                                    'x': '2017-12-31',
                                    'y': '163.94581102199996'
                                },
                                {
                                    'x': '2018-01-31',
                                    'y': '163.25335158299995'
                                },
                                {
                                    'x': '2018-02-28',
                                    'y': '165.01670759599995'
                                },
                                {
                                    'x': '2018-03-31',
                                    'y': '162.10797535999995'
                                },
                                {
                                    'x': '2018-04-30',
                                    'y': '173.10680784099995'
                                },
                                {
                                    'x': '2018-05-31',
                                    'y': '176.23897902299996'
                                },
                                {
                                    'x': '2018-06-30',
                                    'y': '176.96767180499995'
                                },
                                {
                                    'x': '2018-07-31',
                                    'y': '180.44204677999997'
                                },
                                {
                                    'x': '2018-08-31',
                                    'y': '182.51338620599998'
                                },
                                {
                                    'x': '2018-09-30',
                                    'y': '188.86831192799997'
                                },
                                {
                                    'x': '2018-10-31',
                                    'y': '189.278956479'
                                },
                                {
                                    'x': '2018-11-30',
                                    'y': '173.31213011699995'
                                },
                                {
                                    'x': '2018-12-31',
                                    'y': '160.92837875799998'
                                },
                                {
                                    'x': '2019-01-31',
                                    'y': '168.14485285099994'
                                },
                                {
                                    'x': '2019-02-28',
                                    'y': '174.17770441499997'
                                },
                                {
                                    'x': '2019-03-31',
                                    'y': '173.74089133899994'
                                },
                                {
                                    'x': '2019-04-30',
                                    'y': '177.32396634199995'
                                },
                                {
                                    'x': '2019-05-31',
                                    'y': '175.32509360199995'
                                },
                                {
                                    'x': '2019-06-30',
                                    'y': '174.04686178899996'
                                },
                                {
                                    'x': '2019-07-31',
                                    'y': '172.94174483599994'
                                },
                                {
                                    'x': '2019-08-31',
                                    'y': '169.32646241699993'
                                },
                                {
                                    'x': '2019-09-30',
                                    'y': '178.46934256499995'
                                },
                                {
                                    'x': '2019-10-31',
                                    'y': '180.77418575499993'
                                },
                                {
                                    'x': '2019-11-30',
                                    'y': '182.74085107999994'
                                },
                                {
                                    'x': '2019-12-31',
                                    'y': '187.49748379499994'
                                },
                                {
                                    'x': '2020-01-31',
                                    'y': '183.94661620799997'
                                },
                                {
                                    'x': '2020-02-29',
                                    'y': '185.35770361099998'
                                },
                                {
                                    'x': '2020-03-31',
                                    'y': '142.34268690299996'
                                },
                                {
                                    'x': '2020-04-30',
                                    'y': '156.02681267299994'
                                },
                                {
                                    'x': '2020-05-31',
                                    'y': '150.48512419899993'
                                },
                                {
                                    'x': '2020-06-30',
                                    'y': '160.07488224099993'
                                },
                                {
                                    'x': '2020-07-31',
                                    'y': '161.37525665199996'
                                },
                                {
                                    'x': '2020-08-31',
                                    'y': '172.96590039799995'
                                },
                                {
                                    'x': '2020-09-30',
                                    'y': '172.32779097299994'
                                },
                                {
                                    'x': '2020-10-31',
                                    'y': '175.55054551199996'
                                },
                                {
                                    'x': '2020-11-30',
                                    'y': '187.28410966499993'
                                },
                                {
                                    'x': '2020-12-31',
                                    'y': '196.05660453199994'
                                },
                                {
                                    'x': '2021-01-31',
                                    'y': '194.63343934799994'
                                },
                                {
                                    'x': '2021-02-28',
                                    'y': '202.71951366699994'
                                },
                                {
                                    'x': '2021-03-31',
                                    'y': '213.14465155499994'
                                },
                                {
                                    'x': '2021-04-30',
                                    'y': '216.6854543249999'
                                },
                                {
                                    'x': '2021-05-31',
                                    'y': '222.7545392319999'
                                },
                                {
                                    'x': '2021-06-30',
                                    'y': '225.6451547959999'
                                },
                                {
                                    'x': '2021-07-31',
                                    'y': '228.3264221579999'
                                },
                                {
                                    'x': '2021-08-31',
                                    'y': '229.85224847999993'
                                },
                                {
                                    'x': '2021-09-30',
                                    'y': '234.17206811799994'
                                },
                                {
                                    'x': '2021-10-31',
                                    'y': '240.10225854399994'
                                },
                                {
                                    'x': '2021-11-30',
                                    'y': '237.78533757299994'
                                },
                                {
                                    'x': '2021-12-31',
                                    'y': '241.84347195799992'
                                },
                                {
                                    'x': '2022-01-31',
                                    'y': '236.49502797899993'
                                },
                                {
                                    'x': '2022-02-28',
                                    'y': '241.99243125599992'
                                },
                                {
                                    'x': '2022-03-31',
                                    'y': '253.90514915899993'
                                },
                                {
                                    'x': '2022-04-30',
                                    'y': '249.57123877599992'
                                },
                                {
                                    'x': '2022-05-31',
                                    'y': '259.1469060729999'
                                },
                                {
                                    'x': '2022-06-30',
                                    'y': '235.57309070199994'
                                },
                                {
                                    'x': '2022-07-31',
                                    'y': '252.26055799099996'
                                },
                                {
                                    'x': '2022-08-31',
                                    'y': '251.35271145899995'
                                },
                                {
                                    'x': '2022-09-30',
                                    'y': '221.987600142'
                                },
                                {
                                    'x': '2022-10-31',
                                    'y': '236.61379282299998'
                                },
                                {
                                    'x': '2022-11-30',
                                    'y': '245.734530373'
                                },
                                {
                                    'x': '2022-12-31',
                                    'y': '239.341358345'
                                },
                                {
                                    'x': '2023-01-31',
                                    'y': '240.63770682900002'
                                },
                                {
                                    'x': '2023-02-28',
                                    'y': '249.79266475800006'
                                },
                                {
                                    'x': '2023-03-31',
                                    'y': '240.26128265700004'
                                },
                                {
                                    'x': '2023-04-30',
                                    'y': '246.87789363200005'
                                },
                                {
                                    'x': '2023-05-31',
                                    'y': '241.26172550900003'
                                },
                                {
                                    'x': '2023-06-30',
                                    'y': '244.39792261800008'
                                },
                                {
                                    'x': '2023-07-31',
                                    'y': '250.59986311500006'
                                },
                                {
                                    'x': '2023-08-31',
                                    'y': '252.74769515300002'
                                },
                                {
                                    'x': '2023-09-17',
                                    'y': '252.74769515300002'
                                }
                            ],
                            'type': 0
                        }
                    ]
                }
            ],
            'birthYear': 0,
            'showPctValues': false,
            'showXAxisAs': 'AAR'
        },
        'lineGraphReference': {}
    }
];
export default HistoryDevelopmentGraph;
