import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '@eika-infrastruktur/ui-kort-laster';

import { settSideTittel } from '../../../actions/header';
import Feilmelding from '../../../components/feilmelding';
import KjopFondVeiviserSkjema from '../../../components/fond/kjop-fond/kjop-fond-veiviser-skjema';
import FondAnbefaling from '../../../components/common/FondAnbefaling';
import Card from '../../../components/card';
import AppMessage from '../../../components/AppMessage';

import * as kundevurderingConst from '../../../domain/kundevurdering';
import KundevurderingStopp from '../../../components/KundevurderingStopp';


require('../../../styles/kjop-fond-veiviser.scss');

class KjopFondVeiviser extends Component {
    static propTypes = {
        texts: PropTypes.object,
        settSideTittel: PropTypes.func,
        errors: PropTypes.object,
        morningstar: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            step: 0,
        };
    }

    UNSAFE_componentWillMount = () => this.props.settSideTittel(this.props.texts.get('kjopFondTittel'));

    onSubmit = (choices) => {
        this.setState({
            ...choices,
            step: 1,
        });
    };

    sjekkForServerFeil = (errors) => errors.LAST_MORNINGSTAR_DATA;

    render() {
        if ( this.props.kundevurdering === kundevurderingConst.MA_BEHANDLES_MANUELT) {
            return <KundevurderingStopp {...this.props} type="stand-alone" />
        }

        if (this.sjekkForServerFeil(this.props.errors)) {
            return <Feilmelding feil={this.props.errors} texts={this.props.texts} />;
        }

        if (this.state.step === 0) {
            return (
                <Card classes="kjop-fond-veiviser" transparent>
                    <AppMessage appMessage={this.props.serviceMessage} context="FUND_GUIDELINE" />

                    <KjopFondVeiviserSkjema
                        texts={this.props.texts}
                        onChange={(change) => this.setState(change)}
                        onSubmit={this.onSubmit}
                        kundevurdering={this.props.kundevurdering}
                    />
                </Card>
            );
        }

        if (this.state.step === 1 && this.props.morningstar) {
            return (
                <Card classes="kjop-fond-veiviser" transparent>
                    <FondAnbefaling
                        header={this.props.texts.get('anbefalteFond')}
                        texts={this.props.texts}
                        tidshorisont={this.state.tidshorisont}
                        risikoprofil={this.state.risikoprofil}
                        morningstar={this.props.morningstar}
                        visAnbefalingsTekst
                    />
                </Card>
            );
        }

        return <Loading title={this.props.texts.get('lasterFondsApp')} />;
    }
}

export default connect(
    (state) => {
        return {
            ...state.Fond,
            ...state.Feilmeldinger,
            ...state.Texts,
            ...state.Header,
            kundevurdering: state.KjennDinKunde?.kundevurdering,
        };
    },
    (dispatch) => {
        return {
            settSideTittel: (sideTittel) => {
                dispatch(settSideTittel(sideTittel));
            },
        };
    }
)(KjopFondVeiviser);
