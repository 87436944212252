// @ts-nocheck // kiet fix it later
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fondsberegning from '../../components/Kalkulator/Fondsberegning';
import { getNewFondOrder } from '../../common/util/FondFunctions';
import { getSparingOgPensjonUrl } from '../../common/util/BusinessFunctions';

// @ts-ignore
import { getFondList } from '../../actions/fond';
import { getFondKalkulator, setFondKalkulator } from '../../actions/kalkulator';

import './Kalkulator.scss';

interface Props {
    type?: string;
}

const useSelectedFund = ( isin='' ): void => {
    if ( !isin ) { return; }

    getNewFondOrder({fund: isin, fromOpenPage: true, id: `${(new Date()).getTime()}`});
    const url = getSparingOgPensjonUrl();
    window.location.href = url;
};

export default (props: Props): JSX.Element => {
    const { morningstar } = useSelector((state: any) => state?.Fond ?? {});
    const dispatch = useDispatch();
    const config = {
        ...(useSelector((state: any) => {
            const { Texts, ...rest } = state || {};
            return { reducer: {...rest}, appTexts: Texts.texts }; 
        })),
        action: {
            dispatch,
            getFondList: (callback?: (p:any) => void) => { dispatch(getFondList(callback)); },
            getFondKalkulator: (data: any, callback?: (p:any) => void) => { dispatch(getFondKalkulator(data, callback)); },
            setFondKalkulator: (data: any ) => { dispatch(setFondKalkulator(data)); },
        },
    };

    useEffect(() => {
        if ( morningstar !== undefined ) { return; }
        dispatch(getFondList());
    }, [morningstar, dispatch]);


    return <div className={`kalkulator-wrapper -${props.type || 'fund'}-calculator`}>
        { !!morningstar && props.type === 'fund' && <Fondsberegning {...props} {...config} standalone useSelectedFund={(isin='')=> {useSelectedFund(isin);}} /> }
    </div>
};