import { VIS_AKSJESPAREKONTO_POPUP } from '../actions/fond';

import { SKJUL_AKSJESPAREKONTO_POPUP } from '../actions/modals';

const initialState = {
    visAksjesparekontoPopup: true,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case VIS_AKSJESPAREKONTO_POPUP:
            return {
                ...state,
                visAksjesparekontoPopup: action.data,
            };
        case SKJUL_AKSJESPAREKONTO_POPUP:
            return {
                ...state,
                visAksjesparekontoPopup: false,
            };
        default:
            return state;
    }
};
