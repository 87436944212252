import React, { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { usePrevious } from '../../common/util/Hook';
import FundOverview from '../../common/FundOverview/FundOverview';
import FundDetail from '../../common/FundDetail/FundDetail';
import FundSuggestion from '../../common/FundSuggestion/FundSuggestion';
import { getNewFondOrder } from '../../common/util/FondFunctions';
import { getFundTextList } from '../../common/FundOverview/util/Functions';
import Constant from '../../static/data/Constant';
import Grid from '../../common/Grid/Grid';
import AppMessage from '../../common/AppMessage/AppMessage';
import { FooterButton, HeaderLink, Spinner } from '../../common/share/ShareComponents';
import { createCookie, getPageAppending, getURLquery, readCookie } from '../../common/util/Functions';
import './FondVisning.scss';
import useMutualFundStore from '../../stores/MutualFundStore';
import { RouterConfig } from '../../app/Router';

type Props = RouteComponentProps<{ isin?: string }> &
    RouterConfig & {
        onlyInfo?: boolean;
        root?: boolean;
    };

interface State {
    appending: string;
    title: string;
    onShowingDetailModal: boolean;
    content?: any;
}

export default (props: Props): JSX.Element => {
    const { setCurrentFund } = useMutualFundStore();
    const reducer = useSelector((state: any) => {
        return ['App', 'Fond'].reduce((p: { [k: string]: any }, k: string) => {
            return { ...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const getContent = (reducer: any): any => {
        const isin = props.match?.params?.isin;
        const content: any = { pin: {}, eika: [], other: [], detail: null, title: '' };
        ((reducer.Fond || {}).morningstar || []).forEach((d: any) => {
            if (!d || !d.id || !d.navn) {
                return;
            }
            content.pin[d.id] = {
                ...d,
                textList: getFundTextList(d),
                type: d.type || (d.aksjesparekontoKompatibel ? 'Aksjefond' : 'Fond'),
            };

            if (d.isin) {
                content.pin[d.isin] = content.pin[d.id];
            }

            if (/^eika/i.test(d.navn)) {
                content.eika.push(content.pin[d.id]);
            } else {
                content.other.push(content.pin[d.id]);
            }
        });

        content.query = getURLquery();
        if (isin && content.pin[isin]) {
            content.detail = content.pin[isin];
        } else {
            content.list = content.eika.concat(content.other);
            content.suggestion = content.query.suggestion || '';
            content.suggestionSelected = content.suggestion ? readCookie(content.query.suggestion) : '';
        }

        return { content, title: content.title };
    };

    const [state, setState] = useState<State>({
        title: 'Fond',
        appending: getPageAppending(props),
        onShowingDetailModal: false,
    });

    const previousReducer = usePrevious(reducer);

    useEffect(() => {
        if (JSON.stringify(reducer) === JSON.stringify(previousReducer)) {
            return;
        }
        setState({ ...state, ...getContent(reducer) });
        //eslint-disable-next-line
    }, [previousReducer, reducer, state, props]);

    const click = (e: MouseEvent | null, key = '', data?: any): void => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        if (key === 'fund-selected' && data && (data.isin || (data.fund && data.fund.isin))) {
            const url = `${props.location.pathname}/${data.isin || data.fund.isin}`;
            if (data.selectedId && state.content?.suggestion) {
                createCookie(state.content?.suggestion, data.selectedId, 0.1);
            }
            const query = state.content?.query || {};
            const param = [];
            for (const key in query) {
                param.push(`${key}=${query[key]}`);
            }
            props.history.push(`${url}${param.length ? `?${param.join('&')}` : ''}`);
        } else if (key === 'use-fund' && props.match?.params?.isin) {
            const detail = state.content?.detail || {};
            setCurrentFund(detail);
            const onetimeMin = detail.minimumsBelopEngangsKjop;
            const monthlyMin = detail.minimumsBelopPeriodiskKjop;
            if (!onetimeMin && !monthlyMin) {
                return;
            }

            const config = {
                finishBack: 2,
                fund: props.match?.params?.isin,
                skipFundSelecting: true,
            };
            if (onetimeMin && monthlyMin) {
                const order = getNewFondOrder(config);
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step0?action=${Constant.piwikActionBuy}`
                );
            } else if (onetimeMin) {
                const order = getNewFondOrder({
                    ...config,
                    type: Constant.typeEngangsinnkudd,
                });
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionOnetime}`
                );
            } else {
                const order = getNewFondOrder({
                    ...config,
                    type: Constant.typeManedligSparing,
                });
                props.history.push(
                    `${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionMonthly}`
                );
            }
        }
    };

    return (
        <div className="fond-visning-wrapper">
            <Grid
                namespace="sparing-top"
                list={[
                    {
                        layout: 'twelve',
                        element: <HeaderLink text="Forrige side" keepQuery={!!state.content?.detail} />,
                    },
                ]}
            />

            <Grid
                namespace={`sparing-header -${state.title ? 'normal' : 'empty-header'}`}
                list={[
                    {
                        layout: 'twelve',
                        element: state.title ? <h1>{state.content?.title}</h1> : null,
                    },
                ]}
            />

            {!!state.content?.list && state.content?.suggestion && (
                <FundSuggestion
                    {...props}
                    fundList={state.content.list}
                    selectedId={state.content.suggestionSelected}
                    suggestedCallback={(data) => {
                        click(null, 'fund-selected', data);
                    }}
                    appMessage={<AppMessage appMessage={reducer?.App?.serviceMessage} context="FUND_GUIDELINE" />}
                />
            )}

            {!state.content?.suggestion && (
                <div
                    className={`fond-visning-content -${
                        state.onShowingDetailModal ? 'show-detail-modal' : 'none-show-detail-modal'
                    } ${state.appending}`}
                >
                    <Grid
                        namespace="sparing-content"
                        list={[
                            {
                                layout: 'twelve',
                                element: (
                                    <div className="fond-visning-holder">
                                        {state.content ? (
                                            <>
                                                {!state.content.detail && (
                                                    <AppMessage
                                                        appMessage={reducer?.App?.serviceMessage}
                                                        context="FUND_LIST"
                                                    />
                                                )}

                                                {!!state.content.list && !state.content.suggestion && (
                                                    <FundOverview
                                                        {...props}
                                                        fundList={state.content.list || []}
                                                        displayRightArrow
                                                        selectCallback={(data) => {
                                                            click(null, 'fund-selected', data);
                                                        }}
                                                    />
                                                )}

                                                {!!state.content.detail && (
                                                    <>
                                                        {props.onlyInfo ? (
                                                            <FundDetail
                                                                {...props}
                                                                data={state.content.detail}
                                                                setOnShowingDetailModal={(b = false) => {
                                                                    setState({ ...state, onShowingDetailModal: b });
                                                                }}
                                                            />
                                                        ) : (
                                                            <FundDetail
                                                                {...props}
                                                                data={state.content.detail}
                                                                selectFundText="Spar i fond"
                                                                click={(e) => {
                                                                    click(e, 'use-fund');
                                                                }}
                                                                setOnShowingDetailModal={(b = false) => {
                                                                    setState({ ...state, onShowingDetailModal: b });
                                                                }}
                                                            >
                                                                <FooterButton
                                                                    fixForMobile
                                                                    click={(e) => {
                                                                        click(e, 'use-fund');
                                                                    }}
                                                                    next="Spar i fond"
                                                                />
                                                            </FundDetail>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <Spinner type="fond-visning" />
                                        )}
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            )}
        </div>
    );
};
