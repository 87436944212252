import { ProcessJsonResponse } from './common';

export const HENT_LISTE_OVER_CRS_LAND = 'HENT_LISTE_OVER_CRS_LAND';
export const KUNDEVURDERING = 'KUNDEVURDERING';
export const KJENNDINKUNDE_SKJEMA_FOR_KUNDE = 'KJENNDINKUNDE_SKJEMA_FOR_KUNDE';
export const KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE = 'KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE';
export const KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN = 'KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN';
export const KJENNDINKUNDE_KAN_UTFYLLE_AHV = 'KJENNDINKUNDE_KAN_UTFYLLE_AHV';
export const KJENNDINKUNDE_LOADING = 'KJENNDINKUNDE_LOADING';

const baseUrl = '/sparing-min-side-web/rest/resource';
const hentKundevurderingUrl = `${baseUrl}/kjenndinkunde/kundevurdering`;
const hentKundevurderingUrlBarn = `${baseUrl}/kjenndinkunde/kundevurdering/barn`;
const hentCRSLandUrl = `${baseUrl}/kjenndinkunde/crsland`;
const kjennDinKundeUrl = `${baseUrl}/kjenndinkunde`;
const kjennDinKundeOppfolgingUrl = `${baseUrl}/kjenndinkunde/oppfolging`;
const kjennDinKundeUrlKanUtfylleAHV = `${baseUrl}/kjenndinkunde/kan-opprette-sparemal-barn`;
const kjennDinKundeUrlBarn = `${baseUrl}/kjenndinkunde/barn`;
const registrerSvarBarn = `${baseUrl}/kjenndinkunde/registrer/barn`;

export const hentListeOverCRSLand = () => {
    return (dispatch) => {
        fetch(hentCRSLandUrl, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(HENT_LISTE_OVER_CRS_LAND, data, dispatch, undefined, true)
        );
    };
};

export const hentKundevurdering = () => {
    return (dispatch) => {
        fetch(hentKundevurderingUrl, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(KUNDEVURDERING, data, dispatch)
        );
    };
};

export const hentKundevurderingBarn = (fnr) => {
    const urlMedFnr = `${hentKundevurderingUrlBarn}/${fnr}`;
    return (dispatch) => {
        fetch(urlMedFnr, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(KUNDEVURDERING, data, dispatch)
        );
    };
};

export const hentSkjemaForKunde = () => {
    return (dispatch) => {
        fetch(kjennDinKundeUrl, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(KJENNDINKUNDE_SKJEMA_FOR_KUNDE, data, dispatch, undefined, true)
        );
    };
};

export const hentOppfolgingSkjemaForKunde = () => {
    return (dispatch) => {
        fetch(kjennDinKundeOppfolgingUrl, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE, data, dispatch, undefined, true)
        );
    };
};

export const setOppfolgingSkjemaForKunde = ( data ) => {
    return (dispatch) => {
        dispatch({ type: KJENNDINKUNDE_OPPFOLGINGSKJEMA_FOR_KUNDE, data });
    };
};

export const hentSkjemaForKundeBarn = (fnr) => {
    const urlMedFnr = `${kjennDinKundeUrlBarn}/${fnr}`;
    return (dispatch) => {
        fetch(urlMedFnr, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN, data, dispatch)
        );
    };
};

export const kanUtfylleSkjemaForBarn = (fnr) => {
    const url = `${kjennDinKundeUrlKanUtfylleAHV}/${fnr}`;
    return (dispatch) => {
        dispatch({type: KJENNDINKUNDE_LOADING, data: true});
        fetch(url, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(KJENNDINKUNDE_KAN_UTFYLLE_AHV, data, dispatch)
        );
    };
};

export const sendKjennDinKundeSvar = async (dispatch, data, bankregnr, fnrBarn, tradexCustomerId) => {
    const config = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    };
    let url;
    if ( tradexCustomerId ) {
        url = `${baseUrl}/kjenndinkunde/registrer/barn/cid/${tradexCustomerId}`;
    } else if (fnrBarn) {
        url = `${registrerSvarBarn}/${fnrBarn}`;
    } else {
        url = `${kjennDinKundeUrl}/registrersvar`;
    }

    const response = await fetch(url, config);
    if (fnrBarn) {
        return ProcessJsonResponse(KUNDEVURDERING, response, dispatch, { fnrBarn });
    }
    return ProcessJsonResponse(KUNDEVURDERING, response, dispatch);
};

export const getSkjemaForKundeBarn = (tradexCustomerId, callback) => async (dispatch) => {
    let result = null;
    const url = `${baseUrl}/kjenndinkunde/barn/cid/${tradexCustomerId}`;
    // const url = `${baseUrl}/kjenndinkunde/sparing-til-barn-soknad/${sparemalId}`;
    
    try {
        result = await fetch( url ).then( (response) => {
            return response.status === 200 ? response.json() : null;
        }).catch( () => null );
    } catch ( error ) {
        result = null;        
    }

    return typeof(callback) === 'function' ? 
        callback( result ): dispatch({ type: KJENNDINKUNDE_SKJEMA_FOR_KUNDE_BARN, data: result });
};


