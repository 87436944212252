import { get, put } from '@eika-infrastruktur/request';
import EikaFondDescriptionList from '../static/data/EikaFondDescriptionList';
import { getSisteOrdre } from './ordre';
import { processError, processResponse, utforOrdre } from './common';
import { VIS_KVITTERING } from './modals';
import { IPS_OPPRETTET } from './pensjon';
import Constant from '../static/data/Constant';
import { getAvkastning } from '../common/util/Functions';
import { postLog } from './app';

export const LAST_PORTEFOLJE = 'LAST_PORTEFOLJE';
export const VIS_AKSJESPAREKONTO_POPUP = 'VIS_AKSJESPAREKONTO_POPUP';
export const LAST_MORNINGSTAR_DATA = 'LAST_MORNINGSTAR_DATA';
export const HENT_GYLDIGE_PORTEFOLJER = 'HENT_GYLDIGE_PORTEFOLJER';
export const KJOP_UTFORT = 'KJOPS_STEG_FERDIG';
export const AVTALE_GIRO = 'AVTALE_GIRO';
export const HENT_HISTORIKK = 'HENT_HISTORIKK';
export const SET_AARLIG_SPARE_BELOP = 'SET_AARLIG_SPARE_BELOP';
export const OPPDATER_PORTEFOLJER = 'OPPDATER_PORTEFOLJER';
export const HENT_SKATTEMESSIGKOSTPRIS = 'HENT_SKATTEMESSIGKOSTPRIS';
export const HENT_ESTIMAT = 'HENT_ESTIMAT';
export const DONE = 'DONE';
export const LOADING = 'LOADING';
export const FAILURE = 'FAILURE';
export const HENT_PROGNOSE = 'HENT_PROGNOSE';
export const HENT_PORTEFOLJE = 'HENT_PORTEFOLJE';
export const HENT_DISPONERTE_PORTEFOLJE = 'HENT_DISPONERTE_PORTEFOLJE';
export const SET_PORTEFOLJE = 'SET_PORTEFOLJE';
export const SET_FOND_HISTORIKK = 'SET_FOND_HISTORIKK';
export const SET_DEBIT_ACCOUNT = 'SET_DEBIT_ACCOUNT';
export const SET_VPS = 'SET_VPS';

const baseUrlFond = '/sparing-min-side-web/rest/resource/fond';
const endreMndSparingUrl = `${baseUrlFond}/endrespareavtale`;
const byttFondUrl = `${baseUrlFond}/byttfond`;
const kjopFondUrl = `${baseUrlFond}/kjopfond`;
const gyldigePortefoljerUrl = `${baseUrlFond}/gyldigeportefoljer`;
const historikkUrl = `${baseUrlFond}/utvikling`;
const endrePortefoljeNavnUrl = `${baseUrlFond}/portefoljer`;
const flyttFondUrl = `${baseUrlFond}/flyttFond`;
const hentSkattemessigKostprisUrl = `${baseUrlFond}/skattemessigKostpris`;

/*******************************************************************************
 *******************************************************************************/
const mergeOrdreOgTransaksjon = (dispatch, result, callback, disposed) => {
    const pin = (result.portefoljer ?? []).reduce((pin, portefolje) => {
        pin[portefolje.id] = portefolje;
        pin[portefolje.portfolioBaseCode] = portefolje;

        const type = pin[portefolje.portfolioBaseCode].type || pin[portefolje.portfolioBaseCode].portefoljeType;
        pin[portefolje.portfolioBaseCode].isIPS = /ips/i.test(type);
        portefolje.avkastning = getAvkastning(portefolje.totalVerdi, portefolje.innskudd);

        // Midlitidig frontend fiks SPASP-3220
        const note = disposed ? { totalVerdi: 0, urealisertGevinst: 0 } : {};
        (portefolje.fond || []).forEach((fond) => {
            fond.avkastning = getAvkastning(fond.totalVerdi, fond.innskudd);
            pin[`${portefolje.portfolioBaseCode}-${fond.isin}`] = fond;
            ['eier', 'isIPS', 'sperre'].forEach((key) => {
                pin[`${portefolje.portfolioBaseCode}-${fond.isin}`][key] = pin[portefolje.portfolioBaseCode][key];
            });

            fond.portefolje = { ...portefolje, fond: null };
            // Midlitidig frontend fiks SPASP-3220
            for (const k in note) {
                note[k] += fond[k] || 0;
            }
        });

        // Midlitidig frontend fiks SPASP-3220
        for (const k in note) {
            if (!portefolje[k]) {
                portefolje[k] = note[k];
            }
        }

        return pin;
    }, {});

    (result.kontoer || []).forEach((d) => {
        if (pin[d.portefoljeId] && pin[d.portefoljeId].sperre) {
            d.sperre = pin[d.portefoljeId].sperre;
        }
    });

    dispatch(
        getSisteOrdre(result, (source) => {
            (source || []).forEach((src) => {
                if (!src) {
                    return;
                }
                const { fondIsin, portefoljeId } = src;
                const portfolioBaseCode = pin[portefoljeId]?.portfolioBaseCode;
                if (!portfolioBaseCode) {
                    return;
                }

                const key =
                    src.statusGroup === Constant.orderStatusPending || src.statusGroup === Constant.orderStatusNoMoney
                        ? 'ordreList'
                        : 'transaksjonList';
                if (pin[portfolioBaseCode]) {
                    pin[portfolioBaseCode][key] = (pin[portfolioBaseCode][key] || []).concat(src);
                }

                if (pin[`${portfolioBaseCode}-${fondIsin}`]) {
                    pin[`${portfolioBaseCode}-${fondIsin}`][key] = (
                        pin[`${portfolioBaseCode}-${fondIsin}`][key] || []
                    ).concat(src);
                }

                src.portfolioBaseCode = portfolioBaseCode;
            });
            callback(result);
        })
    );
};

/*******************************************************************************
 *******************************************************************************/
export const hentGyldigePortefoljer = (isin) => {
    return (dispatch) => {
        get(`${gyldigePortefoljerUrl}/${isin}?inkluderIps=true`)
            .then((data) => processResponse(HENT_GYLDIGE_PORTEFOLJER, data, dispatch, { isin }))
            .catch((error) => processError(HENT_GYLDIGE_PORTEFOLJER, error, dispatch));
    };
};

export const getVPS = () => async (dispatch) => {
    let result = null;
    const url = `${baseUrlFond}/vps`;
    try {
        result = await get(url).catch((error) => {
            dispatch(postLog(error, { api: url, function: 'getVPS - fetch-catch' }));
            return null;
        });
    } catch (error) {
        dispatch(postLog(error, { api: url, function: 'getVPS - try-catch' }));
        result = null;
    }

    const vps = (result || []).filter((d) => {
        if (!d) {
            return false;
        }

        if (!d.navn) {
            d.navn = 'VPS-konto';
        }

        d.id = d.kontonummer;
        d.portfolioBaseCode = d.kontonummer;
        d.portefoljeType = 'VPS';
        d.initialValue = d.verdi;

        if (d.totaltInnskudd === undefined) {
            d.totaltInnskudd = 0;
        }
        if (d.verdistigning === undefined) {
            d.verdistigning = 0;
        }

        d.eksterneFond = d.verdipapirer || [];
        (d.eksterneFond || []).forEach((f) => {
            f.portefolje = JSON.parse(JSON.stringify(d));
            f.navn = f.fondsnavn;
            f.totalVerdi = f.markedsverdi;
        });
        return true;
    });

    if (vps.length) {
        dispatch({ type: SET_VPS, data: vps });
    }
};

export const getPortefolje = (callback) => async (dispatch) => {
    let result = null;
    const url = `${baseUrlFond}/totalbeholdning/bank?addReachoutData=true&addPortfolioData=true&addDisponentPortfolios=true`;

    try {
        result = await get(url).catch((error) => {
            dispatch(postLog(error, { api: url, function: 'getPortefolje - fetch-catch' }));
            return null;
        });
    } catch (error) {
        dispatch(postLog(error, { api: url, function: 'getPortefolje - try-catch' }));
        result = null;
    }

    [
        { key: 'egneKundeforhold', type: LAST_PORTEFOLJE },
        { key: 'disponerteKundeforhold', type: HENT_DISPONERTE_PORTEFOLJE },
    ].forEach((config) => {
        const portefoljer = ((result || {})[config.key] || []).reduce((list, data) => {
            (data.totalBeholdningPortefoljeList || []).forEach((portfolio) => {
                const source = { ...portfolio };
                if (config.key === 'disponerteKundeforhold') {
                    source.eier = [data.fornavn || '', data.etternavn || ''].join(' ').trim();
                }

                list.push({
                    ...source,
                    fond: (portfolio.totalBeholdningFondList || []).map((fond) => {
                        return {
                            ...fond,
                            ...(fond.fondInfo || {}),
                        };
                    }),
                });
            });
            return list;
        }, []);

        mergeOrdreOgTransaksjon(dispatch, { portefoljer }, () => {
            if (typeof callback === 'function') {
                return callback(portefoljer);
            }
            dispatch({ type: config.type, data: portefoljer });
        });
    });
};

export const setPortefolje = (data) => async (dispatch) => {
    dispatch({ type: SET_PORTEFOLJE, data });
};

export const getAarligSparebelop = (portfolioBaseCode, callback) => async (dispatch) => {
    let result = null;
    if (!portfolioBaseCode) {
        return typeof callback === 'function'
            ? callback(result)
            : dispatch({ type: SET_AARLIG_SPARE_BELOP, data: result });
    }

    const url = `${baseUrlFond}/sparedata/${portfolioBaseCode}`;
    try {
        result = await get(url).catch(() => null);
    } catch (error) {
        result = null;
    }

    if (!result) {
        result = { aarligSparebelop: 0 };
    }

    if (!result.maksbelop) {
        result.maksbelop = Constant.ipsMaxYearlyAmount;
    }

    return typeof callback === 'function'
        ? callback(result)
        : dispatch({ type: SET_AARLIG_SPARE_BELOP, data: { portfolioBaseCode, data: result } });
};

export const setAarligSparebelop = (portfolioBaseCode, data) => async (dispatch) => {
    dispatch({ type: SET_AARLIG_SPARE_BELOP, data: { portfolioBaseCode, data } });
};

export const setLoading = () => {
    return { type: LOADING };
};

export const hentSkattemessigKostpris = (portfolioBaseCode, bankregnr) => {
    return (dispatch) => {
        dispatch({
            type: HENT_SKATTEMESSIGKOSTPRIS,
            data: {
                portfolioBaseCode,
                status: LOADING,
            },
        });

        get(`${hentSkattemessigKostprisUrl}/${portfolioBaseCode}/${bankregnr}`)
            .then((response) => {
                dispatch({
                    type: HENT_SKATTEMESSIGKOSTPRIS,
                    data: {
                        ...response,
                        status: DONE,
                        portfolioBaseCode,
                    },
                });
            })
            .catch(() =>
                dispatch({
                    type: HENT_SKATTEMESSIGKOSTPRIS,
                    data: {
                        status: FAILURE,
                        portfolioBaseCode,
                    },
                })
            );
    };
};

export const lastMorningstarData = (callback) => async (dispatch) => {
    let result = null;
    const url = `${baseUrlFond}/morningstar`;
    try {
        result = await get(url).catch((error) => {
            dispatch(postLog(error, { api: url, function: 'lastMorningstarData - fetch-catch' }));
            return null;
        });
    } catch (error) {
        dispatch(postLog(error, { api: url, function: 'lastMorningstarData - try-catch' }));
        result = null;
    }

    if (result) {
        const pin = EikaFondDescriptionList.reduce((pin, data) => {
            pin[data.isin] = data.descriptionList;
            return pin;
        }, {});

        result.forEach((data) => {
            // SPASP-5619
            if (data.isin === 'NO0010212350') {
                // Eika Alpha
                data.yearlyCost = '1 - 4';
                data.ongoingCharge = '1 - 4';
            }

            data.descriptionList = pin[data.isin] || [];
        });
    }

    // return dispatch({ type: LAST_MORNINGSTAR_DATA, data: (result || [])});
    return typeof callback === 'function'
        ? callback(result)
        : dispatch({ type: LAST_MORNINGSTAR_DATA, data: result || [] });
};

const paaVellykketFondsOrdre = (data, dispatch) => {
    const { avtalegiroUrl, avtalegiroObject, ordreResultater } = data;

    dispatch({
        type: VIS_KVITTERING,
        avtalegiroUrl,
        avtalegiroObject,
        ordreResultater,
    });

    if (avtalegiroUrl || avtalegiroObject) {
        dispatch({
            type: AVTALE_GIRO,
            ...data,
        });
    }
};

export const endreMndSparing = (data) => {
    return utforOrdre(data, endreMndSparingUrl, 'PUT', paaVellykketFondsOrdre);
};

const mapPortefoljeNavnToArray = (portefoljeNavn) => {
    const array = [];
    for (const key in portefoljeNavn) {
        if (Object.prototype.hasOwnProperty.call(portefoljeNavn, key) && portefoljeNavn[key] !== undefined) {
            array.push({
                id: key,
                name: portefoljeNavn[key].name,
                bankregnr: { value: portefoljeNavn[key].bankregnr },
            });
        }
    }
    return array;
};

export const endrePortefoljeNavn = (portefoljeNavn) => {
    const array = mapPortefoljeNavnToArray(portefoljeNavn);

    return (dispatch) => {
        put(endrePortefoljeNavnUrl, array).then((data) => {
            dispatch({
                type: OPPDATER_PORTEFOLJER,
                data,
            });
        });
    };
};

const oppdaterPortefoljer = (data, dispatch) => {
    dispatch({
        type: VIS_KVITTERING,
    });

    dispatch({
        type: OPPDATER_PORTEFOLJER,
        data,
    });
};

export const flyttFond = (nomineeValg) => {
    return utforOrdre(nomineeValg, flyttFondUrl, 'PUT', oppdaterPortefoljer);
};

export const byttFond = (data) => {
    return utforOrdre(data, byttFondUrl, 'POST', paaVellykketFondsOrdre);
};

export const kjopFond = (data) => {
    const { ...rest } = data;
    return utforOrdre(rest, kjopFondUrl, 'POST', (result, dispatch) => {
        paaVellykketFondsOrdre(result, dispatch);
    });
};

export const hentHistorikk = (portfolioBaseCode, isin) => {
    const url = `${historikkUrl}/${portfolioBaseCode}/${isin}`;
    return (dispatch) => {
        get(url)
            .then((data) => {
                processResponse(HENT_HISTORIKK, data, dispatch, { portfolioBaseCode, isin });
            })
            .catch((error) => processError(HENT_HISTORIKK, error, dispatch));
    };
};

/*******************************************************************************
 *******************************************************************************/
export const getFondList = (callback) => async (dispatch) => {
    return dispatch(lastMorningstarData(callback));
};

export const getFondHistorikk = (isin, length, type, callback) => async (dispatch) => {
    let result = null;
    const url = `${baseUrlFond}/historisk/${isin}/${length}/${type}`;
    try {
        result = await get(url).catch(() => null);
    } catch (error) {
        result = null;
    }

    return typeof callback === 'function' ? callback(result) : dispatch({ type: SET_FOND_HISTORIKK, data: result });
};

export const getDebitAccount = (callback) => async (dispatch) => {
    let result = [];
    const url = `${baseUrlFond}/chargeable-accounts`;
    try {
        result = await get(url).catch((error) => {
            dispatch(postLog(error, { api: url, function: 'getDebitAccount - fetch-catch' }));
            return [];
        });
    } catch (error) {
        dispatch(postLog(error, { api: url, function: 'getDebitAccount - try-catch' }));
        result = [];
    }

    return typeof callback === 'function' ? callback(result) : dispatch({ type: SET_DEBIT_ACCOUNT, data: result });
};

/*******************************************************************************
 *******************************************************************************/
export const getFondHistorikkUtvikling = (isin, length, type, callback) => async () => {
    let result = null;
    const url = `${baseUrlFond}/historisk/${isin}/${length}/${type}`;
    try {
        result = await get(url).catch(() => null);
    } catch (error) {
        result = null;
    }

    if (callback) {
        callback(result);
    }
};

/*******************************************************************************
 *******************************************************************************/
export const getFondKalkulator = (data, callback) => async () => {
    let result = null;
    const { isin = '', monthly = 0, onetime = 0, frequency = '', point = 5 } = data;
    if (!isin || (!monthly && !onetime)) {
        return typeof callback === 'function' ? callback(result) : null;
    }

    const start = parseInt(`${monthly}`, 10) + parseInt(`${onetime}`, 10);
    const param = [
        `maanedligSparing=${monthly}`,
        `frekvens=${/^DAGLIG|MAANEDLIG|AARLIG^/.test(frequency) ? frequency : 'AARLIG'}`,
        `punkter=${point}`,
    ];

    const base = '/sparing-min-side-web/rest/resource';
    const url = `${base}/kalkulator/forventet-utvikling/${isin}/${start}?${param.join('&')}`;

    try {
        result = await get(url).catch(() => null);
    } catch (error) {
        result = null;
    }

    if (callback) {
        callback(result);
    }
};

/*******************************************************************************
 *******************************************************************************/
const initialState = {
    kjopStegStatus: {
        done: true,
        success: true,
    },
    loading: false,
    gyldigePortefoljer: {},
    disponertePortefoljer: [],
    harASKKompatibleFondIEVPK: false,
    skattemessigKostpris: {},
    portefoljeFraBeholdning: {},
    lastetDisponertePortefoljer: false,
    fondHistorikk: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HENT_HISTORIKK: {
            const { data } = action;
            const historikk = data.data ? data.data : null;

            return {
                ...state,
                portefoljeData: {
                    ...state.portefoljeData,
                    historikk: {
                        ...state.portefoljeData.historikk,
                        [`${data.portfolioBaseCode}${data.isin}`]: historikk,
                    },
                },
            };
        }
        case HENT_PORTEFOLJE: {
            return {
                ...state,
                loading: false,
                portefoljeFraBeholdning: action.data.data,
            };
        }
        case LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case HENT_DISPONERTE_PORTEFOLJE: {
            return {
                ...state,
                lastetDisponertePortefoljer: true,
                disponertePortefoljer: action.data,
            };
        }
        case OPPDATER_PORTEFOLJER: {
            return {
                ...state,
                portefoljeData: {
                    ...state.portefoljeData,
                    ...action.data,
                },
            };
        }
        case SET_AARLIG_SPARE_BELOP: {
            return {
                ...state,
                portefoljeData: {
                    ...state.portefoljeData,
                    sparebelop: {
                        ...state.portefoljeData.sparebelop,
                        [`${(action.data || {}).portfolioBaseCode || ''}`]: (action.data || {}).data || {},
                    },
                },
            };
        }
        case HENT_GYLDIGE_PORTEFOLJER:
            return {
                ...state,
                gyldigePortefoljer: {
                    ...state.gyldigePortefoljer,
                    portefoljeTilknyttetAnnenBank: action.data.data.portefoljeTilknyttetAnnenBank,
                    [action.data.isin]: action.data.data.portefoljer,
                },
            };
        case SET_PORTEFOLJE:
            return {
                ...state,
                portefoljeData: action.data,
            };
        case SET_VPS:
            return {
                ...state,
                vspList: action.data,
                portefoljeData: action.data
                    ? {
                          ...(state.portefoljeData || {}),
                          portefoljer: ((state.portefoljeData || {}).portefoljer || [])
                              .concat(action.data)
                              .filter((d) => !!d),
                      }
                    : state.portefoljeData,
            };
        // Temporary fix for getting VPS account.
        // Those accounts will later appear in the new API.
        /* eslint-disable */
        case LAST_PORTEFOLJE:
            return {
                ...state,
                portefoljeData: action.data
                    ? {
                          portefoljer: (action.data || []).concat(state.vspList || []).filter((d) => !!d),
                          historikk: {},
                          sparebelop: {},
                      }
                    : state.vpsList
                    ? { portefoljer: state.vspList, sparebelop: {}, historikk: {} }
                    : null,
            };
        /* eslint-enable */

        /*
        case LAST_PORTEFOLJE:
            return {
                ...state,
                portefoljeData: action.data ? {
                    ...action.data,
                    historikk: {},
                    sparebelop: {}
                } : null,
            };
        */

        case LAST_MORNINGSTAR_DATA:
            return {
                ...state,
                morningstar: action.data,
                morningstarPin: (action.data || []).reduce((p, d) => {
                    p[d?.isin] = d;
                    return p;
                }, {}),
            };
        case KJOP_UTFORT: {
            if (action.portefoljer) {
                return {
                    ...state,
                    kjopsStatus: {
                        ...action.kjopsStatus,
                    },
                    portefoljeData: action.portefoljer,
                };
            }

            return {
                ...state,
                kjopsStatus: {
                    ...action.kjopsStatus,
                },
            };
        }
        case AVTALE_GIRO:
            return {
                ...state,
                avtalegiroUrl: action.avtalegiroUrl,
                avtalegiroObject: action.avtalegiroObject,
            };
        case IPS_OPPRETTET: {
            return {
                ...state,
                portefoljeData: action.portefoljer,
            };
        }
        case HENT_SKATTEMESSIGKOSTPRIS: {
            const { portfolioBaseCode } = action.data;

            return {
                ...state,
                skattemessigKostpris: {
                    ...state.skattemessigKostpris,
                    [portfolioBaseCode]: action.data,
                },
            };
        }
        case HENT_ESTIMAT: {
            return {
                ...state,
                fondEstimat: action.data,
            };
        }
        case HENT_PROGNOSE: {
            return {
                ...state,
                fondsPrognose: action.data,
            };
        }
        case SET_FOND_HISTORIKK: {
            return {
                ...state,
                fondHistorikk: action.data,
            };
        }
        case SET_DEBIT_ACCOUNT: {
            return {
                ...state,
                debitAccount: action.data,
            };
        }
        default:
            return state;
    }
};
