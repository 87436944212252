import React from 'react';

import './style/EKFpubliseringsdato.scss';

const EKFpubliseringsdato = (props) => {
    const name = ((window.location.href.match( /([\w-]+)$/ ) || [])[1] || '').replace( /-/g, '').toLowerCase().trim();
    const config = props?.config?.data?.text || {};
    const date = config[`${name}Publiseringsdato`];

    return date ? <div className="ekf-publiseringsdato-wrapper">{date}</div> : null;
};

export default EKFpubliseringsdato;
