import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { LinkButton, StandardButton } from '@eika/button';
import { Link } from '@eika/link';
import { IconTypes } from '@eika/icon';
import { Input } from '@eika/input';
import { Label } from '@eika/label';
import { formatAmount } from '../../../common/util/Functions';
import { getContactUsUrl } from '../../../common/util/BusinessFunctions';
import { Spinner, FooterButton } from '../../../common/share/ShareComponents';
import Countdown from '../../../common/Countdown/Countdown';
import Grid from '../../../common/Grid/Grid';
import Card from '../../../common/Card/Card';
import Chart from '../../../common/Chart/Chart';
import Message from '../../../common/Message/Message';
import { getHistoryGraphChartConfig } from './EPKmoveFunctions';
import Constant from '../../../static/data/Constant';
// @ts-ignore
import EPKriskProfileList from '../../../static/data/EPKriskProfileList';

/** ************************************************************************
 ************************************************************************* */
export const EPKmoveDescription = ({appTexts, children, click}: {appTexts: any; children?: JSX.Element, click: (e:MouseEvent, k:string) => void } ): JSX.Element => {
    const list = [{
        id: 'checked-1',
        type: 'checked-panel -grey',
        data: {text: [appTexts.get('epkMoveDescriptionText1'), appTexts.get('epkMoveDescriptionText2')]},
    }, {
        id: 'checked-2',
        type: 'checked-panel -grey',
        data: {text: [appTexts.get('epkMoveDescriptionText3'), appTexts.get('epkMoveDescriptionText4')]},
    }, {
        id: 'checked-3',
        type: 'checked-panel -grey',
        data: {text: [appTexts.get('epkMoveDescriptionText5'), appTexts.get('epkMoveDescriptionText6')]},
    }];

    return <div className={classNames('epk-move-description-wrapper')}>
        <Grid namespace="sparing-header" list={[{
            layout: 'twelve',
            element: <>
                <h1>{appTexts.get('epkMoveDescriptionTitle')}</h1>
                <p className="max-width-600">{appTexts.get('epkMoveDescriptionText0')}</p>
                <div>
                    <LinkButton icon={IconTypes.INFO_20} onClick={(e: MouseEvent)=>{ click(e, 'what-is-epk')}}>
                        {appTexts.get('whatIsEPK')}
                    </LinkButton>
                </div>
            </>
        }]}/>

        <Grid namespace="sparing-content" list={[{
            layout: 'ten',
            element: <div className="epk-move-description-content">
                <div className="epk-move-description-content-holder">
                    { list.map( (d: any) => ( <Card key={d.id} {...d}/> ))}
                </div>
                { children }
            </div>
        }]}/>
    </div>
};

/** ************************************************************************
 ************************************************************************* */
export const EPKriskProfileOverview = ({appTexts, click}: {appTexts: any; click: (e:MouseEvent, k:string, d:any) => void } ): JSX.Element => {
    return <div className={classNames('epk-risk-profile-overview-wrapper')}>
        <Grid namespace="sparing-header" list={[{
            layout: 'twelve',
            element: <h1>{appTexts.get('velgRisikoprofil')}</h1>
        }]}/>

        <Grid namespace="sparing-content" list={[{
            layout: 'twelve',
            element: <>
                <ul className="paragraph-large risk-profile-list">
                    { EPKriskProfileList.map( (d: any) => (
                        <li key={d.key}>
                            <div className="risk-profile-card">
                                { ['suggested', 'title', 'hint'].map( (field) => {
                                    return <div key={field} className={`${field} -${d[field] ? 'basic' : 'empty'}`}><span>{d[field] || '_'}</span></div>;
                                })}

                                <div className="paragraph -include-top">
                                    <LinkButton onClick={(e: MouseEvent)=>{ click(e, Constant.actionDisplayEPKriskProfileInfo, d.riskProfileId)}}>
                                        {appTexts.get('descriptionAndPrice')}
                                    </LinkButton>
                                </div>
                                <div>
                                    <StandardButton onClick={(e: MouseEvent)=>{ click(e, 'select-risk-profile', d.riskProfileId)}}>
                                        {appTexts.get('select')}
                                    </StandardButton>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>

                <div className="epk-move-contact-us-wraper center max-width-600 -center">
                    <div className="h2">{appTexts.get('doYouNeedHelp')}</div>
                    <p>{appTexts.get('doYouNeedHelpText')}</p>
                    <div className="paragraph -only-top">
                        <Link href={getContactUsUrl()} linkType="button-negative">
                            {appTexts.get('kontaktOss')}
                        </Link>
                    </div>
                </div>
            </>
        }]}/>
    </div>
};

/** ************************************************************************
 ************************************************************************* */
export const EPKriskProfileInfo = ({appTexts, click, riskProfileId}: {appTexts: any; click: (e: any, k: any) => void, riskProfileId?: number } ): JSX.Element | null => {
    const info = EPKriskProfileList.find( (d: any) => d.riskProfileId === riskProfileId );
    return info ? <div className={classNames('epk-risk-profile-info-wrapper paragraph-large')}>
        <h1>{info.title}</h1>
        <p>{info.description}</p>

        <section aria-labelledby="epk-info-price-title" className="paragraph-large -include-top">
            <h2 id="epk-info-price-title" className="paragraph">{info.price.title}</h2>
            { info.price.data.map( (text: any, i: number) => {
                return <Card key={`price-info-${i}`} data={{text: [text]}} type="list-item" />
            })}
        </section>

        <div>
            <LinkButton  icon={IconTypes.NEDLASTING_24} onClick={(e: MouseEvent)=>{ click(e, 'download-risk-profile-pdf')}}>
                {appTexts.get('epkDownloadPDF')}
            </LinkButton>
        </div>

        <div>
            <LinkButton  icon={IconTypes.GRAFLINJE_24} onClick={(e: MouseEvent)=>{ click(e, Constant.actionDisplayEPKhistoryDevelopmentGraph)}}>
                {appTexts.get('seeHistoryDevelopmentGraph')}
            </LinkButton>
        </div>

    </div> : null;
};

/** ************************************************************************
 ************************************************************************* */
export const EPKhistoryDevelopmentGraph = ({appTexts, historyGraph, portfolioValue=100000}: {appTexts: any; historyGraph: any, portfolioValue: number} ): JSX.Element => {
    const chart = getHistoryGraphChartConfig( historyGraph, portfolioValue );
    return <section aria-labelledby="epk-history-development-graph-title" className="epk-history-development-graph-wrapper paragraph-large">
        <h1 id="epk-history-development-graph-title" className="paragraph">{appTexts.get('epkHistoryDevelopmentGraph')}</h1>
        <p className="paragraph">
            {appTexts.get('epkHistoryDevelopmentGraphDescription', {portfolioValue: formatAmount(portfolioValue)})}
        </p>

        <div className="chart-wrapper paragraph-large -only-top">
            <Chart {...chart} />
        </div>

        { (chart.description || []).length && <ul className="chart-description-wrapper flat">
            {chart.description.map( (d: any, i: number) => {
                return <li key={`chart-description-${i}`} className="description-item">
                    <span style={{backgroundColor: d.color}}  className="dot"/>
                    {['name', 'note', 'value'].map( (key: string) => {
                        return d[key] ? <div className={key}>{d[key]}</div> : null;
                    })}
                </li>
            })}
        </ul>}
    </section>
}

/** ************************************************************************
 ************************************************************************* */
interface EPKmoveConfirmationProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    setEmail: (e: any) => void;
    blurEmail: () => void;
    orderStorage: any;
    appTexts: any;
    error?: any;
};

export const EPKmoveConfirmation = ({appTexts, click, orderStorage, error, setEmail, blurEmail}: EPKmoveConfirmationProps ): JSX.Element => {
    const id = `epk-move-confirmation`;
    const info = EPKriskProfileList.find( (d: any) => d.riskProfileId === orderStorage.riskProfileId ) || { title: undefined };
    const note: any = { moveList: [], reserved: [] };
    (orderStorage.epkData || []).forEach( (d: any) => {
        if ( d.reserved ) {
            note.reserved.push( d.name );
        } else {
            note.moveList = note.moveList.concat([
                [{text: d.name, type: 'font-semibold'}, {text: formatAmount(d.sum, true), type: 'font-semibold'}],
                [{text: d.type}],
            ]);
        }
    });

    const moveFrom: any = {data: {text: [
        [{text: appTexts.get('moveFrom')}],
    ].concat(note.moveList)}};

    const moveTo: any = {data: {text: [
        [{text: appTexts.get('moveTo')}],
        [{text: 'Eika Egen pensjonskonto', type: 'font-semibold'}],
        [{text: appTexts.get('risikoProfil')}, {text: info.title, type: 'font-semibold'}],
    ]}};

    return <div className={classNames('epk-move-confirmation-wrapper')}>
        <Grid namespace="sparing-header" list={[{
            layout: 'twelve',
            element: <h1>{appTexts.get('epkConfirm')}</h1>
        }]}/>

        <Grid namespace="sparing-content" list={[{
            layout: 'six',
            element: <>
                <div className="epk-move-confirmation-header">
                    <div className="risk-profile-label">{appTexts.get('epkConfirmNote')}</div>
                    <div className="risk-profile-title">{info.title}</div>
                </div>

                <div className="epk-move-confirmation-body">
                    <Card {...moveFrom} type="list-item epk-move-confirmation-info -from">
                        { note.reserved.length > 0 &&
                             <Message role="Application" type="InfoMessage" icon={IconTypes.INFO_24} text={appTexts.get('reservedEPKmoving', {source: note.reserved.join(', ')})}/>
                        }
                    </Card>
                    <Card {...moveTo} type="list-item epk-move-confirmation-info -to"/>

                    <Card type="list-item" data={{textList: [[{text: 'Bank', type: 'font-normal', id: `${id}-bank`}, {element: <span aria-labelledby={`${id}-bank`}>
                        { (orderStorage.bankList || []).length > 1 ?
                            <LinkButton icon={IconTypes.PENN_24} className="icon-right" onClick={(e) => { click(e, 'select-bank')}}>{orderStorage.banknavn}</LinkButton>
                            :
                            <span>{orderStorage.banknavn}</span>
                        }
                    </span>}]] }}/>

                    { orderStorage.initedEmail ?
                        <Card type="list-item" data={{textList: [[{text: 'Epost', type: 'font-normal', id: `${id}-email`}, {element: <span aria-labelledby={`${id}-email`}>
                            <LinkButton icon={IconTypes.PENN_24} className="icon-right" onClick={(e) => { click(e, 'select-email')}}>{orderStorage.email}</LinkButton>
                        </span>}]] }}/>
                        :
                        <Card type="list-item">
                            <Input type="email" label="Epost" value={orderStorage.email} required error={error?.email}
                                onChange={(e)=>{ setEmail(e); }} onBlur={()=>{ blurEmail(); }}
                            />
                        </Card>
                    }
                </div>

                <FooterButton
                    click={(e: any, k='') =>{ click( e, k ); }}
                    action={Constant.actionConfirmMoveEPK}
                    next={appTexts.get('confirmMoving')}
                    cancel="Avbryt"
                />
            </>
        }]}/>
    </div>
};

/** ************************************************************************
 ************************************************************************* */
interface EPKmoveOnSigningMessageProps {
    click: (e: any, k: any, d?: any, p?: any) => void;
    appTexts: any;
    nextText?: string;
    btnAction?: string;
};

interface EPKmoveOnSigningFinishProps extends EPKmoveOnSigningMessageProps {
    orderStorage: any;
};

interface EPKmoveOnSigningProps extends EPKmoveOnSigningMessageProps {
    orderStorage: any;
    signingMode: string;
};

export const EPKmoveOnSigningFinish = ({appTexts, orderStorage, click, nextText, btnAction='finish'}: EPKmoveOnSigningFinishProps ): JSX.Element => {
    return <div className="epk-move-on-signing-finish">
        <div className="h1">{appTexts.get('epkMovefinished')}</div>
        <p className="paragraph-large">{appTexts.get('epkMovefinishedMessage')}</p>
        <div className="paragraph-large">
            <div className="epk-move-receipt-message">{appTexts.get('kvitteringPaaEpost', orderStorage)}</div>
        </div>

        <FooterButton click={click} next={nextText || appTexts.get('tilbakeTilOversikt')} action={btnAction} nextNegative/>
    </div>
}

export const EPKmoveOnSigningFailed = ({appTexts, click, nextText, btnAction='submit'}: EPKmoveOnSigningMessageProps ): JSX.Element => {
    return <div className="epk-move-on-signing-failed">
        <Message type="ErrorMessage" text={appTexts.get('ops')} />
        <FooterButton click={click} next={nextText ||  appTexts.get('provIgjen')} action={btnAction} cancel="Avbryt" />
    </div>
}

export const EPKmoveOnSigningRejected = ({appTexts, click, nextText, btnAction='cancel'}: EPKmoveOnSigningMessageProps ): JSX.Element => {
    return <div className="epk-move-on-signing-rejected">
        <Message type="WarningMessage" text={appTexts.get('signingRejected')} />
        <FooterButton click={click} next={nextText ||  'Tilbake til starten'} action={btnAction} />
    </div>
}

export const EPKmoveOnSigning = ( props: EPKmoveOnSigningProps ): JSX.Element => {
    const { appTexts, signingMode, orderStorage, click } = props;
    const countDownTextList = [{
        text: appTexts.get('pendingSigningRoom'), type: 'pending-signing-room',
    }, {
        text: appTexts.get('pendingSigningRoomMessage'), type: 'pending-signing-room-message',
    }];

    return <div className="epk-move-on-signing-wrapper">
        <Grid namespace="sparing-header" list={[{
            layout: 'twelve',
            element: <h1 style={{display: 'none'}}>Signering</h1>
        }]}/>
        <Grid namespace="sparing-content" list={[{
            layout: 'six',
            element: <>
                { signingMode === 'preparing' && <div className="epk-move-on-signing-preparing">
                    <Spinner />
                </div>}

                { signingMode === 'pending' && <div className="epk-move-on-signing-pending">
                    <Countdown seconds={90} textList={countDownTextList} />
                    <FooterButton click={click} next={appTexts.get('signLater2')} action={Constant.actionSignLater} nextNegative/>
                </div>}

                { signingMode === 'ready' && <div className="epk-move-on-signing-ready">
                    <div className="pending-signing-room">{appTexts.get('signingIsReady')}</div>
                    <FooterButton
                        click={click}
                        next={appTexts.get('sign')}
                        action={Constant.actionSignNow}
                        cancel={appTexts.get('cancelSigning')}
                        cancelAction={Constant.actionCancelSigning}
                    />
                </div>}

                { (signingMode === Constant.signingSuccess || orderStorage.receipt) &&
                    <EPKmoveOnSigningFinish {...props}  /> }
                { signingMode === Constant.signingError && <EPKmoveOnSigningFailed {...props} /> }
                { signingMode === Constant.signingCancel && <EPKmoveOnSigningRejected {...props} />}
            </>
        }]}/>
    </div>
};

/** ************************************************************************
 ************************************************************************* */
export const EPKmoveInProcess = ({click}: {click: (e: any, k: any) => void} ): JSX.Element => {
    return <div className="epk-move-in-process-wrapper">
        <Grid namespace="sparing-header" list={[{
            layout: 'twelve',
            element: <h1 style={{display: 'none'}}>Fullført</h1>
        }]}/>
        <Grid namespace="sparing-content" list={[{
            layout: 'six',
            element: <div className="fond-handel-ordre-done-wrapper">
                <h1>Hei, vennligst fullfør</h1>
                <p className="paragraph-large">Du kan desverre ikke gå tilbake på dette steget. For å fullføre, må du gå igjennom de siste stegene.</p>
                <FooterButton click={click}  next="Fortsett" action="to-last-step"/>
            </div>
        }]}/>
    </div>
};


/** ************************************************************************
 ************************************************************************* */
export const WhatIsEPK = ({appTexts}: {appTexts: any;}): JSX.Element => {
    const list = [{
        id: 'point-1',
        type: 'checked-panel -white',
        data: {text: appTexts.get('whatIsEPKpoint1'), type: 'font-normal'},
    }, {
        id: 'point-2',
        type: 'checked-panel -white',
        data: {text: appTexts.get('whatIsEPKpoint2'), type: 'font-normal'},
    }, {
        id: 'point-3',
        type: 'checked-panel -white',
        data: {text: appTexts.get('whatIsEPKpoint3'), type: 'font-normal'},
    }];

    return <div className="what-is-epk-content">
        <h2>{appTexts.get('whatIsEPK')}</h2>
        <div className="tag-holder paragraph-small">
            <Label text={appTexts.get('whatIsEPKtag')} className="pension"/>
        </div>
        <p>{appTexts.get('whatIsEPKdescription')}</p>

        <div className="what-is-epk-point-holder">
            { list.map( (d: any) => ( <Card key={d.id} {...d}/> ))}
        </div>


        <h3 className="paragraph-large -only-top">{appTexts.get('whatIsEPKtextHeader')}</h3>
        { Array.from({length: 4}).map( (x: any, i: number) => {
            return <p key={`text-${i}`}>{appTexts.get(`whatIsEPKtext${(i+1)}`)}</p>
        })}
    </div>
};

