/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NedTrekksMenyFond from '../ned-trekks-meny-fond';
import HelperFunctions from '../helper-functions';
import Checkbox from '../checkbox';
import ExpandableCard from '../expandable-card';
import Epost from '../common/epost';
import Format from '../format-functions';
import NumberInput from '../common/number-input';
import ValidationMessages from '../common/validation-messages';
import * as kundevurderingConst from '../../domain/kundevurdering';
import KundevurderingStopp from '../KundevurderingStopp';
import FeeMessage from '../FeeMessage';

class ByttFond extends Component {
    static propTypes = {
        portefoljeId: PropTypes.string.isRequired,
        texts: PropTypes.object.isRequired,
        morningstar: PropTypes.array.isRequired,
        fond: PropTypes.object.isRequired,
        expandItem: PropTypes.object.isRequired,
        action: PropTypes.func.isRequired,
        settKvitteringsInnhold: PropTypes.func.isRequired,
        portefoljeType: PropTypes.string.isRequired,
        epost: PropTypes.string,
        settEpost: PropTypes.func.isRequired,
        bankregnr: PropTypes.string.isRequired,
        kundevurdering: PropTypes.string,
        sperre: PropTypes.string,
    };

    constructor(props) {
        super(props);

        const standardValues = {
            nyttFondIsin: undefined,
            engangsBelop: 0,
            byttHeleBeholdning: false,
            flyttSpareavtale: false,
            minimumsbelop: 0,
            visHjelpetekst: false
        };

        this.state = {
            kompatibleFond: this.filtrerFondBasertPaaPortefoljeType(this.props.morningstar, this.props.portefoljeType),
            standardValues,
            ...standardValues
        };
    }

    filtrerFondBasertPaaPortefoljeType = (morningstar, portefoljeType) => {
        if (portefoljeType === 'ASK') {
            return morningstar.filter(
                fond =>
                    fond.aksjesparekontoKompatibel &&
                    this.props.fond.totalVerdi >= fond.minimumsBelopEngangsKjop &&
                    fond.navn.startsWith('Eika')
            );
        }

        return morningstar.filter(
            fond => this.props.fond.totalVerdi >= fond.minimumsBelopEngangsKjop && fond.navn.startsWith('Eika')
        );
    };

    submitForm = () => {
        if (this.validateFormInput()) {
            if (this.state.epost) {
                this.props.settEpost(this.state.epost);
            }

            this.createReceiptContent();

            const data = this.createFundsExchangeDTO();
            this.resetForm();
            this.props.action(data);
        } else {
            this.setState({
                visHjelpetekst: true
            });
        }
    };

    createFundsExchangeDTO = () => ({
        portefoljeId: this.props.portefoljeId,
        isin: this.props.fond.isin,
        nyIsin: this.state.nyttFondIsin,
        engangsBelop: this.state.engangsBelop,
        byttHeleBeholdning: !!this.state.byttHeleBeholdning,
        flyttSpareavtale: !!this.state.flyttSpareavtale,
        epost: this.state.epost,
        bankregnr: { value: this.props.bankregnr }
    });

    createReceiptContent = () => {
        const kvittering = {
            type: 'byttFond',
            lasteTekst: 'Bytter fond',
            byttetFra: this.props.fond.navn,
            byttetTil: this.state.nyttFondNavn,
            engangsBelop: this.state.byttHeleBeholdning ? this.props.fond.totalVerdi : this.state.engangsBelop,
            byttHeleBeholdning: !!this.state.byttHeleBeholdning,
            flyttSpareavtale: !!this.state.flyttSpareavtale
        };

        this.props.settKvitteringsInnhold(kvittering);
    };

    velgNyttFond = (nyttFondIsin, nyttFondNavn) =>
        this.setState({
            nyttFondIsin,
            nyttFondNavn,
            minimumsbelop: HelperFunctions.hentFondBasertPaaIsin(nyttFondIsin, this.props.morningstar)
                .minimumsBelopEngangsKjop
        });

    endreBelop = event =>
        this.setState({ engangsBelop: HelperFunctions.clamp(event.target.value, 0, this.props.fond.totalVerdi) });

    resetForm = () => {
        const { standardValues } = this.state;
        this.setState({ ...standardValues });
        this.props.expandItem.onExpand(this.props.expandItem.id);
    };

    validateFormInput = () =>
        this.state.nyttFondIsin && this.sjekkBelop(this.state.engangsBelop) && (this.state.epost || this.props.epost);

    sjekkBelop = engangsBelop => {
        const belopOverMin = engangsBelop >= this.state.minimumsbelop;
        const belopUnder85prosent = engangsBelop <= this.props.fond.totalVerdi * 0.85;

        return (belopOverMin && belopUnder85prosent) || this.state.byttHeleBeholdning;
    };

    endreCheckbox = (checked, id) =>
        this.setState({
            [id]: checked
        });

    checkBoxHtml = (tekst, id, func) => (
        <Checkbox id={id} name={id} onChange={func}>
            <div>{tekst}</div>
        </Checkbox>
    );

    visEndreBelop = () => {
        if (!this.state.byttHeleBeholdning) {
            return (
                <div className="fond-skjema-seksjon">
                    <NumberInput
                        id="endreBelop"
                        label={this.props.texts.get('byttForKr')}
                        value={this.state.engangsBelop}
                        onChange={this.endreBelop}
                    />

                    <ValidationMessages
                        showValidationMessages={this.state.visHjelpetekst}
                        validationMessages={[
                            {
                                condition: !(
                                    this.state.engangsBelop >= this.state.minimumsbelop || this.state.byttHeleBeholdning
                                ),
                                message: this.props.texts.get('skrivInnEnGyldigSumOverMinimumsbelop')
                            },
                            {
                                condition: !(
                                    this.state.engangsBelop <= this.props.fond.totalVerdi * 0.85 ||
                                    this.state.byttHeleBeholdning
                                ),
                                message: this.props.texts.get('skrivInnMindreEnnEllerVelgByttHeleBeholdningen')
                            }
                        ]}
                    />
                </div>
            );
        }

        return null;
    };

    visEpost = () => (
        <Epost
            label={this.props.texts.get('epost')}
            show={!this.props.epost}
            onChange={epost => this.setState(epost)}
            showValidationMessage={this.state.visHjelpetekst}
            validationMessage={this.props.texts.get('skrivEnGyldigEpostAdresse')}
        />
    );

    render() {
        const byttHeleBeholdning = HelperFunctions.VariableInjection(this.props.texts.get('byttHeleBeholdning'), {
            beholdning: Format.number(this.props.fond.totalVerdi)
        });

        return (
            <ExpandableCard header="Bytt fond" expandItem={this.props.expandItem} ikon="ikon-overfoere-36">
                { this.props.kundevurdering === kundevurderingConst.MA_BEHANDLES_MANUELT || this.props.sperre ?
                    <KundevurderingStopp
                        {...this.props} 
                        title={this.props.sperre ? this.props.texts.get(`${this.props.sperre.toLowerCase()}SperretKontoTitle`) : ''}
                        text={this.props.sperre ? this.props.texts.get(`${this.props.sperre.toLowerCase()}SperretKontoText`) : ''}
                    />
                    :
                    <div>
                        <div className="fond-skjema-seksjon">
                            <label className="full-width label-left">
                                <div className="label-text">{this.props.texts.get('velgFondDuVilBytteTil')}</div>
                                <NedTrekksMenyFond
                                    texts={this.props.texts}
                                    fond={this.state.kompatibleFond}
                                    onChange={this.velgNyttFond}
                                />
                            </label>
                            <ValidationMessages
                                showValidationMessages={this.state.visHjelpetekst}
                                validationMessages={[
                                    {
                                        condition: !this.state.nyttFondIsin,
                                        message: this.props.texts.get('duMaaVelgePortefolje')
                                    }
                                ]}
                            />
                        </div>

                        {this.checkBoxHtml(byttHeleBeholdning, 'byttHeleBeholdning', this.endreCheckbox)}
                        {this.visEndreBelop()}
                        {this.visEpost()}

                        { !!this.state.nyttFondIsin && <FeeMessage fond={(this.props.morningstar || []).find((d) => d.isin === this.state.nyttFondIsin)} /> }

                        <div className="expandable-card-footer">
                            <button type="button" className="btn btn-flat" onClick={this.resetForm}>
                                {this.props.texts.get('avbryt')}
                            </button>
                            <button type="button" className="btn btn-primary" onClick={this.submitForm}>
                                {this.props.texts.get('bekreftOverforing')}
                            </button>
                        </div>
                    </div>
                }
            </ExpandableCard>
        );
    }
}

export default ByttFond;
