import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import './ProductInfoCard.scss';

export type ProductInfoCardProps = {
    description?: string;
    label: React.ReactNode | string;
    highlightedNote?: string;
    className?: string;
};

const ProductInfoCard: FC<PropsWithChildren<ProductInfoCardProps>> = ({
    label,
    description,
    highlightedNote,
    children,
    className,
}) => {
    return (
        <>
            {highlightedNote && (
                <div className="sgw-product-info-card__highlighted-note">
                    <p>{highlightedNote}</p>
                </div>
            )}
            <div className={classnames('sgw-product-info-card', className)}>
                {typeof label === 'string' ? (
                    <div className={classnames('sgw-product-info-card__label')}>{label}</div>
                ) : (
                    label
                )}
                <div className="sgw-product-info-card__wrapper">
                    {description && <span className="sgw-product-info-card__description">{description}</span>}
                    {children}
                </div>
            </div>
        </>
    );
};

export default ProductInfoCard;
