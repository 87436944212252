import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Select } from '@eika/select';
import { Modal } from '@eika/modal';
import { LinkButton } from '@eika/button';
import { IconTypes } from '@eika/icon';
import Message from '../Message/Message';
import { roundUpValue, formatAmount } from '../util/Functions';
import { usePrevious } from '../util/Hook';

import './FundCalculator.scss';

interface PointOption {
    value: string;
    text: string;
};

interface Props {
    appTexts: any;
    // eslint-disable-next-line
    action: any;
    onetime?: number;
    monthly?: number;
    point?: number;
    // eslint-disable-next-line
    isin?: string;
    aisFrequency?: string;
    aisValue?: string;
    message?: {type: string, text: string, icon?: boolean};
    drawDay?: string | number;
    increaseImmediately?: boolean;
};

interface State {
    value: {
        onetime: number;
        monthly: number;
        point: number;
    };
    pointList: PointOption[];
    result?: any;
    detail: null | string[];
    message?: string;
    id: React.RefObject<string>;
    timer: any;
};

const displayCalculation = (props: Props, state: State, setState: (s: State) => void, response: any ): void => {
    const resultList = response?.withoutInflationWithoutYearlyCost;
    if ( !resultList ) {
        return setState({...state, result: {
            error: 'Forventet verdi kan ikke beregnes for dette fondet.',
            text: 'Valgt sparebeløp kan bli'
        }});
    }

    const last = resultList.length - 1;
    const aisPlanData = (response.aisPlan || [])[(last * 12)];

    const config = {
        value: formatAmount(roundUpValue(resultList[last].verdi, 100)),
        low: formatAmount(roundUpValue(resultList[last].nedreGrense, 100)),
        high: formatAmount(roundUpValue(resultList[last].ovreGrense, 100)),
        return: `${response.aarligAvkastningUtenKostnader}`.replace( '.', ','),
        inflation: `${response.aarligInflasjon}`.replace( '.', ','),
    };
    const result: any = {...state.result, aboutCalculation: [
        aisPlanData ? '' : props.appTexts.get('sparekalkulatorOmUtregningAvsnitt1', config),
        props.appTexts.get('sparekalkulatorOmUtregningAvsnitt2', config),
        props.appTexts.get('sparekalkulatorOmUtregningAvsnitt3', config),
    ].filter( (text: string) => !!text )};
    result.response = response;
    result.value = aisPlanData ? `${formatAmount(roundUpValue(aisPlanData.accumulatedSum, 100))} kr` : `${config.value} kr`; 
    result.text = 'Valgt sparebeløp kan bli'; 

    setState({...state, result});
};

const getCalculation = (props: Props, state: State, setState: (s: State) => void ): void => {
    const keys = ['isin', 'onetime', 'monthly', 'point', 'aisValue', 'aisFrequency', 'drawDay', 'increaseImmediately'];
    const idNote: string[] = [];
    const param: any = keys.reduce( (p: {[k:string]: string}, k: string) => {
        // @ts-ignore
        p[k] = `${props[k] || state.value[k] || ''}`.replace( /\s+/g, '' );
        idNote.push( p[k] );
        return p;
    }, {});

    const id = idNote.join('-');
    if ( id === state.id.current ) { return; }

    // @ts-ignore
    state.id.current = id;

    const update = {...state, message: ''};
    if ( !param.isin || (!param.monthly && !param.onetime) ) {
        const missing = !param.monthly && !param.onetime ? 'beløp' : 'data';
        return setState({...update, message: `Det mangler ${missing} til å kalkulere forventet verdi`});
    }

    setState( update );

    param.point = parseInt( `${param.point || 1}`, 10) + 1;
    props.action.getFondKalkulator( param );
};

const click = (props: Props, state: State, setState: (s: State) => void, e: any, key='', data?: any): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if ( key === 'close-modal' ) {
        setState({...state, detail: null});
    } else if ( key === 'open-detail' && data ) {
        setState({...state, detail: data});
    }
};

const changeYear = ( props: Props, state: State, setState: (s: State) => void, e: any ): void => {
    const value = e.target.value || 1;
    setState({...state, value: {...state.value, point: parseInt(`${value}`, 10) }});
};

export default (props: Props): JSX.Element => {
    const kalkulator = useSelector((state: any) => state.Kalkulator );
    const [state, setState] = useState<State>({
        value: {
            onetime: props.onetime ?? 0,
            monthly: props.monthly ?? 0,
            point: props.point ?? 10,
        },
        pointList: Array.from({length: 30}).map( (x,i) => {
            const year = i+1;
            return {value: `${year}`, text: `Over ${year} år`}
        }),
        detail: null,
        timer: useRef<NodeJS.Timeout | null>(null),
        id: useRef<string>(''),
    }); 

    const fondKalkulator = kalkulator?.fondKalkulator;
    const previousFondKalkulator = usePrevious( fondKalkulator );

    useEffect(() => {
        getCalculation( props, state, setState );
    }, [props, state, setState]);

    useEffect(() => {
        if ( JSON.stringify((fondKalkulator || {})) === JSON.stringify((previousFondKalkulator || {})) ) { return; }
        displayCalculation( props, state, setState, fondKalkulator );
    }, [props, state, setState, fondKalkulator, previousFondKalkulator]);

    return (
        <section role="application" aria-labelledby="fund-calculator-title" className="fund-calculator-wrapper">
            <h1 aria-describedby="fund-calculator-description" id="fund-calculator-title">{props.appTexts.get('sparekalkulator')}</h1>

            <div className="fund-calculator-result">
                {!!state.result?.text && <div id="fund-calculator-result-text" className="fund-calculator-result-text">{state.result.text}</div> }
                { state.result?.value !== undefined && <div aria-labelledby="fund-calculator-result-text" className="fund-calculator-result-value">{state.result.value}</div> }
                { !!state.message && <div aria-labelledby="fund-calculator-message" className="fund-calculator-message">{state.message}</div> }
                { !!state.result?.error && <Message type="ErrorMessage" text={state.result?.error} icon/> }
            </div>

            { !state.result?.error && <>
                <div className="fund-calculator-tool">            
                    <Select label="Velg antall år" defaultValue={`${state.value.point}`} onChange={(e) => {changeYear(props, state, setState, e)}}>
                        {state.pointList.map((opt: PointOption, i: number) => (
                            <option value={opt.value} key={`point-option-${i}`}>{opt.text}</option>
                        ))}
                    </Select>
                </div> 
                <div className="info-wrapper">
                    { !!state.result?.aboutCalculation && <LinkButton
                            icon={IconTypes.INFO_24} 
                            onClick={(e)=>{ click(props, state, setState, e, 'open-detail', state.result?.aboutCalculation)}}
                        >{props.appTexts.get('sparekalkulatorOmUtregningOverskrift')}</LinkButton>
                    }
                </div>
            </>}

            { !!props.message && <Message {...props.message} />}

            <Modal id="fund-calculator" appNamespace="sparing-react-app" onClose={() => { click(props, state, setState, null, 'close-modal'); }} show={!!state.detail}>
                <div className="detail-infomation">
                    <h2>{props.appTexts.get('sparekalkulatorOmUtregningOverskrift')}</h2>
                    {(state.detail ?? []).map( (text: string, i: number) => {
                        return <p key={`calcustion-detail-${i}`}>{text}</p>
                    })}

                </div>
            </Modal>
        </section>
    );
};