import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

require('../styles/headerNavigation.scss');

class HeaderContainer extends Component {
    static propTypes = {
        sideTittel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        underTittel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    goBack = () => {
        window.history.back();
    };

    render() {
        return (
            <div id="sparing-react-app-header">
                <div className="header">
                    <div className="header-inner">
                        <button type="button" className="ikon-pil-venstre btn" onClick={this.goBack}>
                            <span className="screen-reader">Gå tilbake</span>
                        </button>

                        <a href="#/">
                            <div className="ikon-sparegris-24 btn" />
                            <span className="screen-reader">Gå til fremside</span>
                        </a>
                    </div>
                    <div className="header-inner-flex">
                        <h1 className="header-title">
                            {this.props.sideTittel}
                            <br />
                            {this.props.underTittel}
                        </h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => {
    return {
        ...state.Header,
    };
})(HeaderContainer);
