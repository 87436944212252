import { Component } from 'react';

class KjopFondVeiviserSkjema extends Component {
    render = () => {
        return null;
    }   
}

/*
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ActionButton from '../../action-button';
import RadioButtons from '../../radio-buttons';
import ValgKnapper from '../../valg-knapper';
import ValidationMessages from '../../common/validation-messages';
import { getKjopFondNextPage } from '../../common/Functions';

class KjopFondVeiviserSkjema extends Component {
    static propTypes = {
        texts: PropTypes.object,
        onSubmit: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            visHjelpeTekst: false,
            tidshorisontValg: [
                { tekst: props.texts.get('om0-2aar'), id: 2 },
                { tekst: props.texts.get('om2-5aar'), id: 5 },
                { tekst: props.texts.get('omMer5aar'), id: 10 }
            ],
            risikoprofilValg: [
                {
                    navn: this.props.texts.get('mestMuligVerdiOkning'),
                    id: '2',
                    tekst: this.props.texts.get('mestMuligVerdiOkningBeskrivelse')
                },
                {
                    navn: this.props.texts.get('enKombinasjon'),
                    id: '1',
                    tekst: this.props.texts.get('enKombinasjonBeskrivelse')
                },
                {
                    navn: this.props.texts.get('stabilitet'),
                    id: '0',
                    tekst: this.props.texts.get('stabilitetBeskrivelse')
                }
            ]
        };
    }

    onSubmit = () => {
        if (this.state.tidshorisont && this.state.risikoprofil) {
            this.props.onSubmit({
                risikoprofil: this.state.risikoprofil,
                tidshorisont: this.state.tidshorisont
            });
        }

        this.setState({
            visHjelpeTekst: true
        });
    };

    settTidshorisont = tidshorisont => {
        this.setState({
            tidshorisont
        });
    };

    settRisikoProfil = risikoprofil => {
        this.setState({
            risikoprofil: risikoprofil.id
        });
    };

    render = () => {
        return null;
    }
    
    render = () => {
        const { portefoljeId } = this.props;

        return <div className="kjop-fond-veiviser-skjema-wrapper">
            <h1 className="h2">{this.props.texts.get('kjopFondVeiviserSkjemaTittel')}</h1>

            <div className="ingress">{this.props.texts.get('faaAnbefaling')}</div>

            <div className="seksjon">
                <ValgKnapper
                    tittel={this.props.texts.get('naarTrengerDuSparepengene')}
                    valgMuligheter={this.state.tidshorisontValg}
                    endreValg={this.settTidshorisont}
                />
                <ValidationMessages
                    showValidationMessages={this.state.visHjelpeTekst}
                    validationMessages={[
                        {
                            condition: !this.state.tidshorisont,
                            message: this.props.texts.get('DuMaaVelgeEnTidshorisont')
                        }
                    ]}
                />
            </div>
            <div className="seksjon">
                <RadioButtons
                    legend={this.props.texts.get('hvaErMestViktigForDeg')}
                    items={this.state.risikoprofilValg}
                    onChange={this.settRisikoProfil}
                />
                <ValidationMessages
                    showValidationMessages={this.state.visHjelpeTekst}
                    validationMessages={[
                        {
                            condition: !this.state.risikoprofil,
                            message: this.props.texts.get('velgEnRisikoProfil')
                        }
                    ]}
                />
            </div>

            <div className="seksjon">
                <ActionButton text={this.props.texts.get('seAnbefalteFond')} action={this.onSubmit} />
            </div>

            <div className="se-alle-fond paragraph -large">
                <Link className="link font-size-regular" to={getKjopFondNextPage(`kjopFond/${portefoljeId ? `portefolje/${portefoljeId}` : 'fond/alle'}`)}>
                    {this.props.texts.get('seAlleFond')}
                </Link>
            </div>
        </div>
    }
}
*/

export default KjopFondVeiviserSkjema;
