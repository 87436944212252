/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';

class IndividuellPensjonsparingsVilkaar extends Component {
    render() {
        return (
            <div className="vilkaar-tekst">
                <h2 className="h3 centered">Avtale om individuell sparing til pensjon</h2>
                <p>
                    Mellom Kunde (heretter «Kunden») og Eika Kapitalforvaltning AS, org. nr. 979 561 261, (heretter
                    «Eika»), hvor Kunde og Eika i fellesskap er benevnt «Partene», er det inngått følgende avtale
                    (heretter «Avtalen») om individuell sparing til pensjon (heretter «IPS»), jf. skatteloven § 6-47 (1)
                    d) med tilhørende forskrift («IPS-forskriften»):
                </p>
                <h3 className="h4">1. Om Partene og Avtalen</h3>
                <p>
                    Eika er et forvaltningsselskap for verdipapirfond med registrert adresse i Parkveien 61, 0254 Oslo,
                    som er underlagt tilsyn av Finanstilsynet. Eika er et datterselskap av Eika Gruppen AS, som igjen er
                    eid av bankene i Eika-alliansen.
                </p>
                <p>Kunden er over 18 år, og har inngått Avtalen som privatperson.</p>
                <p>
                    Denne Avtale skal gi Kunden rett til alderspensjon i samsvar med bestemmelsene i IPS-forskriften §§
                    6-47-1 til 6-47-17.
                </p>
                <h3 className="h4">2. Informasjon før inngåelse av Avtalen</h3>
                <p>
                    Reglene om IPS krever at tilbyder, før avtale inngås, må gi kunden skriftlige opplysninger om
                    binding av pensjonskapitalen i avtaleperioden, om at avtalen gir rett til alderspensjon, om
                    eventuell forsikring er knyttet til avtalen, samtlige kostnader knyttet til avtalen, reglene for
                    utbetaling av ytelser, samt relevante skatteregler.
                </p>
                <p>
                    Kunden bekrefter ved sin aksept av Avtalen å blant annet ha blitt gjort kjent med følgende før
                    Avtalen ble inngått:
                </p>
                <ul>
                    <li>
                        Avtalen gir Kunden rett til alderspensjon i henhold til reglene i skatteloven § 6-47 (1) d) med
                        tilhørende forskrift.
                    </li>
                    <li>
                        Kunden kan spare opptil kr. 15 000 per år. Det innbetalte beløp kan kreves fradratt i Kundens
                        alminnelige inntekt.
                    </li>
                    <li>Midlene er bundet frem til uttak, jf. nedenfor.</li>
                    <li>
                        Avtalen kan inngås av personer som er fylt 18 år, og det kan spares i ordningen inntil fylte 75
                        år. Kunden kan tidligst ta ut alderspensjon fra fylte 62 år. Alderspensjon skal utbetales i et
                        fastsatt antall år fra uttak av pensjonen og minst til fylte 80 år, men ikke mindre enn 10 år.
                    </li>
                    <li>Uttak skal skattlegges som alminnelig inntekt.</li>
                    <li>Verdien på kontoen er unntatt formuesskatt.</li>
                    <li>Det er ikke knyttet forsikringer til Avtalen</li>
                    <li>
                        Ordinære kostnader knyttet til kjøp og salg av finansielle instrumenter (typisk forvaltnings-,
                        tegnings- og innløsningshonorar for fond) vil påløpe.
                    </li>
                </ul>

                <h3 className="h4">3. Avtaleinngåelsen</h3>
                <p>
                    Avtalen kan inngås ved fysisk signering på «blankett for avtale om individuell sparing til pensjon»
                    (hvor denne Avtale inngår som vedlegg), eller ved digital inngåelse. I sistnevnte tilfelle vil
                    Kunden, som da vil være innlogget, akseptere Avtalen ved avkrysning av at vedkommende har lest,
                    akseptert og godtatt Avtalen. Avtalen vil sendes på e-post til Kunden samt lagres i nettposten.
                </p>
                <h3 className="h4">4. Kartlegging av Kundens behov og ønske om risiko – rådgivning </h3>
                <p>
                    I forbindelse med Avtalen vil Eika gi Kunden et råd om sammensetningen av porteføljen basert på en
                    kartlegging av Kundens behov, ønske om risiko og tiden frem til uttak av pensjonen. Innenfor det
                    produktspekter Eika tilbyr velger Kunden selv hvilke aktiva som skal inngå i porteføljen, og dermed
                    også om rådet skal følges eller ikke.
                </p>
                <p>
                    Når Kunden har nådd en alder med få år igjen til uttak av pensjonen vil Eika – om nødvendig – gi
                    Kunden et råd om risikoreduserende tiltak.
                </p>
                <h3 className="h4">5. Årlige innbetalinger under ordningen </h3>
                <p>
                    Samlet årlig innbetaling av innskudd, vederlag for administrasjon av avtalen og vederlag for
                    forvaltning av årets innskudd kan ikke overstige kr. 15 000. Innbetalinger kan ikke trekkes av
                    pensjonskapitalen.
                </p>
                <p>Kunden kan selv velge størrelse på- og tidspunkt for innbetaling.</p>
                <p>Kunden kan fortsette innbetaling inntil fylte 75 år.</p>
                <h3 className="h4">6. Vilkårene for forvaltning</h3>
                <p>
                    Kunden velger selv hvilke aktiva som skal inngå i portefølje innenfor det produktspekter som Eika
                    tilbyr, og har til enhver tid rett til å endre sammensetningen av porteføljen. Kostnadene ved
                    forvaltning-, tegning- og innløsning fremgår nedenfor under punkt 8.
                </p>
                <p>
                    Kunden vil kunne foreta handel gjennom Eikas ulike handelsplattformer, eller manuelt ved bruk av
                    blanketter og ved hjelp av Kundens lokale sparebank.
                </p>
                <h3 className="h4">7. Vilkår for utbetaling</h3>
                <p>
                    Kunden kan tidligst ta ut alderspensjon ved fylte 62 år. Dersom Kunden har rett til uføreytelser fra
                    folketrygden kan Kunden likevel kreve at Eika straks begynner utbetaling. Den nærmere fastsettelse
                    av slike ytelser skal skje i henhold til reglene i IPS-forskriften.
                </p>
                <p>
                    Kunden skal gi Eika melding om når alderspensjonen skal utbetales. Med mindre Kunden gir melding om
                    annet kommer pensjonen uansett til utbetaling ved fylte 75 år. Uttak av pensjon er ikke til hinder
                    for at Kunden fortsetter innbetaling av innskudd i henhold til Avtalen.
                </p>
                <p>
                    Alderspensjonen skal utbetales i et fastsatt antall år fra uttak av pensjonen og minst til Kunden
                    har fylt 80 år, men likevel aldri i mindre enn 10 år. Kunden kan dog bestemme at pensjonen skal
                    utbetales i en lengre periode. Både Kunden og Eika kan likevel kreve at utbetalingstiden settes ned
                    til det antall hele år som er nødvendig for at samlet årlig alderspensjon utgjør om lag 20 prosent
                    av folketrygdens grunnbeløp.
                </p>
                <p>
                    Utbetalingsperiodens lengde kan endres ved fylte 67 år, og for øvrig etter avtale mellom Kunden og
                    Eika. Kunden kan uansett på ethvert tidspunkt etter uttak endre uttaksgraden til fullt uttak av
                    pensjon.
                </p>
                <p>
                    Alderspensjonen i det enkelte år fastsettes med sikte på at den ikke skal utgjøre en større andel av
                    pensjonskapitalen enn den andelen det aktuelle året utgjør av den gjenværende utbetalingsperioden.
                    Det kan avtales at alderspensjonen etter 10 års utbetaling skal settes ned, men ikke med mer enn 50
                    prosent.
                </p>
                <p>
                    Kunden kan kreve at utbetalingen skal utsettes/ikke utbetales i den utstrekning Kunden mottar lønn.
                </p>
                <h3 className="h4">8. Kostnader ved Avtalen</h3>
                <p>
                    Det påløper ingen kostnader ved opprettelse, drift og administrasjon av Avtalen. Eika står fritt
                    likevel fritt til på ethvert tidspunkt å innføre slike kostnader etter forutgående varsel til
                    Kunden.
                </p>
                <p>
                    Transaksjons- og/eller forvaltningskostnader slik de til enhver tid gjelder for de enkelte
                    finansielle instrumenter som tilbys, vil påløpe. Verdipapirfondene som tilbys innenfor ordningen vil
                    ha et forvaltningshonorar mellom 0,2%-2%, et tegningshonorar mellom 0%-2% og et innløsningshonorar
                    mellom 0%- 0,5%. For en nærmere oversikt over kostnadene knyttet til det enkelte finansielle
                    instrument vises det til www.eika.no. Kunden vil under enhver omstendighet få nærmere informasjon om
                    kostnadene før handler gjennomføres.
                </p>

                <h3 className="h4">9. Flytting, endring og oppsigelse av Avtalen </h3>
                <p>
                    Kunden kan flytte Avtalen med tilhørende midler til en annen institusjon. Flytting skal skje etter
                    forsikringsvirksomhetsloven § 6-13 og 6-14 (6).
                </p>
                <p>
                    Kunden kan når som helst si opp/avbryte Avtalen. Eika skal i så fall utstede pensjonskapitalbevis
                    etter reglene i innskuddspensjonsloven § 6-2. Alderspensjonskapitalen i henhold til slike
                    pensjonskapitalbevis kan overføres til en annen IPS-avtale som Kunden har, og inngå i
                    alderspensjonskapitalen knyttet til denne avtalen.
                </p>

                <h3 className="h4">10. Ved Kundens død</h3>
                <p>
                    Retten til pensjonskapitalen faller ikke bort ved Kundens død. Pensjonskapitalen i henhold til
                    Avtalen skal da benyttes til barnepensjon eller i tilfelle etterlattepensjon til ektefelle,
                    registrert partner eller samboer etter reglene i innskuddspensjonsloven § 7-7. Pensjonskapital som
                    ikke benyttes til slike ytelser utbetales som et engangsbeløp til dødsboet.
                </p>
                <h3 className="h4">11. Overdragelse/pantsettelse mv., samt motregning</h3>
                <p>
                    Pensjonskapitalen og retten til utbetaling av ytelser etter Avtalen kan ikke overdras eller
                    pantsettes eller på annen måte benyttes til dekning av Kundens kreditorer. Kundens rettigheter etter
                    Avtalen kan heller ikke gjenkjøpes av Eika.
                </p>
                <p>
                    Motregning kan ikke skje i midler som inngår i pensjonskapitalen. Ved utbetaling av ytelser kan det
                    likevel foretas motregning med krav som utspringer av Avtalen.
                </p>
                <h3 className="h4">12. Ansvar </h3>
                <p>
                    Kunden erkjenner at informasjonen som gis innenfor Avtalen ikke under noen omstendighet skal
                    oppfattes som et råd eller tilbud om å kjøpe eller selge finansielle instrumenter, med unntak av der
                    hvor det eksplisitt fremgår at et råd faktisk ytes. Ved behov for investeringsrådgivning kan Kundens
                    lokale sparebank eller Eika kontaktes.
                </p>
                <p>
                    Verdipapirfondenes nøkkelinformasjon og prospekter ligger tilgjengelig i Eikas handelsløsninger og
                    nettsider. Kunden må sette seg grundig inn i disse dokumentene før investeringsbeslutning foretas.
                </p>
                <p>
                    Det gjøres oppmerksom på at historisk avkastning ikke er noen garanti for fremtidig avkastning, og
                    at fremtidig avkastning blant annet vil avhenge av markedsutviklingen, forvalters dyktighet, fondets
                    risikoprofil og forvaltningshonorar. Avkastningen kan bli negativ som følge av kurstap.
                </p>
                <h3 className="h4">13. Personopplysninger og forretningsvilkår </h3>
                <p>
                    Eika Kapitalforvaltning foretar sammenstilling, lagring og utlevering av innsamlede og registrerte
                    personopplysninger, og er behandlingsansvarlig for disse. Formålet med behandlingen av
                    personopplysninger er blant annet å kunne oppfylle forpliktelsene i herværende Avtale, herunder å
                    kunne kommunisere med Kunden, samt å oppfylle opplysningsplikten i henhold til gjeldende regler, for
                    eksempel overfor ligningsmyndighetene. Kunden kan be om innsyn i registrerte personopplysninger samt
                    kreve retting av eventuelle feil ved henvendelse til kundens lokalbank eller Eika. Kunden kan også,
                    på nærmere vilkår, kreve opplysningene slettet. Opplysningene vil bli lagret så lenge det er
                    nødvendig for å oppfylle formålet med behandlingen.
                </p>
                <p>
                    Det vises for øvrig til Eikas forretningsvilkår, som Kunden ved inngåelse av Avtalen anses å ha
                    akseptert slik de til enhver tid er utformet. Forretningsvilkårene er tilgjengelig på Eikas
                    nettsider.
                </p>
                <h3 className="h4">14. Tvisteløsning </h3>
                <p>
                    Enhver tvist som springer ut av Avtalen skal løses etter norsk rett og med Oslo tingrett som
                    verneting.
                </p>
                <p>
                    Kunden kan bringe en eventuell tvist inn for Finansklagenemnda til uttalelse. Se Eikas nettside for
                    nærmere informasjon om klageadgangen.
                </p>
            </div>
        );
    }
}

export default IndividuellPensjonsparingsVilkaar;
