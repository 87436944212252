import React, { useState, useEffect, useRef, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { LinkButton, StandardButton, IconButton } from '@eika/button';
import { IconTypes } from '@eika/icon';
import { Modal, ModalSize } from '@eika/modal';
import {RouteComponentProps} from "react-router";
import { usePrevious } from '../../common/util/Hook';
import Card from '../../common/Card/Card';
import Chart from '../../common/Chart/Chart';
import AppMessage from '../../common/AppMessage/AppMessage';
import Message from '../../common/Message/Message';
import { generateId, getPageAppending, formatAmount, readCookie, eraseCookie, getURLquery, parseNumber, formatTrackLinkName } from '../../common/util/Functions';
import { getNewFondOrder } from '../../common/util/FondFunctions';
import Constant from '../../static/data/Constant';
import KalkultorLayout, { InputValueChangeEvent } from './KalkulatorLayout';
import { MultiObject, StringObject } from '../../domain/Types';
import {RouterConfig} from "../../app/Router";

type Props = RouteComponentProps & RouterConfig;

interface State {
    id: string;
    reducer: any;
    appending: string;
    config: any;
    value: any;
    error: StringObject;
    inputList: any;
    mode: RefObject<MultiObject>;
    loading: boolean;
    zeroPublicSector?: boolean;
    calculation?: any;
    detail?: any;
    result?: any;
}

const displayCalculation = ( props: Props, state: State, setState: (s: State) => void, calculation: any): any => {
    if ( !calculation ) {
        return setState({...state, loading: state.loading || false, result: null});
    }

    const result = JSON.parse(JSON.stringify(calculation));
    const sparing = {
        yearly: 0,
        // @ts-ignore        
        ...(state.mode.current?.sparing || {})
    };

    const link = state.inputList.find( (d: any) => d.type === 'link' ) || {};
    if ( !sparing.source ) { 
        sparing.source = {aarligAvkastningUtenKostnader: 6}; 
        link.error = 'Beregningen feilet';
    } else {
        link.error = '';
    }

    result.velgFond = sparing.yearly;
    result.totalForventetPensjonPerAar += result.velgFond;
    result.totalForventetPensjonPerMnd = Math.round((result.totalForventetPensjonPerAar/12));

    const fondNavn = (state.reducer.Fond.morningstarPin || {})[state.value.isin]?.navn || '';
    const avkastning = `${sparing.source.aarligAvkastningUtenKostnader}`.replace('.', ',');
    const antallUtbetalingsAr = parseInt(`${state.value.antallUtbetalingsAr || 0}`.replace(/\s+/g, ''), 10);
    const pensjonsAlder = parseInt(`${state.value.pensjonsAlder || 0}`.replace(/\s+/g, ''), 10);
    const bruttoInntekt = parseInt(`${state.value.bruttoInntekt || 0}`.replace(/\s+/g, ''), 10);
    const difference = result.totalForventetPensjonPerAar - bruttoInntekt;
    const highest = bruttoInntekt < result.totalForventetPensjonPerAar ? result.totalForventetPensjonPerAar : bruttoInntekt;
    const timeLine: any = [
        { year: 'Nå', title: 'Din årslønn før skatt', bar: [{ text: state.config.bruttoInntekt.name, title: `${state.config.bruttoInntekt.name} ${formatAmount(bruttoInntekt, true)} kr`, color: state.config.bruttoInntekt.color, value: bruttoInntekt, percent: ((bruttoInntekt*100)/highest)}]},
        { year: `${pensjonsAlder}`, title: 'Årlig pensjon totalt', bar: []},
        { year: `${pensjonsAlder+antallUtbetalingsAr}`, bar: []},
    ];

    const update = {...state, loading: false, result: {
        total: formatAmount(result.totalForventetPensjonPerAar, true), 
        monthly: formatAmount(result.totalForventetPensjonPerMnd, true),
        textList: [
            { key: 'folketrygdPerAar'                },
            { key: 'privatTjenestePensjonPerAar'     },
            { key: 'offentligTjenestepensjonPerYear' },
            { key: 'privatAFPPerYear'                },
            { key: 'individuelleOrdningerPerYear'    },
            { key: 'sumIndividuellPensjonsSparing'   },
            { key: 'sumEgenSparingIFond'             },
            { key: 'velgFond'                        },
        ].map( (src: any) => {
            const { key } = src;
            const value = result[key] || 0;
            if ( !value || value === -1 ) { return;  }

            const name = state.config[key]?.name || key;
            const color = state.config[key]?.color || '#ff0000';
            timeLine[1].bar.push({value, color, text: name, title: `${name} ${formatAmount(value, true)} kr`, percent: ((value*100)/highest)});
            return [
                {text: '', type: 'dot', style: {color} }, 
                {text: name, id: `${state.id}-${key}`, type: 'font-normal'}, 
                {text: formatAmount(value, true), ariaLabelledby: `${state.id}-${key}`, type: 'font-normal'}
            ];
        }).filter( (d: any) => !!d),
        sum: [ 
            [{text: 'Årlig pensjon', id: `${state.id}-pensjon-aarlig`, type: 'font-normal'}, {text: formatAmount(result.totalForventetPensjonPerAar, true), ariaLabelledby: `${state.id}-pensjon-aarlig`, type: 'font-bold'}],
        ],
        difference: [ 
            [{text: 'Differanse fra dagens lønn', id: `${state.id}-difference`, type: 'font-normal'}, {text: formatAmount(difference, true), ariaLabelledby: `${state.id}-difference`, type: 'font-bold'}],
        ],
        message: calculation.offentligTjenestepensjonPerYear === -1 ? 
            { type: 'AttentionMessage', text: props.appTexts.get('zeroPensionFromPublicSectorNote'), icon: true } : undefined,
        timeLine,
        buyFund: {action: Constant.actionKjop, text: 'Start sparing'},
        aboutCalculation: {
            list: [
                'Det forutsettes at du jobber 100% stilling, uten opphold fra arbeidslivet.',
                'Beregningene forutsetter at du er enslig den dagen du tar ut pensjon. Dette har betydning for hva du får fra folketrygden, offentlig tjenestepensjon og AFP.',
                'Ved uttak av alderspensjon forutsettes det at man slutter å jobbe og ikke får videre opptjening.',
                `Det vises kun den alderspensjon du får første år. Egen sparing er satt til å utbetales over ${antallUtbetalingsAr} år fra du er ${pensjonsAlder} år, pluss eventuelt årene før hvis du ønsker å gå av før.`,
                'Beregningene viser utbetaling før skatt.',
                'Alle tall beregnes i dagens kroner (ingen reallønnsvekst).',
                'Lønn over 12G er ikke pensjonsgivende inntekt for ytelsespensjon, innskuddspensjon og offentlig tjenestepensjon. Grensen for Folketrygden er på 7,1G og beregningene tar hensyn til modernisert regelverk.',
                `I sparekalkulatoren forutsetter vi sparing i fondet ${fondNavn} med avkastningsforventning på ${avkastning} % per år.`,
                // `Sparing på sparekonto med en årlig renteutbetaling på 2,25 %`,
                props.appTexts.get('fundCalculationInflation'),
            ],
            note: [
                props.appTexts.get('fundCalculationText1'),
                props.appTexts.get('fundCalculationText2'),
            ],
            development: sparing.chart ? {
                title: `Grafen viser forventede svingninger fra lav til høy ved egen månedlig sparing i fondet ${fondNavn}:`,
                chart: sparing.chart,
            } : undefined,         
        },
    }};

    update.result.textList.unshift([
        {text: '', type: 'dot', style: {color: state.config.bruttoInntekt.color} }, 
        {text: state.config.bruttoInntekt.name, id: `${state.id}-bruttoInntekt`, type: 'font-normal'}, 
        {text: formatAmount(bruttoInntekt, true), ariaLabelledby: `${state.id}-bruttoInntekt`, type: 'font-bold'}
    ]);
    setState(update);
};
    
const getChart = ( src=[], pensjonsAlder = 67, fondNavn='' ): any => {
    const chart: any = {
        animation: false,
        view: [800, 350],
        data: [],
        type: 'spline',
        padding: { top: 20, left: 10, bottom: 50, right: 160 },
        xAxis: { grid: 1, text: [], size: 22, lineColor: '#000' },
        yAxis: { grid: 0, separationLine: true, separation: 5, color: '#eee', unit: ' kr', toRight: true, trimNumber: 0, size: 20 },
        roundup: 1000,
        highest: 0,
        multipleBlackboard: true,
        description: [],
    };

    const start = pensjonsAlder - (src.length - 1);
    src.forEach( (data: any, i: number) => {
        const isLast = i === (src.length -1);
        [
            {key: 'ovreGrense', color: '#F7C288', text: 'Høy'},
            {key: 'verdi', color: '#45AB5B', text: 'Forventet'},
            {key: 'nedreGrense', color: '#F7C288', text: 'Lav'},
        ].forEach( (note: any, j: number) => {
            if ( !chart.data[j] ) { chart.data[j] = []; }
            chart.data[j].push({
                ...note,
                value: Math.round(data[note.key]) || 0,
            });

            if ( isLast ) {
                chart.description.push({
                    ...note,
                    text: [fondNavn, note.text].join(' - '),
                    value: Math.round(data[note.key]) || 0,
                })
            }
        });

        const title = `${start + i} år`;
        if ( i === 0 ) {
            chart.xAxis.text.push({text: 'Nå', title});
        } else if ( (start + i) === pensjonsAlder ) {
            chart.xAxis.text.push({text: title});        
        } else {
            chart.xAxis.text.push({text: ' ', title});        
        }
    });

    return chart;
}

const getCalculation = ( props: Props, state: State, setState: (s: State) => void): any => {
    if ( !state.value?.bruttoInntekt || !state.value?.pensjonsAlder ) { return; }
    setState({...state, loading: true, result: null, calculation: null});
    props.action.getForventetPensjon(state.value, ( result: any ) => {
        if ( !state.mode.current || state.mode.current.unmounted ) { return; }
        displayCalculation(props, {...state, calculation: JSON.parse(JSON.stringify(result))}, setState, result );
    });
};

const getFondSparing = ( props: Props, state: State, setState: (s: State) => void): any => {
    const alder = state.reducer.SpareProfil?.kundeInfo?.alder || 0;
    const pensjonsAlder = parseInt(`${state.value.pensjonsAlder || 67}`.replace(/\s+/,''), 10);
    const antallUtbetalingsAr = parseInt(`${state.value.antallUtbetalingsAr || 67}`.replace(/\s+/,''), 10);
    const fondNavn = (state.reducer.Fond.morningstarPin || {})[state.value.isin]?.navn || '';
    const config = {
        isin: state.value.isin,
        monthly: parseInt(`${state.value.sparing || 0}`.replace(/\s+/,''), 10),
        point: pensjonsAlder - alder,
    };

    setState({...state, loading: true, result: null});
    props.action.getFondKalkulator(config, ( result: any ) => {
        if ( !state.mode.current || state.mode.current.unmounted ) { return; }

        const development = result?.medInflasjonMedKostnad || [];
        const last = development[(development.length - 1)];
        const value = last?.verdi || 0;
        const yearly = value / antallUtbetalingsAr;
        state.mode.current.sparing = { yearly: Math.round(yearly), source: result, chart: getChart(development, pensjonsAlder, fondNavn) };

        if ( state.calculation ) {
            displayCalculation(props, state, setState, state.calculation );
        }
    });
};

const getInputList = ( customerAge?: number ) => {
    const pensionCustomerMinAge = customerAge && customerAge > Constant.pensionCustomerMinAge ? 
        (customerAge + 1) : Constant.pensionCustomerMinAge;

    return [{ 
        type: 'amount',
        name: 'bruttoInntekt',
        label: 'Årslønn før skatt',
        unit: 'kr', 
        // round: 10000,
        interval: [0, 100000000],
        adjustment: [
            {step: 25000, label: 'Mink 25 000'},
            {step: 25000, label: 'Økt 25 000'},
        ],
        validation: [{
            rule: 'number',
            allow: 'empty',
            message: 'Ugyldig verdi.',
        }, {
            rule: 'lessThanOrLike',
            number: 100000000,
            allow: 'empty',
            message: 'Verdi må være lik eller mindre enn 100 000 000',
        }]
    }, {
        type: 'field',
        name: 'pensjonsAlder',
        label: 'Pensjonsalder',
        unit: 'år',
        round: 1,
        maxLength: 2,
        interval: [pensionCustomerMinAge, Constant.pensionCustomerMaxAge],
        adjustment: [
            {step: 1, label: 'Mink 1 år'},
            {step: 1, label: 'Økt 1 år'},
        ],
         validation: [{
            rule: 'number',
            message: 'Ugyldig verdi.',
        }, {
            rule: 'greatThanOrLike',
            number: pensionCustomerMinAge,
            message: `Verdi må være lik eller større enn ${pensionCustomerMinAge}.`,
        }, {
            rule: 'lessThanOrLike',
            number: Constant.pensionCustomerMaxAge,
            message: `Verdi må være lik eller mindre enn ${Constant.pensionCustomerMaxAge}.`,
        }]
    }, {
        type: 'amount',
        name: 'sparing',
        label: 'Månedlig sparebeløp',
        unit: 'kr',
        interval: [0, Constant.monthlyMaxAmount],
        maxLength: 7,
        adjustment: [
            {step: 200, label: 'Mink 200'},
            {step: 200, label: 'Økt 200'},
        ],
        slider: true,
        validation: [{
            rule: 'number',
            allow: 'empty',
            message: 'Ugyldig verdi.',
        }, {
            rule: 'lessThanOrLike',
            number: Constant.monthlyMaxAmount,
            allow: 'empty',
            message: `Maks. ${formatAmount(Constant.monthlyMaxAmount)} kr`,
        }],
    }, {
        type: 'link',
        name: 'fund',
        label: 'Velg fond',
        value: '',
        link: { action: Constant.actionVelgFond, text: 'Bytt fond'},
        error: '',
    }, {
        type: 'checkbox',
        name: 'inkluderFond', 
        label: 'Inkluder tidligere fondssparing og eksisterende spareavtaler.',
    }];
};

const changeCheckbox = (props: Props, state: State, setState: (s: State) => void, e: boolean, key: string) => {
    if ( !state.calculation ) { return; }

    const calculation = JSON.parse(JSON.stringify(state.calculation));
    const update = { ...state };
    update.value[key] = e;

    if ( !e ) {
        calculation.totalForventetPensjonPerAar -= calculation.sumEgenSparingIFond;
        calculation.totalForventetPensjonPerMnd = Math.round( (calculation.totalForventetPensjonPerAar / 12));
        calculation.sumEgenSparingIFond = 0;
    }

    setState({...update, loading: true, result: null});   
    setTimeout( () => {
       displayCalculation(props, update, setState, calculation ); 
    }, 500);
};

const changeInputValue = (props: Props, state: State, setState: (s: State) => void, e: InputValueChangeEvent) => {
    const update = { ...state };
    const name = e.currentTarget.name;
    const value = e.currentTarget.value || '';
    update.value[name] = value;

    update.error.any = '';
    state.inputList.forEach( (input: any) => {
        if ( input.name !== name ) { return; }
        const error = (input.validation || []).find( (validation: any) => {
            let out = false;
            const number = parseNumber( value );
            if ( validation.rule === 'number') {
                if ( validation.allow === 'empty' && !`${value}`.replace( /\s/g, '' ) ) {
                    out = false;
                } else {
                    out = !`${value}`.replace( /\s/g, '' ).match( /^(?:[1-9]\d*|\d)$/ );
                }
            } else if ( validation.rule === 'lessThanOrLike' && validation.number ) {
                if ( validation.allow === 'empty' && (value === '' || number === 0) ) {
                    out = false;
                } else {
                    out = number && !isNaN(number) ? (number > validation.number) : true;
                }
            } else if ( validation.rule === 'greatThanOrLike' && validation.number ) {
                if ( validation.allow === 'empty' && (value === '' || number === 0) ) {
                    out = false;
                } else {
                    out = number && !isNaN(number) ? (number < validation.number) : true;
                }
            }
            return out;
        });

        update.error[name] = error ? error.message : '';
        update.error.any = update.error.any || update.error[name] || '';
    });

    if ( update.error.any === '' && name === 'pensjonsAlder' && update.mode.current ) {
        if ( update.mode.current.getFondSparing ) {
            clearTimeout((update.mode.current.getFondSparing as NodeJS.Timeout));
        }
        if ( update.mode.current.getCalculation ){
            clearTimeout((update.mode.current.getCalculation as NodeJS.Timeout));
        }

        update.mode.current.getCalculation = setTimeout( () => {
            delete( update.calculation );
            getFondSparing(props, update, setState);
            getCalculation(props, update, setState);
        }, 500);
    } else if ( update.error.any === '' && name === 'sparing' && update.mode.current ) {
        if ( update.mode.current.getFondSparing ) {
            clearTimeout((update.mode.current.getFondSparing as NodeJS.Timeout));
        }
        update.mode.current.getFondSparing = setTimeout( () => {
            getFondSparing( props, update, setState );
        }, 500);
    } else if ( update.error.any === '' && update.mode.current ) {
        if ( update.mode.current.getCalculation ){
            clearTimeout((update.mode.current.getCalculation as NodeJS.Timeout));
        }
        update.mode.current.getCalculation = setTimeout( () => {
            getCalculation( props, update, setState );
        }, 500);
    }

    setState(update);
};

const click = ( props: Props, state: State, setState: (s: State) => void, e: MouseEvent | null, key='', data?: any): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === Constant.actionCloseModal ) {
        setState({...state, detail: null});
    } else if ( key === Constant.actionOpenModal && data && data.detail ) {
        setState({...state, detail: data.detail});        
    } else if ( (key === 'adjustment-decrease' || key === 'adjustment-increase')  && data ) {
        const value = parseInt( `${state.value[data.name] || 0}`.replace(/\s+/g, ''), 10) + (data.step * (key === 'adjustment-increase' ? 1 : -1));
        if ( isNaN(value) || (data.interval && (data.interval[0] > value || data.interval[1] < value)) ) { return; }
        changeInputValue( props, state, setState, {currentTarget: {
            value: `${value}`, name: data.name
        }});
    } else if ( key === 'about-calculation' ) {
        setState({...state, detail: key});
    } else if ( key === Constant.actionVelgFond ) {
        const order = getNewFondOrder({
            type: Constant.typeManedligSparing,
            action: Constant.actionVelgFond,
            value: state.value,
        });
        props.history.push(`${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionMonthly}`);        
    } else if ( key === Constant.actionKjop ) {
        const order = getNewFondOrder({
            type: Constant.typeManedligSparing,
            amount: parseInt( `${state.value.sparing || 100}`.replace(/\s+/g,''), 10),
            fund: state.value.isin,
            finishBack: /arbeidsgiver/i.test(window.location.href) ? 2 : 1, 
        });
        props.history.push(`${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionMonthly}`);    
    }
};

const anyZeroPublicSector = ( reducer: any ): boolean => {
    const { arbeidsgiversPensjonsSparing } = reducer?.Pensjon || {};
    const result = !!(arbeidsgiversPensjonsSparing || []).find((d: any) => {
        return !Constant.regCurrentPension.test(d.navn) && 
            Constant.regPublicSector.test(d.navn) &&
            !d.sum; 
    });
    return result;
};

const initView = ( props: Props, state: State, setState: (s: State) => void, reducer: any ): any => {
    const customerAge = reducer.SpareProfil?.kundeInfo?.alder;
    const pensionAge = reducer.pensjonsvalg?.pensjonsAlder || 67;
    const update = {
        ...state,
        inputList: JSON.parse(JSON.stringify(state.inputList)), 
        config: JSON.parse(JSON.stringify(state.config)), 
        zeroPublicSector: anyZeroPublicSector( reducer ),
        value: {
            pensjonsAlder: customerAge && customerAge >= pensionAge ? `${customerAge + 1}` : pensionAge,
            bruttoInntekt: reducer?.pensjonsvalg?.bruttoInntekt || '400 000',
            ...state.value,
        }
    }

    if ( update.value.inkluderFond === 'false' ) {
        delete( update.value.inkluderFond );
    }

    if ( update.value.isin && (reducer.Fond.morningstarPin || {})[update.value.isin] ) {
        const name = (reducer.Fond.morningstarPin || {})[update.value.isin].navn || 'Fond';
        const input = update.inputList.find( (d: any) => d.name === 'fund' );
        if ( input ) { input.value = name; }
        update.config.velgFond.name = name;
    }

    if ( update.value.sparing && !(update.mode.current || {}).sparing ) {
        getFondSparing(props, update, setState);
    }

    getCalculation( props, update, setState );
    setState( update );
};

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'SpareProfil', 'Fond', 'Pensjon'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });

    const [state, setState] = useState<State>({
        id: generateId('pensjonberegning'),
        inputList: getInputList( reducer.SpareProfil?.kundeInfo?.alder ),
        appending: getPageAppending( props ),
        reducer,
        error: {},
        value: {inkluderFond: true, sparing: 500, antallUtbetalingsAr: 10, isin: 'NO0010003999', ...getURLquery()},
        mode: useRef<MultiObject>({}),
        loading: true,
        config: {
            folketrygdPerAar: {color: '#00383D', name: 'Folketrygd', key: 'folketrygdPerAar', },
            offentligTjenestepensjonPerYear: {color: '#dda0dd', name: 'SPK, KLP eller offentlige ordninger', key: 'offentligTjenestepensjonPerYear'},
            privatTjenestePensjonPerAar: {color: '#9E5F8D', name: 'Arbeidsgivers pensjon', key: 'privatTjenestePensjonPerAar'},
            individuelleOrdningerPerYear: {color: '#61C3E6', name: 'Individuelle ordninger', key: 'individuelleOrdningerPerYear'},           
            privatAFPPerYear: {color: '#C0E7F5', name: 'Privat AFP', key: 'privatAFPPerYear'},       
            sumEgenSparingIFond: {color: '#D3EC99', name: 'Egen fondssparing', key: 'sumEgenSparingIFond'},           
            sumIndividuellPensjonsSparing: {name: 'Eika IPS-konto', color: '#ecf7d4', key: 'sumIndividuellPensjonsSparing'},
            differanse: {name: 'Differanse', color: '#ff0000'},
            bruttoInntekt: {name: 'Dagens årslønn', color: '#C3B3AB'},
            velgFond: {name: 'Valg fond', color: '#84BD01'},
        }
    });

    const previousReducer = usePrevious(reducer);
    useEffect(() => {
        const ignore = readCookie('velgFond') || 
            !reducer.Fond?.morningstarPin || 
            !reducer.SpareProfil?.pensjonsvalg || 
            (
                JSON.stringify(reducer.Fond?.morningstarPin) === JSON.stringify(previousReducer?.Fond?.morningstarPin) &&
                JSON.stringify(reducer.SpareProfil?.pensjonsvalg) === JSON.stringify(previousReducer?.SpareProfil?.pensjonsvalg) &&
                JSON.stringify(reducer.Pensjon?.arbeidsgiversPensjonsSparing) === JSON.stringify(previousReducer?.Pensjon?.arbeidsgiversPensjonsSparing)
            );
        if ( ignore ) { return; }

        initView(props, state, setState, reducer);
    }, [previousReducer, reducer, state, props]);

    useEffect(() => {
        return () => { 
            if ( !state.mode.current ) { return; }
            state.mode.current.unmounted = true; 
        };
    },[state.mode]);

    useEffect(() => {
        const order = readCookie('velgFond');
        const info = order ? readCookie(order) : null;
        if ( info ) {
            const data = JSON.parse(info);
            const param: string[] = [];
            for ( const key in (data.value || {}) ) {
                param.push(`${key}=${data.value[key]}`);
            }

            eraseCookie('velgFond');
            eraseCookie('order');

            window.history.go( -1);
            setTimeout( () => {
                props.history.push({
                    pathname: props.location.pathname.replace(/\?.*/, ''),
                    search: `?${param.join('&')}`
                }); 
            }, 100);
        }
    },[props.history, props.location]);

    return <KalkultorLayout
        id={state.id}
        appending={state.appending}
        namespace="pensjonsberegning-wrapper"
        title="Pensjonskalkulator"
        loading={state.loading && !state.error.any}
        message={ `${state.value.pensjonsAlder}` === `${Constant.pensionCustomerMinAge}` ? props.appTexts.get('pensionAgeWarning') : ''}
        inputList={state.inputList}
        error={state.error}
        value={state.value}
        click={(e: any, k='', d: any) => { click(props, state, setState, e, k, d); }}
        changeInputValue={(e: InputValueChangeEvent) => { changeInputValue(props, state, setState, e); }}
        changeCheckbox={(e: boolean, k='') => { changeCheckbox(props, state, setState, e, k); }}
        noteMessage={[
            { text: props.appTexts.get('pensionCalculatorMessage'), type: 'AttentionMessage', icon: true },
            state.zeroPublicSector ? { text: props.appTexts.get('zeroPensionFromPublicSectorNote'), type: 'AttentionMessage', icon: true} : undefined,
        ].filter( (d: any) => !!d)}
        appMessage={<AppMessage appMessage={reducer?.App?.serviceMessage} context="PENSION_CALCULATOR"/>}
        result={<>
            { !!state.result && !!state.result.message && <div className="paragraph">
                <Message {...state.result.message}/> 
            </div>}

            <h2 id={`${state.id}-summary-title`}>Se hva du får i pensjon</h2>
            { !!state.result && <>
                <div id="summary-total-unit">Årlig pensjon totalt</div>
                <p aria-labelledby="summary-total-unit" className="summary-result-total"><strong>{state.result.total}</strong> kr</p>
                <div className="summary-result-note center">
                    <div>Det blir <strong>{`${state.result.monthly} kr per måned.`}</strong></div>
                    <LinkButton 
                        data-tack-id={Constant.trackIdProduct} 
                        data-track-linkname="om-utregningen" 
                        onClick={()=>{click(props, state, setState, null, 'about-calculation');}}
                    >Om utregningen</LinkButton>
                </div>
                
                { (state.result.timeLine || []).length > 0 && <ul className="summar-time-line">
                    {state.result.timeLine.map( (data: any, i: number) => {
                        return <li key={`time-line-item-${i}`} className="summar-time-line-item">
                            <div className="summar-time-line-year"><span>{data.year}</span></div>
                            { (data.bar || []).length > 0 && <div className="summar-time-line-bar">
                                <div className="summar-time-line-bar-cnt">
                                    <div className="bar-wrappper" title={data.title || ''}>
                                        { data.bar.map( (d: any, j: number) => {
                                            return <span key={`time-line-item-${i}-${j}`} title={d.title || ''} style={{width: `${d.percent || 0}%`, backgroundColor: d.color}} />
                                        })}
                                    </div>
                                </div>                                  
                            </div> }
                        </li>
                    })}
                </ul>}

                {!!state.result.textList && <div className="summary-result-textlist">
                    <Card type="list-item" data={{textList: state.result.textList}} ignoreAriaLabelGenerating/>
                </div>}

                {!!state.result.sum && false && <div className="summary-result-sum">
                    <Card type="list-item" data={{textList: state.result.sum}} ignoreAriaLabelGenerating/>
                </div>}

                {!!state.result.difference && false && <div className="summary-result-difference">
                    <Card type="list-item" data={{textList: state.result.difference}} ignoreAriaLabelGenerating/>
                </div>}

                <div className="paragraph -only-top center">
                    <StandardButton 
                        data-tack-id={Constant.trackIdBuy} 
                        data-track-linkname={formatTrackLinkName(state.result?.buyFund?.text)} 
                        onClick={()=>{click(props, state, setState, null, state.result.buyFund.action);}}
                    >{state.result?.buyFund?.text || ''}</StandardButton>
                </div>
            </>}
        </>}
    >
        <Modal modalSize={ModalSize.LARGE} 
            id="pensjonberegning-modal" 
            appNamespace="sparing-react-app" 
            onClose={() => { click(props, state, setState, null, Constant.actionCloseModal); }} 
            show={!!state.detail}
            headerElements={<>
                <div id="pensjonskalkultor-beregning-title">Om utregningen</div>
                <span>
                    <IconButton type="button" onClick={() => { click(props, state, setState, null, Constant.actionCloseModal); }} icon={IconTypes.KRYSS_24} />
                </span>
            </>}
            footerClickableElements={ <div className="action-holder center">
                <StandardButton 
                    data-tack-id={Constant.trackIdBuy} 
                    data-track-linkname={formatTrackLinkName(state.result?.buyFund?.text)} 
                    onClick={()=>{click(props, state, setState, null, state.result?.buyFund?.action);}}
                >{state.result?.buyFund?.text || ''}</StandardButton>
            </div>}
        >
            { state.detail === 'about-calculation' && !!state.result.aboutCalculation && <article aria-labelledby="pensjonskalkultor-beregning-title" className="pensjonskalkultor-beregning-wrapper">

                { !!state.result.aboutCalculation.development && <div className="pensjonskalkultor-fund-development-info-wrapper paragraph">
                    { !!state.result.aboutCalculation.development.title && <p>{state.result.aboutCalculation.development.title}</p>}
                    { !!state.result.aboutCalculation.development.chart && <Chart {...state.result.aboutCalculation.development.chart}/> }
                    { !!state.result.aboutCalculation.development.chart?.description && <ul className="fund-development-chart-description">
                        { state.result.aboutCalculation.development.chart.description.map( (t: any, i: number) => (
                            <li key={`fund-develoment-chart-${i}`}>
                                <div>
                                    <span className="dot" style={{backgroundColor: t.color}}/>
                                    <span id={`fund-develoment-chart-description-${i}`} className="text">{t.text}</span>
                                </div>
                                <div aria-labelledby={`fund-develoment-chart-description-${i}`}>{`${formatAmount(t.value)} kr`}</div>
                            </li>
                        ))}
                    </ul> }
                </div>}

                <ul className="pensjonskalkultor-beregning-point-list">
                    { state.result.aboutCalculation.list.map( (text: string, i: number) => (
                        <li key={`about-calculation-${i}`}>{text}</li>
                    ))}
                </ul>
                { state.result.aboutCalculation.note.map( (note: string, i: number) => (
                    <p key={`calculation-note${i}`} className="pensjonskalkultor-beregning-note">{note}</p>
                ))}
            </article>
         }
        </Modal>
    </KalkultorLayout>
};