import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ErrorLabel, WarningLabel } from '@eika/label';
import { Modal } from '@eika/modal';
import { ButtonTypes, StandardButton } from '@eika/button';
import { IconTypes } from '@eika/icon';
import { Link } from '@eika/link';
import { formatAmount, formatDateToText, getPageAppending } from '../../../common/util/Functions';
import { getTransactionLabelList } from '../../../common/util/BusinessFunctions';
import { usePrevious, useStorebandInfo } from '../../../common/util/Hook';
import Grid from '../../../common/Grid/Grid';
import Card, { Props as CardProps } from '../../../common/Card/Card';
import AppMessage from '../../../common/AppMessage/AppMessage';
import { HeaderLink, Spinner, SummaryTotalValue } from '../../../common/share/ShareComponents';
import { ViewTransactionContent } from '../share/ShareComponents';
import '../Arbeidsgiver.scss';
import './Storebrand.scss';
import { ContentData, ReducerKey, WithNestedData } from './types';
import safeArray from '../util/SafeArray';
import CardExpander from '../../../common/CardExpander/CardExpander';
import { RouterConfig } from '../../../app/Router';

type Props = RouteComponentProps<{ id?: string }> & RouterConfig;

interface Content {
    error: string;
    totalValue?: number;
    product?: string;
    category?: string;
    riskProfiles?: string | string[];
    portfolioBaseCode?: string[];
    detail?: ContentData;
    saving?: ContentData;
    linkPanel?: ContentData;
    increasing?: ContentData;
}

interface Transaction {
    order?: string[];
    pin: { [k: string]: any };
    hasMore: boolean;
}

interface State {
    appending: string;
    open: boolean;
    loading: boolean;
    now: number;
    modal: string;
    content?: Content;
    transaction?: Transaction;
    pendingTransaction?: Transaction;
}

const getTransactions = (props: Props, state: State, setState: (s: State) => void): void => {
    const aDay = 1000 * 60 * 60 * 24;
    const toDate = new Date(state.now);
    const fromDate = new Date(state.now - aDay * 365);
    const config = {
        id: props.match?.params?.id,
        toDate,
        fromDate: new Date(fromDate.getTime() - aDay * 90),
    };

    const now = fromDate.getTime();
    props.action.getStorebrandTransaction(config, (result: any) => {
        const transaction: Transaction = { pin: state.transaction?.pin ?? {}, hasMore: false };
        const list = result || [];

        if (list.length === 0) {
            return setState({ ...state, transaction });
        }

        list.forEach((d: any) => {
            if (!d || !d.date) {
                return;
            }

            const date = new Date(d.date);
            if (date.getTime() < now) {
                transaction.hasMore = true;
                return;
            }

            const year = `${date.getFullYear()}`;
            if (!transaction.pin[year]) {
                transaction.pin[year] = [];
            }

            const amount = d.amount || 0;
            const data = {
                textList: [
                    [
                        {
                            text: [formatDateToText(date, 'dd.mm')].filter((d) => !!d),
                            type: 'date',
                            ariaLabel: `Den ${formatDateToText(date, 'dd. MM yyyy')}`,
                        },
                        {
                            text: [
                                { text: d.fundName, type: 'fund-name', ariaLabel: `Fond ${d.fundName}` },
                                { text: d.details || '', type: 'fund-action' },
                            ],
                            type: 'info',
                        },
                        { text: `${formatAmount(amount)}`, type: 'amount', ariaLabel: `${amount} kr` },
                    ],
                ],
                labelList: getTransactionLabelList(d),
            };
            transaction.pin[year].push({ data, stamp: date.getTime() });
        });

        transaction.order = Object.keys(transaction.pin).sort().reverse();
        transaction.order.forEach((year: string) => {
            transaction.pin[year] = transaction.pin[year].sort((a: any, b: any): number => {
                const x = a.stamp;
                const y = b.stamp;
                if (x === y) {
                    return 0;
                }
                return x < y ? 1 : -1;
            });
        });
        setState({ ...state, now, transaction });
    });
};

const getContent = (props: Props, state: State, setState: (s: State) => void, reducer: any): void => {
    const content: Content = { error: '', ...(state.content ?? {}) };
    if (content.riskProfiles) {
        return;
    }

    const info = (reducer.Storebrand.info ?? {})[props.match?.params.id || ''];
    if (info === null) {
        content.error = props.appTexts.get('opsProvIgjen');
    } else {
        const employedDate = formatDateToText(new Date(info.employedDate), 'dd.mm.yyyy');
        const returnPercentage = formatAmount(info.totalReturnPct);
        const returnValue = formatAmount(info.totalReturn, true);
        const annualAverageReturnPct = info.annualAverageReturnPct
            ? `${formatAmount(info.annualAverageReturnPct)} %`
            : undefined;

        content.riskProfiles = safeArray(info.riskProfiles)
            .map((t: string) => {
                const v = t.replace(/eika\s+selvvalgt/i, '').trim();
                return /forsiktig/i.test(v) ? 'Lav' : v;
            })
            .filter((t: string) => !!t);

        content.totalValue = Math.round(info.totalMarketValue || 0);
        content.saving = {
            detail: [
                info.activeMarketValue
                    ? {
                          data: {
                              textList: [
                                  [
                                      { text: ['Nåværende arbeidsgiver', info.employerName], type: 'font-normal' },
                                      { text: formatAmount(info.activeMarketValue, true), type: '' },
                                  ],
                              ],
                          },
                      }
                    : undefined,
                info.passiveMarketValue
                    ? {
                          data: {
                              textList: [
                                  [
                                      {
                                          text: 'Tidligere arbeidsgiver',
                                          type: 'font-normal',
                                      },
                                      { text: formatAmount(info.passiveMarketValue, true), type: '' },
                                  ],
                              ],
                          },
                      }
                    : undefined,
            ].filter((d) => !!d),
        };
        content.increasing = {
            percentage: info.totalReturn || 0,
            text: `${returnPercentage} % (${returnValue})`,
            detail: [
                {
                    data: {
                        textList: [
                            [
                                { text: ['Total verdiutvikling fra', employedDate], type: 'font-normal' },
                                { text: [`${returnPercentage} %`, `${returnValue}`], type: 'return-info' },
                            ],
                        ],
                    },
                },
                {
                    data: {
                        textList: [
                            [
                                { text: 'Dato for flytt', type: 'font-normal' },
                                { text: employedDate, type: '' },
                            ],
                        ],
                    },
                },
                annualAverageReturnPct ? {
                    data: {
                        textList: [
                            [
                                {
                                    text: 'Gjennomsnittlig årlig verdiøkning',
                                    type: 'font-normal',
                                },
                                { text: annualAverageReturnPct, type: '' },
                            ],
                        ],
                    },
                } : undefined,
            ].filter((d) => !!d),
        };
    }

    const update = { ...state, content, loading: false };
    setState(update);
    getTransactions(props, update, setState);
};

const click = (props: Props, state: State, setState: (s: State) => void, e: MouseEvent | null, key = ''): void => {
    if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
    }

    if (key === 'open-account-info') {
        setState({ ...state, modal: 'account-info' });
    } else if (key === 'open-saving') {
        setState({ ...state, modal: 'saving' });
    } else if (key === 'show-more') {
        getTransactions(props, state, setState);
    }
};

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Storebrand', 'Features'].reduce((p: Record<ReducerKey, any>, k: string) => {
            return { ...p, [k]: (state || {})[k] || {} };
        }, {} as Record<ReducerKey, any>);
    });
    const storebrandSavingProfilesEnabled = useMemo(
        () => reducer?.Features.storebrandProfilesEnabled,
        [reducer.Features]
    );

    const [state, setState] = useState<State>({
        open: false,
        loading: true,
        modal: '',
        now: new Date().getTime(),
        appending: getPageAppending(props),
    });

    const previousReducer = usePrevious(reducer);
    useEffect(() => {
        const ignore =
            !!state.content ||
            reducer.Storebrand?.info === undefined ||
            JSON.stringify(reducer) === JSON.stringify(previousReducer);
        if (ignore) {
            return;
        }
        getContent(props, state, setState, reducer);
    }, [previousReducer, reducer, state, props]);

    useStorebandInfo(reducer, props.action);

    const cards = (
        <>
            {storebrandSavingProfilesEnabled ? (
                <div className="paragraph">
                    <Card
                        type="spare-link-panel"
                        data={{
                            url: `${window.location.href.replace(/\/+$/g, '')}/spareprofile`,
                            textList: [{ text: props.appTexts.get('dinSpareprofil') }],
                        }}
                    >
                        <div className="paragraph-extra-small -only-top -row">
                            {safeArray(state.content?.riskProfiles).map((t: string) => (
                                <WarningLabel key={t} className="risk-profile-tag" text={t} />
                            ))}
                        </div>
                    </Card>
                </div>
            ) : (
                <div className="paragraph-large">
                    <CardExpander title="Din risikoprofil" riskProfile={state.content?.riskProfiles || 'Ukjent'} static>
                        <div className="paragraph -only-top">
                            Pensjonssidene er under utvikling. Inntil videre kan du gå til Storebrand for å se
                            informasjon om din risikoprofil.
                        </div>
                        <Link
                            href="https://www.storebrand.no/min-sparing/partner/forside?partnerId=eika"
                            iconOnRightSide
                            icon={IconTypes.EKSTERN_24}
                        >
                            Gå til Storebrand
                        </Link>
                    </CardExpander>
                </div>
            )}
            <div className="paragraph">
                <Card
                    type="spare-link-panel"
                    data={{
                        url: `${window.location.href.replace(/\/+$/g, '')}/agreement`,
                        textList: [
                            { text: 'Om pensjonsavtalen din' },
                            { text: 'Detaljer om utbetaling og årlig sparing.', type: 'font-normal' },
                        ],
                    }}
                />
            </div>
        </>
    );

    const content = (
        <>
            <AppMessage appMessage={reducer?.App?.serviceMessage} context="STOREBRAND" />

            {!!state.content && (
                <div className="only-for-mobile paragraph center">
                    {state.content.error ? (
                        <div>
                            <ErrorLabel icon={IconTypes.UTROPSTEGN_24} text={state.content.error} />
                        </div>
                    ) : (
                        <SummaryTotalValue
                            namespace="paragraph-large"
                            label={props.appTexts.get('totalSavedStorebrand')}
                            value={formatAmount(state.content.totalValue)}
                            click={(e: MouseEvent) => {
                                click(props, state, setState, e, 'open-saving');
                            }}
                        />
                    )}

                    {!!state.content?.increasing && (
                        <a
                            href="#"
                            role="button"
                            className="storebrand-info-increasing-note"
                            onClick={(e: MouseEvent) => {
                                click(props, state, setState, e, 'open-account-info');
                            }}
                        >
                            <div className="storebrand-info-increasing-note">
                                <span className="info-text-link">{props.appTexts.get('totalValueSwing')}</span>
                            </div>
                            <span className="value-swing -positive -size-large">
                                {(state.content?.increasing as WithNestedData)?.text}
                            </span>
                        </a>
                    )}
                </div>
            )}

            {!!state.content && (
                <section className="paragraph only-for-desktop">
                    <div className="wallpaper-wrapper">
                        <div className="max-width-600">
                            <div className="summary-total-value-and-increasing-wrapper">
                                {state.content.error ? (
                                    <div className="paragraph">
                                        <ErrorLabel icon={IconTypes.UTROPSTEGN_24} text={state.content.error} />
                                    </div>
                                ) : (
                                    <SummaryTotalValue
                                        namespace="paragraph-large"
                                        label={props.appTexts.get('totalSavedStorebrand')}
                                        value={formatAmount(state.content.totalValue)}
                                        click={(e: MouseEvent) => {
                                            click(props, state, setState, e, 'open-saving');
                                        }}
                                    />
                                )}

                                {!!state.content?.increasing && (
                                    <div>
                                        <a
                                            href="#"
                                            role="button"
                                            className="storebrand-info-increasing-note -desktop"
                                            onClick={(e: MouseEvent) => {
                                                click(props, state, setState, e, 'open-account-info');
                                            }}
                                        >
                                            <div className="storebrand-info-increasing-note">
                                                <span className="info-text-link">
                                                    {props.appTexts.get('totalValueSwing')}
                                                </span>
                                            </div>
                                            <span className="value-swing -positive -size-large">
                                                {(state.content?.increasing as WithNestedData)?.text}
                                            </span>
                                        </a>
                                    </div>
                                )}
                            </div>

                            <ViewTransactionContent
                                order={state.transaction?.order || []}
                                storage={state.transaction?.pin || {}}
                            />

                            {!!state.transaction?.hasMore && !state.loading && (
                                <div className="paragraph -include-top center">
                                    <StandardButton
                                        buttonType={ButtonTypes.STANDARD_NEGATIVE}
                                        onClick={(e) => {
                                            click(props, state, setState, e, 'show-more');
                                        }}
                                    >
                                        {props.appTexts.get('showMore')}
                                    </StandardButton>
                                </div>
                            )}

                            {!state.transaction?.hasMore && !state.loading && (
                                <div className="transaction-footer">
                                    {(state.transaction?.order || []).length ||
                                    (state.pendingTransaction?.order || []).length
                                        ? 'Ingen flere transaksjoner.'
                                        : 'Ingen transaksjoner.'}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            )}

            <div className="only-for-mobile paragraph -only-top">
                <div className="paragraph-large">{cards}</div>

                <ViewTransactionContent
                    title="Transaksjoner"
                    order={state.transaction?.order || []}
                    storage={state.transaction?.pin || {}}
                />

                {!!state.transaction?.hasMore && !state.loading && (
                    <div className="paragraph -include-top center">
                        <StandardButton
                            buttonType={ButtonTypes.STANDARD_NEGATIVE}
                            onClick={(e) => {
                                click(props, state, setState, e, 'show-more');
                            }}
                        >
                            {props.appTexts.get('showMore')}
                        </StandardButton>
                    </div>
                )}

                {!state.transaction?.hasMore && !state.loading && (
                    <div className="transaction-footer">
                        {(state.transaction?.order || []).length
                            ? 'Ingen flere transaksjoner.'
                            : 'Ingen transaksjoner.'}
                    </div>
                )}
            </div>

            {state.loading && <Spinner type="linkPanel" />}
        </>
    );

    return (
        <div className="storebrand-info-wrapper">
            <Grid
                namespace="sparing-top"
                list={[
                    {
                        layout: 'twelve',
                        element: <HeaderLink length={2} text="Pensjon fra arbeidsgiver" />,
                    },
                ]}
            />

            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: (
                            <>
                                <h1>Eika Innskuddspensjon</h1>
                            </>
                        ),
                    },
                ]}
            />

            <div
                className={`arbeidsgiver-profil-common-wrapper storebrand-info-content ${
                    state.appending || 'none-appending'
                }`}
            >
                <Grid
                    namespace="sparing-content"
                    list={[
                        {
                            layout: 'seven',
                            element: content,
                        },
                        {
                            layout: 'four',
                            element: (
                                <>
                                    <div className="only-for-desktop">
                                        {cards}
                                        {state.loading && <Spinner type="linkPanel" />}
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </div>

            <Modal
                id="storebrand-info-modal"
                appNamespace="sparing-react-app"
                onClose={() => {
                    setState({ ...state, modal: '' });
                }}
                show={!!state.modal}
            >
                {state.modal === 'account-info' && (
                    <>
                        <h2 className="h1 paragraph">{props.appTexts.get('storebrandTotalValueIncreaseTitle')}</h2>
                        <p>{props.appTexts.get('storebrandTotalValueIncreaseDescription')}</p>
                        {!!state.content?.increasing && (
                            <div className="storebrand-info-increasing-wrapper paragraph -only-top">
                                {safeArray((state.content?.increasing as WithNestedData)?.detail).map(
                                    (d: CardProps, i: number) => (
                                        <Card key={`increasing-item-${i}`} {...d} type="list-item" />
                                    )
                                )}
                            </div>
                        )}
                    </>
                )}

                {state.modal === 'saving' && (
                    <>
                        <h2 className="h1 paragraph">{props.appTexts.get('storebrandTotalValueSavingTitle')}</h2>
                        <p>{props.appTexts.get('storebrandTotalValueSavingDescription')}</p>
                        {!!state.content?.saving && (
                            <div className="storebrand-info-saving-wrapper paragraph -only-top">
                                {safeArray((state.content?.saving as WithNestedData)?.detail).map(
                                    (d: CardProps, i: number) => (
                                        <Card key={`saving-item-${i}`} {...d} type="list-item" />
                                    )
                                )}
                            </div>
                        )}
                    </>
                )}
            </Modal>
        </div>
    );
};
