import {
    OEK_MND_SPARING,
    HENT_FORVENTET_PENSJONSTALL,
    LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA,
    LASTER_PENSJONS_ESTIMATER,
    PENSJON_PROGNOSE_OK,
    PENSJON_PROGNOSE_ERROR,
    MAX_ANTALL_KALL_OVERSKREDET,
    ACKNOWLEDGE_ERROR,
} from '../actions/pensjon';

const initialState = {
    lasterPensjonsEstimater: false,
    beregnetPensjonError: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HENT_FORVENTET_PENSJONSTALL:
            return {
                ...state,
                lasterPensjonsEstimater: false,
                pensjonsData: action.data,
            };
        case OEK_MND_SPARING:
            return {
                ...state,
                ...action.data,
            };
        case LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA:
            return {
                ...state,
                arbeidsgiversPensjonsSparing: action.data,
            };
        case LASTER_PENSJONS_ESTIMATER:
            return {
                ...state,
                lasterPensjonsEstimater: true,
            };
        case PENSJON_PROGNOSE_OK:
            return {
                ...state,
                prognoser: {
                    status: 'Ok',
                    values: action.data.prognose,
                    uttaksAlder: action.data.pensjonsalder,
                },
            };
        case PENSJON_PROGNOSE_ERROR:
            return {
                ...state,
                prognoser: {
                    status: 'Error',
                },
            };
        case MAX_ANTALL_KALL_OVERSKREDET:
            return {
                ...state,
                beregnetPensjonError: {
                    message: action.message,
                    name: action.name,
                    acknowledged: false,
                },
            };
        case ACKNOWLEDGE_ERROR:
            return {
                ...state,
                beregnetPensjonError: {
                    ...state.error,
                    acknowledged: true,
                },
            };
        default:
            return state;
    }
};
