import React, { useEffect, useCallback } from 'react';
import Constant from '../../static/data/Constant';
import { addEvent, removeEvent } from '../util/Functions';
import './SigningIframe.scss';

interface Props {
    url: string;
    callback: (status: string) => void;
};

const initSigningUrlCallback = ( url='' ): string => {
    const origin = window.location.origin;
    const param = ['lc=no'].concat(
        /localhost/i.test( origin ) ? [
            `exit=${encodeURIComponent(`${window.location.href.replace( /#(\/)?.*/gi, '')}#/signingsuccess`)}`,
            `rejectstatusurl=${encodeURIComponent(`${window.location.href.replace( /#(\/)?.*/gi, '')}#/signingcancel`)}`,
            `status=${encodeURIComponent(`${window.location.href.replace( /#(\/)?.*/gi, '')}#/signingerror`)}`,
        ] : [    
            `exit=${encodeURIComponent(`${origin}/sparing-min-side-web/signing-success.html`)}`,
            `rejectstatusurl=${encodeURIComponent(`${origin}/sparing-min-side-web/signing-cancel.html`)}`,
            `status=${encodeURIComponent(`${origin}/sparing-min-side-web/signing-error.html`)}`,
        ]
    );

    const out =/\?/.test(url) ? `${url}&${param.join('&')}` : `${url}?${param.join('&')}`;
    return out;
};

export default ({url, callback}: Props): JSX.Element => {
    const src = initSigningUrlCallback( url );
    const onMessage = useCallback((e: any) => {
        if (e.data === Constant.signingSuccess || e.message === Constant.signingSuccess) {
            callback( Constant.signingSuccess )
        } else if (e.data === Constant.signingCancel || e.message === Constant.signingCancel) {
            callback( Constant.signingCancel )
        } else if (e.data === Constant.signingError || e.message === Constant.signingError) {
            callback( Constant.signingError )
        }
    }, [callback]);

    useEffect(() => {
        addEvent( onMessage, window, 'message' );
        return () => {
            removeEvent( onMessage, window, 'message')
        };
    }, [onMessage]);

    return (
         <div className="signing-iframe-wrapper">
            {/* eslint-disable-next-line */}
            <iframe src={src} className="signing-frame"></iframe>
        </div>
    );
};