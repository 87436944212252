import React from 'react';
import Format from './format-functions';

class HelperFunctions {
    static filtrerUtAndreKlientKontoer = (kontoer, portefoljeId) =>
        kontoer.filter(konto => !konto.portefoljeId || portefoljeId === konto.portefoljeId);

    static filtrerUtKlientKontoer = kontoer => kontoer.filter(konto => !konto.portefoljeId);

    static beregnAvkastning = (totalVerdi, innskudd) => {
        if ( totalVerdi === null || innskudd === null ) { return null; }
        return totalVerdi && innskudd ? (totalVerdi - innskudd) / innskudd : 0;
    }

    static VariableInjection = (stringToEdit, variablesToInject) => {
        if (stringToEdit) {
            const matches = stringToEdit.match(/{[a-z][A-z]*}/g);

            if (matches) {
                const strippedMatches = matches.map(m => m.replace('{', '')).map(m => m.replace('}', ''));

                for (let i = 0; i < matches.length; i += 1) {
                    const replaceWith = variablesToInject[strippedMatches[i]];

                    if (replaceWith) {
                        if (typeof replaceWith === 'string' || typeof replaceWith === 'number') {
                            stringToEdit = stringToEdit.replace(matches[i], replaceWith);
                        } else {
                            stringToEdit = stringToEdit.replace(
                                matches[i],
                                `<span class="${replaceWith.class}">${replaceWith.value}</span>`
                            );
                        }
                    }
                }
            }
        }

        // eslint-disable-next-line react/no-danger
        return <span dangerouslySetInnerHTML={{ __html: stringToEdit }} />;
    };

    static capitalizeFirstLetter = text => text.charAt(0).toUpperCase() + text.slice(1);

    static finnAvtale = (pensjonsparingsavtaler, id) => {
        let result;
        return (
            pensjonsparingsavtaler.some(pensjonsparingsavtale =>
                pensjonsparingsavtale.pensjonsrettigheter.some(pensjonsrettighet => {
                    result = pensjonsrettighet;
                    return pensjonsrettighet.id && pensjonsrettighet.id.toString() === id;
                })
            ) && result
        );
    };

    static clamp = (num, min, max) => {
        const limitedNum = parseInt((num || 0).toString().slice(0, max.toString().length) || 0, 10);
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(limitedNum)) {
            return min;
        }
        const newMax = limitedNum >= max ? max : limitedNum;
        return Math.round(num <= min ? min : newMax);
    };

    static genererListeMedTall = (min, max) => {
        const liste = [];

        for (let i = min; i <= max; i += 1) {
            liste.push(i);
        }

        return liste;
    };

    static mapDisponertePortefoljerToPortefoljer = totalbeholdning => {
        return totalbeholdning.disponerteKundeforhold.flatMap(kundeforhold =>
            kundeforhold.tbPortefoljeList.map(portefolje =>
                HelperFunctions.mapTotalBeholdnignsPortefolje(portefolje, kundeforhold)
            )
        );
    };

    static mapTotalBeholdnignsPortefolje = (portefolje, kundeforhold) => {
        return {
            navn: portefolje.navn,
            id: portefolje.id ? portefolje.id.toString() : '',
            type: portefolje.portefoljeType,
            relasjonsType: portefolje.relasjonsType,
            kontantBeholdning: portefolje.kontantBeholdning || 0,
            totalVerdi: portefolje.InitialValue || 0,
            maanedligSparing: portefolje.maanedligSparing,
            portfolioBaseCode: portefolje.portfolioBaseCode,
            disposisjonType: portefolje.disposisjonType,
            innskudd: portefolje.InnskuddTotal || 0,
            urealisertGevinst: portefolje.ubenyttetSkjerming || 0,
            skattefrittBelop: portefolje.SkattefrittUttak || 0,
            bank: {
                bankregnr: portefolje.bank ? portefolje.bank.bankregnr : '',
                banknavn: portefolje.bank ? portefolje.bank.banknavn : ''
            },
            fond: portefolje.tbFondList.length ? HelperFunctions.mapToFond(portefolje.tbFondList) : [],
            eier: kundeforhold ? `${kundeforhold.fornavn} ${kundeforhold.etternavn}` : '',
            tradexCustomerId: kundeforhold.tradexCustomerId || 0
        };
    };

    static hentPortefoljeBasertPaaId = (portefoljeId, portefoljer) => {
        let riktigPortofolje = {};
        portefoljer.some(portefolje => {
            if (portefolje.id === portefoljeId || portefolje.id.toString() === portefoljeId) {
                riktigPortofolje = portefolje;
            }
            return portefolje.id === portefoljeId;
        });
        return riktigPortofolje;
    };

    static mapToFond = fondList => {
        if (fondList && fondList.length !== 0) {
            return fondList.map(fond => {
                return HelperFunctions.mapEachFond(fond);
            });
        }
        return [];
    };

    static mapEachFond = fond => {
        return {
            navn: fond.fondInfo ? fond.fondInfo.navn : '',
            isin: fond.fondInfo ? fond.fondInfo.isin : '',
            minimumsBelopEngangsKjop: fond.fondInfo ? fond.fondInfo.minimumsBelopEngangsKjop : 0,
            minimumsBelopPeriodiskKjop: fond.fondInfo ? fond.fondInfo.minimumsBelopPeriodiskKjop : 0,
            totalVerdi: fond.totalVerdi || 0,
            urealisertGevinst: fond.urealisertGevinst || 0,
            innskudd: fond.innskudd || 0,
            spareavtaler: fond.spareavtaler || [],
            maanedligSparing: fond.maanedligSparing || 0
        };
    };

    static hentFondBasertPaaIsinOgPortefoljeId = (fondIsin, portefoljeId, portefoljer, morningstarFond) => {
        const portefolje = HelperFunctions.hentPortefoljeBasertPaaId(portefoljeId, portefoljer);
        let riktigFond = {};
        if (Object.entries(portefolje).length !== 0) {
            portefolje.fond.some(fond => {
                if (fond.isin === fondIsin) {
                    riktigFond = fond;
                    if (morningstarFond) {
                        const mfond = morningstarFond.find(m => m.isin === fondIsin);

                        if (mfond) {
                            riktigFond.utvikling = mfond.utvikling;
                            riktigFond.aksjesparekontoKompatibel = mfond.aksjesparekontoKompatibel;
                            riktigFond.minimumsBelopEngangsKjop = mfond.minimumsBelopEngangsKjop;
                            riktigFond.minimumsBelopPeriodiskKjop = mfond.minimumsBelopPeriodiskKjop;
                            riktigFond.kanHandle = true;
                        } else {
                            riktigFond.kanHandle = false;
                        }
                    }
                }
                return fond.isin === fondIsin;
            });
        }

        if (!riktigFond.spareavtaler) {
            riktigFond.spareavtaler = []; // Dersom fond er null må vi instansiere en tom array
        }
        return riktigFond;
    };

    static hentFondBasertPaaIsin = (fondIsin, morningstarFond) => {
        return morningstarFond.find(m => m.isin === fondIsin);
    };

    static getMaxValue = array => {
        let max = Number.NEGATIVE_INFINITY;
        array.forEach(i => {
            max = i > max ? i : max;
        });
        return max;
    };

    static isEmpty = obj => {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== undefined) {
                return false;
            }
        }
        return true;
    };

    static getMinValue = array => {
        let min = Number.POSITIVE_INFINITY;
        array.forEach(i => {
            min = i < min ? i : min;
        });
        return min;
    };

    static tryMapText = (key, texts) => (texts.has(key) ? texts.get(key) : key);

    static guid = () => {
        const s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        };
        return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    };

    /* eslint-disable */
    static createRegexp = (text='', g=0, i=0, b=0, f=0, ignorReplacing=false ) => {
        if (text === '*') { return /.*/; }
        const v = ignorReplacing ? text : text
            .replace(/\*/, '.*')
            .replace(/\+/g, '\\+')
            .replace(/\(/g, '\\(')
            .replace(/\)/g, '\\)')
            .replace(/\?/g, '\\?')
            .replace(/\-/g, '\\-')
            .replace(/\[/g, '\\[')
            .replace(/\]/g, '\\]')
            .replace(/\$/g, '\\$');

        const m = g && i ? 'gi' : g || i ? (g ? 'g' : 'i') : '';
        const s = b ? (b === 2 ? '^' : b === 3 ? '(^|/|\\s+|,|\\()' : '(^|/|\\s+)') : '';
        const e = f ? (f === 2 ? '$' : f === 3 ? '($|/|\\s+|,|\\))' : '($|/|\\s+)') : '';
        return new RegExp(`${s}(${v})${e}`, m);
    };
    /* eslint-enable */

    static getMonthText = ( shortname=false ) => {
        return shortname ? ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'] :
            ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];
    };

    static formatDateToText = (date = new Date(), format = 'dd.mm.yyyy', ignorAddingZero = false) => {
        const quarterly = ['Første kvartal', 'Andre kvartal', 'Tredje kvartal', 'Fjerde kvartal'];
        const dayShortname = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];
        const monthShortname = HelperFunctions.getMonthText( true );
        const monthName = HelperFunctions.getMonthText();
        const month = date.getMonth();
        const list = [
            { reg: HelperFunctions.createRegexp('yyyy'), value: date.getFullYear() },
            { reg: HelperFunctions.createRegexp('mm'), value: (month + 1) },
            { reg: HelperFunctions.createRegexp('dd'), value: date.getDate() },
            { reg: HelperFunctions.createRegexp('hh'), value: date.getHours(), addZero: true },
            { reg: HelperFunctions.createRegexp('min'), value: date.getMinutes(), addZero: true },
            { reg: HelperFunctions.createRegexp('msec'), value: date.getMilliseconds(), addZero: true },
            { reg: HelperFunctions.createRegexp('sec'), value: date.getSeconds(), addZero: true },
            { reg: HelperFunctions.createRegexp('MMm'), value: monthShortname[month] },
            { reg: HelperFunctions.createRegexp('MM'), value: monthName[month] },
            { reg: HelperFunctions.createRegexp('day'), value: dayShortname[date.getDay()] },
            { reg: HelperFunctions.createRegexp('qa'), value: quarterly[parseInt(`${month / 3}`, 10)] },
        ];

        list.forEach((info) => {
            if (typeof info.value === 'number' && info.value < 10 && (info.addZero || !ignorAddingZero)) {
                info.value = `0${info.value}`;
            }
        });

        let output = format;
        list.forEach((info) => {
            if (!info.reg.test(output)) { return; }
            // @ts-ignore
            output = output.replace(info.reg, info.value);
        });
        return output;
    };

    /**************************************************************************
     * == AIS ===
    **************************************************************************/
    static getAISbyPercent = (years, amount, performance, percentageIncrease, round=false, skipCounter=0) => {
        const months = 12;
        const outcomeArr = [];

        // calc deposits
        let counter = 0;
        // eslint-disable-next-line
        Array.from({ length: years }).forEach( (x,i) => {
            const deposit = i ? outcomeArr[(outcomeArr.length-1)].deposit * (1 + percentageIncrease) : amount;
            // eslint-disable-next-line
            Array.from({length: months}).forEach( (y,j) => {
                if ( skipCounter && skipCounter > counter++ ) {
                    return;
                }

                outcomeArr.push({
                    deposit: round ? Math.round( deposit ) : deposit,
                    depositSum: 0,
                    accumulatedSum: 0
                });
            }); 
        });

        // eslint-disable-next-line
        Array.from({ length: skipCounter }).forEach( (x,i) => {
            outcomeArr.push({
                deposit: outcomeArr[(outcomeArr.length - 1)].deposit,
                depositSum: 0,
                accumulatedSum: 0
            });
        });

        outcomeArr.forEach( (data, i) => {
            if ( i === 0 ) {
                // summarize deposits & accumelated value
                outcomeArr[0].depositSum = outcomeArr[0].deposit;
                outcomeArr[0].accumulatedSum = outcomeArr[0].deposit;           
            } else {
                outcomeArr[i].depositSum = outcomeArr[i].deposit + outcomeArr[i-1].depositSum;
                outcomeArr[i].accumulatedSum = outcomeArr[i].deposit + outcomeArr[i-1].accumulatedSum + (outcomeArr[i-1].accumulatedSum * (performance / 12));

                if ( round ) {
                    outcomeArr[i].accumulatedSum = Math.round(outcomeArr[i].accumulatedSum);
                }            
            }
        });

        return outcomeArr;    
    };

    static getAISbyAmount = (years, amount, performance, yearlyIncrements, incrementAmount, round=false, skipCounter=0) =>{
        const months = 12;
        const outcomeArr = [];
        let amountTmp = amount;

        // map to convert yearly increment to modulus applicable.
        // Probably a mathematical way to convert this but hey.
        const incrementModulusMap = {
            12: 1,
            4: 3,
            2: 6,
            1: 12
        };

        let counter = 0;
        // @ts-ignore
        const yearlyIncrementsTmp = incrementModulusMap[yearlyIncrements];
        if (!yearlyIncrementsTmp ) { return outcomeArr; }

        // calc deposits
        // eslint-disable-next-line
        Array.from({ length: years }).forEach( (x,i) => {
            // eslint-disable-next-line
            Array.from({length: months}).forEach( (y,j) => {
                if ( skipCounter && skipCounter > counter++ ) {
                    return;
                }

                if (!j && !i) {
                    // Gotta skip increments first month
                } else  if (!(j % yearlyIncrementsTmp)) {
                    amountTmp += incrementAmount;
                }

                outcomeArr.push({
                    deposit: amountTmp,
                    depositSum: 0,
                    accumulatedSum: 0
                });
            });
        });

        // eslint-disable-next-line
        Array.from({ length: skipCounter }).forEach( (x,i) => {
            outcomeArr.push({
                deposit: outcomeArr[(outcomeArr.length - 1)].deposit,
                depositSum: 0,
                accumulatedSum: 0
            });
        });

        outcomeArr.forEach( (data, i) => {
            if ( i === 0 ) {
                // summarize deposits & accumelated value
                // Starting off by setting values for first day
                outcomeArr[0].depositSum = outcomeArr[0].deposit;
                outcomeArr[0].accumulatedSum = outcomeArr[0].deposit;
            } else {
                outcomeArr[i].depositSum = outcomeArr[i].deposit + outcomeArr[i-1].depositSum;
                outcomeArr[i].accumulatedSum = outcomeArr[i].deposit + outcomeArr[i-1].accumulatedSum + (outcomeArr[i-1].accumulatedSum * (performance / 12));

                if ( round ) {
                    outcomeArr[i].accumulatedSum = Math.round(outcomeArr[i].accumulatedSum);
                }
            }
        });
        return outcomeArr;
    };

    static getTheFirstDrawDate = (drawDay) => {
        const date = new Date();
        const addition = 7 * 6; // 6 weeks by setting up of "avtalegiro", then begins the first draw 
        date.setDate((date.getDate() + addition));

        const day = parseInt(`${drawDay || 0}`, 10);
        if ( day && day < date.getDate() ) {
            date.setDate( 1 );
            date.setMonth( (date.getMonth() + 1));
        }

        if ( day ) { date.setDate( day ); }
        return date;
    };

    static verifyShallIncreaseImmediately = (frequency, drawDay) => { 
        if (frequency === 'MONTHLY') { return true; }
        const date = HelperFunctions.getTheFirstDrawDate((drawDay ?? 1));

        if (frequency === 'QUARTERLY' && (date.getMonth() % 3) === 0) { return true; }
        return frequency === `${date.getMonth()}`;
    };

    static getAISplan = (config) => {
        const value = parseFloat( `${config.aisValue || 0}`.replace(/\s+/g, '').replace(',', '.') );
        let amount = config.amount || 0;
        let result = [];

        if ( !amount || !value ) { return result }

        let deposit = amount;
        const date = config.date || HelperFunctions.getTheFirstDrawDate( config.drawDay );
        const frequency = config.aisFrequency || '0'; // default every january
        const year = config.year || 30;
        const performance = config.performance || (6/100);

        if ( frequency === 'MONTHLY' || frequency === 'QUARTERLY' ){
            const yearlyIncrements = frequency === 'MONTHLY' ? 12 : 4;
    
            if (config.increaseImmediately && HelperFunctions.verifyShallIncreaseImmediately(frequency, config.drawDay)) {
                amount += value;
            }

            if ( frequency === 'MONTHLY' ) { // Monthly
                result = HelperFunctions.getAISbyAmount(year, amount, performance, yearlyIncrements, value, true );
            } else {
                const skip = date.getMonth() % 3;
                result = HelperFunctions.getAISbyAmount(year, amount, performance, yearlyIncrements, value, true, skip );
            }
        } else if ( /^([0-9]|1[0-1])$/i.test(frequency) ) {
            const fMonth = parseInt(`${frequency}` ,10);
            const dMonth = date.getMonth(); 
            // eslint-disable-next-line
            const skip =  dMonth === fMonth ? 0 : (dMonth < fMonth ? (12 - (fMonth - dMonth)) : (dMonth - fMonth));
            const percent = value / 100;

            if (config.increaseImmediately && HelperFunctions.verifyShallIncreaseImmediately(frequency, config.drawDay)) {
                amount *= (1 + percent);
            }

            result = HelperFunctions.getAISbyPercent(year, amount, performance, percent, true, skip );        
        }

        const cloned = new Date( date.getTime() );
        const dateFormat = frequency === 'QUARTERLY' ? 'yyyy - qa' : 'yyyy - MM';
        result.forEach( (data) => {
            data.dateText = HelperFunctions.formatDateToText(cloned, dateFormat).toLowerCase();
            data.stamp = cloned.toISOString().replace( /T.*/g, 'T00:00:00');
            data.info = HelperFunctions.formatDateToText(cloned, 'MM yyyy').toLowerCase();
            cloned.setMonth((cloned.getMonth() + 1));
        });

        if ( config.onlyWantDifferentDeposit && result.length ) {
            return result.map( (data) => {
                if ( data.deposit !== deposit ) {
                    deposit = data.deposit;
                    return data;
                }
                return null;
            }).filter( (data) => !!data );
        }
        return result;
    };

    static getAISpostData = (config) => {
        return config && config.ais && (config.aisPlan || []).length && config.aisValue ? {
            active: true,
            startDate: (config.aisPlan.find( (d) => d.deposit !== config.aisPlan[0].deposit ) || {}).stamp,
            increaseValueType: config.aisValueUnitPercent ? 'PERCENTAGE' : 'AMOUNT',
            percentage: config.aisValueUnitPercent ? config.aisValue : undefined,
            increaseAmount: config.aisValueUnitPercent ? undefined : config.aisValue,
            frequency: config.aisValueUnitPercent ? 'ANNUAL' : config.aisFrequency,
         } : undefined;
    };

    static getAISsummaryText = (config) => {
        if ( !config || !config.ais || !(config.aisPlan || []).length || !config.aisValue || !config.aisFrequency ) { return 'Av'; }
        const frequency = HelperFunctions.getAISfrequencyList().find( (d) => d.id === config.aisFrequency ) || {};

        if ( config.aisValueUnitPercent ) {
            return `${Format.currency(config.aisValue)} % ${frequency.tekst || ''}`.replace(',-', '').trim();
        }

        return `${Format.currency(config.aisValue)} ${frequency.tekst || ''}`.trim();
    };

    static getAISfrequencyList = () => {  
        return [
            {id: 'QUARTERLY', tekst: 'Hvert kvartal - Fire ganger i året',},
            {id: 'MONTHLY', tekst: 'Hver måned',},
            {id: '0', tekst: 'Hver år - januar',},
            {id: '1', tekst: 'Hver år - februar',},
            {id: '2', tekst: 'Hver år - mars',},
            {id: '3', tekst: 'Hver år - april',},
            {id: '4', tekst: 'Hver år - mai',},
            {id: '5', tekst: 'Hver år - juni',},
            {id: '6', tekst: 'Hver år - juli',},
            {id: '7', tekst: 'Hver år - august',},
            {id: '8', tekst: 'Hver år - september',},
            {id: '9', tekst: 'Hver år - oktober',},
            {id: '10', tekst: 'Hver år - november',},
            {id: '11', tekst: 'Hver år - desember',},
        ];
    };
};

export default HelperFunctions;
