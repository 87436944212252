import { Component } from 'react';

class GrafFunksjoner extends Component {
    static createArrayOfValuesAdjustedToViewBox = (array, bounds, height) => {
        const diff = bounds.max - bounds.min;

        return array.map(v => {
            const newY = ((v.y - bounds.min) / diff) * height;
            return { x: v.x, y: newY };
        });
    };

    static flipValuesToAdjustToSvgOriginOfZero = (values, height) => {
        return values.map(v => {
            return { x: v.x, y: height - v.y };
        });
    };

    static lowerBound = bound => {
        const tmpString = Math.floor(bound * 0.9).toString();
        const firstDigit = parseInt(tmpString[0], 10);
        const stringBound = firstDigit.toString() + new Array(tmpString.length).join('0');
        return parseInt(stringBound, 10);
    };

    static higherBound = bound => {
        const tmpString = Math.floor(bound).toString();
        const firstDigit = parseInt(tmpString[0], 10) + 1;
        const stringBound = firstDigit.toString() + new Array(tmpString.length).join('0');
        return parseInt(stringBound, 10);
    };

    static getYAxisIntervals = (height, lineMargin) => {
        return Math.floor(height / lineMargin);
    };

    static getYAxisIntervalValue = (numberOfYAxisIntervals, min, max) => {
        const diff = max - min;
        const unroundedIntervalValue = diff / (numberOfYAxisIntervals - 1);
        return GrafFunksjoner.higherBound(unroundedIntervalValue);
    };
}

export default GrafFunksjoner;
