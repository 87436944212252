import { get } from "@eika-infrastruktur/request";
import { VIS_FEILMELDINGS_KVITTERING, VIS_KVITTERING, VIS_KVITTERING_LASTER } from './modals';

export const SIGNERING_OPPRETTET = 'SIGNERING_OPPRETTET';
export const KUNNE_IKKE_FULLFORE_ORDRE = 'KUNNE_IKKE_FULLFORE_ORDRE';
export const KUNNE_IKKE_OPPRETTE_SIGNERING = 'KUNNE_IKKE_OPPRETTE_SIGNERING';
export const FERDIG_SIGNERT = 'FERDIG_SIGNERT';
export const KLAR_FOR_SIGNERING = 'KLAR_FOR_SIGNERING';
export const VIS_FEILMELDING = 'VIS_FEILMELDING';
export const SERVER_POLL = 'SERVER_POLL';
export const SIGNERINGS_ORDRE_OPPRETTET = 'SIGNERINGS_ORDRE_OPPRETTET';
export const KUNNE_IKKE_OPPRETTE_ORDRE = 'KUNNE_IKKE_OPPRETTE_ORDRE';
export const IKKE_EIER_AV_KONTONUMMER = 'IKKE_EIER_AV_KONTONUMMER';
export const RESET_ORDRE_PROSESS = 'RESET_ORDRE_PROSESS';

const baseUrlFond = '/sparing-min-side-web/rest/resource/fond';
const ordreUrl = `${baseUrlFond}/ordre`;
const MAX_REPOLLS = 40;
const POLL_TIMEOUT = 3000;

const timeout = (type, feilmelding, dispatch) => {
    dispatch({
        type,
        feilmelding
    });
};

const pollServer = (url, dispatch, orderId, isSuccess, failureMsgKey, failureKey, successFunc, counter = 0) => {
    const repollAfterTimeout = () => {
        if (counter < MAX_REPOLLS) {
            const timeoutId = setTimeout(() => {
                pollServer(
                    url,
                    dispatch,
                    orderId,
                    isSuccess,
                    failureMsgKey,
                    failureKey,
                    successFunc,
                    (counter += 1)
                );
            }, POLL_TIMEOUT);

            dispatch({
                type: SERVER_POLL,
                data: { [orderId]: timeoutId }
            });
        } else {
            timeout(failureKey, failureMsgKey, dispatch);
        }
    };

    get(url)
        .then(data => {
                if (isSuccess(data)) {
                    successFunc(data, dispatch);
                } else {
                    repollAfterTimeout();
                }
        }).catch(()=> {
            dispatch({
                type: failureKey,
                feilmelding: failureMsgKey
            });
        });
};

const paaVelykketOpprettingAvSigneringsDokument = (data, dispatch) => {
    const { signeringsUrl } = data;

    dispatch({
        type: SIGNERING_OPPRETTET,
        data: { signeringsUrl }
    });
};

export const hentSigneringsUrl = (ordreId, dispatch) => {
    const url = `${ordreUrl}/${ordreId}/status`;
    const isSuccess = data => data.status === KLAR_FOR_SIGNERING;

    pollServer(
        url,
        dispatch,
        ordreId,
        isSuccess,
        KUNNE_IKKE_OPPRETTE_SIGNERING,
        VIS_FEILMELDING,
        paaVelykketOpprettingAvSigneringsDokument
    );
};

export const paaVelykketOpprettingAvOrdre = (data, dispatch) => {
    const { ordreId } = data;

    dispatch({
        type: SIGNERINGS_ORDRE_OPPRETTET,
        data: { ordreId }
    });

    hentSigneringsUrl(ordreId, dispatch);
};

const handleSigneringsresultat = (data, dispatch) => {
    if (data.ordreFeilMelding) {
        dispatch({
            type: VIS_FEILMELDINGS_KVITTERING,
            meldingFraServer: data.ordreFeilMelding
        });
    } else {
        dispatch({
            type: VIS_KVITTERING
        });
    }
};

const verifiserSigneringFullfort = (orderId, dispatch) => {
    const url = `${ordreUrl}/${orderId}/status`;
    const isFinished = data => data.fullfort;

    pollServer(
        url,
        dispatch,
        orderId,
        isFinished,
        KUNNE_IKKE_FULLFORE_ORDRE,
        VIS_FEILMELDINGS_KVITTERING,
        handleSigneringsresultat
    );
};

export const fullforOrdre = ordreId => {
    return dispatch => {
        dispatch({
            type: VIS_KVITTERING_LASTER
        });

        verifiserSigneringFullfort(ordreId, dispatch);
    };
};

export const onFeiletSignering = errorCode => {
    return dispatch => {
        dispatch({
            type: VIS_FEILMELDINGS_KVITTERING,
            meldingFraServer:
                errorCode === 'signingCancel'
                    ? 'Signeringen ble kansellert'
                    : 'En feil oppstod under signering. Vennligst prøv på nytt.'
        });
    };
};

export const resetOrdreProsess = () => {
    return dispatch => {
        dispatch({
            type: RESET_ORDRE_PROSESS
        });
    };
};
