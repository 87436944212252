import React, { useEffect, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { StandardButton } from '@eika/button';
import { Modal } from '@eika/modal';
import { Link } from '@eika/link';
import { IconTypes } from '@eika/icon';
import {RouteComponentProps} from "react-router";
import Constant from '../../../static/data/Constant';
import { usePrevious } from '../../../common/util/Hook';
import Message from '../../../common/Message/Message';
import Card, { CardLabel } from '../../../common/Card/Card';
import Grid from '../../../common/Grid/Grid';
import AppMessage from '../../../common/AppMessage/AppMessage';
import { HeaderLink, Spinner, LinkList, LinkListItemProps, AboutTaxFreeInfo } from '../../../common/share/ShareComponents';
import { getNewFondOrder } from '../../../common/util/FondFunctions';
import { formatAmount, openSmartspart, getPageAppending } from '../../../common/util/Functions';
import { getCurrentAccount, getErrorToPerformSalgByttUttak } from '../util/Functions';
import { getLabelList } from '../../../common/util/BusinessFunctions';
import BankList from '../../../static/data/BankList';
import './Klientkonto.scss';
import {RouterConfig} from "../../../app/Router";

type  Props = RouteComponentProps<{accountId?: string}> & RouterConfig;

interface State {
    appending: string;
    content?: any;
    reducer: any;
    attention?: {type: string; text: string};
    labelList?: CardLabel[];
    infoLink: {url: string, name: string};
    linkList?: LinkListItemProps[],
    modal: string;
}

const click = ( props: Props, state: State, setState: (s: State) => void, e: MouseEvent | null, key='' ): void => {
    if ( e && typeof(e.preventDefault) === 'function' ) {
        e.preventDefault();
    }

    if ( key === Constant.actionSettInnPenger || key === Constant.actionApneSmartspar ) {
        openSmartspart( key );
    } else if ( key === 'about-tax-free' ) {
        window.location.href =  `${window.location.href.replace(/\/+$/g,'')}/comingsoon`;
    } else if ( key === Constant.actionUttak ) {        
        const error = getErrorToPerformSalgByttUttak(state.content.account, true);
        if ( error ) {
            props.history.push(`${props.location.pathname}/stopp/${error}`);
        } else {
            const { accountId } = props?.match?.params || {};
            const order = getNewFondOrder({
                placement: accountId || '',
                fund: Constant.typeBankkonto,
                maxAmount: Math.round( state.content.account.kontantBeholdning ),
                action: Constant.actionUttak,
                type: Constant.typeBankkonto,
            });
            props.history.push(`${props.location.pathname}/fondhandel/${order}/step3?action=${Constant.piwikActionWithdrawal}`);
        }
    } else if ( key === Constant.actionKjop ) {
        const { accountId } = props?.match?.params || {};
        const order = getNewFondOrder({
            type: Constant.typeEngangsinnkudd,
            placement: accountId || '',
            bankAccount: '0',
            account: accountId || '',
            maxAmount: Math.round( state.content.account.kontantBeholdning ),
        });
        props.history.push(`${props.location.pathname}/fondhandel/${order}/step1?action=${Constant.piwikActionOnetime}`);       
    } else if ( key === 'open-account-info' || key === Constant.actionOpenAboutTaxFree ) {
        setState({...state, modal: key});
    } else if ( key === Constant.actionCloseModal ) {
        setState({...state, modal: ''});
    }
};

const getAccountDetail = ( account: any, taxFree?: string ): any => {
    if ( !account ){ return; }

    return {
        title: 'Kontodetaljer',
        configList: [
            { 
                data: { textList: [[
                    {text: 'Tilknyttet', type: 'font-normal'},
                    {text: account.navn},
                ]],}
            }, {
                data: { textList: [[
                    {text: 'Kontonummer', type: 'font-normal'},
                    {text: account.portfolioBaseCode},
                ]],}                       
            }, {
                action: Constant.actionOpenAboutTaxFree,
                data: taxFree ? { textList: [[
                    {text: 'Skattefritt uttak', type: 'font-normal'},
                    {text: taxFree},
                ]],} : null                       
            }, {
                data: { textList: [[
                    {text: 'Bank', type: 'font-normal'},
                    {text: account.bank.banknavn},
                ]],}                       
            }
        ].filter( (d: any) => !!d && !!d.data ).filter((d: any) => !!d),
    }
};

const getContent = ( props: Props, reducer: any ): any => {
    const account = getCurrentAccount( props, reducer );
    if ( !account ) { return; }

    const { appTexts, kundeforhold } = props || {};
    const bank: any = BankList.find( (d: any) => d.id === (((kundeforhold || [])[0] || {}).bankregnr)) || {};
    const extention = /eika.no/i.test(bank.homepage) ? 'smartspar' : 'spare/smartspar';
    const aboutSmartsparUrl = bank.homepage ? `${bank.homepage}${extention}` :
        `${window.location.href.replace(/\/+$/g,'')}/comingsoon`;

    const taxFree = account.portefoljeType === Constant.typeAsk ? 
        formatAmount( account.skattefrittUttak , true ) : undefined;

    return {
        labelList: getLabelList(account, {eier: true, ips: true}),
        aboutSmartsparUrl,
        account,
        taxFree,
        totalVerdi: formatAmount(account.kontantBeholdning, true),
        attention: account.sperre ? {type: 'tag -blocked', text: appTexts.get(`${account.sperre.toLowerCase()}SperretKonto`)} : undefined,
        detail: getAccountDetail( account, taxFree ),
        warning: account.totalVerdi === null ? props.appTexts.get('totalVerdiMangelMelding') : '',
        linkList: [
            account.disponert || (account.kontantBeholdning || 0) < 1 || account.portefoljeType === Constant.typeIps ? null : 
                { 
                    id: Constant.actionUttak, 
                    name: appTexts.get('taUtPenger'),
                    dataTrackId: Constant.trackIdProduct,
                    dataTrackLinkname: appTexts.get('taUtPenger', undefined),
                },
        ].filter( (d: any) => !!d )
    };
}

export default (props: Props): JSX.Element => {
    const reducer = useSelector((state: any) => {
        return ['App', 'Fond'].reduce( (p: {[k:string]: any}, k: string) => {
            return {...p, [k]: (state || {})[k] || {} };
        }, {});
    });
    const [state, setState] = useState<State>({
        // content: getContent( props, reducer ),
        appending: getPageAppending( props ),
        reducer,
        infoLink: {url: `${window.location.href.replace(/\/+$/g,'').replace('/klientkonto', '')}`, name: 'Aksjesparekonto'},
        modal: '',
    });
    
    const previousReducer = usePrevious(reducer);
    const { appTexts } = props;
    const { content, appending } = state;

    useEffect(() => {
        if ( JSON.stringify(reducer) === JSON.stringify(previousReducer) ) { return; }
        setState({...state, reducer, content: getContent( props, reducer )});
    }, [previousReducer, reducer, state, props]);

    return (
        <div className="klientkonto-wrapper">
            <Grid namespace="sparing-top" list={[{
                layout: 'twelve',
                element: <HeaderLink text="Aksjesparekontoen"/>
            }]}/>

            <Grid namespace="sparing-header" list={[{
                layout: 'twelve',
                element:<>
                    { (state.labelList || []).length > 0 && <Card type="plain" data={{labelList: state.labelList}}/> }
                    <h1>Klientkonto</h1>
                    { !!state.infoLink && <div className="header-info-link-wrapper">
                        <Link icon={IconTypes.BUET_PIL_HOEYRE_20} href={state.infoLink.url}>{state.infoLink.name}</Link>
                    </div>}

                </>
            }]}/>

            <div className={`klientkonto-content ${appending}`}>
                <Grid namespace="sparing-content" list={[{
                    layout: 'seven',
                    element: <>
                        { content ? <>
                            <AppMessage appMessage={reducer?.App?.serviceMessage} context="CLIENT_ACCOUNT">
                                { !!content.attention && <Message role="presentation" {...content.attention}/> }
                            </AppMessage>

                            <div className="paragraph">
                                { content.warning ? 
                                    <Message type="WarningMessage" text={content.warning} icon/> :
                                    <>
                                        <div aria-labelledby="sparing-fund-total-text" className="header-total-value">{content.totalVerdi}</div>
                                        <div id="sparing-fund-total-text" className="header-total-text">{appTexts.get('totalverdi')}</div>
                                    </>}
                            </div>

                            { state.content.account.kontantBeholdning > 100 && <div className="paragraph center">
                                <StandardButton data-track-id={Constant.trackIdBuy} data-track-linkname="spar-i-fond" onClick={(e)=>{ click(props, state, setState, e, Constant.actionKjop)}}>
                                    Spar i fond
                                </StandardButton>
                            </div> }

                            <LinkList list={state.content.linkList} namespace="paragraph center" click={(e: any, k: string) => { click(props, state, setState, e, k); }}/>

                            { !!state.content.detail && <div  className="paragraph-large -only-top klientkonto-detail-wrapper">
                                <h2 className="paragraph">{state.content.detail.title}</h2>
                                { state.content.detail.configList.map( (config: any, j: number) => (
                                    <Card key={`client-detail-${j}`} 
                                        {...config} 
                                        type={config.type || 'list-item'} 
                                        click={ config.action ? (e: any, k='' )=> { click(props,state, setState, e, k); } : undefined}
                                    />
                                ))}
                            </div>}

                        </> : <Spinner type="konti"/> }
                    </>
                }, {
                    layout: 'four',
                    element: <>
                        <Message
                            role="presentation"
                            type="InfoMessage"
                            text="Med denne kontoen kan du ta penger ut av fond og inn på tilknyttet bankkonto uten at det utløser skatt. Det er først når du tar pengene helt ut fra Aksjesparekontoen og over til en brukskonto at det kan utløse skatt."
                        />
                    </>
                }]}/>
            </div>

            <Modal id="konti-modal"  appNamespace="sparing-react-app"  onClose={() => { click(props, state, setState, null, Constant.actionCloseModal); }}  show={!!state.modal} >
                {  state.modal === Constant.actionOpenAboutTaxFree && !!state.content?.account && <AboutTaxFreeInfo amount={state.content.taxFree}/>}
            </Modal>
        </div>
    );
};
