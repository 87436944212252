import React, { useState  } from 'react';
import FormatFunctions from './format-functions';
import Modal from './modal';

// eslint-disable-next-line
export default ({ fond }) => {    
    const [openDetail, setOpenDetail] = useState(false);
    const isEika = /^eika\s+/i.test((fond || {}).navn);
    const noteList = fond ? [
        { id: 'platform-fee',   label: 'Plattformhonorar', value: FormatFunctions.percent((fond.platformFee || 0)).replace('.', ',')},
        { id: 'ongoing-charge', label: 'Løpende kostnader', value:FormatFunctions.percent((fond.ongoingCharge || 0)).replace('.', ',')},
        { id: fond.kickback && fond.kickback > 0 ? 'kickback' : '', label: 'Returprovisjon', value: `${fond.kickback ? '- ' : ''}${FormatFunctions.percent((fond.kickback || 0)).replace('.', ',')}`},
        { id: 'yearly-const',   label: 'Årlig kostnad totalt', value: FormatFunctions.percent((fond.yearlyCost || 0)).replace('.', ',')},
    ].filter ( (d) => !!d.id ) : null;

    return (
        <div className="message-wrapper -info -icon -fee-info-message">
            { noteList ? <>
                <p>Fra 1. mai endres måten du betaler årlig honorar for fond fra andre fondsforvaltere.</p>
                {  isEika ? <p>Det er ikke plattformhonorar for Eika-fond.</p> :
                    <p>Årlig kostnad består av både plattformhonorar og løpende kostnader.</p>
                }

                <ul className="fee-info-list">
                    { noteList.map( (d,i) => {
                        return <li key={`fee-info-item-${i}`} className="fee-info-item">
                            { d.id === 'ongoing-charge' ?
                                <a 
                                    href="#"
                                    role="button" 
                                    onClick={(e) => { e.preventDefault(); setOpenDetail(true);}} 
                                    id={`fee-info-label-${i}`} className="fee-info-label"
                                >{d.label}</a> : <span id={`fee-info-label-${i}`} className="fee-info-label">{d.label}</span>
                            }
                            <span aria-labelledby={`fee-info-label-${i}`} className="fee-info-value">{d.value}</span>
                        </li>
                    })}
                </ul>          
                <p>
                    <a target="blank" href="https://eika.no/spare/plattformhonorar">Om plattformhonorar</a> 
                </p>
            </> : <>
                <p>Eika kapitalforvaltning innfører plattformhonorar på fond fra andre fondsforvaltere.</p>
                <p>Med virkning fra 1. mai 2023 vil vi endre måten du betaler for fond fra andre fondsforvaltere ved å innføre plattformhonorar. Dette gjør vi, i likhet med andre distributører av verdipapirfond, for å tilfredsstille regulatoriske krav.</p>
                <p>
                    Nærmere informasjon om kostnader, samt beregning og belastning av plattformhonorar finner du på våre nettsider <a target="blank" href="https://eika.no/spare/plattformhonorar">eika.no/spare/plattformhonorar</a> 
                </p>
            </>}

            { openDetail && <Modal visModal={openDetail} namespace="fee-message-about-ongoing-charge" lukkModal={()=>{setOpenDetail(false);}}>
                <div className="fee-message-about-ongoing-charge-cnt">
                    <h2 className="h3">Løpende kostnader</h2>
                    <p>Løpende kostnader inkluderer forvaltningskostnad og enkelte administrasjonskostnader.</p>
                    <p>I tillegg kan ekstraordinære kostnader som er nødvendige for å ivareta andelseiernes interesser belastes fondet.</p>
                    <p>Norskregistrerte verdipapirfond er underlagt et strengt regelverk når det gjelder hvilke kostnader som kan belastes fondet.</p>
                </div>
            </Modal>}
        </div>
    );
};