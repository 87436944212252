import React, { Component, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import VerticalStep from './VerticalStep';

class VerticalProcess extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    };

    renderChildren() {
        const children = this.props.children.filter(Boolean);
        const totalSteps = Children.count(children);

        // Grunnet forskjeller i React versjon på SiteCore og lokalt så vil ikke denne HOC fungere på nåværende tidspunkt.
        // Unngå å bruke index og TotalSteps prosene
        return Children.map(children, (child, index) => {
            if (child.type.displayName === VerticalStep.name) {
                return cloneElement(child, {
                    index: index + 1,
                    totalSteps
                });
            }
            return child;
        });
    }

    render() {
        return (
            <article className="card card-list-expandable card-list-progress js-progress">
                <div className="list-group">{this.renderChildren()}</div>
            </article>
        );
    }
}

export default VerticalProcess;
