/**
 * Created by vagrant on 01.03.17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '@eika-infrastruktur/ui-kort-laster';

import Format from '../format-functions';
import Card from '../card';
import HelperFunctions from '../helper-functions';

require('../../styles/spart-hittil-iaar.scss');

class SpartHittilIAar extends Component {
    static propTypes = {
        texts: PropTypes.object,
        belop: PropTypes.number,
        hentAarligSparebelop: PropTypes.func,
        portfolioBaseCode: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    UNSAFE_componentWillReceiveProps = newProps => {
        if (newProps.belop || newProps.belop === 0) {
            this.setState({
                belop: newProps.belop
            });
        }
    };

    UNSAFE_componentWillMount() {
        if (this.props.belop || this.props.belop === 0) {
            this.setState({
                belop: this.props.belop
            });
        } else {
            this.props.hentAarligSparebelop(this.props.portfolioBaseCode);
        }
    }

    visBraJobbet = belop => {
        const duHarSpart = HelperFunctions.VariableInjection(this.props.texts.get('braJobbet'), {
            belop: { value: Format.currency(belop), class: 'number' }
        });

        if (belop >= 15000) {
            return <div className="du-har-spart">{duHarSpart}</div>;
        }

        return '';
    };

    render() {
        if (this.state.belop || this.state.belop === 0) {
            const fullWidth = true;
            const barWidth = { width: `calc(${(this.props.belop / 15000) * 100}% + 1px)` };

            return (
                <div className="spart-hittil-iaar">
                    <Card fullWidth={fullWidth}>
                        <div>
                            <div>
                                <span>{this.props.texts.get('spartHittilIaar')}</span>
                                <span className="number"> {Format.currency(this.props.belop)}</span>
                            </div>
                            <div>
                                <div className="spart-hittil-iaar-bar">
                                    <div style={barWidth} />
                                </div>
                                <div className="max-per-aar">{this.props.texts.get('maksPerAarIps')}</div>
                            </div>
                            {this.visBraJobbet(this.props.belop)}
                        </div>
                    </Card>
                </div>
            );
        }
        return (
            <div className="spart-hittil-iaar">
                <Loading />
            </div>
        );
    }
}

export default SpartHittilIAar;
