import { ProcessJsonResponse, utforOrdre } from './common';
import { VIS_KVITTERING } from './modals';

export const IPS_OPPRETTET = 'IPS_OPPRETTET';
export const LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA = 'LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA';
export const OEK_MND_SPARING = 'OEK_MND_SPARING';
export const HENT_FORVENTET_PENSJONSTALL = 'HENT_FORVENTET_PENSJONSTALL';
export const LASTER_PENSJONS_ESTIMATER = 'LASTER_PENSJONS_ESTIMATER';
export const PENSJON_PROGNOSE_OK = 'PENSJON_PROGNOSE_OK';
export const PENSJON_PROGNOSE_ERROR = 'PENSJON_PROGNOSE_ERROR';
export const MAX_ANTALL_KALL_OVERSKREDET = 'MAX_ANTALL_KALL_OVERSKREDET';
export const ACKNOWLEDGE_ERROR = 'ACKNOWLEDGE_ERROR';

const baseUrl = '/sparing-min-side-web/rest/resource/pensjon';
const fondUrl = '/sparing-min-side-web/rest/resource/fond';
const spareUrl = '/sparing-min-side-web/rest/resource/spare';
const arbeidsgiversPensjonssparingUrl = `${spareUrl}/arbeidsgiverspensjonssparingV2`;
const opprettIPSUrl = `${fondUrl}/portefoljer/ips`;
const FORBIDDEN = 403;
const MAX_ANTALL_KALL_BEREGN_PENSJON_OVERSKREDET = 'SPA1880007';

const paaVellykketIPSOpprettelse = (data, dispatch) => {
    dispatch({
        type: IPS_OPPRETTET,
        portefoljer: data.portefolje,
    });

    dispatch({
        type: VIS_KVITTERING,
        ordreResultater: data.ordreResultater,
    });
};

export const opprettIPS = (data) => {
    return utforOrdre(data, opprettIPSUrl, 'POST', paaVellykketIPSOpprettelse);
};

export const hentForventetPensjon = (alder, bruttoInntekt) => {
    return (dispatch) => {
        dispatch({
            type: LASTER_PENSJONS_ESTIMATER,
        });

        fetch(
            `${baseUrl}/forventetPensjon/onsketalder/${alder}/bruttoaarsinntekt/${bruttoInntekt}?inkluderFond=true`,
            { credentials: 'same-origin' }
        ).then((data) => {
            if (data.status === FORBIDDEN) {
                data.json().then((errormessage) => {
                    if (errormessage.code === MAX_ANTALL_KALL_BEREGN_PENSJON_OVERSKREDET) {
                        dispatch({
                            type: MAX_ANTALL_KALL_OVERSKREDET,
                            message: errormessage.message,
                            name: errormessage.name,
                        });
                    }
                });
            }

            if (data.ok) {
                data.json().then((data) => {
                    dispatch({
                        type: HENT_FORVENTET_PENSJONSTALL,
                        data,
                    });
                });
            }
        });
    };
};

export const beregnPensjonVedOktMndSparing = (alder, oktMndSparing) => {
    return (dispatch) => {
        fetch(`${baseUrl}/beregnpensjonvedoktmndsparing/onsketalder/${alder}/oktmndsparing/${oktMndSparing}`, {
            credentials: 'same-origin',
        }).then((data) => {
            if (data.status === FORBIDDEN) {
                data.json().then((errormessage) => {
                    if (errormessage.code === MAX_ANTALL_KALL_BEREGN_PENSJON_OVERSKREDET) {
                        dispatch({
                            type: MAX_ANTALL_KALL_OVERSKREDET,
                            message: errormessage.message,
                            name: errormessage.name,
                        });
                    }
                });
            }
            if (data.ok) {
                data.json().then((data) => {
                    dispatch({
                        type: OEK_MND_SPARING,
                        data,
                    });
                });
            }
        });
    };
};

export const lastarbeidsgiversPensjonssparing = () => {
    return (dispatch) => {
        return fetch(`${arbeidsgiversPensjonssparingUrl}`, { credentials: 'same-origin' }).then((data) =>
            ProcessJsonResponse(LAST_ARBEIDSGIVERS_PENSJONSSPARING_DATA, data, dispatch)
        );
    };
};

export const lastPrognoseForAvtale = (avtalenummer, polisenummer) => {
    return (dispatch) => {
        return fetch(`${baseUrl}/prognoser/${avtalenummer}/${polisenummer}`, { credentials: 'same-origin' })
            .then((response) => {
                if (response.ok) {
                    response
                        .json()
                        .then((prognose) => {
                            dispatch({
                                type: PENSJON_PROGNOSE_OK,
                                data: prognose,
                            });
                        })
                        .catch((err) => {
                            dispatch({
                                type: PENSJON_PROGNOSE_ERROR,
                                error: err,
                            });
                        });
                }
            })
            .catch((err) => {
                dispatch({
                    type: PENSJON_PROGNOSE_ERROR,
                    error: err,
                });
            });
    };
};

export const acknowledgeError = () => {
    return (dispatch) => {
        dispatch({ type: ACKNOWLEDGE_ERROR });
    };
};
