import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';
import { Modal } from '@eika/modal';
import { IconTypes } from '@eika/icon';
import { getPageAppending } from '../../../../common/util/Functions';
import './SavingsProfiles.scss';
import Grid from '../../../../common/Grid/Grid';
import { HeaderLink, Spinner } from '../../../../common/share/ShareComponents';
import AppMessage from '../../../../common/AppMessage/AppMessage';
import LargeLinkCardWithAction from '../../../../common/ExtendedStyleguide/LargeLinkCardWithAction';
import { State } from '../../../../domain/State';
import ProductInfoCard from '../../../../common/ExtendedStyleguide/ProductInfoCard';
import { RouterConfig } from '../../../../app/Router';

const SwitchSavingProfilePage: FC<RouteComponentProps & RouterConfig> = (props) => {
    const appState = useSelector((state: State) => state?.App);

    const availableProfiles = [] as { isin: string; name: string; preferred: boolean }[];
    const appending = getPageAppending(props);
    const [loading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className={`switch-profile-page ${appending || 'none-appending'}`}>
            <Grid
                namespace="sparing-top"
                list={[
                    {
                        layout: 'twelve',
                        element: <HeaderLink length={1} text="Spareprofiler" />,
                    },
                ]}
            />

            <Grid
                namespace="sparing-header"
                list={[
                    {
                        layout: 'twelve',
                        element: <h1>Endre spareprofil</h1>,
                    },
                ]}
            />

            <Grid
                list={[
                    {
                        layout: 'seven',
                        element: (
                            <>
                                <AppMessage appMessage={appState?.serviceMessage} context="STOREBRAND_RISK_PROFILE" />
                                <article>
                                    Innskuddene fra din arbeidsgiver investeres i fond, satt sammen til ulike
                                    spareprofiler. Hver profil har ulik risiko og potensiale for verdiutvikling. Det er
                                    andelen aksjefond som bestemmer risikoen. Du kan selv endre spareprofil her dersom
                                    du ønsker en annen spareprofiler enn den som arbeidsgiveren din har valgt for deg.
                                </article>

                                <LargeLinkCardWithAction
                                    url="#"
                                    title="Sammenligne spareprofilene"
                                    icon={IconTypes.GRAFLINJE_36}
                                    text="Se hvordan det har gått med de ulike spareprofilene historisk og hva som er forventet fremtidig verdiutvikling."
                                />

                                {availableProfiles.map((profile) => (
                                    <div className="component-group">
                                        <ProductInfoCard
                                            key={profile.isin}
                                            highlightedNote={profile.preferred ? 'pref' : undefined}
                                            label={profile.name}
                                        >
                                            test test
                                        </ProductInfoCard>
                                    </div>
                                ))}

                                {loading && <Spinner type="linkPanel" />}
                            </>
                        ),
                    },
                    {
                        layout: 'four',
                        element: null,
                    },
                ]}
            />
            <Modal onClose={() => setModalOpen(false)} show={modalOpen}>
                <>
                    <h2 className="h2 paragraph">Om nedtrapping</h2>
                    <p>
                        Totalt oppspart beløp består av både pensjon du sparer nå hos din nåværende arbeidsgiver, og
                        tidligere arbeidsgivere som har blitt flyttet over til denne pensjonskontoen.
                    </p>
                </>
            </Modal>
        </div>
    );
};

export default SwitchSavingProfilePage;
